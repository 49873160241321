import { gql } from "@apollo/client";

const UPLOAD_MENU = gql`
  mutation uploadMenu(
    $locationId: ID!
  ) {
    uploadMenu(
      input: {
        locationId: $locationId,
      }
    ) {
      location {
        id
        label
      }
    }
  }
`;

export default UPLOAD_MENU;
