import moment from 'moment-timezone';
import { ConnectionTable, SelectFactory, Elements, Permission } from '../../../blocks';
import { orderListViewStyles } from '../AllOrders/List/View/styles';
import { paths } from '../../../../constants';
import { generateStoreAbbreviation, convert } from '../../../../utils';
import React, { useState } from 'react';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../constants';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SearchIcon from '../../../../assets/icons/SearchIcon.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { compact, debounce } from 'lodash';
import { useQuery } from '@apollo/client';
import { CATEGORIES, HANDOFFS, LOCATIONS, ORIGINS } from '../../../../apollo/queries/filters';
import ABANDONED_CARTS from '../../../../apollo/queries/abandonedCarts';

const CATERING_CATEGORYTYPE_ID = 3;

const AbandonedCarts = () => {
  const classes = orderListViewStyles();
  const [searchValue, setSearchValue] = useState();
  const [openDateRangeSelectionModal, setOpenDateRangeSelectionModal] = useState(false);
  const [dateRangeStart, setDateRangeStart] = useState(moment());
  const [dateRangeEnd, setDateRangeEnd] = useState(moment());
  const { data: originData, loading: originLoading, error: originError } = useQuery(ORIGINS, {
    variables: {
      filter: {
        id: {
          in: ['T3JpZ2luOjE=', 'T3JpZ2luOjI0', 'T3JpZ2luOjI2'],
        },
      },
    },
  });
  const { data: categoryData, loading: categoryLoading, error: categoryError } = useQuery(
    CATEGORIES,
    {
      variables: {
        filter: {
          id: {
            //[Order Menu, Catering, Holiday, Upsell],
            in: [
              'Q2F0ZWdvcnk6MTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==',
              'Q2F0ZWdvcnk6Mjo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==',
              'Q2F0ZWdvcnk6NDo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==',
              'Q2F0ZWdvcnk6NTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==',
              'Q2F0ZWdvcnk6Mzo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==',
            ],
          },
          showHiddenToLocation: {
            eq: true,
          },
          showHiddenToCustomer: {
            eq: true,
          },
        },
      },
    },
  );
  const { data: handoffData, loading: handoffLoading, error: handoffError } = useQuery(HANDOFFS);

  if (originLoading || categoryLoading || handoffLoading) <div>loading...</div>;
  if (originError || categoryError || handoffError) <div>error</div>;

  const getSelectedDateRangeValue = variables => {
    const filters = variables.filter;
    const startDate = filters?.expected?.gte;
    const endDate = filters?.expected?.lte;

    return `${(startDate && moment(startDate)?.format('MM/DD/YYYY')) ||
      'MM/DD/YYYY'} to ${(endDate && moment(endDate)?.format('MM/DD/YYYY')) || 'MM/DD/YYYY'}`;
  };

  const handleDateRangeSelection = ({ refetch, variables }, removeDate = false) => {
    if (removeDate) {
      const filters = variables.filter;
      delete filters.expected;
      refetch({
        ...variables,
        filter: filters,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          expected: {
            gte: dateRangeStart.format('YYYY-MM-DD 00:00:00'),
            lte: dateRangeEnd.format('YYYY-MM-DD 23:59:59'),
          },
        },
      });
    }
    setOpenDateRangeSelectionModal(false);
  };

  const handleDateRangeStartChange = value => {
    setDateRangeStart(value);
  };
  const handleDateRangeEndChange = value => {
    setDateRangeEnd(value);
  };

  const handleOpenDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(true);
  };
  const handleCloseDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(false);
  };

  const search = (refetch, variables, value) => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
        quoteId: {
          eq: parseInt(value),
        },
      },
    });
    setSearchValue(value);
  };

  const [debouncedSearch] = useState(() =>
    debounce((refetch, variables, value) => search(refetch, variables, value), 200),
  );
  const handleSearchInput = (refetch, variables, event) => {
    setSearchValue(event.target.value);
  };
  const removeQuoteIdFilter = (refetch, variables) => {
    delete variables.filter.quoteId;
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
      },
    });
    document.getElementById('quoteID').value = null;
    setSearchValue(null);
  };
  return (
    <ConnectionTable
      query={ABANDONED_CARTS}
      onTableRowClick={row => {
        if (Number(row.categorytypeId) === CATERING_CATEGORYTYPE_ID) {
          window.open(
            `${paths.CATERING_ORDER.replace(':quoteId', row.quoteId).replace(
              ':currentTab',
              'generalInfo',
            )}`,
          );
        } else {
          window.open(`${paths.ORDERS_ID.replace(':orderId', row.quoteId)}/generalInfo`);
        }
      }}
      searchContainerStyle={{ maxWidth: '29%' }}
      initialQueryVariables={{
        first: ROWS_PER_PAGE_OPTIONS[0],
        // sort: [
        //   {
        //     quoteId: 'DESC',
        //   },
        // ],
        filter: {
          stageId: {
            null: true,
          },
          hasItem: {
            eq: true,
          },
          personId: {
            null: false,
          },
          failedPayment: {
            eq: true,
          },
          origin: {
            id: {
              in: ['T3JpZ2luOjE=', 'T3JpZ2luOjI0', 'T3JpZ2luOjI2'],
            },
          },
        },
        specificFields: ['first_name', 'last_name'],
      }}
      title="Abandoned Carts"
      structureTable={data =>
        data?.viewer?.quoteConnection?.edges.map(quote => {
          return {
            'origin.label': quote.node.origin?.label,
            quoteId: quote.node.quoteId,
            'category.label': quote.node.category?.label,
            categorytypeId: quote.node.category.categorytypeId,
            'handoff.label': quote.node.handoff?.label,
            location: generateStoreAbbreviation(quote.node.location).abbreviation,
            person:
              quote?.node?.person?.firstName && quote?.node?.person?.lastName
                ? `${quote.node.person?.firstName} ${quote.node.person?.lastName}`
                : '/',
            totalPrice: `$ ${convert.centsToDollars(quote.node.totalPrice)}`,
            dueAmount: `$ ${convert.centsToDollars(quote.node.dueAmount)}`,
            expected: quote.node.expected
              ? moment
                  .tz(moment.utc(quote.node.expected), quote.node.location?.timezone?.label)
                  .format('MM/DD/YYYY h:mm A z')
              : '-',
          };
        })
      }
      filterByDate={true}
      columns={[
        { title: 'Source', field: 'origin.label', disableSort: true },
        { title: 'Quote ID', field: 'quoteId' },
        {
          title: 'Category',
          field: 'category.label',
          disableSort: true,
        },
        {
          title: 'Handoff',
          field: 'handoff.label',
          disableSort: true,
        },
        {
          title: 'Location ID',
          field: 'location',
          disableSort: true,
          customPermissionField: 'location.__typename',
        },
        {
          title: 'Customer',
          field: 'person',
          disableSort: true,
          customPermissionField: 'person.__typename',
        },
        { title: 'Total', field: 'totalPrice', disableSort: true },
        { title: 'Due Amount', field: 'dueAmount', disableSort: true },
        { title: 'Order Timing', field: 'expected' },
      ]}
      customActionsCenter={(data, { refetch, variables }, selected) => {
        const dateRangeValue = getSelectedDateRangeValue(variables);
        const permissions = data?.viewer?.quoteConnection?.permissions;

        return (
          <>
            <Permission access={permissions?.quoteId && 7}>
              <div style={{ marginLeft: '.5rem' }}>
                <TextField
                  name={'quoteID'}
                  id={'quoteID'}
                  variant={'outlined'}
                  value={searchValue}
                  type={'number'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => debouncedSearch(refetch, variables, searchValue)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img alt="Search icon" src={SearchIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        aria-label="remove"
                        onClick={() => removeQuoteIdFilter(refetch, variables)}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={event => handleSearchInput(refetch, variables, event)}
                  placeholder={'Search ID'}
                />
              </div>
            </Permission>
            <div className={classes.selectFactoryWrap}>
              <Permission access={permissions?.location?.__typename && 7}>
                <SelectFactory
                  label={'Location'}
                  placeholder={'Select Location'}
                  query={LOCATIONS}
                  disableCloseOnSelect={true}
                  structureData={data => {
                    return data?.viewer?.locationConnection?.edges.map((location, index) => {
                      return {
                        label: compact([location.node.label, location.node.storeNumber]).join(
                          ' - #',
                        ),
                        id: location.node.id,
                        index,
                      };
                    });
                  }}
                  onSelect={values => {
                    const locationIds = values.map(location => location.id);
                    if (values.length === 0) {
                      const filters = variables.filter;
                      delete filters.location;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          location: {
                            id: {
                              in: locationIds,
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              </Permission>
              <Permission
                access={
                  Math.min(
                    permissions?.origin?.label,
                    originData?.viewer?.originConnection?.permissions?.__typename,
                  ) && 7
                }
              >
                <FormControl variant="outlined">
                  <InputLabel>Source</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.originId;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            originId: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="Source"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {originData?.viewer?.originConnection?.edges?.map(origin => (
                      <MenuItem key={origin.node.originId} value={origin.node.originId}>
                        {origin.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Permission>
              <Permission access={permissions?.expected && 7}>
                <FormControl variant="outlined" className={classes.selectDateRange}>
                  <IconButton
                    disableRipple
                    onClick={handleOpenDateRangeSelectionModal}
                    className={classes.dateRangeTextButton}
                  >
                    <TextField
                      label="Select Date Range"
                      value={dateRangeValue || 'MM/DD/YYYY to MM/DD/YYYY'}
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                      variant="outlined"
                    />
                  </IconButton>
                  {dateRangeValue !== 'MM/DD/YYYY to MM/DD/YYYY' && (
                    <IconButton
                      disableRipple
                      className={classes.dateRangeCloseButton}
                      onClick={() => handleDateRangeSelection({ refetch, variables }, true)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Dialog
                    open={openDateRangeSelectionModal}
                    onClose={handleCloseDateRangeSelectionModal}
                    disableEnforceFocus
                  >
                    <DialogTitle disableTypography>
                      <Typography variant="h6">Select Date Range</Typography>
                      <IconButton
                        aria-label="close"
                        className={classes.dialogCloseButton}
                        onClick={handleCloseDateRangeSelectionModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <DialogContent dividers>
                        <Grid container justify="space-between" spacing={2}>
                          <Grid item>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/DD/YYYY"
                              margin="normal"
                              label="Date range start"
                              value={dateRangeStart}
                              onChange={handleDateRangeStartChange}
                            />
                          </Grid>
                          <Grid item>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/DD/YYYY"
                              margin="normal"
                              label="Date range end"
                              value={dateRangeEnd}
                              onChange={handleDateRangeEndChange}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDateRangeSelection({ refetch, variables })}>
                          Apply
                        </Button>
                      </DialogActions>
                    </MuiPickersUtilsProvider>
                  </Dialog>
                </FormControl>
              </Permission>
            </div>
          </>
        );
      }}
      customActionsBottom={(data, { refetch, variables }) => {
        const categoryOptions = categoryData?.viewer?.categoryConnection?.edges?.map(category => ({
          value: category.node.categoryId,
          label: category.node.label,
        }));

        const handoffOptions = handoffData?.viewer?.handoffConnection?.edges?.map(handoff => ({
          value: handoff.node.handoffId,
          label: handoff.node.label,
        }));

        return categoryOptions && handoffOptions ? (
          <div style={{ display: 'flex' }}>
            <Permission
              access={categoryData?.viewer?.categoryConnection?.permissions?.__typename && 7}
            >
              <div style={{ marginRight: '1rem' }}>
                <Elements.ToggleButton
                  options={categoryOptions}
                  handleToggleClick={value => {
                    refetch({
                      ...variables,
                      filter: {
                        ...variables.filter,
                        categoryId: {
                          eq: value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </Permission>
            <Permission
              access={handoffData?.viewer?.handoffConnection?.permissions?.__typename && 7}
            >
              <div>
                <Elements.ToggleButton
                  options={handoffOptions}
                  handleToggleClick={value => {
                    refetch({
                      ...variables,
                      filter: {
                        ...variables.filter,
                        handoffId: {
                          eq: value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </Permission>
          </div>
        ) : null;
      }}
    />
  );
};

export default AbandonedCarts;
