import { gql } from '@apollo/client';

const ABANDONED_CARTS = gql`
  query Orders(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [QuoteSort]
    $filter: [QuoteFilter]
    $specificFields: [String]
  ) {
    viewer {
      id
      quoteConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        specificFields: $specificFields
        fieldGroup: 42
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        permissions
        edges {
          node {
            id
            origin {
              label
            }
            quoteId
            stage {
              label
            }
            category {
              label
              categorytypeId
            }
            handoff {
              label
            }
            location {
              id
              label
              storeNumber
              timezone {
                label
              }
              address {
                state {
                  label
                  abbreviation
                }
              }
            }
            person {
              firstName
              lastName
            }
            totalPrice
            dueAmount
            expected
            doordashId
            printJob {
              datePrinted
              inQueue
            }
          }
        }
      }
    }
  }
`;

export default ABANDONED_CARTS;
