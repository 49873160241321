import React from 'react';

import PropTypes from 'prop-types';

import { ConnectionTable } from '../../../../../blocks';

import { generateStoreAbbreviation } from '../../../../../../utils';

const LocationListView = ({ query, history }) => {
  return (
    <>
      <h2>Select a Location</h2>
      <ConnectionTable
        query={query}
        minSearchValueLength={1}
        initialQueryVariables={{
          first: 15,
          specificFields: ['label', 'store_number'],
          fieldGroupOnly: 92,
        }}
        onTableRowClick={row => {
          history.push(`/spark/inventory/production-forecaster/${row.locationId}`);
        }}
        structureTable={data => {
          return data?.viewer?.locationConnection?.edges.map(location => ({
            id: generateStoreAbbreviation(location.node).id,
            locationId: location.node.locationId,
            storeAbbreviation: generateStoreAbbreviation(location.node),
            label: location.node.label,
            'phone.phone': location.node.phone.phone,
          }));
        }}
        columns={[
          {
            title: 'ID',
            field: 'id',
            customSortField: 'storeNumber',
            render: (id, _, row) => {
              return row.storeAbbreviation.abbreviation;
            },
          },
          { title: 'Name', field: 'label' },
          { title: 'Phone Number', field: 'phone.phone' },
        ]}
      />
    </>
  );
};

LocationListView.propTypes = {
  query: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  createNewLocation: PropTypes.func.isRequired,
  handleLocationActivation: PropTypes.func.isRequired,
  bulkEditLocations: PropTypes.func.isRequired,
};

export default LocationListView;
