import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import PhoneNumberInput from '../../../../../../../blocks/PhoneNumberInput';

import EditLocationFormViewStyles from './styles';

import {
  PlacesAutocomplete,
  Elements,
  AutoComplete,
  ImageUpload,
  Permission,
  PermissionField,
} from '../../../../../../../blocks';

import { regexPattern } from '../../../../../../../../utils';
import { compact } from 'lodash';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';

const EditLocationFormView = ({
  onSubmit,
  locationInfoData,
  register,
  errors,
  control,
  handleSubmit,
  handleCloseModal,
  menuList = [],
  brandList = [],
  permissions,
  countryList,
  timezoneList,
  tiers = [],
  weekDays,
  taxGroups = [],
  stateList,
  createLocationInfoLoading,
  updateLocationInfoLoading,
  locationTypes = [],
  images,
  setImages,
  imageSize,
  payrollPeriods,
  createLocationPermission,
  updateLocationPermission,
  printerServiceList = [],
}) => {
  const styles = EditLocationFormViewStyles();

  const { setSnackbar } = useSnackbar();

  const [coordinates, setCoordinates] = useState({
    latitude: locationInfoData?.address?.latitude,
    longitude: locationInfoData?.address?.longitude,
  });
  const [locationAddress, setLocationAddress] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    additionalInfo: '',
    country: '',
  });
  const [locationAddressDisabled, setLocationAddressDisabled] = useState({
    address: true,
    city: !!locationInfoData?.address?.city,
    state: true,
    zip: true,
    country: true,
  });
  const [fullAddress, setFullAddress] = useState('');
  const [fullAddressError, setFullAddressError] = useState(false);
  const [legalBusinessNameError, setLegalBusinessNameError] = useState(false);

  const locationTypesList = useMemo(
    () =>
      locationTypes.map(locationType => ({
        value: locationType.node.storetypeId,
        label: locationType.node.label,
      })),
    [locationTypes],
  );

  const handleAddressChange = (event, type) => {
    const value = event.target?.value || event.label;
    if (['additionalInfo', 'city'].includes(type)) {
      setLocationAddress({ ...locationAddress, [type]: value });
      return;
    }
    setLocationAddress(prevState => {
      const newAddress = { ...prevState, [type]: value };

      setFullAddress(
        compact(
          Object.values(newAddress).map(address =>
            typeof address === 'string' ? address : address?.label,
          ),
        ).join(', ') || '',
      );

      return newAddress;
    });
  };

  const handleGeocodeAutocomplete = address => {
    let { state, country } = address;

    state = stateList.find(existingState => existingState.label === state);

    // hotfixes for countries (before ngn release)
    if (!countryList.find((existingCountry) => existingCountry.alpha2 === 'CA')) {
      countryList.push({
        id: 36,
        label: 'Canada',
        value: 'Q291bnRyeTozNg==',
        alpha2: 'CA',
      });
    }

    if (!countryList.find((existingCountry) => existingCountry.alpha2 === 'US')) {
      countryList.push({
        id: 228,
        label: 'United States',
        value: 'Q291bnRyeToyMjg=',
        alpha2: 'US',
      });
    }
    
    country = countryList.find(existingCountry => existingCountry.alpha2 === country);

    for (const property in address) {
      if (address[property] === '') {
        setLocationAddressDisabled(prevState => ({
          ...prevState,
          [property]: false,
        }));
      } else {
      }
    }
    setLocationAddress({ ...address, state, country });
  };

  useEffect(() => {
    if (locationInfoData?.address?.id) {
      setLocationAddress({
        address: locationInfoData?.address?.address || '',
        city: locationInfoData?.address?.city || '',
        country: {
          label: locationInfoData?.address?.state?.country?.label || '',
          value: locationInfoData?.address?.state?.country?.countryId || '',
          alpha2: locationInfoData?.address?.state?.country?.alpha2 || '',
        },
        state: {
          label: locationInfoData?.address?.state?.label || '',
          value: locationInfoData?.address?.state?.stateId || '',
        },
        zip: locationInfoData?.address?.zip || '',
        additionalInfo: locationInfoData?.address?.additionalInfo || '',
      });
    }
  }, []);

  const handleOnSubmit = formData => {
    if (!coordinates.latitude || !coordinates.longitude) {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: "Please select address from dropdown on 'Full Address' field.",
      });

      document.getElementById('full-address-autocomplete')?.focus();

      setFullAddressError(true);
      return;
    }

    if (formData.brandId !== 1 && !formData.legalBusinessName) {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: 'VB stores should have a legal business name.',
      });

      setLegalBusinessNameError(true);
      return;
    }

    setLegalBusinessNameError(false);
    setFullAddressError(false);

    const inputData = {
      locationId: locationInfoData?.id,
      storeNumber: Number(formData.storeNumber || locationInfoData?.storeNumber),
      label: formData.name,
      menuId: formData.menu,
      menuLabel: menuList.find(menu => menu.node.menuId === formData.menu)?.node?.label,
      timezoneId: formData.timezone?.id,
      phone: formData.phone,
      payrollPeriodId: formData.payrollPeriod,
      email: formData.email,
      address: locationAddress.address,
      city: locationAddress.city,
      stateLabel: locationAddress.state.label,
      zip: locationAddress.zip,
      countryId: locationAddress.country?.id,
      additionalInfo: locationAddress.additionalInfo,
      printerPassword: formData.printerPassword,
      taxgroupId: formData.defaultTaxGroup,
      burqStoreId:
        formData.burqStoreId === undefined || formData.burqStoreId?.length !== 0
          ? formData.burqStoreId
          : null,
      hygieneChitReminder: formData.hygieneReminder,
      autoSubmitInvoices: formData.autoSubmitInvoices,
      allowAutomaticInventoryitemStockToggle: formData.allowAutomaticInventoryitemStockToggle,
      locationInfo: formData.locationInfo,
      closed: formData.closeDate,
      opened: formData.openDate,
      cateringInfo: formData.cateringInfo,
      receiptFooter: formData.oloReceiptFooter,
      locationPicture: images[0].encoded,
      // TODO: add location picture alt description
      tierId: formData.tierId,
      workWeekStartId: formData.workWeekStart,
      storeTypeId: formData.locationType,
      brandId: formData.brandId,
      legalBusinessName: formData.legalBusinessName,
      printerServiceId: formData.printerServiceId,
      pickupLocation: formData.pickupLocation || null,
      ...coordinates,
    };

    onSubmit(inputData);
  };

  const printerServiceOptions = useMemo(
    () =>
      printerServiceList.map(printerService => (
        <MenuItem value={printerService?.node?.printerserviceId} key={printerService?.node?.id}>
          {printerService?.node?.label}
        </MenuItem>
      )),
    [printerServiceList],
  );

  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={5}>
          <Permission access={permissions?.location?.locationId}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                name="storeNumber"
                label="Location Store Number"
                disabled={
                  locationInfoData?.storeNumber === 0 || Boolean(locationInfoData?.storeNumber)
                }
                defaultValue={locationInfoData?.storeNumber}
                inputRef={register({ required: true, min: 0, max: 50000 })}
                error={!!errors.storeNumber}
                fullWidth
                type="number"
                control={control}
                helperText={errors.storeNumber && errors.storeNumber.message}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.label}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                name="name"
                label="Name"
                defaultValue={locationInfoData?.label}
                inputRef={register({ required: true })}
                error={!!errors.name}
                fullWidth
                control={control}
                helperText={errors.name && 'Location Name is required.'}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.phone?.phone}>
            <Grid item xs={6}>
              <PermissionField
                Component={PhoneNumberInput}
                label={'Phone'}
                name={'phone'}
                id="phone"
                fullWidth
                rules={{ required: true }}
                error={errors.phone}
                defaultValue={locationInfoData?.phone?.phone || ''}
                control={control}
                helperText="Phone number is required."
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.email}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                name="email"
                label="Email"
                type="email"
                defaultValue={locationInfoData?.email}
                inputRef={register({
                  required: true,
                  pattern: {
                    value: regexPattern.email,
                    message: 'Invalid email address',
                  },
                })}
                error={!!errors.email}
                fullWidth
                control={control}
                helperText={errors.email && 'Invalid email address.'}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.address?.__typename}>
            <Grid item xs={6}>
              <PermissionField
                Component={PlacesAutocomplete}
                onSelectCustom={() => {
                  setLocationAddress({ ...locationAddress, additionalInfo: '' });
                }}
                name={'fullAddress'}
                setCoordinates={setCoordinates}
                handleGeocodeAutocomplete={handleGeocodeAutocomplete}
                value={fullAddress}
                id="full-address-autocomplete"
                error={fullAddressError}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.address?.additionalInfo}>
            <Grid item xs={3}>
              <PermissionField
                Component={TextField}
                label="Suite/Address 2"
                name="additionalInfo"
                fullWidth
                onChange={event => handleAddressChange(event, 'additionalInfo')}
                value={locationAddress.additionalInfo}
              />
            </Grid>
          </Permission>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <AutoComplete
                id="country"
                name="country"
                getOptionLabel={option => option.label}
                control={control}
                onChange={selected => {
                  setLocationAddress(prevState => ({
                    ...prevState,
                    country: selected,
                  }));
                }}
                renderInput={params => (
                  <PermissionField
                    {...params}
                    Component={TextField}
                    label="Country"
                    variant="outlined"
                    error={errors.country}
                    helperText={errors.country && 'Country is required'}
                  />
                )}
                disabled={locationAddressDisabled.country}
                options={countryList}
                selectedOption={{
                  label: locationAddress?.country ? locationAddress?.country?.label : '',
                  value: locationAddress?.country ? locationAddress?.country?.value : '',
                  alpha2: locationAddress?.country ? locationAddress?.country?.alpha2 : '',
                }}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Permission access={permissions?.location?.address?.city}>
            <Grid item xs={3}>
              <PermissionField
                Component={TextField}
                name="city"
                label="City"
                inputRef={register({ required: true })}
                error={!!errors.city}
                fullWidth
                onChange={event => handleAddressChange(event, 'city')}
                value={locationAddress.city}
                helperText={errors.city && 'City is required.'}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.address?.state?.__typename}>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <AutoComplete
                  id="state"
                  name="state"
                  getOptionLabel={option => option.label}
                  control={control}
                  renderInput={params => (
                    <Permission access={permissions?.location?.address?.state?.__typename}>
                      <PermissionField
                        Component={TextField}
                        {...params}
                        label="State"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Permission>
                  )}
                  onChange={selected => {
                    setLocationAddress(prevState => ({
                      ...prevState,
                      state: selected,
                    }));
                  }}
                  options={stateList}
                  selectedOption={{
                    label: locationAddress?.state ? locationAddress?.state?.label : '',
                    value: locationAddress?.state ? locationAddress?.state?.value : '',
                  }}
                  disabled={locationAddressDisabled.state}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.address?.zip}>
            <Grid item xs={3}>
              <PermissionField
                Component={TextField}
                name="zip"
                label="Zip"
                inputRef={register({ required: true })}
                error={!!errors.zip}
                fullWidth
                onChange={event => handleAddressChange(event, 'zip')}
                value={locationAddress.zip && locationAddress.zip}
                helperText={errors.zip && 'Zip is required.'}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.timezone?.__typename}>
            <Grid item xs={3}>
              <AutoComplete
                id="timezone"
                name="timezone"
                getOptionLabel={option => option.label}
                control={control}
                rules={{
                  required: true,
                }}
                renderInput={params => (
                  <Permission access={permissions?.location?.timezone?.__typename}>
                    <PermissionField
                      Component={TextField}
                      {...params}
                      label="Timezone"
                      variant="outlined"
                      error={errors.timezone}
                      helperText={errors.timezone && 'Timezone is required'}
                    />
                  </Permission>
                )}
                options={timezoneList}
                defaultValue={() => {
                  if (locationInfoData?.timezone?.id) {
                    const formatDefaultValue = {
                      value: locationInfoData?.timezone?.id,
                      label: locationInfoData?.timezone?.label,
                      id: locationInfoData?.timezone?.timezoneId,
                    };

                    return formatDefaultValue;
                  }
                }}
                fullWidth
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.legalBusinessName}>
            <Grid item xs={3}>
              <TextField
                defaultValue={locationInfoData?.legalBusinessName}
                control={control}
                fullWidth
                name="legalBusinessName"
                id="legalBusinessName"
                label="Legal Name"
                inputRef={register}
                error={!!errors.legalBusinessName || legalBusinessNameError}
                helperText={errors.legalBusinessName?.message}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.opened}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <PermissionField
                  Component={Elements.DatePicker}
                  name="openDate"
                  label="Open Date"
                  control={control}
                  defaultValue={locationInfoData?.opened || null}
                />
              </FormControl>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.closed}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <PermissionField
                  Component={Elements.DatePicker}
                  label="Close Date"
                  name="closeDate"
                  control={control}
                  defaultValue={locationInfoData?.closed || null}
                />
              </FormControl>
            </Grid>
          </Permission>
          <Permission access={locationInfoData ? permissions?.location?.tierId : 7}>
            <Grid item xs={3}>
              <PermissionField
                Component={Elements.Select}
                name="tierId"
                label="Tier"
                control={control}
                defaultValue={locationInfoData?.tierId}
                rules={{ required: true }}
                error={!!errors.tierId}
                shrink
                fullWidth
                helperText={errors.tierId && 'Tier is required'}
              >
                {tiers.map(tier => (
                  <MenuItem value={tier?.node?.tierId} key={tier?.node?.tierId}>
                    {tier?.node?.label}
                  </MenuItem>
                ))}
              </PermissionField>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.workWeekStartId}>
            <Grid item xs={3}>
              <PermissionField
                Component={Elements.Select}
                name="workWeekStart"
                id="workWeekStart"
                label="Work Week Start Day"
                control={control}
                defaultValue={locationInfoData?.workWeekStartId || (weekDays || [])[0]?.id}
                shrink
                fullWidth
              >
                {weekDays?.map(weekDay => (
                  <MenuItem key={weekDay?.id} value={weekDay?.id}>
                    {weekDay?.label}
                  </MenuItem>
                ))}
              </PermissionField>
            </Grid>
          </Permission>
          <Grid item xs={3}>
            <Permission access={permissions?.location?.payrollPeriodId}>
              <PermissionField
                Component={Elements.Select}
                name="payrollPeriod"
                id="payrollPeriodId"
                label="Payroll Period"
                defaultValue={global.btoa(`PayrollPeriod:${locationInfoData?.payrollPeriodId}`)}
                control={control}
                rules={{ required: true }}
                shrink
                fullWidth
                error={!!errors.payrollPeriod}
                helperText={errors.payrollPeriod && 'Payroll period is required'}
              >
                {payrollPeriods?.map(payrollperiod => (
                  <MenuItem key={payrollperiod?.node?.id} value={payrollperiod?.node?.id}>
                    {payrollperiod?.node?.label}
                  </MenuItem>
                ))}
              </PermissionField>
            </Permission>
          </Grid>
          <Permission access={permissions?.location?.storeType}>
            <Grid item xs={3}>
              <PermissionField
                Component={Elements.Select}
                name="locationType"
                id="locationTypeId"
                label="Location Type"
                control={control}
                defaultValue={locationInfoData?.storeTypeId}
                shrink
                fullWidth
              >
                {locationTypesList.map(locationType => (
                  <MenuItem key={locationType?.value} value={locationType?.value}>
                    {locationType.label}
                  </MenuItem>
                ))}
              </PermissionField>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.taxgroupConnection?.edges?.node?.__typename}>
            <Grid item xs={3}>
              <PermissionField
                Component={Elements.Select}
                name="defaultTaxGroup"
                id="defaultTaxGroup"
                label="Default Tax Group"
                control={control}
                defaultValue={locationInfoData?.taxgroup?.id}
                // rules={{ required: true }}
                shrink
                fullWidth
                helperText={errors.defaultTaxGroup && 'Default Tax Group is required.'}
              >
                {taxGroups.map(taxGroup => (
                  <MenuItem key={taxGroup.value} value={taxGroup.value}>
                    {taxGroup.label}
                  </MenuItem>
                ))}
              </PermissionField>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.printerPassword}>
            <Grid item xs={3}>
              <PermissionField
                Component={TextField}
                name="printerPassword"
                placeholder="Printer Password"
                label="Printer Password"
                inputRef={register}
                fullWidth
                defaultValue={locationInfoData?.printerPassword}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.menu?.__typename}>
            <Grid item xs={3}>
              <PermissionField
                Component={Elements.Select}
                name="menu"
                label="Menu"
                control={control}
                defaultValue={locationInfoData?.menu?.menuId}
                rules={{ required: true }}
                error={!!errors.menu}
                helperText={errors.menu && 'Menu is required'}
                shrink
                fullWidth
              >
                {menuList.map(menu => (
                  <MenuItem value={menu?.node?.menuId} key={menu?.node?.id}>
                    {menu?.node?.label}
                  </MenuItem>
                ))}
              </PermissionField>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.brand?.__typename}>
            <Grid item xs={3}>
              <PermissionField
                Component={Elements.Select}
                name="brandId"
                label="Brand"
                control={control}
                defaultValue={locationInfoData?.brand?.brandId}
                rules={{ required: true }}
                error={!!errors.brandId}
                helperText={errors.brandId ? 'Brand is required' : ''}
                shrink
                fullWidth
              >
                {brandList.map(brand => (
                  <MenuItem value={brand?.node?.brandId} key={brand?.node?.id}>
                    {brand?.node?.label}
                  </MenuItem>
                ))}
              </PermissionField>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.printerServiceId}>
            <Grid item xs={3}>
              <PermissionField
                Component={Elements.Select}
                name="printerServiceId"
                label="Printer Service"
                control={control}
                defaultValue={locationInfoData?.printerService?.printerserviceId}
                rules={{ required: true }}
                error={!!errors.printerServiceId}
                helperText={errors.printerServiceId ? 'Printer service is required' : ''}
                shrink
                fullWidth
              >
                {printerServiceOptions}
              </PermissionField>
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.pickupLocation}>
            <Grid item xs={3}>
              <TextField
                defaultValue={locationInfoData?.pickupLocation}
                control={control}
                fullWidth
                name="pickupLocation"
                id="pickupLocation"
                label="Pickup Information"
                inputRef={register}
              />
            </Grid>
          </Permission>
          <Grid item xs={12}>
            <FormGroup className={styles.formGroupRoot}>
              {
                // TODO: ADD drivethru to mutation
              }
              {/* <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={true}
                    name="driveThru"
                    inputRef={register}
                  />
                }
                label="Drive Thru"
                key="driveThru"
              />
              {
                // TODO: ADD bar to mutation
              }
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={true}
                    name="bar"
                    inputRef={register}
                  />
                }
                label="Bar"
                key="bar"
              />
              {
                // TODO: ADD breakfast to mutation
              }
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={true}
                    name="breakfast"
                    inputRef={register}
                  />
                }
                label="Breakfast"
                key="breakfast"
              /> */}
              <Permission access={permissions?.location?.autoSubmitInvoices}>
                <div>
                  <PermissionField createHelperText className={styles.switchContainer}>
                    <Typography variant="subtitle2">Auto-submit Inventory Invoices</Typography>
                    <Elements.Switch
                      checked={locationInfoData?.autoSubmitInvoices}
                      name="autoSubmitInvoices"
                      ref={register}
                      xs={5}
                    />
                  </PermissionField>
                </div>
              </Permission>
              <Permission access={permissions?.location?.allowAutomaticInventoryitemStockToggle}>
                <div>
                  <PermissionField createHelperText className={styles.switchContainer}>
                    <Typography variant="subtitle2">
                      Allow Automatic Inventoryitem Stock Toggle
                    </Typography>
                    <Elements.Switch
                      checked={locationInfoData?.allowAutomaticInventoryitemStockToggle}
                      name="allowAutomaticInventoryitemStockToggle"
                      ref={register}
                      xs={5}
                    />
                  </PermissionField>
                </div>
              </Permission>
              <Grid item xs={6}>
                <Permission access={permissions?.location?.burqId}>
                  <PermissionField
                    Component={TextField}
                    name={'burqStoreId'}
                    inputRef={register}
                    type="string"
                    register={register}
                    className={styles.editTextField}
                    label="Burq Delivery Id"
                    defaultValue={locationInfoData?.burqId}
                    variant="outlined"
                  />
                </Permission>
                <Permission access={permissions?.location?.hygieneChitReminder}>
                  <div>
                    <PermissionField createHelperText className={styles.switchContainer}>
                      <Typography variant="subtitle2">Hygiene Reminder</Typography>
                      <Elements.Switch
                        checked={locationInfoData?.hygieneChitReminder}
                        name="hygieneReminder"
                        ref={register}
                      />
                    </PermissionField>
                  </div>
                </Permission>
              </Grid>
              {/* <div className={styles.switchContainer}>
                {
                  // TODO: ADD TIPS to mutation
                }
                <Typography variant="subtitle2">Tips</Typography>
                <Elements.Switch checked={true} name="tips" ref={register} />
              </div> */}
            </FormGroup>
          </Grid>
          <Permission access={permissions?.location?.receiptFooter}>
            <Grid item xs={6}>
              <PermissionField
                Component={Elements.TextFieldWithButton}
                id="oloReceiptFooter"
                label="OLO Receipt Footer"
                fullWidth
                name="oloReceiptFooter"
                numOfRows={7}
                inputRef={register}
                defaultValue={locationInfoData?.receiptFooter}
                buttonLabel="Test Print"
              />
            </Grid>
          </Permission>
          <Grid item xs={6}>
            {/*<Permission access={locationInfoData ? permissions?.location?.posReceiptFooter : 7}>*/}
            {/*<Elements.TextFieldWithButton*/}
            {/*  id="posReceiptFooter"*/}
            {/*  label="POS Receipt Footer"*/}
            {/*  fullWidth*/}
            {/*  name="posReceiptFooter"*/}
            {/*  numOfRows={7}*/}
            {/*  ref={register}*/}
            {/*  defaultValue={locationInfoData?.cateringInfo}*/}
            {/*  buttonLabel="Test Print"*/}
            {/*/>*/}
            {/*</Permission>*/}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="p">
              <b>Dickeys.com Location web page</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={styles.imageRootContainer}>
              <Permission access={permissions?.location?.locationPicture}>
                <div className={styles.imageContainer}>
                  <PermissionField
                    Component={ImageUpload}
                    images={images}
                    setImages={setImages}
                    size={imageSize}
                    maxSize={250 * 1000}
                    helperText="Tip:
                      JPEG File,
                      W900px x H675px,
                      max. 250kb file size"
                  />
                </div>
              </Permission>
            </div>
          </Grid>
          <Permission access={locationInfoData ? permissions?.location?.slug : 7}>
            <Grid item xs={6}>
              <PermissionField
                disabled
                Component={TextField}
                placeholder="Slug"
                name="slugId"
                id="slugId"
                label="Slug"
                fullWidth
                defaultValue={locationInfoData?.slug}
                inputRef={register}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.locationInfo}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                id="locationInfo"
                name="locationInfo"
                label="Location Info"
                multiline
                rows={4}
                fullWidth
                defaultValue={locationInfoData?.locationInfo}
                inputRef={register}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.location?.cateringInfo}>
            <Grid item xs={6}>
              <TextField
                id="cateringInfo"
                name="cateringInfo"
                label="Catering Info"
                multiline
                fullWidth
                rows={4}
                inputRef={register}
                defaultValue={locationInfoData?.cateringInfo}
              />
            </Grid>
          </Permission>
          <Grid container item xs={12} justify="flex-end" spacing={3}>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Permission
                access={
                  locationInfoData?.id
                    ? updateLocationPermission?.access
                    : createLocationPermission?.access
                }
              >
                <div>
                  <PermissionField createHelperText>
                    <Button
                      type="submit"
                      disabled={createLocationInfoLoading || updateLocationInfoLoading}
                    >
                      Save
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditLocationFormView;
