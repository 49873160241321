import React, { useState } from 'react';
import LOYALTY_POINT_LOG from '../../../../../../../../apollo/queries/loyalty/loyaltyPointLog';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../../../../constants';
import { COLOR } from '../../../../../../../../constants';
import { ConnectionTable } from '../../../../../../../blocks';
import { Grid, Typography, Button } from '@material-ui/core';
import LoyaltyPointHistoryStyles from './styles';
import { useParams } from 'react-router';
import { Modal } from '../../../../../../../blocks';
import LoyaltyPointsModal from '../PointsModal';
import moment from 'moment';
export default function PointsHistoryView({ customerData = {} }) {
  const [showModal, setShowModal] = useState({
    open: false,
    type: null,
    operationType: null,
  });

  const { customerId } = useParams();

  const classes = LoyaltyPointHistoryStyles();

  const handleOpenModal = (type, operationType) => {
    setShowModal(prevState => ({ type, operationType, open: true }));
  };

  const handleCloseModal = () => {
    setShowModal(prevState => ({ ...prevState, open: false }));
  };
  return (
    <div id={'pointsHistory'}>
      <Grid container item xs={12} justify="space-between">
        <Typography variant="h4" component="h2">
          Points History
        </Typography>
        <Grid item>
          <div>
            <Button
              onClick={() => handleOpenModal('Subtract Points', 'SUBTRACT')}
              variant="outlined"
              color="secondary"
              style={{ color: 'white', background: 'red' }}
            >
              Subtract Points
            </Button>
          </div>
          <Button
            className={classes.marginLeft}
            variant="outlined"
            onClick={() => handleOpenModal('Add Points', 'ADD')}
          >
            Add Points
          </Button>
        </Grid>
      </Grid>
      <ConnectionTable
        query={LOYALTY_POINT_LOG}
        showSearch={false}
        initialQueryVariables={{
          first: ROWS_PER_PAGE_OPTIONS[0],
          filter: {
            personId: {
              eq: parseInt(customerId),
            },
          },
          sort: {
            created: 'DESC',
          },
        }}
        structureTable={data =>
          data?.viewer?.loyaltypointlogConnection?.edges.map(loyaltypointlog => {
            return {
              id: loyaltypointlog?.node.id,
              brand: loyaltypointlog?.node?.brand?.label,
              created: moment(loyaltypointlog.node.created).format('MM/DD/YYYY'),
              points: loyaltypointlog.node.points,
              bonusPoints: loyaltypointlog.node.points >= 0 ? loyaltypointlog.node.bonusPoints : 0,
              totalPoints: loyaltypointlog.node.points + loyaltypointlog.node.bonusPoints,
              'loyaltypointreason.label': `${loyaltypointlog.node.points}:${loyaltypointlog?.node?.loyaltypointreason?.label}`,
            };
          })
        }
        columns={[
          { title: 'Date', field: 'created' },
          { title: 'Brand', field: 'brand' },
          {
            title: 'Points',
            field: 'points',
            cellStyle: value => ({
              color: value < 0 ? COLOR.ERROR : COLOR.SUCCESS,
              fontWeight: 'bold',
            }),
          },
          {
            title: 'Bonus Points',
            field: 'bonusPoints',
            cellStyle: value => ({
              color: value > 0 ? COLOR.SUCCESS : 'black',
              fontWeight: 'bold',
            }),
          },
          {
            title: 'Reason',
            field: 'loyaltypointreason.label',
            cellStyle: value => ({
              color: parseInt(value.split(':')[0]) < 0 ? COLOR.ERROR : COLOR.SUCCESS,
              fontWeight: 'bold',
            }),
            render: value => {
              const myArr = value.split(':');
              const reason = myArr[1];
              return reason;
            },
          },
        ]}
      />
      <Modal
        open={showModal.open}
        maxWidth={'sm'}
        handleClose={handleCloseModal}
        title={showModal.type}
      >
        <LoyaltyPointsModal
          customerData={customerData}
          handleCloseModal={handleCloseModal}
          operationType={showModal.operationType}
        />
      </Modal>
    </div>
  );
}
