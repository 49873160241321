import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CREATE_VENDOR } from "../../../../../apollo/mutations";
import CreateVendorView from "./View";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { paths } from '../../../../../constants';

const IMAGES = {
  sizes: ['75x75']
};

function CreateVendor() {
  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [images, setImages] = useState(IMAGES.sizes.map(size => ({ size })));

  const [createVendor] = useMutation(CREATE_VENDOR, {
    onCompleted: (data) => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Vendor created!',
      });
      setDisableCreateButton(false);
      history.push(`${paths.SETTINGS_VENDORS}/${data?.createVendor.vendor.vendorId}/generalInfo`);
    },
    onError: (err) => {
      setDisableCreateButton(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: err.message || 'Oops, something went wrong...',
      });
    },
  });

  const { control, handleSubmit, errors, register } = useForm({
    defaultValues: {
      label: '',
      orderUrl: '',
      promoCode: '',
      contactName: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      stateLabel: '',
      notes: '',
    },
  });

  const submit = (vendor) => {
    setDisableCreateButton(true);
    setSnackbar({
      open: true,
      type: "info",
      text: "Creating vendor...",
    });

    const stateInputLabel = vendor?.stateLabel?.label;

    createVendor({
      variables: {
        input: {
          ...vendor,
          stateLabel: stateInputLabel,
          logoImage: images[0],
        }
      },
    });
  };

  return (
    <>
      <CreateVendorView
        submit={submit}
        handleSubmit={handleSubmit}
        control={control}
        register={register}
        disableCreateButton={disableCreateButton}
        errors={errors}
        image={images}
        setImage={setImages}
        IMAGES={IMAGES}
      />
    </>
  );
}

export default CreateVendor;
