import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Button,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  Switch,
  FormHelperText,
} from "@material-ui/core";
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { SNACKBAR_STATUS } from '../../../../../../../../constants'
import { useSnackbar } from "../../../../../../../../hooks/useSnackbar";

const useStyles = makeStyles(() => ({
  overtimeDialogInputFields: {
    width: "100%",
    "& .MuiTextField-root": { width: "100%", margin: "8px 0" },
  },
  overtimeDialogStatesHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > p": {
      margin: "0 3rem 0 0",
    },
  },
}));

const OvertimeView = ({
  states,
  overtimerule,
  handleClose,
  createOvertimerules,
  updateOvertimerules,
  createOTLoading,
  updateOTLoading,
  error,
  loading,
  selectedStates, 
  setSelectedStates,
}) => {
  const { setSnackbar } = useSnackbar();
  const classes = useStyles();

  const [showExtendedRules, setShowExtendedRules] = useState(
    !!overtimerule?.secondDailyOtStart ||
      !!overtimerule?.seventhDayWhMultiplier ||
      !!overtimerule?.seventhDayOtStart
  );

  const {
    register,
    handleSubmit,
    errors,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    const formData = Object.fromEntries(Object.entries(data).filter(([_, value]) => value != ''));
    if (!overtimerule) {
      setSnackbar({
        text: "Creating overtime rule...",
        type: SNACKBAR_STATUS.INFO,
        open: true,
      })
      createOvertimerules({
        variables: {
          input: {
            ...formData,
            states: selectedStates,
          },
        },
      }).then(() => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Overtime rule was created successfully',
          open: true,
        })

        handleClose();
      }).catch(error => setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      }))
    } else {
      setSnackbar({
        text: "Updating overtime rule...",
        type: SNACKBAR_STATUS.INFO,
        open: true,
      });

      updateOvertimerules({
        variables: {
          input: {
            overtimeruleId: overtimerule.id,
            ...formData,
            states: selectedStates,
          },
        },
      }).then(() => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Overtime rule was updated successfully',
          open: true,
        });

        handleClose();
      }).catch(error => setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      }))
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStates(states.map((state) => state.node.id));
    } else {
      setSelectedStates([]);
    }
  };

  const handleSelect = (e, id) => {
    let states = selectedStates;
    if (e.target.checked) {
      states.push(id);
    } else {
      states = states.filter((state) => state !== id);
    }
    setSelectedStates([...states]);
  };

  const handleExtendedRulesShow = (event) =>
    setShowExtendedRules(event.target.checked);

  return (
    <Dialog fullWidth={true} maxWidth="xl" open={true} onClose={handleClose}>
      {error || loading ? 
        <Grid style={{ height: '500px', margin: "1rem" }}>{
          error ?
            <p>error</p> : 
            <CircularProgress/>
        }
        </Grid> : (
      <div className={classes.overtimeDialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{overtimerule ? "Update Overtime Rule" : "New Overtime Rule"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className={classes.overtimeDialogInputFields}>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <TextField
                        label="Rule Name"
                        name="name"
                        variant="outlined"
                        defaultValue={overtimerule?.name}
                        inputRef={register({ required: true})}
                      />
                      {errors.name && (
                        <FormHelperText error>Name must be entered.</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <TextField
                        label="Weekly OT start after"
                        name="weeklyOtStart"
                        type="number"
                        defaultValue={overtimerule?.weeklyOtStart}
                        variant="outlined"
                        inputRef={register({
                          validate: (value) =>
                            (value && value > 0) || !getValues("weeklyOtMultiplier")
                        })}
                      />
                      {errors.weeklyOtStart && (
                        <FormHelperText error>
                          Start must be a positive number entered in combination with multiplier.
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Weekly OT multiplier"
                        name="weeklyOtMultiplier"
                        type="number"
                        inputProps={{
                          step: 0.01
                        }}
                        defaultValue={overtimerule?.weeklyOtMultiplier}
                        variant="outlined"
                        inputRef={register({
                          validate: (value) =>
                            (value && value > 0) || !getValues("weeklyOtStart"),
                        })}
                      />
                      {errors.weeklyOtMultiplier && (
                        <FormHelperText error>
                          Multiplier must be a positive number entered in combination with start.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <TextField
                        label="Daily OT start after"
                        name="dailyOtStart"
                        type="number"
                        defaultValue={overtimerule?.dailyOtStart}
                        variant="outlined"
                        inputRef={register({
                          validate: (value) =>
                            (value && value > 0) || !getValues("dailyOtMultiplier"),
                        })}
                      />
                      {errors.dailyOtStart && (
                        <FormHelperText error>
                          Start must be a positive number entered in combination with multiplier.
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Daily OT multiplier"
                        name="dailyOtMultiplier"
                        type="number"
                        inputProps={{
                          step: 0.01
                        }}
                        defaultValue={overtimerule?.dailyOtMultiplier}
                        variant="outlined"
                        inputRef={register({
                          validate: (value) =>
                            (value && value > 0) || !getValues("dailyOtStart"),
                        })}
                      />
                      {errors.dailyOtMultiplier && (
                        <FormHelperText error>
                          Multiplier must be a positive number entered in combination with start.
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleExtendedRulesShow}
                            name="extendedOtRules"
                            color="primary"
                            checked={showExtendedRules}
                          />
                        }
                        label="Enable extended overtime rules"
                      />
                    </Grid>
                  </Grid>
                  {showExtendedRules && (
                    <>
                      <Grid container spacing={5}>
                        <Grid item xs={6}>
                          <TextField
                            label="2nd Daily OT start after"
                            name="secondDailyOtStart"
                            type="number"
                            defaultValue={overtimerule?.secondDailyOtStart}
                            variant="outlined"
                            inputRef={register({
                              validate: (value) =>
                                (value && value > 0) || !getValues("secondDailyOtMultiplier"),
                            })}
                          />
                          {errors.secondDailyOtStart && (
                            <FormHelperText error>
                              Start must be a positive number entered in combination with
                              multiplier.
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="2nd Daily OT multiplier"
                            name="secondDailyOtMultiplier"
                            type="number"
                            inputProps={{
                              step: 0.01
                            }}
                            defaultValue={overtimerule?.secondDailyOtMultiplier}
                            variant="outlined"
                            inputRef={register({
                              validate: (value) =>
                                (value && value > 0) || !getValues("secondDailyOtStart"),
                            })}
                          />
                          {errors.secondDailyOtMultiplier && (
                            <FormHelperText error>
                              Multiplier f in combination with
                              start.
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <TextField
                            label="7th day working hours multiplier"
                            name="seventhDayWhMultiplier"
                            inputProps={{
                              step: 0.01
                            }}
                            type="number"
                            defaultValue={overtimerule?.seventhDayWhMultiplier}
                            variant="outlined"
                            inputRef={register({
                              validate: value => value >= 0,
                            })}
                          />
                        </Grid>
                        {errors.seventhDayWhMultiplier && (
                            <FormHelperText error>
                              Multiplier must be a positive number.
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item xs={6}>
                          <TextField
                            label="7th day OT start after"
                            name="seventhDayOtStart"
                            type="number"
                            defaultValue={overtimerule?.seventhDayOtStart}
                            variant="outlined"
                            inputRef={register({
                              validate: (value) =>
                                (value && value > 0) || !getValues("seventhDayOtMultiplier"),
                            })}
                          />
                          {errors.seventhDayOtStart && (
                            <FormHelperText error>
                              Start must be a positive number entered in combination with
                              multiplier.
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="7th day OT multiplier"
                            name="seventhDayOtMultiplier"
                            type="number"
                            inputProps={{
                              step: 0.01
                            }}
                            defaultValue={overtimerule?.seventhDayOtMultiplier}
                            variant="outlined"
                            inputRef={register({
                              validate: (value) =>
                                (value && value > 0) || !getValues("seventhDayOtStart"),
                            })}
                          />
                          {errors.seventhDayOtMultiplier && (
                            <FormHelperText error>
                              Multiplier must be a positive number entered in combination with
                              start.
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={overtimerule?.resident}
                                // checked={state.checkedB}
                                // onChange={handleChange}
                                inputRef={register}
                                name="resident"
                                color="primary"
                              />
                            }
                            label="Resident"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <div className={classes.overtimeDialogStatesHolder}>
                    <DialogContentText>States</DialogContentText>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedStates.length === states.length}
                          onChange={handleSelectAll}
                          name="allStates"
                          color="primary"
                        />
                      }
                      label="Select all States"
                    />
                  </div>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {states?.map((state, index) => {
                        return (
                          <Grid item xs={3} key={index}>
                            <FormControlLabel
                              key={state.node.label}
                              control={
                                <Checkbox
                                  checked={selectedStates.includes(
                                    state.node.id
                                  )}
                                  onChange={(e) =>
                                    handleSelect(e, state.node.id)
                                  }
                                  name={state.node.label}
                                  color="primary"
                                />
                              }
                              label={state.node.label}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" disabled={createOTLoading || updateOTLoading}>Save</Button>
          </DialogActions>
        </form>
      </div>)
    }
    </Dialog>
  );
};

export default OvertimeView;
