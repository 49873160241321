import { SvgIcon } from '@material-ui/core';

const OrderTracker12Active = () => (
  <SvgIcon
    style={{ width: '100%', height: '100%' }}
    viewBox="0 0 91 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m65.61,22.65l-3.94-8.09c-.79-1.53-2.18-2.28-3.8-2.65-2.93-.66-7.9-.74-12.87-.74s-9.94.08-12.87.74c-1.62.36-3.01,1.12-3.8,2.65l-3.94,8.09-3.31,3.24c-.86.97-1.34,2.22-1.34,3.52v6.37c0,1.26.45,2.48,1.27,3.44v7.32c0,1.26,1.02,2.28,2.28,2.28h4.51c1.26,0,2.28-1.02,2.28-2.28v-3.04h29.84v3.04c0,1.26,1.02,2.28,2.28,2.28h4.51c1.26,0,2.28-1.02,2.28-2.28v-7.32c.82-.96,1.27-2.18,1.27-3.44v-6.37c0-1.3-.47-2.55-1.34-3.52l-3.31-3.24Z"
      fill="#ffda00"
    />
    <g>
      <path
        d="m21.01,39.22v7.32c0,1.26,1.02,2.28,2.28,2.28h4.51c1.26,0,2.28-1.02,2.28-2.28v-3.04"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <path
        d="m68.99,39.22c.82-.96,1.27-2.18,1.27-3.44v-6.37c0-1.3-.47-2.55-1.34-3.52l-3.31-3.24"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <line
        x1="26.41"
        y1="43.51"
        x2="63.59"
        y2="43.51"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <path
        d="m24.39,22.65l-3.31,3.24c-.86.97-1.34,2.22-1.34,3.52v6.37c0,1.26.45,2.48,1.27,3.44"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <path
        d="m65.61,22.65c0,.15-6.43,1.47-20.61,1.47s-20.61-1.32-20.61-1.47"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <line
        x1="38.36"
        y1="36.07"
        x2="51.64"
        y2="36.07"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <path
        d="m68.99,39.22v7.32c0,1.26-1.02,2.28-2.28,2.28h-4.51c-1.26,0-2.28-1.02-2.28-2.28v-3.04"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <path
        d="m31.79,33.83c-1.39,0-5.07.38-7.03-2.91"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <path
        d="m58.21,33.83c1.39,0,5.07.38,7.03-2.91"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <line
        x1="23.67"
        y1="23.36"
        x2="20.28"
        y2="21.6"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <line
        x1="66.33"
        y1="23.36"
        x2="69.72"
        y2="21.6"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
      <path
        d="m65.61,22.65l-3.94-8.09c-.79-1.53-2.18-2.28-3.8-2.65-2.93-.66-7.9-.74-12.87-.74s-9.94.08-12.87.74c-1.62.36-3.01,1.12-3.8,2.65l-3.94,8.09"
        fill="none"
        stroke="#010101"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.7px;"
      />
    </g>
  </SvgIcon>
);

export default OrderTracker12Active;
