import PropTypes from 'prop-types';
import { Container, Grid, TextField, MenuItem, FormControl } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import MenuTreeStyles from '../View/styles';
import { Elements, Permission } from '../../../../../blocks';
import NumberInput from '../../../../../blocks/NumberInput';

const GeneralInfoInput = ({
  brandList = [],
  brand,
  isMenu,
  isItem = false,
  imageUrl = '',
  setOpen,
  control,
  register,
  errors,
  itemTypeOptions,
  itemTypeId,
  perPerson,
  numberPeopleCanServe,
  isSubcategory = false,
}) => {
  const classes = MenuTreeStyles();

  return (
    <Container className={classes.sectionContainer}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          {imageUrl.length !== 0 ? (
            <img
              src={imageUrl}
              alt=""
              className={classes.generalInfoImg}
              onClick={() => setOpen(true)}
            />
          ) : (
            <ImageIcon style={{ fontSize: 80, margin: 'auto' }} onClick={() => setOpen(true)} />
          )}
        </Grid>
        <Grid item xs={8} direction="column">
          <Grid container spacing={3}>
            {isItem ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    name="label"
                    label="Item Name"
                    type="text"
                    inputRef={register({ required: true })}
                    fullWidth
                    error={errors.label}
                    helperText={errors.label && 'Item name is a required field.'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="label2"
                    label="Item Subtitle"
                    type="text"
                    inputRef={register}
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <TextField
                  name="label"
                  label="Label"
                  type="text"
                  inputRef={register({ required: true })}
                  fullWidth
                  error={errors.label}
                  helperText={errors.label && 'Label is a required field.'}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                type="text"
                inputRef={register}
                fullWidth
              />
            </Grid>
            {isSubcategory ? (
              <Grid item xs={12}>
                <TextField
                  name="shortName"
                  label="Short Label"
                  type="text"
                  inputRef={register}
                  fullWidth
                />
              </Grid>
            ) : null}
            {isMenu && (
              <Grid item xs={12}>
                <Elements.Select
                  name="brandId"
                  label="Brand"
                  control={control}
                  helperText={errors.brandId && 'Brand is required'}
                  defaultValue={brand?.brandId}
                  rules={{ required: true }}
                  error={errors?.brandId}
                  shrink
                  fullWidth
                >
                  {brandList.map(brand => (
                    <MenuItem value={brand?.node?.brandId} key={brand?.node?.id}>
                      {brand?.node?.label}
                    </MenuItem>
                  ))}
                </Elements.Select>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isItem && (
        <Grid container spacing={3} style={{ marginTop: '0.5rem' }}>
          <Grid item xs={4}>
            <TextField
              name="preparationTime"
              label="Prep Time (S)"
              type="text"
              inputRef={register({
                required: true,
                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
              })}
              fullWidth
              error={errors.preparationTime}
              helperText={
                errors.preparationTime &&
                'Prepration time is a required field, and must be a positive number.'
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="minQuantity"
              label="Minimum Quantity"
              type="number"
              inputRef={register({ required: true, min: 0 })}
              fullWidth
              helperText={
                errors.minQuantity?.type === 'required'
                  ? 'Minimum quantity is a required field.'
                  : errors?.minQuantity?.type === 'min'
                  ? 'Minimum quantity cannot be a negative number.'
                  : null
              }
              error={errors.minQuantity}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="maxQuantity"
              label="Maximum Quantity"
              type="number"
              inputRef={register({ min: 1 })}
              fullWidth
              helperText={
                errors?.maxQuantity?.type === 'min'
                  ? 'Maximum quantity cannot be a negative number.'
                  : null
              }
              error={errors.maxQuantity}
            />
          </Grid>
          <Permission access={7}>
            <Grid item xs={4}>
              <Elements.Select
                name="itemtypeId"
                label="Item Type"
                control={control}
                rules={{ required: true }}
                shrink
                defaultValue={itemTypeId}
                fullWidth
                error={!!errors.itemtypeId}
                helperText={errors.itemtypeId && 'Item Type is required'}
              >
                {itemTypeOptions?.map(itemType => (
                  <MenuItem value={itemType?.itemtypeId} key={itemType?.itemtypeId}>
                    {itemType?.label}
                  </MenuItem>
                ))}
              </Elements.Select>
            </Grid>
          </Permission>
          <Grid item xs={4}>
            <Elements.Select
              name="brandId"
              label="Brand"
              control={control}
              rules={{ required: true }}
              defaultValue={brand?.brandId}
              shrink
              fullWidth
              error={!!errors.brandId}
              helperText={errors.brandId && 'Brand is required'}
            >
              {brandList.map(brand => (
                <MenuItem value={brand?.node?.brandId} key={brand?.node?.id}>
                  {brand?.node?.label}
                </MenuItem>
              ))}
            </Elements.Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="numberPeopleCanServe"
              label="Can Serve Number of People"
              type="number"
              inputRef={register}
              fullWidth
              defaultValue={numberPeopleCanServe !== undefined ? numberPeopleCanServe : 0}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ width: '100%' }}>
              <Elements.Select
                name="perPerson"
                label="Item Price is per Person"
                control={control}
                defaultValue={perPerson !== undefined ? perPerson : false}
                shrink
                fullWidth
              >
                <MenuItem value={false} key={0}>
                  False
                </MenuItem>
                <MenuItem value={true} key={1}>
                  True
                </MenuItem>
              </Elements.Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              name="minCalories"
              error={errors?.minCalories}
              helperText={errors?.minCalories && 'Number must be positive'}
              control={control}
              decimalScale={0}
              id="minCalories"
              label="Min Calories"
              placeholder="0"
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              name="maxCalories"
              error={errors?.maxCalories}
              helperText={errors?.maxCalories && 'Number must be positive'}
              control={control}
              decimalScale={0}
              id="maxCalories"
              label="Max Calories"
              placeholder="0"
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

GeneralInfoInput.propTypes = {
  isItem: PropTypes.bool,
  imageUrl: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
};

export default GeneralInfoInput;
