import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import clsx from 'clsx';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { connectionTableToolbarStyles } from './style';
import SearchIcon from '../../../../assets/icons/SearchIcon.png';
import { Hidden } from '@material-ui/core';

const ConnectionTableToolbar = ({
  customActionsBottom,
  customActionsCenter,
  customActionsTop,
  data,
  numSelected,
  refetch,
  searchStyle,
  searchPlaceholder,
  alignItemsCenter,
  showSearch,
  selected,
  title,
  subtitle,
  tableData,
  variables,
  dynamicDataFetching,
  setSearchValue,
  minSearchValueLength = 2,
  searchContainerStyle,
  permissions,
}) => {
  const classes = connectionTableToolbarStyles();

  const search = (value, variables) => {
    if (dynamicDataFetching) {
      refetch({
        ...variables,
        first: variables.first || variables.last,
        after: null,
        last: null,
        before: null,
        search: value || null,
      });
    } else {
      setSearchValue(value);
    }
  };

  const [debouncedSearch] = useState(() =>
    debounce((value, variables) => search(value, variables), 400),
  );
  const handleSearchInput = (event, variables) => {
    if (event.target.value.length > minSearchValueLength || event.target.value.length === 0) {
      let formatedSearchTerm = event.target.value.replace("'", "''");
      debouncedSearch(formatedSearchTerm, variables);
    }
  };

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <Grid container justify={title ? 'space-between' : 'flex-end'} alignItems="center">
          {title || subtitle ? (
            <div>
              {title && (
                <Typography variant="h3" component="h2" style={{ marginBottom: '16px' }}>
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Hidden only={'xs'}>
                  <Typography
                    variant="body1"
                    style={{
                      margin: 0,
                      fontWeight: 'bold',
                      color: '#575757',
                      fontSize: '20px',
                    }}
                  >
                    {subtitle}
                  </Typography>
                </Hidden>
              )}
            </div>
          ) : null}
          {customActionsTop && customActionsTop(data, { refetch, variables, tableData }, selected)}
        </Grid>
      </Grid>

      {showSearch || customActionsCenter ? (
        <Grid item xs={12} searchContainerStyle={searchContainerStyle}>
          <Grid
            container
            justify="space-between"
            alignItems={alignItemsCenter || 'center'}
            spacing={3}
          >
            {showSearch && (
              <Grid item className={classes.gridItem}>
                <TextField
                  placeholder={searchPlaceholder || 'Search'}
                  variant="outlined"
                  inputProps={{
                    'aria-label': 'search',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="Search icon" src={SearchIcon} />
                      </InputAdornment>
                    ),
                  }}
                  style={searchStyle}
                  onChange={e => {
                    handleSearchInput(e, variables);
                  }}
                  className={classes.searchInput}
                />
              </Grid>
            )}

            {customActionsCenter && (
              <Grid item className={clsx(classes.gridItem, classes.actionsCenterContainer)}>
                {customActionsCenter(
                  data,
                  { refetch, variables, tableData },
                  selected,
                  permissions,
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : null}
      {numSelected > 0 || customActionsBottom ? (
        <Grid item xs={12}>
          {numSelected > 0 && (
            <div className={classes.actionsSelected}>
              <Typography color="inherit" variant="subtitle1" component="div">
                {numSelected} selected
              </Typography>
              <Tooltip title="Delete">
                <IconButton aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          {customActionsBottom && (
            <Grid item xs={12} className={classes.customActionsBottom}>
              {customActionsBottom(data, { refetch, variables, tableData }, selected, permissions)}
            </Grid>
          )}
        </Grid>
      ) : null}

      {subtitle ? (
        <Hidden smUp>
          <div style={{ margin: '0 -16px 0', width: 'calc(100% + 32px)', marginBottom: '12px' }}>
            <div style={{ background: '#F8F8FA', padding: '8px 16px', width: '100%' }}>
              <Typography
                variant="body1"
                style={{ margin: 0, textTransform: 'uppercase', color: '#575757' }}
              >
                {subtitle}
              </Typography>
            </div>
          </div>
        </Hidden>
      ) : null}
    </Grid>
  );
};

export default ConnectionTableToolbar;

ConnectionTableToolbar.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  variables: PropTypes.object,
  title: PropTypes.string,
  numSelected: PropTypes.number,
  selected: PropTypes.array,
  customActionsTop: PropTypes.func,
  customActionsCenter: PropTypes.func,
  customActionsBottom: PropTypes.func,
  availableFilters: PropTypes.object,
  columnsToFilterBy: PropTypes.array,
  filterByDate: PropTypes.bool,
};
