import { gql } from '@apollo/client';

const CATERING_ORDERS = gql`
  query CateringOrders(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [QuotecateringSort]
    $filter: [QuotecateringFilter]
    $specificFields: [String]
  ) {
    viewer {
      id
      quotecateringConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        specificFields:$specificFields
        fieldGroup: 51
      ) {
        totalCount
        availableFilters
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        permissions
        edges {
          node {
            eventTypeId
            inquiryDate
            eventDate
            id
            status {
              id
              action
              color {
                code
              }
              label
            }
            cateringExpert {
              person {
                id
                firstName
                lastName
              }
            }
            quote {
              id
              quoteId
              refunded
              expected
              origin {
                id
                label
              }
              person {
                id
                firstName
                lastName
              }
              totalPrice
              dueAmount
              location {
                id
                label
                timezone {
                  label
                }
                storeNumber
                address {
                  id
                  state {
                    id
                    abbreviation
                  }
                }
              }
            }
          }
        }
      }
    } 
  }
`;

export default CATERING_ORDERS;
