import { gql } from "@apollo/client";

const LOCATION_MULTIPLE_CHOICE = gql`
  query fetchLocationItem(
    $filter: [LocationMultipleChoiceFilter]
  ) {
    viewer {
      id
      locationMultipleChoiceConnection(filter: $filter) {
      edges {
        node {
          id
          price
        }
      }
    } 
  }
  }
`;

export default LOCATION_MULTIPLE_CHOICE;
