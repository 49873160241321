import { Button, Grid } from '@material-ui/core';
import { COLOR, SNACKBAR_STATUS } from '../../../../../../constants';
import { ConnectionTable, Elements } from '../../../../../blocks';
import { MYSTERY_REWARDS } from './queries';
import { useCallback, useState } from 'react';
import MysteryRewardModal from './components/MysteryRewardModal';
import { useMutation } from '@apollo/client';
import { MYSTERY_COUPON_TYPE_ID } from './components/MysteryRewardModal/constants';
import { REMOVE_COUPON, TOGGLE_ARCHIVE_COUPON } from '../../../../../../apollo/mutations/coupons';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const MysteryRewards = () => {
  const [isOpenMysteryRewardModalForm, setIsOpenMysteryRewardModalForm] = useState(false);
  const [mysteryRewardId, setMysteryRewardId] = useState(null);

  const { setSnackbar } = useSnackbar();

  const [toggleArchiveCoupon] = useMutation(TOGGLE_ARCHIVE_COUPON, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Status changed',
        open: true,
      });
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error?.message,
        open: true,
      });
    },
  });
  const [deleteCoupon, { loading: isDeleting }] = useMutation(REMOVE_COUPON, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Mystery reward deleted',
        open: true,
      });
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error?.message,
        open: true,
      });
    },
  });

  const handleDeleteMysteryReward = useCallback((row, vars, refetch) => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Deleting mystery reward...',
      open: true,
    });

    deleteCoupon({
      variables: {
        input: {
          couponId: row.id,
        },
      },
    }).then(() => refetch());
  }, []);

  const handleOnRowClick = useCallback(id => {
    setMysteryRewardId(id);
    setIsOpenMysteryRewardModalForm(true);
  }, []);

  const handleCloseMysteryRewardModalForm = useCallback(() => {
    setIsOpenMysteryRewardModalForm(false);
    setMysteryRewardId(null);
  }, []);

  const handleSwitchChange = useCallback(id => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Changing status...',
      open: true,
    });

    toggleArchiveCoupon({
      variables: {
        input: {
          couponId: id,
        },
      },
    });
  }, []);

  return (
    <>
      <ConnectionTable
        query={MYSTERY_REWARDS}
        minSearchValueLength={1}
        title="Mystery Rewards"
        initialQueryVariables={{
          filter: { coupontypeId: { eq: MYSTERY_COUPON_TYPE_ID } },
          first: 10,
        }}
        onTableRowClick={(row, data) => handleOnRowClick(row.id)}
        customActionsTop={() => (
          <Grid container justify="flex-end" spacing={2}>
            {/* <Grid item>  TODO if backend is implemented
              <Button
                fullWidth
                variant="outlined"
                onClick={() => console.log('open set probability modal')}
              >
                Set Probability
              </Button>
            </Grid> */}
            <Grid item>
              <Button onClick={() => handleOnRowClick(null)}>New Mystery Reward</Button>
            </Grid>
          </Grid>
        )}
        structureTable={data => {
          return data?.viewer?.couponConnection?.edges.map(({ node }) => ({
            id: node?.id,
            couponId: node?.couponId,
            archived: node?.archived,
            label: node?.label,
          }));
        }}
        handleDeleteRow={handleDeleteMysteryReward}
        isDeleting={isDeleting}
        useConfirmationModalForDelete={true}
        deleteConfirmationText={'Are you sure you want to delete this coupon?'}
        columns={[
          {
            title: 'ID',
            field: 'couponId',
          },
          { title: 'Mystery Reward Name', field: 'label' },
          {
            title: 'Status',
            field: 'archived',
            cellStyle: archived => ({
              color: !archived ? COLOR.SUCCESS : COLOR.ERROR,
              fontWeight: 'bold',
            }),
            render: (archived, variables, row) => (
              <Elements.Switch
                checked={!archived}
                handleSwitchChange={() => handleSwitchChange(row.id)}
              />
            ),
          },
        ]}
      />

      <MysteryRewardModal
        isOpen={isOpenMysteryRewardModalForm}
        handleClose={handleCloseMysteryRewardModalForm}
        mysteryRewardId={mysteryRewardId}
      />
    </>
  );
};

export default MysteryRewards;
