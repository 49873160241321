import React, { useCallback, useState, useMemo } from 'react';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { compact, isEmpty } from 'lodash';

import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { LOCATIONS_BY_JOB } from '../../../../../../apollo/queries';
import { DELETE_JOB } from '../../../../../../apollo/mutations';
import { Modal, Permission, PermissionField } from '../../../../../blocks';
import ManageJobForm from '../ManageJobForm';
import { SNACKBAR_STATUS, MUTATION_NAME } from '../../../../../../constants';
import { useFormSubmitPermissions } from '../../../../../../hooks';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const Job = ({ mutationPermissions }) => {
  const { setSnackbar } = useSnackbar();

  const createMutationPermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'createJob'),
    [mutationPermissions],
  );
  const updateMutationPermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'updateJob'),
    [mutationPermissions],
  );
  const deleteMutationPermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'deleteJob'),
    [mutationPermissions],
  );

  const [permissions, setPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [MUTATION_NAME.createJobMutationInput, MUTATION_NAME.updateJobMutationInput],
    permissions,
  });

  const [deleteJob, { loading: isDeletingJob }] = useMutation(DELETE_JOB);

  const [tableState, setTableState] = useState();
  const [existingJob, setExistingJob] = useState();
  const [showModal, setShowModal] = useState(false);
  const orderKeys = {};

  const handleOpenModal = (data, query) => {
    setTableState(query);
    setShowModal({
      open: true,
      permissions: data.permissions,
    });

    setOpenWarningMutationName(MUTATION_NAME.createJobMutationInput);
  };
  const handleCloseModal = () => {
    setShowModal({ open: false });
    setExistingJob(null); // clear state so we don't pickup the last edited job when creating new job
  };

  const handleDeleteRow = useCallback(
    (data, variables) => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.INFO,
        text: 'Deleting job...',
      });

      deleteJob({
        variables: {
          jobId: data.jobId,
        },
        refetchQueries: [{ query: LOCATIONS_BY_JOB, variables }],
      })
        .then(() =>
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Job deleted',
          }),
        )
        .catch(error =>
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
          }),
        );
    },
    [deleteJob, setSnackbar],
  );

  const handleEditJob = useCallback(
    ({ job }, data, variables) => {
      setTableState(variables);
      setExistingJob({ ...job, posAccess: !!job.posAccess });
      setShowModal({
        open: true,
        query: variables,
        permissions: data?.viewer?.jobConnection?.permissions,
      });

      setOpenWarningMutationName(MUTATION_NAME.updateJobMutationInput);
    },
    [setOpenWarningMutationName],
  );

  return (
    <>
      <ConnectionTable
        query={LOCATIONS_BY_JOB}
        initialQueryVariables={{
          first: 15,
          sort: { id: 'ASC' },
        }}
        title="Jobs"
        customActionsTop={(data, query) => {
          if (!permissions) {
            setPermissions({
              ...data?.viewer?.jobConnection?.permissions,
              locationIds:
                data?.viewer?.jobConnection?.permissions?.locationJobConnection?.edges?.node
                  ?.location?.__typename,
              posRoleId: data?.viewer?.jobConnection?.permissions?.posRole?.posRoleId,
            });
          }
          // pass table state so we can refresh it in cache later
          setTableState(query.variables);
          return (
            <Permission
              access={Math.min(
                createMutationPermission?.access,
                accessToMutations.createJobMutationInput,
              )}
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    disabled={isDeletingJob}
                    onClick={() =>
                      handleOpenModal({
                        query,
                        permissions: data?.viewer?.jobConnection?.permissions,
                      })
                    }
                  >
                    New Job
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          );
        }}
        structureTable={data =>
          data?.viewer?.jobConnection?.edges.map(job => {
            orderKeys[job.node.jobId] = job.node.orderKey;

            return {
              id: job.node.id,
              jobId: job.node.jobId,
              label: job.node.label,
              color: job.node.color,
              orderKey: job.node.orderKey,
              stores: job.node.locationJobConnection,
              disableTableRowClick: !updateMutationPermission?.access,
              disableDelete: !deleteMutationPermission?.access,
              job: job.node, // used when editing - passing existing job data
            };
          })
        }
        selectableRows={false}
        columns={[
          { title: 'Id', field: 'jobId' },
          { title: 'Label', field: 'label' },
          {
            title: 'Stores',
            field: 'stores',
            customPermissionField: 'locationJobConnection.edges.node.__typename',
            enablePropagation: true,
            render: locationJob => {
              return (
                <span>
                  {locationJob?.edges?.map(store =>
                    compact([
                      store?.node?.location?.label,
                      store?.node?.location?.storeNumber,
                    ]).join(' - #'),
                  )}
                </span>
              );
            },
          },
        ]}
        handleDeleteRow={handleDeleteRow}
        onTableRowClick={handleEditJob}
        isDeleting={isDeletingJob}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this job?"
      />
      <Modal open={showModal.open} handleClose={handleCloseModal} title="Job">
        <ManageJobForm
          tableState={tableState}
          existingJob={existingJob}
          permissions={showModal.permissions}
          closeModal={handleCloseModal}
          mutationPermissions={{ createMutationPermission, updateMutationPermission }}
          accessToMutations={accessToMutations}
          orderKeys={orderKeys}
        />
      </Modal>
    </>
  );
};

export default Job;
