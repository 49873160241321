import React, { useCallback, useEffect, useRef, useState } from 'react';
import { convert } from '../../../../../../utils';
import { Elements, Modal, Permission } from '../../../../../blocks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TreeItem from '@material-ui/lab/TreeItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField/TextField';
import Grid from '@material-ui/core/Grid';
import { Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import EditIcon from '@material-ui/icons/Edit';
import LOCATION_ITEM_PRICE from '../../../../../../apollo/queries/menus/locationItemPrice';
import TAX_GROUPS from '../../../../../../apollo/queries/taxGroupConnection';
import IconButton from '@material-ui/core/IconButton';
import AutoCompleteMUI from '../../../../../blocks/AutoComplete';
import MultipleModal from './MultipleModal';
import { DEFAULT_PAGE, MUTATION_NAME, ROWS_PER_PAGE_OPTIONS } from '../../../../../../constants';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import {
  TableFooter,
  TablePagination,
  TableRow,
  useTheme,
  Typography,
  Tooltip,
  Box,
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import UPDATE_LOCATION_ITEM from '../../../../../../apollo/mutations/menus/updateLocationItem';
import { uglifyId } from '../../../../../../utils';
import TOGGLE_LOCATION_ITEM from '../../../../../../apollo/mutations/menus/toggleLocationItem';
import TextFieldInput from '../../../../../blocks/TextFieldInput';
import PermissionField from '../../../../../blocks/PermissionField';
import { formatDataForItemMutation } from '../helpers';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { debounce, isNull, isUndefined, orderBy } from 'lodash';
import LocationItemTableToolbar from './LocationItemTableToolbar';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { formatNumberStringToFloat } from '../../../../helpers';

const ConnectionTablePaginationActions = props => {
  const { count, page, rowsPerPage, onChangePage, onTableRefresh, hideTotalCount } = props;

  const classes = useStyles();
  const theme = useTheme();

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleTableRefresh = () => {
    onTableRefresh();
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={event => handleFirstPageButtonClick(event)}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {!hideTotalCount && (
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      )}
      <IconButton onClick={handleTableRefresh}>
        <AutorenewIcon />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
  },
  itemRowHeader: {
    display: 'flex',
    width: '100%',
    fontSize: theme.typography.fontSizeS,
    color: theme.colors.bodyTextSecondary,
  },
  itemRow: {
    width: '100%',
    fontSize: theme.typography.fontSizeS,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0.5rem',
  },
  multipleChoiceColor: {
    color: theme.colors.bodyTextSecondary,
  },
  title: {
    fontSize: theme.typography.fontSizeL,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '1rem',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '1rem',
  },
  noteText: {
    fontSize: '.8rem',
  },
  saveButton: {
    fontSize: theme.typography.fontSizeXS,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  },
  discardButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  modalText: {
    fontSize: theme.typography.fontSizeM,
    fontWeight: theme.typography.fontWeightBold,
    height: '3rem',
    textAlign: 'center',
    width: '85%',
  },
  buttonGroup: {
    justifyContent: 'flex-start',
    display: 'flex',
    padding: '0 8px',
    margin: '1rem',
    '& > button:first-child': {
      marginRight: '1rem',
      backgroundColor: 'transparent',
      borderColor: '#e7e8ef',
    },
  },
  errorText: {
    position: 'absolute',
    top: '50px',
    left: '10px',
    color: '#D40E37',
    fontSize: '0.875rem',
  },
}));

const Item = ({
  locationId,
  menuId,
  tierId,
  filters,
  setFilters,
  statuses,
  isBulkEdit,
  locationIds,
  bulkEditPermission,
  mutationPermissions,
  chowlyAPIkey,
  setOpenWarningMutationName,
  accessToMutations,
  permissionsForMutations,
  setPermissionsForMutations,
  isPriceCapApplicable,
}) => {
  const { setSnackbar } = useSnackbar();

  const toggleLocationItemPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'toggleLocationItem',
  );
  const updateLocationItemPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'updateLocationItem',
  );
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const buttonRef = useRef(null);
  const [showTrackActionModal, setShowTrackActionModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [saveData, setSaveData] = useState({});
  const [connectionName, setConnectionName] = useState();
  const { register, control, handleSubmit, errors, setValue, getValues } = useForm();
  const [choices, setChoices] = useState(false);
  const [multipleId, setMultipleId] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [locationActiveTier, setLocationActiveTier] = useState();
  const [platforms, setPlatforms] = useState();
  const [updateLocationItem, { loading: updateLoading }] = useMutation(UPDATE_LOCATION_ITEM);
  const [toggleLocationItem, { loading: toggleLoading }] = useMutation(TOGGLE_LOCATION_ITEM);

  const { data, loading, error, refetch, variables } = useQuery(LOCATION_ITEM_PRICE, {
    variables: {
      first: 10,
      sort: {
        availableOnline: 'DESC',
        created: 'DESC',
      },
      locationFilter: {
        locationId: {
          eq: parseInt(locationId),
        },
      },
      locationMultipleChoice: {
        locationId: {
          eq: parseInt(locationId),
        },
      },
      subcategoryItemFilter: {
        subcategory: {
          menuId: {
            eq: menuId,
          },
        },
        showUnavailableOnline: { eq: true },
      },
      menuFilter: menuId
        ? {
            menu: {
              menuId: {
                eq: menuId,
              },
            },
          }
        : undefined,
      itemFilter: menuId
        ? {
            locationId: {
              eq: uglifyId('Location', locationId),
            },
            menuId: {
              eq: menuId,
            },
            showUnavailableOnline: {
              eq: true,
            },
          }
        : undefined,
      menuCategoryFilter: menuId
        ? {
            menuId: {
              eq: menuId,
            },
          }
        : undefined,
      locationId: uglifyId('Location', locationId),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data1 => {
      if (!permissionsForMutations) {
        setPermissionsForMutations({
          itemId: data1?.viewer?.itemConnection?.permissions?.itemId,
          toggle:
            data1?.viewer?.itemConnection?.permissions?.locationItemConnection?.edges?.node
              ?.isAvailable,
          multipleId:
            data1?.viewer?.itemConnection?.permissions?.multipleConnection?.edges?.node?.multipleId,
        });
      }
      setLocationActiveTier(data1?.viewer?.location?.tierId);
      setPlatforms(
        data1?.viewer?.platformConnection?.edges.map(platform => {
          if (platform?.node?.id === 'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
            return {
              node: {
                ...platform?.node,
                label: '3PV',
              },
            };
          }
          return platform;
        }),
      );
    },
  });

  const { data: taxGroupData } = useQuery(TAX_GROUPS, {
    variables: {
      filter: {
        locationId: { eq: locationId },
      },
    },
  });
  const locationAvailableTaxGroups = taxGroupData?.viewer?.taxgroupConnection?.edges?.map(elem => ({
    label: elem?.node?.label,
    value: elem?.node?.taxgroupId,
  }));
  let availableTaxGroups =
    locationAvailableTaxGroups?.length > 0
      ? [{ label: 'None', value: 0 }, ...locationAvailableTaxGroups]
      : [{ label: 'None', value: 0 }];

  const permissions = {
    menuCategory: data?.viewer?.menuCategoryConnection?.permissions,
    subcategory: data?.viewer?.subcategoryConnection?.permissions,
    item: data?.viewer?.itemConnection?.permissions,
  };
  useEffect(() => {
    if (!connectionName && data) {
      setConnectionName(Object.keys(data.viewer).find(element => element.includes('Connection')));
    }
  }, [data]);

  const search = value => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      search: value || null,
    });
  };

  const [debouncedSearch] = useState(() => debounce(value => search(value), 200));

  const handleSearchInput = event => {
    setSearchValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  const removeSearchValue = () => {
    setSearchValue();
    refetch({
      first: 10,
      after: null,
      last: null,
      before: null,
      search: null,
    });
  };

  const getMCPIPrice = item => {
    let price = item.price;
    if (item?.itemTierConnection?.edges?.length) {
      const tier = item?.itemTierConnection?.edges.find(
        itemTier => itemTier.node.tierId === tierId,
      );
      if (!tier) {
        return price;
      }
      price = tier.node.price;
    }
    return price;
  };

  const getPriceCap = item => {
    let priceCap = item.priceCap;

    if (item?.itemTierConnection?.edges?.length) {
      const tier = item?.itemTierConnection?.edges.find(
        itemTier => itemTier.node.tierId === tierId,
      );
      if (!tier || typeof tier.node?.priceCap !== 'number') {
        return priceCap;
      }
      priceCap = tier.node.priceCap;
    }
    return priceCap;
  };

  const getMultipleChoicePrice = item => {
    let price = item.price;

    if (item?.multipleChoiceTierConnection?.edges?.length) {
      const tier = item?.multipleChoiceTierConnection?.edges.find(
        itemTier => itemTier.node.tier.tierId === tierId,
      );
      if (!tier || !tier.node?.price) {
        return price;
      }
      price = tier.node.price;
    }
    return price;
  };

  const getMultipleChoicePriceCap = item => {
    let priceCap = item.priceCap;

    if (item?.multipleChoiceTierConnection?.edges?.length) {
      const tier = item?.multipleChoiceTierConnection?.edges.find(
        itemTier => itemTier.node.tier.tierId === tierId,
      );
      if (!tier || typeof tier.node?.priceCap !== 'number') {
        return priceCap;
      }
      priceCap = tier.node.priceCap;
    }
    return priceCap;
  };

  const handleChangePage = (event, page) => {
    if (currentPage > page) {
      const isFirstPage = page === 0;
      refetch({
        ...variables,
        first: null,
        after: null,
        last: variables.first || variables.last,
        before: isFirstPage
          ? btoa(`arrayconnection:${variables.first || variables.last}`) // eslint-disable-line no-undef
          : data.viewer.itemConnection.pageInfo.startCursor,
      });
      setCurrentPage(page);
    } else if (currentPage < page) {
      const isLastPage =
        Math.floor(data.viewer.itemConnection.totalCount / (variables.first || variables.last)) ===
        page;
      refetch({
        ...variables,
        first: variables.first || variables.last,
        after: isLastPage
          ? btoa(`arrayconnection:${(variables.first || variables.last) * page - 1}`) // eslint-disable-line no-undef
          : data.viewer.itemConnection.pageInfo.endCursor,
        last: null,
        before: null,
      });
      setCurrentPage(page);
    }
  };

  const handleChangeRowsPerPage = event => {
    if (variables.first) {
      refetch({
        ...variables,
        first: event.target.value,
      });
    } else {
      refetch({
        ...variables,
        last: event.target.value,
      });
    }
  };

  const handleCloseTrackActionModal = () => {
    setShowTrackActionModal(false);
  };

  const handleOpenTrackActionModal = () => {
    setShowTrackActionModal(true);
  };

  const handleTableRefresh = () => {
    refetch({
      ...variables,
    });
  };

  const toggleItem = (itemId, toggle) => {
    if (toggle !== 0) {
      toggleLocationItem({
        variables: {
          input: {
            locationIds: locationIds ? locationIds : [{ id: uglifyId('Location', locationId) }],
            itemId,
            toggle,
          },
        },
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Item is updated.',
        }),
      );
    }
  };

  const getChoicePrice = (locationChoices, choiceId, parentId) => {
    let choice;
    choice = locationChoices.find(locationChoice => {
      return (
        locationChoice?.node?.choice?.id === choiceId &&
        locationChoice?.node?.multiple?.id === parentId?.multiple
      );
    });

    if (choice) {
      return choice.node;
    }

    return null;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = (choices, multipleId) => {
    setChoices(choices);
    setMultipleId(multipleId);
    setShowModal(true);
    setOpenWarningMutationName(MUTATION_NAME.updateLocationItemMultipleChoicePriceMutationNDInput);
  };

  const checkActions = () => {
    const isInState = Object.keys(saveData).length !== 0 && saveData.constructor === Object;
    if (isInState) {
      handleOpenTrackActionModal();
      return true;
    }
    return false;
  };

  if (error) console.log(error, 'error');

  const formatSubcategoryLabels = subcategoryItemEdges => {
    return subcategoryItemEdges.map(
      subcategoryItem =>
        `${subcategoryItem.node.subcategory.label} (${subcategoryItem.node.subcategory.subcategoryId}) \n`,
    );
  };

  const generateData = (props = {}) => [
    {
      id: 'header',
      type: 'header',
      itemId: 'ITEM ID',
      title: 'ITEM',
      suggestedPrice: 'MCPI',
      priceCap: 'MAX PRICE',
      posPrice: isBulkEdit ? (
        <Tooltip
          title={
            <Typography variant="caption">
              To reset POS price to MCPI price, click on bin (
              <DeleteOutline fontSize="small" />) icon in the price field.
            </Typography>
          }
        >
          <Grid container>
            <Typography variant="body" style={{ marginRight: '1rem' }}>
              POS PRICE
            </Typography>
            <InfoOutlined />
          </Grid>
        </Tooltip>
      ) : (
        'POS PRICE'
      ),
      onlinePrice: isBulkEdit ? (
        <Tooltip
          title={
            <Typography variant="caption">
              To reset POS price to MCPI price, click on bin (
              <DeleteOutline fontSize="small" />) icon in the price field.
            </Typography>
          }
        >
          <Grid container>
            <Typography variant="body" style={{ marginRight: '1rem' }}>
              OLO PRICE
            </Typography>
            <InfoOutlined />
          </Grid>
        </Tooltip>
      ) : (
        'OLO PRICE'
      ),
      thirdPartyPrice: isBulkEdit ? (
        <Tooltip
          title={
            <Typography variant="caption">
              To reset Chowly 3PV price to MCPI price, click on bin (
              <DeleteOutline fontSize="small" />) icon in the price field.
            </Typography>
          }
        >
          <Grid container>
            <Typography variant="body" style={{ marginRight: '1rem' }}>
              CHOWLY 3PV PRICE
            </Typography>
            <InfoOutlined />
          </Grid>
        </Tooltip>
      ) : (
        'CHOWLY 3PV PRICE'
      ),
      taxGroup: 'TAX GROUP',
      active: isBulkEdit ? '' : 'ACTIVE',
    },
    ...(props?.viewer?.itemConnection?.edges?.map(item => ({
      id: item?.node?.id,
      type: 'Item',
      title: item?.node?.label,
      subcategories: item?.node?.subcategoryItemConnection?.edges,
      suggestedPrice: getMCPIPrice(item?.node),
      priceCap: getPriceCap(item?.node),
      posPrice: isBulkEdit
        ? null
        : item?.node?.locationItemConnection?.edges[0]
        ? item?.node?.locationItemConnection?.edges[0]?.node?.posPrice
        : null,
      onlinePrice: isBulkEdit
        ? null
        : item?.node?.locationItemConnection?.edges[0]
        ? item?.node?.locationItemConnection?.edges[0]?.node?.price
        : null,
      thirdPartyPrice: isBulkEdit
        ? null
        : item?.node?.locationItemConnection?.edges[0]
        ? item?.node?.locationItemConnection?.edges[0]?.node?.thirdPartyPrice
        : null,
      active: isBulkEdit
        ? null
        : item?.node?.locationItemConnection?.edges[0]
        ? item?.node?.locationItemConnection?.edges[0]?.node?.isAvailable
        : true,
      taxgroupId: isBulkEdit
        ? null
        : item?.node?.locationItemConnection?.edges[0]
        ? item?.node?.locationItemConnection?.edges[0]?.node?.taxgroupId
        : 0,
      children: [
        ...item?.node?.multipleConnection?.edges?.map(multiple => ({
          id: multiple?.node?.id,
          type: 'Multiple',
          disabled: item?.node?.price === 0,
          title: multiple?.node?.label,
          children: [
            ...multiple?.node?.multipleChoiceConnection?.edges?.map(multipleChoice => {
              let multipleChoicePrices = null;
              if (multiple?.node?.locationMultipleChoiceConnection?.edges[0]) {
                multipleChoicePrices = getChoicePrice(
                  multiple?.node?.locationMultipleChoiceConnection?.edges,
                  multipleChoice?.node?.choice?.id,
                  { multiple: multiple?.node?.id },
                );
              }
              return {
                id: multipleChoice?.node?.choice?.id,
                title: multipleChoice?.node?.choice?.label,
                type: 'MultipleChoice',
                suggestedPrice: getMultipleChoicePrice(multipleChoice?.node),
                priceCap: getMultipleChoicePriceCap(multipleChoice?.node),
                activeTierProps: multipleChoice?.node?.multipleChoiceTierConnection?.edges?.find(
                  tier => tier?.node?.tier?.tierId === locationActiveTier,
                ),
                platforms: platforms,
                onlinePrice: isBulkEdit
                  ? null
                  : multipleChoicePrices && multipleChoicePrices?.price !== null
                  ? multipleChoicePrices?.price
                  : null,
                posPrice: isBulkEdit
                  ? null
                  : multipleChoicePrices && multipleChoicePrices?.posPrice !== null
                  ? multipleChoicePrices?.posPrice
                  : null,
                thirdPartyPrice: isBulkEdit
                  ? null
                  : multipleChoicePrices && multipleChoicePrices?.thirdPartyPrice !== null
                  ? multipleChoicePrices?.thirdPartyPrice
                  : null,
              };
            }),
          ],
        })),
      ],
      platformsPriceCaps: item?.node?.itemTierConnection?.edges.find(
        tier => tier?.node?.tierId === locationActiveTier,
      ),
    })) || []),
  ];

  const onSubmit = formData => {
    const formattedData = formatDataForItemMutation(formData, isBulkEdit);
    var showPriceCapWarning = false;

    data.viewer.itemConnection.edges.forEach(item => {
      var itemPriceCap = getPriceCap(item.node);
      if (itemPriceCap !== null) {
        var itemFormData = formattedData.find(x => x.itemId === item.node.id);

        if (
          itemFormData?.posPrice > itemPriceCap ||
          itemFormData?.onlinePrice > itemPriceCap ||
          itemFormData?.price > itemPriceCap ||
          itemFormData?.thirdPartyPrice > itemPriceCap
        ) {
          showPriceCapWarning = true;
        }
      }
    });

    showPriceCapWarning = document?.querySelector('[data-isValid="Max Price exceeded"]')
      ? true
      : false;

    updateLocationItem({
      variables: {
        input: {
          locationIds: locationIds ? locationIds : [{ id: uglifyId('Location', locationId) }],
          items: formattedData,
        },
      },
    }).then(r => {
      refetch({
        ...variables,
      })
        .then(() => {
          if (showPriceCapWarning) {
            setSnackbar({
              open: true,
              type: 'warning',
              text:
                'Valid items have been updated. Please note: values over the Max Price cannot be saved.',
            });
          } else {
            setSnackbar({
              open: true,
              type: 'success',
              text: 'Items have been updated',
            });
          }
        })

        .catch(error =>
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
          }),
        );
    });
  };

  const trackActions = (id, value, type) => {
    setSaveData(prevState => ({ ...prevState, [id]: { [type]: value } }));
  };

  const isPriceCapExceeded = useCallback(
    (value, priceCap) =>
      priceCap && value && parseFloat(value) > parseFloat(convert.centsToDollars(priceCap))
        ? 'Max Price exceeded'
        : '',
    [],
  );

  const renderTree = nodes => {
    //If price is 0 disable textfields for items
    const isDisabled = nodes.type === 'Item' && nodes.suggestedPrice === 0;
    const title = (
      <Grid
        container
        xs={12}
        className={nodes?.id === 'header' ? classes.itemRowHeader : classes.itemRow}
        onClick={e => e.stopPropagation()}
      >
        <Grid
          container
          className={nodes.type === 'Multiple' ? classes.titleContainer : classes.item}
          item
          xs={3}
          spacing={2}
        >
          <Grid item xs={isPriceCapApplicable ? 5 : 7}>
            {nodes.type === 'Multiple' ? (
              <>
                <Permission
                  access={
                    isBulkEdit
                      ? bulkEditPermission.access
                      : permissions?.item?.multipleConnection?.edges?.node?.label
                  }
                >
                  <div>{nodes.title}</div>
                </Permission>
                <div>
                  <Permission
                    access={
                      accessToMutations?.[
                        MUTATION_NAME.updateLocationItemMultipleChoicePriceMutationNDInput
                      ]
                    }
                  >
                    <div>
                      <PermissionField createHelperText>
                        <IconButton
                          variant="contained"
                          onClick={() => handleOpenModal(nodes.children, nodes.id)}
                          size={'small'}
                        >
                          <EditIcon />
                        </IconButton>
                      </PermissionField>
                    </div>
                  </Permission>
                </div>
              </>
            ) : (
              <div>
                {nodes.title}
                {nodes.subcategories && (
                  <Tooltip
                    title={
                      <Typography variant="caption">
                        {formatSubcategoryLabels(nodes.subcategories)}
                      </Typography>
                    }
                  >
                    <Grid container>
                      <Typography></Typography>
                      <InfoOutlined />
                    </Grid>
                  </Tooltip>
                )}
              </div>
            )}
          </Grid>
          <Grid className={classes.item} item xs={isPriceCapApplicable ? 3 : 5}>
            <Permission access={isBulkEdit ? bulkEditPermission.access : permissions?.item?.price}>
              {nodes.type === 'header' && nodes.suggestedPrice}
            </Permission>
            <Permission access={isBulkEdit ? bulkEditPermission.access : permissions?.item?.label}>
              {nodes.type === 'Item' &&
                `$${nodes.suggestedPrice && convert.centsToDollars(nodes.suggestedPrice)}`}
            </Permission>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission.access
                  : permissions?.item?.multipleConnection?.edges?.node?.multipleChoiceConnection
                      ?.edges?.node?.price
              }
            >
              {nodes.type === 'MultipleChoice' &&
                `$${nodes.suggestedPrice && convert.centsToDollars(nodes.suggestedPrice)}`}
            </Permission>
          </Grid>
          {isPriceCapApplicable && (
            <Grid className={classes.item} style={{ color: 'grey' }} item xs={4}>
              <Permission
                access={isBulkEdit ? bulkEditPermission.access : permissions?.item?.price}
              >
                <span style={{ padding: '0 0.5rem' }}>
                  {nodes.type === 'MultipleChoice' ? (
                    <Tooltip
                      title={orderBy(nodes?.platforms, ['node.label'], ['desc'])?.map(
                        (platform, index) => {
                          {
                            const platformsSerialized = nodes?.activeTierProps
                              ? nodes?.activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.map(
                                  e => {
                                    if (e?.node?.platform?.label === 'TPV') {
                                      return {
                                        ...e.node,
                                        platform: {
                                          ...e?.node?.platform,
                                          label: '3PV',
                                        },
                                      };
                                    }
                                    return e.node;
                                  },
                                )
                              : [];
                            console.log('platformsSerialized', platformsSerialized);
                            const platformMaxPrice =
                              platformsSerialized?.find(
                                pl => pl?.platform?.label === platform?.node?.label,
                              )?.priceCap || nodes?.priceCap;

                            return (
                              <Typography variant="caption" style={{ display: 'block' }}>
                                {`${platform?.node?.label} Max Price: `}
                                {typeof platformMaxPrice === 'number' ? (
                                  <Typography style={{ fontWeight: 700 }} variant="caption">
                                    {`$${convert.centsToDollars(platformMaxPrice)}`}
                                  </Typography>
                                ) : (
                                  <Typography style={{ fontWeight: 700 }} variant="caption">
                                    {'Not Set'}
                                  </Typography>
                                )}
                              </Typography>
                            );
                          }
                        },
                      )}
                    >
                      <InfoOutlined />
                    </Tooltip>
                  ) : null}
                </span>
              </Permission>
              <Permission
                access={isBulkEdit ? bulkEditPermission.access : permissions?.item?.label}
              >
                {nodes.type === 'Item' && (
                  <Tooltip
                    title={orderBy(platforms, ['node.label'], ['desc'])?.map((platform, index) => {
                      {
                        const platformsSerialized = nodes?.platformsPriceCaps?.node?.itemTierPlatformConnection?.edges?.map(
                          e => {
                            if (e?.node?.platform?.label === 'TPV') {
                              return {
                                ...e.node,
                                platform: {
                                  ...e?.node?.platform,
                                  label: '3PV',
                                },
                              };
                            }
                            return e.node;
                          },
                        );

                        const platformMaxPrice = platformsSerialized?.find(
                          pl => pl?.platform?.label === platform?.node?.label,
                        )?.priceCap;

                        const finalPlatformMaxPrice = isUndefined(platformMaxPrice)
                          ? nodes?.priceCap
                          : platformMaxPrice;

                        return (
                          <Typography variant="caption" style={{ display: 'block' }}>
                            {`${platform?.node?.label} Max Price: `}
                            {typeof finalPlatformMaxPrice === 'number' ? (
                              <Typography style={{ fontWeight: 700 }} variant="caption">
                                {`$${convert.centsToDollars(finalPlatformMaxPrice)}`}
                              </Typography>
                            ) : (
                              <Typography style={{ fontWeight: 700 }} variant="caption">
                                {'Not Set'}
                              </Typography>
                            )}
                          </Typography>
                        );
                      }
                    })}
                  >
                    <InfoOutlined />
                  </Tooltip>
                )}
              </Permission>
              <Permission
                access={
                  isBulkEdit
                    ? bulkEditPermission.access
                    : permissions?.item?.multipleConnection?.edges?.node?.multipleChoiceConnection
                        ?.edges?.node?.price
                }
              >
                {/* {nodes.type === 'MultipleChoice' &&
                  (typeof nodes.priceCap === 'number'
                    ? `$${convert.centsToDollars(nodes.priceCap)}`
                    : '/')} */}
              </Permission>
            </Grid>
          )}
        </Grid>

        <Grid container item xs={9} spacing={2}>
          <Permission
            access={
              isBulkEdit
                ? bulkEditPermission.access
                : permissions?.item?.locationItemConnection?.edges?.node?.posPrice
            }
          >
            <Grid className={classes.item} positions="relative" item xs={3}>
              {nodes.type === 'header' && nodes.posPrice}
              {nodes.type === 'Item' && (
                <div style={{ position: 'relative' }}>
                  <PermissionField
                    Component={TextFieldInput}
                    name={`${nodes.id}.posPrice`}
                    hasRemove={isBulkEdit}
                    variant={'outlined'}
                    setValue={setValue}
                    isFloat={true}
                    register={register}
                    disabled={isDisabled}
                    inputProps={{
                      step: 0.01,
                    }}
                    rules={{
                      validate: {
                        positive: value => !value || formatNumberStringToFloat(value) >= 0,
                      },
                    }}
                    type={'number'}
                    onChange={e => trackActions(nodes.id, e.target.value, 'posPrice')}
                    helperText={
                      errors && errors[nodes.id]?.posPrice && 'Number should be positive.'
                    }
                    error={errors && errors[nodes.id]?.posPrice}
                    defaultValue={
                      (nodes.posPrice !== null && convert.centsToDollars(nodes.posPrice)) || ''
                    }
                  />
                  {isPriceCapApplicable && (
                    <span
                      className={classes.errorText}
                      data-isValid={isPriceCapExceeded(
                        getValues(`${nodes.id}.posPrice`),
                        nodes?.platformsPriceCaps?.node?.itemTierPlatformConnection?.edges?.find(
                          pl => pl?.node?.platform?.label === 'POS',
                        )?.node?.priceCap || nodes?.priceCap,
                      )}
                    >
                      {isPriceCapExceeded(
                        getValues(`${nodes.id}.posPrice`),
                        nodes?.platformsPriceCaps?.node?.itemTierPlatformConnection?.edges?.find(
                          pl => pl?.node?.platform?.label === 'POS',
                        )?.node?.priceCap || nodes?.priceCap,
                      )}
                    </span>
                  )}
                </div>
              )}
              <span className={classes.multipleChoiceColor}>
                {nodes.type === 'MultipleChoice' &&
                  (nodes.posPrice !== null
                    ? `$${convert.centsToDollars(nodes.posPrice)}`
                    : 'Price not set.')}
              </span>
            </Grid>
          </Permission>
          <Permission
            access={
              isBulkEdit
                ? bulkEditPermission.access
                : permissions?.item?.locationItemConnection?.edges?.node?.price
            }
          >
            <Grid className={classes.item} item xs={3}>
              {nodes.type === 'header' && nodes.onlinePrice}
              {nodes.type === 'Item' && (
                <div style={{ position: 'relative' }}>
                  <PermissionField
                    Component={TextFieldInput}
                    name={`${nodes.id}.onlinePrice`}
                    setValue={setValue}
                    variant={'outlined'}
                    hasRemove={isBulkEdit}
                    isFloat={true}
                    disabled={isDisabled}
                    helperText={
                      errors && errors[nodes.id]?.onlinePrice && 'Number should be positive.'
                    }
                    error={errors && errors[nodes.id]?.onlinePrice}
                    onChange={e => trackActions(nodes.id, e.target.value, 'onlinePrice')}
                    register={register}
                    rules={{
                      validate: {
                        positive: value => !value || formatNumberStringToFloat(value) >= 0,
                      },
                    }}
                    type={'number'}
                    inputProps={{
                      step: 0.01,
                    }}
                    defaultValue={
                      (nodes.onlinePrice !== null && convert.centsToDollars(nodes.onlinePrice)) ||
                      ''
                    }
                  />
                  {isPriceCapApplicable && (
                    <span
                      className={classes.errorText}
                      data-isValid={isPriceCapExceeded(
                        getValues(`${nodes.id}.onlinePrice`),
                        nodes?.platformsPriceCaps?.node?.itemTierPlatformConnection?.edges?.find(
                          pl => pl?.node?.platform?.label === 'OLO',
                        )?.node?.priceCap || nodes?.priceCap,
                      )}
                    >
                      {isPriceCapExceeded(
                        getValues(`${nodes.id}.onlinePrice`),
                        nodes?.platformsPriceCaps?.node?.itemTierPlatformConnection?.edges?.find(
                          pl => pl?.node?.platform?.label === 'OLO',
                        )?.node?.priceCap || nodes?.priceCap,
                      )}
                    </span>
                  )}
                </div>
              )}
              <span className={classes.multipleChoiceColor}>
                {nodes.type === 'MultipleChoice' &&
                  (nodes.onlinePrice !== null
                    ? `$${convert.centsToDollars(nodes.onlinePrice)}`
                    : 'Price not set')}
              </span>
            </Grid>
          </Permission>
          <Permission
            access={
              isBulkEdit
                ? bulkEditPermission.access
                : permissions?.item?.locationItemConnection?.edges?.node?.thirdPartyPrice ||
                  bulkEditPermission?.access
            }
          >
            <Grid className={classes.item} item xs={isBulkEdit ? 3 : 3}>
              {nodes.type === 'header' && nodes.thirdPartyPrice}
              {nodes.type === 'Item' && (
                <div style={{ position: 'relative' }}>
                  <PermissionField
                    Component={TextFieldInput}
                    name={`${nodes.id}.thirdPartyPrice`}
                    variant={'outlined'}
                    setValue={setValue}
                    hasRemove={isBulkEdit}
                    isFloat={true}
                    disabled={isDisabled || !chowlyAPIkey}
                    onChange={e => trackActions(nodes.id, e.target.value, 'thirdPartyPrice')}
                    helperText={
                      errors && errors[nodes.id]?.thirdPartyPrice && 'Number should be positive.'
                    }
                    error={errors && errors[nodes.id]?.thirdPartyPrice}
                    register={register}
                    rules={{
                      validate: {
                        positive: value => !value || formatNumberStringToFloat(value) >= 0,
                      },
                    }}
                    type={'number'}
                    inputProps={{
                      step: 0.01,
                    }}
                    defaultValue={
                      (nodes.thirdPartyPrice !== null &&
                        convert.centsToDollars(nodes.thirdPartyPrice)) ||
                      ''
                    }
                  />
                  {isPriceCapApplicable && (
                    <span
                      className={classes.errorText}
                      data-isValid={isPriceCapExceeded(
                        getValues(`${nodes.id}.thirdPartyPrice`),
                        nodes?.platformsPriceCaps?.node?.itemTierPlatformConnection?.edges?.find(
                          pl => pl?.node?.platform?.label === '3PV',
                        )?.node?.priceCap || nodes?.priceCap,
                      )}
                    >
                      {isPriceCapExceeded(
                        getValues(`${nodes.id}.thirdPartyPrice`),
                        nodes?.platformsPriceCaps?.node?.itemTierPlatformConnection?.edges?.find(
                          pl => pl?.node?.platform?.label === '3PV',
                        )?.node?.priceCap || nodes?.priceCap,
                      )}
                    </span>
                  )}
                </div>
              )}
              <span className={classes.multipleChoiceColor}>
                {nodes.type === 'MultipleChoice' &&
                  (nodes.thirdPartyPrice !== null
                    ? `$${convert.centsToDollars(nodes.thirdPartyPrice)}`
                    : 'Price not set')}
              </span>
            </Grid>
          </Permission>
          <Permission
            access={isBulkEdit ? bulkEditPermission.access : permissions?.item?.availableOnline}
          >
            <Grid className={classes.item} item xs={isBulkEdit ? 3 : 1}>
              {nodes.type === 'header' && nodes.active}
              {nodes?.type === 'Item' ? (
                <>
                  {statuses ? (
                    <Controller
                      name="activeStatus"
                      control={control}
                      disabled={toggleLoading || updateLoading}
                      defaultValue={statuses[0].value}
                      render={({ onChange, ref }) => (
                        <Permission
                          access={
                            isBulkEdit
                              ? bulkEditPermission.access
                              : Math.min(
                                  toggleLocationItemPermission?.access,
                                  permissions?.item?.availableOnline,
                                )
                          }
                        >
                          {/* <div>
                            <PermissionField
                              Component={Elements.ToggleButton}
                              createHelperText
                              ref={ref}
                              disabled={toggleLoading || updateLoading}
                              options={statuses}
                              value={statuses[0].value}
                              handleToggleClick={value => {
                                toggleItem(nodes.id, value);
                              }}
                            />
                          </div> */}
                        </Permission>
                      )}
                    />
                  ) : (
                    <Permission
                      access={
                        isBulkEdit
                          ? bulkEditPermission.access
                          : Math.min(
                              toggleLocationItemPermission?.access,
                              permissions?.item?.availableOnline,
                              accessToMutations?.[MUTATION_NAME.ToggleLocationItemMutationInput],
                            )
                      }
                    >
                      <div>
                        <PermissionField
                          Component={Elements.Switch}
                          createHelperText
                          checked={nodes.active}
                          handleSwitchChange={toggle => toggleItem(nodes.id, toggle)}
                          disabled={toggleLoading || updateLoading}
                        />
                      </div>
                    </Permission>
                  )}
                </>
              ) : null}
            </Grid>
          </Permission>
          <Permission
            access={
              isBulkEdit
                ? bulkEditPermission.access
                : permissions?.item?.locationItemConnection?.edges?.node?.taxgroupId
            }
          >
            <Grid className={classes.item} item xs={isBulkEdit ? 0 : 2}>
              {nodes.type === 'header' && !isBulkEdit && nodes.taxGroup}
              {nodes.type === 'Item' && !isBulkEdit && (
                <PermissionField
                  Component={AutoCompleteMUI}
                  id={`${nodes.id}.taxGroupId`}
                  getOptionLabel={option => option.label}
                  options={availableTaxGroups}
                  defaultValue={availableTaxGroups?.find(elem => elem?.value === nodes?.taxgroupId)}
                  style={{ width: '100%' }}
                  control={control}
                  renderInput={params => (
                    <Permission
                      access={permissions?.item?.locationItemConnection?.edges?.node?.taxgroupId}
                    >
                      <PermissionField
                        Component={TextField}
                        {...params}
                        label={'Tax Group'}
                        variant="outlined"
                      />
                    </Permission>
                  )}
                />
              )}
            </Grid>
          </Permission>
        </Grid>
      </Grid>
    );

    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        style={{ width: '100%', marginBottom: '1.5rem', marginTop: '1rem' }}
        label={title}
      >
        {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
      </TreeItem>
    );
  };

  const onSubcategoryChange = data => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      itemFilter: {
        ...variables.itemFilter,
        subcategoryId: {
          eq: data?.value || null,
        },
      },
    }).then(r => r);
    setFilters(prevState => ({
      ...prevState,
      subcategoryName: data?.label,
      subcategoryValue: data?.value,
    }));
  };

  const onCategoryChange = data => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      itemFilter: {
        ...variables.itemFilter,
        categoryId: {
          eq: data?.value || null,
        },
      },
    }).then(r => r);
    setFilters(prevState => ({
      ...prevState,
      categoryName: data?.label,
      categoryValue: data?.value,
    }));
  };
  const discardChanges = () => {
    refetch({
      ...variables,
    }).then(r => r);
    setSaveData({});
    handleCloseTrackActionModal();
  };

  const saveChanges = () => {
    handleCloseTrackActionModal();
    setSaveData({});
    buttonRef.current.click();
  };

  const TrackingActionsModalBody = () => {
    return (
      <div>
        <div className={classes.modalText}>
          <p>
            You have made changes that have not been saved. Would you like to Save your changes and
            continue?.
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
          }}
        >
          <div style={{ marginRight: '2rem' }}>
            <Button type={'submit'} onClick={() => saveChanges()}>
              SAVE CHANGES AND CONTINUE
            </Button>
          </div>
          <div>
            <Button className={classes.discardButton} onClick={() => discardChanges()}>
              DISCARD CHANGES
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const onlineStatusChange = data => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      itemFilter: {
        ...variables.itemFilter,
        locationAvailable: {
          eq: data?.value ? data?.value === 'active' : null,
        },
      },
    }).then(r => r);
    setFilters(prevState => ({
      ...prevState,
      onlineStatusName: data?.label,
      onlineStatusValue: data?.value,
    }));
  };
  const groupedData = generateData(data);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id={'items'}>
        <Paper style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <Container>
            <LocationItemTableToolbar
              isBulkEdit={isBulkEdit}
              refetch={refetch}
              variables={variables}
              bulkEditPermission={bulkEditPermission}
              setFilters={setFilters}
              filters={filters}
              menuId={menuId}
              classes={classes}
              control={control}
              itemPermission={data?.viewer?.itemConnection?.permissions}
            />
            {loading ? (
              <div>
                <Skeleton variant="rect" width={'100%'} height={478} />
              </div>
            ) : (
              <div style={{ overflow: 'auto' }}>
                <TreeView
                  className={classes.root}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={['root']}
                  style={{ width: '100%' }}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {groupedData.map(item => renderTree(item))}
                </TreeView>
              </div>
            )}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  style={{ width: '100%' }}
                  count={data?.viewer?.itemConnection?.totalCount}
                  rowsPerPage={variables.first || variables.last}
                  page={currentPage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={props => (
                    <ConnectionTablePaginationActions
                      {...props}
                      onTableRefresh={handleTableRefresh}
                      hideTotalCount={false}
                    />
                  )}
                />
              </TableRow>
            </TableFooter>
            <Grid container spacing={4} alignItems="center" className={classes.buttonGroup}>
              <Button type={'button'} ref={buttonRef} onClick={discardChanges} variant="outlined">
                Discard
              </Button>
              <Permission
                access={
                  isBulkEdit
                    ? bulkEditPermission.access
                    : Math.min(
                        updateLocationItemPermission?.access,
                        accessToMutations?.[MUTATION_NAME.UpdateLocationItemMutationInput],
                      )
                }
              >
                <div>
                  <PermissionField createHelperText>
                    <Button type={'Submit'} ref={buttonRef}>
                      Save
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
            </Grid>
          </Container>
        </Paper>
      </form>
      <Modal open={showModal} handleClose={handleCloseModal} title="Edit">
        <MultipleModal
          choices={choices}
          handleClose={handleCloseModal}
          chowlyAPIkey={chowlyAPIkey}
          multipleId={multipleId}
          locationIds={locationIds}
          locationId={locationId}
          isBulkEdit={isBulkEdit}
          variables={variables}
          mutationPermission={mutationPermissions}
          permissions={{
            suggestedPrice: isBulkEdit ? bulkEditPermission.access : permissions?.item?.price,
            onlinePrice: isBulkEdit
              ? bulkEditPermission.access
              : permissions?.item?.locationItemConnection?.edges?.node?.price,
            posPrice: isBulkEdit
              ? bulkEditPermission.access
              : permissions?.item?.locationItemConnection?.edges?.node?.posPrice,
            thirdPartyPrice: isBulkEdit
              ? bulkEditPermission.access
              : permissions?.item?.locationItemConnection?.edges?.node?.thirdPartyPrice,
          }}
          accessToMutations={accessToMutations}
          isPriceCapApplicable={isPriceCapApplicable}
        />
      </Modal>
      <Modal
        open={showTrackActionModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        handleClose={handleCloseTrackActionModal}
      >
        <TrackingActionsModalBody />
      </Modal>
    </>
  );
};

export default Item;
