import { compact } from "lodash";

const getNonNullFields = (field, name) => {
  if (field.type) {
    return getNonNullFields(field.type, field.name);
  }

  if (field.kind === "LIST") {
    return getNonNullFields(field.ofType);
  }

  if (field.kind === "INPUT_OBJECT" && field.inputFields?.length) {
    const inputFields = compact(field.inputFields.map(childField => getNonNullFields(childField)));
    
    return inputFields.length 
      ? { [name]: inputFields }
      : false;
  }

  return field.kind === "NON_NULL" && name;
}

const getMutationRequiredFields = (data) => {
  if (data) {
    const inputObjects = data?.__schema?.types?.filter(type => type.kind === "INPUT_OBJECT");

    return inputObjects.reduce((result, object) => {
      const inputFields = compact(object?.inputFields?.map(field => getNonNullFields(field)));

      if (inputFields?.length) {
        result[object.name] = inputFields;
      }

      return result;
    }, []);
  }

  return null;
}

export default getMutationRequiredFields;