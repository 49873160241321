import { gql } from "@apollo/client";

const CREATE_LOGIN_HIERARCHY = gql`
  mutation createLoginHierarchy($input: createLoginHierarchyMutationInput!) {
    createLoginHierarchy(input: $input) {
      clientMutationId
    }
  }
`;

export default CREATE_LOGIN_HIERARCHY;
