import { gql } from '@apollo/client';
import { stateFragment } from '../fragments/stateFragment';

const FETCH_STATES = gql`
  query fetchState {
    viewer {
      id
      stateConnection {
        permissions
        edges {
          node {
            ...StateData
          }
        }
      }
    }
  }
  ${stateFragment.state}
`;

export default FETCH_STATES;
