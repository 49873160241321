import { Typography, Grid, TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import { convert, CurrencyInputFormat } from '../../../../../../utils';
import { CurrencyInput } from '../../../../../blocks';
import GenericFileUpload from '../../../../../blocks/GenericFileUpload';

import DatePicker from '../../../../../blocks/Elements/DatePicker';

const InvoiceInfo = ({ image, setImage, invoice, register, errors, control }) => {
  return (
    <>
      <h2>Invoice Info:</h2>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <TextField
            name="supplierName"
            inputRef={register({ required: true })}
            label="Supplier Name"
            error={!!errors?.supplierName}
            helperText={errors?.supplierName ? 'Supplier name is required' : ''}
            fullWidth
            placeholder="Supplier Name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="invoiceNumber"
            inputRef={register({
              required: true,
              validate: {
                isNumber: value => RegExp(/^[0-9]+$/).test(value),
              },
            })}
            label="Invoice Number"
            error={!!errors?.invoiceNumber}
            helperText={
              errors?.invoiceNumber?.type === 'required'
                ? 'Invoice number is required'
                : errors?.invoiceNumber?.type === 'isNumber'
                ? 'Invoice number must contain only numbers'
                : ''
            }
            fullWidth
            placeholder="Invoice Number"
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            control={control}
            name="dateOfPurchase"
            fullWidth
            disableFuture
            rules={{
              required: 'Date of purchase is required',
            }}
            error={!!errors?.dateOfPurchase}
            helperText={errors?.dateOfPurchase ? errors?.dateOfPurchase?.message : ''}
            variant="inline"
            inputVariant="outlined"
            format="LL/dd/yyyy"
            margin="none"
            label="Date of Purchase"
            autoOk={false}
            initialFocusedDate={DateTime.local()}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrencyInput
            fullWidth
            control={control}
            error={!!errors?.subtotal}
            helperText={errors?.subtotal ? errors?.subtotal?.message : ''}
            name="subtotal"
            label="Order Subtotal"
            defaultValue={invoice?.subtotal ? convert.centsToDollars(invoice?.subtotal) : ''}
            rules={{
              required: 'Subtotal is required',
              validate: {
                positive: value => {
                  if (typeof value === 'number') {
                    if (value >= 0) {
                      return true;
                    }
                  }

                  const price = CurrencyInputFormat(value);
                  const isPositive = price >= 0;
                  return isPositive;
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrencyInput
            fullWidth
            control={control}
            error={!!errors?.tax}
            helperText={errors?.tax ? errors?.tax?.message : ''}
            control={control}
            name="tax"
            label="Tax"
            defaultValue={invoice?.tax ? convert.centsToDollars(invoice?.tax) : ''}
            rules={{
              required: 'Tax is required',
              validate: {
                positive: value => {
                  if (typeof value === 'number') {
                    if (value >= 0) {
                      return true;
                    }
                  }

                  const price = CurrencyInputFormat(value);
                  const isPositive = price >= 0;
                  return isPositive;
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrencyInput
            fullWidth
            control={control}
            error={!!errors?.total}
            helperText={errors?.total ? errors?.total?.message : ''}
            control={control}
            name="total"
            label="Total"
            defaultValue={invoice?.total ? convert.centsToDollars(invoice?.total) : ''}
            rules={{
              required: 'Total is required',
              validate: {
                positive: value => {
                  if (typeof value === 'number') {
                    if (value >= 0) {
                      return true;
                    }
                  }

                  const price = CurrencyInputFormat(value);
                  const isPositive = price >= 0;
                  return isPositive;
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" style={{ width: '100%' }}>
            Upload a photo of your receipt
          </Typography>
        </Grid>
        <Grid item container xs={12} style={{ marginBottom: '12px' }}>
          <GenericFileUpload
            file={image}
            setFile={setImage}
            shouldReadFile
            isImage
            disableDelete
            accept="image/jpeg, image/png, .pdf"
            text="Click here to add an image"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceInfo;
