import React from "react";
import SubcategorySetting from "../SubcategorySetting";
function CategoryPrintSettingsView({
  subcategoriesPrinterstations,
  permissions
}) {
  return (
    <div>
      <h2 id="categoryPrintSettings">Category print Settings</h2>
      <div
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "3rem" }}
      >
        {subcategoriesPrinterstations.map((category) => (
          <div style={{ height: "max-content" }}>
            <h3>{category.categoryName}</h3>
            <div>
              {category.subcategories.map((sub) => (
                <SubcategorySetting
                  sub={sub}
                  permissions={permissions}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryPrintSettingsView;
