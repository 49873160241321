import React from 'react';
import DiscountsTable from '../Table';

const DiscountsView = () => {
  return (
    <div>
        <DiscountsTable />
    </div>
  );
};

export default DiscountsView;
