import React from 'react';
import PropTypes from 'prop-types';
import AllergensFormView from './View';
import AllergenCheckbox from './AllergenCheckbox';

function AllergensForm({ register, allergenConnection, ingredientAllergenConnection }) {
  return (
    <AllergensFormView
      AllergenCheckbox={AllergenCheckbox}
      register={register}
      ingredientAllergenConnection={ingredientAllergenConnection}
      allergenConnection={allergenConnection}
    />
  );
}

AllergensForm.propTypes = {
  register: PropTypes.func,
  allergenConnection: PropTypes.object,
  ingredientAllergenConnection: PropTypes.object,
};

export default AllergensForm;
