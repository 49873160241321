import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Typography,
} from '@material-ui/core';

const AvailableOnDaysInput = ({
  days,
  setDays,
  availableOnDays,
  setAvailableOnDays,
}) => {
  const changeAvailableOnDays = (e) => setAvailableOnDays(e.target.value);

  const changeAvailableDays = (e) =>
    setDays({ ...days, [e.target.name]: e.target.checked });

  const {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  } = days;

  return (
    <>
      <Grid container>
        <Grid item xs={12} direction="column">
          <Typography
            variant="h6"
            component="h6"
          >
            Available on days
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="availability"
              name="availability"
              value={availableOnDays}
              onChange={changeAvailableOnDays}
            >
              <FormControlLabel
                value="everyday"
                control={<Radio />}
                label="Everyday"
              />
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Specific"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <br />
        {availableOnDays === 'specific' && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={monday}
                      onChange={changeAvailableDays}
                      name="monday"
                    />
                  }
                  label="Monday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tuesday}
                      onChange={changeAvailableDays}
                      name="tuesday"
                    />
                  }
                  label="Tuesday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wednesday}
                      onChange={changeAvailableDays}
                      name="wednesday"
                    />
                  }
                  label="Wednesday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thursday}
                      onChange={changeAvailableDays}
                      name="thursday"
                    />
                  }
                  label="Thursday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={friday}
                      onChange={changeAvailableDays}
                      name="friday"
                    />
                  }
                  label="Friday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saturday}
                      onChange={changeAvailableDays}
                      name="saturday"
                    />
                  }
                  label="Saturday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sunday}
                      onChange={changeAvailableDays}
                      name="sunday"
                    />
                  }
                  label="Sunday"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

AvailableOnDaysInput.propTypes = {
  days: PropTypes.object.isRequired,
  setDays: PropTypes.func.isRequired,
  availableOnDays: PropTypes.bool.isRequired,
  setAvailableOnDays: PropTypes.func.isRequired,
};

export default AvailableOnDaysInput;
