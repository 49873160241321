import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";

import CREATE_ITEM_TYPE from "../../../../../apollo/mutations/menus/createItemType";
import UPDATE_ITEM_TYPE from "../../../../../apollo/mutations/menus/updateItemType";
import { Permission, PermissionField } from "../../../../blocks";
import Switch from "../../../../blocks/Elements/Switch";
import { useMutation } from "@apollo/client";
import { SNACKBAR_STATUS } from "../../../../../constants";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { formatConnectionName } from "../../../../../utils";

import styles from "./styles";
import { omit } from "lodash";

const ItemTypeForm = ({
  itemType,
  handoffsData,
  handleCloseModal,
  connectionVariables,
}) => {
  const { handleSubmit, register, errors, control, setValue } = useForm();
  const { setSnackbar } = useSnackbar();

  const findHandoff = useCallback(
    handoff =>
      itemType?.itemtypeHandoffConnection?.edges?.find(
        edge => edge.node?.handoff?.handoffId === handoff?.node?.handoffId
      ), [itemType]
  );

  const classes = styles();

  const [createItemType] = useMutation(CREATE_ITEM_TYPE, {
    update: (cache, { data: { createItemtype } }) => {
      const { dynamicConnectionName } = formatConnectionName(
        "itemtype",
        connectionVariables
      );
      cache.modify({
        id: cache.identify(createItemtype.viewer),
        fields: {
          [dynamicConnectionName]: (existingItemtypeConnection) => {
            return {
              ...existingItemtypeConnection,
              totalCount: existingItemtypeConnection.totalCount + 1,
              edges: [
                ...existingItemtypeConnection.edges,
                {
                  node: {
                    __ref: `Itemtype:${createItemtype.itemtype.id}`,
                  },
                },
              ],
            };
          },
        },
      });
    },
    onCompleted: () => {
      handleCloseModal();

      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: "Item Type created",
        open: true,
      });
    },
    onError: (error) => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      });
    },
  });
  const [updateItemType] = useMutation(UPDATE_ITEM_TYPE, {
    onCompleted: () => {
      handleCloseModal();

      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: "Item Type updated",
        open: true,
      });
    },
    onError: (error) => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      });
    },
  });

  const onSubmit = useCallback(
    (formData) => {
      const handoffKeys = [];

      const handoffs = Object.keys(formData)
        .filter((key) => key.includes("handoff"))
        .map((handoffKey) => {
          handoffKeys.push(handoffKey);

          return {
            handoffId: parseInt(handoffKey.split("-")[1]),
            isAvailable: formData[handoffKey],
          };
        });

      const variables = {
        input: {
          ...omit(formData, handoffKeys),
          itemtypeId: itemType?.itemtypeId,
          isTaxable: formData?.isTaxable,
          isDiscountable: formData?.isDiscountable,
          isLoyalty: formData?.isLoyalty,
          handoffs,
        },
      };

      if (itemType?.itemtypeId) {
        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          text: "Item Type updating...",
          open: true,
        });

        updateItemType({
          variables,
        });
      } else {
        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          text: "Item Type creating...",
          open: true,
        });

        createItemType({
          variables,
        });
      }
    },
    [itemType?.itemtypeId, createItemType, updateItemType, setSnackbar]
  );

  const handleOnCancel = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', margin: '1rem 0' }}>
      <Grid container spacing={3}>
        <Permission access={7}>
          <Grid item xs={12} sm={4}>
            <PermissionField
              Component={TextField}
              inputRef={register({ required: 'Required' })}
              label="Label"
              name="label"
              error={!!errors.label}
              helperText={errors.label ? 'Label is required' : ''}
              variant="outlined"
              disabled={false}
              defaultValue={itemType?.label}
              fullWidth
            />
          </Grid>
        </Permission>
        <Permission access={7}>
          <Grid item xs={12} sm={8}>
            <PermissionField
              Component={TextField}
              inputRef={register}
              label="Description"
              name="description"
              variant="outlined"
              disabled={false}
              defaultValue={itemType?.description}
              fullWidth
            />
          </Grid>
        </Permission>

        <Permission access={7}>
          <Grid item container justify="center" xs={6} sm={3}>
            <Grid item xs={8}>
              <PermissionField
                Component={FormControlLabel}
                label="Is Taxable"
                labelPlacement="start"
                control={
                  <Controller
                    control={control}
                    defaultValue={!!itemType?.isTaxable}
                    classes={classes}
                    name={'isTaxable'}
                    render={({ ref }) => (
                      <Permission access={7}>
                        <PermissionField
                          Component={Switch}
                          ref={register}
                          name="isTaxable"
                          onLabel="Yes"
                          offLabel="No"
                          checked={itemType?.isTaxable}
                          handleSwitchChange={value => setValue('isTaxable', value)}
                          xs={0}
                        />
                      </Permission>
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        </Permission>
        <Permission access={7}>
          <Grid item container justify="center" xs={6} sm={3}>
            <Grid item xs={8}>
              <PermissionField
                Component={FormControlLabel}
                label="Is Loyalty"
                labelPlacement="start"
                control={
                  <Controller
                    control={control}
                    classes={classes}
                    defaultValue={!!itemType?.isLoyalty}
                    name={'isLoyalty'}
                    render={({ ref }) => (
                      <Permission access={7}>
                        <PermissionField
                          Component={Switch}
                          ref={register}
                          onLabel="Yes"
                          offLabel="No"
                          name="isLoyalty"
                          checked={itemType?.isLoyalty}
                          xs={0}
                          handleSwitchChange={value => setValue('isLoyalty', value)}
                        />
                      </Permission>
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        </Permission>
        <Permission access={7}>
          <Grid item spacing={1} xs={6} sm={3}>
            <PermissionField
              Component={FormControlLabel}
              label="Is Alcohol"
              labelPlacement="start"
              classes={classes}
              control={
                <Controller
                  control={control}
                  defaultValue={!!itemType?.isAlcohol}
                  name={'isAlcohol'}
                  render={({ ref }) => (
                    <Permission access={7}>
                      <PermissionField
                        Component={Switch}
                        ref={register}
                        name="isAlcohol"
                        onLabel="Yes"
                        offLabel="No"
                        checked={itemType?.isAlcohol}
                        handleSwitchChange={value => setValue('isAlcohol', value)}
                        xs={0}
                      />
                    </Permission>
                  )}
                />
              }
            />
          </Grid>
        </Permission>
        <Permission access={7}>
          <Grid item spacing={1} xs={6} sm={3}>
            <PermissionField
              Component={FormControlLabel}
              label="Is Discountable"
              labelPlacement="start"
              classes={classes}
              control={
                <Controller
                  control={control}
                  defaultValue={!!itemType?.isDiscountable}
                  name={'isDiscountable'}
                  render={({ ref }) => (
                    <Permission access={7}>
                      <PermissionField
                        Component={Switch}
                        ref={register}
                        name="isDiscountable"
                        onLabel="Yes"
                        offLabel="No"
                        checked={itemType?.isDiscountable}
                        handleSwitchChange={value => setValue('isDiscountable', value)}
                        xs={0}
                      />
                    </Permission>
                  )}
                />
              }
            />
          </Grid>
        </Permission>
      </Grid>

      {handoffsData?.length > 0 ? (
        <>
          <Typography variant="h6" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            Handoffs
          </Typography>
          <Grid container xs={12} spacing={3}>
            {handoffsData?.map(handoff => {
              const foundHandoff = findHandoff(handoff);
              return (
                <Permission access={7} key={handoff?.node?.handoffId}>
                  <Grid item xs={3}>
                    <PermissionField
                      Component={FormControlLabel}
                      label={handoff?.node?.label}
                      labelPlacement="start"
                      classes={classes}
                      control={
                        <Controller
                          control={control}
                          defaultValue={!!foundHandoff?.node?.isAvailable}
                          name={`handoff-${handoff?.node?.handoffId}`}
                          render={({ ref }) => (
                            <Permission access={7}>
                              <PermissionField
                                Component={Switch}
                                ref={register}
                                name={`handoff-${handoff?.node?.handoffId}`}
                                onLabel="Yes"
                                offLabel="No"
                                checked={foundHandoff?.node?.isAvailable}
                                handleSwitchChange={value =>
                                  setValue(`handoff-${handoff?.node?.handoffId}`, value)
                                }
                                xs={0}
                              />
                            </Permission>
                          )}
                        />
                      }
                    />
                  </Grid>
                </Permission>
              );
            })}
          </Grid>
        </>
      ) : null}

      <Grid container spacing={3} justify="flex-end">
        <Grid item>
          <Button variant="outlined" type="button" onClick={handleOnCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit">{!itemType?.itemtypeId ? 'Create' : 'Update'}</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ItemTypeForm;
