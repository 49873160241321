import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CONTACT_CUSTOMER } from "../../../../../apollo/mutations/orders/contactCustomer";
import { getDisputeInfo } from "../../../../../utils";
import ContactCustomerView from "./View";
import { MUTATION_NAME } from "../../../../../constants";
import { useSnackbar } from "../../../../../hooks/useSnackbar";

function ContactCustomer({ accessToMutations, data, handleClose }) {
  const { setSnackbar } = useSnackbar();

  let { disputeReason } = getDisputeInfo(data);
  const [disableButton, setDisabledButton] = useState(false);

  const [sendMessage] = useMutation(CONTACT_CUSTOMER, {
    onCompleted: (data) => {
      setDisabledButton(false);
      setSnackbar({
        open: true,
        type: "success",
        text: "Message sent!",
      });
    },
    onError: (err) => {
      setDisabledButton(false);
      console.log(err);
      setSnackbar({
        open: true,
        type: "error",
        text: "Oops, something went wrong...",
      });
    },
  });
  
  const { errors, handleSubmit, control, setValue } = useForm();

  const submit = (_data) => {
    setDisabledButton(true);
    _data.orderId = data?.viewer?.quote?.quoteId; // set valid quote/order ID
    sendMessage({
      variables: { input: _data },
    });
  };

  return (
    <div>
      <ContactCustomerView
        data={data}
        errors={errors}
        control={control}
        disputeReason={disputeReason}
        submit={submit}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        setValue={setValue}
        disableButton={disableButton || accessToMutations?.[MUTATION_NAME.ContactCustomerMutationInput] < 7}
      />
    </div>
  );
}

export default ContactCustomer;
