import { gql } from '@apollo/client';

const INGREDIENT_LOCATION_AVAILABILITY = gql`
  query INGREDIENT_LOCATION_AVAILABILITY(
    $first: Int
    $last: Int
    $ingredientFilter: [LocationIngredientFilter]
    $after: String
    $before: String
    $search: String
    $sort: [LocationSort]
    $filter: [LocationFilter]
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      locationConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: $fieldGroupOnly
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            label
            locationId
            storeNumber
            address {
              city
              state {
                label
              }
            }
            locationIngredientConnection(filter: $ingredientFilter) {
              edges {
                node {
                  available
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default INGREDIENT_LOCATION_AVAILABILITY;
