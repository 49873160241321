import { gql } from '@apollo/client';

const UPDATE_MULTIPLE = gql`
  mutation updateMultiple($input: UpdateMultipleMutationInput!) {
    updateMultiple(input: $input) {
      multiple {
        id
        label
        description
        multipleId
        multipleChoiceConnection {
          edges {
            node {
              id
              price
              priceCap
              multiple {
                id
              }
              choice {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_MULTIPLE;
