import { gql } from "@apollo/client";

const CREATE_LOCATION_STRIPE = gql`
  mutation CreateLocationStripe($input: CreateLocationStripeMutationInput!) {
    createLocationStripe(input: $input) {
      location {
        id
      }
      viewer {
        id
      }
      url
    }
  }
`;

export default CREATE_LOCATION_STRIPE;
