import { gql } from "@apollo/client";

const COPY_QUOTECATERING = gql`
  mutation copyQuotecatering($quotecateringId: ID!) {
    copyQuotecatering(input: { quotecateringId: $quotecateringId }) {
      quote {
        quoteId
      }
    }
  }
`;
export default COPY_QUOTECATERING;