import { gql } from '@apollo/client';

const FETCH_MENU_LIST_ALL = gql`
  query fetchMenuList($filter: [SubcategoryItemFilter]) {
    viewer {
      id
      menuConnection(fieldGroupOnly: 7) {
        edges {
          node {
            id
            menuId
            label
            menuCategoryConnection {
              edges {
                node {
                  id
                  category {
                    id
                    categoryId
                    label
                    subcategoryConnection {
                      edges {
                        node {
                          id
                          subcategoryId
                          menuId
                          label
                          subcategoryItemConnection(filter: $filter) {
                            edges {
                              node {
                                id
                                item {
                                  id
                                  itemId
                                  label
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_MENU_LIST_ALL;
