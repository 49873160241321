import { gql } from '@apollo/client';

const HANDOFFS = gql`
  query Handoffs($sort: [HandoffSort]) {
    viewer {
      id
      handoffConnection(sort: $sort) {
        permissions
        edges {
          node {
            id
            handoffId
            label
          }
        }
      }
    }
  }
`;

export default HANDOFFS;
