import { gql } from '@apollo/client';

const UPDATE_INVENTORY_ITEM_CATEGORY_TYPE = gql`
  mutation UPDATE_INVENTORY_ITEM_CATEGORY_TYPE($input: UpdateInventoryitemcategorytypeInput!) {
    updateInventoryitemcategorytype(input: $input) {
      viewer {
        id
      }
      inventoryitemcategorytype {
        id
        inventoryitemcategorytypeId
        isCost
        label
        active
        created
      }
    }
  }
`;

export default UPDATE_INVENTORY_ITEM_CATEGORY_TYPE;
