import { gql } from '@apollo/client';

export const paymentFragment = {
  payment: gql`
    fragment PaymentData on Payment {
      id
      paymentId
      tender {
        label
      }
      refunded
      amount
      created
      paymentGatewayConnection(first: 1) {
        edges {
          node {
            label
          }
        }
      }
    }
  `,
};
