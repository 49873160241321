import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  root: {
    width: '100%',
    marginLeft: '1rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {},
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowTitle: {
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizeS,

    '& p': {
      margin: 0,
    },
  },
  rowValueGray: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizeL,
    color: theme.colors.bodyTextSecondary,
    fontWeight: theme.typography.fontWeightBold,
  },
  rowValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizeL,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'right',
  },
  rowLarge: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizeXXL,
    fontWeight: theme.typography.fontWeightBold,
  },
});

export const HouseAccountPaymentStyles = makeStyles(Styles);
