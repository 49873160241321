import { gql } from '@apollo/client';

const CREATE_JOB_BREAK_RULE = gql`
  mutation createJobBreakRule($input: CreateJobBreakRuleMutationInput!) {
    createJobBreakRule(input: $input) {
      clientMutationId
    }
  }
`;

export default CREATE_JOB_BREAK_RULE;
