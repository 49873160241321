import { gql } from '@apollo/client';
const FETCH_SCHEDULE_PROJECTIONS = gql`
  query fetchscheduleWeeklysalesprojectionsForLocation(
    $weeklySaleProjectionsFilter: [ScheduleweeklysalesprojectionFilter]
  ) {
    viewer {
      id
      scheduleweeklysalesprojectionConnection(
        filter: $weeklySaleProjectionsFilter
        fieldGroup: 62
      ) {
        permissions
        edges {
          node {
            id
            scheduleweeklysalesprojectionId
            created
            started
            finished
            locationId
            scheduleweeklysalesprojectionWeekday {
              weekdayId
              amount
            }
          }
        }
      }
    }
  }
`;

export default FETCH_SCHEDULE_PROJECTIONS;
