import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Switch,
  FormControlLabel,
  Chip,
  RadioGroup,
  Radio,
  Grid,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Add from '@material-ui/icons/Add';

import { Modal } from '../../../../../../blocks';
import AddItemsModal from '../AddItemsModal';
import { ItemInCartStyle } from './style';

const ITEM_IN_CART_TRIGGER = { id: 3, label: 'Item in cart' };
const ITEM_IN_CART_ACTIONS = [
  {
    id: 1,
    label: 'Multiplier applies to all items in cart',
    isIncluded: true,
    couponactionId: 1,
  },
  {
    id: 2,
    label: 'Multiplier applies to all except items in rule',
    isIncluded: false,
    couponactionId: 1,
  },
  {
    id: 3,
    label: 'Multiplier applies only to item in rule',
    isIncluded: true,
    couponactionId: 3,
  },
  {
    id: 4,
    label: 'No multiplier is applied',
    isIncluded: false,
    couponactionId: 3,
  },
];

function ItemInCart({
  control,
  isEnabled,
  setItemInCartIsEnabled,
  selectedItems,
  setSelectedItems,
  selectedCouponaction,
  setSelectedCouponaction,
  selectedCoupontriggers,
  setSelectedCoupontriggers,
  selectedBrands,
}) {
  const classes = ItemInCartStyle();

  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(
    ITEM_IN_CART_ACTIONS.find(
      action =>
        action.couponactionId === selectedCouponaction &&
        action.isIncluded === selectedItems?.every(item => item.isIncluded),
    ) || { id: 1 },
  );

  const onActionChange = (event, value) => {
    setSelectedAction(ITEM_IN_CART_ACTIONS.find(action => action.id === +value));
  };

  useEffect(() => {
    setSelectedCouponaction(selectedAction.couponactionId);
    setSelectedItems(
      selectedItems.map(item => ({ ...item, isIncluded: selectedAction.isIncluded })),
    );
  }, [selectedAction]);

  const handleClose = items => {
    if (items.length) {
      setSelectedItems(
        items.map(({ label, id, itemId, isIncluded }) => ({
          label,
          id,
          itemId,
          isIncluded,
        })),
      );
    }
    setOpen(false);
  };

  const handleDelete = itemId => {
    const newSelectedItems = selectedItems.filter(item => item.itemId !== itemId);
    setSelectedItems(newSelectedItems);
  };

  return (
    <div>
      <FormGroup row={true}>
        <FormControlLabel
          label="Item in cart"
          labelPlacement="start"
          className={classes.switchLabel}
          control={
            <Controller
              defaultValue={isEnabled}
              name="itemInCart"
              control={control}
              render={props => (
                <div style={{ marginLeft: '1rem' }}>
                  <Switch
                    onChange={e => {
                      props.onChange(e.target.checked);
                      setItemInCartIsEnabled(e.target.checked);
                      if (e.target.checked) {
                        setSelectedCoupontriggers([
                          ...selectedCoupontriggers,
                          ITEM_IN_CART_TRIGGER,
                        ]);
                      } else {
                        setSelectedCoupontriggers(
                          selectedCoupontriggers?.filter(
                            trigger => trigger.id !== ITEM_IN_CART_TRIGGER.id,
                          ),
                        );
                      }
                    }}
                    checked={props.value}
                  />
                </div>
              )}
            />
          }
        />
      </FormGroup>

      {isEnabled && (
        <Grid container spacing={4}>
          <Controller control={control} name="triggerEvents.automatic.itemInCart" />
          <Grid item xs={6} lg={7} direction="column" className={classes.triggerContent}>
            <Button
              onClick={setOpen.bind(this, true)}
              variant="outlined"
              className={classes.itemButton}
            >
              <Add /> Add Items
            </Button>
            <div>
              {selectedItems?.length
                ? selectedItems.map(item => (
                    <Controller
                      name="loyaltysettingsItems"
                      control={control}
                      render={props => (
                        <Chip
                          style={{ margin: '4px' }}
                          key={item.itemId}
                          label={item.label}
                          onDelete={e => handleDelete(item.itemId)}
                          className={classes.itemChip}
                        />
                      )}
                    />
                  ))
                : 'No items added'}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            lg={5}
            direction="column"
            alignContent="stretch"
            className={classes.triggerContent}
          >
            <RadioGroup value={selectedAction.id} onChange={onActionChange} row>
              {ITEM_IN_CART_ACTIONS.map(action => (
                <FormControlLabel
                  value={+action.id}
                  control={<Radio className={classes.radioBtn} />}
                  label={action.label}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      )}
      <Modal open={open} handleClose={handleClose} title="Add Items" maxWidth="md">
        <AddItemsModal
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          handleClose={handleClose}
          selectedBrands={selectedBrands}
        />
      </Modal>
    </div>
  );
}

export default ItemInCart;
