import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
    input: {
        '& .MuiInputBase-root': {
            display: 'block',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiFormControl-root': {
                width: '50%',
            },
        },        
    },
    list: {
        marginBottom: theme.spacing(3),
    },
});

export const MultipleSelectItemStyles = makeStyles(Styles);
