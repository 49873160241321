import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { generateStoreAbbreviation, uglifyId } from "../../../../../../../../utils";
import { ConnectionTable, Elements } from "../../../../../../../blocks";
import { useMutation, useQuery } from "@apollo/client";
import TOGGLE_LOCATION_LOCAL_FAVORITE from "../../../../../../../../apollo/mutations/menus/toggleLocationLocalFavorite";
import LOCATIONS_FOR_ITEM_INFO from "../../../../../../../../apollo/queries/menus/fetchLocationsForItemInfo";
import TOGGLE_ITEM_LOCATION_OLO from "../../../../../../../../apollo/mutations/menus/toggleItemLocationOlo";
import FETCH_LOCATIONS from "../../../../../../../../apollo/queries/fetchLocations";
import { FETCH_ITEM } from "../../../../../../../../apollo/queries";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function LocationItemTable({ itemId }) {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();
  const { control } = useForm();

  const { data, loading, error } = useQuery(FETCH_LOCATIONS);
  const [toggleLocalFavorite] = useMutation(TOGGLE_LOCATION_LOCAL_FAVORITE);
  const [toggleItemLocationOlo] = useMutation(TOGGLE_ITEM_LOCATION_OLO);

  const toggleLocationLocalFavorite = ({ locationId, itemId }) => {
    toggleLocalFavorite({
      variables: {
        input: {
          locationId,
          itemId
        }
      }
    }).then(r => setSnackbar({
      open: true,
      type: "success",
      text: "Local Favorite is updated!"
    }));
  };

  const toggleLocationItemAvailable = ({ locationIds, itemId, toggle, type, variables }) => {
    const input = {
      locationIds,
      itemId,
      toggle,
      type
    };
    if (toggle !== 'None'){
    setSnackbar({
      open: true,
      type: "info",
      text: "Item is updating!"
    });
    toggleItemLocationOlo({
      variables: {
        input
      },
      refetchQueries: [
        {
          query: LOCATIONS_FOR_ITEM_INFO,
          variables,
        },
      ],
    }).then(r => setSnackbar({
      open: true,
      type: "success",
      text: "Item is updated!"
    }));
    }
  };
  const allLocations = data?.viewer?.locationConnection?.edges.map(node => ({ id: node?.node?.id }));
  const statuses = [
    {
      value: 'None',
      label: "None",
    },
    {
      value: true,
      label: "On",
    },
    {
      value: false,
      label: "Off"
    }
  ];
  if (loading) return <div>Loading...</div>
  return (
    <div className={classes.root}>
      <ConnectionTable
        query={LOCATIONS_FOR_ITEM_INFO}
        title="Locations"
        selectableRows={true}
        fetchPolicy={'network-only'}
        customActionsTop={(data, {variables} ) => <Controller
          name="activeStatus"
          control={control}
          render={(
            { onChange, ref }
          ) => (
            <div>
              <p style={{fontSize: '1rem'}}>Available for all locations:</p>
            <Elements.ToggleButton
              ref={ref}
              options={statuses}
              value={'None'}
              handleToggleClick={(data) => {
                const type = "all";
                const graphQlItemId = uglifyId("Item", itemId);
                toggleLocationItemAvailable({
                  itemId: graphQlItemId,
                  toggle: data,
                  type,
                  locationIds: allLocations,
                  variables: variables
                });
              }}/>
            </div>
          )
          }
        />}
        initialQueryVariables={{
          first: 10,
          itemLocationFilter: [
            {
              itemId: {
                eq: parseInt(itemId)
              }
            }
          ],
          specificFields: ["label", "store_number"],
          locationItemFilter: [
            {
              itemId: {
                eq: parseInt(itemId)
              }
            }
          ]
        }}
        structureTable={(data) => {
          return data?.viewer?.locationConnection?.edges.map((location) => ({
            id: generateStoreAbbreviation(location.node),
            label: location.node.label,
            itemLocation: {
              itemId: uglifyId("Item", itemId),
              active: location?.node?.itemLocationConnection?.edges[0]?.node?.localFavorite,
              isAvailable: location?.node?.locationItemConnection?.edges[0]?.node?.isAvailable,
              isAvailableLocation: location?.node?.itemLocationConnection?.edges[0]?.node?.isAvailable,
              locationId: location.node.id
            },
            "address.city": location.node.address.city,
            "address.state.label": location.node.address.state.label,
            "menu.label": location.node.menu.label,
            locationId: location.node.locationId
          }));
        }
        }
        columns={[
          {
            title: "ID",
            field: "id",
            customSortField: "storeNumber",
            render: (id) => {
              return id.abbreviation;
            }
          },
          { title: "Name", field: "label" },
          { title: "City", field: "address.city" },
          { title: "State", field: "address.state.label", filter: true },
          {
            title: "Local Favorite",
            field: "itemLocation",
            filter: true,
            disableSort: true,
            render: (data, variables) => {
              return (
                <Elements.Switch checked={data?.active !== undefined ? data?.active : false}
                  handleSwitchChange={() => toggleLocationLocalFavorite({...data, variables})}/>
              );
            }
          },
          {
            title: "Available",
            field: "itemLocation",
            disableSort: true,
            filter: true,
            render: (data, variables) => {
              return (
                <Elements.Switch checked={data?.isAvailableLocation !== undefined ? data?.isAvailableLocation : true}
                  handleSwitchChange={(toggle) => toggleLocationItemAvailable({
                    locationIds: [{ id: data.locationId }], ...data,
                    toggle,
                    type: 'Location',
                    variables
                  })}/>
              );
            }
          }
        ]}
        /* eslint-disable-next-line no-unused-vars */
      />
    </div>
  );
}
LocationItemTable.propTypes = {
  rows: PropTypes.array.isRequired,
};
