import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Stepper, StepLabel, Step, Box } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';

import { FETCH_HANDOFF_STAGES } from '../../../../../../../apollo/queries';
import { SET_QUOTE_STEP } from '../../../../../../../apollo/mutations';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../../../constants';
import { Permission } from '../../../../../../blocks';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import OrderTrackerStep from './OrderTrackerStep';

const OrderTracker = ({
  accessToMutations,
  classes,
  handoffId,
  stageId,
  quoteId,
  setHasOrderTracker,
  access,
}) => {
  const { setSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState();

  const { data: handoffStagesData, loading, error } = useQuery(FETCH_HANDOFF_STAGES, {
    variables: {
      filter: {
        handoff: {
          id: {
            eq: handoffId,
          },
        },
      },
    },
    onCompleted: data => {
      const foundStepIndex = data?.viewer?.handoffStageConnection?.edges?.findIndex(
        edge => edge?.node?.stage?.id === stageId,
      );

      if (foundStepIndex > -1) {
        const foundStep = data?.viewer?.handoffStageConnection?.edges[foundStepIndex]?.node;
        setActiveStep({
          label: foundStep?.stage?.label,
          stageId: foundStep?.stageId,
          id: foundStep?.stage?.id,
          step: foundStepIndex,
        });
      }
    },
  });

  const [setQuoteStep, { loading: loadingStep, error: errorStep }] = useMutation(SET_QUOTE_STEP);

  const steps = useMemo(
    () =>
      handoffStagesData?.viewer?.handoffStageConnection?.edges?.map((edge, index) => ({
        label: edge.node?.stage?.label,
        stageId: edge.node?.stageId,
        id: edge.node?.stage?.id,
        step: index,
      })) || [],
    [handoffStagesData],
  );

  useEffect(() => {
    setHasOrderTracker(!!steps.length);
  }, [steps, setHasOrderTracker]);

  const handleStepClick = useCallback(
    step => {
      if (step.stageId !== activeStep?.stageId) {
        setSnackbar({
          text: 'Updating order tracker...',
          open: true,
          type: SNACKBAR_STATUS.INFO,
        });

        setQuoteStep({
          variables: {
            input: {
              id: quoteId,
              stageId: step.id,
            },
          },
        })
          .then(() => {
            setActiveStep(step);

            setSnackbar({
              text: 'Order Tracker updated.',
              open: true,
              type: SNACKBAR_STATUS.SUCCESS,
            });
          })
          .catch(e =>
            setSnackbar({
              text: e.message,
              open: true,
              type: SNACKBAR_STATUS.ERROR,
            }),
          );
      }
    },
    [activeStep],
  );

  if (loading) {
    return 'Loading...';
  }

  if (error || errorStep) {
    return 'Error';
  }

  return !!steps.length ? (
    <Grid container spacing={3} id="orderTracker">
      <Grid item xs={12}>
        <Grid container className={classes.titlePadding}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h2" style={{ marginBottom: '12px' }}>
              Order Tracker
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="h2">
              Click the icon to proceed to the next step.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <Permission
          access={Math.min(access, accessToMutations?.[MUTATION_NAME.SetQuoteStepMutationInput])}
        >
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Stepper
                style={{ width: '100%', padding: '24px 0' }}
                activeStep={activeStep?.step}
                alternativeLabel
                connector={null}
              >
                {steps.map(({ label, step, ...rest }) => (
                  <Step key={step}>
                    <StepLabel
                      StepIconComponent={OrderTrackerStep}
                      StepIconProps={{
                        label,
                        stageId: rest.stageId,
                        onClick: () => handleStepClick({ ...rest, step, label }),
                      }}
                    />
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Permission>
      </div>
    </Grid>
  ) : null;
};

export default OrderTracker;
