import { useState, useEffect } from 'react';
import LuxonUtils from '@date-io/luxon';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Button,
  DialogContent,
  DialogActions,
  Dialog,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateTime from 'luxon/src/datetime';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { Elements, Permission, PermissionField, SelectFactory } from '../../../../blocks';
import moment from 'moment-timezone';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../constants';

import convert from '../../../../../utils/convert';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { PERSON_JOBS } from '../../../../../apollo/queries/labor';

const useStyles = makeStyles(theme => ({
  inputLabelSelect: {
    marginLeft: '.2rem',
    top: '-8px',
    left: '8px',
    padding: '0 5px',
    zIndex: 1,
    background: '#fff',
  },
}));

const EditTimeshift = forwardRef(
  (
    {
      open,
      timeshift,
      timezone,
      startedFilter,
      finishedFilter,
      handleCloseEditTimeshiftModal,
      handleSubmit,
      updateTimeshift,
      control,
      errors,
      mutationLoading,
      accessToMutations,
      updateTimeshiftPermission,
    },
    ref,
  ) => {
    const { setSnackbar } = useSnackbar();
    const classes = useStyles();
    const [hours, setHours] = useState(0);
    const [selectedJob, setSelectedJob] = useState(null);

    const onSubmit = data => {
      const start = DateTime.fromFormat(
        `${data.started.date} ${data.started.timeHours}:${data.started.timeMinutes} ${data.started.timeMeridiem}`,
        'LL/dd/yyyy h:m a',
        { zone: timezone },
      );
      const end = DateTime.fromFormat(
        `${data.finished.date} ${data.finished.timeHours}:${data.finished.timeMinutes} ${data.finished.timeMeridiem}`,
        'LL/dd/yyyy h:m a',
        { zone: timezone },
      );

      if (
        start.toMillis() >
        DateTime.local()
          .setZone(timezone)
          .toMillis()
      ) {
        setSnackbar({
          open: true,
          text: 'Started Date/Time must not be in future.',
          type: SNACKBAR_STATUS.WARNING,
        });
        return;
      }

      if (start.toMillis() >= end.toMillis()) {
        setSnackbar({
          open: true,
          text: 'Started Date/Time must be before Finished.',
          type: SNACKBAR_STATUS.WARNING,
        });
        return;
      }

      updateTimeshift({
        variables: {
          input: {
            timeshiftId: timeshift.id,
            start: start.toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
            end: end.toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
            jobId: selectedJob,
            tip: convert.dollarsToCents(data.tip),
          },
          started: startedFilter,
          finished: finishedFilter,
        },
      });
    };

    const started = timeshift?.started?.time
      ? DateTime.fromSQL(timeshift.started.time, { zone: 'utc' }).setZone(
          timeshift.started.timezone,
        )
      : null;
    const finished = timeshift?.finished?.time
      ? DateTime.fromSQL(timeshift.finished.time, { zone: 'utc' }).setZone(
          timeshift.started.timezone,
        )
      : null;

    useEffect(() => {
      if (timeshift) {
        setSelectedJob(timeshift.jobId);
        setHours(
          timeshift?.started?.time &&
            timeshift?.finished?.time &&
            DateTime.fromSQL(timeshift.finished.time)
              .diff(DateTime.fromSQL(timeshift?.started?.time))
              .toObject().milliseconds,
        );
      }
    }, [timeshift]);

    return started || finished ? (
      <Dialog open={open} onClose={handleCloseEditTimeshiftModal} maxWidth="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container xs={12} spacing={3} alignItems={'center'}>
              <Grid item xs={12} container spacing={3}>
                <Permission
                  access={timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.started}
                >
                  <Grid xs={6}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <Controller
                        control={control}
                        name={'started.date'}
                        defaultValue={started.toFormat('LL/dd/yyyy')}
                        render={({ onChange, value }) => (
                          <Permission
                            access={
                              timeshift?.permissions?.person?.timeshiftConnection?.edges?.node
                                ?.started
                            }
                          >
                            <PermissionField
                              Component={KeyboardDatePicker}
                              fullWidth
                              variant="inline"
                              inputVariant="outlined"
                              format="LL/dd/yyyy"
                              margin="normal"
                              label="Started"
                              autoOk={true}
                              initialFocusedDate={DateTime.local()}
                              onChange={date => {
                                setHours(
                                  timeshift?.started?.time &&
                                    timeshift?.finished?.time &&
                                    DateTime.fromSQL(timeshift.finished.time)
                                      .diff(DateTime.fromSQL(timeshift?.started?.time))
                                      .toObject().milliseconds,
                                );
                                onChange(date.toFormat('LL/dd/yyyy'));
                              }}
                              value={value}
                              // Disable text input
                              TextFieldComponent={props => (
                                <TextField {...props} disabled={true} fullWidth />
                              )}
                            />
                          </Permission>
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={6}>
                    <Elements.TimePicker
                      access={
                        timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.started
                      }
                      control={control}
                      hoursName={'started.timeHours'}
                      minutesName={'started.timeMinutes'}
                      amPmName={'started.timeMeridiem'}
                      defaultValues={{
                        hours: started.toFormat('h'),
                        minutes: started.toFormat('mm'),
                        meridiem: started.toFormat('a').toLowerCase(),
                      }}
                    />
                  </Grid>
                </Permission>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Permission
                  access={
                    timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.finished
                  }
                >
                  <Grid xs={6}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <Controller
                        control={control}
                        name={'finished.date'}
                        defaultValue={finished.toFormat('LL/dd/yyyy')}
                        render={({ onChange, value }) => (
                          <Permission
                            access={
                              timeshift?.permissions?.person?.timeshiftConnection?.edges?.node
                                ?.finished
                            }
                          >
                            <PermissionField
                              Component={KeyboardDatePicker}
                              fullWidth
                              variant="inline"
                              inputVariant="outlined"
                              format="LL/dd/yyyy"
                              margin="normal"
                              label="Finished"
                              onChange={date => {
                                // Now we do some computing here
                                onChange(date.toFormat('LL/dd/yyyy'));
                              }}
                              autoOk={true}
                              value={value}
                              // Disable text input
                              TextFieldComponent={props => (
                                <TextField {...props} disabled={true} fullWidth />
                              )}
                            />
                          </Permission>
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Permission>
                <Permission
                  access={
                    timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.finished
                  }
                >
                  <Grid xs={6}>
                    <Elements.TimePicker
                      access={
                        timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.finished
                      }
                      control={control}
                      hoursName={'finished.timeHours'}
                      minutesName={'finished.timeMinutes'}
                      amPmName={'finished.timeMeridiem'}
                      defaultValues={{
                        hours: finished.toFormat('h'),
                        minutes: finished.toFormat('m'),
                        meridiem: finished.toFormat('a').toLowerCase(),
                      }}
                    />
                  </Grid>
                </Permission>
              </Grid>
              <Grid xs={12} container spacing={3} style={{ margin: '0.5rem 0' }}>
                <Grid item xs={2} style={{ flexDirection: 'column' }}>
                  <label>
                    TOTAL HRS <br />
                    {hours ? moment.utc(hours).format('HH:mm') : '0:00'}
                  </label>
                </Grid>

                <Permission
                  access={Math.min(
                    timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.job
                      ?.__typename,
                    timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.job?.label,
                    timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.job?.id,
                  )}
                >
                  <Grid xs={12} direction="column">
                    <PermissionField Component={FormControl} fullWidth createHelperText>
                      <Controller
                        name={'job'}
                        control={control}
                        defaultValue={{
                          label: timeshift.job,
                          value: timeshift.jobId,
                          id: timeshift.jobId,
                        }}
                        render={props => (
                          <Permission
                            access={Math.min(
                              timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.job
                                ?.__typename,
                              timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.job
                                ?.label,
                              timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.job
                                ?.id,
                            )}
                          >
                            <PermissionField
                              Component={SelectFactory}
                              customProps={props}
                              control={control}
                              placeholder={'Job'}
                              fullWidth={true}
                              query={PERSON_JOBS}
                              defaultValue={{
                                label: timeshift.job,
                                value: timeshift.jobId,
                                id: timeshift.jobId,
                              }}
                              filter={{
                                locationId: {
                                  eq: +atob(timeshift?.locationPerson?.location?.id).split(':')[1],
                                },
                                personId: { eq: timeshift?.locationPerson?.person?.personId },
                              }}
                              style={{ width: '100%' }}
                              multiple={false}
                              renderCheckBox={false}
                              onSelect={value => {
                                setSelectedJob(value.id);
                              }}
                              structureData={data => {
                                return data?.viewer?.personJobConnection?.edges?.map(
                                  (edge, index) => ({
                                    label: edge.node.job.label,
                                    value: edge.node.job.id,
                                    id: edge.node.job.id,
                                    index,
                                  }),
                                );
                              }}
                            />
                          </Permission>
                        )}
                      />
                    </PermissionField>
                  </Grid>
                </Permission>
                <Permission
                  access={timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.tip}
                >
                  <Grid item xs={5}>
                    <Controller
                      name={'tip'}
                      control={control}
                      defaultValue={timeshift.tip}
                      rules={{
                        required: true,
                        pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                      }}
                      render={({ onChange, value }) => (
                        <Permission
                          access={
                            timeshift?.permissions?.person?.timeshiftConnection?.edges?.node?.tip
                          }
                        >
                          <PermissionField
                            Component={TextField}
                            fullWidth
                            label={'Tip amount'}
                            variant={'outlined'}
                            onChange={event => {
                              onChange(event);
                            }}
                            helperTex={errors.tip && 'Tip must be a positive number.'}
                            value={value}
                            error={!!errors.tip}
                          />
                        </Permission>
                      )}
                    />
                  </Grid>
                </Permission>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditTimeshiftModal} variant="outlined">
              Cancel
            </Button>
            <Permission
              access={Math.min(
                updateTimeshiftPermission?.access,
                accessToMutations[MUTATION_NAME.UpdateTimeshiftMutationInput],
              )}
            >
              <div>
                <PermissionField createHelperText>
                  <Button type="submit" color="primary" disabled={mutationLoading} autoFocus>
                    Save
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </DialogActions>
        </form>
      </Dialog>
    ) : null;
  },
);

export default EditTimeshift;
