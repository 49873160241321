import { gql } from '@apollo/client';

const UPDATE_ITEM = gql`
  mutation updateItem($input: UpdateItemMutationInput!) {
    updateItem(input: $input) {
      clientMutationId
    }
  }
`;

export default UPDATE_ITEM;
