import { gql } from "@apollo/client";

const UPDATE_ITEM_MULTIPLE = gql`
  mutation updateItemMultiple($input: UpdateItemMultipleInput!) {
    updateItemMultiple(input: $input) {
      viewer {
      id
      }
    }
  }
`;

export default UPDATE_ITEM_MULTIPLE;
