import { convert, toFixed10 } from '../../../../utils';

export const getOvertimeValues = (timeshifts) => {
  let overtimeHours = 0;
  let overtimeFee = 0;
  let error;

  timeshifts.forEach((timeshift) => {
    if (timeshift.node.isFinished) {
      if (timeshift.node.overtime.error) {
        error = timeshift.node.overtime.error;
      }
      overtimeHours += timeshift.node.overtime.hours;
      overtimeFee += timeshift.node.overtime.fee;
    }
  });

  return error
    ? {
        hours: null,
        fee: null,
        error,
      }
    : {
        hours: toFixed10(overtimeHours, 2),
        fee: `${convert.centsToDollars(overtimeFee)} $`,
        error: null,
      };
};

export const getTotalWages = (timeshifts) => {
  let totalWages = 0;
  let error;

  timeshifts.forEach((timeshift) => {
    if (timeshift.node.isFinished) {
      if (timeshift.node.wages.error) {
        error = timeshift.node.wages.error;
      }
      totalWages += timeshift.node.wages.fee;
    }
  });

  return error
    ? {
        fee: null,
        error,
      }
    : {
        fee: `${convert.centsToDollars(totalWages)} $`,
        error: null,
      };
};
