import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  tableRowRoot: {
    transform: 'scale(1)',
    height: '100%',
  },
  actionTableCell: {
    textAlign: 'right',
  },

  iconButtonRoot: {
    padding: `${theme.spacing(1)}px`,
  },
  headerCell: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
  }
});

const DataTableStyles = makeStyles(Styles);

export default DataTableStyles;
