import LoyaltyCustomers from './LoyaltyCustomers';
import LoyaltySettings from './LoyaltySettings';
import LoyaltySubmissions from './LoyaltySubmissions';
import LoyaltyCustomer from './LoyaltyCustomers/LoyaltyCustomer';
import LoyaltyEvents from './LoyaltyEvents';
import LoyaltyRewards from './LoyaltyRewards';

export const Loyalty = {
  LoyaltyCustomers,
  LoyaltyCustomer,
  LoyaltySubmissions,
  LoyaltySettings,
  LoyaltyEvents,
  LoyaltyRewards,
};
