import { gql } from '@apollo/client';

const SAVE_WASTE_LOG = gql`
  mutation saveWasteLog($input: SaveWasteLogMutationInput!) {
    saveWasteLog(input: $input) {
      clientMutationId
    }
  }
`;

export default SAVE_WASTE_LOG;
