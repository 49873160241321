import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Divider,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment';

import { Elements, SelectFactory } from '../../../../../blocks';
import Switch from '../../../../../blocks/Elements/Switch';
import AvailableDays from '../../../../SystemSettings/Discounts/CreateDiscount/GeneralSettings/AvailableDays';
import AvailableDaysUpdate from '../../../../SystemSettings/Discounts/UpdateDiscount/GeneralSettings/AvailableDays';
import TimezoneSelection from '../../../../SystemSettings/Discounts/CreateDiscount/GeneralSettings/TimezoneSelection';
import TimezoneSelectionUpdate from '../../../../SystemSettings/Discounts/UpdateDiscount/GeneralSettings/TimezoneSelection';
import { BRANDS } from '../../../../../../apollo/queries/brands';
import ORIGINS from '../../../../../../apollo/queries/filters/origins';
import Triggers from '../Triggers';
import { isEmpty } from 'lodash';

function CreateLoyaltySettingsView({
  control,
  errors,
  data,
  loadingTimezone,
  submit,
  handleSubmit,
  setShowEndDate,
  showEndDate,
  handleClose,
  loyaltyUpdateData,
  selectedBrands,
  setSelectedBrands,
  selectedOrigins,
  setSelectedOrigins,
  selectedCoupontriggers,
  setSelectedCoupontriggers,
  selectedCouponaction,
  setSelectedCouponaction,
  selectedItems,
  setSelectedItems,
  createLoyaltyLoading,
  updateLoyaltysettingsLoading,
  itemInCartIsEnabled,
  setItemInCartIsEnabled,
}) {
  const [enableStartAndEndTime, setEnableStartAndEndTime] = useState(
    !!loyaltyUpdateData?.startTime.replace('/', '') || false,
  );

  return (
    <form onSubmit={handleSubmit(submit)} style={{ width: '100%' }}>
      <div>
        <Controller
          control={control}
          name="active"
          defaultValue={loyaltyUpdateData?.activeForChange || false}
          render={({ onChange, onBlur, ref }) => (
            <Elements.Switch
              defaultValue={loyaltyUpdateData?.activeForChange || false}
              checked={loyaltyUpdateData?.activeForChange || false}
              ref={ref}
              handleSwitchChange={(isChecked, b) => {
                onChange(isChecked);
              }}
              inputRef={ref}
              fullWidth
              label="Active"
              placeholder="Active"
            />
          )}
        />
      </div>
      <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
        <br />
        <Controller
          control={control}
          name="label"
          defaultValue={loyaltyUpdateData?.label || ''}
          render={({ onChange, onBlur, ref }) => (
            <TextField
              defaultValue={loyaltyUpdateData?.label || ''}
              onChange={e => onChange(e.target.value)}
              inputRef={ref}
              fullWidth
              label="Rule Name"
              required
              placeholder="Rule Name"
            />
          )}
        />

        <Controller
          control={control}
          name="multiplier"
          defaultValue={loyaltyUpdateData?.multiplier || null}
          render={({ onChange, onBlur, ref }) => (
            <TextField
              inputRef={ref}
              onChange={e => onChange(e.target.value)}
              fullWidth
              defaultValue={loyaltyUpdateData?.multiplier || null}
              label="Multiplier"
              inputProps={{
                step: '0.5',
                min: '0.5',
              }}
              required
              type="number"
              placeholder="Multiplier"
            />
          )}
        />

        <Controller
          control={control}
          name="brands"
          id="brands"
          defaultValue={selectedBrands}
          rules={{
            validate: {
              required: values => !isEmpty(values),
            },
          }}
          render={props => (
            <SelectFactory
              customProps={props}
              id="brands"
              name="brands"
              label={'Brands'}
              placeholder={'Brands'}
              query={BRANDS}
              defaultValue={selectedBrands}
              error={!!errors?.brands}
              helperText={errors?.brands?.type === 'required' && 'At least 1 brand is required.'}
              structureData={data => {
                return (
                  data?.viewer?.brandConnection?.edges.map((brand, index) => {
                    return {
                      id: brand?.node?.brandId,
                      label: brand?.node?.label,
                      index,
                    };
                  }) || []
                );
              }}
              onSelect={value => {
                setSelectedBrands(value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          rules={{
            validate: {
              required: values => !isEmpty(values),
            },
          }}
          name="origins"
          id="origins"
          defaultValue={selectedOrigins}
          render={props => (
            <SelectFactory
              customProps={props}
              id="origins"
              name="origins"
              label={'Channels'}
              placeholder={'Channels'}
              query={ORIGINS}
              filter={{ originId: { in: [1, 24, 27] } }}
              defaultValue={selectedOrigins}
              helperText={errors?.origins?.type === 'required' && 'At least 1 channel is required.'}
              error={!!errors?.origins}
              structureData={data => {
                return data?.viewer?.originConnection?.edges.map((origin, index) => {
                  return {
                    id: origin?.node?.originId,
                    label: origin?.node?.label,
                    index,
                  };
                });
              }}
              onSelect={value => {
                setSelectedOrigins(value);
              }}
            />
          )}
        />
      </div>
      <h2>Date &amp; Time Settings</h2>
      <div style={{ display: 'flex', gap: '2rem' }}>
        <Elements.DatePicker
          control={control}
          label="Start Date"
          name="startDate"
          format={'yyyy-MM-dd'}
          defaultValue={loyaltyUpdateData?.startDate || Date.now()}
          required
        />
        <div style={{ minWidth: '10rem' }}>
          <FormControl variant="outlined" style={{ minWidth: '10rem' }}>
            <InputLabel htmlFor="outlined-age-native-simple">End Date</InputLabel>
            <Select
              style={{ minWidth: '10rem' }}
              onChange={(e, v) => setShowEndDate(e.target.value)}
              label="End Date"
              defaultValue={!!loyaltyUpdateData?.endDate.replace('/', '') || false}
            >
              <MenuItem value={false}>None</MenuItem>
              <MenuItem value={true}>By</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Elements.DatePicker
            label="End Date"
            disabled={!showEndDate}
            control={control}
            required
            name="endDate"
            format={'yyyy-MM-dd'}
            defaultValue={loyaltyUpdateData?.endDate || ''}
          />
        </div>
      </div>
      <br />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <FormGroup row={true}>
            <FormControlLabel
              label="Available everyday on specific time"
              labelPlacement="start"
              style={{ marginLeft: '0' }}
              control={
                <Controller
                  defaultValue={enableStartAndEndTime}
                  name="enableTimeSelection"
                  control={control}
                  render={props => (
                    <div style={{ marginLeft: '1rem' }}>
                      <Switch
                        onChange={e => {
                          props.onChange(e.target.checked);
                        }}
                        handleSwitchChange={e => {
                          props.onChange(e);
                          setEnableStartAndEndTime(e);
                        }}
                        checked={props.value}
                      />
                    </div>
                  )}
                />
              }
            />
          </FormGroup>
        </div>
        <div style={{ flexGrow: '1', marginLeft: '5rem' }}>
          <Elements.TimePicker
            control={control}
            hoursName="startTimeHours"
            minutesName="startTimeMinutes"
            amPmName="startTimeType"
            required
            disabled={!enableStartAndEndTime}
            defaultValues={{
              hours:
                loyaltyUpdateData && loyaltyUpdateData.startTime.replace('/', '')
                  ? moment(loyaltyUpdateData.startTime.split(':')[0], 'h')
                      .format('LT')
                      .split(':')[0]
                  : 12,
              minutes:
                loyaltyUpdateData && loyaltyUpdateData.startTime.replace('/', '')
                  ? +moment(loyaltyUpdateData.startTime.split(':')[1]).format('mm')
                  : 0,
              meridiem:
                loyaltyUpdateData && loyaltyUpdateData.startTime.replace('/', '')
                  ? moment(loyaltyUpdateData.startTime, 'h')
                      .format('LT')
                      .split(' ')[1]
                      .toLowerCase()
                  : 'am',
            }}
          />
        </div>
        <div style={{ marginRight: '1rem' }}>
          <h3>to</h3>
        </div>
        <div style={{ flexGrow: '1' }}>
          <Elements.TimePicker
            control={control}
            hoursName="endTimeHours"
            minutesName="endTimeMinutes"
            amPmName="endTimeType"
            disabled={!enableStartAndEndTime}
            required
            defaultValues={{
              hours:
                loyaltyUpdateData && loyaltyUpdateData.endTime.replace('/', '')
                  ? moment(loyaltyUpdateData.endTime.split(':')[0], 'h')
                      .format('LT')
                      .split(':')[0]
                  : 12,
              minutes:
                loyaltyUpdateData && loyaltyUpdateData.endTime.replace('/', '')
                  ? +moment(loyaltyUpdateData.endTime.split(':')[1]).format('mm')
                  : 0,
              meridiem:
                loyaltyUpdateData && loyaltyUpdateData.endTime.replace('/', '')
                  ? moment(loyaltyUpdateData.endTime, 'h')
                      .format('LT')
                      .split(' ')[1]
                      .toLowerCase()
                  : 'am',
            }}
          />
        </div>
      </div>
      <div>
        {!loadingTimezone && !loyaltyUpdateData && (
          <TimezoneSelection
            control={control}
            name="timezoneId"
            timezones={data?.viewer?.timezoneConnection}
          />
        )}
        {loyaltyUpdateData && !loadingTimezone && (
          <TimezoneSelectionUpdate
            control={control}
            name="timezoneId"
            timezones={data?.viewer?.timezoneConnection}
            coupon={loyaltyUpdateData}
          />
        )}
      </div>
      <div>
        {loyaltyUpdateData ? (
          <AvailableDaysUpdate
            control={control}
            coupon={loyaltyUpdateData}
            connectionName="weekdayConnection"
          />
        ) : (
          <AvailableDays control={control} />
        )}
      </div>
      <Divider />
      <div>
        <Triggers
          control={control}
          selectedCoupontriggers={selectedCoupontriggers}
          setSelectedCoupontriggers={setSelectedCoupontriggers}
          selectedCouponaction={selectedCouponaction}
          setSelectedCouponaction={setSelectedCouponaction}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectedBrands={selectedBrands}
          itemInCartIsEnabled={itemInCartIsEnabled}
          setItemInCartIsEnabled={setItemInCartIsEnabled}
        />
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          style={{ marginLeft: '1rem' }}
          type="submit"
          disabled={createLoyaltyLoading || updateLoyaltysettingsLoading}
        >
          Save
        </Button>
      </div>
    </form>
  );
}

export default CreateLoyaltySettingsView;
