import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { SelectFactory } from '../../../../../../../../blocks';
import CATEGORIES_AND_MENUS from '../../../../../../../../../apollo/queries/discounts/categoriesAndMenus';
import OrderCategoryStyles from './styles';

function OrderCategory({ control, errors }) {
  const classes = OrderCategoryStyles();

  return (
    <Grid item xs={10} className={classes.row}>
      <Controller
        control={control}
        name={'triggerEvents.automatic.orderCategory.categoryIds'}
        defaultValue={[]}
        render={({ value, onChange }) => {
          return (
            <SelectFactory
              id="categories"
              label={'Category'}
              placeholder={'Category'}
              query={CATEGORIES_AND_MENUS}
              error={!!errors?.categories}
              multiple={true}
              control={control}
              renderCheckBox
              disableCloseOnSelect={true}
              fetchOnEachOpen={false}
              fetchPolicy="cache-first"
              filter={{ category: { categorytypeId: { nin: [0, 9] } } }}
              structureData={data => {
                const menuCategoryData = data?.viewer?.menuCategoryConnection?.edges?.map(
                  (menuCategory, index) => {
                    return {
                      categoryId: menuCategory?.node?.category?.id,
                      categoryLabel: menuCategory?.node?.category?.label,
                      menuLabel: menuCategory?.node?.menu?.label,
                      id: menuCategory?.node?.category?.categoryId,
                      index,
                    };
                  },
                );

                // Iterate over all menus in which the category is contained and update the label
                const categoryData = [];
                menuCategoryData?.map(menuCategory => {
                  const existingIdIndex = categoryData.findIndex(
                    category => category?.categoryId === menuCategory.categoryId,
                  );

                  if (existingIdIndex >= 0) {
                    const oldSplitLabel = categoryData[existingIdIndex].label.split('(');
                    const newLabel = `${oldSplitLabel[0].trim()} (${
                      menuCategory.menuLabel
                    }, ${oldSplitLabel[1].trim()}`;

                    categoryData[existingIdIndex] = {
                      ...categoryData[existingIdIndex],
                      label: newLabel,
                    };
                  } else {
                    categoryData.push({
                      categoryId: menuCategory.categoryId,
                      id: menuCategory.categoryId,
                      label: `${menuCategory.categoryLabel} (${menuCategory.menuLabel})`,
                      index: categoryData.length - 1,
                    });
                  }
                });

                return categoryData;
              }}
              onSelect={onChange}
            />
          );
        }}
      />
    </Grid>
  );
}

export default OrderCategory;
