import React, { useState } from 'react';
import 'date-fns';
import { FormControlLabel, Grid } from '@material-ui/core';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Elements } from '../../../../../../blocks';
import { Controller } from 'react-hook-form';

function StartEndDatePicker({ control }) {
  const [displayEndDate, setDisplayEndDate] = useState(false);
  return (
    <div style={{ marginBottom: '25px' }}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Grid container style={{ flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <Controller
              control={control}
              name="time.start.date"
              render={({ onChange, value }) => (
                <DatePicker
                  variant="inline"
                  inputVariant="outlined"
                  format="LL/dd/yyyy"
                  style={{ width: '230px' }}
                  margin="normal"
                  label="Start Date"
                  autoOk={true}
                  initialFocusedDate={DateTime.local()}
                  onChange={date => {
                    onChange(date.toJSDate());
                  }}
                  value={value}
                  disablePast={true}
                />
              )}
            />
            <Elements.TimePicker
              control={control}
              hoursName="time.start.time.hours"
              minutesName="time.start.time.minutes"
              amPmName="time.start.time.type"
            />
          </div>

          <div>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Elements.Switch
                  handleSwitchChange={isChecked => {
                    setDisplayEndDate(isChecked);
                  }}
                />
              }
              label="Enable end date"
            />
          </div>
          <div style={{ display: 'flex' }}>
            {displayEndDate && (
              <>
                <Controller
                  control={control}
                  name="time.end.date"
                  render={({ onChange, value }) => (
                    <DatePicker
                      variant="inline"
                      inputVariant="outlined"
                      format="LL/dd/yyyy"
                      margin="normal"
                      style={{ width: '230px' }}
                      label="End Date"
                      autoOk={true}
                      initialFocusedDate={DateTime.local()}
                      onChange={date => {
                        if (date) {
                          onChange(date.toJSDate());
                          return;
                        }
                        onChange(null);
                      }}
                      value={value}
                      disablePast={true}
                    />
                  )}
                />

                <Elements.TimePicker
                  control={control}
                  hoursName="time.end.time.hours"
                  minutesName="time.end.time.minutes"
                  amPmName="time.end.time.type"
                />
              </>
            )}
          </div>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default StartEndDatePicker;
