import { gql } from '@apollo/client';
import { viewerFragment } from '../../fragments';

const TOGGLE_LOCATION_HANDOFF_CATEGORY = gql`
  mutation toggleLocationHandoffCategory($input: ToggleLocationHandoffCategoryMutationInput!) {
    toggleLocationHandoffCategory(input: $input) {
      viewer {
        ...ViewerData
      }
    }
  }
  ${viewerFragment.viewer}
`;

export default TOGGLE_LOCATION_HANDOFF_CATEGORY;
