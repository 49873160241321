import { gql } from '@apollo/client';

const FETCH_TIER_LIST = gql`
  query fetchTierList {
    viewer {
      id
      tierConnection {
        totalCount
        edges {
          node {
            id
            tierId
            label
            locationConnection {
              edges {
                node {
                  label
                  id
                  locationId
                  storeNumber
                  tierId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_TIER_LIST;
