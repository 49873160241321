import { gql } from '@apollo/client';

const REMOVE_INVENTORYITEM_FROM_WASTE_LOG = gql`
  mutation removeInventoryItemFromWasteLog($input: RemoveInventoryitemFromWasteLogInput!) {
    removeInventoryitemFromWasteLog(input: $input) {
      inventoryitem {
        id
        inventoryitemCustomCategory {
          id
          inventoryitemCustomCategoryId
          label
        }
        partOfWastelog
      }
    }
  }
`;

export default REMOVE_INVENTORYITEM_FROM_WASTE_LOG;
