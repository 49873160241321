import { gql } from '@apollo/client';

const CREATE_GENERAL_LEDGER_ACCOUNT = gql`
  mutation CREATE_GENERAL_LEDGER_ACCOUNT($input: CreateGeneralledgeraccountInput!) {
    createGeneralledgeraccount(input: $input) {
      viewer {
        id
      }
      generalledgeraccount {
        id
      }
    }
  }
`;

export default CREATE_GENERAL_LEDGER_ACCOUNT;
