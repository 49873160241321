import { gql } from "@apollo/client";

const LOCATION_ITEM = gql`
  query fetchLocationItem(
    $filter: [LocationItemFilter]
  ) {
    viewer {
      id
         locationItemConnection(filter: $filter) {
        edges {
          node {
            id
            price 
          }
      }
      }
  }
  }
`;

export default LOCATION_ITEM;
