import { gql } from '@apollo/client';

const storage = gql`
  fragment StorageData on Storage {
    id
    storageId
    status
    label
  }
`;

const inventoryItem = gql`
  fragment InventoryItemData on Inventoryitem {
    id
    inventoryitemId
    measureId
    inventoryitemcategoryId
    label
    description
    inventoryStatus
    cost
    calorie
    type
    recipe {
      recipetype {
        id
        recipetypeId
        label
      }
    }
    inventoryitemMeasureConnection(filter: { isForCount: { eq: true } }) {
      edges {
        node {
          id
          measureId
          isForCount
          measure {
            label
            id
            abbreviation
            measureId
            thresholdValue
          }
        }
      }
    }
    measure {
      measureId
      id
      label
      abbreviation
      thresholdValue
    }
  }
`;

const locationStorageInventoryItem = gql`
  fragment LocationStorageInventoryItem on LocationStorageInventoryitem {
    id
    locationStorageInventoryitemId
    locationStoregeId
    inventoryitemId
    orderKey
    inventoryitem {
      ...InventoryItemData
    }
  }
  ${inventoryItem}
`;

const locationStorage = gql`
  fragment LocationStorageData on LocationStorage {
    id
    locationStorageId
    storageId
    locationId
    status
    storagestatusId
    customLabel
    created
    storage {
      ...StorageData
    }
    location {
      inventoryCountStatus
      inventorycount {
        inventorycountId
        inventorycountLocationStorageInventoryitemConnection {
          edges {
            node {
              locationStorageInventoryitemId
              measureId
              quantity
              locationStorageInventoryitem {
                inventoryitemId
                inventoryitem {
                  label
                }
              }
              measure {
                abbreviation
                thresholdValue
              }
            }
          }
        }
      }
    }
    locationStorageInventoryitemConnection {
      edges {
        node {
          ...LocationStorageInventoryItem
        }
      }
    }
  }

  ${storage}
  ${inventoryItem}
  ${locationStorageInventoryItem}
`;

const locationStorageFragment = {
  storage,
  inventoryItem,
  locationStorageInventoryItem,
  locationStorage,
};

export default locationStorageFragment;
