import React, { useState } from "react";
import GenerateCode from "../GenerateCode";

function ManualView({ control, errors, codeGenerationType }) {
  const [eventType, setEventType] = useState(1); // 1 is the first manual event saved in DB
  const renderManualTriggers = () => {
    switch (eventType) {
      case 1:
        return (
          <GenerateCode errors={errors} control={control} codeGenerationType={codeGenerationType} />
        );
      default:
        return "";
    }
  };

  return (
    <div>
      {renderManualTriggers()}
    </div>
  );
}

export default ManualView;
