import React, { useState } from 'react';
import SelectFactory from '../../../../../../../blocks/SelectFactory';
import { useQuery } from '@apollo/client';
import { LIST_CATEGORY_SUBCATEGORY } from '../../../../../../../../apollo/queries';
import { Button } from '@material-ui/core';

function CategorySelect({
  control,
  name = 'discountDetails.category',
  errors,
  handleClose,
  setSelectedSubcategoriesAction,
  selectedSubcategoriesAction,
  multiple,
}) {
  const [tempSelected, setTempSelected] = useState(selectedSubcategoriesAction);
  const save = () => {
    setSelectedSubcategoriesAction(tempSelected);
    handleClose();
  };
  return (
    <div style={{ width: '100%' }}>
      <SelectFactory
        name="discountDetails.category.subcategoryItemInCart"
        label={'Subcategory List'}
        placeholder={'Select'}
        fullWidth={true}
        defaultValue={selectedSubcategoriesAction}
        query={LIST_CATEGORY_SUBCATEGORY}
        style={{ width: '100%' }}
        multiple={multiple}
        groupBy={option => option.groupBy}
        renderCheckBox
        disableSearchReset
        disableCloseOnSelect
        onSelect={data => {
          setTempSelected(data);
        }}
        structureData={data => {
          return data?.viewer?.subcategoryConnection?.edges?.map((subcategory, index) => ({
            label: `${subcategory.node.label} ID_${subcategory.node.subcategoryId} `,
            groupBy: subcategory.node.category.label,
            value: subcategory.node.id,
            id: subcategory.node.id,
            index,
          }));
        }}
      />
      <br />
      <Button onClick={save}>Save</Button>
    </div>
  );
}

export default CategorySelect;
