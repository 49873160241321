import { makeStyles } from '@material-ui/core';
const Styles = (theme) => ({
  flexDirection: {
    flexDirection: 'column',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  borderBottom: {
    borderBottom: `2px solid ${theme.hrColor}`,
  },
  justifyContent: {
    justifyContent: 'flex-end',
  },
  boldLetters: {
    fontWeight: 'bold',
    fontSize: '21px',
  },
  centerContent: {
    justifyContent: 'center',
  },
  heading: {
    margin: theme.headingMargins,
    marginTop: 0,
  },
  fullWidth: {
    width: '100%',
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2),
  },
  titlePadding: {
    paddingBottom: theme.spacing(2.5),
    margin: theme.headingMargins,
  },
  paymentInfoHolder: {
    width: '100%',
  },
  paymentInfoButtonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
});
const OrderInfoStyles = makeStyles(Styles);
export default OrderInfoStyles;
