import React from "react";
import { ConnectionTable } from "../../../../../blocks";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  overtimesListTable: {
    "& th": {
      padding: "6px 9px 6px 3px",
      minWidth: "170px",
      position: "static",
    },
  },
}));

const OvertimeListView = ({
  query,
  createOvertimeRule,
  handleEditOvertimerule,
  handleRemoveOvertime,
  isDeleting,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.overtimesListTable}>
      <ConnectionTable
        query={query}
        title="Overtime"
        // onTableRowClick={(row) =>
        //   history.push(`${history.location.pathname}/${row.locationId}/generalInfo`)
        // }
        structureTable={(data) =>
          data?.viewer?.overtimeruleConnection?.edges.map((overtimerule) => {
            return {
              id: overtimerule.node.id,
              name: overtimerule.node.name,
              weeklyOtStart: overtimerule.node.weeklyOtStart,
              weeklyOtMultiplier: overtimerule.node.weeklyOtMultiplier,
              dailyOtStart: overtimerule.node.dailyOtStart,
              dailyOtMultiplier: overtimerule.node.dailyOtMultiplier,
              secondDailyOtStart: overtimerule.node.secondDailyOtStart,
              secondDailyOtMultiplier:
                overtimerule.node.secondDailyOtMultiplier,
              seventhDayWhMultiplier: overtimerule.node.seventhDayWhMultiplier,
              seventhDayOtStart: overtimerule.node.seventhDayOtStart,
              seventhDayOtMultiplier: overtimerule.node.seventhDayOtMultiplier,
              resident: overtimerule.node.resident,
              stateConnection: overtimerule.node.stateConnection.edges,
            };
          })
        }
        columns={[
          {
            title: "Rule Name",
            field: "name",
          },
          { title: "Weekly OT start after", field: "weeklyOtStart" },
          {
            title: "Weekly OT multiplier",
            field: "weeklyOtMultiplier",
          },
          {
            title: "Daily OT start after",
            field: "dailyOtStart",
          },
          { title: "Daily OT multiplier", field: "dailyOtMultiplier" },
          { title: "2nd Daily OT start after", field: "secondDailyOtStart" },
          {
            title: "2nd Daily OT multiplier",
            field: "secondDailyOtMultiplier",
          },
          {
            title: "7th day working hours multiplier",
            field: "seventhDayWhMultiplier",
          },
          { title: "7th day OT starts after", field: "seventhDayOtStart" },
          { title: "7th day OT multiplier", field: "seventhDayOtMultiplier" },
          {
            title: "Resident",
            field: "resident",
            enablePropagation: true,
            render: (resident) => (resident ? "YES" : "NO"),
          },
          {
            title: "States",
            field: "stateConnection",
            enablePropagation: true,
            render: (states) => {
              let stateList = "";
              states.forEach((state, index) => {
                if (states.length - 1 !== index) {
                  stateList = stateList.concat(`${state.node.abbreviation}, `);
                } else {
                  stateList = stateList.concat(state.node.abbreviation);
                }
              });
              return stateList;
            },
          },
        ]}
        /* eslint-disable-next-line no-unused-vars */
        customActionsTop={(data, query) => (
          <Button onClick={createOvertimeRule} variant="contained">
            New Overtime Rule
          </Button>
        )}
        onTableRowClick={handleEditOvertimerule}
        handleDeleteRow={(row) => handleRemoveOvertime(row.id)}
        deleteConfirmationText="Are you sure you want to delete this overtime rule?"
        useConfirmationModalForDelete
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default OvertimeListView;
