import { gql } from '@apollo/client';
import { quoteFragment } from '../fragments';

const ORDER_INFO = gql`
  query quote($id: ID!) {
    viewer {
      id
      quote(id: $id, fieldGroup: 41) {
        permissions
        coupons {
          id
          label
          description
          code
        }
        stage { 
          id
        }
        ...QuoteData
      }
    }
  }
  ${quoteFragment.quote}
`;
export default ORDER_INFO;
