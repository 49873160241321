import { useMutation } from '@apollo/client';
import { Box, Divider, Button } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CREATE_RECIPE, UPDATE_RECIPE } from '../../../../../../apollo/mutations';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { formatData, formatNumberStringToFloat } from '../../../../helpers';
import BasicInfo from './BasicInfo';
import Equipment from './Equipment';
import InventoryItems from './InventoryItems';
import Instructions from './Instructions';
import { groupBy, isEmpty, sortBy, uniqBy } from 'lodash-es';
import Conversions from './Conversions';
import FactInformation from './FactInformation';
import LocationAvailability from './LocationAvailability';

export const initialInventoryItem = {
  measureId: '',
  inventoryitemId: '',
  count: '',
  preparation: '',
};

const RecipeForm = ({
  handleCancel,
  recipe,
  measureOptions,
  recipeTypeOptions,
  statusOptions,
  brandOptions,
}) => {
  const {
    register,
    unregister,
    errors,
    control,
    handleSubmit,
    reset,
    getValues,
    setError,
    setValue,
  } = useForm({
    name: '',
    cost: '',
    recipeType: '',
    saleCategory: '',
    yieldQuantity: '',
    yieldMeasure: '',
    storageAndHoldingProcedures: '',
    serviceStandards: '',
    preparationNotes: '',
    inventoryItems: [initialInventoryItem],
    equipments: [],
    shelfLife: '',
    prepTime: '',
    flavor: '',
    appearance: '',
    consistency: '',
    temperature: '',
    showOnLocation: '',
    allowAutomaticStockToggle: '',
  });

  const { setSnackbar } = useSnackbar();

  const [inStock, setInStock] = useState('not-changed');
  const [locations, setLocations] = useState([]);

  const [mainMeasure, setMainMeasure] = useState({
    label: 'Yield UOM',
    quantity: 1,
  });
  const [duplicateMeasureIds, setDuplicateMeasureIds] = useState([]);
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState();

  const currentImages = useMemo(
    () => recipe?.recipeimageConnection?.edges?.map(edge => edge.node) || [],
    [recipe],
  );

  const currentLogoUrl = useMemo(() => recipe?.logoUrl || null, [recipe]);

  const [createRecipe, { loading }] = useMutation(CREATE_RECIPE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Recipe created',
        open: true,
      });

      handleCancel();
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createRecipe?.viewer),
        fields: {
          recipeConnection() {
            return {
              __typename: 'RecipeEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [updateRecipe, { loading: updating }] = useMutation(UPDATE_RECIPE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Recipe updated',
        open: true,
      });

      handleCancel();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const handleLocationsChange = locationState => {
    const { locationId } = locationState;
    if (!!locations.filter(loc => loc.locationId === locationId)?.length) {
      setLocations([...locations.filter(l => l.locationId !== locationId)]);
      return;
    }
    setLocations([...locations.filter(l => l.locationId !== locationId), locationState]);
  };

  const onSubmit = useCallback(
    formData => {
      let conversionsForCountNumber = 0;

      const conversionMeasures = formatData({
        formData,
        label: 'conversion',
        mapFunction: value => {
          if (value.isForCount) {
            conversionsForCountNumber += 1;
          }

          return {
            measureId: value.measureId,
            isForCount: value.isForCount,
            quantity: formatNumberStringToFloat(value.conversionMath),
          };
        },
      });

      if (isEmpty(conversionMeasures)) {
        return;
      }

      if (uniqBy(conversionMeasures, 'measureId').length !== conversionMeasures.length) {
        const notUniqConversions = Object.entries(groupBy(conversionMeasures, 'measureId')).filter(
          ([, value]) => value.length > 1,
        );

        setDuplicateMeasureIds(notUniqConversions.map(([measureId]) => +measureId));
        return;
      } else {
        setDuplicateMeasureIds([]);
      }

      if (!conversionsForCountNumber) {
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          open: true,
          text: 'Check at least one conversion to be included in inventory',
        });

        return;
      } else if (conversionsForCountNumber > 3) {
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          open: true,
          text: `Maximum of 3 conversions can be included in inventory, please remove ${conversionsForCountNumber -
            3}`,
        });

        return;
      }

      const components = formData.inventoryItems.map((value, index) => ({
        measureId: value.measureId,
        quantity: formatNumberStringToFloat(value.count),
        componentId: value.inventoryitemId,
        orderKey: index,
        preparation: value.preparation,
      }));

      if (isEmpty(components)) {
        return;
      }

      if (formData.recipeType === 1 && !formData.costCategory) {
        setError('costCategory', 'required');
        return;
      }

      if (formData.recipeType === 2 && !formData.saleCategory) {
        setError('saleCategory', 'required');
        return;
      }

      const equipments =
        formData.equipments?.map((value, index) => ({
          equipmentId: value.equipmentId.id,
          quantity: formatNumberStringToFloat(value.count),
          orderKey: index,
          note: value.note,
        })) || [];

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: recipe?.recipeId ? 'Updating Recipe...' : 'Creating Recipe...',
        open: true,
      });

      const addedImages = [];
      const oldImageRecipeImageIds = [];

      images.forEach(image => {
        if (!image.recipeimageId) {
          addedImages.push(image);
        } else {
          oldImageRecipeImageIds.push(image.recipeimageId);
        }
      });

      const removedImages = currentImages.filter(
        currImage => !oldImageRecipeImageIds.includes(currImage.recipeimageId),
      );

      let recipeLogo = currentLogoUrl;

      if (currentLogoUrl && !logo) {
        recipeLogo = { toRemove: true };
      } else if (logo) {
        recipeLogo = {
          name: logo.name,
          type: logo.type,
          encoded: logo.encoded,
        };
      }

      const input = {
        measureId: formData.yieldMeasureId,
        cost: formData.cost !== '' ? formData.cost : null,
        showOnLocation: formData.showOnLocation,
        allowAutomaticStockToggle: formData.allowAutomaticStockToggle,
        shelfLife: formData.shelfLife,
        prepTime: formData.prepTime,
        label: formData.name,
        flavor: formData.flavor,
        appearance: formData.appearance,
        consistency: formData.consistency,
        temperature: formData.temperature,
        recipetypeId: formData.recipeType,
        inventoryitemcategoryId:
          formData.recipeType === 1 ? formData.costCategory.id : formData.saleCategory.id,
        yieldQty: formatNumberStringToFloat(formData.yieldQuantity),
        equipment: equipments,
        components,
        conversionMeasures,
        preparationNotes: formData.preparationNotes,
        serviceStandards: formData.serviceStandards,
        storageAndHoldingProcedures: formData.storageAndHoldingProcedures,
        recipeImages: [
          ...addedImages.map(({ type, name, encoded }) => ({ type, name, encoded })),
          ...removedImages.map(({ recipeimageId }) => ({
            recipeimageId,
            toRemove: true,
          })),
        ],
        status: !!formData.status,
        brands: formData.brands.map(brand => ({ brandId: brand.value })),
        recipeLogo,
      };

      // check if inStock buttons where clicked.
      if (inStock !== 'not-changed') {
        input.inStock = inStock;
      } else {
        input.inStock = null;
      }

      // remove available because it is used just to present state to the user
      if (locations?.length) {
        input.locations = locations.map(location => ({
          locationId: location.locationId,
        }));
      } else {
        input.locations = [];
      }

      if (recipe?.recipeId) {
        updateRecipe({
          variables: {
            input: {
              ...input,
              recipeId: recipe.recipeId,
            },
          },
        });
      } else {
        createRecipe({
          variables: {
            input,
          },
        });
      }
    },
    [images, currentImages, currentLogoUrl, logo, inStock, locations],
  );

  useEffect(() => {
    if (recipe?.recipeId) {
      setMainMeasure({
        label: recipe.inventoryitem?.measure?.abbreviation,
        quantity: recipe.yieldQuantity,
      });

      setImages(
        recipe.recipeimageConnection?.edges?.map(imageEdge => ({
          url: imageEdge.node.url,
          recipeimageId: imageEdge.node.recipeimageId,
        })),
      );

      setLogo({ url: recipe.logoUrl });

      reset({
        name: recipe.inventoryitem?.label,
        recipeType: recipe?.recipetypeId,
        saleCategory:
          recipe?.recipetypeId === 2
            ? {
                id: recipe.inventoryitem?.inventoryitemcategoryId,
                label: recipe.inventoryitem?.inventoryitemcategory.label,
              }
            : '',
        costCategory:
          recipe?.recipetypeId === 1
            ? {
                id: recipe.inventoryitem?.inventoryitemcategoryId,
                label: recipe.inventoryitem?.inventoryitemcategory.label,
              }
            : '',
        showOnLocation: +recipe.inventoryitem?.showOnLocation,
        allowAutomaticStockToggle: +recipe.inventoryitem?.allowAutomaticStockToggle,
        yieldQuantity: recipe.yieldQuantity,
        yieldMeasureId: recipe.inventoryitem?.measureId,
        preparationNotes: recipe.preparationNotes,
        storageAndHoldingProcedures: recipe.storageAndHoldingProcedures,
        status: recipe.inventoryitem?.inventoryStatus ? '1' : '0',
        brands: recipe.inventoryitem?.brands?.map(brand => ({
          value: brand.brandId,
          label: brand.label,
        })),
        shelfLife: recipe.shelfLife,
        prepTime: recipe.prepTime,
        flavor: recipe.flavor,
        cost: recipe.inventoryitem?.cost,
        appearance: recipe.appearance,
        consistency: recipe.consistency,
        temperature: recipe.temperature,
        serviceStandards: recipe.serviceStandards,
        inventoryItems: sortBy(
          recipe?.components?.map(inventoryitem => ({
            measureId: inventoryitem?.measure?.measureId,
            count: inventoryitem?.count,
            preparation: inventoryitem?.preparation,
            order: inventoryitem?.orderKey,
            measureOptions: inventoryitem?.measures?.map(measure => ({
              value: measure?.measureId,
              abbreviation: measure?.measureAbbreviation,
              label: `${measure?.measureLabel} (${measure?.measureAbbreviation})`,
            })),
            inventoryitemId: inventoryitem?.componentId,
            label: inventoryitem?.name,
          })),
          'order',
        ) || [initialInventoryItem],
        equipments:
          sortBy(
            recipe?.equipment?.map(item => ({
              equipmentId: { id: item.equipmentId, label: item.name },
              count: item.count,
              order: item.orderKey,
              note: item.note,
            })),
            'order',
          ) || [],
      });
    }
  }, [recipe]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Box style={{ marginBottom: '12px' }}>
        <BasicInfo
          errors={errors}
          register={register}
          control={control}
          recipeTypeOptions={recipeTypeOptions}
          images={images}
          logo={logo}
          recipe={recipe}
          statusOptions={statusOptions}
          brandOptions={brandOptions}
          setLogo={setLogo}
          setValue={setValue}
          getValues={getValues}
          measureOptions={measureOptions}
          setMainMeasure={setMainMeasure}
          setImages={setImages}
        />
        <Divider />
        <Conversions
          duplicateMeasureIds={duplicateMeasureIds}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          unregister={unregister}
          mainMeasure={mainMeasure}
          recipe={recipe}
          register={register}
          measureOptions={measureOptions}
        />
        <Divider />
        <Equipment register={register} control={control} errors={errors} setValue={setValue} />
        <Divider />
        <InventoryItems
          register={register}
          recipe={recipe}
          control={control}
          getValues={getValues}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        <Divider />
        <Instructions register={register} />
        <Divider />
        <FactInformation register={register} />
        <Divider />
        <LocationAvailability
          inStock={inStock}
          setInStock={setInStock}
          register={register}
          handleLocationsChange={handleLocationsChange}
          locationIngredientId={recipe?.recipeId}
          myLocations={locations}
          control={control}
        />
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button onClick={handleCancel} style={{ marginRight: '8px' }} variant="outlined">
          Cancel
        </Button>
        <Button type="submit" disabled={loading || updating}>
          Save
        </Button>
      </Box>
    </form>
  );
};

export default RecipeForm;
