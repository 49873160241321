import { gql } from '@apollo/client';

const ADD_INVENTORYITEM_TO_WASTE_LOG = gql`
  mutation addInventoryItemToWasteLog($input: AddInventoryitemToWasteLogInput!) {
    addInventoryitemToWasteLog(input: $input) {
      inventoryitem {
        id
        partOfWastelog
        inventoryitemCustomCategory {
          id
          inventoryitemCustomCategoryId
          label
        }
      }
    }
  }
`;

export default ADD_INVENTORYITEM_TO_WASTE_LOG;
