import { gql } from '@apollo/client';

export const CREATE_VENDOR = gql`
  mutation CreateVendor($input: CreateVendorMutationInput!) {
    createVendor(input: $input) {
      viewer {
        id
      }
      vendor {
        vendorId
      }
    }
  }
`;
