import React, { useState, useEffect, useMemo } from 'react';
import { Button, Chip } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import Add from '@material-ui/icons/Add';

import { Modal } from '../../../../../../../../blocks';
import CategorySelect from '../../../../GeneralSettings/DiscountType/CategorySelect';
import AddItemsModal from '../../../../DiscountRestrictions/Items/AddItemsModal';
import {
  FETCH_ITEM_LIST,
  LIST_CATEGORY_SUBCATEGORY,
} from '../../../../../../../../../apollo/queries';
import { uglifyId } from '../../../../../../../../../utils';

function ItemFromSubcategoryAndItem({
  control,
  cartItemsRequired,
  setCartItemsRequired,
  selectedSubcategoriesTrigger,
  setSelectedSubcategoriesTrigger,
  couponTriggers,
}) {
  const [openSubcategory, setOpenSubcategory] = useState(false);
  const [openItem, setOpenItem] = useState(false);

  const [loadItems, { loading }] = useLazyQuery(FETCH_ITEM_LIST, {
    onCompleted: data => {
      setCartItemsRequired(
        data.viewer.itemConnection.edges.map(edge => ({
          id: edge.node.id,
          label: `${edge.node.label} - ID_${edge.node.itemId}`,
        }))[0],
      );
    },
    onError: err => {
      console.log(err, 'err...');
    },
  });

  const [loadSubcategories, { loadingSubcategories }] = useLazyQuery(LIST_CATEGORY_SUBCATEGORY, {
    onCompleted: data => {
      setSelectedSubcategoriesTrigger(
        data.viewer.subcategoryConnection.edges.map(edge => ({
          id: edge.node.id,
          label: `${edge.node.label} - ID_${edge.node.subcategoryId}`,
        }))[0],
      );
    },
    onError: err => {
      console.log(err, 'err...');
    },
    fetchPolicy: 'network-only',
  });

  const handleCloseSubcategory = () => {
    setOpenSubcategory(false);
  };

  const handleCloseItem = () => {
    setOpenItem(false);
  };

  const handleDeleteItem = itemId => {
    const newArr = cartItemsRequired?.length
      ? cartItemsRequired.filter(item => item.id !== itemId)
      : [];
    setCartItemsRequired(newArr);
  };

  const handleDeleteSubcategory = subcategoryId => {
    const newArr = selectedSubcategoriesTrigger?.length
      ? selectedSubcategoriesTrigger.filter(subcategory => subcategory.id !== subcategoryId)
      : [];
    setSelectedSubcategoriesTrigger(newArr);
  };

  useEffect(() => {
    if (couponTriggers.couponTriggerId === 10) {
      loadItems({
        variables: {
          filter: {
            id: {
              eq: uglifyId('Item', couponTriggers.values.itemId),
            },
          },
        },
      });

      loadSubcategories({
        variables: {
          filter: {
            id: {
              eq: uglifyId('Subcategory', couponTriggers.values.subcategoryId),
            },
          },
        },
      });
    }
  }, []);

  const renderChipItem = useMemo(() => {
    if (cartItemsRequired?.id) {
      return (
        <Chip
          style={{ margin: '4px' }}
          key={cartItemsRequired.id}
          label={cartItemsRequired.label}
          onDelete={e => handleDeleteItem(cartItemsRequired.id)}
        />
      );
    }
  }, [cartItemsRequired]);

  const renderChipSubcategory = useMemo(() => {
    if (selectedSubcategoriesTrigger?.id) {
      return (
        <Chip
          style={{ margin: '4px' }}
          key={selectedSubcategoriesTrigger.id}
          label={selectedSubcategoriesTrigger.label}
          onDelete={e => handleDeleteSubcategory(selectedSubcategoriesTrigger.id)}
        />
      );
    }
  }, [selectedSubcategoriesTrigger]);

  return (
    <>
      <div>
        <Controller
          control={control}
          name="triggerEvents.automatic.forSubcategoryAndItemInCart.subcategoryIds"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3>Selected Subcategories</h3>
          <div>
            <Button onClick={setOpenSubcategory.bind(this, true)} variant="outlined">
              <Add /> Add Subcategories
            </Button>
          </div>
        </div>
        <div>{renderChipSubcategory}</div>
        <Modal
          open={openSubcategory}
          handleClose={handleCloseSubcategory}
          title="Add Subcategories"
          maxWidth="md"
        >
          <CategorySelect
            selectedSubcategoriesAction={selectedSubcategoriesTrigger}
            setSelectedSubcategoriesAction={setSelectedSubcategoriesTrigger}
            handleClose={handleCloseSubcategory}
            multiple={false}
          />
        </Modal>
      </div>

      <div>
        <Controller
          control={control}
          name="triggerEvents.automatic.forSubcategoryAndItemInCart.itemIds"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3>Selected Items</h3>
          <div>
            <Button onClick={setOpenItem.bind(this, true)} variant="outlined">
              <Add /> Add Items
            </Button>
          </div>
        </div>
        <div>{renderChipItem}</div>
        <Modal open={openItem} handleClose={handleCloseItem} title="Add Items" maxWidth="md">
          <AddItemsModal
            excludedItems={[cartItemsRequired]} // Modal accepts only array of items
            setExcludedItems={setCartItemsRequired}
            handleClose={handleCloseItem}
            multiple={false}
          />
        </Modal>
      </div>
    </>
  );
}

export default ItemFromSubcategoryAndItem;
