import { gql } from "@apollo/client";

const QUOTE_LOCATION_ACCEPTANCE = gql`
  mutation quoteLocationAcceptance($input:  QuoteLocationAcceptanceMutationInput!) {
    quoteLocationAcceptance(input: $input) {
      viewer {
        id
      } 
      quote {
        id
        quotecatering {
          status {
            id
            label
          }
        }
      }
    }
  }
`;
export default QUOTE_LOCATION_ACCEPTANCE;
