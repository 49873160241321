import { Grid, Typography, Button, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';

import LocationInfoStyle from './styles';

import { CREATE_TEST_PRINT, UPDATE_STRIPE } from '../../../../../../../apollo/mutations';
import { ContentInfo, Permission, PermissionField } from '../../../../../../blocks';
import { generateStoreAbbreviation, phoneNumberFormat } from '../../../../../../../utils';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../../../constants';
import { compact } from 'lodash';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const LocationInfoView = ({
  handleEditLocation,
  locationInfoData,
  permissions,
  mutationPermissions,
  weekdaysData,
  accessToMutations,
}) => {
  const classes = LocationInfoStyle();

  const { setSnackbar } = useSnackbar();

  const [createTestPrint, { loading: loadingTestPrint }] = useMutation(CREATE_TEST_PRINT, {
    variables: {
      input: {
        locationId: locationInfoData?.id,
      },
    },
  });

  const [updateStripe, { loading: loadingStripeUpdate }] = useMutation(UPDATE_STRIPE, {
    variables: {
      input: {
        locationId: locationInfoData?.id,
      },
    },
  });

  const handleOnUpdateStripeClick = useCallback(() => {
    updateStripe().then(
      ({
        data: {
          updateStripe: { url },
        },
      }) => {
        window.open(url);
      },
    );
  }, [updateStripe]);

  const handleOnCreateTestPrintClick = useCallback(() => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Creating test print...',
      open: true,
    });

    createTestPrint()
      .then(() => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Test print created!',
          open: true,
        });
      })
      .catch((e) => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          open: true,
          text: e.message,
        });
      });
  }, [createTestPrint, setSnackbar]);

  const updateLocationInfoPermission = mutationPermissions.find(
    (mutationPermission) => mutationPermission.label === 'updateLocationInfo',
  );
  const testPrintPermission = mutationPermissions.find(
    (mutationPermission) => mutationPermission.label === 'testPrint',
  );

  const formatLocationAddress = ({ address, city, state, zip, additionalInfo }) =>
    compact([address, city, state.abbreviation, zip, additionalInfo]).join(', ');

  const locationInfoRenderData = [
    [
      {
        id: 'e4e75323-2e0b-4f84-9f98-6a48953e5eea',
        label: 'Location Store Number',
        value: locationInfoData?.storeNumber,
        permission: permissions?.location?.storeNumber,
      },
      {
        id: '16eb1644-92ec-491e-b8ff-a6a819a8496b',
        label: 'Address',
        value: locationInfoData?.address
          ? formatLocationAddress(locationInfoData?.address)
          : undefined,
        permission: permissions?.location?.address?.__typename,
      },
      {
        id: '61edf55d-069e-4b21-97bb-7d644597262d',
        label: 'Printer Password',
        value: locationInfoData?.printerPassword,
        permission: permissions?.location?.printerPassword,
      },
    ],
    [
      {
        id: 'a6690fc1-0fbc-4adf-a18b-ec77dab96122',
        label: 'Location Number',
        value: locationInfoData
          ? generateStoreAbbreviation(locationInfoData).abbreviation
          : undefined,
        permission: permissions?.location?.storeNumber,
      },
      {
        id: '86ec0b27-f79a-4c64-aa81-a66558eb62b2',
        label: 'TimeZone',
        value: locationInfoData?.timezone?.label,
        component: permissions?.location?.timezone?.label,
      },
      {
        id: '549edc5a-69f5-4a62-95ce-b6b70c111eb6',
        label: 'Receipt Footer',
        value: locationInfoData?.receiptFooter,
        permission: permissions?.location?.receiptFooter,
      },
    ],
    [
      {
        id: 'fc9e27f2-71de-40ac-86c7-d56b3bdb224e',
        label: 'Name',
        value: locationInfoData?.label,
        permission: permissions?.location?.label,
      },
      {
        id: '88a596f9-32c0-451e-871d-6f22b17ef01d',
        label: 'Open Date',
        value: locationInfoData?.opened ? moment(locationInfoData.opened).format('MM/DD/YYYY') : '',
        permission: permissions?.location?.opened,
      },
      {
        id: '45d67bac-d214-4978-865e-212953fbe2c6',
        label: 'Close Date',
        value: locationInfoData?.closed ? moment(locationInfoData.closed).format('MM/DD/YYYY') : '',
        permission: permissions?.location?.closed,
      },
    ],
    [
      {
        id: '28899ce6-3956-4d46-9b3b-8353efbefa56',
        label: 'Phone',
        value: phoneNumberFormat(locationInfoData?.phone?.phone),
        linkValue: `tel: ${locationInfoData?.phone?.phone}`,
        permission: permissions?.location?.phone?.phone,
      },
      {
        id: '230c4aa8-c861-4711-832e-61d71963c6da',
        label: 'Work Week Start Day',
        value:
          weekdaysData.find((edge) => edge?.node?.weekdayId === locationInfoData?.workWeekStartId)
            ?.node?.label || '',
        permission: permissions?.location?.workWeekStartId,
      },
      {
        id: '260c4ab8-c861-4711-832e-62d71963c6ds',
        label: 'Payroll Period',
        value: permissions?.location?.payrollPeriodId
          ? locationInfoData?.payrollPeriod?.label
          : '/',
      },
    ],
    [
      {
        id: 'd48b7de2-6071-4a45-ad12-dfba88afdd3b',
        label: 'Hygiene Reminder',
        value: locationInfoData?.hygieneChitReminder ? 'ON' : 'OFF',
        permission: permissions?.location?.hygieneChitReminder,
      },
      {
        id: '97f4d0d0-a90a-4e59-b031-40e8203accbb',
        label: 'Email',
        value: locationInfoData?.email,
        linkValue: `mailto: ${locationInfoData?.email}`,
        permission: permissions?.location?.email,
      },
      {
        id: '24cef972-44fb-48c5-b048-e726fae50e80',
        label: 'Default Tax Group',
        value: locationInfoData?.taxgroup?.label,
        permission: permissions?.location?.taxgroup?.label,
      },
    ],
    [
      {
        id: '123',
        label: 'Menu',
        value: locationInfoData?.menu?.label,
        permission: permissions?.location?.menu?.__typename,
      },
      // {
      //   id: "a1e7af1d-3275-408b-bed8-7f3db336202a",
      //   label: "Tips",
      //   value: locationInfoData?.tipsAvailable ? "ON" : "OFF",
      //   permission: permissions?.location?.tipsAvailable
      // },
      {
        id: '124',
        label: 'Location Type',
        value: locationInfoData?.storeType,
        permission: permissions?.location?.storeTypeId,
      },
      {
        id: '125',
        label: 'Brand',
        value: locationInfoData?.brand.label,
        permission: permissions?.location?.brand?.label,
      },
    ],
    [
      {
        id: '127',
        label: 'Legal Name',
        value: locationInfoData?.legalBusinessName,
        permission: permissions?.location?.legalBusinessName,
      },
      {
        id: '128',
        label: 'Printer Service',
        value: locationInfoData?.printerService?.label,
        permission: permissions?.location?.printerservice,
      },
      {
        id: '129',
        label: 'Pickup information',
        value: locationInfoData?.pickupLocation,
        permission: permissions?.location?.pickupLocation,
      },
      {
        id: '130',
        label: 'Auto Submit Inventory Invoices',
        value: locationInfoData?.autoSubmitInvoices ? 'ON' : 'OFF',
        permission: permissions?.location?.autoSubmitInvoices,
      },
      {
        id: '131',
        label: 'Allow Automatic Inventoryitem Stock Toggle',
        value: locationInfoData?.allowAutomaticInventoryitemStockToggle ? 'ON' : 'OFF',
        permission: permissions?.location?.allowAutomaticInventoryitemStockToggle,
      },
    ],
  ];

  return (
    <div id="locationInfo">
      <Grid container>
        <Grid container item xs={12} className={classes.heading} justify="space-between">
          <Typography variant="h4" component="h2">
            Location Info
          </Typography>
          <Grid item>
            {Boolean(
              locationInfoData?.stripeAccount?.future_requirements?.currently_due?.length,
            ) && (
              <Button
                onClick={handleOnUpdateStripeClick}
                variant="outlined"
                style={{ color: 'white', background: 'red', hidden: true }}
                disabled={loadingStripeUpdate}
              >
                Update Stripe
              </Button>
            )}
            <Permission
              access={Math.min(
                testPrintPermission?.access,
                accessToMutations?.[MUTATION_NAME.CreateTestPrintMutationInput],
              )}
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    onClick={handleOnCreateTestPrintClick}
                    variant="outlined"
                    color="secondary"
                    disabled={!locationInfoData?.id || loadingTestPrint}
                  >
                    Test Print
                  </Button>
                </PermissionField>
              </div>
            </Permission>
            <Permission
              access={Math.min(
                updateLocationInfoPermission?.access,
                !locationInfoData?.id
                  ? accessToMutations?.[MUTATION_NAME.CreateLocationMutationInput]
                  : accessToMutations?.[MUTATION_NAME.UpdateLocationMutationInput],
              )}
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    className={classes.marginLeft}
                    onClick={() => handleEditLocation()}
                    disabled={loadingTestPrint}
                  >
                    {locationInfoData?.id ? 'Edit' : 'Create'}
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </Grid>
        </Grid>

        <ContentInfo contentInfo={locationInfoRenderData} id="LocationInfo" />
      </Grid>

      <Divider />

      <Grid container>
        <Grid container item xs={12}>
          <Typography variant="h6" component="p" className={classes.secondaryHeading}>
            Dickeys.com Location web page
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Permission access={permissions?.location?.locationPicture}>
            <Grid item container xs={6} lg={4} direction="column">
              <Typography variant="subtitle2" component="p" gutterBottom>
                Dickeys.com Image
              </Typography>
              <Grid item>
                <img
                  src={locationInfoData?.locationPicture}
                  className={classes.locationImg}
                  alt=""
                />
              </Grid>
            </Grid>
          </Permission>
          <Grid item container xs={6} lg={4}>
            <Permission access={permissions?.slug}>
              <Grid container direction="column" className={classes.marginBottom}>
                <Typography variant="subtitle2" component="p" gutterBottom>
                  Slug
                </Typography>
                <Typography variant="body2" component="p">
                  {locationInfoData?.slug}
                </Typography>
              </Grid>
            </Permission>
            <Permission access={permissions?.locationInfo}>
              <Grid container item xs={12} direction="column">
                <Typography variant="subtitle2" component="p" gutterBottom>
                  Location Info
                </Typography>
                <Typography variant="body2" component="p">
                  {locationInfoData?.locationInfo}
                </Typography>
              </Grid>
            </Permission>
          </Grid>
          <Permission access={permissions?.cateringInfo}>
            <Grid item container xs={6} lg={4} direction="column">
              <Typography variant="subtitle2" component="p" gutterBottom>
                Catering Info
              </Typography>
              <Typography variant="body2" component="p">
                {locationInfoData?.cateringInfo}
              </Typography>
            </Grid>
          </Permission>
        </Grid>
      </Grid>
    </div>
  );
};

LocationInfoView.propTypes = {
  handleEditLocation: PropTypes.func.isRequired,
  locationInfoData: PropTypes.object.isRequired,
};

export default LocationInfoView;
