import { gql } from '@apollo/client';

const REMOVE_NUTRIENT = gql`
  mutation removeNutrient($input: RemoveNutrientMutationInput!) {
    removeNutrient(input: $input) {
      viewer {
        id
      }
      removedNutrientId
    }
  }
`;

export default REMOVE_NUTRIENT;
