import { gql } from "@apollo/client";

const TOGGLE_LOCATION_INGREDIENT = gql`
  mutation ToggleLocationIngredient($input: ToggleLocationIngredientMutationInput!) {
    toggleLocationIngredient(input: $input) {
      viewer {
      id
      }
    }
  }
`;

export default TOGGLE_LOCATION_INGREDIENT;
