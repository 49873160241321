import { gql } from '@apollo/client';

const LIST_CATEGORIES_SUBCATEGORIES = gql`
  query listCategoriesSubcategories(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [SubcategorySort]
    $filter: [SubcategoryFilter]
  ) {
    viewer {
      subcategoryConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
      ) {
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
        edges {
          node {
            label
            id
            label
            subcategoryId
            category {
              id
              label
              categoryId
            }
          }
        }
      }
    }
  }
`;

export default LIST_CATEGORIES_SUBCATEGORIES;
