import { gql } from '@apollo/client';

const DISCOUNTS_LIST = gql`
  query discounts(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [CouponSort]
    $filter: [CouponFilter]
  ) {
    viewer {
      id
      couponConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 8
      ) {
        permissions
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            couponId
            coupontypeId
            code
            label
            internalLabel
            expired
            archived
            status
            couponAction {
              couponaction_id
              label
            }
            couponBrandConnection {
              edges {
                node {
                  brandId
                }
              }
            }
            started
            finished
            couponPlatformConnection {
              edges {
                node {
                  id
                  platformId
                  platform {
                    id
                    label
                  }
                }
              }
            }
            discountAmount
            used
            coupontype {
              label
            }
            couponActionConnection(first: 1) {
              edges {
                node {
                  id
                  valueOff
                  couponactionId
                }
              }
            }
            couponItemConnection {
              edges {
                node {
                  id
                  item {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DISCOUNTS_LIST;
