import React, { useMemo } from 'react';
import ClosingInventoryView from './View';

const calculateYieldedCost = protein => protein.value * protein.protein.cost * protein.protein.size;

const calculateCost = side => side.value * side.side.cost * side.side.size;

const calculateClosingInventory = apiData => {
  const proteins = apiData.proteins.map(protein => {
    if (protein.value) {
      const yieldedCost = calculateYieldedCost(protein);
      return { ...protein, yieldedCost };
    } else {
      return protein;
    }
  });

  const sides = apiData.sides.map(side => {
    if (side.value) {
      const cost = calculateCost(side);
      return { ...side, cost };
    } else {
      return side;
    }
  });

  const closingInventory = {
    proteins,
    sides,
  };

  return closingInventory;
};

const ClosingInventory = ({
  data,
  saveClosingInventory,
  getSideUOM,
  saveClosingInventoryLoading,
  setSnackbar,
}) => {
  return (
    <ClosingInventoryView
      data={data}
      calculateClosingInventory={calculateClosingInventory}
      calculateYieldedCost={calculateYieldedCost}
      calculateCost={calculateCost}
      saveClosingInventory={saveClosingInventory}
      getSideUOM={getSideUOM}
      saveClosingInventoryLoading={saveClosingInventoryLoading}
      setSnackbar={setSnackbar}
    />
  );
};

export default ClosingInventory;
