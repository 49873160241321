
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    editTextfield: {
      marginTop: "1rem",
      width: 624,
      height: 56,
    },
    editSwitch: {
      margin: 0,
      "& > span": {
        marginRight: "1rem",
      },
    },
    switchWrap: {
      marginBottom: "2rem",
    },
    buttonGroup: {
      justifyContent: "flex-end",
      display: "flex",
      padding: "0 8px",
      marginBottom: ".5rem",
      "& > button:first-child": {
        marginRight: "1rem",
        backgroundColor: "transparent",
        borderColor: "#e7e8ef",
      },
    },
}));

export const useSliderStyles = makeStyles((theme) => ({
  valueLabel: {
    color: theme.palette.primary.main
  },
  thumb: {
    color: theme.palette.background.paper,
  }
}));

