import { gql } from '@apollo/client';

const CREATE_FULL_INVENTORY_COUNT = gql`
  mutation CREATE_FULL_INVENTORY_COUNT($input: CreateInventorycountInput!) {
    createInventorycount(input: $input) {
      inventorycountEdge {
        id
        inventorycountId
        dateCounted
        locationId
      }
    }
  }
`;

export default CREATE_FULL_INVENTORY_COUNT;
