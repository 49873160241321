import { useMutation } from '@apollo/client';
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { CREATE_STORAGE_AREA, UPDATE_STORAGE_AREA } from '../../../../../../apollo/mutations';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const initialFormValues = { label: '', status: '' };

const StorageAreaForm = ({ statusOptions, handleCloseModal, storageArea }) => {
  const { handleSubmit, register, errors, control, reset } = useForm(initialFormValues);

  const { setSnackbar } = useSnackbar();

  const [createStorageArea, { loading: creating }] = useMutation(CREATE_STORAGE_AREA, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Storage Area created',
        open: true,
      });

      handleCloseModal();
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createStorageArea?.viewer),
        fields: {
          storageConnection() {
            return {
              __typename: 'StrorageEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [updateStorageArea, { loading: updating }] = useMutation(UPDATE_STORAGE_AREA, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Storage Area updated',
        open: true,
      });

      handleCloseModal();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (storageArea?.storageId) {
      reset({
        label: storageArea.label,
        status: storageArea.status ? '1' : '0',
      });
    } else {
      reset(initialFormValues);
    }
  }, [storageArea]);

  const onSubmit = useCallback(formData => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: !storageArea?.storageId ? 'Storage Area creating...' : 'Storage Area updating...',
      open: true,
    });

    if (!storageArea?.storageId) {
      createStorageArea({ variables: { input: formData } });
    } else {
      updateStorageArea({
        variables: { input: { ...formData, storageId: storageArea.storageId } },
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6} item>
          <TextField
            autoFocus
            inputRef={register({
              required: 'true',
            })}
            fullWidth
            type="label"
            label="Name"
            error={!!errors.label}
            helperText={errors.label?.type === 'required' && 'Name is required'}
            name="label"
            variant="outlined"
            placeholder="Name"
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.status}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="status"
              name="status"
              label="Status"
              defaultValue=""
              placeholder="Status"
              error={!!errors?.status}
              as={
                <Select label="Status" multiple={false} error={!!errors?.status}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.status?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} container spacing={3} justify="flex-end">
          <Grid item>
            <Button variant="outlined" onClick={handleCloseModal} disabled={creating || updating}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" disabled={creating || updating}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default StorageAreaForm;
