import React, { useCallback } from 'react';
import { Button, FormControl, FormLabel, Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import generateReportPdf from '../../../../../../utils/pdfUtils/generateReportPdf';
import moment from 'moment';

const toggleButtonStyle = { width: '60px', padding: '4px 12px', height: 'min-content' };

const Togglers = ({ filters, setFilters, variables, refetch, tableData, locationLabel }) => {
  const handleDisplayCostToggle = useCallback(
    (e, value) => {
      if (value !== null) {
        setFilters(prevFilters => ({
          ...prevFilters,
          displayAsCost: value,
        }));

        refetch({
          ...variables,
          displayAsCost: !!value,
        });
      }
    },
    [refetch, variables],
  );

  const handleDisplayCategoriesToggle = useCallback((e, value) => {
    if (value !== null) {
      setFilters(prevFilters => ({
        ...prevFilters,
        displaySubategories: value,
      }));
    }
  }, []);

  const handleOnExportPdfClick = useCallback(() => {
    generateReportPdf({
      title: `${locationLabel} - Actual Vs Theoretical (${(filters.dateRange.gte &&
        moment(filters.dateRange.gte)?.format('MM/DD/YYYY')) ||
        ''} - ${(filters.dateRange.lte && moment(filters.dateRange.lte)?.format('MM/DD/YYYY')) ||
        ''})`,
      tableData,
      isBoldFieldName: 'isGroupLabel',
      header: [
        { text: 'Category / Product', field: 'label', isBold: true },
        { text: 'Inv. UOM', field: 'invUom' },
        { text: 'Actual Usage', field: 'actualUsageQty' },
        { text: 'AU %', field: 'auPercentage' },
        { text: 'Theoretical Usage', field: 'theoreticalUsage' },
        { text: 'TU %', field: 'tuPercentage' },
        { text: 'Variance', field: 'variance' },
        { text: 'Variance %', field: 'varPercentage' },
      ],
    });
  }, [tableData, locationLabel, filters.dateRange]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} sm={2}>
        <FormControl>
          <FormLabel style={{ fontSize: '13px', marginBottom: '4px' }}>$ / Count</FormLabel>
          <ToggleButtonGroup
            value={filters.displayAsCost}
            exclusive
            onChange={handleDisplayCostToggle}
          >
            <ToggleButton value={1} style={toggleButtonStyle}>
              $
            </ToggleButton>
            <ToggleButton value={0} style={toggleButtonStyle}>
              Ct.
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4} sm={2}>
        <FormControl>
          <FormLabel style={{ fontSize: '13px', marginBottom: '4px' }}>
            Category / Product
          </FormLabel>
          <ToggleButtonGroup
            value={filters.displaySubategories}
            exclusive
            onChange={handleDisplayCategoriesToggle}
          >
            <ToggleButton value={0} style={toggleButtonStyle}>
              Cat.
            </ToggleButton>
            <ToggleButton value={1} style={toggleButtonStyle}>
              Prod.
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4} sm={8} justify="flex-end">
        <Button onClick={handleOnExportPdfClick}>Export PDF</Button>
      </Grid>
    </Grid>
  );
};

export default Togglers;
