import { gql } from '@apollo/client';

const UPDATE_CATEGORY = gql`
  mutation updateCategory($input: UpdateCategoryMutationInput!) {
    updateCategory(input: $input) {
      category {
        id
        label
        description
        categorytypeId
        showCustomer
      }
    }
  }
`;

export default UPDATE_CATEGORY;
