import React, { useEffect, useState } from 'react';
import { Elements } from '../../../../../../blocks';
import { Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import SubcategoriesStyles from './styles';
import { SelectFactory } from '../../../../../../blocks';
import FETCH_SUBCATEGORY from '../../../../../../../apollo/queries/discounts/fetchSubcategories';

const Subcategories = ({ control, excludedSubcategories, setExcludedSubcategories }) => {
  const classes = SubcategoriesStyles();

  const [showExcludedSubcategories, setShowExcludedSubcategories] = useState(false);

  useEffect(() => {
    if (excludedSubcategories.length) {
      setShowExcludedSubcategories(true);
    }
  }, [excludedSubcategories]);

  return (
    <div>
      <div>
        <Controller
          control={control}
          name="discountRestrictions.subcategories.enabled"
          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '2rem' }}>
                <Elements.Switch
                  ref={ref}
                  checked={value}
                  handleSwitchChange={isChecked => {
                    setShowExcludedSubcategories(isChecked);
                    onChange(isChecked);
                  }}
                />
              </div>
              <div>
                <h3>Exclude Subcategories</h3>
              </div>
            </div>
          )}
        />
      </div>
      {showExcludedSubcategories && (
        <Grid item xs={8} className={classes.row}>
          <Controller
            control={control}
            name={'discountRestrictions.subcategories.excludedSubcategories'}
            defaultValue={excludedSubcategories}
            render={({ onChange, ...props }) => {
              return (
                <SelectFactory
                  customProps={props}
                  id="subcategories"
                  label={'Subcategory'}
                  placeholder={'Subcategory'}
                  query={FETCH_SUBCATEGORY}
                  filter={{ showUnavailableOnline: { eq: 1 } }}
                  defaultValue={excludedSubcategories}
                  multiple={true}
                  renderCheckBox={true}
                  disableCloseOnSelect={true}
                  control={control}
                  structureData={data =>
                    data?.viewer?.subcategoryConnection?.edges?.map((subcategory, index) => ({
                      id: subcategory.node.id,
                      label: `${subcategory.node.label} - ID ${subcategory.node.subcategoryId}`,
                      subcategoryId: subcategory.node.subcategoryId,
                      index,
                    }))
                  }
                  onSelect={selectedSubcategories =>
                    setExcludedSubcategories([...selectedSubcategories])
                  }
                />
              );
            }}
          />
        </Grid>
      )}
    </div>
  );
};

export default Subcategories;
