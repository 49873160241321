import React, { useContext, useMemo, useState } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LOYALTY_HOUSE_ACCOUNTS from '../../../../../apollo/queries/loyalty/loyaltyHouseAccounts';
import { MUTATION_NAME, ROWS_PER_PAGE_OPTIONS } from '../../../../../constants';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CreateIcon from '@material-ui/icons/Create';
import { ConnectionTable, Modal } from '../../../../blocks';
import { LocationIdContext } from '../index';
import Permission from '../../../../blocks/Permission';
import AddHouseAccountModal from './HouseAccountModals/AddHouseAccountModal';
import EditHouseAccountModal from './HouseAccountModals/EditHouseAccountModal';
import PayHouseAccountModal from './HouseAccountModals/PayHouseAccountModal';
import {
  convertHouseAccountValueForFrontend,
  getAvailableCredit,
} from '../../../../../utils/houseAccountUtils';
import { noLimitLabel } from '../../../../../constants/houseAccounts';
import { CoreContext } from '../../../../../CoreContext';
import { useFormSubmitPermissions } from '../../../../../hooks';

const HouseAccountModalType = {
  AddHouseAccount: {
    id: 'add_house_account',
    label: 'Add House Account',
  },
  EditHouseAccount: {
    id: 'edit_house_account',
    label: 'Edit House Account',
  },
  PayHouseAccount: {
    id: 'pay_house_account',
    label: 'Pay House Account',
  },
};

const HouseAccountSettings = () => {
  const useStyles = makeStyles(theme => ({
    container: {
      width: '100%',
      padding: theme.spacing(5),
      '& .MuiPaper-root': {
        boxShadow: 'none',
        marginBottom: theme.spacing(5),
        padding: '0 !important',
      },
    },
    paper: {
      position: 'absolute',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const context = useContext(CoreContext);
  const mutationPermissions = useMemo(() => context?.coreUtils?.getAppActions()[86]?.mutations, [
    context?.coreUtils,
  ]);
  const createHouseAccountPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'createHouseaccount',
      ),
    [mutationPermissions],
  );
  const createHouseAccountDepositPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'createHouseAccountDeposit',
      ),
    [mutationPermissions],
  );
  const updateHouseAccountPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'updateHouseaccount',
      ),
    [mutationPermissions],
  );

  const removeCreditCardPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'removeCreditCard',
      ),
    [mutationPermissions],
  );

  const [permissions, setPermissions] = useState();
  const [noPermissions, setNoPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.CreateHouseaccountMutationInput,
      MUTATION_NAME.CreateHouseAccountDepositMutationInput,
      MUTATION_NAME.UpdateHouseaccountMutationInput,
      MUTATION_NAME.SaveCreditCardMutationInput,
      MUTATION_NAME.removeCreditCard,
    ],
    permissions,
  });

  const [showAddHouseAccountModal, setShowAddHouseAccountModal] = useState({
    open: false,
    title: null,
  });

  const [showEditHouseAccountModal, setShowEditHouseAccountModal] = useState({
    open: false,
    title: null,
    houseAccountData: null,
  });

  const [showPayHouseAccountModal, setShowPayHouseAccountModal] = useState({
    open: false,
    title: null,
    houseAccountData: null,
  });
  const classes = useStyles();
  const locationId = atob(useContext(LocationIdContext))?.split(':')?.[1];
  const handleOpenModal = (houseAccountModalTypeId, data) => {
    if (houseAccountModalTypeId === HouseAccountModalType.AddHouseAccount.id) {
      setShowAddHouseAccountModal(() => ({
        title: HouseAccountModalType.AddHouseAccount.label,
        open: true,
      }));
      setOpenWarningMutationName(MUTATION_NAME.CreateHouseaccountMutationInput);
    } else if (houseAccountModalTypeId === HouseAccountModalType.EditHouseAccount.id) {
      setShowEditHouseAccountModal(() => ({
        title: HouseAccountModalType.EditHouseAccount.label,
        open: true,
        houseAccountData: data,
      }));
      setOpenWarningMutationName(MUTATION_NAME.UpdateHouseaccountMutationInput);
    } else if (houseAccountModalTypeId === HouseAccountModalType.PayHouseAccount.id) {
      setShowPayHouseAccountModal(() => ({
        title: HouseAccountModalType.PayHouseAccount.label,
        open: true,
        houseAccountData: data,
      }));
      setOpenWarningMutationName(MUTATION_NAME.CreateHouseAccountDepositMutationInput);
    }
  };

  const handleCloseModal = () => {
    setShowAddHouseAccountModal(prevState => ({ ...prevState, open: false }));
    setShowEditHouseAccountModal(prevState => ({ ...prevState, open: false }));
    setShowPayHouseAccountModal(prevState => ({ ...prevState, open: false }));
  };
  if (noPermissions) return <div>No data available for this location</div>;
  return (
    <div className={classes.container}>
      <Typography variant="h4" component="h2" id="houseAccountSettings">
        House Account Settings
      </Typography>
      <ConnectionTable
        query={LOYALTY_HOUSE_ACCOUNTS}
        minSearchValueLength={1}
        customActionsCenter={() => {
          return (
            <Permission access={createHouseAccountPermission.access}>
              <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                <Button onClick={() => handleOpenModal(HouseAccountModalType.AddHouseAccount.id)}>
                  Add House Account
                </Button>
              </div>
            </Permission>
          );
        }}
        initialQueryVariables={{
          first: ROWS_PER_PAGE_OPTIONS[0],
          sort: [
            {
              id: 'DESC',
            },
          ],
          filter: {
            locationId: {
              eq: locationId,
            },
          },
          fieldGroup: 86,
        }}
        structureTable={data => {
          if (!data?.viewer?.houseaccountConnection) {
            return null;
          }
          const permissionObject = { ...data.viewer.houseaccountConnection.permissions };
          if (
            permissionObject &&
            Object.keys(permissionObject).length === 0 &&
            Object.getPrototypeOf(permissionObject) === Object.prototype
          ) {
            setNoPermissions(true);
            return null;
          }
          if (!permissions) {
            setPermissions({
              ...permissionObject,
              locationId: permissionObject.location.locationId,
              label: permissionObject.location.label,
              isOpened: permissionObject.location.isOpened,
              loginId: permissionObject.login.loginId,
              person: permissionObject.login.person.__typename,
              personId: permissionObject.login.person.personId,
              firstName: permissionObject.login.person.firstName,
              lastName: permissionObject.login.person.lastName,
              source: permissionObject.login.person.stripeAccount,
              stripeAccount: permissionObject.login.person.stripeAccount,
              stripeCustomerId: permissionObject.login.person.stripeAccount,
              stripeCardId: permissionObject.login.person.stripeAccount,
            });
          }
          return data.viewer.houseaccountConnection.edges?.map(quote => ({
            'login.person.personId': quote.node.login.person.personId,
            'login.person.firstName': quote.node.login.person.firstName,
            'login.person.lastName': quote.node.login.person.lastName,
            balance: quote.node.balance,
            limit: quote.node.limit,
            data: quote.node,
          }));
        }}
        columns={[
          { title: 'Person ID', field: 'login.person.personId' },
          { title: 'Firstname', field: 'login.person.firstName' },
          { title: 'Lastname', field: 'login.person.lastName' },
          {
            title: 'Account Limit',
            field: 'limit',
            render: limit => {
              const formattedLimit = convertHouseAccountValueForFrontend(limit);
              return formattedLimit === null ? noLimitLabel : `$${formattedLimit}`;
            },
          },
          {
            title: 'Account Balance',
            field: 'balance',
            render: balance => {
              return `$${convertHouseAccountValueForFrontend(balance)}`;
            },
          },
          {
            title: 'Available Credit',
            field: 'data',
            disableSort: true,
            render: data => {
              if (!permissions?.limit || !permissions?.balance) {
                return null;
              }
              const formattedAvailableCredit = getAvailableCredit(data.limit, data.balance);
              return formattedAvailableCredit === null
                ? noLimitLabel
                : `$${getAvailableCredit(data.limit, data.balance)}`;
            },
          },
          {
            title: 'Pay',
            field: 'data',
            disableSort: true,
            render: data => {
              return (
                <span style={{ cursor: 'pointer' }}>
                  <IconButton disabled={!data.location.isOpened}>
                    <Permission access={createHouseAccountDepositPermission.access}>
                      <CreditCardIcon
                        onClick={() => {
                          handleOpenModal(HouseAccountModalType.PayHouseAccount.id, data);
                        }}
                      />
                    </Permission>
                  </IconButton>
                </span>
              );
            },
          },
          {
            title: 'Edit',
            field: 'data',
            disableSort: true,
            render: data => {
              return (
                <span style={{ cursor: 'pointer' }}>
                  <IconButton disabled={!data.location.isOpened}>
                    <Permission access={updateHouseAccountPermission.access}>
                      <CreateIcon
                        onClick={() => {
                          handleOpenModal(HouseAccountModalType.EditHouseAccount.id, data);
                        }}
                      />
                    </Permission>
                  </IconButton>
                </span>
              );
            },
          },
        ]}
      />
      <Modal
        open={showAddHouseAccountModal.open}
        maxWidth={'sm'}
        handleClose={handleCloseModal}
        title={showAddHouseAccountModal.title}
      >
        <AddHouseAccountModal
          locationId={locationId}
          createHouseAccountPermission={createHouseAccountPermission}
          permissions={permissions}
          accessToMutations={accessToMutations}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      <Modal
        open={showEditHouseAccountModal.open}
        maxWidth={'sm'}
        handleClose={handleCloseModal}
        title={showEditHouseAccountModal.title}
      >
        <EditHouseAccountModal
          houseAccountData={showEditHouseAccountModal.houseAccountData}
          updateHouseAccountPermission={updateHouseAccountPermission}
          permissions={permissions}
          accessToMutations={accessToMutations}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      <Modal
        open={showPayHouseAccountModal.open}
        maxWidth={'lg'}
        handleClose={handleCloseModal}
        title={showPayHouseAccountModal.title}
      >
        <PayHouseAccountModal
          houseAccountData={showPayHouseAccountModal.houseAccountData}
          createHouseAccountDepositPermission={createHouseAccountDepositPermission}
          removeCreditCardPermission={removeCreditCardPermission}
          permissions={permissions}
          accessToMutations={accessToMutations}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default HouseAccountSettings;
