import React from 'react';
import GeneralSettingsView from './View';

function GeneralSettings({
  platformList,
  platforms,
  setPlatforms,
  timezones,
  control,
  discountDetailsItems,
  setDiscountDetailsItems,
  itemAddedNumberTimes,
  setItemAddedNumberTimes,
  setSelectedSubcategoriesAction,
  selectedSubcategoriesAction,
  errors,
  brands,
  setBrands,
  brandList,
  excludeAllBoostLocations,
  handleBoostStoreExclusion,
  isSmart,
  handleIsSmartChange,
}) {
  return (
    <GeneralSettingsView
      platformList={platformList}
      platforms={platforms}
      setPlatforms={setPlatforms}
      timezones={timezones}
      control={control}
      discountDetailsItems={discountDetailsItems}
      setDiscountDetailsItems={setDiscountDetailsItems}
      itemAddedNumberTimes={itemAddedNumberTimes}
      setItemAddedNumberTimes={setItemAddedNumberTimes}
      setSelectedSubcategoriesAction={setSelectedSubcategoriesAction}
      selectedSubcategoriesAction={selectedSubcategoriesAction}
      errors={errors}
      brands={brands}
      setBrands={setBrands}
      brandList={brandList}
      excludeAllBoostLocations={excludeAllBoostLocations}
      handleIsSmartChange={handleIsSmartChange}
      isSmart={isSmart}
      handleBoostStoreExclusion={handleBoostStoreExclusion}
    />
  );
}

export default GeneralSettings;
