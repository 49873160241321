import { Button, Typography, Grid, TextField, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { SelectFactory } from '../../../../../blocks';
import LOYALTY_STORE_ITEMS from '../../../../../../apollo/queries/loyalty/loyaltystoreitems';
import { LoyaltyEventBrandsStyle } from './style';

const EditLoyaltyEventsModalView = ({
  control,
  submit,
  handleSubmit,
  handleClose,
  loyaltyUpdateData,
  setSelectedItem,
}) => {
  const classes = LoyaltyEventBrandsStyle();

  return (
    <form onSubmit={handleSubmit(submit)} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3" className={classes.label}>
            {loyaltyUpdateData?.label}
          </Typography>
        </Grid>
        <br />

        {loyaltyUpdateData?.loyaltyeventBrandConnection?.edges.map(
          ({ node: eventBrand }, index) => (
            <>
              <Grid item xs={2}>
                <Typography variant="h5" component="h5">
                  {eventBrand?.brand?.label}
                </Typography>
              </Grid>

              <Grid item md={2}>
                <Controller
                  control={control}
                  name={`points-${index}`}
                  defaultValue={eventBrand?.points}
                  render={({ onChange, onBlur, ref }) => (
                    <TextField
                      className={classes.brand}
                      defaultValue={eventBrand?.points}
                      onChange={e => onChange(e.target.value)}
                      inputRef={ref}
                      fullWidth
                      label="Points"
                      placeholder="Points"
                    />
                  )}
                />
              </Grid>

              <Grid item md={2}>
                <Controller
                  control={control}
                  name={`bonusPoints-${index}`}
                  defaultValue={eventBrand?.bonusPoints}
                  render={({ onChange, onBlur, ref }) => (
                    <TextField
                      defaultValue={eventBrand?.bonusPoints}
                      onChange={e => onChange(e.target.value)}
                      inputRef={ref}
                      fullWidth
                      label="Bonus Points"
                      placeholder="Bonus Points"
                    />
                  )}
                />
              </Grid>

              <Grid item md={6}>
                <Controller
                  control={control}
                  name={`loyaltystoreitem-${index}`}
                  defaultValue={{
                    label: eventBrand?.coupon?.label,
                    couponId: eventBrand?.coupon?.couponId,
                    brandId: eventBrand?.brand?.brandId,
                  }}
                  render={({ onChange, onBlur, ref }) => (
                    <SelectFactory
                      label={'Loyalty Store Item'}
                      fullWidth
                      placeholder={'Select Item'}
                      query={LOYALTY_STORE_ITEMS}
                      filter={{
                        loyaltystoreitem: {
                          brandId: {
                            eq: index + 1,
                          },
                        },
                      }}
                      defaultValue={{
                        label: eventBrand?.coupon?.label,
                        couponId: eventBrand?.coupon?.couponId,
                        brandId: eventBrand?.brand?.brandId,
                      }}
                      renderCheckBox={false}
                      disableCloseOnSelect={false}
                      multiple={false}
                      inputRef={ref}
                      control={control}
                      structureData={data => {
                        return data?.viewer?.loyaltystoreitemCouponConnection?.edges.map(item => {
                          return {
                            label: item.node?.loyaltystoreitem?.label,
                            couponId: item.node.couponId,
                            brandId: item.node?.loyaltystoreitem?.brandId,
                          };
                        });
                      }}
                      onSelect={value => {
                        setSelectedItem(prevState => ({
                          ...prevState,
                          [index]: value,
                        }));
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider variant="middle" className={classes.divider} />
              </Grid>
            </>
          ),
        )}
        <div className={classes.buttons}>
          <Button onClick={handleClose} className={classes.discardButton}>
            Discard
          </Button>
          <Button className={classes.submitButton} type="submit">
            SAVE CHANGES
          </Button>
        </div>
      </Grid>
    </form>
  );
};

export default EditLoyaltyEventsModalView;
