import { gql } from '@apollo/client';

const CUSTOMERS = gql`
  query Customers(
    $first: Int
    $last: Int
    $after: String
    $filter: [LoginFilter]
    $sort: [LoginSort]
    $before: String
    $search: String
    $specificFields: [String]
  ) {
    viewer {
      id
      loginConnection(
        first: $first
        last: $last
        filter: $filter
        after: $after
        sort: $sort
        before: $before
        search: $search
        specificFields: $specificFields
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            loginId
            phone
            email
            inactive
            brand {
              id
              brandId
              label
            }
            person {
              id
              firstName
              lastName
              personId
              phone {
                id
                phone
              }
            }
          }
        }
      }
      brandConnection {
        edges {
          node {
            id
            brandId
            label
          }
        }
      }
    }
  }
`;

export default CUSTOMERS;
