import { useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CREATE_GENERAL_LEDGER_ACCOUNT,
  UPDATE_GENERAL_LEDGER_ACCOUNT,
} from '../../../../../../../apollo/mutations';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { statusOptions } from '../../../../../../../constants/statuses';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import NumberInput from '../../../../../../blocks/NumberInput';

const defaultValues = {
  label: '',
  active: '',
  code: '',
};

const GeneralLedgerAccountModal = ({ handleClose, generalLedger }) => {
  const { control, errors, handleSubmit, register, reset } = useForm(defaultValues);

  const { setSnackbar } = useSnackbar();

  const [createGeneralLedgerAccount] = useMutation(CREATE_GENERAL_LEDGER_ACCOUNT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'GL Account created',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createGeneralledgeraccount?.viewer),
        fields: {
          generalledgeraccountConnection() {
            return {
              __typename: 'GeneralledgeraccountEdge',
            };
          },
        },
      });
    },
  });

  const [updateGeneralLEdgerAccount] = useMutation(UPDATE_GENERAL_LEDGER_ACCOUNT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'GL Account updated',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (generalLedger?.generalLedgerAccountId) {
      reset({
        label: generalLedger.label,
        active: generalLedger.active ? 1 : 0,
        code: generalLedger.code,
      });
    } else {
      reset(defaultValues);
    }
  }, [generalLedger]);

  const onSubmit = useCallback(
    formData => {
      const input = {
        label: formData.label,
        active: !!formData.active,
        code: formData.code,
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: generalLedger?.generalLedgerAccountId
          ? 'Updating GL Account...'
          : 'Creating GL Account...',
        open: true,
      });

      if (generalLedger?.generalLedgerAccountId) {
        input.generalledgeraccountId = generalLedger.generalLedgerAccountId;

        updateGeneralLEdgerAccount({ variables: { input } });
      } else {
        createGeneralLedgerAccount({ variables: { input } });
      }
    },
    [generalLedger?.generalLedgerAccountId],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            label="Name"
            name="label"
            variant="outlined"
            placeholder="Name"
            error={errors.label}
            helperText={!!errors.label && 'Name is required'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.active}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="active"
              name="active"
              label="Status"
              placeholder="Status"
              defaultValue={generalLedger?.active ? 1 : 0}
              error={!!errors?.active}
              as={
                <Select label="Status" multiple={false} error={!!errors?.active}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.active?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <NumberInput
            control={control}
            decimalScale={0}
            defaultValue=""
            id="code"
            required={true}
            label="Code"
            thousandSeparator={false}
            name="code"
            variant="outlined"
            placeholder="Code"
            error={errors.code}
            helperText={!!errors.code && 'Code is required'}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit">
                {generalLedger?.generalLedgerAccountId ? 'Save' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default GeneralLedgerAccountModal;
