import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ItemChoiceTable from './ItemChoiceTable';

const useStyles = makeStyles({
  container: {},
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
});

const ItemChoices = () => {
  const [open, setOpen] = useState(false);

  const { itemId } = useParams();

  return (
    <div>
      <ItemChoiceTable
        open={open}
        itemId={itemId}
      />
    </div>
  );
};

ItemChoices.propTypes = {
  gqlItemId: PropTypes.string.isRequired,
};

export default ItemChoices;
