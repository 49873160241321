import { gql } from '@apollo/client';

const REPRINT = gql`
  mutation rePrint(
    $quoteId: ID!
  ) {
    rePrint(
      input: {
        quoteId: $quoteId
      }
    ) {
      clientMutationId
    }
  }
`;

export default REPRINT;
