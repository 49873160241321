import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { TableFooter, TableRow, TablePagination, useTheme, IconButton } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { ROWS_PER_PAGE_OPTIONS, VIEW_ALL_LABEL } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
}));

const ConnectionTablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage, onTableRefresh, hideTotalCount } = props;
  const classes = useStyles();
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleTableRefresh = () => {
    onTableRefresh();
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={(event) => handleFirstPageButtonClick(event)}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {!hideTotalCount && (
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      )}
      <IconButton onClick={handleTableRefresh}>
        <AutorenewIcon />
      </IconButton>
    </div>
  );
};

const ConnectionTablePagination = ({
  variables,
  currentPage,
  hideTotalCount,
  handleChangePage,
  handleChangeRowsPerPage,
  handleTableRefresh,
  count,
}) => {

  const rowsPerPage = () => {
    return variables.first || variables.last || 0;
  }

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[...ROWS_PER_PAGE_OPTIONS, { value: count, label: VIEW_ALL_LABEL }]}
          style={{ width: '100%' }}
          count={count}
          rowsPerPage={rowsPerPage()}
          page={currentPage}
          labelDisplayedRows={({ from, to, count }) =>
            hideTotalCount ? '' : `${from}-${to} of ${count}`
          }
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={(props) => (
            <ConnectionTablePaginationActions
              {...props}
              onTableRefresh={handleTableRefresh}
              hideTotalCount={hideTotalCount}
            />
          )}
        />
      </TableRow>
    </TableFooter>
  );
};

export default ConnectionTablePagination;
