import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Divider,
  Card,
  CardMedia,
  Modal,
  Paper,
  Tabs,
  Tab,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  Radio,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormHelperText,
  TextField,
  Switch,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import GeneralInfo from '../Locations/Location/GeneralInfo';
import OrderSettings from '../Locations/Location/OrderSettings';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import yellow from '@material-ui/core/colors/yellow';
import { ContentInfo, ImageUpload, Notes, TabPanel, SideBarSectionsLayout } from '../../blocks';
import { PlacesAutocomplete } from '../../blocks';

import DemoPageView from './View';
import { tenderId } from '../../../constants/payments';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  paper: {
    position: 'absolute',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formGroup: {
    margin: theme.spacing(3),
  },
  formControl: {
    marginRight: theme.spacing(3),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const bankSettingsRenderData = [
  [
    {
      id: '7a5069de-f86a-4fd8-9bc6-0b8da0982a11',
      label: 'Business Name',
      value: 'lorem',
    },
    {
      id: 'dcb14786-26c5-4c41-b185-f0612cee8d4f',
      label: 'Authorized Representative',
      value: 'lorem',
    },
    {
      id: '9728b128-3747-4f71-844b-7125608fc7a8',
      label: 'Bank Name',
      value: 'lorem',
    },
  ],
  [
    {
      id: '2273099d-51f4-4dde-bd45-d23c100b673c',
      label: 'Business Tax Id',
      value: 'lorem',
    },
    {
      id: '5adc8658-3a25-451d-b529-a0e340525391',
      label: 'Date of Birth (representative)',
      value: 'lorem',
    },
    {
      id: '05b52ead-f4f9-4b17-96f9-605eaeccfc5b',
      label: 'Bank Account Number',
      value: 'lorem',
    },
  ],
  [
    {
      id: '7a5069de-f86a-4fd8-9bc6-0b8da0982a11',
      label: 'Address',
      value: 'lorem',
    },
    {
      id: 'dcb14786-26c5-4c41-b185-f0612cee8d4f',
      label: 'SSN last 4 numbers (representative)',
      value: 'lorem',
    },
    {
      id: '9728b128-3747-4f71-844b-7125608fc7a8',
      label: 'Bank Account Routing Number',
      value: 'lorem',
    },
  ],
];

const getModalStyle = () => {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
  };
};

const tabsViewData = [
  {
    label: 'General Info',
    value: 'generalInfo',
    component: GeneralInfo,
    id: '519e0a6f-598d-4733-a5e5-6069a856637c',
    sections: [
      { name: 'Location Info', id: 'locationInfo' },
      // { name: 'Location Hours', id: 'locationHours' },
      { name: 'Tax Rates', id: 'taxRates' },
      { name: 'Tax Groups', id: 'taxGroups' },
      { name: 'Bank Settings', id: 'bankSettings' },
      { name: 'Payment Processors', id: 'paymentProcessors' },
    ],
  },
  {
    label: 'Order Settings',
    value: 'orderSettings',
    component: OrderSettings,
    id: '32aa54da-28ce-433b-9a69-aa848e9dc44f',
    sections: [
      { name: 'Order Settings', id: 'orderSettings' },
      { name: 'Dine In', id: 'dineIn' },
      { name: 'Pickup', id: 'pickup' },
      { name: 'Delivery', id: 'delivery' },
      { name: 'Curbside', id: 'curbside' },
      { name: 'DriveThru', id: 'drivethru' },
      { name: 'Catering', id: 'catering' },
    ],
  },
  {
    label: 'Menu Settings',
    value: 'menuSettings',
    id: '810b15f6-16b9-47f9-8a8a-2b0849d2f549',
  },
];

const DemoPage = () => {
  const classes = useStyles();
  const [newCard, setNewCard] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAC, setOpenAC] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [value, setValue] = React.useState(0);
  const [paymentMethod, setPaymentMethod] = useState(tenderId.CHARGE);
  const [checked, setChecked] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const changeNewCard = (event, value) => {
    setNewCard(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenAC = () => {
    setOpenAC(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAC = () => {
    setOpenAC(false);
  };

  const handleAddNewNote = () => {
    /* TODO */
    console.log('Add new note.');
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePaymentMethodChange = event => {
    setPaymentMethod(event.target.value);
  };

  const handleCheck = () => setChecked(!checked);

  const changeShowLocation = () => setShowLocation(!showLocation);

  return (
    <>
      <DemoPageView />
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h2" color="primary" align="center">
        Typography variants examples
      </Typography>
      <Typography gutterBottom variant="h1">
        Typography variant h1
      </Typography>
      <Typography gutterBottom variant="h2">
        Typography variant h2
      </Typography>
      <Typography gutterBottom variant="h3">
        Typography variant h3
      </Typography>
      <Typography gutterBottom variant="h4">
        Typography variant h4
      </Typography>
      <Typography gutterBottom variant="h5">
        Typography variant h5
      </Typography>
      <Typography gutterBottom variant="h6">
        Typography variant h6
      </Typography>
      <Typography gutterBottom variant="h6" component="h2">
        Typography variant h6 with component h2 (styling of h6 in h2 tag)
      </Typography>
      <br />
      <Typography variant="subtitle1">Typography variant subtitle1</Typography>
      <Typography variant="subtitle2" gutterBottom>
        Typography variant subtitle2
      </Typography>
      <Typography variant="body1" gutterBottom>
        Typography variant body1
      </Typography>
      <Typography variant="body2" gutterBottom>
        Typography variant body2
        <br />
        <Typography variant="body3">Typography variant body3</Typography>
      </Typography>
      <br />
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h2" color="primary" align="center">
        Block element examples
      </Typography>
      <br />
      <Typography gutterBottom variant="h4" component="h3">
        Content info example:
      </Typography>
      <ContentInfo contentInfo={bankSettingsRenderData} id="bankSettings" numOfColumns={3} />
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h3">
        Toggle button example:
      </Typography>

      <ToggleButtonGroup name="newCard" exclusive value={newCard} onChange={changeNewCard}>
        <ToggleButton value={true}>New Card</ToggleButton>
        <ToggleButton value={false}>Saved Card</ToggleButton>
      </ToggleButtonGroup>
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h3">
        Add image example:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ImageUpload image={() => {}} addImage={() => console.log('Add image')} />
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardMedia
              style={{ height: '180px', width: '180px' }}
              image={'https://picsum.photos/200'}
            />
          </Card>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h3">
        Modal example:
      </Typography>
      <Button onClick={() => handleOpen()}>Open Modal</Button>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Typography gutterBottom variant="h6">
            Lorem ipsum
          </Typography>
          <Grid container xs={12}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
            <Grid container item justify="space-around">
              <Button autofocus onClick={handleClose}>
                Cancel
              </Button>
              <Button autofocus onClick={() => console.log('clicked')}>
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h3">
        Note example:
      </Typography>
      <Notes notes={bankSettingsRenderData} handleAddNewNote={handleAddNewNote} />
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h3">
        Auto complete in modal example:
      </Typography>
      <Button onClick={() => handleOpenAC()}>Open Modal</Button>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openAC}
        onClose={handleCloseAC}
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid item xs={12}>
            Full address
            <PlacesAutocomplete
              handleGeocodeAutocomplete={() => console.log('Geocode autocomplete logic')}
            />
            <button onClick={() => console.log('clicked')}>Submit</button>
          </Grid>
        </div>
      </Modal>
      <Divider variant="middle" />
      <Typography gutterBottom variant="h4" component="h3">
        Simple tabs example:
      </Typography>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Item One" {...a11yProps(0)} />
        <Tab label="Item Two" {...a11yProps(1)} />
        <Tab label="Item Three" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <Divider variant="middle" />

      <Typography gutterBottom variant="h4" component="h3">
        Form elements example:
      </Typography>
      <form className={classes.root}>
        <FormGroup column className={classes.formGroup}>
          <FormControl variant="outlined">
            <InputLabel>Payment method</InputLabel>
            <Select
              value={true}
              onChange={handlePaymentMethodChange}
              label="Payment method"
              variant="outlined"
            >
              <MenuItem value={tenderId.CHARGE}>Internal Credit Card</MenuItem>
              <MenuItem value={tenderId.EXTERNAL_CHARGE}>External Credit Card</MenuItem>
              <MenuItem value={tenderId.CASH}>Cash</MenuItem>
              <MenuItem value={tenderId.GIFT_CARD}>Gift Card</MenuItem>
              <MenuItem value={tenderId.CHECK}>Check</MenuItem>
              <MenuItem value={tenderId.HOUSE_ACCOUNT}>House Account</MenuItem>
              {/* <option value="">None</option>
            <option value={10}>Ten</option>
            <option value={20}>Twenty</option>
            <option value={30}>Thirty</option> */}
            </Select>
          </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
          <FormControl className={classes.formControl}>
            <TextField id="standard-basic" label="Standard" />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="filled-basic" label="Filled" variant="filled" />{' '}
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField id="outlined-basic" label="Outlined" variant="outlined" />
          </FormControl>
        </FormGroup>

        <div>
          <TextField required id="standard-required" label="Required" defaultValue="Hello World" />
          <TextField disabled id="standard-disabled" label="Disabled" defaultValue="Hello World" />
          <TextField
            id="standard-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
          <TextField
            id="standard-read-only-input"
            label="Read Only"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="standard-number"
            label="Number"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField id="standard-search" label="Search field" type="search" />
          <TextField
            id="standard-helperText"
            label="Helper text"
            defaultValue="Default Value"
            helperText="Some important text"
          />
        </div>
        <div>
          <TextField
            required
            id="filled-required"
            label="Required"
            defaultValue="Hello World"
            variant="filled"
          />
          <TextField
            disabled
            id="filled-disabled"
            label="Disabled"
            defaultValue="Hello World"
            variant="filled"
          />
          <TextField
            id="filled-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="filled"
          />
          <TextField
            id="filled-read-only-input"
            label="Read Only"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
          <TextField
            id="filled-number"
            label="Number"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
          />
          <TextField id="filled-search" label="Search field" type="search" variant="filled" />
          <TextField
            id="filled-helperText"
            label="Helper text"
            defaultValue="Default Value"
            helperText="Some important text"
            variant="filled"
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="Required"
            defaultValue="Hello World"
            variant="outlined"
          />
          <TextField
            disabled
            id="outlined-disabled"
            label="Disabled"
            defaultValue="Hello World"
            variant="outlined"
          />
          <TextField
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
          />
          <TextField
            id="outlined-read-only-input"
            label="Read Only"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-number"
            label="Number"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField id="outlined-search" label="Search field" type="search" variant="outlined" />
          <TextField
            id="outlined-helperText"
            label="Helper text"
            defaultValue="Default Value"
            helperText="Some important text"
            variant="outlined"
          />
        </div>

        <FormGroup column className={classes.formGroup}>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleCheck} name="bar" />}
            label="Checkbox"
            key="bar"
          />
          <FormControlLabel
            control={<Radio checked={false} name="bar" />}
            label="Radio button"
            key="bar"
          />
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            label="Show Item to Locations"
            control={
              <Switch checked={showLocation} onChange={changeShowLocation} name="showLocation" />
            }
          />
        </FormGroup>
      </form>
      <Divider variant="middle" />

      <Typography gutterBottom variant="h4" component="h3">
        Button example:
      </Typography>

      <Button autoFocus color="primary" size="medium" variant="outlined" onClick={handleClose}>
        Lorem
      </Button>
      <Button autoFocus color="primary" size="large" variant="outlined" onClick={handleClose}>
        Lorem
      </Button>
    </>
  );
};

export default DemoPage;
