import { gql } from '@apollo/client';

const CREATE_ALLERGEN = gql`
  mutation CreateAllergen($input: CreateAllergenMutationInput!, $first: Int) {
    createAllergen(input: $input) {
      viewer {
        id
        allergenConnection(first: $first) {
          availableFilters
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              permission
              allergenId
              label
              description
              created
            }
          }
        }
      }
    }
  }
`;

export default CREATE_ALLERGEN;
