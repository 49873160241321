import { gql } from '@apollo/client';
import personFragment from './personFragment';

export const quoteForCateringFragment = {
  quote: gql`
    fragment QuoteFromCateringData on Quote {
      id
      quoteId
      locationId
      menuId
      category {
        id
        label
      }
      handoff {
        id
        label
      }
      expected
      email
      dueAmount
      paid
      subtotalPrice
      totalPrice
      discountAmount
      discountFraction
      discountFractionAmount
      couponAmount
      additionalCharge
      totalTax
      tip
      note
      distance
      refunded
      lineConnection {
        edges {
          node {
            id
            subcategory {
              id
              subcategoryId
            }
            quantity
            price
            totalPrice
            totalUnitPrice
            totalPrice
            item {
              id
              itemId
              label
            }
            note
            lineMultipleConnection {
              edges {
                node {
                  id
                  price
                  quantity
                  multiple {
                    id
                    label
                  }
                  choice {
                    id
                    choiceId
                    label
                  }
                }
              }
            }
          }
        }
      }
      location {
        id
        menuId
        locationId
        storeNumber
        locationnoteConnection {
          edges {
            node {
              id
              note
              created
            }
          }
        }
        created
        active
        email
        label
        timezone {
          id
          label
        }
        phone {
          id
          phone
        }
        address {
          id
          address
          city
          longitude
          latitude
          zip
          state {
            id
            label
            stateId
            abbreviation
            country {
              id
              countryId
              alpha2
              label
            }
          }
        }
      }
      address {
        id
        address
        city
        longitude
        latitude
        state {
          id
          label
          abbreviation
          country {
            id
            countryId
            alpha2
            label
          }
        }
        zip
      }
      person {
        id
        personId
        firstName
        lastName
        dateOfBirth
        stripeAccount
        phone {
          id
          phone
        }
        login {
          id
          loginId
          email
        }
        taxId
      }
      taxBasePrice
      paymentConnection {
        edges {
          node {
            id
            paymentId
            tender {
              id
              label
            }
            refunded
            amount
            created
            paymentGatewayConnection(first: 1) {
              edges {
                node {
                  id
                  label
                  brand
                  paymentDetails {
                    isRefundable
                  }
                }
              }
            }
          }
        }
      }
    }
    ${personFragment.person}
  `,
};
