import { gql } from '@apollo/client';

const REMOVE_INGREDIENT = gql`
  mutation removeIngredient($input: RemoveIngredientMutationInput!) {
    removeIngredient(input: $input) {
      viewer {
        id
      }
      removedIngredientId
    }
  }
`;

export default REMOVE_INGREDIENT;
