import { gql } from "@apollo/client";

const UPDATE_TAX_RATE = gql`
  mutation UpdateTaxRate($input: UpdateTaxrateMutationInput!) {
    updateTaxrate(input: $input) {
      taxrate {
        id
      }
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_TAX_RATE;
