import { gql } from '@apollo/client';

export const REMOVE_GENERALLEDGER = gql`
  mutation REMOVE_GENERALLEDGER($generalledgerId: Int!) {
    removeGeneralledger(input: { generalledgerId: $generalledgerId }) {
      viewer {
        id
      }
      removed
    }
  }
`;
