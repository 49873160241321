import { gql } from '@apollo/client';

const REMOVE_OVERTIMERULES = gql`
  mutation RemoveOvertimerules($input: RemoveOvertimerulesMutationInput!) {
    removeOvertimerules(input: $input) {
      overtimerule{
        id
      }
    }
  }
`;

export default REMOVE_OVERTIMERULES;
