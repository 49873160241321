import { gql } from '@apollo/client';

const FETCH_LOCATIONS = gql`
  query fetchLocations($sort: [LocationSort]) {
    viewer {
      id
      locationConnection(first: 9999, sort: $sort) {
        edges {
          node {
            id
            distance
            label
            active
            locationId
            storeNumber
            menuId
            menu {
              label
            }
            address {
            id
            state {
              id
              abbreviation
            }
          }
            timezone {
              id
              label
            }
            locationnoteConnection {
              edges {
                node {
                  id
                  note
                  created
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_LOCATIONS;
