import React, { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { WASTE_LOG } from '../../../../../apollo/queries/inventory';
import uglifyId from '../../../../../utils/uglifyId';
import WasteLogView from './View';
import { CircularProgress } from '@material-ui/core';
import SAVE_WASTE_LOG from '../../../../../apollo/mutations/inventory/saveWasteLog';

function WasteLog() {
  let { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];

  const locationGraphQLId = useMemo(() => uglifyId('Location', locationId), [locationId]);

  const { data, error, refetch, variables, loading } = useQuery(WASTE_LOG, {
    variables: {
      id: locationGraphQLId,
      wasteLogDate: DateTime.local().toFormat('yyyy-LL-dd'),
    },
    notifyOnNetworkStatusChange: true,
  });

  const [saveWasteLog, { loading: saveWasteLogLoading }] = useMutation(SAVE_WASTE_LOG);

  const calculateCostProtein = useCallback(protein => protein.count * protein.protein.cost, []);

  if (error) return { error };
  if (loading) return <CircularProgress />;

  const calculateWasteLogTotal = wasteLog => {
    let wasteLogProteinsTotal = {
      yieldedCost: 0,
    };

    let wasteLogSidesTotal = {
      yieldedCost: 0,
    };

    wasteLog.proteins.forEach(protein => {
      wasteLogProteinsTotal.yieldedCost += protein.yieldedCost || 0;
    });

    wasteLog.sides.forEach(side => {
      wasteLogSidesTotal.yieldedCost += side.yieldedCost || 0;
    });

    return {
      wasteLogProteinsTotal,
      wasteLogSidesTotal,
      wasteLogTotal: {
        yieldedCost: wasteLogProteinsTotal.yieldedCost + wasteLogSidesTotal.yieldedCost,
      },
    };
  };

  const calculateCostSide = side => side.count * side.side.cost;

  const calculateWasteLog = apiData => {
    const proteins = apiData.proteins.map(protein => {
      if (protein.count) {
        const yieldedCost = calculateCostProtein(protein);

        return { ...protein, yieldedCost };
      } else {
        return protein;
      }
    });

    const sides = apiData.sides.map(side => {
      if (side.count) {
        const yieldedCost = calculateCostSide(side);

        return { ...side, yieldedCost };
      } else {
        return side;
      }
    });

    const wasteLog = {
      proteins,
      sides,
      isSubmitted: apiData.isSubmitted,
    };

    return { ...wasteLog, ...calculateWasteLogTotal(wasteLog) };
  };

  return (
    <WasteLogView
      locationId={locationId}
      data={data}
      variables={variables}
      calculateWasteLog={calculateWasteLog}
      calculateWasteLogTotal={calculateWasteLogTotal}
      calculateCostProtein={calculateCostProtein}
      calculateCostSide={calculateCostSide}
      saveWasteLog={saveWasteLog}
      refetch={refetch}
      saveWasteLogLoading={saveWasteLogLoading}
    />
  );
}

export default WasteLog;
