import React, { useState } from "react";
import {
  Select,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Elements, Modal } from "../../../../../../blocks";
import { Controller } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import ItemSelect from "./ItemSelect";
import CategorySelect from "./CategorySelect";
import HandoffSelect from "./HandoffsSelect";
import CurrencyInput from "../../../../../../blocks/CurrencyInput";
import { CurrencyInputFormat } from "../../../../../../../utils";
import SelectItemsNew from "./ItemSelect/ItemSelectNew";
import AddItemsModal from "../../DiscountRestrictions/Items/AddItemsModal";

const options = [
  { value: "dollar", label: "Dollar - $" },
  { value: "percent", label: "Percentage - %" },
  { value: "freeItem", label: "Free Item" },
];

function DiscountType({
  control,
  itemAddedNumberTimes,
  setItemAddedNumberTimes,
  discountDetailsItems,
  setDiscountDetailsItems,
  setSelectedSubcategoriesAction,
  selectedSubcategoriesAction,
  errors,
}) {
  const [discountType, setDiscountType] = useState("dollar");
  const [aplicateTo, setAplicateTo] = useState("item");

  const [openModal, setOpenModal] = useState(false);
  const [openSubcategoryModal, setOpenSubcategoryModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleOpenSubcategoryModal = () => {
    setOpenSubcategoryModal(true);
  };

  const handleCloseSubcategoryModal = () => {
    setOpenSubcategoryModal(false);
  };

  const renderFormFileds = () => {
    switch (discountType) {
      case "dollar":
        return (
          <Controller
            name="discountDetails.amount"
            control={control}
            // defaultValue={1}
            rules={{
              required: "Amount is required",
              validate: {
                positive: (value) => {
                  if (typeof value === "number") {
                    if (value >= 0) {
                      return true;
                    }
                  }
                  const price = CurrencyInputFormat(value);
                  const isPositive = price >= 0;
                  return isPositive;
                },
              },
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <CurrencyInput
                error={
                  errors?.discountDetails && errors?.discountDetails?.amount
                }
                helperText={
                  errors?.discountDetails && errors?.discountDetails?.amount
                    ? errors?.discountDetails?.amount?.message
                    : ""
                }
                control={control}
                name="discountDetails.amount"
                // defaultValue={1}
                label="Amount"
                onValueChange={(e) => {
                  onChange(e.value);
                }}
              />
            )}
          />
        );
      case "percent":
        return (
          <Controller
            name="discountDetails.amount"
            control={control}
            defaultValue={1}
            rules={{
              required: "Percentange is required",
            }}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                error={
                  errors?.discountDetails && errors?.discountDetails?.amount
                }
                helperText={
                  errors?.discountDetails && errors?.discountDetails?.amount
                    ? errors?.discountDetails?.amount?.message
                    : ""
                }
                style={{ width: "150px" }}
                onChange={onChange}
                type="number"
                value={value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 1,
                }}
                label="Value"
                variant="outlined"
              />
            )}
          />
        );
      case "freeItem":
        if (aplicateTo === "item") {
          return (
            <Controller
              name="discountDetails.quantity"
              control={control}
              defaultValue={1}
              rules={{
                required: "Quantity is required",
              }}
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  rules={{
                    required: "Quantity is required",
                  }}
                  error={
                    errors?.discountDetails && errors?.discountDetails?.quantity
                  }
                  helperText={
                    errors?.discountDetails && errors?.discountDetails?.quantity
                      ? errors?.discountDetails?.quantity?.message
                      : ""
                  }
                  style={{ width: "150px" }}
                  onChange={onChange}
                  type="number"
                  value={value}
                  inputProps={{
                    min: 1,
                  }}
                  label="Quantity"
                  variant="outlined"
                />
              )}
            />
          );
        }
        return "";
      default:
        return "";
    }
  };

  const renderAplicateToInputs = () => {
    switch (aplicateTo) {
      case "order":
        return "";
      case "item":
        return (
          <div>
            <div>
              <Button
                onClick={handleOpen}
                variant="outlined"
                style={{ width: "13rem" }}
              >
                + Add Items
              </Button>
            </div>
          </div>
        );
      case "category":
        return (
          <div>
            <div>
              <Button
                onClick={handleOpenSubcategoryModal}
                variant="outlined"
                style={{ width: "13rem" }}
              >
                + Add Subcategories
              </Button>
            </div>
          </div>
        );
      case "handoff":
        return <HandoffSelect control={control} errors={errors} />;
      default:
        return "";
    }
  };

  const renderMenuItems = () => {
    const full = [
      <MenuItem value="item">Item</MenuItem>,
      <MenuItem value="order">Order</MenuItem>,
      <MenuItem value="category">Subcategory</MenuItem>,
      <MenuItem value="handoff">Handoffs Charge</MenuItem>,
    ];

    const item = [<MenuItem value="item">Item</MenuItem>];

    switch (discountType) {
      case "dollar":
        return full;
      case "percent":
        return full;
      case "freeItem":
        setAplicateTo("item");
        return item;
      default:
        return full;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <div style={{ marginRight: 10 }}>
          <Controller
            name="discountDetails.discountType"
            control={control}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <Elements.ToggleButton
                style={{ margin: "1rem 1rem 1rem 0" }}
                options={options}
                value={discountType}
                handleToggleClick={(value) => {
                  setDiscountType(value);
                  onChange(value);
                  if (value === "freeItem") {
                    setAplicateTo("item");
                  }
                }}
              />
            )}
          />
        </div>
        {renderFormFileds()}
        {discountType !== "freeItem" && (
          <Controller
            name="discountDetails.aplicateTo"
            control={control}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Applicate to
                </InputLabel>
                <Select
                  style={{ width: 250 }}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setAplicateTo(e.target.value);
                  }}
                  label="Applicate to"
                >
                  {renderMenuItems()}
                </Select>
              </FormControl>
            )}
          />
        )}
        {renderAplicateToInputs()}
      </div>
      <div>
        {aplicateTo === "item" && (
          <SelectItemsNew
            title="Selected Items"
            discountDetailsItems={discountDetailsItems}
            setDiscountDetailsItems={setDiscountDetailsItems}
          />
        )}
        {aplicateTo === "category" && (
          <SelectItemsNew
            title="Selected Subcategories"
            discountDetailsItems={selectedSubcategoriesAction}
            setDiscountDetailsItems={setSelectedSubcategoriesAction}
          />
        )}
        <Modal open={openModal} handleClose={handleClose} title="Add Items">
          <AddItemsModal
            setExcludedItems={setDiscountDetailsItems}
            handleClose={handleClose}
            excludedItems={discountDetailsItems}
          />
        </Modal>
        <Modal
          open={openSubcategoryModal}
          handleClose={handleCloseSubcategoryModal}
          title="Add Subcategories"
        >
          <CategorySelect
            setSelectedSubcategoriesAction={setSelectedSubcategoriesAction}
            selectedSubcategoriesAction={selectedSubcategoriesAction}
            handleClose={handleCloseSubcategoryModal}
          />
        </Modal>
      </div>
    </div>
  );
}

export default DiscountType;
