import { gql } from '@apollo/client';

const REMOVE_ITEM_TYPE = gql`
  mutation deleteItemtype($input: deleteItemtypeMutationInput!) {
    deleteItemtype(input: $input) {
      viewer {
        id
      }
      deletedItemtypeId
    }
  }
`;

export default REMOVE_ITEM_TYPE;
