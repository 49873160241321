import React, { useContext, useMemo } from 'react';
import { Switch, Router } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { FETCH_INTROSPECTION } from '../apollo/queries';
import { CoreContext } from '../CoreContext';

import config from '../config';
import RouterWrapper from './RouterWrapper';
import { getMutationRequiredFields } from '../utils';

const RouteComponents = () => {
  const context = useContext(CoreContext);
  const appActions = context?.coreUtils?.getAppActions();

  let modules = [];

  // first and second level iteration
  config.modules.forEach(module => {
    const parent = module.id;
    if (module.children === undefined) {
      module.parents = [];
      modules = [...modules, module];
    } else {
      module.children.forEach(child => {
        child.parents = [parent];
        modules = [...modules, child];
      });
    }
  });

  // third level iteration
  modules.forEach(module => {
    const parent = module.id;
    if (module.children !== undefined) {
      module.children.forEach(child => {
        child.parents = [...module.parents, parent];
        modules = [...modules, child];
      });
    }
  });

  const renderRoutes = () =>
    modules.map(module => {
      const isVisible = appActions[module.screenAccessId]?.access;

      return isVisible ? (
        <RouterWrapper
          component={module.component}
          context={context}
          exact={module.exact != null ? module.exact : true}
          id={module.id}
          key={module.id}
          metaTitle={module.metaTitle || ''}
          parents={module.parents}
          path={module.path}
          breadcrumbs={module.breadcrumbs}
        />
      ) : null;
    });

  return <Switch>{renderRoutes()}</Switch>;
};

const Routes = ({ history }) => {
  const context = useContext(CoreContext);
  const { data } = useQuery(FETCH_INTROSPECTION);

  const mutationRequiredFields = useMemo(() => data && getMutationRequiredFields(data), [data]);

  return (
    <CoreContext.Provider value={{ ...context, mutationRequiredFields }}>
      <Router history={history}>
        <RouteComponents />
      </Router>
    </CoreContext.Provider>
  );
};

export default Routes;
