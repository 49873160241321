import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SNACKBAR_STATUS } from '../../../constants';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { convert, CurrencyInputFormat, uglifyId } from '../../../utils';
import { Modal } from '../../blocks';
import FETCH_MULTIPLE_CHOICE_TIERS from './setPricingMultiple.constants';
import UPDATE_MULTIPLE from '../../../apollo/mutations/menus/updateMultiple';

const SetPricingMultiple = ({
  choiceId,
  multipleId,
  choiceLabel,
  gqlMultipleId,
  gqlChoiceId,
  refetch,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [defaultPrice, setDefaultPrice] = useState();
  const [tiersConnection, setTiersConnection] = useState();

  const { setSnackbar } = useSnackbar();

  const [loadTiers, { loading: loadingTiers, data: multipleDataResponse }] = useLazyQuery(
    FETCH_MULTIPLE_CHOICE_TIERS,
    {
      onCompleted: data => {
        if (data?.viewer?.multipleChoiceTierConnection && data?.viewer?.platformConnection) {
          setTiersConnection(
            data?.viewer?.tierConnection?.edges.map(edge => {
              return {
                tierId: edge?.node?.id,
                tierPrice: edge?.node?.price,
                tierLabel: edge?.node.label,
                nameLabel: edge?.node?.label.replace(/\s+/g, '-').toLowerCase(),
                tierPlatforms: data?.viewer?.platformConnection,
                tierPriceData: data?.viewer?.multipleChoiceTierConnection?.edges.find(
                  tier => tier?.node?.tier?.id === edge?.node?.id,
                ),
              };
            }),
          );
        }
        if (data?.viewer?.multiple?.multipleChoiceConnection?.edges?.length) {
          setDefaultPrice({
            defaultMcpi: data.viewer.multiple.multipleChoiceConnection.edges[0].node.price,
            defaultMaxPrice:
              data.viewer.multiple.multipleChoiceConnection.edges[0].node.priceCap || '',
          });
        }
      },
      onError: err => {
        console.log(err, 'error...');
      },
      fetchPolicy: 'network-only',
    },
  );

  const multipleData = useMemo(() => {
    if (multipleDataResponse) {
      return multipleDataResponse?.viewer?.multiple?.multipleChoiceConnection?.edges[0]?.node;
    }
    return undefined;
  }, [multipleDataResponse]);

  const [updateMultipleChoice] = useMutation(UPDATE_MULTIPLE, {
    onCompleted: async () => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Multiple choice updated!',
      });
      setButtonDisabled(false);
      setOpenModal(false);
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      }),
        setButtonDisabled(false);
    },
  });

  const { handleSubmit, register, getValues, errors, reset, setError } = useForm({
    defaultValues: {},
  });

  const handleOpen = useCallback(() => {
    setOpenModal(true);
    loadTiers({
      variables: {
        id: multipleId,
        choiceId: choiceId,
        gqlMultipleId: gqlMultipleId,
      },
    });
  }, [multipleId, choiceId, loadTiers]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const getPriceCap = (index, platformHash) => {
    const platformId = tiersConnection[index]?.tierPlatforms?.edges.find(
      el => el?.node?.id === platformHash,
    )?.node?.id;

    const priceCapValue = tiersConnection[
      index
    ]?.tierPriceData?.node?.multipleChoiceTierPlatformConnection?.edges?.find(
      e => e?.node?.platform?.id === platformId,
    )?.node?.priceCap;

    if (typeof priceCapValue === 'number') {
      return convert.centsToDollars(priceCapValue);
    }
    return '';
  };

  useEffect(() => {
    if (defaultPrice && tiersConnection?.length) {
      const tiersFields = tiersConnection?.map((tier, index) => ({
        [`${tier.nameLabel}-mcpi`]:
          typeof tier?.tierPriceData?.node?.price === 'number'
            ? convert.centsToDollars(tier?.tierPriceData?.node?.price)
            : '',
        [`${tier.nameLabel}-oloMaxPrice`]: getPriceCap(
          index,
          'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
        ),
        [`${tier.nameLabel}-posMaxPrice`]: getPriceCap(
          index,
          'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
        ),
        [`${tier.nameLabel}-3pvMaxPrice`]: getPriceCap(
          index,
          'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
        ),
      }));
      const tiersProps = tiersFields?.reduce((acc, curr) => {
        return Object.assign(acc, curr);
      }, {});

      reset({
        defaultMaxPrice:
          typeof defaultPrice?.defaultMaxPrice === 'number'
            ? convert.centsToDollars(defaultPrice.defaultMaxPrice)
            : '',
        defaultMcpi:
          typeof defaultPrice?.defaultMcpi === 'number'
            ? convert.centsToDollars(defaultPrice.defaultMcpi)
            : '',
        ...tiersProps,
      });
    }
  }, [defaultPrice, tiersConnection]);

  const onSubmit = useCallback(
    async data => {
      try {
        tiersConnection.forEach(tier => {
          if (!getValues(`${tier.nameLabel}-mcpi`)) {
            if (
              getValues(`${tier.nameLabel}-oloMaxPrice`) !== '' ||
              getValues(`${tier.nameLabel}-posMaxPrice`) !== '' ||
              getValues(`${tier.nameLabel}-3pvMaxPrice`) !== ''
            ) {
              setError(`${tier.nameLabel}-mcpi`, { type: 'customError' });
              throw new Error(
                'MCPI can’t be empty if you’re trying to enter Max Price per Platform',
              );
            }
          }
        });

        const names = Object.keys(data);
        const values = Object.values(data);

        const dataSerialized = names.reduce((acc, curr, index) => {
          acc.push({
            name: curr,
            value: values[index],
          });
          return acc;
        }, []);

        const inputTiersData = tiersConnection?.map(data => {
          if (dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value) {
            return {
              tierId: data.tierId,
              price: dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value || null,
              platforms: data.tierPlatforms.edges.reduce((acc, curr) => {
                if (curr?.node?.id === 'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                  if (dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)?.value) {
                    acc.push({
                      platformId: curr?.node?.id,
                      priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)
                        ?.value
                        ? parseFloat(
                            dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)
                              ?.value,
                          )
                        : null,
                    });
                  }
                }
                if (curr?.node?.id === 'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                  if (dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)?.value) {
                    acc.push({
                      platformId: curr?.node?.id,
                      priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)
                        ?.value
                        ? parseFloat(
                            dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)
                              ?.value,
                          )
                        : null,
                    });
                  }
                }
                if (curr?.node?.id === 'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                  if (dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)?.value) {
                    acc.push({
                      platformId: curr?.node?.id,
                      priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)
                        ?.value
                        ? parseFloat(
                            dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)
                              ?.value,
                          )
                        : null,
                    });
                  }
                }
                return acc;
              }, []),
            };
          }
        });

        const input = {
          multipleId: gqlMultipleId,
          choices: {
            choiceId: gqlChoiceId,
            price: data.defaultMcpi,
            priceCap: data.defaultMaxPrice || null,
            orderKey: 1,
            tiers: inputTiersData.filter(data => data !== undefined),
            preparationTime: multipleData?.preparationTime,
            allowedMaximum: multipleData?.allowedMaximum,
            defaultSelected: multipleData?.defaultSelected,
          },
        };

        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.INFO,
          text: 'Updating Multiple choice...',
        });

        setButtonDisabled(true);
        await updateMultipleChoice({
          variables: {
            input,
          },
        });
        await refetch({
          id: uglifyId('Multiple', multipleId),
        });
      } catch (errorMessage) {
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: `${errorMessage}`,
        });
      }
    },
    [updateMultipleChoice, tiersConnection],
  );

  const forwardSave = data => {
    onSubmit(data);
  };

  const handleSubmitWithoutPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(forwardSave)(e);
  };

  const formRender = useCallback(() => {
    return tiersConnection && !loadingTiers ? (
      <Grid container>
        <form
          onSubmit={handleSubmitWithoutPropagation}
          style={{
            width: '100%',
          }}
        >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4" component="h4">
                Default
              </Typography>
            </Grid>
            <Grid
              container
              style={{
                gap: '10px',
                flexWrap: 'nowrap',
              }}
            >
              <Grid item xs={6}>
                <TextField
                  name="defaultMcpi"
                  label="Default MCPI"
                  type="text"
                  inputRef={register({
                    required: true,
                    pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                  })}
                  fullWidth
                  defaultValue={
                    defaultPrice?.defaultMcpi
                      ? convert.centsToDollars(defaultPrice?.defaultMcpi)
                      : ''
                  }
                  error={errors?.defaultMcpi}
                  helperText={
                    errors?.defaultMcpi &&
                    (errors?.defaultMcpi ? 'Default MCPI is required.' : null)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="defaultMaxPrice"
                  label="Default Max Price"
                  type="text"
                  inputRef={register({
                    pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                    validate: {
                      min: value => {
                        const priceValue = getValues('defaultMcpi');
                        return priceValue && value
                          ? parseFloat(value) >= parseFloat(priceValue)
                          : true;
                      },
                    },
                  })}
                  fullWidth
                  defaultValue={
                    typeof defaultPrice?.defaultMaxPrice === 'number'
                      ? convert.centsToDollars(defaultPrice.defaultMaxPrice)
                      : ''
                  }
                  error={errors?.defaultMaxPrice}
                  helperText={
                    errors?.defaultMaxPrice &&
                    (errors?.defaultMaxPrice?.type === 'min'
                      ? "Max Price can't be less than Default MCPI"
                      : 'Tier Max Price must be a positive number.')
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                justifyContent: 'space-between',
              }}
            >
              {tiersConnection?.map((tier, index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <Typography gutterBottom variant="h4" component="h4">
                      {tier.tierLabel}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    style={{
                      gap: '10px',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-mcpi`}
                        label={`${tier.tierLabel} MCPI`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                        defaultValue={
                          typeof tier?.tierPriceData?.node?.price === 'number'
                            ? convert.centsToDollars(tier?.tierPriceData?.node?.price)
                            : ''
                        }
                        error={errors[`${tier.nameLabel}-mcpi`]}
                        helperText={
                          errors[`${tier.nameLabel}-mcpi`]?.type === 'customError'
                            ? 'MCPI can’t be empty if you’re trying to enter Max Price per Platform'
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-posMaxPrice`}
                        label={`${tier.tierLabel} POS Max Price`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                        defaultValue={getPriceCap(
                          index,
                          'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-oloMaxPrice`}
                        label={`${tier.tierLabel} OLO Max Price`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                        defaultValue={getPriceCap(
                          index,
                          'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-3pvMaxPrice`}
                        label={`${tier.tierLabel} 3PV Max Price`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                        defaultValue={getPriceCap(
                          index,
                          'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
            <Grid
              container
              style={{
                justifyContent: 'flex-end',
                marginTop: '16px',
              }}
            >
              <Grid item>
                <Button
                  onClick={handleClose}
                  style={{
                    marginRight: '14px',
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={buttonDisabled}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    ) : (
      <Typography gutterBottom variant="h5" component="h5">
        Loading...
      </Typography>
    );
  }, [tiersConnection, errors, buttonDisabled, handleClose, getPriceCap, loadingTiers]);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Set Pricing
      </Button>
      <Modal open={openModal} handleClose={handleClose} title={choiceLabel} maxWidth="lg">
        {formRender()}
      </Modal>
    </>
  );
};

export default SetPricingMultiple;
