import { gql } from '@apollo/client';

const REMOVE_ITEM_MULTIPLE = gql`
  mutation removeItemMultiple($input: RemoveItemMultipleInput!) {
    removeItemMultiple(input: $input) {
      item {
        id
        multipleConnection {
          edges {
            node {
              id
              label
              description
              multipleId
              multipleChoiceConnection {
                edges {
                  node {
                    id
                    choice {
                      id
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default REMOVE_ITEM_MULTIPLE;
