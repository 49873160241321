import visa from '../assets/icons/CreditCards/visa.png';
import mastercard from '../assets/icons/CreditCards/mastercard.png';
import discover from '../assets/icons/CreditCards/discover.png';
import JCB from '../assets/icons/CreditCards/JCB.png';
import DCI from '../assets/icons/CreditCards/discover.png';
import union from '../assets/icons/CreditCards/union_pay.png';
import NA from '../assets/icons/CreditCards/n-a_card.png';

function setCreditCardImage(cardBrand) {
  switch (cardBrand) {
    case 'Visa':
      return visa;
    case 'MasterCard':
      return mastercard;
    case 'Discover':
      return discover;
    case 'Diners Club':
      return DCI;
    case 'JCB':
      return JCB;
    case 'UnionPay':
      return union;
    default:
      return NA;
  }
}
export  {
  setCreditCardImage
};
