import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import { convert, generateStoreAbbreviation } from '../';

const getEmployeeOvertimeData = (locationPersons, weeklyTotalValues) => {
  const getEmployeeTimeshifts = timeshifts => {
    const timeshiftData = [];
    let totalOTfee = 0;
    let totalWHfee = 0;
    let totalFee = 0;
    let totalWhHours = 0;
    let totalOtHours = 0;
    let totalHours = 0;
    if (timeshifts.length) {
      timeshifts.forEach(timeshift => {
        totalHours += timeshift?.node?.isFinished
          ? moment
              .duration(moment(timeshift?.node?.finished).diff(moment(timeshift?.node?.started)))
              .asHours()
          : 0;
        totalOTfee += !timeshift?.node?.overtime?.error ? timeshift?.node?.overtime?.fee : 0;
        totalWHfee += !timeshift?.node?.wages?.error ? timeshift?.node?.wages?.fee : 0;
        totalFee += !timeshift?.node?.shiftTotal?.error ? timeshift?.node?.shiftTotal?.fee : 0;
        totalWhHours += !timeshift?.node?.wages?.error ? timeshift?.node?.wages?.hours : 0;
        totalOtHours += !timeshift?.node?.overtime?.error ? timeshift?.node?.overtime?.hours : 0;

        weeklyTotalValues.allHours +=
          (!timeshift?.node?.wages?.error ? timeshift?.node?.overtime?.hours : 0) +
          (!timeshift?.node?.overtime?.error ? timeshift?.node?.overtime?.hours : 0);
        weeklyTotalValues.allOtFee += !timeshift?.node?.overtime?.error
          ? timeshift?.node?.overtime?.fee
          : 0;
        weeklyTotalValues.allWhFee += !timeshift?.node?.wages?.error
          ? timeshift?.node?.wages?.fee
          : 0;
        weeklyTotalValues.allFee += !timeshift?.node?.shiftTotal?.error
          ? timeshift?.node?.shiftTotal?.fee
          : 0;
        weeklyTotalValues.allWhHours += !timeshift?.node?.wages?.error
          ? timeshift?.node?.wages?.hours
          : 0;
        weeklyTotalValues.allOtHours += !timeshift?.node?.overtime?.error
          ? timeshift?.node?.overtime?.hours
          : 0;

        timeshiftData.push([
          { text: timeshift?.node?.job?.label, margin: [0, 10, 0, 0] },
          {
            text: moment
              .tz(moment.utc(timeshift?.node?.started), timeshift?.node?.location?.timezone?.label)
              .format('MM/DD/YYYY hh:mm A'),
            margin: [0, 10, 0, 0],
          },
          {
            text: moment
              .tz(moment.utc(timeshift?.node?.finished), timeshift?.node?.location?.timezone?.label)
              .format('MM/DD/YYYY hh:mm A'),
            margin: [0, 10, 0, 0],
          },
          { text: `$${convert.centsToDollars(timeshift?.node?.tip)}`, margin: [0, 10, 0, 0] },
          {
            text: timeshift?.node?.wages?.error || `$${timeshift?.node?.wages?.rate}`,
            margin: [0, 10, 0, 0],
          },
          {
            text: timeshift?.node?.overtime?.error || `$${timeshift?.node?.overtime?.rate}`,
            margin: [0, 10, 0, 0],
          },
          {
            text:
              timeshift?.node?.overtime?.error ||
              `$${convert.centsToDollars(timeshift?.node?.overtime?.fee)}`,
            margin: [0, 10, 0, 0],
          },
          {
            text:
              timeshift?.node?.wages?.error ||
              convert.decimalHoursToHHmm(timeshift?.node?.wages?.hours),
            margin: [0, 10, 0, 0],
          },
          {
            text:
              timeshift?.node?.overtime?.error ||
              convert.decimalHoursToHHmm(timeshift?.node?.overtime?.hours),
            margin: [0, 10, 0, 0],
          },
          {
            text:
              timeshift?.node?.wages?.error ||
              `$${convert.centsToDollars(timeshift?.node?.wages?.fee)}`,
            margin: [0, 10, 0, 0],
          },
          {
            text: timeshift?.node?.isFinished
              ? convert.decimalHoursToHHmm(
                  moment
                    .duration(
                      moment(timeshift?.node?.finished).diff(moment(timeshift?.node?.started)),
                    )
                    .asHours(),
                )
              : 'Not clocked out',
            margin: [0, 10, 0, 0],
          },
          {
            text:
              timeshift?.node?.shiftTotal?.error ||
              `$${convert.centsToDollars(timeshift?.node?.shiftTotal?.fee)}`,
            margin: [0, 10, 0, 0],
          },
        ]);
      });

      timeshiftData.push([
        { text: 'Total:', bold: true, color: 'black', margin: [0, 0, 0, 10] },
        '',
        '',
        '',
        '',
        '',
        {
          text: `$${convert.centsToDollars(totalOTfee)}`,
          bold: true,
          color: 'black',
          margin: [0, 0, 0, 10],
        },
        {
          text: convert.decimalHoursToHHmm(totalWhHours),
          bold: true,
          color: 'black',
          margin: [0, 0, 0, 10],
        },
        {
          text: convert.decimalHoursToHHmm(totalOtHours),
          bold: true,
          color: 'black',
          margin: [0, 0, 0, 10],
        },
        {
          text: `$${convert.centsToDollars(totalWHfee)}`,
          bold: true,
          color: 'black',
          margin: [0, 0, 0, 10],
        },
        {
          text: convert.decimalHoursToHHmm(totalHours),
          bold: true,
          color: 'black',
          margin: [0, 0, 0, 10],
        },
        {
          text: `$${convert.centsToDollars(totalFee)}`,
          bold: true,
          color: 'black',
          margin: [0, 0, 0, 10],
        },
      ]);
    } else {
      timeshiftData.push('', '', '', '', '', '', '', '', '', '', '', '');
    }

    return timeshiftData;
  };

  const overtimes = [];

  locationPersons.forEach(locationPerson => {
    overtimes.push([
      {
        text: `${locationPerson?.node?.person?.firstName} ${locationPerson?.node?.person?.lastName}`,
        margin: [0, 10, 0, 0],
      },
      {
        layout: 'lightHorizontalLines', // optional
        table: {
          widths: [40, 65, 65, 30, 35, 35, 35, 35, 35, 35, 30, 40],
          body: getEmployeeTimeshifts(locationPerson?.node?.person?.timeshiftConnection?.edges),
        },
      },
    ]);
  });

  return overtimes;
};

const generateOvertimePdf = (locationPersons, started, finished, location) => {
  const weeklyTotalValues = {
    allOtFee: 0,
    allWhHours: 0,
    allOtHours: 0,
    allWhFee: 0,
    allHours: 0,
    allFee: 0,
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const docDefinition = {
    pageOrientation: 'landscape',
    pageSize: 'A4',
    content: [
      {
        table: {
          widths: ['60%', '10%', '10%', '10%'],
          body: [
            [
              [
                {
                  text: 'Location code',
                  alignment: 'left',
                  style: 'header',
                  margin: [0, 10, 0, -10],
                },
              ],
            ],
          ],
        },
        layout: 'noBorders',
      },
      {
        table: {
          widths: ['60%', '10%', '30%', '10%'],
          body: [
            [
              [
                {
                  text: generateStoreAbbreviation(location).abbreviation,
                  alignment: 'left',
                  style: 'header',
                  margin: [0, 10, 0, 30],
                },
              ],
            ],
          ],
        },
        layout: 'noBorders',
      },
      {
        widths: [40, 65, 65, 30, 30, 35, 35, 35, 35, 35, 30, 40],
        columns: [
          { text: 'Employee', width: '*', style: 'header' },
          { text: 'Job Name', width: '*', style: 'header' },
          { text: 'Clock In Dttm', width: '*', style: 'header' },
          { text: 'Clock Out Dttm', width: '*', style: 'header' },
          { text: 'Tips', width: '*', style: 'header' },
          { text: 'Shift Pay Rate', width: '*', style: 'header' },
          { text: 'OT Pay Rate', width: '*', style: 'header' },
          { text: 'OT Pay Amt', width: '*', style: 'header' },
          { text: 'Shift Hour Duration', width: '*', style: 'header' },
          { text: 'OT Hour Duration', width: '*', style: 'header' },
          { text: 'Shft Pay Amt', width: '*', style: 'header' },
          { text: 'Total hours', width: '*', style: 'header' },
          { text: 'Total Payment', width: '*', style: 'header' },
        ],
      },
      {
        layout: 'lightHorizontalLines', // optiona
        table: {
          body: [
            [
              {
                layout: 'lightHorizontalLines', // optional
                table: {
                  body: getEmployeeOvertimeData(locationPersons, weeklyTotalValues),
                },
              },
            ],
            [
              {
                layout: 'lightHorizontalLines', // optional
                table: {
                  widths: [40, 190, 65, 65, 30, 30, 35, 35, 35, 35, 35],
                  body: [
                    [
                      { text: 'TOTAL:', bold: true, color: 'black' },
                      '',
                      '',
                      '',
                      '',
                      {
                        text: `$${convert.centsToDollars(weeklyTotalValues.allOtFee)}`,
                        bold: true,
                        color: 'black',
                      },
                      {
                        text: convert.decimalHoursToHHmm(weeklyTotalValues.allWhHours),
                        bold: true,
                        color: 'black',
                      },
                      {
                        text: convert.decimalHoursToHHmm(weeklyTotalValues.allOtHours),
                        bold: true,
                        color: 'black',
                      },
                      {
                        text: `$${convert.centsToDollars(weeklyTotalValues.allWhFee)}`,
                        bold: true,
                        color: 'black',
                      },
                      {
                        text: convert.decimalHoursToHHmm(weeklyTotalValues.allHours),

                        bold: true,
                        color: 'black',
                      },
                      {
                        text: `$${convert.centsToDollars(weeklyTotalValues.allFee)}`,
                        bold: true,
                        color: 'black',
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 10,
        bold: true,
        color: 'black',
        alignment: 'center',
        margin: [0, 10, 10, 0],
      },
      tableHeader: {
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 10,
      color: '#444444',
    },
  };

  pdfMake.createPdf(docDefinition).open();
};

export default generateOvertimePdf;
