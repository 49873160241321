import { gql } from '@apollo/client';

const SUBCATEGORY_ITEM = gql`
  query subcategoryItem($filter: [SubcategoryItemFilter], $locationIdPrice: Int) {
    viewer {
      id
      subcategoryItemConnection(filter: $filter) {
        edges {
          node {
            id
            subcategoryId
            item {
              id
              itemId
              label
              minQuantity
              maxQuantity
              price(locationId: $locationIdPrice)
            }
          }
        }
      }
    }
  }
`;

export default SUBCATEGORY_ITEM;
