import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import ConnectionTable from "../../../../../blocks/ConnectionTable";
import {
  CREATE_TIER,
  UPDATE_TIER,
  REMOVE_TIER,
} from "../../../../../../apollo/mutations";
import { FETCH_TIER_LIST } from "../../../../../../apollo/queries";
import TierForm from "../TierForm";
import { SNACKBAR_STATUS } from "../../../../../../constants";
import { compact } from "lodash";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";

const TiersView = () => {
  const { setSnackbar } = useSnackbar();
  
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [tier, setTier] = useState({});
  const [locationIds, setLocationIds] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openCreateModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openCreateModal]);

  const [createTier] = useMutation(CREATE_TIER);
  const [updateTier] = useMutation(UPDATE_TIER);
  const [removeTier] = useMutation(REMOVE_TIER);

  const { handleSubmit, control, register, reset, errors } = useForm({
    defaultValues: {
      label: "",
      description: "",
    },
  });

  useEffect(() => {
    if (tier) {
      reset(tier);
    }
  }, [tier, reset]);

  const handleClose = () => {
    setOpenCreateModal(false);
    setOpenUpdateModal(false);
    setLocationIds([]);
    setTier({});
  };

  const onSubmit = async ({ label, description }) => {
    const temp_locationIds = locationIds.map((loc) => loc.id);
    setButtonDisabled(true);
    if (tier.id) {
      await updateTier({
        variables: {
          input: {
            tierId: tier.tierId,
            label,
            description,
            locationIds: temp_locationIds,
          },
        },
        refetchQueries: [{ query: FETCH_TIER_LIST }],
      });
    } else {
      await createTier({
        variables: {
          input: {
            label,
            description,
            locationIds: temp_locationIds,
          },
        },
        refetchQueries: [{ query: FETCH_TIER_LIST }],
      });
    }

    handleClose();
    setButtonDisabled(false);

    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.SUCCESS,
      text: tier?.id ? "Tier updated." : "Tier created.",
    });
  };

  const handleDeleteTier = useCallback(
    (tier, variables) => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.INFO,
        text: "Deleting tier...",
      });

      removeTier({
        variables: {
          input: {
            tierId: tier.tierId,
          },
        },
        refetchQueries: [{ query: FETCH_TIER_LIST }],
      })
        .then(() =>
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: "Tier deleted",
          })
        )
        .catch((error) =>
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
          })
        );
    },
    [removeTier]
  );

  const handleEditTier = useCallback((tier) => {
    setTier(tier);
    let tiersCopy = tier.locationFull.map((edge) => {
      const copy = {...edge.node};
      copy.label = compact([copy.label, copy.storeNumber]).join(' - #');
      return copy;
    });
    setLocationIds(tiersCopy);
    setOpenUpdateModal(true);
  }, []);

  return (
    <div>
      <ConnectionTable
        query={FETCH_TIER_LIST}
        title="Tier Settings"
        customActionsTop={(data, query) => {
          return (
            <Button onClick={() => setOpenCreateModal(true)}>New Tier</Button>
          );
        }}
        onTableRowClick={handleEditTier}
        structureTable={(data) =>
          data?.viewer?.tierConnection?.edges.map((edge) => {
            return {
              id: edge.node.id,
              tierId: edge.node.tierId,
              label: edge.node.label,
              tier: edge.node,
              locationFull: edge.node.locationConnection.edges,
              locations: edge.node.locationConnection.edges.map(
                (edge) => edge.node.label + ", "
              ),
            };
          })
        }
        columns={[
          { title: "Id", field: "tierId" },
          { title: "Tier Name", field: "label" },
          { title: "Stores", field: "locations" },
        ]}
        handleDeleteRow={handleDeleteTier}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this tier?"
      />
      <TierForm
        title={"Create Tier"}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        open={openCreateModal}
        control={control}
        register={register}
        errors={errors}
        descriptionElementRef={descriptionElementRef}
        handleClose={handleClose}
        buttonDisabled={buttonDisabled}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
      />
      <TierForm
        title={"Update Tier"}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        open={openUpdateModal}
        control={control}
        register={register}
        errors={errors}
        descriptionElementRef={descriptionElementRef}
        handleClose={handleClose}
        buttonDisabled={buttonDisabled}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
      />
    </div>
  );
};

TiersView.propTypes = {
};

export default TiersView;
