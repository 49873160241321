import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import ConnectionTable from "../../../../../blocks/ConnectionTable";
import {
  CREATE_WAGE_RULE,
  UPDATE_WAGE_RULE,
  REMOVE_WAGE_RULE,
} from "../../../../../../apollo/mutations";
import { WAGE_RULES_LIST } from "../../../../../../apollo/queries";
import MinimumWageRuleForm from "../MinimumWageRuleForm";
import convert from "../../../../../../utils/convert";
import { SNACKBAR_STATUS } from "../../../../../../constants";
import { Permission, PermissionField } from "../../../../../blocks";
import { useFormSubmitPermissions } from "../../../../../../hooks";
import { MUTATION_NAME } from '../../../../../../constants'
import { compact } from "lodash";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";

const MinimumWagesView = ({ mutationPermissions }) => {
  const { setSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState();

  const createPermission = useMemo(() => mutationPermissions.find(permission => permission.label === "createWageRule"),
    [mutationPermissions]
  );
  const updatePermission = useMemo(() => mutationPermissions.find(permission => permission.label === "updateWageRule"),
  [mutationPermissions]
);
  const deletePermission = useMemo(() => mutationPermissions.find(permission => permission.label === "removeWageRule"),
    [mutationPermissions]
  );

  const [openCreateModal, setOpenCreateModal] = useState({
    open: false,
    permissions: null,
  });
  const [openUpdateModal, setOpenUpdateModal] = useState({
    open: false,
    permissions: null,
  });
  const [minimumWageRule, setMinimumWageRule] = useState({});
  const [locationIds, setLocationIds] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [MUTATION_NAME.UpdateWageRuleMutationInput, MUTATION_NAME.CreateWageRuleMutationInput],
    openWarningMutationName: undefined,
    permissions,
  });

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openCreateModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openCreateModal]);

  const [
    createMinimumWageRule,
    { loading: createMinimumWageRuleLoading },
  ] = useMutation(CREATE_WAGE_RULE);
  const [
    updateMinimumWageRule,
    { loading: updateMinimumWageRuleLoading },
  ] = useMutation(UPDATE_WAGE_RULE);
  const [
    removeMinimumWageRule,
    { loading: isDeletingMinimumWageRule },
  ] = useMutation(REMOVE_WAGE_RULE);

  const { handleSubmit, control, register, reset, errors } = useForm({
    defaultValues: {
      label: "",
      minimumWage: "0",
      tipCreditWage: "0",
    },
  });

  useEffect(() => {
    if (minimumWageRule) {
      reset(minimumWageRule);
    }
  }, [minimumWageRule, reset]);

  const handleClose = () => {
    setOpenCreateModal({
      open: false,
      permissions: null,
    });
    setOpenUpdateModal({
      open: false,
      permissions: null,
    });
    setMinimumWageRule({});
  };

  const onSubmit = async (formData) => {
    const { label, minimumWage, tipCreditWage } = formData;

    setButtonDisabled(true);
    if (minimumWageRule.wageRuleId) {
      await updateMinimumWageRule({
        variables: {
          input: {
            wageRuleId: minimumWageRule.wageRuleId,
            label,
            minimumWage: minimumWage ? convert.dollarsToCents(minimumWage) : undefined,
            tipCreditWage: tipCreditWage ? convert.dollarsToCents(tipCreditWage) : undefined,
            locationIds,
          },
        },
        refetchQueries: [{ query: WAGE_RULES_LIST }],
      });
      handleClose();
      setButtonDisabled(false);
      return;
    }

    await createMinimumWageRule({
      variables: {
        input: {
          label,
          minimumWage: convert.dollarsToCents(minimumWage),
          tipCreditWage: convert.dollarsToCents(tipCreditWage),
          locationIds,
        },
      },
      refetchQueries: [{ query: WAGE_RULES_LIST }],
    });

    handleClose();
    setButtonDisabled(false);
  };

  const handleDeleteRow = useCallback(
    (row) => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: "Deleting wage rule...",
        open: true,
      });

      removeMinimumWageRule({
        variables: {
          input: {
            wageRuleId: row.wageRuleId,
          },
        },
        refetchQueries: [{ query: WAGE_RULES_LIST }],
      })
        .then(() =>
          setSnackbar({
            type: SNACKBAR_STATUS.SUCCESS,
            text: "Wage rule deleted",
            open: true,
          })
        )
        .catch((error) =>
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
            open: true,
          })
        );
    },
    [removeMinimumWageRule, setSnackbar]
  );

  const handleEditMinimumWage = useCallback((wageRule, data) => {
    setMinimumWageRule(wageRule);
    setOpenUpdateModal({
      open: true,
      permissions: data?.viewer?.wageruleConnection?.permissions,
      locations: wageRule?.wageRule?.locationConnection.edges.map(
        edge => ({
          label: compact([edge.node.label, edge.node.storeNumber]).join(' - #'),
          id: edge.node.id,
          locationId: edge.node.locationId,
        })
      ),
    });
    setOpenWarningMutationName(MUTATION_NAME.UpdateWageRuleMutationInput);
  }, [setOpenWarningMutationName]);

  const handleOpenCreateModal = useCallback((data) => {
      setOpenCreateModal({
        open: true,
        permissions: data?.viewer?.wageruleConnection?.permissions,
      });
      setOpenWarningMutationName(MUTATION_NAME.CreateWageRuleMutationInput);
  }, [setOpenWarningMutationName])

  return (
    <div>
      <ConnectionTable
        query={WAGE_RULES_LIST}
        title="Minimum Wage Rules"
        customActionsTop={(data) => {
          if (!permissions) {
            setPermissions({
              ...data?.viewer?.wageruleConnection?.permissions,
              locationIds: data?.viewer?.wageruleConnection?.permissions?.locationConnection?.edges?.node?.id,
              wageRuleId: data?.viewer?.wageruleConnection?.permissions?.wageruleId,
            });
          }

          return (
            <Permission access={Math.min(createPermission?.access, accessToMutations?.[MUTATION_NAME.CreateWageRuleMutationInput])}>
              <div>
                <PermissionField createHelperText>
                  <Button
                    disabled={
                      updateMinimumWageRuleLoading ||
                      createMinimumWageRuleLoading ||
                      isDeletingMinimumWageRule
                    }
                    onClick={() => handleOpenCreateModal(data)}
                  >
                    New Minimum Wage Rule
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          );
        }}
        structureTable={(data) => {
          const permissions = data?.viewer?.wageruleConnection?.permissions;
          return data?.viewer?.wageruleConnection?.edges.map((edge) => {
            return {
              id: edge.node.id,
              wageRuleId: edge.node.wageruleId,
              label: edge.node.label,
              minimumWage: edge.node?.minimumWage && convert.centsToDollars(edge.node.minimumWage),
              tipCreditWage: edge.node?.tipCreditWage && convert.centsToDollars(edge.node.tipCreditWage),
              wageRule: edge.node,
              permissions,
              disableTableRowClick: updatePermission?.access,
              disableDelete: !deletePermission?.access
            };
          });
        }}
        columns={[
          { title: "Id", field: "wageRuleId", customPermissionField: 'wageruleId' },
          { title: "Rule Name", field: "label" },
          { title: "Minimum wage", field: "minimumWage" },
          { title: "Tip credit wage", field: "tipCreditWage" },
        ]}
        handleDeleteRow={handleDeleteRow}
        onTableRowClick={handleEditMinimumWage}
        isDeleting={isDeletingMinimumWageRule}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delele minimum wage?"
      />
      <MinimumWageRuleForm
        title={"Create Minimum Wage Rule"}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        open={openCreateModal.open}
        mutationPermissions={mutationPermissions}
        control={control}
        permissions={openCreateModal.permissions}
        register={register}
        errors={errors}
        descriptionElementRef={descriptionElementRef}
        handleClose={handleClose}
        buttonDisabled={buttonDisabled || accessToMutations.CreateWageRuleMutationInput < 7}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
        wageRuleId={minimumWageRule?.wageRuleId}
      />
      <MinimumWageRuleForm
        title={"Update Minimum Wage Rule"}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        open={openUpdateModal.open}
        control={control}
        permissions={openUpdateModal.permissions}
        mutationPermissions={mutationPermissions}
        register={register}
        errors={errors}
        descriptionElementRef={descriptionElementRef}
        handleClose={handleClose}
        buttonDisabled={buttonDisabled || accessToMutations.UpdateMinimumWageRuleMutationInput < 7}
        locations={openUpdateModal.locations}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
      />
    </div>
  );
};

MinimumWagesView.propTypes = {
};

export default MinimumWagesView;
