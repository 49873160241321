import { gql } from '@apollo/client';

const ADD_LOCATION_NOTE = gql`
  mutation AddLocationNote($input: NewLocationnoteMutationInput!) {
    newLocationnote(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default ADD_LOCATION_NOTE;
