import React, { useState, useCallback } from 'react';
import { Button, Grid } from '@material-ui/core';

import ItemGroup from './ItemGroup';
import ItemAddedNumberTimesStyles from './styles';

function ItemAddedNumberTimes({ control, errors }) {
  const classes = ItemAddedNumberTimesStyles();

  const [itemGroups, setItemGroups] = useState([{}]);

  const handleAddGroup = useCallback(() => {
    setItemGroups([...itemGroups, {}]);
  }, [itemGroups]);

  const handleDelete = useCallback(
    index => {
      const tempItemGroups = [...itemGroups];
      tempItemGroups[index] = null;
      setItemGroups(tempItemGroups);
    },
    [itemGroups],
  );

  return (
    <Grid container className={classes.triggerContainer}>
      <Grid item xs={12}>
        <Button onClick={handleAddGroup}>Add Group</Button>
      </Grid>
      {itemGroups?.map((group, index) => {
        if (group) {
          return (
            <Grid item xs={12} className={classes.groups}>
              <ItemGroup
                control={control}
                index={index}
                handleDelete={handleDelete}
                errors={errors}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

export default ItemAddedNumberTimes;
