import React, { useEffect, useState } from 'react';
import AutoComplete from '../../../../../../../blocks/AutoComplete';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FETCH_ITEM_LIST, FETCH_ITEMS } from '../../../../../../../../apollo/queries';
import { TextField, FormControl } from '@material-ui/core';
import { SelectFactory } from '../../../../../../../blocks';

function ItemSelect({
  control,
  name = 'discountDetails.item',
  isTrigger,
  isEvent,
  setItem,
  discountDetailsItems,
  setDiscountDetailsItems,
  errors,
  includeSubcategories = true,
}) {
  const [itemList, setItemList] = useState([
    {
      label: 'Loading....',
      value: 1,
    },
  ]);

  const [fetchItems, { data, loading, error }] = useLazyQuery(
    includeSubcategories ? FETCH_ITEM_LIST : FETCH_ITEMS,
    {
      onCompleted: data => {
        let itemList = data?.viewer?.itemConnection?.edges.map(item => ({
          label: `${item.node.label} -  ID_${item.node.itemId}`,
          value: item.node.id,
          isItem: true,
          id: item.node.id,
        }));
        setItemList(itemList);
      },
      variables: {
        filter: {
          showHiddenToLocation: {
            eq: true,
          },
          showUnavailableOnline: {
            eq: true,
          },
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (isTrigger) {
      fetchItems({
        variables: {
          filter: {
            showHiddenToLocation: {
              eq: true,
            },
            showUnavailableOnline: {
              eq: true,
            },
          },
        },
      });
    }
  }, [name]);

  return (
    <div style={{ width: '50%' }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        {!isTrigger && (
          <SelectFactory
            label={'Items'}
            placeholder={'Select'}
            query={includeSubcategories ? FETCH_ITEM_LIST : FETCH_ITEMS}
            filter={{
              showHiddenToLocation: {
                eq: true,
              },
              showUnavailableOnline: {
                eq: true,
              },
            }}
            disableCloseOnSelect={true}
            onSelect={items => {
              setDiscountDetailsItems(items);
            }}
            structureData={data => {
              return data?.viewer?.itemConnection?.edges?.map((edge, index) => ({
                label: edge.node.label,
                id: edge?.node.id,
                index,
              }));
            }}
          />
        )}
        {isTrigger && (
          <AutoComplete
            id={name}
            onChange={(e, newValue) => {
              if (setItem) {
                setItem({
                  label: e.label,
                  id: e.value,
                  quantity: 1,
                });
              }
            }}
            control={control}
            multiple={true}
            rules={{
              required: 'Item is required!',
            }}
            getOptionLabel={option => option.label || ''}
            defaultValue={[]}
            disableCloseOnSelect
            options={itemList || []}
            renderInput={params => {
              if (isEvent) {
                return (
                  <TextField
                    {...params}
                    error={errors?.discountDetails && errors?.discountDetails?.item}
                    helperText={
                      errors?.discountDetails && errors?.discountDetails?.item
                        ? errors?.discountDetails?.item.message
                        : ''
                    }
                    label={'Items'}
                    variant="outlined"
                  />
                );
              }
              if (isTrigger && name !== 'triggerEvents.automatic.itemAddedNumberTimes.itemId') {
                return (
                  <TextField
                    {...params}
                    error={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemInCart?.itemId
                    }
                    helperText={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemInCart?.itemId
                        ? errors?.triggerEvents?.automatic?.itemInCart?.itemId?.message
                        : ''
                    }
                    label={'Items'}
                    variant="outlined"
                  />
                );
              }
              if (
                isTrigger &&
                name.includes('triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData')
              ) {
                return (
                  <TextField
                    {...params}
                    name={name}
                    error={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData
                        ?.itemIds
                    }
                    helperText={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData
                        ?.itemIds
                        ? errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData
                            ?.itemIds?.message
                        : ''
                    }
                    label={'Items'}
                    variant="outlined"
                  />
                );
              }
            }}
          />
        )}
      </FormControl>
    </div>
  );
}

export default ItemSelect;
