import React, { useState } from 'react';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { convert, generateStoreAbbreviation } from '../../../../../../utils';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment-timezone';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { Close as CloseIcon } from '@material-ui/icons';
import { Elements, Permission, SelectFactory } from '../../../../../blocks';
import { orderListViewStyles } from './styles';
import SearchIcon from '../../../../../../assets/icons/SearchIcon.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { compact, debounce } from 'lodash';

function DisputesView({
  sources,
  categories,
  handoffs,
  query,
  stages,
  history,
  getSelectedDateRangeValue,
  handleDateRangeStartChange,
  handleDateRangeEndChange,
  handleOpenDateRangeSelectionModal,
  handleCloseDateRangeSelectionModal,
  dateRangeStart,
  dateRangeEnd,
  openDateRangeSelectionModal,
  handleDateRangeSelection,
  locationSelectFactoryQuery,
}) {
  const classes = orderListViewStyles();
  const [searchValue, setSearchValue] = useState();
  const [debouncedSearch] = useState(() =>
    debounce((refetch, variables, value) => search(refetch, variables, value), 400),
  );
  const handleSearchInput = (refetch, variables, event) => {
    setSearchValue(event.target.value);
  };

  const search = (refetch, variables, value) => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
        quoteId: {
          eq: parseInt(value),
        },
      },
    });
    setSearchValue(value);
  };

  const removeQuoteIdFilter = (refetch, variables) => {
    delete variables.filter.quoteId;
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
      },
    });
    document.getElementById('quoteID').value = null;
    setSearchValue(null);
  };
  return (
    <ConnectionTable
      query={query}
      onTableRowClick={row => history.push(`${history.location.pathname}/${row.quoteId}`)}
      initialQueryVariables={{
        first: 15,
        filter: {
          dispute: {
            eq: true,
          },
        },
        specificFields: ['first_name', 'last_name'],
      }}
      //   filterByDate={"expected"}
      title="Disputes"
      structureTable={data =>
        data?.viewer?.quoteConnection?.edges.map(quote => {
          return {
            'origin.label': quote?.node?.origin?.label,
            quoteId: quote?.node?.quoteId,
            'category.label': quote?.node?.category?.label,
            'handoff.label': quote?.node?.handoff?.label,
            location: generateStoreAbbreviation(quote?.node?.location).abbreviation,
            person: `${quote.node.person?.firstName} ${quote.node.person?.lastName}`,
            totalPrice: `$ ${convert.centsToDollars(quote.node.totalPrice)}`,
            expected: moment
              .tz(moment.utc(quote.node.expected), quote.node.location?.timezone?.label)
              .format('MM/DD/YYYY h:mm A z'),
            'stage.label': quote.node.stage?.label,
          };
        })
      }
      columns={[
        { title: 'Source', field: 'origin.label', filter: true },
        { title: 'Quote ID', field: 'quoteId' },
        {
          title: 'Category',
          field: 'category.label',
        },
        {
          title: 'Handoff',
          field: 'handoff.label',
          filter: true,
        },
        {
          title: 'Location ID',
          field: 'location',
          filter: 'location.label',
          customSortField: 'location.storeNumber',
        },
        {
          title: 'Customer',
          field: 'person',
          customSortField: 'person.firstName',
          customPermissionField: 'person.__typename',
        },
        { title: 'Total', field: 'totalPrice' },
        { title: 'Order Timing', field: 'expected' },
        { title: 'Status', field: 'stage.label' },
        {
          title: 'DoorDash ID',
          field: 'doordashId',
        },
        {
          title: 'Printed',
          field: 'printjob',
          customPermissionField: 'printJob.__typename',
          disableSort: true,
          render: printjob => {
            if (!printjob?.inQueue) {
              return 'N/A';
            }
            return printjob?.inQueue && printjob?.datePrinted ? (
              <CheckCircleOutlineIcon />
            ) : (
              <ErrorOutlineIcon />
            );
          },
        },
      ]}
      customActionsCenter={(data, { refetch, variables }) => {
        const permissions = data?.viewer?.quoteConnection?.permissions;
        const dateRangeValue = getSelectedDateRangeValue(variables);

        return (
          <>
            <div className={classes.selectFactoryWrap}>
              <Permission access={permissions?.location?.__typename && 7}>
                <SelectFactory
                  label={'Location'}
                  placeholder={'Select Location'}
                  query={locationSelectFactoryQuery}
                  disableCloseOnSelect={true}
                  structureData={data => {
                    return data?.viewer?.locationConnection?.edges.map((location, index) => {
                      return {
                        label: compact([location.node.label, location.node.storeNumber]).join(
                          ' - #',
                        ),
                        id: location.node.id,
                        index,
                      };
                    });
                  }}
                  onSelect={values => {
                    const locationIds = values.map(location => location.id);
                    if (values.length === 0) {
                      const filters = variables.filter;
                      delete filters.location;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          location: {
                            id: {
                              in: locationIds,
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              </Permission>
              <Permission access={sources?.viewer?.originConnection?.permissions?.__typename && 7}>
                <FormControl variant="outlined">
                  <InputLabel>Source</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.originId;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            originId: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="Source"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sources?.viewer?.originConnection?.edges?.map(origin => (
                      <MenuItem key={origin.node.originId} value={origin.node.originId}>
                        {origin.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Permission>
              <Permission access={stages?.viewer?.stageConnection?.permissions?.__typename && 7}>
                <FormControl variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;

                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.stageId;
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            stageId: {
                              null: false,
                            },
                          },
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            stageId: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {stages?.viewer?.stageConnection?.edges?.map(stage => (
                      <MenuItem key={stage.node.stageId} value={stage.node.stageId}>
                        {stage.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Permission>
              <Permission access={permissions?.expected && 7}>
                <FormControl variant="outlined" className={classes.selectDateRange}>
                  <IconButton onClick={handleOpenDateRangeSelectionModal}>
                    <TextField
                      label="Select Date Range"
                      value={dateRangeValue || 'MM/DD/YYYY to MM/DD/YYYY'}
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                      variant="outlined"
                    />
                  </IconButton>
                  {dateRangeValue !== 'MM/DD/YYYY to MM/DD/YYYY' && (
                    <IconButton
                      onClick={() => handleDateRangeSelection({ refetch, variables }, true)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Dialog
                    open={openDateRangeSelectionModal}
                    onClose={handleCloseDateRangeSelectionModal}
                    disableEnforceFocus
                  >
                    <DialogTitle>
                      <Typography variant="h6">Select Date Range</Typography>
                      <IconButton aria-label="close" onClick={handleCloseDateRangeSelectionModal}>
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          margin="normal"
                          label="Date range start"
                          value={dateRangeStart}
                          onChange={handleDateRangeStartChange}
                        />
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          margin="normal"
                          label="Date range end"
                          value={dateRangeEnd}
                          onChange={handleDateRangeEndChange}
                        />
                        <Button onClick={() => handleDateRangeSelection({ refetch, variables })}>
                          Apply
                        </Button>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Dialog>
                </FormControl>
              </Permission>
            </div>
          </>
        );
      }}
      customActionsBottom={(data, { refetch, variables }) => {
        const categoryOptions = categories?.viewer?.categoryConnection?.edges?.map(category => ({
          value: category.node.categoryId,
          label: category.node.label,
        }));

        const handoffOptions = handoffs?.viewer?.handoffConnection?.edges?.map(handoff => ({
          value: handoff.node.handoffId,
          label: handoff.node.label,
        }));

        return categoryOptions && handoffOptions ? (
          <>
            <div style={{ marginBottom: '.5rem', marginTop: '.5rem' }}>
              <TextField
                name={'quoteID'}
                id={'quoteID'}
                variant={'outlined'}
                onKeyPress={ev => {
                  if (ev.key === 'Enter') {
                    debouncedSearch(refetch, variables, searchValue);
                  }
                }}
                value={searchValue}
                type={'number'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={() => debouncedSearch(refetch, variables, searchValue)}
                      style={{ cursor: 'pointer' }}
                    >
                      <img alt="Search icon" src={SearchIcon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="remove"
                      onClick={() => removeQuoteIdFilter(refetch, variables)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  ),
                }}
                onChange={event => handleSearchInput(refetch, variables, event)}
                placeholder={'Search ID'}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Permission
                access={categories?.viewer?.categoryConnection?.permissions?.__typename && 7}
              >
                <div style={{ marginRight: '1rem' }}>
                  <Elements.ToggleButton
                    options={categoryOptions}
                    handleToggleClick={value => {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          categoryId: {
                            eq: value,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Permission>
              <Permission
                access={handoffs?.viewer?.handoffConnection?.permissions?.__typename && 7}
              >
                <div>
                  <Elements.ToggleButton
                    options={handoffOptions}
                    handleToggleClick={value => {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          handoffId: {
                            eq: value,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Permission>
            </div>
          </>
        ) : null;
      }}
    />
  );
}

export default DisputesView;
