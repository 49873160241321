import { gql } from '@apollo/client';

export const VENDOR_PRODUCT_MEASURES = gql`
  query VENDOR_PRODUCT_MEASURES(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [VendorproductmeasureSort]
    $filter: [VendorproductmeasureFilter]
    $specificFields: [String]
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      vendorproductmeasureConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        specificFields: $specificFields
        fieldGroupOnly: $fieldGroupOnly
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        permissions
        edges {
          node {
            id
            vendorproductmeasureId
            packSize
            active
            created
          }
        }
      }
    }
  }
`;
