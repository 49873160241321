import { gql } from '@apollo/client';
import { stateFragment } from "../../fragments";

const USERGROUPS = gql`
  query Usergroups(
    $first: Int
    $stateFirst: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [UsergroupSort]
  ) {
    viewer {
      id  
      usergroupConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        sort: $sort
        fieldGroup: 68
      ) {
        totalCount
        availableFilters
        permissions
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            usergroupId
            label
          }
        }
      }
      stateConnection(first: $stateFirst) {
        edges {
          node {
            ...StateData
          }
        }
      }
    }
  }
  ${stateFragment.state}
`;

export default USERGROUPS;
