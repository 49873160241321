const validateSingleValue = (
  data,
  concatString = undefined,
  parseFunction = undefined
) => {
  if (!data || data === undefined || data === null || data === "") {
    return "/";
  }

  if (parseFunction && typeof parseFunction === "function") {
    return concatString
      ? `${parseFunction(data)} ${concatString}`
      : parseFunction(data);
  }

  return concatString ? `${data} ${concatString}` : data;
};

export default validateSingleValue;
