import { gql } from '@apollo/client';

const ADD_CATEGORY_TO_TREE = gql`
  mutation AddCategoryToTree($input: AddCategoryToTreeMutationInput!) {
    addCategoryToTree(input: $input) {
        startingMenuId
        targetMenuId
        operation
        menuCategory {
          id
          category {
            id
            categoryId
            label
            subcategoryConnection(sort: { orderKey: ASC }
                  filter: { showUnavailableOnline: { eq: true }}) {
              edges {
                node {
                  id
                  subcategoryId
                      menuId
                  label
                }
              }
            }
          }
        }
    }
  }
`;

export default ADD_CATEGORY_TO_TREE;
