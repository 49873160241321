import { gql } from '@apollo/client';

const FETCH_INVOICES = gql`
  query US_FOODS_INVOICES(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [InoviceFilter]
    $sort: [InoviceSort]
  ) {
    viewer {
      id
      invoiceConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        fieldGroupOnly: 95
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            calculatedTotal
            created
            invoiceNumber
            invoiceId
            invoiceDate
            dueDate
            isSimple
            storeNumber
            supplierName
            subtotal
            tax
            total
            isSubmitted
            location {
              id
              locationId
              storeNumber
              address {
                id
                state {
                  abbreviation
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_INVOICES;
