import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { ConfirmationModal, TimePicker } from '../../../../../blocks';
import { Button } from '@material-ui/core';
import { UPDATE_ALL_WEEKDAYS_FOR_LOCATIONS } from '../../../../../../apollo/mutations/locations';
import { useMutation } from '@apollo/client';
import { FETCH_LOCATION } from '../../../../../../apollo/queries';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useState } from 'react';
import moment from 'moment';

export function AddForAllDays({ locationId, handleClose }) {
  const { setSnackbar } = useSnackbar();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [send, { loading }] = useMutation(UPDATE_ALL_WEEKDAYS_FOR_LOCATIONS, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'All Weekdays updated.',
        open: true,
      });
      handleClose();
    },
    refetchQueries: [
      {
        query: FETCH_LOCATION,
        variables: {
          id: locationId,
          first: 2147483647,
        },
      },
    ],
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      shifts: [
        {
          start: {
            hours: '',
            minutes: '',
            amPm: '',
            startFormatted: '',
          },
          end: {
            hours: '',
            minutes: '',
            amPm: '',
            endFormatted: '',
          },
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shifts',
  });

  const onSubmitt = data => {
    const weekdays = [];

    for (const shift of data.shifts) {
      if (!shift.start.startFormatted || !shift.end.endFormatted) {
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          text: 'Please provide valid start and end time',
          open: true,
        });
        return;
      } else {
        const format = 'hh:mm:ss';
        const startTime = moment(shift.start.startFormatted, format);
        const endTime = moment(shift.end.endFormatted, format);

        if (startTime.isAfter(endTime)) {
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: "Start time can't be later than end time",
            open: true,
          });
          return;
        }
      }
    }

    for (const day of [1, 2, 3, 4, 5, 6, 7]) {
      for (const shift of data.shifts) {
        weekdays.push({
          weekdayId: day,
          startTime: shift.start.startFormatted,
          endTime: shift.end.endFormatted,
        });
      }
    }

    const dataToSend = {
      locationIds: [locationId],
      weekdays,
    };

    send({
      variables: {
        input: dataToSend,
      },
    });
  };

  return (
    <div>
      <h4>Set For All Days</h4>
      <form>
        <div>
          {fields.map((item, index) => (
            <div key={item.id} style={{ display: 'flex', gap: '2rem', margin: '1rem 0' }}>
              <div>
                <p>From</p>
              </div>
              <div>
                <TimePicker
                  hoursName={`shifts.${index}.start.hours`}
                  defaultValues={{
                    hours: 'none',
                    minutes: 'none',
                    amPm: 'none',
                  }}
                  control={control}
                  hasEmptyValue
                  amPmName={`shifts.${index}.start.amPm`}
                  minutesName={`shifts.${index}.start.minutes`}
                  onChange={time => {
                    if (time) {
                      setValue(`shifts.${index}.start.startFormatted`, time);
                    }
                  }}
                  format="H:mm:ss"
                />
                <Controller
                  render={({ field }) => <input hidden {...field} />}
                  name={`shifts.${index}.start.startFormatted`}
                  control={control}
                />
              </div>
              <div>
                <p>To</p>
              </div>
              <div>
                <TimePicker
                  hoursName={`shifts.${index}.end.hours`}
                  defaultValues={{
                    hours: 'none',
                    minutes: 'none',
                    amPm: 'none',
                  }}
                  control={control}
                  hasEmptyValue
                  amPmName={`shifts.${index}.end.amPm`}
                  minutesName={`shifts.${index}.end.minutes`}
                  onChange={time => {
                    if (time) {
                      setValue(`shifts.${index}.end.endFormatted`, time);
                    }
                  }}
                  format="H:mm:ss"
                />
                <Controller
                  render={({ field }) => <input hidden {...field} />}
                  name={`shifts.${index}.end.endFormatted`}
                  control={control}
                />
              </div>
              {index > 0 && (
                <div>
                  <Button type="button" onClick={() => remove(index)}>
                    Remove Shift
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              type="button"
              onClick={() =>
                append({
                  start: {
                    hours: '',
                    minutes: '',
                    amPm: '',
                    startFormatted: '',
                  },
                  end: {
                    hours: '',
                    minutes: '',
                    amPm: '',
                    endFormatted: '',
                  },
                })
              }
              style={{ marginRight: '1rem' }}
            >
              Add Shift
            </Button>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                setIsConfirmationModalOpen(true);
              }}
            >
              Submit
            </Button>
            <h3 style={{ color: 'red' }}>NOTE: This action overwrites all shifts for all days.</h3>
          </div>
        </div>
      </form>

      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        disabled={loading}
        text={'Are you sure you want to submit? This will overwrite open hours for all days.'}
        confirmAction={handleSubmit(data => onSubmitt(data))}
      />
    </div>
  );
}
