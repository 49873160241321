import { useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import ACHIEVEMENTS from '../../../../../../apollo/queries/loyalty/achievements';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { ConnectionTable, Elements, PermissionField } from '../../../../../blocks';
import useStyles from './styles';

const Achievements = () => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();
  // const [toggleAchievement] = useMutation(ACHIEVEMENTS); // TODO adjust mutation when it's ready on API

  const handleAchievementActivation = (active, { id }) => {
    // TODO adjust mutation
    // toggleAchievement({
    //   variables: {
    //     input: {
    //       id,
    //     },
    //   },
    // })
    //   .then(() =>
    //     setSnackbar({
    //       open: true,
    //       type: 'success',
    //       text: 'Achievement is updated.',
    //     }),
    //   )
    //   .catch(error =>
    //     setSnackbar({
    //       open: true,
    //       type: 'error',
    //       text: error.message,
    //     }),
    //   );
  };

  return (
    <>
      <ConnectionTable
        query={ACHIEVEMENTS}
        title="Achievements"
        customActionsTop={undefined}
        showSearch={false}
        handleLocationActivation={handleAchievementActivation}
        initialQueryVariables={{
          first: 10,
        }}
        structureTable={data => {
          return data?.viewer?.achievementConnection?.edges.map(edge => {
            return {
              id: edge.node?.id,
              label: edge.node?.label,
              status: edge.node?.active,
            };
          });
        }}
        columns={[
          { title: 'Achievement Name', field: 'label' },
          {
            title: 'Status',
            field: 'status',
            render: active => (
              <Typography variant="inherit" className={active ? classes.active : classes.inactive}>
                {active ? 'Active' : 'Inactive'}
              </Typography>
            ),
          },
        ]}
      />
    </>
  );
};

export default Achievements;
