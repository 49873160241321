import { gql } from '@apollo/client';

const REMOVE_ITEM_FROM_TREE = gql`
  mutation RemoveItemFromTree($input: RemoveItemFromTreeMutationInput!) {
    removeItemFromTree(input: $input) {
      viewer{
        id
      }
      removedSubcategoryItemId
      removedSubcategoryItemParent
    }
  }
`;

export default REMOVE_ITEM_FROM_TREE;
