import { gql } from '@apollo/client';

const menuFragment = {
  menu: gql`
    fragment MenuData on Menu {
      id
      menuId
      label
    }
  `,
};

export default menuFragment;
