

export function pad(number, places = 0, char = '0') {
    const charsToAdd = places - number.toString().length;
    const charsList = []
    if (charsToAdd > 0) {
      for (let i = 0; i < charsToAdd; i++) {
        charsList.push(char);
      }
    }
  
    return [...charsList, number.toString()].join('');
  }