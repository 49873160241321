import { gql } from '@apollo/client';

export const SIMPLE_INVOICE_INGREDIENTS = gql`
  query SIMPLE_INVOICE_INGREDIENTS($filter: [InoviceFilter!]) {
    viewer {
      id
      invoiceConnection(filter: $filter, fieldGroupOnly: 88) {
      permissions
        edges {
          node {
            id
            location {
              storeNumber
              label
              address {
                state {
                  abbreviation
                }
              }
            }
            invoiceId
            isSimple
            created
            invoiceFileUrl
            invoiceDate
            dueDate
            invoiceId
            updatable
            invoiceNumber
            storeNumber
            supplierName
            subtotal
            tax
            total
            calculatedTotal
            isSubmitted
            invoiceIngredientConnection {
              edges {
                node {
                  id
                  ingredientId
                  note
                  measureId
                  measure {
                    label
                    id
                    abbreviation
                    measureId
                  }
                  ingredient {
                    inventoryitem {
                      id
                      cost
                      label
                      inventoryitemId
                      description
                      measure {
                        label
                        id
                        measureId
                        abbreviation
                      }
                    }
                  }
                  cost
                  quantity
                  baseQuantity
                }
              }
            }
          }
        }
      }
    }
  }
`;