import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ChargebackNotification from "../ChargebackNotification";
import { ORDER_INFO } from "../../../../../apollo/queries";
import Order from "../../AllOrders/Order";
import { useQuery } from "@apollo/client";
import { uglifyId } from "../../../../../utils";
import ChargebackModal from "../DisputeChargebackModal";
import { Modal } from "../../../../blocks";
import ContactCustomer from "../DisputeContactCustomerModal";
import { useFormSubmitPermissions } from '../../../../../hooks';
import { MUTATION_NAME } from '../../../../../constants';

function DisputedOrderInfo() {
  const { orderId } = useParams();

  const [openContactCustomerModal, setOpenContactCustomerModal] = useState(
    false
  );
  const [openChargebackModal, setOpenChargebackModal] = useState(false);
  const [permissions, setPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.ChargebackRespondMutationInput,
      MUTATION_NAME.ContactCustomerMutationInput,
    ],
    permissions,
  })

  const { data, error } = useQuery(ORDER_INFO, {
    variables: {
      id: uglifyId("Quote", orderId),
    },
    onCompleted: data1 => {
      if (!permissions) {
        setPermissions({
          orderId: data1?.viewer?.quote?.permissions?.quoteId,
          productDescription: data1?.viewer?.quote?.permissions?.lineConnection?.edges?.node?.__typename,
          locationEmail: data1?.viewer?.quote?.permissions?.location?.email,
          customerEmail: data1?.viewer?.quote?.permissions?.person?.login?.email,
        });
      }
    }
  });

  if (error) return <div>Error</div>;
  if (!data) return <h2>Loading...</h2>;

  const handleOpenChargebackModal = () => {
    setOpenChargebackModal(!openChargebackModal);
    if (!openChargebackModal) {
      setOpenChargebackModal(MUTATION_NAME.ChargebackRespondMutationInput);
    }
  };

  const handleOpenContactCustomerModal = () => {
    setOpenContactCustomerModal(!openContactCustomerModal);
    if (!openContactCustomerModal) {
      setOpenWarningMutationName(MUTATION_NAME.ContactCustomerMutationInput);
    }
  };

  return (
    <div>
      <ChargebackNotification
        data={data}
        handleOpenChargebackModal={handleOpenChargebackModal}
        handleOpenContactCustomerModal={handleOpenContactCustomerModal}
      />
      <Order />
      <Modal
        title="You have received a chargeback"
        open={openChargebackModal}
        handleClose={handleOpenChargebackModal}
      >
        <ChargebackModal 
          handleClose={handleOpenChargebackModal}
          data={data}
          accessToMutations={accessToMutations}  
        />
      </Modal>

      <Modal
        title="Contact Customer"
        open={openContactCustomerModal}
        handleClose={handleOpenContactCustomerModal}
      >
        <ContactCustomer
          handleClose={handleOpenContactCustomerModal}
          data={data}
          accessToMutations={accessToMutations}
        />
      </Modal>
    </div>
  );
}

export default DisputedOrderInfo;
