import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import { Modal } from '../../../../../blocks';

import { useForm } from 'react-hook-form';
import CategoryCard from './CategoryCard';
import { ORDER_SETTINGS_HANDOFF } from '../../../../../../apollo/queries';
import TOGGLE_LOCATION_HANDOFF_CATEGORY from '../../../../../../apollo/mutations/locations/toggleLocationHandoffCategory';
import UPDATE_LOCATION_HANDOFF_CATEGORY from '../../../../../../apollo/mutations/locations/updateLocationHandoffCategory';
import { uglifyId } from '../../../../../../utils';
import CategoryHandoffCard from '../../../components/CategoryHandoffCard';
import LocationNotes from '../LocationNotes';
import Permission from '../../../../../blocks/Permission';
import PermissionField from '../../../../../blocks/PermissionField';
import TextField from '@material-ui/core/TextField';
import UPDATE_LOCATIONS_TIME_SLOT from '../../../../../../apollo/mutations/locations/updateLocationsTimeSlot';
import { MUTATION_NAME } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const HandoffComponent = ({
  setOpenWarningMutationName,
  accessToMutations,
  locationId,
  mutationPermissions,
  menuId,
  handoffPermissions,
  setHandoffPermissions,
}) => {
  const { setSnackbar } = useSnackbar();

  HandoffComponent.propTypes = {
    locationId: PropTypes.number,
  };

  const updateLocationHandoffCategoryPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'updateLocationHandoffCategory',
  );
  const toggleLocationHandoffCategoryPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'toggleLocationHandoffCategory',
  );

  const [handoff, setHandoff] = useState({});
  const [showModal, setShowModal] = useState(false);
  // TODO: can be optimized server side, this fix was faster
  const [updateLocationsTimeSlot] = useMutation(UPDATE_LOCATIONS_TIME_SLOT);

  const upsellAndCateringIds = [2, 9];

  const [
    toggleLocationHandoffCategory,
    { loading: toggleLocationHandoffCategoryLoading },
  ] = useMutation(TOGGLE_LOCATION_HANDOFF_CATEGORY, {
    refetchQueries: [
      {
        query: ORDER_SETTINGS_HANDOFF,
        variables: {
          first: 5,
          id: uglifyId('Location', locationId),
          categoryFilter: [
            {
              categorytypeId: {
                nin: upsellAndCateringIds,
              },
            },
            {
              showHiddenToCustomer: {
                eq: true,
              },
            },
          ],
          locationHandoffFilter: [
            {
              locationId: {
                eq: locationId,
              },
            },
          ],
        },
      },
    ],
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Handoff updated',
      });
    },
    onError: error => {
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
  });

  const [
    updateLocationHandoffCategory,
    { loading: updateLocationHandoffCategoryLoading },
  ] = useMutation(UPDATE_LOCATION_HANDOFF_CATEGORY, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Handoff updated',
      });
    },
    onError: error => {
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
    refetchQueries: [
      {
        query: ORDER_SETTINGS_HANDOFF,
        variables: {
          first: 5,
          id: uglifyId('Location', locationId),
          categoryFilter: [
            {
              categorytypeId: {
                nin: upsellAndCateringIds,
              },
            },
            {
              showHiddenToCustomer: {
                eq: true,
              },
            },
          ],
          locationHandoffFilter: [
            {
              locationId: {
                eq: locationId,
              },
            },
          ],
        },
      },
    ],
  });

  const {
    handleSubmit: handleSubmitTimeSlot,
    register: registerTimeSlot,
    errors: errorsTimeSlot,
  } = useForm();

  const { data, loading, error } = useQuery(ORDER_SETTINGS_HANDOFF, {
    variables: {
      first: 5,
      id: uglifyId('Location', locationId),
      categoryFilter: [
        {
          categorytypeId: {
            nin: upsellAndCateringIds,
          },
          menuId: {
            eq: menuId,
          },
        },
        {
          showHiddenToCustomer: {
            eq: true,
          },
        },
      ],
      locationHandoffFilter: [
        {
          locationId: {
            eq: locationId,
          },
        },
      ],
    },
    onCompleted: data1 => {
      if (!handoffPermissions) {
        setHandoffPermissions({
          handoffId: data1?.viewer?.handoffConnection?.permissions?.handoffId,
          categoryId:
            data1?.viewer?.locationHandoffCategoryConnection?.permissions?.category?.__typename,
          toggle: data?.viewer?.locationHandoffCategoryConnection?.permissions?.active,
        });
      }
    },
  });

  const permissions = {
    category: data?.viewer?.categoryConnection?.permissions,
    handoff: data?.viewer?.handoffConnection?.permissions,
    locationHandoffCategory: data?.viewer?.locationHandoffCategoryConnection?.permissions,
  };

  if (loading) return <CircularProgress />;

  if (error) return <div>error</div>;

  const handleOpenModal = () => {
    setShowModal(true);
    setOpenWarningMutationName(MUTATION_NAME.UpdateLocationHandoffCategoryMutationInput);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onSubmit = formData => {
    const newCategories = data?.viewer?.categoryConnection?.edges;
    const field = [];
    for (let i = 0; i < newCategories.length; i++) {
      if (Object.keys(formData).length === 0 && formData.constructor === Object) {
        break;
      }

      const categoryObject = {};
      const categoryId = newCategories[i]?.node?.id;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(formData)) {
        if (categoryId === key.split('-')[0]) {
          categoryObject[key.split('-')[1]] = typeof value === 'string' ? parseFloat(value) : value;

          // eslint-disable-next-line no-param-reassign
          delete formData[key];
        }
      }
      if (Object.keys(categoryObject).length !== 0 && categoryObject.constructor === Object) {
        categoryObject.id = categoryId;
        field.push(categoryObject);

        if (categoryObject.doordashDriveEnabled && categoryObject.burqEnabled) {
          setSnackbar({
            open: true,
            type: 'error',
            text: 'Cannot enable both DoorDash and Burq',
          });
          return;
        }
      }
    }

    updateLocationHandoffCategory({
      variables: {
        input: {
          locationIds: [{ id: uglifyId('Location', locationId) }],
          orderSettings: {
            handoffId: handoff.id,
            categories: field.map(f => ({
              ...f,
              discountFraction: f.discountFraction && f.discountFraction / 100,
              toggleCategory: true,
              minOrderAmount: f.minOrderAmount || 0,
              minOrderQuantity: f.minOrderQuantity || 0,
            })),
          },
        },
      },
    });
  };

  const HandoffCard = ({
    handoffLabel,
    categories,
    locationHandoffCategoryArray,
    handoffId,
    locationHandoffCategoryPermissions,
  }) => {
    HandoffCard.propTypes = {
      handoffLabel: PropTypes.string,
      categories: PropTypes.array,
      locationHandoffCategoryArray: PropTypes.array,
      handoffId: PropTypes.string,
    };

    const Newcategories = categories?.edges?.map(category => {
      return (
        <CategoryCard
          handoffLabel={handoffLabel}
          categoryLabel={category?.node?.label}
          key={`${category?.node?.id}-${handoffId}`}
          locationHandoffCategory={locationHandoffCategoryArray.edges.find(
            locationHandoffEdge =>
              locationHandoffEdge?.node?.handoff &&
              locationHandoffEdge?.node?.handoff?.id === handoffId &&
              locationHandoffEdge.node.category &&
              locationHandoffEdge.node.category.id === category.node.id,
          )}
          locationHandoffCategoryPermissions={locationHandoffCategoryPermissions}
        />
      );
    });

    return (
      <div id={handoffId}>
        <Box mt={5}>
          <Grid item container spacing={4} justify="space-between">
            <Typography gutterBottom variant="h4" component="h2">
              {handoffLabel}
            </Typography>
            <Permission
              access={Math.min(
                updateLocationHandoffCategoryPermission?.access,
                accessToMutations?.[MUTATION_NAME.UpdateLocationHandoffCategoryMutationInput],
              )}
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    onClick={() => {
                      handleOpenModal();
                      setHandoff({
                        id: handoffId,
                        label: handoffLabel,
                      });
                    }}
                  >
                    Edit
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </Grid>
          {Newcategories}
        </Box>
      </div>
    );
  };

  const editHandoffBody = (categories, locationHandoffCategory, location) => {
    return (
      <CategoryHandoffCard
        categories={categories}
        handleCloseModal={handleCloseModal}
        handoff={handoff}
        location={location}
        updateLocationHandoffCategoryLoading={updateLocationHandoffCategoryLoading}
        toggleLocationHandoffCategoryLoading={toggleLocationHandoffCategoryLoading}
        locationHandoffCategory={locationHandoffCategory}
        locationId={locationId}
        onSubmit={onSubmit}
        permissions={permissions}
        toggleLocationHandoffCategory={toggleLocationHandoffCategory}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
        toggleLocationHandoffCategoryPermission={toggleLocationHandoffCategoryPermission}
        updateLocationHandoffCategoryPermission={updateLocationHandoffCategoryPermission}
      />
    );
  };

  const onSubmitTimeSlot = formData => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Time slot is being updated!',
    });

    updateLocationsTimeSlot({
      variables: {
        input: {
          locationIds: [{ id: uglifyId('Location', locationId) }],
          ordersInTimeslot: formData?.timeSlot,
        },
      },
    })
      .then(res => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Time slot is updated',
        });
      })
      .catch(e => {
        setSnackbar({
          open: true,
          type: 'error',
          text: e.message,
        });
      });
  };

  return (
    <div>
      <Grid item xs={12} direction={'column'}>
        <Typography gutterBottom variant="h4" component="h2">
          Order Settings
        </Typography>
        <Permission access={data?.viewer?.location?.permissions?.ordersInTimeslot}>
          <Typography variant={'p'} style={{ color: '#A9A9A9', marginBottom: '1rem' }}>
            Please enter how many orders your store can fulfill in one time slot (15 minutes).
          </Typography>
          <form
            onSubmit={handleSubmitTimeSlot(onSubmitTimeSlot)}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <PermissionField
              Component={TextField}
              variant={'outlined'}
              name={'timeSlot'}
              type={'number'}
              inputProps={{
                step: 1,
              }}
              defaultValue={data?.viewer?.location?.ordersInTimeslot}
              inputRef={registerTimeSlot({
                required: true,
                valueAsNumber: true,
                min: 3,
              })}
              error={errorsTimeSlot && errorsTimeSlot['timeSlot']}
              helperText={
                errorsTimeSlot &&
                errorsTimeSlot['timeSlot'] &&
                'Should not be empty and must be greater than 2.'
              }
              label={'Maximum number of orders in time slot'}
              style={{ paddingBottom: '1rem' }}
            />
            <div>
              <Permission
                access={accessToMutations?.[MUTATION_NAME.UpdateLocationsTimeSlotMutationInput]}
              >
                <div>
                  <PermissionField createHelperText>
                    <Button type={'submit'}>Update</Button>
                  </PermissionField>
                </div>
              </Permission>
            </div>
          </form>
        </Permission>
      </Grid>
      {data?.viewer?.handoffConnection?.edges?.map((handoff, index) => {
        return (
          <>
            <HandoffCard
              key={handoff?.node?.id}
              handoffId={handoff?.node?.id}
              handoffLabel={handoff?.node?.label}
              categories={data?.viewer?.categoryConnection}
              locationHandoffCategoryArray={data?.viewer?.locationHandoffCategoryConnection}
              locationHandoffCategoryPermissions={
                data?.viewer?.locationHandoffCategoryConnection?.permissions
              }
            />
            {data?.viewer?.handoffConnection?.edges.length - 1 !== index && <Divider />}
          </>
        );
      })}
      <LocationNotes
        locationId={locationId}
        mutationPermissions={mutationPermissions}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
      />
      <Modal open={showModal} handleClose={handleCloseModal} title={handoff.label}>
        {editHandoffBody(
          data?.viewer?.categoryConnection,
          data?.viewer?.locationHandoffCategoryConnection,
          data?.viewer?.location,
        )}
      </Modal>
    </div>
  );
};

export default HandoffComponent;
