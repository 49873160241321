import { gql } from '@apollo/client';

const SEND_SCHEDULE_EMAIL = gql`
  mutation sendScheduleEmail($input: SendScheduleEmailInput!) {
    sendScheduleEmail(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default SEND_SCHEDULE_EMAIL;
