import { isEmpty } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import FormItems from '../../../../blocks/FormItems';
import AddRecipeItem from './AddRecipeComponent';
import Recipe from './Recipe';

const RecipesTable = ({
  setValue,
  unregister,
  control,
  register,
  errors,
  inventoryitems = [],
  getValues,
}) => {
  const [selectedRecipeIds, setSelectedRecipeIds] = useState([]);
  const [showSelectFactory, setShowSelectFactory] = useState(true);

  const recipes = useMemo(() => {
    const result = inventoryitems;

    if (!isEmpty(result)) {
      setSelectedRecipeIds(result.map(item => item.inventoryitemId));
    }

    return result;
  }, [inventoryitems]);

  const handleSetItems = useCallback(items => {
    setSelectedRecipeIds(items.map(item => item.inventoryitemId));
  }, []);

  const handleOnSelect = useCallback(() => {
    setShowSelectFactory(false);
  }, []);

  return (
    <FormItems
      title="Choice Ingredients"
      onSelect={handleOnSelect}
      setItems={handleSetItems}
      addItemComponent={AddRecipeItem}
      addItemComponentProps={{
        setShowSelectFactory,
        showSelectFactory,
        selectedRecipeIds,
      }}
      name="recipe"
      getValues={getValues}
      items={recipes}
      setValue={setValue}
      unregister={unregister}
      control={control}
      register={register}
      errors={errors}
      itemComponent={Recipe}
      dividerBetweenItems={true}
      dividerAfterItems={false}
      enableRemove
    />
  );
};

export default RecipesTable;
