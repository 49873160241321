import { gql } from '@apollo/client';

const CREATE_NUTRIENT = gql`
  mutation CreateNutrient($input: CreateNutrientMutationInput!, $first: Int) {
    createNutrient(input: $input) {
      viewer {
        id
        nutrientConnection(first: $first) {
          availableFilters
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              permission
              nutrientId
              label
              description
              created
              measure {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default CREATE_NUTRIENT;
