import { isEmpty } from 'lodash-es';
import { convert, CurrencyInputFormat } from '../../../../../../utils';
import { formatData, formatNumberStringToFloat } from '../../../../helpers';

export const prepareFormData = ({
  formData,
  setShowInventoryItemsError,
  isSubmit,
  label = 'inventoryItem',
  mapFunction = ({ id, quantity, cost, ...rest }) => ({
    ...rest,
    cost: CurrencyInputFormat(cost),
    quantity: formatNumberStringToFloat(quantity),
  }),
}) => {
  const ingredients = formatData({
    formData,
    label,
    mapFunction,
  });

  if (isSubmit && isEmpty(ingredients)) {
    if (setShowInventoryItemsError) {
      setShowInventoryItemsError(true);
    }

    return;
  }

  if (setShowInventoryItemsError) {
    setShowInventoryItemsError(false);
  }

  return {
    isSubmit: isSubmit,
    ingredients,
  };
};
