import React, { useState } from 'react';
import { Elements, SerializedCouponDistributionModal } from '../../../../../../blocks';
import Manual from '../Manual';
import Automatic from '../Automatic';
import { Controller } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { SERIALIZED_COUPON_TYPE_ID } from '../../../../../../../constants';
const options = [
  { value: 'manual', label: 'Manual' },
  { value: 'automatic', label: 'Automatic' },
];

const codeGenerationTypes = [
  { value: 1, label: 'Single entry code' },
  { value: 6, label: 'Serialized codes' },
];

function TriggerEventsView({
  coupon,
  couponTriggers,
  control,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  itemInCart,
  setItemInCart,
  triggers,
  errors,
  forEachItem,
  setForEachItem,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
  serializedCouponModal,
  setSerializedCouponModal,
  brands,
  setValue,
}) {
  const [triggerEvent, setTriggerEvent] = useState('all');
  const [codeGenerationType, setCodeGenerationType] = useState(coupon?.coupontypeId);

  const handleToggleClick = value => {
    setTriggerEvent(value);
  };

  const handleGenerateCodeModal = () => {
    setSerializedCouponModal(true);
  };

  const switchCase = () => {
    switch (triggerEvent) {
      case 'manual':
        return (
          <Manual
            errors={errors}
            coupon={coupon}
            couponTriggers={couponTriggers}
            control={control}
            codeGenerationType={codeGenerationType}
          />
        );
      case 'automatic':
        return (
          <Automatic
            errors={errors}
            couponTriggers={couponTriggers}
            coupon={coupon}
            triggers={triggers}
            control={control}
            cartItemsRequired={cartItemsRequired}
            setCartItemsRequired={setCartItemsRequired}
            itemAddedNumberTimes={itemAddedNumberTimes}
            itemInCart={itemInCart}
            setItemInCart={setItemInCart}
            forEachItem={forEachItem}
            setForEachItem={setForEachItem}
            setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
            selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
            codeGenerationType={codeGenerationType}
            setValue={setValue}
          />
        );
      case 'all':
        return (
          <div>
            <Manual
              errors={errors}
              coupon={coupon}
              couponTriggers={couponTriggers}
              control={control}
              codeGenerationType={codeGenerationType}
            />
            <br />
            <Automatic
              errors={errors}
              couponTriggers={couponTriggers}
              coupon={coupon}
              triggers={triggers}
              control={control}
              cartItemsRequired={cartItemsRequired}
              setCartItemsRequired={setCartItemsRequired}
              itemAddedNumberTimes={itemAddedNumberTimes}
              itemInCart={itemInCart}
              setItemInCart={setItemInCart}
              forEachItem={forEachItem}
              setForEachItem={setForEachItem}
              setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
              selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
              codeGenerationType={codeGenerationType}
              setValue={setValue}
            />
          </div>
        );
      default:
        return <Manual codeGenerationType={codeGenerationType} />;
    }
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h2 id="triggerEvents" style={{ marginTop: '30px' }}>
          Trigger events
        </h2>
        <Controller
          name="couponTypeId"
          control={control}
          render={({ onChange }) => (
            <Elements.ToggleButton
              style={{ margin: '1rem 1rem 1rem 0' }}
              options={codeGenerationTypes}
              value={codeGenerationType}
              handleToggleClick={value => {
                onChange(value);
                setCodeGenerationType(value);
              }}
            />
          )}
        />
        {coupon.coupontypeId === SERIALIZED_COUPON_TYPE_ID && codeGenerationType && (
          <Button variant="outlined" style={{ width: '220px' }} onClick={handleGenerateCodeModal}>
            GENERATE CODES
          </Button>
        )}
        <SerializedCouponDistributionModal
          open={serializedCouponModal}
          setSerializedCouponModal={setSerializedCouponModal}
          couponId={serializedCouponModal}
          brands={brands}
        />
        {/* <Elements.ToggleButton
          options={options}
          value={triggerEvent}
          handleToggleClick={handleToggleClick}
        /> */}
      </div>
      {switchCase()}
    </>
  );
}

export default TriggerEventsView;
