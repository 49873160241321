import React, { useCallback, useRef, useState } from "react";
import { IconButton, ListItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';

const SubcategoryListItem = ({edge, menuId, categoryId, basePath, classes, moveSubcategory, index, subcategoryId, order}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'Subcategory',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveSubcategory(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "Subcategory", subcategoryId, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
      <ListItem
        key={edge?.node?.id}
        ref={ref}
        style={{opacity}}
        className={classes.listItem}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={{paddingRight: '1rem'}}><b>{order + 1}.</b> {edge?.node?.label}</p>
          <Link
            to={`${basePath}/menus/${menuId}/categories/${categoryId}/subcategories/${edge?.node?.subcategoryId}`}
          >
            ID_{edge?.node?.subcategoryId}
          </Link>
          <IconButton aria-label="move">
            <OpenWithOutlinedIcon />
          </IconButton>
        </div>
      </ListItem>
  )
};

export default SubcategoryListItem;
