import React, { useCallback } from 'react';
import { CSVLink } from 'react-csv';
// Local Imports
import ConnectionTable from '../../../../blocks/ConnectionTable';
import {
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  FormControl,
  TextField,
  DialogTitle,
  DialogContentText,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { AutoComplete, DataTable, PermissionField } from '../../../../blocks';
import {
  convert,
  generateOvertimePdf,
  generateOvertimeCSV,
  generateStoreAbbreviation,
} from '../../../../../utils';
import moment from 'moment-timezone';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import DateTime from 'luxon/src/datetime';
import Clockout from './Clockout';
import EditTimeshift from './EditTImeshift';
import Button from '@material-ui/core/Button';
import Permission from '../../../../blocks/Permission';
import CreateTimeshift from './CreateTimeshift';
import { compact } from 'lodash';
import { MUTATION_NAME } from '../../../../../constants';

const useStyles = makeStyles({
  selectHolder: {
    '& label': {
      transform: 'translate(14px, -6px) scale(0.75)',
      padding: '0 6px',
      background: '#fff',
      zIndex: 1,
      marginLeft: '-3px',
    },
  },
});

const TimeshiftView = ({
  calculateWeeklyTimeshiftHours,
  closeFinalizePayrollModal,
  control,
  errors,
  finalizePayroll,
  finalizePayrollModalOpen,
  getOvertimeValues,
  getTotalWages,
  handleClockout,
  handleCloseClockoutModal,
  handleCloseCreateTimeshiftModal,
  handleCloseEditTimeshiftModal,
  handleLocationAutocompleteChange,
  handleOpenClockoutModal,
  handleOpenCreateTimeshiftModal,
  handleOpenEditTimeshiftModal,
  handleSubmit,
  isDeletingTimeshift,
  locationData,
  locationList,
  accessToMutations,
  permissions,
  mutationLoading,
  mutationPermissions,
  openClockoutModal,
  handleDeleteTimeshift,
  setPermissions,
  openCreateTimeshiftModal,
  openEditTimeshiftModal,
  openFinalizePayrollModal,
  query,
  register,
  setLocationInfo,
  tableState,
  createTimeshift,
  updateTimeshift,
  locationInfo,
}) => {
  const classes = useStyles();
  const handleDateChange = date => {
    setLocationInfo({
      started: date.toFormat('yyyy-MM-dd'),
      finished: date.plus({ days: locationInfo?.payrollPeriod?.duration }).toFormat('yyyy-MM-dd'),
      locationId: locationInfo.locationId,
      workWeekStartId: locationInfo.workWeekStartId,
      payrollPeriod: locationInfo.payrollPeriod,
    });
  };

  const finalizePayrollPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'finalizePayroll',
  );
  const exportTimeshiftPDFPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'exportTimeshiftPDF',
  );
  const exportTimeshiftCSVPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'exportTimeshiftCSV',
  );
  const createTimeshiftPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'createTimeshift',
  );
  const updateTimeshiftPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'updateTimeshift',
  );
  const clockoutTimeshiftPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'clockoutTimeshift',
  );
  const removeTimeshiftPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'removeTimeshift',
  );

  const renderPerson = useCallback(
    person => (
      <Permission
        access={Math.min(
          createTimeshiftPermission?.access,
          accessToMutations?.[MUTATION_NAME.CreateTimeshiftMutationInput],
        )}
      >
        <div>
          <PermissionField createHelperText>
            <Button
              style={{ width: '200px' }}
              onClick={() => {
                handleOpenCreateTimeshiftModal(person);
              }}
            >
              Create Timeshift
            </Button>
          </PermissionField>
        </div>
      </Permission>
    ),
    [createTimeshiftPermission?.access, handleOpenCreateTimeshiftModal, accessToMutations],
  );

  return (
    <>
      <ConnectionTable
        query={query}
        title="Timeshifts"
        initialQueryVariables={{
          first: 20,
          last: null,
          sort: { locationId: 'ASC' },
          before: null,
          after: null,
          timeshiftFilter: {
            started: {
              gte: DateTime.fromFormat(locationInfo.started, 'yyyy-MM-dd')
                .toUTC()
                .toFormat('yyyy-MM-dd HH:mm:ss'),
              lt: DateTime.fromFormat(locationInfo.finished, 'yyyy-MM-dd')
                .toUTC()
                .toFormat('yyyy-MM-dd HH:mm:ss'),
            },
            locationId: {
              eq: locationInfo?.locationId,
            },
          },
          jobFilter: {
            locationId: {
              eq: locationInfo?.locationId,
            },
          },
          filter: {
            locationId: {
              eq: locationInfo?.locationId,
            },
            person: {
              login: {
                id: {
                  null: false,
                },
              },
            },
          },
          started: DateTime.fromFormat(locationInfo.started, 'yyyy-MM-dd')
            .toUTC()
            .toFormat('yyyy-MM-dd HH:mm:ss'),
          finished: DateTime.fromFormat(locationInfo.finished, 'yyyy-MM-dd')
            .toUTC()
            .toFormat('yyyy-MM-dd HH:mm:ss'),
        }}
        structureTable={data => {
          return data?.viewer?.locationPersonConnection?.edges.map(edge => {
            const overtimeValues = getOvertimeValues(edge.node.person?.timeshiftConnection?.edges);
            const totalWages = getTotalWages(edge.node.person?.timeshiftConnection?.edges);

            return {
              loginId: edge.node.person?.login?.loginId,
              personFullName: `${edge.node.person?.firstName} ${edge.node.person?.lastName}`,
              hours: calculateWeeklyTimeshiftHours(edge.node.person?.timeshiftConnection?.edges),
              shifts: edge.node.person?.timeshiftConnection?.edges.length,
              overtime: overtimeValues.error || convert.decimalHoursToHHmm(overtimeValues.hours),
              overtimeTotal: overtimeValues.error || overtimeValues.fee,
              totalWages: totalWages.error || totalWages.fee,
              locationPerson: edge.node,
              person: {
                locationId: edge.node?.location?.id,
                locationIdInt: +atob(edge.node?.location?.id).split(':')[1],
                personId: edge.node?.person?.personId,
                timezone: edge?.node?.location?.timezone?.label,
                permissions: data?.viewer?.locationPersonConnection?.permissions,
              },
            };
          });
        }}
        columns={[
          { title: 'Employee Id', field: 'loginId' },
          { title: 'Employee Name', field: 'personFullName' },
          { title: 'Hours', field: 'hours' },
          { title: 'Shifts', field: 'shifts' },
          { title: 'Overtime', field: 'overtime' },
          { title: 'Overtime Total', field: 'overtimeTotal' },
          { title: 'Total Wages', field: 'totalWages' },
          {
            title: 'Action',
            field: 'person',
            customPermissionField: '__typename',
            render: renderPerson,
          },
        ]}
        customActionsTop={(data, { refetch, variables }) => {
          if (!permissions) {
            setPermissions({
              ...data?.viewer?.locationPersonConnection,
              locationId: data?.viewer?.locationPersonConnection?.permissions?.location?.__typename,
              personId: data?.viewer?.locationPersonConnection?.permissions?.person?.personId,
              jobId:
                data?.viewer?.locationPersonConnection?.permissions?.person?.personJobConnection
                  ?.edges?.node?.job?.__typename,
              start:
                data?.viewer?.locationPersonConnection?.permissions?.person?.timeshiftConnection
                  ?.edges?.node?.started,
              end:
                data?.viewer?.locationPersonConnection?.permissions?.person?.timeshiftConnection
                  ?.edges?.node?.finished,
              timeshiftId:
                data?.viewer?.locationPersonConnection?.permissions?.person?.timeshiftConnection
                  ?.edges?.node?.__typename,
            });
          }

          return data ? (
            <div style={{ display: 'flex' }}>
              <Permission access={exportTimeshiftPDFPermission?.access}>
                <div>
                  <PermissionField createHelperText>
                    <Button
                      variant="outlined"
                      style={{ marginRight: '20px' }}
                      onClick={() => {
                        generateOvertimePdf(
                          data?.viewer?.locationPersonConnection?.edges,
                          locationInfo.started,
                          locationInfo.finished,
                          data?.viewer?.locationPersonConnection?.edges?.[0]?.node?.location,
                        );
                      }}
                    >
                      Export PDF
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
              <Permission access={exportTimeshiftCSVPermission?.access}>
                <CSVLink
                  data={generateOvertimeCSV(data?.viewer?.locationPersonConnection?.edges)}
                  filename={`OtReport ${DateTime.fromSQL(locationInfo?.started).toFormat(
                    'MM/dd/yyyy',
                  )}-${DateTime.fromSQL(locationInfo?.finished).toFormat(
                    'MM/dd/yyyy',
                  )}-LocationCode${
                    generateStoreAbbreviation(
                      data?.viewer?.locationPersonConnection?.edges?.[0]?.node?.location,
                    ).abbreviation
                  }.csv`}
                >
                  <div>
                    <PermissionField createHelperText>
                      <Button variant="outlined" style={{ marginRight: '20px' }}>
                        Export CSV
                      </Button>
                    </PermissionField>
                  </div>
                </CSVLink>
              </Permission>
              <Permission access={finalizePayrollPermission?.access}>
                <div>
                  <PermissionField createHelperText>
                    <Button
                      onClick={() => {
                        openFinalizePayrollModal({
                          locationId:
                            data?.viewer?.locationPersonConnection?.edges?.[0]?.node?.location?.id,
                          variables,
                        });
                      }}
                    >
                      Finalize Payroll
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
            </div>
          ) : null;
        }}
        customActionsCenter={(data, { refetch, variables }) => {
          return (
            <Grid
              container
              item
              xs={12}
              spacing={3}
              // alignItems='center'
            >
              <Grid item xs={2} />
              <Grid item xs={4} className={classes.selectHolder}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="location-label-id">Locations</InputLabel>
                  <AutoComplete
                    name="locations"
                    id="locations"
                    onChange={location => {
                      // setWeek({
                      //   started: DateTime.fromObject({ weekday: workWeekStart }).toFormat(
                      //     'yyyy-MM-dd'
                      //   ),
                      //   finished:  DateTime.fromObject({ weekday: workWeekStart })
                      //     .plus({ days: location?.payrollPeriod?.duration })
                      //     .toFormat('yyyy-MM-dd'),
                      //   locationId: location.value,
                      // });
                      handleLocationAutocompleteChange(
                        location,
                        {
                          ...variables,
                          // timeshiftFilter: {
                          //     started: {
                          //       gte: DateTime.fromObject({ weekday: workWeekStart }).toFormat(
                          //         'yyyy-MM-dd'),
                          //       lt: DateTime.fromObject({ weekday: workWeekStart })
                          //         .plus({ days: location?.payrollPeriod?.duration })
                          //         .toFormat('yyyy-MM-dd'),
                          //     }
                          // }
                        },
                        refetch,
                      );
                    }}
                    getOptionLabel={option => option.label}
                    control={control}
                    defaultValue={locationList[0]}
                    style={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={'Locations'}
                        variant="outlined"
                        inputRef={register()}
                      />
                    )}
                    options={locationList}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {locationInfo.finished && (
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="LL/dd/yyyy"
                      // margin="normal"
                      fullWidth
                      label="Date range start"
                      labelFunc={() => {
                        return `${DateTime.fromFormat(
                          variables.timeshiftFilter.started.gte,
                          'yyyy-MM-dd HH:mm:ss',
                        ).toFormat('yyyy-MM-dd')} to ${DateTime.fromFormat(
                          variables.timeshiftFilter.started.lt,
                          'yyyy-MM-dd HH:mm:ss',
                        )
                          .minus({ days: 1 })
                          .toFormat('yyyy-MM-dd')}`;
                      }}
                      initialFocusedDate={variables.timeshiftFilter.started.gte}
                      onChange={date => handleDateChange(date)}
                      shouldDisableDate={date =>
                        date.weekday !== (locationInfo.workWeekStartId || 1)
                      }
                      TextFieldComponent={props => <TextField {...props} disabled={true} />}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Grid>
            </Grid>
          );
        }}
        collapsableRows={(data, row) => {
          return (
            <DataTable
              data={row.locationPerson}
              permissions={data?.viewer?.locationPersonConnection?.permissions}
              structureTable={locationPerson => {
                return locationPerson.person?.timeshiftConnection?.edges?.map(timeshift => {
                  return {
                    id: timeshift?.node?.id,
                    job: timeshift?.node?.job?.label,
                    started: {
                      time: timeshift?.node?.started,
                      timezone: timeshift?.node?.location?.timezone?.label,
                    },
                    finished: {
                      time: timeshift?.node?.finished,
                      timezone: timeshift?.node?.location?.timezone?.label,
                      isFinished: timeshift?.node?.isFinished,
                    },
                    hours: timeshift?.node?.isFinished
                      ? (timeshift?.node?.finished &&
                          timeshift?.node?.started &&
                          convert.decimalHoursToHHmm(
                            moment
                              .duration(
                                moment(timeshift?.node?.finished).diff(
                                  moment(timeshift?.node?.started),
                                ),
                              )
                              .asHours(),
                          )) ||
                        'Clocked out'
                      : 'Not clocked out',
                    wages: timeshift?.node?.isFinished
                      ? (timeshift?.node?.wages?.fee &&
                          `${timeshift?.node?.wages?.error ||
                            convert.centsToDollars(timeshift?.node?.wages?.fee)}$`) ||
                        'Not available'
                      : 'Not clocked out',
                    payRate: timeshift?.node?.wages?.error || `${timeshift?.node?.wages?.rate} $`,
                    overtimeHours:
                      timeshift?.node?.overtime?.error ||
                      convert.decimalHoursToHHmm(timeshift?.node?.overtime?.hours),
                    overtimeFee:
                      timeshift?.node?.overtime?.error ||
                      `${convert.centsToDollars(timeshift?.node?.overtime?.fee)} $`,
                    paidBreak: moment.utc(timeshift?.node?.totalPaidBreak).isValid()
                      ? convert.decimalHoursToHHmm(
                          moment.duration(timeshift?.node?.totalPaidBreak).asHours(),
                        )
                      : 'Job breaks not defined',
                    unpaidBreak: moment.utc(timeshift?.node?.totalUnpaidBreak).isValid()
                      ? convert.decimalHoursToHHmm(
                          moment.duration(timeshift?.node?.totalUnpaidBreak).asHours(),
                        )
                      : 'Job breaks not defined',
                    tip: timeshift?.node?.isFinished
                      ? timeshift?.node?.tip
                        ? `${convert.centsToDollars(timeshift?.node?.tip)} $`
                        : 0
                      : 'Not clocked out',
                    shiftTotal:
                      timeshift?.node?.shiftTotal?.error ||
                      `${convert.centsToDollars(timeshift?.node?.shiftTotal?.fee)} $`,
                    creator: compact([
                      timeshift?.node?.creator?.firstName,
                      timeshift?.node?.creator?.lastName,
                    ]).join(' '),
                    clockedOutBy: timeshift?.node?.clockedOutBy,
                    clockout: {
                      id: timeshift?.node?.id,
                      timezone: timeshift?.node?.location?.timezone?.label,
                      disabled: timeshift?.node?.disabled,
                      permissions: data?.viewer?.locationPersonConnection?.permissions,
                    },
                    isDelivery: timeshift?.node?.isDelivery,
                    timezone: timeshift?.node?.location?.timezone?.label,
                    jobId: timeshift?.node?.job?.id,
                    permissions: data?.viewer?.locationPersonConnection?.permissions,
                    locationPerson,
                    disableTableRowClick:
                      timeshift?.node?.disabled || !updateTimeshiftPermission?.access,
                    disableDelete: timeshift?.node?.disabled || !removeTimeshiftPermission?.access,
                  };
                });
              }}
              columns={[
                {
                  title: 'Job',
                  field: 'job',
                  customPermissionField: 'person.timeshiftConnection.edges.node.job.label',
                },
                {
                  title: 'Clock In',
                  field: 'started',
                  customPermissionField: 'person.timeshiftConnection.edges.node.started',
                  enablePropagation: true,
                  render: started =>
                    moment
                      .tz(moment.utc(started.time), started.timezone)
                      .format('MM/DD/YYYY hh:mm A'),
                },
                {
                  title: 'Clock Out',
                  field: 'finished',
                  customPermissionField: 'person.timeshiftConnection.edges.node.finished',
                  enablePropagation: true,
                  render: finished =>
                    finished.isFinished
                      ? moment
                          .tz(moment.utc(finished.time), finished.timezone)
                          .format('MM/DD/YYYY hh:mm A')
                      : 'Not clocked out',
                },
                {
                  title: 'Hours',
                  field: 'hours',
                  customPermissionField: 'person.timeshiftConnection.edges.node.isFinished',
                },
                {
                  title: 'Wages',
                  field: 'wages',
                  customPermissionField: 'person.timeshiftConnection.edges.node.wages',
                },
                {
                  title: 'Pay Rate $',
                  field: 'payRate',
                  customPermissionField: 'person.timeshiftConnection.edges.node.wages',
                },
                {
                  title: 'OT',
                  field: 'overtimeHours',
                  customPermissionField: 'person.timeshiftConnection.edges.node.overtime',
                },
                {
                  title: 'OT Total',
                  field: 'overtimeFee',
                  customPermissionField: 'person.timeshiftConnection.edges.node.overtime',
                },
                {
                  title: 'Paid Break',
                  field: 'paidBreak',
                  customPermissionField: 'person.timeshiftConnection.edges.node.totalPaidBreak',
                },
                {
                  title: 'Unpaid Break',
                  field: 'unpaidBreak',
                  customPermissionField: 'person.timeshiftConnection.edges.node.totalUnpaidBreak',
                },
                {
                  title: 'Tips',
                  field: 'tip',
                  customPermissionField: 'person.timeshiftConnection.edges.node.tip',
                },
                {
                  title: 'Shift Total',
                  field: 'shiftTotal',
                  customPermissionField: 'person.timeshiftConnection.edges.node.shiftTotal',
                },
                {
                  title: 'Edited By',
                  field: 'creator',
                  customPermissionField: 'person.timeshiftConnection.edges.node.creator.__typename',
                },
                {
                  title: 'Clocked Out By',
                  field: 'clockedOutBy',
                  customPermissionField: 'person.timeshiftConnection.edges.node.clockedOutBy',
                },
                {
                  title: '',
                  field: 'clockout',
                  customPermissionField: '__typename',
                  render: timeshift =>
                    !timeshift?.disabled ? (
                      <Permission
                        access={Math.min(
                          clockoutTimeshiftPermission?.access,
                          accessToMutations?.[MUTATION_NAME.ClockoutTimeshiftMutationInput],
                        )}
                      >
                        <div>
                          <PermissionField createHelperText>
                            <Button
                              style={{ width: '200px' }}
                              onClick={() => {
                                handleOpenClockoutModal(timeshift);
                              }}
                            >
                              Clock Out Employee
                            </Button>
                          </PermissionField>
                        </div>
                      </Permission>
                    ) : (
                      ''
                    ),
                },
              ]}
              handleDeleteRow={handleDeleteTimeshift}
              onTableRowClick={handleOpenEditTimeshiftModal}
              isDeleting={isDeletingTimeshift}
              useConfirmationModalForDelete
              deleteConfirmationModalText="Are you sure you want to delete timeshift?"
            />
          );
        }}
      />
      <Dialog open={finalizePayrollModalOpen.open} onClose={closeFinalizePayrollModal}>
        <DialogTitle>{`Finalize Payrolls ${DateTime.fromSQL(locationInfo.started).toFormat(
          'MM/dd/yyyy',
        )} - ${DateTime.fromSQL(locationInfo.finished)
          .minus({ days: 1 })
          .toFormat('MM/dd/yyyy')}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`You are to finalize your payroll report. This will lock the ${DateTime.fromSQL(
              locationInfo.started,
            ).toFormat('MM/dd/yyyy')} - ${DateTime.fromSQL(locationInfo.finished)
              .minus({ days: 1 })
              .toFormat('MM/dd/yyyy')} payroll period!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFinalizePayrollModal} variant="outlined">
            Cancel
          </Button>
          <Permission access={finalizePayrollPermission?.access}>
            <div>
              <PermissionField createHelperText>
                <Button
                  disabled={mutationLoading}
                  onClick={() =>
                    finalizePayroll({
                      variables: {
                        input: {
                          locationId: finalizePayrollModalOpen.locationId,
                          started: locationInfo.started,
                          finished: locationInfo.finished,
                        },
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [{ query, variables: { ...tableState } }],
                    })
                  }
                  color="primary"
                  autoFocus
                >
                  CONFIRM & FINALIZE
                </Button>
              </PermissionField>
            </div>
          </Permission>
        </DialogActions>
      </Dialog>
      <Clockout
        handleCloseClockoutModal={handleCloseClockoutModal}
        openClockoutModal={openClockoutModal}
        permissions={openClockoutModal.permissions}
        control={control}
        mutationLoading={mutationLoading}
        handleSubmit={handleSubmit}
        handleClockout={handleClockout}
        started={locationInfo.started}
        finished={locationInfo.finished}
        accessToMutations={accessToMutations}
        clockoutTimeshiftPermission={clockoutTimeshiftPermission}
      />
      <CreateTimeshift
        open={openCreateTimeshiftModal.open}
        permissions={openCreateTimeshiftModal?.locationPerson?.permissions}
        createTimeshift={createTimeshift}
        createTimeshiftPermission={createTimeshiftPermission}
        handleCloseCreateTimeshiftModal={handleCloseCreateTimeshiftModal}
        locationPerson={openCreateTimeshiftModal.locationPerson}
        handleSubmit={handleSubmit}
        timezone={openCreateTimeshiftModal?.locationPerson?.timezone}
        control={control}
        errors={errors}
        mutationLoading={mutationLoading}
        startedFilter={locationInfo.started}
        finishedFilter={locationInfo.finished}
        accessToMutations={accessToMutations}
      />
      <EditTimeshift
        open={openEditTimeshiftModal.open}
        updateTimeshift={updateTimeshift}
        accessToMutations={accessToMutations}
        handleCloseEditTimeshiftModal={handleCloseEditTimeshiftModal}
        timeshift={openEditTimeshiftModal.timeshift}
        timezone={openEditTimeshiftModal.timezone}
        handleSubmit={handleSubmit}
        control={control}
        errors={errors}
        mutationLoading={mutationLoading}
        startedFilter={locationInfo.started}
        finishedFilter={locationInfo.finished}
        updateTimeshiftPermission={updateTimeshiftPermission}
      />
    </>
  );
};

// Custom functions
export default TimeshiftView;
