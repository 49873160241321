import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import ITEM_FROM_MULTIPLE from "../../../../../../../../apollo/queries/menus/itemsFromMultiple";
import paths from "../../../../../../../../constants/paths";
import { uglifyId } from "../../../../../../../../utils";

const ItemList = ({ index }) => {
  const { multipleId } = useParams();
  const { data: itemListData, error: itemListError, loading: itemListLoading } = useQuery(ITEM_FROM_MULTIPLE, {
    variables: {
      id: uglifyId("Multiple", multipleId)
    },
    fetchPolicy: "cache-first"
  });
  if (itemListLoading) return <div>loading...</div>;
  if (itemListError) console.log(itemListError);

  const Items = () => {
    const getPath = (menuId, categoryId, subcategoryId, itemId) => {
      const str = paths.SETTINGS_ITEM_ID.replace(":menuId", menuId);
      const str1 = str.replace(":categoryId", categoryId);
      const str2 = str1.replace(":subcategoryId", subcategoryId);
      return str2.replace(":itemId", itemId);
    };

    const items = itemListData?.viewer?.multiple?.itemMultipleConnection?.edges?.map(item => ({
      id: item?.node?.item?.id,
      label: item?.node?.item?.label,
      itemId: item?.node?.item?.itemId,
      subcategoryId: item?.node?.item?.subcategoryItemConnection?.edges[0]?.node?.subcategory?.subcategoryId,
      categoryId: item?.node?.item?.subcategoryItemConnection?.edges[0]?.node?.subcategory?.categoryId,
      menuId: item?.node?.item?.subcategoryItemConnection?.edges[0]?.node?.subcategory?.menuId
    }));

    return items?.length > 0 ? (
      <div>
        <Typography component="h3" variant="h6">This multiple choice is used on these items:</Typography>
        {items.map(item => (
        <Typography component="p" key={`itemList-${item.id}`}>
          {item.label} <Link
          to={getPath(item.menuId, item.categoryId, item.subcategoryId, item.itemId)}>{item.itemId}</Link>
        </Typography>
        ))}
      </div>
    ) : (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <IconButton style={{ padding: '0' }} aria-label="copy">
          <WarningIcon style={{ color: '#F79903', fontSize: '3rem' }} />
        </IconButton>
        <Typography component="p" style={{ color: '#F79903' }}>
          NOTE: This Item doesn't have any multiple choices.
        </Typography>
      </div>
    );
  };
  return (
    <div>
      <Items />
    </div>
  );
};

export default ItemList;
