import React, { useState, useContext, useMemo } from 'react';
import { CircularProgress, Grid, Button, Typography, Link } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import HandoffComponent from './HandoffComponent';

import { LocationIdContext } from '../index';

import { PlacesAutocomplete, ConnectionTable, Modal, PermissionField } from '../../../../blocks';

import { uglifyId } from '../../../../../utils';
import CREATE_LOCATION_EXCLUSIVE_ADDRESS from '../../../../../apollo/mutations/locations/createLocationExclusiveDeliveryAddress';
import CREATE_ADDRESS from '../../../../../apollo/mutations/locations/createAddress';
import REMOVE_LOCATION_EXCLUSIVE_DELIVERY_ADDRESS from '../../../../../apollo/mutations/locations/removeLocationExclusiveDeliveryAddress';
import {
  FETCH_LOCATION_EXCLUSIVE_DELIVERY_ADDRESS,
  FETCH_STATES,
} from '../../../../../apollo/queries';
import { SNACKBAR_STATUS, MUTATION_NAME } from '../../../../../constants';
import { CoreContext } from '../../../../../CoreContext';
import Permission from '../../../../blocks/Permission';
import FETCH_LOCATION_MENU from '../../../../../apollo/queries/locations/fetchLocationMenu';
import { useFormSubmitPermissions } from '../../../../../hooks';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    padding: theme.spacing(5),
    '& .MuiPaper-root': {
      boxShadow: 'none',
      marginBottom: theme.spacing(5),
      padding: '0 !important',
    },
  },
  paper: {
    position: 'absolute',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const OrderSettings = () => {
  const context = useContext(CoreContext);
  const { setSnackbar } = useSnackbar();

  const specificPermissions = context?.coreUtils?.getAppActions()[18]?.specific;

  const burqAccountSetupPermission = useMemo(
    () => specificPermissions.find(permission => permission.label === 'Burq account setup button'),
    [specificPermissions],
  );

  const mutationPermissions = context?.coreUtils?.getAppActions()[18]?.mutations;
  const removeLocationExclusiveDeliveryAddressPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'removeLocationExclusiveDeliveryAddress',
  );
  const createLocationExclusiveDeliveryAddressPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'createLocationExclusiveDeliveryAddress',
  );
  const createAddressPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'createAddress',
  );

  const locationId = atob(useContext(LocationIdContext))?.split(':')?.[1];

  OrderSettings.propTypes = {
    locationId: PropTypes.number,
  };
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [locationAddress, setLocationAddress] = useState(false);
  const { data, error, loading } = useQuery(FETCH_STATES, {
    variables: {
      first: 99999,
    },
  });
  const [permissions, setPermissions] = useState();
  const [handoffPermissions, setHandoffPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.CreateLocationExclusiveDeliveryAddressMutationInput,
      MUTATION_NAME.UpdateLocationTimeSlotMutationInput,
      MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput,
      MUTATION_NAME.UpdateLocationHandoffCategoryMutationInput,
      MUTATION_NAME.NewLocationnoteMutationInput,
    ],
    permissions: {
      ...(permissions || {}),
      ...(handoffPermissions || {}),
    },
  });

  const {
    data: dataLocationMenu,
    error: errorLocationMenu,
    loading: loadingLocationMenu,
  } = useQuery(FETCH_LOCATION_MENU, {
    variables: {
      id: uglifyId('Location', locationId),
    },
  });

  const [createLocationExclusiveAddress] = useMutation(CREATE_LOCATION_EXCLUSIVE_ADDRESS, {
    // eslint-disable-next-line no-shadow
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createLocationExclusiveDeliveryAddress?.viewer),
        fields: {
          locationExclusiveDeliveryAddressConnection() {
            return {
              __typename: 'LocationExclusiveDeliveryAddressEdge',
            };
          },
        },
      });
    },
  });
  const [createAddress] = useMutation(CREATE_ADDRESS, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createAddress?.viewer),
        fields: {
          address() {
            return {
              __typename: 'Address',
            };
          },
        },
      });
    },
  });
  const [
    removeLocationExclusiveDeliveryAddressMutation,
    { loading: isDeletingLocationExclusiveDeliveryAddress },
  ] = useMutation(REMOVE_LOCATION_EXCLUSIVE_DELIVERY_ADDRESS);

  const handleOpen = () => {
    setOpen(true);

    setOpenWarningMutationName(MUTATION_NAME.CreateLocationExclusiveDeliveryAddressMutationInput);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loadingLocationMenu) return <CircularProgress />;
  if (errorLocationMenu) return <div>error</div>;
  if (!dataLocationMenu?.viewer?.location) return <div>No data available for this location</div>;

  if (loading) return <CircularProgress />;
  if (error) return <div>error</div>;

  const handleGeocodeAutocomplete = address => {
    const { state } = address;
    // eslint-disable-next-line no-shadow
    const formatState = data?.viewer?.stateConnection?.edges?.map(state => ({
      label: state.node.label,
      value: state.node.stateId,
    }));
    // eslint-disable-next-line no-param-reassign
    address.state = formatState.find(existingState => existingState.label === state);
    setLocationAddress(address);
  };
  const createLocationExclusiveDeliveryAddress = () => {
    if (locationAddress) {
      const {
        address,
        city,
        state: { label },
        zip,
      } = locationAddress;
      const input = {
        address,
        city,
        zip,
        stateLabel: label,
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Adding address...',
        open: true,
      });

      createAddress({
        variables: {
          input,
        },
      })
        .then(r => {
          createLocationExclusiveAddress({
            variables: {
              input: {
                addressId: r?.data?.createAddress?.address?.id,
                locationId: uglifyId('Location', locationId),
              },
            },
          })
            .then(() =>
              setSnackbar({
                type: SNACKBAR_STATUS.SUCCESS,
                text: 'Address added',
                open: true,
              }),
            )
            .catch(e =>
              setSnackbar({
                type: SNACKBAR_STATUS.ERROR,
                text: e.message,
                open: true,
              }),
            );
        })
        .catch(e =>
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: e.message,
            open: true,
          }),
        );
    }

    handleClose();
  };

  const removeLocationExclusiveDeliveryAddress = ({ locationExclusive }) => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Deleting location...',
      open: true,
    });

    removeLocationExclusiveDeliveryAddressMutation({
      variables: {
        input: {
          locationId,
          addressId: locationExclusive?.address?.addressId,
        },
      },
      // eslint-disable-next-line no-shadow
      update(cache, data) {
        cache.modify({
          id: cache.identify(data.data?.removeLocationExclusiveDeliveryAddress?.viewer),
          fields: {
            locationExclusiveDeliveryAddressConnection() {
              return {
                __typename: 'LocationExclusiveDeliveryAddressConnection',
              };
            },
          },
        });
      },
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Location deleted',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" component="h2" id="orderSettings">
        Order Settings
      </Typography>
      <ConnectionTable
        query={FETCH_LOCATION_EXCLUSIVE_DELIVERY_ADDRESS}
        // title={'Delivery for specific addresses'}
        customActionsCenter={data => {
          if (!permissions) {
            setPermissions({
              addressId:
                data?.viewer?.locationExclusiveDeliveryAddressConnection?.permissions?.addressId,
              locationId:
                data?.viewer?.locationExclusiveDeliveryAddressConnection?.permissions?.locationId,
            });
          }
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div>
                {dataLocationMenu?.viewer?.location?.burqId && (
                  <Permission access={!!burqAccountSetupPermission?.access}>
                    <Link
                      component={Button}
                      variant="text"
                      color="secondary"
                      style={{
                        color: 'white',
                        background: 'red',
                        marginRight: '10px',
                      }}
                      href={
                        'https://dashboard.burqup.com/onboarding/platform_accounts/acct_19g67ld4oj3eg'
                      }
                      target="_blank"
                    >
                      BURQ ACCOUNT SETUP
                    </Link>
                  </Permission>
                )}
              </div>
              <div>
                <Permission
                  access={Math.min(
                    createLocationExclusiveDeliveryAddressPermission?.access,
                    accessToMutations?.[
                      MUTATION_NAME.CreateLocationExclusiveDeliveryAddressMutationInput
                    ],
                  )}
                >
                  <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                    <PermissionField createHelperText>
                      <Button variant="text" onClick={() => handleOpen()}>
                        Add Exclusive Delivery Address
                      </Button>
                    </PermissionField>
                  </div>
                </Permission>
              </div>
            </div>
          );
        }}
        initialQueryVariables={{
          first: 5,
          filter: [
            {
              locationId: {
                eq: locationId,
              },
            },
          ],
        }}
        /* eslint-disable-next-line no-shadow */
        structureTable={data =>
          data?.viewer?.locationExclusiveDeliveryAddressConnection?.edges.map(location => ({
            'address.address': location?.node.address.address,
            locationExclusive: location?.node,
          }))
        }
        columns={[
          {
            title: 'Exlusive Delivery Address',
            field: 'address.address',
            disableDelete: !removeLocationExclusiveDeliveryAddressPermission?.access,
          },
        ]}
        deleteConfirmationText="Are you sure you want to delete location?"
        useConfirmationModalForDelete
        handleDeleteRow={removeLocationExclusiveDeliveryAddress}
        isDeleting={isDeletingLocationExclusiveDeliveryAddress}
      />
      <HandoffComponent
        locationId={locationId}
        menuId={dataLocationMenu?.viewer?.location?.menuId}
        mutationPermissions={mutationPermissions}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
        handoffPermissions={handoffPermissions}
        setHandoffPermissions={setHandoffPermissions}
      />
      <Modal title="Add Exclusive Delivery" open={open} handleClose={handleClose}>
        <Grid container item xs={12} spacing={4}>
          <Grid item container xs={12}>
            <PlacesAutocomplete handleGeocodeAutocomplete={handleGeocodeAutocomplete} />
          </Grid>
          <Permission
            access={Math.min(
              accessToMutations?.[
                MUTATION_NAME.CreateLocationExclusiveDeliveryAddressMutationInput
              ],
              createLocationExclusiveDeliveryAddressPermission?.access,
              createAddressPermission?.access,
            )}
          >
            <Grid container item xs={12} justify="flex-end">
              <div>
                <PermissionField createHelperText>
                  <Button onClick={() => createLocationExclusiveDeliveryAddress()}>Submit</Button>
                </PermissionField>
              </div>
            </Grid>
          </Permission>
        </Grid>
      </Modal>
    </div>
  );
};

export default OrderSettings;
