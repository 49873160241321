import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";

import VendorLocationsView from './View';
import AddLocationView from './AddLocationView'

import Modal from '../../../../../../blocks/Modal';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import { CREATE_LOCATION_VENDOR } from '../../../../../../../apollo/mutations/vendors';
import { formatConnectionName } from '../../../../../../../utils';

const VendorLocations = () => {
  
  const history = useHistory();
  const { vendorId } = useParams();

  const { setSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [connectionVariables, setConnectionVariables] = useState({});

  const [createLocationVendor, { loading: createLocationVendorLoading }] = useMutation(CREATE_LOCATION_VENDOR, {  
    update: (cache, { data: { createLocationVendor } }) => {
      const { dynamicConnectionName } = formatConnectionName('locationVendor', connectionVariables);
      cache.modify({
        id: cache.identify(createLocationVendor.viewer),
        fields: {
          [dynamicConnectionName]: existingLocationVendorConnection => {
            return {
              ...existingLocationVendorConnection,
              totalCount: existingLocationVendorConnection.totalCount + 1,
              edges: [
                ...existingLocationVendorConnection.edges,
                {
                  node: {
                    __ref: `LocationVendor:${createLocationVendor.locationVendor.id}`
                  }
                }
              ]
            }
          }
        }
      });
    },
    onCompleted: (data) => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Location added!',
      });

      handleCloseModal();
    },
    onError: (err) => {
      setSnackbar({
        open: true,
        type: 'error',
        text: err.message || 'Oops, something went wrong...',
      });
    },
  });

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);
  
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSubmit = useCallback(async (formData) => {
    await createLocationVendor({
      variables: {
        input: formData,
        fieldGroupOnly: 76,
      },
    });
  }, [createLocationVendor]);

  return (
    <>
      <VendorLocationsView 
        history={history}
        vendorId={vendorId}
        handleOpenModal={handleOpenModal}
        connectionVariables={connectionVariables}
        setConnectionVariables={setConnectionVariables}
      />
      <Modal
        open={showModal}
        handleClose={handleCloseModal}
        title="Add Location Vendor"
      >
        <AddLocationView
          onSubmit={onSubmit}
          vendorId={vendorId}
          handleCloseModal={handleCloseModal}
          createLocationVendorLoading={createLocationVendorLoading}
        />
      </Modal>
    </>
    );
};

export default VendorLocations;
