// used for both creating and copying usergroups based on `type` param
import { gql } from '@apollo/client';

const MANIPULATE_USERGROUP = gql`
  mutation manipulateUsergroup($usergroupLabel: String!, $action: String!) {
    manipulateUsergroup(input: { usergroupLabel: $usergroupLabel, action: $action }) {
      usergroup {
        id
      }
    }
  }
`;

export default MANIPULATE_USERGROUP;
