import React from 'react';
import { Grid } from '@material-ui/core';
import Payment from '../../../../../../../../blocks/Payment';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../../../../../../../../App';
import { HouseAccountPaymentStyles } from './styles';
import {
  convertHouseAccountValueForFrontend,
  getAvailableCredit,
} from '../../../../../../../../../utils/houseAccountUtils';
import { noLimitLabel } from '../../../../../../../../../constants/houseAccounts';

export default function PayHouseAccountModal({
  houseAccountData,
  customerData,
  createHouseAccountDepositPermission,
  handleCloseModal,
}) {
  const classes = HouseAccountPaymentStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={4}>
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Credit Limit</p>
              </div>
              <div className={classes.rowValue}>
                {houseAccountData.limit || houseAccountData.limit === 0
                  ? `$${convertHouseAccountValueForFrontend(houseAccountData.limit)}`
                  : noLimitLabel}
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Account Balance</p>
              </div>
              <div className={classes.rowValue}>
                ${convertHouseAccountValueForFrontend(houseAccountData.balance)}
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Available Credit</p>
              </div>
              <div className={classes.rowValue}>
                {houseAccountData.limit || houseAccountData.limit === 0
                  ? `$${getAvailableCredit(houseAccountData.limit, houseAccountData.balance)}`
                  : noLimitLabel}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Elements stripe={stripePromise}>
          <Payment
            quote={{
              person: { ...customerData.node },
              dueAmount: Math.abs(houseAccountData.balance),
            }}
            handleClose={handleCloseModal}
            paymentType={'houseAccount'}
            houseAccount={houseAccountData}
            payPermission={createHouseAccountDepositPermission}
          />
        </Elements>
      </Grid>
    </Grid>
  );
}
