import { gql } from '@apollo/client';

const FETCH_MENUS = gql`
  query fetchMenus {
    viewer {
      id
      menuConnection(fieldGroup: 17) {
        edges {
          node {
            id
            menuId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_MENUS;
