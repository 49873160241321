import { gql } from '@apollo/client';

const FETCH_PRINTER_SERVICE = gql`
  query fetchPrinterService {
    viewer {
      id
      printerserviceConnection {
        edges {
          node {
            id
            printerserviceId
            label
            description
          }
        }
      }
    }
  }
`;

export default FETCH_PRINTER_SERVICE;
