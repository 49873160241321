import { gql } from '@apollo/client';

export const colorFragment = {
  color: gql`
    fragment ColorData on Color {
      id
      label
      colorId
    }
  `,
};
