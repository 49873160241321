import React from 'react';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import PropTypes from 'prop-types';
import Payment from '../Payment/index';

const Tip = ({ open, quote, handleClose, stateList, addTipPermission }) => {
  return (
    <Dialog open={open} keepMounted onClose={handleClose} maxWidth={false}>
      <DialogTitle>Add Tip</DialogTitle>
      <DialogContent>
        {quote && (
          <Payment
            quote={quote}
            paymentType={'tip'}
            handleClose={handleClose}
            stateList={stateList}
            addTipPermission={addTipPermission}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Tip;

Tip.propTypes = {
  open: PropTypes.bool,
  quote: PropTypes.object,
  stateList: PropTypes.array,
  handleClose: PropTypes.func,
};
