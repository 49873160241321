import React, { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { getDisputeInfo } from "../../../../../utils";
import { fileReaders } from "../../../../../utils";
import { CHARGEBACK_RESPOND } from "../../../../../apollo/mutations";
import DisputeChargebackModalView from "./View/index";
import { MUTATION_NAME } from "../../../../../constants";
import { useSnackbar } from "../../../../../hooks/useSnackbar";

/**
 * TODO: implement notifications(after chargeback initiated) success or error.
 */

function ChargebackModal({ accessToMutations, handleClose, data }) {
  const { setSnackbar } = useSnackbar();

  let { disputeReason, paymentDetails } = getDisputeInfo(data);
  const [submitToBank, setSubmitTobank] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [chargeback, setChargeback] = useState({
    response: paymentDetails.uncategorizedText || "",
    receipt: null,
    communication: null,
    uncategorized: null,
  });
  const [createChargeBack, { loading: createChargeBackLoading }] = useMutation(
    CHARGEBACK_RESPOND,
    {
      onCompleted: (data) => {
        setDisableButton(false);
        if (submitToBank) {
          setSnackbar({
            open: true,
            type: "success",
            text: "Evidence submited to bank",
          });
          return;
        }
        setSnackbar({
          open: true,
          type: "success",
          text: "Evidence saved.",
        });
      },
      onError: (err) => {
        setDisableButton(false);
        setSnackbar({
          open: true,
          type: "error",
          text: err.message,
        });
      },
    }
  );

  const handleResponseChange = (e) => {
    setChargeback({ ...chargeback, response: e.target.value });
  };

  //Upload file forms
  //Recepient
  const uploadReceiptForm = useRef(null);
  const onUploadReceiptFormClick = () => {
    uploadReceiptForm.current.click();
  };
  const handleUploadReceipt = async (e) => {
    const file = getFile(e);
    if (file) {
      setChargeback({ ...chargeback, receipt: file });
    }
  };

  //Comunication
  const uploadCommunicationForm = useRef(null);
  const onUploadCommunicationFormClick = () => {
    uploadCommunicationForm.current.click();
  };
  const handleUploadCommunication = (e) => {
    const file = getFile(e);
    if (file) {
      setChargeback({ ...chargeback, communication: file });
    }
  };

  //Uncategorized
  const uploadUncategorizedForm = useRef(null);
  const onUploadUncategorizedFormClick = () => {
    uploadUncategorizedForm.current.click();
  };
  const handleUploadUncategorized = (e) => {
    const file = getFile(e);
    if (file) {
      setChargeback({ ...chargeback, uncategorized: file });
    }
  };

  const getFile = (e) => {
    if (!e?.target?.files[0]) return null;
    return e.target.files[0];
  };

  const handleSubmitToBank = async (_submit) => {
    /* @param _submit:  if true the dispute evidence will be submited to bank immediately,
         if false you can submit the evidence until due date after which stripe will auto-submit evidence.
    */
    setSubmitTobank(_submit);
    const files = {
      receiptFile: chargeback.receipt
        ? await fileReaders.readFile(chargeback.receipt)
        : null,
      customerCommunicationFile: chargeback.communication
        ? await fileReaders.readFile(chargeback.communication)
        : null,
      uncategorizedFile: chargeback.uncategorized
        ? await fileReaders.readFile(chargeback.uncategorized)
        : null,
    };

    const _data = {
      orderId: data.viewer.quote.quoteId,
      response: chargeback.response,
      productDescription: generateProductDescription(),
      submit: _submit,
      ...files,
    };
    setDisableButton(true);
    await createChargeBack({ variables: { input: { ..._data } } });
  };

  const generateProductDescription = () => {
    const { lineConnection } = data.viewer.quote;
    let productDescription = "";

    lineConnection.edges.forEach(({ node: line }) => {
      productDescription += `Item name: ${line?.item?.label} \n`;
      productDescription += `\tDescription: ${line?.item?.description} \n`;
      productDescription += "\tChoices:\n";
      line.lineMultipleConnection?.edges.forEach(({ node: { choice } }) => {
        productDescription += `\t\t+${choice?.label} \n`;
      });
      if (line.note) productDescription += `\tNote: ${line?.note} \n`;
      productDescription += "\n\n";
    });
    return productDescription;
  };

  return (
    <DisputeChargebackModalView
      handleUploadReceipt={handleUploadReceipt}
      handleUploadCommunication={handleUploadCommunication}
      handleUploadUncategorized={handleUploadUncategorized}
      handleResponseChange={handleResponseChange}
      handleSubmitToBank={handleSubmitToBank}
      uploadReceiptForm={uploadReceiptForm}
      uploadCommunicationForm={uploadCommunicationForm}
      uploadUncategorizedForm={uploadUncategorizedForm}
      onUploadReceiptFormClick={onUploadReceiptFormClick}
      onUploadCommunicationFormClick={onUploadCommunicationFormClick}
      onUploadUncategorizedFormClick={onUploadUncategorizedFormClick}
      disputeReason={disputeReason}
      chargeback={chargeback}
      createChargeBackLoading={createChargeBackLoading}
      handleClose={handleClose}
      disableButton={disableButton || accessToMutations?.[MUTATION_NAME.ChargebackRespondMutationInput] < 7}
    />
  );
}

export default ChargebackModal;
