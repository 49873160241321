import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  active: {
		color: theme.palette.success.main,
	},
	inactive: {
		color: theme.palette.error.main,
	}
});

const useStyles = makeStyles(Styles);
export default useStyles;