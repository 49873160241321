import 'date-fns';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  Container,
  TextField,
  Button,
  Grid,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider,
} from '@material-ui/core';
import ImageUpload from '../../../../../../../blocks/ImageUpload';
import { CREATE_ITEM } from '../../../../../../../../apollo/mutations';
import { FETCH_BRANDS, FETCH_TIER_LIST } from '../../../../../../../../apollo/queries';
import GeneralInfoInput from '../../GeneralInfoInput';
import ViewSettingsInput from '../../ViewSettingsInput';
import AvailableOnDaysInput from '../../AvailableOnDaysInput';
import convert from '../../../../../../../../utils/convert';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import { SetPricingCreateItem } from '../../../../../../../blocks';

const IMAGE = {
  sizes: ['1144x510', '375x268', '490x350', '375x167'],
};

const CreateItem = ({ ids, basePath, classes, refetchMenuList, itemTypeOptions }) => {
  const { setSnackbar } = useSnackbar();

  const [redirectUrl, setRedirectUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [availableOnDays, setAvailableOnDays] = useState('everyday');
  const [pricingData, setPricingData] = useState();
  const [tiersConnectionCreate, setTiersConnectionCreate] = useState();
  const [customError, setCustomError] = useState(false);

  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const { data: brandList, error: brandListError, loading: brandListLoading } = useQuery(
    FETCH_BRANDS,
    {
      onCompleted: data => {
        if (data?.viewer?.tierConnection) {
          setTiersConnectionCreate(
            data?.viewer?.tierConnection?.edges.map(edge => {
              return {
                tierId: edge?.node?.id,
                tierPrice: edge?.node?.price,
                tierLabel: edge?.node.label,
                nameLabel: edge?.node?.label.replace(/\s+/g, '-').toLowerCase(),
                tierPlatforms: data?.viewer?.platformConnection,
                tierPriceData: data?.viewer?.itemTierConnection?.edges.find(
                  tier => tier?.node?.tier?.id === edge?.node?.id,
                ),
              };
            }),
          );
        }
      },
    },
  );

  useEffect(() => {
    setImages(
      IMAGE.sizes.map(size => {
        return {
          size,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find(image => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { handleSubmit, control, register, errors, getValues } = useForm({
    defaultValues: {
      label: '',
      label2: '',
      description: '',
      price: null,
      priceCap: null,
      preparationTime: 0,
      minQuantity: 0,
      maxQuantity: null,
      showLocation: false,
      availableOnline: false,
      availablePos: false,
      availableThirdParty: false,
      perPerson: true,
      numberPeopleCanServe: 1,
    },
  });

  const [createItem] = useMutation(CREATE_ITEM, {
    onError: error =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      }),
    // eslint-disable-next-line no-shadow
    update: (cache, data) => {
      cache.modify({
        id: cache.identify(data.data.createItem.viewer),
        fields: {
          [`subcategoryItemConnection({"filter":{"showHiddenToLocation":{"eq":true},"showUnavailableOnline":{"eq":true},"subcategoryId":{"eq":${data.data.createItem.subcategoryItem.subcategoryId}}}})`]: existingSubcategoryItems => {
            return {
              ...existingSubcategoryItems,
              edges: [
                ...existingSubcategoryItems.edges,
                {
                  node: {
                    __ref: `SubcategoryItem:${data.data.createItem.subcategoryItem.id}`,
                  },
                },
              ],
            };
          },
        },
      });
    },
  });

  const { data, error, loading } = useQuery(FETCH_TIER_LIST);

  if (loading || brandListLoading) return <CircularProgress className={classes.spinner} />;
  if (error || brandListError) console.log(error, brandListError);

  const handleClose = () => {
    setImages([]);
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const { menuId, categoryId, subcategoryId, gqlSubcategoryId } = ids;

  const onSubmit = async data => {
    if (data.maxQuantity || data.maxQuantity === 0) {
      if (+data.minQuantity > +data.maxQuantity) {
        return setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: 'Min quantity can not be greater than max quantity!',
        });
      }
    }
    if (!pricingData?.defaultMcpi) {
      setCustomError(true);
    }

    const showOnDays = availableOnDays === 'specific';
    const weekdays = Object.values(days);

    const tiers = Object.entries(data)
      .filter(pair => pair[0].includes('tier') && pair[1] && !pair[0].includes('price-cap'))
      .map(tier => {
        return {
          tierId: tier[0].split('-')[1],
          price: convert.dollarsToCents(tier[1]),
          priceCap: convert.dollarsToCents(
            Object.entries(data).find(pair =>
              pair[0].includes(`tier-price-cap-${tier[0].split('-')[1]}`),
            )[1],
          ),
        };
      });

    const names = Object.keys(pricingData);
    const values = Object.values(pricingData);

    const dataSerialized = names.reduce((acc, curr, index) => {
      acc.push({
        name: curr,
        value: values[index],
      });
      return acc;
    }, []);

    const inputTiersData = tiersConnectionCreate.map(data => {
      if (dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value) {
        return {
          tierId: data.tierId,
          price: convert.dollarsToCents(
            dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value,
          ),
          platforms: data.tierPlatforms.edges.reduce((acc, curr) => {
            if (curr?.node?.id === 'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
              if (dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)?.value) {
                acc.push({
                  platformId: curr?.node?.id,
                  priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)
                    ?.value
                    ? convert.dollarsToCents(
                        dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)?.value,
                      )
                    : null,
                });
              }
            }
            if (curr?.node?.id === 'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
              if (dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)?.value) {
                acc.push({
                  platformId: curr?.node?.id,
                  priceCap: convert.dollarsToCents(
                    dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)?.value,
                  ),
                });
              }
            }
            if (curr?.node?.id === 'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
              if (dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)?.value) {
                acc.push({
                  platformId: curr?.node?.id,
                  priceCap: convert.dollarsToCents(
                    dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)?.value,
                  ),
                });
              }
            }
            return acc;
          }, []),
        };
      }
    });

    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Creating item...',
    });

    setButtonDisabled(true);

    const imagesToUpload = images.map(image => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    const response = await createItem({
      variables: {
        input: {
          minCalories: data.minCalories ? data.minCalories : null,
          maxCalories: data.maxCalories ? data.maxCalories : null,
          subcategoryId: gqlSubcategoryId,
          label: data.label,
          label2: data.label2,
          description: data.description,
          price: convert.dollarsToCents(pricingData?.defaultMcpi),
          priceCap: convert.dollarsToCents(pricingData?.defaultMaxPrice),
          preparationTime: data.preparationTime,
          minQuantity: data.minQuantity,
          ...(data.maxQuantity && { maxQuantity: data.maxQuantity }),
          showLocation: data.showLocation,
          availableOnline: data.availableOnline,
          availablePos: data.availablePos,
          availableThirdParty: data.availableThirdParty,
          tiers: inputTiersData?.filter(data => data !== undefined),
          itemtypeId: data.itemtypeId,
          isDonation: false,
          isGift: false,
          showOnDays,
          weekdays,
          images: imagesToUpload,
          brandId: data.brandId,
          perPerson: data.perPerson,
          numberPeopleCanServe: data.numberPeopleCanServe,
        },
      },
      refetchQueries: [
        {
          query: FETCH_TIER_LIST,
        },
      ],
    });

    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.SUCCESS,
      text: 'Item created!',
    });

    setRedirectUrl(
      `${basePath}/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}/items/${response.data.createItem.subcategoryItem.item.itemId}`,
    );

    setButtonDisabled(false);
  };

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <GeneralInfoInput
            brandList={brandList?.viewer?.brandConnection?.edges}
            isItem={true}
            imageUrl={previewImage}
            setOpen={setOpen}
            control={control}
            register={register}
            errors={errors}
            itemTypeOptions={itemTypeOptions}
          />
          <AvailableOnDaysInput
            days={days}
            setDays={setDays}
            availableOnDays={availableOnDays}
            setAvailableOnDays={setAvailableOnDays}
          />
          <Container className={classes.sectionContainer}>
            <Grid container spacing={3}>
              <ViewSettingsInput
                title={'View Settings'}
                showToDashboardUser={true}
                control={control}
              />
            </Grid>
          </Container>
          <Divider style={{ margin: '25px 0px', width: '100%' }} />
          <Container
            className={classes.sectionContainer}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              style={{ marginRight: '40px', whiteSpace: 'nowrap' }}
            >
              Item Pricing
            </Typography>
            <SetPricingCreateItem
              setPricingData={setPricingData}
              customError={customError}
              setCustomError={setCustomError}
              pricingData={pricingData}
            />
          </Container>
          <Divider style={{ margin: '25px 0px', width: '100%' }} />
          <Grid item xs={3}>
            <Button disabled={buttonDisabled} type="submit">
              Create Item
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Images</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                Web
              </Grid>
              <Grid item xs={6}>
                Mobile
              </Grid>
            </Grid>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[0]}
                maxSize={130 * 1000}
                helperText="Required image size is 1144 x 510 px in JPG format. File weight in max. 130KB."
              />
            </Grid>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[1]}
                maxSize={50 * 1000}
                helperText="Required image size is 375 x 268 px in JPG format. File weight in max. 50KB."
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[2]}
                maxSize={70 * 1000}
                helperText="Required image size is 490 x 350 px in JPG format. File weight in max. 70KB."
              />
            </Grid>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[3]}
                maxSize={70 * 1000}
                helperText="Required image size is 375 x 167 px in JPG format. File weight in max. 70KB."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

CreateItem.propTypes = {
  ids: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CreateItem;
