import { gql } from "@apollo/client";
import { itemtypeFragment } from "../../fragments";

const FETCH_ITEM_TYPES = gql`
  query fetchItemTypes(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [ItemtypeFilter]
    $sort: [ItemtypeSort]
  ) {
    viewer {
      id
      itemtypeConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        filter: $filter
        sort: $sort
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        permissions
        edges {
          node {
            ...ItemtypeData
          }
        }
      }
    }
  }
  ${itemtypeFragment.itemtype}
`;

export default FETCH_ITEM_TYPES;
