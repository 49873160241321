import { gql } from '@apollo/client';

const LOCATIONS_FOR_SUBCATEGORY_AVAILABILITY = gql`
  query LocationsForSubcategoryAvailability(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationSort]
    $specificFields: [String]
    $filter: [LocationFilter]
    $subcategoryFilter: [LocationSubcategoryFilter]
  ) {
    viewer {
      id
      locationConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        specificFields: $specificFields
        search: $search
        sort: $sort
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            locationId
            label
            storeNumber
            address {
              city
              state {
                label
                abbreviation
              }
            }
            locationSubcategoryConnection(filter: $subcategoryFilter) {
              edges {
                node {
                  id
                  subcategoryId
                  locationId
                  available
                  startTime
                  endTime
                  locationSubcategoryWeekdayConnection {
                    edges {
                      node {
                        id
                        locationId
                        subcategoryId
                        weekdayId
                        startTime
                        endTime
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LOCATIONS_FOR_SUBCATEGORY_AVAILABILITY;
