import { Grid, GridListTile, GridList } from '@material-ui/core';
import PropTypes from 'prop-types';
import IngredientsTable from '../Tables/IngredientsTable';
import AllergensTable from '../Tables/AllergensTable';
import NutrientsTable from '../Tables/NutrientsTable';
import { SideBarSectionsLayout } from '../../../../../components/blocks';

const tabViewData = [
  {
    label: 'Ingredients',
    value: 'ingredients',
    id: 'ingredients',
    sections: [
      { name: 'Ingredients', id: 'ingredients' },
      { name: 'Allergens', id: 'allergens' },
      { name: 'Nutrients', id: 'nutrients' },
    ],
  },
];

function IngredientsSettingsView() {
  return (
    <>
      <SideBarSectionsLayout sectionList={tabViewData[0].sections || []}>
        <Grid container>
          <Grid item md={12}>
            <GridList cellHeight={'auto'} cols={1}>
              <GridListTile>
                <IngredientsTable />
              </GridListTile>
              <GridListTile>
                <AllergensTable />
              </GridListTile>
              <GridListTile>
                <NutrientsTable />
              </GridListTile>
            </GridList>
          </Grid>
        </Grid>
      </SideBarSectionsLayout>
    </>
  );
}

IngredientsSettingsView.propTypes = {
  classes: PropTypes.array,
};

export default IngredientsSettingsView;
