import { FormControl } from '@material-ui/core';
import React from 'react';
import PaymentGateway from '../PaymentGateway';
function PaymentProcessorsView({
  devicesAndPrinters,
  enablePayPalAndSquare,
  gateways,
  permissions,
}) {
  return (
    <div>
      <h2 id="paymentProcessors">Payment Processors</h2>
      <div>
        {devicesAndPrinters
          .filter(device => !!device.deviceSetting)
          .map(device => (
            <div style={{ display: 'flex', alignItems: 'center', gap: '3rem' }}>
              <div>
                <h2>
                  POS ID - {device.deviceId}{' '}
                  {device.posAppVersion ? `(${device.posAppVersion})` : '(No version)'}
                </h2>
              </div>
              <div>
                <FormControl component="div">
                  {!device?.deviceSetting?.gatewayId && (
                    <PaymentGateway
                      enablePayPalAndSquare={enablePayPalAndSquare}
                      devicesettingId={device?.deviceSetting?.id}
                      deviceGatewayId={4}
                      gateways={gateways}
                      permissions={permissions}
                    />
                  )}
                  {device?.deviceSetting?.gatewayId && (
                    <PaymentGateway
                      enablePayPalAndSquare={enablePayPalAndSquare}
                      devicesettingId={device?.deviceSetting?.id}
                      deviceGatewayId={device?.deviceSetting?.gatewayId}
                      gateways={gateways}
                      permissions={permissions}
                    />
                  )}
                </FormControl>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default PaymentProcessorsView;
