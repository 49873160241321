import { FormControl, InputLabel, Grid, MenuItem, Select, Box } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import React, { useCallback } from 'react';
import {
  FETCH_RECIPE_CATEGORIES,
  FETCH_GENERAL_LEDGER_ACCOUNT,
  FETCH_REPORT_CATEGORY,
} from '../../../../../../apollo/queries';
import { DateRange, SelectFactory } from '../../../../../blocks';

const Filters = ({ filters, setFilters, groupByOptions, refetch, variables }) => {
  const handleOnGroupBySelect = useCallback(
    groupByValue => {
      setFilters(prevFilters => ({
        ...prevFilters,
        groupBy: groupByValue,
      }));

      const updatedVariables = {
        ...variables,
        filter: {
          ...variables.filter,
          inventoryitem: {
            inventoryitemcategory: {
              [`${groupByValue}Id`]: {
                in: filters[groupByValue].map(item => item.value),
              },
            },
          },
        },
        sort: {
          ingredient: {
            inventoryitem: {
              inventoryitemcategory: {
                [`${groupByValue}Id`]: 'ASC',
              },
            },
          },
        },
      };

      refetch(updatedVariables);
    },
    [setFilters, refetch, variables, filters],
  );

  const handleOnDateRangeChange = useCallback(
    dateRange => {
      refetch({
        ...variables,
        dateRange: {
          startDate: dateRange.gte,
          endDate: dateRange.lte,
        },
      });

      setFilters(prevFilters => ({ ...prevFilters, dateRange }));
    },
    [setFilters, refetch, variables],
  );

  const onGroupSelectChange = useCallback(
    items => {
      if (isEmpty(items)) {
        const filters = variables.filter;
        if (filters?.inventoryitem) {
          delete filters.inventoryitem;
        }

        refetch({
          ...variables,
          filter: !isEmpty(filters) ? filters : undefined,
        });
      } else {
        refetch({
          ...variables,
          filter: {
            ...variables.filter,
            inventoryitem: {
              inventoryitemcategory: {
                [`${filters.groupBy}Id`]: {
                  in: items.map(item => item.value),
                },
              },
            },
          },
          sort: {
            ingredient: {
              inventoryitem: {
                inventoryitemcategory: {
                  [`${filters.groupBy}Id`]: 'ASC',
                },
              },
            },
          },
        });
      }

      setFilters(prevFilters => ({ ...prevFilters, [filters.groupBy]: items }));
    },
    [filters, refetch, variables],
  );

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={6} sm={4}>
        <DateRange
          fieldName={'dateRange'}
          variables={{ filter: { dateRange: filters.dateRange } }}
          onDateChange={handleOnDateRangeChange}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Group By</InputLabel>
          <Select
            defaultValue={filters.groupBy}
            label="Group By"
            placeholder="Select group"
            multiple={false}
            fullWidth
          >
            {groupByOptions?.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => handleOnGroupBySelect(option.value)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6} sm={4}>
        <Box hidden={filters.groupBy !== groupByOptions[0].value} style={{ width: '100%' }}>
          <SelectFactory
            label="Category"
            placeholder="Search categories..."
            query={FETCH_RECIPE_CATEGORIES}
            otherVariables={{
              fieldGroupOnly: 95,
            }}
            onSelect={onGroupSelectChange}
            structureData={data =>
              data?.viewer?.inventoryitemcategoryConnection?.edges?.map((edge, index) => ({
                value: edge.node.inventoryitemcategoryId,
                id: edge.node.inventoryitemcategoryId,
                index,
                label: edge.node.label,
              }))
            }
            fullWidth
            multiple={true}
            defaultValue={[]}
            disableCloseOnSelect
            renderCheckBox={true}
          />
        </Box>
        <Box hidden={filters.groupBy !== groupByOptions[1].value} style={{ width: '100%' }}>
          <SelectFactory
            label="General Ledgers"
            placeholder="Search General Ledgers..."
            query={FETCH_GENERAL_LEDGER_ACCOUNT}
            onSelect={onGroupSelectChange}
            structureData={data =>
              data?.viewer?.generalledgeraccountConnection?.edges?.map((edge, index) => ({
                value: edge.node.generalLedgerAccountId,
                id: edge.node.generalLedgerAccountId,
                index,
                label: edge.node.label,
              }))
            }
            otherVariables={{
              fieldGroupOnly: 95,
            }}
            fullWidth
            multiple={true}
            defaultValue={[]}
            disableCloseOnSelect
            renderCheckBox={true}
          />
        </Box>
        <Box hidden={filters.groupBy !== groupByOptions[2].value} style={{ width: '100%' }}>
          <SelectFactory
            label="Report Categories"
            placeholder="Search Report Categories..."
            query={FETCH_REPORT_CATEGORY}
            onSelect={onGroupSelectChange}
            structureData={data =>
              data?.viewer?.reportcategoryConnection?.edges?.map((edge, index) => ({
                value: edge.node.reportcategoryId,
                id: edge.node.reportcategoryId,
                index,
                label: edge.node.label,
              }))
            }
            otherVariables={{
              fieldGroupOnly: 95,
            }}
            fullWidth
            multiple={true}
            defaultValue={[]}
            disableCloseOnSelect
            renderCheckBox={true}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Filters;
