import { gql } from "@apollo/client";

const CREATE_HIERARCHY = gql`
  mutation createLocationPersonHierarchy(
    $input: createLocationPersonHierarchyMutationInput!
  ) {
    createLocationPersonHierarchy(input: $input) {
      clientMutationId
    }
  }
`;

export default CREATE_HIERARCHY;
