const { gql } = require("@apollo/client");

export const FETCH_ICONTACT_DATA = gql`
  query iContact {
    viewer {
      id
      iContact{
        id
        lists
        customFields
      }
    }
  }
`;
