import React from 'react';
import DiscountResctrictionsView from './View';

function DiscountResctrictions({
  control,
  setExcludedItems,
  excludedItems,
  includedLocations,
  setIncludedLocations,
  excludedLocations,
  setExcludedLocations,
  excludedDates,
  setExcludedDates,
  excludedSubcategories,
  setExcludedSubcategories,
  setExcludedHandoffs,
  excludedHandoffs,
  handoffList,
  displayedLocationRestriction,
  setDisplayedLocationRestriction,
}) {
  return (
    <DiscountResctrictionsView
      control={control}
      setExcludedItems={setExcludedItems}
      excludedItems={excludedItems}
      includedLocations={includedLocations}
      setIncludedLocations={setIncludedLocations}
      excludedLocations={excludedLocations}
      setExcludedLocations={setExcludedLocations}
      excludedDates={excludedDates}
      setExcludedDates={setExcludedDates}
      excludedSubcategories={excludedSubcategories}
      setExcludedSubcategories={setExcludedSubcategories}
      setExcludedHandoffs={setExcludedHandoffs}
      excludedHandoffs={excludedHandoffs}
      handoffList={handoffList}
      displayedLocationRestriction={displayedLocationRestriction}
      setDisplayedLocationRestriction={setDisplayedLocationRestriction}
    />
  );
}

export default DiscountResctrictions;
