import { gql } from "@apollo/client";

export const REMOVE_VENDOR = gql`
  mutation RemoveVendor(
    $input: RemoveVendorMutationInput!
  ) {
    removeVendor(input: $input) {
      viewer{
        id
      }
      removedVendorId
    }
  }
`;
