import { useState, useMemo, useEffect, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Button,
  Grid,
  InputBase,
  IconButton,
  TextField,
  MenuItem,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';

import { EmailModalStyles } from './styles';
import { SEND_SCHEDULE_EMAIL } from '../../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const MANAGEMENT_JOBS = ['Assistant Manager', 'General Manager', 'Owner/Operator'];

const EmailPdfModal = ({
  employees,
  prettySchedulesData,
  timelineVisibleDate,
  locationId,
  generatePdf,
  setPdfSortOrder,
  pdfSortOrder,
}) => {
  const classes = EmailModalStyles();
  const { setSnackbar } = useSnackbar();

  const [search, setSearch] = useState('');
  const [emailEntireWeek, setEmailEntireWeek] = useState(true);
  const [preselected, setPreselected] = useState([]);
  const [selected, setSelected] = useState([]);

  const columns = [
    { field: 'personId', headerName: 'Person ID', width: 175, disableClickEventBubbling: true },
    { field: 'name', headerName: 'Name', width: 250, disableClickEventBubbling: true },
    { field: 'id', headerName: 'Email', width: '*', disableClickEventBubbling: true },
  ];

  const [sendEmail, { loading: sendEmailLoading }] = useMutation(SEND_SCHEDULE_EMAIL, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Emails sent successfully',
      });
    },
    onError: data => {
      setSnackbar({
        open: true,
        type: 'error',
        text: `Error sending emails.\n ${data}`,
      });
    },
  });

  const generateRows = useCallback(() => {
    return (
      employees?.viewer?.loginConnection?.edges?.map(employee => ({
        personId: employee.node.person.personId,
        name: `${employee.node.person.firstName} ${employee.node.person.lastName}`,
        id: employee.node.email, // email is set as ID so emails can be passed to the mutation
      })) || []
    );
  }, [employees]);

  const rows = useMemo(() => generateRows(), [generateRows]);

  // Select managers, assistant managers, and OO's as well as employees with
  // schedules in the preselected timeframe (single day/week) to export
  const generatePreselected = useCallback(() => {
    const preselectedSet = new Set();

    prettySchedulesData.map(schedule => {
      if (!emailEntireWeek) {
        const personHasManagementJob = schedule.node.person.personJobConnection.edges.some(
          personJob => MANAGEMENT_JOBS.includes(personJob.node.job.label),
        );
        const personHasScheduleOnCurrentDate = moment(schedule.node.started).isSame(
          timelineVisibleDate,
          'date',
        );

        if (personHasManagementJob || personHasScheduleOnCurrentDate) {
          preselectedSet.add(schedule.node.person.login.email);
        }

        return;
      }

      if (schedule.node.personId) {
        preselectedSet.add(schedule.node.person.login.email);
      }
    });

    if (preselectedSet.size) {
      setPreselected(Array.from(preselectedSet));
      setSelected(Array.from(preselectedSet));
    }
  }, [prettySchedulesData, emailEntireWeek]);

  useEffect(() => {
    generatePreselected();
  }, [generatePreselected]);

  const handleSelectionChange = useCallback(selectionModel => {
    setSelected(selectionModel);
  }, []);

  const handleEmailClick = useCallback(async () => {
    const pdfData = await generatePdf(emailEntireWeek, false);
    sendEmail({
      variables: {
        input: {
          locationId,
          dateRange: emailEntireWeek
            ? `${timelineVisibleDate.format('MM-DD-YYYY')}-${timelineVisibleDate
                .clone()
                .add(6, 'day')
                .format('MM-DD-YYYY')}`
            : timelineVisibleDate.format('MM-DD-YYYY'),
          employeeEmails: selected,
          attachment: pdfData,
        },
      },
    });
  }, [emailEntireWeek, generatePdf, sendEmail, locationId, timelineVisibleDate, selected]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} alignItems="center" className={classes.typography}>
        <Typography>Current date: {timelineVisibleDate.format('MM/DD/YYYY')}</Typography>
      </Grid>
      <Grid item xs={6} className={classes.select}>
        <TextField
          onChange={(e, v) => setEmailEntireWeek(e.target.value)}
          label="Period to Email"
          value={emailEntireWeek}
          select
        >
          <MenuItem value={false}>Current Date</MenuItem>
          <MenuItem value={true}>Whole Week</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6} className={classes.select}>
        <TextField
          onChange={(e, v) => setPdfSortOrder(e.target.value)}
          label="PDF Sort Order"
          value={pdfSortOrder}
          select
        >
          <MenuItem value={1}>By Job</MenuItem>
          <MenuItem value={2}>By First Name</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <InputBase
          placeholder="Search Employees by Email"
          inputProps={{ 'aria-label': 'search employees' }}
          onChange={e => setSearch(e.target.value.toLowerCase())}
          value={search}
        />
        <IconButton aria-label="search">
          {search?.length !== 0 ? <CloseIcon onClick={() => setSearch('')} /> : <SearchIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          autoHeight={true}
          columns={columns}
          loading={!rows?.length}
          rows={rows}
          pageSize={10}
          checkboxSelection
          disableColumnFilter
          filterModel={{
            items: [{ columnField: 'name', operatorValue: 'contains', value: search }],
          }}
          selectionModel={selected}
          onSelectionModelChange={e => handleSelectionChange(e.selectionModel)}
        />
      </Grid>
      <Grid item xs={12} className={classes.sendButton}>
        <Button disabled={!preselected?.length || sendEmailLoading} onClick={handleEmailClick}>
          Send Email
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmailPdfModal;
