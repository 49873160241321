import { gql } from '@apollo/client';

const CREATE_MENU = gql`
  mutation createMenu($input: CreateMenuMutationInput!) {
    createMenu(input: $input) {
      menuEdge {
        node {
          menuId
        }
      }
    }
  }
`;

export default CREATE_MENU;
