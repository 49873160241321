import React from 'react';
import EmployeeList from './EmployeeList'
const EmployeesView = () => {
  return (
    <>
      <EmployeeList/>
    </>
  );
};

export default EmployeesView;
