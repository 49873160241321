import { gql } from '@apollo/client';

export const FETCH_MEASURES = gql`
         query measures(
           $first: Int
           $last: Int
           $after: String
           $before: String
           $search: String
           $sort: [MeasureSort]
           $filter: [MeasureFilter]
         ) {
           viewer {
             id
             measureConnection(
               first: $first
               last: $last
               before: $before
               after: $after
               sort: $sort
               filter: $filter
               search: $search
             ) {
               pageInfo {
                 hasNextPage
                 endCursor
                 hasPreviousPage
                 startCursor
               }
               totalCount
               edges {
                 node {
                   id
                   measureId
                   active
                   label
                   abbreviation
                   thresholdValue
                 }
               }
             }
           }
         }
       `;
