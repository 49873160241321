import { gql } from '@apollo/client';

export const TOGGLE_ARCHIVE_COUPON = gql`
  mutation toggleArchiveCoupon($input: ToggleArchiveCouponMutationInput!) {
    toggleArchiveCoupon(input: $input) {
      viewer {
        id
      }
      couponId
    }
  }
`;
