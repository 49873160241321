import React from "react";
import PointsHistoryView from "./View";

export default function PointsHistory({ customerData }) {
  return (
    <>
      <PointsHistoryView customerData={customerData} />
    </>
  );
}
