import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import {
  ConnectionTable,
  Elements,
  Permission,
  PermissionField,
  SelectFactory,
} from '../../../../blocks';
import { COLOR, MUTATION_NAME } from '../../../../../constants';
import { generateStoreAbbreviation, phoneNumberFormat } from '../../../../../utils';
import { locationListViewStyles } from './styles';
import { FETCH_LOG_ACTIONS, FETCH_STATES } from '../../../../../apollo/queries';
import { useQuery } from '@apollo/client';
import { Autocomplete } from '@material-ui/lab';
import { groupBy } from 'lodash';

const LocationListView = ({
  query,
  brands,
  bulkEditLocations,
  handleLocationActivation,
  createNewLocation,
  history,
  mutationPermissions,
  setPermissions,
  permissions,
  accessToMutations,
  stateList,
  menuData,
}) => {
  const bulkEditPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'bulkEdit',
  );
  const createLocationPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'createLocation',
  );
  const toggleLocationPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'toggleLocation',
  );

  const classes = locationListViewStyles();

  return (
    <ConnectionTable
      query={query}
      minSearchValueLength={1}
      title="Locations"
      initialQueryVariables={{
        first: 15,
        fieldGroup: 13,
        specificFields: ['label', 'store_number'],
      }}
      selectableRows={true}
      onTableRowClick={row =>
        history.push(`${history.location.pathname}/${row.locationId}/generalInfo`)
      }
      structureTable={data => {
        return data?.viewer?.locationConnection?.edges.map(location => ({
          id: generateStoreAbbreviation(location.node).id,
          storeAbbreviation: generateStoreAbbreviation(location.node),
          label: location.node.label,
          'phone.phone': location.node.phone.phone,
          email: location.node.email,
          opened: location.node.opened ? moment(location.node.opened).format('MM/DD/YYYY') : '',
          closed: location.node.closed ? moment(location.node.closed).format('MM/DD/YYYY') : '',
          'timezone.label': location.node.timezone.label,
          'address.city': location.node.address.city,
          'address.state.label': location.node.address.state.label,
          'menu.label': location.node.menu?.label,
          online: location.node.online ? 'ONLINE' : 'OFFLINE',
          active: location.node.active,
          locationId: location.node.locationId,
        }));
      }}
      columns={[
        {
          title: 'ID',
          field: 'id',
          customSortField: 'storeNumber',
          render: (id, _, row) => {
            return row.storeAbbreviation.abbreviation;
          },
        },
        { title: 'Name', field: 'label' },
        {
          title: 'Phone Number',
          field: 'phone.phone',
          cellStyle: {
            color: COLOR.INFO,
          },
          render: phone => {
            return phoneNumberFormat(phone);
          },
        },
        {
          title: 'Email',
          field: 'email',
          cellStyle: {
            color: COLOR.INFO,
          },
        },
        { title: 'Open Date', field: 'opened' },
        { title: 'Close Date', field: 'closed' },
        { title: 'Timezone', field: 'timezone.label' },
        { title: 'City', field: 'address.city' },
        { title: 'State', field: 'address.state.label', filter: true },
        { title: 'Menu', field: 'menu.label', filter: true },
        {
          title: 'Status',
          field: 'online',
          customSortField: 'online.order',
          cellStyle: value => ({
            color: value === 'ONLINE' ? COLOR.SUCCESS : COLOR.ERROR,
            fontWeight: 'bold',
          }),
        },
        {
          title: 'Active',
          field: 'active',
          filter: true,
          render: (active, _, row) => {
            return (
              <Permission
                access={Math.min(
                  toggleLocationPermission?.access,
                  accessToMutations?.[MUTATION_NAME.ToggleLocationMutationInput],
                )}
              >
                <div>
                  <PermissionField createHelperText>
                    <PermissionField
                      Component={Elements.Switch}
                      checked={active}
                      handleSwitchChange={() => handleLocationActivation(active, row)}
                    />
                  </PermissionField>
                </div>
              </Permission>
            );
          },
        },
      ]}
      /* eslint-disable-next-line no-unused-vars */
      customActionsTop={(data, query) => {
        if (!permissions) {
          setPermissions({
            ...(data?.viewer?.locationConnection?.permissions || {}),
            menuId: data?.viewer?.locationConnection?.permissions?.menu?.__typename,
            address: data?.viewer?.locationConnection?.permissions?.address?.address,
            city: data?.viewer?.locationConnection?.permissions?.address?.city,
            stateLabel: data?.viewer?.locationConnection?.permissions?.address?.state?.label,
            zip: data?.viewer?.locationConnection?.permissions?.address?.zip,
            phone: data?.viewer?.locationConnection?.permissions?.phone?.phone,
            timezoneId: data?.viewer?.locationConnection?.permissions?.timezone?.__typename,
            printerPassword: data?.viewer?.locationConnection?.permissions?.printerPassword,
          });
        }

        return (
          <Permission
            access={Math.min(
              createLocationPermission?.access,
              accessToMutations?.[MUTATION_NAME.CreateLocationMutationInput],
            )}
          >
            <div>
              <PermissionField createHelperText>
                <Button onClick={createNewLocation} variant="contained">
                  New Location
                </Button>
              </PermissionField>
            </div>
          </Permission>
        );
      }}
      customActionsCenter={(data, { refetch, variables }, selected) => {
        let cities = [];

        if (variables?.filter?.address?.state?.id?.eq) {
          const uniqueCities = data?.viewer?.locationConnection?.edges?.slice((cityA, cityB) =>
            cityA.node.address.city > cityB.node.address.city ? 1 : -1,
          );
          const groupedResult = groupBy(uniqueCities, 'node.address.city');
          cities = Object.entries(groupedResult)
            .map(value => ({
              label: value[0],
              value: value[0],
            }))
            .sort((cityA, cityB) => cityA.label.localeCompare(cityB.label));
        }

        return (
          <>
            <Permission access={bulkEditPermission?.access}>
              <div style={{ marginLeft: '.5rem' }}>
                <div>
                  <PermissionField createHelperText>
                    <Button
                      onClick={() => bulkEditLocations(selected)}
                      variant="outlined"
                      disabled={selected.length < 1}
                    >
                      Bulk Editing
                    </Button>
                  </PermissionField>
                </div>
              </div>
            </Permission>
            <div className={classes.selectFactoryWrap}>
              <Grid xs={3}>
                <Autocomplete
                  id="state"
                  getOptionLabel={option => option.label}
                  defaultValue={{ label: 'None', value: 0 }}
                  renderInput={params => <TextField {...params} label="State" />}
                  onChange={(event, selected) => {
                    document.querySelector('#city__grid .MuiAutocomplete-clearIndicator')?.click();

                    if (selected) {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          address: {
                            state: {
                              id: {
                                eq: selected.value,
                              },
                            },
                          },
                        },
                      });
                    } else if (variables?.filter?.address?.state?.id?.eq) {
                      const filters = variables.filter;
                      delete filters.address;

                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    }
                  }}
                  options={stateList || []}
                  fullWidth
                />
              </Grid>
              <Grid xs={3} id="city__grid">
                <Autocomplete
                  id="city"
                  getOptionLabel={option => option.label}
                  defaultValue={{ label: 'None', value: 0 }}
                  renderInput={params => <TextField {...params} label="City" />}
                  onChange={(event, selected) => {
                    if ((!selected || selected.value === 'None') && variables?.filter?.address) {
                      const filters = variables.filter;
                      delete filters.address.city;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else if (selected?.value) {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          address: {
                            ...variables.filter.address,
                            city: {
                              eq: selected.value,
                            },
                          },
                        },
                      });
                    }
                  }}
                  options={cities || []}
                  disabled={!cities.length}
                  fullWidth
                />
              </Grid>
              <Grid xs={3}>
                {/* TODO: add permission for brand */}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Brand</InputLabel>
                  <Select
                    fullWidth
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.brand;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            brand: {
                              id: {
                                eq: value,
                              },
                            },
                          },
                        });
                      }
                    }}
                    label="Brands"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {brands?.viewer?.brandConnection?.edges?.map(brand => (
                      <MenuItem key={brand.node.brandId} value={brand.node.id}>
                        {brand.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Menu</InputLabel>
                  <Select
                    fullWidth
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.menu;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            menu: {
                              id: {
                                eq: value,
                              },
                            },
                          },
                        });
                      }
                    }}
                    label="Menus"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {menuData?.viewer?.menuConnection?.edges?.map(menu => (
                      <MenuItem key={menu.node.id} value={menu.node.id}>
                        {menu.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </div>
          </>
        );
      }}
    />
  );
};

LocationListView.propTypes = {
  query: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  createNewLocation: PropTypes.func.isRequired,
  handleLocationActivation: PropTypes.func.isRequired,
  bulkEditLocations: PropTypes.func.isRequired,
};

export default LocationListView;
