import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = ({
  maxWidth = 'lg',
  children,
  open,
  handleClose,
  title,
  fullScreen,
  xs = 12,
  headerContent = () => {},
}) => {
  return (
    <Dialog
      disableEnforceFocus
      fullWidth
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperComponent={Paper}
      headerContent={headerContent}
      disableBackdropClick
    >
      <Grid container>
        <Grid container item xs={xs} justify="space-between">
          <DialogTitle disableTypography id={`title-${title}`}>
            {title}
          </DialogTitle>
          <DialogActions>
            {headerContent()}
            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container>
          {children}
          {/* - example for passing props to childrens
              - mainly used for passing handler for cancel button
          */}
          {/* <DialogContent>{React.cloneElement(children, { cancel: handleClose })}</DialogContent> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  handleClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default Modal;
