import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import PropTypes from "prop-types";

import ContentInfoStyles from "./styles";
import Permission from "../Permission";

const ContentInfo = ({ contentInfo, id }) => {
  const classes = ContentInfoStyles();

  const renderInfoRow = (infoRow) =>
    infoRow.map(({ permission = 7, ...row }, index) => (
      <Permission access={permission} key={row.id}>
        <Grid
          item
          container
          xs={4}
          direction="column"
          className={
            infoRow.length - 1 === index ? classes.marginBottom : undefined
          }
        >
          <Typography variant="subtitle2" component="p" gutterBottom>
            {row.label}
          </Typography>
          <Typography variant="body2" component="p">
            {row.linkValue ? (
              <Link href={row.linkValue}>{row.value ? row.value : "/"}</Link>
            ) : row.value ? (
              row.value
            ) : (
              "-"
            )}
          </Typography>
        </Grid>
      </Permission>
    ));

  return (
    <Grid container>
      {contentInfo.map((locationInfoRow, index) => (
        <Grid container item xs={12} key={`${id}-${index}`}>
          {renderInfoRow(locationInfoRow)}
        </Grid>
      ))}
    </Grid>
  );
};

ContentInfo.propTypes = {
  contentInfo: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

export default ContentInfo;
