import React, { useCallback } from 'react';
import { Button, Grid } from '@material-ui/core';
import generateReportPdf from '../../../../../../utils/pdfUtils/generateReportPdf';
import moment from 'moment';
import { generateXLSX } from './generateXlsx';

const Togglers = ({ filters, setFilters, variables, refetch, tableData, locationLabel }) => {
  const handleDisplayCategoriesToggle = useCallback((e, value) => {
    if (value !== null) {
      setFilters(prevFilters => ({
        ...prevFilters,
        displaySubategories: value,
      }));
    }
  }, []);

  const handleOnExportPdfClick = useCallback(() => {
    let header;
    if (!filters.displaySubategories) {
      header = [
        { text: 'Category', field: 'label', isBold: true },
        { text: 'Total Cost', field: 'totalQtyCost' },
      ];
    } else {
      header = [
        { text: 'Category', field: 'groupByLabel', isBold: true },
        { text: 'Inventory Item', field: 'label', isBold: true },
        { text: 'Storage Area', field: 'storages' },
        { text: 'Main Uom', field: 'invUom' },
        { text: 'Total Qty', field: 'totalQty' },
        { text: 'Total Cost', field: 'totalQtyCost' },
      ];
    }
    generateReportPdf({
      title: `${locationLabel} - Inventory Count (${(filters.dateRange.gte &&
        moment(filters.dateRange.gte)?.format('MM/DD/YYYY')) ||
        ''} - ${(filters.dateRange.lte && moment(filters.dateRange.lte)?.format('MM/DD/YYYY')) ||
        ''})`,
      tableData,
      isBoldFieldName: 'isGroupLabel',
      header,
    });
  }, [tableData, locationLabel, filters.dateRange, filters.displaySubategories]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={2}></Grid>
      <Grid item xs={6} sm={10} justify="flex-end">
        <Button style={{ marginRight: '1rem' }} onClick={handleOnExportPdfClick}>
          Export PDF
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            generateXLSX(tableData, filters, locationLabel);
          }}
        >
          Export XLSX
        </Button>
      </Grid>
    </Grid>
  );
};

export default Togglers;
