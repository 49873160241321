import { gql } from '@apollo/client';

export const FETCH_LAST_INVENTORY_COUNT = gql`
         query FETCH_LAST_INVENTORY_COUNT($locationId: Int) {
           viewer {
             id
             inventorycountConnection(
               last: 1
               filter: { locationId: { eq: $locationId }, dateCounted: { null: false } }
             ) {
               edges {
                 node {
                   id
                   dateCounted
                   inventorycountLocationStorageInventoryitemConnection {
                     edges {
                       node {
                         quantity
                         calculatedCost
                         measure {
                           label
                         }
                         locationStorageInventoryitem {
                           inventoryitem {
                             id
                             label
                           }
                           locationStorage {
                             locationStorageId
                             customLabel
                           }
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       `;
