import React from "react";
import PrinterstationSetting from "../PrinterstationSettings";
function PrinterStationSettingsView({ devicesAndPrinters, permissions }) {
  return (
    <div>
      <h2 id="printerStationSettings">Printer Station View</h2>
      <div>
        {devicesAndPrinters.map((device) => (
          <>
            <div>
              <h3>POS ID - {device.deviceId}</h3>
            </div>
            <div>
              {device.devicePrinters.map((printer) => (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5rem" }}
                >
                  <div>
                    <p>{printer.printerAddress}</p>
                  </div>
                  <div>
                    <PrinterstationSetting
                      currentSetting={printer.printerstationId}
                      printerstationId={printer.id}
                      permissions={permissions}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default PrinterStationSettingsView;
