import { gql } from "@apollo/client";

const FETCH_HIERARCHY_USERS = gql`
  query fetchHierarchy($id: ID!) {
    viewer {
      id
      hierarchy(id: $id) {
        id
        loginConnection {
          edges {
            node {
              id
              loginId
              person {
                firstName
                lastName
                personId
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_HIERARCHY_USERS;
