import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Modal } from '../';
import {
  ADD_INVENTORYITEM_TO_WASTE_LOG,
  REMOVE_INVENTORYITEM_FROM_WASTE_LOG,
} from '../../../apollo/mutations';
import { INVENTORYITEM_CUSTOM_CATEGORY } from '../../../apollo/queries/inventory';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../constants';
import SelectMUI from '../Select';

const WasteLogConfirmationModal = ({
  isWasteLogModalOpen,
  handleWasteLogModalClose,
  inventoryitem,
  updateInventoryitem,
  yieldQuantity,
}) => {
  const { setSnackbar } = useSnackbar();
  const { register, errors, control, handleSubmit } = useForm();
  const { data, loading: categoryLoading, error } = useQuery(INVENTORYITEM_CUSTOM_CATEGORY);
  const [addInventoryitemToWasteLog, { loading }] = useMutation(ADD_INVENTORYITEM_TO_WASTE_LOG, {
    onCompleted: data => {
      updateInventoryitem(prevData => {
        return {
          ...prevData,
          inventoryitem: {
            ...prevData.inventoryitem,
            partOfWastelog: data.addInventoryitemToWasteLog.inventoryitem.partOfWastelog,
            inventoryitemCustomCategory: {
              ...data.addInventoryitemToWasteLog.inventoryitem.inventoryitemCustomCategory,
            },
          },
        };
      });
    },
  });

  const [removeInventoryitemFromWasteLog, { loading: loadingRemove }] = useMutation(
    REMOVE_INVENTORYITEM_FROM_WASTE_LOG,
    {
      onCompleted: data => {
        updateInventoryitem(prevData => {
          return {
            ...prevData,
            inventoryitem: {
              ...prevData.inventoryitem,
              partOfWastelog: data.removeInventoryitemFromWasteLog.inventoryitem.partOfWastelog,
              inventoryitemCustomCategory: {
                ...data.removeInventoryitemFromWasteLog.inventoryitem.inventoryitemCustomCategory,
              },
            },
          };
        });
      },
    },
  );

  const onSubmit = formData => {
    addInventoryitemToWasteLog({
      variables: {
        input: {
          inventoryitemId: inventoryitem?.inventoryitemId,
          inventoryitemCustomCategoryId: formData.inventoryitemCustomCategory,
          wasteLogMeasureOverrideId:
            formData.wasteLogMeasureOverrideId === 'default'
              ? null
              : formData.wasteLogMeasureOverrideId,
        },
      },
    })
      .then(() => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: inventoryitem.partOfWastelog
            ? 'Waste Log info edited'
            : `${inventoryitem?.label} added to Waste Log`,
          open: true,
        });
        handleWasteLogModalClose();
      })
      .catch(err => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: `${err}`,
          open: true,
        });
      });
  };

  const removeInventoryitem = () => {
    removeInventoryitemFromWasteLog({
      variables: {
        input: {
          inventoryitemId: inventoryitem?.inventoryitemId,
        },
      },
    })
      .then(() => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: `${inventoryitem?.label} removed from Waste Log`,
          open: true,
        });
        handleWasteLogModalClose();
      })
      .catch(err => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: `${err}`,
          open: true,
        });
      });
  };

  const categories = useMemo(
    () =>
      data?.viewer?.inventoryitemCustomCategoryConnection.edges?.map(
        inventoryitemCustomCategory => ({
          value: inventoryitemCustomCategory.node.inventoryitemCustomCategoryId,
          label: inventoryitemCustomCategory.node.label,
        }),
      ),
    [data],
  );

  const uoms = useMemo(() => {
    if (inventoryitem) {
      const uomList = [
        {
          value: 'default',
          label: `Default (${inventoryitem?.measure.abbreviation})`,
        },
        ...inventoryitem?.inventoryitemMeasureConnection.edges.map(inventoryitemMeasure => ({
          value: inventoryitemMeasure.node.measure.measureId,
          label: `${inventoryitemMeasure.node.measure.abbreviation}`,
        })),
      ];

      return uomList;
    }
  }, [inventoryitem]);

  return (
    <Modal
      handleClose={handleWasteLogModalClose}
      open={isWasteLogModalOpen}
      title={
        inventoryitem?.partOfWastelog
          ? `Edit Waste Log info for ${inventoryitem?.label}`
          : `Add ${inventoryitem?.label} to Waste Log`
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '12px', width: '100%' }}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12} sm={6}>
                <SelectMUI
                  items={categories}
                  id={'inventoryitemCustomCategory'}
                  label={'Select category'}
                  defaultValue={
                    inventoryitem?.inventoryitemCustomCategory?.inventoryitemCustomCategoryId ||
                    categories?.[0]?.value
                  }
                  variant="outlined"
                  control={control}
                  width={'100%'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectMUI
                  items={uoms}
                  id={'wasteLogMeasureOverrideId'}
                  label={'Select UOM'}
                  defaultValue={inventoryitem?.wasteLogMeasureOverrideId || uoms?.[0]?.value}
                  variant="outlined"
                  control={control}
                  width={'100%'}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleWasteLogModalClose}
              style={{ marginRight: '8px' }}
              variant="outlined"
            >
              Cancel
            </Button>
            {inventoryitem?.partOfWastelog && (
              <Button
                disabled={loadingRemove}
                style={{ marginRight: '8px' }}
                onClick={removeInventoryitem}
              >
                Remove
              </Button>
            )}
            <Button type="submit" disabled={loading || loadingRemove}>
              {inventoryitem?.partOfWastelog ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default WasteLogConfirmationModal;
