import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { generateStoreAbbreviation, uglifyId } from '../../../../../../../../utils';
import { ConnectionTable, Elements } from '../../../../../../../blocks';
import { useMutation, useQuery } from '@apollo/client';
import FETCH_LOCATIONS from '../../../../../../../../apollo/queries/fetchLocations';
import { Controller } from 'react-hook-form';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import LOCATIONS_FOR_SUBCATEGORY_AVAILABILITY from '../../../../../../../../apollo/queries/menus/fetchLocationsForSubcategoryTable';
import LocationSubcategoryView from '.';
import { Button } from '@material-ui/core';
import { TOGGLE_AVAILABLE_LOCATION_SUBCATEGORY } from '../../../../../../../../apollo/mutations';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function LocationSubcategoryTable({
  control,
  subcategoryId,
  handleOnSpecificLocationDaysChange,
  handleOnSpecificTimeChange,
  setLocationSubcategories,
  locationSubcategories,
  handleTimeChange,
  locationSubcategoriesAdvanced,
  toggleEnableAdvancedLocationDays,
  onUpdateLocationSubcategory,
  createUpdateLoading,
  subcategory,
  toggledLocationSubcategory,
  setToggledLocationSubcategory,
}) {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();

  const { data, loading, error } = useQuery(FETCH_LOCATIONS);
  const [toggleLocationSubcategory] = useMutation(TOGGLE_AVAILABLE_LOCATION_SUBCATEGORY, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Location availability updated',
      });
    },
  });
  const statuses = [
    {
      value: 'None',
      label: 'None',
    },
    {
      value: true,
      label: 'On',
    },
    {
      value: false,
      label: 'Off',
    },
  ];

  if (loading) return <div>Loading...</div>;
  return (
    <div className={classes.root}>
      <ConnectionTable
        query={LOCATIONS_FOR_SUBCATEGORY_AVAILABILITY}
        title="Locations"
        fetchPolicy={'cache-first'}
        customActionsTop={(data, { variables }) => (
          <div style={{ marginLeft: 'auto' }}>
            <Button
              onClick={() => onUpdateLocationSubcategory(subcategory)}
              variant="outlined"
              disabled={createUpdateLoading}
            >
              Update Locations Time Settings
            </Button>
          </div>
        )}
        customActionsCenter={(data, { variables }) => (
          <Controller
            name="activeStatus"
            control={control}
            render={({ onChange, ref }) => (
              <div style={{ marginLeft: 'auto' }}>
                <div>
                  <p style={{ fontSize: '1rem' }}>Available for all locations:</p>
                  <Elements.ToggleButton
                    ref={ref}
                    options={statuses}
                    value={'None'}
                    handleToggleClick={data => {
                      toggleLocationSubcategory({
                        variables: {
                          input: {
                            subcategoryId,
                            toggle: data,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </div>
            )}
          />
        )}
        initialQueryVariables={{
          first: 10,
          filter: {
            menuId: { eq: subcategory.menuId },
          },
          subcategoryFilter: [
            {
              subcategoryId: {
                eq: parseInt(subcategoryId),
              },
            },
          ],
          specificFields: ['label', 'store_number'],
        }}
        collapsableRows={(data, row, query) => {
          return (
            <LocationSubcategoryView
              control={control}
              row={row}
              handleOnSpecificTimeChange={handleOnSpecificTimeChange}
              handleTimeChange={handleTimeChange}
              setLocationSubcategories={setLocationSubcategories}
              locationSubcategories={locationSubcategories}
              handleOnSpecificLocationDaysChange={handleOnSpecificLocationDaysChange}
              locationSubcategoriesAdvanced={locationSubcategoriesAdvanced}
              toggleEnableAdvancedLocationDays={toggleEnableAdvancedLocationDays}
              subcategory={subcategory}
            />
          );
        }}
        structureTable={data => {
          return data?.viewer?.locationConnection?.edges.map(location => ({
            id: generateStoreAbbreviation(location?.node),
            label: location?.node?.label,
            'address.city': location.node.address.city,
            'address.state.label': location.node.address.state.label,
            available:
              location?.node?.locationSubcategoryConnection?.edges[0]?.node?.available === null ||
              location?.node?.locationSubcategoryConnection?.edges[0]?.node?.available === true ||
              !location?.node?.locationSubcategoryConnection?.edges[0]
                ? true
                : false,
            startTime: location?.node?.locationSubcategoryConnection?.edges[0]?.node?.startTime,
            locationId: location.node.locationId,
            locationSubcategoryWeekday:
              location?.node?.locationSubcategoryConnection?.edges[0]?.node,
            locationId: location?.node?.locationId,
          }));
        }}
        columns={[
          {
            title: 'ID',
            field: 'id',
            customSortField: 'storeNumber',
            render: id => {
              return id.abbreviation;
            },
          },
          { title: 'Name', field: 'label' },
          { title: 'City', field: 'address.city' },
          { title: 'State', field: 'address.state.label', filter: true },
          {
            title: 'Available',
            field: 'available',
            disableSort: true,
            filter: true,
            render: (data, variables, row) => {
              return (
                <Elements.Switch
                  checked={data !== undefined ? data : true}
                  handleSwitchChange={toggle => {
                    setToggledLocationSubcategory({
                      ...toggledLocationSubcategory,
                      [row.locationId]: toggle,
                    });
                    toggleLocationSubcategory({
                      variables: {
                        input: {
                          subcategoryId,
                          locationId: row.locationId,
                          toggle,
                        },
                      },
                    });
                  }}
                />
              );
            },
          },
        ]}
        /* eslint-disable-next-line no-unused-vars */
      />
    </div>
  );
}
LocationSubcategoryTable.propTypes = {
  rows: PropTypes.array.isRequired,
};
