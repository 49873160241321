import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { TextField, MenuItem, Paper, makeStyles } from '@material-ui/core';
import { PLACES_AUTOCOMPLETE_INPUT_DEBOUNCE } from '../../../constants/index';

import { getAddressPartsFromGeocode } from '../../../utils';
import { debounce } from 'lodash';

const Styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  dialog: {
    zIndex: 9999,
    position: 'absolute',
    width: '100%',
    top: '120%',
    backgroundColor: theme.colors.boxBackground,
    boxShadow: theme.boxShadowBase,
  },
  mainText: {
    marginRight: theme.spacing(2),
  },
});

const PlacesAutocompleteStyles = makeStyles(Styles);

const PlacesAutocomplete = ({
  value: propsValue = '',
  handleGeocodeAutocomplete,
  name,
  error,
  disabled,
  setCoordinates,
  id,
  onSelectCustom,
  ...rest
}) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us', 'ca'] },
      types: ['address'],
    },
  });

  const classes = PlacesAutocompleteStyles();

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue, setValue]);

  const debouncedSearch = useMemo(
    () =>
      debounce(inputValue => {
        if (inputValue.length >= 3) {
          setValue(inputValue);
        }
      }, PLACES_AUTOCOMPLETE_INPUT_DEBOUNCE),
    [setValue],
  );

  const handleInput = useCallback(
    e => {
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    if (onSelectCustom && typeof onSelectCustom === 'function') {
      onSelectCustom();
    }

    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(results => {
        const address = getAddressPartsFromGeocode(results[0]);

        handleGeocodeAutocomplete(address);

        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        if (setCoordinates) {
          setCoordinates({ latitude: lat, longitude: lng });
        }
      })
      .catch(error => {
        console.log('usePLacesAutocomplete Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <MenuItem key={place_id} onClick={handleSelect(suggestion)}>
          <strong className={classes.mainText}>{main_text} </strong>{' '}
          <small className={classes.secondaryText}>{secondary_text}</small>
        </MenuItem>
      );
    });

  return (
    <div className={classes.container}>
      <TextField
        {...rest}
        id={id}
        fullWidth
        onChange={handleInput}
        name={name}
        error={error}
        label="Full Address"
        disabled={!ready || disabled}
        placeholder="Start typing address"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <Paper className={classes.dialog}>{renderSuggestions()}</Paper>}
    </div>
  );
};

PlacesAutocomplete.propTypes = {
  handleGeocodeAutocomplete: PropTypes.func,
};

export default PlacesAutocomplete;
