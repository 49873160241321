import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from 'react-hook-form';
import {
  Container,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import ImageUpload from '../../../../../../../blocks/ImageUpload';
import GeneralInfoInput from '../../GeneralInfoInput';
import { CREATE_MENU } from '../../../../../../../../apollo/mutations';
import { FETCH_MENU_LIST, FETCH_BRANDS } from '../../../../../../../../apollo/queries';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';

const IMAGE = {
  sizes: ['375x268'],
};

const CreateMenu = ({
  basePath,
  classes,
  refetchMenuList,
}) => {
  const { setSnackbar } = useSnackbar();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    setImages(
      IMAGE.sizes.map((size) => {
        return {
          size,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find((image) => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const {
    data: brandList,
    error: brandListError,
    loading: brandListLoading
  } = useQuery(FETCH_BRANDS);

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      label: '',
      description: '',
    },
  });

  const [createMenu] = useMutation(CREATE_MENU);

  const handleClose = () => {
    setImages([]);
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    setButtonDisabled(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Creating menu generation...',
    });

    const imagesToUpload = images.map((image) => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    const response = await createMenu({
      variables: {
        input: {
          label: data.label,
          description: data.description,
          images: imagesToUpload,
        },
      },
      refetchQueries: [
        {
          query: FETCH_MENU_LIST,
          variables: {
            filter: {
              showHiddenToLocation: { eq: true },
              showUnavailableOnline: { eq: true },
            },
          },
        },
      ],
    });

    await refetchMenuList();

    setSnackbar({
      open: true,
      type: 'success',
      text: 'Menu generation created!',
    });

    setRedirectUrl(
      `${basePath}/menus/${response.data.createMenu.menuEdge.node.menuId}`
    );
    setButtonDisabled(false);
  };

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Button disabled={buttonDisabled} type="submit">
              Create Menu Generation
            </Button>
          </Grid>
        </Grid>
        <GeneralInfoInput
          isMenu
          brandList={brandList?.viewer?.brandConnection?.edges}
          control={control}
          imageUrl={previewImage}
          setOpen={setOpen}
          register={register}
          errors={errors}
        />
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Menu Generation Image
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ImageUpload
                  images={images}
                  setImages={setImages}
                  size={IMAGE.sizes[0]}
                  maxSize={50*1000}
                  helperText="Required image size is 375 x 268 px in JPG format. File weight in max. 50KB."
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

CreateMenu.propTypes = {
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CreateMenu;
