import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const options = [
  {
    label: "OLO",
    value: 1,
  },
  {
    label: "Mobile APP",
    value: 2,
  },
  {
    label: "POS",
    value: 3,
  },
];

function Platforms({ setFilter, refetch: { refetch, variables } }) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      onChange={(e, values) => {
        if (values.length) {
          refetch({
            ...variables,
            filter: {
              ...variables.filter,
              platforms: values.map((plat) => plat.value),
            },
          });
        } else {
          refetch({
            ...variables,
            filter: {
              ...variables.filter,
              platforms: undefined,
            },
          });
        }
      }}
      getOptionLabel={(option) => option.label}
      renderTags={(value, getTagProps) => value.map((option, index) => "")}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </React.Fragment>
      )}
      style={{ width: 200 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Applied Locations"
          placeholder="Applied Locations"
        />
      )}
    />
  );
}

export default Platforms;
