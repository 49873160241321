import { Button, Grid, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FETCH_RECIPE_CATEGORY_TYPES } from '../../../../../../apollo/queries';
import { REMOVE_INVENTORYITEMCATEGORYTYPE } from '../../../../../../apollo/mutations/inventory/removeInventoryitemcategorytype';
import { ConnectionTable, Modal } from '../../../../../blocks';
import CategoryModal from './CategoryModal';
import { renderStatus, statusOptions } from '../../../../../../constants/statuses';
import { isEmpty } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../constants';

const isCostOptions = [
  { value: 1, label: 'Cost' },
  { value: 0, label: 'Sale' },
];

const Categories = () => {
  const { setSnackbar } = useSnackbar();
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [category, setCategory] = useState();

  const [remove, { loading }] = useMutation(REMOVE_INVENTORYITEMCATEGORYTYPE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Category removed',
        open: true,
      });
    },
    onError: data => {
      setSnackbar({
        type: SNACKBAR_STATUS.WARNING,
        text: data.graphQLErrors[0].message,
        open: true,
      });
    },
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(data?.removeInventoryitemcategorytype?.viewer),
        fields: {
          inventoryitemcategorytypeConnection() {
            return {
              __typename: 'inventoryitemcategorytypeEdge',
            };
          },
        },
      });
    },
  });

  const handleOnRowClick = useCallback(({ data }) => {
    setCategory(data);
    setIsCategoryModalOpen(true);
  }, []);

  const handleOnDeleteClick = useCallback(id => {
    remove({
      variables: {
        inventoryitemcategorytypeId: id,
      },
    });
  }, []);

  const handleCloseCategoryModal = useCallback(() => {
    setCategory();
    setIsCategoryModalOpen(false);
  }, []);

  const handleOnAddCategoryClick = useCallback(() => {
    setIsCategoryModalOpen(true);
  }, []);

  const handleOnTypeChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.isCost) {
        delete filters.isCost;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          isCost: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.active) {
        delete filters.active;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          active: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        query={FETCH_RECIPE_CATEGORY_TYPES}
        title="Categories"
        customActionsTop={() => (
          <Button onClick={handleOnAddCategoryClick} fullWidth>
            Add New Category
          </Button>
        )}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Type</InputLabel>
                <Select
                  label="Type"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnTypeChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All' }, ...isCostOptions]?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        columns={[
          {
            field: 'inventoryitemcategorytypeId',
            title: 'ID',
          },
          {
            field: 'label',
            title: 'Name',
          },
          {
            field: 'isCost',
            title: 'Type',
            render: isCost => (isCost ? 'Cost' : 'Sale'),
            enablePropagation: true,
          },
          {
            field: 'active',
            title: 'Status',
            render: renderStatus,
            enablePropagation: true,
            disableSort: true,
          },
          { field: 'updated', customSortField: 'created', title: 'Updated' },
        ]}
        structureTable={data =>
          data?.viewer?.inventoryitemcategorytypeConnection?.edges?.map(category => ({
            inventoryitemcategorytypeId: category.node.inventoryitemcategorytypeId,
            label: category.node.label,
            isCost: category.node.isCost,
            active: category.node.active,
            updated: moment(category.node.created).format('MM/DD/YYYY'),
            data: category.node,
          })) || []
        }
        onTableRowClick={handleOnRowClick}
        handleDeleteRow={row => {
          handleOnDeleteClick(row.inventoryitemcategorytypeId);
        }}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this category?"
      />
      <Modal
        open={isCategoryModalOpen}
        handleClose={handleCloseCategoryModal}
        title={category?.inventoryitemcategorytypeId ? 'Edit Category' : 'Add Category'}
      >
        <CategoryModal
          category={category}
          handleClose={handleCloseCategoryModal}
          isCostOptions={isCostOptions}
        />
      </Modal>
    </>
  );
};

export default Categories;
