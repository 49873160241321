import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, TextField, Button } from '@material-ui/core';

function CreateUpdateAllergenModalView({
  handleClose,
  allergen,
  handleSubmit,
  register,
  buttonDisabled,
  submit,
}) {
  return (
    <form onSubmit={handleSubmit(submit)}>
      {allergen && (
        <div style={{ display: 'flex', gap: '2rem' }}>
          <TextField fullWidth inputRef={register} name="label" type="text" label="Allergen Name" />
          <TextField
            fullWidth
            inputRef={register}
            name="description"
            type="text"
            label="Description"
          />
        </div>
      )}
      {!allergen && (
        <div style={{ display: 'flex', gap: '2rem' }}>
          <TextField inputRef={register} fullWidth type="text" name="label" label="Allergen Name" />
          <TextField
            inputRef={register}
            fullWidth
            type="text"
            name="description"
            label="Description"
          />
        </div>
      )}
      <DialogActions>
        <Button disabled={buttonDisabled} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={buttonDisabled} type="submit" color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

CreateUpdateAllergenModalView.propTypes = {
  allergen: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  register: PropTypes.func,
  submit: PropTypes.func,
};

export default CreateUpdateAllergenModalView;
