import React from "react";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Controller } from "react-hook-form";
import CurrencyInput from "../../../../../../../../blocks/CurrencyInput";
import { CurrencyInputFormat, convert } from "../../../../../../../../../utils";
function SubtotalSpecifiAmount({ control, couponTriggers, errors }) {
  return (
    <>
      <CurrencyInput
        rules={{
          required: "Minimal amount is requried",
          validate: {
            positive: (value) => {
              const price = CurrencyInputFormat(value);
              const isPositive = price >= 0;
              return isPositive;
            },
          },
        }}
        control={control}
        error={
          errors?.triggerEvents?.automatic &&
          errors?.triggerEvents?.automatic?.subtotalSpecificAmount?.minAmount
        }
        helperText={
          errors?.triggerEvents?.automatic &&
          errors?.triggerEvents?.automatic?.subtotalSpecificAmount?.minAmount
            ? errors?.triggerEvents?.automatic?.subtotalSpecificAmount.minAmount
                .message
            : ""
        }
        name="triggerEvents.automatic.subtotalSpecificAmount.minAmount"
        label="Minimum Amount"
        defaultValue={`${convert.centsToDollars(
          couponTriggers.values?.min_price || 100
        )}`}
      />
      <CurrencyInput
        rules={{
          validate: {
            positive: (value) => {
              if(value.trim().length === 0){
                return true;
              }
              const price = CurrencyInputFormat(value);
              const isPositive = price >= 0;
              return isPositive;
            },
          },
        }}
        control={control}
        error={
          errors?.triggerEvents?.automatic &&
          errors?.triggerEvents?.automatic?.subtotalSpecificAmount?.maxAmount
        }
        helperText={
          errors?.triggerEvents?.automatic &&
          errors?.triggerEvents?.automatic?.subtotalSpecificAmount?.maxAmount
            ? errors?.triggerEvents?.automatic?.subtotalSpecificAmount.maxAmount
                .message
            : ""
        }
        name="triggerEvents.automatic.subtotalSpecificAmount.maxAmount"
        label="Maximum Amount"
        defaultValue={couponTriggers?.values?.max_price ? `${convert.centsToDollars(
          couponTriggers.values?.max_price
        )}` : ""}
        placeholder = {errors?.triggerEvents?.automatic?.subtotalSpecificAmount?.maxAmount ? "" : "NO MAX AMOUNT"}
      />
    </>
  );
}

export default SubtotalSpecifiAmount;
