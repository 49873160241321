import { gql } from '@apollo/client';

const CATEGORIES_AND_MENUS = gql`
  query categoryAndMenu {
    viewer {
      id
      menuCategoryConnection(filter: { category: { categorytypeId: { nin: [0, 1, 9] } } }) {
        edges {
          node {
            menu {
              id
              menuId
              label
              brand {
                id
                brandId
              }
            }
            category {
              id
              categoryId
              label
              categorytypeId
            }
          }
        }
      }
    }
  }
`;

export default CATEGORIES_AND_MENUS;
