import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  InputLabel,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FETCH_LOCATION_COUNT_SHEET } from '../../../../../apollo/queries/locations/fetchCountSheets';
import { ConnectionTable, Modal } from '../../../../blocks';
import CountSheetForm from './CountSheetForm';

const statusOptions = [
  { label: 'Active', value: 1, color: '#37C78A' },
  { label: 'Inactive', value: 0, color: '#FF9900' },
];

const CountSheets = () => {
  const [countSheet, setCountSheet] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { locationId } = useParams();

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setCountSheet();
  }, []);

  const handleOnTableRowClick = useCallback(row => {
    setIsModalOpen(true);
    setCountSheet(row);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {

    if (value === -1) {
      const filters = variables.locationStorageFilter;
      if (filters?.status) {
        delete filters.status;
      }

      refetch({
        ...variables,
        locationStorageFilter: !isEmpty(filters)
          ? filters
          : undefined,
      });
    } else {
      refetch({
        ...variables,
        locationStorageFilter: {
          ...variables.locationStorageFilter,
          status: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        query={FETCH_LOCATION_COUNT_SHEET}
        title="Count Sheets"
        onTableRowClick={handleOnTableRowClick}
        customActionsTop={() => (
          <Button onClick={() => setIsModalOpen(true)}>New Count Sheet</Button>
        )}
        structureTable={data =>
          data?.viewer?.locationStorageConnection?.edges.map((edge, index) => ({
            locationStorageId: edge?.node?.locationStorageId,
            storage: {
              label: `${edge?.node?.storage?.label} - ${edge?.node?.storage?.storageId}`,
              id: edge?.node?.storageId,
            },
            label: edge?.node?.storage?.label,
            customLabel: edge?.node?.customLabel,
            status: edge?.node?.status,
            inventorycount: data?.viewer?.locationConnection?.edges[0]?.node?.inventorycount,
            created: moment(edge?.node?.created).format('MM/DD/YYYY z'),
            inventoryitems: edge.node.locationStorageInventoryitemConnection?.edges?.map(
              ({ node }) => node,
            ),
          }))
        }
        initialQueryVariables={{
          locationId,
          shouldSkip: false,
          locationStorageFilter: {
            locationId: { eq: locationId },
          },
        }}
        columns={[
          {
            enablePropagation: true,
            title: 'ID',
            field: 'locationStorageId',
          },
          {
            enablePropagation: true,
            title: 'Name',
            field: 'customLabel',
          },
          {
            enablePropagation: true,
            title: 'Name',
            field: 'label',
          },
          {
            title: 'Status',
            field: 'status',
            enablePropagation: true,
            render: (status, _, a) => (
              <Typography variant="body1" style={{ color: status ? '#37C78A' : '#FF9900' }}>
                <strong>{status ? 'Active' : 'Inactive'}</strong>
              </Typography>
            ),
          },
          {
            title: 'Last Updated',
            field: 'created',
            enablePropagation: true,
          },
        ]}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={12} justify="flex-end">
            <Grid xs={12} sm={6} md={4} lg={2}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      />
      <Modal
        handleClose={handleCloseModal}
        open={isModalOpen}
        title={countSheet?.locationStorageId ? 'Update Count Sheet' : 'New Count Sheet'}
      >
        <CountSheetForm
          statusOptions={statusOptions}
          handleCloseModal={handleCloseModal}
          countSheet={countSheet}
        />
      </Modal>
    </>
  );
};

export default CountSheets;
