import React from 'react';
import PropTypes from 'prop-types';
import BasicInfoForms from '../BasicInfoForms';
import NutrientForm from '../NutrientForm';
import AllergensForm from '../AllergensForm';
import UnitOfMeasureFields from '../UnitOfMeasure';
import LocationAvailability from '../LocationAvailability';
import VendorProductNumbers from '../VendorProductNumbers';
import UploadFilesComponent from '../UploadFilesComponent';
import { DialogActions, Button, Divider } from '@material-ui/core';
const CreateUpdateIngredientModalView = ({
  handleClose,
  allergenConnection,
  nutrientConnection,
  inStock,
  setInStock,
  measureOptions,
  ingredientConnection,
  locationIngredientId,
  getValues,
  duplicateMeasureIds,
  handleNutrientsChange,
  handleLocationsChange,
  ingredientNutrientConnection,
  ingredientAllergenConnection,
  myLocations,
  register,
  handleSubmit,
  buttonDisabled,
  control,
  errors,
  submit,
  unregister,
  setValue,
  setFiles,
  files,
  setMainMeasureLabel,
  mainMeasureLabel,
}) => {
  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(submit)}>
      <BasicInfoForms
        control={control}
        register={register}
        errors={errors}
        ingredientConnection={ingredientConnection}
      />
      <Divider />
      <h2>Nutrients</h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {nutrientConnection.edges.map((edge, i) => (
          <NutrientForm
            key={i}
            nut={edge.node}
            ingredientNutrientConnection={ingredientNutrientConnection}
            control={control}
            handleNutrientsChange={handleNutrientsChange}
          />
        ))}
      </div>
      <Divider />
      <h2>Allergens</h2>
      <AllergensForm
        register={register}
        allergenConnection={allergenConnection}
        ingredientAllergenConnection={ingredientAllergenConnection}
      />
      <Divider />
      <UnitOfMeasureFields
        errors={errors}
        register={register}
        control={control}
        getValues={getValues}
        unregister={unregister}
        ingredientConnection={ingredientConnection}
        setValue={setValue}
        measureOptions={measureOptions}
        mainMeasureLabel={mainMeasureLabel}
        setMainMeasureLabel={setMainMeasureLabel}
        duplicateMeasureIds={duplicateMeasureIds}
      />
      <Divider />
      <VendorProductNumbers
        errors={errors}
        register={register}
        setValue={setValue}
        getValues={getValues}
        control={control}
        unregister={unregister}
        ingredientConnection={ingredientConnection}
        mainMeasureLabel={mainMeasureLabel}
        measureOptions={measureOptions}
      />
      <Divider />
      <UploadFilesComponent setFiles={setFiles} files={files} />
      <Divider />
      <LocationAvailability
        inStock={inStock}
        setInStock={setInStock}
        register={register}
        handleLocationsChange={handleLocationsChange}
        locationIngredientId={locationIngredientId}
        myLocations={myLocations}
        control={control}
      />

      <DialogActions>
        <Button disabled={buttonDisabled} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={buttonDisabled} type="submit" color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

CreateUpdateIngredientModalView.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.object,
  allergenConnection: PropTypes.object,
  nutrientConnection: PropTypes.object,
  inStock: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setInStock: PropTypes.func,
  locationIngredientId: PropTypes.number,
  handleBasicInfoChange: PropTypes.func,
  handleNutrientsChange: PropTypes.func,
  handleLocationsChange: PropTypes.func,
  ingredientNutrientConnection: PropTypes.object,
  ingredientAllergenConnection: PropTypes.object,
  myLocations: PropTypes.array,
  register: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  control: PropTypes.object,
  submit: PropTypes.func,
};

export default CreateUpdateIngredientModalView;
