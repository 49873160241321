import { gql } from '@apollo/client';

const US_FOODS_INVOICES = gql`
  query US_FOODS_INVOICES(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [InoviceFilter]
    $filterInventorytransfers: [InventorytransferFilter]
    $sort: [InoviceSort]
    $storeNumber: Int!
  ) {
    viewer {
      id
      locationConnection(filter: { storeNumber: { eq: $storeNumber } }, fieldGroupOnly: 88) {
        edges {
          node {
            id
            locationId
            storeNumber
            inventoryCountStatus
            inventoryCountDueDate
            inventorycount {
              inventorycountId
              dateCounted
            }
          }
        }
      }
      invoiceConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        fieldGroupOnly: 88
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            created
            invoiceNumber
            invoiceId
            invoiceDate
            dueDate
            isSimple
            storeNumber
            supplierName
            subtotal
            tax
            total
            isSubmitted
          }
        }
      }
      inventorytransferConnection(
        filter: $filterInventorytransfers
        search: $search
        fieldGroupOnly: 88
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            inventorytransferId
            sourceLocation {
              label
              storeNumber
              address {
                state {
                  abbreviation
                }
              }
            }
            targetLocation {
              label
              storeNumber
              address {
                state {
                  abbreviation
                }
              }
            }
            targetLocationAccepted
          }
        }
      }
    }
  }
`;

export default US_FOODS_INVOICES;
