import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Modal } from '../../../../../blocks';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { renderStatus, statusOptions } from '../../../../../../constants/statuses';
import { VENDOR_PRODUCT_MEASURES } from '../../../../../../apollo/queries/vendorProductMeasures';
import VendorPackSizeModal from './VendorPackSizeModal';
import { isEmpty } from 'lodash-es';

const VendorPackSizes = () => {
  const [isVendorPackSizeModalOpen, setIsVendorPackSizeModalOpen] = useState(false);
  const [vendorPackSize, setVendorPackSize] = useState();

  const handleOnRowClick = useCallback(({ data }) => {
    setVendorPackSize(data);
    setIsVendorPackSizeModalOpen(true);
  }, []);

  const handleCloseVendorPackSizeModal = useCallback(() => {
    setVendorPackSize();
    setIsVendorPackSizeModalOpen(false);
  }, []);

  const handleOnAddVendorPackSizeClick = useCallback(() => {
    setIsVendorPackSizeModalOpen(true);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.active) {
        delete filters.active;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          active: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        title="Vendor Pack Sizes"
        customActionsTop={() => (
          <Button onClick={handleOnAddVendorPackSizeClick} fullWidth>
            Add New Vendor Pack Size
          </Button>
        )}
        columns={[
          { field: 'vendorproductmeasureId', title: 'ID' },
          {
            field: 'packSize',
            title: 'Name',
          },
          {
            field: 'status',
            title: 'Status',
            render: renderStatus,
            enablePropagation: true,
            disableSort: true,
          },
          { field: 'updated', customSortField: 'created', title: 'Updated' },
        ]}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        query={VENDOR_PRODUCT_MEASURES}
        initialQueryVariables={{
          fieldGroupOnly: 94,
        }}
        structureTable={data =>
          data?.viewer?.vendorproductmeasureConnection?.edges?.map(edge => ({
            packSize: edge.node.packSize,
            vendorproductmeasureId: edge.node.vendorproductmeasureId,
            status: edge.node.active,
            updated: moment(edge.node.created).format('MM/DD/YYYY'),
            data: edge.node,
          })) || []
        }
        onTableRowClick={handleOnRowClick}
      />
      <Modal
        open={isVendorPackSizeModalOpen}
        handleClose={handleCloseVendorPackSizeModal}
        title={
          vendorPackSize?.vendorproductmeasureId ? 'Edit Vendor Pack Size' : 'Add Vendor Pack Size'
        }
      >
        <VendorPackSizeModal
          vendorPackSize={vendorPackSize}
          handleClose={handleCloseVendorPackSizeModal}
        />
      </Modal>
    </>
  );
};

export default VendorPackSizes;
