import { gql } from '@apollo/client';

const ALLERGENS_LIST = gql`
  query Allergens(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [AllergenSort]
    $filter: [AllergenFilter]
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      allergenConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: $fieldGroupOnly
      ) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            permission
            allergenId
            label
            description
            created
            updated
          }
        }
      }
    }
  }
`;

export default ALLERGENS_LIST;
