import { gql } from '@apollo/client';

// TODO: add fieldgroup
export const LOCATION_INGREDIENTS = gql`
  query LocationIngredient(
    $filter: [LocationIngredientFilter]
    $sort: [LocationIngredientSort]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    viewer {
      id
      locationIngredientConnection(
        filter: $filter
        sort: $sort
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        fieldGroupOnly: 88
      ) {
        permissions
        edges {
          node {
            id
            cost
            available
            ingredientId
            ingredient {
              id
              inventoryitem {
                label
                cost
                description
                measure {
                  id
                  measureId
                  label
                  abbreviation
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const INVENTORYITEMS = gql`
  query InventoryItems(
    $filter: [InventoryitemFilter]
    $sort: [InventoryitemSort]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $locationId: Int
  ) {
    viewer {
      id
      inventoryitemConnection(
        filter: $filter
        sort: $sort
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        fieldGroupOnly: 88
      ) {
        permissions
        edges {
          node {
            inventoryitemId
            latestCost(locationId: $locationId)
            label
            cost
            description
            measure {
              id
              measureId
              label
              abbreviation
            }
          }
        }
      }
    }
  }
`;
