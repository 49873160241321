import { gql } from '@apollo/client';
import { loyaltysettingsFragment } from '../../fragments';

export const LOYALTY_SETTINGS = gql`
  query loyaltySettings(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $sort: [LoyaltysettingsSort]
    $filter: [LoyaltysettingsFilter]
    $search: String
  ) {
    viewer {
      id
      loyaltysettingsConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
      ) {
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
        totalCount
        edges {
          node {
            ...LoyaltysettingsData
          }
        }
      }
    }
  }
  ${loyaltysettingsFragment.loyaltysettings}
`;
