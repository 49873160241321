import { Box, Grid, IconButton, List, ListItem, Typography, FormLabel } from '@material-ui/core';
import { HighlightOff, Remove, Add } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';

function getValue(value, minValue, maxValue) {
  if (!value) {
    return 0;
  }

  if (minValue !== null && value < minValue) {
    return minValue;
  }

  if (maxValue !== null && value > maxValue) {
    return maxValue;
  }

  return value;
}

const NumberOperators = ({
  defaultValue = 1,
  minValue = null,
  maxValue = null,
  onChange,
  disabled,
  step = 1,
  label,
}) => {
  const [value, setValue] = useState(getValue(defaultValue, minValue, maxValue));

  const handleChange = useCallback(
    change => {
      const newValue = value + change;
      setValue(newValue);

      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, value],
  );

  return (
    <div>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Grid
        spacing={3}
        container
        style={{
          background: disabled ? '#bdbdbd' : '#FFF6CC',
          borderRadius: '25px',
          width: 'fit-content',
          padding: '0 15px',
          opacity: disabled ? 0.4 : 1,
          boxShadow: '#00000029 0 1px 6px',
          marginTop: label ? '8px' : 0,
        }}
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <IconButton
            onClick={() => handleChange(-step)}
            size="small"
            disabled={disabled || (minValue !== null && value <= minValue)}
            style={{ background: 'white' }}
          >
            <Remove size="small" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="body1" align="center">
            {value}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => handleChange(step)}
            size="small"
            disabled={disabled || (maxValue !== null && value >= maxValue)}
            style={{ background: 'white' }}
          >
            <Add size="small" />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default NumberOperators;
