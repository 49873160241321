import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import Grid from '@material-ui/core/Grid';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import DraggableTreeItem from './DraggableTreeItem';
import Menu from './MenuTreeItem/Menu';
import CreateMenu from './MenuTreeItem/Menu/CreateMenu';
import Category from './MenuTreeItem/Category';
import CreateCategory from './MenuTreeItem/Category/CreateCategory';
import Subcategory from './MenuTreeItem/Subcategory';
import CreateSubcategory from './MenuTreeItem/Subcategory/CreateSubcategory';
import Item from './MenuTreeItem/Item';
import { menuSettings as constants } from '../../../../../constants';
import { FETCH_MENU_LIST, SUBCATEGORY_ITEM_LIST } from '../../../../../apollo/queries';
import {
  ADD_MENU_TO_TREE,
  ADD_CATEGORY_TO_TREE,
  ADD_SUBCATEGORY_TO_TREE,
  ADD_ITEM_TO_TREE,
  REMOVE_MENU_FROM_TREE,
  REMOVE_CATEGORY_FROM_TREE,
  REMOVE_SUBCATEGORY_FROM_TREE,
  REMOVE_ITEM_FROM_TREE,
} from '../../../../../apollo/mutations';
import PaperMain from '../../../../blocks/PaperMain';
import MenuTreeStyles from './View/styles';
import { result } from 'lodash';
import FETCH_ITEM_TYPES from '../../../../../apollo/queries/menus/fetchItemTypes';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const BASE_PATH = '/spark/system-settings/menu-tree';

const MenuTree = () => {
  const classes = MenuTreeStyles();

  const { setSnackbar } = useSnackbar();
  const history = useHistory();
  const params = useParams();
  const route = useRouteMatch();

  const [treeAction, setTreeAction] = useState({});
  const [itemData, setItemData] = useState();

  const [addItemToTree, { loading: addItemToTreeLoading }] = useMutation(ADD_ITEM_TO_TREE, {
    // eslint-disable-next-line no-shadow
    update: (cache, data) => {
      const startSubcategoryid = treeAction.actions[0].variables
        ? +atob(treeAction.actions[0].variables?.input.startingSubcategoryId).split(':')[1]
        : null;
      const targetSubcategoryId = data.data.addItemToTree.subcategoryItem.subcategoryId;
      const itemId = data.data.addItemToTree.subcategoryItem.item.itemId;

      cache.modify({
        id: cache.identify(data.data.addItemToTree.viewer),
        fields: {
          [`subcategoryItemConnection({"filter":{"showHiddenToLocation":{"eq":true},"showUnavailableOnline":{"eq":true},"subcategoryId":{"eq":${startSubcategoryid}}}})`]: existingSubcategoryItems => {
            if (data.data.addItemToTree.operation === 'move') {
              return {
                ...existingSubcategoryItems,
                edges: existingSubcategoryItems.edges.filter(
                  edge =>
                    +atob(edge.node.__ref.split(':')[1]).split(':')[2] !==
                    +atob(data.data.addItemToTree.subcategoryItem.id).split(':')[2],
                ),
              };
            }

            return existingSubcategoryItems;
          },
          [`subcategoryItemConnection({"filter":{"showHiddenToLocation":{"eq":true},"showUnavailableOnline":{"eq":true},"subcategoryId":{"eq":${data.data.addItemToTree.subcategoryItem.subcategoryId}}}})`]: existingSubcategoryItems => {
            return {
              ...existingSubcategoryItems,
              edges: [
                ...existingSubcategoryItems.edges,
                {
                  node: {
                    __ref: `SubcategoryItem:${data.data.addItemToTree.subcategoryItem.id}`,
                  },
                  __typename: 'SubcategoryItemEdge',
                },
              ],
            };
          },
        },
      });

      setItemData(prevData => {
        if (data.data.addItemToTree.operation === 'move') {
          const result = [...prevData];

          result.forEach(subcategory => {
            if (subcategory.subcategoryId === startSubcategoryid) {
              subcategory.items = subcategory.items.filter(item => item.itemId !== itemId);
            } else if (subcategory.subcategoryId === targetSubcategoryId) {
              subcategory.items = [
                ...subcategory.items,
                data.data.addItemToTree.subcategoryItem.item,
              ];
            }
          });

          return result;
        }

        if (['copy', 'clone'].includes(data.data.addItemToTree.operation)) {
          const result = [...prevData];

          result.find(subcategory => {
            if (subcategory.subcategoryId === targetSubcategoryId) {
              subcategory.items = [
                ...subcategory.items,
                data.data.addItemToTree.subcategoryItem.item,
              ];
              return true;
            }

            return false;
          });

          return result;
        }

        return result;
      });

      setTreeAction({});
    },
  });
  const [addSubcategoryToTree, { loading: addSubcategoryToTreeLoading }] = useMutation(
    ADD_SUBCATEGORY_TO_TREE,
    {
      // eslint-disable-next-line no-shadow
      update: () => {
        setTreeAction({});
      },
    },
  );

  const [addCategoryToTree, { loading: addCategoryToTreeLoading }] = useMutation(
    ADD_CATEGORY_TO_TREE,
    {
      // eslint-disable-next-line no-shadow
      update: (cache, data) => {
        const categoryId = atob(data.data.addCategoryToTree.menuCategory.category.id).split(':')[1];

        if (data.data.addCategoryToTree.operation === 'move') {
          cache.modify({
            id: `Menu:${data.data.addCategoryToTree.startingMenuId}`,
            fields: {
              menuCategoryConnection: existingMenuCategories => {
                return {
                  ...existingMenuCategories,
                  edges: existingMenuCategories.edges.filter(
                    edge => atob(edge.node.__ref.split(':')[1]).split(':')[2] !== categoryId,
                  ),
                };
              },
            },
          });
        }

        cache.modify({
          id: `Menu:${data.data.addCategoryToTree.targetMenuId}`,
          fields: {
            menuCategoryConnection: existingMenuCategories => {
              return {
                ...existingMenuCategories,
                edges: [
                  ...existingMenuCategories.edges,
                  {
                    node: {
                      ['__ref']: `MenuCategory:${data.data.addCategoryToTree.menuCategory.id}`,
                    },
                    __typename: 'MenuCategoryEdge',
                  },
                ],
              };
            },
          },
        });

        setTreeAction({});
      },
    },
  );

  const [addMenuToTree, { loading: addMenuToTreeLoading }] = useMutation(ADD_MENU_TO_TREE, {
    // eslint-disable-next-line no-shadow
    update: () => {
      setTreeAction({});
    },
  });

  const [removeMenuFromTree, { loading: removeMenuFromTreeLoading }] = useMutation(
    REMOVE_MENU_FROM_TREE,
    {
      // eslint-disable-next-line no-shadow
      update: () => {
        setTreeAction({});
      },
    },
  );

  const [removeCategoryFromTree, { loading: removeCategoryFromTreeLoading }] = useMutation(
    REMOVE_CATEGORY_FROM_TREE,
    {
      // eslint-disable-next-line no-shadow
      update: () => {
        setTreeAction({});
      },
    },
  );

  const [removeSubcategoryFromTree, { loading: removeSubcategoryFromTreeLoading }] = useMutation(
    REMOVE_SUBCATEGORY_FROM_TREE,
    {
      // eslint-disable-next-line no-shadow
      update: () => {
        setTreeAction({});
      },
    },
  );

  const [removeItemFromTree, { loading: removeItemFromTreeLoading }] = useMutation(
    REMOVE_ITEM_FROM_TREE,
    {
      // eslint-disable-next-line no-shadow
      update: (cache, { data: { removeItemFromTree } }) => {
        cache.modify({
          id: cache.identify(removeItemFromTree.viewer),
          fields: {
            [`subcategoryItemConnection({"filter":{"showHiddenToLocation":{"eq":true},"showUnavailableOnline":{"eq":true},"subcategoryId":{"eq":${removeItemFromTree.removedSubcategoryItemParent}}}})`]: existingSubcategoryItems => {
              return {
                ...existingSubcategoryItems,
                edges: [
                  ...existingSubcategoryItems.edges.filter(
                    subcategoryItem =>
                      subcategoryItem.node.__ref !==
                      `SubcategoryItem:${removeItemFromTree.removedSubcategoryItemId}`,
                  ),
                ],
              };
            },
          },
        });

        setItemData(prevData => {
          const result = [...prevData];

          result.find(subcategory => {
            if (subcategory.subcategoryId === removeItemFromTree.removedSubcategoryItemParent) {
              subcategory.items = subcategory.items.filter(
                item =>
                  item.itemId !== +atob(removeItemFromTree.removedSubcategoryItemId).split(':')[2],
              );

              return true;
            }

            return false;
          });

          return result;
        });

        cache.gc();
        setTreeAction({});
      },
    },
  );
  const disabled =
    addCategoryToTreeLoading ||
    addItemToTreeLoading ||
    addSubcategoryToTreeLoading ||
    addMenuToTreeLoading ||
    removeCategoryFromTreeLoading ||
    removeItemFromTreeLoading ||
    removeSubcategoryFromTreeLoading ||
    removeMenuFromTreeLoading;
  const { data: menuData, error, loading, refetch, variables } = useQuery(FETCH_MENU_LIST);
  const { data: itemTypeData, loading: itemTypeLoading, error: itemTypeError } = useQuery(
    FETCH_ITEM_TYPES,
  );

  const itemTypeOptions = useMemo(
    () =>
      itemTypeData?.viewer?.itemtypeConnection?.edges?.map(edge => ({
        ...edge.node,
      })),
    [itemTypeData],
  );

  const [
    getSubcategoryItems,
    {
      data: subcategoryData,
      error: subcategoryError,
      loading: subcategoryLoading,
      variables: subcategoryVariables,
      called,
    },
  ] = useLazyQuery(SUBCATEGORY_ITEM_LIST);

  const generateTreeData = useCallback((data, itemData) => {
    return data?.viewer?.menuConnection?.edges.map(menu => {
      return {
        key: uuid(),
        id: menu.node.id,
        menuId: menu.node.menuId,
        type: constants.TREE_NODE_TYPES.MENU,
        childType: 'category',
        title: menu.node.label,
        children: menu.node.menuCategoryConnection.edges.map(menuCategory => {
          return {
            key: uuid(),
            id: menuCategory.node.category.id,
            categoryId: menuCategory.node.category.categoryId,
            menuId: menu.node.menuId,
            type: constants.TREE_NODE_TYPES.CATEGORY,
            title: menuCategory.node.category.label,
            childType: 'subcategory',
            children: menuCategory.node.category.subcategoryConnection.edges
              .filter(subcategory => subcategory.node.menuId === menu.node.menuId)
              .map(subcategory => {
                const matchingSubcategory = itemData?.find(
                  item => subcategory.node.subcategoryId === item.subcategoryId,
                );
                return {
                  key: uuid(),
                  id: subcategory.node.id,
                  subcategoryId: subcategory.node.subcategoryId,
                  parentGlobalId: menuCategory.node.category.id,
                  menuId: menu.node.menuId,
                  categoryId: menuCategory.node.category.categoryId,
                  type: constants.TREE_NODE_TYPES.SUBCATEGORY,
                  childType: 'item',
                  title: subcategory.node.label,
                  children: matchingSubcategory
                    ? matchingSubcategory.items.map(item => {
                        return {
                          key: uuid(),
                          id: item.id,
                          itemId: item.itemId,
                          subcategoryId: subcategory.node.subcategoryId,
                          parentGlobalId: subcategory.node.id,
                          categoryId: menuCategory.node.category.categoryId,
                          menuId: menu.node.menuId,
                          type: constants.TREE_NODE_TYPES.ITEM,
                          title: item.label,
                        };
                      })
                    : [
                        {
                          type: 'item',
                          subcategoryId: subcategory.node.subcategoryId,
                          menuId: menu.node.menuId,
                          categoryId: menuCategory.node.category.categoryId,
                        },
                      ],
                };
              }),
          };
        }),
      };
    });
  }, []);

  const treeData = useMemo(() => generateTreeData(menuData, itemData), [
    menuData,
    itemData,
    generateTreeData,
  ]);

  useEffect(() => {
    if (subcategoryData) {
      setItemData(prevState => {
        const fetchedItems = [...(prevState || [])];
        const subcategoryId =
          subcategoryData?.viewer?.subcategoryItemConnection?.edges[0]?.node?.subcategoryId;
        let existingSubcategory = false;

        fetchedItems.forEach(subcategoryItem => {
          if (subcategoryItem.subcategoryId === subcategoryId) {
            subcategoryItem.items = subcategoryData?.viewer?.subcategoryItemConnection?.edges.map(
              subcategoryItem => subcategoryItem.node.item,
            );
            existingSubcategory = true;
          }
        });

        return existingSubcategory
          ? [...fetchedItems]
          : [
              ...fetchedItems,
              {
                subcategoryId:
                  subcategoryData?.viewer?.subcategoryItemConnection?.edges[0]?.node?.subcategoryId,
                items: subcategoryData?.viewer?.subcategoryItemConnection?.edges.map(
                  subcategoryItem => subcategoryItem.node.item,
                ),
              },
            ];
      });
    }
  }, [subcategoryData?.viewer?.subcategoryItemConnection?.edges, subcategoryData]);

  if (loading || itemTypeLoading || subcategoryLoading)
    return <CircularProgress className={classes.spinner} />;
  if (error || itemTypeError || subcategoryError) console.log(error);

  if (params.itemId && !itemData && !called) {
    getSubcategoryItems({
      variables: {
        ...subcategoryVariables,
        filter: {
          showHiddenToLocation: { eq: true },
          showUnavailableOnline: { eq: true },
          subcategoryId: {
            in: +params.subcategoryId,
          },
        },
      },
    });
  }

  const handleModalClose = () => setTreeAction({});

  const cloneTreeElement = (e, treeNode, parentId) => {
    e.stopPropagation();

    setTreeAction({});

    setSnackbar({
      open: true,
      type: 'info',
      text: `Cloning ${treeNode.type}...`,
    });

    if (treeNode.type === constants.TREE_NODE_TYPES.ITEM) {
      addItemToTree({
        variables: {
          input: {
            operation: 'clone',
            itemId: treeNode.id,
            targetSubcategoryId: parentId,
          },
        },
        refetchQueries: [
          {
            query: FETCH_MENU_LIST,
            variables: { ...variables, subcategoryId: { eq: parentId } },
          },
        ],
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} cloned successfully`,
        }),
      );
    } else if (treeNode.type === constants.TREE_NODE_TYPES.SUBCATEGORY) {
      addSubcategoryToTree({
        variables: {
          input: {
            subcategoryId: treeNode.id,
            targetMenuId: treeNode?.menuId,
            targetCategoryId: parentId,
          },
        },
        refetchQueries: [{ query: FETCH_MENU_LIST, variables: { ...variables } }],
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} cloned successfully`,
        }),
      );
    } else if (treeNode.type === constants.TREE_NODE_TYPES.CATEGORY) {
      addCategoryToTree({
        variables: {
          input: {
            startingMenuId: parentId,
            operation: 'clone',
            categoryId: treeNode.id,
            targetMenuId: parentId,
          },
        },
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} cloned successfully`,
        }),
      );
    } else if (treeNode.type === constants.TREE_NODE_TYPES.MENU) {
      addMenuToTree({
        variables: {
          input: {
            menuId: treeNode.id,
          },
        },
        refetchQueries: [{ query: FETCH_MENU_LIST, variables: { ...variables } }],
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} cloned successfully`,
        }),
      );
    }
  };

  const removeTreeElement = (e, treeNode) => {
    e.stopPropagation();

    setTreeAction({});

    setSnackbar({
      open: true,
      type: 'info',
      text: `Removing ${treeNode.type}...`,
    });

    if (treeNode.type === constants.TREE_NODE_TYPES.ITEM) {
      removeItemFromTree({
        variables: {
          input: {
            itemId: treeNode.id,
            subcategoryId: treeNode.parentGlobalId,
          },
        },
        refetchQueries: [{ query: FETCH_MENU_LIST, variables: { ...variables } }],
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} removed successfully.`,
        }),
      );
    } else if (treeNode.type === constants.TREE_NODE_TYPES.SUBCATEGORY) {
      removeSubcategoryFromTree({
        variables: {
          input: {
            subcategoryId: treeNode.id,
          },
        },
        refetchQueries: [{ query: FETCH_MENU_LIST, variables: { ...variables } }],
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} removed successfully.`,
        }),
      );
    } else if (treeNode.type === constants.TREE_NODE_TYPES.CATEGORY) {
      removeCategoryFromTree({
        variables: {
          input: {
            categoryId: treeNode.id,
            menuId: treeNode.menuId,
          },
        },
        refetchQueries: [{ query: FETCH_MENU_LIST }],
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} removed successfully.`,
        }),
      );
    } else if (treeNode.type === constants.TREE_NODE_TYPES.MENU) {
      removeMenuFromTree({
        variables: {
          input: {
            menuId: treeNode.id,
          },
        },
        refetchQueries: [{ query: FETCH_MENU_LIST, variables: { ...variables } }],
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: `${treeNode.type[0].toUpperCase() +
            treeNode.type.substring(1)} removed successfully.`,
        }),
      );
    }
  };

  const menuTreeActionPathBuilder = (type, id, menuId, categoryId, subcategoryId) => {
    let path = BASE_PATH;

    switch (type) {
      case 'menu':
        path += '/menus';
        break;
      case 'category':
        path += `/menus/${menuId}/categories`;
        break;
      case 'subcategory':
        path += `/menus/${menuId}/categories/${categoryId}/subcategories`;
        break;
      case 'item':
        path += `/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}/items`;
        break;
      default:
        path += '';
        break;
    }

    return path;
  };

  const menuTreeNodePathBuilder = (type, menuId, categoryId, subcategoryId, itemId) => {
    let path = BASE_PATH;

    switch (type) {
      case 'menu':
        path += `/menus/${menuId}`;
        break;
      case 'category':
        path += `/menus/${menuId}/categories/${categoryId}`;
        break;
      case 'subcategory':
        path += `/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}`;
        break;
      case 'item':
        path += `/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}/items/${itemId}`;
        break;
      default:
        path += '';
        break;
    }

    return path;
  };

  function handleOnCloneTreeElementClick(e, treeNode, parentId) {
    e.stopPropagation();
    setTreeAction({
      isClone: true,
      treeNode,
      e,
      parentId,
      actions: [
        {
          label: 'Clone',
        },
      ],
    });
  }

  const renderMenuTree = (data, parentId) => {
    const treeData = data;
    if (!parentId && !treeData[treeData.length - 1].action) {
      treeData.push({
        key: uuid(),
        action: true,
        type: constants.TREE_NODE_TYPES.MENU,
        childType: 'category',
      });
    }
    return treeData?.length
      ? treeData?.map(treeNode => {
          if (
            treeNode?.children?.length &&
            !treeNode?.children?.[treeNode.children.length - 1]?.action
          ) {
            treeNode.children.push({
              key: uuid(),
              action: true,
              type: treeNode.children[0].type,
              menuId: treeNode.children[0].menuId,
              categoryId: treeNode.children[0].categoryId,
              subcategoryId: treeNode.children[0].subcategoryId,
            });
          }
          if (treeNode?.action) {
            const path = menuTreeActionPathBuilder(
              treeNode.type,
              treeNode.id,
              treeNode.menuId,
              treeNode.categoryId,
              treeNode.subcategoryId,
            );
            return (
              <Button
                className={classes.treeItemNew}
                component={Link}
                disabled={disabled}
                to={{
                  pathname: path,
                  state: {
                    menuId: treeNode.menuId,
                    categoryId: treeNode.categoryId,
                    subcategoryId: treeNode.subcategoryId,
                  },
                }}
                variant="outlined"
                size="small"
              >
                {`Add ${treeNode.type.charAt(0).toUpperCase() + treeNode.type.slice(1)}`}
              </Button>
            );
          }

          let prettyId = '';

          switch (treeNode?.type) {
            case 'menu':
              prettyId = treeNode.menuId;
              break;
            case 'category':
              prettyId = treeNode.categoryId;
              break;
            case 'subcategory':
              prettyId = treeNode.subcategoryId;
              break;
            case 'item':
              prettyId = treeNode.itemId;
              break;
            default:
              prettyId = '';
              break;
          }

          return (
            treeNode?.id && (
              <DraggableTreeItem
                className={classes.treeItem}
                key={treeNode.key}
                nodeId={treeNode.id}
                onIconClick={(a, b) => {
                  if (treeNode.type === 'subcategory') {
                    getSubcategoryItems({
                      variables: {
                        filter: {
                          showHiddenToLocation: { eq: true },
                          showUnavailableOnline: { eq: true },
                          subcategoryId: {
                            eq: +treeNode.subcategoryId,
                          },
                        },
                      },
                    });
                  }
                }}
                menuId={treeNode.menuId}
                parentId={parentId}
                label={
                  <div className={classes.treeItemLabel}>
                    <span className={classes.treeItemTitle}>{treeNode.title}</span>
                    <span className={classes.treeItemId}>{`ID_${prettyId}`}</span>
                    <span className={classes.treeItemActions}>
                      <Tooltip title="Clone">
                        <IconButton
                          style={{ padding: '0' }}
                          disabled={disabled}
                          onClick={e => {
                            handleOnCloneTreeElementClick(e, treeNode, parentId);
                          }}
                          aria-label="clone"
                        >
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          style={{ padding: '0' }}
                          disabled={disabled}
                          onClick={e => {
                            e.stopPropagation();
                            setTreeAction({
                              treeNode,
                              actions: [
                                {
                                  label: 'Remove',
                                },
                              ],
                              isRemove: true,
                              e,
                              parentId,
                            });
                          }}
                          aria-label="delete"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                }
                itemLabel={treeNode.title}
                type={treeNode.type}
                childtype={treeNode.childType}
                setTreeAction={setTreeAction}
                mutations={{
                  addItemToTree,
                  addSubcategoryToTree,
                  addCategoryToTree,
                }}
                onLabelClick={() => {
                  if (treeNode.type === 'subcategory') {
                    getSubcategoryItems({
                      variables: {
                        filter: {
                          showHiddenToLocation: { eq: true },
                          showUnavailableOnline: { eq: true },
                          subcategoryId: {
                            eq: +treeNode.subcategoryId,
                          },
                        },
                      },
                    });
                  }
                  const path = menuTreeNodePathBuilder(
                    treeNode.type,
                    treeNode.menuId,
                    treeNode.categoryId,
                    treeNode.subcategoryId,
                    treeNode.itemId,
                  );
                  history.push(path);
                }}
              >
                {treeNode.children && renderMenuTree(treeNode.children, treeNode.id)}
              </DraggableTreeItem>
            )
          );
        })
      : '';
  };

  const findNodeById = (tree, menuId, categoryId, subcategoryId, itemId) => {
    const expanded = [];
    let gqlMenuId = '';
    let gqlCategoryId = '';
    let gqlSubcategoryId = '';
    let gqlItemId = '';

    if (!treeData)
      return {
        menuId: null,
        categoryId: null,
        subcategoryId: null,
        itemId: null,
      };
    if (menuId) {
      const menuIdx = tree.findIndex(menu => menu.menuId === menuId);
      if (menuIdx !== -1) {
        gqlMenuId = tree[menuIdx].id;
        expanded.push(gqlMenuId);
        if (categoryId) {
          const categoryIdx = tree[menuIdx].children.findIndex(
            category => category.categoryId === categoryId,
          );
          if (categoryIdx !== -1) {
            gqlCategoryId = tree[menuIdx].children[categoryIdx].id;
            expanded.push(gqlCategoryId);
            if (subcategoryId) {
              const subcategoryIdx = tree[menuIdx].children[categoryIdx].children.findIndex(
                subcategory => subcategory.subcategoryId === subcategoryId,
              );
              if (subcategoryIdx !== -1) {
                gqlSubcategoryId = tree[menuIdx].children[categoryIdx].children[subcategoryIdx].id;
                expanded.push(gqlSubcategoryId);
                if (itemId) {
                  const itemIdx = tree[menuIdx].children[categoryIdx].children[
                    subcategoryIdx
                  ].children.findIndex(item => item.itemId === itemId);
                  if (itemIdx !== -1) {
                    gqlItemId =
                      tree[menuIdx].children[categoryIdx].children[subcategoryIdx].children[itemIdx]
                        .id;
                    expanded.push(gqlItemId);
                  }
                }
              }
            }
          }
        }
      }
    }

    return { expanded, gqlMenuId, gqlCategoryId, gqlSubcategoryId, gqlItemId };
  };

  const { menuId, categoryId, subcategoryId, itemId } = params;

  const { expanded, gqlMenuId, gqlCategoryId, gqlSubcategoryId, gqlItemId } = findNodeById(
    treeData,
    parseInt(menuId, 10),
    parseInt(categoryId, 10),
    parseInt(subcategoryId, 10),
    parseInt(itemId, 10),
  );

  const ids = Object.assign(
    {},
    { gqlMenuId, gqlCategoryId, gqlSubcategoryId, gqlItemId },
    { menuId, categoryId, subcategoryId, itemId },
  );

  return (
    <PaperMain>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Container className={classes.container}>
            <Typography variant="h3" component="h2">
              Menu Setup
            </Typography>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              defaultExpanded={expanded}
              className={classes.treeView}
            >
              {treeData && renderMenuTree(treeData)}
            </TreeView>
          </Container>
        </Grid>
        <Grid item xs={9}>
          {route.path === `${BASE_PATH}/menus/:menuId` && (
            <Menu ids={ids} basePath={BASE_PATH} classes={classes} refetchMenuList={refetch} />
          )}
          {route.path === `${BASE_PATH}/menus` && (
            <CreateMenu
              ids={ids}
              basePath={BASE_PATH}
              classes={classes}
              refetchMenuList={refetch}
            />
          )}
          {route.path === `${BASE_PATH}/menus/:menuId/categories/:categoryId` && (
            <Category ids={ids} basePath={BASE_PATH} classes={classes} refetchMenuList={refetch} />
          )}
          {route.path === `${BASE_PATH}/menus/:menuId/categories` && (
            <CreateCategory
              ids={ids}
              basePath={BASE_PATH}
              classes={classes}
              refetchMenuList={refetch}
            />
          )}
          {route.path ===
            `${BASE_PATH}/menus/:menuId/categories/:categoryId/subcategories/:subcategoryId` && (
            <Subcategory
              ids={ids}
              basePath={BASE_PATH}
              classes={classes}
              refetchMenuList={refetch}
              menuData={menuData}
            />
          )}
          {route.path === `${BASE_PATH}/menus/:menuId/categories/:categoryId/subcategories` && (
            <CreateSubcategory
              ids={ids}
              basePath={BASE_PATH}
              classes={classes}
              refetchMenuList={refetch}
            />
          )}
          {route.path ===
            `${BASE_PATH}/menus/:menuId/categories/:categoryId/subcategories/:subcategoryId/items/:itemId` && (
            <Item
              ids={ids}
              basePath={BASE_PATH}
              classes={classes}
              refetchMenuList={refetch}
              itemTypeOptions={itemTypeOptions}
              menuData={menuData}
            />
          )}
          {route.path ===
            `${BASE_PATH}/menus/:menuId/categories/:categoryId/subcategories/:subcategoryId/items` && (
            <Item
              ids={ids}
              basePath={BASE_PATH}
              classes={classes}
              refetchMenuList={refetch}
              itemTypeOptions={itemTypeOptions}
            />
          )}
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={Object.keys(treeAction).length}
        onClose={handleModalClose}
      >
        <DialogTitle style={{ height: '1rem' }}>
          <IconButton
            aria-label="close"
            onClick={() => setTreeAction({})}
            style={{ height: '2px', width: '2px', float: 'right' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {treeAction?.isRemove ? (
            <DialogContentText>{`Remove ${treeAction?.treeNode?.type}?`}</DialogContentText>
          ) : treeAction?.isClone ? (
            <DialogContentText>
              {`Clone ${treeAction?.treeNode?.type}?`}
              <br />
              {treeAction?.treeNode?.type === 'menu' && (
                <span style={{ color: '#D40E37', fontSize: '0.75rem' }}>
                  *This feature is in testing, it's prohibited to clone production menu.
                </span>
              )}
            </DialogContentText>
          ) : (
            <DialogContentText>
              {`You placed ${treeAction?.item?.label} ${treeAction?.item?.type} into ${treeAction?.destination?.label} ${treeAction?.destination?.type}`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {treeAction?.actions?.map(action => {
            if (treeAction?.isRemove) {
              return (
                <>
                  <Button onClick={() => setTreeAction({})} size="small" color="primary" autoFocus>
                    No
                  </Button>
                  <Button
                    onClick={() =>
                      removeTreeElement(treeAction?.e, treeAction?.treeNode, treeAction?.parentId)
                    }
                    size="small"
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </Button>
                </>
              );
            } else if (treeAction?.isClone) {
              return (
                <>
                  <Button
                    onClick={() =>
                      cloneTreeElement(treeAction?.e, treeAction?.treeNode, treeAction?.parentId)
                    }
                    size="small"
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </Button>
                </>
              );
            } else {
              return (
                <Button
                  onClick={() => {
                    action.mutation({
                      variables: action.variables,
                    });
                  }}
                  size="small"
                  color="primary"
                  autoFocus
                  className={classes.button}
                >
                  {action.label}
                </Button>
              );
            }
          })}
        </DialogActions>
      </Dialog>
    </PaperMain>
  );
};

export default MenuTree;
