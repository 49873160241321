import React from 'react';
// Local Imports
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from '@material-ui/core';
import { Elements, Permission, PermissionField } from '../../../../blocks';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import Button from '@material-ui/core/Button';
import { Controller } from 'react-hook-form';
import DateTime from 'luxon/src/datetime';
import { MUTATION_NAME } from '../../../../../constants';

const Clockout = ({
  handleCloseClockoutModal,
  openClockoutModal,
  control,
  handleSubmit,
  handleClockout,
  permissions,
  started,
  finished,
  mutationLoading,
  accessToMutations,
  clockoutTimeshiftPermission,
  }) => {
  const onSubmit = data => {
    handleClockout({
      timeshiftId: openClockoutModal.timeshiftId,
      end: DateTime.fromFormat(`${data.finished.date} ${data.finished.timeHours}:${data.finished.timeMinutes} ${data.finished.timeMeridiem}`, 'LL/dd/yyyy h:m a', {zone: openClockoutModal.timezone}).toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
      started,
      finished
    })
  };

  return (
      <Dialog
        open={openClockoutModal.open}
        onClose={handleCloseClockoutModal}
        maxWidth="md"
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid xs={12} spacing={3}>
              <Permission access={permissions?.person?.timeshiftConnection?.edges?.node?.finished}>
                <Grid xs={12}>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Controller
                      control={control}
                      name="finished.date"
                      defaultValue={DateTime.local().toFormat("LL/dd/yyyy")}
                      render ={({ onChange,value}) =>
                        <Permission access={permissions?.person?.timeshiftConnection?.edges?.node?.finished}>
                          <PermissionField
                            Component={KeyboardDatePicker}
                            fullWidth
                            variant="inline"
                            inputVariant="outlined"
                            format="LL/dd/yyyy"
                            margin="normal"
                            label="Clock Out"
                            onChange={(date)=> {
                              // Now we do some computing here
                              onChange(date.toFormat("LL/dd/yyyy"))}
                            }
                            autoOk={true}
                            value={value}
                            // Disable text input
                            TextFieldComponent={(props) => <TextField {...props} disabled={true}/>}
                          />
                        </Permission>
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid xs={12}>
                  <Elements.TimePicker
                    control={control}
                    access={permissions?.person?.timeshiftConnection?.edges?.node?.finished}
                    hoursName={`finished.timeHours`}
                    minutesName={`finished.timeMinutes`}
                    amPmName={`finished.timeMeridiem`}
                    defaultValues={{hours: DateTime.local().toFormat('h'), minutes: DateTime.local().toFormat('mm'), meridiem: DateTime.local().toFormat('a').toLowerCase()}}
                  />
                </Grid>
              </Permission>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseClockoutModal} variant="outlined">
              Cancel
            </Button>
            <Permission access={Math.min(clockoutTimeshiftPermission, accessToMutations?.[MUTATION_NAME.ClockoutTimeshiftMutationInput])}>
              <div>
                <PermissionField createHelperText>
                  <Button type="submit" color="primary" disabled={mutationLoading} autoFocus>
                    Save
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </DialogActions>
        </form>
      </Dialog>
    )
};

// Custom functions
export default Clockout;