import React, { useState } from 'react';

import ItemInCart from './ItemInCart';

function Triggers({
  control,
  selectedCoupontriggers,
  setSelectedCoupontriggers,
  selectedCouponaction,
  setSelectedCouponaction,
  selectedItems,
  setSelectedItems,
  selectedBrands,
  itemInCartIsEnabled,
  setItemInCartIsEnabled,
}) {
  return (
    <div
      style={{
        gap: '1rem',
        marginBottom: '1rem',
      }}
    >
      <ItemInCart
        control={control}
        isEnabled={itemInCartIsEnabled}
        setItemInCartIsEnabled={setItemInCartIsEnabled}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedCouponaction={selectedCouponaction}
        setSelectedCouponaction={setSelectedCouponaction}
        selectedCoupontriggers={selectedCoupontriggers}
        setSelectedCoupontriggers={setSelectedCoupontriggers}
        selectedBrands={selectedBrands}
      />
    </div>
  );
}

export default Triggers;
