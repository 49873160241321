import React, { useState } from "react";
import { Button, Chip } from "@material-ui/core";
import { Modal } from "../../../../../../../../blocks";
import { Controller } from "react-hook-form";
import Add from "@material-ui/icons/Add";
import AddItemsModal from "../../../../DiscountRestrictions/Items/AddItemsModal";
function CartContainsItems({
  control,
  cartItemsRequired,
  setCartItemsRequired,
}) {
  const [open, setOpen] = useState(false);

  const handleClose = (items) => {
    if (items.length) {
      setCartItemsRequired(
        items.map(({ label, id, key, itemId, isItem }) => ({
          label,
          id,
          itemId,
          key,
          isItem,
        }))
      );
    }
    setOpen(false);
  };

  const handleDelete = (itemId) => {
    const newArr = cartItemsRequired.filter((item) => item.id !== itemId);
    setCartItemsRequired(newArr);
  };

  return (
    <div>
      <Controller
        control={control}
        name="triggerEvents.automatic.cartContainsRequiredItems"
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Items</h3>
        <div>
          <Button onClick={setOpen.bind(this, true)} variant="outlined">
            <Add /> Add Items
          </Button>
        </div>
      </div>
      <div>
        {cartItemsRequired.map((item) => {
          if (item.isItem) {
            return (
              <Chip
                style={{ margin: "4px" }}
                key={item.key}
                label={item.label}
                onDelete={(e) => handleDelete(item.id)}
              />
            );
          } else {
            return "";
          }
        })}
      </div>
      <Modal open={open} handleClose={handleClose} title="Add Items">
        <AddItemsModal
          excludedItems={cartItemsRequired}
          setExcludedItems={setCartItemsRequired}
          handleClose={handleClose}
        />
      </Modal>
    </div>
  );
}

export default CartContainsItems;
