import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";

function Status({ setFilter, refetch: { refetch, variables } }) {
  return (
    <div>
      <FormControl>
        <InputLabel htmlFor="name-native-error">Status</InputLabel>
        <Select
          style={{ width: "8rem" }}
          inputProps={{
            name: "Status",
          }}
          onChange={(e) => {
            let filter;
            if (e.target.value !== "none") {
              filter = {
                ...variables,
                filter: {
                  ...variables.filter,
                  status: { eq: e.target.value },
                },
              };
            } else {
              filter = {
                ...variables,
                filter: {
                  ...variables.filter,
                  status: undefined,
                },
              };
            }
            refetch(filter);
          }}
          variant="outlined"
          defaultValue="none"
          label="Status"
          placeholder="Status"
        >
          <MenuItem value="none">ALL</MenuItem>
          <MenuItem
            style={{ fontWeight: "bold", color: "orange" }}
            value="SCHEDULE AHEAD"
          >
            SCHEDULE AHEAD
          </MenuItem>
          <MenuItem
            style={{ fontWeight: "bold", color: "green" }}
            value="PUBLISHED"
          >
            PUBLISHED
          </MenuItem>
          <MenuItem style={{ fontWeight: "bold" }} value="ENDED">
            ENDED
          </MenuItem>
          <MenuItem
            style={{ fontWeight: "bold", color: "red" }}
            value="ARCHIVED"
          >
            ARCHIVED
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default Status;
