import React, { useState } from "react";
import {
  Grid,
  TextField,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Elements } from "../../../../../../blocks";

function ExcludeHandoffs({
  control,
  setExcludedHandoffs,
  excludedHandoffs,
  handoffList,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setExcludedHandoffs([...excludedHandoffs, +e.target.value]);
    } else {
      setExcludedHandoffs(
        excludedHandoffs.filter((plat) => plat !== +e.target.value)
      );
    }
  };

  const renderCheckboxes = () => {
    return handoffList.edges.map((edge) => (
      <FormControlLabel
        key={edge.node.handoffId}
        control={
          <Checkbox
            onChange={handleCheckboxChange}
            checked={excludedHandoffs.includes(edge.node.handoffId)}
            value={edge.node.handoffId}
          />
        }
        label={edge.node.label}
      />
    ));
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Controller
          control={control}
          name="discountRestrictions.excludeHandoffs.enabled"
          defaultValue={showOptions}
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "2rem" }}>
                <Elements.Switch
                  ref={ref}
                  checked={showOptions}
                  handleSwitchChange={(isChecked) => {
                    setShowOptions(isChecked);
                    onChange(isChecked);
                  }}
                />
              </div>
              <div>
                <h3>Exclude Handoffs</h3>
              </div>
            </div>
          )}
        />
      </div>
      {showOptions && (
        <div style={{ display: "flex" }}>{renderCheckboxes()}</div>
      )}
    </div>
  );
}

export default ExcludeHandoffs;
