import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { LOCATIONS } from '../../../../../apollo/queries';
import FETCH_ACTUAL_VS_THEORETICAL from '../../../../../apollo/queries/fetchActualVsTheoretical';
import { convert, pad, toFixed10 } from '../../../../../utils';
import { ConnectionTable, SelectFactory } from '../../../../blocks';
import PaperMain from '../../../../blocks/PaperMain';
import Filters from './Filters';
import Togglers from './Togglers';

const groupByOptions = [
  { label: 'Category/Subcategory', value: 'inventoryitemcategory' },
  { label: 'General Ledger', value: 'generalledgeraccount' },
  { label: 'Reporting Category', value: 'reportcategory' },
];

function toPercentageForm(number) {
  if (!number && number !== 0) {
    return '-';
  }

  return `${toFixed10(number, 2)}%`;
}

const initialDateRange = {
  gte: moment()
    .add(-7, 'days')
    .format('MM/DD/YYYY'),
  lte: moment().format('MM/DD/YYYY'),
};

const ActualTheoretical = () => {
  const [location, setLocation] = useState();
  const [filters, setFilters] = useState({
    dateRange: initialDateRange,
    inventoryitemcategory: [],
    generalledgeraccount: [],
    reportcategory: [],
    groupBy: groupByOptions[0].value,
    displayAsCost: 0,
    displaySubategories: 1,
  });

  const handleOnLocationSelect = useCallback(value => {
    setLocation(value);
  }, []);

  const formatUsage = useCallback(
    quantity => {
      if (isNumber(quantity)) {
        return filters.displayAsCost
          ? `$ ${convert.centsToDollars(toFixed10(quantity, 2))}`
          : toFixed10(quantity, 2);
      }

      return quantity;
    },
    [filters.displayAsCost],
  );

  const structureTable = useCallback(
    data => {
      const groupById = `${filters.groupBy}Id`;

      const groups = [];

      const tableData = data?.viewer?.locationIngredientConnection?.edges
        ?.filter(edge => !!edge.node?.ingredient)
        .reduce((result, edge) => {
          if (
            result[result.length - 1]?.[groupById] !==
            edge.node?.ingredient?.inventoryitem?.inventoryitemcategory?.[groupById]
          ) {
            groups.push({
              [groupById]: edge.node?.ingredient?.inventoryitem?.inventoryitemcategory?.[groupById],
              auPercentage: edge.node?.actualVsTheoretical?.auPercentage || 0,
              tuPercentage: edge.node?.actualVsTheoretical?.tuPercentage || 0,
              varPercentage: edge.node?.actualVsTheoretical?.varPercentage || 0,
              auCount:
                edge.node?.actualVsTheoretical?.auPercentage ||
                edge.node?.actualVsTheoretical?.auPercentage === 0
                  ? 1
                  : 0,
              tuCount:
                edge.node?.actualVsTheoretical?.tuPercentage ||
                edge.node?.actualVsTheoretical?.tuPercentage === 0
                  ? 1
                  : 0,
              varCount:
                edge.node?.actualVsTheoretical?.varPercentage ||
                edge.node?.actualVsTheoretical?.varPercentage === 0
                  ? 1
                  : 0,
              index: result.length,
            });

            result.push({
              label:
                filters.groupBy === groupByOptions[0].value
                  ? edge.node?.ingredient?.inventoryitem?.inventoryitemcategory.label
                  : edge.node?.ingredient?.inventoryitem?.inventoryitemcategory[filters.groupBy]
                      .label,
              isGroupLabel: true,
              [groupById]: edge.node?.ingredient?.inventoryitem?.inventoryitemcategory?.[groupById],
            });
          } else {
            const group = groups[groups.length - 1];

            groups[groups.length - 1] = {
              ...group,
              auPercentage: group.auPercentage + edge.node?.actualVsTheoretical?.auPercentage || 0,
              tuPercentage: group.tuPercentage + edge.node?.actualVsTheoretical?.tuPercentage || 0,
              varPercentage:
                group.varPercentage + edge.node?.actualVsTheoretical?.varPercentage || 0,
              auCount:
                group.auCount +
                (edge.node?.actualVsTheoretical?.auPercentage ||
                edge.node?.actualVsTheoretical?.auPercentage === 0
                  ? 1
                  : 0),
              tuCount:
                group.tuCount +
                (edge.node?.actualVsTheoretical?.tuPercentage ||
                edge.node?.actualVsTheoretical?.tuPercentage === 0
                  ? 1
                  : 0),
              varCount:
                group.varCount +
                (edge.node?.actualVsTheoretical?.varPercentage ||
                edge.node?.actualVsTheoretical?.varPercentage === 0
                  ? 1
                  : 0),
            };
          }

          if (filters.displaySubategories) {
            result.push({
              label: edge.node?.ingredient?.inventoryitem?.label,
              invUom: edge.node?.actualVsTheoretical?.measure?.abbreviation,
              actualUsageQty: formatUsage(edge.node?.actualVsTheoretical?.actualUsageQty),
              theoreticalUsage: formatUsage(edge.node?.actualVsTheoretical?.theoreticalUsage),
              variance: formatUsage(edge.node?.actualVsTheoretical?.variance),
              auPercentage: toPercentageForm(edge.node?.actualVsTheoretical?.auPercentage),
              tuPercentage: toPercentageForm(edge.node?.actualVsTheoretical?.tuPercentage),
              varPercentage: toPercentageForm(edge.node?.actualVsTheoretical?.varPercentage),
              [groupById]: edge.node?.ingredient?.inventoryitem?.inventoryitemcategory?.[groupById],
            });
          }
          return result;
        }, []);

      groups.forEach(group => {
        tableData[group.index] = {
          ...tableData[group.index],
          auPercentage: group.auCount ? toPercentageForm(group.auPercentage / group.auCount) : '-',
          tuPercentage: group.tuCount ? toPercentageForm(group.tuPercentage / group.tuCount) : '-',
          varPercentage: group.varCount
            ? toPercentageForm(group.varPercentage / group.varCount)
            : '-',
        };
      });

      return tableData;
    },
    [filters.groupBy, filters.displaySubategories],
  );

  return (
    <PaperMain>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <SelectFactory
            label="Location"
            name="locationId"
            placeholder="Search location..."
            fullWidth
            query={LOCATIONS}
            multiple={false}
            disableSearchReset
            renderCheckBox={false}
            otherVariables={{
              fieldGroupOnly: 95,
            }}
            onSelect={value => {
              handleOnLocationSelect(value);
            }}
            structureData={data =>
              data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                value: edge.node.locationId,
                id: edge.node.locationId,
                index,
                label: `${edge.node?.address?.state?.abbreviation}-${pad(
                  edge.node?.storeNumber || 0,
                  4,
                  '0',
                )} - ${edge.node.label}`,
              }))
            }
          />
        </Grid>
        {location?.id ? (
          <Grid item xs={12}>
            <ConnectionTable
              nested
              alignItemsCenter="flex-start"
              showPagination={false}
              customActionsCenter={(_, { refetch, variables }) => (
                <Filters
                  groupByOptions={groupByOptions}
                  filters={filters}
                  setFilters={setFilters}
                  refetch={refetch}
                  variables={variables}
                />
              )}
              customActionsBottom={(data, { refetch, variables, tableData }) => (
                <Togglers
                  refetch={refetch}
                  variables={variables}
                  filters={filters}
                  setFilters={setFilters}
                  tableData={tableData}
                  locationLabel={location?.label}
                />
              )}
              query={FETCH_ACTUAL_VS_THEORETICAL}
              initialQueryVariables={{
                filter: {
                  locationId: { eq: location?.id },
                },
                sort: {
                  ingredient: {
                    inventoryitem: {
                      inventoryitemcategory: {
                        inventoryitemcategoryId: 'ASC',
                      },
                    },
                  },
                },
                dateRange: {
                  startDate: initialDateRange.gte,
                  endDate: initialDateRange.lte,
                },
              }}
              columns={[
                {
                  title: 'Category / Product',
                  field: 'label',
                  disableSort: true,
                  render: (label, _, row) => (row.isGroupLabel ? <b>{label}</b> : label),
                },
                { title: 'Inv.UOM', field: 'invUom', disableSort: true },
                {
                  title: 'Actual Usage',
                  field: 'actualUsageQty',
                  disableSort: true,
                },
                { title: 'AU %', field: 'auPercentage', disableSort: true },
                {
                  title: 'Theoretical Usage',
                  field: 'theoreticalUsage',
                  disableSort: true,
                },
                { title: 'TU %', field: 'tuPercentage', disableSort: true },
                {
                  title: 'Variance',
                  field: 'variance',
                  disableSort: true,
                },
                { title: 'Var %', field: 'varPercentage', disableSort: true },
              ]}
              structureTable={data => structureTable(data)}
              title="Actual vs Theoretical"
            />
          </Grid>
        ) : null}
      </Grid>
    </PaperMain>
  );
};

export default ActualTheoretical;
