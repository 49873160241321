import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { convert, pad } from '../../../../../../utils';
import moment from 'moment';

// TODO: location connection
const Summary = ({ invoice }) => {
  return (
    <>
      <List style={{ margin: '0 -16px 0' }}>
        <ListItem style={{ background: '#F8F8FA', padding: '8px 16px' }}>
          <Typography variant="h5" style={{ margin: 0, fontWeight: 'bold' }}>
            Summary
          </Typography>
        </ListItem>
      </List>
      <div style={{ marginBottom: '16px' }}>
        <Typography variant="body2">
          Location: {invoice.location?.address?.state?.abbreviation}-
          {pad(invoice.location?.storeNumber || '', 4, '0')} {invoice.location?.label}
        </Typography>
        <Typography variant="body2">Invoice #: {invoice.invoiceId}</Typography>
        <Typography variant="body2">
          Invoice Date: {moment(invoice.invoiceDate).format('MM/DD/YYYY z')}
        </Typography>
      </div>

      <div style={{ marginBottom: '24px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
          <tbody>
            <tr>
              <td
                align="right"
                style={{ width: '50%', border: '1px solid #D3D3DB', padding: '8px 16px' }}
              >
                Subtotal
              </td>
              <td align="center" style={{ border: '1px solid #D3D3DB', padding: '8px 16px' }}>
                $ {convert.centsToDollars(invoice.subtotal)}
              </td>
            </tr>
            <tr>
              <td align="right" style={{ border: '1px solid #D3D3DB', padding: '8px 16px' }}>
                Tax
              </td>
              <td align="center" style={{ border: '1px solid #D3D3DB', padding: '8px 16px' }}>
                $ {convert.centsToDollars(invoice.tax)}
              </td>
            </tr>
            <tr>
              <th align="right" style={{ border: '1px solid #D3D3DB', padding: '8px 16px' }}>
                Total Cost
              </th>
              <th align="center" style={{ border: '1px solid #D3D3DB', padding: '8px 16px' }}>
                $ {convert.centsToDollars(invoice.calculatedTotal)}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Summary;
