import { gql } from '@apollo/client';

const UPDATE_TIMESHIFT = gql`
  mutation UpdateTimeshift(
    $input: UpdateTimeshiftMutationInput!
    $started: String!
    $finished: String!
  ) {
    updateTimeshift(input: $input) {
      timeshift {
        creator {
          id
          firstName
          lastName
        }
        id
        started
        personId
        jobId
        isFinished
        disabled
        created
        totalPaidBreak
        totalUnpaidBreak
        clockedOutBy
        finished
        tip
        wages(started: $started, finished: $finished) {
          error
          fee
        }
        overtime(started: $started, finished: $finished) {
          error
          hours
          type
          fee
        }
        shiftTotal(started: $started, finished: $finished) {
          fee
          error
        }
        personJob {
          salaryType {
            id
            salarytypeId
            label
          }
          salaryAmount
        }
        location {
          id
          timezone {
            id
            label
          }
        }
        job {
          id
          label
          personJobConnection {
            edges {
              node {
                salaryAmount
                salaryType {
                  salarytypeId
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_TIMESHIFT;
