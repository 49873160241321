import { makeStyles } from '@material-ui/core';
const Styles = theme => ({
  divider: {
    width: '100%',
    margin: '1rem 0',
  },
  brand: {
    margin: 'auto 0',
  },
  label: {
    marginBottom: '1.5rem',
  },
  buttons: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
  },
  submitButton: {
    marginLeft: '1rem',
  },
  discardButton: {
    backgroundColor: 'white !important',
    border: '1px solid #e0e0e0',
  },
});

export const LoyaltyEventBrandsStyle = makeStyles(Styles);
