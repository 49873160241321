import React from 'react';
import { Grid, makeStyles, TextField, Button, Container } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import QuoteInfo from '../../../../apollo/mutations/quotecatering/quoteInfo';
import { convert } from '../../../../utils';
import CATERING_ORDER_INFO from '../../../../apollo/queries/caterings/cateringOrderInfo';
import { CurrencyInputFormat } from '../../../../utils';
import CurrencyInput from '../../../blocks/CurrencyInput';
import PercentageInput from '../../../blocks/PercentageInput';
import percentageInputFormat from '../../../../utils/percentageInputFormat';
import Permission from '../../../blocks/Permission';
import PermissionField from '../../../blocks/PermissionField';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../constants';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../hooks/useSnackbar';

const useStyles = makeStyles(theme => ({
  additionalChargeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widthModal: {
    minWidth: '85rem',
    minHeight: '41rem',
  },
  root: {
    display: 'flex',
    boxShadow: 'none',
  },
  title: {
    marginTop: '-1.25rem',
    marginBottom: '1rem',
    background: theme.palette.background.paper,
    padding: '0 0.5rem',
    margin: '0 -0.5rem',
    fontSize: theme.typography.fontSizeL,
  },
}));

const AdditionalInfo = ({ quote, variables, quoteInfoPermission, accessToMutations }) => {
  const classes = useStyles();
  const [setQuoteInfo, { loading: setQuoteInfoLoading }] = useMutation(QuoteInfo);
  const { handleSubmit, control, register, errors } = useForm();
  const { setSnackbar } = useSnackbar();

  const onSubmit = ({ additionalCharge, discountInDollars, discountInPercentage, note }) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Order details are updating.',
    });
    setQuoteInfo({
      variables: {
        input: {
          id: quote?.id,
          personId: quote?.person?.id,
          note,
          additionalCharge: CurrencyInputFormat(additionalCharge) || 0,
          discountInDollars: CurrencyInputFormat(discountInDollars) || 0,
          discountInPercentage: percentageInputFormat(discountInPercentage) / 100 || 0,
        },
      },
      refetchQueries: [
        {
          query: CATERING_ORDER_INFO,
          variables: { ...variables },
        },
      ],
    })
      .then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Order details are updated successfully.',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  return (
    <Container>
      <div className={classes.root}>
        <span>
          <h2 className={classes.title}>Additional info</h2>
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Permission access={quote?.permissions?.note}>
            <Grid item xs={12}>
              <PermissionField
                Component={TextField}
                id="note"
                name={'note'}
                disabled={!quote}
                inputRef={register}
                variant={'outlined'}
                defaultValue={quote?.note || ''}
                label={'Other special instructions'}
                fullWidth
              />
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.additionalCharge}>
            <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
              <div className={classes.additionalChargeContainer} style={{ width: '50%' }}>
                <PermissionField
                  Component={CurrencyInput}
                  control={control}
                  rules={{
                    validate: {
                      positive: value => {
                        const price = CurrencyInputFormat(value);
                        const isPositive = price >= 0;
                        return isPositive;
                      },
                    },
                  }}
                  label={'Service fee'}
                  error={Boolean(errors.additionalCharge)}
                  helperText={Boolean(errors.additionalCharge) && "Service fee can't be negative"}
                  name={'additionalCharge'}
                  disabled={!quote}
                  fullWidth={true}
                  defaultValue={convert.centsToDollars(
                    quote?.additionalCharge?.serviceAndDeliveryFee ?? 0,
                  )}
                />
              </div>
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.discountFraction}>
            <Grid item xs={6} style={{ marginTop: '1.5rem' }}>
              <PermissionField
                Component={PercentageInput}
                name={'discountInPercentage'}
                error={Boolean(errors.discountInPercentage)}
                helperText={Boolean(errors.discountInPercentage) && "Discount can't be negative"}
                control={control}
                label={'Discount in percentage'}
                fullWidth
                defaultValue={quote?.discountFraction * 100}
                disabled={!quote}
              />
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.discountAmount}>
            <Grid item xs={6} style={{ marginTop: '1.5rem' }}>
              <PermissionField
                Component={CurrencyInput}
                control={control}
                rules={{
                  validate: {
                    positive: value => {
                      const price = CurrencyInputFormat(value);
                      const isPositive = price >= 0;
                      return isPositive;
                    },
                  },
                }}
                error={Boolean(errors.discountInDollars)}
                helperText={Boolean(errors.discountInDollars) && "Discount can't be negative"}
                name={'discountInDollars'}
                disabled={!quote}
                fullWidth={true}
                defaultValue={
                  quote?.discountAmount && convert.centsToDollars(quote?.discountAmount)
                }
                variant={'outlined'}
                label={'Discount in dollars'}
              />
            </Grid>
          </Permission>
        </Grid>
        <br />
        <Permission
          access={Math.min(
            quoteInfoPermission?.access,
            accessToMutations?.[MUTATION_NAME.QuoteInfoMutationInput],
          )}
        >
          <div>
            <PermissionField createHelperText>
              <Button type={'submit'} disabled={!quote || setQuoteInfoLoading}>
                Update
              </Button>
            </PermissionField>
          </div>
        </Permission>
      </form>
    </Container>
  );
};

export default AdditionalInfo;
