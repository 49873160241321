import React, { useCallback, useMemo } from 'react';
import FormItems from '../../../../../../blocks/FormItems';
import VendorProduct from './VendorProduct';

const initialVendorProduct = {
  vendor: '',
  vpn: '',
  vendorUOM: '',
  packSize: '',
  invConversion: '',
  isPrimary: false,
  isSplit: false,
  isSub: false,
};

const VendorProductNumbers = ({
  ingredientConnection,
  register,
  errors,
  control,
  measureOptions,
  setValue,
  unregister,
  getValues,
  mainMeasureLabel,
}) => {
  const vendorProducts = useMemo(
    () =>
      ingredientConnection?.edges[0]?.node.vendorproductConnection.edges.map(edge => ({
        ...edge.node,
        packSize: edge.node.packSize
          ? {
              label: edge.node.packSize,
              value: edge.node.packSize,
            }
          : '',
        vendor: edge.node.vendor
          ? {
              label: `${edge.node.vendor.label} - ${edge.node.vendor.inventoryvendorVendorId}`,
              id: edge.node.vendor.inventoryvendorId,
            }
          : '',
      })) || [],
    [ingredientConnection],
  );

  return (
    <FormItems
      title="Vendor Product Numbers"
      addItemButtonText="Add Vendor Products"
      name="vendor-product"
      initialItem={initialVendorProduct}
      items={vendorProducts}
      unregister={unregister}
      control={control}
      getValues={getValues}
      register={register}
      setValue={setValue}
      errors={errors}
      itemComponent={VendorProduct}
      dividerBetweenItems={true}
      dividerAfterItems={false}
      enableRemove
      enableEmpty={false}
      emptyErrorText="At least one Vendor is required"
      itemComponentProps={{
        measureOptions,
        mainMeasureLabel,
      }}
    />
  );
};

export default VendorProductNumbers;
