import React, { useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Select from '../../blocks/Select';
import { pad } from '../../../utils'
import { FormHelperText } from '@material-ui/core';
/*
* Example of usage:
 <Elements.TimePicker
    control={control}
    hoursName="yourNameInForm"
    minutesName="yourNameInForm"
    amPmName="yourNameInForm
 />
props:        'control' - object from 'useFrom()' (@react-hook-form)
              'hoursName' - name that will be used to set field name in a form
              'minutesName' - name that will be used to set field name in a form
              'amPmName' - name that will be used to set field name in a form
NOTE:
    Use 'formatToValidDate' util when getting the value from the form.
    Use 'formatFromValidDate' util when you need to set the value into the form
*/

function getSelectItems({ totalItems, from = 1, padding, hasEmptyValue }) {
  let items = hasEmptyValue ? [{value: 'none', label: '--'}] : [];

  for (let i = from; i <= totalItems; i++) {
    const value = pad(i, padding);
      items.push({ value, label: value});
    }

  return items;
}

function Timepicker({ id, hoursName, minutesName, amPmName, control, defaultValues, fullWidth, disabled, hasEmptyValue, format = 'hh-mm-A', onChange, onClick, helperText, error }) {
  const getTime = useCallback(({ hours, minutes, amPm }) => {
    if ([hours, minutes, amPm].includes('none')) {
      return {
        hours,
        minutes,
        amPm,
        time: null,
      };;
    }

    return {
      hours,
      minutes,
      amPm,
      time: moment(`${hours}-${minutes}-${amPm}`, 'hh-mm-A').format(format)
    };
  }, [format])

  const [time, setTime] = useState(getTime(defaultValues));

  const hours = useMemo(() => getSelectItems({ totalItems: 12, hasEmptyValue }), [hasEmptyValue])
  const minutes = useMemo(() => getSelectItems({ totalItems: 59, from: 0, padding: 2, hasEmptyValue }), [hasEmptyValue])
  const amPmItems = useMemo(() => hasEmptyValue ? [
  {
    value: 'none',
    label: '--'
  },
  {
    value: 'AM',
    label: 'AM'
  },{
    value: 'PM',
    label: 'PM'
  }] : 
  [{
    value: 'AM',
    label: 'AM'
  },{
    value: 'PM',
    label: 'PM'
  }], [hasEmptyValue]);

  const handleTimeChange = useCallback((values) => {
    const newTime = getTime(values);

    setTime(newTime);
    onChange(newTime.time);
  }, [setTime, getTime, onChange])

  return (
    <div onClick={onClick}>
      <Grid container spacing={2} id={id}>
        <Grid item xs={4}>
          <Select 
            disabled={disabled}
            items={hours}
            isMultiple={false} 
            name={hoursName}
            label="Hours"
            id={hoursName}
            control={control}
            defaultValue={defaultValues?.hours}
            fullWidth={fullWidth} 
            onValueChange={(event) => handleTimeChange({...time, hours: event.target.value})}
          />
        </Grid>
        <Grid item xs={4}>
          <Select 
            disabled={disabled}
            items={minutes}
            isMultiple={false} 
            name={minutesName}
            label="Minutes"
            id={minutesName}
            control={control}
            defaultValue={defaultValues?.minutes}
            fullWidth={fullWidth} 
            onValueChange={(event) => handleTimeChange({...time, minutes: event.target.value})}
            />
        </Grid>
        <Grid item xs={4}>
          <Select 
            disabled={disabled}
            items={amPmItems}
            isMultiple={false} 
            name={amPmName}
            label="AM/PM"
            id={amPmName}
            control={control}
            defaultValue={defaultValues?.amPm}
            fullWidth={fullWidth} 
            onValueChange={(event) => handleTimeChange({...time, amPm: event.target.value})}
            />
        </Grid>
      </Grid>
      {helperText && (<FormHelperText error={error}>{helperText}</FormHelperText>)}
    </div>
  );
}

export default Timepicker;
