import React, { useState } from "react";
import ItemsView from "./View";
function Items({ control, coupon, setExcludedItems, excludedItems }) {
  const [showOptions, setShowOptions] = useState(!!coupon.couponItemConnection.edges.length);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleDelete = (itemId) => {
    const newExcluded = excludedItems.filter((item) => item.id !== itemId);
    setExcludedItems(newExcluded);
  };

  return (
    <ItemsView
      control={control}
      showOptions={showOptions}
      setShowOptions={setShowOptions}
      openModal={openModal}
      setOpenModal={setOpenModal}
      handleClose={handleClose}
      handleOpen={handleOpen}
      setExcludedItems={setExcludedItems}
      excludedItems={excludedItems}
      handleDelete={handleDelete}
    />
  );
}

export default Items;
