import React from 'react';
import {
  TextField,
  Button,
  Grid,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Permission, PermissionField, SelectFactory } from '../../../../../blocks';
import { LOCATIONS } from '../../../../../../apollo/queries';
import { compact } from 'lodash';

const JobBreakRuleForm = ({
  title,
  jobBreakRule,
  handleSubmit,
  onSubmit,
  register,
  errors,
  descriptionElementRef,
  buttonDisabled,
  permissions,
  open,
  handleClose,
  mutationPermission,
  locationIds,
  setLocationIds,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={8}>
                      <Grid container spacing={3}>
                        <Permission access={permissions?.ruleName}>
                          <Grid item xs={12}>
                            <PermissionField
                              Component={TextField}
                              name="ruleName"
                              label="Rule Name"
                              type="text"
                              inputRef={register({ required: true })}
                              fullWidth
                              error={!!errors.ruleName}
                              helperText={errors.ruleName && 'Rule name is a required field.'}
                            />
                          </Grid>
                        </Permission>
                        <Grid item xs={12}>
                          Paid Break
                      </Grid>
                        <Permission
                          access={permissions?.paidBreakMaxDuration}
                        >
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="paidBreakMaxDuration"
                              label="Max Duration (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.paidBreakMaxDuration}
                              helperText={errors.paidBreakMaxDuration && "Max duration is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission
                          access={permissions?.paidBreakMinDuration}
                        >
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="paidBreakMinDuration"
                              label="Min Duration (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.paidBreakMinDuration}
                              helperText={errors.paidBreakMinDuration && "Min duration is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission access={permissions?.paidBreakInterval}>
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="paidBreakInterval"
                              label="Interval duration (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.paidBreakInterval}
                              helperText={errors.paidBreakInterval && "Interval duration is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission
                          access={permissions?.paidBreakStartOffset}
                        >
                         <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="paidBreakStartOffset"
                              label="Start Offset (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth 
                              error={!!errors.paidBreakStartOffset}
                              helperText={errors.paidBreakStartOffset && "Start offset is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission
                          access={permissions?.paidBreakIntervalBetweenBreaks}
                        >
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="paidBreakIntervalBetweenBreaks"
                              label="Interval between breaks (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.paidBreakIntervalBetweenBreaks}
                              helperText={errors.paidBreakIntervalBetweenBreaks && "Interval between breaks is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Grid item xs={12}>
                          <FormGroup row>
                            <Permission
                              access={permissions?.requiresManagerApproval}
                            >
                              <div>
                                <PermissionField
                                  Component={FormControlLabel}
                                  label="Breaks require approval"
                                  createHelperText
                                  control={
                                    <Switch
                                      defaultChecked={jobBreakRule?.paidBreakRequiresApproval}
                                      inputRef={register}
                                      name="paidBreakRequiresApproval"
                                    />
                                  }
                                />
                              </div>
                            </Permission>
                            <Permission
                              access={permissions?.paidBreakFlipsToUnpaid}
                            >
                              <div>
                                <PermissionField 
                                  Component={FormControlLabel}
                                  createHelperText
                                  label="Paid break flips to unpaid"
                                  control={
                                    <Switch
                                      defaultChecked={jobBreakRule?.paidBreakFlipsToUnpaid}
                                      inputRef={register}
                                      name="paidBreakFlipsToUnpaid"
                                    />
                                  }
                                />
                              </div>
                            </Permission>
                            <Permission
                              access={
                                permissions?.paidBreakManagerApprovalToClockinEarly
                              }
                            >
                              <div>
                                <PermissionField
                                  Component={FormControlLabel}
                                  label="Approval to clock-in early"
                                  createHelperText
                                  control={
                                    <Switch
                                      defaultChecked={jobBreakRule?.paidBreakManagerApprovalToClockinEarly}
                                      inputRef={register}
                                      name="paidBreakManagerApprovalToClockinEarly"
                                    />
                                  }
                                />
                              </div>
                            </Permission>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          Unpaid Break
                        </Grid>
                        <Permission
                          access={permissions?.unpaidBreakMaxDuration}
                        >
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="unpaidBreakMaxDuration"
                              label="Max Duration (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.unpaidBreakMaxDuration}
                              helperText={errors.unpaidBreakMaxDuration && "Max duration is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission
                          access={permissions?.unpaidBreakMinDuration}
                        >
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="unpaidBreakMinDuration"
                              label="Min Duration (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.unpaidBreakMinDuration}
                              helperText={errors.unpaidBreakMinDuration && "Min duration is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission
                          access={permissions?.unpaidBreakStartOffset}
                        >
                         <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="unpaidBreakStartOffset"
                              label="Start Offset (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.unpaidBreakStartOffset}
                              helperText={errors.unpaidBreakStartOffset && "Start offset is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission
                          access={
                            permissions?.unpaidBreakIntervalBetweenBreaks
                          }
                        >
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="unpaidBreakIntervalBetweenBreaks"
                              label="Interval between breaks (in min.)"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.unpaidBreakIntervalBetweenBreaks}
                              helperText={errors.unpaidBreakIntervalBetweenBreaks && "Interval between breaks is a required field, and must be a positive number."}
                            />
                          </Grid>
                        </Permission>
                        <Permission
                          access={
                            permissions?.unpaidBreakManagerApprovalToClockinEarly
                          }
                        >
                          <Grid item xs={12}>
                            <div>
                              <PermissionField
                                Component={FormControlLabel}
                                label="Approval to clock-in early"
                                createHelperText
                                control={
                                  <Switch
                                    defaultChecked={jobBreakRule?.unpaidBreakManagerApprovalToClockinEarly}
                                    inputRef={register}
                                    name="unpaidBreakManagerApprovalToClockinEarly"
                                  />
                                }
                              />
                            </div>
                          </Grid>
                        </Permission>
                      </Grid>
                    </Grid>
                    <Permission access={permissions?.location?.__typename}>
                      <Grid item xs={4}>
                        <PermissionField
                          Component={SelectFactory}
                          label={'Locations'}
                          placeholder={'Select Locations'}
                          query={LOCATIONS}
                          defaultValue={locationIds}
                          disableCloseOnSelect={true}
                          multiple
                          renderCheckBox
                          structureData={(data) => {
                            return data?.viewer?.locationConnection?.edges.map(
                              (location, index) => {
                                return {
                                  label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
                                  locationId: location.node.locationId,
                                  id: location.node.id,
                                  index,
                                };
                              }
                            );
                          }}
                          onSelect={(values) => {
                            setLocationIds([
                              ...locationIds,
                              ...values.map((loc) => loc.locationId),
                            ]);
                          }}
                        />
                      </Grid>
                    </Permission>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Permission access={mutationPermission?.access}>
              <Button disabled={buttonDisabled} type="submit">
                Save
              </Button>
            </Permission>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

JobBreakRuleForm.propTypes = {};

export default JobBreakRuleForm;
