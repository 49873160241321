import { gql } from '@apollo/client';

// Adding brand inside of loyaltyclaimedreceiptConnection
// slows the query down significantly for some reason.
const LOYALTY_CLAIMED_RECEIPT = gql`
  query LoyaltyClaimedReceipt(
    $first: Int
    $last: Int
    $search: String
    $filter: [LoyaltyClaimedReceiptFilter]
    $after: String
    $specificFields: [String]
    $before: String
  ) {
    viewer {
      id
      loyaltyclaimedreceiptConnection(
        filter: $filter
        sort: { created: DESC }
        first: $first
        last: $last
        specificFields: $specificFields
        after: $after
        search: $search
        before: $before
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            dateOfVisit
            loyaltyclaimedreceiptId
            person {
              id
              personId
              login {
                id
                email
              }
            }
            location {
              id
              storeNumber
              address {
                id
                state {
                  id
                  abbreviation
                }
              }
            }
            receiptNumber
            totalAmount
            brandId
            approved
            created
          }
        }
      }
      brandConnection {
        edges {
          node {
            id
            brandId
            label
          }
        }
      }
    }
  }
`;

export default LOYALTY_CLAIMED_RECEIPT;
