import { gql } from "@apollo/client";

export const UPDATE_PRINTERSTATION_SUB_LOC = gql`
  mutation updatePrinterstation($input: UpdatePrinterstationInput!) {
    updatePrinterstation(input: $input) {
      printerstationSubcategoryLocationEdge {
        node {
          locationId
          subcategoryId
          printerstationId
        }
      }
    }
  }
`;