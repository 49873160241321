import AllOrders from "./AllOrders";
import Disputes from "./Disputes";
import DisputedOrderInfo from "./Disputes/DisputedOrderInfo";
import AbandonedCarts from "./AbandonedCarts";

const Orders = {
  AllOrders,
  Disputes,
  AbandonedCarts,
  DisputedOrderInfo,
};

export default Orders;
