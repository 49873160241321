// used for both creating and copying usergroups based on `type` param
import { gql } from "@apollo/client";

const UPDATE_USERGROUP_LABEL = gql`
  mutation updateUsergroupLabel($input: UpdateUsergroupLabelMutationInput!) {
    updateUsergroupLabel(input: $input) {
      usergroup {
        id
      }
    }
  }
`;

export default UPDATE_USERGROUP_LABEL;
