import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FETCH_INVENTORY_VENDORS } from '../../../../../../../../apollo/queries/inventoryVendors/fetchInventoryVendors';
import { VENDOR_PRODUCT_MEASURES } from '../../../../../../../../apollo/queries/vendorProductMeasures';
import { SelectFactory } from '../../../../../../../blocks';
import NumberInput from '../../../../../../../blocks/NumberInput';

const VendorProduct = ({
  id,
  errors,
  register,
  item: vendorProduct,
  control,
  autoFocus,
  unregister,
  measureOptions: propsMeasueOptions = [],
  mainMeasureLabel,
}) => {
  useEffect(() => {
    return () => {
      unregister(`${id}.isPrimary`);
      unregister(`${id}.isSplit`);
      unregister(`${id}.isSub`);
      unregister(id);
    };
  }, [unregister, id]);

  const measureOptions = useMemo(
    () =>
      propsMeasueOptions
        .filter(measure => [16, 18, 19].includes(measure.value))
        .map(measure => ({
          label: measure.abbreviation,
          value: measure.abbreviation,
        })),
    [propsMeasueOptions],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus={autoFocus}
          inputRef={register({ required: true })}
          name={`${id}.vpn`}
          label="VPN"
          fullWidth
          defaultValue={vendorProduct?.vpn}
          placeholder="VPN"
          error={!!errors[id]?.vpn}
          helperText={!!errors[id]?.vpn && 'VPN is required'}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name={`${id}.vendor`}
          defaultValue={vendorProduct.vendor}
          rules={{ required: true }}
          render={props => (
            <SelectFactory
              customProps={props}
              error={!!errors[id]?.vendor}
              helperText={!!errors[id]?.vendor && 'Vendor is required'}
              label={'Vendor'}
              fullWidth
              placeholder={'Select Vendor'}
              query={FETCH_INVENTORY_VENDORS}
              otherVariables={{
                fieldGroupOnly: 89,
              }}
              renderCheckBox={false}
              defaultValue={vendorProduct.vendor}
              disableCloseOnSelect={false}
              multiple={false}
              structureData={data => {
                return data?.viewer?.inventoryvendorConnection?.edges.map(edge => {
                  return {
                    label: `${edge.node.label} - ${edge.node.inventoryvendorVendorId}`,
                    id: edge.node.inventoryvendorId,
                  };
                });
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} sm={3}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography variant="body1">1</Typography>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel error={!!errors[id]?.vendorUOM}>Vendor UOM</InputLabel>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    id={`${id}.vendorUOM`}
                    name={`${id}.vendorUOM`}
                    defaultValue={vendorProduct?.vendorUOM || ''}
                    label="Vendor UOM"
                    placeholder="Vendor UOM"
                    items={measureOptions}
                    error={!!errors[id]?.vendorUOM}
                    as={
                      <Select label="Vendor UOM" multiple={false} error={!!errors[id]?.vendorUOM}>
                        {measureOptions?.map(measure => (
                          <MenuItem key={measure.value} value={measure.value}>
                            {measure.label}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    control={control}
                  />
                  {errors[id]?.vendorUOM?.type === 'required' ? (
                    <FormHelperText error>Measure is required</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography variant="body1">of</Typography>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Controller
                  control={control}
                  name={`${id}.packSize`}
                  defaultValue={vendorProduct.packSize || ''}
                  rules={{ required: true }}
                  render={props => (
                    <SelectFactory
                      customProps={props}
                      error={!!errors[id]?.packSize}
                      helperText={!!errors[id]?.packSize && 'Vendor Pack Size is required'}
                      label={'Vendor Pack Size'}
                      fullWidth
                      placeholder={'Search Pack Sizes'}
                      query={VENDOR_PRODUCT_MEASURES}
                      renderCheckBox={false}
                      defaultValue={vendorProduct.packSize}
                      otherVariables={{
                        fieldGroupOnly: 89,
                      }}
                      disableCloseOnSelect={false}
                      multiple={false}
                      structureData={data => {
                        return (
                          data?.viewer?.vendorproductmeasureConnection?.edges?.map(
                            (edge, index) => {
                              return {
                                index: index + 1,
                                label: edge.node.packSize,
                                id: edge.node.id,
                              };
                            },
                          ) || []
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography variant="body1">=</Typography>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <NumberInput
                  required
                  control={control}
                  defaultValue={vendorProduct?.invConversion}
                  id={`${id}.invConversion`}
                  validations={{ positive: value => value > 0 }}
                  label="Conversion (How Many UOMs are in 1 Vendor Pack)"
                  error={!!errors[id]?.invConversion}
                  helperText={
                    errors[id]?.invConversion?.type === 'required'
                      ? 'Value is required'
                      : errors[id]?.invConversion?.type === 'positive'
                      ? 'Value must be greater than 0'
                      : ''
                  }
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">{mainMeasureLabel}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={6}>
        <Grid item>
          <FormControl
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox
              defaultChecked={vendorProduct?.isPrimary}
              inputRef={register}
              name={`${id}.isPrimary`}
              id={`${id}.invConversion`}
            ></Checkbox>
            <FormLabel>Primary</FormLabel>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox
              defaultChecked={vendorProduct?.isSplit}
              inputRef={register}
              name={`${id}.isSplit`}
              id={`${id}.isSplit`}
            ></Checkbox>
            <FormLabel>Split</FormLabel>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox
              defaultChecked={vendorProduct?.isSub}
              inputRef={register}
              name={`${id}.isSub`}
              id={`${id}.isSub`}
            ></Checkbox>
            <FormLabel>Sub</FormLabel>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VendorProduct;
