import React from 'react';
import { Grid } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { HouseAccountPaymentStyles } from './styles';
import { Permission } from '../../../../../../blocks';
import Payment from '../../../../../../blocks/Payment';
import { stripePromise } from '../../../../../../../App';
import {
  convertHouseAccountValueForFrontend,
  getAvailableCredit,
} from '../../../../../../../utils/houseAccountUtils';
import { noLimitLabel } from '../../../../../../../constants/houseAccounts';

export default function PayHouseAccountModal({
  houseAccountData,
  createHouseAccountDepositPermission,
  removeCreditCardPermission,
  permissions,
  accessToMutations,
  handleCloseModal,
}) {
  const classes = HouseAccountPaymentStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={4}>
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Credit Limit</p>
              </div>
              <div className={classes.rowValue}>
                <Permission access={permissions.limit}>
                  {houseAccountData.limit || houseAccountData.limit === 0
                    ? `$${convertHouseAccountValueForFrontend(houseAccountData.limit)}`
                    : noLimitLabel}
                </Permission>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Account Balance</p>
              </div>
              <div className={classes.rowValue}>
                <Permission access={permissions.balance}>
                  ${convertHouseAccountValueForFrontend(houseAccountData.balance)}
                </Permission>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Available Credit</p>
              </div>
              <div className={classes.rowValue}>
                <Permission access={Math.min(permissions.limit, permissions.balance)}>
                  {houseAccountData.limit || houseAccountData.limit === 0
                    ? `$${getAvailableCredit(houseAccountData.limit, houseAccountData.balance)}`
                    : noLimitLabel}
                </Permission>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Elements stripe={stripePromise}>
          <Payment
            quote={{
              person: { ...houseAccountData.login.person },
              dueAmount: Math.abs(houseAccountData.balance),
            }}
            handleClose={handleCloseModal}
            paymentType={'houseAccount'}
            houseAccount={houseAccountData}
            payPermission={createHouseAccountDepositPermission}
            removeCreditCardPermission={removeCreditCardPermission}
            accessToMutations={accessToMutations}
          />
        </Elements>
      </Grid>
    </Grid>
  );
}
