import { gql } from '@apollo/client';

export const TypeRefFragment = `
  fragment TypeRef on __Type {
    kind
    name
    ofType {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const InputValueFragment = `
  fragment InputValue on __InputValue {
    name
    type { ...TypeRef }
    defaultValue
  }
  ${TypeRefFragment}
`;

export const FullTypeFragment = gql`
  fragment FullType on __Type {
    kind
    name
    fields(includeDeprecated: false) {
      name
      type {
        ...TypeRef
      }
    }
    inputFields {
      ...InputValue
    }
    interfaces {
     ...TypeRef
    }
    enumValues(includeDeprecated: false) {
      name
    }
    possibleTypes {
      ...TypeRef
    }
  }
  ${InputValueFragment}
  ${TypeRefFragment}
`;

const FETCH_INTROSPECTION = gql`
  query IntrospectionQuery {
    __schema {
      types {
        ...FullType
      }
    }
  }
  ${FullTypeFragment}
`

export default FETCH_INTROSPECTION;
