import React, { useState } from 'react';
import { Elements } from '../../../../../../blocks';
import Manual from '../Manual';
import Automatic from '../Automatic';
import { Controller } from 'react-hook-form';
const options = [
  { value: 'manual', label: 'Manual' },
  { value: 'automatic', label: 'Automatic' },
];

const codeGenerationTypes = [
  { value: 1, label: 'Single entry code' },
  { value: 6, label: 'Serialized codes' },
];

function TriggerEventsView({
  control,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  itemInCart,
  setItemInCart,
  triggers,
  errors,
  forEachItem,
  setForEachItem,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
  selectedCategory,
  setSelectedCategory,
}) {
  const [triggerEvent, setTriggerEvent] = useState('all');
  const [codeGenerationType, setCodeGenerationType] = useState(1);

  const handleToggleClick = value => {
    setTriggerEvent(value);
  };

  const switchCase = () => {
    switch (triggerEvent) {
      case 'manual':
        return <Manual errors={errors} control={control} codeGenerationType={codeGenerationType} />;
      case 'automatic':
        return (
          <Automatic
            errors={errors}
            triggers={triggers}
            control={control}
            cartItemsRequired={cartItemsRequired}
            setCartItemsRequired={setCartItemsRequired}
            itemAddedNumberTimes={itemAddedNumberTimes}
            forEachItem={forEachItem}
            setForEachItem={setForEachItem}
            itemInCart={itemInCart}
            setItemInCart={setItemInCart}
            setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
            selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
            codeGenerationType={codeGenerationType}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        );
      case 'all':
        return (
          <div>
            <Manual errors={errors} control={control} codeGenerationType={codeGenerationType} />
            <br />
            <Automatic
              errors={errors}
              triggers={triggers}
              control={control}
              cartItemsRequired={cartItemsRequired}
              setCartItemsRequired={setCartItemsRequired}
              itemAddedNumberTimes={itemAddedNumberTimes}
              forEachItem={forEachItem}
              setForEachItem={setForEachItem}
              itemInCart={itemInCart}
              setItemInCart={setItemInCart}
              setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
              selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
              codeGenerationType={codeGenerationType}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        );
      default:
        return <Manual codeGenerationType={codeGenerationType} />;
    }
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h2 id="triggerEvents" style={{ marginTop: '30px' }}>
          Trigger events
        </h2>
        <Controller
          name="couponTypeId"
          control={control}
          render={({ onChange }) => (
            <Elements.ToggleButton
              style={{ margin: '1rem 1rem 1rem 0' }}
              options={codeGenerationTypes}
              value={codeGenerationType}
              handleToggleClick={value => {
                onChange(value);
                setCodeGenerationType(value);
              }}
            />
          )}
        />
        {/* <Elements.ToggleButton
          value={triggerEvent}
          options={options}
          handleToggleClick={handleToggleClick}
        /> */}
      </div>
      {switchCase()}
    </>
  );
}

export default TriggerEventsView;
