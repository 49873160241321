import { gql } from '@apollo/client';

const UPDATE_INVENTORY_ITEM_CATEGORY = gql`
  mutation UPDATE_INVENTORY_ITEM_CATEGORY($input: UpdateinventoryitemcategoryInput!) {
    updateInventoryitemcategory(input: $input) {
      viewer {
        id
      }
      inventoryitemcategory {
        id
        active
        inventoryitemcategoryId
        label
        inventoryitemcategorytype {
          id
          inventoryitemcategorytypeId
          label
        }
        created
        inventoryitemcategoryId
        generalledgeraccountId
        reportcategoryId
        generalledgeraccount {
          id
          generalLedgerAccountId
          label
        }
        reportcategory {
          id
          reportcategoryId
          label
        }
      }
    }
  }
`;

export default UPDATE_INVENTORY_ITEM_CATEGORY;
