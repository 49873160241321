import { gql } from "@apollo/client";

const CREATE_TEST_PRINT = gql`
  mutation createTestPrint(
    $input: CreateTestPrintMutationInput!
  ) {
    createTestPrint(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default CREATE_TEST_PRINT;
