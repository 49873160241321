import { gql } from '@apollo/client';

const UPDATE_SUBCATEGORY = gql`
  mutation updateSubcategory($input: UpdateSubcategoryMutationInput!) {
    updateSubcategory(input: $input) {
      clientMutationId
    }
  }
`;

export default UPDATE_SUBCATEGORY;
