import { gql } from '@apollo/client';

const FETCH_COST_OF_GOODS = gql`
  query FETCH_COST_OF_GOODS($storeNumber: Int!, $dateRange: CostOfGoodsDateRangeInput!) {
    viewer {
      id
      reportcategoryConnection {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            label
            creatorId
            reports(storeNumber: $storeNumber, dateRange: $dateRange) {
              costOfGoods
            }
          }
        }
      }
    }
  }
`;

export default FETCH_COST_OF_GOODS;
