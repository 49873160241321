import { gql } from "@apollo/client";

const UPDATE_POS_ACCESS_ROLE = gql`
  mutation updatePosRole(
    $input: updatePosRoleInput!
  ) {
    updatePosRole(input: $input) {
      clientMutationId
    }
  }
`;

export default UPDATE_POS_ACCESS_ROLE;
