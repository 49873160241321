import { Button, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ChoiceIngredient from './choiceIngredient';

function ItemChoiceTree({
  itemchildren,
  itemId,
  refetch,
  setDisabledSave,
  disabledSave,
}) {
  const [myKids, setMyKids] = useState(
    itemchildren.ingredients.edges.filter(i => i.node.itemId === +itemId).map(ing => ing.node),
  );
  const [defaultKids, setDefaultKids] = useState(
    itemchildren.ingredients.edges.filter(i => !i.node.itemId).map(ing => ing.node),
  );
  const [lastSubmitId, setLastSubmitId] = useState(null);

  useEffect(() => {
    const nonSubmittedKids = myKids.filter(k => (k.new || k.reused) && k.id !== lastSubmitId);
    setMyKids([
      ...itemchildren.ingredients.edges
        .filter(i => i.node.itemId === +itemId)
        .map(ing => ing.node),
      ...nonSubmittedKids,
    ]);
    setDefaultKids(itemchildren.ingredients.edges.filter(i => !i.node.itemId).map(ing => ing.node));
  }, [itemchildren.ingredients.edges]);

  const handleRemove = id => {
    const copy = myKids.filter(k => k.id !== id);
    setMyKids(copy);
  };

  const handleReuse = ing => {
    let copy = { ...ing };
    delete copy.choiceIngredientId;
    copy.id = Math.random()
      .toString(16)
      .slice(2);
    copy.reused = true;
    setMyKids([...myKids, copy]);
  };

  const handleAddNew = () => {
    setMyKids([
      ...myKids,
      {
        id: Math.random()
          .toString(16)
          .slice(2),
        new: true,
      },
    ]);
  };

  return (
    <div>
      <Grid container spacing={2} onClick={e => e.stopPropagation()}>
        <Grid item xs={2}>
          <h4>{itemchildren.title}</h4>
        </Grid>
      </Grid>
      <div>
        <p>
          Choice ingredients (all items){' '}
          {myKids.length ? '- NOT IN USE' : <strong> - IN USE</strong>}
        </p>
        <div style={{ marginLeft: '2rem' }}>
          {defaultKids.map(defaultKid => {
            return (
              <ChoiceIngredient
                choiceId={itemchildren.choiceId}
                node={defaultKid}
                myKids={myKids}
                refetch={refetch}
                setDisabledSave={setDisabledSave}
                disabledSave={disabledSave}
                reusable={true}
                handleReuse={handleReuse}
              />
            );
          })}
        </div>
      </div>
      <div>
        <hr />
        <div>
          <p>
            Choice ingredients (this item only) {myKids.length ? <strong>- IN USE </strong> : ''}
          </p>
          <div style={{ marginLeft: '2rem' }}>
            {myKids.map(myKid => {
              return (
                <ChoiceIngredient
                  key={myKid.id}
                  itemId={itemId}
                  choiceId={itemchildren.choiceId}
                  node={myKid}
                  refetch={refetch}
                  setDisabledSave={setDisabledSave}
                  myKids={myKids}
                  disabledSave={disabledSave}
                  handleRemove={handleRemove}
                  setLastSubmitId={setLastSubmitId}
                />
              );
            })}
          </div>
        </div>
        <div>
          <Button style={{ marginTop: '1rem' }} onClick={handleAddNew}>
            + Add Ingredients{' '}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ItemChoiceTree;
