import { gql } from '@apollo/client';

const REMOVE_SUBCATEGORY_FROM_TREE = gql`
  mutation RemoveSubcategoryFromTree($input: RemoveSubcategoryFromTreeMutationInput!) {
    removeSubcategoryFromTree(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default REMOVE_SUBCATEGORY_FROM_TREE;
