import React, { useEffect, useState } from 'react';
import { Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import LOCATION_MULTIPLE_CHOICE from '../../../../../../../../apollo/queries/caterings/locationMultipleChoice';
import { useQuery } from '@apollo/client';
import { CurrencyInputFormat } from '../../../../../../../../utils';
import { CurrencyInput, Permission, PermissionField } from '../../../../../../../blocks';

import { ChoiceSelectedListStyles } from './styles';
import { Controller } from 'react-hook-form';

const ChoiceSelectedList = ({
  label,
  errors,
  control,
  fromLine,
  setMultipleChoicesQuantity,
  cost,
  id,
  multipleId,
  allowedMaximum,
  defaultQuantity,
  lineNumber,
  locationId,
  register,
  permissions,
  setValue,
}) => {
  const [quantity, setQuantity] = useState(defaultQuantity);
  const classes = ChoiceSelectedListStyles();
  const { data, error, loading } = useQuery(LOCATION_MULTIPLE_CHOICE, {
    variables: {
      filter: {
        locationId: {
          eq: locationId?.value ? atob(locationId?.value)?.split(':')?.[1] : null,
        },
        choiceId: {
          eq: id && atob(id)?.split(':')?.[1],
        },
        multipleId: {
          eq: multipleId && atob(multipleId)?.split(':')?.[1],
        },
      },
    },
  });
  if (loading) <div>Loading</div>;

  return (
    <Grid item xs={12} container spacing={2} className={classes.root}>
      <Grid item xs={2} alignItems={'center'}>
        <p style={{ fontSize: '0.75rem' }}>{label}</p>
      </Grid>
      <Permission
        access={
          permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges?.node?.price
        }
      >
        <Grid item xs={4} alignItems={'center'}>
          <div style={{ width: '100%' }}>
            {cost !== undefined && (
              <PermissionField
                Component={CurrencyInput}
                control={control}
                rules={{
                  validate: {
                    positive: value => {
                      const price = CurrencyInputFormat(value);
                      const isPositive = price >= 0;
                      return isPositive;
                    },
                  },
                }}
                label={'Price'}
                error={
                  errors && errors[`${lineNumber}-selected-multipleId-${multipleId}.${id}.price`]
                }
                helperText={
                  errors &&
                  errors[`${lineNumber}-selected-multipleId-${multipleId}.${id}.price`] &&
                  "Price can't be negative"
                }
                name={`${lineNumber}-selected-multipleId-${multipleId}.${id}.price`}
                defaultValue={cost}
              />
            )}
          </div>
        </Grid>
      </Permission>
      <Permission
        access={
          permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges?.node?.quantity
        }
      >
        <Grid item xs={4} alignItems={'center'}>
          <Controller
            name={`${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`}
            id={`${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`}
            control={control}
            defaultValue={defaultQuantity}
            rules={{
              required: 'Required',
              validate: {
                positive: value => {
                  const isPositive = parseInt(value) > 0;
                  return isPositive;
                },
              },
            }}
            render={({ onChange, value, ref }) => (
              <PermissionField
                Component={TextField}
                inputRef={ref}
                variant={'outlined'}
                type={'number'}
                id={`${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`}
                defaultValue={defaultQuantity}
                fullWidth
                onChange={e => {
                  setQuantity(() => {
                    setMultipleChoicesQuantity(prev => ({
                      ...prev,
                      [`${lineNumber}-selected-multipleId-${multipleId}-${id}`]:
                        parseInt(value) || 0,
                    }));
                    onChange(e);
                    return parseInt(value);
                  });
                }}
                name={`${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`}
                label={'Quantity'}
              />
            )}
            error={
              errors &&
              ((errors[`${lineNumber}-selected-multipleId-${multipleId}`] &&
                errors[`${lineNumber}-selected-multipleId-${multipleId}`][id]?.quantity?.type ===
                  'max') ||
                (errors[`${lineNumber}-selected-multipleId-${multipleId}`] &&
                  errors[`${lineNumber}-selected-multipleId-${multipleId}`][id]?.quantity?.type ===
                    'positive'))
            }
            helperText={
              errors &&
              ((errors[`${lineNumber}-selected-multipleId-${multipleId}`] &&
                errors[`${lineNumber}-selected-multipleId-${multipleId}`][id]?.quantity?.type ===
                  'max' &&
                `You can choose maximum of ${allowedMaximum} ${label}`) ||
                (errors &&
                  errors[`${lineNumber}-selected-multipleId-${multipleId}`] &&
                  errors[`${lineNumber}-selected-multipleId-${multipleId}`][id]?.quantity?.type ===
                    'positive' &&
                  `Quantity can't be negative or 0.`))
            }
          />
        </Grid>
        <Grid item xs={2} alignItems={'center'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <PermissionField
              createHelperText
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <PermissionField
                Component={IconButton}
                variant="contained"
                style={{ padding: 0 }}
                onClick={() =>
                  setQuantity(prevState => {
                    setMultipleChoicesQuantity(prevState => ({
                      ...prevState,
                      [`${lineNumber}-selected-multipleId-${multipleId}-${id}`]: quantity - 1,
                    }));
                    document.getElementById(
                      `${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`,
                    ).value = prevState - 1;
                    setValue(
                      `${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`,
                      prevState - 1,
                    );
                    return prevState - 1;
                  })
                }
              >
                <RemoveCircleOutlineIcon className={classes.colorSubtrack} />
              </PermissionField>
              <PermissionField Component={IconButton} variant="contained" style={{ padding: 0 }}>
                <AddCircleOutlineIcon
                  className={classes.colorAdd}
                  onClick={() =>
                    setQuantity(prevState => {
                      setMultipleChoicesQuantity(prevState => ({
                        ...prevState,
                        [`${lineNumber}-selected-multipleId-${multipleId}-${id}`]: quantity + 1,
                      }));
                      document.getElementById(
                        `${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`,
                      ).value = prevState + 1;
                      setValue(
                        `${lineNumber}-selected-multipleId-${multipleId}.${id}.quantity`,
                        prevState + 1,
                      );
                      return prevState + 1;
                    })
                  }
                />
              </PermissionField>
            </PermissionField>
          </div>
        </Grid>
      </Permission>
      <Grid item xs={12} lg={3}>
        {errors &&
          errors[`${lineNumber}-selected-multipleId-${multipleId}`] &&
          errors[`${lineNumber}-selected-multipleId-${multipleId}`][id]?.quantity?.type ===
            'max' && (
            <span
              style={{
                color: 'red',
                fontSize: '0.75rem',
              }}
            >{`You can choose maximum of ${allowedMaximum} ${label}`}</span>
          )}
        {errors &&
          errors[`${lineNumber}-selected-multipleId-${multipleId}`] &&
          errors[`${lineNumber}-selected-multipleId-${multipleId}`][id]?.quantity?.type ===
            'positive' && (
            <span
              style={{
                color: 'red',
                fontSize: '0.75rem',
              }}
            >{`Quantity can't be negative or 0.`}</span>
          )}
      </Grid>
    </Grid>
  );
};

export default ChoiceSelectedList;
