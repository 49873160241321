import { SvgIcon } from '@material-ui/core';

const OrderTracker6 = () => (
  <SvgIcon
    style={{ width: '100%', height: '100%' }}
    viewBox="0 0 90 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m20.31,25.14H4.91c-.42,0-.81-.16-1.1-.46s-.46-.69-.46-1.1.16-.81.46-1.1.69-.46,1.1-.46h15.4c.42,0,.81.16,1.11.46s.46.69.46,1.1-.16.81-.46,1.1-.69.46-1.1.46Zm-15.4-2.12c-.15,0-.29.06-.4.16s-.16.25-.16.4.06.29.16.4.25.16.4.16h15.4c.15,0,.29-.06.4-.16s.16-.25.16-.4-.06-.29-.16-.4-.25-.16-.4-.16H4.91Z"
      fill="#747480"
    />
    <path
      d="m24.56,20.36h-12.21c-.42,0-.81-.16-1.1-.46s-.46-.69-.46-1.1.17-.81.46-1.1c.3-.29.69-.46,1.1-.46h12.21c.42,0,.81.16,1.1.46.29.29.46.69.46,1.1s-.16.81-.46,1.1-.69.46-1.1.46Zm-12.21-2.12c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4.25.17.4.17h12.21c.15,0,.29-.06.4-.17s.16-.25.16-.4-.06-.29-.16-.4c-.11-.11-.25-.16-.4-.16h-12.21Z"
      fill="#747480"
    />
    <path
      d="m23.49,29.39h-12.21c-.42,0-.81-.16-1.1-.46s-.46-.69-.46-1.1.16-.81.46-1.1.69-.46,1.1-.46h12.21c.42,0,.81.16,1.1.46s.46.69.46,1.1-.16.81-.46,1.1-.69.46-1.1.46Zm-12.21-2.12c-.15,0-.29.06-.4.16s-.16.25-.16.4.06.29.16.4.25.16.4.16h12.21c.15,0,.29-.06.4-.16s.16-.25.16-.4-.06-.29-.16-.4-.25-.16-.4-.16h-12.21Z"
      fill="#747480"
    />
    <g>
      <path
        d="m41.91,40.5h-.18c-1.07,0-2.1.31-2.99.91-.89.59-1.58,1.43-1.99,2.42-.41.99-.52,2.07-.31,3.12.21,1.05.72,2,1.47,2.76.76.76,1.71,1.27,2.76,1.48,1.05.21,2.13.11,3.12-.3.99-.41,1.83-1.09,2.42-1.98.59-.89.91-1.92.91-3,0-1.45-.56-2.8-1.58-3.82-.98-.97-2.26-1.53-3.63-1.58Zm3.01,6.7c-.17.42-.42.8-.74,1.12-.32.32-.7.57-1.12.75-.42.17-.86.26-1.32.26-.7,0-1.36-.2-1.93-.58-.57-.38-1.01-.92-1.27-1.55-.26-.63-.33-1.32-.2-1.99.13-.67.46-1.28.94-1.77.48-.48,1.09-.81,1.77-.94.67-.13,1.36-.07,1.99.2.63.26,1.17.7,1.55,1.27.38.57.58,1.23.58,1.92,0,.46-.09.9-.26,1.32Z"
        fill="#e7e8ef"
      />
      <path
        d="m72.74,40.5h-.18c-1.07,0-2.1.31-2.99.91-.89.59-1.58,1.43-1.99,2.42-.41.99-.52,2.07-.31,3.12.21,1.05.72,2.01,1.47,2.76.76.76,1.71,1.27,2.76,1.48,1.05.21,2.13.11,3.12-.3.99-.41,1.83-1.09,2.42-1.98.59-.89.91-1.92.91-3,0-1.45-.56-2.8-1.58-3.82-.97-.97-2.26-1.53-3.63-1.58Zm3.26,5.38c0,.92-.36,1.79-1,2.44-.65.65-1.51,1.01-2.43,1.01h-.01c-.68,0-1.34-.2-1.91-.57-.57-.38-1.01-.91-1.28-1.54s-.33-1.32-.21-1.99c.13-.67.46-1.28.94-1.77.48-.49,1.09-.81,1.76-.95.67-.13,1.36-.07,1.99.19.63.26,1.17.7,1.55,1.26.38.57.59,1.23.59,1.91h0Z"
        fill="#e7e8ef"
      />
      <path
        d="m85.4,30.73c-.17-.4-.41-.76-.72-1.07l-11.16-11.04c-.62-.62-1.44-.95-2.31-.96h-6.13v-2.6c0-.63-.25-1.21-.69-1.66-.44-.44-1.04-.68-1.65-.68H30.22c-.33-.01-.61.06-.89.18-.29.12-.54.29-.76.5-.22.22-.39.47-.51.76-.12.28-.18.58-.18.89v26.88c0,.62.25,1.22.69,1.66.44.44,1.04.69,1.65.69h3.39c.36-1.8,1.31-3.44,2.71-4.65,1.51-1.3,3.43-2.02,5.42-2.02s3.92.72,5.42,2.02c1.4,1.21,2.35,2.85,2.71,4.65h14.56c.36-1.8,1.31-3.44,2.71-4.65,1.51-1.3,3.43-2.02,5.42-2.02s3.92.72,5.42,2.02c1.4,1.21,2.35,2.84,2.71,4.65h2.62c.62,0,1.22-.25,1.66-.69.44-.44.69-1.03.69-1.66v-9.93c0-.44-.09-.86-.25-1.27Zm-4.35-1.16c-.07.18-.19.33-.35.43-.15.1-.34.16-.52.16h-10.82c-.25,0-.49-.1-.66-.27-.18-.17-.27-.41-.28-.66v-8.35c0-.24.1-.48.27-.66.18-.18.41-.28.66-.28h2.07c.24,0,.46.09.64.23l8.76,8.37c.14.13.23.29.27.48.04.18.03.37-.04.55Z"
        fill="#e7e8ef"
      />
      <path
        d="m80.82,28.55l-8.76-8.37c-.17-.15-.4-.23-.64-.23h-2.07c-.25,0-.48.1-.66.28-.17.18-.27.42-.27.66v8.35c0,.25.1.48.28.66.18.18.41.27.66.27h10.82c.19,0,.37-.06.52-.16.16-.11.28-.26.35-.43.07-.18.08-.37.04-.55-.04-.19-.13-.35-.27-.48Zm-11.4.68l-.07-8.29,2.04-.02,8.64,8.24-10.61.06Z"
        fill="#747481"
      />
      <path
        d="m86.32,30.34c-.22-.53-.54-1-.94-1.4l-11.16-11.04c-.8-.8-1.87-1.25-3.01-1.25h-5.13v-1.61c0-.89-.35-1.73-.98-2.36-.62-.62-1.48-.98-2.36-.98H30.21c-.44,0-.86.08-1.27.25-.41.17-.77.41-1.08.72-.31.31-.56.67-.72,1.08-.17.41-.26.84-.26,1.28v26.88c0,.89.35,1.73.98,2.36.62.62,1.48.98,2.36.98h4.24l.06-.43c.25-1.73,1.12-3.32,2.44-4.46s3.02-1.78,4.77-1.78,3.44.63,4.77,1.78c1.32,1.15,2.19,2.73,2.44,4.46l.06.43h16.27l.06-.43c.25-1.73,1.12-3.32,2.45-4.46,1.33-1.15,3.02-1.78,4.77-1.78s3.45.63,4.77,1.78c1.33,1.15,2.19,2.73,2.45,4.46l.06.43h3.47c.88,0,1.74-.36,2.36-.98s.98-1.47.98-2.36v-9.93c0-.57-.11-1.13-.33-1.65Zm-1.36,13.24c-.44.44-1.04.69-1.66.69h-2.62c-.36-1.8-1.31-3.44-2.71-4.65-1.51-1.3-3.43-2.02-5.42-2.02s-3.92.72-5.42,2.02c-1.4,1.21-2.35,2.84-2.71,4.65h-14.56c-.36-1.8-1.31-3.44-2.71-4.65-1.51-1.3-3.43-2.02-5.42-2.02s-3.92.72-5.42,2.02c-1.4,1.21-2.35,2.84-2.71,4.65h-3.39c-.62,0-1.22-.25-1.65-.69-.44-.44-.69-1.04-.69-1.66V15.05c0-.31.06-.61.18-.89.12-.28.29-.54.51-.76.22-.22.47-.39.76-.5.28-.12.56-.19.89-.18h32.52c.62,0,1.22.25,1.65.68.44.44.69,1.03.69,1.66v2.6h6.13c.87,0,1.69.34,2.31.96l11.16,11.04c.31.31.55.67.72,1.07.17.4.25.83.25,1.27v9.93c0,.63-.24,1.21-.68,1.66Z"
        fill="#747480"
      />
      <path
        d="m72.57,39.5h-.5v.02c-1.09.08-2.14.44-3.05,1.05-1.06.7-1.87,1.69-2.36,2.87-.49,1.17-.61,2.45-.37,3.69.25,1.25.85,2.38,1.75,3.27.9.9,2.03,1.5,3.27,1.75.42.08.84.13,1.26.13.83,0,1.66-.16,2.43-.49,1.17-.48,2.17-1.3,2.87-2.35.71-1.05,1.08-2.28,1.08-3.57,0-1.7-.67-3.31-1.87-4.51-1.2-1.21-2.81-1.87-4.51-1.87Zm4.47,9.4c-.6.89-1.43,1.57-2.42,1.98-.99.41-2.07.51-3.12.3-1.05-.21-2-.72-2.76-1.48-.75-.76-1.26-1.71-1.47-2.76-.21-1.05-.1-2.13.31-3.12.41-.99,1.1-1.82,1.99-2.42.89-.59,1.92-.91,2.99-.91h.18c1.37.05,2.66.6,3.63,1.58,1.02,1.02,1.58,2.37,1.58,3.82,0,1.07-.32,2.11-.91,3Z"
        fill="#747481"
      />
      <path
        d="m73.86,42.69c-.63-.26-1.32-.32-1.99-.19-.67.14-1.28.46-1.76.95-.48.49-.81,1.1-.94,1.77-.13.67-.06,1.36.21,1.99s.71,1.17,1.28,1.54c.57.38,1.23.57,1.91.57h.01c.92,0,1.78-.36,2.43-1.01.65-.65,1-1.52,1-2.44h0c0-.7-.21-1.36-.59-1.93-.38-.57-.92-1-1.55-1.26Zm1.14,3.19c0,.65-.25,1.27-.71,1.73-.46.46-1.07.72-1.73.72-.49,0-.96-.14-1.36-.41-.41-.27-.72-.65-.91-1.1-.19-.45-.24-.94-.14-1.41.09-.48.32-.91.66-1.26.34-.34.78-.58,1.25-.67.16-.03.33-.05.49-.05.32,0,.63.06.92.18.45.18.83.5,1.1.9.27.4.42.87.42,1.36h0Z"
        fill="#747481"
      />
      <path
        d="m43.05,42.7c-.63-.26-1.32-.33-1.99-.2-.67.13-1.28.46-1.77.94-.48.48-.81,1.1-.94,1.77-.13.67-.07,1.36.2,1.99.26.63.7,1.17,1.27,1.55.57.38,1.23.58,1.93.58.45,0,.9-.09,1.32-.26.42-.17.79-.43,1.12-.75.32-.32.57-.7.74-1.12.17-.42.26-.86.26-1.32,0-.68-.2-1.35-.58-1.92-.38-.57-.92-1.01-1.55-1.27Zm.95,4.12c-.12.3-.3.56-.53.79-.23.23-.49.41-.79.53-.73.31-1.62.23-2.31-.22-.4-.27-.72-.65-.9-1.1-.19-.45-.23-.94-.14-1.41s.33-.91.67-1.25.78-.58,1.25-.67c.16-.03.32-.05.48-.05.32,0,.64.06.94.19.45.19.83.5,1.1.9.27.41.41.88.41,1.36,0,.32-.06.64-.18.94Z"
        fill="#747481"
      />
      <path
        d="m41.73,39.5h-.36s0,.01,0,.01c-1.14.06-2.23.43-3.19,1.06-1.06.7-1.87,1.7-2.36,2.87-.49,1.17-.61,2.45-.37,3.69.25,1.25.85,2.38,1.75,3.27.9.9,2.03,1.5,3.27,1.75.42.08.84.13,1.26.13.83,0,1.65-.16,2.43-.49,1.17-.48,2.17-1.3,2.87-2.35.71-1.05,1.08-2.28,1.08-3.57,0-1.7-.67-3.31-1.87-4.51-1.21-1.21-2.81-1.87-4.51-1.87Zm4.47,9.4c-.6.89-1.43,1.57-2.42,1.98-.99.41-2.06.51-3.12.3-1.05-.21-2-.72-2.76-1.48-.76-.76-1.26-1.71-1.47-2.76-.21-1.05-.1-2.13.31-3.12.41-.99,1.1-1.82,1.99-2.42.89-.59,1.92-.91,2.99-.91h.18c1.37.05,2.65.6,3.63,1.58,1.02,1.02,1.58,2.37,1.58,3.82,0,1.07-.32,2.11-.91,3Z"
        fill="#747481"
      />
    </g>
    <path
      d="m24.56,18.24h-12.21c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4.25.17.4.17h12.21c.15,0,.29-.06.4-.17s.16-.25.16-.4-.06-.29-.16-.4c-.11-.11-.25-.16-.4-.16Z"
      fill="#e7e8ef"
    />
    <path
      d="m20.71,23.98c.11-.11.16-.25.16-.4s-.06-.29-.16-.4-.25-.16-.4-.16H4.91c-.15,0-.29.06-.4.16s-.16.25-.16.4.06.29.16.4.25.16.4.16h15.4c.15,0,.29-.06.4-.16Z"
      fill="#e7e8ef"
    />
    <path
      d="m23.49,27.27h-12.21c-.15,0-.29.06-.4.16s-.16.25-.16.4.06.29.16.4.25.16.4.16h12.21c.15,0,.29-.06.4-.16s.16-.25.16-.4-.06-.29-.16-.4-.25-.16-.4-.16Z"
      fill="#e7e8ef"
    />
  </SvgIcon>
);

export default OrderTracker6;
