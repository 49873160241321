import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  InputLabel,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FETCH_STORAGE_AREAS } from '../../../../../apollo/queries';
import { ConnectionTable, Modal } from '../../../../blocks';
import StorageAreaForm from './StorageAreaForm';

const statusOptions = [
  { label: 'Active', value: 1, color: '#37C78A' },
  { label: 'Inactive', value: 0, color: '#FF9900' },
];

const StorageAreas = () => {
  const [storageArea, setStorageArea] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setStorageArea();
  }, []);

  const handleOnTableRowClick = useCallback(row => {
    setStorageArea(row);
    setIsModalOpen(true);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.status) {
        delete filters.status;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          status: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        query={FETCH_STORAGE_AREAS}
        title="Storage Areas"
        customActionsTop={() => (
          <Button onClick={() => setIsModalOpen(true)}>New Storage Area</Button>
        )}
        onTableRowClick={handleOnTableRowClick}
        structureTable={data =>
          data?.viewer?.storageConnection?.edges?.map(edge => ({
            storageId: edge?.node?.storageId,
            label: edge?.node?.label,
            status: edge?.node?.status,
            created: moment(edge?.node?.created).format('MM/DD/YYYY z'),
          }))
        }
        columns={[
          {
            enablePropagation: true,
            title: 'ID',
            field: 'storageId',
          },
          {
            enablePropagation: true,
            title: 'Name',
            field: 'label',
          },
          {
            title: 'Status',
            field: 'status',
            enablePropagation: true,
            render: status => (
              <Typography variant="body1" style={{ color: status ? '#37C78A' : '#FF9900' }}>
                <strong>{status ? 'Active' : 'Inactive'}</strong>
              </Typography>
            ),
          },
          {
            title: 'Last Updated',
            field: 'created',
            enablePropagation: true,
          },
        ]}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={1} justify="flex-end">
            <Grid xs={12} sm={6} md={4} lg={2}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      />
      <Modal
        handleClose={handleCloseModal}
        open={isModalOpen}
        title={storageArea?.storageAreaId ? 'Update Storage Area' : 'New Storage Area'}
      >
        <StorageAreaForm
          statusOptions={statusOptions}
          handleCloseModal={handleCloseModal}
          storageArea={storageArea}
        />
      </Modal>
    </>
  );
};

export default StorageAreas;
