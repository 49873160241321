import { makeStyles } from '@material-ui/core';

const StylesCross = theme => ({
  divider: {
    width: '100%',
    margin: '25px 0',
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: '600',
    marginBottom: '15px',
  },
  addItemButton: {
    textTransform: 'uppercase',
  },
  chipGrid: {
    marginTop: '25px',
    marginBottom: '10px',
  },
  chip: {
    margin: '4px',
  },
  noItems: {
    marginLeft: '5px',
  },
});

export const CrossSellStyles = makeStyles(StylesCross);

const StylesAdd = theme => ({
  typography: {
    margin: '5px 0',
  },
  dropdowns: {
    width: '100%',
    margin: '10px 0',
  },
  button: {
    margin: '10px 0',
  },
});

export const AddItemsStyles = makeStyles(StylesAdd);
