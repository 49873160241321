import React from 'react';
import AutomaticView from './View';
function Automatic({
  coupon,
  couponTriggers,
  control,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  itemInCart,
  setItemInCart,
  triggers,
  errors,
  forEachItem,
  setForEachItem,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
  setValue,
}) {
  return (
    <div>
      <AutomaticView
        couponTriggers={couponTriggers}
        coupon={coupon}
        control={control}
        triggers={triggers}
        cartItemsRequired={cartItemsRequired}
        setCartItemsRequired={setCartItemsRequired}
        itemAddedNumberTimes={itemAddedNumberTimes}
        itemInCart={itemInCart}
        setItemInCart={setItemInCart}
        errors={errors}
        forEachItem={forEachItem}
        setForEachItem={setForEachItem}
        setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
        selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
        setValue={setValue}
      />
    </div>
  );
}

export default Automatic;
