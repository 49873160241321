import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Divider } from '@material-ui/core';
import ProductionForecasterStyles from '../../View/styles.js';
import { toFixed10 } from '../../../../../../../utils';
import { SNACKBAR_STATUS } from '../../../../../../../constants';

const StoreSpecificUsageView = ({
  calculateAmPmSplit,
  calculateProteinSSPMTotal,
  calculateSideSSPMTotal,
  calculateStoreSpecificUsageData,
  saveSSPM,
  data,
  saveSSPMLoading,
  setSnackbar,
}) => {
  const classes = ProductionForecasterStyles();

  const [storeSpecificUsage, setStoreSpecificUsage] = useState(
    calculateStoreSpecificUsageData(data.viewer.location.storeSpecificUsage),
  );

  const handleSaveSSPM = () => {
    if (parseFloat(toFixed10(storeSpecificUsage.amPmSplitTotal, 2)) !== 100) {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: 'AM and PM Split total should equal 100%',
      });
      return;
    } else if (parseFloat(toFixed10(storeSpecificUsage.sspmProteinTotal, 2)) !== 100) {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: 'Protein Store Specific PM total should equal 100%',
      });
      return;
    } else if (parseFloat(toFixed10(storeSpecificUsage.sspmSideTotal, 2)) !== 100) {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: 'Side Store Specific PM total should equal 100%',
      });
      return;
    }

    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Saving Store Specific Usage...',
    });

    const saveSSPMInput = {
      locationId: data.viewer.location.locationId,
      amSplit: storeSpecificUsage.amPmSplit.amSplit,
      pmSplit: storeSpecificUsage.amPmSplit.pmSplit,
      inventoryitems: [
        ...storeSpecificUsage.proteins.map(protein => ({
          inventoryitemId: protein.protein.id,
          sspm: protein.sspm,
        })),
        ...storeSpecificUsage.sides.map(side => ({
          inventoryitemId: side.side.id,
          sspm: side.sspm,
        })),
      ],
    };

    saveSSPM({
      variables: {
        input: saveSSPMInput,
      },
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Store Specific Usage saved',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        className={classes.titlePadding}
        id="storeSpecificUsage"
      >
        Store Specific Usage - %
      </Typography>
      <Grid container className={classes.heading}>
        <Grid item xs={2} className={classes.flexDirection}>
          <div className={classes.centerVerticalContent}>Sales Split</div>
        </Grid>
        <Grid item xs={2} className={classes.flexDirection} style={{ marginRight: '1rem' }}>
          <div className={classes.contentInfoHolder}>
            <TextField
              label="AM Split (%)"
              name="amSplit"
              type="number"
              defaultValue={storeSpecificUsage?.amPmSplit?.amSplit * 100}
              variant="outlined"
              onChange={event => {
                setStoreSpecificUsage(prevStoreSpecificUsage => {
                  const amPmSplit = {
                    amSplit: parseFloat(event.target.value) / 100,
                    pmSplit: prevStoreSpecificUsage.amPmSplit.pmSplit || 0,
                  };

                  const storeSpecificUsage = {
                    ...prevStoreSpecificUsage,
                    amPmSplit,
                    amPmSplitTotal: calculateAmPmSplit(amPmSplit),
                  };

                  return storeSpecificUsage;
                });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={2} className={classes.flexDirection} style={{ marginRight: '1rem' }}>
          <div className={classes.contentInfoHolder}>
            <TextField
              label="PM Split (%)"
              name="pmSplit"
              type="number"
              defaultValue={storeSpecificUsage?.amPmSplit?.pmSplit * 100}
              variant="outlined"
              onChange={event => {
                setStoreSpecificUsage(prevStoreSpecificUsage => {
                  const amPmSplit = {
                    amSplit: prevStoreSpecificUsage.amPmSplit.amSplit || 0,
                    pmSplit: parseFloat(event.target.value) / 100,
                  };

                  const storeSpecificUsage = {
                    ...prevStoreSpecificUsage,
                    amPmSplit,
                    amPmSplitTotal: calculateAmPmSplit(amPmSplit),
                  };

                  return storeSpecificUsage;
                });
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.flexDirection}
          style={{
            color:
              parseFloat(toFixed10(storeSpecificUsage.amPmSplitTotal, 2)) !== 100 ? 'red' : 'green',
          }}
        >
          <div className={classes.centerVerticalContent}>{`${storeSpecificUsage.amPmSplitTotal ||
            0} %`}</div>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid container className={classes.heading}>
        <Grid item xs={6} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}>
            <Grid container className={classes.heading}>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}></div>
              </Grid>

              <Grid item xs={2} className={classes.flexDirection} style={{ marginBottom: '1rem' }}>
                <div className={classes.contentInfoHolder}>
                  <b>CSPM</b>
                </div>
              </Grid>

              <Grid item xs={4} className={classes.flexDirection} style={{ marginBottom: '1rem' }}>
                <div className={classes.contentInfoHolder}>
                  <b>Store Specific PM</b>
                </div>
              </Grid>
              {storeSpecificUsage.proteins.map((proteinSSU, index) => (
                <React.Fragment key={proteinSSU.protein.id}>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '1rem' }}
                  >
                    <div className={classes.centerVerticalContent}>{proteinSSU.protein.label}</div>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    className={classes.flexDirection}
                    style={{ marginBottom: '1rem' }}
                  >
                    <div className={classes.centerVerticalContent}>
                      <b>{`${proteinSSU.cspm} %`}</b>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '1rem' }}
                  >
                    <div className={classes.flexDirectionRow}>
                      <TextField
                        name="sspm"
                        type="number"
                        defaultValue={proteinSSU.sspm}
                        onChange={event => {
                          setStoreSpecificUsage(prevStoreSpecificUsage => {
                            let proteins = [...prevStoreSpecificUsage.proteins];

                            proteins[index] = {
                              ...proteins[index],
                              sspm: parseFloat(event.target.value),
                            };

                            const storeSpecificUsage = {
                              ...prevStoreSpecificUsage,
                              proteins,
                              sspmProteinTotal: calculateProteinSSPMTotal(proteins),
                            };

                            return storeSpecificUsage;
                          });
                        }}
                        variant="outlined"
                      />
                      <div className={classes.percentageAlignment}>%</div>
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
              <Divider style={{ width: '90%' }} />
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>TOTAL</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>{`${toFixed10(storeSpecificUsage.cspmProteinTotal, 2)} %`}</b>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div
                  className={classes.contentInfoHolder}
                  style={{
                    color:
                      parseFloat(toFixed10(storeSpecificUsage.sspmProteinTotal, 2)) !== 100
                        ? 'red'
                        : 'green',
                  }}
                >
                  <b>{`${toFixed10(storeSpecificUsage.sspmProteinTotal, 2)} %`}</b>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.flexDirection}
          style={{ borderLeft: '2px solid #d3d3db', paddingLeft: '7%' }}
        >
          <div className={classes.contentInfoHolder}>
            <Grid container className={classes.heading}>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}></div>
              </Grid>

              <Grid item xs={2} className={classes.flexDirection} style={{ marginBottom: '1rem' }}>
                <div className={classes.contentInfoHolder}>
                  <b>CSPM</b>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection} style={{ marginBottom: '1rem' }}>
                <div className={classes.contentInfoHolder}>
                  <b>Store Specific PM</b>
                </div>
              </Grid>
              {storeSpecificUsage.sides.map((sideSSU, index) => (
                <React.Fragment key={sideSSU.side.id}>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '1rem' }}
                  >
                    <div className={classes.centerVerticalContent}>{sideSSU.side.label}</div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.flexDirection}
                    style={{ marginBottom: '1rem' }}
                  >
                    <div className={classes.centerVerticalContent}>{`${sideSSU.cspm} %`}</div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '1rem' }}
                  >
                    <div className={classes.flexDirectionRow}>
                      <TextField
                        name="sspm"
                        type="number"
                        defaultValue={sideSSU.sspm}
                        variant="outlined"
                        onChange={event => {
                          setStoreSpecificUsage(prevStoreSpecificUsage => {
                            let sides = [...prevStoreSpecificUsage.sides];

                            sides[index] = {
                              ...sides[index],
                              sspm: parseFloat(event.target.value),
                            };

                            const storeSpecificUsage = {
                              ...prevStoreSpecificUsage,
                              sides,
                              sspmSideTotal: calculateSideSSPMTotal(sides),
                            };

                            return storeSpecificUsage;
                          });
                        }}
                      />
                      <div className={classes.percentageAlignment}>%</div>
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
              <Divider style={{ width: '90%' }} />
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>TOTAL</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>{`${toFixed10(storeSpecificUsage.cspmSideTotal, 2)} %`}</b>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div
                  className={classes.contentInfoHolder}
                  style={{
                    color:
                      parseFloat(toFixed10(storeSpecificUsage.sspmSideTotal, 2)) !== 100
                        ? 'red'
                        : 'green',
                  }}
                >
                  <b>{`${toFixed10(storeSpecificUsage.sspmSideTotal, 2)} %`}</b>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div>
        <Button
          disabled={saveSSPMLoading}
          onClick={() => {
            handleSaveSSPM();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default StoreSpecificUsageView;
