import React from "react";
import { SideBarSectionsLayout, TabPanel } from "../../../../../blocks";
import Customer from "../Customer";

export default function LoyaltyCustomerView({ currentTab }) {
  const tabsViewData = [
    {
      label: "General Info",
      value: "generalInfo",
      component: Customer,
      id: "524a0a6s-59sd-4733-a5e5-6062a856637c",
      sections: [
        { name: "General Info", id: "generalInfo" },
        { name: "Orders", id: "ordersView" },
        { name: "Points History", id: "pointsHistory" },
        { name: "Loyalty Submission", id: "loyaltySubmissions" },
        { name: "House Accounts", id: "houseAccounts" },
      ],
    },
  ];
  return (
    <>
      {tabsViewData.map((tabPanel) => (
        <TabPanel value={currentTab} index={tabPanel.value} key={tabPanel.id}>
          <SideBarSectionsLayout sectionList={tabPanel.sections || []}>
            {<tabPanel.component />}
          </SideBarSectionsLayout>
        </TabPanel>
      ))}
    </>
  );
}