import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

const API_LINK = process.env.REACT_APP_API_URL;

const httpLink = createHttpLink({
  uri: API_LINK,
  credentials: 'include',
});
const getClient = coreUtils => {
  const { logout: coreLogout } = coreUtils.useAuth();
  const coreIndexPath = coreUtils.getIndexPath();
  const authMiddleware = new ApolloLink((operation, forward) => {
    const { jwtToken } = coreUtils.getToken();

    operation.setContext(() => ({
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }));

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`GraphQL Error: ${message}`),
      );
    }
    if (networkError) {
      if (
        networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 401
      ) {
        coreLogout().then(() => {
          window.location.href = coreIndexPath;
        });
      }
    }
  });

  const link = ApolloLink.from([
    authMiddleware,
    errorLink,
    httpLink,
  ]);

  const client = new ApolloClient({
    link: link,
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache(),
  });

  return client;
}

export default getClient;
