import { modules } from './components/modules';
import { icons, paths } from './constants';

const config = {
  modules: [
    {
      id: '0',
      component: modules.Home,
      icon: icons.HomeIcon,
      label: 'Home',
      screenAccessId: 0,
      metaTitle: 'Dashboard',
      breadcrumbs: [
        {
          title: 'Dashboard',
          link: paths.BASE,
        },
      ],
      path: paths.BASE,
    },
    {
      id: '1',
      label: 'System Settings',
      icon: icons.SystemSettingsIcon,
      children: [
        {
          id: '7',
          component: modules.SystemSettings.MenuSettings,
          label: 'Menu Settings',
          exact: false,
          screenAccessId: 7,
          metaTitle: 'System Settings | Menu Tree',
          path: paths.SETTINGS_MENU_TREE,
          breadcrumbs: [
            {
              title: 'System Settings',
              link: paths.SETTINGS_MENU_TREE,
            },
            {
              title: 'Menu Tree',
              link: paths.SETTINGS_MENU_TREE,
            },
          ],
          children: [
            {
              id: 'c7599d2f-a3d0-410d-af1c-cd7561bc4a6e',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree ID | Menu',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Menu Tree | Menu',
              path: paths.SETTINGS_MENU,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu',
                  link: paths.SETTINGS_MENU,
                },
              ],
            },
            {
              id: 'b7738850-6ba1-4733-ba96-0828d6ba178e',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree ID | Menu ID',
              navExclude: true,
              screenAccessId: 7,
              exact: false,
              metaTitle: 'System Settings | Menu Tree | Menu ID',
              path: paths.SETTINGS_MENU_ID,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu ID',
                  link: paths.SETTINGS_MENU,
                },
              ],
            },
            {
              id: 'fbb89e44-dbb5-403a-a869-96bd61da6992',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree | Category',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Menu Tree | Category',
              path: paths.SETTINGS_CATEGORY,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Category',
                  link: paths.SETTINGS_CATEGORY,
                },
              ],
            },
            {
              id: '933067b5-5422-4b5f-91e4-b662e22b2ea3',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree | Category ID',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Menu Tree | Category ID',
              path: paths.SETTINGS_CATEGORY_ID,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Category ID',
                  link: paths.SETTINGS_CATEGORY_ID,
                },
              ],
            },
            {
              id: '7fa806b2-121f-4dac-8f92-e367ea2af5a2',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree | Subcategory',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Menu Tree | Subcategory',
              path: paths.SETTINGS_SUBCATEGORY,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Subcategory',
                  link: paths.SETTINGS_SUBCATEGORY,
                },
              ],
            },
            {
              id: '17103fd68-f7aa-496e-8fef-e61ac070a865',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree | Subcategory ID',
              navExclude: true,
              screenAccessId: 7,
              exact: false,
              metaTitle: 'System Settings | Menu Tree | Subcategory ID',
              path: paths.SETTINGS_SUBCATEGORY_ID,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Subcategory ID',
                  link: paths.SETTINGS_SUBCATEGORY_ID,
                },
              ],
            },
            {
              id: '2bab83e7-cf16-4d34-a1a2-bea0798ef4c2',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree | Item',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Menu Tree | Item',
              path: paths.SETTINGS_ITEM,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Item',
                  link: paths.SETTINGS_ITEM,
                },
              ],
            },
            {
              id: '7723283b-8448-4f95-8253-aee607b626c3',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree | Item',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Menu Tree | Item',
              path: paths.ITEM_INFO,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Item',
                  link: paths.ITEM_INFO,
                },
              ],
            },
            {
              id: 'f31b7189-5eb7-41f7-a10a-4c44a5779429',
              component: modules.SystemSettings.MenuSettings,
              label: 'Menu Tree | Item ID',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Menu Tree | Item ID',
              path: paths.SETTINGS_ITEM_ID,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Menu Tree',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Item ID',
                  link: paths.SETTINGS_ITEM_ID,
                },
              ],
            },
            {
              id: 'b30d378b-01f0-4b2c-8357-0302e7e498d4',
              component: modules.SystemSettings.MenuSettings,
              label: 'Choices',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Choices',
              path: paths.SETTINGS_CHOICES,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Choices',
                  link: paths.SETTINGS_CHOICES,
                },
              ],
            },
            {
              id: 'dfd01bb0-0dbe-4ac8-8ae5-a3be79fff2e7',
              component: modules.SystemSettings.MenuSettings,
              label: 'Choice ID',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Choice ID',
              path: paths.SETTINGS_CHOICES_ID,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Choice ID',
                  link: paths.SETTINGS_CHOICES_ID,
                },
              ],
            },
            {
              id: '28ee702c-2337-11eb-adc1-0242ac120002',
              component: modules.SystemSettings.MenuSettings,
              label: 'Multiple Edit',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Choices',
              path: paths.SETTINGS_CHOICES,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Choices',
                  link: paths.SETTINGS_CHOICES,
                },
              ],
            },
            {
              id: '93bce259-cccc-4d56-97ef-dd09c1aaad4d',
              component: modules.SystemSettings.MenuSettings,
              label: 'Multiple Edit',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Choices | Multiple Edit | Multiple ID',
              path: paths.SETTINGS_CHOICES_MULTIPLE_EDIT,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Choices',
                  link: paths.SETTINGS_CHOICES,
                },
                {
                  title: 'Multiple Edit',
                  link: paths.SETTINGS_CHOICES_MULTIPLE_EDIT,
                },
                {
                  title: 'Multiple ID',
                  link: paths.SETTINGS_CHOICES_MULTIPLE_EDIT,
                },
              ],
            },
            {
              id: '22222',
              component: modules.SystemSettings.MenuSettings,
              label: 'Recipes',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Ingredients',
              path: paths.SETTINGS_RECIPES,
              breadcrumbs: [
                {
                  title: 'Recipes',
                  link: paths.SETTINGS_RECIPES,
                },
              ],
            },
            {
              id: '22222',
              component: modules.SystemSettings.MenuSettings,
              label: 'Equipment',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Ingredient ID',
              path: paths.SETTINGS_EQUIPMENT,
              breadcrumbs: [
                {
                  title: 'Equipment',
                  link: paths.SETTINGS_EQUIPMENT,
                },
              ],
            },
            {
              id: '33047335-64ae-4553-bf36-6f54d7da7bbd',
              component: modules.SystemSettings.MenuSettings,
              label: 'Tiers',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Tiers',
              path: paths.SETTINGS_TIERS,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Tiers',
                  link: paths.SETTINGS_TIERS,
                },
              ],
            },
            {
              id: '33047335-64ae-4553-bf36-6f54d7da7bbe',
              component: modules.SystemSettings.MenuSettings,
              label: 'Item Type',
              navExclude: true,
              exact: false,
              screenAccessId: 7,
              metaTitle: 'System Settings | Item Type',
              path: paths.SETTINGS_ITEM_TYPE,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_MENU_TREE,
                },
                {
                  title: 'Item Type',
                  link: paths.SETTINGS_ITEM_TYPE,
                },
              ],
            },
          ],
        },
        {
          id: '8',
          component: modules.SystemSettings.Discounts,
          label: 'Discounts',
          screenAccessId: 8,
          metaTitle: 'System Settings | Discounts',
          path: paths.SETTINGS_DISCOUNTS,
          breadcrumbs: [
            {
              title: 'System Settings',
              link: paths.SETTINGS_DISCOUNTS,
            },
            {
              title: 'Discounts',
              link: paths.SETTINGS_DISCOUNTS,
            },
          ],
          children: [
            {
              id: '3b332848-2343-11eb-adc1-0242ac120003',
              component: modules.SystemSettings.CreateDiscount,
              label: 'Discounts New',
              metaTitle: 'System Settings | Discounts | New',
              navExclude: true,
              screenAccessId: 8,
              exact: true,
              path: paths.SETTINGS_DISCOUNTS_NEW,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_DISCOUNTS,
                },
                {
                  title: 'Discounts',
                  link: paths.SETTINGS_DISCOUNTS,
                },
                {
                  title: 'New',
                  link: paths.SETTINGS_DISCOUNTS_NEW,
                },
              ],
            },
            {
              id: '3b332848-2343-11eb-adc1-0242ac120004',
              component: modules.SystemSettings.UpdateDiscount,
              label: 'Discount ID',
              metaTitle: 'System Settings | Discounts | ID',
              navExclude: true,
              screenAccessId: 8,
              exact: true,
              path: paths.SETTINGS_DISCOUNTS_ID,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_DISCOUNTS,
                },
                {
                  title: 'Discounts',
                  link: paths.SETTINGS_DISCOUNTS,
                },
                {
                  title: 'ID',
                  link: paths.SETTINGS_DISCOUNTS_ID,
                },
              ],
            },
          ],
        },
        {
          id: '9',
          component: modules.SystemSettings.Loyalty,
          label: 'Loyalty',
          metaTitle: 'System Settings | Loyalty',
          screenAccessId: 9,
          path: paths.SETTINGS_LOYALTY,
          breadcrumbs: [
            {
              title: 'System Settings',
              link: paths.SETTINGS_LOYALTY,
            },
            {
              title: 'Loyalty',
              link: paths.SETTINGS_LOYALTY,
            },
          ],
        },
        {
          id: '10',
          component: modules.SystemSettings.AccessManagement.UsergroupList,
          label: 'Access Management',
          metaTitle: 'System Settings | Access Management',
          screenAccessId: 10,
          path: paths.SETTINGS_ACCESS_MANAGEMENT,
          breadcrumbs: [
            {
              title: 'System Settings',
              link: paths.SETTINGS_ACCESS_MANAGEMENT,
            },
            {
              title: 'Access Management',
              link: paths.SETTINGS_ACCESS_MANAGEMENT,
            },
          ],
          children: [
            {
              id: '11',
              component: modules.SystemSettings.AccessManagement.Usergroup,
              label: 'Access Management',
              screenAccessId: 10,
              metaTitle: 'System Settings | Access Management | Usergroup',
              path: paths.SETTINGS_ACCESS_MANAGEMENT_USERGROUP_ID,
              navExclude: true,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_ACCESS_MANAGEMENT,
                },
                {
                  title: 'Access Management',
                  link: paths.SETTINGS_ACCESS_MANAGEMENT,
                },
                {
                  title: 'Usergroup',
                  link: paths.SETTINGS_ACCESS_MANAGEMENT_USERGROUP_ID,
                },
              ],
            },
          ],
        },
        {
          id: '12',
          component: modules.SystemSettings.Overtimes.List,
          label: 'Overtime',
          screenAccessId: 12,
          metaTitle: 'System Settings | Overtime',
          path: paths.SETTINGS_OVERTIME,
          breadcrumbs: [
            {
              title: 'System Settings',
              link: paths.SETTINGS_OVERTIME,
            },
            {
              title: 'Overtime',
              link: paths.SETTINGS_OVERTIME,
            },
          ],
        },
        {
          id: '69',
          component: modules.SystemSettings.Hierarchy,
          label: 'Hierarchy',
          screenAccessId: 69,
          metaTitle: 'System Settings | Hierarchy',
          path: paths.SETTINGS_HIERARCHY,
          breadcrumbs: [
            {
              title: 'System Settings',
              link: paths.SETTINGS_HIERARCHY,
            },
            {
              title: 'Hierarchy',
              link: paths.SETTINGS_HIERARCHY,
            },
          ],
        },
        {
          id: '76',
          component: modules.SystemSettings.Vendors,
          label: 'Vendors',
          screenAccessId: 76,
          metaTitle: 'System Settings | All Vendors',
          path: paths.SETTINGS_VENDORS,
          breadcrumbs: [
            {
              title: 'System Settings',
              link: paths.SETTINGS_HIERARCHY,
            },
            {
              title: 'All Vendors',
              link: paths.SETTINGS_VENDORS,
            },
          ],
          children: [
            {
              id: '77',
              component: modules.SystemSettings.CreateVendor,
              label: 'New Vendor',
              screenAccessId: 76,
              metaTitle: 'System Settings | Vendors | New',
              navExclude: true,
              exact: true,
              path: paths.SETTINGS_VENDOR_NEW,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_VENDORS,
                },
                {
                  title: 'Vendors',
                  link: paths.SETTINGS_VENDORS,
                },
                {
                  title: 'New',
                  link: paths.SETTINGS_VENDOR_NEW,
                },
              ],
            },
            {
              id: '78',
              component: modules.SystemSettings.Vendor,
              screenAccessId: 76,
              exact: false,
              label: 'Vendor',
              metaTitle: 'Vendor',
              navExclude: true,
              path: paths.SETTINGS_VENDOR,
              breadcrumbs: [
                {
                  title: 'Vendor',
                  link: paths.SETTINGS_VENDOR,
                },
              ],
            },
          ],
        },
        {
          id: '94',
          label: 'Inventory Settings',
          path: paths.SETTINGS_INVENTORY_SETTINGS_VENDORS,
          component: modules.SystemSettings.InventorySettings,
          children: [
            {
              id: '94',
              label: 'Vendors',
              metaTitle: 'System Settings | Inventory Settings | Storage',
              component: modules.SystemSettings.InventorySettings,
              screenAccessId: 94,
              navExclude: true,
              exact: false,
              path: paths.SETTINGS_INVENTORY_SETTINGS_VENDORS,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_VENDORS,
                },
                {
                  title: 'Inventory Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_VENDORS,
                },
                {
                  title: 'Vendors',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_VENDORS,
                },
              ],
            },
            {
              id: '94',
              component: modules.SystemSettings.InventorySettings,
              label: 'Storage Areas',
              navExclude: true,
              exact: false,
              screenAccessId: 94,
              metaTitle: 'System Settings | Inventory Settings| Storage Areas',
              path: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
                },
                {
                  title: 'Inventory Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
                },
                {
                  title: 'Storage Areas',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
                },
              ],
            },
            {
              id: '94',
              component: modules.SystemSettings.InventorySettings,
              label: 'Categories',
              navExclude: true,
              exact: false,
              screenAccessId: 94,
              metaTitle: 'System Settings | Inventory Settings | Category Settings',
              path: paths.SETTINGS_INVENTORY_SETTINGS_CATEGORY_SETTINGS,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
                },
                {
                  title: 'Inventory Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
                },
                {
                  title: 'Category Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_CATEGORY_SETTINGS,
                },
              ],
            },
            {
              id: '94',
              component: modules.SystemSettings.InventorySettings,
              label: 'Measure Settings',
              navExclude: true,
              exact: false,
              screenAccessId: 94,
              metaTitle: 'System Settings | Inventory Settings | Measure Settings',
              path: paths.SETTINGS_INVENTORY_SETTINGS_MEASURE_SETTINGS,
              breadcrumbs: [
                {
                  title: 'System Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
                },
                {
                  title: 'Inventory Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS,
                },
                {
                  title: 'Measure Settings',
                  link: paths.SETTINGS_INVENTORY_SETTINGS_MEASURE_SETTINGS,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: '2',
      icon: icons.LocationIcon,
      label: 'Locations',
      children: [
        {
          id: '2',
          component: modules.Locations.List,
          icon: icons.LocationIcon,
          label: 'All Locations',
          screenAccessId: 13,
          metaTitle: 'Locations | All Locations',
          path: paths.LOCATIONS,
          breadcrumbs: [
            {
              title: 'Locations',
              link: paths.LOCATIONS,
            },
            {
              title: 'All Locations',
              link: paths.LOCATIONS,
            },
          ],
          children: [
            {
              id: '243', // TODO edit id
              component: modules.Locations.BulkEdit,
              screenAccessId: 16,
              exact: true,
              label: 'Locations | Bulk Edit',
              metaTitle: 'Locations - Bulk Edit',
              navExclude: true,
              path: paths.LOCATIONS_BULK_EDIT,
              breadcrumbs: [
                {
                  title: 'Locations',
                  link: paths.LOCATIONS,
                },
                {
                  title: 'Bulk Edit',
                  link: paths.LOCATIONS_BULK_EDIT,
                },
              ],
            },
            {
              id: '3d400c96-24c9-11eb-adc1-0242ac120002',
              component: modules.Locations.Location,
              exact: false,
              screenAccessId: 16,
              label: 'Location',
              metaTitle: 'Location',
              navExclude: true,
              path: paths.LOCATION,
              breadcrumbs: [
                {
                  title: 'Location',
                  link: paths.LOCATION,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: '90',
      label: 'Inventory',
      icon: icons.InventoryIcon,
      children: [
        {
          id: '89',
          component: modules.Inventory.Ingredients,
          label: 'Ingredients',
          screenAccessId: 89,
          metaTitle: 'Inventory | Ingredients',
          path: paths.INVENTORY_INGREDIENTS,
          breadcrumbs: [
            {
              title: 'Inventory | Ingredients',
              link: paths.INVENTORY_INGREDIENTS,
            },
          ],
        },
        // {
        //   id: '92',
        //   component: modules.Inventory.ProductionForecaster.Locations,
        //   icon: icons.LocationIcon,
        //   exact: false,
        //   label: 'Production Forecaster',
        //   screenAccessId: 92,
        //   metaTitle: 'Production Forecaster | Stores',
        //   path: paths.PRODUCTION_FORECASTER_LOCATIONS,
        //   breadcrumbs: [
        //     {
        //       title: 'Production Forecaster | Stores',
        //       link: paths.PRODUCTION_FORECASTER_LOCATIONS,
        //     },
        //   ],
        //   children: [
        //     {
        //       id: '92',
        //       component: modules.Inventory.ProductionForecaster.ProductionForecasterForm,
        //       exact: false,
        //       label: 'Production Forecaster',
        //       screenAccessId: 92,
        //       metaTitle: 'Inventory | Production Forecaster',
        //       navExclude: true,
        //       path: paths.PRODUCTION_FORECASTER,
        //       breadcrumbs: [
        //         {
        //           title: 'Inventory | Production Forecaster',
        //           link: paths.PRODUCTION_FORECASTER,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
    {
      id: '87',
      label: 'Core Ops',
      icon: icons.CoreOpsIcon,
      children: [
        {
          id: '88',
          component: modules.CoreOps.Inventory,
          label: 'Inventory',
          screenAccessId: 88,
          metaTitle: 'Core Ops | Inventory',
          path: paths.COREOPS_INVENTORY,
          children: [
            {
              id: '87',
              component: modules.CoreOps.Inventory.TaskList,
              exact: true,
              label: 'Task List',
              screenAccessId: 88,
              metaTitle: 'Task List',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_TASKLIST,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.SimpleInvoice,
              exact: true,
              label: 'Simple Invoice',
              screenAccessId: 88,
              metaTitle: 'Simple Invoice',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_NEW,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.SimpleInvoice,
              exact: true,
              label: 'Simple Invoice',
              screenAccessId: 88,
              metaTitle: 'Simple Invoice',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_ID,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.InvoiceSummary,
              exact: true,
              label: 'Invoice Summary',
              screenAccessId: 88,
              metaTitle: 'Invoice Summary',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_INVOICE_SUMMARY,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.WasteLog,
              exact: false,
              screenAccessId: 87,
              label: 'Waste Log',
              metaTitle: 'Waste Log',
              navExclude: true,
              path: paths.WASTE_LOG,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.InvoiceJournal,
              exact: true,
              label: 'Invoice Journal',
              screenAccessId: 88,
              metaTitle: 'Invoice Journal',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_INVOICE_JOURNAL,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.StorageAreas,
              exact: true,
              label: 'Storage Areas',
              screenAccessId: 88,
              metaTitle: 'Storage Areas',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.Summary,
              exact: true,
              label: 'Summary',
              screenAccessId: 88,
              metaTitle: 'Summary',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS_SUMMARY,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.StorageArea,
              exact: false,
              label: 'Storage Areas',
              screenAccessId: 88,
              metaTitle: 'Storage Areas',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS_STORAGE_ID,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.InventoryTransfer.CreateInventoryTransfer,
              exact: false,
              label: 'Create Inventory Transfer',
              screenAccessId: 88,
              metaTitle: 'Create Inventory Transfer',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER_NEW,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.InventoryTransfer.InventorytransferDetails,
              exact: false,
              label: 'Inventory Transfers Details',
              screenAccessId: 88,
              metaTitle: 'Inventory Transfers Details',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER_ID,
            },
            {
              id: '87',
              component: modules.CoreOps.Inventory.InventoryTransfer.InventorytransferList,
              exact: false,
              label: 'Inventory Transfers',
              screenAccessId: 88,
              metaTitle: 'Inventory Transfers',
              navExclude: true,
              path: paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER,
            },
          ],
        },
        {
          id: '95',
          component: modules.CoreOps.Reports,
          exact: true,
          label: 'Reports',
          screenAccessId: 95,
          metaTitle: 'Core Ops | Reports | Actual v Theoretical',
          path: paths.COREOPS_REPORTS,
        },
        {
          id: '95',
          component: modules.CoreOps.Reports,
          label: 'Reports',
          navExclude: true,
          exact: false,
          screenAccessId: 95,
          metaTitle: 'Core Ops | Reports | Week In The Life',
          path: paths.COREOPS_REPORTS_WEEK_IN_LIFE,
        },
        {
          id: '95',
          component: modules.CoreOps.Reports,
          label: 'Reports',
          navExclude: true,
          exact: false,
          screenAccessId: 95,
          metaTitle: 'Core Ops | Reports | Purchase Log - Invoices',
          path: paths.COREOPS_REPORTS_PURCHASE_LOG_INVOICES,
        },
        {
          id: '95',
          component: modules.CoreOps.Reports,
          label: 'Reports',
          navExclude: true,
          exact: false,
          screenAccessId: 95,
          metaTitle: 'Core Ops | Reports | Purchase Log - Products',
          path: paths.COREOPS_REPORTS_PURCHASE_LOG_PRODUCTS,
        },
        {
          id: '95',
          component: modules.CoreOps.Reports,
          label: 'Reports',
          navExclude: true,
          exact: false,
          screenAccessId: 95,
          metaTitle: 'Core Ops | Reports | Cost Of Goods',
          path: paths.COREOPS_REPORTS_COST_OF_GOODS,
        },
        {
          id: '95',
          component: modules.CoreOps.Reports,
          label: 'Reports',
          navExclude: true,
          exact: false,
          screenAccessId: 95,
          metaTitle: 'Core Ops | Reports | Inventory Count',
          path: paths.COREOPS_REPORTS_INVENTORY_COUNT,
        },
      ],
    },
    {
      id: '3',
      label: 'Orders',
      icon: icons.OrdersIcon,
      children: [
        {
          id: '39',
          component: modules.Orders.AllOrders.List,
          label: 'All Orders',
          metaTitle: 'Orders | All Orders',
          screenAccessId: 39,
          path: paths.ORDERS_ALL,
          breadcrumbs: [
            {
              title: 'Orders',
              link: paths.ORDERS_ALL,
            },
            {
              title: 'All Orders',
              link: paths.ORDERS_ALL,
            },
          ],
          children: [
            {
              id: '39',
              component: modules.Orders.AllOrders.Order,
              exact: false,
              label: 'Order',
              screenAccessId: 41,
              metaTitle: 'Order',
              navExclude: true,
              path: paths.ORDERS_ID,
              breadcrumbs: [
                {
                  title: 'Order',
                  link: paths.ORDERS_ID,
                },
              ],
            },
          ],
        },
        {
          id: '40',
          component: modules.Orders.Disputes,
          label: 'Disputes',
          metaTitle: 'Orders | Disputes',
          screenAccessId: 40,
          path: paths.ORDERS_DISPUTES,
          breadcrumbs: [
            {
              title: 'Order',
              link: paths.ORDERS_DISPUTES,
            },
            {
              title: 'Disputes',
              link: paths.ORDERS_DISPUTES,
            },
          ],
        },
        {
          id: '41',
          component: modules.Orders.DisputedOrderInfo,
          label: 'Disputes',
          metaTitle: 'Orders | Disputes | Details',
          screenAccessId: 40,
          navExclude: true,
          path: paths.ORDERS_DISPUTES_ID,
          breadcrumbs: [
            {
              title: 'Order',
              link: paths.ORDERS_DISPUTES,
            },
            {
              title: 'Disputes',
              link: paths.ORDERS_DISPUTES,
            },
            {
              title: 'Details',
              link: paths.ORDERS_DISPUTES_ID,
            },
          ],
        },
        {
          id: '42',
          component: modules.Orders.AbandonedCarts,
          label: 'Abandoned Carts',
          screenAccessId: 42,
          metaTitle: 'Orders | Abandoned Carts',
          path: paths.ABANDONED_CARTS,
          breadcrumbs: [
            {
              title: 'Order',
              link: paths.ABANDONED_CARTS,
            },
            {
              title: 'Abandoned Carts',
              link: paths.ABANDONED_CARTS,
            },
          ],
        },
      ],
    },
    {
      id: '4',
      label: 'Catering',
      icon: icons.CateringIcon,
      metaTitle: 'Catering',
      children: [
        {
          id: '51',
          component: modules.CateringOrderList,
          label: 'All Orders',
          screenAccessId: 51,
          metaTitle: 'Catering | All Orders',
          path: paths.CATERING_ORDERS,
          breadcrumbs: [
            {
              title: 'Catering',
              link: paths.CATERING_ORDERS,
            },
            {
              title: 'All Orders',
              link: paths.CATERING_ORDERS,
            },
          ],
          children: [
            {
              id: '3b332dsad848-2343-11eb-adc1-xxxsad2',
              component: modules.Catering,
              label: 'Catering',
              screenAccessId: 52,
              navExclude: true,
              metaTitle: 'Catering | Order',
              path: paths.CATERING_ORDER,
              breadcrumbs: [
                {
                  title: 'Catering',
                  link: paths.CATERING_ORDER,
                },
                {
                  title: 'Order',
                  link: paths.CATERING_ORDER,
                },
              ],
            },
            {
              id: '3213dsc-2343-sadsa-adc1-0242ac120002',
              component: modules.Catering,
              label: 'Catering',
              navExclude: true,
              screenAccessId: 52,
              metaTitle: 'Catering | New Catering Order',
              path: paths.NEW_CATERING_ORDER,
              breadcrumbs: [
                {
                  title: 'Catering',
                  link: paths.NEW_CATERING_ORDER,
                },
                {
                  title: 'New Catering Order',
                  link: paths.NEW_CATERING_ORDER,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: '5',
      label: 'Labor',
      icon: icons.LaborIcon,
      path: paths.LABOR_EMPLOYEES,
      component: modules.Labor.Employees,
      breadcrumbs: [
        {
          title: 'Labor',
          link: paths.LABOR_EMPLOYEES,
        },
      ],
      children: [
        {
          id: '59',
          component: modules.Labor.Settings,
          label: 'Labor settings',
          screenAccessId: 59,
          metaTitle: 'Labor | Settings',
          path: paths.LABOR_SETTINGS,
          breadcrumbs: [
            {
              title: 'Labor',
              link: paths.LABOR_SETTINGS,
            },
            {
              title: 'Settings',
              link: paths.LABOR_SETTINGS,
            },
          ],
        },
        {
          id: '60',
          component: modules.Labor.Employees,
          label: 'Employees',
          metaTitle: 'Labor | Employees',
          screenAccessId: 60,
          path: paths.LABOR_EMPLOYEES,
          breadcrumbs: [
            {
              title: 'Labor',
              link: paths.LABOR_EMPLOYEES,
            },
            {
              title: 'Employees',
              link: paths.LABOR_EMPLOYEES,
            },
          ],
        },
        {
          id: '61',
          component: modules.Labor.Timeshift,
          label: 'Timeshift',
          screenAccessId: 61,
          metaTitle: 'Labor | Timeshift',
          path: paths.LABOR_TIMESHIFT,
          breadcrumbs: [
            {
              title: 'Labor',
              link: paths.LABOR_TIMESHIFT,
            },
            {
              title: 'Timeshift',
              link: paths.LABOR_TIMESHIFT,
            },
          ],
        },
        {
          id: '62',
          component: modules.Labor.Schedule,
          label: 'Schedule',
          screenAccessId: 62,
          metaTitle: 'Labor | Schedule',
          path: paths.LABOR_SCHEDULE,
          breadcrumbs: [
            {
              title: 'Labor',
              link: paths.LABOR_SCHEDULE,
            },
            {
              title: 'Schedule',
              link: paths.LABOR_SCHEDULE,
            },
          ],
        },
      ],
    },
    {
      id: '70',
      label: 'Reports',
      icon: icons.ReportsIcon,
      metaTitle: 'Reports',
      screenAccessId: 70,
      path: paths.REPORTS,
      component: modules.Reports,
      breadcrumbs: [
        {
          title: 'Reports',
          link: paths.REPORTS,
        },
      ],
    },
    {
      id: '28ee702c-2337-11eb-adc1-0242ac120002',
      component: modules.DemoPage,
      label: 'Demo page',
      screenAccessId: 0,
      metaTitle: 'Demo page',
      path: paths.DEMO_PAGE,
      breadcrumbs: [
        {
          title: 'Demo page',
          link: paths.DEMO_PAGE,
        },
      ],
    },
    {
      id: '1000',
      component: modules.Help,
      label: 'Help',
      screenAccessId: 0,
      metaTitle: 'Help',
      path: paths.HELP,
      breadcrumbs: [
        {
          title: 'Help',
          link: paths.HELP,
        },
      ],
    },
    {
      id: '71',
      component: modules.Loyalty.LoyaltyCustomers,
      label: 'Loyalty',
      metaTitle: 'Loyalty',
      screenAccessId: 72,
      path: paths.LOYALTY_CUSTOMERS,
      breadcrumbs: [
        {
          title: 'Loyalty Customers',
          link: paths.LOYALTY_CUSTOMERS,
        },
        {
          title: 'Loyalty Submissions',
          link: paths.LOYALTY_SUBMISSIONS,
        },
        {
          title: 'Loyalty Settings',
          link: paths.LOYALTY_SETTINGS,
        },
        {
          title: 'Loyalty Rewards',
          link: paths.LOYALTY_REWARDS_MYSTERY_REWARDS,
        },
        {
          title: 'Achievements',
          path: paths.LOYALTY_REWARDS_ACHIEVEMENTS,
        },
        {
          title: 'Loyalty Events',
          link: paths.LOYALTY_EVENTS,
        },
      ],
      children: [
        {
          id: '72',
          component: modules.Loyalty.LoyaltyCustomers,
          exact: true,
          path: paths.LOYALTY_CUSTOMERS,
          screenAccessId: 72,
          label: 'Customers',
          metaTitle: 'Loyalty | Customers',
          children: [
            {
              id: '75',
              component: modules.Loyalty.LoyaltyCustomer,
              exact: false,
              label: 'Customer',
              screenAccessId: 72,
              metaTitle: 'Customer',
              // navExclude: true,
              path: paths.LOYALTY_CUSTOMER,
              breadcrumbs: [
                {
                  title: 'Customer',
                  link: paths.LOYALTY_CUSTOMER,
                },
              ],
            },
          ],
        },
        {
          id: '73',
          component: modules.Loyalty.LoyaltySubmissions,
          label: 'Loyalty Submissions',
          metaTitle: 'Loyalty | Submissions',
          screenAccessId: 73,
          exact: true,
          path: paths.LOYALTY_SUBMISSIONS,
        },
        {
          id: '74',
          component: modules.Loyalty.LoyaltySettings,
          label: 'Loyalty Settings',
          screenAccessId: 74,
          metaTitle: 'Loyalty | Settings',
          exact: true,
          path: paths.LOYALTY_SETTINGS,
        },
        {
          id: '74', // TODO fix id
          component: modules.Loyalty.LoyaltyRewards,
          label: 'Loyalty Rewards',
          screenAccessId: 74, // TODO fix id
          metaTitle: 'Loyalty | Rewards | Mystery Rewards',
          exact: true,
          path: paths.LOYALTY_REWARDS_MYSTERY_REWARDS,
        },
        {
          id: '74', // TODO fix id
          component: modules.Loyalty.LoyaltyRewards,
          label: 'Loyalty Rewards',
          navExclude: true,
          screenAccessId: 74, // TODO fix id
          metaTitle: 'Loyalty | Rewards | Achievements',
          exact: true,
          path: paths.LOYALTY_REWARDS_ACHIEVEMENTS,
        },
        {
          id: '84',
          component: modules.Loyalty.LoyaltyEvents,
          label: 'Loyalty Events',
          screenAccessId: 84,
          metaTitle: 'Loyalty | Events',
          exact: true,
          path: paths.LOYALTY_EVENTS,
        },
      ],
    },
    {
      id: '79',
      label: 'Action Logs',
      metaTitle: 'Action Logs',
      path: paths.ACTION_LOG_LOCATIONS,
      breadcrumbs: [
        {
          title: 'Location Log',
          link: paths.ACTION_LOG_LOCATIONS,
        },
        {
          title: 'Location Items',
          link: paths.ACTION_LOG_LOCATION_ITEMS,
        },
        {
          title: 'Quote logs',
          link: paths.ACTION_LOG_LOCATION_ITEMS,
        },
        {
          title: 'Location Inventory Item logs',
          link: paths.ACTION_LOG_LOCATION_INVENTORYITEM,
        },
      ],
      children: [
        {
          id: '80',
          component: modules.ActionLogs.LocationLog,
          exact: true,
          path: paths.ACTION_LOG_LOCATIONS,
          label: 'Location',
          screenAccessId: 80,
          metaTitle: 'Action Log | Location Log',
        },
        {
          id: '81',
          component: modules.ActionLogs.LocationItemLog,
          exact: true,
          path: paths.ACTION_LOG_LOCATION_ITEMS,
          label: 'Location Items',
          screenAccessId: 81,
          metaTitle: 'Action Log | Location Items Log',
        },
        {
          id: '82',
          component: modules.ActionLogs.QuoteLog,
          exact: true,
          path: paths.ACTION_LOG_QUOTE,
          screenAccessId: 82,
          label: 'Quote',
          metaTitle: 'Action Log | Quotes Log',
        },
        {
          id: '83',
          component: modules.ActionLogs.QuotecateringLog,
          exact: true,
          screenAccessId: 83,
          path: paths.ACTION_LOG_QUOTECATERING,
          label: 'Quotecatering',
          metaTitle: 'Action Log | Quotecatering Log',
        },
        {
          id: '96',
          component: modules.ActionLogs.LocationInventoryitemLog,
          exact: true,
          screenAccessId: 96,
          path: paths.ACTION_LOG_LOCATION_INVENTORYITEM,
          label: 'Location Inventory item',
          metaTitle: 'Action Log | Location Inventory item Log',
        },
      ],
    },
  ],
};

export default config;
