import { gql } from '@apollo/client';
import { colorFragment } from "../fragments";

const FETCH_COLORS = gql`
  query FetchColors($first: Int, $filter: [ColorFilter]) {
    viewer {
      id
      colorConnection(first: $first, filter: $filter) {
        edges {
          node {
            ...ColorData
          }
        }
      }
    }
  }
  ${colorFragment.color}
`;

export default FETCH_COLORS;
