import moment from "moment";
import { DateTime } from "luxon";

const formatToValidDate = (date, time, amPm, options = { returnISODate: null }) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let hours = time.hours;
  let minutes = time.minutes;

  const _time = moment(`${hours}:${minutes} ${amPm}`, ["h:mm A"])
    .format("HH:mm")
    .split(":");
  if (options.returnISODate) {
    return DateTime.fromObject({
      day,
      month,
      year,
      hour: _time[0],
      minute: _time[1],
    }).toISO();
  }
  return DateTime.fromObject({
    day,
    month,
    year,
    hour: _time[0],
    minute: _time[1],
  }).toJSDate();
};

const formatFromValidDate = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let hours = date.getHours();
  let minutes = date.getMinutes();

  const _time = moment(`${hours}:${minutes}`, ["HH:mm"])
    .format("hh:mm a")
    .split(":");
  const type = _time[1].split(" ")[1];
  return {
    year,
    month,
    day,
    hours: +_time[0],
    minutes: +_time[1].split(" ")[0],
    type
  }
};

const formatFrom24hTo12 = (hours, minutes) => {
  const _time = moment(`${hours}:${minutes}`, ["HH:mm"])
  .format("hh:mm a")
  .split(":");

  const _hour = + _time[0];
  const _minute = + _time[1].split(" ")[0];
  const _type = _time[1].split(" ")[1];

  return { _hour, _minute, _type };
}

const date = { formatFromValidDate, formatToValidDate, formatFrom24hTo12 };

export default date;
