import { gql } from '@apollo/client';
import { loyaltysettingsFragment } from '../../fragments';

export const CREATE_LOYALTY_SETTINGS = gql`
  mutation createLoyaltysettings($input: CreateLoyaltysettingsMutationInput!) {
    createLoyaltysettings(input: $input) {
      viewer {
        id
      }
      loyaltysettingsEdge {
        ...LoyaltysettingsData
      }
    }
  }
  ${loyaltysettingsFragment.loyaltysettings}
`;
