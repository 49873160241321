import { gql } from '@apollo/client';

const TOGGLE_LOCATION = gql`
  mutation toggleLocation($input: ToggleLocationMutationInput!) {
    toggleLocation (input: $input) {
    location {
      id
    }
    viewer {
      id
    }
    locationEdge {
      node {
        id
      }
    }
    clientMutationId
  }
}
`;

export default TOGGLE_LOCATION;
