import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Paper, CircularProgress, Tabs, Tab } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { TabPanel, SideBarSectionsLayoutButtonVariant } from '../../../../../blocks';
import { FIELDGROUP_LIST, USERGROUP_RULES } from '../../../../../../apollo/queries';
import Rules from '../Rules';
import UserList from '../UserList';

const tabsViewData = [
  {
    label: 'Rules',
    value: 'rules',
    component: Rules,
    id: '519e0a6f-598d-4733-a5e5-6069a856637c',
  },
  {
    label: 'Users',
    value: 'users',
    component: UserList,
    id: '32aa54da-28ce-433b-9a69-aa848e9dc44f',
  },
];

const Usergroup = () => {
  const { usergroupId, currentTab = 'rules' } = useParams();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [getRulesForUsergroupFieldgroup, { loading: rulesLoading, data: rulesData }] = useLazyQuery(
    USERGROUP_RULES,
    {
      // onCompleted: () =>
      fetchPolicy: 'no-cache',
    },
  );

  // List of all root modules
  const { data, loading, error } = useQuery(FIELDGROUP_LIST, {
    variables: {
      first: 99999,
      fieldgroupFilter: {
        parentFieldgroup: {
          id: {
            null: true,
          },
        },
      },
    },
  });

  const fieldgroups = data?.viewer?.fieldgroupConnection?.edges.map(fieldgroup => ({
    id: fieldgroup.node.id,
    fieldgroupId: fieldgroup.node.fieldgroupId,
    name: fieldgroup.node.label,
  }));

  const handleFieldgroupSelection = useCallback(
    data => {
      getRulesForUsergroupFieldgroup({
        variables: {
          usergroupId,
          fieldgroupId: data.fieldgroupId,
        },
      });
    },
    [usergroupId, getRulesForUsergroupFieldgroup],
  );

  useEffect(() => {
    if (isFirstRender && fieldgroups && fieldgroups[0]) {
      setIsFirstRender(false);
      handleFieldgroupSelection(fieldgroups[0]);
    }
  }, [handleFieldgroupSelection, fieldgroups, isFirstRender]);

  const history = useHistory();

  const handleTabChange = (event, newTabValue) => {
    history.replace(newTabValue);
  };

  if (loading) return <CircularProgress />;
  if (error) return 'Check network tab';

  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {tabsViewData.map(tab => (
          <Tab label={tab.label} value={tab.value} key={tab.id} />
        ))}
      </Tabs>
      <TabPanel value={currentTab} index="rules" key="rules">
        <SideBarSectionsLayoutButtonVariant
          sectionList={fieldgroups}
          onClick={data => handleFieldgroupSelection(data)}
        >
          {rulesLoading ? (
            <CircularProgress />
          ) : (
            <Rules rulesData={rulesData} usergroupId={usergroupId} />
          )}
        </SideBarSectionsLayoutButtonVariant>
      </TabPanel>
      <TabPanel value={currentTab} index="users" key="users">
        <UserList />
      </TabPanel>
    </>
  );
};

export default Usergroup;
