import React from "react";
import CateringView from "./View";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
const Catering = () => {
  const { currentTab } = useParams();

  const history = useHistory();

  const handleTabChange = (event, newTabValue) => {
    event.preventDefault();
    history.replace(newTabValue);
  };

  return (
    <CateringView
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default Catering;
