import { gql } from '@apollo/client';

const UPDATE_OVERTIMERULES = gql`
  mutation UpdateOvertimerules($input: UpdateOvertimerulesMutationInput!) {
    updateOvertimerules(input: $input) {
      overtimerule{
        id
      }
    }
  }
`;

export default UPDATE_OVERTIMERULES;
