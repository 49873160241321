import React from 'react';
import {
  Button,
  FormControl,
  TextField,
  DialogActions, FormLabel, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
// import TipStyles from './styles';
import PropTypes from 'prop-types';
import { convert } from '../../../utils'
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { REFUNDREASONS } from '../../../apollo/queries';
import CREATE_REFUND from '../../../apollo/mutations/payment/createRefund';
import { SNACKBAR_STATUS } from '../../../constants';
import { Permission, PermissionField } from '../../../components/blocks';
import { useSnackbar } from '../../../hooks/useSnackbar';

const Refund = ({ payment, handleRefundModalOpen, refundPermission }) => {
  const { setSnackbar } = useSnackbar();
  const { register, control, handleSubmit } = useForm();

  const { data, loading } = useQuery(REFUNDREASONS);
  const [createRefund, { loading: createRefundLoading }] = useMutation(CREATE_REFUND, {
    onCompleted: () => {
      handleRefundModalOpen({
        open: false,
        payment: null
      });
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Refund was successful'
      });
    },
    onError: (error) => setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.ERROR,
      text: error.message
    })
  });
  // const classes = TipStyles();

  if(loading) <div>loading...</div>

  const handleRefund = variables => {
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text:'Refund in progress...'
    });
    createRefund(variables)
  };

  const onSubmit = data => {
    handleRefund({
      variables: {
        input: {
          amount: convert.dollarsToCents(data.amount),
          note: data.note,
          refundreasonId: btoa(`Refundreason:${data.refundreason}`),
          paymentId: payment?.node?.id
        }
      }
    })
  };

  return (
      <div style={{ width: "100%" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="amount"
          label="Amount"
          fullWidth
          inputRef={register}
          placeholder={payment?.node?.amount && `Maximum refund amount ${convert.centsToDollars(payment?.node?.amount)} $`}
          variant="outlined"
        />
        <br/>
        <br/>
          <Controller
            control={control}
            name="refundreason"
            defaultValue={1}
            render={({ onChange, value }) => {
              return (
                  <div>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Reason</FormLabel>
                      <RadioGroup value={+value} onChange={onChange} row>
                        {data?.viewer?.refundreasonConnection?.edges?.map((refundreason, index) => {
                          return (<FormControlLabel
                              value={+refundreason?.node?.refundreasonId}
                              key={refundreason?.node?.refundreasonId}
                              control={<Radio/>}
                              label={refundreason?.node?.label}
                          />)
                        })}
                      </RadioGroup>
                    </FormControl>
                  </div>
              );
            }}
              />
          <TextField
            name="note"
            inputRef={register}
            fullWidth
            rows={4}
            multiline
            placeholder="Enter Note"
            label="Note"
            variant="outlined"
          />
          <DialogActions>
            <Button variant="outlined" onClick={() => handleRefundModalOpen({
              open: false,
              payment: null
            })}>
              Cancel
            </Button>
            <Permission access={refundPermission.access}>
              <div>
                <PermissionField createHelperText>
                  <Button type="submit" variant="contained" disabled={createRefundLoading}>
                    Refund
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </DialogActions>
        </form>
    </div>
  );
};

export default Refund;

Refund.propTypes = {
  open: PropTypes.bool,
  quote: PropTypes.object,
  stateList: PropTypes.array,
  handleClose: PropTypes.func,
};
