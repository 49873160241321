import { gql } from "@apollo/client";

const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerMutationInput!) {
    createCustomer(input: $input) {
      loginEdge {
        node {
          id
          loginId
          phone
          email
          inactive
          person {
            id
            firstName
            lastName
            personId
            phone {
              id
              phone
            }
          }
        }
      }
    }
  }
`;

export default CREATE_CUSTOMER;
