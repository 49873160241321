import React, { useState, useMemo, useCallback } from 'react';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import { Elements, Permission, PermissionField } from '../../../../../../blocks';
import { Controller } from 'react-hook-form';
import { convert } from '../../../../../../../utils';
import { SwitchStyles } from './styles';
import { InfoOutlined } from '@material-ui/icons';
import { TooltipStyles } from '../../../../../../blocks/SelectFactory/styles';

const jobIncomeOptions = [
  {
    value: 1,
    label: 'Hourly',
  },
  {
    value: 2,
    label: 'Annually',
  },
];

const EmployeeJobs = ({
  errors,
  setValue,
  register,
  control,
  jobInfo,
  jobPermissions,
  hiddenTitle,
}) => {
  const switchClasses = SwitchStyles();
  const tooltipClasses = TooltipStyles();

  const smallerScreen = useMediaQuery('(max-width: 1279px)');

  const jobIncomeType = useMemo(
    () => jobInfo.personJob?.salaryType?.salarytypeId || jobIncomeOptions[0].value,
    [jobInfo.personJob?.salaryType?.salarytypeId],
  );

  const [jobIncomeTypeValue, setJobIncomeTypeValue] = useState(jobIncomeType);
  const [isChecked, setIsChecked] = useState(!!jobInfo.personJob?.person);

  const handleOnJobIncomeTypeChange = useCallback((onChange, value) => {
    onChange(value);
    setJobIncomeTypeValue(value);

    if (value === 2) {
      setValue(`jobs.${jobInfo.jobId}.payRate`, '0.00', { shouldDirty: true });
    }
  }, []);

  return (
    <Permission access={jobPermissions?.__typename}>
      <Grid container spacing={3} alignItems="center" style={{ marginBottom: '0.5rem' }}>
        <Grid container item xs={12} lg={7} alignItems="center">
          <Grid item xs={12} sm={6} md={4} direction="column">
            <Typography
              gutterBottom
              variant="caption"
              style={{ visibility: smallerScreen || !hiddenTitle ? 'unset' : 'hidden' }}
            >
              Job
            </Typography>
            <Permission access={jobPermissions?.label}>
              <PermissionField
                Component={FormControlLabel}
                label={jobInfo.label}
                createHelperText
                control={
                  <Checkbox
                    onClick={() => setIsChecked(prevState => !prevState)}
                    name={`jobs.${jobInfo.jobId}.enabled`}
                    defaultChecked={!!jobInfo?.personJob?.person}
                    inputRef={register}
                  />
                }
              />
            </Permission>
          </Grid>
          {isChecked && (
            <>
              <Permission
                access={jobPermissions?.personJobConnection?.edges?.node?.salaryType?.__typename}
              >
                <Grid item xs={12} sm={6} md={4} direction="column">
                  <Typography
                    gutterBottom
                    variant="caption"
                    style={{ visibility: smallerScreen || !hiddenTitle ? 'unset' : 'hidden' }}
                  >
                    Income
                  </Typography>
                  <Controller
                    name={`jobs.${jobInfo.jobId}.salaryTypeId`}
                    control={control}
                    defaultValue={jobIncomeType}
                    render={({ onChange, ref }) => (
                      <Permission
                        access={
                          jobPermissions?.personJobConnection?.edges?.node?.salaryType?.__typename
                        }
                      >
                        <div>
                          <PermissionField
                            Component={Elements.ToggleButton}
                            createHelperText
                            ref={ref}
                            options={jobIncomeOptions}
                            value={jobIncomeType}
                            handleToggleClick={isChecked =>
                              handleOnJobIncomeTypeChange(onChange, isChecked)
                            }
                          />
                        </div>
                      </Permission>
                    )}
                  />
                </Grid>
              </Permission>
              <Permission access={jobPermissions?.personJobConnection?.edges?.node?.salaryAmount}>
                <Grid item xs={12} sm={6} md={4} alignItems="center" style={{ marginTop: '20px' }}>
                  <TextField
                    label="$ Pay rate"
                    name={`jobs.${jobInfo.jobId}.payRate`}
                    inputRef={register({
                      // required: 'Pay rate must be filled',
                      valueAsNumber: 'Pay rate must be a number',
                    })}
                    disabled={jobIncomeTypeValue === 2}
                    error={!!errors.payRate}
                    helperText={errors[jobInfo.jobId]?.payRate?.message}
                    defaultValue={
                      jobInfo.personJob?.salaryAmount &&
                      convert.centsToDollars(jobInfo.personJob.salaryAmount)
                    }
                  />
                  {jobIncomeTypeValue === 2 ? (
                    <Tooltip
                      title={'Annual Salaries cannot be managed in Spark.'}
                      placement="top-end"
                      classes={tooltipClasses}
                      style={{ marginLeft: '4px' }}
                    >
                      <InfoOutlined color="action" />
                    </Tooltip>
                  ) : null}
                </Grid>
              </Permission>
            </>
          )}
        </Grid>
        {isChecked && (
          <Grid container item xs={12} lg={5} style={{ marginTop: smallerScreen ? '0px' : '20px' }}>
            <Permission access={jobPermissions?.personJobConnection?.edges?.node?.tipCredit}>
              <Grid item xs={12} sm={6} md={4} alignItems="center">
                <Grid item xs={12} direction="column">
                  <PermissionField
                    Component={FormControlLabel}
                    label="Tip credit"
                    createHelperText
                    classes={switchClasses}
                    labelPlacement="start"
                    control={
                      <Elements.Switch
                        checked={jobInfo.personJob?.tipCredit}
                        ref={register}
                        name={`jobs.${jobInfo.jobId}.tipCredit`}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Permission>
            <Permission access={jobPermissions?.personJobConnection?.edges?.node?.overtimeExempt}>
              <Grid item xs={12} sm={6} md={4} alignItems="center">
                <Grid item xs={12} direction="column">
                  <PermissionField
                    Component={FormControlLabel}
                    label="Overtime exempt"
                    createHelperText
                    classes={switchClasses}
                    labelPlacement="start"
                    control={
                      <Elements.Switch
                        checked={jobInfo.personJob?.overtimeExempt}
                        ref={register}
                        name={`jobs.${jobInfo.jobId}.overtimeExempt`}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Permission>
            <Permission access={jobPermissions?.personJobConnection?.edges?.node?.posManagerAccess}>
              <Grid item xs={12} sm={6} md={4} alignItems="center">
                <Grid item xs={12} direction="column">
                  <PermissionField
                    Component={FormControlLabel}
                    label="POS manager access"
                    createHelperText
                    classes={switchClasses}
                    labelPlacement="start"
                    control={
                      <Elements.Switch
                        checked={jobInfo.personJob?.posManagerAccess}
                        ref={register}
                        name={`jobs.${jobInfo.jobId}.posManagerAccess`}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Permission>
          </Grid>
        )}
      </Grid>
    </Permission>
  );
};

export default EmployeeJobs;
