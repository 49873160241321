import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Elements } from '../../../../../../../blocks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

function LocationAdvancedTimeSettingsModal({
  control,
  onDaysChange,
  locationId,
  locationSubcategoryWeekday,
  setShowAdvanced,
  locationSubcategoriesAdvanced,
  showAdvanced,
  toggleEnableAdvancedLocationDays,
  row,
}) {
  // const [disabledSelection, setDisalbedSelection] = useState(showAdvanced);
  const [renderdDays, setRenderdDays] = useState([]);
  const [extendedWeekdays, setExtendedWeekdays] = useState([
    {
      weekdayId: 1,
      name: 'Monday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 2,
      name: 'Tuesday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 3,
      name: 'Wednsday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 4,
      name: 'Thursday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 5,
      name: 'Friday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 6,
      name: 'Saturday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 7,
      name: 'Sunday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
  ]);

  useEffect(() => {
    let globalEnable = false;
    if (
      locationSubcategoriesAdvanced.filter(location => location.locationId === locationId)[0]?.days
    ) {
      const days = locationSubcategoriesAdvanced.filter(
        location => location.locationId === locationId,
      )[0].days;
      extendedWeekdays.forEach((day, i) => {
        days.forEach(edge => {
          if (edge.weekdayId === day.weekdayId && edge.enabled) {
            extendedWeekdays[i].locationId = locationId;
            extendedWeekdays[i].enabled = true;
            extendedWeekdays[i].startTime = edge.startTime;
            extendedWeekdays[i].endTime = edge.endTime;
            globalEnable = true;
          }
        });
      });
    } else {
      extendedWeekdays.forEach((day, i) => {
        locationSubcategoryWeekday?.locationSubcategoryWeekdayConnection?.edges.forEach(edge => {
          if (edge.node.weekdayId === day.weekdayId) {
            extendedWeekdays[i].locationId = locationId;
            extendedWeekdays[i].formatedStartTime = edge.node.startTime;
            extendedWeekdays[i].formatedEndTime = edge.node.endTime;
            extendedWeekdays[i].enabled = true;
            extendedWeekdays[i].startTime = {
              hours: +moment(extendedWeekdays[i].formatedStartTime.split(':')[0], 'h')
                .format('LT')
                .split(':')[0],
              minutes: +extendedWeekdays[i].formatedStartTime.split(':')[1],
              meridiem: moment(extendedWeekdays[i].formatedStartTime, 'h')
                .format('LT')
                .split(' ')[1]
                .toLowerCase(),
            };
            extendedWeekdays[i].endTime = {
              hours: +moment(extendedWeekdays[i].formatedEndTime.split(':')[0], 'h')
                .format('LT')
                .split(':')[0],
              minutes: +extendedWeekdays[i].formatedEndTime.split(':')[1],
              meridiem: moment(extendedWeekdays[i].formatedEndTime, 'h')
                .format('LT')
                .split(' ')[1]
                .toLowerCase(),
            };
            globalEnable = true;
          }
        });
      });
    }
    setExtendedWeekdays([...extendedWeekdays]);
    onDaysChange([...extendedWeekdays], locationId, globalEnable, row.available);
  }, []);

  useEffect(() => {
    renderDays();
  }, [showAdvanced, extendedWeekdays]);

  const handleDayUpdate = (e, index) => {
    if (e.target.checked) {
      extendedWeekdays[index].enabled = true;
    } else {
      extendedWeekdays[index].enabled = false;
    }
    if (!extendedWeekdays[index].locationId) {
      extendedWeekdays[index].locationId = locationId;
    }
    setExtendedWeekdays([...extendedWeekdays]);
    onDaysChange([...extendedWeekdays], locationId, true, row.available);
  };

  const handleTimeChange = (e, timeType, index) => {
    const value = e.target.value;
    switch (timeType) {
      case 'hours-start':
        extendedWeekdays[index].startTime.hours = +value;
        break;
      case 'minutes-start':
        extendedWeekdays[index].startTime.minutes = +value;
        break;
      case 'meridiem-start':
        extendedWeekdays[index].startTime.meridiem = value;
        break;
      case 'hours-end':
        extendedWeekdays[index].endTime.hours = +value;
        break;
      case 'minutes-end':
        extendedWeekdays[index].endTime.minutes = +value;
        break;
      case 'meridiem-end':
        extendedWeekdays[index].endTime.meridiem = value;
        break;
      default:
    }
    setExtendedWeekdays([...extendedWeekdays]);
    onDaysChange([...extendedWeekdays], locationId, true, row.available);
  };

  const getDefaultValue = (day, startOrEnd) => {
    if (startOrEnd === 'start') {
      return {
        hours: +day.startTime.hours || 12,
        minutes: +day.startTime.minutes,
        meridiem: day.startTime.meridiem || 'am',
      };
    }

    if (startOrEnd === 'end') {
      return {
        hours: +day.endTime.hours || 12,
        minutes: +day.endTime.minutes,
        meridiem: day.endTime.meridiem || 'pm',
      };
    }
  };

  const dayFormat = (day, index) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ minWidth: '7rem' }}>
          <FormControlLabel
            label={day.name}
            disabled={!showAdvanced}
            control={
              <Checkbox
                onChange={e => handleDayUpdate(e, index)}
                checked={day.enabled}
                name="type"
              />
            }
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: '1', width: '20rem' }}>
            <Elements.TimePicker
              control={control}
              hoursName={`startTimeHours-${index}`}
              minutesName={`startTimeMinutes-${index}`}
              amPmName={`startTimeType-${index}`}
              required
              disabled={!day.enabled}
              onHoursChange={e => handleTimeChange(e, 'hours-start', index)}
              onMinutesChange={e => handleTimeChange(e, 'minutes-start', index)}
              onMeridiemChange={e => handleTimeChange(e, 'meridiem-start', index)}
              defaultValues={getDefaultValue(day, 'start')}
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <h3>to</h3>
          </div>
          <div style={{ flexGrow: '1', width: '20rem' }}>
            <Elements.TimePicker
              control={control}
              hoursName={`endTimeHours-${index}`}
              minutesName={`endTimeMinutes-${index}`}
              amPmName={`endTimeType-${index}`}
              disabled={!day.enabled}
              required
              onHoursChange={e => handleTimeChange(e, 'hours-end', index)}
              onMinutesChange={e => handleTimeChange(e, 'minutes-end', index)}
              onMeridiemChange={e => handleTimeChange(e, 'meridiem-end', index)}
              defaultValues={getDefaultValue(day, 'end')}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days_temp = [];
    extendedWeekdays.forEach((day, i) => {
      days_temp.push(dayFormat(day, i));
    });
    setRenderdDays(days_temp);
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '4rem' }}>
        <div style={{ minWidth: '7rem' }}>
          <FormControlLabel
            label="Available on specific day of week"
            labelPlacement="start"
            style={{ marginLeft: '0' }}
            control={
              <Controller
                name="enableSpecificDaySelection"
                control={control}
                defaultValue={showAdvanced}
                render={props => (
                  <div style={{ marginLeft: '1rem' }}>
                    <Elements.Switch
                      onChange={e => {
                        props.onChange(e.target.checked);
                      }}
                      handleSwitchChange={e => {
                        props.onChange(e);
                        if (setShowAdvanced) {
                          setShowAdvanced(e);
                        }
                        if (toggleEnableAdvancedLocationDays) {
                          toggleEnableAdvancedLocationDays(e, locationId);
                        }
                      }}
                      checked={props.value}
                    />
                  </div>
                )}
              />
            }
          />
        </div>
        <div>{renderdDays}</div>
      </div>
    </div>
  );
}

export default LocationAdvancedTimeSettingsModal;
