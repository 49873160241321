import { gql } from "@apollo/client";

const FETCH_ITEM_LIST = gql`
  query itemList(
    $filter: [ItemFilter]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    viewer {
      id
      itemConnection(
        filter: $filter
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            itemId
            label
            subcategoryItemConnection {
              edges {
                node {
                  subcategory {
                    subcategoryId
                    menu {
                      menuId
                    }
                    category {
                      categoryId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_ITEM_LIST;
