/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import EditBankSettingsForm from "./EditBankSettingsForm";
import BankSettingsView from "./View";

import { Modal, Permission } from "../../../../../blocks";
import { MUTATION_NAME, SNACKBAR_STATUS } from "../../../../../../constants";

import { CREATE_LOCATION_STRIPE } from "../../../../../../apollo/mutations/locations";
import { FETCH_LOCATION } from "../../../../../../apollo/queries";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";

const BankSettings = ({
  bankInfo,
  stateList,
  locationInfo,
  permissions,
  mutationPermissions,
  accessToMutations,
  setOpenWarningMutationName,
}) => {
  const { setSnackbar } = useSnackbar();

  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [createLocationStripe] = useMutation(CREATE_LOCATION_STRIPE, {
    onCompleted: ({ createLocationStripe: { url } }) => {
      window.open(url);
    },
  });

  const stateListFormat = stateList?.edges?.map((state) => ({
    label: state.node.label,
    value: state.node.stateId,
  }));

  const handleOpenModal = () => {
    setShowModal(true);

    setOpenWarningMutationName(MUTATION_NAME.CreateLocationStripeMutationInput);
  };
  const handleCloseModal = () => {
    setDisableSubmitButton(false);
    setShowModal(false);
  };

  const handleOpenSnackBar = (text, type) => {
    setSnackbar({ open: true, type, text });
  };

  useEffect(() => {
    const search = location.search
      ? location.search.substring(1)
      : "message=none";
    JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    if (search.includes("Successfully")) {
      setSnackbar({
        open: true,
        type: "success",
        text: "Successfully connected to stripe",
      });
    }
  }, []);

  const onSubmit = async (formData) => {
    try {
      await createLocationStripe({
        variables: {
          input: formData,
        },
        refetchQueries: [
          {
            query: FETCH_LOCATION,
            variables: { id: formData.locationId, first: 2147483647 },
          },
        ],
      });
      setDisableSubmitButton(false);
      handleCloseModal();
      handleOpenSnackBar("Bank Settings added.", SNACKBAR_STATUS.SUCCESS);
    } catch (error) {
      setDisableSubmitButton(false);
      handleOpenSnackBar(error.message, SNACKBAR_STATUS.ERROR);
    }
  };

  return (
    <>
      <Permission access={permissions?.stripeAccount}>
        <BankSettingsView
          permission={permissions?.stripeAccount}
          bankInfo={bankInfo}
          handleOpenModal={handleOpenModal}
          mutationPermissions={mutationPermissions}
          accessToMutations={accessToMutations}
        />
        <Modal
          open={showModal}
          handleClose={handleCloseModal}
          title="Bank Settings"
          key="editBankSettings"
        >
          <EditBankSettingsForm
            bankInfo={bankInfo}
            onSubmit={onSubmit}
            locationInfo={locationInfo}
            stateList={stateListFormat}
            handleCloseModal={handleCloseModal}
            disableSubmitButton={disableSubmitButton}
            setDisableSubmitButton={setDisableSubmitButton}
            accessToMutations={accessToMutations}
            mutationPermissions={mutationPermissions}
          />
        </Modal>
      </Permission>
    </>
  );
};

BankSettings.propTypes = {
  bankInfo: PropTypes.object,
  isEditable: PropTypes.bool,
  stateList: PropTypes.object,
};

export default BankSettings;
