import { gql } from '@apollo/client';

const PRODUCTION_FORECASTER = gql`
  query productionForecaster(
    $id: ID!
    $orderingForecasterStart: String
    $orderingForecasterEnd: String
    $orderingForecasterDate: String
  ) {
    viewer {
      id
      location(id: $id, fieldGroupOnly: 92) {
        id
        storeNumber
        locationId
        label
        storeSpecificUsage
        prepCommitments(orderingForecasterStart: $orderingForecasterStart)
        forecastedOrderingPar(
          orderingForecasterStart: $orderingForecasterStart
          orderingForecasterEnd: $orderingForecasterEnd
        )
        dailyForecaster(orderingForecasterDate: $orderingForecasterDate)
        closingInventory
      }
    }
  }
`;

export default PRODUCTION_FORECASTER;
