import React, { useMemo, useState } from 'react';
import { Grid, Container, Typography, Button } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import clsx from 'clsx';
import {
  SideBarSectionsLayout,
  ContentInfo,
  Permission,
  PermissionField,
} from '../../../../../../components/blocks';
import OrderInfoStyles from './styles';
import moment from 'moment-timezone';
import Tip from '../../../../../blocks/Tip';
import Refund from '../../../../../blocks/Refund';
import { convert, phoneNumberFormat, generateOrderPdf } from '../../../../../../utils';
import { MUTATION_NAME } from '../../../../../../constants';
import { compact } from 'lodash';
import OrderTracker from './components/OrderTracker';
import Modal from '../../../../../blocks/Modal';

const CANADA_ALPHA_2 = 'CA';

const getComponentViewData = (hasOrderTracker, orderTrackerAccess) => [
  {
    sections: hasOrderTracker
      ? [
          { name: 'Quote Summary', id: 'quoteSummary' },
          { name: 'Location Info', id: 'locationInfo' },
          { name: 'Customer Info', id: 'customerInfo' },
          // { name: 'Order Items', id: 'orderItems' },
          { name: 'Payment Info', id: 'paymentInfo' },
          { name: 'Order Tracker', id: 'orderTracker', access: orderTrackerAccess },
        ]
      : [
          { name: 'Quote Summary', id: 'quoteSummary' },
          { name: 'Location Info', id: 'locationInfo' },
          { name: 'Customer Info', id: 'customerInfo' },
          { name: 'Payment Info', id: 'paymentInfo' },
        ],
  },
];

const OrderView = ({
  data,
  handleReprint,
  handleTipModalOpen,
  tipModalOpen,
  stateList,
  refundModalOpen,
  handleRefundModalOpen,
  handleTipModalClose,
  handleRefund,
  mutationPermissions,
  accessToMutations,
}) => {
  const classes = OrderInfoStyles();

  const [hasOrderTracker, setHasOrderTracker] = useState(false);

  const permissions = data?.viewer?.quote?.permissions;
  const addTipPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'addTip',
  );
  const exportPDFPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'exportPDF',
  );
  const reprintPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'reprint',
  );
  const refundPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'refund',
  );

  const componentViewData = useMemo(
    () => getComponentViewData(hasOrderTracker, data?.viewer?.quote?.permissions?.stage?.id),
    [hasOrderTracker, data?.viewer?.quote?.permissions?.stage?.id],
  );
  const handleCloseRefundModal = () => {
    handleRefundModalOpen(prevState => ({ ...prevState, open: false }));
  };

  const quoteSummaryData = [
    [
      {
        id: 'e4e75323-2e0b-4f84-9f98-6a48953e5eea',
        label: 'Quote',
        value: data?.viewer?.quote?.quoteId,
        permission: permissions?.quoteId,
      },
    ],
    [
      {
        id: 'a6690fc1-0fbc-4adf-a18b-ec77dab96122',
        label: 'Category',
        value: data?.viewer?.quote?.category?.label,
        permission: permissions?.category?.label,
      },
    ],
    [
      {
        id: 'fc9e27f2-71de-40ac-86c7-d56b3bdb224e',
        label: 'Handoff',
        value: data?.viewer?.quote?.handoff?.label,
        permission: permissions?.handoff?.label,
      },
    ],
    [
      {
        id: '28899ce6-3956-4d46-9b3b-8353efbefa56',
        label: 'Pickup Date & Time',
        value: data?.viewer?.quote?.expected
          ? moment
              .utc(data?.viewer?.quote?.expected)
              .tz(data?.viewer?.quote?.location?.timezone?.label)
              .format('MM/DD/YYYY hh:mm A')
          : '-',
        permission: permissions?.expected,
      },
    ],
    [
      {
        id: 'deliveryAddress',
        label: 'Delivery Address',
        value: permissions?.address?.address ? data?.viewer?.quote?.address?.address : '/',
      },
    ],
    [
      {
        id: 'city',
        label: 'City',
        value: permissions?.address?.city ? data?.viewer?.quote?.address?.city : '/',
      },
    ],
    [
      {
        id: 'state',
        label: 'State',
        value: permissions?.address?.state?.label
          ? data?.viewer?.quote?.address?.state?.label
          : '/',
      },
    ],
    [
      {
        id: 'zip',
        label: 'Zip Code',
        value: permissions?.address?.zip ? data?.viewer?.quote?.address?.zip : '/',
      },
    ],
  ];

  const locationInfoData = [
    [
      {
        id: 'e4e75323-2e0b-4f84-9f98-6a48953e5eea',
        label: 'Location',
        value: data?.viewer?.quote?.location?.label,
        permission: permissions?.location?.label,
      },
    ],
    [
      {
        id: 'a6690fc1-0fbc-4adf-a18b-ec77dab96122',
        label: 'Address',
        value: `${data?.viewer?.quote?.location?.address?.address}, ${data?.viewer?.quote?.location?.address?.city}, ${data?.viewer?.quote?.location?.address?.state?.abbreviation} ${data?.viewer?.quote?.location?.address?.zip}`,
        permission: Math.min(
          permissions?.location?.address?.address,
          permissions?.location?.address?.city,
          permissions?.location?.address?.state?.abbreviation,
          permissions?.location?.address?.zip,
        ),
      },
    ],
    [
      {
        id: 'fc9e27f2-71de-40ac-86c7-d56b3bdb224e',
        label: 'Phone',
        value: phoneNumberFormat(data?.viewer?.quote?.location?.phone?.phone),
        linkValue: `tel: ${data?.viewer?.quote?.location?.phone?.phone}`,
        permission: permissions?.location?.phone?.phone,
      },
    ],
    [
      {
        id: '28899ce6-3956-4d46-9b3b-8353efbefa56',
        label: 'Distance',
        value: data?.viewer?.quote?.distance,
        permission: permissions?.distance,
      },
    ],
  ];

  const customerInfoData = [
    [
      {
        id: 'e4e75323-2e0b-4f84-9f98-6a48953e5eea',
        label: 'First Name',
        value: data?.viewer?.quote?.person?.firstName,
        permission: permissions?.person?.firstName,
      },
    ],
    [
      {
        id: 'a6690fc1-0fbc-4adf-a18b-ec77dab96122',
        label: 'Last Name',
        value: data?.viewer?.quote?.person?.lastName,
        permission: permissions?.person?.lastName,
      },
    ],
    [
      {
        id: 'fc9e27f2-71de-40ac-86c7-d56b3bdb224e',
        label: 'Phone',
        value: phoneNumberFormat(data?.viewer?.quote?.person?.phone?.phone),
        linkValue: `tel: ${data?.viewer?.quote?.person?.phone?.phone}`,
        permission: permissions?.person?.phone?.phone,
      },
    ],
    [
      {
        id: '28899ce6-3956-4d46-9b3b-8353efbefa56',
        label: 'Email',
        value: data?.viewer?.quote?.email,
        linkValue: `mailto: ${data?.viewer?.quote?.email}`,
        permission: permissions?.email,
      },
    ],
    [
      {
        id: 'deliveryPartner',
        label: 'Delivery Partner',
        value:
          (data?.viewer?.quote?.doordashId && `Doordash ID - ${data?.viewer?.quote?.doordashId}`) ||
          (data?.viewer?.quote?.burqId && `Burq ID - ${data?.viewer?.quote?.burqId}`),
      },
    ],
  ];

  return (
    data?.viewer?.quote && (
      <>
        {componentViewData.map((tabPanel, index) => (
          <SideBarSectionsLayout key={index} sectionList={tabPanel.sections || []}>
            <Container>
              <Grid container className={classes.heading}>
                <Grid item xs={4} className={classes.flexDirection} id="quoteSummary">
                  <>
                    <Typography variant="h4" component="h2" className={classes.titlePadding}>
                      Quote Summary
                    </Typography>
                    <div className={classes.contentInfoHolder}>
                      <ContentInfo
                        id="quoteSummary"
                        contentInfo={quoteSummaryData}
                        title="quoteSummary"
                        numOfColumns={1}
                      />
                    </div>
                  </>
                </Grid>

                <Grid item xs={4} className={classes.flexDirection} id="locationInfo">
                  <>
                    <Typography variant="h4" component="h2" className={classes.titlePadding}>
                      Location Info
                    </Typography>
                    <div className={classes.contentInfoHolder}>
                      <ContentInfo
                        id="locationInfo"
                        contentInfo={locationInfoData}
                        title="locationInfo"
                        numOfColumns={1}
                      />
                    </div>
                  </>
                </Grid>

                <Grid item xs={4} className={classes.flexDirection} id="customerInfo">
                  <>
                    <Typography variant="h4" component="h2" className={classes.titlePadding}>
                      Customer Info
                    </Typography>
                    <div className={classes.contentInfoHolder}>
                      <ContentInfo
                        id="customerInfo"
                        contentInfo={customerInfoData}
                        title="customerInfo"
                        numOfColumns={1}
                      />
                    </div>
                  </>
                </Grid>
                <div className={classes.spaceBetween} id="orderItems">
                  <Typography variant="h4" component="h2" className={classes.titlePadding}>
                    Order Items
                  </Typography>
                  <Permission access={addTipPermission?.access}>
                    <Button variant="contained" onClick={() => handleTipModalOpen()}>
                      Add Tip
                    </Button>
                  </Permission>
                </div>
                <Grid container item xs={12} spacing={1} className={classes.borderBottom}>
                  <Permission access={permissions?.lineConnection?.edges?.node?.subcategory?.label}>
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Subcategory</Typography>
                    </Grid>
                  </Permission>
                  <Permission access={permissions?.lineConnection?.edges?.node?.item?.label}>
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Item</Typography>
                    </Grid>
                  </Permission>
                  <Permission access={permissions?.lineConnection?.edges?.node?.quantity}>
                    <Grid item xs={2} className={classes.centerContent}>
                      <Typography variant="subtitle2">Item Quantity</Typography>
                    </Grid>
                  </Permission>
                  <Permission
                    access={Math.min(
                      permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges?.node
                        ?.quantity,
                      permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges?.node
                        ?.choice?.label,
                    )}
                  >
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">Item Options</Typography>
                    </Grid>
                  </Permission>
                  <Permission
                    access={
                      permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges?.node
                        ?.price
                    }
                  >
                    <Grid item xs={1} className={classes.justifyContent}>
                      <Typography variant="subtitle2">Price</Typography>
                    </Grid>
                  </Permission>
                  <Permission access={permissions?.lineConnection?.edges?.node?.price}>
                    <Grid item xs={1} className={classes.justifyContent}>
                      <Typography variant="subtitle2">Unit Price</Typography>
                    </Grid>
                  </Permission>
                  <Permission access={permissions?.lineConnection?.edges?.node?.totalPrice}>
                    <Grid item xs={1} className={classes.justifyContent}>
                      <Typography variant="subtitle2">Price</Typography>
                    </Grid>
                  </Permission>
                </Grid>
                {data?.viewer?.quote?.lineConnection?.edges.map((line, index) => (
                  <React.Fragment key={index}>
                    <Grid container item xs={12} className={classes.paddingTop}>
                      <Permission
                        access={permissions?.lineConnection?.edges?.node?.subcategory?.label}
                      >
                        <Grid item xs={2}>
                          <Typography variant="body2">{line?.node?.subcategory?.label}</Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.lineConnection?.edges?.node?.item?.label}>
                        <Grid item xs={2}>
                          <Typography variant="body2">{line?.node?.item?.label}</Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.lineConnection?.edges?.node?.quantity}>
                        <Grid item xs={2} className={classes.centerContent}>
                          <Typography variant="body2">{line?.node?.quantity}</Typography>
                        </Grid>
                      </Permission>
                      <Permission
                        access={Math.min(
                          permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges
                            ?.node?.quantity,
                          permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges
                            ?.node?.choice?.label,
                        )}
                      >
                        <Grid item xs={3}>
                          <Grid container item xs={12}>
                            {line?.node?.lineMultipleConnection?.edges.map(
                              (lineMultiple, index) => (
                                <Grid
                                  key={`${index}-multipleChoiceLabel`}
                                  item
                                  xs={12}
                                  className={classes.paddingBottom}
                                >
                                  <Typography key={`${index}-multipleChoiceLabel`} variant="body2">
                                    {lineMultiple?.node?.quantity} x{' '}
                                    {lineMultiple?.node?.choice?.label}
                                  </Typography>
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </Grid>
                      </Permission>
                      <Permission
                        access={
                          permissions?.lineConnection?.edges?.node?.lineMultipleConnection?.edges
                            ?.node?.price
                        }
                      >
                        <Grid item xs={1}>
                          <Grid container item xs={12}>
                            {line?.node?.lineMultipleConnection?.edges.map(
                              (lineMultiple, index) => (
                                <Grid
                                  key={`${index}-multipleChoicePrice`}
                                  item
                                  xs={12}
                                  className={clsx(classes.justifyContent, classes.paddingBottom)}
                                >
                                  {lineMultiple?.node?.price && (
                                    <Typography variant="body2">
                                      ${convert.centsToDollars(lineMultiple?.node?.price)}
                                    </Typography>
                                  )}
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.lineConnection?.edges?.node?.price}>
                        <Grid item xs={1} className={classes.justifyContent}>
                          <Typography variant="body2">
                            {line?.node?.price
                              ? `$${convert.centsToDollars(line?.node?.price)}`
                              : ''}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.lineConnection?.edges?.node?.totalPrice}>
                        <Grid item xs={1} className={classes.justifyContent}>
                          <Typography variant="body2">
                            {line?.node?.totalPrice
                              ? `$${convert.centsToDollars(line?.node?.totalPrice)}`
                              : ''}
                          </Typography>
                        </Grid>
                      </Permission>
                    </Grid>
                    <Permission access={permissions?.lineConnection?.edges?.node?.note}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(
                            classes.borderBottom,
                            classes.paddingTop,
                            classes.paddingBottom,
                          )}
                        >
                          <Typography variant="body2">Note: {line?.node?.note}</Typography>
                        </Grid>
                      </Grid>
                    </Permission>
                  </React.Fragment>
                ))}
                <Grid container item xs={12} justify="flex-end" className={classes.paddingTop}>
                  <Grid item xs={2}>
                    <Grid item xs={12} className={classes.flexDirection}>
                      <Permission access={permissions?.subtotalPrice}>
                        <Grid item xs={12} className={classes.paddingBottom}>
                          <Typography variant="subtitle2">Subtotal</Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.discountAmount && permissions?.couponAmount}>
                        <Grid item xs={12} className={classes.paddingBottom}>
                          <Typography variant="subtitle2">Discount</Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.coupons}>
                        {data?.viewer?.quote?.coupons &&
                          data?.viewer?.quote?.coupons.length &&
                          data.viewer.quote.coupons.map(coupon => (
                            <Grid
                              item
                              xs={12}
                              className={classes.paddingBottom}
                              key={coupon.code || coupon.label}
                            >
                              <Typography variant="subtitle2">Coupon</Typography>
                            </Grid>
                          ))}
                      </Permission>
                      <Permission access={permissions?.additionalCharge}>
                        {data?.viewer?.quote?.additionalCharge?.serviceAndDeliveryFee ? (
                          <Grid item xs={12} className={classes.paddingBottom}>
                            <Typography variant="subtitle2">
                              {data?.viewer?.quote?.location?.address?.state?.country?.alpha2 ===
                              'CA'
                                ? 'Takeaway Bag:'
                                : 'Service & Delivery Fee:'}
                            </Typography>
                          </Grid>
                        ) : null}
                        {data?.viewer?.quote?.additionalCharge?.processingFee ? (
                          <Grid item xs={12} className={classes.paddingBottom}>
                            <Typography variant="subtitle2">Renew/Recycle Program</Typography>
                          </Grid>
                        ) : null}
                        {data?.viewer?.quote?.additionalCharge?.convenienceFee ? (
                          <Grid item xs={12} className={classes.paddingBottom}>
                            <Typography variant="subtitle2">Convenience Fee</Typography>
                          </Grid>
                        ) : null}
                      </Permission>
                      <Permission access={permissions?.totalTax}>
                        <Grid item xs={12} className={classes.paddingBottom}>
                          <Typography variant="subtitle2">Tax</Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.tip}>
                        <Grid item xs={12} className={classes.paddingBottom}>
                          <Typography variant="subtitle2">Tip</Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.totalPrice}>
                        <Grid item xs={12} className={classes.paddingBottom}>
                          <Typography variant="subtitle2" className={classes.boldLetters}>
                            Total
                          </Typography>
                        </Grid>
                      </Permission>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid item xs={12} className={classes.flexDirection}>
                      <Permission access={permissions?.subtotalPrice}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(classes.justifyContent, classes.paddingBottom)}
                        >
                          <Typography variant="body2">
                            ${convert.centsToDollars(data?.viewer?.quote?.subtotalPrice || 0)}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.discountAmount && permissions?.couponAmount}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(classes.justifyContent, classes.paddingBottom)}
                        >
                          <Typography variant="body2">
                            $
                            {convert.centsToDollars(
                              data?.viewer?.quote?.discountAmount +
                                data?.viewer?.quote?.couponAmount || 0,
                            )}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.coupons}>
                        {data?.viewer?.quote?.coupons &&
                          data?.viewer?.quote?.coupons.length &&
                          data.viewer.quote.coupons.map(coupon => (
                            <Grid
                              key={coupon.label || coupon.code}
                              item
                              xs={12}
                              className={clsx(classes.justifyContent, classes.paddingBottom)}
                            >
                              <Typography variant="body2">
                                {compact([coupon.label || coupon.code, coupon.description]).join(
                                  ' - ',
                                )}
                              </Typography>
                            </Grid>
                          ))}
                      </Permission>
                      <Permission access={permissions?.additionalCharge}>
                        {data?.viewer?.quote?.additionalCharge?.serviceAndDeliveryFee ? (
                          <Grid
                            item
                            xs={12}
                            className={clsx(classes.justifyContent, classes.paddingBottom)}
                          >
                            <Typography variant="body2">
                              $
                              {convert.centsToDollars(
                                data?.viewer?.quote?.additionalCharge?.serviceAndDeliveryFee || 0,
                              )}
                            </Typography>
                          </Grid>
                        ) : null}
                        {data?.viewer?.quote?.additionalCharge?.processingFee ? (
                          <Grid
                            item
                            xs={12}
                            className={clsx(classes.justifyContent, classes.paddingBottom)}
                          >
                            <Typography variant="body2">
                              $
                              {convert.centsToDollars(
                                data?.viewer?.quote?.additionalCharge?.processingFee || 0,
                              )}
                            </Typography>
                          </Grid>
                        ) : null}
                        {data?.viewer?.quote?.additionalCharge?.convenienceFee ? (
                          <Grid
                            item
                            xs={12}
                            className={clsx(classes.justifyContent, classes.paddingBottom)}
                          >
                            <Typography variant="body2">
                              $
                              {convert.centsToDollars(
                                data?.viewer?.quote?.additionalCharge?.convenienceFee || 0,
                              )}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Permission>
                      <Permission access={permissions?.totalTax}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(classes.justifyContent, classes.paddingBottom)}
                        >
                          <Typography variant="body2">
                            ${convert.centsToDollars(data?.viewer?.quote?.totalTax || 0)}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.tip}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(classes.justifyContent, classes.paddingBottom)}
                        >
                          <Typography variant="body2">
                            ${convert.centsToDollars(data?.viewer?.quote?.tip || 0)}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.totalPrice}>
                        <Grid
                          item
                          xs={12}
                          className={clsx(classes.justifyContent, classes.paddingBottom)}
                        >
                          <Typography variant="body2" className={classes.boldLetters}>
                            ${convert.centsToDollars(data?.viewer?.quote?.totalPrice || 0)}
                          </Typography>
                        </Grid>
                      </Permission>
                    </Grid>
                  </Grid>
                </Grid>
                <Permission access={permissions?.note}>
                  <Grid container item xs={12} className={classes.titlePadding}>
                    <Typography variant="body2">Special instructions</Typography>
                    <Typography variant="body2">{data?.viewer?.quote?.note}</Typography>
                  </Grid>
                </Permission>
                <div className={classes.paymentInfoHolder}>
                  <Grid container item xs={12} className={classes.borderBottom} id="paymentInfo">
                    <Grid item xs={12} className={classes.spaceBetween}>
                      <Typography variant="h4" component="h2" className={classes.titlePadding}>
                        Payment Info
                      </Typography>
                      <div className={classes.paymentInfoButtonGroup}>
                        <Permission access={exportPDFPermission?.access}>
                          <div>
                            <PermissionField createHelperText>
                              <Button
                                variant="outlined"
                                style={{ marginRight: '20px' }}
                                startIcon={<PictureAsPdfOutlinedIcon />}
                                onClick={() => generateOrderPdf(data?.viewer?.quote, 2)}
                              >
                                Export PDF
                              </Button>
                            </PermissionField>
                          </div>
                        </Permission>
                        <Permission
                          access={Math.min(
                            reprintPermission?.access,
                            accessToMutations?.[MUTATION_NAME.RePrintMutationInput],
                          )}
                        >
                          <div>
                            <PermissionField createHelperText>
                              <Button
                                variant="outlined"
                                onClick={() => handleReprint()}
                                startIcon={<PrintOutlinedIcon />}
                              >
                                Reprint
                              </Button>
                            </PermissionField>
                          </div>
                        </Permission>
                      </div>
                    </Grid>

                    <Permission access={permissions?.paymentConnection?.edges?.node?.paymentId}>
                      <Grid item xs={1}>
                        <Typography variant="subtitle2">Payment ID</Typography>
                      </Grid>
                    </Permission>
                    <Permission access={permissions?.paymentConnection?.edges?.node?.tender?.label}>
                      <Grid item xs={1}>
                        <Typography variant="subtitle2">Payment Method</Typography>
                      </Grid>
                    </Permission>
                    <Permission access={permissions?.person?.taxId}>
                      <Grid item xs={1}>
                        <Typography variant="subtitle2">Tax Exempt</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="subtitle2">Tax ID</Typography>
                      </Grid>
                    </Permission>
                    <Permission
                      access={
                        permissions?.paymentConnection?.edges?.node?.paymentGatewayConnection?.edges
                          ?.node?.label
                      }
                    >
                      <Grid item xs={2}>
                        <Typography variant="subtitle2">Card Number</Typography>
                      </Grid>
                    </Permission>
                    <Permission access={permissions?.created}>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2">Processed Date</Typography>
                      </Grid>
                    </Permission>
                    <Permission access={permissions?.paymentConnection?.edges?.node?.amount}>
                      <Grid item xs={1}>
                        <Typography variant="subtitle2">Paid</Typography>
                      </Grid>
                    </Permission>
                    <Permission access={permissions?.paymentConnection?.edges?.node?.refunded}>
                      <Grid item xs={1}>
                        <Typography variant="subtitle2">Refunded</Typography>
                      </Grid>
                    </Permission>
                    <Permission access={refundPermission?.access}>
                      <Grid item xs={1} />
                    </Permission>
                  </Grid>
                  {data?.viewer?.quote?.paymentConnection?.edges.map((payment, index) => (
                    <Grid
                      container
                      item
                      xs={12}
                      key={index}
                      className={clsx(
                        classes.borderBottom,
                        classes.paddingTop,
                        classes.paddingBottom,
                      )}
                    >
                      <Permission access={permissions?.paymentConnection?.edges?.node?.paymentId}>
                        <Grid item xs={1}>
                          <Typography variant="body2">{payment.node.paymentId}</Typography>
                        </Grid>
                      </Permission>
                      <Permission
                        access={permissions?.paymentConnection?.edges?.node?.tender?.label}
                      >
                        <Grid item xs={1}>
                          <Typography variant="body2">{payment.node.tender.label}</Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.person?.taxId}>
                        <Grid item xs={1}>
                          <Typography variant="body2">
                            {data?.viewer?.quote?.person?.taxId ? 'Yes' : 'No'}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.person?.taxId}>
                        <Grid item xs={1}>
                          <Typography variant="body2">
                            {data?.viewer?.quote?.person?.taxId || ''}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission
                        access={
                          permissions?.paymentConnection?.edges?.node?.paymentGatewayConnection
                            ?.edges?.node?.label
                        }
                      >
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            {payment?.node?.paymentGatewayConnection?.edges.length
                              ? `...X-${payment?.node?.paymentGatewayConnection?.edges[0]?.node?.label}`
                              : ''}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.created}>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            {moment(moment.utc(payment.node.created).toDate()).format(
                              'MMMM Do YYYY',
                            ) || ''}
                          </Typography>
                        </Grid>
                      </Permission>
                      {/*<Permission access={permissions?.paymentConnection?.edges?.node?.amount && permissions?.taxBasePrice && permissions?.totalPrice}>*/}
                      {/*  <Grid item xs={1}>*/}
                      {/*    <Typography variant='body2'>*/}
                      {/*      ${convert.centsToDollars(*/}
                      {/*      data?.viewer?.quote?.taxBasePrice **/}
                      {/*      (payment.node.amount /*/}
                      {/*        data?.viewer?.quote?.totalPrice)*/}
                      {/*    )}*/}
                      {/*    </Typography>*/}
                      {/*  </Grid>*/}
                      {/*</Permission>*/}
                      <Permission access={permissions?.paymentConnection?.edges?.node?.amount}>
                        <Grid item xs={1}>
                          <Typography variant="body2">
                            ${convert.centsToDollars(payment.node.amount)}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={permissions?.paymentConnection?.edges?.node?.refunded}>
                        <Grid item xs={1}>
                          <Typography variant="body2">
                            {payment.node.refunded
                              ? `$${convert.centsToDollars(payment.node.refunded)}`
                              : ''}
                          </Typography>
                        </Grid>
                      </Permission>
                      <Permission access={refundPermission?.access}>
                        <Grid item xs={1}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleRefundModalOpen({
                                open: true,
                                payment,
                              })
                            }
                          >
                            Refund
                          </Button>
                        </Grid>
                      </Permission>
                    </Grid>
                  ))}
                </div>
                <OrderTracker
                  accessToMutations={accessToMutations}
                  classes={classes}
                  access={data?.viewer?.quote?.permissions?.stage?.id}
                  setHasOrderTracker={setHasOrderTracker}
                  handoffId={data?.viewer?.quote?.handoff?.id}
                  stageId={data?.viewer?.quote?.stage?.id}
                  quoteId={data?.viewer?.quote?.id}
                />
              </Grid>
              <Tip
                open={tipModalOpen}
                quote={data?.viewer?.quote}
                stateList={stateList}
                handleClose={handleTipModalClose}
                addTipPermission={addTipPermission}
              />
              <Modal
                maxWidth="md"
                title={'Refund Payment'}
                open={refundModalOpen.open}
                handleClose={handleCloseRefundModal}
              >
                <Refund
                  open={refundModalOpen.open}
                  payment={refundModalOpen.payment}
                  handleRefundModalOpen={handleRefundModalOpen}
                  handleRefund={handleRefund}
                  refundPermission={refundPermission}
                />
              </Modal>
            </Container>
          </SideBarSectionsLayout>
        ))}
      </>
    )
  );
};

export default OrderView;
