import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import logo from '../../assets/images/logo2.png';
import { convert } from '../';
import { getDataUri } from '.';

const generateOrderPdf = (quote, destination, callback = null) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let pdf = null;
  getDataUri(logo, logoUri => {
    // change the time by location timezone offset
    function getDateTime(date, format) {
      const locationTZ = quote.location.timezone.label;
      return moment
        .utc(date)
        .tz(locationTZ)
        .format(format);
    }
    function multiples(line) {
      let res = '';
      let multipleChoiceMap = {};
      line.node.lineMultipleConnection.edges.forEach(edge => {
        let multipleLabel = edge.node.multiple && edge.node.multiple.label;
        let choiceLabel = edge.node.choice && edge.node.choice.label;
        if (multipleLabel in multipleChoiceMap) {
          multipleChoiceMap[multipleLabel].push(choiceLabel);
        } else {
          multipleChoiceMap[multipleLabel] = [choiceLabel];
        }
      });
      Object.keys(multipleChoiceMap).forEach(key => {
        res += `${key}: ${multipleChoiceMap[key].join()}\n`;
      });
      return res;
    }
    function lines(edge) {
      const line = [];
      line.push({
        border: [true, false, false, false],
        text: `${edge.node?.item?.label}`,
      });
      line.push({
        border: [false, false, false, false],
        text: `${edge.node.quantity}`,
      });
      line.push({
        border: [false, false, false, false],
        text: `$${convert.centsToDollars(edge.node.totalUnitPrice || 0)}`,
      });
      line.push({
        border: [false, false, true, false],
        text: `$${convert.centsToDollars(edge.node.totalPrice || 0)}`,
        alignment: 'right',
      });

      return line;
    }

    function multiplesInfo(edge) {
      const line = [];
      line.push({
        colSpan: 4,
        border: [true, false, true, edge.node.note ? false : true],
        text: `${multiples(edge)}`,
      });

      return line;
    }

    function itemsTable() {
      const body = [];
      body.push([
        { text: 'Item', style: 'tableHeader' },
        { text: 'Quantity', style: 'tableHeader' },
        { text: 'Unit Price', style: 'tableHeader' },
        { text: 'Price', style: 'tableHeader', alignment: 'right' },
      ]);

      quote.lineConnection.edges.forEach(edge => {
        body.push(lines(edge));
        body.push(multiplesInfo(edge));
        if (edge.node.note) {
          body.push([
            {
              border: [true, false, true, true],
              text: `Note: ${edge.node.note}`,
              italics: true,
              colSpan: 4,
              lineHeight: 1.5,
            },
          ]);
        }
      });

      const renewRecycleValue = convert.centsToDollars(quote.additionalCharge?.processingFee || 0);
      const convenienceFeeValue = convert.centsToDollars(
        quote.additionalCharge?.convenienceFee || 0,
      );
      const serviceAndDeliveryFeeValue = convert.centsToDollars(
        quote.additionalCharge?.serviceAndDeliveryFee || 0,
      );

      let textBody = 'Subtotal:\nDiscount:\n';
      let textPrice = `$${convert.centsToDollars(
        quote.subtotalPrice || 0,
      )}\n $${convert.centsToDollars(quote.couponAmount || 0)}\n`;

      if (renewRecycleValue > 0) {
        textBody += 'Renew/Recycle Program:\n';
        textPrice += `$${renewRecycleValue}\n`;
      }

      if (convenienceFeeValue > 0) {
        textBody += 'Convenience Fee:\n';
        textPrice += `$${convenienceFeeValue}\n`;
      }

      if (serviceAndDeliveryFeeValue > 0) {
        textBody +=
          quote?.location?.address?.state?.country?.alpha2 === 'CA'
            ? 'Takeaway Bag:\n'
            : 'Service & Delivery Fee:\n';
        textPrice += `$${serviceAndDeliveryFeeValue}\n`;
      }

      textBody += 'Tax:\nTip:\n';
      textPrice += `$${convert.centsToDollars(quote.totalTax || 0)}\n $${convert.centsToDollars(
        quote.tip || 0,
      )}\n`;

      body.push([
        '',
        {
          text: textBody,
          alignment: 'right',
        },
        '',
        {
          text: textPrice,
          alignment: 'right',
        },
      ]);
      body.push([
        '',
        { text: 'TOTAL:', bold: true, color: 'black', alignment: 'right' },
        '',
        {
          text: `$${convert.centsToDollars(quote.totalPrice || 0)}`,
          bold: true,
          color: 'black',
          alignment: 'right',
        },
      ]);

      return body;
    }

    function payment(edge) {
      const line = [];

      line.push(edge.node.paymentId);
      line.push(edge.node.tender.label);
      // eslint-disable-next-line no-unused-expressions
      quote.person ? line.push(quote.person.taxId ? 'Yes' : 'No') : line.push('');
      // eslint-disable-next-line no-unused-expressions
      quote.person ? line.push(quote.person.taxId) : line.push('');
      if (edge.node.paymentGatewayConnection.edges.length) {
        line.push(`...${edge.node.paymentGatewayConnection.edges[0].node.label}`);
      } else {
        line.push('');
      }
      line.push(moment(moment.utc(edge.node.created).toDate()).format('MMMM Do YYYY') || '');
      if (quote.quotecatering) {
        line.push(
          `${quote.quotecatering.cateringExpert.person.firstName} ${quote.quotecatering.cateringExpert.person.lastName}`,
        );
      }
      line.push(
        `$${convert.centsToDollars(quote.taxBasePrice * (edge.node.amount / quote.totalPrice))}`,
      );
      line.push(`$${convert.centsToDollars(edge.node.amount)}`);
      // eslint-disable-next-line no-unused-expressions
      edge.node.refunded
        ? line.push(`$${convert.centsToDollars(edge.node.refunded)}`)
        : line.push('');

      return line;
    }

    function paymentsTable() {
      const body = [];

      if (quote.quotecatering) {
        body.push([
          { text: 'Payment ID', style: 'tableHeader' },
          { text: 'Payment Method', style: 'tableHeader' },
          { text: 'Tax Exempt', style: 'tableHeader' },
          { text: 'Tax ID', style: 'tableHeader' },
          { text: 'Card Number', style: 'tableHeader' },
          { text: 'Processed Date', style: 'tableHeader' },
          { text: 'Catering Expert', style: 'tableHeader' },
          { text: 'Tax base', style: 'tableHeader' },
          { text: 'Paid', style: 'tableHeader' },
          { text: 'Refunded', style: 'tableHeader' },
        ]);
      } else {
        body.push([
          { text: 'Payment ID', style: 'tableHeader' },
          { text: 'Payment Method', style: 'tableHeader' },
          { text: 'Tax Exempt', style: 'tableHeader' },
          { text: 'Tax ID', style: 'tableHeader' },
          { text: 'Card Number', style: 'tableHeader' },
          { text: 'Processed Date', style: 'tableHeader' },
          { text: 'Tax base', style: 'tableHeader' },
          { text: 'Paid', style: 'tableHeader' },
          { text: 'Refunded', style: 'tableHeader' },
        ]);
      }

      quote.paymentConnection.edges.forEach(edge => body.push(payment(edge)));

      if (quote.quotecatering) {
        body.push([
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          {
            text: `Due Amount:\nTotal Paid:${
              quote.refunded ? '\nTotal Refunded:\nNet Payment:' : ''
            }`,
            bold: true,
            color: 'black',
          },
          quote.refunded
            ? [
                {
                  text: `$${convert.centsToDollars(
                    quote.totalPrice - quote.paid + quote.refunded,
                  )}`,
                  bold: true,
                  color: 'red',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.paid)}`,
                  bold: true,
                  color: 'black',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.refunded)}`,
                  bold: true,
                  color: 'green',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.totalPrice - quote.refunded)}`,
                  bold: true,
                  color: 'black',
                  alignment: 'right',
                },
              ]
            : [
                {
                  text: `$${convert.centsToDollars(quote.totalPrice - quote.paid)}`,
                  bold: true,
                  color: 'red',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.paid)}`,
                  bold: true,
                  color: 'black',
                  alignment: 'right',
                },
              ],
        ]);
      } else {
        body.push([
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          {
            text: `Due Amount:\nTotal Paid:${
              quote.refunded ? '\nTotal Refunded:\nNet Payment:' : ''
            }`,
            bold: true,
            color: 'black',
          },
          quote.refunded
            ? [
                {
                  text: `$${convert.centsToDollars(
                    quote.totalPrice - quote.paid + quote.refunded,
                  )}`,
                  bold: true,
                  color: 'red',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.paid)}`,
                  bold: true,
                  color: 'black',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.refunded)}`,
                  bold: true,
                  color: 'green',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.totalPrice - quote.refunded)}`,
                  bold: true,
                  color: 'black',
                  alignment: 'right',
                },
              ]
            : [
                {
                  text: `$${convert.centsToDollars(quote.totalPrice - quote.paid)}`,
                  bold: true,
                  color: 'red',
                  alignment: 'right',
                },
                {
                  text: `$${convert.centsToDollars(quote.paid)}`,
                  bold: true,
                  color: 'black',
                  alignment: 'right',
                },
              ],
        ]);
      }

      return body;
    }

    const docDefinition = {
      pageMargins: [20, 80, 20, 20],
      header: {
        columns: [
          {
            image: logoUri,
            width: 100,
          },
          {
            alignment: 'right',
            text: quote.quotecatering
              ? `* 1.866.BARBECUE (227-2328)*\n\nCatering Quote #${quote.quoteId}`
              : `Quote #${quote.quoteId}`,
            margin: [0, 35, 20, 20],
          },
        ],
      },
      content: [
        {
          alignment: 'left',
          columns: [
            {
              text: 'Quote Summary',
              style: 'header',
            },
            {
              text: 'Location Info',
              style: 'header',
            },
            {
              text: 'Customer Info',
            },
          ],
          style: 'header',
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 5,
              x2: 595 - 80,
              y2: 5,
              lineWidth: 3,
              color: '#ffda00',
            },
          ],
        },
        '\n',
        {
          alignment: 'left',
          columns: [
            quote.quotecatering
              ? {
                  text: `Catering Expert: ${
                    quote.quotecatering.cateringExpert
                      ? quote.quotecatering.cateringExpert.person.firstName
                      : ''
                  } ${
                    quote.quotecatering.cateringExpert
                      ? quote.quotecatering.cateringExpert.person.lastName
                      : ''
                  }\n
                            Contact Info: 1866 BARBECUE (227-2328)\n
                            Status: ${
                              quote.quotecatering.status ? quote.quotecatering.status.label : ''
                            }\n
                            Quote: #${quote.quoteId}`,
                }
              : {
                  text: `Quote: #${quote.quoteId}\n
                           Handoff: ${quote.handoff ? quote.handoff.label : ''}\n
                           ${quote.handoff ? quote.handoff.label : 'Handoff'} Time: ${
                    quote.expected ? getDateTime(quote.expected, 'MMMM Do YYYY hh:mm A') : ''
                  }\n
                        ${
                          quote.handoff.id === 'SGFuZG9mZjozOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw'
                            ? `Delivery Address: ${quote.address ? quote.address.address : ''}\n
                            City: ${quote.address ? quote.address.city : ''}\n
                            State: ${quote.address ? quote.address.state.label : ''}\n
                            Zip Code: ${quote.address ? quote.address.zip : ''}\n
                        `
                            : ''
                        }`,
                },
            {
              text: `Location: ${quote.location ? quote.location.label : ''}\n
                            Address: ${quote.location ? quote.location.address.address : ''},
                                    ${quote.location ? quote.location.address.city : ''},${
                quote.location ? quote.location.address.state.abbreviation : ''
              } ${quote.location ? quote.location.address.zip : ''}\n
                            Phone: ${quote.location ? quote.location.phone.phone : ''}\n
                            Distance: ${quote.distance}`,
            },
            {
              text: `First Name: ${quote.person ? quote.person.firstName : ''}\n
                            Last Name:  ${quote.person ? quote.person.lastName : ''}\n
                            Phone: ${
                              quote.person && quote.person.phone ? quote.person.phone.phone : ''
                            }\n
                            Email: ${quote.email || ''}\n`,
            },
          ],
        },
        '\n\n',
        {
          alignment: 'left',
          columns: [
            quote.quotecatering && {
              width: '33%',
              text: 'Event Info',
            },
            {
              width: quote.quotecatering ? '67%' : '100%',
              text: 'Order Items',
            },
          ],
          style: 'header',
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 5,
              x2: 595 - 80,
              y2: 5,
              lineWidth: 3,
              color: '#ffda00',
            },
          ],
        },
        '\n',
        {
          alignment: 'left',
          columns: [
            quote.quotecatering && {
              width: '33%',
              stack: [
                quote.quotecatering && {
                  text: `Event Type: ${
                    quote.quotecatering.eventType ? quote.quotecatering.eventType.label : ''
                  }\n
                              Number of Guests: ${quote.quotecatering.numberOfCustomers || ''}\n\n`,
                },
                quote.quotecatering && {
                  text: `Event Date: ${
                    quote.quotecatering.eventDate
                      ? `${getDateTime(
                          quote.quotecatering.eventDate,
                          'MMMM Do YYYY',
                        )} ${getDateTime(quote.quotecatering.eventDate, 'hh:mm A')}`
                      : ''
                  }\n\n`,
                  background: '#faff00',
                },
                {
                  text: `Handoff: ${quote.handoff ? quote.handoff.label : ''}\n\n`,
                },
                {
                  text: `${quote.handoff && 'Handoff'} Time: ${
                    quote.expected ? getDateTime(quote.expected, 'MMMM Do YYYY hh:mm A') : ''
                  }\n`,
                  background: '#faff00',
                },
                quote.handoff.id === 'SGFuZG9mZjozOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw'
                  ? {
                      text: `
                            Location Type: ${
                              quote.quotecatering.locationType
                                ? quote.quotecatering.locationType.label
                                : ''
                            }\n
                            Company Name: ${quote.quotecatering.companyName || ''}\n
                            Delivery Street Address: ${quote.address ? quote.address.address : ''}\n
                            Delivery Street Address Suite: ${quote.quotecatering.instructions ||
                              ''}\n
                            City: ${quote.address ? quote.address.city : ''}\n
                            State: ${quote.address ? quote.address.state.label : ''}\n
                            Zip Code: ${quote.address ? quote.address.zip : ''}\n
                            Contact Name: ${quote.quotecatering.contactName || ''}\n
                            Contact Phone: ${quote.quotecatering.contactPhone || ''}\n
                            Additional Info: ${quote.quotecatering.additionalInfo || ''}\n`,
                    }
                  : {
                      text: `
                            Location Type: ${
                              quote.quotecatering.locationType
                                ? quote.quotecatering.locationType.label
                                : ''
                            }\n
                            Company Name: ${quote.quotecatering.companyName || ''}\n
                            Contact Name: ${quote.quotecatering.contactName || ''}\n
                            Contact Phone: ${quote.quotecatering.contactPhone || ''}\n
                            Additional Info: ${quote.quotecatering.additionalInfo || ''}\n`,
                    },
              ],
            },
            {
              width: quote.quotecatering ? '67%%' : '100%%',
              stack: [
                {
                  table: {
                    widths: ['25%', '35%', '20%', '20%'],
                    margin: [10, 0, 0],
                    body: itemsTable(),
                  },
                  layout: {
                    hLineWidth() {
                      return 1;
                    },
                    vLineWidth(i, node) {
                      return i === 0 || i === node.table.widths.length ? 1 : 0;
                    },
                    hLineColor() {
                      return 'lightgray';
                    },
                    vLineColor() {
                      return 'lightgray';
                    },
                    paddingBottom() {
                      return 4;
                    },
                    paddingTop() {
                      return 4;
                    },
                  },
                },
                {
                  text: `\nSpecial Instructions:  ${quote.note ? quote.note : '/'}`,
                },
              ],
            },
          ],
        },
        '\n\n',
        {
          text: 'Payment Info',
          style: 'header',
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 5,
              x2: 595 - 80,
              y2: 5,
              lineWidth: 3,
              color: '#ffda00',
            },
          ],
        },
        '\n',
        {
          table: {
            widths: quote.quotecatering
              ? ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']
              : ['*', '*', '6%', '*', '8%', 'auto', '*', 'auto', 'auto'],
            body: paymentsTable(),
          },
          layout: {
            hLineWidth() {
              return 1;
            },
            vLineWidth(i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0;
            },
            hLineColor() {
              return 'lightgray';
            },
            vLineColor() {
              return 'lightgray';
            },
            paddingBottom() {
              return 4;
            },
            paddingTop() {
              return 4;
            },
          },
        },
        quote.quotecatering && {
          italics: true,
          bold: true,
          text:
            '\nAll quotes are honored for 60 days.\nFull payment is required to confirm your event. A 50% deposit may also be taken to reserve your event date and time. All refunds and cancellations are upon the local stores discretion. Delivery fees vary by location. An additional delivery fee may be added to the catering total of this order upon the local stores’ discretion.',
        },
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          color: 'black',
        },
        tableHeader: {
          color: 'black',
        },
      },
      defaultStyle: {
        fontSize: 8,
        color: '#444444',
      },
    };

    if (destination === 1) {
      pdfMake.createPdf(docDefinition).open();
    } else if (destination === 2) {
      pdfMake
        .createPdf(docDefinition)
        .download(
          quote.quotecatering ? `${quote.quoteId}_Catering_Quote` : `${quote.quoteId}_Quote`,
        );
    } else {
      pdf = pdfMake.createPdf(docDefinition);
      callback(pdf);
    }
  });
};

export default generateOrderPdf;
