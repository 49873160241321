import { gql } from '@apollo/client';

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerMutationInput!) {
    updateCustomer(input: $input) {
      person {
        id
        dateOfBirth
        firstName
        lastName
        personId
        address {
          id
          address
          city
          zip
          additionalInfo
          state {
            label
            stateId
          }
        }
        login {
          id
          loginId
          referralCode
          lifetimePoints
          email
          spendablePoints
          phone
          brand {
            id
            brandId
            label
          }
        }
      }
    }
  }
`;

export default UPDATE_CUSTOMER;
