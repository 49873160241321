import React from "react";
import LoyaltyCustomerView from "./View";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function LoyaltyCustomer() {
  const { currentTab } = useParams();

  const history = useHistory();

  const handleTabChange = (event, newTabValue) => {
    event.preventDefault();
    history.replace(newTabValue);
  };
  return (
    <LoyaltyCustomerView
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
}
export default LoyaltyCustomer;
