import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import { parse } from "graphql";

const UncontrolledCurrencyInput = ({ name, setValue, register, defaultValue, label, onValueChange, rules, helperText, error, fullWidth }) => {
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    register(name, rules);
    setValue(name, null);
  }, []);

  return (
    <NumberFormat
      prefix={`$`}
      decimalScale={2}
      label={label}
      error={error}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      helperText={helperText}
      onValueChange={e => {
        onValueChange && onValueChange(e);
        setValue(name, e.floatValue);
      }}
      thousandSeparator
      customInput={TextField}
    />
  );
};

export default UncontrolledCurrencyInput;
