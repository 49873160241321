import { gql } from '@apollo/client';

const ADD_REMOVE_LOYALTY_POINTS_FROM_FILE = gql`
  mutation addRemovePointsFromFile($base64File: String!) {
    addRemovePointsFromFile(input: { base64File: $base64File }) {
      reportUrl
    }
  }
`;

export default ADD_REMOVE_LOYALTY_POINTS_FROM_FILE;
