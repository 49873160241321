import { gql } from '@apollo/client';
import { timezoneFragment } from '../../fragments/timezoneFragment';
import { taxGroupFragment } from '../../fragments/taxGroupFragment';
import { locationAddressFragment } from '../../fragments';

const UPDATE_LOCATION_INFO = gql`
  mutation UpdateLocation($input: UpdateLocationMutationInput!, $locationId: ID!) {
    updateLocation(input: $input) {
      viewer {
        id
        location(id: $locationId) {
          id
          tierId
          locationId
          label
          locationPicture
          locationInfo
          cateringInfo
          receiptFooter
          printerPassword
          email
          opened
          closed
          phone {
            id
            phone
          }
          timezone {
            ...TimezoneData
          }
          taxgroup {
            ...TaxGroupData
          }
          address {
            ...LocationAddressData
          }
          automaticTracker
          hygieneChitReminder
        }
      }
    }
  }
  ${timezoneFragment.timezone}
  ${taxGroupFragment.taxGroup}
  ${locationAddressFragment.address}
`;

export default UPDATE_LOCATION_INFO;
