import React from 'react';
import { Grid, Typography, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ConnectionTable } from '../../../../../../../blocks';
import LOYALTY_CLAIMED_RECEIPT from '../../../../../../../../apollo/queries/loyalty/loyaltyClaimedReceipt';
import { generateStoreAbbreviation } from '../../../../../../../../utils';
import { useParams } from 'react-router';
import { convert } from '../../../../../../../../utils';
import { COLOR } from '../../../../../../../../constants';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { SelectStyle } from './../../../../../LoyaltySubmissions/View/style';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import LOYALTY_CLAIMED_RECEIPT_STATUS from '../../../../../../../../apollo/mutations/loyalty/loyaltyClaimedReceiptStatus';
export default function LoyaltySubmissionView() {
  const { customerId } = useParams();
  const selectClasses = SelectStyle();
  const { setSnackbar } = useSnackbar();

  const [
    updateLoyaltyClaimedReceipt,
    { loading: updateLoyaltyClaimedReceiptLoading },
  ] = useMutation(LOYALTY_CLAIMED_RECEIPT_STATUS);

  const updateLoyaltyClaimedReceiptStatus = (id, status) => {
    updateLoyaltyClaimedReceipt({
      variables: {
        input: {
          id,
          status,
        },
      },
    })
      .then(() => {
        setSnackbar({
          text: 'Loyalty receipt status updated!.',
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
        });
      })
      .catch(e =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        }),
      );
  };
  return (
    <div id={'loyaltySubmissions'}>
      <Grid container item xs={12} justify="space-between">
        <Typography variant="h4" component="h2">
          Loyalty Submissions
        </Typography>
      </Grid>
      <ConnectionTable
        query={LOYALTY_CLAIMED_RECEIPT}
        showSearch={false}
        // fetchPolicy={'network-only'}
        initialQueryVariables={{
          first: 5,
          sort: [
            {
              expected: 'DESC',
            },
          ],
          filter: {
            personId: {
              eq: parseInt(customerId),
            },
          },
        }}
        structureTable={data =>
          data?.viewer?.loyaltyclaimedreceiptConnection?.edges.map(loylatyClaimedReceipt => {
            return {
              personId: loylatyClaimedReceipt?.node?.person?.personId,
              brandLabel: data?.viewer?.brandConnection?.edges?.filter(
                brand => brand?.node?.brandId === loylatyClaimedReceipt.node?.brandId,
              )[0]?.node?.label,
              email: loylatyClaimedReceipt?.node?.person?.login?.email,
              id: loylatyClaimedReceipt?.node?.id,
              submissionDate: moment(loylatyClaimedReceipt?.node?.created).format('MM/DD/YYYY'),
              dateOfVisit: moment(loylatyClaimedReceipt?.node?.dateOfVisit).format('MM/DD/YYYY'),
              location: generateStoreAbbreviation(loylatyClaimedReceipt?.node?.location)
                .abbreviation,
              totalPrice: `$ ${convert.centsToDollars(loylatyClaimedReceipt?.node?.totalAmount)}`,
              receiptNumber: loylatyClaimedReceipt?.node?.receiptNumber,
              requestStatus: `${loylatyClaimedReceipt?.node.loyaltyclaimedreceiptId}:${loylatyClaimedReceipt?.node?.approved}`,
            };
          })
        }
        columns={[
          { title: 'Person Id', field: 'personId', disableSort: true },
          { title: 'Brand', field: 'brandLabel' },
          { title: 'Email', field: 'email', disableSort: true },
          { title: 'Submission Date', field: 'submissionDate' },
          {
            title: 'Location Id',
            field: 'location',
            disableSort: true,
          },
          {
            title: 'Date of Visit',
            field: 'dateOfVisit',
            disableSort: true,
          },
          {
            title: 'Receipt Number',
            field: 'receiptNumber',
            disableSort: true,
            cellStyle: {
              color: COLOR.INFO,
            },
          },
          { title: 'Total Amount', field: 'totalPrice', disableSort: true },
          {
            title: 'Request Status',
            field: 'requestStatus',
            render: value => {
              let status = value.split(':')[1];
              if (status === 'null') {
                status = 'PENDING';
              } else if (status === 'true') {
                status = 'APPROVED';
              } else {
                status = 'DECLINED';
              }
              const loyaltyClaimedReceiptId = value.split(':')[0];
              return (
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{
                    boxShadow: 'none',
                  }}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    fullWidth
                    defaultValue={status}
                    disableUnderline
                    classes={{
                      select:
                        status === 'PENDING'
                          ? selectClasses.selectYellow
                          : status === 'APPROVED'
                          ? selectClasses.selectGreen
                          : selectClasses.selectRed,
                    }}
                    onChange={event => {
                      const value = event.target.value;
                      updateLoyaltyClaimedReceiptStatus(loyaltyClaimedReceiptId, value);
                    }}
                    label="Status"
                  >
                    <MenuItem value="PENDING" style={{ color: COLOR.WARNING }} disabled>
                      <em>PENDING</em>
                    </MenuItem>
                    <MenuItem key={'inactive'} value={'APPROVED'} style={{ color: COLOR.SUCCESS }}>
                      APPROVED
                    </MenuItem>

                    <MenuItem key={'active'} value={'DECLINED'} style={{ color: COLOR.ERROR }}>
                      DECLINED
                    </MenuItem>
                  </Select>
                </FormControl>
              );
            },
          },
        ]}
      />
    </div>
  );
}
