import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button, Grid, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { FETCH_PERSONS } from "../../../../../apollo/queries/fetchLocationPersons";
import { FETCH_HIERARCHY_USERS } from "../../../../../apollo/queries";
import { HierarchyStyles } from "../View/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const UserDataGrid = ({
  userIds,
  setUserIds,
  buttonDisabled,
  sendUsersAndLocations,
  locationId,
  reset,
  setReset,
  preSelectedUsers,
  loginIds,
  setLoginIds,
  isHierarchyDetails,
  bulkUpdateUsersState,
  setBulkUpdateUsersState,
  area,
}) => {
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [users, setUsers] = useState([]);
  const [preSelected, setPreselected] = useState(preSelectedUsers || []);
  const [filter, setFilter] = useState({});
  const classes = HierarchyStyles();

  useEffect(() => {
    if (locationId) {
      setFilter({
        showOnlyEmployees: { eq: true },
        showEmployesOnLocation: locationId,
      });
    } else if (!locationId) {
      setFilter({ showOnlyEmployees: { eq: true } });
    }
  }, [locationId]);

  const [fetchPeople, { data, error, loading }] = useLazyQuery(FETCH_PERSONS, {
    variables: {
      filter,
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.log(error, "error");
    },
    onCompleted: (data) => {
      if (locationId) {
        setPreselected(
          data?.viewer?.loginConnection?.edges.map(
            (edge) => edge.node.person.personId
          )
        );
      }
      setUsers(
        data?.viewer?.loginConnection?.edges.map((edge) => ({
          id: edge.node.person.personId,
          firstName: edge.node.person.firstName,
          lastName: edge.node.person.lastName,
          loginId: edge.node.person.login.loginId,
        }))
      );
    },
    fetchPolicy: "network-only",
  });

  const [
    fetchHierarchyUsers,
    { loading: loadingHierarchyUsers, data: hierarchyData },
  ] = useLazyQuery(FETCH_HIERARCHY_USERS, {
    onCompleted: (data) => {
      setPreselected(
        data.viewer.hierarchy.loginConnection.edges.map(
          (edge) => edge.node.person.personId
        )
      );
      setUsers(
        data?.viewer?.hierarchy.loginConnection?.edges.map((edge) => ({
          id: edge.node.person.personId,
          firstName: edge.node.person.firstName,
          lastName: edge.node.person.lastName,
          loginId: edge.node.loginId,
        }))
      );
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (isHierarchyDetails) {
      fetchHierarchyUsers({
        variables: {
          id: area.id,
        },
      });
    } else {
      fetchPeople();
    }
  }, [area, locationId]);

  React.useEffect(() => {
    if (reset) {
      setPreselected([]);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (search.length !== 0) {
      setSearchResult(
        users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(search) ||
            user.lastName.toLowerCase().includes(search)
        )
      );
    } else {
      if (locationId || isHierarchyDetails) {
        if (locationId) {
          setPreselected(
            data?.viewer?.loginConnection?.edges.map(
              (edge) => edge.node.person.personId
            )
          );
        }
        if (isHierarchyDetails) {
          setPreselected(
            hierarchyData?.viewer?.hierarchy.loginConnection?.edges.map(
              (edge) => edge.node.person.personId
            )
          );
        }
      }
      setSearchResult([]);
    }
  }, [search]);

  // useEffect(() => {
  //   const addEvent = (e) => {
  //     console.log("click");
  //     if (e.currentTarget.checked) {
  //       setBulkUpdateUsersState({ action: "add" });
  //     } else {
  //       setBulkUpdateUsersState({ action: "remove" });
  //     }
  //   };
  //   if (
  //     document.querySelector(
  //       ".usersAll .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //     )
  //   ) {
  //     document
  //       .querySelector(
  //         ".usersAll .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //       )
  //       .addEventListener("change", addEvent);
  //     return function() {
  //       if (
  //         document.querySelector(
  //           ".usersAll .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //         )
  //       ) {
  //         document
  //           .querySelector(
  //             ".usersAll .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //           )
  //           .removeEventListener("change", addEvent);
  //       }
  //     };
  //   }

  //   if (
  //     document.querySelector(
  //       ".usersLocations .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //     )
  //   ) {
  //     document
  //       .querySelector(
  //         ".usersLocations .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //       )
  //       .addEventListener("change", addEvent);
  //     return function() {
  //       if (
  //         document.querySelector(
  //           ".usersLocations .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //         )
  //       ) {
  //         document
  //           .querySelector(
  //             ".usersLocations .MuiDataGrid-colCellCheckbox input[type='checkbox']"
  //           )
  //           .removeEventListener("change", addEvent);
  //       }
  //     };
  //   }
  // });

  const searchClasses = useStyles();

  if (loading) {
    return "Loading...";
  }

  if (error) console.log(error);

  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "firstName", headerName: "First Name", width: 180 },
    { field: "lastName", headerName: "Last Name", width: 180 },
  ].map((column) => ({
    ...column,
    disableClickEventBubbling: true,
  }));

  const handleRowSelect = async (e) => {
    let newUserIds = userIds;
    let newLoginIds = loginIds;
    if (newLoginIds) {
      newLoginIds = newLoginIds.filter((login) => login.id !== e.data.loginId);
    }
    if (newUserIds) {
      newUserIds = newUserIds.filter((loc) => loc.id !== e.data.id);
    }
    if (!e.isSelected) {
      if (userIds) {
        setUserIds([...newUserIds, { action: "remove", id: e.data.id }]);
      }
      if (loginIds) {
        setLoginIds([...newLoginIds, { action: "remove", id: e.data.loginId }]);
      }
    } else {
      if (userIds) {
        setUserIds([...newUserIds, { action: "add", id: e.data.id }]);
      }
      if (loginIds) {
        setLoginIds([...newLoginIds, { action: "add", id: e.data.loginId }]);
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h2">
          Users
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputBase
          className={searchClasses.input}
          placeholder="Search Users"
          inputProps={{ "aria-label": "search users" }}
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
        />
        <IconButton className={searchClasses.iconButton} aria-label="search">
          {search.length !== 0 ? (
            <CloseIcon onClick={() => setSearch("")} />
          ) : (
            <SearchIcon />
          )}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          onColumnHeaderClick={(e) => {
            if (e.field === "__check__") {
              if (locationId && bulkUpdateUsersState.action === "none") {
                setBulkUpdateUsersState({ action: "remove" });
                return;
              }
              if (bulkUpdateUsersState.action === "none") {
                setBulkUpdateUsersState({ action: "add" });
              }
              if (bulkUpdateUsersState.action === "add") {
                setBulkUpdateUsersState({ action: "remove" });
              }
              if (bulkUpdateUsersState.action === "remove") {
                setBulkUpdateUsersState({ action: "add" });
              }
            }
          }}
          autoHeight={true}
          columns={columns}
          loading={loading || loadingHierarchyUsers}
          rows={searchResult.length !== 0 ? searchResult : users}
          pageSize={8}
          checkboxSelection
          selectionModel={preSelected}
          onRowSelected={handleRowSelect}
        />
      </Grid>
      <Grid item xs={12} className={classes.areasActions}>
        <Button disabled={buttonDisabled} onClick={sendUsersAndLocations}>
          {(locationId || isHierarchyDetails) && "Update users"}
          {!locationId && !isHierarchyDetails && "Assign to Locations"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserDataGrid;
