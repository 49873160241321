import moment from 'moment-timezone';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ConnectionTable } from '../../../../../../../blocks';
import { generateStoreAbbreviation, convert } from '../../../../../../../../utils';
import React from 'react';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../../../../constants';
import { Grid, Typography } from '@material-ui/core';
import LoyaltyOrdersStyles from './styles';
import { useParams } from 'react-router';
const CustomerOrdersView = ({ query }) => {
  const { customerId } = useParams();

  return (
    <div id={'ordersView'}>
      <Grid container item xs={12} justify="space-between">
        <Typography variant="h4" component="h2">
          Orders
        </Typography>
      </Grid>
      <ConnectionTable
        query={query}
        showSearch={false}
        //   onTableRowClick={(row) => window.open(`${paths.ORDERS_ID.replace(':orderId',row.quoteId)}/generalInfo`)}
        initialQueryVariables={{
          first: ROWS_PER_PAGE_OPTIONS[0],
          sort: [
            {
              expected: 'DESC',
            },
          ],
          filter: {
            stageId: {
              null: false,
            },
            personId: {
              eq: parseInt(customerId),
            },
          },
          // specificFields: ["first_name", "last_name"]
        }}
        structureTable={data =>
          data?.viewer?.quoteConnection?.edges.map(quote => {
            return {
              'brand.label': quote.node?.brand?.label || 'Dickeys',
              'origin.label': quote.node.origin?.label,
              quoteId: quote.node.quoteId,
              'category.label': quote.node.category?.label,
              'handoff.label': quote.node.handoff?.label,
              location: generateStoreAbbreviation(quote.node.location).abbreviation,
              totalPrice: `$ ${convert.centsToDollars(quote.node.totalPrice)}`,
              dueAmount: `$ ${convert.centsToDollars(quote.node.dueAmount)}`,
              expected: moment
                .tz(moment.utc(quote.node.expected), quote.node.location?.timezone?.label)
                .format('MM/DD/YYYY HH:mm A z'),
              'stage.label': quote?.node?.stage?.label,
              doordashId: quote?.node?.doordashId,
              printjob: quote?.node?.printJob,
            };
          })
        }
        columns={[
          { title: 'Brand', field: 'brand.label' },
          { title: 'Source', field: 'origin.label', disableSort: true },
          { title: 'Quote ID', field: 'quoteId' },
          {
            title: 'Category',
            field: 'category.label',
            disableSort: true,
          },
          {
            title: 'Handoff',
            field: 'handoff.label',
            disableSort: true,
          },
          {
            title: 'Location ID',
            field: 'location',
            disableSort: true,
            customPermissionField: 'location.id',
          },
          { title: 'Total', field: 'totalPrice', disableSort: true },
          { title: 'Due Amount', field: 'dueAmount', disableSort: true },
          { title: 'Order Timing', field: 'expected' },
          { title: 'Status', field: 'stage.label', disableSort: true },
          {
            title: 'DoorDash ID',
            field: 'doordashId',
            disableSort: true,
          },
          {
            title: 'Printed',
            field: 'printjob',
            disableSort: true,
            render: printjob => {
              if (!printjob?.inQueue) {
                return 'N/A';
              }
              return printjob?.inQueue && printjob?.datePrinted ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ErrorOutlineIcon />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default CustomerOrdersView;
