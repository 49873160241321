import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import LocationHoursWeekday from './locationHoursWeekday';
import { UPDATE_LOCATION_HOURS } from '../../../../../../apollo/mutations/locations';
import { FETCH_LOCATION } from '../../../../../../apollo/queries';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../../constants';
import { isEmpty } from 'lodash';
import { PermissionField, Permission } from '../../../../../blocks';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const LocationHoursModal = ({
  locationWeekdayInformation,
  locationId,
  weekdayType,
  handleClose,
  permissions,
  accessToMutations,
  updateLocationHoursPermission,
}) => {
  const { handleSubmit } = useForm({});
  const { setSnackbar } = useSnackbar();

  const [errors, setErrors] = useState({});

  const [formDataArray, setFormDataArray] = useState([]);
  const [updateLocationHours, { loading: updateLocationHoursLoading }] = useMutation(
    UPDATE_LOCATION_HOURS,
    {
      update() {
        setFormDataArray([]);
      },
    },
  );

  const onSubmit = () => {
    const filterFormdataObj = formDataArray.filter(value => value.type !== 'delete-added');

    const newErrors = {};

    filterFormdataObj.forEach(hour => {
      if (
        ((hour.type === 'active' && hour.active) ||
          hour.type === 'add' ||
          hour.type === 'update') &&
        (!hour.opened || !hour.closed)
      ) {
        newErrors[`locationHours[${hour.weekdayId}]`] = true;
      }

      if (!['00:00:00', '0:00:00'].includes(hour.closed)) {
        const [openingHours, openingMinutes, openingSeconds] = hour.opened.split(':');
        const [closingHours, closingMinutes, closingSeconds] = hour.closed.split(':');

        const open = new Date().setHours(
          Number(openingHours),
          Number(openingMinutes),
          Number(openingSeconds),
        );
        const closed = new Date().setHours(
          Number(closingHours),
          Number(closingMinutes),
          Number(closingSeconds),
        );

        if (moment(open).isAfter(closed)) {
          newErrors[`locationHoursInvalidConfiguration[${hour.weekdayId}]`] = true;
        }
      }
    });

    setErrors(newErrors);

    if (isEmpty(newErrors)) {
      updateLocationHours({
        variables: {
          input: {
            locationIds: [{ id: locationId }],
            locationHours: filterFormdataObj,
          },
        },
        refetchQueries: [
          {
            query: FETCH_LOCATION,
            variables: {
              id: locationId,
              first: 2147483647,
            },
          },
        ],
      })
        .then(() => {
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Hours updated',
          });

          handleClose();
        })
        .catch(error => {
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: 'Something went wrong',
          });
        });
    }
  };

  const modalContent = () => {
    const array = [];
    locationWeekdayInformation?.forEach(weekday => {
      if (weekday?.type === weekdayType) {
        array.push(
          <div>
            <LocationHoursWeekday
              permissions={permissions}
              weekday={weekday}
              setFormDataArray={setFormDataArray}
              formDataArray={formDataArray}
              errors={errors}
            />
          </div>,
        );
      }
    });

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {array}
        <Grid container item xs={12} justify="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Permission
            access={Math.min(
              accessToMutations?.[MUTATION_NAME.UpdateLocationWeekdayMutationInput],
              updateLocationHoursPermission?.access,
            )}
          >
            <Grid item>
              <div>
                <PermissionField createHelperText>
                  <Button type="submit" disabled={updateLocationHoursLoading}>
                    Save
                  </Button>
                </PermissionField>
              </div>
            </Grid>
          </Permission>
        </Grid>
      </form>
    );
  };

  return <>{modalContent()}</>;
};

LocationHoursModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  locationHours: PropTypes.array,
  weekdayType: PropTypes.string,
  title: PropTypes.string,
  locationWeekdayInformation: PropTypes.array,
};

export default LocationHoursModal;
