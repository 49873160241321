import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  Container,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import ImageUpload from '../../../../../../blocks/ImageUpload';
import GeneralInfoInput from '../GeneralInfoInput';
import { UPDATE_MENU } from '../../../../../../../apollo/mutations';
import { FETCH_MENU, FETCH_BRANDS } from '../../../../../../../apollo/queries';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const IMAGE = {
  sizes: ['375x268'],
};

const Menu = ({
  ids,
  basePath,
  classes,
  refetchMenuList,
}) => {
  const { setSnackbar } = useSnackbar();


  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    setImages(
      IMAGE.sizes.map((size) => {
        return {
          size,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find((image) => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { handleSubmit, control, register, errors, reset } = useForm({
    defaultValues: {
      label: '',
      description: '',
      availableOnline: false,
      availablePos: false,
      availableThirdParty: false,
    },
  });

  const [updateMenu] = useMutation(UPDATE_MENU);

  const { menuId, gqlMenuId } = ids;

  const {
    data: brandList,
    error: brandListError,
    loading: brandListLoading
  } = useQuery(FETCH_BRANDS);

  const { data, error, loading } = useQuery(FETCH_MENU, {
    variables: {
      id: gqlMenuId,
    },
    onCompleted(data) {
      if (data.viewer.menu) {
        data.viewer.menu.menuimageConnection.edges.forEach(({ node }) => {
          const index = images.findIndex((image) =>
            Object.values(image).includes(node.label)
          );
          if (index !== -1) {
            setImages((images) => {
              const image = {
                name: '',
                description: node.description || '',
                encoded: '',
                size: node.label,
                url: node.url || '',
              };
              images[index] = image;
              return [...images];
            });
          }
        });
        reset(data.viewer.menu);
      }
    },
  });

  if (loading || brandListLoading) return <CircularProgress className={classes.spinner} />;
  if (error || brandListError) console.log(error);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    setButtonDisabled(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Updating menu...',
    });

    const imagesToUpload = images.map((image) => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    await updateMenu({
      variables: {
        input: {
          menuId: gqlMenuId,
          label: data.label,
          description: data.description,
          images: imagesToUpload,
          brandId: data.brandId,
        },
      },
      refetchQueries: () => [
        {
          query: FETCH_MENU,
          variables: {
            id: gqlMenuId,
          },
        },
      ],
    });

    await refetchMenuList();

    setSnackbar({
      open: true,
      type: 'info',
      text: 'Menu updated...',
    });
    setButtonDisabled(false);
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Button disabled={buttonDisabled} type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
        <GeneralInfoInput
          isMenu
          brandList={brandList?.viewer?.brandConnection?.edges}
          brand={data?.viewer?.menu?.brand}
          imageUrl={previewImage}
          setOpen={setOpen}
          control={control}
          register={register}
          errors={errors}
        />
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} className={classes.subcategoryHeader}>
            <Typography gutterBottom variant="h6" component="h2">
              Categories
            </Typography>
            <Button
              className={classes.buttonAdd}
              component={Link}
              variant="outlined"
              size="small"
              to={`${basePath}/menus/${menuId}/categories`}
            >
              Add Category
            </Button>
          </Grid>
          <Grid item xs={3}>
            <List component="nav" className={classes.list}>
              {data?.viewer?.menu?.menuCategoryConnection?.edges.map((edge) => {
                return (
                  <Link
                    to={`${basePath}/menus/${menuId}/categories/${edge?.node?.category?.categoryId}`}
                    className={classes.listLink}
                  >
                    <ListItem
                      key={edge?.node?.category?.id}
                      className={classes.listItem}
                    >
                      <ListItemText
                        primary={`${edge?.node?.category?.label} ID_${edge?.node?.category?.categoryId}`}
                      />
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Menu Generation Image
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ImageUpload
                  images={images}
                  setImages={setImages}
                  size={IMAGE.sizes[0]}
                  maxSize={50*1000}
                  helperText="Required image size is 375 x 268 px in JPG format. File weight in max. 50KB."
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

Menu.propTypes = {
  ids: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Menu;
