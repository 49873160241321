import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import paths from '../../../../../../../../constants/paths';
import ItemChoiceTable from './ItemChoiceTable';

const useStyles = makeStyles({
  container: {},
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
});

const ItemChoices = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { itemId } = useParams();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getPath = id => {
    return paths.SETTINGS_CHOICES_MULTIPLE_EDIT.replace(':multipleId', id);
  };
  // value, name, multipleId, choiceId

  return (
    <div className={classes.container}>
      <ItemChoiceTable
        getPath={getPath}
        open={open}
        itemId={itemId}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
    </div>
  );
};

ItemChoices.propTypes = {
  gqlItemId: PropTypes.string.isRequired,
};

export default ItemChoices;
