import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  renderRulesWrap: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "2rem",
  },
  renderRulesChildren: {
    display: "flex",
    flexDirection: "column",
  },
  renderRulesCheckbox: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "0",
    display: "grid",
  },
  renderRulesCheckboxNoMargin: {
    '& label': {
      margin: 0,
    }
  },
  renderRulesReadWrite: {
    display: "flex",
    justifyContent: "flex-end",
    "& label": {
      display: "flex",
      justifyContent: "center",
      margin: 0,
      width: "20rem",
      alignItems: "center",
    },
  },
  fieldgroupsTitle: {
    display: "flex",
    justifyContent: "flex-end",
    "& span": {
      display: "flex",
      justifyContent: "center",
      width: "20rem",
      margin: 0,
      alignItems: "center",
    },
  },
});

const UsergroupRulesStyles = makeStyles(Styles);

export default UsergroupRulesStyles;
