import { gql } from "@apollo/client";

const LOCATIONS_FOR_ITEM_INFO = gql`
  query LocationsForItemInfo(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationSort]
    $specificFields: [String]
    $filter: [LocationFilter]
    $itemLocationFilter: [ItemLocationFilter]
    $locationItemFilter:  [LocationItemFilter]
  ) {
    viewer {
      id
      locationConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        specificFields: $specificFields
        search: $search
        sort: $sort
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            locationId
            label
            storeNumber
              address {
              city
              state {
                label
                abbreviation
              }
            }
            locationItemConnection(filter: $locationItemFilter) {
            edges {
              node {
                id
                isAvailable
              }
            }
          }
             itemLocationConnection(filter: $itemLocationFilter) {
                edges {
                  node {
                    id
                    itemId
                    localFavorite
                    isAvailable
                    item {
                    id
                    }
                  }
                }
               }
            menu {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export default LOCATIONS_FOR_ITEM_INFO;
