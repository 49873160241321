import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { upperFirst, isEmpty } from 'lodash'
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Checkbox, Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SAVE_USERGROUP_RULES from "../../../../../../apollo/mutations/usergroups/saveUsergroupRules";
import { paths } from "../../../../../../constants";
import UsergroupRulesStyles from "./styles";

const setAllRules = (ruleTree, type, checked, isControlGroupChecked) => ruleTree.reduce((result, parent) => {
  let childrenRules = [];

  if (!isEmpty (parent.children)) {
    childrenRules = setAllRules(Object.values(parent.children || {}), type, checked, isControlGroupChecked)
  }

  if (!isEmpty(parent.fields)) {
    return {
      ...result, 
      [`fieldgroup.${parent.id}.Control`]: isControlGroupChecked,
      [`fieldgroup.${parent.id}.${upperFirst(type)}`]: checked,
      ...(parent.fields || []).reduce((resultField, field) => ({
        ...resultField,
      [`field.${parent.id}.${field.id}.Control`]: isControlGroupChecked,
      [`field.${parent.id}.${field.id}.${upperFirst(type)}`]: checked,
      ...childrenRules,
      }), []),
    } 
  }

  return {
    ...result, 
      [`fieldgroup.${parent.id}.Control`]: isControlGroupChecked,
      [`fieldgroup.${parent.id}.${upperFirst(type)}`]: checked,
    ...setAllRules(Object.values(parent.children || {}), type, checked, isControlGroupChecked)
  }  
}, []);

const Rules = ({ rulesData, usergroupId }) => {
  const classes = UsergroupRulesStyles();

  Rules.propTypes = {
    fieldgroups: PropTypes.array,
  };

  const history = useHistory();

  const [saveUsergroupRules, {loading: saveUsergroupRulesLoading}] = useMutation(SAVE_USERGROUP_RULES);
  const { register, handleSubmit } = useForm();
  const [ruleState, setRuleState] = useState();
  const [isCheckedCollumn, setIsCheckedColumn] = useState({
    read: false,
    write: false,
  })

  const handleCheckbox = (event, id, fieldGroupId) => {
    // If clicking on the main section, toggle the read/write as well
    if (event.target.name.includes("Control")) {
      setRuleState({
        ...ruleState,
        [event.target.name]: event.target.checked,
        [`${id}.Read`]: event.target.checked,
        [`${id}.Write`]: event.target.checked,
      });
    } else {
      setRuleState({ ...ruleState, [event.target.name]: event.target.checked });
    }
  };

  const handleCheckCollumn = useCallback((type) => {
    setIsCheckedColumn(prevProps => {
        setRuleState(prevState => ({
        ...prevState,
        ...setAllRules(
            Object.values(rulesData?.viewer?.usergroupJsonTree?.jsonTree || {}), 
            type, 
            !prevProps[type], 
            !prevProps[type] && prevProps.read !== prevProps.write,
          ),
        }
      ));

      return {
        ...prevProps,
        [type]: !prevProps[type],
      }
    
  })}, [rulesData?.viewer?.usergroupJsonTree?.jsonTree])

  const renderTopCheckbox = useCallback((type, checked) => {
    return(
      <Grid container justify="center" style={{ width: '20rem' }} className={classes.renderRulesCheckboxNoMargin}>
        <FormControlLabel
          className={classes.renderRulesLabel}
          control={
            <Checkbox
              onClick={() => handleCheckCollumn(type)}
              checked={checked}
              name='type'
            />
          }
        />
      </Grid>
    )
  }, [handleCheckCollumn, classes.renderRulesLabel, classes.renderRulesCheckboxNoMargin])

  const renderCheckbox = (id, label, access, type, fieldGroupId) => {
    return (
      <FormControlLabel
        label={(type === "Control" && label) || null}
        className={classes.renderRulesLabel}
        control={
          <Checkbox
            onClick={(event) => handleCheckbox(event, id, fieldGroupId)}
            // used for defaulting the checkbox values from the DB
            checked={(ruleState && ruleState[`${id}.${type}`]) ?? !!access}
            name={`${id}.${type}`}
            // dont pass inputRef of 'control' type checkboxes since we dont need them in our onSubmit handler
            inputRef={type === "Control" ? null : register}
          />
        }
      />
    );
  };

  const renderRules = (ruleTree) => {
    const mutationList = [];
    return Object.entries(ruleTree).map(([key, value]) => {
      const { id, access = 0, mutations, children, fields, isField, fieldgroup_id } = value;
      const groupedId = isField ? `field.${fieldgroup_id}.${id}` : `fieldgroup.${id}`;
      mutationList.push(mutations); // Mutations will be rendered separately TODO: dont forget this!
      return (
        <div className={classes.renderRulesWrap} key={`${id}-${key}`}>
          <div className={classes.renderRulesCheckbox}>
            {renderCheckbox(groupedId, key, access & 2, "Control", fieldgroup_id)}
            <div className={classes.renderRulesReadWrite}>
              {renderCheckbox(groupedId, key, access & 4, "Read", fieldgroup_id)}
              {renderCheckbox(groupedId, key, access & 2, "Write", fieldgroup_id)}
            </div>
          </div>

          {
            fields &&
              fields.map((field) =>
                renderRules({ [field.prettified_name || field.label]: { ...field, isField: true } })
              ) // TODO: prettified_name
          }

          {children && (
            <div className={classes.renderRulesChildren}>
              {renderRules(children)}
            </div>
          )}
        </div>
      );
    });
  };

  const onSubmit = async (data) => {
    let fieldRules = [];
    const fieldgroupRules = {};

    if(data.field) {
      for (const [key, value] of Object.entries(data.field)) {
          const filteredValues = value.map((permissions, index) => ({ access: (permissions["Read"] ? 4 : 0) + (permissions["Write"] ? 3 : 0), fieldId: index, fieldGroupId: key })).filter(v => v);
          fieldRules = [...fieldRules, ...filteredValues];
        }
    }

    if(data.fieldgroup) {
      for (const [key, value] of Object.entries(data.fieldgroup)) {
        // Combine the values into binary
        fieldgroupRules[key] = (value["Read"] ? 4 : 0) + (value["Write"] ? 3 : 0);
      }
    }

    await saveUsergroupRules({
      variables: {
        fieldgroups: JSON.stringify(fieldgroupRules),
        fields: JSON.stringify(fieldRules),
        usergroupId: parseInt(usergroupId),
      },
    }).catch((e) => {
      // TODO: do something with error
      alert(e);
    });

    // TODO: add success message with redirect
    history.push(paths.SETTINGS_ACCESS_MANAGEMENT);
  };

  if (saveUsergroupRulesLoading) return <CircularProgress />;

  return rulesData ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justify="flex-end">
        {renderTopCheckbox('read', isCheckedCollumn.read)}
        {renderTopCheckbox('write', isCheckedCollumn.write)}
      </Grid>
      <Grid>
        <div className={classes.fieldgroupsTitle}>
          <span>Read</span>
          <span>Write</span>
        </div>
      </Grid>
      {renderRules(rulesData.viewer.usergroupJsonTree.jsonTree)}
      <Button type="submit">Save</Button>
    </form>
  ) : null;
};

export default Rules;
