import Duration from 'luxon/src/duration.js';
import moment from 'moment';

const DATE_FILTER_END_SECONDS = 43199; // 11 hours 59 minutes and 59 seconds to seconds
import { toFixed10 } from './mathUtils';

const dollarsToCents = amount => {
  if (typeof amount !== 'string' && typeof amount !== 'number') {
    throw new Error('Amount passed must be of type String or Number.');
  }

  return Math.round(100 * parseFloat(amount));
};

const centsToDollars = amount => {
  if (typeof amount !== 'string' && typeof amount !== 'number') {
    throw new Error('Amount passed must be of type String or Number.');
  }

  return toFixed10(parseFloat(amount) / 100, 2);
};

const decimalHoursToHHmm = duration => {
  const dur = Duration.fromObject({ hours: parseFloat(duration) });
  const hours = Math.floor(dur.as('hours'));
  const mins = Math.floor(dur.as('minutes')) - hours * 60;
  const sec = Math.floor(dur.as('seconds')) - hours * 60 * 60 - mins * 60;

  return hours + ':' + (sec > 30 ? mins + 1 : mins);
};

const formattedCurrency = cents => {
  if (!Number(cents)) return '$0.00';
  const amount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100);
  return `${amount}`;
};

const convert = { centsToDollars, dollarsToCents, decimalHoursToHHmm, formattedCurrency };

export default convert;
