import { gql } from '@apollo/client';

const FETCH_ITEM = gql`
  query fetchItem($id: ID!, $itemCrosssellSort: [ItemCrosssellSort]) {
    viewer {
      id
      item(id: $id) {
        id
        brand {
          id
          brandId
          label
          description
          abbreviation
        }
        itemId
        itemtype {
          itemtypeId
        }
        label
        label2
        description
        price
        priceCap
        preparationTime
        minQuantity
        maxQuantity
        donation
        minCalories
        maxCalories
        giftCard
        showLocation
        availableOnline
        availablePos
        availableThirdParty
        perPerson
        numberPeopleCanServe
        weekdays
        itemimageConnection {
          edges {
            node {
              id
              label
              url
              description
            }
          }
        }
        itemCrosssellConnection(sort: $itemCrosssellSort) {
          edges {
            node {
              crosssellItem {
                id
                itemId
                label
              }
            }
          }
        }
        itemLocationConnection {
          edges {
            node {
              localFavorite
              location {
                id
                storeNumber
                label
                address {
                  city
                  state {
                    id
                    label
                  }
                }
              }
            }
          }
        }
        itemTierConnection {
          edges {
            node {
              id
              tierId
              price
              priceCap
            }
          }
        }
        itemChoiceConnection {
          edges {
            node {
              id
            }
          }
        }
        ingredients {
          ingredientLabel
          ingredientQuantity
          measure
        }
      }
      weekdayConnection {
        edges {
          node {
            id
            weekdayId
          }
        }
      }
      tierConnection {
        totalCount
        edges {
          node {
            id
            tierId
            label
            locationConnection {
              edges {
                node {
                  label
                  tierId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_ITEM;
