import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  row: {
    margin: '20px 0',
  },
});

const SubcategoriesStyles = makeStyles(Styles);

export default SubcategoriesStyles;
