import { gql } from '@apollo/client';

const INGREDIENTS_LIST = gql`
  query Ingredients(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [IngredientSort]
    $filter: [IngredientFilter]
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      ingredientConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: $fieldGroupOnly
      ) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            permission
            ingredientId
            inventoryitem {
              label
              description
              cost
              created
              measure {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default INGREDIENTS_LIST;
