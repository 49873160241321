import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';

import { SelectFactory } from '../../../../../../blocks';
import { FETCH_ITEM_LIST } from '../../../../../../../apollo/queries';

function AddItemsModal({ selectedItems, setSelectedItems, handleClose, selectedBrands }) {
  const [selected, setSelected] = useState(selectedItems ? [...selectedItems] : []);
  const itemBrandsFilter = { brand: { brandId: { in: selectedBrands?.map(brand => brand.id) } } };

  const handleSave = () => {
    setSelectedItems(selected);
    handleClose(true);
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container xs={12} style={{ marginBottom: '1rem' }}>
        <Grid item xs={12}>
          <SelectFactory
            style={{ minWidth: '15rem' }}
            name="loyaltysettingsItems"
            label="Items"
            placeholder="Select items"
            disableSearchReset
            disableCloseOnSelect={true}
            query={FETCH_ITEM_LIST}
            filter={selectedBrands?.length > 0 ? itemBrandsFilter : null}
            onSelect={values => {
              const newValues = values.map(item => {
                delete item.index;
                return item;
              });
              setSelected(newValues);
            }}
            defaultValue={selected}
            structureData={data => {
              return data?.viewer?.itemConnection?.edges.map((edge, index) => {
                return {
                  label: `${edge.node.label} - ID_${edge.node.itemId}`,
                  itemId: edge.node.itemId,
                  index,
                  id: edge.node.id,
                  isIncluded: selected?.length ? selected[0].isIncluded : false,
                };
              });
            }}
          />
        </Grid>
      </Grid>
      <Button onClick={handleSave}>Save</Button>
    </div>
  );
}

export default AddItemsModal;
