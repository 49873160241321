import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TreeView, TreeItem } from '@material-ui/lab';
import {
  Grid,
  Chip,
  Paper,
  Checkbox,
  Button,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { SelectFactory } from '../../../../../../../../blocks';
import { FETCH_ITEM_LIST } from '../../../../../../../../../apollo/queries';

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function AddItemsModalView({ data, setExcludedItems, excludedItems, handleClose, multiple }) {
  const [selected, setSelected] = useState([...excludedItems]);

  const getChildById = (node, key) => {
    let array = [];

    const getAllChild = nodes => {
      if (nodes === null) return [];
      array.push(nodes);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach(node => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((node, i) => array.indexOf(node) === i);
        });
      }
      return array;
    };

    const getNodeByKey = (nodes, key) => {
      if (nodes.key === key) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach(node => {
          if (!!getNodeByKey(node, key)) {
            result = getNodeByKey(node, key);
          }
        });
        return result;
      }

      return null;
    };

    return getAllChild(getNodeByKey(node, key));
  };

  const getOnChange = (checked, nodes) => {
    let allNode = getChildById(data[0], nodes.key);

    let array = checked
      ? [...selected, ...allNode]
      : selected.filter(value => {
          let val_one = value.key;
          return !allNode.some(item => item.key === val_one);
        });
    array = array.filter((v, i) => array.indexOf(v) === i);
    setSelected(array);
  };

  const renderTree = nodes => {
    return (
      <TreeItem
        key={nodes.key}
        nodeId={nodes.key}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.some(item => {
                  return item.key === nodes.key;
                })}
                onChange={event => {
                  return getOnChange(event.currentTarget.checked, nodes);
                }}
                onClick={e => e.stopPropagation()}
              />
            }
            label={nodes.title}
            key={nodes.key}
          />
        }
      >
        {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
      </TreeItem>
    );
  };

  const handleDelete = itemId => {
    const newArr = selected.filter(item => item.id !== itemId);
    setSelected(newArr);
  };

  const handleSave = () => {
    setExcludedItems(selected);
    handleClose(selected);
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container xs={12} style={{ marginBottom: '1rem' }}>
        <Grid item xs={12} md={6}>
          {/* <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {renderTree(data[0])}
          </TreeView> */}
          <SelectFactory
            style={{ minWidth: '15rem' }}
            name="excludedItems"
            label="Items"
            placeholder="Select items"
            disableSearchReset
            disableCloseOnSelect={true}
            query={FETCH_ITEM_LIST}
            filter={{
              showUnavailableOnline: {
                eq: true,
              },
            }}
            onSelect={values => {
              setSelected(values);
            }}
            multiple={multiple}
            defaultValue={selected}
            structureData={data => {
              return data?.viewer?.itemConnection?.edges.map((edge, index) => {
                return {
                  isItem: true,
                  label: `${edge.node.label} - ID_${edge.node.itemId}`,
                  value: edge.node.id,
                  index,
                  id: edge.node.id,
                };
              });
            }}
          />
        </Grid>

        {/* <Grid item md={9}>
          <Paper style={{ width: "100%" }}>
            <div>
              <h3>Selected Items</h3>
            </div>

            <div>
              {selected.map((item) => {
                if (item.isItem) {
                  return (
                    <Chip
                      key={item.id}
                      label={item.label}
                      style={{ margin: "4px" }}
                      onDelete={(e) => handleDelete(item.id)}
                    />
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </Paper>
        </Grid> */}
      </Grid>
      <Button onClick={handleSave}>Save</Button>
    </div>
  );
}

export default AddItemsModalView;
