import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Modal } from '../../../../../blocks';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { renderStatus, statusOptions } from '../../../../../../constants/statuses';
import { FETCH_MEASURES } from '../../../../../../apollo/queries';
import UnitOfMeasureModal from './UnitOfMeasureModal';
import { isEmpty } from 'lodash-es';

const UnitsOfMeasure = () => {
  const [isMeasureModalOpen, setIsMeasureModalOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [measure, setMeasure] = useState();

  const handleOnRowClick = useCallback(({ data }) => {
    setMeasure(data);
    setIsMeasureModalOpen(true);
  }, []);

  const handleCloseMeasureModal = useCallback(() => {
    setMeasure();
    setIsMeasureModalOpen(false);
  }, []);

  const handleOnAddMeasureClick = useCallback(() => {
    setIsMeasureModalOpen(true);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.active) {
        delete filters.active;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          active: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        title="Units of Measure"
        customActionsTop={() => (
          <Button onClick={handleOnAddMeasureClick} fullWidth>
            Add New Unit of Measure
          </Button>
        )}
        shouldRefetch={refetch}
        setShouldRefetch={setRefetch}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        columns={[
          { field: 'measureId', title: 'ID' },
          {
            field: 'label',
            title: 'Name',
          },
          {
            field: 'description',
            title: 'Description',
            disableSort: true,
          },
          {
            field: 'thresholdValue',
            title: 'Max Threshold Warning',
            disableSort: true,
          },
          {
            field: 'status',
            title: 'Status',
            render: renderStatus,
            enablePropagation: true,
            disableSort: true,
          },
          { field: 'updated', customSortField: 'created', title: 'Updated' },
        ]}
        query={FETCH_MEASURES}
        structureTable={data =>
          data?.viewer?.measureConnection?.edges?.map(edge => ({
            label: edge.node.abbreviation,
            thresholdValue:
              edge.node.thresholdValue !== null ? edge.node.thresholdValue : 'Not set',
            description: edge.node.label,
            measureId: edge.node.measureId,
            status: edge.node.active,
            updated: moment(edge.node.created).format('MM/DD/YYYY'),
            data: edge.node,
          })) || []
        }
        onTableRowClick={handleOnRowClick}
      />
      <Modal
        open={isMeasureModalOpen}
        handleClose={handleCloseMeasureModal}
        title={measure?.measureId ? 'Edit Unit of Measure' : 'Add Unit of Measure'}
      >
        <UnitOfMeasureModal
          measure={measure}
          handleClose={handleCloseMeasureModal}
          refetch={setRefetch}
        />
      </Modal>
    </>
  );
};

export default UnitsOfMeasure;
