import InventoryItem from './InventoryItem';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import AddInventoryItem from './AddInventoryItem';
import FormItems from '../../../../../blocks/FormItems';
import { FormHelperText } from '@material-ui/core';

const AddInventoryItems = ({
  invoice,
  submitButtonLocked,
  register,
  errors,
  control,
  setValue,
  unregister,
  getValues,
  showInventoryItemsError,
}) => {
  const [showSelectFactory, setShowSelectFactory] = useState(true);
  const [selectedIngredientIds, setSelectedIngredientIds] = useState([]);

  const handleSetItems = useCallback(items => {
    setSelectedIngredientIds(items.map(item => item.ingredientId));
  }, []);

  const handleOnSelect = useCallback(() => {
    setShowSelectFactory(false);
  }, []);

  const inventoryItems = useMemo(
    () =>
      invoice.invoiceIngredientConnection?.edges?.map((edge, index) => ({
        label: edge.node.ingredient?.inventoryitem?.label || 'Inventory Item not created',
        ingredientId: edge.node.ingredientId,
        index,
        cost: edge.node.cost || 0,
        quantity: edge?.node?.quantity,
        measureId: edge.node.measure?.measureId,
        measure: `${edge.node.measure?.label} (${edge.node.measure?.abbreviation})`,
      })),
    [invoice.invoiceIngredientConnection?.edges],
  );

  useEffect(() => {
    setSelectedIngredientIds(inventoryItems?.map(item => item.ingredientId) || []);
  }, [inventoryItems]);

  const invoiceNumber = useMemo(() => getValues('invoiceNumber'), [getValues]);

  return (
    <>
      <Grid container direction="column" spacing={3} style={{ marginBottom: '16px' }}>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            Invoice #{invoice.invoiceNumber || invoiceNumber}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormItems
            onSelect={handleOnSelect}
            setItems={handleSetItems}
            addItemComponent={AddInventoryItem}
            addItemComponentProps={{
              setShowSelectFactory,
              showSelectFactory,
              submitButtonLocked,
              selectedIngredientIds,
            }}
            name="inventoryItem"
            setValue={setValue}
            unregister={unregister}
            control={control}
            getValues={getValues}
            register={register}
            items={inventoryItems}
            errors={errors}
            itemComponent={InventoryItem}
            enableEmpty
          />
        </Grid>
        {showInventoryItemsError ? (
          <Grid item xs={12}>
            <FormHelperText error>At least one Product is required</FormHelperText>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default AddInventoryItems;
