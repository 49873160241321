import { SvgIcon } from '@material-ui/core';

const OrderTracker5 = () => (
  <SvgIcon
    style={{ width: '100%', height: '100%' }}
    viewBox="0 0 91 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clippath">
        <rect x="29.24" y="10.09" width="34.45" height="43.86" fill="none" />
      </clipPath>
    </defs>
    <path
      d="m63.61,27.43l-3.14-12.11c-.06-.23-.27-.4-.52-.4h-.95v-4.14c0-.29-.24-.53-.53-.53l-26.01-.09c-.15,0-.29.05-.4.16-.11.1-.17.23-.17.38v4.62l-2.58,12.15s0,.08,0,.11v25.78c0,.29.24.53.53.53h33.25c.29,0,.53-.24.53-.53v-25.78s0-.09-.02-.13Z"
      fill="#e7e8ef"
    />
    <g>
      <g clip-path="url(#clippath)">
        <path
          id="Path_32749"
          d="m33.91,44.19h-.18c-.29,0-.53.24-.53.53s.24.53.53.53h.18c.29,0,.53-.24.53-.53s-.24-.53-.53-.53"
          fill="#747480"
        />
        <path
          id="Path_32750"
          d="m37.17,44.19h-1.4c-.29,0-.53.24-.53.53s.24.53.53.53h1.4c.29,0,.53-.24.53-.53s-.24-.53-.53-.53"
          fill="#747480"
        />
        <path
          id="Path_32751"
          d="m49.13,44.19h-10.07c-.29,0-.53.24-.53.53s.24.53.53.53h10.07c.29,0,.53-.24.53-.53s-.24-.53-.53-.53h0Z"
          fill="#747480"
        />
        <path
          id="Path_32752"
          d="m33.91,46.77h-.18c-.29,0-.53.24-.53.53s.24.53.53.53h.18c.29,0,.53-.24.53-.53s-.24-.53-.53-.53"
          fill="#747480"
        />
        <path
          id="Path_32753"
          d="m37.17,46.77h-1.4c-.29,0-.53.24-.53.53s.24.53.53.53h1.4c.29,0,.53-.24.53-.53s-.24-.53-.53-.53"
          fill="#747480"
        />
        <path
          id="Path_32754"
          d="m49.13,46.77h-10.07c-.29,0-.53.24-.53.53s.24.53.53.53h10.07c.29,0,.53-.24.53-.53s-.24-.53-.53-.53h0Z"
          fill="#747480"
        />
        <path
          d="m63.61,27.43l-3.14-12.11c-.06-.23-.27-.4-.52-.4h-.95v-4.14c0-.29-.24-.53-.53-.53l-26.01-.09c-.15,0-.29.05-.4.16-.11.1-.17.23-.17.38v4.62l-2.58,12.15s0,.08,0,.11v25.78c0,.29.24.53.53.53h33.25c.29,0,.53-.24.53-.53v-25.78s0-.09-.02-.13Zm-30.64-16.21l24.97.08v3.6l-24.98-.07v-3.61Zm20.17,16.35v25.24h-22.75v-25.19l2.51-11.73,22.29.07-2.03,11.52s-.02.06-.02.09h0Zm1.07.04l2.05-11.64h1.68v33.52l-3.73,2.79v-24.67Zm1.1,25.2l3.26-2.45,3.03,2.45h-6.29Zm7.26-.58l-3.55-2.86V15.98h.53l3.02,11.66v24.59Z"
          fill="#747480"
        />
        <path
          id="Path_32756"
          d="m63.61,27.43l-3.14-12.11c-.06-.23-.27-.4-.52-.4h-.95v-4.14c0-.29-.24-.53-.53-.53l-26.01-.09c-.15,0-.29.05-.4.16-.11.1-.17.23-.17.38v4.62l-2.58,12.15s0,.08,0,.11v25.78c0,.29.24.53.53.53h33.25c.29,0,.53-.24.53-.53v-25.78s0-.09-.02-.13Zm-30.64-16.21l24.97.08v3.6l-24.98-.07v-3.61Zm20.17,16.35v25.24h-22.75v-25.19l2.51-11.73,22.29.07-2.03,11.52s-.02.06-.02.09h0Zm1.07.04l2.05-11.64h1.68v33.52l-3.73,2.79v-24.67Zm1.1,25.2l3.26-2.45,3.03,2.45h-6.29Zm7.26-.58l-3.55-2.86V15.98h.53l3.02,11.66v24.59Z"
          fill="none"
          stroke="#747480"
          stroke-width=".15px"
        />
        <path
          id="Path_32757"
          d="m34.29,18.52c.29.06.57-.12.63-.41,0,0,0,0,0,0h0l.09-.42c.06-.29-.12-.57-.41-.63-.29-.06-.57.12-.63.41l-.09.42c-.06.29.12.57.41.63h0"
          fill="#747480"
        />
        <path
          id="Path_32758"
          d="m34.5,20.11c.06-.29-.12-.57-.41-.63-.29-.06-.57.12-.63.41l-1.2,5.72c-.06.29.12.57.41.63.29.06.57-.12.63-.41l1.2-5.72Z"
          fill="#747480"
        />
      </g>
      <path
        d="m49.97,34.11c.19-.22.29-.5.29-.79v-.34c0-.37-.16-.71-.44-.94.1-.17.16-.36.16-.55.04-1.6-.47-2.94-1.51-4-1.43-1.45-3.78-2.23-6.63-2.23-2.87-.11-5.24.64-6.66,2.08-1.07,1.08-1.58,2.49-1.5,4.18,0,.17.05.33.13.48-.32.23-.5.6-.5.99v.34c0,.32.12.62.34.85-.19.22-.29.5-.29.79v.27c0,.38.17.73.46.96l.08.78c.08,1.02.92,1.8,1.95,1.82h11.88c1.04-.02,1.9-.84,1.96-1.88l.04-.62c.36-.22.59-.62.58-1.05v-.27c0-.32-.12-.62-.34-.85Zm-.59,1.44c-.07.06-.16.11-.27.11h-3.43l.92-1.13h2.51s.04,0,.06.01c.2.03.36.2.36.42v.27c0,.13-.06.24-.15.32Zm-15,.08s-.02-.01-.03-.02c-.04-.02-.07-.04-.1-.07,0,0,0-.01-.01-.02-.07-.07-.11-.16-.11-.26v-.31c0-.23.18-.42.41-.43h.71c.21.48.71.76,1.24.67.56-.11.96-.59.95-1.16v-.17c0-.08.04-.16.1-.21.06-.05.15-.08.22-.08.16,0,.28.13.29.28,0,.49.39.9.88.91h.21c.25,0,.48-.08.67-.24h3.14c.06.06.15.17.27.31.35.4.58.66.74.82h-9.43c-.06,0-.11-.01-.16-.03Zm15.11-2.66v.35c0,.23-.19.42-.42.43h-1.83l.99-1.21h.84c.23,0,.42.2.42.43Zm-9.26.78c.01-.19.01-.49,0-.96,0-.09,0-.18,0-.24h.94l1.08,1.2h-2.02Zm8.69-2h-14.17c-.16,0-.29-.13-.29-.29-.08-1.27.35-2.54,1.19-3.5l.04-.04c1.09-1.21,3.19-1.89,5.8-1.89.12,0,.23,0,.35,0,2.86.04,4.93.73,6.15,2.03.84.93,1.28,2.14,1.23,3.4,0,.16-.13.29-.29.29Zm-14.42.8h.65v1.2h-.65s-.05-.01-.08-.02c-.19-.04-.34-.21-.33-.41v-.34c0-.23.18-.42.41-.43Zm14.4,3.9h.05s-.03.4-.03.4h0c-.04.63-.56,1.14-1.19,1.15h-11.88c-.62,0-1.13-.48-1.18-1.1l-.04-.45c.6,0,3.17,0,14.27,0Z"
        fill="#747480"
      />
      <g>
        <path
          id="Path_32761"
          d="m38.98,34.17h.21c.26,0,.47-.22.47-.48v-1.26h-3.84v1.64c-.01.29.22.53.51.55.29.01.53-.22.55-.51v-.26c0-.51.43-.92.94-.92.51,0,.92.43.92.94v.03c0,.14.11.26.26.27,0,0,0,0,0,0"
          fill="#e7e8ef"
        />
        <path
          id="Path_32762"
          d="m45.24,35.41l2.38-2.98h-5.66l2.63,2.99c.15.18.41.2.59.05,0,0,0,0,0,0,.02-.02.04-.04.06-.07"
          fill="#e7e8ef"
        />
      </g>
    </g>
  </SvgIcon>
);

export default OrderTracker5;
