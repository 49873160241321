import React from 'react';

import ConnectionTable from '../../../../blocks/ConnectionTable';
import { LOYALTY_EVENTS } from '../../../../../apollo/queries/loyalty/loyaltyEvents';
import { Modal, DataTable } from '../../../../blocks';

import EditLoyaltyEventModal from '../EditLoyaltyEventsModal';
import { LoyaltyEventsStyle } from './style';

const LoyaltyEventsView = ({
  setOpen,
  setLoyaltyUpdateData,
  open,
  handleClose,
  loyaltyUpdateData,
  handleOnUpdateLoyaltyevent,
  setConnectionVariables,
}) => {
  const classes = LoyaltyEventsStyle();

  return (
    <div id="loyaltyevents">
      <ConnectionTable
        setConnectionVariables={setConnectionVariables}
        query={LOYALTY_EVENTS}
        initialQueryVariables={{
          sort: { loyaltyeventId: 'ASC' },
          first: 15,
        }}
        title="Loyalty Events"
        showSearch={false}
        onTableRowClick={(row, data, variables) => {
          setLoyaltyUpdateData(row);
          setOpen(true);
        }}
        structureTable={data =>
          data?.viewer?.loyaltyeventConnection?.edges?.map(edge => {
            return {
              loyaltyeventId: edge.node.loyaltyeventId,
              label: edge.node.label,
              description: edge.node?.description,
              loyaltyeventBrandConnection: edge.node?.loyaltyeventBrandConnection,
            };
          })
        }
        columns={[
          {
            title: 'Event ID',
            field: 'loyaltyeventId',
          },
          {
            title: 'Event Name',
            field: 'label',
          },
          {
            title: 'Description',
            field: 'description',
          },
        ]}
        collapsableRows={(data, row, query) => {
          return (
            <div className={classes.dataTable}>
              <DataTable
                data={row?.loyaltyeventBrandConnection}
                structureTable={eventBrands => {
                  return eventBrands?.edges?.map(eventBrand => {
                    return {
                      brand: eventBrand?.node?.brand?.label,
                      points: eventBrand?.node?.points,
                      bonusPoints: eventBrand?.node?.bonusPoints,
                      loyaltystoreitem: eventBrand?.node?.coupon?.label,
                    };
                  });
                }}
                columns={[
                  { title: 'Brand', field: 'brand' },
                  { title: 'Points', field: 'points' },
                  { title: 'Bonus Points', field: 'bonusPoints' },
                  { title: 'Loyalty Store Item', field: 'loyaltystoreitem' },
                ]}
              />
            </div>
          );
        }}
      />
      <Modal open={open} handleClose={handleClose} title={'Update Loyalty Event'}>
        <EditLoyaltyEventModal
          loyaltyUpdateData={loyaltyUpdateData}
          handleClose={handleClose}
          handleOnUpdateLoyaltyevent={handleOnUpdateLoyaltyevent}
        />
      </Modal>
    </div>
  );
};

export default LoyaltyEventsView;
