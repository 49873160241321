import { gql } from '@apollo/client';

const UPDATE_MEASURE = gql`
  mutation UPDATE_MEASURE($input: UpdateMeasureInput!) {
    updateMeasure(input: $input) {
      viewer {
        id
      }
      measure {
        id
        measureId
        label
        abbreviation
        active
        created
      }
    }
  }
`;

export default UPDATE_MEASURE;
