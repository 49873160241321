import React from 'react';
import UsageLimit from '../UsageLimit';
import CouponStacking from '../CouponStacking';
import Locations from '../Locations';
import Categories from '../Categories';
import Subcategories from '../Subcategories';
import Items from '../Items';
import ExcludeHandoffs from '../ExcludeHandoffs';
import ExcludeDates from '../ExcludeDates';
import { Divider } from '@material-ui/core';

function DiscountResctrictionsView({
  control,
  setExcludedItems,
  excludedItems,
  includedLocations,
  setIncludedLocations,
  excludedLocations,
  setExcludedLocations,
  excludedDates,
  setExcludedDates,
  excludedSubcategories,
  coupon,
  setExcludedSubcategories,
  setExcludedHandoffs,
  excludedHandoffs,
  handoffList,
  displayedLocationRestriction,
  setDisplayedLocationRestriction,
}) {
  return (
    <div>
      <h2 id="discountRestrictions">Discount restrictions</h2>
      <UsageLimit coupon={coupon} control={control} />
      <Divider />
      <CouponStacking coupon={coupon} control={control} />

      {(displayedLocationRestriction === 'all' || displayedLocationRestriction === 'include') && (
        <>
          <Divider />
          <Locations
            title={'Include Locations'}
            coupon={coupon}
            control={control}
            locations={includedLocations}
            setLocations={setIncludedLocations}
            displayedLocationRestriction={displayedLocationRestriction}
            setDisplayedLocationRestriction={setDisplayedLocationRestriction}
          />
        </>
      )}
      {(displayedLocationRestriction === 'all' || displayedLocationRestriction === 'exclude') && (
        <>
          <Divider />
          <Locations
            title={'Exclude Locations'}
            type="exclude"
            name="discountRestrictions.locations.enabledExclude"
            coupon={coupon}
            control={control}
            locations={excludedLocations}
            setLocations={setExcludedLocations}
            displayedLocationRestriction={displayedLocationRestriction}
            setDisplayedLocationRestriction={setDisplayedLocationRestriction}
          />
        </>
      )}
      <Divider />
      {/* <Categories
        control={control}
        excludedCategories={excludedCategories}
        setExcludedCategories={setExcludedCategories}
      /> */}
      <Subcategories
        control={control}
        excludedSubcategories={excludedSubcategories}
        setExcludedSubcategories={setExcludedSubcategories}
      />
      <Divider />
      <Items
        coupon={coupon}
        control={control}
        setExcludedItems={setExcludedItems}
        excludedItems={excludedItems}
      />
      <Divider />
      <ExcludeHandoffs
        control={control}
        setExcludedHandoffs={setExcludedHandoffs}
        excludedHandoffs={excludedHandoffs}
        handoffList={handoffList}
        coupon={coupon}
      />
      {/* <Divider />
      <ExcludeDates
        excludedDates={excludedDates}
        setExcludedDates={setExcludedDates}
        control={control}
      /> */}
    </div>
  );
}

export default DiscountResctrictionsView;
