import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { paths } from '../../../../constants';
import ActualTheoretical from './ActualTheoretical';
import WeekInLife from './WeekInLife';
import PurchaseLogInvoices from './PurchaseLogInvoices';
import PurchaseLogProducts from './PurchaseLogProducts';
import CostOfGoods from './CostOfGoods';
import InventoryCount from './InventoryCount';

const TabContainer = ({ value }) => (
  <AppBar position="static">
    <Tabs
      value={value}
      variant="scrollable"
      visibleScrollbar={false}
      scrollButtons={{ display: 'none' }}
    >
      {/* <Tab label="Actual v Theoretical" component={Link} to={paths.COREOPS_REPORTS} /> */}
      <Tab label="Inventory Count" component={Link} to={paths.COREOPS_REPORTS_INVENTORY_COUNT} />
      <Tab label="Week In The Life" component={Link} to={paths.COREOPS_REPORTS_WEEK_IN_LIFE} />
      <Tab
        label="Purchase Log - Invoices"
        component={Link}
        to={paths.COREOPS_REPORTS_PURCHASE_LOG_INVOICES}
      />
      <Tab
        label="Purchase Log - Products"
        component={Link}
        to={paths.COREOPS_REPORTS_PURCHASE_LOG_PRODUCTS}
      />
      {/* <Tab label="Cost Of Goods" component={Link} to={paths.COREOPS_REPORTS_COST_OF_GOODS} /> */}
    </Tabs>
  </AppBar>
);

const Reports = () => {
  return (
    <div>
      <Typography variant="h4">Reports</Typography>
      <Switch>
        <Route
          exact
          path={paths.COREOPS_REPORTS}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <ActualTheoretical />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.COREOPS_REPORTS_WEEK_IN_LIFE}
          render={() => (
            <Fragment>
              <TabContainer value={1} />
              <WeekInLife />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.COREOPS_REPORTS_PURCHASE_LOG_INVOICES}
          render={() => (
            <Fragment>
              <TabContainer value={2} />
              <PurchaseLogInvoices />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.COREOPS_REPORTS_PURCHASE_LOG_PRODUCTS}
          render={() => (
            <Fragment>
              <TabContainer value={3} />
              <PurchaseLogProducts />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.COREOPS_REPORTS_COST_OF_GOODS}
          render={() => (
            <Fragment>
              <TabContainer value={4} />
              <CostOfGoods />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.COREOPS_REPORTS_INVENTORY_COUNT}
          render={() => (
            <Fragment>
              <TabContainer value={5} />
              <InventoryCount />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  );
};

Reports.WeekInLife = WeekInLife;
export default Reports;
