import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import LocationUsersView from "./View";
import UserDataGrid from "../DataGrids/UserDataGrid";

function LocationUsers({
  sendUsersAndLocations,
  locationId,
  locationDetails,
  userIds,
  bulkUpdateUsersState,
  setBulkUpdateUsersState,
  setUserIds,
  loginIds,
  setLoginIds,
}) {
  return (
    <div>
      <h2>{locationDetails.label}</h2>
      <UserDataGrid
        userIds={userIds}
        loginIds={loginIds}
        bulkUpdateUsersState={bulkUpdateUsersState}
        setBulkUpdateUsersState ={setBulkUpdateUsersState}
        setLoginIds={setLoginIds}
        sendUsersAndLocations={sendUsersAndLocations}
        locationId={locationId}
        setUserIds={setUserIds}
      />
    </div>
  );
}

export default LocationUsers;
