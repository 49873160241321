import { sortBy } from 'lodash-es';
import moment from 'moment';

export const prepareDataForPrepPdf = (data = {}) => {
  const measure =
    data.inventoryitem?.inventoryitemMeasureConnection?.edges &&
    data.inventoryitem.inventoryitemMeasureConnection.edges[0]?.node?.measure;

  const recipeImageUrl =
    data.recipeimageConnection?.edges && data.recipeimageConnection.edges[0]?.node?.url;

  return {
    title: data.inventoryitem?.label,
    prepTime: data.prepTime,
    yieldQuantity: measure ? `${data.yieldQuantity} ${measure.abbreviation}` : '',
    recipeImageUrl,
    shelfLife: data.shelfLife,
    storageAndHoldingProcedures: data.storageAndHoldingProcedures,
    equipment: sortBy(data.equipment || [], 'orderKey').map(item => ({
      label: item.name,
      quantity: item.count,
    })),
    ingredients: sortBy(data.components || [], 'orderKey').map(item => ({
      label: item.name,
      quantity: item.count,
      measure: item.measure?.measureAbbreviation,
    })),
    prepInstructions: data.preparationNotes,
    serviceStandards: data.serviceStandards,
    lastUpdated: moment(data.created).format('MMMM DD, YYYY'),
    facts: {
      flavor: data.flavor,
      consistency: data.consistency,
      appearance: data.appearance,
      temperature: data.temperature,
    },
  };
};
