import React, { useContext } from 'react';
import config from '../../../config';
import { CoreContext } from '../../../CoreContext';

import HomeView from './View';

const Home = () => {
  const context = useContext(CoreContext);
  const actions = context.coreUtils.getAppActions();

  const features = [];

  const modules = config.modules;

  const addFeature = module => {
    if (module.id !== '0' && features.length < 4) {
      const feature = {
        id: module.id,
        label: module.label,
        icon: module.icon,
        path: module.path || (module.children ? module.children[0].path : ''),
      };
      features.push(feature);
    }
  };

  modules.forEach((module) => {
    actions[module.id]?.access && addFeature(module);
  });

  return <HomeView features={features} />;
};

export default Home;
