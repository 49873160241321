import React, { useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DailyForecasterCategoryView from './View';
import { DateTime } from 'luxon';

const calculateProteinUsage = protein =>
  (protein.am || 0) + (protein.pm || 0) + (protein.catering || 0) - (protein.onHand || 0);

const calculateSideUsage = side =>
  (side.am || 0) + (side.pm || 0) + (side.catering || 0) - (side.onHand || 0);

const calculateSmokeTodayForTomorrowUsage = protein =>
  (protein.am || 0) + (protein.pm || 0) + (protein.catering || 0);

const calculateDailyForecaster = apiData => {
  const proteins = apiData.proteins.map(protein => {
    const usage = calculateProteinUsage(protein);
    return { ...protein, usage, strategic: usage };
  });

  const sides = apiData.sides.map(side => {
    const usage = calculateSideUsage(side);
    return { ...side, usage, strategic: usage };
  });

  const smokeTodayForTomorrowProteins = apiData.smokeTodayForTomorrow.proteins.map(protein => {
    const usage = calculateSmokeTodayForTomorrowUsage(protein);
    return { ...protein, usage, strategic: usage };
  });

  const dailyForecaster = {
    proteins,
    sides,
    smokeTodayForTomorrow: {
      ...apiData.smokeTodayForTomorrow,
      proteins: smokeTodayForTomorrowProteins,
    },
  };

  return dailyForecaster;
};

const getPreviousDay = (variables, refetch) => {
  const orderingForecasterStart =
    variables?.orderingForecasterStart || DateTime.local().toFormat('yyyy-LL-dd');
  const orderingForecasterDate =
    variables?.orderingForecasterDate || DateTime.local().toFormat('yyyy-LL-dd');
  const forecasterStart = DateTime.fromFormat(orderingForecasterStart, 'yyyy-LL-dd');
  const previousDay = DateTime.fromFormat(orderingForecasterDate, 'yyyy-LL-dd').minus({ day: 1 });
  const currentDay = DateTime.fromFormat(orderingForecasterDate, 'yyyy-LL-dd');

  if (forecasterStart < currentDay) {
    return (
      <IconButton
        onClick={() => {
          refetch({
            ...variables,
            orderingForecasterDate: previousDay.toFormat('yyyy-LL-dd'),
          });
        }}
      >
        <ArrowBackIosIcon />
        {previousDay.toFormat('ccc dd')}
      </IconButton>
    );
  }
};

const getNextDay = (variables, refetch) => {
  const orderingForecasterEnd =
    variables?.orderingForecasterEnd || DateTime.local().toFormat('yyyy-LL-dd');
  const orderingForecasterDate =
    variables?.orderingForecasterDate || DateTime.local().toFormat('yyyy-LL-dd');
  const forecasterEnd = DateTime.fromFormat(orderingForecasterEnd, 'yyyy-LL-dd');
  const nextDay = DateTime.fromFormat(orderingForecasterDate, 'yyyy-LL-dd').plus({ day: 1 });
  const currentDay = DateTime.fromFormat(orderingForecasterDate, 'yyyy-LL-dd');

  if (forecasterEnd > currentDay) {
    return (
      <IconButton
        onClick={() => {
          refetch({
            ...variables,
            orderingForecasterDate: nextDay.toFormat('yyyy-LL-dd'),
          });
        }}
      >
        <ArrowForwardIosIcon />
        {nextDay.toFormat('ccc dd')}
      </IconButton>
    );
  }
};

const DailyForecasterCategory = ({
  data,
  refetch,
  variables,
  saveDailyForecaster,
  getSideUOM,
  saveDailyForecasterLoading,
  setSnackbar,
}) => {
  return (
    <DailyForecasterCategoryView
      refetch={refetch}
      variables={variables}
      getNextDay={getNextDay}
      getPreviousDay={getPreviousDay}
      calculateDailyForecaster={calculateDailyForecaster}
      calculateSmokeTodayForTomorrowUsage={calculateSmokeTodayForTomorrowUsage}
      calculateSideUsage={calculateSideUsage}
      calculateProteinUsage={calculateProteinUsage}
      data={data}
      saveDailyForecaster={saveDailyForecaster}
      saveDailyForecasterLoading={saveDailyForecasterLoading}
      getSideUOM={getSideUOM}
      setSnackbar={setSnackbar}
    />
  );
};

export default DailyForecasterCategory;
