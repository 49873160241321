import { gql } from '@apollo/client';

const FETCH_CATEGORY_TYPES = gql`
  query fetchCategoryTypes($first: Int) {
    viewer {
      id
      categorytypeConnection(first: $first) {
        edges {
          node {
            id
            categorytypeId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_CATEGORY_TYPES;
