import { gql } from '@apollo/client';

export const CREATE_CUSTOM_FIELD_ICONTACT = gql`
mutation createCustomFieldiContact(
  $input: CreateCustomFieldiContactMutationInput!
) {
 createCustomFieldiContact(input: $input){
   customField
 }
}
`