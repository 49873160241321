import { useContext, useEffect, useState, useMemo } from 'react';
import { SNACKBAR_STATUS } from '../constants';

import { CoreContext } from '../CoreContext';
import { useSnackbar } from './useSnackbar';

const accessForRequiredFields = ({ requiredFields = [], permissions = {}}) => {
  return Math.min(...requiredFields.map(requiredField => {
    let access = permissions[requiredField];
    if (access && typeof access === 'object') {
      access = access.__typename; 
    }

   if (typeof requiredField !== "string") {
     return accessForRequiredFields({
       requiredFields: requiredField,
       permissions: access,
     })
   } 

   // logs all fields that have no access (if access = null, propagate those fields)
   if (!([true, undefined].includes(access) || access)){
     console.log("NO ACCESS FIELD: ", requiredField, access);
   }

   return [true, undefined].includes(access) ? 7 : access;
 }), 7);
};

const useFormSubmitPermissions = ({
        mutationNames,
        permissions,
        changeFieldLabel,
      }) => {
        const { mutationRequiredFields = {} } = useContext(CoreContext);const { setSnackbar } = useSnackbar();

        const [
          openWarningMutationName,
          setOpenWarningMutationName,
        ] = useState();

        useEffect(() => {
          if (changeFieldLabel) {
            changeFieldLabel.forEach(({ label, mutationName, newLabel }) => {
              const labelIndex = mutationRequiredFields[
                mutationName
              ]?.findIndex((field) => field === label);
              if (labelIndex > -1) {
                mutationRequiredFields[mutationName][labelIndex] = newLabel;
              }
            });
          }
        }, [changeFieldLabel, mutationRequiredFields]);

        const accessToMutations = useMemo(
          () =>
            mutationNames.reduce((result, mutationName) => {
              // log all mutation required fields
              // console.log(mutationRequiredFields[mutationName]);
              result[mutationName] = accessForRequiredFields({
                requiredFields: mutationRequiredFields[mutationName],
                permissions,
              });

              return result;
            }, {}),
          [mutationNames, permissions, mutationRequiredFields]
        );

        useEffect(() => {
          if (
            openWarningMutationName &&
            accessToMutations[openWarningMutationName] !== 7
          ) {
            setSnackbar({
              type: SNACKBAR_STATUS.WARNING,
              text:
                "You do not have right access to all required fields to submit this form.",
              open: true,
            });
          }

          setOpenWarningMutationName();
        }, [accessToMutations, openWarningMutationName, setSnackbar]);

        // log all mutatition required field accesses
        // console.log({
        //   accessToMutations,
        //   openWarningMutationName,
        // });

        return {
          accessToMutations,
          setOpenWarningMutationName,
        };
      };;

export default useFormSubmitPermissions;
