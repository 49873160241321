import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  triggerContainer: {
    marginLeft: '25px',
  },
  groups: {
    marginTop: '15px',
  },
});

const ItemAddedNumberTimesStyles = makeStyles(Styles);

export default ItemAddedNumberTimesStyles;
