import { Button } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SelectFactory } from '../../../../../../../blocks';

import { INVENTORYITEMS } from '../queries';

const AddInventoryItem = ({
  onClick,
  showSelectFactory,
  setShowSelectFactory,
  hasItems,
  submitButtonLocked,
  selectedIngredientIds,
}) => {
  const { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];

  const handleOnSelect = useCallback(
    value => {
      if (value) {
        onClick(value);
      } else {
        setShowSelectFactory(false);
      }
    },
    [onClick, setShowSelectFactory],
  );

  return (
    <Grid container spacing={3} justify="flex-end" alignItems="center">
      {showSelectFactory || !hasItems ? (
        <>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Add Ingredients
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectFactory
              label="Ingredient Name"
              fullWidth
              filter={{
                inventoryitemId: {
                  nin: selectedIngredientIds,
                },
                type: { eq: 'ingredient' },
              }}
              otherVariables={{
                locationId: +locationId,
              }}
              sort={{
                label: 'ASC',
              }}
              name="product"
              placeholder="Search Ingredients"
              query={INVENTORYITEMS}
              structureData={data =>
                data?.viewer?.inventoryitemConnection?.edges?.map((edge, index) => {
                  return {
                    label: edge.node.label,
                    ingredientId: edge.node.inventoryitemId,
                    index,
                    measureId: edge.node.measure?.measureId,
                    latestCost: edge.node.latestCost,
                    measure: `${edge.node.measure.label} (${edge.node.measure.abbreviation})`,
                  };
                })
              }
              defaultValue=""
              multiple={false}
              renderCheckBox={false}
              onSelect={handleOnSelect}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={6}>
          <Button
            disabled={submitButtonLocked}
            style={{ minWidth: '100%' }}
            type="button"
            variant="outlined"
            onClick={() => setShowSelectFactory(true)}
          >
            <AddIcon style={{ marginRight: '4px' }} /> Add ingredient
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AddInventoryItem;
