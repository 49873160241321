import React, { useMemo } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import Permission from '../../../../../blocks/Permission';
import { convert, toFixed10 } from '../../../../../../utils';
import { ScheduleLaborStyles } from './styles';

const TARGET_LABOR_TOOLTIP_TEXT =
  'Corporate suggested labor cost based on a recommended 14% target and the Daily Sales Projections above.';
const SCHEDULED_LABOR_TOOLTIP_TEXT =
  'Actual labor cost and percentage based on shifts in the schedule below.';

const PERMISSION_READ_ACCESS = [5, 6, 7];

const ScheduleLaborView = ({
  salaryPermission,
  weeklyScheduleProjections,
  targetLaborData,
  sortedWeekdays,
  smokestackData,
  actualLaborData,
  totalActualLabor,
  targetLaborPercentage,
  totalActualLaborPercent,
  totalTargetLabor,
  SPECIFIC_LOCATION_LABOR_PERCENTAGE,
}) => {
  const classes = ScheduleLaborStyles();

  const formattedCurrency = convert.formattedCurrency;
  const userHasSalaryViewPermission = useMemo(
    () => PERMISSION_READ_ACCESS.includes(salaryPermission),
    [salaryPermission],
  );

  const totalActualLaborColor = useMemo(() => {
    if (totalActualLaborPercent === 0) {
      return '#000';
    }
    if (totalActualLaborPercent > targetLaborPercentage * 100) {
      return '#d40e37';
    }

    return '#37c78a';
  }, [totalActualLaborPercent]);

  return (
    <div className={classes.laborContainer}>
      <Permission
        access={
          weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection?.permissions
            ?.scheduleweeklysalesprojectionId
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid item xs={2} direction="column" justify="center">
              <strong className={classes.title}>Target Labor $/%</strong>
              <div className={classes.tooltipDiv}>
                <strong className={classes.title}>Based on 14% target</strong>
                <Tooltip
                  title={TARGET_LABOR_TOOLTIP_TEXT}
                  aria-label="add"
                  className={classes.tooltip}
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            </Grid>

            {targetLaborData?.map((amount, index) => (
              <Grid item xs={1} direction="column">
                <strong>{sortedWeekdays[index]}</strong>
                {userHasSalaryViewPermission && (
                  <span className={classes.data}>{formattedCurrency(amount)}</span>
                )}
                <span className={classes.data}>
                  {toFixed10(
                    SPECIFIC_LOCATION_LABOR_PERCENTAGE[
                      smokestackData?.viewer?.locationConnection?.edges[0]?.node?.id
                    ] * 100 || 14,
                    2,
                  )}
                  %
                </span>
              </Grid>
            ))}

            <Grid item xs={1} direction="column">
              <strong>Labor $/% Week Total</strong>
              {userHasSalaryViewPermission && (
                <span className={classes.data}>{formattedCurrency(totalTargetLabor)}</span>
              )}
              <span className={classes.data}>
                {toFixed10(
                  SPECIFIC_LOCATION_LABOR_PERCENTAGE[
                    smokestackData?.viewer?.locationConnection?.edges[0]?.node?.id
                  ] * 100 || 14,
                  2,
                )}
                %
              </span>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={2} alignItems="center">
              <strong className={classes.title}>Scheduled Labor $/%</strong>
              <div className={classes.tooltip}>
                <Tooltip title={SCHEDULED_LABOR_TOOLTIP_TEXT} aria-label="add">
                  <InfoIcon />
                </Tooltip>
              </div>
            </Grid>

            {actualLaborData?.map((day, index) => {
              const percent = isFinite(day.amount) ? Math.round(day.percent * 10000) / 100 : 0;
              const isOverFourteenPercent = percent > Math.round(targetLaborPercentage * 100);
              let fontColor = '';
              if (percent > Math.round(targetLaborPercentage * 100)) {
                fontColor = '#d40e37';
              } else if (percent === 0) {
                fontColor = '#000000';
              } else {
                fontColor = '#37c78a';
              }

              return (
                <Grid item xs={1} direction="column">
                  <strong>{sortedWeekdays[index]}</strong>
                  {userHasSalaryViewPermission && (
                    <span className={classes.data} style={{ color: fontColor }}>
                      {formattedCurrency(day.amount)}
                    </span>
                  )}
                  <span className={classes.data} style={{ color: fontColor }}>
                    {percent}%
                  </span>
                </Grid>
              );
            })}

            <Grid item xs={1} direction="column">
              <strong>Labor $/% Week Total</strong>
              {userHasSalaryViewPermission && (
                <span className={classes.data} style={{ color: totalActualLaborColor }}>
                  {formattedCurrency(totalActualLabor.amount)}
                </span>
              )}
              <span className={classes.data} style={{ color: totalActualLaborColor }}>
                {totalActualLaborPercent}%
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Permission>
    </div>
  );
};

export default ScheduleLaborView;
