import React from 'react';
import UsageLimit from '../UsageLimit';
import CouponStacking from '../CouponStacking';
import Locations from '../Locations';
import Subcategories from '../Subcategories';
import Categories from '../Categories';
import Items from '../Items';
import ExcludeHandoffs from '../ExcludeHandoffs';
import ExcludeDates from '../ExcludeDates';
import { Divider } from '@material-ui/core';

function DiscountResctrictionsView({
  control,
  setExcludedItems,
  excludedItems,
  includedLocations,
  setIncludedLocations,
  excludedLocations,
  setExcludedLocations,
  excludedDates,
  setExcludedDates,
  excludedSubcategories,
  setExcludedSubcategories,
  setExcludedHandoffs,
  excludedHandoffs,
  handoffList,
  displayedLocationRestriction,
  setDisplayedLocationRestriction,
}) {
  return (
    <div>
      <h2 id="discountRestrictions">Discount restrictions</h2>
      <UsageLimit control={control} />
      <Divider />
      <CouponStacking control={control} />
      {displayedLocationRestriction === 'all' || displayedLocationRestriction === 'include' ? (
        <>
          <Divider />
          <Locations
            control={control}
            locations={includedLocations}
            setLocations={setIncludedLocations}
            displayedLocationRestriction={displayedLocationRestriction}
            setDisplayedLocationRestriction={setDisplayedLocationRestriction}
          />
        </>
      ) : (
        ''
      )}
      {displayedLocationRestriction === 'all' || displayedLocationRestriction === 'exclude' ? (
        <>
          <Divider />
          <Locations
            displayedLocationRestriction={displayedLocationRestriction}
            setDisplayedLocationRestriction={setDisplayedLocationRestriction}
            type="exclude"
            name="discountRestrictions.locations.enabledExclude"
            control={control}
            locations={excludedLocations}
            setLocations={setExcludedLocations}
          />
        </>
      ) : (
        ''
      )}
      <Divider />
      {/* <Categories
        control={control}
        excludedCategories={excludedCategories}
        setExcludedCategories={setExcludedCategories}
      /> */}
      <Subcategories
        control={control}
        excludedSubcategories={excludedSubcategories}
        setExcludedSubcategories={setExcludedSubcategories}
      />
      <Divider />
      <Items control={control} setExcludedItems={setExcludedItems} excludedItems={excludedItems} />
      <Divider />
      <ExcludeHandoffs
        control={control}
        setExcludedHandoffs={setExcludedHandoffs}
        excludedHandoffs={excludedHandoffs}
        handoffList={handoffList}
      />
      {/* <Divider />
      <ExcludeDates
        excludedDates={excludedDates}
        setExcludedDates={setExcludedDates}
        control={control}
      /> */}
    </div>
  );
}

export default DiscountResctrictionsView;
