import { gql } from '@apollo/client';
import { loyaltysettingsFragment } from '../../fragments';

export const UPDATE_LOYALTY_SETTINGS = gql`
  mutation updateLoyaltysettings($input: UpdateLoyaltysettingsMutationInput!) {
    updateLoyaltysettings(input: $input) {
      viewer {
        id
      }
      clientMutationId
      loyaltysettingsEdge {
        ...LoyaltysettingsData
      }
    }
  }
  ${loyaltysettingsFragment.loyaltysettings}
`;
