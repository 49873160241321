import { gql } from '@apollo/client';

const NUTRIENTS_LIST = gql`
  query Nutrients(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [NutrientSort]
    $filter: [NutrientFilter]
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      nutrientConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: $fieldGroupOnly
      ) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            permission
            nutrientId
            label
            description
            created
            updated
            measure {
              id
              label
              abbreviation
            }
          }
        }
      }
      measureConnection(fieldGroupOnly: 7) {
        edges {
          node {
            id
            label
            abbreviation
          }
        }
      }
    }
  }
`;

export default NUTRIENTS_LIST;
