import { isEmpty } from 'lodash-es';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function createContent({ title, subtitles, tableData, header, isBoldFieldName = 'isBold' }) {
  return [
    {
      text: title,
      style: 'header',
    },
    ...(!isEmpty(subtitles)
      ? subtitles.map(subtitle => ({
          text: subtitle,
          style: 'subtitle',
        }))
      : []),
    {
      style: 'tableStyle',
      table: {
        widths: header.map(() => '*'),
        body: [
          header.map(header => header.text),
          ...tableData.map(row =>
            header.map(header => {
              const item = row[header.field] || '';
              return { text: item, bold: !!header.isBold && !!row[isBoldFieldName] };
            }),
          ),
        ],
      },
      layout: {
        paddingLeft: function() {
          return 5;
        },
        paddingRight: function() {
          return 5;
        },
        paddingTop: function() {
          return 5;
        },
        paddingBottom: function() {
          return 5;
        },
        hLineWidth: function(i) {
          return i === 1 ? 2 : 1;
        },
      },
    },
  ];
}

const generateReportPdf = ({
  title = 'Report',
  tableData = [],
  header = [],
  subtitles,
  isBoldFieldName = 'isBold',
}) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const docDefinition = {
    pageSize: 'A4',
    content: createContent({
      title,
      isBoldFieldName,
      tableData,
      subtitles,
      header,
    }),
    styles: {
      tableStyle: {},
      header: {
        fontSize: 16,
        bold: true,
        marginBottom: 10,
        color: '#3B3A43',
      },
      subtitle: {
        fontSize: 14,
        bold: true,
        marginBottom: 10,
        color: '#3B3A43',
      },
    },
    defaultStyle: {
      color: '#3B3A43',
      fontSize: '11',
    },
    pageMargins: [12, 12, 12, 12],
    pageOrientation: 'landscape',
  };

  pdfMake.createPdf(docDefinition).open();
};

export default generateReportPdf;
