import { FormHelperText } from '@material-ui/core';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import NumberInput from '../../../../../../../blocks/NumberInput';

const Conversion = ({
  item: conversion,
  control,
  register,
  autoFocus,
  errors,
  measureOptions,
  mainMeasure,
  getValues,
  id,
  duplicateMeasureIds,
}) => {
  const isDuplicate = useMemo(() => duplicateMeasureIds.includes(getValues(`${id}.measureId`)), [
    duplicateMeasureIds,
  ]);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <Typography variant="body1">1 {mainMeasure || 'Main UOM'} =</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={5}>
            <NumberInput
              autoFocus={autoFocus}
              defaultValue={conversion.conversionMath || ''}
              id={`${id}.conversionMath`}
              validations={{
                positive: value => value > 0,
              }}
              required
              label="Conversion Math"
              error={!!errors[id]?.conversionMath}
              helperText={
                errors[id]?.conversionMath?.type === 'required'
                  ? 'Value is required'
                  : errors[id]?.conversionMath?.type === 'positive'
                  ? 'Value must be greater than 0'
                  : ''
              }
              control={control}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel error={!!errors[id]?.measureId || isDuplicate}>Recipe UOM</InputLabel>
              <Controller
                rules={{
                  required: true,
                  duplicate: value => duplicateMeasureIds.includes(value),
                }}
                id={`${id}.measureId`}
                name={`${id}.measureId`}
                defaultValue={conversion.measureId || ''}
                label="Recipe UOM"
                placeholder="Recipe UOM"
                error={!!errors[id]?.measureId || isDuplicate}
                as={
                  <Select
                    label="Recipe UOM"
                    multiple={false}
                    error={!!errors[id]?.measureId || isDuplicate}
                  >
                    {measureOptions?.map(measure => (
                      <MenuItem key={measure.value} value={measure.value}>
                        {measure.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                control={control}
              />
              {errors[id]?.measureId?.type === 'required' ? (
                <FormHelperText error>Measure is required</FormHelperText>
              ) : isDuplicate ? (
                <FormHelperText error>Measure is duplicated</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              name={`${id}.isForCount`}
              id={`${id}.isForCount`}
              defaultValue={conversion.isForCount}
              defaultChecked={conversion.isForCount}
              inputRef={register()}
              control={
                <Checkbox
                  id={`${id}.isForCount`}
                  defaultChecked={conversion.isForCount}
                  name={`${id}.isForCount`}
                />
              }
              label="Include in Inventory Count"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Conversion;
