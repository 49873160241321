import { gql } from '@apollo/client';

export const REMOVE_INVENTORYITEMCATEGORY = gql`
  mutation REMOVE_INVENTORYITEMCATEGORY($inventoryitemcategoryId: Int!) {
    removeInventoryitemcategory(input: { inventoryitemcategoryId: $inventoryitemcategoryId }) {
      viewer {
        id
      }
      removed
    }
  }
`;
