import { gql } from '@apollo/client';

const FETCH_LOCATION_INVENTORYITEM_ACTION_LOGS = gql`
  query actionLogLocationItems(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [ActionLogLocationInventoryItemFilter]
    $sort: [ActionLogLocationInventoryItemSort]
  ) {
    viewer {
      id
      actionLogLocationInventoryitemConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        filter: $filter
        sort: $sort
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        permissions
        edges {
          node {
            id
            actionLogLocationItemId
            location {
              label
            }
            inventoryitem {
              inventoryitemId
              label
            }
            timestamp
            description
            logAction {
              label
              type
            }
            creator {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export default FETCH_LOCATION_INVENTORYITEM_ACTION_LOGS;
