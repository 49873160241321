import { gql } from '@apollo/client';

const REMOVE_INVENTORYITEM_FROM_PRODUCTION_FORECASTER = gql`
  mutation removeInventoryItemFromProductionForecaster(
    $input: RemoveInventoryitemFromProductionForecasterInput!
  ) {
    removeInventoryitemFromProductionForecaster(input: $input) {
      inventoryitem {
        id
        inventoryitemCustomCategory {
          id
          inventoryitemCustomCategoryId
          label
        }
        inventoryitemPrep {
          id
          cspm
          size
          sizeCooked
          format
          partOfClosingInventory
          hasStrategic
          averageCasePackInfo
          smokeTodayForTomorrow
        }
      }
    }
  }
`;

export default REMOVE_INVENTORYITEM_FROM_PRODUCTION_FORECASTER;
