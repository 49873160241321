import { gql } from "@apollo/client";

const TOGGLE_LOCATION_ITEM = gql`
  mutation ToggleLocationItem($input: ToggleLocationItemMutationInput!) {
    toggleLocationItem(input: $input) {
      viewer {
      id
      }
    }
  }
`;

export default TOGGLE_LOCATION_ITEM;
