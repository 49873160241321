import { gql } from "@apollo/client";

const REMOVE_TAX_RATE = gql`
  mutation removeTaxRate($input: RemoveTaxrateMutationInput!) {
    removeTaxrate(input: $input) {
      viewer {
        id
      }
      removedTaxrateId
    }
  }
`;

export default REMOVE_TAX_RATE;
