import { Box, Collapse, ListItem, Typography, Grid, List } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { identity } from 'lodash-es';
import React, { useState, useEffect, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import Measure from './Measure';

const InventoryItem = ({
  id,
  register,
  inventoryItem,
  setValue,
  index,
  errors,
  measures,
  disabled,
  control,
}) => {
  const [open, setOpen] = useState(true);

  const isPrep = useMemo(
    () => inventoryItem.inventoryitem?.recipe?.recipetype?.recipetypeId === 1,
    [inventoryItem],
  );

  useEffect(() => {
    register(`inventoryItems.${index}.locationStorageInventoryitemId`);

    setValue(
      `inventoryItems.${index}.locationStorageInventoryitemId`,
      inventoryItem.locationStorageInventoryitemId,
    );
  }, [index]);

  return (
    <List style={{ margin: '0 -16px 0', padding: '0' }}>
      <ListItem
        role="button"
        onClick={() => setOpen(prev => !prev)}
        style={{ background: '#F8F8FA', padding: '8px 16px' }}
      >
        <Box
          style={{
            minWidth: '100%',
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {isPrep ? (
              <div
                style={{
                  background: '#575757',
                  borderRadius: '50%',
                  height: '26px',
                  width: '26px',
                  color: 'white',
                  marginRight: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                P
              </div>
            ) : null}
            <div>
              <Typography variant="h6">{inventoryItem.inventoryitem?.label}</Typography>
            </div>
          </Box>
          <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
        </Box>
      </ListItem>
      <Collapse style={{ padding: '0 16px' }} in={open} timeout="auto" unmountOnExit={false}>
        <Grid container spacing={3} style={{ padding: '16px 0' }}>
          <Grid item xs={12}>
            <Typography variant="body2">Item#: {inventoryItem.inventoryitemId}</Typography>
          </Grid>
          <Grid item xs={12} container spacing={3} justify="flex-end">
            {measures.length ? (
              measures.map((measure, measureIndex) => (
                <Measure
                  disabled={disabled}
                  name={`inventoryItems.${index}.measures.${measureIndex}`}
                  measure={measure}
                  id={`${id}.measures.${measure.measureId}`}
                  key={`${id}.measures.${measure.measureId}`}
                  register={register}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              ))
            ) : (
              <Typography variant="body1" style={{ color: 'red' }}>
                No measures provided for this item
              </Typography>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </List>
  );
};

export default InventoryItem;
