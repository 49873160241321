import React, { useState, useContext } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import AddTaxRate from "./AddTaxRate";
import { LocationIdContext } from "../../../Location";
import { DataTable, Modal, PermissionField } from "../../../../../blocks";

import { convert, toFixed10 } from '../../../../../../utils';
import { MUTATION_NAME, SNACKBAR_STATUS } from "../../../../../../constants";
import { REMOVE_TAX_RATE } from "../../../../../../apollo/mutations/locations";
import { FETCH_LOCATION } from "../../../../../../apollo/queries";
import Permission from '../../../../../blocks/Permission';
import { useSnackbar } from "../../../../../../hooks/useSnackbar";

const TaxRates = ({ taxRates, permissions, mutationPermissions, setOpenWarningMutationName, accessToMutations }) => {
  const { setSnackbar } = useSnackbar();

  const createTaxRatePermission = mutationPermissions.find(
    (mutationPermission) =>
      mutationPermission.label === 'createTaxRate'
  );
  const updateTaxRatePermission = mutationPermissions.find(
    (mutationPermission) =>
      mutationPermission.label === 'updateTaxRate'
  );
  const removeTaxRatePermission = mutationPermissions.find(
    (mutationPermission) =>
      mutationPermission.label === 'removeTaxRate'
  );
  const [showModal, setShowModal] = useState(false);
  const [editTaxRateData, setEditTaxRateData] = useState(null);
  const [removeTaxRate, { loading: isDeletingTaxRate }] = useMutation(REMOVE_TAX_RATE);
  const locationId = useContext(LocationIdContext);

  const handleOpenSnackBar = (text, type) => {
    setSnackbar({ open: true, type, text });
  };

  const handleRemoveTaxRate = async (taxRateId) => {
    try {
      await removeTaxRate({
        variables: {
          input: {
            taxrateId: taxRateId,
          },
        },
        refetchQueries: [
          {
            query: FETCH_LOCATION,
            variables: { id: locationId, first: 2147483647 },
          },
        ],
      });
      handleOpenSnackBar("Tax Rate removed.", SNACKBAR_STATUS.SUCCESS);
    } catch (error) {
      handleOpenSnackBar(error.message, SNACKBAR_STATUS.ERROR);
    }
  };

  const handleEditTaxRate = ({ id }) => {
    const selectedTaxRateData = taxRates?.edges.filter(
      (taxRate) => taxRate?.node?.id === id
    );
    if (selectedTaxRateData) {
      setEditTaxRateData(selectedTaxRateData[0]?.node);
      handleOpenModal(MUTATION_NAME.UpdateTaxrateMutationInput);
    }
  };

  const handleOpenModal = (mutationName) => {
    setShowModal(true);

    setOpenWarningMutationName(mutationName);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    if (editTaxRateData) setEditTaxRateData(null);
  };

  return (
    <>
      <Grid container key="locationTaxRates" id="taxRates">
        <DataTable
          data={taxRates}
          title="Tax Rates"
          permissions={permissions?.taxrateConnection?.edges?.node}
          structureTable={(data) =>
            data?.edges.map((taxrate) => ({
              label: taxrate.node.label,
              description: taxrate.node.description,
              fraction:
                taxrate.node.fraction === 0
                  ? "/"
                  : `${toFixed10(taxrate.node.fraction * 100, 2)} %`,
              amount:
                !taxrate?.node?.amount || taxrate.node.amount === 0
                  ? "/"
                  : `${convert.centsToDollars(taxrate.node.amount)} $`,
              surcharge: taxrate.node.surcharge,
              taxable: taxrate.node.taxable,
              display: taxrate.node.display,
              id: taxrate.node.id,
              disableTableRowClick: !updateTaxRatePermission?.access,
              disableRemove: !removeTaxRatePermission?.access
            }))
          }
          columns={[
            { title: "Tax Name", field: "label" },
            { title: "Description", field: "description" },
            {
              title: "Fraction - %",
              field: "fraction",
            },
            {
              title: "Amount - $",
              field: "amount",
            },
            {
              title: "Surcharge",
              field: "surcharge",
              enablePropagation: true,
              render: (surcharge) => (surcharge ? "On" : "Off"),
            },
            {
              title: "Taxable",
              field: "taxable",
              enablePropagation: true,
              render: (taxable) => (taxable ? "On" : "Off"),
            },
            {
              title: "Display on bill",
              field: "display",
              enablePropagation: true,
              render: (display) => (display ? "On" : "Off"),
            },
          ]}
          customActionsTop={() => (
            <Permission access={Math.min(createTaxRatePermission?.access, accessToMutations?.[MUTATION_NAME.CreateTaxrateMutationInput])}>
              <div>
                <PermissionField createHelperText>
                  <IconButton onClick={() => handleOpenModal(MUTATION_NAME.CreateTaxrateMutationInput)} variant="contained">
                    <Add />
                  </IconButton>
                </PermissionField>
              </div>
            </Permission>
          )}
          handleDeleteRow={handleRemoveTaxRate}
          onTableRowClick={handleEditTaxRate}
          useConfirmationModalForDelete
          isDeleting={isDeletingTaxRate}
          deleteConfirmationModalText="Are you sure you want to delete tax rate?"
        />
      </Grid>
      <Modal open={showModal} handleClose={handleCloseModal} title="Tax Rates">
        <AddTaxRate
          permissions={permissions}
          handleCancel={handleCloseModal}
          handleOpenSnackBar={handleOpenSnackBar}
          editData={editTaxRateData}
          accessToMutations={accessToMutations}
          mutationPermissions={mutationPermissions}
        />
      </Modal>
    </>
  );
};

export default TaxRates;

TaxRates.propTypes = {
  taxRates: PropTypes.object,
  taxGroups: PropTypes.object,
};
