import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

import NotesStyles from './styles';

const Notes = ({ notes = [], handleAddNewNote }) => {
  const classes = NotesStyles();

  const renderNoteRow = (noteRow) => (
    <>
      <Grid item xs={2}>
        <span className={classes.outlinedLabel}>External</span>
      </Grid>
      <Grid item xs={10} className={classes.paddingLeft}>
        <Typography variant="body2" component="p">
          {noteRow.note}
        </Typography>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography variant="subtitle2" component="p" gutterBottom>
          Notes
        </Typography>
        <IconButton variant="contained" onClick={handleAddNewNote}>
          <Add />
        </IconButton>
      </Grid>
      <Grid container>
        {notes.map((note) => (
          <Grid container item alignItems="center" key={note.id} className={classes.noteRow}>
            {renderNoteRow(note)}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

Notes.propTypes = {
  notes: PropTypes.array.isRequired,
  handleAddNewNote: PropTypes.func.isRequired,
};

export default Notes;
