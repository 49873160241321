import { gql } from "@apollo/client";

const REMOVE_TAX_GROUP = gql`
  mutation RemoveTaxGroup($input: RemoveTaxgroupMutationInput!) {
    removeTaxgroup(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default REMOVE_TAX_GROUP;
