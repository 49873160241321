import { gql } from "@apollo/client";

export default gql`
	query storeTypes{
		viewer {
			id 
			storeTypeConnection {
				edges {
					node {
						id
						storetypeId
						label
					}
				}
			}
		}
	}
`;
