import { gql } from "@apollo/client";

const LOCATION_VENDORS = gql`
  query LocationVendors(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationVendorSort]
    $filter: [LocationVendorFilter]
  ) {
    viewer {
      id
      locationVendorConnection(
        fieldGroup: 78
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
      ) {
        totalCount
        permissions
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            vendorId
            vendor {
              id
              label
            }
            deliveryUrl
          }
        }
      }
    }
  }
`;

export default LOCATION_VENDORS;
