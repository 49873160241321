import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

import ConnectionTable from '../../../../blocks/ConnectionTable';
import Permission from '../../../../blocks/Permission';
import { DataTable } from '../../../../blocks';
import { FETCH_JOBS } from '../../../../../apollo/queries';

import { EmployeesJobsTotalSyles } from './styles';

const EmployeeJobsTotal = ({
  prettySchedulesData,
  schedulesLoading,
  setEmployeeJobHourTotals,
  setAllJobs,
  permissions,
}) => {
  const classes = EmployeesJobsTotalSyles();

  // Get working hours for each job for each person
  const employeeJobHoursArray = useMemo(
    () =>
      prettySchedulesData?.map(scheduleEdge => ({
        id: scheduleEdge.node.person.id,
        firstName: scheduleEdge.node.person.firstName,
        lastName: scheduleEdge.node.person.lastName,
        jobId: scheduleEdge.node.jobId,
        hours: moment
          .duration(moment(scheduleEdge.node.finished).diff(moment(scheduleEdge.node.started)))
          .asHours(),
        delivery: scheduleEdge.node.delivery,
      })),
    [prettySchedulesData],
  );

  // Add all hours for each job for each person
  const employeeJobHourTotals = useMemo(() => {
    const empJobHourTotals = {};
    employeeJobHoursArray?.map(employee => {
      if (empJobHourTotals[employee.id]) {
        // If job exists add hours to existing hours. If not, set job hours
        if (empJobHourTotals[employee.id].jobs[employee.jobId]) {
          // An employee can have 1 shift with delivery ON and 1 shift with delivery OFF for the same job
          const sameDeliveryJob = empJobHourTotals[employee.id].jobs[employee.jobId].filter(
            job => job.delivery === employee.delivery,
          );
          if (sameDeliveryJob.length > 0) {
            sameDeliveryJob[0].hours += employee.hours;
          } else {
            empJobHourTotals[employee.id].jobs[employee.jobId].push({
              hours: employee.hours,
              delivery: employee.delivery,
            });
          }
        } else {
          empJobHourTotals[employee.id].jobs[employee.jobId] = [
            {
              hours: employee.hours,
              delivery: employee.delivery,
            },
          ];
        }
      } else {
        empJobHourTotals[employee.id] = { ...employee };

        // Remove id and job related props from employee level of the object
        delete empJobHourTotals[employee.id].jobId;
        delete empJobHourTotals[employee.id].hours;
        delete empJobHourTotals[employee.id].id;
        delete empJobHourTotals[employee.id].delivery;

        // Add job related props to job level of the object
        empJobHourTotals[employee.id].jobs = {};
        empJobHourTotals[employee.id].jobs[employee.jobId] = [
          {
            hours: employee.hours,
            delivery: employee.delivery,
          },
        ];
      }
    });

    Object.keys(empJobHourTotals)?.map(emp => {
      const keys = Object.keys(empJobHourTotals[emp]?.jobs);
      const flatValues = Object.values(empJobHourTotals[emp].jobs).flat();
      const employeeHasMoreThanOneJob = keys?.length > 1;
      const employeehasMoreThanOneDeliveryOnOneJob = keys?.length === 1 && flatValues?.length > 1;

      if (employeeHasMoreThanOneJob || employeehasMoreThanOneDeliveryOnOneJob) {
        empJobHourTotals[emp].totalHours = flatValues?.reduce((prev, curr) => {
          if (prev?.hours) {
            return prev.hours + curr.hours;
          }
          return prev + curr.hours;
        });
      } else {
        empJobHourTotals[emp].totalHours = flatValues[0]?.hours;
      }
    });

    setEmployeeJobHourTotals(empJobHourTotals);

    return empJobHourTotals;
  }, [employeeJobHoursArray]);

  const generateFinalEmployeeData = useCallback(() => {
    // Add together hours from same jobs with same delivery value. Keep same jobs with different delivery values
    // separate so they can be rendered on a separate row in the ConnectionTable.
    return Object.values(employeeJobHourTotals)?.map(employee => {
      const jobsArray = [];
      for (const [jobId, jobData] of Object.entries(employee.jobs)) {
        jobData?.map(currentJobData => {
          const sameJobSameDeliveryCondition = (job, jobId, currentJobData) =>
            Number(job[0]) === Number(jobId) && job[1].delivery === currentJobData.delivery;
          const jobWithSameDelivery = jobsArray.filter(job =>
            sameJobSameDeliveryCondition(job, jobId, currentJobData),
          );

          if (jobWithSameDelivery.length > 0) {
            // If a job with the same delivery value as currentJobData exists, add hours to it
            const foundIndex = jobsArray.findIndex(element =>
              sameJobSameDeliveryCondition(element, jobId, currentJobData),
            );
            jobsArray[foundIndex][1].hours += currentJobData.hours;
          } else {
            // Create new element if job is different or if job is same but delivery value is different
            jobsArray.push([jobId, currentJobData]);
          }
        });
      }
      return {
        name: `${employee.firstName} ${employee.lastName}`,
        totalHours: employee.totalHours,
        jobs: jobsArray,
      };
    });
  }, [employeeJobHourTotals]);

  return schedulesLoading || !employeeJobHourTotals ? (
    <CircularProgress />
  ) : (
    <div id="employeejobstotal">
      <Permission access={permissions?.scheduleweeklysalesprojectionId}>
        <ConnectionTable
          title="Employee Jobs Total Hours"
          query={FETCH_JOBS}
          initialQueryVariables={{ first: 1000 }}
          showSearch={false}
          hideTotalCount={true}
          structureTable={data => {
            setAllJobs(data);
            return generateFinalEmployeeData();
          }}
          columns={[
            { title: 'Employee', field: 'name' },
            { title: 'Total hours', field: 'totalHours' },
          ]}
          collapsableRows={(data, row) => {
            return (
              <div className={classes.dataTable}>
                <DataTable
                  data={row?.jobs}
                  structureTable={jobs => {
                    return jobs.map(job => {
                      const jobData = job[1];
                      return {
                        job: data?.viewer?.jobConnection?.edges?.filter(
                          edge => edge.node.jobId === Number(job[0]),
                        )[0]?.node?.label,
                        hours: jobData.hours,
                        delivery: jobData.delivery ? 'Yes' : 'No',
                      };
                    });
                  }}
                  columns={[
                    { title: 'Job', field: 'job' },
                    { title: 'Hours', field: 'hours' },
                    { title: 'Delivery', field: 'delivery' },
                  ]}
                />
              </div>
            );
          }}
        />
      </Permission>
    </div>
  );
};

export default EmployeeJobsTotal;
