import React, { useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

import ScheduleLaborView from './View';

const SPECIFIC_LOCATION_LABOR_PERCENTAGE = {
  'TG9jYXRpb246NDk6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=': 0.07, // Wycliff
  'TG9jYXRpb246MTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==': 0.1, // Central
  TG9jYXRpb246NzYyOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw: 0.1, // OKC
  'TG9jYXRpb246MzQ6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=': 0.07, // Corsicana
  TG9jYXRpb246MTgwOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw: 0.1, // Love
  'TG9jYXRpb246MTA2NTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==': 0.1, // DFW E
  'TG9jYXRpb246MTA2NDo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==': 0.07, // WingBoss
  'TG9jYXRpb246Mjk6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=': 0.12, // McKinney,
  TG9jYXRpb246ODYwOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw: 0.1, // DFW C
  'TG9jYXRpb246MzY6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=': 0.1, // Beltline
};

const ScheduleLabor = ({
  prettySchedulesData,
  schedulesLoading,
  selectedLocationState,
  weeklyScheduleProjections,
  smokestackData,
  sortedWeekdays,
  projectionInputs,
  weeklyScheduleProjectionsLoading,
  salaryPermission,
}) => {
  const weeklySaleProjectionsData = useMemo(() => {
    if (
      weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection?.edges[0]?.node
        ?.scheduleweeklysalesprojectionWeekday?.length > 0
    ) {
      return weeklyScheduleProjections.viewer.scheduleweeklysalesprojectionConnection.edges[0].node;
    } else if (
      smokestackData?.viewer?.locationConnection?.edges[0]?.node?.averageFourWeekSales?.length > 0
    ) {
      return smokestackData.viewer.locationConnection.edges[0].node.averageFourWeekSales;
    }
  }, [weeklyScheduleProjections, smokestackData]);

  const initialWeeklyScheduleWagesSorted = useMemo(() => {
    const result = {};
    sortedWeekdays.forEach(weekday => {
      result[weekday] = 0;
    });

    return result;
  }, [sortedWeekdays]);

  const weeklyScheduleWages = useMemo(() => {
    return prettySchedulesData?.reduce((accumulator, currentValue) => {
      const scheduleStarted = moment
        .tz(currentValue.node.started, 'UTC')
        .tz(selectedLocationState.timezone.label);

      const scheduleFinished = moment
        .tz(currentValue.node.finished, 'UTC')
        .tz(selectedLocationState.timezone.label);

      // If using scheduleStarted object to format it return utc weekday
      const dayOfWeek = scheduleStarted.format('dddd');

      const totalWorkHoursMs = scheduleFinished.valueOf() - scheduleStarted.valueOf();
      const totalWorkHours = Math.ceil(totalWorkHoursMs / (1000 * 60 * 60));
      const scheduledJob = currentValue?.node?.person?.personJobConnection.edges.filter(
        elem => elem?.node?.job.jobId === currentValue?.node.jobId,
      )?.[0]?.node;
      let salaryForShift = 0;

      if (scheduledJob?.salaryType?.salarytypeId === 1) {
        // Hourly
        salaryForShift = scheduledJob?.salaryAmount * totalWorkHours;
      } else {
        // Yearly
        salaryForShift = scheduledJob?.salaryAmount / 365 / 24;
      }

      if (accumulator[dayOfWeek]) {
        accumulator[dayOfWeek] += salaryForShift;
      } else {
        accumulator[dayOfWeek] = salaryForShift;
      }
      return accumulator;
    }, cloneDeep(initialWeeklyScheduleWagesSorted));
  }, [initialWeeklyScheduleWagesSorted, prettySchedulesData]);

  const targetLaborPercentage = useMemo(
    () =>
      SPECIFIC_LOCATION_LABOR_PERCENTAGE[
        smokestackData?.viewer?.locationConnection?.edges[0]?.node?.id
      ] || 0.14,
    [smokestackData],
  );

  const targetLaborData = useMemo(() => {
    if (projectionInputs.length) {
      return projectionInputs.map(projection => Number(projection) * 100 * targetLaborPercentage);
    }
  }, [projectionInputs]);

  const totalTargetLabor = useMemo(() => {
    return targetLaborData?.reduce((prev, curr) => prev + curr, 0);
  }, [targetLaborData]);

  const weeklySaleProjectionAmounts = useMemo(() => {
    if (weeklySaleProjectionsData?.scheduleweeklysalesprojectionWeekday) {
      return weeklySaleProjectionsData?.scheduleweeklysalesprojectionWeekday?.map(
        day => day.amount,
      );
    } else {
      return weeklySaleProjectionsData?.map(day => day.salesAmount * 100);
    }
  }, [weeklySaleProjectionsData]);

  const actualLaborData = useMemo(() => {
    const actualLaborDataArray = Array.from({ length: 7 }, () => ({
      amount: 0,
      percent: 0,
    }));

    if (weeklyScheduleWages && weeklySaleProjectionAmounts) {
      sortedWeekdays.map((day, index) => {
        const percent = weeklyScheduleWages[day] / weeklySaleProjectionAmounts[index];
        actualLaborDataArray[index] = {
          amount: weeklyScheduleWages[day],
          percent: isFinite(percent) ? percent : 0,
        };
      });
    }

    return actualLaborDataArray;
  }, [weeklyScheduleWages, weeklySaleProjectionAmounts]);

  const totalActualLabor = useMemo(() => {
    const total = actualLaborData?.reduce((prev, curr) => {
      if (prev?.amount) {
        prev.amount + curr.amount;
      }
      return prev + curr.amount;
    }, 0);
    const projectionTotal = weeklySaleProjectionAmounts?.reduce((prev, curr) => prev + curr, 0);

    const percent = total / projectionTotal;
    return { amount: total, percent: isFinite(percent) ? percent : 0 };
  }, [actualLaborData, weeklySaleProjectionAmounts]);

  const totalActualLaborPercent = useMemo(() => {
    const percent = Math.round(totalActualLabor.percent * 10000) / 100;
    return isFinite(percent) ? percent : 0;
  }, [totalActualLabor]);

  return schedulesLoading || weeklyScheduleProjectionsLoading ? (
    <CircularProgress />
  ) : (
    <ScheduleLaborView
      salaryPermission={salaryPermission}
      weeklyScheduleProjections={weeklyScheduleProjections}
      targetLaborData={targetLaborData}
      sortedWeekdays={sortedWeekdays}
      smokestackData={smokestackData}
      actualLaborData={actualLaborData}
      totalActualLaborPercent={totalActualLaborPercent}
      totalTargetLabor={totalTargetLabor}
      SPECIFIC_LOCATION_LABOR_PERCENTAGE={SPECIFIC_LOCATION_LABOR_PERCENTAGE}
      targetLaborPercentage={targetLaborPercentage}
      totalActualLabor={totalActualLabor}
    />
  );
};
export default ScheduleLabor;
