import { useCallback, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { isEmpty } from "lodash";
import { Grid, Button } from "@material-ui/core";

import { useStyles } from "../OrderSettings/styles";
import LocationHoursWeekday from "../../Location/GeneralInfo/LocationHours/locationHoursWeekday";
import LOCATIONS from "../../../../../apollo/queries/locations";
import UPDATE_LOCATION_HOURS from "../../../../../apollo/mutations/locations/updateLocationHours";
import { SNACKBAR_STATUS } from "../../../../../constants";
import { Permission, PermissionField } from '../../../../blocks';
import { useSnackbar } from "../../../../../hooks/useSnackbar";

const statuses = [
  {
    value: 0,
    label: "None",
  },
  {
    value: true,
    label: "Opened",
  },
  {
    value: false,
    label: "Closed",
  },
];

const LocationSettings = ({
  weekdays,
  locationIds,
  handleDiscard,
  bulkEditPermission,
  updateLocationHoursPermission,
}) => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();

  const [formDataArray, setFormDataArray] = useState([]);
  const [errors, setErrors] = useState({});

  const [fetchLocations, { loading: loadingNewLocations }] = useLazyQuery(
    LOCATIONS
  );

  const [updateLocationHours, { loading }] = useMutation(UPDATE_LOCATION_HOURS);

  const onSubmit = () => {
    const newErrors = {};
    formDataArray?.forEach((hour) => {
      if (
        ((hour.type === "active" && hour.active) || hour.type === "add") &&
        (!hour.opened || !hour.closed)
      ) {
        newErrors[`locationHours[${hour.weekdayId}]`] = true;
      }
    });

    setErrors(newErrors);
    const setToAddArray = formDataArray.map((hour) => ({
      ...hour,
      type: "add",
    }));
    if (isEmpty(newErrors)) {
      updateLocationHours({
        variables: {
          input: {
            locationIds,
            locationHours: setToAddArray,
          },
        },
      })
        .then(() => {
          fetchLocations({
            variables: {
              first: 15,
            },
          });

          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: "Hours updated",
          });
        })
        .catch((error) =>
        setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
          })
        );
    }
  };

  const createLocationHoursWeekdaysArray = useCallback(
    (type) => {
      const hoursArray = [];
      weekdays?.forEach((weekdayEdge) => {
        if (weekdayEdge?.node?.type === type) {
          if (weekdayEdge?.node?.locationHours.length > 0) {
            hoursArray.push(
              <LocationHoursWeekday
                key={weekdayEdge?.node?.id}
                permission={undefined}
                bulkEditPermission={bulkEditPermission}
                disabled={loading || loadingNewLocations}
                weekday={weekdayEdge.node}
                setFormDataArray={setFormDataArray}
                formDataArray={formDataArray}
                statuses={statuses}
                errors={errors}
                isBulkEdit
                defaultStatus={statuses.find(
                  (status) =>
                    status.value === weekdayEdge?.node?.locationHours[0].active
                )}
              />
            );
          } else {
            hoursArray.push(
              <LocationHoursWeekday
                key={weekdayEdge?.node?.id}
                permission={undefined}
                disabled={loading || loadingNewLocations}
                bulkEditPermission={bulkEditPermission}
                weekday={weekdayEdge?.node}
                setFormDataArray={setFormDataArray}
                formDataArray={formDataArray}
                statuses={statuses}
                isBulkEdit
                errors={errors}
                defaultStatus={statuses[0]}
              />
            );
          }
        }
      });

      return hoursArray;
    },
    [
      weekdays,
      formDataArray,
      setFormDataArray,
      errors,
      loading,
      bulkEditPermission,
      loadingNewLocations,
    ]
  );

  const weekdaysArray = useMemo(
    () => createLocationHoursWeekdaysArray("Weekday"),
    [createLocationHoursWeekdaysArray]
  );
  const holidaysArray = useMemo(
    () => createLocationHoursWeekdaysArray("Holiday"),
    [createLocationHoursWeekdaysArray]
  );

  return (
    <>
      <h2>Location Hours</h2>
      {weekdaysArray}
      <h2>Working Hours on Holidays</h2>
      {holidaysArray}
      <Grid
        container
        spacing={4}
        alignItems="center"
        className={classes.buttonGroup}
      >
        <Button onClick={handleDiscard} variant="outlined" disabled={loading}>
          Discard
        </Button>
        <Permission access={updateLocationHoursPermission?.access}>
          <div>
            <PermissionField createHelperText>
              <Button onClick={onSubmit} disabled={loading}>
                Save
              </Button>
            </PermissionField>
          </div>
        </Permission>
      </Grid>
    </>
  );
};

export default LocationSettings;
