import { keys, isEmpty } from "lodash";

export default function findLastRef(item) {
	// finds last ref nested in the object
	const itemKeys = keys(item);

	if (!isEmpty(itemKeys)) {
	  return item.ref || findLastRef(item[itemKeys[itemKeys.length - 1]]);
	}

	return null;
}