import { gql } from '@apollo/client';

const CREATE_STORAGE_AREA = gql`
  mutation CREATE_STORAGE_AREA($input: CreateStorageAreaInput!) {
    createStorageArea(input: $input) {
      viewer {
        id
      }
      newStorage {
        id
        created
        storageId
        status
        label
      }
    }
  }
`;

export default CREATE_STORAGE_AREA;
