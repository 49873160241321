import React, { useEffect, useState } from 'react';
import TimeSelection from '../DateAndTimeSettings/TimeSelection';
import LocationAdvancedTimeSettingsModal from './AdvancedTimeSelect';

function LocationSubcategoryView({
  control,
  row,
  handleOnSpecificTimeChange,
  handleSpecficTimeChange,
  handleTimeChange,
  setLocationSubcategories,
  locationSubcategories,
  handleOnSpecificLocationDaysChange,
  locationSubcategoriesAdvanced,
  toggleEnableAdvancedLocationDays,
  subcategory,
}) {
  const [showSimple, setShowSimple] = useState(
    locationSubcategories?.filter(location => location.locationId === row.locationId)[0]?.enabled ||
      !!row.startTime,
  );
  /**
   * If location subcategory has specific weekdays, but not yet changed use them as default.
   * if a location subcategory has specific weekdays and they are
   * changed use the changed as default.
   * If location subcategory dosen't have specific weekdays, but are changed use changed as default
   */
  const [showAdvanced, setShowAdvanced] = useState(
    !!row.locationSubcategoryWeekday?.locationSubcategoryWeekdayConnection?.edges.length &&
      locationSubcategoriesAdvanced.filter(location => location.locationId === row.locationId)
        .length
      ? locationSubcategoriesAdvanced.filter(location => location.locationId === row.locationId)[0]
          ?.enabled
      : !!row.locationSubcategoryWeekday?.locationSubcategoryWeekdayConnection?.edges.length ||
          locationSubcategoriesAdvanced.filter(
            location => location.locationId === row.locationId,
          )[0]?.enabled,
  );

  return (
    <div>
      {(showSimple || !showAdvanced) && (
        <TimeSelection
          setShowSimple={setShowSimple}
          showSimple={showSimple}
          control={control}
          subcategory={row.locationSubcategoryWeekday}
          nameAdditional="specific"
          showTimezone={false}
          showSpecificTime={true}
          showSpecificWeekday={false}
          locationId={row.locationId}
          handleSpecficTimeChange={handleOnSpecificTimeChange}
          handleTimeChange={handleTimeChange}
          setLocationSubcategories={setLocationSubcategories}
          locationSubcategories={locationSubcategories}
          row={row}
        />
      )}
      <br />
      {(showAdvanced || !showSimple) && (
        <LocationAdvancedTimeSettingsModal
          setShowAdvanced={setShowAdvanced}
          showAdvanced={showAdvanced}
          locationSubcategoriesAdvanced={locationSubcategoriesAdvanced}
          locationId={row.locationId}
          locationSubcategoryWeekday={row.locationSubcategoryWeekday}
          onDaysChange={handleOnSpecificLocationDaysChange}
          control={control}
          setLocationSubcategories={setLocationSubcategories}
          locationSubcategories={locationSubcategories}
          toggleEnableAdvancedLocationDays={toggleEnableAdvancedLocationDays}
          row={row}
        />
      )}
    </div>
  );
}

export default LocationSubcategoryView;
