import { gql } from "@apollo/client";

export const REMOVE_LOCATION_VENDOR = gql`
  mutation RemoveLocationVendor(
    $input: RemoveLocationVendorMutationInput!
  ) {
    removeLocationVendor(input: $input){
      viewer{
        id
      }
      removedLocationVendorId
    }
  }
`;
