import React, { useMemo, useState } from 'react';
import MainMeasure from './MainMeasure';
import Conversion from './Conversion';
import FormItems from '../../../../../../blocks/FormItems';

const initialConversion = {
  conversionMath: '',
  measureId: '',
  quantity: '',
};

const UnitOfMeasureFields = ({
  control,
  errors,
  register,
  getValues,
  setValue,
  unregister,
  measureOptions,
  ingredientConnection,
  duplicateMeasureIds,
  mainMeasureLabel,
  setMainMeasureLabel,
}) => {
  const conversions = useMemo(
    () =>
      (ingredientConnection?.edges[0]?.node.inventoryitem?.inventoryitemMeasureConnection?.edges
        ?.length &&
        ingredientConnection?.edges[0]?.node.inventoryitem?.inventoryitemMeasureConnection?.edges.map(
          edge => {
            return {
              conversionMath: edge.node.conversion,
              measureId: edge.node.measureId,
              isForCount: edge.node.isForCount,
            };
          },
        )) ||
      [],
    [ingredientConnection?.edges[0]?.node.ingredientMeasureConnection?.edges],
  );

  const mainMeasure = useMemo(
    () => (
      <MainMeasure
        measureOptions={measureOptions}
        errors={errors}
        setMainMeasure={setMainMeasureLabel}
        control={control}
        ingredient={ingredientConnection?.edges && ingredientConnection.edges[0]?.node}
      />
    ),
    [measureOptions, errors, control, ingredientConnection],
  );

  return (
    <FormItems
      title="Units Of Measure"
      addItemButtonText="Add UOM"
      name="unit-of-measure"
      initialItem={initialConversion}
      items={conversions}
      unregister={unregister}
      getValues={getValues}
      additionalHeaderComponent={mainMeasure}
      control={control}
      register={register}
      setValue={setValue}
      errors={errors}
      itemComponent={Conversion}
      dividerBetweenItems={true}
      dividerAfterItems={false}
      enableRemove
      enableEmpty={false}
      emptyErrorText="At least one unit of measure is required."
      itemComponentProps={{
        measureOptions,
        duplicateMeasureIds,
        mainMeasure: mainMeasureLabel,
      }}
    />
  );
};

export default UnitOfMeasureFields;
