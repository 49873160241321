import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Elements } from "../../../../../../blocks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: "20px 0",
  },
  switchControl: {
    margin: "0 20px",
  },
}));

function AvailableDays({
  coupon,
  control,
  connectionName = "couponWeekdayConnection",
  disabled = false,
  handleSwitchChange,
}) {
  const classes = useStyles();

  const getHasExtendedDays = (edges) => {
    for (const edge of edges) {
      if (edge.node.startTime) {
        return false;
      }
    }
    return true;
  };
  const [enableDaySelection, setEnableDaySelection] = useState(
    getHasExtendedDays(coupon[connectionName].edges) &&
      !!coupon[connectionName].edges.length
  );

  const [state, setState] = React.useState({
    sunday: !!coupon[connectionName].edges.find(
      (edge) => edge.node.weekdayId === 7 && !edge.node?.startTime
    ),
    monday: !!coupon[connectionName].edges.find(
      (edge) => edge.node.weekdayId === 1 && !edge.node?.startTime
    ),
    tuesday: !!coupon[connectionName].edges.find(
      (edge) => edge.node.weekdayId === 2 && !edge.node?.startTime
    ),
    wednesday: !!coupon[connectionName].edges.find(
      (edge) => edge.node.weekdayId === 3 && !edge.node?.startTime
    ),
    thursday: !!coupon[connectionName].edges.find(
      (edge) => edge.node.weekdayId === 4 && !edge.node?.startTime
    ),
    friday: !!coupon[connectionName].edges.find(
      (edge) => edge.node.weekdayId === 5 && !edge.node?.startTime
    ),
    saturday: !!coupon[connectionName].edges.find(
      (edge) => edge.node.weekdayId === 6 && !edge.node?.startTime
    ),
  });

  const handleEnableChange = (isChecked) => {
    if (isChecked) {
      setState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
    }
    setEnableDaySelection(!enableDaySelection);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup row>
          <Controller
            control={control}
            name="availableOnDays.enabled"
            defaultValue={enableDaySelection}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControlLabel
                style={{ marginLeft: "0", marginRight: "15px" }}
                labelPlacement="start"
                disabled={disabled}
                control={
                  <div style={{ marginLeft: "2rem" }}>
                    <Elements.Switch
                      ref={ref}
                      disabled={disabled}
                      checked={enableDaySelection}
                      handleSwitchChange={(isChecked) => {
                        handleEnableChange(isChecked);
                        onChange(isChecked);
                        handleSwitchChange(isChecked);
                      }}
                      color="primary"
                    />
                  </div>
                }
                label="Available on specific day of week"
              />
            )}
          />
          <div>
            {Object.keys(state).map((day, i) => (
              <FormControlLabel
                key={day}
                labelPlacement="end"
                disabled={!enableDaySelection}
                control={
                  <Controller
                    control={control}
                    name={`availableOnDays.days[${day}]`}
                    defaultValue={state[`${day}`]}
                    render={(
                      { onChange, onBlur, value, name, ref },
                      { invalid, isTouched, isDirty }
                    ) => (
                      <Checkbox
                        inputRef={ref}
                        disabled={!enableDaySelection}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        value={day}
                        defaultChecked={value}
                      />
                    )}
                  />
                }
                label={day[0].toUpperCase() + day.substring(1)}
              />
            ))}
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default AvailableDays;
