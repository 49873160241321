import React, { useState } from 'react';
import FETCH_MULTIPLE from '../../../../../../apollo/queries/menus/fetchMultiple';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { Container } from '@material-ui/core';
import MultipleModal from '../Item/MultipleModal';
import { Modal } from '../../../../../blocks';
import { MUTATION_NAME } from '../../../../../../constants';

const Multiples = ({
  locationId,
  locationIds,
  mutationPermissions,
  menuId,
  isBulkEdit,
  chowlyAPIkey,
  bulkEditPermission,
  setOpenWarningMutationName,
  accessToMutations,
  isPriceCapApplicable,
  tierId,
}) => {
  const updateLocationMultipleChoicePermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'updateLocationMultipleChoice',
  );
  const [choices, setChoices] = useState(false);
  const [permissions, setPermissions] = useState();
  const [variables, setVariables] = useState(false);
  const [multipleId, setMultipleId] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = (choices, multipleId, variables, permissions) => {
    setChoices(choices);
    setVariables(variables);
    setMultipleId(multipleId);
    setShowModal(true);
    setPermissions(permissions);
    setOpenWarningMutationName(MUTATION_NAME.updateLocationItemMultipleChoicePriceMutationNDInput);
  };
  let menu = {};
  if (!locationIds) {
    menu = {
      menuId: {
        eq: menuId,
      },
    };
  }

  const getMultipleChoicePrice = item => {
    let price = item.price;

    if (item?.multipleChoiceTierConnection?.edges?.length) {
      const tier = item?.multipleChoiceTierConnection?.edges.find(
        itemTier => itemTier.node.tier.tierId === tierId,
      );
      if (!tier || !tier.node?.price) {
        return price;
      }
      price = tier.node.price;
    }
    return price;
  };

  const getMultipleChoicePriceCap = item => {
    let priceCap = item.priceCap;

    if (item?.multipleChoiceTierConnection?.edges?.length) {
      const tier = item?.multipleChoiceTierConnection?.edges.find(
        itemTier => itemTier.node.tier.tierId === tierId,
      );
      if (!tier || typeof tier.node?.priceCap !== 'number') {
        return priceCap;
      }

      priceCap = tier.node.priceCap;
    }
    return priceCap;
  };

  return (
    <Container style={{ marginBottom: '1rem' }} id={'multiples'}>
      <ConnectionTable
        query={FETCH_MULTIPLE}
        title="Multiples"
        onTableRowClick={(data, _, variables) =>
          handleOpenModal(data?.locationChoices, data?.id, variables, data?.permissions)
        }
        hideTotalCount={true}
        initialQueryVariables={{
          first: 15,
          fieldGroup: 19,
          locationMultipleChoice: {
            locationId: {
              eq: parseInt(locationId),
            },
          },
          filter: {
            ...menu,
          },
          tierId,
        }}
        structureTable={data => {
          return data?.viewer?.multipleConnection?.edges.map(multiple => {
            return {
              id: multiple?.node?.id,
              multipleId: multiple?.node?.multipleId,
              label: multiple?.node?.label,
              'choice.options': multiple?.node?.multipleChoiceConnection?.edges.map(
                edge => `${edge?.node?.choice?.label}, `,
              ),
              locationChoices: multiple?.node?.multipleChoiceConnection?.edges.map(edge => {
                return {
                  id: edge?.node?.choice?.id,
                  title: edge?.node?.choice?.label,
                  suggestedPrice: getMultipleChoicePrice(edge?.node),
                  priceCap: getMultipleChoicePriceCap(edge?.node),
                  onlinePrice: multiple?.node?.locationMultipleChoiceConnection?.edges.filter(
                    choiceEdge => choiceEdge?.node?.choice?.id === edge?.node?.choice?.id,
                  )[0]?.node?.price,
                  posPrice: multiple?.node?.locationMultipleChoiceConnection?.edges.filter(
                    choiceEdge => choiceEdge?.node?.choice?.id === edge?.node?.choice?.id,
                  )[0]?.node?.posPrice,
                  thirdPartyPrice: multiple?.node?.locationMultipleChoiceConnection?.edges.filter(
                    choiceEdge => choiceEdge?.node?.choice?.id === edge?.node?.choice?.id,
                  )[0]?.node?.thirdPartyPrice,
                  activeTierProps: multiple?.node?.multipleChoiceConnection?.edges
                    ?.filter(
                      choiceEdge => choiceEdge?.node?.choice?.id === edge?.node?.choice?.id,
                    )[0]
                    ?.node?.multipleChoiceTierConnection?.edges?.find(
                      tier => tier?.node?.tier?.tierId === tierId,
                    ),
                  platforms: data?.viewer?.platformConnection?.edges?.map(platform => {
                    if (platform?.node?.id === 'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                      return {
                        node: {
                          ...platform?.node,
                          label: '3PV',
                        },
                      };
                    }
                    return platform;
                  }),
                };
              }),
              disableTableRowClick: !updateLocationMultipleChoicePermission?.access,
              permissions: {
                suggestedPrice:
                  data?.viewer?.multipleConnection?.permissions?.multipleChoiceConnection?.edges
                    ?.node?.price,
                priceCap:
                  data?.viewer?.multipleConnection?.permissions?.multipleChoiceConnection?.edges
                    ?.node?.priceCap,
                onlinePrice:
                  data?.viewer?.multipleConnection?.permissions?.locationMultipleChoiceConnection
                    ?.edges?.node?.price,
                posPrice:
                  data?.viewer?.multipleConnection?.permissions?.locationMultipleChoiceConnection
                    ?.edges?.node?.posPrice,
                thirdPartyPrice:
                  data?.viewer?.multipleConnection?.permissions?.locationMultipleChoiceConnection
                    ?.edges?.node?.thirdPartyPrice,
              },
            };
          });
        }}
        columns={[
          { title: 'ID', field: 'multipleId' },
          { title: 'Name', field: 'label' },
          {
            title: 'Options',
            field: 'choice.options',
            customPermissionField: 'multipleChoiceConnection.edges.node.choice.label',
          },
        ]}
      />
      <Modal open={showModal} handleClose={handleCloseModal} title="Edit">
        <MultipleModal
          choices={choices}
          chowlyAPIkey={chowlyAPIkey}
          handleClose={handleCloseModal}
          multipleId={multipleId}
          locationMultiple={true}
          isBulkEdit={isBulkEdit}
          locationIds={locationIds}
          locationId={locationId}
          variables={variables}
          permissions={
            isBulkEdit
              ? {
                  suggestedPrice: bulkEditPermission.access,
                  priceCap: bulkEditPermission.access,
                  onlinePrice: bulkEditPermission.access,
                  posPrice: bulkEditPermission.access,
                  thirdPartyPrice: bulkEditPermission.access,
                }
              : permissions
          }
          accessToMutations={accessToMutations}
          isPriceCapApplicable={isPriceCapApplicable}
        />
      </Modal>
    </Container>
  );
};

export default Multiples;
