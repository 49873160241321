import { FormControlLabel, FormHelperText, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { forwardRef, useCallback, useState } from 'react';

import { Elements, PermissionField } from '../../../../../blocks';
import moment from 'moment';
import { ModifyScheduleStyles } from './styles';
import Permission from '../../../../../blocks/Permission';
import SelectMUI from '../../../../../blocks/Select';
import { convert } from '../../../../../../utils';

const ModifyScheduleView = forwardRef(
  (
    {
      keyValue,
      control,
      employee,
      defaultValues,
      fromValues,
      removeTimeshiftForm,
      onDateChange,
      onHoursChange,
      onMinutesChange,
      onSelectedJobChange,
      totalHoursForSchedule,
      schedulePermissions,
      errors,
      removeTimeshiftFormLoading,
      disabled,
      totalTimeshiftsNumber,
      selectedWeek,
    },
    ref,
  ) => {
    const classes = ModifyScheduleStyles();
    const formattedCurrency = convert.formattedCurrency;

    const [jobSalary, setJobSalary] = useState(null);

    const getSelectedJobId = useCallback(() => {
      let selectedJobId = employee?.personJobConnection?.edges.filter(job => {
        if (job?.node?.job?.jobId === defaultValues.selectedJob) {
          return job.node.job.jobId;
        }
      })?.[0]?.node?.job?.id;

      if (selectedJobId === undefined) {
        return employee?.personJobConnection?.edges[0]?.node?.job?.id;
      } else {
        return selectedJobId;
      }
    }, [employee]);

    const getJobSalary = useCallback(
      event => {
        const personJob = employee?.personJobConnection?.edges.filter(job =>
          event ? job.node.job.id === event.target.value : job.node.job.id === getSelectedJobId(),
        );
        setJobSalary(formattedCurrency(personJob[0]?.node?.salaryAmount));
      },
      [employee, getSelectedJobId],
    );

    const jobs = employee?.personJobConnection?.edges.map(personJob => {
      return {
        value: personJob?.node?.job?.id,
        label: personJob?.node?.job?.label,
      };
    });

    if (jobSalary === null) {
      getJobSalary();
    }

    return (
      <React.Fragment key={keyValue}>
        <Permission access={schedulePermissions.started}>
          <Grid container xs={12} spacing={3} alignItems={'center'}>
            <Grid item xs={6}>
              <PermissionField
                Component={Elements.DatePicker}
                name={`employeeSchedule.${keyValue}.dateIn`}
                disabled={disabled}
                label="Date In"
                fullWidth
                control={control}
                defaultValue={defaultValues.dateIn}
                format={'yyyy-MM-dd'}
                onDateChange={event => onDateChange(event, keyValue, 'dateIn')}
                shouldDisableDate={date =>
                  moment(date).startOf('day') < moment(selectedWeek.toISODate()).startOf('day') ||
                  moment(date).startOf('day') >
                    moment(selectedWeek.plus({ days: 6 }).toISODate()).startOf('day')
                }
              />
            </Grid>
            <Grid xs={6} item>
              <Elements.TimePicker
                control={control}
                disabled={disabled}
                hoursName={`employeeSchedule.${keyValue}.timeInHours`}
                minutesName={`employeeSchedule.${keyValue}.timeInMinutes`}
                amPmName={`employeeSchedule.${keyValue}.timeInMeridiem`}
                defaultValues={{
                  hours: defaultValues.timeInHours,
                  minutes: defaultValues.timeInMinutes === '00' ? '0' : defaultValues.timeInMinutes,
                  meridiem: defaultValues.timeInMeridiem,
                }}
                onHoursChange={hoursEvent => {
                  onHoursChange(hoursEvent, keyValue, 'timeInHours');
                }}
                onMinutesChange={minutesEvent => {
                  onMinutesChange(minutesEvent, keyValue, 'timeInMinutes');
                }}
                onMeridiemChange={meridiemEvent => {
                  onHoursChange(meridiemEvent, keyValue, 'timeInMeridiem');
                }}
                stateValues={{
                  hours: fromValues.timeInHours,
                  minutes: fromValues.timeInMinutes === '00' ? '0' : fromValues.timeInMinutes,
                  meridiem: fromValues.timeInMeridiem,
                }}
              />
            </Grid>
          </Grid>
        </Permission>
        <Grid container style={{ margin: '1rem 0' }}>
          <span>To</span>
        </Grid>
        <Permission access={schedulePermissions.finished}>
          <Grid container xs={12} spacing={3} alignItems="center">
            <Grid xs={6} item>
              <PermissionField
                Component={Elements.DatePicker}
                fullWidth
                name={`employeeSchedule.${keyValue}.dateOut`}
                label="Date Out"
                control={control}
                disabled={disabled}
                defaultValue={defaultValues.dateOut}
                format={'yyyy-MM-dd'}
                onDateChange={event => onDateChange(event, keyValue, 'dateOut')}
                value={fromValues.dateIn}
                shouldDisableDate={date =>
                  moment(date) < moment(selectedWeek.toISODate()).startOf('day') ||
                  moment(date) > moment(selectedWeek.plus({ days: 6 }).toISODate()).startOf('day')
                }
                rules={{
                  validate: () => {
                    const timeshiftData = fromValues;
                    let dateInStarting = moment(timeshiftData.dateIn);
                    let dateOutStarting = moment(timeshiftData.dateOut);

                    dateInStarting.set({
                      hours: timeshiftData.timeInHours,
                      minute: timeshiftData.timeInMinutes,
                    });
                    dateInStarting = moment(
                      `${dateInStarting.format('MM/DD/YYYY hh:mm:ss')} ${
                        timeshiftData.timeInMeridiem
                      }`,
                    );
                    dateOutStarting.set({
                      hours: timeshiftData.timeOutHours,
                      minute: timeshiftData.timeOutMinutes,
                    });
                    dateOutStarting = moment(
                      `${dateOutStarting.format('MM/DD/YYYY hh:mm:ss')} ${
                        timeshiftData.timeOutMeridiem
                      }`,
                    );
                    return dateInStarting.valueOf() < dateOutStarting.valueOf();
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Elements.TimePicker
                control={control}
                access={schedulePermissions.finished}
                hoursName={`employeeSchedule.${keyValue}.timeOutHours`}
                minutesName={`employeeSchedule.${keyValue}.timeOutMinutes`}
                amPmName={`employeeSchedule.${keyValue}.timeOutMeridiem`}
                defaultValues={{
                  hours: defaultValues.timeOutHours,
                  minutes: defaultValues.timeInMinutes === '00' ? '0' : defaultValues.timeInMinutes,
                  meridiem: defaultValues.timeOutMeridiem,
                }}
                onHoursChange={hoursEvent => {
                  onHoursChange(hoursEvent, keyValue, 'timeOutHours');
                }}
                onMinutesChange={minutesEvent => {
                  onMinutesChange(minutesEvent, keyValue, 'timeOutMinutes');
                }}
                onMeridiemChange={meridiemEvent => {
                  onHoursChange(meridiemEvent, keyValue, 'timeOutMeridiem');
                }}
                stateValues={{
                  hours: fromValues.timeOutHours,
                  minutes: fromValues.timeOutMinutes === '00' ? '0' : fromValues.timeOutMinutes,
                  meridiem: fromValues.timeOutMeridiem,
                }}
              />
            </Grid>
          </Grid>
        </Permission>
        <Grid container xs={12} className={classes.bottomGrid}>
          <Grid item xs={1} direction="column" className={classes.centered}>
            <label>TOTAL HRS </label>
            <span style={{ fontWeight: 'bold' }}>{totalHoursForSchedule(keyValue)}</span>
          </Grid>
          <Permission
            access={Math.min(
              schedulePermissions.person?.personJobConnection?.edges?.node?.job?.__typename,
              schedulePermissions.person?.personJobConnection?.edges?.node?.job?.label,
              schedulePermissions.person?.personJobConnection?.edges?.node?.job?.jobId,
            )}
          >
            <Grid item xs={4}>
              <PermissionField
                Component={SelectMUI}
                items={jobs}
                fullWidth
                id={`employeeSchedule.${keyValue}.selectedJob`}
                helperText={!jobs.length && 'You first need to add a job to employee.'}
                label={'Select job'}
                disabled={disabled}
                defaultValue={getSelectedJobId()}
                variant="outlined"
                onValueChange={event => {
                  onSelectedJobChange(event, keyValue, 'selectedJob');
                  getJobSalary(event);
                }}
                control={control}
                width={'10rem'}
              />
            </Grid>
          </Permission>
          <Permission access={schedulePermissions.delivery}>
            <Grid item xs={2} className={classes.switch}>
              <div>
                <PermissionField
                  Component={FormControlLabel}
                  label="Delivery"
                  disabled={disabled}
                  createHelperText
                  labelPlacement="start"
                  control={
                    <Elements.Switch
                      name={`employeeSchedule.${keyValue}.isDelivery`}
                      checked={defaultValues.isDelivery}
                      ref={ref}
                    />
                  }
                />
              </div>
            </Grid>
          </Permission>
          <Grid item xs={3}>
            {totalTimeshiftsNumber > 1 && (
              <Button
                variant="outlined"
                size="small"
                disabled={removeTimeshiftFormLoading}
                onClick={() => removeTimeshiftForm(keyValue)}
              >
                Remove Timeshift
              </Button>
            )}
            <Permission
              access={schedulePermissions.person.personJobConnection.edges.node.salaryAmount}
            >
              <Grid item xs={1} direction="column" className={classes.salary}>
                <label>Salary:</label>
                <span style={{ fontWeight: 'bold' }}>{jobSalary}</span>
              </Grid>
            </Permission>
          </Grid>
        </Grid>
        {errors?.employeeSchedule?.[keyValue]?.dateOut && (
          <FormHelperText error>Scheduled clock out date for employee in invalid.</FormHelperText>
        )}
      </React.Fragment>
    );
  },
);

export default ModifyScheduleView;
