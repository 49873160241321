import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import { Controller } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  Permission,
  Elements,
  TimePicker,
  PermissionField,
} from '../../../../../../components/blocks';

import { COLOR } from '../../../../../../constants';

const useStyles = makeStyles(() => ({
  hide: {
    display: 'none',
  },

  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  iconButtonText: {
    fontWeight: 800,
    marginLeft: '5px',
  },
}));

const getTimeDefaultValue = time => {
  if (!time) {
    return {
      hours: 'none',
      minutes: 'none',
      amPm: 'none',
    };
  }

  const formattedTime = moment(time, 'hh:mm').format('h-mm-A');

  return {
    hours: formattedTime.split('-')[0] || '',
    minutes: formattedTime.split('-')[1] || '',
    amPm: formattedTime.split('-')[2] || '',
  };
};

const LocationHoursWeekday = ({
  weekday,
  setFormDataArray,
  formDataArray,
  permissions,
  statuses,
  defaultStatus,
  errors,
  disabled: disabledStatusChange,
  bulkEditPermission,
  isBulkEdit,
}) => {
  const [locationHours, setLocationHours] = useState([]);
  const [index, setIndex] = useState(0);
  const [disabled, setDisabled] = useState(defaultStatus ? true : false);
  const classes = useStyles();

  useEffect(() => {
    weekday?.locationHours?.forEach((hours, i) => {
      if (i === 0) {
        setDisabled(!hours?.active);
      }

      setLocationHours(prevState => [...prevState, hours]);
    });
  }, []);

  const { control, getValues } = useForm({
    defaultValues: {
      measureId: '',
      label: '',
      description: '',
      cost: 0,
      nutrients: [],
      allergens: [],
      locationAvailability: [],
    },
  });

  const hourstextField = (hours, i) => (
    <Grid
      item
      container
      key={`${hours.id}-locationHours`}
      spacing={4}
      justify="space-around"
      alignItems="center"
    >
      <Permission
        access={
          isBulkEdit
            ? bulkEditPermission.access
            : permissions?.locationWeekdayConnection?.edges?.node?.locationHours?.__typename
        }
      >
        <div>
          <PermissionField
            Component={Grid}
            createHelperText
            item
            className={locationHours.length <= 1 && classes.hide}
          >
            <PermissionField
              Component={IconButton}
              disableRipple
              className={classes.iconButton}
              variant="contained"
              disabled={disabled}
              onClick={() => {
                setLocationHours(prevState => {
                  return prevState.filter(value => value?.id !== `${hours.id}`);
                });
                const isValue = formDataArray.some(value => value.id === hours.id);
                if (isValue) {
                  setFormDataArray(prevState => {
                    return prevState.map(hour => {
                      if (hour?.id === `${hours.id}`) {
                        // eslint-disable-next-line no-param-reassign
                        hour.type = hour.type === 'add' ? 'delete-added' : 'delete';
                      }
                      return hour;
                    });
                  });
                } else {
                  setFormDataArray(prevState => {
                    return [
                      ...prevState,
                      {
                        opened: hours.opened,
                        closed: hours.closed,
                        id: hours.id,
                        weekdayId: hours.weekdayId,
                        type: 'delete',
                      },
                    ];
                  });
                }
              }}
            >
              <RemoveCircleOutlineIcon color="error" />
              <Typography variant="body2" className={classes.iconButtonText}>
                Delete Shift
              </Typography>
            </PermissionField>
          </PermissionField>
        </div>
      </Permission>
      <Permission
        access={
          isBulkEdit
            ? bulkEditPermission?.access
            : permissions?.locationWeekdayConnection?.edges?.node?.locationHours?.opened
        }
      >
        <Grid item>
          <Typography>from</Typography>
        </Grid>
        <Grid item>
          <PermissionField
            Component={TimePicker}
            id="time"
            key={`locationHours[${hours.weekdayId}].opened-${i}`}
            defaultValues={getTimeDefaultValue(hours?.opened)}
            hoursName={'hoursOpened'}
            control={control}
            disabled={disabled}
            hasEmptyValue
            fullWidth={true}
            amPmName={'amPmOpened'}
            minutesName={'minutesOpened'}
            format="H:mm:ss"
            onChange={time => {
              const isValue = formDataArray.some(value => value.id === hours.id);
              const locationHour = locationHours.find(value => value.id === hours.id);
              if (!isValue && locationHour) {
                const newTime = { ...locationHour };
                delete newTime.__typename;
                setFormDataArray(() => [
                  ...formDataArray,
                  {
                    ...newTime,
                    opened: time,
                    type: 'update',
                  },
                ]);
                return;
              }
              if (isValue) {
                setFormDataArray(prevState => {
                  return prevState.map(hour => {
                    if (hour?.id === `${hours.id}`) {
                      // eslint-disable-next-line no-param-reassign
                      hour.opened = time;
                      // eslint-disable-next-line no-param-reassign
                      hour.type = hour.type === 'add' ? 'add' : 'update';
                    }
                    return hour;
                  });
                });
              } else {
                setFormDataArray(prevState => {
                  return [
                    ...prevState,
                    {
                      opened: time,
                      closed: hours.closed,
                      id: hours.id,
                      weekdayId: hours.weekdayId,
                      type: 'update',
                    },
                  ];
                });
              }
            }}
          />
        </Grid>
      </Permission>

      <Permission
        access={
          isBulkEdit
            ? bulkEditPermission?.access
            : permissions?.locationWeekdayConnection?.edges?.node?.locationHours?.closed
        }
      >
        <Grid item>
          <Typography>to</Typography>
        </Grid>
        <Grid item>
          <PermissionField
            Component={TimePicker}
            id="time"
            key={`locationHours[${hours.weekdayId}].closed-${i}`}
            defaultValues={getTimeDefaultValue(hours?.closed)}
            hoursName={'hoursClosed'}
            control={control}
            disabled={disabled}
            hasEmptyValue
            fullWidth={true}
            amPmName={'amPmClosed'}
            minutesName={'minutesClosed'}
            format="H:mm:ss"
            onChange={time => {
              const isValue = formDataArray.some(value => value.id === hours.id);
              const locationHour = locationHours.find(value => value.id === hours.id);

              if (!isValue && locationHour) {
                const newTime = { ...locationHour };
                delete newTime.__typename;
                setFormDataArray(() => [
                  ...formDataArray,
                  {
                    ...newTime,
                    closed: time,
                    type: 'update',
                  },
                ]);
                return;
              }

              if (isValue) {
                setFormDataArray(prevState => {
                  return prevState.map(hour => {
                    if (hour?.id === `${hours.id}`) {
                      // eslint-disable-next-line no-param-reassign
                      hour.closed = time;
                      // eslint-disable-next-line no-param-reassign
                      hour.type = hour.type === 'add' ? 'add' : 'update';
                    }
                    return hour;
                  });
                });
              } else {
                setFormDataArray(prevState => [
                  ...prevState,
                  {
                    closed: time,
                    opened: hours.opened,
                    id: hours.id,
                    active: hours.active,
                    weekdayId: hours.weekdayId,
                    type: 'update',
                  },
                ]);
              }
            }}
          />
        </Grid>
      </Permission>
    </Grid>
  );

  const textFields = locationHours.map((hours, i) => {
    return hourstextField(hours, i, weekday);
  });

  return (
    <>
      <Grid container item xs={12} spacing={4} alignItems="flex-start" justify="space-evenly">
        <Grid item container xs={3} justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">{weekday?.label}</Typography>
          </Grid>
          <Permission
            access={
              isBulkEdit
                ? bulkEditPermission?.access
                : permissions?.locationWeekdayConnection?.edges?.node?.active
            }
          >
            <Grid item>
              <div>
                <PermissionField
                  Component={FormControlLabel}
                  createHelperText
                  control={
                    statuses ? (
                      <Controller
                        name="activeStatus"
                        control={control}
                        defaultValue={defaultStatus?.value}
                        render={({ onChange, ref }) => (
                          <Permission
                            access={
                              isBulkEdit
                                ? bulkEditPermission.access
                                : permissions?.locationWeekdayConnection?.edges?.node?.active
                            }
                          >
                            <PermissionField
                              Component={Elements.ToggleButton}
                              ref={ref}
                              disabled={disabledStatusChange}
                              options={statuses}
                              value={defaultStatus?.value}
                              handleToggleClick={value => {
                                const isValue = formDataArray.some(
                                  value => value.weekdayId === weekday?.weekdayId,
                                );

                                if (value === defaultStatus?.value) {
                                  setDisabled(true);
                                  if (isValue) {
                                    setFormDataArray(prevState =>
                                      prevState.filter(
                                        hour => hour?.weekdayId !== weekday?.weekdayId,
                                      ),
                                    );
                                  }
                                } else if (isValue) {
                                  setDisabled(!value);
                                  setFormDataArray(prevState => {
                                    prevState.map(hour => {
                                      if (hour?.weekdayId === weekday?.weekdayId) {
                                        // eslint-disable-next-line no-param-reassign
                                        hour.active = value;
                                        // eslint-disable-next-line no-param-reassign
                                        hour.type = 'active';
                                      }
                                      return hour;
                                    });
                                    return prevState;
                                  });
                                } else {
                                  setDisabled(!value);
                                  setFormDataArray(prevState => [
                                    ...prevState,
                                    {
                                      id: weekday?.id,
                                      weekdayId: weekday?.weekdayId,
                                      active: value,
                                      type: 'active',
                                    },
                                  ]);
                                }
                              }}
                            />
                          </Permission>
                        )}
                      />
                    ) : (
                      <Checkbox
                        name="closed"
                        defaultChecked={!weekday?.locationHours[0]?.active}
                        onChange={(event, isChecked) => {
                          setDisabled(isChecked);

                          const isValue = formDataArray.some(
                            value => value.weekdayId === weekday?.weekdayId,
                          );

                          // todo check if it works
                          if (isValue) {
                            setFormDataArray(prevState => {
                              prevState.map(hour => {
                                if (hour?.weekdayId === weekday?.weekdayId) {
                                  // eslint-disable-next-line no-param-reassign
                                  hour.active = !isChecked;
                                  // eslint-disable-next-line no-param-reassign
                                  hour.type = 'active';
                                }
                                return hour;
                              });
                              return prevState;
                            });
                          } else {
                            const {
                              amPmClosed,
                              amPmOpened,
                              hoursClosed,
                              hoursOpened,
                              minutesClosed,
                              minutesOpened,
                            } = getValues();

                            setFormDataArray(prevState => [
                              ...prevState,
                              {
                                closed: [hoursClosed, minutesClosed, amPmClosed].includes('none')
                                  ? null
                                  : moment(
                                      `${hoursClosed}-${minutesClosed}-${amPmClosed}`,
                                      'hh-mm-A',
                                    ).format('H:mm:ss'),
                                opened: [hoursOpened, minutesOpened, amPmOpened].includes('none')
                                  ? null
                                  : moment(
                                      `${hoursOpened}-${minutesOpened}-${amPmOpened}`,
                                      'hh-mm-A',
                                    ).format('H:mm:ss'),
                                id: weekday?.id,
                                weekdayId: weekday?.weekdayId,
                                active: !isChecked,
                                type: 'active',
                              },
                            ]);
                          }
                        }}
                      />
                    )
                  }
                  label={statuses ? '' : 'Closed'}
                />
              </div>
            </Grid>
          </Permission>
        </Grid>
        <Grid item container xs={7} spacing={4} direction="column">
          {textFields}
        </Grid>
        <Permission
          access={
            isBulkEdit
              ? bulkEditPermission?.access
              : permissions?.locationWeekdayConnection?.edges?.node?.locationHours?.__typename
          }
        >
          <Grid item xs={2}>
            <div>
              <PermissionField createHelperText>
                <PermissionField
                  Component={IconButton}
                  disableRipple
                  className={classes.iconButton}
                  variant="contained"
                  disabled={disabled}
                  onClick={() => {
                    setLocationHours(prevState => [
                      ...prevState,
                      {
                        closed: null,
                        weekdayId: `${weekday?.weekdayId}`,
                        opened: null,
                        active: true,
                        id: `${index}-addedLocationHours`,
                      },
                    ]);
                    setFormDataArray(prevState => [
                      ...prevState,
                      {
                        closed: null,
                        opened: null,
                        weekdayId: `${weekday?.weekdayId}`,
                        id: `${index}-addedLocationHours`,
                        type: 'add',
                      },
                    ]);
                    setIndex(index + 1);
                  }}
                >
                  <Add style={{ color: COLOR.SUCCESS }} />
                  <Typography variant="body2" className={classes.iconButtonText}>
                    Add Shift
                  </Typography>
                </PermissionField>
              </PermissionField>
            </div>
          </Grid>
        </Permission>
      </Grid>
      {errors && errors[`locationHours[${weekday?.weekdayId}]`] && (
        <FormHelperText error>Opened and closed times are required.</FormHelperText>
      )}
      {errors && errors[`locationHoursInvalidConfiguration[${weekday?.weekdayId}]`] && (
        <FormHelperText error>Opened times can't be before closed times.</FormHelperText>
      )}
      <Divider />
    </>
  );
};

export default LocationHoursWeekday;
