const phoneNumberFormat = phoneNumber => {
  const cleanedNumber = ('' + phoneNumber).replace(/\D/g, '');
  const matchFormat = cleanedNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (matchFormat) {
    return ['(', matchFormat[2], ') ', matchFormat[3], '-', matchFormat[4]].join('');
  }
  return null;
};

export default phoneNumberFormat;
