import {
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { INVOICE_ICON, paths } from '../../../../../../constants';
import { INVENTORY_TRANSFERS } from '../../../../../../apollo/queries/inventory';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { renderIcon, getButtons, BUTTON_NAME } from '../../renderers';
import moment from 'moment';
import MobileButton from '../../../../../blocks/Mobile/MobileButton';
import { Modal } from '../../../../../blocks';
import { generateStoreAbbreviation } from '../../../../../../utils';

const InvoiceJournal = () => {
  const { push } = useHistory();
  let { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];

  const buttons = getButtons([
    { buttonName: BUTTON_NAME.TAKS_LIST },
    { buttonName: BUTTON_NAME.INVENTORY_TRANSFER_CREATE },
    { buttonName: BUTTON_NAME.LOCATIONS },
  ]);

  const [openModal, setOpenModal] = useState(false);

  const handleTableRowClick = useCallback(row => {
    push(
      `/spark/core-ops/inventory/locations/${ids}/inventory-transfer/${row.inventorytransferId}/details`,
    );
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleTransferTypeChange = useCallback(({ value, refetch, variables }) => {
    refetch({
      ...variables,
      filter: {
        [value]: { eq: +locationId },
      },
    });
  }, []);

  return (
    <>
      <ConnectionTable
        title="Inventory Transfers"
        connectionName="inventorytransferConnection"
        headerHidden={'xs'}
        onTableRowClick={handleTableRowClick}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Transfer Type</InputLabel>
                <Select
                  label="Type"
                  multiple={false}
                  defaultValue={'targetLocationId'}
                  onChange={({ target: { value } }) =>
                    handleTransferTypeChange({ value, refetch, variables })
                  }
                >
                  {[
                    { label: 'Incoming', value: 'targetLocationId' },
                    { label: 'Outgoing', value: 'sourceLocationId' },
                  ]?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        customActionsTop={() => (
          <>
            <Hidden only="xs">{buttons}</Hidden>
            <Hidden smUp>
              <MobileButton style={{ cursor: 'pointer' }} onClick={handleOpen} />
            </Hidden>
          </>
        )}
        columns={[
          {
            title: 'From',
            field: 'sourceLocation',
            enablePropagation: true,
            render: data => {
              return `${data.label}  (${generateStoreAbbreviation(data).abbreviation})`;
            },
          },
          {
            title: 'To',
            field: 'targetLocation',
            enablePropagation: true,
            render: data => {
              return `${data.label}  (${generateStoreAbbreviation(data).abbreviation})`;
            },
          },
          {
            title: 'Status',
            enablePropagation: true,
            field: 'targetLocationAccepted',
            render: accepted => {
              if (accepted === null) {
                return <span style={{ color: 'orange' }}>AWAITING RESPONSE</span>;
              }
              return accepted ? (
                <span style={{ color: 'green' }}>ACCEPTED</span>
              ) : (
                <span style={{ color: 'red' }}>DECLINED</span>
              );
            },
          },
        ]}
        structureTable={data =>
          data?.viewer?.inventorytransferConnection?.edges?.map(edge => edge.node)
        }
        initialQueryVariables={{
          filter: {
            targetLocationId: { eq: +locationId },
          },
          sort: {
            created: 'DESC',
          },
          first: 10,
        }}
        query={INVENTORY_TRANSFERS}
        fetchPolicy={'network-only'}
      />
      <Hidden smUp>
        <Modal open={openModal} handleClose={handleClose} title="Options" maxWidth="sm">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {buttons}
          </div>
        </Modal>
      </Hidden>
    </>
  );
};

export default InvoiceJournal;
