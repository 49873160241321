import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import getClient from './apollo/client';

import { CoreContext } from './CoreContext';
import { SnackbarProvider } from './SnackbarContext';
import config from './config';
import Router from './router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  apiVersion: '2022-11-15',
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'spark',
});

const defaultHistory = createBrowserHistory();
const defaultTheme = createMuiTheme();

function App(props) {
  const { coreUtils = {}, history = defaultHistory, theme, name = '' } = props;

  useEffect(() => {
    const modules = Array.from(config.modules);

    if (coreUtils.switchApp && coreUtils.getBasePathPrefix) {
      coreUtils.switchApp(name, modules);
    }
  }, [coreUtils, name]);

  // useEffect(() => {
  //   if (typeof document !== "undefined") {
  //     const gtmScript = document.createElement("script");
  //     gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-59FPL2F');`;

  //     const gtmNoScript = document.createElement("noscript");
  //     const iframe = document.createElement("iframe");
  //     iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-59FPL2F";
  //     iframe.height = "0";
  //     iframe.width = "0";
  //     iframe.style = "display:none;visibility:hidden";
  //     gtmNoScript.appendChild(iframe);

  //     document.head.appendChild(gtmScript);
  //     document.body.appendChild(gtmNoScript);
  //   }
  // });

  return (
    <CoreContext.Provider value={{ coreUtils, appName: name }}>
      <ApolloProvider client={getClient(coreUtils)}>
        <ThemeProvider theme={theme || defaultTheme}>
          <DndProvider backend={HTML5Backend}>
            <Elements stripe={stripePromise}>
              <StylesProvider generateClassName={generateClassName}>
                <SnackbarProvider>
                  <Router history={history} />
                </SnackbarProvider>
              </StylesProvider>
            </Elements>
          </DndProvider>
        </ThemeProvider>
      </ApolloProvider>
    </CoreContext.Provider>
  );
}

export default App;
