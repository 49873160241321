import { useMutation } from '@apollo/client';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import CREATE_INVENTORY_VENDOR from '../../../../../../apollo/mutations/inventory/createInventoryVendor';
import UPDATE_INVENTORY_VENDOR from '../../../../../../apollo/mutations/inventory/updateInventoryVendor';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { formatNumberStringToFloat } from '../../../../helpers';

const VendorForm = ({ statusOptions, handleCloseModal, vendor }) => {
  const { setSnackbar } = useSnackbar();

  const [createVendor, { loading: creating }] = useMutation(CREATE_INVENTORY_VENDOR, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Vendor created',
        open: true,
      });

      handleCloseModal();
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createInventoryvendor?.viewer),
        fields: {
          inventoryvendorConnection() {
            return {
              __typename: 'InventoryvendorEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [updateVendor, { loading: updating }] = useMutation(UPDATE_INVENTORY_VENDOR, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Vendor updated',
        open: true,
      });

      handleCloseModal();
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.updateInventoryvendor?.viewer),
        fields: {
          inventoryvendorConnection() {
            return {
              __typename: 'InventoryvendorEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const { handleSubmit, register, control, errors, reset } = useForm();

  useEffect(() => {
    reset(
      vendor || {
        inventoryvendorVendorId: '',
        name: '',
        status: '',
      },
    );
  }, [vendor?.inventoryvendorId]);

  const onSubmit = useCallback(
    async formData => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: vendor?.inventoryvendorId ? 'Vendor updating...' : 'Vendor creating...',
        open: true,
      });

      const input = {
        ...formData,
        status: +formData.status === 1,
      };

      if (vendor?.inventoryvendorId) {
        await updateVendor({
          variables: {
            input: {
              ...input,
              inventoryvendorId: vendor.inventoryvendorId,
            },
          },
        });
      } else {
        await createVendor({ variables: { input } });
      }
    },
    [vendor?.inventoryvendorId],
  );

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              autoFocus
              inputRef={register({
                required: 'true',
                validate: {
                  positive: value => value && formatNumberStringToFloat(value) > 0,
                },
              })}
              fullWidth
              type="number"
              label="ID"
              error={!!errors.inventoryvendorVendorId}
              helperText={
                (errors.inventoryvendorVendorId?.type === 'required' && 'ID is required') ||
                (errors.inventoryvendorVendorId?.type === 'positive' &&
                  'ID should be a positive number')
              }
              name="inventoryvendorVendorId"
              variant="outlined"
              placeholder="ID"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              inputRef={register({ required: 'true' })}
              fullWidth
              label="Name"
              error={!!errors.label}
              helperText={!!errors.label ? 'Name is required' : ''}
              name="label"
              variant="outlined"
              placeholder="Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel error={!!errors?.status}>Status</InputLabel>
              <Controller
                rules={{
                  required: true,
                }}
                id="status"
                name="status"
                label="Status"
                defaultValue=""
                placeholder="Status"
                error={!!errors?.status}
                as={
                  <Select label="Status" multiple={false} error={!!errors?.status}>
                    {statusOptions?.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                control={control}
              />
              {errors?.status?.type === 'required' ? (
                <FormHelperText error>Status is required</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          <Button type="submit" disabled={creating || updating}>
            {vendor?.inventoryvendorId ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default VendorForm;
