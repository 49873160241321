import { FormControlLabel, FormGroup } from '@material-ui/core';
import { Elements } from '../../../../../../../blocks';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import AvailableDaysUpdate from '../../../../../Discounts/UpdateDiscount/GeneralSettings/AvailableDays';
import TimezoneSelectionUpdate from '../../../../../Discounts/UpdateDiscount/GeneralSettings/TimezoneSelection';
import { useEffect, useState } from 'react';

function TimeSelection({
  control,
  subcategory,
  nameAdditional = 'normal',
  showTimezone = true,
  timezoneLoading,
  timezoneData,
  handleSpecficTimeChange,
  handleSwitchChange,
  showSpecificTime,
  showSpecificWeekday,
  locationId,
  handleTimeChange,
  setLocationSubcategories,
  locationSubcategories,
  setShowSimple,
  showSimple,
  row,
}) {
  const [changedDaySetting, setChangedDaySetting] = useState(
    locationSubcategories?.filter(location => location.locationId === locationId)[0],
  );
  const [element, setElement] = useState(null);

  useEffect(() => {
    if (!showTimezone) {
      if (!changedDaySetting && subcategory?.startTime) {
        const newRecord = {
          global: {},
          locationId,
          enabled: true,
          available: row.available,
        };
        newRecord.global.startTime = {
          hours: +moment(subcategory.startTime.split(':')[0], 'h')
            .format('LT')
            .split(':')[0],
          minutes: +subcategory.startTime.split(':')[1],
          meridiem: moment(subcategory.startTime, 'h')
            .format('LT')
            .split(' ')[1]
            .toLowerCase(),
        };
        newRecord.global.endTime = {
          hours: +moment(subcategory.endTime.split(':')[0], 'h')
            .format('LT')
            .split(':')[0],
          minutes: +subcategory.endTime.split(':')[1],
          meridiem: moment(subcategory.endTime, 'h')
            .format('LT')
            .split(' ')[1]
            .toLowerCase(),
        };

        if (!changedDaySetting) {
          setChangedDaySetting(newRecord);
        }
        setLocationSubcategories([...locationSubcategories, newRecord]);
      } else if (!changedDaySetting && !subcategory?.startTime) {
        let defaultValues = {
          enabled: false,
          available: row.available,
          locationId,
          global: {
            startTime: {
              hours: 12,
              minutes: 0,
              meridiem: 'am',
            },
            endTime: {
              hours: 11,
              minutes: 59,
              meridiem: 'pm',
            },
          },
        };
        if (!changedDaySetting) {
          setChangedDaySetting(defaultValues);
        }
        setLocationSubcategories([...locationSubcategories, defaultValues]);
      }
    }
    rerenderElement();
  }, [changedDaySetting, showSpecificTime, showSimple]);

  const rerenderElement = () => {
    setElement([
      ...[
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <FormGroup row={true}>
              <FormControlLabel
                label="Available everyday on specific time"
                labelPlacement="start"
                style={{ marginLeft: '0' }}
                control={
                  <Controller
                    name={
                      locationId
                        ? `enableTimeSelection-${locationId}-enabled`
                        : 'enableTimeSelection'
                    }
                    defaultValue={showSimple || !!subcategory?.startTime}
                    control={control}
                    render={props => (
                      <div style={{ marginLeft: '1rem' }}>
                        <Elements.Switch
                          onChange={e => {
                            props.onChange(e.target.checked);
                          }}
                          handleSwitchChange={e => {
                            props.onChange(e);
                            if (handleSpecficTimeChange) {
                              handleSpecficTimeChange(e, locationId, row ? row.available : null);
                            }
                            if (setShowSimple) {
                              setShowSimple(e);
                            }
                            if (showTimezone) {
                              rerenderElement();
                            }
                          }}
                          checked={props.value}
                        />
                      </div>
                    )}
                  />
                }
              />
            </FormGroup>
          </div>
          <div style={{ flexGrow: '1', marginLeft: '5rem', width: '20rem' }}>
            <Elements.TimePicker
              control={control}
              hoursName={`startTimeHours-${nameAdditional}`}
              minutesName={`startTimeMinutes-${nameAdditional}`}
              amPmName={`startTimeType-${nameAdditional}`}
              handleTimeChange={(time, type) => {
                if (handleTimeChange) {
                  handleTimeChange(time, type, locationId, 'startTime');
                }
              }}
              required
              disabled={!showSpecificTime}
              defaultValues={{
                hours: changedDaySetting
                  ? changedDaySetting.global.startTime.hours
                  : subcategory && subcategory.startTime
                  ? moment(subcategory.startTime.split(':')[0], 'h')
                      .format('LT')
                      .split(':')[0]
                  : 12,
                minutes: changedDaySetting
                  ? changedDaySetting.global.startTime.minutes
                  : subcategory && subcategory.startTime
                  ? +subcategory.startTime.split(':')[1]
                  : 0,
                meridiem: changedDaySetting
                  ? changedDaySetting.global.startTime.meridiem
                  : subcategory && subcategory.startTime
                  ? moment(subcategory.startTime, 'h')
                      .format('LT')
                      .split(' ')[1]
                      .toLowerCase()
                  : 'am',
              }}
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <h3>to</h3>
          </div>
          <div style={{ flexGrow: '1', width: '20rem' }}>
            <Elements.TimePicker
              control={control}
              handleTimeChange={(time, type) => {
                if (handleTimeChange) {
                  handleTimeChange(time, type, locationId, 'endTime');
                }
              }}
              hoursName={`endTimeHours-${nameAdditional}`}
              minutesName={`endTimeMinutes-${nameAdditional}`}
              amPmName={`endTimeType-${nameAdditional}`}
              disabled={!showSpecificTime}
              required
              defaultValues={{
                hours: changedDaySetting
                  ? changedDaySetting.global.endTime.hours
                  : subcategory && subcategory.endTime
                  ? moment(subcategory.endTime.split(':')[0], 'h')
                      .format('LT')
                      .split(':')[0]
                  : 11,
                minutes: changedDaySetting
                  ? changedDaySetting.global.endTime.minutes
                  : subcategory && subcategory.endTime
                  ? +subcategory.endTime.split(':')[1]
                  : 59,
                meridiem: changedDaySetting
                  ? changedDaySetting.global.endTime.meridiem
                  : subcategory && subcategory.endTime
                  ? moment(subcategory.endTime, 'h')
                      .format('LT')
                      .split(' ')[1]
                      .toLowerCase()
                  : 'pm',
              }}
            />
          </div>
        </div>,
      ],
    ]);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {showSpecificTime && (
          <div>
            <div>
              <div>{element}</div>
              {subcategory && !timezoneLoading && showTimezone && (
                <TimezoneSelectionUpdate
                  control={control}
                  name="timezoneId"
                  timezones={timezoneData?.viewer?.timezoneConnection}
                  coupon={subcategory}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {showSpecificWeekday && (
        <AvailableDaysUpdate
          handleSwitchChange={handleSwitchChange}
          control={control}
          coupon={subcategory}
          connectionName="subcategoryWeekdayConnection"
        />
      )}
    </div>
  );
}

export default TimeSelection;
