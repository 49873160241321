import { gql } from '@apollo/client';

const UPDATE_ALLERGEN = gql`
  mutation UpdateAllergen($input: UpdateAllergenMutationInput!) {
    updateAllergen(input: $input) {
      allergenEdge {
        node {
          id
          label
          description
          updated
        }
      }
    }
  }
`;

export default UPDATE_ALLERGEN;
