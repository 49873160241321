import Help from './Help';
import Home from './Home';
import Labor from './Labor';
import Reports from './Reports';
import { Locations } from './Locations';
import Orders from './Orders';
import SystemSettings from './SystemSettings';
import DemoPage from './DemoPage';
import Catering from './Catering';
import CateringOrderList from './Catering/CateringOrderList';
import { Loyalty } from './Loyalty';
import { ActionLogs } from './ActionLogs';
import { CoreOps } from './CoreOps';
import Inventory from './Inventory';

export const modules = {
  Help,
  Home,
  Labor,
  Reports,
  Locations,
  Orders,
  SystemSettings,
  DemoPage,
  Catering,
  CateringOrderList,
  Loyalty,
  ActionLogs,
  CoreOps,
  Inventory,
};
