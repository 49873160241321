import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, IconButton } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import LocationHoursModal from './locationHoursModal';
import { Modal, DataTable } from '../../../../../blocks';
import Permission from '../../../../../blocks/Permission';
import { MUTATION_NAME } from '../../../../../../constants';
import { AddForAllDays } from './allDaysLocationHours';

const LocationHours = ({
  locationHours,
  locationId,
  weekdayType,
  title,
  permissions,
  mutationPermissions,
  accessToMutations,
  setOpenWarningMutationName,
}) => {
  const updateLocationHoursPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'updateLocationHours',
  );

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setOpenWarningMutationName(MUTATION_NAME.UpdateLocationWeekdayMutationInput);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatColumns = () => {
    const columns = [];

    locationHours?.forEach(locationWeekday => {
      if (locationWeekday?.type === weekdayType) {
        columns.push({
          title: locationWeekday?.label,
          field: locationWeekday?.id,
          customPermissionField:
            'permissions.location.locationWeekdayConnection.edges.node.locationHours.__typename',
          // eslint-disable-next-line no-shadow
          render: weekdayHours => {
            return weekdayHours?.map((hours, i) => {
              if (!hours.active) {
                return <div key={`${locationWeekday?.id}-${i}`}>Closed</div>;
              }

              return (
                <div key={`${locationWeekday?.id}-${i}`}>
                  {hours.opened?.split(':', 2).join(':')}-{hours.closed?.split(':', 2).join(':')}
                </div>
              );
            });
          },
        });
      }
    });
    return columns;
  };

  const formatStructTable = () => {
    const object = {};
    locationHours?.forEach((locationWeekday, i) => {
      if (locationWeekday?.type === weekdayType) {
        if (!locationWeekday?.locationHours?.length) {
          locationWeekday.locationHours = [
            {
              active: false,
              opened: null,
              closed: null,
              id: locationWeekday.id,
            },
          ];
        }
        object[locationWeekday?.id] = locationWeekday.locationHours;
      }
    });
    return [object];
  };

  return (
    <>
      <DataTable
        title={title}
        data={locationHours}
        permissions={permissions}
        structureTable={() => formatStructTable()}
        columns={formatColumns()}
        customActionsTop={() => (
          <Permission access={updateLocationHoursPermission?.access}>
            <IconButton variant="contained" onClick={() => handleOpen()}>
              <Add />
            </IconButton>
          </Permission>
        )}
      />
      <Modal
        open={open}
        handleClose={handleClose}
        title={weekdayType === 'Weekday' ? 'Core Hours' : 'Holiday Hours'}
        key="editCoreHours"
      >
        <div style={{ width: '100%' }}>
          {weekdayType === 'Weekday' && (
            <div>
              <AddForAllDays locationId={locationId} handleClose={handleClose} />
              <Divider />
            </div>
          )}
          <LocationHoursModal
            permissions={permissions}
            handleClose={handleClose}
            locationWeekdayInformation={locationHours}
            locationId={locationId}
            weekdayType={weekdayType}
            title={title}
            updateLocationHoursPermission={updateLocationHoursPermission}
            accessToMutations={accessToMutations}
          />
        </div>
      </Modal>
    </>
  );
};

LocationHours.propTypes = {
  locationId: PropTypes.string,
  weekdayType: PropTypes.string,
  title: PropTypes.string,
};

export default LocationHours;
