import { gql } from '@apollo/client';

export const JOB_BREAK_RULE_LIST = gql`
  query JobBreakRules(
    $first: Int
    $search: String
    $last: Int
    $after: String
    $before: String
  ) {
    viewer {
      id
      jobBreakRuleConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        fieldGroup: 59
      ) {
        totalCount
        availableFilters
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        permissions
        edges {
          node {
            id
            ruleName
            paidBreakMaxDuration
            paidBreakMinDuration
            paidBreakInterval
            paidBreakStartOffset
            paidBreakIntervalBetweenBreaks
            requiresManagerApproval
            paidBreakFlipsToUnpaid
            paidBreakManagerApprovalToClockinEarly
            unpaidBreakMaxDuration
            unpaidBreakMinDuration
            unpaidBreakStartOffset
            unpaidBreakIntervalBetweenBreaks
            unpaidBreakManagerApprovalToClockinEarly
            location {
              label
              locationId
              storeNumber
              id
            }
          }
        }
      }
    }
  }
`;

export const JOB_BREAK_RULE = gql`
  query JobBreakRule($id: ID!) {
    viewer {
      jobBreakRule(id: $id) {
        id
        ruleName
        paidBreakMaxDuration
        paidBreakMinDuration
        paidBreakInterval
        paidBreakStartOffset
        paidBreakIntervalBetweenBreaks
        requiresManagerApproval
        paidBreakFlipsToUnpaid
        paidBreakManagerApprovalToClockinEarly
        unpaidBreakMaxDuration
        unpaidBreakMinDuration
        unpaidBreakStartOffset
        unpaidBreakIntervalBetweenBreaks
        unpaidBreakManagerApprovalToClockinEarly
        location {
          label
          locationId
          storeNumber
          id
        }
      }
    }
  }
`;
