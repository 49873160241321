import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import { FormHelperText } from "@material-ui/core";

const CurrencyInput = ({
  name,
  control,
  defaultValue,
  label,
  onValueChange,
  rules,
  placeholder,
  helperText,
  error,
  fullWidth,
  isAllowed,
  ...rest
}) => {
  const [currencyValue, setCurrencyValue] = useState(defaultValue);
  useEffect(() => {
    setCurrencyValue(defaultValue);
  }, [defaultValue]);

  return (
    <Controller
      rules={rules}
      render={(props) => (
        <>
          <NumberFormat
            {...props}
            {...rest}
            prefix={`$`}
            isAllowed={isAllowed}
            decimalScale={2}
            label={label}
            value={currencyValue}
            placeholder={placeholder}
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            onValueChange={(e) => {
              onValueChange && onValueChange(e);
              setCurrencyValue(e.value);
            }}
            thousandSeparator
            customInput={TextField}
          />
        </>
        
      )}
      name={name}
      value={currencyValue}
      defaultValue={currencyValue}
      onValueChange={(e) => {
        onValueChange(e);
        setCurrencyValue(e);
      }}
      control={control}
    />
  );
};

export default CurrencyInput;
