import React from 'react';
import {
  Checkbox,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import GeneralSettingsStyles from '../styles';

function BasicInfo({
  platformList,
  platforms,
  setPlatforms,
  control,
  brands,
  setBrands,
  isSmart,
  brandList,
  excludeAllBoostLocations,
  handleBoostStoreExclusion,
  handleIsSmartChange,
}) {
  const classes = GeneralSettingsStyles();

  const handlePlatformChange = e => {
    if (e.target.checked) {
      setPlatforms([...platforms, +e.target.value]);
    } else {
      setPlatforms(platforms.filter(plat => plat !== +e.target.value));
    }
  };

  const handleBrandChange = e => {
    if (e.target.checked) {
      setBrands([...brands, +e.target.value]);
    } else {
      setBrands(brands.filter(brand => brand !== +e.target.value));
    }
  };

  const renderPlatforms = () => {
    return platformList.edges.map(edge => {
      if (edge.node.platformId === 4) {
        return '';
      }
      if (edge.node.platformId === 2) {
        return (
          <FormControlLabel
            key={edge.node.platformId}
            control={
              <Checkbox
                onChange={handlePlatformChange}
                checked={platforms.includes(edge.node.platformId)}
                value={edge.node.platformId}
              />
            }
            label={edge.node.label[0] + edge.node.label.substr(1).toLowerCase() + ' APP'}
          />
        );
      }
      return (
        <FormControlLabel
          key={edge.node.platformId}
          control={
            <Checkbox
              onChange={handlePlatformChange}
              checked={platforms.includes(edge.node.platformId)}
              value={edge.node.platformId}
            />
          }
          label={edge.node.label}
        />
      );
    });
  };

  return (
    <Grid container className={classes.marginBottom}>
      <Grid container direction="column" item md={6}>
        <p>Internal name</p>
        <FormControl className={classes.nameField} component="fieldset">
          <Controller
            control={control}
            name="internalLabel"
            render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
              <TextField
                onChange={e => onChange(e.target.value)}
                inputRef={ref}
                fullWidth
                placeholder="Internal discount name"
              />
            )}
          />
        </FormControl>

        <p>Receipt and OLO label</p>
        <FormControl className={classes.nameField} component="fieldset">
          <Controller
            control={control}
            name="label"
            render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
              <TextField
                onChange={e => onChange(e.target.value)}
                inputRef={ref}
                fullWidth
                placeholder="Public discount Name"
              />
            )}
          />
        </FormControl>

        <FormControl className={classes.basicInfoRadioWrap} component="fieldset">
          <FormGroup className={classes.basicInfoRadio}>
            <FormLabel component="legend">Available on platforms</FormLabel>
          </FormGroup>
        </FormControl>
        <div style={{ marginTop: '1rem' }}>{renderPlatforms()}</div>

        <FormControl className={classes.basicInfoRadioWrap} component="fieldset">
          <FormGroup className={classes.basicInfoRadio}>
            <FormLabel component="legend">Available on brands</FormLabel>
          </FormGroup>
        </FormControl>
        <div style={{ marginTop: '1rem' }}>
          {brandList?.edges?.map(brand => (
            <FormControlLabel
              key={brand.node.brandId}
              control={
                <Checkbox
                  onChange={handleBrandChange}
                  checked={brands.includes(brand.node.brandId)}
                  value={brand.node.brandId}
                />
              }
              label={brand.node.label}
            />
          ))}
        </div>

        <div style={{ marginTop: '1rem' }}>
          <FormControlLabel
            key="excludeAllBoostLocations"
            control={
              <Checkbox
                onChange={event => handleBoostStoreExclusion(event.target.checked)}
                checked={excludeAllBoostLocations}
                value={excludeAllBoostLocations}
              />
            }
            label="Exlude All Boost Locations"
          />
          <FormControlLabel
            key="isSmart"
            control={
              <Checkbox
                onChange={event => handleIsSmartChange(event.target.checked)}
                checked={isSmart}
                value={isSmart}
              />
            }
            label="Smart Discount"
          />
        </div>
      </Grid>
      <Grid item md={6}>
        <Controller
          control={control}
          name="description"
          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
            <TextField
              rows={8}
              inputRef={ref}
              onChange={e => onChange(e.target.value)}
              fullWidth
              multiline
              placeholder="Description"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
export default BasicInfo;
