import React, { useMemo, useState } from 'react';
import { TextField, Container, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import AutoCompleteMUI from '../../../blocks/AutoComplete';
import AutoComplete from '../../../blocks';
import Timepicker from '../../../blocks/TimePicker';
import moment from 'moment-timezone';

import PhoneNumberInput from '../../../blocks/PhoneNumberInput';
import 'date-fns';
import DateInput from '../../../blocks/DateInput';
import { Elements, PlacesAutocomplete } from '../../../blocks';
import { useQuery } from '@apollo/client';
import { FETCH_COUNTRIES, FETCH_STATES } from '../../../../apollo/queries';
import Permission from '../../../blocks/Permission';
import PermissionField from '../../../blocks/PermissionField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: theme.typography.fontSizeL,
  },
}));

const EventInfo = ({
  quote,
  handoffTypes,
  eventTypes,
  register,
  errors,
  control,
  isLocationSet,
  containerId,
  setEventAddress,
}) => {
  EventInfo.propTypes = {
    quote: PropTypes.object,
    handoffTypes: PropTypes.object,
    eventTypes: PropTypes.object,
    register: PropTypes.func,
    errors: PropTypes.object,
  };

  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(
    quote
      ? moment
          .tz(moment.utc(quote?.quotecatering?.eventDate), quote?.location?.timezone?.label)
          .format('YYYY-MM-DD hh:mm')
      : new Date(),
  );

  const [showDelivery, setShowDelivery] = useState(Boolean(quote?.address));
  const [showCustomAddress, setShowCustomAddress] = useState(!Boolean(quote?.address));
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const { data, loading, error } = useQuery(FETCH_STATES, {
    variables: {
      first: 100000,
    },
  });

  const { data: countryData, loading: countryLoading, error: countryError } = useQuery(
    FETCH_COUNTRIES,
    {
      variables: {
        first: 10000,
      },
    },
  );

  const [selectedTime, setSelectedTime] = useState(
    quote?.quotecatering?.eventDate && quote?.location?.timezone?.label
      ? moment
          .tz(moment.utc(quote?.quotecatering?.eventDate), quote?.location?.timezone?.label)
          .format('h-mm-A')
      : null,
  );

  // eslint-disable-next-line no-param-reassign
  handoffTypes = handoffTypes.edges?.map(handoffType => ({
    label: handoffType.node.label,
    value: handoffType.node.id,
  }));

  // eslint-disable-next-line no-param-reassign
  eventTypes = eventTypes.edges?.map(eventType => ({
    label: eventType.node.label,
    value: eventType.node.id,
  }));

  const stateList = data?.viewer?.stateConnection?.edges?.map(state => ({
    label: state.node.label,
    value: state.node.stateId,
  }));

  const countryList = useMemo(() => {
    return (
      countryData?.viewer?.countryConnection?.edges?.map(country => ({
        label: country.node.label,
        value: country.node.id,
        alpha2: country.node.alpha2,
        id: country.node.id,
        countryId: country.node.countryId,
      })) || []
    );
  }, [countryData?.viewer?.countryConnection?.edges]);

  const [locationAddress, setLocationAddress] = useState({
    address: quote?.address?.address,
    city: quote?.address?.city,
    state: { label: quote?.address?.state?.label, value: quote?.address?.state?.stateId },
    country: {
      alpha2: quote?.address?.state?.country?.alpha2,
      label: quote?.address?.state?.country?.label,
      value: quote?.address?.state?.country?.id,
      id: quote?.address?.state?.country?.id,
      countryId: quote?.address?.state?.country?.countryId,
    },
    zip: quote?.address?.zip,
  });

  const handleGeocodeAutocomplete = address => {
    const { state, country } = address;

    address.state = stateList.find(existingState => existingState.label === state);
    address.country = countryList.find(existingState => existingState.alpha2 === country);
    setLocationAddress({
      ...address,
      state: address.state,
      country: address.country,
    });
    setEventAddress({ ...address, state: address.state, country: { ...(address.country || {}) } });
  };

  return (
    <Container key="cateringEventInfo" style={{ width: '100%' }} id={containerId}>
      <h2 className={classes.title}>Event info</h2>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={3}>
          <Permission access={quote?.permissions?.quotecatering?.eventType?.__typename}>
            <Grid item xs={2} direction={'column'}>
              <AutoCompleteMUI
                id={'eventType'}
                getOptionLabel={option => option.label}
                control={control}
                disabled={!isLocationSet}
                defaultValue={
                  quote && {
                    label: quote?.quotecatering?.eventType?.label,
                    value: quote?.quotecatering?.eventType?.id,
                  }
                }
                options={eventTypes}
                rules={{ required: true }}
                renderInput={params => (
                  <Permission access={quote?.permissions?.quotecatering?.eventType?.__typename}>
                    <PermissionField
                      {...params}
                      Component={TextField}
                      label="Event type"
                      variant="outlined"
                      error={!!errors?.eventType}
                      helperText={errors?.eventType && 'Event type is required'}
                    />
                  </Permission>
                )}
              />
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.quotecatering?.numberOfCustomers}>
            <Grid item xs={2}>
              <PermissionField
                Component={TextField}
                inputRef={register({ required: 'Required' })}
                x-id="Number of customers"
                label="Number of guests"
                disabled={!isLocationSet}
                name="numberOfCustomers"
                error={errors.numberOfCustomers?.message}
                helperText={errors.numberOfCustomers?.message}
                type="number"
                variant="outlined"
                defaultValue={quote?.quotecatering?.numberOfCustomers}
                style={{ width: '100%' }}
              />
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.quotecatering?.eventDate}>
            <Grid item xs={4}>
              <PermissionField
                Component={Timepicker}
                id={'EventTimePicker'}
                key={'EventTimePicker'}
                defaultValues={{
                  hours: selectedTime?.split('-')[0],
                  minutes: selectedTime?.split('-')[1],
                  amPm: selectedTime?.split('-')[2],
                }}
                hoursName={'eventHours'}
                control={control}
                fullWidth={true}
                amPmName={'amPmEvent'}
                minutesName={'minutesEvent'}
                onChange={time => {
                  setSelectedTime(time);
                }}
              />
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.quotecatering?.eventDate}>
            <Grid item xs={4}>
              <PermissionField
                Component={DateInput}
                control={control}
                name={'eventDate'}
                fullWidth
                defaultValue={selectedDate}
                label={'Event Date'}
                disabled={!isLocationSet}
              />
            </Grid>
          </Permission>
          <Grid container item xs={12} spacing={3}>
            <Permission access={quote?.permissions?.handoff?.__typename}>
              <Grid item xs={4} direction={'column'}>
                <AutoCompleteMUI
                  id={'handoffType'}
                  getOptionLabel={option => option.label}
                  options={handoffTypes}
                  disabled={!isLocationSet}
                  onChange={e => {
                    if (e.value === 'SGFuZG9mZjozOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw') {
                      setShowDelivery(true);
                    } else {
                      setShowDelivery(false);
                      setEventAddress({});
                    }
                  }}
                  control={control}
                  defaultValue={{
                    label: quote?.handoff?.label,
                    value: quote?.handoff?.id,
                  }}
                  rules={{ required: true }}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <Permission access={quote?.permissions?.handoff?.__typename}>
                      <PermissionField
                        {...params}
                        Component={TextField}
                        label="Handoff Type"
                        variant="outlined"
                        error={!!errors?.handoffType}
                        helperText={errors?.handoffType && 'Handoff type is required'}
                      />
                    </Permission>
                  )}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.quotecatering?.contactName}>
              <Grid item xs={4}>
                <PermissionField
                  Component={TextField}
                  inputRef={register({ required: 'Required' })}
                  label="Contact Name"
                  name="contactName"
                  error={errors.contactName?.message}
                  helperText={errors.contactName?.message}
                  variant="outlined"
                  disabled={!isLocationSet}
                  defaultValue={quote?.quotecatering?.contactName}
                  fullWidth
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.quotecatering?.contactPhone}>
              <Grid item xs={4}>
                <PermissionField
                  Component={PhoneNumberInput}
                  label={'Contact Phone'}
                  rules={{ required: true }}
                  name={'contactPhone'}
                  error={errors.contactPhone}
                  helperText={'Phone is required.'}
                  defaultValue={quote?.quotecatering?.contactPhone}
                  disabled={!isLocationSet}
                  control={control}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.quotecatering?.companyName}>
              <Grid item xs={4}>
                <PermissionField
                  Component={TextField}
                  name={'companyName'}
                  fullWidth
                  inputRef={register}
                  defaultValue={quote?.quotecatering?.companyName}
                  label={'Company Name'}
                  variant={'outlined'}
                />
              </Grid>
            </Permission>
          </Grid>
        </Grid>
        {showDelivery && (
          <>
            <Permission access={quote?.permissions?.address?.__typename}>
              <Grid item xs={8}>
                <PermissionField
                  Component={PlacesAutocomplete}
                  handleGeocodeAutocomplete={handleGeocodeAutocomplete}
                  name="googleAutocompleteAddress"
                  disabled={showCustomAddress}
                  // error={!!errors.googleAutocompleteAddress}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                  <PermissionField
                    Component={Elements.Switch}
                    createHelperText
                    checked={showCustomAddress}
                    label={'Custom address'}
                    xs={2}
                    handleSwitchChange={isChecked => {
                      setShowCustomAddress(isChecked);
                      setEventAddress({});
                      setLocationAddress(prev => ({ country: prev.country }));
                    }}
                  />
                </div>
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.address?.state?.country?.countryId}>
              <Grid item xs={3}>
                <AutoCompleteMUI
                  id="country"
                  name="country"
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <PermissionField
                      {...params}
                      Component={TextField}
                      label="Country"
                      name="country"
                      id="country"
                      inputRef={register({ required: showDelivery })}
                      variant="outlined"
                      error={errors.country}
                      helperText={errors.country && 'Country is required'}
                    />
                  )}
                  onChange={selected => {
                    setEventAddress(prevState => ({
                      ...prevState,
                      country: {
                        alpha2: selected?.alpha2,
                        label: selected?.label,
                        value: selected?.value,
                        countryId: selected?.countryId,
                        id: selected?.id,
                      },
                    }));
                    setLocationAddress(prevState => ({
                      ...prevState,
                      country: {
                        alpha2: selected?.alpha2,
                        label: selected?.label,
                        value: selected?.value,
                        id: selected?.id,
                        countryId: selected?.countryId,
                      },
                    }));
                  }}
                  disabled={!showCustomAddress}
                  options={countryList}
                  selectedOption={
                    locationAddress
                      ? {
                          countryId: locationAddress?.country?.countryId ?? '',
                          id: locationAddress?.country?.id ?? '',
                          label: locationAddress?.country?.label ?? '',
                          value: locationAddress?.country?.id ?? '',
                          alpha2: locationAddress?.country?.alpha2 ?? '',
                        }
                      : null
                  }
                  fullWidth
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.address?.address}>
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={'streetAddress'}
                  inputRef={register({ required: showDelivery })}
                  error={errors.streetAddress}
                  disabled={!showCustomAddress}
                  value={locationAddress?.address}
                  helperText={'Street Address is required.'}
                  fullWidth
                  label={'Street Address'}
                  variant={'outlined'}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.quotecatering?.additionalInfo}>
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={'additionalinfoStreetAddress'}
                  fullWidth
                  disabled={!showCustomAddress}
                  inputRef={register}
                  label={'Apartment, Suit, Building, etc...'}
                  variant={'outlined'}
                />
              </Grid>
            </Permission>
            <br />
            <Permission access={quote?.permissions?.address?.city}>
              <Grid item xs={4}>
                <PermissionField
                  Component={TextField}
                  name={'cityAddress'}
                  label={'City'}
                  helperText={'City is required.'}
                  disabled={!showCustomAddress}
                  error={errors.cityAddress}
                  inputRef={register({ required: showDelivery })}
                  value={locationAddress?.city}
                  fullWidth
                  variant={'outlined'}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.address?.state?.label}>
              <Grid item xs={4}>
                <PermissionField
                  Component={TextField}
                  name={'stateAddress'}
                  fullWidth
                  helperText={'State is required.'}
                  disabled={!showCustomAddress}
                  error={errors.stateAddress}
                  inputRef={register({ required: showDelivery })}
                  value={locationAddress?.state?.label}
                  label={'State'}
                  variant={'outlined'}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permisison?.address?.zip}>
              <Grid item xs={4}>
                <PermissionField
                  Component={TextField}
                  name={'zipAddress'}
                  fullWidth
                  helperText={'Zip is required.'}
                  disabled={!showCustomAddress}
                  error={errors.zipAddress}
                  inputRef={register({ required: showDelivery })}
                  value={locationAddress?.zip}
                  label={'Zip'}
                  variant={'outlined'}
                />
              </Grid>
            </Permission>
          </>
        )}
      </Grid>
      <Permission access={quote?.permissions?.quotecatering?.additionalInfo}>
        <PermissionField
          Component={TextField}
          inputRef={register()}
          label="Additional Info"
          name="additionalInfo"
          variant="outlined"
          disabled={!isLocationSet}
          multiline
          defaultValue={quote?.quotecatering?.additionalInfo}
          rowsMax={5}
          error={!!errors.additionalInfo}
          helperText={errors.additionalInfo && errors.additionalInfo.message}
          style={{ width: '100%', marginTop: '2rem' }}
        />
      </Permission>
    </Container>
  );
};

export default EventInfo;
