import { gql } from '@apollo/client';

const BULK_UPDATE_ORDER_MENU_CATEGORY = gql`
  mutation updateBulkLocationHandoffCategory($input: UpdateLocationHandoffCategoryMutationInput!) {
    updateLocationHandoffCategory(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default BULK_UPDATE_ORDER_MENU_CATEGORY;