import { gql } from '@apollo/client';

const LOCATIONS = gql`
  query Locations(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationSort]
    $filter: [LocationFilter]
  ) {
    viewer {
      id
      locationConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
      ) {
        permissions
         pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            locationId
            storeNumber
            address{
                id
                city
            }
            payrollPeriodId
            payrollPeriod {
            id
            label
            duration
          }
            label
            workWeekStartId
          }
        }
      }
    }
  }
`;

export default LOCATIONS;
