import { gql } from "@apollo/client";

const UPDATE_STRIPE = gql`
  mutation updateStripe(
    $input: UpdateStripeMutationInput!
  ) {
    updateStripe(input: $input) {
     url
    }
  }
`;

export default UPDATE_STRIPE;
