import { gql } from '@apollo/client';

const UPDATE_REPORTING_CATEGORY = gql`
  mutation UPDATE_REPORTING_CATEGORY($input: UpdateReportcategoryInput!) {
    updateReportcategory(input: $input) {
      viewer {
        id
      }
      reportcategory {
        id
        reportcategoryId
        label
        description
        active
        created
      }
    }
  }
`;

export default UPDATE_REPORTING_CATEGORY;
