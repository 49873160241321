import { gql } from '@apollo/client';

const REMOVE_WAGE_RULE = gql`
  mutation removeWageRule($input: RemoveWageRuleMutationInput!) {
    removeWageRule(input: $input) {
      clientMutationId
    }
  }
`;

export default REMOVE_WAGE_RULE;
