import { gql } from '@apollo/client';

const REMOVE_LOCATION_NOTE = gql`
  mutation RemoveLocationNote($input: RemoveLocationnoteMutationInput!) {
    removeLocationnote(input: $input) {
      viewer {
        id
      }
      location {
        locationId
      }
    }
  }
`;

export default REMOVE_LOCATION_NOTE;
