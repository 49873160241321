import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { paths } from '../../../../../constants';
import MenuTreeView from '../MenuTree/View';
import ChoicesSettingsView from '../Choices/View';
import TiersSettingsView from '../Tiers/View';
import Recipes from '../Recipes';
import MultipleEdit from '../Choices/MultipleEdit';
import ItemType from '../ItemType';
import RecipeForm from '../Recipes/RecipeForm';
import Equipment from '../Equipment';

const TabContainer = ({ value }) => (
  <AppBar position="static">
    <Tabs
      value={value}
      variant="scrollable"
      visibleScrollbar={false}
      scrollButtons={{ display: 'none' }}
    >
      <Tab label="Menus" component={Link} to={paths.SETTINGS_MENU_TREE} />
      <Tab label="Choices" component={Link} to={paths.SETTINGS_CHOICES} />
      <Tab label="Recipes" component={Link} to={paths.SETTINGS_RECIPES} />
      <Tab label="Equipment" component={Link} to={paths.SETTINGS_EQUIPMENT} />
      <Tab label="Tiers" component={Link} to={paths.SETTINGS_TIERS} />
      <Tab label="Item Type" component={Link} to={paths.SETTINGS_ITEM_TYPE} />
    </Tabs>
  </AppBar>
);

TabContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const MenuSettingsView = () => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path={paths.SETTINGS_MENU_TREE}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_MENU}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_MENU_ID}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_CATEGORY}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_CATEGORY_ID}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_SUBCATEGORY}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_SUBCATEGORY_ID}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_ITEM}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_ITEM_ID}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MenuTreeView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_CHOICES}
          render={() => (
            <Fragment>
              <TabContainer value={1} />
              <ChoicesSettingsView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_CHOICES_MULTIPLE_EDIT}
          render={() => (
            <Fragment>
              <TabContainer value={1} />
              <MultipleEdit />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_RECIPES}
          render={() => (
            <Fragment>
              <TabContainer value={2} />
              <Recipes />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_EQUIPMENT}
          render={() => (
            <Fragment>
              <TabContainer value={3} />
              <Equipment />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_TIERS}
          render={() => (
            <Fragment>
              <TabContainer value={4} />
              <TiersSettingsView />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_ITEM_TYPE}
          render={() => (
            <Fragment>
              <TabContainer value={5} />
              <ItemType />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  );
};

export default MenuSettingsView;
