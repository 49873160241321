import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import NumberInput from '../../../../../../../blocks/NumberInput';
import { useWatch } from 'react-hook-form';
import WarningIcon from '@material-ui/icons/Warning';
import { formatNumberStringToFloat } from '../../../../../../helpers';

const WarningThreshold = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '.5rem',
      }}
    >
      <WarningIcon style={{ color: 'orange' }} />
      <p>Check count</p>
    </div>
  );
};

const Threshold = ({ control, name, defaultValue, threshold }) => {
  const quantity = useWatch({
    control,
    name,
    defaultValue,
  });

  // if no treshold set
  if (threshold === null) {
    return '';
  }

  return formatNumberStringToFloat(quantity) >= threshold ? <WarningThreshold /> : '';
};

const Measure = ({ control, measure, disabled, errors, setValue, register, name }) => {
  useEffect(() => {
    register(`${name}.measureId`);
    setValue(`${name}.measureId`, measure.measureId);
  }, [name]);

  return (
    <Grid item xs={4} style={{ flexDirection: 'column' }}>
      <NumberInput
        control={control}
        disabled={disabled}
        id={`${name}.quantity`}
        defaultValue={measure.quantity}
        label={measure.label}
        error={!!get(errors, `${name}.quantity`)}
        helperText={get(errors, `${name}.quantity`) && 'Quantity should be a positive number'}
      />
      <Threshold
        control={control}
        name={`${name}.quantity`}
        defaultValue={measure.quantity}
        threshold={measure.thresholdValue}
      />
    </Grid>
  );
};

export default Measure;
