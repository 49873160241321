import { gql } from '@apollo/client';

const UPDATE_CHOICE = gql`
  mutation updateChoice($input: UpdateChoiceMutationInput!) {
    updateChoice(input: $input) {
      viewer {
        id
      }
      choiceEdge {
        node {
          id
          choiceId
          label
          description
          cost
          choiceimageConnection {
            edges {
              node {
                id
                url
              }
            }
          }
          choiceIngredientConnection {
            edges {
              node {
                id
                quantity
                ingredient {
                  id
                  ingredientId
                  inventoryitem {
                    inventoryitemId
                    measureId
                    label
                    inventoryitemMeasureConnection {
                      edges {
                        node {
                          measureId
                          measure {
                            id
                            measureId
                            abbreviation
                            label
                          }
                        }
                      }
                    }
                  }
                  measure {
                    id
                    measureId
                    abbreviation
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_CHOICE;
