import { gql } from '@apollo/client';

const UPDATE_INVENTORY_VENDOR = gql`
  mutation UPDATE_INVENTORY_VENDOR($input: UpdateInventoryvendorInput!) {
    updateInventoryvendor(input: $input) {
      viewer {
        id
      }
      inventoryVendorEdge {
        id
        inventoryvendorId
        inventoryvendorVendorId
        label
        status
      }
    }
  }
`;

export default UPDATE_INVENTORY_VENDOR;
