import { getGeocode, getLatLng } from 'use-places-autocomplete';

const validateGeocode = async (streetAddress, city, state, zip, countryAlpha2 = 'us') => {
  let error;

  const response = await getGeocode({
    address: `${streetAddress}, ${city}, ${state}, ${zip}`,
    componentRestrictions: { country: countryAlpha2 },
  })
    .then(result => {
      // don't accept partial matches
      if (result[0]?.partial_match) {
        error = 'Unknown combination of Address, City, State and Zip code';
        return {
          isValidAddress: false,
          error,
        };
      }
      return getLatLng(result[0]);
    })
    .then(({ lat, lng }) => {
      return {
        isValidAddress: true,
        lat,
        lng,
      };
    })
    .catch(e => (error = e));
  if (error) {
    return {
      isValidAddress: false,
      error,
    };
  }
  return response;
};

export default validateGeocode;
