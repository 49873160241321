import { gql } from '@apollo/client';

const equipment = gql`
  fragment EquipmentData on Equipment {
    id
    equipmentId
    label
    description
  }
`;

const equipmentFragment = { equipment };

export default equipmentFragment;
