import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  heading: {
    margin: theme.headingMargins,
  },

  secondaryHeading: {
    fontWeight: theme.secondaryHeadingFontWeight,
    padding: theme.spacing(3, 0, 3, 0),
  },

  marginBottom: {
    marginBottom: theme.spacing(4),
  },

  locationImg: {
    alignSelf: 'flex-start',
    width: '100%',
    maxWidth: '240px',
    height: 'auto',
  },

  marginLeft: {
    marginLeft: theme.spacing(3),
  },
});

const LocationInfoStyles = makeStyles(Styles);

export default LocationInfoStyles;
