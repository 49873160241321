import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel } from '@material-ui/core';

function AllergensFormView({
  AllergenCheckbox,
  allergenConnection,
  ingredientAllergenConnection,
  register,
}) {
  return (
    <FormGroup row style={{ justifyContent: 'space-between', width: '100%' }}>
      {allergenConnection.edges.map((edge, i) => (
        <FormControlLabel
          key={i}
          style={{
            width: 'min-content',
            minHeight: '100%',
            textAlign: 'center',
            justifyContent: 'space-between',
          }}
          control={
            <AllergenCheckbox
              register={register}
              allergen={edge.node}
              ingredientAllergenConnection={ingredientAllergenConnection}
            />
          }
          labelPlacement="top"
          label={edge.node.label}
        />
      ))}
    </FormGroup>
  );
}

AllergensFormView.propTypes = {
  AllergenCheckbox: PropTypes.func,
  allergenConnection: PropTypes.object,
  ingredientAllergenConnection: PropTypes.object,
  register: PropTypes.func,
};

export default AllergensFormView;
