import { gql } from '@apollo/client';

const CANCEL_INVENTORYTRANSFER = gql`
  mutation CANCEL_INVENTORYTRANSFER($input: CancelInventorytransferInput!) {
    cancelInventorytransfer(input: $input) {
      viewer {
        id
      }
      inventorytransferCanceled
    }
  }
`;

export default CANCEL_INVENTORYTRANSFER;
