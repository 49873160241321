import { gql } from "@apollo/client";
import { locationAddressFragment } from "../../fragments";

const FETCH_VENDOR = gql`
  query fetchVendor(
    $id: ID!
  ) {
    viewer {
      id
      vendor(
        id: $id
        fieldGroupOnly: 76
      ) {
        permission
        id
        vendorId
        label
        orderUrl
        promoCode
        contactName
        phone {
          id
          phone
        }
        email
        address {
          ...LocationAddressData
        }
        notes
        vendorimage {
          label
          description
          url
        }
      }
    }
  }
  ${locationAddressFragment.address}
`;

export default FETCH_VENDOR;
