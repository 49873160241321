import React, { useState, useMemo, useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import { Chip, CircularProgress, Tabs, Tab, AppBar, Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { BULK_EDIT_LOCATIONS, FETCH_WEEKDAYS } from '../../../../apollo/queries';
// import LocationHours from './LocationHours/index';
// import BULK_EDIT_GENERAL_INFO from '../../../../apollo/mutations/locations/bulkEditGeneralInfo';
import { generateStoreAbbreviation } from '../../../../utils';
import { HierarchySideBar } from '../../SystemSettings/Hierarchy/View';

import LocationSettings from './LocationSettings';
import OrderSettings from './OrderSettings';
import MenuSettings from './MenuSettings';
import { paths } from '../../../../constants';
import { Modal } from '../../../blocks';
import { CoreContext } from '../../../../CoreContext';
import { useSnackbar } from '../../../../hooks/useSnackbar';

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
  },
  divider: {
    border: 'none',
    height: '2px',
    color: '#333',
    backgroundColor: '#333',
  },
  appBar: {
    padding: '-1rem',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    backgroundColor: '#E7E8EF',
    boxShadow: 'none',
  },
  tab: {
    color: 'black',
  },
  tabActive: {
    backgroundColor: '#ffda00',
    color: 'black',
  },
  chip: {
    backgroundColor: '#E7E8EF',
    marginRight: '0.4rem',
    fontSize: 'medium',
    fontWeight: 'bold',
    margin: '0 1rem 1rem 0',
  },
  shownTabPanel: {
    display: 'block',
  },
  hiddenTabPanel: {
    display: 'none',
  },
}));

const TabPanel = ({ children, value, index, tabToReload, setTabToReload }) => {
  TabPanel.propTypes = {
    children: PropTypes.object,
    value: PropTypes.number,
    index: PropTypes.number,
  };

  const classes = useStyles();

  if (tabToReload >= 0) {
    setTabToReload(-1);
    return null;
  }

  return (
    <div
      className={value === index ? classes.shownTabPanel : classes.hiddenTabPanel}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Container>
        <Box p={3}>{children}</Box>
      </Container>
    </div>
  );
};

const BulkEditLocation = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useContext(CoreContext);
  const { setSnackbar } = useSnackbar();

  const mutationPermissions = useMemo(
    () => [
      ...(context?.coreUtils?.getAppActions()[13]?.mutations || []),
      ...(context?.coreUtils?.getAppActions()[17]?.mutations || []),
      ...(context?.coreUtils?.getAppActions()[18]?.mutations || []),
    ],
    [context?.coreUtils],
  );

  const bulkEditGeneralInfoPermission = useMemo(
    () => mutationPermissions.find(mutationPermission => mutationPermission.label === 'bulkEdit'),
    [mutationPermissions],
  );
  const updateLocationHoursPermission = useMemo(
    () => mutationPermissions?.find(permission => permission.label === 'updateLocationHours'),
    [mutationPermissions],
  );
  const toggleLocationHandoffCategoryPermission = useMemo(
    () =>
      mutationPermissions?.find(permission => permission.label === 'toggleLocationHandoffCategory'),
    [mutationPermissions],
  );

  const bulkEditPermission = { access: 7 }; // TODO: ADD PERMISSION FOR ALL OTHER BULK EDITS

  const [value, setValue] = useState(0);
  const [menuIdForBulkEdit, setMenuIdForBulkEdit] = useState(null);

  const [tabToReload, setTabToReload] = useState(-1);
  const [selected, setSelected] = useState(
    history?.location?.state?.selected?.map(location => location.id) || [],
  );
  // const [bulkEditGeneralInfo] = useMutation(BULK_EDIT_GENERAL_INFO);
  const [hierarchyLocations, setHierarchyLocations] = useState(
    history?.location?.state?.selected?.map(location => location.id) || [],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryStatuses, setCategoryStatuses] = useState({});

  const [queryLocations, { data, error, loading }] = useLazyQuery(BULK_EDIT_LOCATIONS);
  const { data: weekdays, error: weekdaysError, loading: weekdaysLoading } = useQuery(
    FETCH_WEEKDAYS,
  );

  const formatedWeekdays = useMemo(
    () =>
      weekdays?.viewer?.weekdayConnection?.edges.map(edge => ({
        ...(edge || {}),
        node: {
          ...(edge?.node || {}),
          locationHours: [
            {
              active: 0,
              opened: null,
              closed: null,
              id: edge.node.id,
            },
          ],
        },
      })),
    [weekdays?.viewer?.weekdayConnection?.edges],
  );

  useEffect(() => {
    queryLocations({
      variables: {
        filter: { id: { in: selected || [] } },
      },
    });
  }, [queryLocations]);

  function handleOnHierarchySave() {
    const newSelected = selected.concat(
      hierarchyLocations.filter(nodes => nodes.locationId).map(location => location.id),
    );
    setSelected(newSelected);
    setIsModalOpen(false);

    queryLocations({
      variables: {
        filter: { id: { in: newSelected || [] } },
      },
    });
  }

  const locationIds = useMemo(() => selected.map(id => ({ id })), [selected]);

  const checkBulkMenuIds = newValue => {
    if (newValue === 0) return true;
    const menuIds = {};

    data?.viewer?.locationConnection?.edges.forEach(edge => {
      menuIds[edge.node.menuId] = 'true';
    });

    if (Object.keys(menuIds).length > 1 && [1, 2].includes(newValue)) {
      setSnackbar({
        open: true,
        type: 'error',
        text: `${
          newValue === 1 ? 'Order ' : 'Menu '
        }settings for stores using different menus can't be edited in bulk!`,
      });
      return false;
    }
    setMenuIdForBulkEdit(+Object.keys(menuIds).at(0));
    return true;
  };

  const checkBulkTierIds = newValue => {
    if (newValue === 0) return true;
    const tiers = {};

    data?.viewer?.locationConnection?.edges.forEach(edge => {
      tiers[edge.node.tierId] = 'true';
    });

    if (Object.keys(tiers).length > 1 && [1, 2].includes(newValue)) {
      setSnackbar({
        open: true,
        type: 'error',
        text: `${
          newValue === 1 ? 'Menu ' : 'Menu '
        }settings for stores using different tiers can't be edited in bulk!`,
      });
      return false;
    }
    setMenuIdForBulkEdit(+Object.keys(tiers).at(0));
    return true;
  };

  const handleChange = (event, newValue) => {
    checkBulkMenuIds(newValue) && checkBulkTierIds(newValue) && setValue(newValue);
  };

  function handleDiscard(index) {
    setTabToReload(index);
  }

  function handleDeleteLocation(locationId) {
    const filteredLocationIds = selected.filter(id => id !== locationId);

    if (filteredLocationIds.length === 1) {
      history.push(
        paths.LOCATION.replace(':locationId', atob(selected[0])?.split(':')?.[1]).replace(
          ':currentTab?',
          'generalInfo',
        ),
      );
    }

    setSelected(filteredLocationIds);
    queryLocations({
      variables: {
        filter: { id: { in: filteredLocationIds || [] } },
      },
    });
  }

  if (loading || weekdaysLoading) {
    return <CircularProgress />;
  }
  if (error || weekdaysError) {
    return <div>{error}</div>;
  }

  return (
    <Container maxWidth={'lg'}>
      <Paper elevation={3}>
        <div className={classes.container}>
          <h2>Bulk edit</h2>
          <h5>Locations</h5>
          <Chip
            label="Add Location"
            icon={<AddIcon />}
            clickable
            onClick={() => setIsModalOpen(true)}
            key={'Chip-add-location'}
            className={classes.chip}
          />
          {selected.map((locationId, i) => {
            const location = data?.viewer?.locationConnection?.edges?.find(
              edge => edge?.node?.id === locationId,
            );

            return (
              <Chip
                label={`${generateStoreAbbreviation(location?.node).abbreviation}-${
                  location?.node?.label
                }`}
                onClick={() => {
                  history.push(
                    paths.LOCATION.replace(
                      ':locationId',
                      atob(location?.node?.id)?.split(':')?.[1],
                    ).replace(':currentTab?', 'generalInfo'),
                  );
                }}
                onDelete={() => handleDeleteLocation(location.node?.id)}
                key={`Chip-${i}`}
                className={classes.chip}
              />
            );
          })}
        </div>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            aria-label="simple tabs example"
            className={classes.tabs}
            value={value}
            onChange={handleChange}
          >
            <Tab
              label="Location Settings"
              className={value === 0 ? classes.tabActive : classes.tab}
            />
            {/* <Tab label="Order Settings" className={value === 1 ? classes.tabActive : classes.tab} /> */}
            <Tab label="Menu Settings" className={value === 2 ? classes.tabActive : classes.tab} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} setTabToReload={setTabToReload} tabToReload={tabToReload}>
          <LocationSettings
            handleDiscard={() => handleDiscard(0)}
            locationIds={locationIds}
            weekdays={formatedWeekdays}
            bulkEditPermission={bulkEditPermission}
            updateLocationHoursPermission={updateLocationHoursPermission}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={1} setTabToReload={setTabToReload} tabToReload={tabToReload}>
          <OrderSettings
            handleDiscard={() => handleDiscard(1)}
            categoryStatuses={categoryStatuses}
            setCategoryStatuses={setCategoryStatuses}
            bulkEditPermission={bulkEditPermission}
            locationIds={locationIds}
            toggleLocationHandoffCategoryPermission={toggleLocationHandoffCategoryPermission}
            menuIdForBulkEdit={menuIdForBulkEdit}
          />
        </TabPanel> */}
        <TabPanel value={value} index={1} setTabToReload={setTabToReload} tabToReload={tabToReload}>
          <MenuSettings
            handleDiscard={() => handleDiscard(1)}
            data={data}
            locationId={selected ? selected[0] : ''}
            locationIds={locationIds}
            bulkEditGeneralInfoPermission={bulkEditGeneralInfoPermission}
            bulkEditPermission={bulkEditPermission}
          />
        </TabPanel>
      </Paper>
      <Modal open={isModalOpen} handleClose={() => setIsModalOpen(false)} title="Add Locations">
        <>
          <HierarchySideBar
            locations
            setLocationIds={setHierarchyLocations}
            locationIds={hierarchyLocations}
          />
          <Button onClick={handleOnHierarchySave}>Save</Button>
        </>
      </Modal>
    </Container>
  );
};

export default BulkEditLocation;
