import { gql } from '@apollo/client';

const SAVE_DAILY_FORECASTER = gql`
  mutation saveDailyForecaster($input: SaveDailyForecasterMutationInput!) {
    saveDailyForecaster(input: $input) {
      clientMutationId
    }
  }
`;

export default SAVE_DAILY_FORECASTER;
