import { gql } from '@apollo/client';

const CREATE_CATEGORY = gql`
  mutation createCategory($input: CreateCategoryMutationInput!) {
    createCategory(input: $input) {
      categoryEdge {
        node {
          categoryId
        }
      }
    }
  }
`;

export default CREATE_CATEGORY;
