import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
    inputRoot: {
        height: 'fit-content',
        width: '100%',  
    }
});

export const AutocompleteStyles = makeStyles(Styles);
