import { Grid, Typography } from '@material-ui/core'

import PaperMain from '../../blocks/PaperMain'
import Styles from './styles';
import dashboardManual from '../../../assets/images/dashboard_manual.png'
import posManual from '../../../assets/images/pos_manual.png'

const Help = () => {
	const classes = Styles();

	return (
		<PaperMain>
			<Typography variant="h1" className={classes.title}>Help</Typography>
			<Grid container spacing={4}>
				<Grid item xs={12} lg={6}> 
					<div className={classes.manualWrapper}>
						<a href="https://new-dashboard-user-manual.netlify.app/" target="__blank" className={classes.link}>
							<div className={classes.imageContainer}>
								<img className={classes.image} src={`${process.env.REACT_APP_SITE_URL}${dashboardManual}`} alt="Dashboard manual" />
							</div> 
							<Grid container justify="center">
								<Typography variant="h2" className={classes.title}>Dashboard manual</Typography>
							</Grid>
						</a>
					</div>
				</Grid>
				<Grid item xs={12} lg={6}> 
					<div className={classes.manualWrapper}>
						<a href="https://spark-pos-user-manual.netlify.app/" target="__blank" className={classes.link}>
							<div className={classes.imageContainer}>
								<img className={classes.image} src={`${process.env.REACT_APP_SITE_URL}${posManual}`} alt="POS manual" />
							</div> 
							<Grid container justify="center">
								<Typography variant="h2" className={classes.title}>POS manual</Typography>
							</Grid>
						</a>
					</div>
				</Grid> 
			</Grid>
		</PaperMain>
	)
}

export default Help;