import React from 'react';

import StoreSpecificUsageView from './View';

const calculateAmPmSplit = amPmSplit => {
  const amPmSplitTotal = amPmSplit.amSplit * 100 + amPmSplit.pmSplit * 100;

  return amPmSplitTotal;
};

const calculateProteinSSPMTotal = proteins => {
  const sspmProteinTotal = proteins.reduce(
    (currentSspm, currentProtein) => currentSspm + (currentProtein.sspm || 0),
    0,
  );

  return sspmProteinTotal;
};

const calculateSideSSPMTotal = sides => {
  const sspmSideTotal = sides.reduce(
    (currentCspm, currentSide) => currentCspm + (currentSide.sspm || 0),
    0,
  );

  return sspmSideTotal;
};

const calculateStoreSpecificUsageData = apiData => {
  let storeSpecificUsage = {
    amPmSplit: apiData.amPmSplit,
    amPmSplitTotal: calculateAmPmSplit(apiData.amPmSplit),
  };

  const proteins = apiData.proteins.map(protein => ({
    ...protein,
    sspm: protein.sspm || protein.cspm,
  }));

  storeSpecificUsage.proteins = proteins;

  const cspmProteinTotal = proteins.reduce(
    (currentCspm, currentProtein) => currentCspm + (currentProtein.cspm || 0),
    0,
  );

  storeSpecificUsage.cspmProteinTotal = cspmProteinTotal;

  storeSpecificUsage.sspmProteinTotal = calculateProteinSSPMTotal(proteins);

  const sides = apiData.sides.map(side => ({ ...side, sspm: side.sspm || side.cspm }));

  storeSpecificUsage.sides = sides;

  const cspmSideTotal = sides.reduce(
    (currentCspm, currentSide) => currentCspm + (currentSide.cspm || 0),
    0,
  );

  storeSpecificUsage.cspmSideTotal = cspmSideTotal;

  storeSpecificUsage.sspmSideTotal = calculateSideSSPMTotal(sides);

  return storeSpecificUsage;
};

const StoreSpecificUsage = ({ data, saveSSPM, saveSSPMLoading, setSnackbar }) => {
  return (
    <StoreSpecificUsageView
      calculateAmPmSplit={calculateAmPmSplit}
      calculateProteinSSPMTotal={calculateProteinSSPMTotal}
      calculateSideSSPMTotal={calculateSideSSPMTotal}
      calculateStoreSpecificUsageData={calculateStoreSpecificUsageData}
      saveSSPM={saveSSPM}
      saveSSPMLoading={saveSSPMLoading}
      data={data}
      setSnackbar={setSnackbar}
    />
  );
};

export default StoreSpecificUsage;
