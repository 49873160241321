import React, { useState, useContext, useMemo } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";

import { LocationIdContext } from "../../../../Location";

import { Elements, Permission, PermissionField } from "../../../../../../blocks";

import { MUTATION_NAME, SNACKBAR_STATUS } from "../../../../../../../constants";
import {
  CREATE_TAX_RATE,
  UPDATE_TAX_RATE,
} from "../../../../../../../apollo/mutations/locations";
import { FETCH_LOCATION, FETCH_HANDOFFS } from "../../../../../../../apollo/queries";
import { regexPattern, convert, toFixed10 } from '../../../../../../../utils';

const TAX_RATE_VALUE = {
  FRACTION: "fraction",
  AMOUNT: "amount",
};

const taxRateOptions = [
  {
    value: TAX_RATE_VALUE.FRACTION,
    label: "Fraction - %",
  },
  {
    value: TAX_RATE_VALUE.AMOUNT,
    label: "Amount - $",
  },
];

const AddTaxRate = ({ handleCancel, handleOpenSnackBar, editData, permissions, accessToMutations, mutationPermissions }) => {
  const [taxRateInputOption, setTaxRateInputOption] = useState(
    taxRateOptions[0]
  );
  const [handoffChecked, setHandoffChecked] = useState(false);
  const locationId = useContext(LocationIdContext);

  const { register, handleSubmit, errors, control } = useForm();

  const { data: handoffs, loading: handoffsLoading, error: handoffsError } = useQuery(FETCH_HANDOFFS);

  const updateTaxRatePermission = useMemo(() => mutationPermissions?.find(permission => permission.label === "updateTaxRate"), [mutationPermissions]);
  const createTaxRatePermission = useMemo(() => mutationPermissions?.find(permission => permission.label === "createTaxRate"), [mutationPermissions]);

  const [createTaxRate, { loading: createLoading }] = useMutation(CREATE_TAX_RATE);
  const [updateTaxRate, { loading: updateLoading }] = useMutation(UPDATE_TAX_RATE);

  const defaultValues = useMemo(() => {
    if (editData) {
        const result = {
          taxId: editData?.id,
          taxName: editData?.label,
          taxDescription: editData?.description,
          taxFraction: toFixed10(editData?.fraction * 100, 2),
          taxAmount: convert.centsToDollars(editData?.amount),
          taxDisplay: editData?.display,
          taxSurcharge: editData?.surcharge,
          taxTaxable: editData?.taxable,
          handoffDependents: editData?.taxrateHandoffConnection?.edges?.map(edge => edge.node.handoff.id) || []
        };

        if (result.handoffDependents.length > 0) {
          setHandoffChecked(true);
        }

        return result;
      }

    return {};
  }, [editData])

  const onSubmit = async (data) => {
    const {
      taxName,
      description,
      taxRateOptionAmount,
      surcharge,
      taxable,
      displayOnBill,
      handoffDependents,
    } = data;

    const fraction =
      taxRateInputOption.value === TAX_RATE_VALUE.FRACTION
        ? Number(taxRateOptionAmount) / 100
        : 0;
    const amount =
      taxRateInputOption.value === TAX_RATE_VALUE.AMOUNT
        ? convert.dollarsToCents(Number(taxRateOptionAmount))
        : 0;

    const taxRateInputVariables = {
      locationId,
      description,
      fraction,
      amount,
      surcharge,
      taxable,
      label: taxName,
      display: displayOnBill,
      handoffIds: Object.keys(handoffDependents || {}).filter(id => handoffDependents[id]),
    };

    try {
      if (editData) {
        await updateTaxRate({
          variables: {
            input: { ...taxRateInputVariables, taxrateId: defaultValues.taxId },
          },
          refetchQueries: [
            {
              query: FETCH_LOCATION,
              variables: { id: locationId, first: 2147483647 },
            },
          ],
        });
      } else {
        await createTaxRate({
          variables: { input: taxRateInputVariables },
          refetchQueries: [
            {
              query: FETCH_LOCATION,
              variables: { id: locationId, first: 2147483647 },
            },
          ],
        });
      }
      const snackbarMessage = editData ? "Tax Rate Updated" : "Added Tax Rate";
      handleCancel();
      handleOpenSnackBar(snackbarMessage, SNACKBAR_STATUS.SUCCESS);
    } catch (error) {
      handleOpenSnackBar(error.message, SNACKBAR_STATUS.ERROR);
    }
  };

  const handleToogle = (toggleValue) => {
    const selectedToggleOption = taxRateOptions.filter(
      (option) => option.value === toggleValue
    );
    setTaxRateInputOption(selectedToggleOption[0]);
  };

  if (handoffsLoading) {
    return <CircularProgress />;
  }

  if (handoffsError) {
    return <>Error</>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container item xs={12} spacing={4}>
        <Grid container item xs={12} spacing={4}>
          <Permission access={permissions?.taxrateConnection?.edges?.node?.label}>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                label="Tax Name"
                fullWidth
                name="taxName"
                placeholder="City"
                defaultValue={defaultValues && defaultValues.taxName}
                inputRef={register({ required: true })}
                error={!!errors.taxName}
                helperText={errors.taxName && "Tax name is required"}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.taxrateConnection?.edges?.node?.description}>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                label="Description"
                fullWidth
                name="description"
                defaultValue={defaultValues && defaultValues.taxDescription}
                placeholder="Apply on city"
                inputRef={register}
              />
            </Grid>
          </Permission>
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <Grid
            container
            item
            xs={6}
            justify="space-between"
            alignItems="center"
          >
            <Permission access={permissions?.taxrateConnection?.edges?.node?.fraction}>
              <Grid item container xs={6}>
                <div>
                  <PermissionField
                    Component={Elements.ToggleButton}
                    createHelperText
                    options={taxRateOptions}
                    name="taxRateOption"
                    value={taxRateInputOption.value}
                    handleToggleClick={(value) => handleToogle(value)}
                  />
                </div>
              </Grid>
            </Permission>
            <Permission access={taxRateInputOption.value === TAX_RATE_VALUE.FRACTION ? permissions?.taxrateConnection?.edges?.node?.fraction : permissions?.taxrateConnection?.edges?.node?.amount}>
              <Grid item container xs={6}>
                <PermissionField
                  Component={TextField}
                  name="taxRateOptionAmount"
                  fullWidth
                  defaultValue={
                    defaultValues &&
                    (taxRateInputOption.value === TAX_RATE_VALUE.FRACTION
                      ? defaultValues.taxFraction
                      : defaultValues.taxAmount)
                  }
                  inputRef={register({
                    required: true,
                    pattern: { value: regexPattern.onlyNumber, message: 'Only numbers' },
                    max: {
                      value: taxRateInputOption.value === TAX_RATE_VALUE.FRACTION ? 100 : 10000,
                      message: `Fractions can't be over 100`,
                    },
                    min: { value: 0, message: 'No negative numbers' },
                  })}
                  placeholder={`Enter ${taxRateInputOption.label} value`}
                  error={!!errors.taxRateOptionAmount}
                  helperText={errors.taxRateOptionAmount && errors.taxRateOptionAmount.messag}
                />
              </Grid>
            </Permission>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <Grid item container xs={12} spacing={4}>
            <Permission access={permissions?.taxrateConnection?.edges?.node?.surcharge}>
              <Grid item container xs={12} direction="column">
                <PermissionField createHelperText Component={Grid} item xs={3} container alignItems="center" justify="space-between" spacing={4}>
                  <Grid item xs={8} >
                    <Typography variant="subtitle2">Surcharge</Typography>
                  </Grid>
                  <Grid item xs={4} >
                    <Elements.Switch
                      name="surcharge"
                      ref={register}
                      checked={defaultValues && defaultValues.taxSurcharge}
                    />
                  </Grid>
                </PermissionField>
              </Grid>
            </Permission>
            <Permission access={permissions?.taxrateConnection?.edges?.node?.taxable}>
              <Grid item container xs={12} direction="column">
                <PermissionField createHelperText Component={Grid} item xs={3} container alignItems="center" justify="space-between" spacing={4}>
                  <Grid item xs={8} >
                    <Typography variant="subtitle2">Taxable</Typography>
                  </Grid>
                  <Grid item xs={4} >
                    <Elements.Switch
                      name="taxable"
                      ref={register}
                      checked={defaultValues && defaultValues.taxTaxable}
                    />
                  </Grid>
                </PermissionField>
              </Grid>
            </Permission>
            <Permission access={permissions?.taxrateConnection?.edges?.node?.display}>
              <Grid item container xs={12} direction="column">
                <PermissionField createHelperText Component={Grid} item xs={3} container alignItems="center" justify="space-between" spacing={4}>
                  <Grid item xs={8} >
                    <Typography variant="subtitle2">Display on bill</Typography>
                  </Grid>
                  <Grid item xs={4} >
                    <Elements.Switch
                      name="displayOnBill"
                      ref={register}
                      checked={defaultValues && defaultValues.taxDisplay}
                    />
                  </Grid>
                </PermissionField>
              </Grid>
            </Permission>
            <Permission access={permissions?.taxrateConnection?.edges?.node?.taxrateHandoffConnection?.edges?.node?.handoff?.id}>
              <Grid item xs={12} container direction="column">
                <PermissionField createHelperText Component={Grid} item xs={3} alignItems="center" justify="space-between" spacing={4}>
                  <Grid item xs={8} >
                    <Typography variant="subtitle2">Handoff Dependent</Typography>
                  </Grid>
                  <Grid item xs={4} >
                    <Elements.Switch
                      name="handoffChecked"
                      ref={register}
                      checked={handoffChecked}
                      handleSwitchChange={setHandoffChecked}
                    />
                  </Grid>
                </PermissionField>
                {true || handoffChecked
                ? (
                  <Grid item xs={9} >
                    {handoffs?.viewer?.handoffConnection?.edges?.map(edge => (
                      <Grid item xs={4} key={edge.node.id}>
                        <Controller
                          control={control}
                          defaultValue={defaultValues?.handoffDependents?.includes(edge.node.id)}
                          name={`handoffDependents.${edge.node.id}`}
                          render={({ onChange }) => (
                            <Permission access={permissions?.taxrateConnection?.edges?.node?.taxrateHandoffConnection?.edges?.node?.handoff?.id}>
                              <div>
                                <PermissionField
                                  createHelperText
                                  Component={FormControlLabel}
                                  control={
                                    <Checkbox
                                      ref={register}
                                      defaultChecked={defaultValues?.handoffDependents?.includes(edge.node.id)}
                                      onChange={({target: {checked}}) => onChange(checked)}
                                    />
                                  }
                                  label={edge.node.label}
                                />
                              </div>
                            </Permission>
                          )}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )
              : null }
            </Grid>
          </Permission>
          </Grid>

        </Grid>
        <Grid container item xs={12} justify="flex-end">
          <Grid container item xs={3} justify="space-around">
            <Grid item xs={6}>
              <Button
                onClick={handleCancel}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Permission access={accessToMutations?.[editData
                  ? Math.min(
                      MUTATION_NAME.UpdateTaxrateMutationInput,
                      updateTaxRatePermission?.access,
                    )
                  : Math.min(
                    MUTATION_NAME.CreateTaxrateMutationInput,
                    createTaxRatePermission?.access,
                  )
                ]}>
                  <Button type="submit" disabled={updateLoading || createLoading}>Submit</Button>
                </Permission>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddTaxRate;
