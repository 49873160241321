import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
    container: {
        padding: 0,
        width: '100%',
    },
    button: {
        color: 'black',
        borderColor: 'black',
        marginLeft: '0.2rem',
        marginBottom: '0.4rem',
        maxWidth: '30%',
        maxHeight: '30%',
        fontSize: '8px',
        padding: '0.4rem',
    },
    input: {
        display: 'none',
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    media: {
        height: 140,
    },
    paper: {
        width: '80%',
        height: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    link: {
        textDecoration: 'none',
        color: 'black',
    },
    spinner: {
        position: 'fixed',
        top: '50%',
        left: '50%',
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    treeView: {
        marginTop: theme.spacing(3),
        maxHeight: 800,
        overflowY: 'auto',
    },
    treeItem: {
        '& .MuiCollapse-container': {
            borderLeft: '1px dashed #000',
            marginLeft: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
    },
    treeItemLabel: {
        alignItems: 'center',
        display: 'flex',
        fontSize: theme.typography.fontSizeS,
        margin: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(2)}px 0`,
    },
    treeItemId: {
        fontSize: theme.typography.fontSizeXS,
        marginLeft: 'auto',
        opacity: '.75',
    },
    treeItemActions: {
        marginLeft: '10px',
        whiteSpace: 'nowrap',
    },
    treeItemNew: {        
        '&.MuiButtonBase-root': {
            margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        },
    },
    buttonAdd: {
        '&.MuiButtonBase-root': {
            marginLeft: theme.spacing(4),
        },
    },
    sectionContainer: {
        margin: `${theme.spacing(3)}px 0`,
        padding: 0,
    },
    generalInfoImg: {
        alignSelf: 'flex-start',
        margin: '0 auto',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 8,        
    },
    subcategoryHeader: {
        alignItems: 'center',
        margin: `${theme.spacing(2)}px 0`,
    },
    list: {
        width: '100%',
        maxHeight: 400,
        overflowY: 'auto',
    },
    listLink: {
        color: 'inherit',
        textDecoration: 'none',
        margin: 0,
        padding: 0,
    },
    listItem: {
        padding: `${theme.spacing(1)}px 0`,

        '& .MuiTypography-body1': {
            fontSize: theme.typography.fontSizeS,
        },
    },
    switch: {
        margin: `0 ${theme.spacing(2)}px`,
    }
});

const MenuTreeStyles = makeStyles(Styles);

export default MenuTreeStyles;
