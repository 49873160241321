import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

const DatePicker = ({
  control,
  defaultValue,
  disablePast = false,
  disableToolbar,
  shouldDisableDate,
  onDateChange,
  disableFuture,
  disabled = false,
  error,
  format,
  label,
  name,
  rules,
  fullWidth,
  helperText,
  clearable = false,
  value,
  ...rest
}) => {
  const [isInvalidDate, setIsInvalidDate] = useState(false);

  const selectedDate = useMemo(() => defaultValue || value || null, [value, defaultValue]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        control={control}
        name={name}
        defaultValue={selectedDate}
        rules={rules}
        render={({ onChange, value }) => {
          return (
            <KeyboardDatePicker
              {...rest}
              disableToolbar={disableToolbar}
              autoOk={true}
              clearable={clearable}
              placeholder="MM/DD/YYYY"
              value={value || null}
              label={label || 'Select Date'}
              variant="inline"
              fullWidth={fullWidth}
              inputVariant="outlined"
              shouldDisableDate={shouldDisableDate}
              onChange={event => {
                if (event !== null && Number.isNaN(Date.parse(event))) {
                  setIsInvalidDate(true);
                } else {
                  setIsInvalidDate(false);
                }

                if (onDateChange) {
                  onDateChange(event);
                }
                onChange(event);
              }}
              disablePast={disablePast}
              disableFuture={disableFuture}
              error={isInvalidDate || error}
              helperText={isInvalidDate ? 'Invalid Date' : helperText}
              format={format || 'MM/dd/yyyy'}
              disabled={disabled}
            />
          );
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
export default DatePicker;
