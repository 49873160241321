import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Print } from '@material-ui/icons';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import DELETE_RECIPE from '../../../../../apollo/mutations/recipes/deleteRecipe';
import {
  FETCH_BRANDS,
  FETCH_MEASURES,
  FETCH_RECIPES,
  FETCH_RECIPE_CATEGORIES,
  FETCH_RECIPE_TYPES,
} from '../../../../../apollo/queries';
import { SNACKBAR_STATUS } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import generateRecipePdf from '../../../../../utils/pdfUtils/generateRecipePdf';
import { Modal, ProductionForecasterSettings, WasteLogConfirmationModal } from '../../../../blocks';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import { prepareDataForPrepPdf } from './helpers';
import PrintRecipe from './PrintRecipe';
import RecipeForm from './RecipeForm';

const statusOptions = [
  { label: 'Active', value: 1, color: '#37C78A' },
  { label: 'Inactive', value: 0, color: '#FF9900' },
];

const Recipes = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [recipe, setRecipe] = useState();
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const [selectedSalecategoryIds, setSelectedSalecategoryIds] = useState([]);
  const [isProductionForecasterModalOpen, setIsProductionForecasterModalOpen] = useState(false);
  const [isWasteLogModalOpen, setIsWasteLogModalOpen] = useState(false);

  const { setSnackbar } = useSnackbar();

  const [deleteRecipe, { loading: deleting }] = useMutation(DELETE_RECIPE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Recipe deleted',
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.deleteRecipe?.viewer),
        fields: {
          recipeConnection() {
            return {
              __typename: 'RecipeEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const { data: measureData, loading: loadingMeasures, error: measureError } = useQuery(
    FETCH_MEASURES,
  );
  const { data: brandData, loading: loadingBrands, error: brandsError } = useQuery(FETCH_BRANDS);
  const { data: recipeTypeData, loading: loadingRecipeType, error: recipeTypeError } = useQuery(
    FETCH_RECIPE_TYPES,
  );
  const {
    data: salecategoryData,
    loading: loadingSalecategories,
    error: salecategoryError,
  } = useQuery(FETCH_RECIPE_CATEGORIES, {
    variables: {
      fieldGroupOnly: 7,
    },
  });

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setRecipe();
  }, []);

  const handleClosePrintModal = useCallback(() => {
    setIsPrintModalOpen(false);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;

      if (filters?.inventoryitem?.inventoryStatus) {
        delete filters.inventoryitem.inventoryStatus;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          inventoryitem: {
            ...(variables.filter?.inventoryitem || {}),
            inventoryStatus: {
              eq: !!value,
            },
          },
        },
      });
    }
  }, []);

  const handleOnBrandChange = useCallback(({ value, refetch, variables }) => {
    setSelectedBrandIds(value.map(item => item.value));
    if (isEmpty(value)) {
      const filters = variables.filter;
      if (filters?.brandId) {
        delete filter.brandId;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          brandId: {
            in: value.map(item => item.value),
          },
        },
      });
    }
  }, []);

  const handleOnSaleCategoryChange = useCallback(({ value, refetch, variables }) => {
    setSelectedSalecategoryIds(value.map(item => item.value));
    if (isEmpty(value)) {
      const filters = variables.filter;
      if (filters?.inventoryitem?.inventoryitemcategoryId) {
        delete filters.inventoryitem.inventoryitemcategoryId;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          inventoryitem: {
            inventoryitemcategoryId: {
              in: value.map(item => item.value),
            },
          },
        },
      });
    }
  }, []);

  const measureOptions = useMemo(() => {
    if (!loadingMeasures && !measureError) {
      return (
        measureData?.viewer?.measureConnection?.edges?.map(edge => ({
          value: edge.node?.measureId,
          label: `${edge.node?.label} (${edge.node?.abbreviation})`,
          abbreviation: edge.node?.abbreviation,
        })) || []
      );
    }

    return [];
  }, [measureData?.viewer?.measureConnection, loadingMeasures, measureError]);

  const brandOptions = useMemo(() => {
    if (!loadingBrands && !brandsError) {
      return (
        brandData?.viewer?.brandConnection?.edges?.map(edge => ({
          value: edge.node?.brandId,
          label: edge.node?.label,
        })) || []
      );
    }

    return [];
  }, [brandData?.viewer?.measureConnection, loadingBrands, brandsError]);

  const recipeTypeOptions = useMemo(() => {
    if (!loadingRecipeType && !recipeTypeError) {
      return (
        recipeTypeData?.viewer?.recipetypeConnection?.edges?.map(edge => ({
          value: edge.node?.recipetypeId,
          label: edge.node?.label,
        })) || []
      );
    }

    return [];
  }, [recipeTypeData?.viewer?.recipetypeConnection, loadingRecipeType, recipeTypeError]);

  const salecategoryOptions = useMemo(() => {
    if (!loadingSalecategories && !salecategoryError) {
      return (
        salecategoryData?.viewer?.inventoryitemcategoryConnection?.edges?.map(edge => ({
          value: edge.node?.inventoryitemcategoryId,
          label: edge.node?.label,
          recipeTypeId: edge.node?.inventoryitemcategorytypeId,
        })) || []
      );
    }

    return [];
  }, [salecategoryData?.viewer?.measureConnection, loadingSalecategories, salecategoryError]);

  const onDelete = useCallback(
    ({ recipeId }) => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Deleting Recipe...',
        open: true,
      });

      deleteRecipe({ variables: { input: { recipeId } } });
    },
    [deleteRecipe],
  );

  const onAddToProductionForecasterClick = useCallback(() => {
    setIsProductionForecasterModalOpen(true);
  }, []);

  const handleProductionForecasterModalClose = useCallback(() => {
    setIsProductionForecasterModalOpen(false);
  }, []);

  const onAddToWasteLogClick = useCallback(() => {
    setIsWasteLogModalOpen(true);
  }, []);

  const handleWasteLogModalClose = useCallback(() => {
    setIsWasteLogModalOpen(false);
  }, []);

  const handlePrintRecipe = useCallback(({ data }) => {
    generateRecipePdf([prepareDataForPrepPdf(data)]);
  }, []);

  return (
    <>
      <ConnectionTable
        query={FETCH_RECIPES}
        title="Recipes"
        customActionsTop={() => (
          <Grid item>
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button variant="outlined" onClick={() => setIsPrintModalOpen(true)}>
                  <Print style={{ marginRight: '8px' }} />
                  Print Recipe Book
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => setIsModalOpen(true)}>New Recipe</Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        onTableRowClick={row => {
          setRecipe(row?.data);
          setIsModalOpen(true);
        }}
        structureTable={data =>
          data?.viewer?.recipeConnection?.edges?.map(edge => ({
            recipeId: edge?.node?.recipeId,
            label: edge?.node?.inventoryitem?.label,
            salecategoryLabel: edge?.node?.inventoryitem?.inventoryitemcategory?.label,
            recipeType: edge?.node?.recipetype?.label,
            status: edge?.node?.inventoryitem?.inventoryStatus,
            created: moment(edge?.node?.created).format('MM/DD/YYYY z'),
            data: edge?.node,
          }))
        }
        columns={[
          {
            enablePropagation: true,
            title: 'ID',
            field: 'recipeId',
          },
          {
            enablePropagation: true,
            title: 'Name',
            field: 'label',
          },
          {
            enablePropagation: true,
            title: 'Category',
            field: 'salecategoryLabel',
          },
          {
            enablePropagation: true,
            title: 'Recipe Type',
            field: 'recipeType',
          },
          {
            enablePropagation: true,
            title: 'Status',
            field: 'status',
            render: status => (
              <Typography variant="body1" style={{ color: status ? '#37C78A' : '#FF9900' }}>
                <strong>{status ? 'Active' : 'Inactive'}</strong>
              </Typography>
            ),
          },
          {
            enablePropagation: true,
            title: 'Last Updated',
            field: 'created',
          },
          {
            title: 'Print',
            field: 'recipeId',
            cellStyle: { padding: 0 },
            render: (recipeId, _, row) => (
              <IconButton onClick={() => handlePrintRecipe(row)}>
                <Print />
              </IconButton>
            ),
          },
        ]}
        handleDeleteRow={onDelete}
        useConfirmationModalForDelete
        isDeleting={deleting}
        deleteConfirmationText="Are you sure you want to delete this recipe?"
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  multiple
                  defaultValue={[]}
                  onChange={({ target: { value } }) =>
                    handleOnSaleCategoryChange({ value, refetch, variables })
                  }
                  renderValue={selected => selected.map(({ label }) => label).join(', ')}
                >
                  {salecategoryOptions?.map(option => (
                    <MenuItem key={option.value} value={option}>
                      <Checkbox checked={selectedSalecategoryIds.includes(option.value)} />
                      <ListItemText style={{ margin: '0' }} primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Brand</InputLabel>
                <Select
                  label="Brand"
                  multiple
                  defaultValue={[]}
                  onChange={({ target: { value } }) =>
                    handleOnBrandChange({ value, refetch, variables })
                  }
                  renderValue={selected => selected.map(({ label }) => label).join(', ')}
                >
                  {brandOptions?.map(option => (
                    <MenuItem key={option.value} value={option}>
                      <Checkbox checked={selectedBrandIds.includes(option.value)} />
                      <ListItemText style={{ margin: '0' }} primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      />

      <Modal
        handleClose={handleCloseModal}
        open={isModalOpen}
        title={recipe?.recipeId ? 'Update Recipe' : 'Create Recipe'}
        headerContent={() =>
          recipe?.recipeId && (
            <>
              <Button variant="outlined" onClick={() => onAddToWasteLogClick()}>
                {recipe.inventoryitem.partOfWastelog ? 'Edit Waste Log Info' : 'Add To Waste Log'}
              </Button>
              <Button variant="outlined" onClick={() => onAddToProductionForecasterClick()}>
                {recipe?.inventoryitem?.inventoryitemPrep?.id
                  ? 'Edit Production Forecaster Info'
                  : 'Add To Production Forecaster'}
              </Button>
            </>
          )
        }
      >
        <RecipeForm
          handleCancel={handleCloseModal}
          recipe={recipe}
          recipeTypeOptions={recipeTypeOptions}
          brandOptions={brandOptions}
          statusOptions={statusOptions}
          measureOptions={measureOptions}
        />
      </Modal>
      <Modal handleClose={handleClosePrintModal} open={isPrintModalOpen} title="Print Recipe Book">
        <PrintRecipe
          handleCancel={handleClosePrintModal}
          categoryOptions={salecategoryOptions}
          brandOptions={brandOptions}
          recipeTypeOptions={recipeTypeOptions}
        />
      </Modal>
      <ProductionForecasterSettings
        isProductionForecasterModalOpen={isProductionForecasterModalOpen}
        updateInventoryitem={setRecipe}
        handleProductionForecasterModalClose={handleProductionForecasterModalClose}
        inventoryitem={recipe?.inventoryitem}
      />
      <WasteLogConfirmationModal
        isWasteLogModalOpen={isWasteLogModalOpen}
        handleWasteLogModalClose={handleWasteLogModalClose}
        updateInventoryitem={setRecipe}
        inventoryitem={recipe?.inventoryitem}
        yieldQuantity={recipe?.yieldQuantity}
      />
    </>
  );
};

export default Recipes;
