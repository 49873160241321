import React, { useState } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ProductionForecasterStyles from '../../View/styles.js';
import { toFixed10 } from '../../../../../../../utils';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { SNACKBAR_STATUS } from '../../../../../../../constants';

const ClosingInventoryView = ({
  data,
  calculateClosingInventory,
  calculateYieldedCost,
  calculateCost,
  saveClosingInventory,
  getSideUOM,
  saveClosingInventoryLoading,
  setSnackbar,
}) => {
  const classes = ProductionForecasterStyles();

  const [closingInventory, setClosingInventory] = useState(
    calculateClosingInventory(data.viewer.location.closingInventory),
  );

  const handleSaveClosingInventory = () => {
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Saving Closing Inventory...',
    });

    const saveClosingInventoryInput = {
      locationId: data.viewer.location.locationId,
      closingInventoryDate: DateTime.local().toFormat('yyyy-LL-dd'),
      items: [
        ...closingInventory.proteins.map(protein => ({
          itemId: protein.protein.id,
          closingInventoryValue: protein.value,
        })),
        ...closingInventory.sides.map(side => ({
          itemId: side.side.id,
          closingInventoryValue: side.value,
        })),
      ],
    };

    saveClosingInventory({
      variables: {
        input: saveClosingInventoryInput,
      },
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Closing Inventory saved',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        className={classes.titlePadding}
        id="closingInventory"
      >
        Closing Inventory
      </Typography>
      <Grid container className={classes.heading}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            format="LL/dd/yyyy"
            margin="normal"
            style={{ width: '230px' }}
            label="Closing Inventory Date"
            autoOk={true}
            disabled={true}
            initialFocusedDate={DateTime.local()}
            value={DateTime.local()}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container className={classes.heading}>
        <Grid item xs={6} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}>
            <Grid container className={classes.heading}>
              <Grid item xs={3} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>Proteins</b>
                </div>
              </Grid>
              <Grid item xs={3} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>UOM</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>Count</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flexDirection} style={{ marginLeft: '2rem' }}>
                <div className={classes.contentInfoHolder}>
                  <b>Yielded Cost</b>
                </div>
              </Grid>
              {closingInventory.proteins.map((proteinCI, index) => (
                <React.Fragment key={proteinCI.protein.id}>
                  <Grid
                    item
                    xs={3}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <div className={classes.centerVerticalContent}>{proteinCI.protein.label}</div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <div className={classes.centerVerticalContent}>{`${
                      Math.round(proteinCI.protein.size * 100) / 100 <= 1
                        ? ''
                        : Math.round(proteinCI.protein.size * 100) / 100
                    }${proteinCI.protein.measure} ${proteinCI.protein.format}`}</div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <div className={classes.contentInfoHolder}>
                      <TextField
                        name="ciCount"
                        type="number"
                        defaultValue={proteinCI.value}
                        variant="outlined"
                        onChange={event => {
                          setClosingInventory(prevClosingInventory => {
                            const proteins = [...prevClosingInventory.proteins];

                            const protein = {
                              ...proteins[index],
                              value: parseFloat(event.target.value),
                            };

                            proteins[index] = {
                              ...protein,
                              yieldedCost: calculateYieldedCost(protein),
                            };

                            const closingInventory = {
                              ...prevClosingInventory,
                              proteins,
                            };

                            return closingInventory;
                          });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginLeft: '2rem' }}
                  >
                    <div className={classes.centerVerticalContent}>
                      <b>
                        {proteinCI.yieldedCost ? `$ ${toFixed10(proteinCI.yieldedCost, 2)}` : ''}
                      </b>
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.flexDirection}
          style={{ borderLeft: '2px solid #d3d3db', paddingLeft: '7%' }}
        >
          <div className={classes.contentInfoHolder}>
            <Grid container className={classes.heading}>
              <Grid item xs={3} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>Sides</b>
                </div>
              </Grid>
              <Grid item xs={3} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>UOM</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>
                  <b>Count</b>
                </div>
              </Grid>
              <Grid item xs={3} className={classes.flexDirection} style={{ marginLeft: '2rem' }}>
                <div className={classes.contentInfoHolder}>
                  <b>Cost</b>
                </div>
              </Grid>
              {closingInventory.sides.map((sideCI, index) => (
                <React.Fragment key={sideCI.side.id}>
                  <Grid
                    item
                    xs={3}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <div className={classes.centerVerticalContent}>{sideCI.side.label}</div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <div className={classes.centerVerticalContent}>
                      {getSideUOM(sideCI.side, 1)}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <div className={classes.contentInfoHolder}>
                      <TextField
                        name="ciCount"
                        type="number"
                        defaultValue={sideCI.value}
                        variant="outlined"
                        onChange={event => {
                          setClosingInventory(prevClosingInventory => {
                            const sides = [...prevClosingInventory.sides];

                            const side = {
                              ...sides[index],
                              value: parseFloat(event.target.value),
                            };

                            sides[index] = {
                              ...side,
                              cost: calculateCost(side),
                            };

                            const closingInventory = {
                              ...prevClosingInventory,
                              sides,
                            };

                            return closingInventory;
                          });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginLeft: '2rem' }}
                  >
                    <div className={classes.centerVerticalContent}>
                      <b>{sideCI.cost ? `$ ${toFixed10(sideCI.cost, 2)}` : ''}</b>
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div>
        <Button
          disabled={saveClosingInventoryLoading}
          onClick={() => {
            handleSaveClosingInventory();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default ClosingInventoryView;
