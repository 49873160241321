import { useLazyQuery } from '@apollo/client';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { FETCH_RECIPES } from '../../../../../../apollo/queries';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import generateRecipePdf from '../../../../../../utils/pdfUtils/generateRecipePdf';
import { prepareDataForPrepPdf } from '../helpers';

const PrintRecipe = ({
  categoryOptions = [],
  brandOptions = [],
  recipeTypeOptions = [],
  handleCancel,
}) => {
  const { setSnackbar } = useSnackbar();

  const [allItemsChecked, setAllItemsChecked] = useState(false);
  const [selectedPrepCategories, setSelectedPrepCategories] = useState([]);
  const [selectedLineCategories, setSelectedLineCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedRecipeTypes, setSelectedRecipeTypes] = useState([]);

  const [fetchRecipes, { loading, data: recipes }] = useLazyQuery(FETCH_RECIPES, {
    onCompleted: async data => {
      if (isEmpty(data?.viewer?.recipeConnection?.edges)) {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: 'No recipes found.',
          open: true,
        });
      } else {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'PDF Generated',
          open: true,
        });
      }
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const prepCategories = useMemo(() =>
    categoryOptions.filter(categoryOption => categoryOption.recipeTypeId === 1),
  );
  const showPrepCategories = useMemo(
    () => !!selectedRecipeTypes.find(recipeType => recipeType.value === 1),
    [selectedRecipeTypes],
  );
  const lineCategories = useMemo(() =>
    categoryOptions.filter(categoryOption => categoryOption.recipeTypeId === 2),
  );
  const showLineCategories = useMemo(
    () => !!selectedRecipeTypes.find(recipeType => recipeType.value === 2),
    [selectedRecipeTypes],
  );

  const handlePrint = useCallback(() => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Generating PDF...',
      open: true,
    });

    if (allItemsChecked) {
      fetchRecipes();
    } else {
      fetchRecipes({
        variables: {
          filter: {
            recipetypeId: { in: selectedRecipeTypes.map(item => item.value) },
            brandId: { in: selectedBrands.map(item => item.value) },
            inventoryitem: {
              inventoryitemcategoryId: {
                in: [
                  ...(showPrepCategories ? selectedPrepCategories : []),
                  ...(showLineCategories ? selectedLineCategories : []),
                ].map(item => item.value),
              },
            },
          },
        },
      });
    }
  }, [
    allItemsChecked,
    selectedRecipeTypes,
    selectedPrepCategories,
    selectedLineCategories,
    selectedBrands,
    showPrepCategories,
    showLineCategories,
  ]);

  const recipesData = useMemo(
    () => recipes?.viewer?.recipeConnection?.edges?.map(edge => prepareDataForPrepPdf(edge.node)),
    [recipes],
  );

  const handleOpenPdfClick = useCallback(() => {
    if (!isEmpty(recipesData)) {
      generateRecipePdf(recipesData);
    }
  }, [recipesData]);

  const handleOnAllItemsCheckboxToggle = useCallback(() => {
    setAllItemsChecked(prevChecked => !prevChecked);
    setSelectedPrepCategories([]);
    setSelectedLineCategories([]);
    setSelectedBrands([]);
    setSelectedRecipeTypes([]);
  }, []);

  const handleOnPrepCategoryChange = useCallback((checked, option) => {
    if (checked) {
      setSelectedPrepCategories(prevCategories => [...prevCategories, option]);
    } else {
      setSelectedPrepCategories(prevCategories =>
        prevCategories.filter(cat => cat.value !== option.value),
      );
    }

    setAllItemsChecked(false);
  }, []);

  const handleOnLineCategoryChange = useCallback((checked, option) => {
    if (checked) {
      setSelectedLineCategories(prevCategories => [...prevCategories, option]);
    } else {
      setSelectedLineCategories(prevCategories =>
        prevCategories.filter(cat => cat.value !== option.value),
      );
    }

    setAllItemsChecked(false);
  }, []);

  const handleOnBrandChange = useCallback((checked, option) => {
    if (checked) {
      setSelectedBrands(prevBrands => [...prevBrands, option]);
    } else {
      setSelectedBrands(prevBrands => prevBrands.filter(cat => cat.value !== option.value));
    }

    setAllItemsChecked(false);
  }, []);

  const handleOnRecipeTypeChange = useCallback((checked, option) => {
    if (checked) {
      setSelectedRecipeTypes(prevTypes => [...prevTypes, option]);
    } else {
      setSelectedRecipeTypes(prevTypes => prevTypes.filter(cat => cat.value !== option.value));
    }

    setAllItemsChecked(false);
  }, []);

  const isPrintButtonDisabled = useMemo(
    () =>
      !allItemsChecked &&
      isEmpty(selectedRecipeTypes) &&
      isEmpty(selectedPrepCategories) &&
      isEmpty(selectedLineCategories) &&
      isEmpty(selectedBrands),
    [
      allItemsChecked,
      selectedRecipeTypes,
      selectedPrepCategories,
      selectedLineCategories,
      selectedBrands,
    ],
  );

  const isPdfGenerated = useMemo(() => !isEmpty(recipesData), [recipesData]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          name="allItems"
          id="allItems"
          checked={allItemsChecked}
          control={
            <Checkbox
              checked={allItemsChecked}
              onClick={handleOnAllItemsCheckboxToggle}
              id="allItems"
              name="allItems"
              disabled={isPdfGenerated}
            />
          }
          label="All Recipes"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Brand</InputLabel>
          <Select
            label="Brand"
            multiple
            disabled={isPdfGenerated}
            defaultValue={[]}
            renderValue={() => selectedBrands.map(({ label }) => label).join(', ')}
          >
            {brandOptions?.map(option => {
              const checked = selectedBrands.map(cat => cat.value).includes(option.value);

              return (
                <MenuItem
                  onClick={() => handleOnBrandChange(!checked, option)}
                  key={option.value}
                  value={option}
                >
                  <Checkbox checked={checked} />
                  <ListItemText style={{ margin: '0' }} primary={option.label} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Recipe Type</InputLabel>
          <Select
            label="Recipe Type"
            disabled={isPdfGenerated}
            multiple
            defaultValue={selectedRecipeTypes}
            renderValue={() => selectedRecipeTypes.map(({ label }) => label).join(', ')}
          >
            {recipeTypeOptions?.map(option => {
              const checked = selectedRecipeTypes.map(cat => cat.value).includes(option.value);

              return (
                <MenuItem
                  onClick={() => handleOnRecipeTypeChange(!checked, option)}
                  key={option.value}
                  value={option}
                >
                  <Checkbox checked={checked} />
                  <ListItemText style={{ margin: '0' }} primary={option.label} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      {showPrepCategories ? (
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Cost Category</InputLabel>
            <Select
              label="Cost Category"
              disabled={isPdfGenerated}
              multiple
              defaultValue={selectedPrepCategories}
              renderValue={() => selectedPrepCategories.map(({ label }) => label).join(', ')}
            >
              {prepCategories?.map(option => {
                const checked = selectedPrepCategories.map(cat => cat.value).includes(option.value);

                return (
                  <MenuItem
                    onClick={() => handleOnPrepCategoryChange(!checked, option)}
                    key={option.value}
                    value={option}
                  >
                    <Checkbox checked={checked} />
                    <ListItemText style={{ margin: '0' }} primary={option.label} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      {showLineCategories ? (
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Line Category</InputLabel>
            <Select
              label="Line Category"
              disabled={isPdfGenerated}
              multiple
              defaultValue={selectedLineCategories}
              renderValue={() => selectedLineCategories.map(({ label }) => label).join(', ')}
            >
              {lineCategories?.map(option => {
                const checked = selectedLineCategories.map(cat => cat.value).includes(option.value);

                return (
                  <MenuItem
                    onClick={() => handleOnLineCategoryChange(!checked, option)}
                    key={option.value}
                    value={option}
                  >
                    <Checkbox checked={checked} />
                    <ListItemText style={{ margin: '0' }} primary={option.label} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            {isPdfGenerated ? (
              <Button onClick={handleOpenPdfClick}>Open Recipe Book</Button>
            ) : (
              <Button disabled={isPrintButtonDisabled || loading} onClick={handlePrint}>
                Generate PDF
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrintRecipe;
