import React from 'react';
import moment from 'moment-timezone';
import { Button } from '@material-ui/core';

import ConnectionTable from '../../../../blocks/ConnectionTable';
import { LOYALTY_SETTINGS } from '../../../../../apollo/queries/loyalty/loyaltySettings';
import { Modal } from '../../../../blocks';
import CreateLoyaltySettings from '../CreateLoyaltySettingsModal';

function LoyaltySettingsView({
  removeLoyaltysettingsLoading,
  setOpen,
  setLoyaltyUpdateData,
  onDeleteConfirmation,
  getWeekdays,
  getStatus,
  open,
  handleClose,
  loyaltyUpdateData,
  loadingTimezone,
  timezoneData,
  handleOnCreateLoyaltysettings,
  setConnectionVariables,
  createLoyaltyLoading,
  updateLoyaltysettingsLoading,
}) {
  return (
    <div id="loyaltysettings">
      <ConnectionTable
        setConnectionVariables={setConnectionVariables}
        query={LOYALTY_SETTINGS}
        initialQueryVariables={{
          sort: { created: 'ASC' },
          first: 15,
        }}
        title="Loyalty Settings"
        customActionsTop={(data, query) => {
          return (
            <Button onClick={() => setOpen(true)} disabled={removeLoyaltysettingsLoading}>
              New Rule
            </Button>
          );
        }}
        onTableRowClick={(row, data, variables) => {
          setLoyaltyUpdateData(row);
          setOpen(true);
        }}
        structureTable={data =>
          data?.viewer?.loyaltysettingsConnection?.edges.map(edge => {
            return {
              loyaltysettingsId: edge.node.loyaltysettingId,
              timezoneId: edge.node.timezoneId,
              label: edge.node.label,
              multiplier: edge.node.multiplier,
              startDate: moment(edge.node.startDate).format('MM/DD/YYYY'),
              endDate: edge.node.endDate ? moment(edge.node.endDate).format('MM/DD/YYYY') : '/',
              startTimeView: edge.node.startTime
                ? moment(edge.node.startTime, 'HH:mm A').format('LT')
                : '/',
              startTime: edge.node.startTime || '/',
              endTimeView: edge.node.endTime
                ? moment(edge.node.endTime, 'HH:mm A').format('LT')
                : '/',
              endTime: edge.node.endTime || '/',
              weekdays: getWeekdays(edge.node.weekdayConnection),
              weekdaysRaw: edge.node.weekdayConnection,
              weekdayConnection: edge.node.weekdayConnection,
              active: getStatus(edge.node.active),
              activeForChange: edge.node.active,
              brands: edge.node.brands,
              origins: edge.node.origins,
              couponaction:
                edge.node.loyaltysettingsCouponactionConnection.edges[0]?.node?.couponaction,
              coupontriggers: edge.node.loyaltysettingsCoupontriggerConnection.edges,
              items: edge.node.loyaltysettingsItemConnection.edges,
            };
          })
        }
        handleDeleteRow={onDeleteConfirmation}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this loyalty rule?"
        columns={[
          {
            title: 'Rule Name',
            field: 'label',
          },
          {
            title: 'Points Multiplier',
            field: 'multiplier',
          },
          { title: 'Start Date', field: 'startDate' },
          { title: 'End Date', field: 'endDate' },
          {
            title: 'Start Time',
            field: 'startTimeView',
          },
          {
            title: 'End Time',
            field: 'endTimeView',
          },
          {
            title: 'Available On Specific Day Of Week',
            field: 'weekdays',
            disableSort: true,
          },
          { title: 'Status', field: 'active', disableSort: true },
        ]}
      />
      <Modal
        open={open}
        handleClose={handleClose}
        title={loyaltyUpdateData ? 'Update Loyalty Rule' : 'New Loyalty Rule'}
      >
        <CreateLoyaltySettings
          loyaltyUpdateData={loyaltyUpdateData}
          handleClose={handleClose}
          loadingTimezone={loadingTimezone}
          timezones={timezoneData}
          handleOnCreateLoyaltysettings={handleOnCreateLoyaltysettings}
          createLoyaltyLoading={createLoyaltyLoading}
          updateLoyaltysettingsLoading={updateLoyaltysettingsLoading}
        />
      </Modal>
    </div>
  );
}
export default LoyaltySettingsView;
