import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress, TextField, Grid } from '@material-ui/core';
import { PermissionField, Select, SelectFactory } from '../../../../../blocks/index';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_COLORS, LOCATIONS, POS_ACCESS_LIST } from '../../../../../../apollo/queries';
import { CREATE_JOB, UPDATE_JOB } from '../../../../../../apollo/mutations/index';
import { LOCATIONS_BY_JOB } from '../../../../../../apollo/queries';
import { MUTATION_NAME } from '../../../../../../constants';
import Permission from '../../../../../blocks/Permission';
import { compact } from 'lodash';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const ManageJobForm = ({
  tableState,
  existingJob,
  permissions,
  closeModal,
  mutationPermissions,
  accessToMutations,
  orderKeys,
}) => {
  const { register, handleSubmit, errors, control } = useForm();
  const { setSnackbar } = useSnackbar();

  const [includedLocations, setIncludedLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [posRoleId, setPosRoleId] = useState(existingJob?.posRole?.posRoleId);
  const [posRoleError, setPosRoleError] = useState(false);

  const isNewJob = useMemo(() => !existingJob?.jobId, [existingJob?.jobId]);
  const accessToSubmit = useMemo(
    () =>
      isNewJob
        ? Math.min(
            mutationPermissions?.createMutationPermission?.access,
            accessToMutations?.[MUTATION_NAME.createJobMutationInput],
          )
        : Math.min(
            mutationPermissions?.updateMutationPermission?.access,
            accessToMutations?.[MUTATION_NAME.updateJobMutationInput],
          ),
    [isNewJob, mutationPermissions, accessToMutations],
  );

  const { data, loading } = useQuery(FETCH_COLORS, {
    variables: {
      first: 15,
    },
  });

  const { data: initialLocations, loading: loadingInitialLocations } = useQuery(LOCATIONS_BY_JOB, {
    skip: !existingJob?.jobId,
    variables: {
      filter: {
        jobId: {
          eq: existingJob?.jobId,
        },
      },
    },
  });

  const [createJob, { loading: createLoading }] = useMutation(CREATE_JOB);
  const [updateJob, { loading: updateLoading }] = useMutation(UPDATE_JOB);

  const colors = data?.viewer?.colorConnection?.edges.map(color => ({
    label: color.node.label,
    value: color.node.colorId,
  }));

  useEffect(() => {
    if (existingJob) {
      if (initialLocations) {
        setIncludedLocations(
          initialLocations.viewer?.jobConnection?.edges[0]?.node?.locationJobConnection?.edges?.map(
            edge => {
              let loc = { ...edge?.node?.location };
              loc.label = `${loc.label} - ${loc.storeNumber}`;
              loc.id = loc.locationId;
              return loc;
            },
          ) || [],
        );

        setIsLoading(false);
      }
    } else {
      setIncludedLocations([]);

      setIsLoading(false);
    }
  }, [initialLocations, existingJob]);

  const onSubmit = async formData => {
    if (!posRoleId) {
      return setPosRoleError(true);
    }

    setPosRoleError(false);

    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: existingJob?.jobId ? 'Updating job...' : 'Creating job...',
      open: true,
    });

    // get all locationIds from the hierarchy
    const locationIds = includedLocations?.map(location => location.id || location) || [];

    if (existingJob?.jobId) {
      updateJob({
        variables: {
          jobId: existingJob.jobId,
          label: formData.label,
          locationIds,
          colorId: formData.color,
          posRoleId,
          orderKey: formData.orderKey,
        },
        refetchQueries: [{ query: LOCATIONS_BY_JOB, variables: { ...tableState } }],
        fetchPolicy: 'no-cache',
      })
        .then(() => {
          setSnackbar({
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Job updated',
            open: true,
          });

          closeModal();
        })
        .catch(error => {
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
            open: true,
          });
        });
    } else {
      createJob({
        variables: {
          label: formData.label,
          locationIds,
          colorId: formData.color,
          posRoleId,
        },
        refetchQueries: [{ query: LOCATIONS_BY_JOB, variables: { ...tableState } }],
        fetchPolicy: 'no-cache',
      })
        .then(() => {
          setSnackbar({
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Job created',
            open: true,
          });

          closeModal();
        })
        .catch(error => {
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
            open: true,
          });
        });
    }
  };

  const sortedOrderKeys = Object.values(orderKeys)
    .map(key => ({ label: key, value: key }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  if (loading || loadingInitialLocations || isLoading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container xs={12} spacing={4} style={{ marginBottom: '0.5rem' }}>
        <Grid container item xs={8} spacing={4}>
          <Permission access={permissions?.label}>
            <Grid item xs={12}>
              <PermissionField
                Component={TextField}
                fullWidth
                defaultValue={existingJob?.label}
                name="label"
                label="Job name"
                inputRef={register({ required: true })}
                error={!!errors.label}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.color?.__typename}>
            <Grid item xs={12}>
              <PermissionField
                Component={Select}
                width="100%"
                defaultValue={existingJob?.color?.colorId || colors[0].value}
                label="Colors"
                id="color"
                variant="outlined"
                items={colors}
                control={control}
              />
            </Grid>
          </Permission>
          <Permission access={permissions?.posRole?.posRoleId}>
            <Grid xs={12} item>
              <PermissionField
                Component={SelectFactory}
                label={'POS Access Role'}
                placeholder={'Select POS Access'}
                fullWidth
                query={POS_ACCESS_LIST}
                style={{ width: '100%' }}
                multiple={false}
                disableCloseOnSelect={false}
                renderCheckBox={false}
                structureData={data => {
                  return data?.viewer?.posRoleConnection?.edges?.map(edge => ({
                    label: edge?.node?.label,
                    id: edge.node?.posRoleId,
                    value: edge.node?.posRoleId,
                  }));
                }}
                onSelect={value => {
                  setPosRoleId(value?.id || null);
                }}
                helperText={posRoleError ? 'POS Access Role is required' : ''}
                error={posRoleError}
                name="posRoleId"
                defaultValue={{
                  label: existingJob?.posRole?.label,
                  id: existingJob?.posRole?.posRoleId,
                  value: existingJob?.posRole?.posRoleId,
                }}
              />
            </Grid>
          </Permission>
          {existingJob?.orderKey >= 0 ? (
            <Permission access={permissions?.orderKey}>
              <Grid item xs={3}>
                <PermissionField
                  Component={Select}
                  width="100%"
                  defaultValue={existingJob?.orderKey}
                  label="Order Key"
                  id="orderKey"
                  variant="outlined"
                  items={sortedOrderKeys}
                  control={control}
                />
              </Grid>
            </Permission>
          ) : null}
        </Grid>
        <Permission access={permissions?.locationJobConnection?.edges?.node?.location?.__typename}>
          <Grid container item xs={4}>
            <PermissionField
              Component={SelectFactory}
              label={'Locations'}
              placeholder={'Select Locations'}
              query={LOCATIONS}
              defaultValue={includedLocations}
              disableCloseOnSelect={true}
              multiple
              fullWidth
              renderCheckBox
              structureData={data => {
                return data?.viewer?.locationConnection?.edges.map((location, index) => {
                  return {
                    label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
                    id: location.node.locationId,
                    index,
                  };
                });
              }}
              onSelect={values => {
                setIncludedLocations(values.map(loc => loc.id));
              }}
            />
          </Grid>
        </Permission>
      </Grid>
      <Permission access={accessToSubmit}>
        <Grid xs={12}>
          <Button disabled={createLoading || updateLoading} type="submit">
            Submit
          </Button>
        </Grid>
      </Permission>
    </form>
  );
};

export default ManageJobForm;
