import { timezoneFragment } from '../../fragments/timezoneFragment';
const { gql } = require('@apollo/client');

export const FETCH_DISCOUNT = gql`
  query discount($id: ID!, $storeTypeId: Int) {
    viewer {
      id
      coupon(id: $id) {
        id
        couponId
        code
        description
        coupontypeId
        label
        internalLabel
        created
        isStackable
        maxUses
        usesLeft
        maxUsesPerUser
        timezoneId
        isSmart
        expired
        started
        finished
        excludedOnAllBoostLocations
        couponWeekdayConnection {
          edges {
            node {
              id
              weekdayId
            }
          }
        }
        couponActionConnection {
          edges {
            node {
              couponId
              couponactionId
              valueOff
              target
            }
          }
        }
        couponTriggerConnection {
          edges {
            node {
              id
              coupontrigerId
              valueOff
              valueType
              triggerGroup
            }
          }
        }
        couponItemConnection {
          edges {
            node {
              id
              item {
                id
                label
                itemId
                subcategoryItemConnection {
                  edges {
                    node {
                      id
                      subcategoryId
                      subcategory {
                        categoryId
                        menuId
                      }
                    }
                  }
                }
              }
            }
          }
        }
        couponSubcategoryConnection {
          edges {
            node {
              id
              subcategory {
                id
                subcategoryId
                label
              }
            }
          }
        }
        couponHandoffConnection {
          edges {
            node {
              id
              handoff {
                id
                handoffId
              }
            }
          }
        }
        couponLocationConnection {
          edges {
            node {
              id
              excluded
              location {
                id
                label
                storeNumber
              }
            }
          }
        }
        couponPlatformConnection {
          edges {
            node {
              id
              platformId
            }
          }
        }
        couponBrandConnection {
          edges {
            node {
              id
              brandId
            }
          }
        }
      }
      locationConnection(filter: { storeTypeId: { eq: $storeTypeId } }) {
        edges {
          node {
            id
            label
            storeNumber
          }
        }
      }
      couponTriggerConnection {
        edges {
          node {
            id
            couponTriggerId
            label
          }
        }
      }
      platformConnection {
        edges {
          node {
            id
            label
            platformId
          }
        }
      }
      brandConnection {
        edges {
          node {
            id
            label
            brandId
          }
        }
      }
      timezoneConnection {
        edges {
          node {
            ...TimezoneData
          }
        }
      }
      handoffConnection {
        edges {
          node {
            id
            handoffId
            label
          }
        }
      }
    }
  }
  ${timezoneFragment.timezone}
`;
