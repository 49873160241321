import { gql } from "@apollo/client";

export const UPDATE_PAYMENT_PROCESSOR = gql`
  mutation updatePaymentProcessor($input: UpdatePaymentProcessorInput!) {
    updatePaymentProcessor(input: $input) {
      devicesettingEdge {
        id
        deviceId
        gatewayId
      }
    }
  }
`;