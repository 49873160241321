import React, { useState } from "react";
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import { Elements } from "../../../../../../blocks";
import { Controller } from "react-hook-form";

function UsageLimit({ control }) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Controller
          control={control}
          name="discountRestrictions.usageLimit.enabled"
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "2rem" }}>
                <Elements.Switch
                  ref={ref}
                  checked={value}
                  handleSwitchChange={(isChecked) => {
                    setShowOptions(isChecked);
                    onChange(isChecked);
                  }}
                />
              </div>
              <div>
                <h3>Usage limit</h3>
              </div>
            </div>
          )}
        />
      </div>
      {showOptions && (
        <div style={{ display: "flex", gap: "2rem" }}>
          <div
            className="row-1"
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "1",
              gap: "1rem",
            }}
          >
            <Controller
              control={control}
              name="discountRestrictions.usageLimit.options.maxNumberOfUses"
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                  type="number"
                  label="Maximal Number of uses"
                  placeholder="Maximal Number of uses"
                />
              )}
            />
            <Controller
              control={control}
              name="discountRestrictions.usageLimit.options.usageLimitPerUser"
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                  type="number"
                  label="Usage limit per user"
                  placeholder="Usage limit per user"
                />
              )}
            />
          </div>
          {/* <div
            className="row-2"
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
              gap: "1rem",
            }}
          >
            <Controller
              control={control}
              name="discountRestrictions.usageLimit.options.usageLimitPerCoupon"
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                  type="number"
                  placeholder="Usage limit per coupon (Unlimited usage)"
                />
              )}
            />
            <Controller
              control={control}
              name="discountRestrictions.usageLimit.options.discountNeedsManagerApproval"
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.checked)}
                      value={value}
                    />
                  }
                  label="Discount needs to be approved by Manager"
                />
              )}
            />
          </div> */}
        </div>
      )}
    </div>
  );
}

export default UsageLimit;
