import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { CircularProgress } from '@material-ui/core';

import { ScheduleEmployeesStyles } from './styles';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import Permission from '../../../../../blocks/Permission';
import DataTable from '../../../../../blocks/DataTable';
import { MUTATION_NAME } from '../../../../../../constants';

const ScheduleEmployees = ({
  employees,
  selectedWeek,
  setScheduleDialogData,
  setIsScheduleModalVisible,
  setOpenWarningMutationName,
  selectedLocationState,
}) => {
  const classes = ScheduleEmployeesStyles();

  const { setSnackbar } = useSnackbar();

  return (
    <div className={classes.employeeList}>
      <Permission
        access={Math.min(
          employees?.viewer?.loginConnection?.permissions?.person?.firstName,
          employees?.viewer?.loginConnection?.permissions?.person?.lastName,
        )}
      >
        {isEmpty(employees) ? (
          <CircularProgress />
        ) : (
          <DataTable
            data={employees}
            structureTable={data =>
              data?.viewer?.loginConnection?.edges.map(loginEdge => {
                const person = loginEdge.node.person;
                return {
                  name: `${person?.firstName} ${person?.lastName}`,
                  person: person,
                };
              })
            }
            columns={[{ title: 'Employee', field: 'name' }]}
            onTableRowClick={tableRow => {
              if (
                moment(selectedWeek.ts)
                  .add(6, 'day')
                  .isSameOrAfter(moment().startOf('day'))
              ) {
                setScheduleDialogData({
                  person: tableRow.person,
                  location: selectedLocationState,
                });
                setIsScheduleModalVisible(true);
                setOpenWarningMutationName(MUTATION_NAME.CreateScheduleMutationInput);
              } else {
                setSnackbar({
                  type: 'error',
                  text: "Can't add schedule for passed date range.",
                  open: true,
                });
              }
            }}
          />
        )}
      </Permission>
    </div>
  );
};

export default ScheduleEmployees;
