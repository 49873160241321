const generateStoreAbbreviation = location => {
  return {
    abbreviation: `${
      location?.address?.state?.abbreviation
    }-${location?.storeNumber?.toString().padStart(4, '0')}`,
    id: location?.id,
  };
};

export default generateStoreAbbreviation;
