import { gql } from '@apollo/client';

const CREATE_INGREDIENT = gql`
  mutation CreateIngredientMutationND($input: CreateIngredientMutationNDInput!) {
    createIngredientND(input: $input) {
      viewer {
        id
      }
      ingredientEdge {
        node {
          id
          ingredientId
          inventoryitem {
            label
            description
            cost
          }
        }
      }
    }
  }
`;

export default CREATE_INGREDIENT;
