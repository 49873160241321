import { gql } from '@apollo/client';

const UPDATE_STORAGE_AREA = gql`
  mutation UPDATE_STORAGE_AREA($input: UpdateStorageAreaInput!) {
    updateStorageArea(input: $input) {
      viewer {
        id
      }
      newStorage {
        id
        created
        storageId
        status
        label
      }
    }
  }
`;

export default UPDATE_STORAGE_AREA;
