import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
  IconButton,
  Paper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ConfirmationModal = ({
  open,
  confirmAction,
  handleClose,
  text,
  disabled,
  buttonText = 'OK',
  title = 'Confirmation',
  showCancel = true,
}) => {
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (close && !disabled) {
      handleClose();
      setClose(false);
    }
  }, [close, disabled, handleClose]);

  return (
    <Dialog
      disableEnforceFocus
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      scroll="paper"
      PaperComponent={Paper}
      disableBackdropClick
    >
      <Grid container>
        <Grid container item xs={12} justify="space-between">
          <DialogTitle disableTypography id="Confirmation dialog">
            {title}
          </DialogTitle>
          <DialogActions>
            <IconButton onClick={handleClose} aria-label="close" disabled={disabled}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </Grid>
        <Grid container>
          <DialogContent>
            <Grid container item xs={12}>
              <Grid item>
                <Typography>{text || 'Are you sure?'}</Typography>
              </Grid>
              <Grid container item>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: showCancel ? 'space-between' : 'center',
                    width: '100%',
                    marginTop: '2rem',
                  }}
                >
                  {showCancel ? (
                    <Button
                      disabled={disabled}
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  ) : null}

                  <Button
                    disabled={disabled}
                    onClick={() => {
                      if (confirmAction) {
                        confirmAction();
                      }

                      setClose(true);
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmationModal;
