import { gql } from '@apollo/client';

const ACTIVATE_LOCATION_EMPLOYEE = gql`mutation activateLocationEmployee($input: ActivationLocationPersonMutationInput!) {
  activationLocationPerson(input:$input){
    activationLocationPersonId
  }
}
`;

export default ACTIVATE_LOCATION_EMPLOYEE;
