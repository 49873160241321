import { gql } from '@apollo/client';

const DISCONNECT_FROM_PAYPAL = gql`
  mutation disconnectFromPayPal($input: PayPalOffboardingMutationInput!) {
    payPalOffboarding(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default DISCONNECT_FROM_PAYPAL;
