import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  switchControlLabelTextOn: {
    fontSize: "0.8rem",
    position: "absolute",
    left: ".6rem",
    top: "calc(50% - 0.58rem)",
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  switchControlLabelTextOff: {
    fontSize: "0.8rem",
    position: "absolute",
    right: ".7rem",
    top: "calc(50% - 0.58rem)",
    fontWeight: theme.typography.fontWeightSemiBold,
    color: "rgba(0,0,0,.5)",
  },
});

const LabelStyle = (theme) => ({
  root: {
    position: "relative",
    marginRight: 0,
    marginLeft: 0,
  },
});

const SwitchStyles = makeStyles(Styles);
const LabelStyles = makeStyles(LabelStyle);

export { SwitchStyles, LabelStyles };
