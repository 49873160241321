import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, CircularProgress, makeStyles, Typography, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import FETCH_LOCATION_STORAGES from '../../../../../apollo/queries/locations/fetchLocationStorages';
import PaperMain from '../../../../blocks/PaperMain';
import InventoryCountForm from './InventoryCountForm';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { paths, SNACKBAR_STATUS } from '../../../../../constants';
import { ConfirmationModal } from '../../../../blocks';
import { useMutation } from '@apollo/client';
import SAVE_LOCATION_STORAGE_INVENTORY_COUNT from '../../../../../apollo/mutations/inventoryCount/saveLocationStorageInventoryCount';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { useFieldArray, useForm } from 'react-hook-form';
import { omit, sortBy } from 'lodash-es';
import { formatNumberStringToFloat } from '../../../helpers';

const useStyles = makeStyles(theme => ({
  paper: {
    '&.MuiPaper-root': {
      backgroundColor: theme.colors.boxBackground,
      boxShadow: theme.boxShadowBase,
      borderRadius: 0,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      width: '100%',
      zIndex: 1,

      [theme.breakpoints.down('xs')]: {
        backgroundColor: theme.colors.boxBackground,
        borderRadius: 0,
        marginBottom: theme.spacing(3),
        width: '100%',
        zIndex: 1,
        boxShadow: 'none',
        margin: '-16px',
        width: 'calc(100% + 32px)',
        padding: '16px',
        height: 'calc(100vh - 100px)',
      },
    },
  },
  buttonsContainer: {
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      bottom: '-16px',
      zIndex: 1200,
      position: 'sticky',
      background: 'white',
      borderTop: '2px solid #D3D3DB',
      margin: '0 -16px',
      padding: '8px 4px 4px',
      width: 'calc(100% + 32px)',
    },
  },
  button: {
    minWidth: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const StorageArea = () => {
  const classes = useStyles();

  const { locationId: ids, locationStorageId } = useParams();
  const locationId = ids.split('-')[0];

  const { push } = useHistory();
  const { setSnackbar } = useSnackbar();

  const {
    register,
    errors,
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    inventoryItems: [],
  });
  const { fields } = useFieldArray({ control, name: 'inventoryItems' });

  const { data: storageAreaData, loading, error } = useQuery(FETCH_LOCATION_STORAGES, {
    skip: !locationId || !locationStorageId,
    variables: {
      first: 1,
      filter: {
        locationId: { eq: locationId },
        locationStorageId: { eq: locationStorageId },
      },
    },
    fetchPolicy: 'network-only',
  });

  const [saveLocationStorageInventorycount, { loading: submitting }] = useMutation(
    SAVE_LOCATION_STORAGE_INVENTORY_COUNT,
    {
      onCompleted: () => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Inventory Count saved',
          open: true,
        });

        push(paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS.replace(':locationId', ids));
      },
      onError: e => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        });
      },
      refetchQueries: [
        {
          query: FETCH_LOCATION_STORAGES,
          variables: {
            filter: {
              locationId: { eq: locationId },
              status: { eq: 1 },
            },
          },
        },
      ],
    },
  );

  const storageArea = useMemo(
    () =>
      (!isEmpty(storageAreaData?.viewer?.locationStorageConnection?.edges) &&
        storageAreaData.viewer.locationStorageConnection.edges[0].node) ||
      null,
    [storageAreaData],
  );

  const inventoryCountId = useMemo(() => storageArea?.location?.inventorycount?.inventorycountId, [
    storageArea,
  ]);

  const submitted = useMemo(() => storageArea?.location?.inventoryCountStatus === 'DONE', [
    storageArea,
  ]);

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [tempFormData, setTempFormData] = useState();

  useEffect(() => {
    const savedItems =
      (storageArea?.location?.inventorycount?.inventorycountLocationStorageInventoryitemConnection
        ?.edges?.length &&
        storageArea.location.inventorycount.inventorycountLocationStorageInventoryitemConnection.edges.map(
          edge => edge.node,
        )) ||
      [];

    reset({
      inventoryItems:
        sortBy(
          storageArea?.locationStorageInventoryitemConnection?.edges?.map(edge => ({
            ...omit(edge.node, ['id']),
            measures:
              edge.node.inventoryitem?.inventoryitemMeasureConnection?.edges?.map(measureEdge => {
                const savedItem = savedItems?.find(
                  item =>
                    item.locationStorageInventoryitemId ===
                      edge.node.locationStorageInventoryitemId &&
                    item.measureId === measureEdge.node.measureId,
                );
                return {
                  measureId: measureEdge.node.measureId,
                  label: `${measureEdge.node.measure?.label} (${measureEdge.node.measure?.abbreviation})`,
                  quantity: savedItem ? savedItem?.quantity : '',
                  thresholdValue: measureEdge.node.measure.thresholdValue,
                };
              }) || [],
          })),
          'orderKey',
        ) || [],
    });
  }, [storageArea]);

  const handleCloseSubmitModal = useCallback(() => {
    setIsSubmitModalOpen(false);
  }, []);

  const onSave = useCallback(
    formData => {
      const input = {
        inventorycountId: inventoryCountId,
        locationStorageInventoryitems: formData.inventoryItems.map(inventoryItem => ({
          ...inventoryItem,
          measures: inventoryItem.measures?.map(measure => ({
            ...measure,
            quantity: measure.quantity ? formatNumberStringToFloat(measure.quantity) : 0,
          })),
        })),
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Saving Inventory Count...',
        open: true,
      });

      saveLocationStorageInventorycount({
        variables: {
          input,
        },
      });
    },
    [locationStorageId, setError, clearErrors, inventoryCountId],
  );

  const onSubmitModalConfirm = useCallback(() => {
    setIsSubmitModalOpen(false);
    onSave(tempFormData);
  }, [onSave, tempFormData]);

  const handleOnBackClick = useCallback(() => {
    push(paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS.replace(':locationId', ids));
  }, [locationId]);

  const onSaveClick = useCallback(formData => {
    setIsSubmitModalOpen(true);
    setTempFormData(formData);
  }, []);

  return (
    <PaperMain className={classes.paper}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <div>Error</div>
      ) : !storageArea ? (
        <div>Storage not found</div>
      ) : (
        <>
          <form style={{ minHeight: 'calc(100% - 66px)' }}>
            <InventoryCountForm
              submitted={submitted}
              storageArea={storageArea}
              disabled={submitting || submitted}
              inventoryCountId={inventoryCountId}
              control={control}
              errors={errors}
              fields={fields}
              register={register}
              setValue={setValue}
            />
          </form>
          <Grid container spacing={2} className={classes.buttonsContainer}>
            {submitted ? (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    maxWidth: '600px',
                    color: '#DD1416',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  * Inventory Count already submitted
                </Typography>
              </Grid>
            ) : (
              <Grid item className={classes.button}>
                <Button
                  disabled={submitting}
                  onClick={handleSubmit(onSaveClick)}
                  style={{ minWidth: '100%' }}
                >
                  Save
                </Button>
              </Grid>
            )}
            <Grid item className={classes.button}>
              <Button
                disabled={submitting}
                style={{ minWidth: '100%' }}
                variant="outlined"
                onClick={handleOnBackClick}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <ConfirmationModal
        handleClose={handleCloseSubmitModal}
        open={isSubmitModalOpen}
        text="Are you sure you want to submit? Quantities for all products left blank will be set to 0."
        title="Submit"
        confirmAction={onSubmitModalConfirm}
        buttonText="Continue"
      />
    </PaperMain>
  );
};

export default StorageArea;
