import { gql } from '@apollo/client';

const FETCH_MENU_LIST = gql`
  query fetchMenuList {
    viewer {
      id
      menuConnection(fieldGroupOnly: 7) {
        edges {
          node {
            id
            menuId
            label
            menuCategoryConnection {
              edges {
                node {
                  id
                  category {
                    id
                    categoryId
                    label
                    subcategoryConnection(
                      sort: { orderKey: ASC }
                      filter: { showUnavailableOnline: { eq: true } }
                    ) {
                      edges {
                        node {
                          id
                          subcategoryId
                          menuId
                          label
                          isAvailableOnline
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_MENU_LIST;
