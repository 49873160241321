import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { FETCH_STORAGES } from '../../../../../../../apollo/queries';
import { SelectFactory } from '../../../../../../blocks';

const BasicInfo = ({ register, errors, control, countSheet, statusOptions }) => {
  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={12} sm={4}>
        <TextField
          autoFocus
          inputRef={register({
            required: 'true',
          })}
          fullWidth
          type="text"
          label="Name"
          error={!!errors.customLabel}
          helperText={errors.customLabel?.type === 'required' && 'Name is required'}
          name="customLabel"
          variant="outlined"
          placeholder="Name"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name={'storage'}
          defaultValue={countSheet?.storage}
          rules={{ required: true }}
          render={props => (
            <SelectFactory
              customProps={props}
              error={!!errors?.storage}
              helperText={!!errors?.storage && 'Storage Area is required'}
              label={'Storage Area'}
              fullWidth
              placeholder={'Search Storage Areas'}
              query={FETCH_STORAGES}
              filter={{ status: { eq: true } }}
              renderCheckBox={false}
              defaultValue={countSheet?.storage}
              disableCloseOnSelect={false}
              disabled={countSheet?.locationStorageId}
              multiple={false}
              structureData={data => {
                return data?.viewer?.storageConnection?.edges.map(edge => {
                  return {
                    label: edge.node.label,
                    id: edge.node.storageId,
                  };
                });
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel error={!!errors?.status}>Status</InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            id="status"
            name="status"
            label="Status"
            defaultValue=""
            placeholder="Status"
            error={!!errors?.status}
            as={
              <Select label="Status" multiple={false} error={!!errors?.status}>
                {statusOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
          />
          {errors?.status?.type === 'required' ? (
            <FormHelperText error>Status is required</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default BasicInfo;
