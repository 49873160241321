import React, { useState, useEffect, useMemo } from 'react';
import { Button, TextField, Grid, Typography, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import PermissionField from '../../../../../blocks/PermissionField';
import Permission from '../../../../../blocks/Permission';
import { MUTATION_NAME } from '../../../../../../constants';
import { SalesProjectionsStyles } from './styles';

const DAILY_SALES_COMMITMENTS_TOOPTIP_TEXT =
  'Daily SSS/Commitments pulled from Smoke Stack for the selected date range.';
const DAILY_SALES_TRENDS_TOOLTIP_TEXT =
  'Weighted average of daily sales for the prior 4 weeks from the current date.';
const AUV_WEIGHT_TRENDS_TOOLTIP_TEXT =
  'Average percentage of sales per day of week for the prior 4 weeks from the current date.';
const DAILY_SALES_PROJECTED_TOOLTIP_TEXT =
  'Owner/Operator projected sales per day. This defaults to the Daily Sales Trend, and can be overridden';
const PERCENT_PROJ_V_COMMIT_TOOLTIP_TEXT =
  'Week Total of Projected Sales compared to Week Total of Daily Sales Commitments for the selected date range shown as a reference.';

const SalesProjectionsView = ({
  mutationPermissions,
  handleSubmit,
  onSubmit,
  weeklyScheduleProjections,
  dailySalesCommitments,
  smokestackWeekTotal,
  dailySalesTrends,
  totalWeekSalesTrend,
  auvSalesTrendWeights,
  totalAuvPercent,
  sortedWeekdays,
  onInputChange,
  weeklySaleProjectionsData,
  userSetProjectionValues,
  register,
  adjustedWeeklyTotal,
  submitProjectionIsDisabled,
  formattedCurrency,
  accessToMutations,
  insertWeeklySaleProjectionsLoading,
  updateWeeklySaleProjectionsLoading,
}) => {
  const classes = SalesProjectionsStyles();

  const [projVCommit, setProjVCommit] = useState(100);
  const [projCommitColor, setProjCommitColor] = useState();
  const [projCommitMessage, setProjCommitMessage] = useState('');

  const createWeeklySaleCommitmentPermission = useMemo(
    () =>
      mutationPermissions?.find(
        mutationPermission => mutationPermission.label === 'createWeeklySaleCommitment',
      ),
    [mutationPermissions],
  );
  const updateWeeklySaleCommitmentPermission = useMemo(
    () =>
      mutationPermissions?.find(
        mutationPermission => mutationPermission.label === 'updateWeeklySaleCommitment',
      ),
    [mutationPermissions],
  );

  useEffect(() => {
    let percentage = Math.round(adjustedWeeklyTotal * 100) / Math.round(smokestackWeekTotal);
    if (!isFinite(percentage)) {
      percentage = 0;
    }

    const overUnder = 100 - Math.round(percentage * 100);
    let message = '';
    let color = '';

    if (percentage === 1 || percentage === 0) {
      if (adjustedWeeklyTotal === 0 && smokestackWeekTotal > 0) {
        color = '#d40e37';
        message = 'You are projecting 100% under your commitment.';
      } else if (adjustedWeeklyTotal > 0 && smokestackWeekTotal === 0) {
        color = '#000000';
        message = 'Your commitments are $0.';
      } else {
        color = '#000000';
        message = 'You are projecting exactly as your commitment.';
      }
    } else if (percentage < 1) {
      color = '#d40e37';
      message = `You are projecting ${Math.abs(overUnder)}% under your commitment.`;
    } else {
      color = '#37c78a';
      message = `You are projecting ${Math.abs(overUnder)}% over your commitment.`;
    }

    setProjVCommit(Math.round(percentage * 100));
    setProjCommitColor(color);
    setProjCommitMessage(message);
  }, [adjustedWeeklyTotal]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.projectionContainer}>
      <Permission
        access={
          weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection?.permissions
            ?.scheduleweeklysalesprojectionId
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid item xs={2} alignItems="center">
              <strong className={classes.title}>Daily Sales Commitments</strong>
              <div className={classes.tooltip}>
                <Tooltip title={DAILY_SALES_COMMITMENTS_TOOPTIP_TEXT} aria-label="add">
                  <InfoIcon />
                </Tooltip>
              </div>
            </Grid>

            {Object.keys(dailySalesCommitments)?.map(day => (
              <Grid item xs={1} direction="column">
                <strong className={classes.data}>{day}</strong>
                <span className={classes.data}>{dailySalesCommitments[day]}</span>
              </Grid>
            ))}

            <Grid item xs={1} direction="column">
              <strong className={classes.data}>Week total</strong>
              <span className={classes.data}>{formattedCurrency(smokestackWeekTotal)}</span>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={2} direction="column" justify="center">
              <div className={classes.tooltipDiv}>
                <strong className={classes.title}>Daily Sales Trends</strong>
                <Tooltip
                  title={DAILY_SALES_TRENDS_TOOLTIP_TEXT}
                  aria-label="add"
                  className={classes.tooltip}
                >
                  <InfoIcon />
                </Tooltip>
              </div>
              <strong className={classes.title}>(Avg. based on last 4 weeks of sales)</strong>
            </Grid>

            {Object.keys(dailySalesTrends)?.map(day => (
              <Grid item xs={1} direction="column">
                <strong className={classes.data}>{day}</strong>
                <span className={classes.data}>{dailySalesTrends[day]}</span>
              </Grid>
            ))}

            <Grid item xs={1} direction="column">
              <strong className={classes.data}>Week total</strong>
              <span className={classes.data}>{formattedCurrency(totalWeekSalesTrend * 100)}</span>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={2} alignItems="center">
              <strong className={classes.title}>AUV Weight Trends</strong>
              <div className={classes.tooltip}>
                <Tooltip title={AUV_WEIGHT_TRENDS_TOOLTIP_TEXT} aria-label="add">
                  <InfoIcon />
                </Tooltip>
              </div>
            </Grid>

            {Object.keys(auvSalesTrendWeights)?.map(day => (
              <Grid item xs={1} direction="column">
                <strong className={classes.data}>{day}</strong>
                <span className={classes.data}>{auvSalesTrendWeights[day]}%</span>
              </Grid>
            ))}

            <Grid item xs={1} direction="column">
              <strong className={classes.data}>Week total</strong>
              <span className={classes.data}>{totalAuvPercent}%</span>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Permission
              access={
                weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection
                  ?.permissions?.scheduleweeklysalesprojectionId
              }
            >
              <Grid item xs={2} alignItems="center">
                <strong className={classes.title}>Daily Sales Projected</strong>
                <div className={classes.tooltip}>
                  <Tooltip title={DAILY_SALES_PROJECTED_TOOLTIP_TEXT} aria-label="add">
                    <InfoIcon />
                  </Tooltip>
                </div>
              </Grid>
            </Permission>

            {sortedWeekdays.map((currentValue, index) => {
              return (
                <Permission
                  access={
                    weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection
                      ?.permissions?.scheduleweeklysalesprojectionId
                  }
                >
                  <Grid item xs={1} className={classes.input}>
                    <PermissionField
                      Component={TextField}
                      onChange={onInputChange}
                      key={index + 1}
                      fullWidth={true}
                      name={index + 1}
                      label={currentValue}
                      inputRef={register}
                      className={classes.data}
                      defaultValue={
                        weeklySaleProjectionsData?.scheduleweeklysalesprojectionWeekday?.length > 0
                          ? Number(userSetProjectionValues[currentValue]?.replace(/[^0-9.-]+/g, ''))
                          : Number(dailySalesTrends[currentValue]?.replace(/[^0-9.-]+/g, ''))
                      }
                    />
                  </Grid>
                </Permission>
              );
            })}
            <Grid item xs={1} direction="column">
              <strong>Adj. Week Total $</strong>
              <span className={classes.data}>{formattedCurrency(adjustedWeeklyTotal * 100)}</span>
            </Grid>
            <Grid item xs={1} direction="column">
              <div className={classes.tooltipDiv}>
                <strong>% Proj. v Commit.</strong>
                <Tooltip
                  title={PERCENT_PROJ_V_COMMIT_TOOLTIP_TEXT}
                  aria-label="add"
                  className={classes.tooltip}
                >
                  <InfoIcon />
                </Tooltip>
              </div>
              <span className={classes.data} style={{ color: projCommitColor }}>
                {projVCommit}%
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="flex-end"
          className={classes.projectionContainer}
        >
          <Grid item xs={10} justify="flex-end" alignItems="center">
            <Typography align="right" style={{ color: projCommitColor }}>
              {projCommitMessage}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Permission
              access={
                weeklySaleProjectionsData
                  ? Math.min(
                      updateWeeklySaleCommitmentPermission?.access,
                      accessToMutations?.[
                        MUTATION_NAME.UpdateScheduleweeklysalesprojectionMutationInput
                      ],
                    )
                  : Math.min(
                      createWeeklySaleCommitmentPermission?.access,
                      accessToMutations?.[
                        MUTATION_NAME.CreateScheduleweeklysalesprojectionMutationInput
                      ],
                    )
              }
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    type="submit"
                    disabled={
                      submitProjectionIsDisabled ||
                      insertWeeklySaleProjectionsLoading ||
                      updateWeeklySaleProjectionsLoading
                    }
                  >
                    Submit
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </Grid>
        </Grid>
      </Permission>
    </form>
  );
};

export default SalesProjectionsView;
