import React, { useState, useCallback } from 'react';
import OvertimeListView from './View';
import { useMutation } from '@apollo/client';
import { OVERTIMERULES } from '../../../../../apollo/queries'
import REMOVE_OVERTIMERULES from '../../../../../apollo/mutations/overtime/removeOvertimerules'
import { ROWS_PER_PAGE_OPTIONS, SNACKBAR_STATUS } from '../../../../../constants';
import OverTime from './View/Overtime';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const List = () => {
  const { setSnackbar } = useSnackbar();

  const [openDeleteModal, setOpenDeleteModal] = useState({
    id: null,
    open: false,
    name: null
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalId, setEditModalId] = useState();

  const [removeOvertimerule, { loading: removeOTLoading }] = useMutation(REMOVE_OVERTIMERULES, {
    refetchQueries: [
      { query: OVERTIMERULES, variables: {
          first: ROWS_PER_PAGE_OPTIONS[0]
        } }
    ],
  });

  const handleCloseEditModal = useCallback(() => {
    setShowEditModal(false);
    setEditModalId();
  }, [])

  const handleCreateOvertimeRule = () => {
    setShowEditModal(true);
  };

  const handleEditOvertimerule = overtime => {
    setShowEditModal(true);
    setEditModalId(overtime.id);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      id: null,
      open: false,
      name: null,
    })
  };

  const handleRemoveOvertime = id => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Deleting overtime rule...',
      open: true,
    });

    removeOvertimerule({
      variables: {
        input: {
          overtimeruleId: id
        }
      }
    }).then(() =>  {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Overtime rule deleted',
        open: true,
      })

      handleCloseDeleteModal();
    }).catch((error) => setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      }))

  };

  return (
    <>
      <OvertimeListView
      query={OVERTIMERULES}
      createOvertimeRule={handleCreateOvertimeRule}
      handleEditOvertimerule={handleEditOvertimerule}
      openDeleteModal={openDeleteModal}
      handleRemoveOvertime={handleRemoveOvertime}
      isDeleting={removeOTLoading}
    />
    <OverTime show={showEditModal} handleClose={handleCloseEditModal} overtimeId={editModalId} />
  </>
  )
};

export default List;
