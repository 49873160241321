import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { ContentInfo } from '../../../../../../../blocks';
import LoyaltyGeneralInfoStyles from './styles';
import CustomerModal from '../../CustomerModal';
import { Modal } from '../../../../../../../blocks';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { ConfirmationModal } from '../../../../../../../blocks';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import { paths } from '../../../../../../../../constants';
import DEACTIVATE_CUSTOMER from '../../../../../../../../apollo/mutations/loyalty/deactivateCustomer';
export default function GeneralInfoView({ customerInfo }) {
  const locationInfoRenderData = [
    [
      {
        id: 'e4e75323-2e0b-4f84-9f98-6a48953e5eea',
        label: 'Full Name',
        value: `${customerInfo?.node?.firstName} ${customerInfo?.node?.lastName}`,
      },
      {
        id: '16eb1644-92ec-491e-b8ff-a6a819a8496b',
        label: 'Address',
        value: `${customerInfo?.node?.address?.address}`,
      },
      {
        id: '16eb1644-92ec-491e-b8ff-a6a819a8496c',
        label: 'Signup Brand',
        value: customerInfo?.node?.login?.brand?.label,
      },
    ],
    [
      {
        id: '61edf55d-069e-4b21-97bb-7d644597262d',
        label: 'Phone',
        linkValue: `tel: ${customerInfo?.node?.login?.phone}`,
        value: customerInfo?.node?.login?.phone || customerInfo?.node?.phone?.phone || '/',
      },
      {
        id: '86ec0b27-f79a-4c64-aa81-a66558eb62b2',
        label: 'Email',
        linkValue: `mailto: ${customerInfo?.node?.login?.email}`,
        value: customerInfo?.node?.login?.email,
      },
      {
        id: 'a6690fc1-0fbc-4adf-a18b-ec77dab96122',
        label: 'User Code for refer a friend',
        value: customerInfo?.node?.login?.referralCode,
      },
    ],
    [
      {
        id: 'fc9e27f2-71de-40ac-86c7-d56b3bdb224e',
        label: 'Birthday',
        value: customerInfo?.node?.dateOfBirth
          ? moment(customerInfo?.node?.dateOfBirth).format('MM/DD/YYYY')
          : '/',
      },
      {
        id: '549edc5a-69f5-4a62-95ce-b6b70c111eb6',
        label: 'Spendable Points',
        value: customerInfo?.node?.login?.spendablePoints,
      },
      {
        id: '88a596f9-32c0-451e-871d-6f22b17ef01d',
        label: 'Life time points',
        value: customerInfo?.node?.login?.lifetimePoints,
      },
    ],
    [
      {
        id: '88a596f9-32c0-451e-871d-6f22b17ef01c',
        label: 'Life time bonus points',
        value: customerInfo?.node?.login?.lifetimeBonusPoints,
      },
    ],
  ];
  const [showModal, setShowModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { setSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = LoyaltyGeneralInfoStyles();

  const [deactivateCustomer] = useMutation(DEACTIVATE_CUSTOMER);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const redirectToCustomerTable = () => {
    history.push(paths.LOYALTY_CUSTOMERS);
  };

  const handleDeactivateCustomer = deactivate => {
    deactivateCustomer({
      variables: {
        input: {
          loginId: customerInfo?.node?.login?.id,
          deactivate,
        },
      },
    })
      .then(() => {
        if (deactivate) {
          setSnackbar({
            text: 'Customer deactivated!.',
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
          });
        } else {
          setSnackbar({
            text: 'Customer activated!.',
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
          });
        }
        redirectToCustomerTable();
      })
      .catch(e =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        }),
      );
  };

  return (
    <div id="generalInfo">
      <Grid container>
        <Grid container item xs={12} className={classes.heading} justify="space-between">
          <Typography variant="h4" component="h2">
            Customer Info
          </Typography>
          <Grid item>
            <div>
              {!customerInfo?.node?.login?.inactive ? (
                <Button
                  onClick={() => setOpenConfirm(true)}
                  variant="outlined"
                  color="secondary"
                  style={{ color: 'white', background: 'red' }}
                  // disabled={!locationInfoData?.id || loadingTestPrint}
                >
                  Deactivation
                </Button>
              ) : (
                <Button
                  onClick={() => setOpenConfirm(true)}
                  // disabled={!locationInfoData?.id || loadingTestPrint}
                >
                  Activation
                </Button>
              )}
            </div>
            <Button
              className={classes.marginLeft}
              variant="outlined"
              onClick={() => handleOpenModal()}
              //   disabled={loadingTestPrint}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <ContentInfo contentInfo={locationInfoRenderData} id="generalInfo" />
      </Grid>
      <Modal open={showModal} handleClose={handleCloseModal} title="Edit Customer">
        <CustomerModal handleClose={handleCloseModal} customerInfo={customerInfo} />
      </Modal>
      <ConfirmationModal
        handleClose={() => {
          setOpenConfirm(false);
        }}
        open={openConfirm}
        confirmAction={() => {
          handleDeactivateCustomer(customerInfo?.node?.login?.inactive ? false : true);
        }}
        text={
          customerInfo?.node?.login?.inactive
            ? 'Are you sure you want to activate the customer?'
            : 'Are you sure you want to deactivate the customer?'
        }
        buttonText={
          customerInfo?.node?.login?.inactive ? 'Activate Customer' : 'Deactivate Customer'
        }
      />
    </div>
  );
}
