import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import { PERSON_TIMESHIFT_CONNECTION } from '../../../../apollo/queries/';
import { LOCATIONS } from '../../../../apollo/queries/filters';
import { convert } from '../../../../utils';
import TimeshiftView from './View';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import CREATE_TIMESHIFT from '../../../../apollo/mutations/timeshifts/createTimeshift';
import UPDATE_TIMESHIFT from '../../../../apollo/mutations/timeshifts/updateTimshift';
import REMOVE_TIMESHIFT from '../../../../apollo/mutations/timeshifts/removeTimeshift';
import CLOCKOUT_TIMESHIFT from '../../../../apollo/mutations/timeshifts/clockoutTimeshift';
import FINALIZE_PAYROLL from '../../../../apollo/mutations/timeshifts/finalizePayroll';
import DateTime from 'luxon/src/datetime';
import { CoreContext } from '../../../../CoreContext';
import { SNACKBAR_STATUS } from '../../../../constants';
import { useFormSubmitPermissions } from '../../../../hooks';
import { MUTATION_NAME } from '../../../../constants';
import { getOvertimeValues, getTotalWages } from './helpers'
import { compact } from 'lodash';
import { useSnackbar } from '../../../../hooks/useSnackbar';

const Timeshift = () => {
  const { setSnackbar } = useSnackbar();
  const context = useContext(CoreContext);

  const mutationPermissions = context?.coreUtils?.getAppActions()[61]
    ?.mutations;
  const {
    data: locationData,
    loading: locationLoading,
    error: locationError,
  } = useQuery(LOCATIONS, {
    variables: {
      sort: {locationId: 'ASC'}
    }
  });

  const [permissions, setPermissions] = useState();
  const [tableState, setTableState] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.CreateTimeshiftMutationInput,
      MUTATION_NAME.UpdateTimeshiftMutationInput,
      MUTATION_NAME.ClockoutTimeshiftMutationInput,
      MUTATION_NAME.FinalizePayrollMutationInput,
    ],
    permissions,
  });

  const [locationInfo, setLocationInfo] = useState({
    started: DateTime.fromObject({ weekday: locationData?.viewer?.locationConnection?.edges[0]?.node?.workWeekStartId || 1 }).toFormat('yyyy-MM-dd'),
    finished: DateTime
        .fromObject({ weekday: locationData?.viewer?.locationConnection?.edges[0]?.node?.workWeekStartId || 1 })
      .plus({ days: locationData?.viewer?.locationConnection?.edges[0].node?.payrollPeriod?.duration })
      .toFormat('yyyy-MM-dd'),
    locationId:
      locationData?.viewer?.locationConnection?.edges[0].node.locationId,
    payrollPeriod: locationData?.viewer?.locationConnection?.edges[0].node.payrollPeriod,
    workWeekStartId: locationData?.viewer?.locationConnection?.edges[0].node?.workWeekStartId
  });
  const { handleSubmit, register, control, errors } = useForm();
  const [openClockoutModal, setOpenClockoutModal] = useState({
    open: false,
    timeshiftId: null,
    timezone: null,
    permissions: null,
  });
  const [openRemoveTimeshiftModal, setOpenRemoveTimeshiftModal] = useState({
    id: null,
    open: false,
  });
  const [openCreateTimeshiftModal, setOpenCreateTimeshiftModal] = useState({
    locationPerson: {},
  });
  const [openEditTimeshiftModal, setOpenEditTimeshiftModal] = useState({
    id: null,
    timeshift: {},
    timezone: null,
  });
  const [finalizePayrollModalOpen, setFinalizePayrollModalOpen] = useState({
    open: false,
    locationId: null,
  });
  const [createTimeshift, { loading: createTimeshiftLoading }] = useMutation(
    CREATE_TIMESHIFT
  );
  const [updateTimeshift, { loading: updateTimeshiftLoading }] = useMutation(
    UPDATE_TIMESHIFT
  );
  const [
    clockoutTimeshift,
    { loading: clockoutTimeshiftLoading },
  ] = useMutation(CLOCKOUT_TIMESHIFT);
  const [finalizePayroll, { loading: finalizePayrollLoading }] = useMutation(
    FINALIZE_PAYROLL
  );
  const [removeTimeshift, { loading: removeTimeshiftLoading }] = useMutation(
    REMOVE_TIMESHIFT,
    {
      update: (
        cache,
        {
          data: {
            removeTimeshift: { removedTimeshiftId },
          },
        }
      ) => {
        cache.evict({
          id: `timeshift:${removedTimeshiftId}`,
          broadcast: false,
        });
        cache.gc();
      },
    }
  );

  const locationList = useMemo(
    () =>
      locationData?.viewer?.locationConnection?.edges?.map((location) => ({
        label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
        value: location.node.locationId,
        payrollPeriod: location.node.payrollPeriod,
        workWeekStartId: location.node.workWeekStartId
      })) || [],
    [locationData?.viewer?.locationConnection?.edges]
  );

  useEffect(() => {
    const workWeekStart =
      locationData?.viewer?.locationConnection?.edges[0]?.node?.workWeekStartId;
    if (
      locationData?.viewer?.locationConnection?.edges[0]?.node?.workWeekStartId
    ) {
      setLocationInfo({
        started: DateTime.fromObject({ weekday: workWeekStart }).toFormat(
          'yyyy-MM-dd'
        ),
        finished: DateTime.fromObject({ weekday: workWeekStart })
          .plus({ days: locationData?.viewer?.locationConnection?.edges[0].node?.payrollPeriod?.duration })
          .toFormat('yyyy-MM-dd'),
        locationId:
          locationData?.viewer?.locationConnection?.edges[0].node.locationId,
        payrollPeriod: locationData?.viewer?.locationConnection?.edges[0].node.payrollPeriod,
        workWeekStartId: locationData?.viewer?.locationConnection?.edges[0].node?.workWeekStartId
      });
    }
  }, [locationData]);

  const handleCloseClockoutModal = useCallback(() => {
    setOpenClockoutModal({
      open: false,
      timeshiftId: null,
      timezone: null,
      permissions: null,
    });
  }, []);

  const handleCloseCreateTimeshiftModal = useCallback(() => {
    setOpenCreateTimeshiftModal({
      open: false,
      locationPerson: null,
    });
  }, []);

  const handleCloseEditTimeshiftModal = useCallback(() => {
    setOpenEditTimeshiftModal({
      open: false,
      timeshift: null,
      timezone: null,
    });
  }, []);

  const closeFinalizePayrollModal = useCallback(() => {
    setFinalizePayrollModalOpen({
      open: false,
      locationId: null,
    });
  }, []);

  const handleCreateTimeShift = useCallback(
    ({ variables }) => {
      setSnackbar({
        open: true,
        text: 'Creating timeshift',
        type: SNACKBAR_STATUS.INFO,
      });

      createTimeshift({ variables })
        .then(() => {
          setSnackbar({
            open: true,
            text: 'Timeshift was created successfully',
            type: SNACKBAR_STATUS.SUCCESS,
          });

          handleCloseCreateTimeshiftModal();
        })
        .catch((error) =>
          setSnackbar({
            open: true,
            text: error.message,
            type: SNACKBAR_STATUS.ERROR,
          })
        );
    },
    [createTimeshift, handleCloseCreateTimeshiftModal]
  );

  const handleUpdateTimeShift = useCallback(
    (variables) => {
      setSnackbar({
        open: true,
        text: 'Timeshift is updating',
        type: SNACKBAR_STATUS.INFO,
      });

      updateTimeshift(variables)
        .then(() => {
          setSnackbar({
            open: true,
            text: 'Timeshift was updated successfully',
            type: SNACKBAR_STATUS.SUCCESS,
          });

          handleCloseEditTimeshiftModal();
        })
        .catch((error) =>
          setSnackbar({
            open: true,
            text: error.message,
            type: SNACKBAR_STATUS.ERROR,
          })
        );
    },
    [updateTimeshift, handleCloseEditTimeshiftModal]
  );

  const handleClockoutTimeshift = useCallback(
    (input) => {
      setSnackbar({
        text: 'Employee is being clocked out...',
        open: true,
        type: SNACKBAR_STATUS.INFO,
      });

      clockoutTimeshift({
        variables: {
          input: {
            timeshiftId: input.timeshiftId,
            end: input.end,
          },
          started: input.started,
          finished: input.finished,
        },
      })
        .then(() => {
          setSnackbar({
            text: 'Employee clocked out successfully',
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
          });

          handleCloseClockoutModal();
        })
        .catch((error) =>
          setSnackbar({
            text: error.message,
            open: true,
            type: SNACKBAR_STATUS.ERROR,
          })
        );
    },
    [clockoutTimeshift, handleCloseClockoutModal]
  );

  const handleFinalizePayroll = useCallback(
    (variables) => {
      setSnackbar({
        text: 'Payroll finalizing...',
        open: true,
        type: SNACKBAR_STATUS.INFO,
      });

      finalizePayroll(variables)
        .then(() => {
          setSnackbar({
            text: 'Payroll was finalized successfully',
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
          });

          closeFinalizePayrollModal();
        })
        .catch((error) =>
          setSnackbar({
            text: error.message,
            open: true,
            type: SNACKBAR_STATUS.ERROR,
          })
        );
    },
    [finalizePayroll, closeFinalizePayrollModal]
  );

  const calculateWeeklyTimeshiftHours = useCallback((timeshifts) => {
    let totalHours = 0;
    timeshifts.forEach((timeshift) => {
      totalHours += moment
        .duration(
          moment(timeshift?.node?.finished).diff(
            moment(timeshift?.node?.started)
          )
        )
        .asHours();
    });
    return `${convert.decimalHoursToHHmm(totalHours)}`;
  }, []);

  const handleOpenClockoutModal = useCallback((timeshift) => {
    setOpenClockoutModal({
      open: true,
      timeshiftId: timeshift.id,
      timezone: timeshift.timezone,
      permissions: timeshift.permissions,
    });

    setOpenWarningMutationName(MUTATION_NAME.ClockoutTimeshiftMutationInput);
  }, [setOpenWarningMutationName]);

  const openFinalizePayrollModal = useCallback((data) => {
    setTableState(data.variables);
    setFinalizePayrollModalOpen({
      open: true,
      locationId: data.locationId,
    });

    setOpenWarningMutationName(MUTATION_NAME.FinalizePayrollMutationInput);
  }, [setOpenWarningMutationName]);

  const handleDeleteTimeshift = useCallback((input) => {
    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Deleting timeshift...',
      open: true,
    });

    removeTimeshift({
      variables: {
        input: {
          timeshiftId: input,
        },
      },
    })
      .then(() =>
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Timeshift deleted',
          open: true,
        })
      )
      .catch((error) =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
          open: true,
        })
      );
  }, [removeTimeshift]);

  const handleOpenCreateTimeshiftModal = useCallback((locationPerson) => {
    setOpenCreateTimeshiftModal({
      open: true,
      locationPerson,
    });

    setOpenWarningMutationName(MUTATION_NAME.CreateTimeshiftMutationInput);
  }, [setOpenWarningMutationName]);

  const handleOpenEditTimeshiftModal = useCallback((timeshift) => {
    setOpenEditTimeshiftModal({
      open: true,
      timeshift,
      timezone: timeshift.timezone,
    });

    setOpenWarningMutationName(MUTATION_NAME.UpdateTimeshiftMutationInput);
  }, [setOpenWarningMutationName]);

  const handleLocationAutocompleteChange = useCallback((location, variables, refetch) => {
    const started = DateTime.fromObject({ weekday: location?.workWeekStartId || 1 }).toUTC().toFormat('yyyy-MM-dd');
    const finished = DateTime
        .fromObject({ weekday: location?.workWeekStartId || 1 })
        .toUTC()
        .plus({ days: location?.payrollPeriod?.duration })
        .toFormat('yyyy-MM-dd');
    if (location?.value) {
      refetch({
        ...variables,
        started,
        finished,
        timeshiftFilter: {
          ...variables.timeshiftFilter,
          started: {
            gte: started,
            lt: finished,
          },
          locationId: {
            eq: location?.value,
          },
        },
        filter: {
          ...variables?.filter,
          locationId: {
            eq: location?.value,
          },
        },
      });
      setLocationInfo({
        started,
        finished,
        locationId: location?.value,
        payrollPeriod: location?.payrollPeriod,
        workWeekStartId: location?.workWeekStartId
      });
    } else {
      refetch({ variables });
    }
  }, []);

  if (locationLoading || !locationInfo) return <div>loading...</div>;
  if (locationError) return <div>error</div>;

  const mutationLoading =
    createTimeshiftLoading ||
    updateTimeshiftLoading ||
    clockoutTimeshiftLoading ||
    finalizePayrollLoading ||
    removeTimeshiftLoading;

  return (
    <TimeshiftView
      query={PERSON_TIMESHIFT_CONNECTION}
      locationData={locationData}
      calculateWeeklyTimeshiftHours={calculateWeeklyTimeshiftHours}
      getOvertimeValues={getOvertimeValues}
      getTotalWages={getTotalWages}
      createTimeshift={handleCreateTimeShift}
      accessToMutations={accessToMutations}
      permissions={permissions}
      setPermissions={setPermissions}
      updateTimeshift={handleUpdateTimeShift}
      openClockoutModal={openClockoutModal}
      locationList={locationList}
      handleSubmit={handleSubmit}
      handleLocationAutocompleteChange={handleLocationAutocompleteChange}
      handleOpenClockoutModal={handleOpenClockoutModal}
      handleCloseClockoutModal={handleCloseClockoutModal}
      control={control}
      errors={errors}
      openRemoveTimeshiftModal={openRemoveTimeshiftModal}
      setOpenRemoveTimeshiftModal={setOpenRemoveTimeshiftModal}
      mutationPermissions={mutationPermissions}
      register={register}
      locationInfo={locationInfo}
      isDeletingTimeshift={removeTimeshiftLoading}
      setLocationInfo={setLocationInfo}
      openFinalizePayrollModal={openFinalizePayrollModal}
      closeFinalizePayrollModal={closeFinalizePayrollModal}
      handleDeleteTimeshift={handleDeleteTimeshift}
      handleOpenCreateTimeshiftModal={handleOpenCreateTimeshiftModal}
      handleOpenEditTimeshiftModal={handleOpenEditTimeshiftModal}
      openCreateTimeshiftModal={openCreateTimeshiftModal}
      openEditTimeshiftModal={openEditTimeshiftModal}
      handleCloseCreateTimeshiftModal={handleCloseCreateTimeshiftModal}
      handleCloseEditTimeshiftModal={handleCloseEditTimeshiftModal}
      handleClockout={handleClockoutTimeshift}
      mutationLoading={mutationLoading}
      finalizePayroll={handleFinalizePayroll}
      tableState={tableState}
      finalizePayrollModalOpen={finalizePayrollModalOpen}
    />
  );
};

export default Timeshift;
