import { gql } from '@apollo/client';

const FETCH_SUBCATEGORY = gql`
  query fetchSubcategory(
    $filter: [SubcategoryFilter]
    $after: String
    $first: Int
    $before: String
    $last: Int
    $search: String
  ) {
    viewer {
      id
      subcategoryConnection(
        filter: $filter
        after: $after
        first: $first
        before: $before
        last: $last
        search: $search
      ) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            subcategoryId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_SUBCATEGORY;
