import { gql } from '@apollo/client';

const CREATE_LOCATION_HIERARCHY = gql`
  mutation createLocationHierarchy(
    $input: createLocationHierarchyMutationInput!
  ) {
    createLocationHierarchy(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default CREATE_LOCATION_HIERARCHY;
