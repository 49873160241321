import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  FormLabel,
  TextField,
  Grid,
} from '@material-ui/core';
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons';
import { convert, CurrencyInputFormat } from '../../../../../../utils';
import NumberInput from '../../../../../blocks/NumberInput';
import { CurrencyInput } from '../../../../../blocks';

const Ingredient = ({ isSimple, item: ingredient, control, register, setValue, id, errors }) => {
  const [checked, setChecked] = useState(false);

  const disabled = false;

  useEffect(() => {
    register(`${id}.isChecked`);
    register(`${id}.productId`);

    register(`${id}.quantity`);
    register(`${id}.vendorUOM`);
    register(`${id}.packSize`);
    register(`${id}.hasMapping`);

    setValue(
      `${id}.isChecked`,
      isSimple || !!ingredient?.vendorproduct?.ingredient?.invoiceIngredient,
    );
    setValue(`${id}.productId`, ingredient.productId);
    setValue(`${id}.quantity`, ingredient.quantity || 0);
    setValue(`${id}.vendorUOM`, ingredient.vendorUOM);
    setValue(`${id}.packSize`, ingredient.packSize);
    
    setValue(`${id}.hasMapping`, !!ingredient.vendorproduct);

    if (isSimple) {
      register(`${id}.measureId`);
      register(`${id}.cost`);

      setValue(`${id}.measureId`, ingredient.measureId);
      setValue(`${id}.cost`, ingredient.cost);
    }
  }, [register, id, ingredient, isSimple]);

  const onCheckedChange = useCallback(
    value => {
      setChecked(value);
      setValue(`${id}.isChecked`, value);
    },
    [setValue, id, setChecked],
  );

  const isDefaultChecked = useMemo(() => {
    const result = isSimple || !!ingredient?.vendorproduct?.ingredient?.invoiceIngredient;

    onCheckedChange(result);

    return result;
  }, [isSimple, ingredient]);

  const unitsStyle = useMemo(() => {
    if (ingredient.unitsOrdered !== ingredient.unitsShiped) {
      return { color: '#DD1416' };
    }
  }, [ingredient]);

  return (
    <List style={{ margin: '0 -16px 0', padding: '0', width: 'calc(100% + 32px)' }}>
      <ListItem style={{ background: '#F8F8FA', padding: '8px 16px' }}>
        <Box
          style={{
            minWidth: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {disabled ? null : (
            <div>
              <IconButton
                style={{ marginRight: '12px' }}
                size="small"
                id={`${id}.isChecked`}
                name={`${id}.isChecked`}
                onClick={() => onCheckedChange(!checked)}
                disabled={isDefaultChecked || !ingredient.ingredientId}
              >
                {checked ? <CheckCircle style={{ color: '#2360b6' }} /> : <RadioButtonUnchecked />}
              </IconButton>
            </div>
          )}
          <div>
            {!isSimple ? (
              <>
                <Typography variant="caption">{ingredient.productId}</Typography>
                <Typography variant="h6">
                  {ingredient.label} - {ingredient.description}
                </Typography>
                <Typography
                  variant="body2"
                  style={!ingredient.ingredientId ? { color: 'red' } : undefined}
                >
                  {ingredient.ingredientLabel || 'Ingredient not found'}
                </Typography>
              </>
            ) : (
              <Typography variant="h6">
                {ingredient.productId} - {ingredient.ingredientLabel}
              </Typography>
            )}
          </div>
        </Box>
      </ListItem>
      <Grid container spacing={3} style={{ padding: '16px' }}>
        <Grid item xs={12}>
          <Grid container spacing={3} justify="space-between" alignItems="flex-end">
            <Grid item style={{ display: 'block' }}>
              {!isSimple ? (
                <>
                  <Typography variant="body2">
                    Units Ordered: <span style={unitsStyle}>{ingredient.unitsOrdered}</span>
                  </Typography>
                  <Typography variant="body2">
                    Units Shipped: <span style={unitsStyle}>{ingredient.unitsShiped}</span>
                  </Typography>
                  <Typography variant="body2">
                    Unit Price: $ {convert.centsToDollars(ingredient.unitPrice || 0)}
                  </Typography>
                  <Typography variant="body2">
                    Extended Price: $ {convert.centsToDollars(ingredient.extendedPrice || 0)}
                  </Typography>
                  <Typography variant="body2">Net Weight: {ingredient.netWeight}</Typography>
                  <Typography variant="body2">Pack Size: {ingredient.packSize}</Typography>
                </>
              ) : null}
            </Grid>
            {isSimple ? (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <CurrencyInput
                      fullWidth
                      control={control}
                      defaultValue={
                        ingredient?.cost || ingredient?.cost === 0
                          ? convert.centsToDollars(ingredient?.cost)
                          : ''
                      }
                      error={!!errors[id]?.cost}
                      helperText={
                        errors[id]?.cost?.type === 'required'
                          ? 'Value is required'
                          : errors[id]?.cost?.type === 'positive'
                          ? "Value can't be negative"
                          : ''
                      }
                      id={`${id}.cost`}
                      name={`${id}.cost`}
                      label="Cost"
                      required={true}
                      rules={{
                        required: 'Cost is required',
                        validate: {
                          positive: value => {
                            if (typeof value === 'number') {
                              if (value >= 0) {
                                return true;
                              }
                            }

                            const price = CurrencyInputFormat(value);
                            const isPositive = price >= 0;
                            return isPositive;
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      disabled={!ingredient.ingredientId}
                      required
                      control={control}
                      decimalScale={2}
                      defaultValue={ingredient.quantity || 0}
                      error={!!errors[id]?.quantity}
                      helperText={
                        errors[id]?.quantity?.type === 'required' ? 'Value is required' : ''
                      }
                      id={`${id}.quantity`}
                      label={ingredient?.vendorUOM}
                      placeholder="0.00"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <FormLabel>
                  Quantity: {ingredient.quantity} {ingredient.vendorUOM}
                </FormLabel>
              </Grid>
            )}
            {isSimple || !ingredient.vendorproduct || !checked ? null : (
              <Grid item xs={12}>
                <TextField
                  inputRef={register()}
                  name={`${id}.note`}
                  defaultValue={ingredient.note}
                  id={`${id}.note`}
                  label="Note"
                  fullWidth
                  placeholder="Note"
                  multiline
                  rows={3}
                  maxrows={3}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </List>
  );
};

export default Ingredient;
