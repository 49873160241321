import React from "react";
import CategoryPrintSettingsView from "./View";

function CategoryPrintSettings({
  subcategoriesPrinterstations,
  permissions
}) {
  return (
    <CategoryPrintSettingsView
      subcategoriesPrinterstations={subcategoriesPrinterstations}
      permissions={permissions}
    />
  );
}

export default CategoryPrintSettings;
