import { gql } from '@apollo/client';

const MULTIPLE_CHOICE = gql`
  query multipleChoicesQuery($id: ID!) {
    viewer {
      id
      multiple(id: $id) {
        id
        label
        onlineLabel
        description
        multipleId
        multipleChoiceConnection(sort: { orderKey: ASC }) {
          edges {
            node {
              id
              price
              priceCap
              defaultSelected
              preparationTime
              allowedMaximum
              multiple {
                id
              }
              choice {
                id
                choiceId
                label
              }
              multipleChoiceTierConnection {
                totalCount
                edges {
                  node {
                    multipleChoiceTierPlatformConnection {
                      edges {
                        node {
                          id
                          platform {
                            id
                            label
                          }
                          priceCap
                        }
                      }
                    }
                    price
                    priceCap
                    tier {
                      id
                      tierId
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MULTIPLE_CHOICE;
