import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
    home: {
        padding: theme.spacing(4),
        textAlign: 'center',
    },
    logo: {
        width: 200,
    },
    title: {
    },
    description: {
        fontSize: theme.typography.fontSizeML,
        maxWidth: 900,
        margin: `0 auto ${theme.spacing(6)}px auto`,
    },
    subtitle: {
        fontSize: theme.typography.fontSizeML,
        fontWeight: theme.typography.fontWeightRegular,
        margin: '0 auto',
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        maxWidth: 1100,

        '&:before, &:after': {
            content: '""',
            flex: 1,
            height: 1,
            borderTop: '1px solid #ccc',
        },

        '& span': {
            margin: `0 ${theme.spacing(6)}px`
        },
    },
    list: {
        margin: '0 auto',
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 1100,

        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap',
        },

        '& li': {
            listStyle: 'none',
            background: 'transparent',
            border: `2px solid ${theme.colors.primary}`,
            borderRadius: theme.spacing(4),
            fontSize: theme.typography.fontSizeBase,
            padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
            transition: theme.transitionBase,
                        
            '&:hover': {
                background: theme.colors.boxBackground,
                boxShadow: theme.boxShadowBase,
            },

            [theme.breakpoints.down('lg')]: {
                width: '44%',
                margin: '2%',
            },
            
            [theme.breakpoints.up('lg')]: {
                flexGrow: 1,
                flexBasis: 0,
                margin: theme.spacing(6),            
            },

            '& a': {
                display: 'block',
                color: 'inherit !important',
                textDecoration: 'none !important',

                '& img': {
                    display: 'block',
                    margin: `0 auto ${theme.spacing(2)}px auto`,
                    width: 40,
                }
            }
        },
    }
});

export const HomeViewStyles = makeStyles(Styles);
