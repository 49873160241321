import { useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, TableCell, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SUBMIT_INVENTORY_COUNT from '../../../../../apollo/mutations/inventoryCount/submitInventoryCount';
import FETCH_LOCATION_INVENTORY_COUNT_ITEMS from '../../../../../apollo/queries/locations/fetchLocationInventoryCountItems';
import { paths, SNACKBAR_STATUS } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { ConfirmationModal } from '../../../../blocks';
import DataTable from '../../../../blocks/DataTable';
import PaperMain from '../../../../blocks/PaperMain';
import { formatDataForTable, getLocationStorageInventoryitems } from './helpers';
import useStyles from './styles';

const Summary = () => {
  const { push } = useHistory();
  let { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];

  const { setSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnBackClick = useCallback(() => {
    push(paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS.replace(':locationId', ids));
  }, [locationId]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);

    push(paths.COREOPS_INVENTORY_LOCATION_TASKLIST.replace(':locationId', ids));
  }, []);

  const { data, loading, error } = useQuery(FETCH_LOCATION_INVENTORY_COUNT_ITEMS, {
    variables: {
      locationId,
      filter: {
        locationId: {
          eq: locationId,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const [submitInventoryCount, { loading: submitting }] = useMutation(SUBMIT_INVENTORY_COUNT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Inventory Count Submitted',
        open: true,
      });

      setIsModalOpen(true);
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const { tableData, locationStorageColumns } = useMemo(() => formatDataForTable(data), [data]);

  const lastRow = [
    <div>
      <strong>Total: </strong>  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data?.viewer.locationConnection.edges[0].node.inventorycount.totalInventorycountCost)}
    </div>
  ]
  const locationStorageInventoryItemConnection = useMemo(
    () =>
      data?.viewer?.locationConnection?.edges?.length &&
      data.viewer.locationConnection.edges[0].node,
    [data],
  );

  const inventorycountId = useMemo(
    () => locationStorageInventoryItemConnection?.inventorycount?.inventorycountId,
    [locationStorageInventoryItemConnection],
  );

  const handleOnSubmit = useCallback(() => {
    const locationStorageInventoryitems = getLocationStorageInventoryitems(
      locationStorageInventoryItemConnection,
    );

    setSnackbar({
      type: SNACKBAR_STATUS.INFO,
      text: 'Submitting inventory count...',
      open: true,
    });

    submitInventoryCount({
      variables: {
        input: {
          inventorycountId,
          locationStorageInventoryitems,
        },
      },
    });
  }, [inventorycountId, locationStorageInventoryItemConnection]);

  return (
    <PaperMain>
      <Typography variant="h3" style={{ marginBottom: '16px' }}>
        Summary:
      </Typography>
      {!loading && !error ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ minWidth: '100%' }}>
              <DataTable
                lastRow={lastRow}
                columns={[
                  {
                    title: 'Name',
                    field: 'label',
                    getRowspan: row => row.rowSpan,
                  },
                  {
                    title: 'UOM',
                    field: 'uom.measureLabel',
                    cellStyle: { paddingLeft: '16px' },
                  },
                  ...locationStorageColumns,
                  { title: 'Total', field: 'uom.total' },
                  {
                    title: 'Total Cost',
                    field: 'uom',
                    render: row => {
                      if (row.totalCost) {
                        return `$ ${row.totalCost.toFixed(2)}`;
                      }
                      return '/';
                    },
                  },
                ]}
                data={{ tableData }}
                structureTable={value => value.tableData}
              />
            </div>
          </Grid>
          {locationStorageInventoryItemConnection.inventoryCountStatus === 'DONE' ? (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                style={{ color: '#DD1416', textAlign: 'center', width: '100%' }}
              >
                * Inventory Count already submitted
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item className={classes.button}>
                <Button style={{ minWidth: '100%' }} variant="outlined" onClick={handleOnBackClick}>
                  Back
                </Button>
              </Grid>
              {locationStorageInventoryItemConnection.inventoryCountStatus !== 'DONE' ? (
                <Grid item className={classes.button}>
                  <Button
                    style={{ minWidth: '100%' }}
                    onClick={handleOnSubmit}
                    disabled={submitting}
                  >
                    Confirm
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <div>error</div>
      )}
      <ConfirmationModal
        handleClose={handleCloseModal}
        open={isModalOpen}
        text="Your inventory has been updated, and a report has been emailed to you."
        title="Thank
      You"
        showCancel={false}
        buttonText="Continue"
      />
    </PaperMain>
  );
};

export default Summary;
