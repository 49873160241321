import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  titleContainer: {
    marginBottom: theme.spacing(5),
  },
  itemContainer: {
    marginBottom: theme.spacing(4),
  },
});

const PaymentProcessorsStyles = makeStyles(Styles);

export default PaymentProcessorsStyles;
