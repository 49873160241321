import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddInventoryItems from './AddInventoryItems';
import InvoiceInfo from './InvoiceInfo';
import { Box, Grid, Button } from '@material-ui/core';
import { STEP, INITIAL_INVOICE_INFO } from './constants';
import { ConfirmationModal } from '../../../../blocks';
import { useHistory, useParams } from 'react-router-dom';
import { paths, SNACKBAR_STATUS } from '../../../../../constants';
import CREATE_SIMPLE_INVOICE from '../../../../../apollo/mutations/inventory/createSimpleInvoice';
import { useMutation, useQuery } from '@apollo/client';
import PaperMain from '../../../../blocks/PaperMain';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash-es';
import useStyles from './styles';
import { prepareFormData } from './helpers';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import UPDATE_SIMPLE_INVOICE from '../../../../../apollo/mutations/inventory/updateSimpleInvoice';
import { convert } from '../../../../../utils';
import { SIMPLE_INVOICE_INGREDIENTS } from '../../../../../apollo/queries/inventoryInvoices/simpleInvoiceIngredients';

function SimpleInvoice() {
  const [image, setImage] = useState();
  const [step, setStep] = useState(STEP.INVOICE_INFO);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showInventoryItemsError, setShowInventoryItemsError] = useState(false);

  let { locationId: ids, invoiceId } = useParams();
  const locationId = ids.split('-')[0];
  const storeNumber = ids.split('-')[1];
  const { push } = useHistory();
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();

  const {
    control,
    errors,
    register,
    handleSubmit,
    reset,
    getValues,
    unregister,
    setValue,
  } = useForm(INITIAL_INVOICE_INFO);

  const [createSimpleInvoice, { loading }] = useMutation(CREATE_SIMPLE_INVOICE, {
    onCompleted: data => {
      if (data.createSimpleInvoice.invoice.isSubmitted) {
        setIsModalOpen(true);
      } else {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Invoice saved',
          open: true,
        });

        push(paths.COREOPS_INVENTORY_LOCATION_TASKLIST.replace(':locationId', ids));
      }
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [updateSimpleInvoice, { loading: updating }] = useMutation(UPDATE_SIMPLE_INVOICE, {
    onCompleted: data => {
      if (data.updateSimpleInvoice.invoice.isSubmitted) {
        setIsModalOpen(true);
      } else {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Invoice updated',
          open: true,
        });

        push(paths.COREOPS_INVENTORY_LOCATION_TASKLIST.replace(':locationId', ids));
      }
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const { data: invoiceInfoData, loading: loadingInvoiceData, error: invoiceDataError } = useQuery(
    SIMPLE_INVOICE_INGREDIENTS,
    {
      skip: !invoiceId,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          invoiceId: {
            eq: invoiceId,
          },
          storeNumber: { eq: storeNumber },
        },
      },
    },
  );

  const invoice = useMemo(
    () =>
      !isEmpty(invoiceInfoData?.viewer?.invoiceConnection?.edges) &&
      invoiceInfoData.viewer.invoiceConnection.edges[0].node,
    [invoiceInfoData],
  );

  useEffect(() => {
    if (invoice && (invoice.isSubmitted || !invoice.isSimple)) {
      push(
        paths.COREOPS_INVENTORY_LOCATION_INVOICE_SUMMARY.replace(':locationId', ids).replace(
          ':invoiceId',
          invoiceId,
        ),
      );
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice) {
      reset({
        supplierName: invoice.supplierName,
        invoiceNumber: invoice.invoiceNumber,
        dateOfPurchase: invoice.invoiceDate,
        subtotal: invoice?.subtotal ? convert.centsToDollars(invoice?.subtotal) : '',
        tax: invoice?.tax ? convert.centsToDollars(invoice?.tax) : '',
        total: invoice?.total ? convert.centsToDollars(invoice?.total) : '',
      });

      if (invoice.invoiceFileUrl) {
        setImage({ url: invoice.invoiceFileUrl });
      }
    }
  }, [invoice]);

  const pathToTaskList = useMemo(
    () => paths.COREOPS_INVENTORY_LOCATION_TASKLIST.replace(':locationId', ids),
    [locationId],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    push(pathToTaskList);
  }, [pathToTaskList]);

  const handleSubmitAction = useCallback(
    ({ formData, isSubmit }) => {
      const input = prepareFormData({
        formData,
        image,
        setShowInventoryItemsError,
        isSubmit,
        storeNumber,
        invoiceId: invoice?.invoiceId,
      });

      if (input) {
        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          text: isSubmit ? 'Submitting Invoice...' : 'Saving Invoice...',
          open: true,
        });

        if (!invoice.invoiceId) {
          createSimpleInvoice({
            variables: {
              input,
            },
          });
        } else {
          updateSimpleInvoice({
            variables: {
              input,
            },
          });
        }
      }
    },
    [image, locationId, setShowInventoryItemsError, invoice.invoiceId],
  );

  const onSave = useCallback(
    formData => {
      handleSubmitAction({ formData, isSubmit: false });
    },
    [handleSubmitAction],
  );

  const onSubmit = useCallback(
    formData => {
      handleSubmitAction({ formData, isSubmit: true });
    },
    [handleSubmitAction],
  );

  const handleOnAddProductsClick = useCallback(() => {
    setStep(STEP.ADD_PRODUCTS);
  }, []);

  const onCancelClick = useCallback(() => {
    push(pathToTaskList);
  }, [pathToTaskList]);

  return (
    <PaperMain className={classes.paper}>
      {!loadingInvoiceData && !invoiceDataError ? (
        invoiceId && !invoice ? (
          <div>Invoice Not found</div>
        ) : (
          <>
            <form
              style={{
                width: '100%',
                maxWidth: '600px',
                minHeight: step === STEP.INVOICE_INFO ? 'calc(100% - 66px)' : 'calc(100% - 100px)',
              }}
            >
              <Box hidden={step !== STEP.INVOICE_INFO} style={{ width: '100%', height: '100%' }}>
                <InvoiceInfo
                  image={image}
                  setImage={setImage}
                  invoiceInfo={invoiceInfoData}
                  pathToTaskList={pathToTaskList}
                  register={register}
                  errors={errors}
                  control={control}
                  invoice={invoice}
                />
              </Box>
              <Box hidden={step !== STEP.ADD_PRODUCTS} style={{ width: '100%', height: '100%' }}>
                <AddInventoryItems
                  invoiceInfo={invoiceInfoData}
                  register={register}
                  errors={errors}
                  control={control}
                  showInventoryItemsError={showInventoryItemsError}
                  setValue={setValue}
                  invoice={invoice}
                  unregister={unregister}
                  getValues={getValues}
                  submitButtonLocked={loading || updating}
                />
              </Box>
            </form>
            {step === STEP.INVOICE_INFO ? (
              <Grid container className={classes.buttonsContainer} spacing={2}>
                <Grid className={classes.button} item>
                  <Button
                    style={{ minWidth: '100%' }}
                    onClick={handleSubmit(handleOnAddProductsClick)}
                  >
                    Add Products
                  </Button>
                </Grid>
                <Grid className={classes.button} item>
                  <Button style={{ minWidth: '100%' }} onClick={onCancelClick} variant="outlined">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container className={classes.buttonsContainer} spacing={2}>
                <Grid className={classes.button} item>
                  <Button
                    style={{ minWidth: '100%' }}
                    disabled={loading || updating || errors.inventoryItems?.message}
                    onClick={handleSubmit(onSave)}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item className={classes.button}>
                  <Button
                    style={{ minWidth: '100%' }}
                    disabled={loading || updating || errors.inventoryItems?.message}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid className={classes.button} item>
                  <Button
                    style={{ minWidth: '100%' }}
                    type="button"
                    variant="outlined"
                    onClick={() => setStep(STEP.INVOICE_INFO)}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )
      ) : null}

      <ConfirmationModal
        handleClose={handleCloseModal}
        open={isModalOpen}
        text="Your inventory has been updated, and a report has been emailed to you."
        title="Thank You"
        showCancel={false}
        buttonText="Continue"
      />
    </PaperMain>
  );
}

export default SimpleInvoice;
