import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { ConnectionTable, Modal } from '../../../../blocks';
import VendorForm from './VendorForm';
import { FETCH_INVENTORY_VENDORS } from '../../../../../apollo/queries/inventoryVendors/fetchInventoryVendors';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../constants';
import DELETE_INVENTORY_VENDOR from '../../../../../apollo/mutations/inventory/deleteInventoryVendor';
import { Typography } from '@material-ui/core';

const statusOptions = [
  { label: 'Active', value: 1, color: '#37C78A' },
  { label: 'Inactive', value: 0, color: '#FF9900' },
];

const Vendors = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendor, setVendor] = useState();
  const { setSnackbar } = useSnackbar();

  const [deleteInventoryVendor, { loading }] = useMutation(DELETE_INVENTORY_VENDOR, {
    onCompleted: () =>
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        open: true,
        text: 'Vendor deleted',
      }),
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: e.message,
      }),
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.removeInventoryvendor?.viewer),
        fields: {
          inventoryvendorConnection() {
            return {
              __typename: 'InventoryvendorEdge',
            };
          },
        },
      });
    },
  });

  const onDelete = useCallback(
    ({ inventoryvendorId }) => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        open: true,
        text: 'Vendor deleting...',
      });

      deleteInventoryVendor({
        variables: {
          inventoryvendorId,
        },
      });
    },
    [deleteInventoryVendor],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setVendor();
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.status) {
        delete filters.status;
      }

      refetch({
        ...variables,
        filter: filters,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          status: {
            eq: value.toString(),
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        query={FETCH_INVENTORY_VENDORS}
        title="Vendors"
        customActionsTop={() => <Button onClick={() => setIsModalOpen(true)}>New Vendor</Button>}
        onTableRowClick={row => {
          setVendor(row);
          setIsModalOpen(true);
        }}
        initialQueryVariables={{
          fieldGroupOnly: 94,
        }}
        structureTable={data =>
          data?.viewer?.inventoryvendorConnection?.edges?.map(vendor => ({
            inventoryvendorId: vendor?.node?.inventoryvendorId,
            inventoryvendorVendorId: vendor?.node?.inventoryvendorVendorId,
            label: vendor?.node?.label,
            status: vendor?.node?.status,
          }))
        }
        columns={[
          {
            enablePropagation: true,
            title: 'ID',
            field: 'inventoryvendorVendorId',
          },
          {
            enablePropagation: true,
            title: 'Name',
            field: 'label',
          },
          {
            title: 'Status',
            field: 'status',
            enablePropagation: true,
            render: status => (
              <Typography variant="body1" style={{ color: status === '1' ? '#37C78A' : '#FF9900' }}>
                <strong>{status === '1' ? 'Active' : 'Inactive'}</strong>
              </Typography>
            ),
          },
        ]}
        handleDeleteRow={onDelete}
        useConfirmationModalForDelete
        isDeleting={loading}
        deleteConfirmationText="Are you sure you want to delete this vendor?"
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={1} justify="flex-end">
            <Grid xs={12} sm={6} md={4} lg={2}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      />
      <Modal
        handleClose={handleCloseModal}
        open={isModalOpen}
        title={vendor?.inventoryvendorId ? 'Update Vendor' : 'New Vendor'}
      >
        <VendorForm
          statusOptions={statusOptions}
          handleCloseModal={handleCloseModal}
          vendor={vendor}
        />
      </Modal>
    </>
  );
};

export default Vendors;
