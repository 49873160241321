import { gql } from "@apollo/client";

const DEACTIVATE_CUSTOMER = gql`
  mutation deactivateCustomer($input: DeactivateCustomerMutationInput!) {
    deactivateCustomer(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default DEACTIVATE_CUSTOMER;
