import { gql } from '@apollo/client';

const CREATE_JOB = gql`
  mutation createJob($label: String!, $locationIds: [Int]!, $colorId: Int, $posRoleId: Int!) {
    createJob(
      input: { label: $label, locationIds: $locationIds, colorId: $colorId, posRoleId: $posRoleId }
    ) {
      job {
        id
        jobId
        label
        posAccess
      }
    }
  }
`;

export default CREATE_JOB;
