import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import PaymentProcessorsStyles from "./styles";

// import DISCONNECT_FROM_PAYPAL from "../../../../../../apollo/mutations/locations/disconnectFromPayPal";
import DISCONNECT_FROM_SQUARE from "../../../../../../apollo/mutations/locations/disconnectFromSquare";
import Permission from "../../../../../blocks/Permission";
import ConfirmationModal from "../../../../../blocks/ConfirmationModal";
import { SNACKBAR_STATUS } from "../../../../../../constants";
import { PermissionField } from "../../../../../blocks";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";

const SQUARE_SCOPES = [
  "DEVICE_CREDENTIAL_MANAGEMENT",
  "MERCHANT_PROFILE_READ",
  "ORDERS_READ",
  "ORDERS_WRITE",
  "PAYMENTS_READ",
  "PAYMENTS_WRITE",
  "PAYMENTS_WRITE_IN_PERSON",
];

const PaymentProcessors = ({
  locationId,
  locationInfo,
  squareRefreshToken,
  payPalRefreshToken,
  query,
  mutationPermissions,
}) => {
  const { setSnackbar } = useSnackbar();

  // const disconnectFromPaypalPermission = mutationPermissions.find(
  //   (mutationPermission) => mutationPermission.label === "disconnectFromPaypal"
  // );
  const disconnectFromSquarePermission = mutationPermissions.find(
    (mutationPermission) => mutationPermission.label === "disconnectFromSquare"
  );

  const classes = PaymentProcessorsStyles();
  PaymentProcessors.propTypes = {
    locationId: PropTypes.string,
    squareRefreshToken: PropTypes.string,
    payPalRefreshToken: PropTypes.string,
    query: PropTypes.string,
  };

  const [showConnectWithSquare, setshowConnectWithSquare] = useState(
    !!locationInfo?.squareLocationId
  );
  const [IsConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  useEffect(() => query && setSnackbar({
    text: query,
    type: SNACKBAR_STATUS.INFO,
    open: true,
  }));

  // const [disconnectFromPayPal] = useMutation(DISCONNECT_FROM_PAYPAL);
  const [
    disconnectFromSquare,
    { loading: loadingSquareDisconnect },
  ] = useMutation(DISCONNECT_FROM_SQUARE);

  // const handleConnectWithPayPal = () => {
  //   const payPalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

  //   // eslint-disable-next-line no-undef
  //   window.open(
  //     `${process.env.REACT_APP_PAYPAL_API}/signin/authorize?scope=openid ` +
  //       `https://uri.paypal.com/services/paypalattributes/business ` +
  //       `https://uri.paypal.com/services/paypalhere address email profile&response_type=code&redirect_uri=${window.location.href}&client_id=${payPalClientId}&state=${locationId}`
  //   );
  // };

  // const handleDisconnectFromPayPal = async () => {
  //   try {
  //     await disconnectFromPayPal({
  //       variables: {
  //         input: { locationId },
  //       },
  //     });
  //     setSnackbar({
  //       text: "PayPal account successfully disconnected!",
  //       type: SNACKBAR_STATUS.SUCCESS,
  //       open: true,
  //     });
  //   } catch (err) {
  //     setSnackbar({
  //       text: err.message,
  //       open: true,
  //       type: SNACKBAR_STATUS.ERROR,
  //     });
  //   }
  // };

  const handleConnectWithSquare = () => {
    const squareClientId = process.env.REACT_APP_SQUARE_CLIENT_ID;
    const scopesUriEncoded = encodeURI(SQUARE_SCOPES.join(" "));

    // eslint-disable-next-line no-undef
    window.open(
      `${process.env.REACT_APP_SQUARE_API}/oauth2/authorize?client_id=${squareClientId}&scope=${scopesUriEncoded}&response_type=code&state=${locationId}`
    );
  };

  const handleDisconnectFromSquare = async () => {
    try {
      await disconnectFromSquare({
        variables: {
          input: { locationId },
        },
      });

      setSnackbar({
        text: "Square account successfully disconnected!",
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
      })

      setshowConnectWithSquare(false);
    } catch (err) {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        message: err.message,
      });
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      key="locationPaymentProcessors"
      direction="column"
      id="paymentProcessors"
    >
      <Grid item className={classes.titleContainer}>
        <Typography variant="h4" component="h2">
          Payment Processors
        </Typography>
      </Grid>
      <Grid container item className={classes.itemContainer}>
        <Grid item>
          <Typography variant="h4" component="h3">
            Stripe
          </Typography>
        </Grid>
        <Grid item container direction="column">
          <Typography variant="subtitle2" gutterBottom>
            Stripe Terms and Conditions
          </Typography>
          <Typography variant="body2">On</Typography>
        </Grid>
      </Grid>
      <Grid container item className={classes.itemContainer}>
        {/* <Grid item>
          <Typography variant="h4" component="h3">
            PayPal
          </Typography>
        </Grid> */}
        <Grid item container direction="column">
          {/* {payPalRefreshToken ? (
            <Permission access={disconnectFromPaypalPermission?.access}>
              <Button onClick={() => handleDisconnectFromPayPal()}>
                DISCONNECT PAYPAL
              </Button>
            </Permission>
          ) : (
            <Permission access={disconnectFromPaypalPermission?.access}>
              <Button onClick={() => handleConnectWithPayPal()}>
                CONNECT WITH PAYPAL
              </Button>
            </Permission>
          )} */}
        </Grid>
      </Grid>
      <Grid container item className={classes.itemContainer}>
        <Grid item>
          <Typography variant="h4" component="h3">
            Square
          </Typography>
        </Grid>
        <Grid item container direction="column">
          <Grid item className={classes.itemContainer}>
            {showConnectWithSquare ? (
              <Permission access={disconnectFromSquarePermission?.access}>
                <div>
                  <PermissionField createHelperText>
                    <Button
                      style={{ color: "white", background: "red" }}
                      onClick={() => setIsConfirmationModalOpen(true)}
                    >
                      DISCONNECT SQUARE
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
            ) : (
              <Permission access={disconnectFromSquarePermission?.access}>
                <div>
                  <PermissionField createHelperText>
                    <Button onClick={() => handleConnectWithSquare()}>
                      CONNECT WITH SQUARE
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.itemContainer}
          >
            <Typography variant="subtitle2" gutterBottom>
              Terms and conditions acceptance
            </Typography>
            <Typography variant="body2">On</Typography>
          </Grid>
          <Grid item container direction="column">
            <Typography variant="subtitle2" gutterBottom>
              oAuth Access Granted
            </Typography>
            <Typography variant="body2">On</Typography>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationModal
        buttonText="Disconnect"
        open={IsConfirmationModalOpen}
        handleClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        disabled={loadingSquareDisconnect}
        text="Are you sure you want to disconnect square?"
        confirmAction={() => handleDisconnectFromSquare()}
      />
    </Grid>
  );
};

export default PaymentProcessors;
