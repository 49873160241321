import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../../../../../blocks';
import ChoicesSettings from './ChoicesSettings';
// import ItemList from "./ItemList";

const MultipleModal = ({
  mutationPermissions,
  accessToMutations,
  choices,
  handleClose,
  isBulkEdit,
  multipleId,
  locationId,
  variables,
  locationMultiple = false,
  locationIds,
  chowlyAPIkey,
  permissions,
  isPriceCapApplicable,
}) => {
  const [currentTab, setCurrentTab] = useState(false);
  const handleTabChange = () => {
    setCurrentTab(prevState => !prevState);
  };
  const tabsViewData = [
    {
      label: 'Choices Settings',
      value: false,
      component: (
        <ChoicesSettings
          choices={choices}
          handleClose={handleClose}
          multipleId={multipleId}
          isBulkEdit={isBulkEdit}
          locationId={locationId}
          variables={variables}
          locationMultiple={locationMultiple}
          locationIds={locationIds}
          chowlyAPIkey={chowlyAPIkey}
          permissions={permissions}
          accessToMutations={accessToMutations}
          mutationPermissions={mutationPermissions}
          isPriceCapApplicable={isPriceCapApplicable}
        />
      ),
      id: '519e0a6f-598d-4733-a5e5-6069a856637c',
    },
    // {
    //   label: "Item List",
    //   value: true,
    //   component: <ItemList/>,
    //   id: "519e0a6f-598d-4733-a5e5-6069a856637c"
    // }
  ];

  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {tabsViewData.map(tab => (
          <Tab label={tab.label} value={tab.value} key={tab.id} />
        ))}
      </Tabs>
      {tabsViewData.map(tabPanel => (
        <TabPanel value={currentTab} index={tabPanel.value} key={tabPanel.id}>
          {tabPanel.component}
        </TabPanel>
      ))}
    </>
  );
};

export default MultipleModal;
