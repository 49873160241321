import { gql } from '@apollo/client';

export const fieldgroupFragment = {
  fieldgroup: gql`
    fragment FieldgroupData on Fieldgroup {
      id
      fieldgroupId
      label
    }
  `,
};
