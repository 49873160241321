import { gql } from '@apollo/client';

const CHOICES = gql`
  query choices(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [ChoiceSort]
    $filter: [ChoiceFilter]
  ) {
    viewer {
      id
      choiceConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        sort: $sort
        search: $search
        filter: $filter
        fieldGroupOnly: 7
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            choiceId
            label
            description
            cost
            choiceimageConnection {
              edges {
                node {
                  id
                  url
                }
              }
            }
            choiceIngredientConnection {
              edges {
                node {
                  id
                  quantity
                  itemId
                  itemMenuLink
                  measure {
                    measureId
                    label
                    abbreviation
                  }
                  recipe {
                    inventoryitem {
                      inventoryitemId
                      measureId
                      label
                      inventoryitemMeasureConnection {
                        edges {
                          node {
                            measureId
                            measure {
                              id
                              measureId
                              abbreviation
                              label
                            }
                          }
                        }
                      }
                    }
                  }
                  ingredient {
                    id
                    ingredientId
                    inventoryitem {
                      inventoryitemId
                      measureId
                      label
                      inventoryitemMeasureConnection {
                        edges {
                          node {
                            measureId
                            measure {
                              id
                              measureId
                              abbreviation
                              label
                            }
                          }
                        }
                      }
                    }

                    measure {
                      id
                      measureId
                      abbreviation
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CHOICES;
