import { gql } from '@apollo/client';

const CREATE_TIP_PAYMENT = gql`
  mutation createTipPaymentV2(
    $quoteId: ID!
    $gatewayId: ID
    $tenderId: ID
    $tip: Int!
    $source: String
    $chargeCustomer: Boolean
    $giftCardNumber: String
    $confirmPaymentIntentId: String
  ) {
    createTipPaymentV2(
      input: {
        quoteId: $quoteId
        gatewayId: $gatewayId
        tenderId: $tenderId
        tip: $tip
        source: $source
        chargeCustomer: $chargeCustomer
        giftCardNumber: $giftCardNumber
        confirmPaymentIntentId: $confirmPaymentIntentId
      }
    ) {
      payment {
        id
        paymentId
        tender {
          label
        }
        refunded
        amount
        created
        paymentGatewayConnection(first: 1) {
          edges {
            node {
              label
              disputeReason
              disputeStatus
              disputeDueBy
              paymentDetails {
                customerIPAddress
                billingAddress
                submissionCount
                isRefundable
                uncategorizedText
                customerCommunication
                receipt
                uncategorizedFile
              }
            }
          }
        }
      }
      requiresAction {
        paymentIntentId
        paymentIntentClientSecret
      }
    }
  }
`;

export default CREATE_TIP_PAYMENT;
