import { gql } from '@apollo/client';

const UPDATE_WAGE_RULE = gql`
  mutation updateWageRule($input: UpdateWageRuleMutationInput!) {
    updateWageRule(input: $input) {
      clientMutationId
    }
  }
`;

export default UPDATE_WAGE_RULE;
