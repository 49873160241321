import { gql } from '@apollo/client';

const CREATE_INVENTORY_ITEM_CATEGORY = gql`
  mutation CREATE_INVENTORY_ITEM_CATEGORY($input: CreateinventoryitemcategoryInput!) {
    createInventoryitemcategory(input: $input) {
      viewer {
        id
      }
      inventoryitemcategory {
        id
      }
    }
  }
`;

export default CREATE_INVENTORY_ITEM_CATEGORY;
