import { gql } from '@apollo/client';

const CHARGEBACK_RESPOND = gql`
mutation ChargebackRespond($input: ChargebackRespondMutationInput!){
    chargebackRespond(input: $input){
        viewer{
            id
        },
        quote {
            id
        }
    }
}
`

export default CHARGEBACK_RESPOND;