import { gql } from '@apollo/client';

export const CREATE_UPDATE_LOCATION_SUBCATEGORY = gql`
  mutation createLocationSubcategoryWeekday(
    $input: CreateLocationSubcategoryWeekdayMutationInput!
  ) {
    createLocationSubcategoryWeekday(input: $input) {
      subcategoryEdge {
        node {
          id
          subcategoryId
          label
          description
          isAvailableOnline
          isAvailablePos
          isAvailable3pv
          startDate
          startTime
          endDate
          endTime
          timezoneId
          subcategoryWeekdayConnection {
            edges {
              node {
                id
                subcategoryId
                weekdayId
                startTime
                endTime
              }
            }
          }
          locationSubcategoryConnection {
            edges {
              node {
                id
                subcategoryId
                locationId
                available
                startTime
                endTime
              }
            }
          }
          locationSubcategoryWeekdayConnection {
            edges {
              node {
                id
                locationId
                subcategoryId
                weekdayId
                startTime
                endTime
              }
            }
          }
        }
      }
    }
  }
`;
