import moment from 'moment';
import React from 'react';
import { Grid } from '@material-ui/core';

import { FETCH_QUOTE_ACTION_LOGS, FETCH_LOG_ACTIONS } from '../../../../../apollo/queries';
import { ConnectionTable, DateRange, SelectFactory } from '../../../../blocks';

function QuotecateringLogsView() {
  return (
    <div>
      <ConnectionTable
        title={'Quotecatering logs'}
        customActionsCenter={(data, { refetch, variables }) => {
          return (
            <Grid container justify="flex-end" spacing={3}>
              <Grid item xs={3}>
                <SelectFactory
                  label={'Actions'}
                  placeholder={'Select Actions'}
                  query={FETCH_LOG_ACTIONS}
                  filter={{
                    type: {
                      eq: 'CATERING',
                    },
                  }}
                  disableCloseOnSelect={true}
                  structureData={data => {
                    return data?.viewer?.logActionConnection?.edges.map((logAction, index) => {
                      return {
                        label: logAction.node.label,
                        type: logAction.node.type,
                        id: logAction.node.logActionId,
                        index,
                      };
                    });
                  }}
                  onSelect={values => {
                    const logActionIds = values.map(logAction => logAction.id);
                    if (values.length === 0) {
                      const filters = variables.filter;
                      delete filters.logAction;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          logAction: {
                            logActionId: {
                              in: logActionIds,
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <DateRange variables={variables} refetch={refetch} fieldName={'timestamp'} />
              </Grid>
            </Grid>
          );
        }}
        query={FETCH_QUOTE_ACTION_LOGS}
        initialQueryVariables={{
          first: 10,
          filter: {
            logAction: {
              type: {
                eq: 'CATERING',
              },
            },
          },
          sort: {
            timestamp: 'DESC',
          },
        }}
        structureTable={data =>
          data?.viewer?.actionLogQuoteConnection?.edges?.map(edge => edge.node) || []
        }
        columns={[
          {
            disableSort: true,
            title: 'Quote Id',
            field: 'quote',
            render: quote => quote.quoteId,
          },
          {
            disableSort: true,
            title: 'Action',
            field: 'logAction',
            render: logAction => logAction.label,
          },
          {
            title: 'Description',
            field: 'description',
          },
          {
            title: 'Timestamp',
            field: 'timestamp',
            render: timestamp =>
              moment
                .utc(timestamp)
                .local()
                .format('YYYY-MM-DD h:mm A'),
          },
          {
            title: 'Person',
            field: 'creator',
            render: creator => {
              return <>{`${creator.firstName} ${creator.lastName}`}</>;
            },
          },
        ]}
      />
    </div>
  );
}

export default QuotecateringLogsView;
