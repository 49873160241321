import React, { useEffect, useState } from "react";
import {
  Select,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Chip,
} from "@material-ui/core";
import { Elements, Modal } from "../../../../../../blocks";
import { Controller } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import ItemSelect from "./ItemSelect";
import CategorySelect from "./CategorySelect";
import HandoffSelect from "./HandoffsSelect";
import CurrencyInput from "../../../../../../blocks/CurrencyInput";
import { CurrencyInputFormat, convert } from "../../../../../../../utils";
import AddItemsModal from "../../DiscountRestrictions/Items/AddItemsModal";
import SelectItemsNew from "./ItemSelect/ItemSelectNew";
import SelectSubcategoriesNew from "./CategorySelect/SubcategorySelectNew";

const options = [
  { value: "dollar", label: "Dollar - $" },
  { value: "percent", label: "Percentage - %" },
  { value: "freeItem", label: "Free Item" },
];

function DiscountType({
  control,
  itemAddedNumberTimes,
  setItemAddedNumberTimes,
  discountDetailsItems,
  setDiscountDetailsItems,
  coupon,
  errors,
  setSelectedSubcategoriesAction,
  selectedSubcategoriesAction,
}) {
  const [discountType, setDiscountType] = useState("dollar");
  const [aplicateTo, setAplicateTo] = useState("item");

  const [openModal, setOpenModal] = useState(false);
  const [openSubcategoryModal, setOpenSubcategoryModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleOpenSubcategoryModal = () => {
    setOpenSubcategoryModal(true);
  };

  const handleCloseSubcategoryModal = () => {
    setOpenSubcategoryModal(false);
  };

  const decideOnCouponActionFromServer = (couponAction) => {
    if (couponAction.couponactionId === 1) {
      setDiscountType("dollar");
      setAplicateTo("order");
    }
    if (couponAction.couponactionId === 2) {
      setDiscountType("percent");
      setAplicateTo("order");
    }
    if (couponAction.couponactionId === 3) {
      setDiscountType("dollar");
      setAplicateTo("item");
    }
    if (couponAction.couponactionId === 4) {
      setDiscountType("percent");
      setAplicateTo("item");
    }
    if (couponAction.couponactionId === 5) {
      setDiscountType("dollar");
      setAplicateTo("category");
    }
    if (couponAction.couponactionId === 6) {
      setDiscountType("percent");
      setAplicateTo("category");
    }
    if (couponAction.couponactionId === 7) {
      setDiscountType("dollar");
      setAplicateTo("handoff");
    }
    if (couponAction.couponactionId === 8) {
      setDiscountType("percent");
      setAplicateTo("handoff");
    }
    if (couponAction.couponactionId === 9) {
      setDiscountType("freeItem");
    }
  };

  useEffect(() => {
      if(coupon.couponActionConnection.edges.length) {
          decideOnCouponActionFromServer(coupon.couponActionConnection.edges[0].node);
      }
  }, []);

  const renderFormFileds = () => {
    switch (discountType) {
      case "dollar":
        return (
          <CurrencyInput
            rules={{
              required: "Amount is required",
              validate: {
                positive: (value) => {
                  if (typeof value === "number") {
                    if (value >= 0) {
                      return true;
                    }
                  }
                  const price = CurrencyInputFormat(value);
                  const isPositive = price >= 0;
                  return isPositive;
                },
              },
            }}
            error={errors?.discountDetails && errors?.discountDetails?.amount}
            helperText={
              errors?.discountDetails && errors?.discountDetails?.amount
                ? errors?.discountDetails?.amount?.message
                : ""
            }
            control={control}
            name="discountDetails.amount"
            defaultValue={coupon.couponActionConnection.edges.length ? `${convert.centsToDollars(
              `${coupon.couponActionConnection.edges[0].node.valueOff}`
            )}` : ''}
            label="Amount"
          />
        );
      case "percent":
        return (
          <Controller
            name="discountDetails.amount"
            rules={{
              required: "amount is required",
              min: 1,
            }}
            defaultValue={coupon.couponActionConnection.edges[0].node.valueOff}
            control={control}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <TextField
                rules={{
                  required: "Amount is required",
                }}
                error={
                  errors?.discountDetails && errors?.discountDetails?.amount
                }
                helperText={
                  errors?.discountDetails && errors?.discountDetails?.amount
                    ? errors?.discountDetails?.amount?.message
                    : ""
                }
                style={{ width: "150px" }}
                onChange={onChange}
                type="number"
                value={value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 1,
                }}
                label="Value"
                variant="outlined"
              />
            )}
          />
        );
      case "freeItem":
        if (aplicateTo === "item") {
          return (
            <Controller
              name="discountDetails.quantity"
              rules={{
                required: "Qunatity is required",
              }}
              defaultValue={
                coupon.couponActionConnection.edges[0].node.valueOff
              }
              control={control}
              render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty }
              ) => (
                <TextField
                  rules={{
                    required: "Quantity is required",
                  }}
                  error={
                    errors?.discountDetails && errors?.discountDetails?.quantity
                  }
                  helperText={
                    errors?.discountDetails && errors?.discountDetails?.quantity
                      ? errors?.discountDetails?.quantity?.message
                      : ""
                  }
                  style={{ width: "150px" }}
                  onChange={onChange}
                  type="number"
                  value={value}
                  inputProps={{
                    min: 1,
                  }}
                  label="Quantity"
                  variant="outlined"
                />
              )}
            />
          );
        }
        return "";
      default:
        return "";
    }
  };

  const renderAplicateToInputs = () => {
    switch (aplicateTo) {
      case "order":
        return "";
      case "item":
        return (
          <div>
            <div>
              <Button
                onClick={handleOpen}
                variant="outlined"
                style={{ width: "13rem" }}
              >
                + Add Items
              </Button>
            </div>
            {/* <div style={{ display: "none" }}>
              <ItemSelect
                errors={errors}
                isEvent={true}
                coupon={coupon}
                control={control}
                setItem={setItemAddedNumberTimes}
                discountDetailsItems={discountDetailsItems}
                setDiscountDetailsItems={setDiscountDetailsItems}
              />
            </div> */}
          </div>
        );
      case "category":
        return (
          <div>
            <div>
              <Button
                onClick={handleOpenSubcategoryModal}
                variant="outlined"
                style={{ width: "13rem" }}
              >
                + Add Subcategories
              </Button>
            </div>
          </div>
        );
      case "handoff":
        return (
          <HandoffSelect errors={errors} coupon={coupon} control={control} />
        );
      default:
        return "";
    }
  };

  const renderMenuItems = () => {
    const full = [
      <MenuItem value="item">Item</MenuItem>,
      <MenuItem value="order">Order</MenuItem>,
      <MenuItem value="category">Subategory</MenuItem>,
      <MenuItem value="handoff">Handoffs Charge</MenuItem>,
    ];

    const item = [<MenuItem value="item">Item</MenuItem>];

    switch (discountType) {
      case "dollar":
        return full;
      case "percent":
        return full;
      case "freeItem":
        setAplicateTo("item");
        return item;
      default:
        return full;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          gap: "1rem",
        }}
      >
        <div style={{ marginRight: 10 }}>
          <Controller
            name="discountDetails.discountType"
            control={control}
            defaultValue={discountType}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <Elements.ToggleButton
                options={options}
                value={value}
                handleToggleClick={(value) => {
                  setDiscountType(value);
                  onChange(value);
                  if (value === "freeItem") {
                    setAplicateTo("item");
                  }
                }}
              />
            )}
          />
        </div>
        {renderFormFileds()}
        {discountType !== "freeItem" && (
          <Controller
            name="discountDetails.aplicateTo"
            control={control}
            defaultValue={aplicateTo}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Applicate to
                </InputLabel>
                <Select
                  style={{ width: 250 }}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setAplicateTo(e.target.value);
                  }}
                  label="Applicate to"
                >
                  {renderMenuItems()}
                </Select>
              </FormControl>
            )}
          />
        )}
        {renderAplicateToInputs()}
      </div>
      {aplicateTo === "item" && (
        <SelectItemsNew
          discountDetailsItems={discountDetailsItems}
          setDiscountDetailsItems={setDiscountDetailsItems}
          coupon={coupon}
        />
      )}
      {aplicateTo === "category" && (
        <SelectSubcategoriesNew
          discountDetailsItems={selectedSubcategoriesAction}
          setDiscountDetailsItems={setSelectedSubcategoriesAction}
          coupon={coupon}
        />
      )}

      <Modal open={openModal} handleClose={handleClose} title="Add Items">
        <AddItemsModal
          setExcludedItems={setDiscountDetailsItems}
          handleClose={handleClose}
          excludedItems={discountDetailsItems}
        />
      </Modal>
      <Modal
        open={openSubcategoryModal}
        handleClose={handleCloseSubcategoryModal}
        title="Add Subcategories"
      >
        <CategorySelect
          setSelectedSubcategoriesAction={setSelectedSubcategoriesAction}
          selectedSubcategoriesAction={selectedSubcategoriesAction}
          handleClose={handleCloseSubcategoryModal}
        />
      </Modal>
    </div>
  );
}

export default DiscountType;
