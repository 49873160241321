import { gql } from '@apollo/client';
import { itemtypeFragment } from "../../fragments";

const UPDATE_ITEM_TYPE = gql`
  mutation updateItemtype($input: updateItemtypeMutationInput!) {
    updateItemtype(input: $input) {
      viewer {
        id
      }
      itemtype {
        ...ItemtypeData
      }
    }
  }
  ${itemtypeFragment.itemtype}
`;

export default UPDATE_ITEM_TYPE;
