import { Box } from '@material-ui/core';
import React from 'react';
import DragAndDropInput from '../../../../../../blocks/DragAndDropInput';
import InventoryItem from './InventoryItem';

const initialInventoryItem = {
  InventoryItemId: '',
};

const InventoryItems = ({ control, getValues, errors, duplicateInventoryItemIds }) => {
  return (
    <Box style={{ width: '100%' }}>
      <DragAndDropInput
        name="inventoryItems"
        control={control}
        title="Inventory Items"
        addItemButtonText="Add Inventory Item"
        initialItem={initialInventoryItem}
        enableEmpty={false}
        emptyErrorText="At least one Inventory Item is required"
        itemComponent={InventoryItem}
        itemComponentProps={{
          duplicateInventoryItemIds,
          errors,
          getValues,
          control,
        }}
      />
    </Box>
  );
};

export default InventoryItems;
