import React, { useContext } from 'react';

import MinimumWages from './MinimumWages';
import Jobs from "./Jobs/view";
import PosAccess from './PosAccess';
import JobBreak from "./JobBreak";
import { CoreContext } from '../../../../CoreContext';

const LaborSettings = () => {
  const context = useContext(CoreContext);
  const mutationPermissions = context?.coreUtils?.getAppActions()[59]
    ?.mutations;

  return <div>
    <MinimumWages mutationPermissions={mutationPermissions} />
    <br/>
    <Jobs mutationPermissions={mutationPermissions} />
    <br/>
    <PosAccess mutationPermissions={mutationPermissions} />
    <br/>
    <JobBreak mutationPermissions={mutationPermissions} />
  </div>
};

export default LaborSettings;
