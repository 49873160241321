import React, { useState, useEffect, useMemo } from 'react';
import {
  Grid,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { AddItemsStyles } from './style';
import { SelectFactory } from '../../blocks';
import { FETCH_ITEM_LIST } from '../../../apollo/queries';

const AddItemsModal = ({
  menuId,
  categoryId,
  setCrossSellItems,
  handleClose,
  crossSellItems,
  itemSelectionFilter,
  setItemSelectionFilter,
  menuData,
}) => {
  console.log(categoryId, 'catId');
  const classes = AddItemsStyles();
  const [selectedItems, setSelectedItems] = useState([...crossSellItems]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(Number(categoryId));
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);

  useEffect(() => {
    if (itemSelectionFilter?.subcategoryId) {
      setSelectedSubcategoryId(itemSelectionFilter.subcategoryId?.eq);
    }
  }, []);

  const menu = useMemo(
    () =>
      menuData?.viewer?.menuConnection?.edges.filter(
        menu => menu?.node?.menuId === Number(menuId),
      )[0]?.node,
    [menuData],
  );

  const menuLabel = useMemo(() => {
    let label = 'Menu: Loading...';

    if (menu) {
      label = `Menu: ${menu.label} - ID_${menu.menuId}`;
    }

    return label;
  }, [menu]);

  const categories = useMemo(() => {
    if (menu) {
      return menu?.menuCategoryConnection?.edges?.map(menuCategory => menuCategory?.node?.category);
    }
  }, [menu]);

  const subcategories = useMemo(() => {
    if (selectedCategoryId) {
      return categories
        ?.filter(category => category.categoryId === selectedCategoryId)[0]
        ?.subcategoryConnection?.edges?.map(subcategory => subcategory?.node)
        ?.filter(
          subcategory => subcategory.menuId === Number(menuId) && subcategory.isAvailableOnline,
        );
    }
  }, [categories, selectedCategoryId]);

  const handleSave = () => {
    setCrossSellItems(selectedItems);
    handleClose();
  };

  useEffect(() => {
    const filter = {
      showHiddenToLocation: {
        eq: true,
      },
    };

    filter.menuId = { eq: menuId };

    if (selectedCategoryId) {
      filter.categoryId = { eq: selectedCategoryId };
    }

    if (selectedSubcategoryId) {
      filter.subcategoryId = { eq: selectedSubcategoryId };
    }

    setItemSelectionFilter(filter);
  }, [menuId, selectedCategoryId, selectedSubcategoryId]);

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.typography}>
            {menuLabel}
          </Typography>
        </Grid>

        {(selectedItems.length > 0 || menu) && (
          <Grid item xs={12} className={classes.dropdowns}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="categories">Category</InputLabel>
              <Select
                labelId="categories"
                items={categories}
                label="Categories"
                variant="outlined"
                disabled={true}
                fullWidth
                onChange={event => {
                  setSelectedCategoryId(event.target.value);
                  setSelectedSubcategoryId(null);
                }}
                value={selectedCategoryId || ''}
              >
                {categories?.map(category => (
                  <MenuItem key={category.id} value={category.categoryId}>
                    {`${category.label} - ID_${category.categoryId}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {(selectedItems.length > 0 || selectedCategoryId) && (
          <Grid item xs={12} className={classes.dropdowns}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="subcategories">Subcategory</InputLabel>
              <Select
                items={subcategories}
                labelId="subcategories"
                label="Subcategories"
                variant="outlined"
                fullWidth
                onChange={event => {
                  setSelectedSubcategoryId(event.target.value);
                }}
                value={selectedSubcategoryId || ''}
              >
                {subcategories?.map(subcategory => (
                  <MenuItem key={subcategory.id} value={subcategory.subcategoryId}>
                    {`${subcategory.label} - ID_${subcategory.subcategoryId}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {(selectedItems.length > 0 || selectedSubcategoryId) && (
          <Grid item xs={12} className={classes.dropdowns}>
            <SelectFactory
              name="crossSellItems"
              label="Items"
              placeholder="Select Items"
              disableSearchReset
              disableCloseOnSelect={true}
              query={FETCH_ITEM_LIST}
              filter={itemSelectionFilter}
              onSelect={values => {
                setSelectedItems(values);
              }}
              multiple={true}
              defaultValue={selectedItems}
              structureData={data => {
                return data?.viewer?.itemConnection?.edges.map((edge, index) => {
                  return {
                    label: `${edge.node.label} - ID_${edge.node.itemId}`,
                    itemId: edge.node.itemId,
                    value: edge.node.id,
                    index,
                    id: edge.node.id,
                  };
                });
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid xs={12}>
        <Button onClick={handleSave} className={classes.button}>
          Add
        </Button>
      </Grid>
    </>
  );
};

export default AddItemsModal;
