import { makeStyles } from '@material-ui/core';
import { SportsRugbySharp } from '@material-ui/icons';
const Styles = theme => ({
  itemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0.5rem 0',
  },
  buttonsDiv: {
    display: 'inline-flex',
  },
  triggerContent: {
    margin: '1rem 0',
  },
  itemButton: {
    marginBottom: '1rem',
  },
  itemChip: {
    margin: '0.2rem 0',
  },
  radioBtn: {
    color: 'rgba(0, 0, 0, 0.34)',
  },
});

export const ItemInCartStyle = makeStyles(Styles);
