import { Grid, TextField } from '@material-ui/core';
import React from 'react';

const Instructions = ({ register }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          inputRef={register()}
          fullWidth
          multiline
          label="Preparation Notes"
          name="preparationNotes"
          variant="outlined"
          placeholder="Preparation Notes"
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputRef={register()}
          fullWidth
          multiline
          label="Service Standards"
          name="serviceStandards"
          variant="outlined"
          placeholder="Service Standards"
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputRef={register()}
          fullWidth
          multiline
          label="Storage & Holding Procedures"
          name="storageAndHoldingProcedures"
          variant="outlined"
          placeholder="Storage & Holding Procedures"
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default Instructions;
