import { gql } from '@apollo/client';
import { locationHandoffCategoryFragment } from '../fragments';

const ORDER_SETTINGS_HANDOFF = gql`
  query orderSettingsHandoff(
    $locationHandoffFilter: [LocationHandoffCategoryFilter]
    $categoryFilter: [CategoryFilter]
    $id: ID!
  ) {
    viewer {
      id
      location(id: $id, fieldGroup: 18) {
        permissions
        id
        burqId
        ordersInTimeslot
      }
      categoryConnection(filter: $categoryFilter, fieldGroup: 18) {
        permissions
        edges {
          node {
            id
            label
            categoryId
          }
        }
      }

      handoffConnection(fieldGroup: 18) {
        permissions
        totalCount
        edges {
          node {
            id
            label
            handoffId
          }
        }
      }

      locationHandoffCategoryConnection(filter: $locationHandoffFilter, fieldGroup: 18) {
        permissions
        edges {
          node {
            ...LocationHandoffCategoryData
          }
        }
      }
    }
  }
  ${locationHandoffCategoryFragment.locationHandoffCategory}
`;

export default ORDER_SETTINGS_HANDOFF;
