import React from 'react';
import Disputes from "../DisputesList"

const DisputesView = () => {
  return (
      <Disputes />
  );
};

export default DisputesView;
