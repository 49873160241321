import { gql } from '@apollo/client';

const UPDATE_TIER = gql`
  mutation updateTier($input: UpdateTierMutationInput!) {
    updateTier(input: $input) {
      clientMutationId
    }
  }
`;

export default UPDATE_TIER;
