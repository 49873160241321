import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const FactInformation = ({ register }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ fontSize: '1.25rem' }}>
          FACT Information
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          inputRef={register()}
          fullWidth
          label="Flavor"
          name="flavor"
          variant="outlined"
          placeholder="Flavor"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          inputRef={register()}
          fullWidth
          label="Appearance"
          name="appearance"
          variant="outlined"
          placeholder="Appearance"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          inputRef={register()}
          fullWidth
          label="Consistency"
          name="consistency"
          variant="outlined"
          placeholder="Consistency"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          inputRef={register()}
          fullWidth
          label="Temperature"
          name="temperature"
          variant="outlined"
          placeholder="Temperature"
        />
      </Grid>
    </Grid>
  );
};

export default FactInformation;
