import { gql } from '@apollo/client';

const CREATE_OVERTIMERULES = gql`
  mutation CreateOvertimerules($input: CreateOvertimerulesMutationInput!) {
    createOvertimerules(input: $input) {
      overtimerule{
        id
      }
    }
  }
`;

export default CREATE_OVERTIMERULES;
