import { gql } from '@apollo/client';

const components = gql`
  fragment ComponentsData on RecipeComponents {
    componentId
    orderKey
    name
    count
    measure
    measures {
      measureId
      conversion
      measureLabel
      measureAbbreviation
    }
    preparation
    type
  }
`;

const recipeEquipment = gql`
  fragment EquipmentData on RecipeEquipment {
    equipmentId
    orderKey
    name
    count
    note
  }
`;

const inventoryItem = gql`
  fragment InventoryItemData on Inventoryitem {
    inventoryitemId
    measureId
    showOnLocation
    allowAutomaticStockToggle
    measure {
      abbreviation
    }
    inventoryitemcategoryId
    label
    description
    inventoryStatus
    cost
    calorie
    type
    partOfWastelog
    inventoryitemMeasureConnection {
      edges {
        node {
          conversion
          measure {
            measureId
            abbreviation
            label
          }
        }
      }
    }
    inventoryitemPrep {
      id
      cspm
      size
      sizeCooked
      format
      partOfClosingInventory
      hasStrategic
      averageCasePackInfo
      smokeTodayForTomorrow
    }
    inventoryitemCustomCategory {
      id
      inventoryitemCustomCategoryId
      label
    }
    brands {
      brandId
      label
    }
  }
`;

const recipeImage = gql`
  fragment RecipeImageData on RecipeimageConnection {
    edges {
      node {
        id
        recipeimageId
        recipeId
        type
        url
      }
    }
    totalCount
  }
`;

const recipeType = gql`
  fragment RecipeTypeData on Recipetype {
    recipetypeId
    label
    description
  }
`;

const inventoryitemcategory = gql`
  fragment InventoryItemCategoryData on Inventoryitemcategory {
    id
    inventoryitemcategoryId
    label
  }
`;

const recipe = gql`
  fragment RecipeData on Recipe {
    id
    recipeId
    recipetypeId
    yieldQuantity
    serviceStandards
    storageAndHoldingProcedures
    logoUrl
    preparationNotes
    shelfLife
    prepTime
    flavor
    appearance
    consistency
    temperature
    inventoryitem {
      id
      cost
      inventoryitemcategoryId
      inventoryitemcategory {
        ...InventoryItemCategoryData
      }
      wasteLogMeasureOverrideId
      ...InventoryItemData
    }
    recipeimageConnection {
      ...RecipeImageData
    }
    recipetype {
      ...RecipeTypeData
    }

    equipment {
      ...EquipmentData
    }
    components {
      ...ComponentsData
    }
    recipeMeasureConnection {
      edges {
        node {
          measureId
          isForCount
          conversion
        }
      }
    }
  }
  ${components}
  ${inventoryItem}
  ${recipeEquipment}
  ${recipeImage}
  ${recipeType}
  ${inventoryitemcategory}
`;

const recipeFragment = {
  components,
  recipeEquipment,
  inventoryItem,
  recipeImage,
  recipeType,
  inventoryitemcategory,
  recipe,
};

export default recipeFragment;
