import { gql } from "@apollo/client";

const SET_STATUS = gql`
  mutation setStatus($input:  SetStatusMutationInput!) {
    setStatus(input: $input) {
      viewer {
        id
      }
    }
  }
`;
export default SET_STATUS;
