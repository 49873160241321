import { gql } from "@apollo/client";

const TOGGLE_ITEM_MULTIPLE_OPTIONAL = gql`
  mutation toggleItemMultipleOptional($input: toggleItemMultipleOptionalInput!) {
    toggleItemMultipleOptional(input: $input) {
      viewer {
      id
      }
    }
  }
`;

export default TOGGLE_ITEM_MULTIPLE_OPTIONAL;
