import { gql } from '@apollo/client';

const FETCH_RECIPE_CATEGORY_TYPES = gql`
  query FETCH_RECIPE_CATEGORY_TYPES(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [InventoryitemcategorytypeSort]
    $filter: [InventoryitemcategorytypeFilter]
  ) {
    viewer {
      id
      inventoryitemcategorytypeConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 94
      ) {
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
        totalCount
        edges {
          node {
            id
            inventoryitemcategorytypeId
            isCost
            label
            active
            created
          }
        }
      }
    }
  }
`;

export default FETCH_RECIPE_CATEGORY_TYPES;
