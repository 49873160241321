import { gql } from '@apollo/client';

const PERSON_TIMESHIFT_CONNECTION = gql`
  query personTimeshiftConnection(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [locationPersonFilter]
    $timeshiftFilter: [timeshiftFilter]
    $started: String!
    $finished: String!
  ) {
    viewer {
      id
      locationPersonConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        filter: $filter
        fieldGroup: 61
      ) {
        totalCount
        permissions
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            location {
              id
              storeNumber
              workWeekStartId
              overtimerule {
                id
                weeklyOtStart
              }
              address {
                city
                state {
                  label
                  abbreviation
                }
              }
            }
            person {
              personId
              firstName
              lastName
              login {
                loginId
              }
              timeshiftConnection(filter: $timeshiftFilter) {
                edges {
                  node {
                    creator {
                      id
                      firstName
                      lastName
                    }
                    id
                    started
                    personId
                    jobId
                    isFinished
                    disabled
                    created
                    totalPaidBreak
                    totalUnpaidBreak
                    clockedOutBy
                    finished
                    tip
                    wages(started: $started, finished: $finished) {
                      error
                      fee
                      rate
                      hours
                    }
                    overtime(started: $started, finished: $finished) {
                      error
                      hours
                      type
                      fee
                      rate
                    }
                    shiftTotal(started: $started, finished: $finished) {
                      fee
                      error
                    }
                    personJob {
                      salaryType {
                        id
                        salarytypeId
                        label
                      }
                      salaryAmount
                    }
                    location {
                      id
                      timezone {
                        id
                        label
                      }
                    }
                    job {
                      id
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PERSON_TIMESHIFT_CONNECTION;
