
import { gql } from '@apollo/client';

const POS_ACCESS_LIST = gql`
  query PosAccess($first: Int, $search: String, $last: Int, $after: String, $before: String) {
    viewer {
      id
      posRoleConnection(after: $after, first: $first, search: $search, last: $last, before: $before, fieldGroup: 59) {
        permissions
        totalCount
        edges {
          node {
            id
            label
            posRoleId
            description
            active
            posRolePosPermissionConnection{
              edges {
                node {
                  id
                  permission
                  posPermissionId
                  posPermission {
                    id
                    label
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default POS_ACCESS_LIST;