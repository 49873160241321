import { gql } from '@apollo/client';

const REMOVE_TIER = gql`
  mutation removeTier($input: RemoveTierMutationInput!) {
    removeTier(input: $input) {
      clientMutationId
    }
  }
`;

export default REMOVE_TIER;
