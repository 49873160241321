import { gql } from '@apollo/client';

const BULK_TOGGLE_ORDER_MENU_CATEGORY = gql`
  mutation bulkEditGeneralInfo($input: ToggleLocationHandoffCategoryMutationInput!) {
    toggleLocationHandoffCategory(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default BULK_TOGGLE_ORDER_MENU_CATEGORY;