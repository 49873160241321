import { gql } from '@apollo/client';

const REMOVE_LOCATION_EMPLOYEE = gql`
  mutation RemoveLocationEmployee($input: RemoveLocationEmployeeMutationInput!) {
    removeLocationEmployee(input: $input) {
      clientMutationId
    }
  }
`;

export default REMOVE_LOCATION_EMPLOYEE;
