import React, { useEffect, useState } from 'react';
import { Box, ListItem, List, Collapse, Typography, IconButton, Grid } from '@material-ui/core';
import { ExpandLess, ExpandMore, HighlightOff } from '@material-ui/icons';
import NumberInput from '../../../../../../blocks/NumberInput';
import { CurrencyInput } from '../../../../../../blocks';
import { convert, CurrencyInputFormat } from '../../../../../../../utils';

const InventoryItem = ({
  id,
  item: inventoryItem,
  setValue,
  register,
  errors,
  onRemoveItem,
  control,
  unregister,
  autoFocus,
}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    register(`${id}.measureId`);
    register(`${id}.ingredientId`);

    setValue(`${id}.measureId`, inventoryItem.measureId);
    setValue(`${id}.ingredientId`, inventoryItem.ingredientId);

    return () => {
      unregister(`${id}.ingredientId`);
      unregister(`${id}.measureId`);
    };
  }, [inventoryItem, setValue, register]);

  return (
    <List
      style={{
        margin: '0 -16px',
        width: 'calc(100% + 32px)',
      }}
    >
      <ListItem
        role="button"
        onClick={() => setOpen(prev => !prev)}
        style={{ background: '#F8F8FA', padding: '8px 16px' }}
      >
        <Box
          style={{
            minWidth: '100%',
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body1">{inventoryItem.label}</Typography>
          <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
        </Box>
      </ListItem>
      <Collapse style={{ padding: '0 16px' }} in={open} timeout="auto" unmountOnExit={false}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="body2">Item#: {inventoryItem.ingredientId}</Typography>
          </div>
          <div>
            <IconButton onClick={onRemoveItem}>
              <HighlightOff />
            </IconButton>
          </div>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <CurrencyInput
              fullWidth
              autoFocus={autoFocus}
              control={control}
              defaultValue={`$${inventoryItem?.cost}`}
              error={!!errors[id]?.cost}
              helperText={
                errors[id]?.cost?.type === 'required'
                  ? 'Value is required'
                  : errors[id]?.cost?.type === 'positive'
                  ? "Value can't be negative"
                  : ''
              }
              id={`${id}.cost`}
              name={`${id}.cost`}
              label="Cost per 1 unit"
              required={true}
              rules={{
                required: 'Cost is required',
                validate: {
                  positive: value => {
                    if (typeof value === 'number') {
                      if (value >= 0) {
                        return true;
                      }
                    }

                    const price = CurrencyInputFormat(value);
                    const isPositive = price >= 0;
                    return isPositive;
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              control={control}
              defaultValue={inventoryItem?.quantity || ''}
              error={!!errors[id]?.quantity}
              helperText={
                errors[id]?.quantity?.type === 'required'
                  ? 'Value is required'
                  : errors[id]?.quantity?.type === 'positive'
                  ? "Value can't be negative"
                  : ''
              }
              id={`${id}.quantity`}
              label={inventoryItem.measure}
              required={true}
            />
          </Grid>
        </Grid>
      </Collapse>
    </List>
  );
};

export default InventoryItem;
