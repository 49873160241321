import { gql } from '@apollo/client';

const OVERTIMERULES = gql`
  query overtimerules(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [OvertimeruleSort]
    $filter: [OvertimeruleFilter]
  ) {
    viewer {
      id
      overtimeruleConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        filter: $filter
        sort: $sort
        fieldGroupOnly: 12
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            overtimeruleId
            name
            weeklyOtStart
            weeklyOtMultiplier
            dailyOtStart
            dailyOtMultiplier
            secondDailyOtStart
            secondDailyOtMultiplier
            seventhDayWhMultiplier
            seventhDayOtStart
            seventhDayOtMultiplier
            resident
            stateConnection{
              edges{
                node{
                  id
                  abbreviation
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OVERTIMERULES;
