import { makeStyles } from '@material-ui/core';

const connectionTableToolbar = theme => ({
  container: {
    // padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minHeight: '150px',
    },
  },
  gridItem: {
    flex: 1,

    '&:last-child': {
      flex: 2,
    },
  },
  select: {
    minWidth: '149px',
  },
  searchInput: {
    width: '100%',
  },
  actionsCenterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  customActionsBottom: {
    width: '100%',
  },
  actionsSelected: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
});

const connectionTableBody = theme => ({
  tableRowRoot: {
    transform: 'scale(1)',
    height: '100%',
  },
  actionTableCell: {
    textAlign: 'right',
    width: 80,
  },
  iconButtonRoot: {
    padding: theme.spacing(1),
  },
  circularProgressCell: {
    border: 'none',
    padding: `${theme.spacing(3)}px 0`,
  },
});

const connectionTableHeader = theme => ({
  headerCell: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
});

export const connectionTableToolbarStyles = makeStyles(connectionTableToolbar);
export const connectionTableBodyStyles = makeStyles(connectionTableBody);
export const connectionTableHeaderStyles = makeStyles(connectionTableHeader);
