import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { endsWith, isEmpty } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { fileReaders } from '../../../utils';

function MyDropzone({
  file,
  setFile,
  disableDelete,
  isImage,
  shouldReadFile,
  multiple = false,
  justify = 'center',
  accept,
  fullWidth,
  fullWidthDropBox,
  shouldRenderImages = true,
  text = "CLICK HERE TO SELECT A FILE AND THE CLICK 'UPLOAD",
}) {
  const onDrop = useCallback(
    async acceptedFiles => {
      if (shouldReadFile) {
        const encoded = await Promise.all(
          acceptedFiles.map(acceptedFile => fileReaders.readFile(acceptedFile)),
        );

        if (multiple) {
          setFile(prevFiles => [
            ...prevFiles,
            ...acceptedFiles.map((acceptedFile, index) => ({
              name: acceptedFile.name,
              encoded: encoded[index],
              url: URL.createObjectURL(acceptedFile),
              type: acceptedFile.type,
            })),
          ]);
        } else {
          setFile({
            name: acceptedFiles[0].name,
            encoded: encoded[0],
            url: URL.createObjectURL(acceptedFiles[0]),
            type: acceptedFiles[0].type,
          });
        }
      } else {
        setFile(multiple ? acceptedFiles : acceptedFiles[0]);
      }
    },
    [setFile, shouldReadFile, multiple],
  );

  const handleOnRemove = useCallback(
    (e, image) => {
      e.stopPropagation();

      if (!multiple) {
        setFile();
      } else {
        setFile(prevImages => prevImages.filter(prevImage => prevImage.url !== image.url));
      }
    },
    [multiple, setFile],
  );

  const renderImage = useCallback(
    image => {
      if (image?.url) {
        const isPdf = endsWith(image.url, '.pdf') || endsWith(image.name, '.pdf');

        const imagePath = image.url.split('/');

        return (
          <Box
            style={{
              position: 'relative',
              height: isPdf ? 'fit-content' : '110px',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            {!disableDelete ? (
              <Box style={{ position: 'absolute', top: -10, right: -10 }}>
                <IconButton
                  size="small"
                  onClick={e => handleOnRemove(e, image)}
                  style={{ background: 'white' }}
                >
                  <HighlightOff />
                </IconButton>
              </Box>
            ) : null}
            {isPdf ? (
              <Box
                style={{
                  background: '#F0F1F5',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '100%',
                  textAlign: 'center',
                  cursor: 'pointer',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                  {image.name || imagePath[imagePath.length - 1]}
                </Typography>
              </Box>
            ) : (
              <img
                src={image.url || ''}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '4px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  cursor: !multiple ? 'pointer' : 'default',
                }}
                alt="preview"
              />
            )}
          </Box>
        );
      }

      return null;
    },
    [multiple],
  );

  const dropbox = useMemo(() => {
    return (
      <Box
        style={{
          background: '#F0F1F5',
          padding: '24px',
          borderRadius: '10px',
          width: '100%',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        {text}
      </Box>
    );
  }, [text]);

  const renderImages = useMemo(() => {
    if (isImage) {
      if (!multiple && (isEmpty(file) || !shouldRenderImages)) {
        return (
          <Grid container spacing={3} justify={justify}>
            <Grid item xs={fullWidthDropBox ? 12 : 6} sm={fullWidthDropBox ? 12 : 4}>
              {dropbox}
            </Grid>
          </Grid>
        );
      }

      return (
        <Grid container spacing={4} justify={justify}>
          <Grid item xs={12} container spacing={2} align="stretch" justify={justify}>
            {multiple ? (
              file?.map(image => (
                <Grid key={image.url} item xs={fullWidth ? 12 : 6} sm={fullWidth ? 12 : 4}>
                  {renderImage(image)}
                </Grid>
              ))
            ) : (
              <Grid item xs={fullWidth ? 12 : 6} sm={fullWidth ? 12 : 4}>
                {file?.url ? renderImage(file) : dropbox}
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  }, [file, multiple, isImage, renderImage, dropbox]);

  const renderFileNames = useMemo(() => {
    if (!isImage) {
      if (!multiple) {
        return file?.name ? <p>{file?.name}</p> : dropbox;
      }

      return dropbox;
    }

    return null;
  }, [file, dropbox, isImage, multiple]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Dropzone multiple={multiple} onDrop={onDrop} accept={accept}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ width: '100%' }}>
              <input {...getInputProps()} />
              {!isImage ? renderFileNames : !multiple ? renderImages : dropbox}
            </div>
          )}
        </Dropzone>
      </Grid>
      {multiple ? (
        <Grid item xs={12}>
          {renderImages}
        </Grid>
      ) : null}
    </Grid>
  );
}

export default MyDropzone;
