import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import LocationSubcategoryTable from './LocationSubcategory/LocationSubcategoryTable';
import moment from 'moment';
import {
  Container,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ImageUpload from '../../../../../../blocks/ImageUpload';
import GeneralInfoInput from '../GeneralInfoInput';
import {
  CREATE_SUBCATEGORY_WEEKDAY,
  CREATE_UPDATE_LOCATION_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
} from '../../../../../../../apollo/mutations';
import { FETCH_SUBCATEGORY, TIMEZONE_LIST } from '../../../../../../../apollo/queries';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import ItemList from './ItemList';
import DateAndTimeSettings from './DateAndTimeSettings/DateAndTimeSettings';
import AdvancedTimeSettingsModal from './DateAndTimeSettings/AdvancedTimeSettingsModal';
import { CrossSell } from '../../../../../../blocks';

const IMAGE = {
  sizes: ['375x268', 'icon'],
};

const WEEKDAYS = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};
const Subcategory = ({ ids, basePath, classes, refetchMenuList, menuData }) => {
  const { setSnackbar } = useSnackbar();
  const [extendedWeekdays, setExtendedWeekdays] = useState([
    {
      weekdayId: 1,
      name: 'Monday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 2,
      name: 'Tuesday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 3,
      name: 'Wednsday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 4,
      name: 'Thursday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 5,
      name: 'Friday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 6,
      name: 'Saturday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
    {
      weekdayId: 7,
      name: 'Sunday',
      enabled: false,
      startTime: {
        hours: 12,
        minutes: 0,
        meridiem: 'am',
      },
      endTime: {
        hours: 11,
        minutes: 59,
        meridiem: 'pm',
      },
      formatedStartTime: null,
      formatedEndTime: null,
    },
  ]);
  const [locationSubcategories, setLocationSubcategories] = useState([]);
  const [locationSubcategoriesAdvanced, setLocationSubcategoriesAdvanced] = useState([]);
  const [toggledLocationSubcategory, setToggledLocationSubcategory] = useState({});

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAdvancedDate, setOpenAdvancedDate] = useState(false);

  const [showSpecificTime, setShowSpecificTime] = useState(true);
  const [showSpecificWeekday, setShowSpecificWeekday] = useState(true);
  const [hasExtendedWeekdays, setHasExtendedWeekdays] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  const [crossSellItems, setCrossSellItems] = useState([]);

  const { state } = useLocation();

  useEffect(() => {
    setImages(
      IMAGE.sizes.map(size => {
        return {
          size,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find(image => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { handleSubmit, control, register, errors, reset, setValue } = useForm({
    defaultValues: {
      label: '',
      description: '',
      shortName: '',
      isAvailableOnline: false,
      isAvailablePos: false,
      isAvailable3pv: false,
      crossSellItems: [],
    },
  });

  const [updateSubcategory] = useMutation(UPDATE_SUBCATEGORY);
  const [updateSubcategoryWeekday] = useMutation(CREATE_SUBCATEGORY_WEEKDAY);
  const [createUpdateLocationSubacategoryWeekday, { loading: createUpdateLoading }] = useMutation(
    CREATE_UPDATE_LOCATION_SUBCATEGORY,
    {
      onCompleted: () => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Location Time settings updated',
        });
      },
    },
  );

  const { menuId, categoryId, gqlSubcategoryId, subcategoryId } = ids;

  const { data: timezoneData, error: timezoneError, loading: timezoneLoading } = useQuery(
    TIMEZONE_LIST,
  );

  // Enable global day selection
  const handleSwitchChange = checked => {
    setShowSpecificWeekday(checked);
    setShowSpecificTime(false);
    if (!checked) {
      setShowSpecificWeekday(true);
      setShowSpecificTime(true);
    }
  };

  const handleSpecficTimeChange = checked => {
    setShowSpecificTime(checked);
    setShowSpecificWeekday(false);
    if (!checked) {
      setShowSpecificWeekday(true);
      setShowSpecificTime(true);
    }
  };

  const handleOnSpecificLocationDaysChange = (
    days,
    locationId,
    globalEnable,
    isLocationAvailable,
  ) => {
    const exists = locationSubcategoriesAdvanced.filter(
      location => location.locationId === locationId,
    );
    if (exists.length) {
      const locationForUpdateIndex = locationSubcategoriesAdvanced.indexOf(exists[0]);
      locationSubcategoriesAdvanced[locationForUpdateIndex].days = days;
      locationSubcategoriesAdvanced[locationForUpdateIndex].enabled = globalEnable;
      locationSubcategoriesAdvanced[locationForUpdateIndex].available = isLocationAvailable;
      setLocationSubcategoriesAdvanced([...locationSubcategoriesAdvanced]);
      return;
    } else {
      const newLocation = {
        locationId,
        enabled: globalEnable,
        available: isLocationAvailable,
        days,
      };
      setLocationSubcategoriesAdvanced([...locationSubcategoriesAdvanced, newLocation]);
    }
  };

  const toggleEnableAdvancedLocationDays = (enabled, locationId) => {
    const exists = locationSubcategoriesAdvanced.filter(
      location => location.locationId === locationId,
    );
    if (exists.length) {
      const locationForUpdateIndex = locationSubcategoriesAdvanced.indexOf(exists[0]);
      locationSubcategoriesAdvanced[locationForUpdateIndex].enabled = enabled;
      if (!enabled) {
        locationSubcategoriesAdvanced[locationForUpdateIndex].days.forEach(
          day => (day.enabled = false),
        );
      }
      setLocationSubcategoriesAdvanced([...locationSubcategoriesAdvanced]);
      return;
    } else {
      const newLocation = {
        locationId,
        enabled,
        days,
      };
      setLocationSubcategoriesAdvanced([...locationSubcategoriesAdvanced, newLocation]);
    }
  };

  const { data, error, loading } = useQuery(FETCH_SUBCATEGORY, {
    variables: {
      id: gqlSubcategoryId || state?.gqlSubcategoryId,
      sort: {
        orderKey: 'ASC',
      },
      subcategoryCrosssellSort: {
        created: 'ASC',
      },
      filter: {
        showHiddenToLocation: { eq: true },
        showUnavailableOnline: { eq: true },
      },
    },
    onCompleted(data) {
      const weekdays = data.viewer.subcategory.subcategoryWeekdayConnection.edges;
      const hasCustomWeekdayTimeRange = weekdays.filter(day => day.startTime !== null);

      if (data.viewer.subcategory) {
        if (data.viewer.subcategory.startTime) {
          setShowSpecificWeekday(false);
          setShowSpecificTime(true);
          setValue('enableTimeSelection', true);
        }

        if (hasCustomWeekdayTimeRange.length) {
          setShowSpecificWeekday(false);
          setShowSpecificTime(false);
        }

        setCrossSellItems(
          data.viewer.subcategory.subcategoryCrosssellConnection.edges.map((edge, index) => ({
            label: `${edge.node.crosssellItem.label} - ID_${edge.node.crosssellItem.itemId}`,
            itemId: edge.node.crosssellItem.itemId,
            value: edge.node.crosssellItem.id,
            index,
            id: edge.node.crosssellItem.id,
          })),
        );

        data.viewer.subcategory.subcategoryimageConnection.edges.forEach(({ node }) => {
          const index = images.findIndex(image => Object.values(image).includes(node.label));

          if (index !== -1) {
            setImages(images => {
              const image = {
                name: '',
                description: node.description || '',
                encoded: '',
                size: node.label,
                url: node.url || '',
              };

              images[index] = image;

              return [...images];
            });
          }
        });

        reset(data.viewer.subcategory);

        let hasExtendedWeekdays = false;

        setHasExtendedWeekdays(false);

        extendedWeekdays.forEach((day, i) => {
          weekdays.forEach(edge => {
            if (edge.node.weekdayId === day.weekdayId) {
              if (!edge.node.startTime) {
                return;
              }

              extendedWeekdays[i].formatedStartTime = edge.node.startTime;
              extendedWeekdays[i].formatedEndTime = edge.node.endTime;

              extendedWeekdays[i].enabled = true;

              extendedWeekdays[i].startTime = {
                hours: moment(day.formatedStartTime.split(':')[0], 'h')
                  .format('LT')
                  .split(':')[0],
                minutes: +day.formatedStartTime.split(':')[1],
                meridiem: moment(day.formatedStartTime, 'h')
                  .format('LT')
                  .split(' ')[1]
                  .toLowerCase(),
              };

              extendedWeekdays[i].endTime = {
                hours: moment(day.formatedEndTime.split(':')[0], 'h')
                  .format('LT')
                  .split(':')[0],
                minutes: +day.formatedEndTime.split(':')[1],
                meridiem: moment(day.formatedEndTime, 'h')
                  .format('LT')
                  .split(' ')[1]
                  .toLowerCase(),
              };

              hasExtendedWeekdays = true;
              setHasExtendedWeekdays(true);
            }
          });
        });

        if (hasExtendedWeekdays) {
          setShowSpecificTime(true);
          setShowSpecificWeekday(true);
        }

        if (weekdays.length) {
          if (!hasExtendedWeekdays) {
            setShowSpecificWeekday(true);
            setShowSpecificTime(true);
          }

          setExtendedWeekdays([...extendedWeekdays]);
        }
      }
    },
  });

  if (loading) return <CircularProgress className={classes.spinner} />;

  if (error) console.log(error);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAdvancedDate = () => {
    setOpenAdvancedDate(false);
  };

  const handleSaveAdvancedDate = () => {
    setOpenAdvancedDate(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const onSubmit = async data => {
    setButtonDisabled(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Updating subcategory...',
    });

    const imagesToUpload = images.map(image => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    const formatedData = {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      timezoneId: null,
      weekdayIds: null,
    };

    const crossSellItemIds = crossSellItems.map(item => item.itemId);

    if (data.startDate) {
      formatedData.startDate = moment(data.startDate).format('YYYY-MM-DD');
    }

    if (data.endDate) {
      formatedData.endDate = moment(data.endDate).format('YYYY-MM-DD');
    }

    if (data.endDate && !data.startDate) {
      setSnackbar({
        open: true,
        type: 'warning',
        text: 'Please select start date',
      });
      setButtonDisabled(false);

      return;
    }

    if (data?.enableTimeSelection) {
      formatedData.startTime = moment(
        `${data['startTimeHours-normal']}:${data['startTimeMinutes-normal']} ${data['startTimeType-normal']}`,
        ['HH:mm A'],
      ).format('HH:mm');

      formatedData.endTime = moment(
        `${data['endTimeHours-normal']}:${data['endTimeMinutes-normal']} ${data['endTimeType-normal']}`,
        ['HH:mm A'],
      ).format('HH:mm');
    }

    if (data?.timezoneId) {
      if (!data.timezone.timezoneId) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Please select timezone',
        });

        return;
      }

      formatedData.timezoneId = data?.timezone.timezoneId.numId;
    }

    if (data?.timezone?.enabled) {
      if (!data.timezone.timezoneId) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Please select timezone',
        });

        return;
      }

      formatedData.timezoneId = data?.timezone.timezoneId.numId;
    }

    formatedData.weekdayIds = [];

    if (data?.availableOnDays?.enabled && showSpecificWeekday && !showSpecificTime) {
      for (const day in data?.availableOnDays?.days) {
        if (data?.availableOnDays?.days[day]) {
          formatedData.weekdayIds.push(WEEKDAYS[day]);
        }
      }

      if (!formatedData.weekdayIds.length) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Please select at least 1 day',
        });

        return;
      }
    }

    formatedData.subcategoryWeekdays = [];

    if (showSpecificWeekday && showSpecificTime) {
      for (const day of extendedWeekdays) {
        if (day.enabled) {
          formatedData.subcategoryWeekdays.push({
            weekdayId: day.weekdayId,
            startTime: moment(
              `${day.startTime.hours}:${day.startTime.minutes} ${day.startTime.meridiem}`,
              ['HH:mm A'],
            ).format('HH:mm'),
            endTime: moment(`${day.endTime.hours}:${day.endTime.minutes} ${day.endTime.meridiem}`, [
              'HH:mm A',
            ]).format('HH:mm'),
          });
        }
      }
    }

    if (formatedData.subcategoryWeekdays.length) {
      formatedData.weekdayIds = [];
    }

    await updateSubcategoryWeekday({
      variables: {
        input: {
          subcategoryId,
          ...formatedData,
        },
      },
    });

    await updateSubcategory({
      variables: {
        input: {
          id: gqlSubcategoryId,
          label: data.label,
          description: data.description,
          shortName: data.shortName,
          availableOnline: data.isAvailableOnline,
          isAvailablePos: data.isAvailablePos,
          isAvailable3pv: data.isAvailable3pv,
          images: imagesToUpload,
          crossSellItems: crossSellItemIds,
        },
      },
      refetchQueries: () => [
        {
          query: FETCH_SUBCATEGORY,
          variables: {
            id: gqlSubcategoryId,
            filter: {
              showHiddenToLocation: { eq: true },
              showUnavailableOnline: { eq: true },
            },
          },
        },
      ],
    });

    await refetchMenuList();

    setSnackbar({
      open: true,
      type: 'info',
      text: 'Subcategory updated!',
    });
    setButtonDisabled(false);
  };

  const onUpdateLocationSubcategory = async () => {
    const temp_locationSubcategory = [];
    let formatedLocationSubcategory = {};

    if (locationSubcategories.length) {
      locationSubcategories.forEach(sub => {
        temp_locationSubcategory.push({
          locationId: sub.locationId,
          available: toggledLocationSubcategory[sub.locationId] ?? sub.available,
          enabled: sub.enabled,
          global: sub.global,
          specificWeekdays: {
            enabled:
              locationSubcategoriesAdvanced.filter(l => l.locationId === sub.locationId)[0]
                ?.enabled || [],
            days:
              locationSubcategoriesAdvanced.filter(l => l.locationId === sub.locationId)[0]?.days ||
              [],
          },
        });
      });
    } else if (!locationSubcategories.length && locationSubcategoriesAdvanced.length) {
      locationSubcategoriesAdvanced.forEach(loc => {
        if (loc.enabled) {
          const tempLoc = {
            locationId: loc.locationId,
            available: loc.available,
            enabled: false,
          };
          tempLoc.specificWeekdays = {
            locationId: loc.locationId,
            enabled: loc.enabled,
            days: loc.days,
          };
          temp_locationSubcategory.push(tempLoc);
        }
      });
    }

    if (temp_locationSubcategory.length) {
      formatedLocationSubcategory.subcategoryId = subcategoryId;
      formatedLocationSubcategory.locations = [];
      temp_locationSubcategory.forEach(temp_sub => {
        if (temp_sub.enabled) {
          let tempData = {
            locationId: temp_sub.locationId,
            available: temp_sub.available,
          };
          tempData.startTime =
            temp_sub?.global?.startTime &&
            moment(
              `${temp_sub.global.startTime.hours}:${temp_sub.global.startTime.minutes} ${temp_sub.global.startTime.meridiem}`,
              ['HH:mm A'],
            ).format('HH:mm');
          tempData.endTime =
            temp_sub?.global?.endTime &&
            moment(
              `${temp_sub.global.endTime.hours}:${temp_sub.global.endTime.minutes} ${temp_sub.global.endTime.meridiem}`,
              ['HH:mm A'],
            ).format('HH:mm');
          formatedLocationSubcategory.locations.push(tempData);
        } else {
          let tempData = {
            locationId: temp_sub.locationId,
            available: temp_sub.available,
            startTime: null,
            endTime: null,
          };
          formatedLocationSubcategory.locations.push(tempData);
        }
        if (temp_sub?.specificWeekdays?.enabled) {
          let tempData = {
            locationId: temp_sub.locationId,
            available: temp_sub.available,
            subcategoryWeekday: temp_sub.specificWeekdays.days
              .filter(day => day.enabled)
              .map(day => {
                return {
                  weekdayId: day.weekdayId,
                  startTime: moment(
                    `${day.startTime.hours}:${day.startTime.minutes} ${day.startTime.meridiem}`,
                    ['HH:mm A'],
                  ).format('HH:mm'),
                  endTime: moment(
                    `${day.endTime.hours}:${day.endTime.minutes} ${day.endTime.meridiem}`,
                    ['HH:mm A'],
                  ).format('HH:mm'),
                };
              }),
          };
          const alreadyExists = formatedLocationSubcategory.locations.find(
            item => item.locationId === tempData.locationId,
          );

          if (alreadyExists) {
            formatedLocationSubcategory.locations.forEach((loc, i) => {
              if (loc.locationId === tempData.locationId) {
                formatedLocationSubcategory.locations[i].subcategoryWeekday =
                  tempData.subcategoryWeekday;
              }
            });
          } else {
            formatedLocationSubcategory.locations.push(tempData);
          }
        }
      });
    }

    if (formatedLocationSubcategory?.locations?.length) {
      await createUpdateLocationSubacategoryWeekday({
        variables: {
          input: formatedLocationSubcategory,
        },
      });
    }
    setButtonDisabled(false);
    return;
  };

  const handleOnSpecificTimeChange = (enable, locationId, isLocationAvailable) => {
    const exists = locationSubcategories.filter(location => location.locationId === locationId);
    if (exists.length) {
      const locationAt = locationSubcategories.indexOf(exists[0]);
      locationSubcategories[locationAt].enabled = enable;
      locationSubcategories[locationAt].available = isLocationAvailable;
      setLocationSubcategories([...locationSubcategories]);
    }
  };

  const handleTimeChange = (timeValue, type, locationId, timeType) => {
    const exists = locationSubcategories.filter(location => location.locationId === locationId);
    if (exists.length) {
      const locationAt = locationSubcategories.indexOf(exists[0]);
      locationSubcategories[locationAt].global[timeType][type] =
        type === 'minutes' ? +timeValue : timeValue;
    } else {
      let defaultValues = {
        global: {
          startTime: {
            hours: 12,
            minutes: 0,
            meridiem: 'am',
          },
          endTime: {
            hours: 11,
            minutes: 59,
            meridiem: 'pm',
          },
        },
      };
      const newRecord = {
        global: {
          ...defaultValues.global,
          [timeType]: {
            ...defaultValues.global[timeType],
            [type]: type === 'minutes' ? +timeValue : timeValue,
          },
        },
      };
      newRecord.locationId = locationId;
      setLocationSubcategories([...locationSubcategories, newRecord]);
      return;
    }
    setLocationSubcategories([...locationSubcategories]);
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
          <Grid item>
            <Button disabled={buttonDisabled} type="submit">
              Save
            </Button>
          </Grid>
        </Grid>

        <GeneralInfoInput
          imageUrl={previewImage}
          setOpen={setOpen}
          control={control}
          register={register}
          errors={errors}
          isSubcategory
        />

        <Grid container spacing={1} direction="row">
          <Grid item xs={3}>
            <FormControl component="fieldset">
              <Typography gutterBottom variant="h6" component="h2">
                Display On
              </Typography>

              <FormControlLabel
                label="Is Available on OLO"
                control={
                  <Controller
                    name="isAvailableOnline"
                    control={control}
                    render={props => (
                      <Switch
                        onChange={e => props.onChange(e.target.checked)}
                        checked={props.value}
                        className={classes.switch}
                      />
                    )}
                  />
                }
              />

              <FormControlLabel
                label="Is Available on POS"
                control={
                  <Controller
                    name="isAvailablePos"
                    control={control}
                    render={props => (
                      <Switch
                        onChange={e => props.onChange(e.target.checked)}
                        checked={props.value}
                        className={classes.switch}
                      />
                    )}
                  />
                }
              />

              <FormControlLabel
                label="Is Available on 3PV"
                control={
                  <Controller
                    name="isAvailable3pv"
                    control={control}
                    render={props => (
                      <Switch
                        onChange={e => props.onChange(e.target.checked)}
                        checked={props.value}
                        className={classes.switch}
                      />
                    )}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={9} className={classes.subcategoryHeader}>
            <div>
              <Typography gutterBottom variant="h6" component="h2">
                Items
              </Typography>

              <div>
                <Button
                  component={Link}
                  style={{ marginBottom: '1rem' }}
                  variant="outlined"
                  size="small"
                  to={`${basePath}/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}/items`}
                >
                  Add Item
                </Button>

                <ItemList
                  data={data?.viewer?.subcategory?.subcategoryItemConnection?.edges || []}
                  categoryId={categoryId}
                  subcategoryId={subcategoryId}
                  menuId={menuId}
                  basePath={basePath}
                  classes={classes}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} direction="column">
          <Grid item md={12} className={classes.subcategoryHeader}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div>
                <Typography gutterBottom variant="h5" component="h2">
                  Date & Time Settings
                </Typography>
              </div>

              <div>
                <Button
                  onClick={() => setOpenAdvancedDate(true)}
                  variant="outlined"
                  disabled={!showSpecificTime && !showSpecificWeekday}
                >
                  Advanced Date - Time options
                </Button>
                {hasExtendedWeekdays && (
                  <p
                    style={{
                      color: 'green',
                      textAlign: 'center',
                    }}
                  >
                    Days Set
                  </p>
                )}
              </div>
            </div>
          </Grid>

          <Grid item md={12}>
            <DateAndTimeSettings
              control={control}
              showSpecificTime={showSpecificTime}
              showSpecificWeekday={showSpecificWeekday}
              handleSwitchChange={handleSwitchChange}
              handleSpecficTimeChange={handleSpecficTimeChange}
              subcategory={!loading && data?.viewer?.subcategory}
              timezoneData={timezoneData}
              timezoneLoading={timezoneLoading}
            />
          </Grid>

          <Grid container spacing={3}>
            <CrossSell
              crossSellItems={crossSellItems}
              setCrossSellItems={setCrossSellItems}
              menuId={ids.menuId}
              categoryId={ids.categoryId}
              menuData={menuData}
            />
          </Grid>

          <Grid item md={12}>
            <LocationSubcategoryTable
              subcategoryId={subcategoryId}
              locationSubcategories={locationSubcategories}
              setLocationSubcategories={setLocationSubcategories}
              locationSubcategoriesAdvanced={locationSubcategoriesAdvanced}
              control={control}
              handleTimeChange={handleTimeChange}
              handleOnSpecificLocationDaysChange={handleOnSpecificLocationDaysChange}
              handleOnSpecificTimeChange={handleOnSpecificTimeChange}
              toggleEnableAdvancedLocationDays={toggleEnableAdvancedLocationDays}
              onUpdateLocationSubcategory={onUpdateLocationSubcategory}
              createUpdateLoading={createUpdateLoading}
              subcategory={!loading && data?.viewer?.subcategory}
              toggledLocationSubcategory={toggledLocationSubcategory}
              setToggledLocationSubcategory={setToggledLocationSubcategory}
            />
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Subcategory Image</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ImageUpload
                  images={images}
                  setImages={setImages}
                  size={IMAGE.sizes[0]}
                  maxSize={50 * 1000}
                  helperText={
                    'Required image size is 375 x 268 px in JPG format. File weight in max. 50KB.'
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ImageUpload
                  isIcon
                  images={images}
                  setImages={setImages}
                  size={IMAGE.sizes[1]}
                  maxSize={50 * 1000}
                  helperText={
                    'Required image size is 40 x 40 px in PNG format. File weight in max. 50KB.'
                  }
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAdvancedDate}
        onClose={handleCloseAdvancedDate}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Advanced Date - Time options</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AdvancedTimeSettingsModal
                  control={control}
                  days={extendedWeekdays}
                  setDays={setExtendedWeekdays}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdvancedDate}>Cancel</Button>
          <Button onClick={handleSaveAdvancedDate}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

Subcategory.propTypes = {
  ids: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Subcategory;
