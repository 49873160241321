import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {
  MYSTERY_REWARD,
  FETCH_SUBCATEGORIES,
  FETCH_ITEM,
  FETCH_SUBCATEGORY,
  FETCH_HANDOFF,
} from '../../queries';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  COUPON_ACTION,
  COUPON_ACTION_MAP,
  COUPON_TYPE,
  IMAGE,
  MYSTERY_COUPON_TYPE_ID,
  MYSTERY_REWARD_TYPE,
  PLATFORM_ID,
} from './constants';
import { CurrencyInput, ImageUpload, SelectFactory } from '../../../../../../../blocks';
import PercentageInput from '../../../../../../../blocks/PercentageInput';
import { FETCH_HANDOFFS, FETCH_ITEM_LIST } from '../../../../../../../../apollo/queries';
import { CREATE_COUPON, UPDATE_COUPON } from '../../../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import { convert, uglifyId } from '../../../../../../../../utils';

const MysteryRewardModal = ({ handleClose, isOpen, mysteryRewardId }) => {
  const { register, handleSubmit, errors, control, reset, setError, clearErrors } = useForm({
    defaultValues: {
      couponType: COUPON_TYPE.amount,
    },
  });

  const [mysteryRewardType, setMysteryRewardType] = useState(null);
  const [couponTypeValue, setCouponTypeValue] = useState(COUPON_TYPE.amount);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedHandoff, setSelectedHandoff] = useState(null);
  const [images, setImages] = useState([]);
  const [amountValue, setAmountValue] = useState('');

  const { setSnackbar } = useSnackbar();

  const [fetchItem, { loading: isLoadingItem }] = useLazyQuery(FETCH_ITEM, {
    onCompleted: itemData => {
      const item = itemData?.viewer?.item;

      if (item) {
        setSelectedItem({
          id: item.id,
          value: item.id,
          label: `${item.label} - ID_${item.itemId}`,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [fetchSubcategory, { loading: isLoadingSubcategory }] = useLazyQuery(FETCH_SUBCATEGORY, {
    onCompleted: subcategoryData => {
      const subcategory = subcategoryData?.viewer?.subcategory;

      if (subcategory) {
        setSelectedSubcategory({
          id: subcategory.id,
          value: subcategory.id,
          label: `${subcategory.label} - ID_${subcategory.subcategoryId}`,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [fetchHandoff, { loading: isLoadingHandoff }] = useLazyQuery(FETCH_HANDOFF, {
    onCompleted: handoffData => {
      const handoff = handoffData?.viewer?.handoff;

      if (handoff) {
        setSelectedHandoff({
          id: handoff.id,
          value: handoff.id,
          label: handoff.label,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const { data, loading, error } = useQuery(MYSTERY_REWARD, {
    variables: {
      id: mysteryRewardId,
    },
    skip: !mysteryRewardId,
    nextFetchPolicy: 'no-cache',
    onCompleted: couponData => {
      const coupon = couponData?.viewer?.coupon;

      const couponAction = COUPON_ACTION_MAP[coupon?.couponAction?.couponaction_id];

      if (couponAction) {
        setMysteryRewardType(couponAction.mysteryRewardType);
        setCouponTypeValue(couponAction.couponType);
      }

      const item = coupon?.couponActionConnection?.edges?.[0];

      if (item && couponAction.mysteryRewardType === MYSTERY_REWARD_TYPE.item) {
        fetchItem({ variables: { id: uglifyId('Item', item.node?.target) } });
      }

      if (item && couponAction.mysteryRewardType === MYSTERY_REWARD_TYPE.subcategory) {
        fetchSubcategory({ variables: { id: uglifyId('Subcategory', item.node?.target) } });
      }

      if (item && couponAction.mysteryRewardType === MYSTERY_REWARD_TYPE.handoff) {
        fetchHandoff({ variables: { id: uglifyId('Subcategory', item.node?.target) } });
      }

      const amount =
        couponAction?.couponType === COUPON_TYPE.amount
          ? convert.centsToDollars(coupon?.couponActionConnection?.edges?.[0]?.node?.valueOff)
          : undefined;

      reset({
        internalLabel: coupon.internalLabel,
        percentage:
          couponAction?.couponType === COUPON_TYPE.percentage
            ? `${coupon.couponActionConnection?.edges?.[0]?.node?.valueOff}`
            : undefined,
        amount,
        label: coupon.label,
      });

      setAmountValue(amount);

      if (coupon?.couponimageConnection?.edges?.[0]?.node?.url) {
        setImages(
          IMAGE.sizes.map(size => {
            return {
              size,
              url: coupon?.couponimageConnection?.edges?.[0]?.node?.url,
            };
          }),
        );
      } else {
        setImages(
          IMAGE.sizes.map(size => {
            return {
              size,
            };
          }),
        );
      }
    },
  });

  const coupon = useMemo(() => data?.viewer?.coupon, [data?.viewer?.coupon]);

  const resetValues = useCallback(() => {
    setMysteryRewardType(null);
    setCouponTypeValue(COUPON_TYPE.amount);
    setSelectedItem(null);
    setSelectedSubcategory(null);
    setAmountValue('');

    reset({
      internalLabel: '',
      percentage: null,
      amount: null,
      label: '',
    });

    setImages(
      IMAGE.sizes.map(size => {
        return {
          size,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (!coupon) {
      resetValues();
    }
  }, [coupon, isOpen]);

  const onClose = useCallback(() => {
    setMysteryRewardType(null);
    handleClose();
  });

  const [createCoupon, { loading: isCreatingCoupon }] = useMutation(CREATE_COUPON, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Coupon Created',
        open: true,
      });
      onClose();
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error?.message,
        open: true,
      });
    },
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(data?.createCoupon?.viewer),
        fields: {
          couponConnection() {
            return {
              __typename: 'CouponEdge',
            };
          },
        },
      });
    },
  });

  const [updateCoupon, { loading: isUpdatingCoupon }] = useMutation(UPDATE_COUPON, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Coupon Updated',
        open: true,
      });
      onClose();
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error?.message,
        open: true,
      });
    },
  });

  const isLoading = useMemo(
    () =>
      loading ||
      isCreatingCoupon ||
      isUpdatingCoupon ||
      isLoadingSubcategory ||
      isLoadingItem ||
      isLoadingHandoff,
    [
      loading,
      isCreatingCoupon,
      isUpdatingCoupon,
      isLoadingSubcategory,
      isLoadingItem,
      isLoadingHandoff,
    ],
  );

  const handleOnCouponTypeChange = useCallback(e => {
    setCouponTypeValue(e.target.value);
  });

  const handleOnTypeClick = useCallback(mysteryRewardType => {
    setMysteryRewardType(mysteryRewardType);
  }, []);

  const onSubmit = useCallback(
    formData => {
      if (mysteryRewardType === MYSTERY_REWARD_TYPE.item && !selectedItem) {
        setError('item', { message: 'Item is required', type: 'required' });
        return;
      }

      if (mysteryRewardType === MYSTERY_REWARD_TYPE.subcategory && !selectedSubcategory) {
        setError('subcategory', { message: 'Subcategory is required', type: 'required' });
        return;
      }

      let couponType = formData.couponType;
      let percentage = formData.percentage ? Number(formData.percentage.replace('%', '')) : null;

      if (
        mysteryRewardType !== MYSTERY_REWARD_TYPE.item &&
        formData.couponType === COUPON_TYPE.free
      ) {
        couponType = COUPON_TYPE.percentage;
        percentage = 100;
      } else if (
        mysteryRewardType === MYSTERY_REWARD_TYPE.item &&
        formData.couponType === COUPON_TYPE.free
      ) {
        percentage = 100;
      }

      const couponActionId = Object.entries(COUPON_ACTION_MAP).find(([_, values]) => {
        return values.couponType === couponType && values.mysteryRewardType === mysteryRewardType;
      })?.[0];

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: mysteryRewardId ? 'Updating coupon...' : 'Creating Coupon...',
        open: true,
      });

      const variables = {
        input: {
          couponId: mysteryRewardId || undefined,
          label: formData.label,
          internalLabel: formData.internalLabel,
          isSmart: false,
          isMystery: true,
          images: images?.[0]?.encoded
            ? { ...images[0], size: IMAGE.sizes[0], url: undefined }
            : images?.[0]?.description
            ? { description: images?.[0]?.description, size: IMAGE.sizes[0] }
            : undefined,
          couponTypeId: MYSTERY_COUPON_TYPE_ID,
          brands: [1], // DICKEYS
          discountDetails: {
            couponActionId,
            items: mysteryRewardType === MYSTERY_REWARD_TYPE.item ? [selectedItem.id] : undefined,
            amount:
              couponType === COUPON_TYPE.amount
                ? convert.dollarsToCents(formData.amount.replace('$', ''))
                : percentage,
            category:
              mysteryRewardType === MYSTERY_REWARD_TYPE.subcategory
                ? selectedSubcategory.id
                : undefined,
            handoff:
              mysteryRewardType === MYSTERY_REWARD_TYPE.handoff ? selectedHandoff.id : undefined,
            quantity: couponActionId === COUPON_ACTION.itemFree ? 1 : undefined,
          },
          platforms: [PLATFORM_ID.MOBILE, PLATFORM_ID.WEBSITE],
        },
      };

      if (mysteryRewardId) {
        updateCoupon({
          variables,
        });
      } else {
        createCoupon({
          variables,
        });
      }
    },
    [
      mysteryRewardId,
      mysteryRewardType,
      selectedItem,
      images,
      selectedSubcategory,
      selectedHandoff,
    ],
  );

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <Dialog open={!!isOpen} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="md">
      {isLoading ? <CircularProgress /> : null}
      <DialogTitle style={{ textAlign: 'center', fontSize: '32px' }}>
        {coupon ? coupon?.label : 'New Mystery Reward'}
      </DialogTitle>
      <DialogContent>
        {!isLoading ? (
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid fullWidth container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginBottom: '12px', fontWeight: 'bold' }}>
                    Select Mystery Reward Type
                  </FormLabel>

                  <Grid container justify="center">
                    <Grid item fullWidth container spacing={2}>
                      <Grid item xs={6} sm={3}>
                        <Button
                          style={{ width: '100%' }}
                          color={
                            mysteryRewardType === MYSTERY_REWARD_TYPE.order
                              ? 'secondary'
                              : 'primary'
                          }
                          disabled={mysteryRewardId || isLoading}
                          variant="outlined"
                          onClick={() => handleOnTypeClick(MYSTERY_REWARD_TYPE.order)}
                        >
                          Order
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Button
                          style={{ width: '100%' }}
                          color={
                            mysteryRewardType === MYSTERY_REWARD_TYPE.item ? 'secondary' : 'primary'
                          }
                          disabled={mysteryRewardId || isLoading}
                          variant="outlined"
                          onClick={() => handleOnTypeClick(MYSTERY_REWARD_TYPE.item)}
                        >
                          Item
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Button
                          style={{ width: '100%' }}
                          color={
                            mysteryRewardType === MYSTERY_REWARD_TYPE.subcategory
                              ? 'secondary'
                              : 'primary'
                          }
                          disabled={mysteryRewardId || isLoading}
                          variant="outlined"
                          onClick={() => handleOnTypeClick(MYSTERY_REWARD_TYPE.subcategory)}
                        >
                          Subcategory
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Button
                          style={{ width: '100%' }}
                          color={
                            mysteryRewardType === MYSTERY_REWARD_TYPE.handoff
                              ? 'secondary'
                              : 'primary'
                          }
                          disabled={mysteryRewardId || isLoading}
                          variant="outlined"
                          onClick={() => handleOnTypeClick(MYSTERY_REWARD_TYPE.handoff)}
                        >
                          Handoff
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {mysteryRewardType ? (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel style={{ marginBottom: '12px', fontWeight: 'bold' }}>
                        Settings
                      </FormLabel>

                      {mysteryRewardType === MYSTERY_REWARD_TYPE.item && !isLoadingItem ? (
                        <Grid item xs={12} sm={8} style={{ marginBottom: '12px' }}>
                          <SelectFactory
                            style={{ minWidth: '15rem' }}
                            name="item"
                            label="Item"
                            placeholder="Select item"
                            disableSearchReset
                            query={FETCH_ITEM_LIST}
                            renderCheckBox={false}
                            filter={{
                              showUnavailableOnline: {
                                eq: true,
                              },
                            }}
                            onSelect={value => {
                              setSelectedItem(value);

                              if (value) {
                                clearErrors('item');
                              }
                            }}
                            value={selectedItem}
                            defaultValue={selectedItem}
                            error={errors.item}
                            helperText={errors.item?.message}
                            multiple={false}
                            structureData={data => {
                              return data?.viewer?.itemConnection?.edges.map(edge => {
                                return {
                                  label: `${edge.node.label} - ID_${edge.node.itemId}`,
                                  value: edge.node.id,
                                  id: edge.node.id,
                                };
                              });
                            }}
                          />
                        </Grid>
                      ) : null}

                      {mysteryRewardType === MYSTERY_REWARD_TYPE.subcategory &&
                      !isLoadingSubcategory ? (
                        <Grid item xs={12} sm={8} style={{ marginBottom: '12px' }}>
                          <SelectFactory
                            style={{ minWidth: '15rem' }}
                            name="subcategory"
                            label="Subcategory"
                            placeholder="Select subcategory"
                            disableSearchReset
                            query={FETCH_SUBCATEGORIES}
                            renderCheckBox={false}
                            onSelect={value => {
                              setSelectedSubcategory(value);

                              if (value) {
                                clearErrors('subcategory');
                              }
                            }}
                            error={errors.subcategory}
                            value={selectedSubcategory}
                            defaultValue={selectedSubcategory}
                            helperText={errors.subcategory?.message}
                            multiple={false}
                            structureData={data => {
                              return data?.viewer?.subcategoryConnection?.edges.map(edge => {
                                return {
                                  label: `${edge.node.label} - ID_${edge.node.subcategoryId}`,
                                  value: edge.node.id,
                                  id: edge.node.id,
                                };
                              });
                            }}
                          />
                        </Grid>
                      ) : null}

                      {mysteryRewardType === MYSTERY_REWARD_TYPE.handoff && !isLoadingHandoff ? (
                        <Grid item xs={12} sm={8} style={{ marginBottom: '12px' }}>
                          <SelectFactory
                            style={{ minWidth: '15rem' }}
                            name="handoff"
                            label="Handoff"
                            placeholder="Select handoff"
                            disableSearchReset
                            query={FETCH_HANDOFFS}
                            onSelect={value => {
                              setSelectedHandoff(value);

                              if (value) {
                                clearErrors('handoff');
                              }
                            }}
                            error={errors.handoff}
                            renderCheckBox={false}
                            value={selectedHandoff}
                            defaultValue={selectedHandoff}
                            helperText={errors.handoff?.message}
                            multiple={false}
                            structureData={data => {
                              return data?.viewer?.handoffConnection?.edges.map(edge => {
                                return {
                                  label: edge.node.label,
                                  value: edge.node.id,
                                  id: edge.node.id,
                                };
                              });
                            }}
                          />
                        </Grid>
                      ) : null}

                      <RadioGroup value={couponTypeValue} onChange={handleOnCouponTypeChange}>
                        <Grid container spacing={4} alignItems="center">
                          <Grid item>
                            <FormControlLabel
                              value={COUPON_TYPE.amount}
                              name="couponType"
                              inputRef={register}
                              control={<Radio />}
                              disabled={isLoading}
                              label={'Amount ($)'}
                            />
                          </Grid>
                          <Grid>
                            <FormControlLabel
                              value={COUPON_TYPE.percentage}
                              name="couponType"
                              inputRef={register}
                              control={<Radio />}
                              disabled={isLoading}
                              label={'Percentage (%)'}
                            />
                          </Grid>
                          <Grid>
                            <FormControlLabel
                              value={COUPON_TYPE.free}
                              name="couponType"
                              inputRef={register}
                              control={<Radio />}
                              disabled={isLoading}
                              label={'Free'}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    {couponTypeValue === COUPON_TYPE.amount ? (
                      <CurrencyInput
                        name="amount"
                        fullWidth
                        control={control}
                        label="Amount"
                        rules={{
                          required: true,
                        }}
                        defaultValue={amountValue}
                        disabled={isLoading}
                        error={errors?.amount}
                        helperText={errors?.amount ? 'Amount is required' : undefined}
                      />
                    ) : null}
                    {couponTypeValue === COUPON_TYPE.percentage ? (
                      <PercentageInput
                        name="percentage"
                        fullWidth
                        control={control}
                        label="Percentage"
                        rules={{
                          required: true,
                        }}
                        disabled={isLoading}
                        error={errors?.percentage}
                        helperText={errors?.percentage ? 'Percentage is required' : undefined}
                      />
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <FormLabel style={{ marginBottom: '12px', fontWeight: 'bold' }}>
                      General Info
                    </FormLabel>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      name="internalLabel"
                      fullWidth
                      inputRef={register({ required: 'true' })}
                      label="Internal Label"
                      rules={{ required: true }}
                      error={errors?.internalLabel}
                      disabled={isLoading}
                      helperText={errors?.internalLabel ? 'Internal Label is required' : undefined}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      name="label"
                      fullWidth
                      inputRef={register({ required: 'true' })}
                      label="Display Label"
                      rules={{ required: true }}
                      error={errors?.label}
                      disabled={isLoading}
                      helperText={errors?.label ? 'Display Label is required' : undefined}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ImageUpload
                      images={images}
                      setImages={setImages}
                      size={IMAGE.sizes[0]}
                      maxSize={200 * 1000}
                      helperText="Required image size is 490 x 350 px in JPG format. File weight in max. 200KB."
                    />
                  </Grid>

                  <Grid item xs={12} container spacing={2} justify="flex-end">
                    <Grid item>
                      <Button variant="outlined" onClick={onClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button disabled={!mysteryRewardType || isLoading} type="submit">
                        {mysteryRewardId ? 'Save Changes' : 'Create'}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </form>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default MysteryRewardModal;
