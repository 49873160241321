import { gql } from '@apollo/client';

const FETCH_TIERS = gql`
  query priceCapPerPlatform($id: Int, $gqlItemId: ID!) {
    viewer {
      id
      platformConnection {
        edges {
          node {
            id
            label
          }
        }
      }
      item(id: $gqlItemId) {
        label
        price
        priceCap
      }
      tierConnection {
        edges {
          node {
            id
            label
            tierId
          }
        }
      }
      itemTierConnection(filter: { itemId: { eq: $id } }) {
        edges {
          node {
            id
            tier {
              id
              label
            }
            price
            itemTierPlatformConnection {
              edges {
                node {
                  id
                  platform {
                    id
                    label
                  }
                  priceCap
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default FETCH_TIERS;
