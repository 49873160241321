import React, { forwardRef } from "react";
import { TextField, Button } from "@material-ui/core";
import TextFieldWithButtonStyles from "./styles";

const TextFieldWithButton = forwardRef(
  (
    {
      defaultValue,
      numOfRows,
      id,
      label,
      inputRef,
      fullWidth,
      handleButtonClick,
      buttonLabel,
      ...rest
    },
  ) => {
    const classes = TextFieldWithButtonStyles();
    return (
      <div className={classes.container}>
        <TextField
          {...rest}
          id={id}
          name={id}
          label={label}
          multiline
          rows={numOfRows}
          fullWidth={fullWidth}
          defaultValue={defaultValue}
          inputRef={inputRef}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={handleButtonClick}
        >
          {buttonLabel}
        </Button>
      </div>
    );
  }
);

export default TextFieldWithButton;
