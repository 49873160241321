import React, { useState, useContext } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { useMutation } from "@apollo/client";

import AddTaxGroup from "./AddTaxGroup";

import { LocationIdContext } from "../../index";

import { DataTable, Modal, PermissionField } from "../../../../../blocks";
import { REMOVE_TAX_GROUP } from "../../../../../../apollo/mutations/locations";
import { FETCH_LOCATION } from "../../../../../../apollo/queries";
import { MUTATION_NAME, SNACKBAR_STATUS } from "../../../../../../constants";
import Permission from "../../../../../blocks/Permission";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";

const TaxGroups = ({
  setOpenWarningMutationName,
  accessToMutations,
  taxGroups,
  taxRates,
  permissions,
  mutationPermissions,
}) => {
  const { setSnackbar } = useSnackbar();

  const createTaxGroupPermission = mutationPermissions.find(
    (mutationPermission) => mutationPermission.label === "createTaxGroup"
  );
  const updateTaxGroupPermission = mutationPermissions.find(
    (mutationPermission) => mutationPermission.label === "updateTaxGroup"
  );
  const removeTaxGroupPermission = mutationPermissions.find(
    (mutationPermission) => mutationPermission.label === "removeTaxGroup"
  );
  const [showModal, setShowModal] = useState(false);
  const [editTaxGroupData, setEditTaxGroupData] = useState(null);
  const [removeTaxGroup, { loading: isDeletingTaxGroup }] = useMutation(
    REMOVE_TAX_GROUP
  );
  const locationId = useContext(LocationIdContext);

  const handleRemoveTaxGroup = async (taxGroupId) => {
    try {
      await removeTaxGroup({
        variables: {
          input: {
            taxgroupId: taxGroupId,
          },
        },
        refetchQueries: [
          {
            query: FETCH_LOCATION,
            variables: { id: locationId, first: 2147483647 },
          },
        ],
      });
      handleOpenSnackBar("Tax Group removed.", SNACKBAR_STATUS.SUCCESS);
    } catch (error) {
      handleOpenSnackBar(error.message, SNACKBAR_STATUS.ERROR);
    }
  };

  const handleEditTaxGroup = ({ id }) => {
    const selectedTaxGroupData = taxGroups?.edges.filter(
      (taxGroup) => taxGroup?.node?.id === id
    );

    if (selectedTaxGroupData) {
      setEditTaxGroupData(selectedTaxGroupData[0]?.node);
      handleOpenModal(MUTATION_NAME.UpdateTaxgroupMutationInput);
    }
  };

  const handleOpenSnackBar = (text, type) => {
    setSnackbar({ open: true, type, text });
  };

  const handleOpenModal = (mutationName) => {
    setShowModal(true);
    setOpenWarningMutationName(mutationName);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    if (editTaxGroupData) setEditTaxGroupData(null);
  };

  return (
    <Grid container key="locationTaxGroups" id="taxGroups">
      <DataTable
        data={taxGroups}
        permissions={permissions?.taxgroupConnection?.edges?.node}
        title="Tax Groups"
        structureTable={(data) =>
          data?.edges.map((taxgroup) => ({
            label: taxgroup.node.label,
            description: taxgroup.node.description,
            taxrates: taxgroup.node.taxgroupTaxrateConnection.edges,
            id: taxgroup.node.id,
            disableTableRowClick: !updateTaxGroupPermission?.access,
            disableDelete: !removeTaxGroupPermission?.access,
          }))
        }
        columns={[
          { title: "Tax Group Name", field: "label" },
          { title: "Description", field: "description" },
          {
            title: "Taxrates",
            field: "taxrates",
            customPermissionField:
              "taxgroupTaxrateConnection.edges.node.taxrate.label",
            enablePropagation: true,
            render: (taxrates) => {
              let taxrateCollection = "";
              taxrates.forEach((taxrate, index) => {
                taxrateCollection = taxrateCollection.concat(
                  index !== taxrates.length - 1
                    ? `${taxrate.node.taxrate.label}, `
                    : `${taxrate.node.taxrate.label}`
                );
              });

              return taxrateCollection;
            },
          },
        ]}
        customActionsTop={() => (
          <Permission
            access={Math.min(
              createTaxGroupPermission?.access,
              accessToMutations?.[MUTATION_NAME.CreateTaxgroupMutationInput]
            )}
          >
            <div>
              <PermissionField createHelperText>
                <IconButton
                  onClick={() =>
                    handleOpenModal(MUTATION_NAME.CreateTaxgroupMutationInput)
                  }
                  variant="contained"
                >
                  <Add />
                </IconButton>
              </PermissionField>
            </div>
          </Permission>
        )}
        handleDeleteRow={handleRemoveTaxGroup}
        onTableRowClick={handleEditTaxGroup}
        useConfirmationModalForDelete
        isDeleting={isDeletingTaxGroup}
        deleteConfirmationModalText="Are you sure you want to delete tax group?"
      />
      <Modal open={showModal} handleClose={handleCloseModal} title="Tax Groups">
        <AddTaxGroup
          permissions={permissions}
          taxRates={taxRates}
          handleCancel={handleCloseModal}
          handleOpenSnackBar={handleOpenSnackBar}
          locationId={locationId}
          showModal={showModal}
          editData={editTaxGroupData}
          accessToMutations={accessToMutations}
          mutationPermissions={mutationPermissions}
        />
      </Modal>
    </Grid>
  );
};

export default TaxGroups;
