import { gql } from '@apollo/client';

const SAVE_USERGROUP_RULES = gql`
  mutation saveUsergroupRules($fieldgroups: String!, $fields: String!, $usergroupId: Int!) {
    saveUsergroupRules(input: { fieldgroups: $fieldgroups, fields: $fields, usergroupId: $usergroupId }) {
      usergroup {
        id
      }
    }
  }
`;

export default SAVE_USERGROUP_RULES;
