import { gql } from "@apollo/client";

const FETCH_HANDOFF_STAGES = gql`
	query FetchHandoffStages ($filter: [HandoffStageFilter]) {
		viewer {
			handoffStageConnection (filter: $filter) {
				edges {
					node {
						stageId
						stage{
							id
							label
						}
					}
				}
			}
		}
	}
`;

export default FETCH_HANDOFF_STAGES;
