import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  twoColumnField: {
    paddingRight: '10px',
  },
  noPaddingTop: {
    paddingTop: 0,
  },
});

export const CustomerGroupFiltersStyles = makeStyles(Styles);
