import { gql } from "@apollo/client";

const UPDATE_LOCATION_INGREDIENT = gql`
  mutation updateLocationIngredient($input: UpdateLocationIngredientMutationInput!) {
    updateLocationIngredient(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_LOCATION_INGREDIENT;
