import { gql } from "@apollo/client";

export const PAYROLL_PERIODS = gql`
  query LocationNotes {
  viewer {
    id
    payrollPeriodConnection {
      edges {
        node {
          id
          label
          duration
        }
      }
    }
  }
}
`;
