import { gql } from '@apollo/client';

export const TOGGLE_AVAILABLE_LOCATION_SUBCATEGORY = gql`
  mutation toggleLocationSubcategory($input: ToggleLocationSubcategoryMutationInput!) {
    toggleLocationSubcategory(input: $input) {
      subcategory {
        id
        subcategoryId
        label
        description
        isAvailableOnline
        isAvailablePos
        isAvailable3pv
        startDate
        startTime
        endDate
        endTime
        timezoneId
        subcategoryWeekdayConnection {
          edges {
            node {
              id
              subcategoryId
              weekdayId
              startTime
              endTime
            }
          }
        }
        locationSubcategoryConnection {
          edges {
            node {
              id
              subcategoryId
              locationId
              available
              startTime
              endTime
            }
          }
        }
        locationSubcategoryWeekdayConnection {
          edges {
            node {
              id
              locationId
              subcategoryId
              weekdayId
              startTime
              endTime
            }
          }
        }
      }
    }
  }
`;
