import { gql } from '@apollo/client';

const UPDATE_SIMPLE_INVOICE = gql`
  mutation UPDATE_SIMPLE_INVOICE($input: UpdateSimpleInvoiceInput!) {
    updateSimpleInvoice(input: $input) {
      viewer {
        id
      }
      invoice {
        isSubmitted
      }
    }
  }
`;

export default UPDATE_SIMPLE_INVOICE;
