import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';

import AddLocationView from '../AddLocationView';

import Modal from '../../../../../../../blocks/Modal';
import { VENDOR_LOCATIONS_LIST } from '../../../../../../../../apollo/queries';
import {
  REMOVE_LOCATION_VENDOR,
  UPDATE_LOCATION_VENDOR,
} from '../../../../../../../../apollo/mutations/vendors';
import { COLOR, paths } from '../../../../../../../../constants';
import ConnectionTable from '../../../../../../../blocks/ConnectionTable';
import { uglifyId, formatConnectionName, pad } from '../../../../../../../../utils';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';

const VendorLocationsView = ({
  history,
  vendorId,
  handleOpenModal,
  connectionVariables,
  setConnectionVariables,
}) => {
  const { setSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [editLocationData, setEditLocationData] = useState({});

  const handleOpenEditModal = () => {
    setShowModal(true);
  };
  const handleCloseEditModal = () => {
    setShowModal(false);
  };

  const [updateLocationVendor, { loading: updateLocationVendorLoading }] = useMutation(
    UPDATE_LOCATION_VENDOR,
    {
      onCompleted: data => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Location vendor updated!',
        });

        handleCloseEditModal();
      },
      onError: err => {
        setSnackbar({
          open: true,
          type: 'error',
          text: err.message || 'Oops, something went wrong...',
        });
      },
    },
  );

  const [removeLocationVendor] = useMutation(REMOVE_LOCATION_VENDOR, {
    update: (cache, { data: { removeLocationVendor } }) => {
      const { dynamicConnectionName } = formatConnectionName('locationVendor', connectionVariables);
      cache.modify({
        id: cache.identify(removeLocationVendor.viewer),
        fields: {
          [dynamicConnectionName]: existingLocationVendorConnection => {
            return {
              ...existingLocationVendorConnection,
              totalCount: existingLocationVendorConnection.totalCount - 1,
              edges: [
                ...existingLocationVendorConnection.edges.filter(
                  locationVendor =>
                    locationVendor.node.__ref !==
                    `LocationVendor:${uglifyId(
                      'LocationVendor',
                      removeLocationVendor.removedLocationVendorId,
                    )}`,
                ),
              ],
            };
          },
        },
      });
    },
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Location deleted',
      });
    },
    onError: error => {
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
  });

  const onSubmit = useCallback(
    async formData => {
      await updateLocationVendor({
        variables: {
          input: formData,
          fieldGroupOnly: 76,
        },
      });
    },
    [updateLocationVendor],
  );

  const onDeleteConfirmation = (row, vars, refetch) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Deleting location...',
    });

    removeLocationVendor({
      variables: {
        input: {
          vendorId: row.vendorRawId,
          locationId: row.locationRawId,
        },
        ...vars,
      },
    });
  };

  return (
    <div id="vendorLocations">
      <ConnectionTable
        setConnectionVariables={setConnectionVariables}
        query={VENDOR_LOCATIONS_LIST}
        initialQueryVariables={{
          sort: {
            location: {
              id: 'ASC',
            },
          },
          first: 15,
          filter: {
            vendor: {
              id: {
                eq: uglifyId('Vendor', vendorId),
              },
            },
          },
          fieldGroupOnly: 76,
        }}
        title="Vendor Locations"
        customActionsTop={(data, query) => {
          return (
            <div>
              <Link to={paths.SETTINGS_VENDORS} style={{ margin: '0 1rem' }}>
                <Button variant="outlined">Cancel</Button>
              </Link>
              <Button onClick={handleOpenModal}>Add Vendor Location</Button>
            </div>
          );
        }}
        onTableRowClick={(row, data, variables) => {
          setEditLocationData(row);
          handleOpenEditModal();
        }}
        structureTable={data =>
          data?.viewer?.locationVendorConnection?.edges
            .filter(edge => {
              return edge.node.location;
            })
            .map(edge => {
              return {
                id: edge.node.location?.locationId,
                label: edge.node.location?.label,
                deliveryUrl: edge.node.deliveryUrl,
                vendorRawId: edge.node.vendor.id,
                locationRawId: edge.node.location?.id,
                locationState: edge.node.location?.address.state.abbreviation,
                locationStoreNum: edge.node.location?.storeNumber,
              };
            })
        }
        handleDeleteRow={onDeleteConfirmation}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete the location for this vendor?"
        columns={[
          {
            title: 'Location Number',
            field: 'location.storeNumber',
            render: (data, vars, row) =>
              `${row.locationState}-${
                row.locationStoreNum ? pad(row.locationStoreNum, 4) : undefined
              }`,
          },
          {
            title: 'Location Name',
            field: 'label',
            disableSort: true,
          },
          {
            title: 'Link',
            field: 'deliveryUrl',
            disableSort: true,
            render: (data, vars, row) => (
              <a href={`${data}`} rel="noreferrer" target="_blank">
                {data}
              </a>
            ),
            cellStyle: {
              color: COLOR.INFO,
            },
          },
        ]}
      />
      <Modal open={showModal} handleClose={handleCloseEditModal} title="Edit Location Vendor">
        <AddLocationView
          onSubmit={onSubmit}
          vendorId={vendorId}
          editRowData={editLocationData}
          handleCloseModal={handleCloseEditModal}
          createLocationVendorLoading={updateLocationVendorLoading}
        />
      </Modal>
    </div>
  );
};

export default VendorLocationsView;
