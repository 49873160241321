import { gql } from "@apollo/client";

const TOGGLE_ITEM_LOCATION_OLO = gql`
  mutation toggleIsAvailableForOLOItem($input:  ToggleItemLocationOLOMutationInput!) {
    toggleIsAvailableItem(input: $input) {
      viewer {
      id
      }
    }
  }
`;

export default TOGGLE_ITEM_LOCATION_OLO;
