import { gql } from '@apollo/client';

const REMOVE_MULTIPLE_CHOICE = gql`
  mutation removeMultipleChoice($input: RemoveMultipleChoiceMutationInput!) {
    removeMultipleChoice(input: $input) {
      viewer {
        id
      }
      removedMultipleChoiceId
    }
  }
`;

export default REMOVE_MULTIPLE_CHOICE;
