import { gql } from '@apollo/client';

export const MYSTERY_REWARDS = gql`
  query MYSTERY_REWARDS(
    $first: Int
    $last: Int
    $after: String
    $filter: [CouponFilter]
    $sort: [CouponSort]
    $before: String
    $search: String
  ) {
    viewer {
      id
      couponConnection(
        first: $first
        last: $last
        filter: $filter
        after: $after
        sort: $sort
        before: $before
        search: $search
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            couponId
            label
            archived
          }
        }
        totalCount
      }
    }
  }
`;

export const MYSTERY_REWARD = gql`
  query MYSTERY_REWARD($id: ID!) {
    viewer {
      id
      coupon(id: $id) {
        id
        couponId
        status
        permission
        coupontypeId
        couponimageConnection(filter: { label: { eq: "490x350" } }) {
          edges {
            node {
              id
              url
            }
          }
        }
        couponActionConnection {
          edges {
            node {
              id
              target
              valueOff
            }
          }
        }
        couponBrandConnection {
          edges {
            node {
              id
              brandId
            }
          }
        }
        couponAction {
          couponaction_id
          label
          id
        }
        code
        personalCode
        internalLabel
        label
        isStackable
        maxUses
        description
        discountAmount
        discountFraction
      }
    }
  }
`;

export const FETCH_SUBCATEGORIES = gql`
  query fetchSubcategories(
    $filter: [SubcategoryFilter]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    viewer {
      id
      subcategoryConnection(
        filter: $filter
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            subcategoryId
            label
          }
        }
      }
    }
  }
`;

export const FETCH_ITEM = gql`
  query fetchItem($id: ID!) {
    viewer {
      id
      item(id: $id) {
        id
        itemId
        label
      }
    }
  }
`;

export const FETCH_SUBCATEGORY = gql`
  query fetchSubcategory($id: ID!) {
    viewer {
      id
      subcategory(id: $id) {
        id
        subcategoryId
        label
      }
    }
  }
`;

export const FETCH_HANDOFF = gql`
  query fetchHandoff($id: ID!) {
    viewer {
      id
      handoff(id: $id) {
        id
        label
      }
    }
  }
`;
