import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Paper,
  Divider,
  Grid,
  GridListTile,
  GridList,
  Button,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { paths } from '../../../../../../constants';
import {
  SideBarSectionsLayout,
  SerializedCouponDistributionModal,
  ConfirmationModal,
} from '../../../../../blocks';

import GeneralSettings from '../GeneralSettings';
import TriggerEvents from '../TriggerEvents';
import DiscountResctrictions from '../DiscountRestrictions';
import { SERIALIZED_COUPON_TYPE_ID } from '../../../../../../constants';

const sectionList = [
  {
    name: 'General Settings',
    id: 'generalSettings',
  },
  {
    name: 'Trigger Events',
    id: 'triggerEvents',
  },
  {
    name: 'Discount Restrictions',
    id: 'discountRestrictions',
  },
];

const useStyles = makeStyles(theme => ({
  gridList: {
    width: '100%',
    padding: '0 20px',
  },
}));

function CreateDiscountView({
  handleSubmit,
  submit,
  control,
  updateCouponLoading,
  setExcludedItems,
  excludedItems,
  discountDetailsItems,
  setDiscountDetailsItems,
  includedLocations,
  setIncludedLocations,
  excludedLocations,
  setExcludedLocations,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  setItemAddedNumberTimes,
  excludedDates,
  setExcludedDates,
  itemInCart,
  setItemInCart,
  excludedSubcategories,
  setExcludedSubcategories,
  disableCreateButton,
  coupon,
  errors,
  couponTriggers,
  platformList,
  timezones,
  platforms,
  setPlatforms,
  brands,
  setBrands,
  setExcludedHandoffs,
  excludedHandoffs,
  handoffList,
  forEachItem,
  setForEachItem,
  setSelectedSubcategoriesAction,
  selectedSubcategoriesAction,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
  brandList,
  copyCoupon,
  showConfirmModal,
  setShowConfirmModal,
  displayedLocationRestriction,
  setDisplayedLocationRestriction,
  setValue,
  excludeAllBoostLocations,
  handleBoostStoreExclusion,
  handleIsSmartChange,
  isSmart,
}) {
  const classes = useStyles();
  const [serializedCouponModal, setSerializedCouponModal] = useState(false);

  const handleGenerateCodesClick = () => setSerializedCouponModal(true);

  return (
    <Paper>
      <SideBarSectionsLayout sectionList={sectionList}>
        <Grid container>
          <Grid item md={12}>
            <form onSubmit={handleSubmit(submit)}>
              <GridList className={classes.gridList} cellHeight={'auto'} cols={1}>
                <GridListTile>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h1>Update Discount</h1>
                    <div>
                      <Link to={paths.SETTINGS_DISCOUNTS}>
                        <Button
                          disabled={disableCreateButton}
                          style={{ marginRight: '10px' }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button type="submit" disabled={updateCouponLoading}>
                        UPDATE
                      </Button>
                    </div>
                  </div>
                  <div style={{ float: 'right' }}>
                    {coupon.coupontypeId === SERIALIZED_COUPON_TYPE_ID && (
                      <Button
                        variant="outlined"
                        style={{ width: '220px' }}
                        disabled={updateCouponLoading}
                        onClick={handleGenerateCodesClick}
                      >
                        GENERATE CODES
                      </Button>
                    )}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Tooltip title="Makes a copy of this discount without the code">
                      <Button
                        onClick={e => {
                          setShowConfirmModal(true);
                        }}
                      >
                        COPY
                      </Button>
                    </Tooltip>
                  </div>
                </GridListTile>

                <GridListTile>
                  <GeneralSettings
                    platformList={platformList}
                    coupon={coupon}
                    itemAddedNumberTimes={itemAddedNumberTimes}
                    setItemAddedNumberTimes={setItemAddedNumberTimes}
                    control={control}
                    errors={errors}
                    timezones={timezones}
                    platforms={platforms}
                    setPlatforms={setPlatforms}
                    brands={brands}
                    setBrands={setBrands}
                    discountDetailsItems={discountDetailsItems}
                    setDiscountDetailsItems={setDiscountDetailsItems}
                    setSelectedSubcategoriesAction={setSelectedSubcategoriesAction}
                    selectedSubcategoriesAction={selectedSubcategoriesAction}
                    brandList={brandList}
                    excludeAllBoostLocations={excludeAllBoostLocations}
                    isSmart={isSmart}
                    handleIsSmartChange={handleIsSmartChange}
                    handleBoostStoreExclusion={handleBoostStoreExclusion}
                  />
                </GridListTile>

                <GridListTile>
                  <Divider />
                  <TriggerEvents
                    coupon={coupon}
                    control={control}
                    serializedCouponModal={serializedCouponModal}
                    setSerializedCouponModal={setSerializedCouponModal}
                    cartItemsRequired={cartItemsRequired}
                    setCartItemsRequired={setCartItemsRequired}
                    itemAddedNumberTimes={itemAddedNumberTimes}
                    setItemAddedNumberTimes={setItemAddedNumberTimes}
                    itemInCart={itemInCart}
                    setItemInCart={setItemInCart}
                    errors={errors}
                    couponTriggers={couponTriggers}
                    forEachItem={forEachItem}
                    setForEachItem={setForEachItem}
                    setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
                    selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
                    brands={brands}
                    setValue={setValue}
                  />
                </GridListTile>
                <div style={{ marginBottom: '25px' }}></div>
                <GridListTile>
                  <Divider />
                  <DiscountResctrictions
                    control={control}
                    coupon={coupon}
                    setExcludedItems={setExcludedItems}
                    excludedItems={excludedItems}
                    setIncludedLocations={setIncludedLocations}
                    includedLocations={includedLocations}
                    excludedLocations={excludedLocations}
                    setExcludedLocations={setExcludedLocations}
                    excludedDates={excludedDates}
                    setExcludedDates={setExcludedDates}
                    excludedSubcategories={excludedSubcategories}
                    setExcludedSubcategories={setExcludedSubcategories}
                    setExcludedHandoffs={setExcludedHandoffs}
                    excludedHandoffs={excludedHandoffs}
                    handoffList={handoffList}
                    displayedLocationRestriction={displayedLocationRestriction}
                    setDisplayedLocationRestriction={setDisplayedLocationRestriction}
                  />
                </GridListTile>
              </GridList>
            </form>
            <SerializedCouponDistributionModal
              open={serializedCouponModal}
              setSerializedCouponModal={setSerializedCouponModal}
              couponId={coupon.id}
              brands={brands}
            />
          </Grid>
        </Grid>
      </SideBarSectionsLayout>
      <ConfirmationModal
        open={showConfirmModal}
        confirmAction={() => {
          copyCoupon({
            variables: {
              input: {
                couponId: coupon.couponId,
              },
            },
          });
        }}
        handleClose={() => setShowConfirmModal(false)}
        text={
          'Are you sure you want to copy this discount? (click update button before copying if there where changes made)'
        }
        buttonText="Confirm and Copy"
      ></ConfirmationModal>
    </Paper>
  );
}

export default CreateDiscountView;
