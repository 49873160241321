import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  employeeList: {
    maxHeight: 600,
    overflowY: 'auto',

    '& .MuiGrid-container': {
      display: 'none',
    },
  },
});

export const ScheduleEmployeesStyles = makeStyles(Styles);
