import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { Elements } from '../../../../../../../blocks';
import TimeSelection from './TimeSelection';
function DateAndTimeSettings({
  control,
  subcategory,
  timezoneData,
  timezoneLoading,
  handleSpecficTimeChange,
  handleSwitchChange,
  showSpecificWeekday,
  showSpecificTime,
}) {
  const [showEndDate, setShowEndDate] = useState(!!subcategory?.endDate);
  return (
    <div>
      <div style={{ display: 'flex', gap: '2rem' }}>
        <Elements.DatePicker
          control={control}
          label="Start Date"
          name="startDate"
          clearable
          format={'yyyy-MM-dd'}
          defaultValue={subcategory?.startDate || Date.now()}
        />
        <div style={{ minWidth: '10rem' }}>
          <FormControl variant="outlined" style={{ minWidth: '10rem' }}>
            <InputLabel htmlFor="outlined-age-native-simple">End Date</InputLabel>
            <Select
              style={{ minWidth: '10rem' }}
              onChange={(e, v) => setShowEndDate(e.target.value)}
              label="End Date"
              defaultValue={!!subcategory?.endDate || false}
            >
              <MenuItem value={false}>None</MenuItem>
              <MenuItem value={true}>By</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Elements.DatePicker
            label="End Date"
            disabled={!showEndDate}
            control={control}
            required
            name="endDate"
            format={'yyyy-MM-dd'}
            defaultValue={subcategory?.endDate || ''}
          />
        </div>
      </div>
      <div>
        <TimeSelection
          control={control}
          subcategory={subcategory}
          timezoneData={timezoneData}
          timezoneLoading={timezoneLoading}
          handleSpecficTimeChange={handleSpecficTimeChange}
          handleSwitchChange={handleSwitchChange}
          showSpecificWeekday={showSpecificWeekday}
          showSpecificTime={showSpecificTime}
        />
      </div>
    </div>
  );
}

export default DateAndTimeSettings;
