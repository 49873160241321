import React, { useCallback, useState, useMemo } from 'react';

import PosAccessEditModal from './components/PosAccessEditModal';
import { POS_ACCESS_LIST } from '../../../../../apollo/queries';
import { ConnectionTable, Permission, PermissionField } from '../../../../blocks';
import { useFormSubmitPermissions } from '../../../../../hooks';
import { Button, Typography } from '@material-ui/core';
import { MUTATION_NAME } from '../../../../../constants';
import useStyles from './styles';

const PosAccess = ({ mutationPermissions }) => {
	const classes = useStyles();

	const [permissions, setPermissions] = useState();
	const [isOpenPosAccessRoleModal, setIsOpenPosAccessRoleModal] = useState(false);
	const [posAccessRole, setPosAccessRole] = useState();
	const [posAccessRolePermissions, setPosAccessRolePermissions] = useState();
	const [variables, setVariables] = useState();

	const createPosRolePermission = useMemo(() => mutationPermissions?.find(perm => perm.label === "createPosRole"), [mutationPermissions]);
	const updatePosRolePermission = useMemo(() => mutationPermissions?.find(perm => perm.label === "updatePosRole"), [mutationPermissions]);

	const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
		permissions,
		mutationNames: [
			MUTATION_NAME.createPosRoleInput,
			MUTATION_NAME.updatePosRoleInput,
		],
	});

	const handleOpenModal = useCallback((data) => {
		setOpenWarningMutationName(data ? MUTATION_NAME.updatePosRoleInput : MUTATION_NAME.createPosRoleInput);
		setPosAccessRole(data);
		setIsOpenPosAccessRoleModal(true);
	}, [setOpenWarningMutationName]);

	const handleCloseModal = useCallback(() => {
		setPosAccessRole();
		setIsOpenPosAccessRoleModal(false);
	}, []);

	return (
		<>
			<ConnectionTable
				query={POS_ACCESS_LIST}
				title="POS Access Roles"
				customActionsTop={(data, { variables }) => {
					if (!permissions) {
						setPermissions({
							label: data?.viewer?.posRoleConnection?.permissions?.label,
							active: data?.viewer?.posRoleConnection?.permissions?.active,
							posPermissionIds: data?.viewer?.posRoleConnection?.permissions?.posRolePosPermissionConnection?.edges?.node?.id,
							posRoleId: data?.viewer?.posRoleConnection?.permissions?.posRoleId,
						});

						setPosAccessRolePermissions(data?.viewer?.posRoleConnection?.permissions);
					}

					setVariables(variables);

					return (
						<Permission access={Math.min(accessToMutations?.[MUTATION_NAME.createPosRoleInput], createPosRolePermission)}>
							<div>
								<PermissionField createHelperText>
									<Button
										disabled={false}
										onClick={() => handleOpenModal()}
									>
										New POS Access Role
									</Button>
								</PermissionField>
							</div>
						</Permission>
					);
				}}
				structureTable={(data) => {
					return data?.viewer?.posRoleConnection?.edges.map((edge) => {
						return {
							id: edge.node?.posRoleId,
							label: edge.node?.label,
							status: edge.node?.active,
							posRolePosPermissionConnection: edge.node?.posRolePosPermissionConnection?.edges,
						};
					});
				}}
				columns={[
					{ title: "ID", field: "id" },
					{ title: "POS Access Roles", field: "label" },
					{ 
						title: "Status", 
						field: "status",
						render: (active) => (
							<Typography variant="inherit" className={active ? classes.active : classes.inactive}>
								{active ? "Active" : "Inactive"}
							</Typography>
						)
					},
				]}
				onTableRowClick={handleOpenModal}
			/>
			<PosAccessEditModal
				open={isOpenPosAccessRoleModal}
				handleClose={handleCloseModal}
				posAccessRole={posAccessRole}
				accessToMutations={accessToMutations}
				posAccessRolePermissions={posAccessRolePermissions}
				createPosRolePermission={createPosRolePermission}
				updatePosRolePermission={updatePosRolePermission}
				variables={variables}
			/>
		</>
	)
};

export default PosAccess;