import { gql } from '@apollo/client';

export const UPDATE_COUPON = gql`
  mutation updateCoupon($input: UpdateCouponMutationInput!) {
    updateCoupon(input: $input) {
      coupon {
        id
        couponId
        label
        coupontypeId
        archived
      }
    }
  }
`;
