import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

window.renderSpark = (containerId, containerConfig) => {
  const { history, theme, coreUtils, name = '' } = containerConfig || {}; // we get utilities provided by the core and we pass them along to the App component below.

  ReactDOM.render(
    <App history={history} coreUtils={coreUtils} theme={theme} name={name} />,
    document.getElementById(containerId),
  );
}

window.unmountSpark = (containerId) => {
  const containerEl = document.getElementById(containerId);

  if (containerEl) {
    ReactDOM.unmountComponentAtNode(containerEl);
  }
}