import { gql } from '@apollo/client';

const MENU_CATEGORIES = gql`
  query menuCategories {
    viewer {
        id
        menuCategoryConnection {
          edges {
            node {
              id
              menu {
                id
                brand {
                  id
                  label
                }
              }
              category {
                id
                categoryId
                label
              }
            }
          }
        }
      }
  }
`;

export default MENU_CATEGORIES;
