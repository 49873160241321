import { gql } from '@apollo/client';

const FETCH_BRANDS = gql`
  query fetchBrands($sort: [BrandSort]) {
    viewer {
      id
      brandConnection(sort: $sort) {
        edges {
          node {
            id
            brandId
            label
            abbreviation
            description
          }
        }
      }
      tierConnection {
        edges {
          node {
            id
            label
            tierId
          }
        }
      }
      platformConnection {
        edges {
          node {
            id
            label
          }
        }
      }
    }
  }
`;

export default FETCH_BRANDS;
