import { gql } from '@apollo/client';

const FETCH_WEEK_IN_LIFE = gql`
  query FETCH_WEEK_IN_LIFE(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationIngredientSort]
    $filter: [LocationIngredientFilter]
    $dateRange: DateRangeFilterInput
    $displayAsCost: Boolean!
  ) {
    viewer {
      id
      locationIngredientConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 95
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            inventoryitem {
              id
              type
              inventoryitemId
              label
              measure {
                label
              }
            }
            weekInTheLife(dateRange: $dateRange, displayAsCost: $displayAsCost) {
              invoiceNumber
              supplierName
              isSimple
              transactionId
              transactionType
              user
              storageLocation
              value
              qtyIn
              qtyOut
              theoreticalQty
              actualQty
              qtyInCost
              qtyOutCost
              theoreticalQtyCost
              actualQtyCost
              transactionDate
              note
            }
          }
        }
      }
    }
  }
`;

export default FETCH_WEEK_IN_LIFE;
