import React, { useState, useCallback } from 'react';
import { Button, Grid } from '@material-ui/core';

import ItemGroup from './ItemGroup';
import ItemAddedNumberTimesStyles from './styles';

function ItemAddedNumberTimes({
  couponTriggers,
  control,
  itemAddedNumberTimes: { label, id, quantity },
  errors,
}) {
  const classes = ItemAddedNumberTimesStyles();

  const triggerGroups = couponTriggers?.triggerGroups;

  const [itemGroups, setItemGroups] = useState(triggerGroups ? [...triggerGroups] : []);

  const handleAddGroup = useCallback(() => {
    setItemGroups([...itemGroups, { minQty: 1, maxQty: 1, value: [] }]);
  }, [itemGroups]);

  const handleDelete = useCallback(
    index => {
      const tempItemGroups = [...itemGroups];
      tempItemGroups[index] = null;
      setItemGroups(tempItemGroups);
    },
    [itemGroups],
  );

  return (
    <Grid container className={classes.triggerContainer}>
      <Grid item xs={12}>
        <Button onClick={handleAddGroup}>Add Group</Button>
      </Grid>
      {itemGroups?.map((group, index) => {
        if (group) {
          return (
            <Grid item xs={12} className={classes.groups}>
              <ItemGroup
                control={control}
                index={index}
                group={group}
                handleDelete={handleDelete}
                errors={errors}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

export default ItemAddedNumberTimes;
