import React, { useEffect, useState } from 'react';
import AutoComplete from '../../../../../../../blocks/AutoComplete';
import { useQuery } from '@apollo/client';
import { FETCH_ITEM_LIST, FETCH_ITEMS } from '../../../../../../../../apollo/queries';
import { TextField, FormControl } from '@material-ui/core';
import { uglifyId } from '../../../../../../../../utils';
import { SelectFactory } from '../../../../../../../blocks';

function ItemSelect({
  control,
  name = 'discountDetails.item',
  setItem,
  isTrigger,
  isEvent,
  coupon,
  couponTriggers,
  discountDetailsItems,
  setDiscountDetailsItems,
  errors,
  includeSubcategories = true,
}) {
  const [itemList, setItemList] = useState([]);
  const [selectedItems, setSelctedItems] = useState({});
  const [preSelectedItems, setPreSelectedItems] = useState([]);

  const { data, loading, error } = useQuery(includeSubcategories ? FETCH_ITEM_LIST : FETCH_ITEMS, {
    variables: {
      filter: {
        showHiddenToLocation: {
          eq: true,
        },
        showUnavailableOnline: {
          eq: true,
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const itemList = data?.viewer?.itemConnection?.edges.map(item => ({
        label: `${item.node.label} -  ID_${item.node.itemId}`,
        value: item.node.id,
        isItem: true,
        id: item.node.id,
      }));
      setItemList(itemList);

      if (isEvent) {
        const myItems = coupon?.couponActionConnection?.edges.map(edge =>
          uglifyId('Item', edge.node.target || null),
        );
        const temp_preSelectedItems = itemList
          .filter(item => myItems.includes(item.value))
          .map(item => ({ label: item.label, id: item.value, isItem: true }));

        setPreSelectedItems(temp_preSelectedItems);
        setDiscountDetailsItems(temp_preSelectedItems);
      }

      if (isTrigger) {
        const graphQLItemIds = couponTriggers?.value.map(itemId => uglifyId('Item', itemId));
        const selectedItems = itemList.filter(cat => graphQLItemIds.includes(cat.value));
        setSelctedItems(selectedItems);
      }
    }
  }, [data]);

  if (loading) return 'Loading...';
  if (error) return 'error';

  const temp_itemList = data?.viewer?.itemConnection?.edges.map(item => ({
    label: `${item.node.label} -  ID_${item.node.itemId}`,
    value: item.node.id,
    isItem: true,
    id: item.node.id,
    numericId: item.node.itemId,
  }));

  let temp_preSelectedItems = [];
  let temp_itemsSelected = [];
  if (isEvent) {
    const myItems = coupon?.couponActionConnection?.edges.map(edge =>
      uglifyId('Item', edge.node.target || null),
    );
    temp_preSelectedItems = temp_itemList
      .filter(item => myItems.includes(item.value))
      .map(item => ({ label: item.label, id: item.value, isItem: true }));
  }

  if (isTrigger) {
    temp_itemsSelected = temp_itemList.filter(cat => couponTriggers.value?.includes(cat.numericId));
  }

  return (
    <div style={{ width: '50%' }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        {!isTrigger && data && (
          <SelectFactory
            label={'Items'}
            placeholder={'Select'}
            query={includeSubcategories ? FETCH_ITEM_LIST : FETCH_ITEMS}
            disableCloseOnSelect={true}
            defaultValue={temp_preSelectedItems}
            onSelect={items => {
              setDiscountDetailsItems(items);
            }}
            structureData={data => {
              return data?.viewer?.itemConnection?.edges?.map((edge, index) => ({
                label: edge.node.label,
                id: edge?.node.id,
                index,
              }));
            }}
          />
        )}
        {isTrigger && (
          <AutoComplete
            id={name}
            defaultValue={temp_itemsSelected || []}
            multiple={true}
            onChange={(e, newValue) => {
              if (setItem) {
                setItem({
                  label: e.label,
                  id: e.value,
                  quantity: 1,
                });
              }
            }}
            control={control}
            rules={{
              required: 'Item is required!',
            }}
            getOptionLabel={option => option.label || ''}
            options={itemList}
            disableCloseOnSelect
            renderInput={params => {
              if (isEvent) {
                return (
                  <TextField
                    {...params}
                    error={errors?.discountDetails && errors?.discountDetails?.item}
                    helperText={
                      errors?.discountDetails && errors?.discountDetails?.item
                        ? errors?.discountDetails?.item.message
                        : ''
                    }
                    label={'Items'}
                    variant="outlined"
                  />
                );
              }
              if (isTrigger && name !== 'triggerEvents.automatic.itemAddedNumberTimes.itemId') {
                return (
                  <TextField
                    {...params}
                    error={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemInCart?.itemId
                    }
                    helperText={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemInCart?.itemId
                        ? errors?.triggerEvents?.automatic?.itemInCart?.itemId?.message
                        : ''
                    }
                    label={'Items'}
                    variant="outlined"
                  />
                );
              }
              if (
                isTrigger &&
                name.includes('triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData')
              ) {
                return (
                  <TextField
                    {...params}
                    error={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData
                        ?.itemIds
                    }
                    helperText={
                      errors?.triggerEvents?.automatic &&
                      errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData
                        ?.itemIds
                        ? errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData
                            ?.itemIds?.message
                        : ''
                    }
                    label={'Items'}
                    variant="outlined"
                  />
                );
              }
            }}
          />
        )}
      </FormControl>
    </div>
  );
}

export default ItemSelect;
