import React from "react";
import ManualView from "./View";

function Manual({ coupon, control, manualTriggers, codeGenerationType }) {
  return (
    <div>
      <ManualView
        coupon={coupon}
        manualTriggers={manualTriggers}
        control={control}
        codeGenerationType={codeGenerationType}
      />
    </div>
  );
}

export default Manual;
