import { gql } from "@apollo/client";

const CREATE_TAX_GROUP = gql`
  mutation CreateTaxGroup($input: CreateTaxgroupMutationInput!) {
    createTaxgroup(input: $input) {
      taxgroup {
        id
      }
      viewer {
        id
      }
    }
  }
`;

export default CREATE_TAX_GROUP;
