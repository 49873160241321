import { gql } from '@apollo/client';

const FETCH_CATEGORY = gql`
  query fetchCategory($id: ID!, $filter: [SubcategoryFilter], $sort: [SubcategorySort]) {
    viewer {
      id
      category(id: $id, fieldGroupOnly: 7) {
        id
        categoryId
        label
        categorytype {
          id
          categorytypeId
          label
        }
        showCustomer
        description
        categoryimageConnection {
          edges {
            node {
              id
              label
              url
              description
            }
          }
        }
        subcategoryConnection(filter: $filter, sort: $sort) {
          edges {
            node {
              id
              subcategoryId
              label
            }
          }
        }
      }
    }
  }
`;

export default FETCH_CATEGORY;
