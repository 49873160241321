import React from "react";
import GeneralInfo from "./GeneralInfo";
import Orders from "./Orders";
import { Container } from "@material-ui/core";
import PointsHistory from "./PointsHistory";
import LoyaltyRewards from "./LoyaltyRewards";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import CUSTOMER from "../../../../../../apollo/queries/loyalty/customer";
import LoyaltySubmission from "./LoyaltySubmissions";
import HouseAccounts from './HouseAccounts';
export default function Customer() {
  const { customerId } = useParams();
  const { data, error, loading } = useQuery(CUSTOMER, {
    variables: {
      filter: {
        personId: {
          eq: parseInt(customerId),
        },
      },
    },
  });
  if (loading) return <div>Loading...</div>;

  if (error) console.log(error, "error");
  if (data)
    return (
      <Container>
        <GeneralInfo customerData={data?.viewer?.personConnection?.edges[0]} />
        <Orders />
        <PointsHistory
          customerData={data?.viewer?.personConnection?.edges[0]}
        />
        <LoyaltyRewards />
        <LoyaltySubmission />
        <HouseAccounts customerData={data?.viewer?.personConnection?.edges[0]} />
      </Container>
    );
}
