import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import PrepForecasterFormView from './View';
import { uglifyId } from '../../../../../utils';
import { useMutation, useQuery } from '@apollo/client';
import { PRODUCTION_FORECASTER } from '../../../../../apollo/queries/inventory';
import SAVE_SSPM from '../../../../../apollo/mutations/inventory/saveSSPM';
import SAVE_DAILY_FORECASTER from '../../../../../apollo/mutations/inventory/saveDailyForecaster';
import SAVE_CLOSING_INVENTORY from '../../../../../apollo/mutations/inventory/saveClosingInventory';
import { CircularProgress } from '@material-ui/core';

const ProductionForecasterForm = () => {
  const { locationId } = useParams();

  const locationGraphQLId = useMemo(() => uglifyId('Location', locationId), [locationId]);

  const { data, error, refetch, variables, loading } = useQuery(PRODUCTION_FORECASTER, {
    variables: {
      id: locationGraphQLId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [saveSSPM, { loading: saveSSPMLoading }] = useMutation(SAVE_SSPM, {
    refetchQueries: [
      {
        query: PRODUCTION_FORECASTER,
        variables,
      },
    ],
  });
  const [saveDailyForecaster, { loading: saveDailyForecasterLoading }] = useMutation(
    SAVE_DAILY_FORECASTER,
  );
  const [saveClosingInventory, { loading: saveClosingInventoryLoading }] = useMutation(
    SAVE_CLOSING_INVENTORY,
  );

  if (error) return { error };
  if (loading) return <CircularProgress />;

  const getSideUOM = (side, isClosingInventory) =>
    `${
      Math.round(side.size * 100) / 100 <= 1 && isClosingInventory
        ? ''
        : Math.round(side.size * 100) / 100
    }${side.measure} ${side.format}`;

  return (
    <PrepForecasterFormView
      data={data}
      refetch={refetch}
      saveSSPMLoading={saveSSPMLoading}
      saveDailyForecasterLoading={saveDailyForecasterLoading}
      saveClosingInventoryLoading={saveClosingInventoryLoading}
      variables={variables}
      saveSSPM={saveSSPM}
      saveDailyForecaster={saveDailyForecaster}
      getSideUOM={getSideUOM}
      saveClosingInventory={saveClosingInventory}
    />
  );
};

export default ProductionForecasterForm;
