import React, { useState, forwardRef } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const PasswordInput = forwardRef(
  ({ error, label, name, id, defaultValue, helperText, disabled, onClick }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <TextField
        disabled={disabled}
        onClick={onClick}
        id={id}
        error={error}
        name={name}
        helperText={helperText}
        type={showPassword ? "text" : "password"}
        placeholder="*********"
        label={label}
        defaultValue={defaultValue}
        fullWidth
        inputRef={ref}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prevState) => !prevState)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default PasswordInput;
