import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  scheduleTimelineActions: {
    marginBottom: theme.spacing(2),

    '& .MuiButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
  modalSub: {
    marginBottom: '20px',
  },
  modalButtons: {
    margin: '10px 0',
  },
  sortSelect: {
    marginBottom: '20px',

    '& .MuiSelect-root': {
      minWidth: '10rem',
    },
  },
});

export const ScheduleTimelineHeaderStyles = makeStyles(Styles);
