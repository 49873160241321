import { gql } from '@apollo/client';

const FETCH_COUNTRIES = gql`
  query FetchCountries($first: Int, $filter: [CountryFilter]) {
    viewer {
      id
      countryConnection(first: $first, filter: $filter) {
        edges {
          node {
            id
            countryId
            label
            alpha2
          }
        }
      }
    }
  }
`;

export default FETCH_COUNTRIES;
