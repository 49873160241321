import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { NUTRIENTS_LIST } from '../../../../../../apollo/queries';
import { REMOVE_NUTRIENT } from '../../../../../../apollo/mutations';
import CreateUpdateNutrient from '../../Modals/CreateUpdateNutrient';
import { Modal } from '../../../../../blocks';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

function NutrientsTable() {
  const { setSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({});

  const [nutrientId, setNutrientId] = useState(null);
  const [removeNutrient, { loading: isDeletingNutrient }] = useMutation(REMOVE_NUTRIENT);

  const handleClose = () => {
    setNutrientId(null);
    setOpen(false);
  };

  const onDeleteConfirmationClick = (nutrient, variables) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Removing nutrient...',
    });

    removeNutrient({
      variables: { input: { nutrientId: nutrient.rawId } },
      refetchQueries: [{ query: NUTRIENTS_LIST, variables: variables }],
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Nutrient removed',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: 'error',
          text: error.message,
        }),
      );
  };

  return (
    <div id="nutrients">
      <ConnectionTable
        query={NUTRIENTS_LIST}
        title="Nutrients"
        customActionsTop={(data, query) => {
          return (
            <Button
              onClick={() => {
                setQuery(query);
                setOpen(true);
              }}
              disabled={isDeletingNutrient}
            >
              New Nutrient
            </Button>
          );
        }}
        initialQueryVariables={{
          fieldGroupOnly: 89,
        }}
        onTableRowClick={(row, data, variables) => {
          setNutrientId(row.rawId);
          setQuery({ query: variables });
          setOpen(true);
        }}
        structureTable={data =>
          data?.viewer?.nutrientConnection?.edges?.map(ing => {
            return (
              {
                id: ing?.node?.nutrientId,
                label: ing?.node?.label,
                unitType: ing?.node?.measure?.label,
                description: ing?.node?.description,
                updated: moment
                  .tz(ing?.node?.updated, 'YYYY/MM/DD HH:mm', 'UTC')
                  .tz(moment.tz.guess())
                  .format('MM/DD/YYYY h:mm A'),
                rawId: ing?.node?.id,
              } || null
            );
          })
        }
        columns={[
          { title: 'Id', field: 'id' },
          { title: 'Name', field: 'label' },
          { title: 'Unit Type', field: 'unitType' },
          { title: 'Description', field: 'description' },
          { title: 'Updated', field: 'updated' },
        ]}
        handleDeleteRow={onDeleteConfirmationClick}
        useConfirmationModalForDelete
        isDeleting={isDeletingNutrient}
        deleteConfirmationText="Are you sure you want to delete this nutrient?"
      />
      <Modal
        open={open}
        handleClose={handleClose}
        title={nutrientId ? 'Update Nutrient' : 'Add Nutrient'}
      >
        <CreateUpdateNutrient handleClose={handleClose} query={query} nutrientId={nutrientId} />
      </Modal>
    </div>
  );
}

NutrientsTable.propTypes = {};

export default NutrientsTable;
