import { gql } from '@apollo/client';

const UPDATE_MENU = gql`
  mutation updateMenu($input: UpdateMenuMutationInput!) {
    updateMenu(input: $input) {
      clientMutationId
    }
  }
`;

export default UPDATE_MENU;
