import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ItemChoice from '../../../../../MenuTree/MenuTreeItem/Item/ItemChoices/ItemChoice';
import { useDrag, useDrop } from 'react-dnd';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import MULTIPLE_CHOICE from '../../../../../../../../../apollo/queries/menus/multipleChoice';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import {
  Divider,
  makeStyles,
  TextField,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core';
import { convert, CurrencyInputFormat, uglifyId } from '../../../../../../../../../utils';
import { debounce } from 'lodash';
import REMOVE_MULTIPLE_CHOICE from '../../../../../../../../../apollo/mutations/menus/removeMultipleChoice';
import UPDATE_MULTIPLE from '../../../../../../../../../apollo/mutations/menus/updateMultiple';
import CREATE_MULTIPLE from '../../../../../../../../../apollo/mutations/menus/createMultiple';
import paths from '../../../../../../../../../constants/paths';
import { useForm } from 'react-hook-form';
import { useSnackbar } from '../../../../../../../../../hooks/useSnackbar';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    position: 'relative',
  },
  childrenContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  mainTextField: {
    width: '75%',
  },
  textFieldContainer: {
    display: 'flex',
  },
  preparationTime: {
    marginRight: '0.5rem',
  },
});

const MultipleChoiceTable = ({ setNumberOfMultipleChoices, setMultipleLabel }) => {
  const { setSnackbar } = useSnackbar();
  const ref = useRef(null);
  const history = useHistory();
  const classes = useStyles();

  const { register, handleSubmit, control, setValue, errors, getValues } = useForm();
  const { multipleId: paramsMultipleId } = useParams();
  const [multipleChoiceValues, setMultipleChoiceValues] = useState({});
  const [choices, setChoices] = useState([]);
  const [
    removeMultipleChoiceMutation,
    { loading: removeMultipleChoiceMutationLoading },
  ] = useMutation(REMOVE_MULTIPLE_CHOICE);
  const [updateMultipleMutation, { loading: updateMultipleMutationLoading }] = useMutation(
    UPDATE_MULTIPLE,
  );
  const [createMultiple, { loading: createMultipleLoading }] = useMutation(CREATE_MULTIPLE);
  const [newChoicesSetUp, setNewChoicesSetUp] = useState([]);

  const disabled =
    createMultipleLoading || updateMultipleMutationLoading || removeMultipleChoiceMutationLoading;

  const { loading, data, error, refetch, variables } = useQuery(MULTIPLE_CHOICE, {
    variables: {
      id: uglifyId('Multiple', paramsMultipleId),
    },
    fetchPolicy: 'cache-first',
    skip: paramsMultipleId === 'new-multiple',
    onCompleted: data1 => {
      setNumberOfMultipleChoices(data1?.viewer?.multiple?.multipleChoiceConnection?.edges.length);
      setMultipleLabel(data1?.viewer?.multiple?.label);
      setChoices(
        data1?.viewer?.multiple?.multipleChoiceConnection?.edges.map((choice, index) => ({
          choiceId: choice?.node?.choice?.choiceId,
          id: choice?.node?.choice?.id,
          multipleId: choice?.node?.multiple?.id,
          defaultSelected: choice?.node?.defaultSelected,
          tiers: choice?.node?.multipleChoiceTierConnection?.edges?.map(multipleChoiceTier => ({
            tierId: multipleChoiceTier?.node?.tier?.tierId,
            price: multipleChoiceTier?.node?.price,
            priceCap: multipleChoiceTier?.node?.priceCap,
          })),
          label: choice?.node?.choice?.label,
          preparationTime: choice?.node?.preparationTime,
          allowedMaximum: parseInt(choice?.node?.allowedMaximum),
          cost: choice?.node?.price,
          priceCap: choice?.node?.priceCap,
          order: index + 1,
        })),
      );
    },
  });

  const multipleChoicesConnection = useMemo(() => {
    if (data) {
      return data?.viewer?.multiple?.multipleChoiceConnection?.edges;
    }
    return [];
  }, [data]);

  const loadingSections = () => {
    const array = [];
    for (let step = 0; step < 3; step++) {
      array.push(
        <Grid item xs={4}>
          <Skeleton variant="rect" width={'80%'} height={'5vh'} />
        </Grid>,
      );
    }
    return array;
  };

  const addChoice = item => {
    setChoices(prevState => [...prevState, item]);
  };

  const [{ isOver }, drop] = useDrop({
    accept: ['Choice'],
    drop: (item, monitor) => {
      const isAdedd = choices.filter(choice => choice.id === item.id).length;

      !isAdedd && item.newChoice && addChoice(item);

      if (isAdedd && item.newChoice) {
        setSnackbar({
          open: true,
          type: 'error',
          text: 'Choice is already added!',
        });
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'Choice' },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (loading)
    return (
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <Skeleton variant="rect" width={'100%'} height={'5vh'} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rect" width={'100%'} height={'5vh'} />
        </Grid>
        {loadingSections()}
        {loadingSections()}
        {loadingSections()}
      </Grid>
    );

  if (error) console.log(error);

  const getPath = () => {
    return paths.SETTINGS_CHOICES;
  };

  const switchElements = (arr, indexA, indexB) => {
    let temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };
  const moveChoiceItem = (dragIndex, hoverIndex) => {
    setChoices(prevState => [...switchElements(prevState, dragIndex, hoverIndex)]);
  };

  const onSubmit = formData => {
    const formatData = [];
    for (const property in formData) {
      const multiple = formData[property];
      const multipleValues = multiple.multipleValues;
      const choicesValues = multiple.choicesValues;
      const choicesArray = [];
      for (const choiceProperty in choicesValues) {
        const choice = choicesValues[choiceProperty];

        const defaultSelected = choice.defaultSelected;
        const choicesWithOrder = choices.map((el, index) => {
          el.order = index + 1;
          return el;
        });

        newChoicesSetUp?.forEach(choice => {
          delete choice?.formData;
        });
        const multipleChoicePrice = multipleChoicesConnection?.length
          ? multipleChoicesConnection?.find(e => e?.node?.choice?.id === choiceProperty)?.node
              ?.price
          : null;
        const multipleChoicePriceCap = multipleChoicesConnection?.length
          ? multipleChoicesConnection?.find(e => e?.node?.choice?.id === choiceProperty)?.node
              ?.priceCap
          : null;
        const multipleChoiceTiersConnections = multipleChoicesConnection?.length
          ? multipleChoicesConnection
              ?.find(e => e?.node?.choice?.id === choiceProperty)
              ?.node?.multipleChoiceTierConnection?.edges.map(e => ({
                tierId: e?.node?.tier.id,
                price:
                  typeof e?.node?.price === 'number' ? convert.centsToDollars(e?.node?.price) : 0,
                platforms: e?.node?.multipleChoiceTierPlatformConnection?.edges.map(e => ({
                  platformId: e?.node?.platform?.id,
                  priceCap:
                    typeof e?.node?.priceCap === 'number'
                      ? convert.centsToDollars(e?.node?.priceCap)
                      : null,
                })),
              }))
          : [];

        const orderKey = choicesWithOrder.filter(el => el.id === choiceProperty)[0]?.order;
        if (paramsMultipleId === 'new-multiple') {
          if (!newChoicesSetUp?.find(choice => choice?.choiceId === choiceProperty)) {
            setSnackbar({
              open: true,
              type: 'error',
              text: 'Update failed! Make sure that every choice have default MCPI defined.',
            });
            return;
          }
          choicesArray.push({
            choiceId: choiceProperty,
            ...newChoicesSetUp?.find(choice => choice?.choiceId === choiceProperty),
            preparationTime: choice?.preparationTime,
            allowedMaximum: choice?.allowedMaximum,
            defaultSelected,
          });
        } else {
          choicesArray.push({
            choiceId: choiceProperty,
            ...choice,
            defaultSelected,
            orderKey,
            preparationTime: choice.preparationTime,
            price:
              typeof multipleChoicePrice === 'number'
                ? convert.centsToDollars(multipleChoicePrice)
                : null,
            priceCap:
              typeof multipleChoicePriceCap === 'number'
                ? convert.centsToDollars(multipleChoicePriceCap)
                : null,
            tiers: multipleChoiceTiersConnections,
          });
        }
      }
      if (!choicesArray?.length) {
        setSnackbar({
          open: true,
          type: 'error',
          text: 'Choices must be selected!',
        });
        return;
      }

      if (choicesArray?.find(ch => ch.price === null)) {
        setSnackbar({
          open: true,
          type: 'error',
          text: 'Update failed! Make sure that every choice have default MCPI defined.',
        });
        return;
      }

      formatData.push({
        multipleId: property,
        ...multipleValues,
        choices: choicesArray,
      });
    }
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Choice is being updated!',
    });

    if (paramsMultipleId === 'new-multiple') {
      createMultiple({
        variables: {
          input: {
            label: formatData[0].label,
            onlineLabel: formatData[0].onlineLabel,
            allowedMaximum: formatData[0].allowedMaximum,
            orderKey: 1,
            description: formatData[0].description,
            choices: formatData[0].choices,
          },
        },
      }).then(res => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Multiple is created successfully!',
        });
        debounce(() => history.push(getPath()), 600)();
      });
    } else {
      updateMultipleMutation({
        variables: {
          input: {
            description: formatData[0].description,
            multipleId: formatData[0].multipleId,
            label: formatData[0].label,
            onlineLabel: formatData[0].onlineLabel,
            choices: formatData[0].choices,
          },
        },
      }).then(() => {
        refetch(variables).then(r => r);
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Multiple choices are successfully updated!',
        });
      });
    }
  };

  const removeMultipleChoice = (choiceId, multipleId) => {
    setChoices(prevState => prevState.filter(choice => choice.id !== choiceId));
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Choice is being deleted!',
    });
    removeMultipleChoiceMutation({
      variables: {
        input: {
          multipleId,
          choiceId,
        },
      },
    }).then(() => {
      refetch({
        filter: {
          multipleId: { eq: atob(multipleId)?.split(':')?.[1] },
        },
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Choice is successfully deleted!',
        }),
      );
    });
  };

  const multipleLabelFromQuery = data?.viewer?.multiple?.label;
  const multipleOnlineLabelFromQuery = data?.viewer?.multiple?.onlineLabel;
  const multipleDescriptionFromQuery = data?.viewer?.multiple?.description;
  const fullMultipleId = uglifyId('Multiple', paramsMultipleId);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
            <Button type={'submit'} disabled={disabled}>
              Save
            </Button>
            <br />
            <br />
            {disabled ? <LinearProgress /> : null}
          </div>
          <div className={classes.root}>
            <div className={classes.mainTextField}>
              <TextField
                label="Multiple Name"
                variant={'outlined'}
                inputRef={register({ required: true })}
                error={
                  errors[fullMultipleId] && Boolean(errors[fullMultipleId]?.multipleValues?.label)
                }
                helperText={
                  errors[fullMultipleId]?.multipleValues?.label && 'Multiple label is required.'
                }
                name={`${fullMultipleId}.multipleValues.label`}
                defaultValue={multipleLabelFromQuery}
                fullWidth={true}
              />
            </div>
            <div>
              <TextField
                label="Online Ordering Label"
                inputRef={register({ required: true })}
                error={
                  errors[fullMultipleId] &&
                  Boolean(errors[fullMultipleId]?.multipleValues?.onlineLabel)
                }
                helperText={
                  errors[fullMultipleId]?.multipleValues &&
                  errors[fullMultipleId]?.multipleValues?.onlineLabel &&
                  'Online ordering label is required.'
                }
                name={`${fullMultipleId}.multipleValues.onlineLabel`}
                variant={'outlined'}
                defaultValue={multipleOnlineLabelFromQuery}
              />
            </div>
          </div>
          <div>
            <TextField
              label="Description"
              inputRef={register}
              fullWidth
              name={`${fullMultipleId}.multipleValues.description`}
              variant={'outlined'}
              defaultValue={multipleDescriptionFromQuery}
            />
          </div>
        </div>
        <div ref={drop} style={{ height: '45vh', overflow: 'scroll' }}>
          <Divider />
          {choices?.map((choice, index) => (
            <ItemChoice
              key={`${choice?.id}-itemChoicesId`}
              moveChoiceItem={moveChoiceItem}
              index={index}
              control={control}
              getValues={getValues}
              setValue={setValue}
              choice={choice}
              multipleId={fullMultipleId}
              choices={choices}
              setChoices={setChoices}
              register={register}
              setMultipleChoiceValues={setMultipleChoiceValues}
              errors={errors}
              multipleChoiceValues={multipleChoiceValues}
              removeMultipleChoice={removeMultipleChoice}
              paramsMultipleId={paramsMultipleId}
              setNewChoicesSetUp={setNewChoicesSetUp}
              newChoicesSetUp={newChoicesSetUp}
              refetch={refetch}
            />
          ))}
        </div>
      </form>
    </div>
  );
};

export default MultipleChoiceTable;
