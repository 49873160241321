import React, { useEffect, useState } from "react";
import { Button, Chip, CircularProgress } from "@material-ui/core";
import { Modal } from "../../../../../../../../blocks";
import { Controller } from "react-hook-form";
import Add from "@material-ui/icons/Add";
import AddItemsModal from "../../../../DiscountRestrictions/Items/AddItemsModal";
import { FETCH_ITEM_LIST } from "../../../../../../../../../apollo/queries";
import { useLazyQuery } from "@apollo/client";
import { uglifyId } from "../../../../../../../../../utils";
function ForEachItem({
  couponTriggers,
  control,
  cartItemsRequired,
  setCartItemsRequired,
}) {
  const [open, setOpen] = useState(false);
  const [loadItems, { loading }] = useLazyQuery(FETCH_ITEM_LIST, {
    onCompleted: (data) => {
      setCartItemsRequired(
        data.viewer.itemConnection.edges.map((edge) => ({
          key: edge.node.id,
          id: edge.node.id,
          itemId: edge.node.itemId,
          label: `${edge.node.label} - ID_${edge.node.itemId}`,
          isItem: true,
        }))
      );
    },
    onError: (err) => {
      console.log(err, "err...");
    },
  });

  const handleClose = (items) => {
    if (items.length) {
      setCartItemsRequired(
        items.map(({ label, id, key, itemId, isItem }) => ({
          label,
          id,
          itemId,
          key,
          isItem,
        }))
      );
    }
    setOpen(false);
  };

  const handleDelete = (itemId) => {
    const newArr = cartItemsRequired.filter((item) => item.id !== itemId);
    setCartItemsRequired(newArr);
  };

  useEffect(() => {
    if (
      couponTriggers.couponTriggerId === 4 ||
      couponTriggers.couponTriggerId === 7
    ) {
      loadItems({
        variables: {
          filter: {
            id: {
              in: couponTriggers.values.map((itemNumId) =>
                uglifyId("Item", itemNumId.valueOff)
              ),
            },
          },
        },
      });
    }
  }, []);

  if (loading) {
    return (
      <p>
        Loading items... <CircularProgress />
      </p>
    );
  }

  return (
    <div>
      <Controller
        control={control}
        name="triggerEvents.automatic.forEachItem"
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Items</h3>
        <div>
          <Button onClick={setOpen.bind(this, true)} variant="outlined">
            <Add /> Add Items
          </Button>
        </div>
      </div>
      <div>
        {cartItemsRequired.map((item) => {
          if (item.isItem) {
            return (
              <Chip
                key={item.id}
                style={{ margin: "4px" }}
                label={item.label}
                onDelete={(e) => handleDelete(item.id)}
              />
            );
          } else {
            return "";
          }
        })}
      </div>
      <Modal open={open} handleClose={handleClose} title="Add Items">
        <AddItemsModal
          excludedItems={cartItemsRequired}
          setExcludedItems={setCartItemsRequired}
          handleClose={handleClose}
        />
      </Modal>
    </div>
  );
}

export default ForEachItem;
