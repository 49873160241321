import { gql } from '@apollo/client';

const REFUNDREASON = gql`
  query Refundreason {
    viewer {
      id
      refundreasonConnection {
        permissions
        edges{
          node{
            id
            refundreasonId
            label
            }
          }
        }
    }
  }
`;

export default REFUNDREASON;
