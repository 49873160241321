import { gql } from '@apollo/client';

const CREATE_HOUSE_ACCOUNT = gql`
  mutation createHouseaccount($input: CreateHouseaccountMutationInput!) {
    createHouseaccount(input: $input) {
      houseaccount {
        id
        houseaccountId
        limit
        locationId
      }
      viewer {
        id
      }
    }
  }
`;

export default CREATE_HOUSE_ACCOUNT;
