import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CreateItem from './CreateItem';
import ItemInfo from './ItemInfo';
import ItemChoices from './ItemChoices';
import ItemRecipes from './ItemRecipes';
import { Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../../../../../blocks';

const getTabs = gqlItemId =>
  !gqlItemId
    ? [
        {
          label: 'Create Item',
          Component: CreateItem,
          id: 0,
        },
      ]
    : [
        {
          label: 'Item Info',
          Component: ItemInfo,
          id: 0,
        },
        {
          label: 'Choices',
          Component: ItemChoices,
          id: 1,
        },
        {
          label: 'Ingredients',
          Component: ItemRecipes,
          id: 2,
        },
        // {
        //   label: 'Item History',
        //   component: ItemHistory ,
        // },
      ];

const Item = ({ ids, basePath, classes, refetchMenuList, itemTypeOptions, menuData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { gqlItemId, itemId } = useMemo(() => ids, [ids]);

  const onChange = useCallback((e, value) => {
    setActiveTab(value);
  }, []);

  const tabs = useMemo(() => getTabs(gqlItemId), [gqlItemId]);

  return (
    <div className={classes.container}>
      <Tabs value={activeTab} onChange={onChange}>
        {tabs.map(tab => (
          <Tab key={tab.id} label={tab.label} />
        ))}
      </Tabs>
      {tabs.map(({ id, Component }) => (
        <TabPanel value={activeTab} index={id} key={id}>
          <div className={classes.tabContent}>
            <Component
              ids={ids}
              basePath={basePath}
              classes={classes}
              refetchMenuList={refetchMenuList}
              gqlItemId={gqlItemId}
              itemTypeOptions={itemTypeOptions}
              itemId={itemId}
              refetchMenuList={refetchMenuList}
              itemTypeOptions={itemTypeOptions}
              menuData={menuData}
            />
          </div>
        </TabPanel>
      ))}
    </div>
  );
};

Item.propTypes = {
  ids: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Item;
