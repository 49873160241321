import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';

import BankSettingsStyles from './styles';

import { ContentInfo, PermissionField } from '../../../../../../blocks';
import Permission from '../../../../../../blocks/Permission';
import { MUTATION_NAME } from '../../../../../../../constants';
import { compact } from 'lodash';

const BankSettingsView = ({
  bankInfo,
  handleOpenModal,
  mutationPermissions,
  permission,
  accessToMutations,
}) => {
  const createLocationStripePermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'createLocationStripe',
  );
  const classes = BankSettingsStyles();

  const bankSettingsRenderData = [
    [
      {
        id: '7a5069de-f86a-4fd8-9bc6-0b8da0982a11',
        label: 'Business Name',
        value: bankInfo?.settings?.dashboard?.display_name,
        permission,
      },
      {
        id: 'dcb14786-26c5-4c41-b185-f0612cee8d4f',
        label: 'Authorized Representative',
        value:
          bankInfo?.person &&
          compact([bankInfo.person.first_name, bankInfo.person.last_name]).join([' ']),
        permission,
      },
      {
        id: '9728b128-3747-4f71-844b-7125608fc7a8',
        label: 'Bank Name',
        value: bankInfo?.external_accounts?.data && bankInfo.external_accounts.data[0]?.bank_name,
        permission,
      },
    ],
    [
      {
        id: '2273099d-51f4-4dde-bd45-d23c100b673c',
        label: 'Business Tax Id',
        value: bankInfo?.company?.tax_id_provided && '**********',
        permission,
      },
      {
        id: '5adc8658-3a25-451d-b529-a0e340525391',
        label: 'Date of Birth (representative)',
        value:
          bankInfo?.person?.dob &&
          moment(`
                ${bankInfo?.person?.dob?.year}
                ${bankInfo?.person?.dob?.month}
                ${bankInfo?.person?.dob?.day}
              `).format('MM/DD/YYYY'),
        permission,
      },
      {
        id: '05b52ead-f4f9-4b17-96f9-605eaeccfc5b',
        label: 'Bank Account Number',
        value: bankInfo?.external_accounts?.total_count && '**********',
        permission,
      },
    ],
    [
      {
        id: '7a5069de-f86a-4fd8-9bc6-0b8da0982a11',
        label: 'Address',
        value:
          bankInfo?.person?.address &&
          `
                  ${bankInfo?.person?.address?.line1},
                  ${bankInfo?.person?.address?.city},
                  ${bankInfo?.person?.address?.state},
                  ${bankInfo?.person?.address?.postal_code},
                  ${bankInfo?.person?.address?.country}
                `,
        permission,
      },
      {
        id: 'dcb14786-26c5-4c41-b185-f0612cee8d4f',
        label: 'SSN last 4 numbers (representative)',
        value: bankInfo?.person?.ssn_last_4_provided && '**********',
        permission,
      },
      {
        id: '9728b128-3747-4f71-844b-7125608fc7a8',
        label: 'Bank Account Routing Number',
        value: bankInfo?.external_accounts?.total_count && '**********',
        permission,
      },
    ],
    [
      {
        id: '0c1471ac-ecb9-4c3e-a82c-25b195027cd5',
        label: 'Bank Account Type',
        value: bankInfo?.external_accounts?.data[0]?.account_holder_type,
        permission,
      },
    ],
  ];

  const verified = bankInfo?.requirements && !bankInfo.requirements.currently_due?.length;

  return (
    <div id="bankSettings">
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="center"
        key="locationBankSettings"
        className={classes.heading}
      >
        <Grid item>
          <Grid container alignItems="center">
            <Typography variant="h4" className={classes.titleRoot}>
              Bank Settings
            </Typography>
            <Typography variant="subtitle2" color={verified ? 'inherit' : 'error'}>
              Verification status: {verified ? 'Verified' : 'Unverified'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Permission
            access={Math.min(
              createLocationStripePermission?.access,
              accessToMutations?.[MUTATION_NAME.CreateLocationStripeMutationInput],
            )}
          >
            <div>
              <PermissionField createHelperText>
                <Button onClick={() => handleOpenModal()}>Edit</Button>
              </PermissionField>
            </div>
          </Permission>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container md={10} xs={12}>
          <Typography variant="body2" className={classes.textContent}>
            After bank settings are created, please, check if your verification status is set to
            Verified. If not, please enter the whole security session number in the SSN field. If
            all information is successfully saved, you can activate your location from the Locations
            screen.
          </Typography>
        </Grid>
        <ContentInfo contentInfo={bankSettingsRenderData} id="bankSettings" numOfColumns={3} />
      </Grid>
    </div>
  );
};

BankSettingsView.propTypes = {
  bankInfo: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func,
};

export default BankSettingsView;
