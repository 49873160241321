import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import {
  ImageUpload,
  PermissionField,
  AutoComplete,
} from '../../../../../../blocks';
import { FETCH_STATES } from '../../../../../../../apollo/queries'
import { regexPattern } from '../../../../../../../utils';
import PhoneNumberInput from '../../../../../../blocks/PhoneNumberInput';

const EditVendorView = ({
  onSubmit,
  vendor,
  handleCloseModal,
  updateVendorLoading,
  setImages,
  images,
  IMAGES,
}) => {

  const { handleSubmit, register, control, errors } = useForm();

  const { data } = useQuery(FETCH_STATES, {
    variables: {
      first: 100,
    },
  });

  const stateList = useMemo(() => data?.viewer?.stateConnection?.edges?.map(state => ({
    label: state.node.label,
    value: state.node.stateId
  })), [data?.viewer?.stateConnection?.edges]);

  const handleOnSubmit = useCallback((formData) => {

    const inputData = {
      vendorId: vendor?.id,
      label: formData.label,
      orderUrl: formData.orderUrl,
      promoCode: formData.promoCode,
      contactName: formData.contactName,
      email: formData.email,
      phone: formData.phone,
      address1: formData.address,
      address2: formData.additionalInfo,
      city: formData.city,
      stateLabel: formData?.stateLabel?.label || '',
      zip: formData.zip,
      notes: formData.notes,
    };

    onSubmit(inputData);
  }, [onSubmit, vendor?.id]);

  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <PermissionField
              Component={TextField}
              name="label"
              label="Name"
              inputRef={register({ required: true })}
              defaultValue={vendor?.label}
              fullWidth
              control={control}
              error={errors.label}
              placeholder={'Label'}
              helperText={errors?.label ? 'Label cannot be empty' : errors?.label?.message}
            />
          </Grid>
          <Grid item xs={4}>
            <PermissionField
              Component={TextField}
              name="promoCode"
              label="Promo Code"
              defaultValue={vendor?.promoCode}
              fullWidth
              type='number'
              inputRef={register}
              control={control}
              placeholder={'Promo code'}
            />
          </Grid>
          <Grid item xs={12}>
            <PermissionField
              Component={TextField}
              name="orderUrl"
              label="Order URL"
              defaultValue={vendor?.orderUrl}
              fullWidth
              inputRef={register}
              control={control}
              placeholder={'Order URL'}
            />
          </Grid>
          <Grid item xs={4}>
            <PermissionField
              Component={TextField}
              name="contactName"
              label="Contact Name"
              defaultValue={vendor?.contactName}
              fullWidth
              inputRef={register}
              control={control}
              placeholder={'Contact name'}
            />
          </Grid>
          <Grid item xs={4}>
            <PermissionField
              Component={TextField}
              name="email"
              label="Email"
              defaultValue={vendor?.email}
              fullWidth
              inputRef={register({
                pattern: {
                  value: regexPattern.email,
                  message: "Invalid email address",
                },
              })}
              control={control}
              error={!!errors.email}
              helperText={errors.email && "Invalid email address."}
              placeholder={'Email'}
            />
          </Grid>
          <Grid item xs={4}>
            <PermissionField
              Component={PhoneNumberInput}
              label={"Phone"}
              name="phone"
              id='phone'
              fullWidth
              error={errors?.phone}
              helperText='Phone is not correct.'
              control={control}
              defaultValue={vendor?.phone?.phone}
              placeholder={'Phone number'}
            />
          </Grid>
          <Grid item xs={4}>
            <PermissionField
              Component={TextField}
              name="address"
              label="Address"
              defaultValue={vendor?.address?.address}
              fullWidth
              inputRef={register}
              control={control}
            />
          </Grid>
          <Grid item xs={4}>
            <PermissionField
              Component={TextField}
              name="additionalInfo"
              label="Additional Address"
              defaultValue={vendor?.address?.additionalInfo}
              fullWidth
              inputRef={register}
              control={control}
              placeholder={'Additional address'}
            />
          </Grid>
          <Grid item xs={4}>
            <PermissionField
              Component={TextField}
              name="city"
              label="City"
              placeholder={'City'}
              inputRef={register({
                pattern: {
                  value: regexPattern.onlyText,
                  message: "Invalid city",
                },
              })}
              fullWidth
              error={!!errors.city?.message}
              helperText={errors.city?.message && "Invalid city."}
              defaultValue={vendor?.address?.city}
            />
          </Grid>
          <Grid item xs={2}>
            <PermissionField
              Component={TextField}
              name="zip"
              label="Zip"
              defaultValue={vendor?.address?.zip}
              fullWidth
              inputRef={register}
              control={control}
              type={'number'}
              placeholder={'Zip'}
            />
          </Grid>
          <Grid item xs={2}>
            <PermissionField
              Component={AutoComplete}
              name='stateLabel'
              id={"stateLabel"}
              getOptionLabel={(option) => option.label}
              control={control}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={"State"}
                  variant="outlined"
                />
              }
              options={stateList || []}
              defaultValue={vendor?.address?.state}
            />
          </Grid>
          <Grid item xs={12}>
            <PermissionField
              Component={TextField}
              name="notes"
              label="Notes"
              defaultValue={vendor?.notes}
              fullWidth
              rows={4}
              multiline
              inputRef={register}
              control={control}
              placeholder={'Notes'}
            />
          </Grid>
          <Grid item xs={6}>
            <PermissionField
              Component={ImageUpload}
              name="logoImage"
              images={images}
              setImages={setImages}
              size={IMAGES.sizes[0]}
              maxSize={50*1000}
              helperText="Required image size is 75 x 75 px in JPG format. File weight in max. 50KB."
              inputRef={register}
              control={control}
            />
          </Grid>

          <Grid container item xs={12} justify="flex-end" spacing={3}>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Grid>
              <Grid item>
                <div>
                  <PermissionField createHelperText>
                    <Button
                      type="submit"
                      disabled={updateVendorLoading}
                    >
                      Save
                    </Button>
                  </PermissionField>
                </div>
              </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditVendorView;
