import React, { useEffect, useState } from 'react';
import Add from '@material-ui/icons/Add';
import { Waypoint } from 'react-waypoint';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import ChoiceItem from './choiceItem';
import AddChoice from './AddChoice';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../../../constants';
import { useQuery } from '@apollo/client';
import CHOICES from '../../../../../../../apollo/queries/choices';
import { debounce } from 'lodash';
import { convert } from '../../../../../../../utils';
import { Modal } from '../../../../../../blocks';

const useStyles = makeStyles({
  root: {
    height: '70vh',
    width: '100%',
    padding: '0.5rem',
  },
  hederContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  scrrolable: {
    overflow: 'auto',
    height: '80%',
  },
});

const ChoicesSideBar = ({ numberOfMultipleChoices, value }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [choiceListSideBar, setChoiceListSideBar] = useState();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const {
    data: choiceData,
    error: choiceError,
    loading: choiceLoading,
    fetchMore,
    variables,
    refetch,
  } = useQuery(CHOICES, {
    variables: {
      first: 30,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: !Boolean(value),
    onError: error => {
      console.log(error, 'error');
    },
  });
  const [searchValue, setSearchValue] = useState(variables.search);

  // if (choiceLoading && variables.first === 30) return <Grid container wrap={"nowrap"} style={{ display: "flex", flexDirection: "column" }}>
  //   <Skeleton variant="rect" width={"100%"} height={"2rem"}
  //             style={{ marginBottom: "1rem", paddingRight: "1rem", paddingLeft: "1rem" }}/>
  //   <Skeleton variant="rect" width={"100%"} height={"55vh"}/>
  // </Grid>;
  useEffect(() => {
    setChoiceListSideBar(
      choiceData?.viewer?.choiceConnection?.edges?.map(choice => {
        return {
          id: choice?.node?.id,
          cost: convert.centsToDollars(choice?.node?.cost),
          label: choice?.node?.label,
          imageUrl: choice?.node?.choiceimageConnection?.edges[0]?.node?.url,
          description: choice?.node?.description,
          choiceId: choice?.node?.choiceId,
          ingredients: choice?.node?.choiceIngredientConnection?.edges?.map((edge, index) => {
            const inventoryItem = edge.node.ingredient || edge.node.recipe;

            return {
              label: inventoryItem?.inventoryitem?.label,
              inventoryitemId: inventoryItem?.inventoryitem?.inventoryitemId,
              quantity: edge.node.quantity,
              itemId: edge.node.itemId,
              itemMenuLink: edge.node.itemMenuLink,
              index,
              measureId: edge.node.measure?.measureId,
              measureOptions: inventoryItem?.inventoryitem?.inventoryitemMeasureConnection?.edges?.map(
                measureEdge => ({
                  value: measureEdge.node.measure?.measureId,
                  label: `${measureEdge.node.measure?.label} (${measureEdge.node.measure?.abbreviation})`,
                }),
              ),
            };
          }),
        };
      }),
    );
  }, [choiceData]);
  if (choiceError) console.log(choiceError);
  const search = value => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      search: value || null,
    });
  };
  const [debouncedSearch] = useState(() => debounce(value => search(value), 200));
  const handleSearchInput = event => {
    debouncedSearch(event.target.value);
  };
  return (
    <div className={classes.root}>
      <div className={classes.hederContainer}>
        <div>
          <h3>Choices</h3>
        </div>
        <div>
          <IconButton style={{ padding: '0' }} aria-label="copy" onClick={() => handleOpen()}>
            <Add fontSize={'large'} />
          </IconButton>
        </div>
      </div>
      <div>
        <TextField
          label="Search"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleSearchInput}
        />
      </div>
      <div className={classes.scrrolable}>
        <Table aria-label="simple table">
          <TableBody>
            {choiceListSideBar
              ? choiceListSideBar?.length
                ? choiceListSideBar?.map((row, i) => {
                    return (
                      <>
                        <TableRow key={`choice-${i}`}>
                          <ChoiceItem
                            name={row.name}
                            key={row?.id}
                            refetch={refetch}
                            variables={variables}
                            id={row.id}
                            cost={row.cost}
                            label={row.label}
                            description={row.description}
                            ingredients={row.ingredients}
                            imageUrl={row.imageUrl}
                            choiceId={row.choiceId}
                            numberOfMultipleChoices={numberOfMultipleChoices}
                          />
                        </TableRow>
                        {i === choiceListSideBar?.length - ROWS_PER_PAGE_OPTIONS[0] && (
                          <Waypoint
                            onEnter={() => {
                              fetchMore({
                                variables: {
                                  first: ROWS_PER_PAGE_OPTIONS[0],
                                  after: choiceData?.viewer?.choiceConnection?.pageInfo?.endCursor,
                                },
                                updateQuery: (pv, { fetchMoreResult }) => {
                                  if (!fetchMoreResult) {
                                    return pv;
                                  }
                                  return {
                                    viewer: {
                                      ...pv.viewer,
                                      choiceConnection: {
                                        ...pv?.viewer?.choiceConnection,
                                        pageInfo: {
                                          ...pv.viewer?.choiceConnection?.pageInfo,
                                          endCursor:
                                            fetchMoreResult.viewer?.choiceConnection?.pageInfo
                                              ?.endCursor,
                                          hasNextPage:
                                            fetchMoreResult.viewer?.choiceConnection?.pageInfo
                                              ?.hasNextPage,
                                        },
                                        edges: [
                                          ...pv.viewer?.choiceConnection?.edges,
                                          ...fetchMoreResult.viewer?.choiceConnection.edges,
                                        ],
                                      },
                                    },
                                  };
                                },
                              });
                            }}
                          />
                        )}
                      </>
                    );
                  })
                : 'No Data'
              : 'Loading'}
          </TableBody>
        </Table>
      </div>
      <Modal open={open} handleClose={handleClose}>
        <AddChoice handleClose={handleClose} variables={variables} />
      </Modal>
    </div>
  );
};

export default ChoicesSideBar;
