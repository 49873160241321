import { Grid, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { useCallback, useEffect } from 'react';

const FormItem = ({
  id,
  register,
  control,
  errors,
  getValues,
  item,
  autoFocus,
  setAutofocusId,
  itemComponent: ItemComponent,
  onRemoveItem,
  showRemoveItemButton,
  setValue,
  itemComponentProps = {},
  unregister,
}) => {
  useEffect(() => {
    setAutofocusId(-1);

    return () => {
      Object.keys(item).forEach(key => unregister(`${id}.${key}`));
      unregister(`${id}.id`);
      unregister(id);
    };
  }, [id, item]);

  const handleRemoveClick = useCallback(() => {
    onRemoveItem(id);
  }, [id, onRemoveItem]);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={showRemoveItemButton ? 11 : 12}>
        <ItemComponent
          {...itemComponentProps}
          id={id}
          unregister={unregister}
          item={item}
          getValues={getValues}
          autoFocus={autoFocus}
          register={register}
          errors={errors}
          setValue={setValue}
          control={control}
          onRemoveItem={handleRemoveClick}
        />
      </Grid>
      {showRemoveItemButton ? (
        <Grid item xs={1} justify="center" container>
          <IconButton size="small" onClick={handleRemoveClick}>
            <Close />
          </IconButton>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FormItem;
