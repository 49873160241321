import { gql } from '@apollo/client';
import equipmentFragment from '../../fragments/equipmentFragment';

const UPDATE_EQUIPMENT = gql`
  mutation UPDATE_EQUIPMENT($input: updateEquipmentMutationInput!) {
    updateEquipment(input: $input) {
      viewer {
        id
      }
      equipment {
        ...EquipmentData
      }
    }
  }

  ${equipmentFragment.equipment}
`;

export default UPDATE_EQUIPMENT;
