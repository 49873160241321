const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const onlyNumber = /^-?[0-9]\d*\.?\d*$/i;

const onlyText = /^[a-zA-Z ]*$/i;

const regexPattern = {
  email,
  onlyNumber,
  onlyText,
};

export default regexPattern;
