import { gql } from "@apollo/client";

export const CONTACT_CUSTOMER = gql`
  mutation contactCustomer($input: ContactCustomerMutationInput!) {
    contactCustomer(input: $input) {
      quote {
        id
      }
    }
  }
`;
