import { gql } from '@apollo/client';

const INGREDIENT_BUILDER = gql`
  query INGREDIENT_BUILDER(
    $ingredientFilter: [IngredientFilter]
    $ingridientAllergenFilter: [IngredientAllergenFilter]
    $ingridientNutrientFilter: [IngredientNutrientFilter]
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      ingredientConnection(filter: $ingredientFilter, fieldGroupOnly: $fieldGroupOnly) {
        edges {
          node {
            id
            inventoryitem {
              id
              inventoryitemId
              label
              showOnLocation
              allowAutomaticStockToggle
              partOfWastelog
              wasteLogMeasureOverrideId
              inventoryitemPrep {
                id
                cspm
                size
                sizeCooked
                format
                partOfClosingInventory
                hasStrategic
                averageCasePackInfo
                smokeTodayForTomorrow
              }
              inventoryitemCustomCategory {
                id
                inventoryitemCustomCategoryId
                label
              }
              inventoryitemcategoryId
              inventoryitemcategory {
                id
                inventoryitemcategoryId
                label
              }
              inventoryitemMeasureConnection {
                edges {
                  node {
                    measureId
                    isForCount
                    conversion
                    measure {
                      measureId
                      abbreviation
                    }
                  }
                }
              }
              label
              description
              cost
              inventoryStatus
              status
              created
              measure {
                id
                label
                measureId
                abbreviation
              }
              brands {
                brandId
                label
              }
            }

            vendorproductConnection {
              edges {
                node {
                  id
                  ingredientId
                  vendorproductId
                  vpn: vendorProductNumber
                  measureId: vendorMeasureId
                  invConversion: inventoryConversion
                  isPrimary: primary
                  isSub: substitute
                  isSplit: split
                  vendorUOM
                  packSize
                  vendor: inventoryvendor {
                    inventoryvendorId
                    inventoryvendorVendorId
                    label
                  }
                }
              }
            }
            ingredientfilesConnection {
              edges {
                node {
                  id
                  ingredientfileId
                  ingredientId
                  label
                  fileUrl
                  fileType
                }
              }
            }
          }
        }
      }
      nutrientConnection(fieldGroupOnly: $fieldGroupOnly) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            permission
            nutrientId
            label
            description
            created
            updated
            measure {
              id
              label
              abbreviation
            }
          }
        }
      }
      allergenConnection(fieldGroupOnly: $fieldGroupOnly) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            label
            description
          }
        }
      }
      measureConnection(fieldGroupOnly: $fieldGroupOnly) {
        edges {
          node {
            id
            measureId
            label
            abbreviation
            measureId
          }
        }
      }
      ingredientAllergenConnection(
        filter: $ingridientAllergenFilter
        fieldGroupOnly: $fieldGroupOnly
      ) {
        edges {
          node {
            allergen {
              id
              label
            }
          }
        }
      }
      ingredientNutrientConnection(
        filter: $ingridientNutrientFilter
        fieldGroupOnly: $fieldGroupOnly
      ) {
        edges {
          node {
            nutrient {
              id
              label
            }
            quantity
          }
        }
      }
    }
  }
`;

export default INGREDIENT_BUILDER;
