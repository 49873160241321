import { gql } from '@apollo/client';

const CREATE_RECIPE = gql`
  mutation CREATE_RECIPE($input: CreateRecipeMutationInput!) {
    createRecipe(input: $input) {
      viewer {
        id
      }
      recipeEdge {
        node {
          recipeId
        }
      }
    }
  }
`;

export default CREATE_RECIPE;
