import { gql } from '@apollo/client';

export const stateFragment = {
  state: gql`
    fragment StateData on State {
      stateId
      id
      label
      abbreviation
      country {
        id
        countryId
        label
        alpha2
      }
    }
  `,
};
