import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@material-ui/core';
import MANIPULATE_USERGROUP from '../../../../../../apollo/mutations/usergroups/manipulateUsergroup';
import { USERGROUPS } from '../../../../../../apollo/queries';
import UPDATE_USERGROUP_LABEL from '../../../../../../apollo/mutations/usergroups/updateUsergroupLabel';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const CreateUsergroupForm = ({ tableState, updateData, handleClose }) => {
  const { setSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm();

  const [disableButton, setDisableButton] = useState(false);
  const [manipulateUsergroup] = useMutation(MANIPULATE_USERGROUP, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Usergroup created',
      });
      setDisableButton(false);
      handleClose();
    },
    onError: error => {
      setDisableButton(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: error?.message ? error?.message : 'Something went wrong',
      });
    },
  });
  const [updateUsergroupLabel] = useMutation(UPDATE_USERGROUP_LABEL, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Usergroup label updated!',
      });
      setDisableButton(false);
      handleClose();
    },
    onError: error => {
      setDisableButton(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: error?.message ? error?.message : 'Something went wrong',
      });
    },
  });

  const onSubmit = async ({ usergroupLabel }) => {
    setDisableButton(true);
    if (usergroupLabel.trim().length === 0) {
      setSnackbar({
        open: true,
        type: 'error',
        text: "Label is required, it can't be empty string",
      });
      setDisableButton(false);
      return;
    }
    if (updateData) {
      await updateUsergroupLabel({
        variables: {
          input: {
            newUsergroupLabel: usergroupLabel,
            usergroupId: updateData.usergroupId,
          },
        },
        refetchQueries: [{ query: USERGROUPS, variables: { ...tableState } }],
      });
      return;
    }
    await manipulateUsergroup({
      variables: {
        usergroupLabel: usergroupLabel,
        action: 'Create',
      },
      refetchQueries: [{ query: USERGROUPS, variables: { ...tableState.variables } }],
    });
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <div style={{ width: '100%' }}>
        <TextField
          name="usergroupLabel"
          label="Label"
          defaultValue={updateData ? updateData.label : ''}
          inputRef={register({ required: 'Usergroup label is required!' })}
          error={!!errors.usergroupLabel}
          helperText={errors.usergroupLabel ? errors.usergroupLabel.message : ' '}
          fullWidth
        />
      </div>
      <br />
      <div style={{ display: 'flex', gap: '1rem', marginLeft: 'auto' }}>
        <Button disabled={disableButton} type="submit">
          {updateData ? 'Update' : 'Create'}
        </Button>
        <Button disabled={disableButton} onClick={handleClose} type="button">
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default CreateUsergroupForm;
