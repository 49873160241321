import { gql } from '@apollo/client';

const CREATE_MEASURE = gql`
  mutation CREATE_MEASURE($input: CreateMeasureInput!) {
    createMeasure(input: $input) {
      viewer {
        id
      }
      measure {
        id
      }
    }
  }
`;

export default CREATE_MEASURE;
