import { gql } from '@apollo/client';

const CREATE_LOCATION = gql`
  mutation CreateLocation($input: CreateLocationMutationInput!) {
    createLocation(input: $input) {
      viewer {
        id
      }
      location {
        locationId
      }
    }
  }
`;

export default CREATE_LOCATION;
