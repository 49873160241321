import React, { useCallback, useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { TextField, FormHelperText, Typography, FormLabel } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { fileReaders } from '../../../../../../../../utils';
import CREATE_CHOICE from '../../../../../../../../apollo/mutations/menus/createChoice';
import UPDATE_CHOICE from '../../../../../../../../apollo/mutations/menus/updateChoice';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { formatData, formatNumberStringToFloat } from '../../../../../../helpers';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import RecipesTable from '../../../../RecipesTable';

const AddChoice = ({ handleClose, update, existingChoiceData, variables }) => {
  AddChoice.propTypes = {
    handleClose: PropTypes.func,
  };

  const { setSnackbar } = useSnackbar();
  const { handleSubmit, control, errors, register, unregister, setValue, getValues } = useForm();

  const [image, setImage] = useState();
  const [imageError, setImageError] = useState();

  const [addChoice] = useMutation(CREATE_CHOICE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Choice created',
        open: true,
      });

      handleClose();
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createChoice?.viewer),
        fields: {
          choiceConnection() {
            return {
              __typename: 'ChoiceEdge',
            };
          },
        },
      });
    },
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      }),
  });

  const [updateChoice] = useMutation(UPDATE_CHOICE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Choice updated',
        open: true,
      });

      handleClose();
    },
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      }),
  });

  const onSubmit = useCallback(
    async formData => {
      if (image) {
        if (image.size / 1024 > 80) {
          return setImageError('File is too big.');
        } else {
          setImageError();
        }
      }

      const inventoryitems = formatData({
        formData,
        label: 'recipe',
        mapFunction: value => ({
          inventoryitemId: value.inventoryitemId,
          itemId: value.itemId,
          quantity: formatNumberStringToFloat(value.quantity),
          measureId: value.measureId,
        }),
      });

      const imageFile = image && (await fileReaders.readFile(image));
      const data = {
        label: formData.choiceName,
        description: formData.choiceDescription,
        inventoryitems,
        choiceImage: imageFile,
      };

      if (update) {
        setSnackbar({
          open: true,
          type: 'info',
          text: 'Updating choice...',
        });

        updateChoice({
          variables: {
            input: {
              choiceId: existingChoiceData?.id,
              ...data,
            },
          },
        });
      } else {
        setSnackbar({
          open: true,
          type: 'info',
          text: 'Creating choice...',
        });

        addChoice({
          variables: {
            input: data,
          },
        });
      }
    },
    [image, updateChoice, addChoice],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={6}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              as={
                <TextField
                  label="Choose Name"
                  error={!!errors.choiceName}
                  helperText={errors.choiceName && 'Choice Name is required'}
                  placeholder={'Choice Name'}
                  variant={'outlined'}
                  fullWidth={true}
                />
              }
              name={'choiceName'}
              control={control}
              rules={{ required: true }}
              defaultValue={update ? existingChoiceData.label : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={
                <TextField
                  label="Description"
                  placeholder={'Choice Description'}
                  variant={'outlined'}
                  fullWidth={true}
                />
              }
              name={'choiceDescription'}
              control={control}
              defaultValue={update ? existingChoiceData?.description : ''}
            />
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item>
              <img
                src={
                  image
                    ? URL.createObjectURL(image)
                    : update
                    ? existingChoiceData?.imageUrl
                    : 'https://breakthrough.org/wp-content/uploads/2018/10/default-placeholder-image.png'
                }
                alt=""
                height={152}
                width={160}
              />
            </Grid>
            <Grid item style={{ maxWidth: '350px' }}>
              <Typography variant="body1">
                Required image size is 150 x 130px in JPG format. Max file size 80KB
              </Typography>

              <input
                name={'image'}
                accept="image/jpg"
                style={{ display: 'none' }}
                id="icon-button-file"
                type="file"
                onChange={event => setImage(event.target.files[0])}
              />
              {imageError ? <FormHelperText error>{imageError}</FormHelperText> : null}
              <FormLabel htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </FormLabel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RecipesTable
            setValue={setValue}
            unregister={unregister}
            control={control}
            register={register}
            getValues={getValues}
            errors={errors}
            inventoryitems={existingChoiceData?.ingredients}
          />
        </Grid>
        <Grid item xs={12} container spacing={3} alignItems="center" justify="flex-end">
          <Grid item>
            <Button variant="outlined" style={{ marginRight: '1rem' }} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit">Save</Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddChoice;
