import { gql } from '@apollo/client';

const LOCATION_INGREDIENTS = gql`
  query LocationIngredient(
    $filter: [InventoryitemFilter]
    $locationFilter: [LocationIngredientFilter]
    $locationIngredientSort: [LocationIngredientSort]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [InventoryitemSort]
  ) {
    viewer {
      id
      inventoryitemConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        filter: $filter
        sort: $sort
        fieldGroup: 19
      ) {
        permissions
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            inventoryitemId
            label
            partOf
            type
          }
        }
      }
      locationIngredientConnection(
        filter: $locationFilter
        sort: $locationIngredientSort
        fieldGroup: 19
      ) {
        permissions
        edges {
          node {
            id
            ingredientId
            theoreticalQuantity
            available
            inventoryitem {
              id
              measure {
                abbreviation
              }
              label
            }
          }
        }
      }
    }
  }
`;

export default LOCATION_INGREDIENTS;
