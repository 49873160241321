import { gql } from '@apollo/client';

const CREATE_LOCATION_EXCLUSIVE_ADDRESS = gql`
  mutation CreateLocationExclusiveDeliveryAddress(
    $input: CreateLocationExclusiveDeliveryAddressMutationInput!
  ) {
    createLocationExclusiveDeliveryAddress(input: $input) {
      viewer {
        id
      }
      locationExclusiveDeliveryAddressEdge {
        cursor
        node {
          id
          locationId
          addressId
          address {
            id
            addressId
            address
          }
        }
      }
    }
  }
`;

export default CREATE_LOCATION_EXCLUSIVE_ADDRESS;
