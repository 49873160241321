import icons from './icons';
import paths from './paths';
import menuSettings from './menuSettings';
import MUTATION_NAME from './mutationNames';

const tableSortTypes = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
};

const COLOR = {
  SUCCESS: '#37C78A',
  ERROR: '#D40E37',
  INFO: '#0096FA',
  WARNING: '#FF9900',
};

const INVOICE_ICON = {
  CREATE_SIMPLE_INVOICE: 'createSimpleInvoice',
  FULL_INVENTORY_COUNT: 'fullInventoryCount',
  SIMPLE_INVOICE: 'simpleInvoice',
  US_FOODS: 'usFoods',
  VIEW_INVOICE_JOURNAL: 'viewInvoiceJournal',
  WASTE_LOG: 'wasteLog',
  LOCATIONS: 'locations',
};

export const vendorOptions = [
  { value: -1, label: 'All' },
  { value: 0, label: 'US Foods' },
  { value: 1, label: 'Simple Invoice' },
];

const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 15;
const ROWS_PER_PAGE_OPTIONS = [10, 15, 20, 30, 50];
const VIEW_ALL_LABEL = 'View All';
const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const SNACKBAR_STATUS = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};
const PLACES_AUTOCOMPLETE_INPUT_DEBOUNCE = 600;

const NEW_CATERING_ORDER_ID = 'new-catering-order';

const SERIALIZED_COUPON_TYPE_ID = 6;

export {
  COLOR,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  INVOICE_ICON,
  MUTATION_NAME,
  NEW_CATERING_ORDER_ID,
  PLACES_AUTOCOMPLETE_INPUT_DEBOUNCE,
  ROWS_PER_PAGE_OPTIONS,
  SERIALIZED_COUPON_TYPE_ID,
  SNACKBAR_STATUS,
  VIEW_ALL_LABEL,
  WEEK_DAYS,
  icons,
  menuSettings,
  paths,
  tableSortTypes,
};
