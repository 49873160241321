import { gql } from '@apollo/client';

const EMPLOYEE_EMAILS = gql`
  query employeeEmails($filter: [LoginFilter]) {
    viewer {
      id
      loginConnection(fieldGroup: 62, filter: $filter) {
        permissions
        edges {
          node {
            id
            email
            person {
              id
              personId
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export default EMPLOYEE_EMAILS;
