import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { TOGGLE_ARCHIVE_COUPON } from "../../../../../../../apollo/mutations/coupons";
import { Elements } from "../../../../../../blocks";
import { useSnackbar } from "../../../../../../../hooks/useSnackbar";

function ArchiveSwitchButton({
  data,
  couponId
}) {
  const { setSnackbar } = useSnackbar();

  const [tempData, setTempData] = useState(data);
  const [toggleCoupon] = useMutation(TOGGLE_ARCHIVE_COUPON, {
    onCompleted: () => {
      setTempData(!tempData);
      setSnackbar({
        open: true,
        type: "success",
        text: tempData ? "Discount unarchived!" : "Discount archived!",
      });
    },
    onError: (err) => {
      setSnackbar({
        open: true,
        type: "error",
        text: "Oops, something went wrong...",
      });
    },
  });

  const onArchiveChange = () => {
    toggleCoupon({
      variables: {
        input: {
          couponId,
        },
      },
    });
  };
  return (
    <Elements.Switch handleSwitchChange={onArchiveChange} checked={tempData} />
  );
}

export default ArchiveSwitchButton;
