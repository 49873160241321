import React, { useState } from "react";
import GenerateCode from "../GenerateCode";

function ManualView({ coupon, control,codeGenerationType }) {
  const [eventType, setEventType] = useState(1); // 1 is the first maual event saved in DB
  const renderManualTriggers = () => {
    switch (eventType) {
      case 1:
        return <GenerateCode coupon={coupon} control={control} codeGenerationType={codeGenerationType} />;
      default:
        return "";
    }
  };

  return <div>{renderManualTriggers()}</div>;
}

export default ManualView;
