import React, { useState } from 'react';
import 'date-fns';
import { FormControlLabel, Grid } from '@material-ui/core';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Elements } from '../../../../../../blocks';
import { Controller } from 'react-hook-form';

function StartEndDatePicker({ control, coupon }) {
  const [displayEndDate, setDisplayEndDate] = useState(!!coupon.finished);

  return (
    <div style={{ marginBottom: '25px' }}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={4} md={3}>
                <Controller
                  control={control}
                  name="time.start.date"
                  render={({ onChange, value }) => (
                    <DatePicker
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      format="LL/dd/yyyy"
                      // disablePast
                      minDate={value}
                      // style={{ minWidth: '230px' }}
                      margin="normal"
                      label="Start Date"
                      autoOk={true}
                      onChange={date => {
                        onChange(date.toJSDate());
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Elements.TimePicker
                  control={control}
                  hoursName="time.start.time.hours"
                  minutesName="time.start.time.minutes"
                  amPmName="time.start.time.type"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Elements.Switch
                  checked={displayEndDate}
                  handleSwitchChange={isChecked => {
                    setDisplayEndDate(isChecked);
                  }}
                />
              }
              label="Enable end date"
            />
          </Grid>
          <Grid item xs={12}>
            {displayEndDate && (
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={4} md={3}>
                  <Controller
                    control={control}
                    name="time.end.date"
                    render={({ onChange, value }) => (
                      <DatePicker
                        variant="inline"
                        inputVariant="outlined"
                        format="LL/dd/yyyy"
                        margin="normal"
                        style={{ width: '230px' }}
                        label="End Date"
                        autoOk={true}
                        onChange={date => {
                          if (date) {
                            onChange(date.toJSDate());
                            return;
                          }
                          onChange(null);
                        }}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Elements.TimePicker
                    control={control}
                    hoursName="time.end.time.hours"
                    minutesName="time.end.time.minutes"
                    amPmName="time.end.time.type"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default StartEndDatePicker;
