import { FormLabel } from '@material-ui/core';
import { Grid, Typography, Button, Divider } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import DragAndDropItem from './DragAndDropItem';

const DragAndDropInput = ({
  title,
  addItemButtonText = 'Add Item',
  addItemComponent: AddItemComponent = null,
  addItemComponentProps = {},
  name,
  initialItem,
  onSelect,
  control,
  itemComponentProps,
  itemComponent,
  enableEmpty = true,
  emptyErrorText = 'At least one item is required',
}) => {
  const { fields, move, remove, append } = useFieldArray({ control, name });

  const onAddItemClick = useCallback(
    (itemToAdd = initialItem) => {
      if (onSelect) {
        onSelect(itemToAdd);
      }

      append(itemToAdd);
    },
    [append, initialItem, onSelect],
  );

  const handleDrop = useCallback(
    (removeItemId, newItemId) => {
      move(
        fields.findIndex(item => item.id === removeItemId),
        fields.findIndex(item => item.id === newItemId),
      );
    },
    [move, fields],
  );

  const onRemoveItemClick = useCallback(
    index => {
      remove(index);
    },
    [remove],
  );

  return (
    <Grid container spacing={3}>
      <Grid container xs={12} item spacing={3} alignItems="center" justify="space-between">
        {title ? (
          <Grid item xs={12} sm={6} direction="column" spacing={3} container>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ fontSize: '1.25rem' }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        {AddItemComponent ? null : (
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => onAddItemClick({})}
              style={{
                marginBottom: '12px',
              }}
            >
              {addItemButtonText}
            </Button>
          </Grid>
        )}
      </Grid>

      {fields?.length
        ? fields.map((item, index) => (
            <Grid item xs={12} key={item.id}>
              {index !== 0 ? <Divider /> : null}
              <DragAndDropItem
                index={index}
                handleDrop={handleDrop}
                item={item}
                id={item.id}
                onRemoveItem={onRemoveItemClick}
                type={name}
                itemComponent={itemComponent}
                itemComponentProps={itemComponentProps}
              />
            </Grid>
          ))
        : null}

      {AddItemComponent ? (
        <Grid item xs={12}>
          <AddItemComponent
            {...addItemComponentProps}
            onClick={onAddItemClick}
            hasItems={!!fields?.length}
          />
        </Grid>
      ) : null}

      {!enableEmpty && isEmpty(fields) ? (
        <Grid item xs={12}>
          <FormLabel error>{emptyErrorText}</FormLabel>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DragAndDropInput;
