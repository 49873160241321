import { gql } from '@apollo/client';

const REMOVE_JOB_BREAK_RULE = gql`
  mutation removeJobBreakRule($input: RemoveJobBreakRuleMutationInput!) {
    removeJobBreakRule(input: $input) {
      clientMutationId
    }
  }
`;

export default REMOVE_JOB_BREAK_RULE;
