import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, List, ListItem, ListItemText, IconButton } from "@material-ui/core";

import ConfirmationModal from '../ConfirmationModal';
import SideBarSectionsLayoutStyles from '../SideBarSectionsLayout/styles';
import { DeleteOutline } from "@material-ui/icons";
import { Permission, PermissionField} from "../index";

const SideBarSectionsLayoutButtonVariant = ({ children, sectionList, deletePermission, initialSelectedSection, onClick = null, onDelete, isDeleting, deleteText }) => {
  const [selectedSection, setSelectedSection] = useState(
    initialSelectedSection || sectionList[0]?.id || ""
  );
  const [sectionToDelete, setSectionToDelete] = useState();

  const classes = SideBarSectionsLayoutStyles();

  const handleOnDelete = useCallback((event, section) => {
    event.stopPropagation();

    setSectionToDelete(section);
  }, []);

  const handleSectionClick = (event, section) => {
    event.preventDefault();
    setSelectedSection(section.id);
    onClick && onClick(section);
  };

  return (
    <Grid container fullWidth>
      <Grid item xs={2} className={classes.sectionListContainer}>
        {sectionList.length > 0 && (
          <List
            aria-label="sub module navigation"
            className={classes.sectionList}
          >
            {sectionList.map((section) => (
              <ListItem
                key={section.id}
                button
                selected={section.id === selectedSection}
                onClick={(event) => handleSectionClick(event, section)}
                className={classes.sectionListItem}
                classes={{ selected: classes.selectedSectionListItem }}
              >
                {section.isNew ? (
                  <ListItemText secondary={section.name} />
                ):(
                  <Grid container justify="space-between" alignItems="center">
                    <Grid>
                      <ListItemText primary={section.name} />
                    </Grid>
                    {onDelete && section.id !== "newLocation" ? (
                      <Grid style={{ height: 'min-content' }}>
                        <Permission access={deletePermission?.access}>
                          <div>
                            <PermissionField createHelperText>
                              <IconButton size="small" onClick={(e) => handleOnDelete(e, section)}>
                                <DeleteOutline fontSize="small"/>
                              </IconButton>
                            </PermissionField>
                          </div>
                        </Permission>
                      </Grid>
                    ) : null}
                  </Grid>
                )}  
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
      <Grid
        item
        xs={10}
        className={classes.contentContainer}
        id="scroll-container"
      >
        {children}
      </Grid>
      <ConfirmationModal
        open={!!sectionToDelete} 
        confirmAction={() => onDelete(sectionToDelete)} 
        handleClose={() => setSectionToDelete()} 
        text={deleteText} 
        disabled={isDeleting}
      ></ConfirmationModal>
    </Grid>
  );
};

SideBarSectionsLayoutButtonVariant.propTypes = {
  children: PropTypes.node.isRequired,
  sectionList: PropTypes.array.isRequired,
};

export default SideBarSectionsLayoutButtonVariant;
