import { makeStyles } from '@material-ui/core';
import { COLOR } from '../../../../../constants';
const Styles = () => ({
  underline: {
    borderBottom: '0px solid red !important',
    '&:hover': {
      borderBottom: '0px solid rgba(0,0,0,0)',
    },
  },
  select: {
    color: 'red',
  },
});

const SelectStyles = () => ({
  selectRed: {
    color: COLOR.ERROR,
  },
  selectGreen: {
    color: COLOR.SUCCESS,
  },
  selectYellow: {
    color: COLOR.WARNING,
  },
});

const DateFilterStyles = theme => ({
  selectDateRange: {
    flexDirection: 'row',
    '& button': {
      padding: 0,
      '& span > div': {
        flex: 1,
      },
    },

    '&:hover $dateRangeCloseButton': {
      visibility: 'visible',
    },
  },
  dateRangeTextButton: {
    flex: 1,
    padding: 0,

    '&:hover': {
      background: 'none',
    },
  },
  dateRangeCloseButton: {
    position: 'absolute',
    right: 20,
    top: '50%',
    transform: 'translateY(-50%)',
    visibility: 'hidden',
    backgroundColor: theme.customToggleButton.backgroundColor,
    color: theme.customToggleButton.labelColor,

    '&:hover': {
      backgroundColor: theme.customToggleButton.hoverBackgroundColor,
    },

    '& svg': {
      transform: 'scale(.75)',
    },
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
});

export const LoyaltySubmissionStyle = makeStyles(Styles);
export const SelectStyle = makeStyles(SelectStyles);
export const DateFilterStyle = makeStyles(DateFilterStyles);
