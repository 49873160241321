import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  formGroupRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },

  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > h6': {
      marginRight: theme.spacing(5),
    },
  },

  editTextField: {
    marginRight: '15px',
  },

  imageRootContainer: {
    display: 'flex',
    width: '100%',
    height: '160px',
    border: '1px solid rgb(203, 203, 203)',
    borderRadius: '4px',
    padding: '4px',
  },

  imageContainer: {
    position: 'relative',
    display: 'flex',
    width: '66%',
    alignItems: 'center',

    '& > img': {
      height: 'auto',
      width: '100%',
    },
  },

  imageEditButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#fff',
    borderRadius: 0,
    top: '10px',
    padding: '6px',

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  imageTipContainer: {
    width: '65%',
    marginLeft: `${theme.spacing(4)}px`,

    '& > p:not(:last-child)': {
      marginBottom: `${theme.spacing(1)}px`,
    },
  },
});

const EditLocationFormViewStyles = makeStyles(Styles);

export default EditLocationFormViewStyles;
