import { ConnectionTable, Modal, Permission } from '../../../../../../../blocks';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../../../../constants';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import LOYALTY_HOUSE_ACCOUNTS from '../../../../../../../../apollo/queries/loyalty/loyaltyHouseAccounts';
import React, { useCallback, useMemo, useState } from 'react';
import AddHouseAccountModal from '../HouseAccountModals/AddHouseAccountModal';
import EditHouseAccountModal from '../HouseAccountModals/EditHouseAccountModal';
import PayHouseAccountModal from '../HouseAccountModals/PayHouseAccountModal';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffOutlined from '@material-ui/icons/HighlightOffOutlined';
import HouseAccountStyles from './styles';
import {
  convertHouseAccountValueForFrontend,
  getAvailableCredit,
} from '../../../../../../../../utils/houseAccountUtils';
import { noLimitLabel } from '../../../../../../../../constants/houseAccounts';

const HouseAccountModalType = {
  AddHouseAccount: {
    id: 'add-house_account',
    label: 'Add House Account',
  },
  EditHouseAccount: {
    id: 'edit-house_account',
    label: 'Edit House Account',
  },
  PayHouseAccount: {
    id: 'pay-house_account',
    label: 'Pay House Account',
  },
};

const HouseAccountsView = ({ customerData, mutationPermissions }) => {
  const [showAddHouseAccountModal, setShowAddHouseAccountModal] = useState({
    open: false,
    title: null,
  });

  const [showEditHouseAccountModal, setShowEditHouseAccountModal] = useState({
    open: false,
    title: null,
    houseAccountData: null,
  });

  const [showPayHouseAccountModal, setShowPayHouseAccountModal] = useState({
    open: false,
    title: null,
    houseAccountData: null,
  });

  const createHouseAccountPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'createHouseaccount',
      ),
    [mutationPermissions],
  );
  const createHouseAccountDepositPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'createHouseAccountDeposit',
      ),
    [mutationPermissions],
  );
  const updateHouseAccountPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'updateHouseaccount',
      ),
    [mutationPermissions],
  );

  const handleOpenModal = useCallback((houseAccountModalTypeId, data) => {
    if (houseAccountModalTypeId === HouseAccountModalType.AddHouseAccount.id) {
      setShowAddHouseAccountModal(() => ({
        title: HouseAccountModalType.AddHouseAccount.label,
        open: true,
      }));
    } else if (houseAccountModalTypeId === HouseAccountModalType.EditHouseAccount.id) {
      setShowEditHouseAccountModal(() => ({
        title: HouseAccountModalType.EditHouseAccount.label,
        open: true,
        houseAccountData: data,
      }));
    } else if (houseAccountModalTypeId === HouseAccountModalType.PayHouseAccount.id) {
      setShowPayHouseAccountModal(() => ({
        title: HouseAccountModalType.PayHouseAccount.label,
        open: true,
        houseAccountData: data,
      }));
    }
  }, [customerData, mutationPermissions]);

  const handleCloseModal = () => {
    setShowAddHouseAccountModal(prevState => ({ ...prevState, open: false }));
    setShowEditHouseAccountModal(prevState => ({ ...prevState, open: false }));
    setShowPayHouseAccountModal(prevState => ({ ...prevState, open: false }));
  };
  const classes = HouseAccountStyles();
  return (
    <div id={'houseAccounts'}>
      <Grid container item xs={12} justify="space-between">
        <Typography variant="h4" component="h2">
          House Accounts
        </Typography>
        <Grid item>
          <Permission access={createHouseAccountPermission.access}>
            <Button
              className={classes.marginLeft}
              variant="outlined"
              onClick={() => handleOpenModal(HouseAccountModalType.AddHouseAccount.id)}
            >
              Add House Account
            </Button>
          </Permission>
        </Grid>
      </Grid>

      <ConnectionTable
        query={LOYALTY_HOUSE_ACCOUNTS}
        minSearchValueLength={3}
        initialQueryVariables={{
          first: ROWS_PER_PAGE_OPTIONS[0],
          sort: [
            {
              id: 'DESC',
            },
          ],
          filter: {
            loginId: {
              eq: parseInt(customerData.node.login.loginId),
            },
          },
          fieldGroupOnly: 75,
        }}
        structureTable={data =>
          data?.viewer?.houseaccountConnection?.edges.map(quote => {
            return {
              id: quote.node.houseaccountId,
              balance: quote.node.balance,
              limit: quote.node.limit,
              'location.label': quote.node.location.label,
              'location.opened': quote.node.location.isOpened,
              data: quote.node,
            };
          })
        }
        columns={[
          { title: 'ID', field: 'id' },
          { title: 'Location', field: 'location.label' },
          {
            title: 'Location Open',
            field: 'location.opened',
            render: isOpened => {
              return isOpened ? (
                <CheckCircleOutlineIcon style={{ color: '#37C78A' }} />
              ) : (
                <HighlightOffOutlined color="error" />
              );
            },
          },
          {
            title: 'Credit Limit',
            field: 'limit',
            render: limit => {
              const formattedLimit = convertHouseAccountValueForFrontend(limit);
              return formattedLimit === null ? noLimitLabel : `$${formattedLimit}`;
            },
          },
          {
            title: 'Account Balance',
            field: 'balance',
            render: balance => {
              return `$${convertHouseAccountValueForFrontend(balance)}`;
            },
          },
          {
            title: 'Available Credit',
            field: 'data',
            disableSort: true,
            render: data => {
              const formattedAvailableCredit = getAvailableCredit(data.limit, data.balance);
              return formattedAvailableCredit === null
                ? noLimitLabel
                : `$${getAvailableCredit(data.limit, data.balance)}`;
            },
          },
          {
            title: 'Pay',
            field: 'data',
            disableSort: true,
            render: data => {
              return (
                <span style={{ cursor: 'pointer' }}>
                  <IconButton disabled={!data.location.isOpened}>
                    <Permission access={createHouseAccountDepositPermission.access}>
                      <CreditCardIcon
                        onClick={() => {
                          handleOpenModal(HouseAccountModalType.PayHouseAccount.id, data);
                        }}
                      />
                    </Permission>
                  </IconButton>
                </span>
              );
            },
          },
          {
            title: 'Edit',
            field: 'data',
            disableSort: true,
            render: data => {
              return (
                <span style={{ cursor: 'pointer' }}>
                  <IconButton disabled={!data.location.isOpened}>
                    <Permission access={updateHouseAccountPermission.access}>
                      <CreateIcon
                        onClick={() => {
                          handleOpenModal(HouseAccountModalType.EditHouseAccount.id, data);
                        }}
                      />
                    </Permission>
                  </IconButton>
                </span>
              );
            },
          },
        ]}
      />
      <Modal
        open={showAddHouseAccountModal.open}
        maxWidth={'sm'}
        handleClose={handleCloseModal}
        title={showAddHouseAccountModal.title}
      >
        <AddHouseAccountModal
          customerData={customerData}
          createHouseAccountPermission={createHouseAccountPermission}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      <Modal
        open={showEditHouseAccountModal.open}
        maxWidth={'sm'}
        handleClose={handleCloseModal}
        title={showEditHouseAccountModal.title}
      >
        <EditHouseAccountModal
          houseAccountData={showEditHouseAccountModal.houseAccountData}
          customerData={customerData}
          updateHouseAccountPermission={updateHouseAccountPermission}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      <Modal
        open={showPayHouseAccountModal.open}
        maxWidth={'lg'}
        handleClose={handleCloseModal}
        title={showPayHouseAccountModal.title}
      >
        <PayHouseAccountModal
          houseAccountData={showPayHouseAccountModal.houseAccountData}
          customerData={customerData}
          createHouseAccountDepositPermission={createHouseAccountDepositPermission}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default HouseAccountsView;
