import { gql } from "@apollo/client";

const REMOVE_CHOICE = gql`
  mutation removeChoice($input: RemoveChoiceMutationInput!) {
    removeChoice(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default REMOVE_CHOICE;
