import { Paper, makeStyles } from '@material-ui/core';

const Styles = theme => ({
  paper: {
    '&.MuiPaper-root': {
      backgroundColor: theme.colors.boxBackground,
      boxShadow: theme.boxShadowBase,
      borderRadius: 0,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      width: '100%',
      zIndex: 1,

      [theme.breakpoints.down('xs')]: {
        margin: '-16px -16px 16px -16px',
        width: 'calc(100% + 32px)',
      },
    },
  },
  nested: {
    '&.MuiPaper-root': {
      backgroundColor: theme.colors.boxBackground,
      borderRadius: 0,
      marginBottom: theme.spacing(3),
      width: '100%',
      zIndex: 1,
      padding: 0,
      boxShadow: 'none',
    },
  },
});

const PaperMainStyles = makeStyles(Styles);

const PaperMain = ({ nested, children, className }) => {
  const classes = PaperMainStyles();

  return (
    <Paper className={className ? className : nested ? classes.nested : classes.paper}>
      {children}
    </Paper>
  );
};

export default PaperMain;
