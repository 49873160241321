import React, { useState } from 'react';
import LOYALTY_CLAIMED_RECEIPT from '../../../../../apollo/queries/loyalty/loyaltyClaimedReceipt';
import { ConnectionTable } from '../../../../blocks';
import moment from 'moment';
import { COLOR } from '../../../../../constants';
import { useMutation } from '@apollo/client';
import { convert } from '../../../../../utils';
import {
  FormControl,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  IconButton,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import MomentUtils from '@date-io/moment';
import { generateStoreAbbreviation } from '../../../../../utils';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../constants';
import LOYALTY_CLAIMED_RECEIPT_STATUS from '../../../../../apollo/mutations/loyalty/loyaltyClaimedReceiptStatus';
import { SelectStyle, DateFilterStyle } from './style';

function LoyaltySubmissionsView({
  getSelectedDateRangeValue,
  handleDateRangeStartChange,
  handleDateRangeEndChange,
  handleOpenDateRangeSelectionModal,
  handleCloseDateRangeSelectionModal,
  dateRangeStart,
  dateRangeEnd,
  openDateRangeSelectionModal,
  handleDateRangeSelection,
}) {
  const selectClasses = SelectStyle();
  const classes = DateFilterStyle();
  const { setSnackbar } = useSnackbar();

  const [
    updateLoyaltyClaimedReceipt,
    { loading: updateLoyaltyClaimedReceiptLoading },
  ] = useMutation(LOYALTY_CLAIMED_RECEIPT_STATUS);

  const updateLoyaltyClaimedReceiptStatus = (id, status) => {
    updateLoyaltyClaimedReceipt({
      variables: {
        input: {
          id,
          status,
        },
      },
    })
      .then(() => {
        setSnackbar({
          text: 'Loyalty receipt status updated!.',
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
        });
      })
      .catch(e =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        }),
      );
  };
  return (
    <ConnectionTable
      query={LOYALTY_CLAIMED_RECEIPT}
      title={'Loyalty Submissions'}
      initialQueryVariables={{
        first: 10,
        sort: [
          {
            created: 'DESC',
          },
        ],
        specificFields: ['receiptNumber', 'email'],
      }}
      structureTable={data =>
        data?.viewer?.loyaltyclaimedreceiptConnection?.edges.map(loylatyClaimedReceipt => {
          return {
            personId: loylatyClaimedReceipt.node.person.personId,
            email: loylatyClaimedReceipt?.node?.person?.login?.email,
            id: loylatyClaimedReceipt.node.id,
            loyaltyclaimedreceiptId: loylatyClaimedReceipt.node?.loyaltyclaimedreceiptId,
            submissionDate: moment(loylatyClaimedReceipt.node.created).format('MM/DD/YYYY'),
            dateOfVisit: moment(loylatyClaimedReceipt.node.dateOfVisit).format('MM/DD/YYYY'),
            location: generateStoreAbbreviation(loylatyClaimedReceipt.node.location).abbreviation,
            totalPrice: `$ ${convert.centsToDollars(loylatyClaimedReceipt.node.totalAmount)}`,
            receiptNumber: loylatyClaimedReceipt.node.receiptNumber,
            requestStatus: `${loylatyClaimedReceipt.node.loyaltyclaimedreceiptId}:${loylatyClaimedReceipt?.node?.approved}`,
            brandId: loylatyClaimedReceipt.node?.brandId,
            brandLabel: data?.viewer?.brandConnection?.edges?.filter(
              brand => brand?.node?.brandId === loylatyClaimedReceipt.node?.brandId,
            )[0]?.node?.label,
          };
        })
      }
      columns={[
        { title: 'Submission ID', field: 'loyaltyclaimedreceiptId' },
        { title: 'Brand', field: 'brandLabel' },
        {
          title: 'Location Id',
          field: 'location',
          disableSort: true,
        },
        { title: 'Person Id', field: 'personId', disableSort: true },
        { title: 'Email', field: 'email', disableSort: true },
        { title: 'Submission Date', field: 'submissionDate' },
        {
          title: 'Date of Visit',
          field: 'dateOfVisit',
          disableSort: true,
        },
        {
          title: 'Receipt Number',
          field: 'receiptNumber',
          disableSort: true,
          cellStyle: {
            color: COLOR.INFO,
          },
        },
        { title: 'Total Amount', field: 'totalPrice', disableSort: true },
        {
          title: 'Request Status',
          field: 'requestStatus',
          cellStyle: value => ({
            color: value ? COLOR.SUCCESS : COLOR.ERROR,
            fontWeight: 'bold',
          }),
          render: value => {
            let status = value.split(':')[1];
            if (status === 'null') {
              status = 'PENDING';
            } else if (status === 'true') {
              status = 'APPROVED';
            } else {
              status = 'DECLINED';
            }
            const loyaltyClaimedReceiptId = value.split(':')[0];
            return (
              <FormControl
                variant="outlined"
                fullWidth
                style={{
                  boxShadow: 'none',
                  color: 'red',
                }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  defaultValue={status}
                  disableUnderline
                  classes={{
                    select:
                      status === 'PENDING'
                        ? selectClasses.selectYellow
                        : status === 'APPROVED'
                        ? selectClasses.selectGreen
                        : selectClasses.selectRed,
                  }}
                  onChange={event => {
                    const value = event.target.value;
                    updateLoyaltyClaimedReceiptStatus(loyaltyClaimedReceiptId, value);
                  }}
                  label="Status"
                >
                  <MenuItem value="PENDING" style={{ color: COLOR.WARNING }} disabled>
                    <em>PENDING</em>
                  </MenuItem>
                  <MenuItem key={'inactive'} value={'APPROVED'} style={{ color: COLOR.SUCCESS }}>
                    APPROVED
                  </MenuItem>

                  <MenuItem key={'active'} value={'DECLINED'} style={{ color: COLOR.ERROR }}>
                    DECLINED
                  </MenuItem>
                </Select>
              </FormControl>
            );
          },
        },
      ]}
      customActionsCenter={(data, { refetch, variables }, selected) => {
        const dateRangeValue = getSelectedDateRangeValue(variables);

        return (
          <Grid container justify="flex-end">
            <Grid container item xs={9} spacing={3}>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Brand</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.brand;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            brand: {
                              brandId: {
                                eq: value,
                              },
                            },
                          },
                        });
                      }
                    }}
                    label="All brands"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {data?.viewer?.brandConnection?.edges?.map(brand => (
                      <MenuItem key={brand.node.brandId} value={brand.node.brandId}>
                        {brand.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={5}>
                <FormControl variant="outlined" className={classes.selectDateRange} fullWidth>
                  <IconButton
                    disableRipple
                    onClick={handleOpenDateRangeSelectionModal}
                    className={classes.dateRangeTextButton}
                  >
                    <TextField
                      label="Date of Visit"
                      value={dateRangeValue || 'MM/DD/YYYY to MM/DD/YYYY'}
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                      variant="outlined"
                    />
                  </IconButton>
                  {dateRangeValue !== 'MM/DD/YYYY to MM/DD/YYYY' && (
                    <IconButton
                      disableRipple
                      className={classes.dateRangeCloseButton}
                      onClick={() => handleDateRangeSelection({ refetch, variables }, true)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Dialog
                    open={openDateRangeSelectionModal}
                    onClose={handleCloseDateRangeSelectionModal}
                    disableEnforceFocus
                  >
                    <DialogTitle disableTypography>
                      <Typography variant="h6">Select Date Range</Typography>
                      <IconButton
                        aria-label="close"
                        className={classes.dialogCloseButton}
                        onClick={handleCloseDateRangeSelectionModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <DialogContent dividers>
                        <Grid container justify="space-between" spacing={2}>
                          <Grid item>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/DD/YYYY"
                              margin="normal"
                              label="Date range start"
                              value={dateRangeStart}
                              onChange={handleDateRangeStartChange}
                            />
                          </Grid>
                          <Grid item>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/DD/YYYY"
                              margin="normal"
                              label="Date range end"
                              value={dateRangeEnd}
                              onChange={handleDateRangeEndChange}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDateRangeSelection({ refetch, variables })}>
                          Apply
                        </Button>
                      </DialogActions>
                    </MuiPickersUtilsProvider>
                  </Dialog>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Request Status</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.approved;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            approved: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="All request status"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem key={1} value={null}>
                      PENDING
                    </MenuItem>
                    <MenuItem key={2} value={1}>
                      APPROVED
                    </MenuItem>
                    <MenuItem key={3} value={0}>
                      DECLINED
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default LoyaltySubmissionsView;
