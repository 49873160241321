import { gql } from '@apollo/client';

const FETCH_STORAGE_INVENTORY_ITEMS = gql`
  query FETCH_STORAGE_INVENTORY_ITEMS2(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: [InventoryitemFilter]
    $sort: [InventoryitemSort]
    $search: String
  ) {
    viewer {
      id
      inventoryitemConnection(
        first: $first
        last: $last
        sort: $sort
        after: $after
        before: $before
        filter: $filter
        search: $search
        fieldGroup: 102
      ) {
        totalCount
        permissions
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            inventoryitemId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_STORAGE_INVENTORY_ITEMS;
