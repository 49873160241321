import { gql } from "@apollo/client";
import { viewerFragment } from "../../fragments";

const CREATE_QUOTE_CATERING = gql`
  mutation CreateQuotecatering($input: CreateQuotecateringNDMutationInput!) {
    createQuotecateringND(input: $input) {
      viewer {
        ...ViewerData
      }
      quote {
        id
      }
    }
  }
  ${viewerFragment.viewer}
`;

export default CREATE_QUOTE_CATERING;
