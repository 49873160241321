import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    colorAdd: {
        color: "#82DCB6",
        padding: 0
    },
    colorSubtrack: {
        color: "#E67189",
        padding: 0
    },
    helperText: {
        textAlign: "center"
    }
});

export const ChoiceSelectedListStyles = makeStyles(Styles);
