import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import DELETE_EQUIPMENT from '../../../../../apollo/mutations/equipment/deleteEquipment';
import { EQUIPMENTS } from '../../../../../apollo/queries';
import { SNACKBAR_STATUS } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { Modal } from '../../../../blocks';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import EquipmentForm from './EquipmentForm';

const Equipment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [equipment, setEquipment] = useState(0);

  const { setSnackbar } = useSnackbar();

  const [deleteEquipment, { loading: deleting }] = useMutation(DELETE_EQUIPMENT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Equipment deleted',
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.deleteEquipment?.viewer),
        fields: {
          equipmentConnection() {
            return {
              __typename: 'EquipmentEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setEquipment();
  }, []);

  const onDelete = useCallback(
    ({ equipmentId }) => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Deleting Equipment...',
        open: true,
      });

      deleteEquipment({ variables: { input: { equipmentId } } });
    },
    [deleteEquipment],
  );

  return (
    <>
      <ConnectionTable
        query={EQUIPMENTS}
        title="Equipment"
        customActionsTop={() => <Button onClick={() => setIsModalOpen(true)}>New Equipment</Button>}
        onTableRowClick={row => {
          setEquipment(row);
          setIsModalOpen(true);
        }}
        structureTable={data =>
          data?.viewer?.equipmentConnection?.edges?.map(edge => ({
            equipmentId: edge?.node?.equipmentId,
            label: edge?.node?.label,
          }))
        }
        columns={[
          {
            enablePropagation: true,
            title: 'ID',
            field: 'equipmentId',
          },
          {
            enablePropagation: true,
            title: 'Name',
            field: 'label',
          },
        ]}
        handleDeleteRow={onDelete}
        useConfirmationModalForDelete
        isDeleting={deleting}
        deleteConfirmationText="Are you sure you want to delete this equipment?"
      />

      <Modal
        handleClose={handleCloseModal}
        open={isModalOpen}
        title={equipment?.equipmentId ? 'Update Equipment' : 'Add Equipment'}
      >
        <EquipmentForm handleCancel={handleCloseModal} equipment={equipment} />
      </Modal>
    </>
  );
};

export default Equipment;
