import { gql } from '@apollo/client';
import { paymentFragment } from '../../fragments/index';

const CREATE_REFUND = gql`
  mutation createRefund(
    $input: CreateRefundMutationInput!
  ) {
    createRefund(
      input: $input
    ) {
      payment {
          ...PaymentData
      }
    }
  }
  ${paymentFragment.payment}
`;

export default CREATE_REFUND;
