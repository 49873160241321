import DatePicker from "./DatePicker";
import PasswordInput from "./PasswordInput";
import Select from "./Select";
import Switch from "./Switch";
import TextFieldWithButton from "./TextFieldWithButton";
import TimePicker from "./Timepicker";
import ToggleButton from "./ToggleButton";

const Elements = {
  DatePicker,
  PasswordInput,
  Select,
  Switch,
  TextFieldWithButton,
  TimePicker,
  ToggleButton,
};

export default Elements;
