import { gql } from '@apollo/client';
import locationStorageFragment from '../../fragments/locationStorageFragment';

const FETCH_LOCATION_STORAGES = gql`
  query FETCH_STORAGE_AREAS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationStorageSort]
    $filter: [LocationStorageFilter]
  ) {
    viewer {
      id
      locationStorageConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 88
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...LocationStorageData
          }
        }
      }
    }
  }

  ${locationStorageFragment.locationStorage}
`;

export default FETCH_LOCATION_STORAGES;
