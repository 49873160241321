import { INVOICE_ICON } from '../../../../../constants';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import printCountSheet from '../../../../../utils/pdfUtils/printCountSummary';
import { generateStoreAbbreviation } from '../../../../../utils';

export function getName(row) {
  if (row.type === INVOICE_ICON.WASTE_LOG) {
    return 'Waste Log';
  } else if (row.type === INVOICE_ICON.FULL_INVENTORY_COUNT) {
    return 'Full Inventory Count';
  } else if (row.isTransfer) {
    if (row.transferType === 'Outgoing') {
      return `Outgoing Transfer To: ${row.targetLocation.label}  (${
        generateStoreAbbreviation(row.targetLocation).abbreviation
      })`;
    }
    return `Incoming Transfer From: ${row.sourceLocation.label}  (${
      generateStoreAbbreviation(row.sourceLocation).abbreviation
    })`;
  } else {
    return row.subtotal > 0
      ? `Invoice# ${row.invoiceNumber}`
      : `Invoice# ${row.invoiceNumber} - RETURN`;
  }
}

export function getDescription(row) {
  if (row.type === INVOICE_ICON.WASTE_LOG) {
    return 'Account for product waste';
  } else if (row.type === INVOICE_ICON.FULL_INVENTORY_COUNT) {
    return 'Inventory Audit';
  } else {
    return `Recieved ${moment(row.invoiceDate).format('MM/DD/YYYY z')}`;
  }
}

export function structureTable(data) {
  return [
    {
      type: INVOICE_ICON.FULL_INVENTORY_COUNT,
      ...((data?.viewer?.locationConnection?.edges &&
        data?.viewer?.locationConnection?.edges[0]?.node) ||
        {}),
      dueDate:
        data?.viewer?.locationConnection?.edges[0]?.node?.inventoryCountStatus === 'DONE' ? (
          <Typography style={{ fontWeight: 'bold', color: '#009A5C' }}>DONE</Typography>
        ) : (
          data?.viewer?.locationConnection?.edges[0]?.node?.inventoryCountDueDate
        ),
      invoiceDate: data?.viewer?.locationConnection?.edges[0]?.node?.inventorycount?.dateCounted,
    },
    {
      type: INVOICE_ICON.WASTE_LOG,
    },
  ]
    .concat(
      ...(data?.viewer?.inventorytransferConnection?.edges?.map(edge => ({
        ...edge.node,
        currentLocation: data?.viewer?.locationConnection?.edges[0]?.node,
        type: INVOICE_ICON.SIMPLE_INVOICE,
        transferType:
          edge.node.sourceLocation.storeNumber ===
          data?.viewer?.locationConnection?.edges[0]?.node.storeNumber
            ? 'Outgoing'
            : 'Incoming',
        isTransfer: true,
      })) || []),
    )
    .concat(
      ...(data?.viewer?.invoiceConnection?.edges?.map(edge => ({
        ...edge.node,
        type: edge.node.isSimple ? INVOICE_ICON.SIMPLE_INVOICE : INVOICE_ICON.US_FOODS,
      })) || []),
    );
}

export function printCountSheetResults(data) {
  const countInfo = {
    dateCounted: data.viewer.inventorycountConnection.edges[0].node.dateCounted,
    storages: [],
  };
  const storagesTemp = {};

  data.viewer.inventorycountConnection.edges[0].node.inventorycountLocationStorageInventoryitemConnection.edges.forEach(
    edge => {
      storagesTemp[edge.node.locationStorageInventoryitem.locationStorage.locationStorageId] = {
        label: edge.node.locationStorageInventoryitem.locationStorage.customLabel,
        storageId: edge.node.locationStorageInventoryitem.locationStorage.locationStorageId,
        dateCounted: countInfo.dateCounted,
        items: [],
      };
    },
  );
  data.viewer.inventorycountConnection.edges[0].node.inventorycountLocationStorageInventoryitemConnection.edges.forEach(
    edge => {
      if (
        storagesTemp[edge.node.locationStorageInventoryitem.locationStorage.locationStorageId]
          .storageId === edge.node.locationStorageInventoryitem.locationStorage.locationStorageId
      ) {
        storagesTemp[
          edge.node.locationStorageInventoryitem.locationStorage.locationStorageId
        ].items = [
          ...storagesTemp[edge.node.locationStorageInventoryitem.locationStorage.locationStorageId]
            .items,
          edge.node,
        ];
      }
    },
  );
  for (const key in storagesTemp) {
    countInfo.storages.push(storagesTemp[key]);
  }
  printCountSheet(countInfo.storages);
}
