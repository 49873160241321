import { useMutation } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import { groupBy, sortBy, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CREATE_LOCATION_STORAGE,
  UPDATE_LOCATION_STORAGE,
} from '../../../../../../apollo/mutations/locations';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { ConfirmationModal } from '../../../../../blocks';
import BasicInfo from './BasicInfo';
import InventoryItems from './InventoryItems';

const initialValues = {
  customLabel: '',
  label: '',
  status: '',
  storage: '',
  inventoryItems: [],
};

const CountSheetForm = ({ handleCloseModal, countSheet, statusOptions }) => {
  const { handleSubmit, reset, register, errors, control, getValues } = useForm(initialValues);

  const { locationId } = useParams();
  const { setSnackbar } = useSnackbar();

  const [duplicateInventoryItemIds, setDuplicateInventoryItemIds] = useState([]);
  const [showModal, setShowModal] = useState({ open: false, confirmed: false });

  const [createLocationStorage, { loading }] = useMutation(CREATE_LOCATION_STORAGE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Count Sheet created',
        open: true,
      });

      handleCloseModal();
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createLocationStorage?.viewer),
        fields: {
          locationStorageConnection() {
            return {
              __typename: 'LocationStorageEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [updateLocationStorage, { loading: updating }] = useMutation(UPDATE_LOCATION_STORAGE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Count Sheet updated',
        open: true,
      });

      handleCloseModal();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (countSheet?.locationStorageId) {
      reset({
        customLabel: countSheet.customLabel,
        storage: countSheet.storage,
        status: countSheet.status ? '1' : '0',
        label: countSheet.label,
        inventoryItems:
          sortBy(
            countSheet?.inventoryitems?.map(item => ({
              inventoryItemId: {
                id: item.inventoryitem?.inventoryitemId,
                label: item.inventoryitem?.label,
              },
              order: item.orderKey,
            })),
            'order',
          ) || [],
      });
    } else {
      reset(initialValues);
    }
  }, [countSheet?.locationStorageId]);

  const handleOnCancelClick = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const onSubmit = useCallback(
    formData => {
      const inventoryItems = formData.inventoryItems.map((field, index) => ({
        orderKey: index,
        inventoryitemId: field.inventoryItemId.id,
      }));

      if (uniqBy(inventoryItems, 'inventoryitemId').length !== inventoryItems.length) {
        const notUniqInventoryItems = Object.entries(
          groupBy(inventoryItems, 'inventoryitemId'),
        ).filter(([, value]) => value.length > 1);

        setDuplicateInventoryItemIds(
          notUniqInventoryItems.map(([inventoryitemId]) => Number(inventoryitemId)),
        );
        return;
      } else {
        setDuplicateInventoryItemIds([]);
      }

      if (countSheet?.locationStorageId) {
        if (countSheet?.inventorycount && !showModal.confirmed) {
          setShowModal({ open: true, confirmed: false });
          return;
        }
        updateLocationStorage({
          variables: {
            input: {
              customLabel: formData.customLabel,
              locationStorageId: countSheet?.locationStorageId,
              // storageId: formData.storage.id,
              status: !!formData.status,
              inventoryitems: inventoryItems,
            },
          },
        });
      } else {
        createLocationStorage({
          variables: {
            input: {
              locationId,
              customLabel: formData.customLabel,
              storageId: formData.storage.id,
              status: !!formData.status,
              inventoryitems: inventoryItems,
            },
          },
        });
      }
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: countSheet?.locationStorageId ? 'Updating Count Sheet...' : 'Creating Count Sheet...',
        open: true,
      });
    },
    [locationId, createLocationStorage, countSheet?.locationStorageId, showModal],
  );

  return (
    <div>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid container item xs={12} spacing={3}>
            <BasicInfo
              register={register}
              errors={errors}
              control={control}
              countSheet={countSheet}
              statusOptions={statusOptions}
            />
          </Grid>
          <Grid item xs={12} spacing={3} container>
            <InventoryItems
              control={control}
              getValues={getValues}
              errors={errors}
              duplicateInventoryItemIds={duplicateInventoryItemIds}
            />
          </Grid>
          <Grid container item xs={12} justify="flex-end" spacing={3}>
            <Grid item>
              <Button onClick={handleOnCancelClick} variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" disabled={loading || updating}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmationModal
        open={showModal.open}
        confirmAction={() => {
          setShowModal({ open: false, confirmed: true });
        }}
        handleClose={() => {
          setShowModal({ open: false, confirmed: true });
        }}
        showCancel={false}
        text={'You are editing an active count sheet and may lose any unsubmitted count data.'}
      />
    </div>
  );
};

export default CountSheetForm;
