import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  datePicker: {
    '& > div': {
      margin: 0,
    },
  },
  timePicker: {
    '& > div': {
      margin: 0,
    },
  },
  actions: {
    margin: `${theme.spacing(4)}px 0`,

    '& .MuiGrid-root': {
      justifyContent: 'right',

      '& .MuiButton-root': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacing: {
    margin: `${theme.spacing(2)}px 0`,

    '& .MuiButton-root': {
      margin: `0 ${theme.spacing(2)}px`,
    },
  },
  switch: {
    margin: `0 ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    height: '44px',
  },
  bottomGrid: {
    paddingBottom: '20px',
  },
  salary: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 50px',
  },
  schedulePreference: {
    margin: '20px 0',
  },
});

export const ModifyScheduleStyles = makeStyles(Styles);
