import React, { useCallback, useMemo } from 'react';
import TriggerEventsView from './View';
import { SERIALIZED_COUPON_TYPE_ID } from '../../../../../../constants';

function TriggerEvents({
  coupon,
  control,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  itemInCart,
  setItemInCart,
  errors,
  couponTriggers: temp_couponTriggers,
  forEachItem,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
  setForEachItem,
  setSerializedCouponModal,
  serializedCouponModal,
  brands,
  setValue,
}) {
  const triggers = temp_couponTriggers.edges.map(edge => {
    return {
      couponTriggerId: edge.node.couponTriggerId,
      label: edge.node.label,
    };
  });

  const getCouponTriggers = useCallback(
    couponTriggers => {
      if (couponTriggers[0].node.coupontrigerId === 1) {
        // Manual code
        return {
          couponTriggerId: couponTriggers[0].node.coupontrigerId,
          valueType: couponTriggers[0].node.valueType,
          values: {},
        };
      }

      if (couponTriggers[0].node.coupontrigerId === 2) {
        // subtotatlSpecificAmount
        const minValue = couponTriggers[2] ? couponTriggers[2].node.valueType : null;
        const maxValue = couponTriggers[1] ? couponTriggers[1].node.valueType : null;

        let values = {};

        if (maxValue) {
          values = {
            [`${couponTriggers[0].node.valueType}`]: couponTriggers[0].node.valueOff,
            [`${couponTriggers[1].node.valueType}`]: couponTriggers[1].node.valueOff,
          };
        } else {
          values = {
            [`${couponTriggers[0].node.valueType}`]: couponTriggers[0].node.valueOff,
            max_amount: '',
          };
        }

        if (minValue) {
          values = {
            ...values,
            [`${couponTriggers[2].node.valueType}`]: couponTriggers[2].node.valueOff,
          };
        }

        return {
          couponTriggerId: couponTriggers[0].node.coupontrigerId,
          valueType: 'min_max',
          values: values,
        };
      }

      if ([3, 4, 5, 7, 8].includes(couponTriggers[0].node.coupontrigerId)) {
        return {
          couponTriggerId: couponTriggers[0].node.coupontrigerId,
          valueType: couponTriggers[0].node.valueType,
          values: couponTriggers.map(edge => ({
            valueOff: edge.node.valueOff,
            valueType: edge.node.valueType,
          })),
        };
      }

      if (couponTriggers[0].node.coupontrigerId === 6) {
        // itemAddedNumberTimes
        const triggerGroups = [];

        couponTriggers.forEach(couponTrigger => {
          if (couponTrigger.node.triggerGroup > 0) {
            const triggerGroupArrayIndex = couponTrigger.node.triggerGroup - 1;
            if (!triggerGroups[triggerGroupArrayIndex]) {
              triggerGroups[triggerGroupArrayIndex] = {
                minQty: null,
                maxQty: null,
                value: [],
              };
            }

            switch (couponTrigger.node.valueType) {
              case 'min_qty':
                triggerGroups[triggerGroupArrayIndex].minQty = parseInt(
                  couponTrigger.node.valueOff,
                );
                break;

              case 'max_qty':
                triggerGroups[triggerGroupArrayIndex].maxQty = parseInt(
                  couponTrigger.node.valueOff,
                );
                break;

              case 'item_id':
                triggerGroups[triggerGroupArrayIndex].value.push(
                  parseInt(couponTrigger.node.valueOff),
                );
                break;

              default:
                break;
            }
          }
        });

        return {
          couponTriggerId: couponTriggers[0].node.coupontrigerId,
          valueType: 'min_max_itemId',
          triggerGroups,
        };
      }

      if (couponTriggers[0].node.coupontrigerId === 10) {
        let values = {
          itemId: null,
          subcategoryId: null,
        };

        couponTriggers.forEach(couponTrigger => {
          if (couponTrigger.node.valueType === 'item_id') {
            values.itemId = +couponTrigger.node.valueOff;
          }
          if (couponTrigger.node.valueType === 'subcategory_id') {
            values.subcategoryId = +couponTrigger.node.valueOff;
          }
        });

        return {
          couponTriggerId: couponTriggers[0].node.coupontrigerId,
          values,
        };
      }

      if (couponTriggers[0].node.coupontrigerId === 11) {
        let values = [];

        couponTriggers.forEach(couponTrigger => {
          if (couponTrigger.node.valueType === 'category_id') {
            values.push(couponTrigger.node.valueOff);
          }
        });

        return {
          couponTriggerId: couponTriggers[0].node.coupontrigerId,
          values,
        };
      }
    },
    [coupon.couponTriggerConnection],
  );

  const couponTriggers = useMemo(() => {
    const couponTriggersWithoutSerializedCodes = coupon.couponTriggerConnection.edges?.filter(
      trigger => trigger.node.coupontrigerId !== 1,
    );

    if (
      coupon.coupontypeId === SERIALIZED_COUPON_TYPE_ID &&
      !couponTriggersWithoutSerializedCodes?.length
    ) {
      return {
        couponTriggerId: 1,
        valueType: 'code',
        values: {},
      };
    } else if (coupon.couponTriggerConnection?.edges?.length) {
      return getCouponTriggers(coupon.couponTriggerConnection?.edges);
    }
  }, [coupon, getCouponTriggers, SERIALIZED_COUPON_TYPE_ID]);

  return (
    <TriggerEventsView
      coupon={coupon}
      couponTriggers={couponTriggers}
      control={control}
      cartItemsRequired={cartItemsRequired}
      setCartItemsRequired={setCartItemsRequired}
      itemAddedNumberTimes={itemAddedNumberTimes}
      itemInCart={itemInCart}
      setItemInCart={setItemInCart}
      forEachItem={forEachItem}
      setForEachItem={setForEachItem}
      triggers={triggers}
      setSerializedCouponModal={setSerializedCouponModal}
      serializedCouponModal={serializedCouponModal}
      errors={errors}
      setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
      selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
      brands={brands}
      setValue={setValue}
    />
  );
}

export default TriggerEvents;
