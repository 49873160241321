import React, { useState, useCallback, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import VendorView from './View';
import EditVendorView from './EditVendorView';

import FETCH_VENDOR from '../../../../../../apollo/queries/vendors/fetchVendor';
import { UPDATE_VENDOR } from '../../../../../../apollo/mutations/vendors';
import { Modal } from "../../../../../blocks";
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { uglifyId } from '../../../../../../utils';
import { setImageToFetchedImage } from './helpers';

const IMAGES = {
  sizes: ['75x75']
};

const Vendor = () => {

  const { vendorId, currentTab } = useParams();
  const history = useHistory();

  const { setSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);

  const [images, setImages] = useState(IMAGES.sizes.map(size => ({ size })));

  const realVendorId = uglifyId('Vendor', vendorId);

  useEffect(() => {
    setImages(
      IMAGES.sizes.map((size) => {
        return {
          size,
        };
      })
    );
  }, []);

  const { data: vendorData, loading: vendorLoading, error: vendorError } = useQuery(FETCH_VENDOR, {
    variables: { id: realVendorId },
    onCompleted: (data) => {
      const fetchedImage = data?.viewer?.vendor?.vendorimage;
      if (fetchedImage) {
        setImages(setImageToFetchedImage(images, fetchedImage));
      }
    },
  });

  const [updateVendor, { loading: updateVendorLoading }] = useMutation(UPDATE_VENDOR, {    
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Vendor updated!',
      });

      handleCloseModal();
    },
    onError: err => {
      setSnackbar({
        open: true,
        type: 'error',
        text: err.message || 'Oops, something went wrong...',
      });
    },
  });

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);
  
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setImages(setImageToFetchedImage(images, vendorData?.viewer?.vendor?.vendorimage));
  }, [images, vendorData?.viewer?.vendor?.vendorimage]);

  const onSubmit = useCallback(async (formData) => {
    await updateVendor({
      variables: {
        input: {
          ...formData,
          logoImage: images[0]
        },
        vendorId: realVendorId,
      },
    });
  }, [images, realVendorId, updateVendor]);

  const handleTabChange = (event, newTabValue) => {
    event.preventDefault();
    history.replace(newTabValue);
  };

  return (
    <>
      <VendorView
        loading={vendorLoading}
        error={vendorError}
        vendor={vendorData?.viewer?.vendor}
        updateVendor={updateVendor}
        updateVendorLoading={updateVendorLoading}
        handleOpenModal={handleOpenModal}
        currentTab={currentTab || 'generalInfo'}
        handleTabChange={handleTabChange}
      />
      <Modal
        open={showModal}
        handleClose={handleCloseModal}
        title="Vendor"
      >
        <EditVendorView
          onSubmit={onSubmit}
          vendor={vendorData?.viewer?.vendor}
          handleCloseModal={handleCloseModal}
          setImages={setImages}
          images={images}
          IMAGES={IMAGES}
        />
      </Modal>
    </>
  )
};

export default Vendor;
