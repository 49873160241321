import React from 'react';
import TriggerEventsView from './View';

function TriggerEvents({
  couponTriggers,
  control,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  itemInCart,
  setItemInCart,
  errors,
  forEachItem,
  setForEachItem,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
  selectedCategory,
  setSelectedCategory,
}) {
  const triggers = couponTriggers.edges.map(edge => {
    return {
      couponTriggerId: edge.node.couponTriggerId,
      label: edge.node.label,
    };
  });

  return (
    <TriggerEventsView
      control={control}
      cartItemsRequired={cartItemsRequired}
      setCartItemsRequired={setCartItemsRequired}
      itemAddedNumberTimes={itemAddedNumberTimes}
      itemInCart={itemInCart}
      setItemInCart={setItemInCart}
      triggers={triggers}
      errors={errors}
      forEachItem={forEachItem}
      setForEachItem={setForEachItem}
      setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
      selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
    />
  );
}

export default TriggerEvents;
