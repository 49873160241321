import { gql } from '@apollo/client';

export const SET_ITEM_CHOICE_INVENTORYITEM = gql`
  mutation SET_ITEM_CHOICE_INVENTORYITEM($input: SetItemChoiceInventoryitemInput!) {
    setItemChoiceInventoryitem(input: $input) {
      viewer {
        id
      }
      isRemove
    }
  }
`;
