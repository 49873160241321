import { FormControl, Grid, InputLabel, MenuItem, Button, Select } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FETCH_INVOICES, LOCATIONS } from '../../../../../apollo/queries';
import { vendorOptions } from '../../../../../constants';
import { convert, pad } from '../../../../../utils';
import generateReportPdf from '../../../../../utils/pdfUtils/generateReportPdf';
import { ConnectionTable, DateRange, SelectFactory } from '../../../../blocks';

const initialDateRange = {
  gte: moment()
    .add(-7, 'days')
    .format('MM-DD-YYYY'),
  lte: moment().format('MM-DD-YYYY'),
};

const PurchaseLogInvoices = () => {
  const handleOnLocationSelect = useCallback(({ values, refetch, variables }) => {
    if (isEmpty(values)) {
      const filters = variables.filter;
      if (filters?.location) {
        delete filters.location;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          location: {
            locationId: {
              in: values.map(({ id }) => id),
            },
          },
        },
      });
    }
  }, []);

  const handleOnVendorChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.isSimple) {
        delete filters.isSimple;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          isSimple: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  const handleOnExportPdfClick = useCallback(({ tableData, variables }) => {
    generateReportPdf({
      title: `Purchase Log - Invoices (${(variables?.filter?.invoiceDate?.gte &&
        moment(variables.filter.invoiceDate.gte)?.format('MM/DD/YYYY')) ||
        ''} - ${(variables?.filter?.invoiceDate?.lte &&
        moment(variables.filter.invoiceDate.lte)?.format('MM/DD/YYYY')) ||
        ''})`,
      tableData,
      header: [
        { text: 'Location', field: 'location' },
        { text: 'Invoice #', field: 'invoiceNumber' },
        { text: 'Vendor Name', field: 'supplierName' },
        { text: 'Total Cost', field: 'calculatedTotal' },
        { text: 'Date Recieved', field: 'invoiceDate' },
        { text: 'Date Submitted', field: 'created' },
      ],
    });
  }, []);

  return (
    <ConnectionTable
      alignItemsCenter="flex-start"
      customActionsTop={(_, { tableData, variables }) => (
        <Button
          disabled={isEmpty(tableData)}
          onClick={() => handleOnExportPdfClick({ tableData, variables })}
        >
          Export PDF
        </Button>
      )}
      customActionsCenter={(_, { refetch, variables }) => (
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <SelectFactory
              label="Location"
              name="locationId"
              placeholder="Search location..."
              fullWidth
              query={LOCATIONS}
              multiple
              defaultValue={[]}
              disableSearchReset={false}
              renderCheckBox
              otherVariables={{
                fieldGroupOnly: 95,
              }}
              onSelect={values => {
                handleOnLocationSelect({ values, refetch, variables });
              }}
              structureData={data =>
                data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                  value: edge.node.locationId,
                  id: edge.node.locationId,
                  index,
                  label: `${edge.node?.address?.state?.abbreviation}-${pad(
                    edge.node?.storeNumber || 0,
                    4,
                    '0',
                  )} - ${edge.node.label}`,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateRange fieldName={'invoiceDate'} variables={variables} refetch={refetch} />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel>Vendor</InputLabel>
              <Select
                label="Vendor"
                multiple={false}
                defaultValue={-1}
                onChange={({ target: { value } }) =>
                  handleOnVendorChange({ value, refetch, variables })
                }
              >
                {vendorOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      query={FETCH_INVOICES}
      showPagination={false}
      initialQueryVariables={{
        filter: {
          isSubmitted: { eq: true },
          invoiceDate: {
            gte: moment(initialDateRange.gte)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
            lte: moment(initialDateRange.lte)
              .utc()
              .add(23, 'hours')
              .add('59', 'minutes')
              .add(59, 'seconds')
              .format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      }}
      columns={[
        {
          title: 'Location',
          field: 'location',
          customSortField: 'location.storeNumber',
        },
        { title: 'Invoice #', field: 'invoiceNumber' },
        { title: 'Vendor Name', field: 'supplierName' },
        { title: 'Total Cost', field: 'calculatedTotal', customSortField: 'subtotal' },
        { title: 'Date Recieved', field: 'invoiceDate' },
        { title: 'Date Submitted', field: 'created' },
      ]}
      structureTable={data =>
        data?.viewer?.invoiceConnection?.edges?.map(edge => ({
          location: `${edge.node?.location?.address?.state?.abbreviation}-${pad(
            edge.node?.location?.storeNumber,
            4,
            '0',
          )}`,
          invoiceNumber: edge.node?.invoiceNumber,
          supplierName: edge.node?.isSimple
            ? `Simple Invoice - "${edge.node?.supplierName}"`
            : 'US Foods',
          calculatedTotal: `$ ${convert.centsToDollars(edge.node?.calculatedTotal || 0)}`,
          invoiceDate: moment(edge.node?.invoiceDate).format('YYYY-MM-DD h:mm A'),
          created: moment(edge.node?.created).format('YYYY-MM-DD h:mm A'),
        })) || []
      }
      title="Purchase Log - Invoices"
    />
  );
};

export default PurchaseLogInvoices;
