import { gql } from '@apollo/client';

const CREATE_WAGE_RULE = gql`
  mutation createWageRule($input: CreateWageRuleMutationInput!) {
    createWageRule(input: $input) {
      clientMutationId
    }
  }
`;

export default CREATE_WAGE_RULE;
