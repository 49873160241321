import React from "react";
import PrinterStationSettingsView from "./View";
function PrinterStationSettings({ devicesAndPrinters, permissions }) {
  return (
    <PrinterStationSettingsView
      devicesAndPrinters={devicesAndPrinters}
      permissions={permissions}
    />
  );
}

export default PrinterStationSettings;
