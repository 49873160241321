import { gql } from '@apollo/client';

const SAVE_CREDIT_CARD = gql`
  mutation saveCreditCard($personId: ID!, $paymentMethodId: String!) {
    saveCreditCardV2(input: { paymentMethodId: $paymentMethodId, personId: $personId }) {
      viewer {
        id
      }
    }
  }
`;

export default SAVE_CREDIT_CARD;
