import { gql } from '@apollo/client';

const BULK_EDIT_GENERAL_INFO = gql`
  mutation bulkEditGeneralInfo($input: BulkEditGeneralInfoInput!) {
    bulkEditGeneralInfo(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default BULK_EDIT_GENERAL_INFO;
