import { gql } from '@apollo/client';

const LOCATION_ITEM_PRICE = gql`
  query LocationItems(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sort: [ItemSort]
    $itemFilter: [ItemFilter]
    $locationMultipleChoice: [LocationMultipleChoiceFilter]
    $subcategoryItemFilter: [SubcategoryItemFilter]
    $locationFilter: [LocationItemFilter]
    $search: String
    $locationId: ID!
    $locationActiveTier: Int
  ) {
    viewer {
      id
      location(id: $locationId) {
        label
        tierId
      }
      platformConnection(
        filter: {
          id: {
            in: [
              "UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ=="
              "UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ=="
              "UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ=="
            ]
          }
        }
        sort: { label: DESC }
      ) {
        edges {
          node {
            id
            label
          }
        }
      }
      itemConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $itemFilter
        locationMultipleItemsSearch: $search
        fieldGroup: 19
      ) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        permissions
        edges {
          cursor
          node {
            description
            preparationTime
            id
            itemId
            label
            price
            priceCap
            availableOnline
            itemTierConnection {
              edges {
                node {
                  tier {
                    label
                  }
                  itemTierPlatformConnection {
                    edges {
                      node {
                        platformId
                        priceCap
                        platform {
                          label
                          platformId
                        }
                      }
                    }
                  }
                }
              }
            }
            subcategoryItemConnection(filter: $subcategoryItemFilter) {
              edges {
                node {
                  subcategory {
                    subcategoryId
                    label
                  }
                }
              }
            }
            itemTierConnection {
              edges {
                node {
                  id
                  tierId
                  price
                  priceCap
                }
              }
            }
            locationItemConnection(filter: $locationFilter) {
              totalCount
              edges {
                node {
                  price
                  posPrice
                  thirdPartyPrice
                  isAvailable
                  taxgroupId
                }
              }
            }
            multipleConnection {
              edges {
                node {
                  multipleId
                  id
                  label
                  locationMultipleChoiceConnection(filter: $locationMultipleChoice) {
                    edges {
                      node {
                        price
                        posPrice
                        thirdPartyPrice
                        multiple {
                          id
                        }
                        choice {
                          id
                        }
                      }
                    }
                  }
                  multipleChoiceConnection {
                    edges {
                      node {
                        id
                        choiceId
                        price
                        priceCap
                        choice {
                          id
                          label
                        }
                        multipleChoiceTierConnection {
                          edges {
                            node {
                              id
                              tier {
                                id
                                tierId
                              }
                              multipleChoiceTierPlatformConnection(
                                filter: { tier: { tierId: { eq: $locationActiveTier } } }
                              ) {
                                edges {
                                  node {
                                    tier {
                                      label
                                    }
                                    platform {
                                      label
                                    }
                                    priceCap
                                  }
                                }
                              }
                              price
                              priceCap
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LOCATION_ITEM_PRICE;
