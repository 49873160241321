import { makeStyles } from '@material-ui/core';

const OrderTrackerStepStyles = () => ({
  container: {
    width: '100%',
    cursor: ({ active }) => (!active ? 'pointer' : 'default'),
    margin: '0 6px',
  },
  icon: {
    width: '100%',
    height: 'auto',
    marginBottom: '12px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '12px',
  },
  iconWrapper: {
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: ({ active, completed }) => (active || completed ? '#FFDA00' : '#E7E8EF'),
    color: ({ active, completed }) => (!active && !completed ? '#747480' : '#010101'),
  },
  labelContainer: {
    textAlign: 'center',
  },
  labelWrapper: {
    fontSize: '16px',
    fontWeight: ({ active }) => (active ? '700' : '500'),
    color: ({ active, completed }) => (!active && !completed ? '#747480' : '#010101'),
  },
});

export const OrderTrackerClasses = makeStyles(OrderTrackerStepStyles);
