import React, { useContext, useState, useMemo, useEffect } from 'react';
import Menu from './Menu';
import Multiples from './Multiples';
import Ingredients from './Ingredients';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Item from './Item';
import { useLazyQuery } from '@apollo/client';
import FETCH_LOCATION_MENU from '../../../../../apollo/queries/menus/fetchLocationMenu';
import { CircularProgress } from '@material-ui/core';
import { CoreContext } from '../../../../../CoreContext';
import { useFormSubmitPermissions } from '../../../../../hooks';
import { MUTATION_NAME } from '../../../../../constants';

const MenuSettings = ({
  locationId: propsLocationId,
  statuses,
  isBulkEdit,
  locationIds,
  bulkEditPermission,
}) => {
  const context = useContext(CoreContext);
  const mutationPermissions = context?.coreUtils?.getAppActions()[19]?.mutations;
  const { locationId } = useParams();
  const [filters, setFilters] = useState({});

  const [permissions, setPermissions] = useState();
  const [ingredientPermissions, setIngredientPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.UpdateLocationItemMutationInput,
      MUTATION_NAME.ToggleLocationItemMutationInput,
      MUTATION_NAME.updateLocationItemMultipleChoicePriceMutationNDInput,
      MUTATION_NAME.UpdateLocationsIngredientMutationInput,
      MUTATION_NAME.ToggleLocationIngredientMutationInput,
      MUTATION_NAME.ToggleAllLocationIngredientsMutationInput,
    ],
    permissions: {
      ...(permissions || {}),
      ...(ingredientPermissions || {}),
    },
  });

  const locationFilter = useMemo(
    () =>
      isBulkEdit
        ? { in: locationIds.map(location => atob(location.id).split(':')[1]) }
        : { eq: locationId || propsLocationId },
    [isBulkEdit, locationId, locationIds, propsLocationId],
  );

  const [fetchMenu, { data, loading, error }] = useLazyQuery(FETCH_LOCATION_MENU, {
    variables: {
      filter: {
        locationId: {
          ...locationFilter,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    fetchMenu();
  }, [fetchMenu]);

  if (loading) return <CircularProgress />;
  if (error) return <>error</>;
  if (!data?.viewer?.locationConnection?.edges?.length)
    return <div>No data available for this location</div>;
  const locationChowlyKeys = data?.viewer?.locationConnection?.edges.map(location =>
    Boolean(location?.node?.locationChowly?.chowlyApiKey),
  );
  const everylocatioHasChowly = isBulkEdit ? locationChowlyKeys.every(location => location) : false;

  return (
    <div style={{ minWidth: '100%' }}>
      {isBulkEdit ? null : (
        <Menu
          menuLabel={data?.viewer?.locationConnection?.edges[0]?.node?.menu?.label}
          showPublishButton={
            !!data?.viewer?.locationConnection?.edges[0]?.node?.locationChowly?.chowlyApiKey
          }
          lastPublished={
            data?.viewer?.locationConnection?.edges[0]?.node?.locationChowly?.lastUpload
              ? moment
                  .utc(data?.viewer?.locationConnection?.edges[0]?.node?.locationChowly?.lastUpload)
                  .tz(data?.viewer?.locationConnection?.edges[0]?.node?.timezone?.label)
                  .format('MM-DD-YYYY h:mm A')
              : '-'
          }
          locationId={locationId || propsLocationId}
          locationIds={locationIds}
          permissions={data?.viewer?.locationConnection?.permissions}
          mutationPermissions={mutationPermissions}
          fetchMenu={fetchMenu}
        />
      )}
      <Item
        isBulkEdit={isBulkEdit}
        locationIds={locationIds}
        bulkEditPermission={bulkEditPermission}
        locationId={locationId || propsLocationId}
        menuId={data?.viewer?.locationConnection?.edges[0]?.node?.menuId}
        tierId={data?.viewer?.locationConnection?.edges[0]?.node?.tierId}
        chowlyAPIkey={
          isBulkEdit
            ? everylocatioHasChowly
            : data?.viewer?.locationConnection?.edges[0]?.node?.locationChowly?.chowlyApiKey
        }
        setFilters={setFilters}
        filters={filters}
        permissions={data?.viewer?.locationConnection?.permissions}
        mutationPermissions={mutationPermissions}
        statuses={statuses}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
        permissionsForMutations={permissions}
        setPermissionsForMutations={setPermissions}
        isPriceCapApplicable={data?.viewer?.locationConnection?.edges[0]?.node?.priceCapApplicable}
      />
      <Multiples
        locationId={locationId}
        isBulkEdit={isBulkEdit}
        locationIds={locationIds}
        menuId={data?.viewer?.locationConnection?.edges[0]?.node?.menuId}
        chowlyAPIkey={
          isBulkEdit
            ? everylocatioHasChowly
            : data?.viewer?.locationConnection?.edges[0]?.node?.locationChowly?.chowlyApiKey
        }
        mutationPermissions={mutationPermissions}
        bulkEditPermission={bulkEditPermission}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
        isPriceCapApplicable={data?.viewer?.locationConnection?.edges[0]?.node?.priceCapApplicable}
        tierId={data?.viewer?.locationConnection?.edges[0]?.node?.tierId}
      />
      {!isBulkEdit && (
        <Ingredients
          locationIds={locationIds}
          locationId={locationId || propsLocationId}
          statuses={statuses}
          mutationPermissions={mutationPermissions}
          bulkEditPermission={bulkEditPermission}
          isBulkEdit={isBulkEdit}
          accessToMutations={accessToMutations}
          ingredientPermissions={ingredientPermissions}
          setIngredientPermissions={setIngredientPermissions}
        />
      )}
    </div>
  );
};

export default MenuSettings;
