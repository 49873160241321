import React, { useState } from 'react';
import { Button, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import SelectMUI from '../../../../blocks/Select';
import { useQuery } from '@apollo/client';
import SUBCATEGORY_ITEM from '../../../../../apollo/queries/caterings/subcategoryItem';
import MultipleSelect from './MultipleSelect';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { CurrencyInputFormat, convert } from '../../../../../utils';
import { CurrencyInput, PermissionField, Permission } from '../../../../blocks';

const useStyles = makeStyles(theme => ({
  colorAdd: {
    color: '#82DCB6',
    padding: 3,
  },
  colorSubtrack: {
    color: '#E67189',
    padding: 3,
  },
}));

const ItemSelect = ({
  subcategoryId,
  id,
  setValue,
  errors,
  line,
  control,
  register,
  lineNumber,
  locationId,
  itemId,
  setItemId,
  quote,
  handleOpen,
  removeOrderItemPermission,
}) => {
  const classes = useStyles();
  const [locationItem, setLocationItem] = useState({});
  const [price, setPrice] = useState(line?.price && convert.centsToDollars(line?.price));

  const [quantity, setQuantity] = useState(line?.quantity && line?.quantity);
  const { data, error, loading } = useQuery(SUBCATEGORY_ITEM, {
    variables: {
      filter: {
        subcategoryId: {
          eq: subcategoryId,
        },
        showUnavailableOnline: { eq: true },
      },
      locationFilter: {
        locationId: {
          eq: locationId.locationId,
        },
      },
      locationIdPrice: locationId.locationId,
    },
    skip: !subcategoryId,
    onCompleted: () => {
      setItemId(line?.item?.itemId);
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>error</div>;
  }
  const subCategoryItemValues = data?.viewer?.subcategoryItemConnection?.edges?.map(
    subcategoryItem => ({
      label: subcategoryItem?.node?.item?.label,
      value: subcategoryItem?.node?.item?.itemId,
      price: subcategoryItem?.node?.item?.price,
      quantity: subcategoryItem?.node?.item?.minQuantity,
    }),
  );
  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Permission access={quote?.permissions?.lineConnection?.edges?.node?.item?.__typename}>
          <Grid item xs={12} lg={itemId && locationItem ? 6 : 12}>
            <PermissionField
              Component={SelectMUI}
              items={subCategoryItemValues}
              label={'Item'}
              id={`${lineNumber}-selected-itemId`}
              rules={{ required: true }}
              selectedFullItem={item => {
                setPrice(item?.rest?.price && convert.centsToDollars(item?.rest?.price));
                setQuantity(item?.rest?.quantity);
              }}
              error={errors && errors[`${lineNumber}-selected-itemId`]}
              helperText={errors && errors[`${lineNumber}-selected-itemId`] && 'Item is required.'}
              defaultValue={line?.item?.itemId || ''}
              control={control}
              setSelected={value => {
                setItemId(value);
              }}
              variant="outlined"
              width={'100%'}
            />
          </Grid>
        </Permission>
        {itemId && (
          <>
            <Permission access={quote?.permissions?.lineConnection?.edges?.node?.price}>
              <Grid item xs={4} lg={2}>
                <PermissionField
                  Component={CurrencyInput}
                  control={control}
                  rules={{
                    validate: {
                      positive: value => {
                        const formattedPrice = CurrencyInputFormat(value);
                        const isPositive = formattedPrice >= 0;
                        return isPositive;
                      },
                    },
                  }}
                  label={'Item Price'}
                  onValueChange={value =>
                    setValue(`${lineNumber}-selected-price-item`, value.value)
                  }
                  defaultValue={price}
                  error={errors && errors[`${lineNumber}-selected-price-item`]}
                  helperText={
                    errors &&
                    errors[`${lineNumber}-selected-price-item`] &&
                    "Price can't be negative."
                  }
                  name={`${lineNumber}-selected-price-item`}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.lineConnection?.edges?.node?.quantity}>
              <Grid item xs={3} lg={2}>
                <PermissionField
                  Component={TextField}
                  label={'Quantity'}
                  id={`${lineNumber}-selected-quantity-item`}
                  inputRef={register({
                    validate: {
                      positive: value => {
                        return value > 0;
                      },
                    },
                  })}
                  control={control}
                  variant="outlined"
                  defaultValue={quantity}
                  onChange={event => setQuantity(parseInt(event.target.value) || 0)}
                  error={errors && errors[`${lineNumber}-selected-quantity-item`]}
                  helperText={
                    errors &&
                    errors[`${lineNumber}-selected-quantity-item`] &&
                    "Quantity can't be negative or 0."
                  }
                  name={`${lineNumber}-selected-quantity-item`}
                />
              </Grid>
            </Permission>
            <Grid item xs={2} lg={2}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PermissionField
                  createHelperText
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <div>
                    <IconButton variant="contained" className={classes.colorSubtrack}>
                      <PermissionField
                        Component={RemoveCircleOutlineIcon}
                        onClick={() => {
                          const value = document.getElementById(
                            `${lineNumber}-selected-quantity-item`,
                          ).value;
                          document.getElementById(`${lineNumber}-selected-quantity-item`).value =
                            value - 1;
                          setQuantity(value - 1);
                        }}
                      />
                    </IconButton>
                  </div>
                  <div>
                    <IconButton variant="contained" className={classes.colorAdd}>
                      <PermissionField
                        Component={AddCircleOutlineIcon}
                        onClick={() => {
                          const value = parseInt(
                            document.getElementById(`${lineNumber}-selected-quantity-item`).value,
                          );
                          document.getElementById(`${lineNumber}-selected-quantity-item`).value =
                            value + 1;
                          setQuantity(value + 1);
                        }}
                      />
                    </IconButton>
                  </div>
                </PermissionField>
              </div>
              <Permission access={line ? removeOrderItemPermission?.access : 7}>
                <div>
                  <PermissionField createHelperText>
                    <Button onClick={() => handleOpen()}>Remove</Button>
                  </PermissionField>
                </div>
              </Permission>
            </Grid>
          </>
        )}
      </Grid>
      <br />
      <br />
      {itemId && (
        <MultipleSelect
          permissions={quote?.permissions}
          setLocationItem={setLocationItem}
          itemId={itemId}
          control={control}
          key={`multipleSelect-${id}`}
          id={id}
          note={line?.note}
          errors={errors}
          register={register}
          lineNumber={lineNumber}
          locationId={locationId}
          line={line}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default ItemSelect;
