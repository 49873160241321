import React from "react";
import {
  TextField,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { SERIALIZED_COUPON_TYPE_ID } from '../../../../../../../../constants';

function GenerateCode({ coupon, control, errors, codeGenerationType }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* <Controller
        control={control}
        name="triggerEvents.manual.code.autoGenerate"
        render={(
          { onChange, onBlur, value, name, ref },
          { invalid, isTouched, isDirty }
        ) => (
          <FormControlLabel
            labelPlacement="end"
            control={<Checkbox onChange={(e) => onChange(e.target.checked)} />}
            label="Generate Unique Redemption"
          />
        )}
      /> */}
      <div>
          {codeGenerationType !== SERIALIZED_COUPON_TYPE_ID ? <Controller
          control={control}
          name="triggerEvents.manual.code.code"
          defaultValue={coupon.code}
          rules={{
            required: "Coupon code is required",
          }}
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <TextField
              style={{ width: "20rem" }}
              inputRef={ref}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Code"
              defaultValue={value}
              error={errors?.triggerEvents?.manual?.code?.code}
              helperText={
                errors?.triggerEvents?.manual?.code?.code
                  ? errors?.triggerEvents?.manual?.code?.code.message
                  : ""
              }
              InputProps={
                {
                  // endAdornment: <Button variant="outlined">Generate</Button>,
                }
              }
            />
          )}
        /> : <span>Codes will be generated automatically by clicking "GENERATE CODES" button.</span>}
      </div>
    </div>
  );
}

export default GenerateCode;
