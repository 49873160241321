import { useQuery } from "@apollo/client";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { REFUNDREASONS } from "../../../../../apollo/queries";
import { MUTATION_NAME } from "../../../../../constants";
import CurrencyInput from "../../../../blocks/CurrencyInput";

function RefundPaymentModal({
  data: refundData,
  handleRefund,
  disableRefundControls,
  handleCloseRefundModal,
  setRefundReasonsPermissions,
  accessToMutations,
  refundReasonPermissions,
}) {
  const { data: reasons, loading } = useQuery(REFUNDREASONS, {
    onCompleted: data1 => {
      if (!refundReasonPermissions)
      setRefundReasonsPermissions({
        paymentId: data1?.viewer?.refundreasonConnection.permissions?.__typename,
        refundreasonId: data1?.viewer.refundreasonConnection.permissions.refundreasonId,
      });
    }
  });
  const { control, handleSubmit, errors } = useForm();
  if (loading) {
    return "Loading...";
  }
  const temp_reasons = reasons?.viewer?.refundreasonConnection?.edges.map(
    (edge) => edge.node
  );

  return (
    <div style={{ width: "100%" }}>
      <form action="" onSubmit={handleSubmit(handleRefund)}>
        <CurrencyInput
          name="amount"
          fullWidth
          control={control}
          helperText={errors && errors.amount && errors?.amount?.message}
          error={errors && errors?.amount && errors?.amount?.message}
          label="Amount $"
        />
        <br />
        <br />
        <Controller
          name="reason"
          control={control}
          defaultValue={1}
          render={({ onChange, value }) => {
            return (
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Reason</FormLabel>
                  <RadioGroup value={+value} onChange={onChange} row>
                    {temp_reasons.map((reason) => (
                      <FormControlLabel
                        value={+reason.refundreasonId}
                        control={<Radio />}
                        label={reason.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            );
          }}
        />
        <br />
        <Controller
          control={control}
          name="note"
          render={({ onChange, value }) => {
            return (
              <TextField
                onChange={onChange}
                fullWidth
                value={value}
                id="outlined-multiline-static"
                label="Note"
                multiline
                rows={4}
                placeholder="Enter Note"
                variant="outlined"
              />
            );
          }}
        />
        <br />
        <br />
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: ".5rem" }}
        >
          <Button
            disabled={disableRefundControls}
            type="button"
            onClick={handleCloseRefundModal}
          >
            Cancel
          </Button>
          <Button
            disabled={disableRefundControls || accessToMutations?.[MUTATION_NAME.CreateRefundMutationInput] < 7}
            style={
              disableRefundControls
                ? { background: "gray", color: "white" }
                : { background: "red", color: "white" }
            }
            type="submit"
          >
            Refund
          </Button>
        </div>
      </form>
    </div>
  );
}

export default RefundPaymentModal;
