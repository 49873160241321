import { gql } from '@apollo/client';

const CREATE_WEEKLY_SALE_COMMITMENT = gql`mutation createWeeklySaleCommitment($input:CreateScheduleweeklysalesprojectionMutationInput!, $weeklySaleProjectionsFilter: [ScheduleweeklysalesprojectionFilter]) {
  createScheduleweeklysalesprojection(input:$input){
    viewer {
      id
      scheduleweeklysalesprojectionConnection(filter: $weeklySaleProjectionsFilter) {
        edges {
          node {
            id
            scheduleweeklysalesprojectionId
            created
            started
            locationId
            auvCommitmentAmount
            auvWeightMondayFraction
            auvWeightTuesdayFraction
            auvWeightWednesdayFraction
            auvWeightThursdayFraction
            auvWeightFridayFraction
            auvWeightSaturdayFraction
            auvWeightSundayFraction
          }
        }
      }
    }
  }
}`

export default CREATE_WEEKLY_SALE_COMMITMENT;