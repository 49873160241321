import Discounts from './Discounts';
import CreateDiscount from './Discounts/CreateDiscount';
import UpdateDiscount from './Discounts/UpdateDiscount';
import Loyalty from './Loyalty';
import MenuSettings from './MenuSettings';
import { Overtimes } from './Overtimes';
import AccessManagement from './AccessManagement';
import Hierarchy from './Hierarchy';
import Vendors from './Vendors';
import CreateVendor from './Vendors/CreateVendor';
import Vendor from './Vendors/Table/Vendor';
import InventorySettings from './InventorySettings';

const SystemSettings = {
  Discounts,
  CreateDiscount,
  UpdateDiscount,
  Loyalty,
  MenuSettings,
  Overtimes,
  AccessManagement,
  Hierarchy,
  Vendors,
  CreateVendor,
  Vendor,
  InventorySettings,
};

export default SystemSettings;
