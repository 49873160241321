import { gql } from '@apollo/client';

const CREATE_TIER = gql`
  mutation createTier($input: CreateTierMutationInput!) {
    createTier(input: $input) {
      clientMutationId
    }
  }
`;

export default CREATE_TIER;
