import { gql } from "@apollo/client";

const REMOVE_MULTIPLE = gql`
  mutation removeMultiple($input:  RemoveMultipleMutationInput!) {
    removeMultiple(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default REMOVE_MULTIPLE;
