import { gql } from '@apollo/client';

const DELETE_USERGROUP = gql`
  mutation deleteUsergroup($usergroupId: Int!) {
    deleteUsergroup(input: { usergroupId: $usergroupId }) {
      usergroupId
    }
  }
`;

export default DELETE_USERGROUP;
