export const STEP = {
  INVOICE_INFO: 0,
  ADD_PRODUCTS: 1,
};

export const INITIAL_IMAGES = ['150x150'];

export const INITIAL_INVOICE_INFO = {
  supplierName: '',
  invoiceNumber: '',
  dateOfPurchase: '',
  subtotal: '',
  tax: '',
  total: '',
  receiptimage: null,
};
