import { gql } from "@apollo/client";

const FETCH_LOG_ACTIONS = gql`
  query logActions (
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [LogActionFilter]
    $sort: [LogActionSort]
  ) {
    viewer {
      id
      logActionConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        filter: $filter
        sort: $sort
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        permissions
        edges {
          node {
            id
            logActionId
            label
            type
          }
        }
      }
    }
  }
`;

export default FETCH_LOG_ACTIONS;
