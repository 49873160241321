import { gql } from '@apollo/client';

const FETCH_QUOTE_CATERING_ITEM_CHOICES = gql`
  query fetchItemChoice(
    $filter: [ItemMultipleFilter]
    $locationItemFilter: [LocationItemFilter]
    $itemFilter: [ItemFilter]
    $locationId: Int
  ) {
    viewer {
      id
      locationItemConnection(filter: $locationItemFilter) {
        edges {
          node {
            id
            price
          }
        }
      }
      itemConnection(filter: $itemFilter) {
        edges {
          node {
            id
            price
            minQuantity
            maxQuantity
          }
        }
      }

      itemMultipleConnection(filter: $filter) {
        edges {
          node {
            id
            item {
              id
              itemId
              label
              minQuantity
              price
            }
            allowedMaximum
            requiredMinimum
            multiple {
              id
              label
              multipleId
              multipleChoiceConnection {
                edges {
                  node {
                    id
                    allowedMaximum
                    price(locationId: $locationId)
                    choice {
                      id
                      choiceId
                      cost
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_QUOTE_CATERING_ITEM_CHOICES;
