import { find } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';

import { MUTATION_NAME } from '../../../../../../constants';
import Modal from '../../../../../blocks/Modal';
import Permission from '../../../../../blocks/Permission';
import CreateEmployeeSchedule from '../../ModifySchedule';
import CalendarScheduleTimelineView from './View';

const CalendarScheduleTimeline = ({
  scheduleData,
  selectedWeek,
  refetchScheduleVariables,
  jobList,
  locationTimezone,
  schedulePermissions,
  mutationPermissions,
  setOpenWarningMutationName,
  timelineVisibleDate,
  setVisibleDayStart,
}) => {
  useEffect(() => {
    // Check if selected week has changed
    let startOfWeek = moment.tz(selectedWeek, locationTimezone).startOf('day');
    let endOfWeek = moment.tz(selectedWeek, locationTimezone).add(6, 'days');
    if (
      timelineVisibleDate?.valueOf() < startOfWeek.valueOf() ||
      timelineVisibleDate?.valueOf() > endOfWeek.valueOf()
    ) {
      setVisibleDayStart(moment.tz(selectedWeek, locationTimezone).startOf('day'));
      setDateLimiter({ startOfWeek: startOfWeek, endOfWeek: endOfWeek });
    }
  }, [selectedWeek]);

  const [dateLimiter, setDateLimiter] = useState({
    startOfWeek: moment.tz(selectedWeek, locationTimezone).startOf('day'),
    endOfWeek: moment
      .tz(selectedWeek, locationTimezone)
      .add(6, 'days')
      .startOf('day'),
  });

  const [isEditScheduleVisible, setIsEditScheduleVisible] = useState(false);
  const [scheduleToEdit, setScheduleToEdit] = useState({});

  // Helper functions
  const onIncrementDate = useCallback(() => {
    if (timelineVisibleDate < dateLimiter.endOfWeek) {
      setVisibleDayStart(moment.tz(timelineVisibleDate, locationTimezone).add(1, 'day'));
    }
  }, [timelineVisibleDate, locationTimezone]);
  const onDecreaseDate = useCallback(() => {
    if (timelineVisibleDate > dateLimiter.startOfWeek) {
      setVisibleDayStart(moment.tz(timelineVisibleDate, locationTimezone).add(-1, 'day'));
    }
  }, [timelineVisibleDate, dateLimiter, locationTimezone]);

  const prepareForScheduleEdit = useCallback(
    scheduleId => {
      const schedule = find(scheduleData, scheduleEdge => {
        return scheduleEdge.node.scheduleId === scheduleId;
      })?.node;

      setScheduleToEdit(schedule);
      setIsEditScheduleVisible(true);
      setOpenWarningMutationName(MUTATION_NAME.UpdateScheduleMutationInput);
    },
    [scheduleData, MUTATION_NAME],
  );

  const formattedCurrency = useCallback(value => {
    if (!Number(value)) return '$0.00';

    const amount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value / 100);

    return `${amount}`;
  }, []);

  return (
    <>
      <Permission access={Math.min(schedulePermissions?.started, schedulePermissions?.finished)}>
        <CalendarScheduleTimelineView
          scheduleData={scheduleData}
          schedulePermissions={schedulePermissions}
          timelineVisibleDate={moment(timelineVisibleDate, locationTimezone)}
          prepareForScheduleEdit={prepareForScheduleEdit}
          jobList={jobList}
          onIncrementDate={onIncrementDate}
          onDecreaseDate={onDecreaseDate}
          formattedCurrency={formattedCurrency}
          locationTimezone={locationTimezone}
        />
      </Permission>
      <Modal
        open={isEditScheduleVisible}
        handleClose={() => {
          setIsEditScheduleVisible(false);
        }}
        title={`${scheduleToEdit?.person?.firstName} ${scheduleToEdit?.person?.lastName}`}
      >
        <CreateEmployeeSchedule
          disabled={moment(selectedWeek.ts)
            .add(6, 'days')
            .isBefore(moment().startOf('day'))}
          editScheduleData={scheduleToEdit}
          setIsVisibleState={setIsEditScheduleVisible}
          mutationPermissions={mutationPermissions}
          refetchScheduleVariables={refetchScheduleVariables}
          locationTimezone={locationTimezone}
          schedulePermissions={schedulePermissions}
          timelineVisibleDate={timelineVisibleDate}
          setVisibleDayStart={setVisibleDayStart}
          selectedWeek={DateTime.local(
            selectedWeek.year(),
            selectedWeek.month() + 1,
            selectedWeek.date(),
          )}
        />
      </Modal>
    </>
  );
};

export default CalendarScheduleTimeline;
