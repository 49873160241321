import { gql } from '@apollo/client';
import { timezoneFragment } from '../fragments/timezoneFragment';

const TIMEZONE_LIST = gql`
  query timezoneConnection {
    viewer {
      id
      timezoneConnection {
        edges {
          node {
            ...TimezoneData
          }
        }
      }
    }
  }
  ${timezoneFragment.timezone}
`;

export default TIMEZONE_LIST;
