import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import COPY_QUOTECATERING from "../../../../apollo/mutations/quotecatering/copyQuotecatering";
import REMOVE_QUOTECATERING from "../../../../apollo/mutations/quotecatering/removeQuoteCatering";
import SEND_LOCATION from "../../../../apollo/mutations/quotecatering/sendLocation";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  SvgIcon,
  Icon,
  InputAdornment,
  Typography,
  IconButton
} from "@material-ui/core";
import CATERING_ORDERS from "../../../../apollo/queries/cateringOrders";
import { generateOrderPdf, generatePrepPdf } from "../../../../utils/pdfUtils";
import SEND_EMAIL from "../../../../apollo/mutations/quotecatering/sendEmail";
import ViewQuoteInfo from "../../../../assets/icons/View_Quote_info.png";
import ExportQuoteInfo from "../../../../assets/icons/Export_Quote.png";
import ViewPrep from "../../../../assets/icons/View_Prep.png";
import ExportPrep from "../../../../assets/icons/Export_Prep.png";
import CopyIcon from "../../../../assets/icons/Copy.png";
import DeleteIcon from "../../../../assets/icons/delete_icon.png";
import AddNoteIcon from "../../../../assets/icons/Add_note.png";
import EmailCustomer from "../../../../assets/icons/Mask_Group_68.png";
import EmailLocation from "../../../../assets/icons/Email_Location.png";
import LocationInfo from "../../../../assets/icons/Location_Info.png";
import LocationMenu from "../../../../assets/icons/Location_Menu.png";
import { paths, SNACKBAR_STATUS } from "../../../../constants";
import { Modal } from "../../../blocks";
import SnackBar from "../../../blocks/SnackBar";
import AddNote from "../../../../apollo/mutations/quotecatering/addNote";
import { useForm } from "react-hook-form";
import CATERING_ORDER_INFO from "../../../../apollo/queries/caterings/cateringOrderInfo";
import SET_STATUS from "../../../../apollo/mutations/quotecatering/setStatus";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
  toolbarWrapper: {
    width: '100%',
    overflowX: "auto",
    boxShadow: theme.boxShadowBase,
    textAlign: 'center',
    padding: '0 1rem'
  },
  containerToolbar: {
    backgroundColor: "white",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconText: {
    fontSize: '9.84px',
    fontWeight: theme?.typography?.fontWeightThin,
    textAlign: 'left',
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  }
}));

const QuoteCateringToolbar = ({ quote }) => {
  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const [copyQuotecateringMutation] = useMutation(COPY_QUOTECATERING);
  const [removeQuotecateringMutation] = useMutation(REMOVE_QUOTECATERING);
  const [sendLocationMutation] = useMutation(SEND_LOCATION);
  const [sendEmailMutation] = useMutation(SEND_EMAIL);
  const [addQuoteNote] = useMutation(AddNote);
  const [setStatus] = useMutation(SET_STATUS);
  const [toolbarAction, setToolbarAction] = useState({ open: false });
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const openToolbarAction = toolbarAction => setToolbarAction(toolbarAction);
  const closeToolbarAction = () => setToolbarAction({ open: false });

  const copyQuotecatering = () => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Quote is coping',
    })
    copyQuotecateringMutation({
      variables: {
        quotecateringId: quote.quotecatering.id
      }
    }).then(r => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Success',
      })
      setTimeout(navigateToCaterings, 2000)
    })
      .catch((error) => setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      }));
  };

  const addQuoteCateringNote = (noteText) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Quote note is creating.',
    });

    addQuoteNote({
      variables: {
        input: {
          quotecateringId: quote.quotecatering.id,
          note: noteText
        }
      },
      refetchQueries: [
        {
          query: CATERING_ORDER_INFO,
          variables: { id: quote?.id },
        },
      ],
    }).then(r => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Success',
      })
      setTimeout(closeToolbarAction, 1500)
    })
      .catch((error) => setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      }));
  };
  const onSubmit = (data, isForm, action) => {
    if (!isForm) {
      action();
    } else {
      addQuoteCateringNote(data?.noteText);
    }
  };
  const removeQuotecatering = () => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Quote is deleting',
    })
    removeQuotecateringMutation({
      variables: {
        quotecateringId: quote.quotecatering.id
      },
    })
      .then(r => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Success',
        })
        setTimeout(navigateToCaterings, 2000)
      })
      .catch((error) => setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      }))
  }
  const sendEmail = () => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Email is sending.',
    })
    if (quote.quotecatering.cateringExpert) {
      generateOrderPdf(quote, null, pdf => {
        pdf.getBase64(pdfBase64 => {
          sendEmailMutation({
            variables: {
              input: {
                quotecateringId: quote.quotecatering.id,
                template: "catering_quote",
                to: quote.email,
                attachment: pdfBase64,
                attachmentName: `${quote.quotecatering.quotecateringId}_catering_order_details.pdf`
              }
            }
          })
            .then(r => {
              setSnackbar({
                open: true,
                type: 'success',
                text: 'Success',
              })
              try {
                addQuoteNote({
                  variables: {
                    input: {
                      quotecateringId: quote.quotecatering.id,
                      note: "Catering emailed to customer"
                    }
                  },
                  refetchQueries: [
                    {
                      query: CATERING_ORDER_INFO,
                      variables: { id: quote?.id },
                    },
                  ],
                }).then(r => r)

                setStatus({
                  variables: {
                    input: {
                      quotecateringId: quote.quotecatering.id,
                      statusId: 2
                    }
                  },
                  refetchQueries: [
                    {
                      query: CATERING_ORDER_INFO,
                      variables: { id: quote?.id },
                    },
                  ],
                }).then(r => r)
              } catch (e) {
                setSnackbar({
                  open: true,
                  type: 'error',
                  text: 'Something went wrong',
                })
              }
            })
            .catch((error) => setSnackbar({
              open: true,
              type: 'error',
              text: error.message,
            }))
        });
      });
    } else {
      setSnackbar({
        open: true,
        type: 'error',
        text: "Catering expert is not selected",
      })
    }
  };
  const shareLocation = () => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Email is sending',
    })
    sendLocationMutation({
      variables: {
        input: {
          quotecateringId: quote.quotecatering.id,
          locationId: quote.location.id
        }
      }
    })
      .then(r => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Success',
        })

      })
      .catch((error) => setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      }))
  };
  const navigateToLocationInfo = () => {
    const path = paths.LOCATION.replace(":locationId", quote.locationId).replace(":currentTab?", "generalInfo");
    history.push(path);
  };
  const navigateToCaterings = () => {
    const path = paths.CATERING_ORDERS;
    history.push(path);
  };
  const navigateToLocationMenu = () => {
    const path = paths.SETTINGS_MENU_ID.replace(":menuId", quote?.menuId);
    history.push(path);
  };

  return (
    <div className={classes.toolbarWrapper}>
      <div className={classes.containerToolbar}>
        <div className={classes.iconContainer}>
          <IconButton onClick={() => generateOrderPdf(quote, 1)} disabled={!quote}>
            <img alt="Search icon" src={ViewQuoteInfo} />
          </IconButton>
          <span className={classes.iconText}>View Quote Info</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton onClick={() => generateOrderPdf(quote, 2)} disabled={!quote}>
            <img alt="Search icon" src={ExportQuoteInfo} />
          </IconButton>
          <span className={classes.iconText}>Export Quote Info</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton onClick={() => generatePrepPdf(quote, 1)} disabled={!quote}>
            <img alt="Search icon" src={ViewPrep} />
          </IconButton>
          <span className={classes.iconText}>View Prep</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton onClick={() => generatePrepPdf(quote, 2)} disabled={!quote}>
            <img alt="Search icon" src={ExportPrep} />
          </IconButton>
          <span className={classes.iconText}>Export Prep</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton onClick={() =>
            openToolbarAction({
              title: "Copy",
              open: true,
              content: (
                <DialogContentText>Are you sure you want to copy this quote?</DialogContentText>
              ),
              actions: {
                destructive: "No",
                constructive: "Yes"
              },
              action: copyQuotecatering
            })
          }>
            <img alt="Search icon" src={CopyIcon} />
          </IconButton>
          <span className={classes.iconText}>Copy</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton onClick={() =>
            openToolbarAction({
              title: "Delete",
              open: true,
              content: (
                <DialogContentText>Are you sure you want to delete this quote?</DialogContentText>
              ),
              actions: {
                destructive: "No",
                constructive: "Yes"
              },
              action: removeQuotecatering
            })
          } disabled={!quote}
          >
            <img alt="Search icon" src={DeleteIcon} />
          </IconButton>
          <span className={classes.iconText}>Delete</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton onClick={() =>
            openToolbarAction({
              title: "Note",
              open: true,
              content: <TextField inputRef={register} name={'noteText'} label="Note" />,
              actions: {
                destructive: "Cancel",
                constructive: "Add note"
              },
              isForm: true,
              action: () => addQuoteCateringNote()
            })
          }
            disabled={!quote}
          >
            <img alt="Search icon" src={AddNoteIcon} />
          </IconButton>
          <span className={classes.iconText}>Add Note</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton
            disabled={!quote}
            onClick={() =>
              openToolbarAction({
                title: "Email",
                open: true,
                content: (
                  <DialogContentText>
                    Dear
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {quote.person
                      ? ` ${quote.person.firstName} ${quote.person.lastName}`
                      : quote.quotecatering.contactName
                        ? ` ${quote.quotecatering.contactName}`
                        : "Guest"}
                    ,<br /> <br />
                    Thank you for choosing Dickey's Barbecue Pit for your upcoming event. Your
                    catering quote number is #{quote.quoteId}.<br />
                    <br />
                    At each of our locally owned Dickey's Barbecue Pit locations, we smoke the highest
                    quality meats on-site daily to ensure your guests will enjoy great Texas style
                    barbecue. Please review your catering quote by clicking on the following link:
                    <br />
                    Your Catering Quote
                    <br />
                    If you are ready to move forward with your catering quote, please ACCEPT the quote
                    at least 72 hours prior to your event date and you will receive payment
                    instructions via email once your local store has received your order request.
                    However, if you have questions or you would like any changes to the menu, budget
                    or event timing, please REJECT the quote via the link above and call our Catering
                    Experts on our national hotline at 1-866-227-2328 Monday – Saturday 8:00 a.m. to
                    7:00 p.m. CST and we can revise the quote to meet your needs.
                    <br />
                    We thank you for the opportunity to service your upcoming event and look forward
                    to finalizing your catering event soon.
                    <br />
                    <br />
                    Kind Regards,
                    <br />
                    Dickey's Barbecue Pit Catering Team
                    <br />
                    National Hotline: 1-866-227-2328
                    <br />
                    Mon – Sat 8:00AM – 7:00PM CST | Closed Sundays
                    <br />
                  </DialogContentText>
                ),
                actions: {
                  destructive: "Cancel",
                  constructive: "Send email"
                },
                action: sendEmail
              })
            }>
            <img alt="Search icon" src={EmailCustomer} />
          </IconButton>
          <span className={classes.iconText}>Email Customer</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton
            disabled={!quote}
            onClick={() =>
              openToolbarAction({
                title: "Share With Location",
                open: true,
                content: (
                  <DialogContentText>
                    Dear <b>{quote.location.label}</b>,<br /> <br />
                    Please login to the Spark Online Dashboard to view a Catering Quote you requested
                    from the Catering Department. The Catering Quote number is <b>{quote.quoteId}</b>.
                    <br />
                    <br />
                    You must reach out to this guest within 24 hours of receiving this email.
                    <br />
                    <br />
                    By requesting this Quote from the Catering Department you agree to process payment
                    through the Spark Online Dashboard. Should you choose not to process payment
                    through Spark, expect a call from the Catering Department!
                    <br />
                    <br />
                    <b>Instructions:</b>
                    <br />
                    1. Review order details and CALL the catering department if you need any
                    additional information.
                    <br />
                    * Don&#39;t forget to view all special instructions.
                    <br />
                    * Exporting Quote Info will give you a printable PDF copy of the order, including
                    all of the information you need.
                    <br />
                    2. CALL the customer to introduce yourself and reconfirm order details.
                    <br />
                    <br />
                    Kind Regards,
                    <br />
                    Dickey's Barbecue Pit Catering Team
                    <br />
                    National Hotline: 1-866-227-2328
                    <br />
                    Mon – Sat 8:00AM – 7:00PM CST | Closed Sundays
                  </DialogContentText>
                ),
                actions: {
                  destructive: "Cancel",
                  constructive: "Send"
                },
                action: shareLocation
              })
            }>
            <img alt="Search icon" src={EmailLocation} />
          </IconButton>
          <span className={classes.iconText}>Email Location</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton
            disabled={!quote}
            onClick={() => navigateToLocationInfo()}>
            <img alt="Search icon" src={LocationInfo} />
          </IconButton>
          <span className={classes.iconText}>Location Info</span>
        </div>
        <div className={classes.iconContainer}>
          <IconButton
            disabled={!quote}
            onClick={() => navigateToLocationMenu()}>
            <img alt="Search icon" src={LocationMenu} />
          </IconButton>
          <span className={classes.iconText}>Location Menu</span>
        </div>
        <Dialog
          open={toolbarAction?.open}
          onClose={() => closeToolbarAction()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" disableTypography>
            <Typography variant="h6">{toolbarAction?.title}</Typography>
          </DialogTitle>
          <DialogContent dividers id="alert-dialog-description" style={{ padding: '1rem' }}>
            {toolbarAction?.content}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toolbarAction?.action()}>
              {toolbarAction?.actions?.constructive}
            </Button>
            <Button onClick={() => closeToolbarAction()}>
              {toolbarAction?.actions?.destructive}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Dialog
        open={toolbarAction?.open}
        onClose={() => closeToolbarAction()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h6">{toolbarAction?.title}</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit((data) => onSubmit(data, toolbarAction.isForm, toolbarAction?.action))}>
          <DialogContent dividers id="alert-dialog-description" style={{ padding: '1rem' }}>
            <DialogContentText id="alert-dialog-description" style={{ padding: '1rem' }}>
              {toolbarAction?.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button type={'submit'}>
              {toolbarAction?.actions?.constructive}
            </Button>
            <Button onClick={() => closeToolbarAction()}>
              {toolbarAction?.actions?.destructive}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default QuoteCateringToolbar;
