import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Chip,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { setCreditCardImage } from "../../../utils/creditCards";

const useStyles = makeStyles((theme) => ({
  noLabel: {
    marginTop: theme.spacing(3),
  },
  chip: {
    margin: 1,
  },
  inputLabelSelect: {
    marginLeft: '.2rem',
    top: '-8px',
    left: '8px',
    padding: '0 5px',
    zIndex: 1,
    background: '#fff',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const SelectMUI = ({ fullWidth = true, width, defaultValue = null, rules, variant, items, helperText, label, isMultiple, setSelected, disabled, control, id, error, onValueChange, isValueReseted, selectedFullItem, ...rest }) => {
  const classes = useStyles();
  const [item, setItem] = React.useState(isValueReseted ? null : defaultValue);

  const handleChange = (event) => {
    if (isMultiple) {
      setSelected && setSelected(prevState => {
        //The last added item to array is the selected one
        const selectedObj = event.target.value[event.target.value.length - 1];
        if (prevState?.filter(el => el.id === selectedObj.id).length) {
          return prevState?.filter(el => el.id !== selectedObj.id);
        } else {
          return [...prevState, selectedObj];
        }
      });
      setItem(prevState => {
        //The last added item to array is the selected one
        const selectedObj = event.target.value[event.target.value.length - 1];
        if (prevState?.filter(el => el.id === selectedObj.id).length) {
          return prevState?.filter(el => el.id !== selectedObj.id);
        } else {
          return [...prevState, selectedObj];
        }
      });
    } else {
      setSelected && setSelected(event.target.value);
      setItem(event.target.value);
    }
  };
  const renderValue = (selected) => {
    return (
      <div className={classes.chips}>
        {selected.map((value) => (
          <Chip key={value.id} label={value.label} className={classes.chip} />
        ))}
      </div>
    );
  };
  const MultipleSelect = ({ onChange}) => {
      return (<Select
      {...rest}
      variant={variant}
      error={error}
      multiple={isMultiple}
      inputProps={{ 'aria-label': 'Without label' }}
      id={id}
      style={{maxHeight: '3rem'}}
      value={item || []}
      onChange={(e) => {
        handleChange(e);
      }}
      disabled={disabled}
      renderValue={(selected) => selected.map(({ label }) => label).join(', ')}
      MenuProps={MenuProps}
    >
      {items?.map((x) => {
        return (
          <MenuItem key={x.value} value={{ id: x.value, ...x }}>
            <Checkbox checked={item?.find(el => el.id === x.id)} />
            <ListItemText style={{ margin: '0' }} primary={x.label}/>
          </MenuItem>
        );
      })}
      </Select>)
    }
  ;
  const SingleSelect = ({ onChange }) => (
    <Select
      {...rest}
      variant={variant}
      error={error}
      value={item}
      id={id}
      inputProps={{ 'aria-label': 'Without label' }}
      multiple={isMultiple}
      style={{maxHeight: '3rem'}}
      defaultValue={item}
      disabled={disabled}
      onChange={(e) => {
        onChange(e);
        onValueChange && onValueChange(e);
        handleChange(e);
      }}
    >
      {items?.map(({ value, label, disabledOption, icon, ...rest }) => (
        <MenuItem
          disabled={disabledOption}
          selected={value !== defaultValue}
          key={value}
          onClick={() => selectedFullItem && selectedFullItem({value, label, rest})}
          value={value}
        >
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
            <ListItemText style={{ marginRight: '1rem' }} primary={label} />
            {icon && <ListItemIcon>
              <img src={setCreditCardImage(icon)} alt="" width={36} height={22} />
            </ListItemIcon>}
          </div>
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <FormControl style={{ minWidth: width }} fullWidth={fullWidth} error={error} >
      <InputLabel
        id='demo-controlled-open-select-label'
        className={classes.inputLabelSelect}
        disabled={disabled}
        error={error}
      >
        {label}
      </InputLabel>
      <Controller
        name={id}
        rules={rules}
        defaultValue={item}
        render={({ onChange }) =>
          isMultiple ? (
            <MultipleSelect onChange={onChange} />
          ) : (
            <SingleSelect onChange={onChange} />
          )
        }
        control={control}
      />
      {helperText && (
        <FormHelperText error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default SelectMUI;
