import React, { useState } from "react";
import {
  Grid,
  TextField,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Elements } from "../../../../../../blocks";

function CouponStacking({coupon, control }) {
  const [showOptions, setShowOptions] = useState(coupon.isStackable);

  return (
    <div>
      <div style={{ display: "flex", alignItems:"center"}}>
        <Controller
          control={control}
          name="discountRestrictions.couponStacking.enabled"
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "2rem"}}>
                <Elements.Switch
                  ref={ref}
                  checked={value}
                  handleSwitchChange={(isChecked) => {
                    setShowOptions(isChecked);
                    onChange(isChecked);
                  }}
                />
              </div>
              <div>
                <h3>Coupon Stacking</h3>
              </div>
            </div>
          )}
        />
      </div>
      {showOptions && (
        <h3>Coupon stacking is enabled</h3>
        // <div style={{ display: "flex" }}>
        //   <div className="row-1">
        //     <Controller
        //       control={control}
        //       name="discountRestrictions.couponStacking.options.allowCouponsToBeCombined"
        //       render={(
        //         { onChange, onBlur, value, name, ref },
        //         { invalid, isTouched, isDirty }
        //       ) => (
        //         <FormControlLabel
        //           control={
        //             <Checkbox
        //               inputRef={ref}
        //               onChange={(e) => onChange(e.target.checked)}
        //               value={value}
        //             />
        //           }
        //           label="Allow coupons to be combined"
        //         />
        //       )}
        //     />
        //     <br/>
        //     <Controller
        //       control={control}
        //       name="discountRestrictions.couponStacking.options.excludeSaleItems"
        //       render={(
        //         { onChange, onBlur, value, name, ref },
        //         { invalid, isTouched, isDirty }
        //       ) => (
        //         <FormControlLabel
        //           control={
        //             <Checkbox
        //               inputRef={ref}
        //               onChange={(e) => onChange(e.target.checked)}
        //               value={value}
        //             />
        //           }
        //           label="Exclude Sale Items"
        //         />
        //       )}
        //     />
        //   </div>
        // </div>
      )}
    </div>
  );
}

export default CouponStacking;
