import { gql } from '@apollo/client';

export const CREATE_COUPON = gql`
  mutation createCoupon($input: CreateCouponMutationInput!) {
    createCoupon(input: $input) {
      viewer {
        id
      }
      coupon {
        id
        couponId
        label
        archived
      }
    }
  }
`;
