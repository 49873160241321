import { gql } from '@apollo/client';

const REMOVE_TIMESHIFT = gql`
  mutation RemoveTimeshift($input: RemoveTimeshiftMutationInput!) {
    removeTimeshift(input: $input) {
      removedTimeshiftId
    }
  }
`;

export default REMOVE_TIMESHIFT;
