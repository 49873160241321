import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AutoCompleteMUI from '../../../../../../blocks/AutoComplete';
import { Permission, PermissionField } from '../../../../../../blocks';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import { useQuery } from '@apollo/client';
import WarningIcon from '@material-ui/icons/Warning';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Skeleton from '@material-ui/lab/Skeleton';
import { FETCH_SUBCATEGORIES } from '../../../../../../../apollo/queries/locations/subcategoryFetch';
import { DeleteOutlined } from '@material-ui/icons';
import { useParams } from 'react-router';

const LocationItemTableToolbar = ({
  refetch,
  variables,
  isBulkEdit,
  bulkEditPermission,
  setFilters,
  filters,
  menuId,
  control,
  classes,
  itemPermission,
}) => {
  const { locationId } = useParams();

  const {
    data: subcategoryData,
    loading: subcategoryLoading,
    error: subcategoryError,
    variables: subcategoryVariables,
    refetch: subcategoriesRefetch,
  } = useQuery(FETCH_SUBCATEGORIES, {
    variables: {
      menuFilter: menuId
        ? {
            menu: {
              menuId: {
                eq: menuId,
              },
            },
            locationId: {
              eq: locationId,
            },
            showUnavailableOnline: { eq: 1 },
          }
        : { showUnavailableOnline: { eq: 1 } },
      menuCategoryFilter: menuId
        ? {
            menuId: {
              eq: menuId,
            },
          }
        : undefined,
      locationId: {
        eq: locationId,
      },
      locationId,
    },
  });

  const subcategoryOptions = subcategoryData?.viewer?.subcategoryConnection?.edges.map(
    subcategory => ({
      label: subcategory?.node?.label,
      value: subcategory?.node?.subcategoryId,
    }),
  );

  const categoryOptions = subcategoryData?.viewer?.menuCategoryConnection?.edges.map(category => ({
    label: category?.node?.category?.label,
    value: category?.node?.category?.categoryId,
  }));

  const availableOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];
  const subcategoryFilter = filters?.subcategoryValue
    ? {
        label: filters?.subcategoryName,
        value: filters?.subcategoryValue,
      }
    : null;
  const categoryFilter = filters?.categoryValue
    ? {
        label: filters?.categoryName,
        value: filters?.categoryValue,
      }
    : null;
  const onlineStatusFilter = filters?.onlineStatusValue
    ? {
        label: filters?.onlineStatusName,
        value: filters?.onlineStatusValue,
      }
    : null;

  const onlineStatusChange = data => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      itemFilter: {
        ...variables.itemFilter,
        locationAvailable: {
          eq: data?.value ? data?.value === 'active' : null,
        },
      },
    }).then(r => r);
    setFilters(prevState => ({
      ...prevState,
      onlineStatusName: data?.label,
      onlineStatusValue: data?.value,
    }));
  };

  const onSubcategoryChange = data => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      itemFilter: {
        ...variables.itemFilter,
        subcategoryId: {
          eq: data?.value || null,
        },
      },
    }).then(r => r);
    setFilters(prevState => ({
      ...prevState,
      subcategoryName: data?.label,
      subcategoryValue: data?.value,
    }));
  };

  const onCategoryChange = data => {
    subcategoriesRefetch({
      ...subcategoryVariables,
      menuFilter: {
        ...subcategoryVariables.menuFilter,
        categoryId: {
          eq: data?.value || null,
        },
      },
    }).then(r => r);
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      itemFilter: {
        ...variables.itemFilter,
        categoryId: {
          eq: data?.value || null,
        },
      },
    }).then(r => r);
    setFilters(prevState => ({
      ...prevState,
      categoryName: data?.label,
      categoryValue: data?.value,
    }));
  };

  const search = e => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      after: null,
      last: null,
      before: null,
      search: e.target.value || null,
    });
  };

  if (subcategoryLoading)
    return (
      <div>
        <Grid container spacing={3} style={{ marginBottom: '1rem' }}>
          <Grid item xs={6}>
            <Skeleton variant="rect" width={'50%'} height={20} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="rect" width={'100%'} height={20} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="rect" width={'100%'} height={20} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="rect" width={'100%'} height={20} />
          </Grid>
        </Grid>
      </div>
    );
  if (subcategoryError) console.log('error');

  const permissions = {
    menuCategory: subcategoryData?.viewer?.menuCategoryConnection?.permissions,
    subcategory: subcategoryData?.viewer?.subcategoryConnection?.permissions,
    item: itemPermission,
  };

  return (
    <>
      <div className={classes.title}>Items</div>
      <div className={classes.infoContainer}>
        <div style={{ display: 'flex', alignItems: 'center', color: '#FF9902' }}>
          <div>
            <WarningIcon style={{ fontSize: '2rem', marginRight: '.5rem' }} />
          </div>
          <div>
            <p className={classes.noteText}>
              NOTE: After completing all of your Adjustments, you will need to publish the full menu
              for any applicable changes to be reflected on Chowly 3PV.
            </p>
            {isBulkEdit ? (
              <p className={classes.noteText}>
                NOTE: To reset POS, OLO AND Chowly 3PV price to MCPI value, click on bin icon (
                <DeleteOutlined color="inherit" />) in the price field.
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <Grid container spacing={4} style={{ marginBottom: '1.5rem' }}>
        <Permission
          access={isBulkEdit ? bulkEditPermission.access && 7 : permissions?.item?.label && 7}
        >
          <Grid item xs={6}>
            <PermissionField
              Component={TextField}
              label="Search"
              style={{ width: '50%' }}
              onChange={debounce(search, 500)}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/*<Button onClick={() => submitSearch()} style={{ marginLeft: "0.2rem" }}>Search</Button>*/}
          </Grid>
        </Permission>
        <Permission
          access={
            isBulkEdit
              ? bulkEditPermission.access && 7
              : permissions?.menuCategory?.category?.label && 7
          }
        >
          <Grid item xs={2}>
            <PermissionField
              Component={AutoCompleteMUI}
              id={'menuType'}
              getOptionLabel={option => option.label}
              options={categoryOptions}
              defaultValue={categoryFilter}
              style={{ width: '100%' }}
              onChange={(_, data) => {
                onCategoryChange(_);
              }}
              control={control}
              renderInput={params => (
                <Permission
                  access={
                    isBulkEdit
                      ? bulkEditPermission.access && 7
                      : permissions?.menuCategory?.category?.label && 7
                  }
                >
                  <PermissionField
                    Component={TextField}
                    {...params}
                    label={'Menu Type'}
                    variant="outlined"
                  />
                </Permission>
              )}
            />
          </Grid>
        </Permission>
        <Permission
          access={isBulkEdit ? bulkEditPermission.access : permissions?.subcategory?.label && 7}
        >
          <Grid item xs={2}>
            <PermissionField
              Component={AutoCompleteMUI}
              id={'subcategory'}
              getOptionLabel={option => option.label}
              options={subcategoryOptions}
              defaultValue={subcategoryFilter}
              style={{ width: '100%' }}
              onChange={(_, data) => onSubcategoryChange(_)}
              control={control}
              renderInput={params => (
                <Permission
                  access={
                    isBulkEdit
                      ? bulkEditPermission.access && 7
                      : permissions?.subcategory?.label && 7
                  }
                >
                  <PermissionField
                    Component={TextField}
                    {...params}
                    label={'Subcategory'}
                    variant="outlined"
                  />
                </Permission>
              )}
            />
          </Grid>
        </Permission>
        <Permission
          access={
            isBulkEdit ? bulkEditPermission.access && 7 : permissions?.item?.availableOnline && 7
          }
        >
          <Grid item xs={2}>
            <PermissionField
              Component={AutoCompleteMUI}
              id={'onlineStatus'}
              getOptionLabel={option => option.label}
              options={availableOptions}
              defaultValue={onlineStatusFilter}
              style={{ width: '100%' }}
              onChange={data => {
                onlineStatusChange(data);
              }}
              control={control}
              renderInput={params => (
                <Permission
                  access={
                    isBulkEdit
                      ? bulkEditPermission.access && 7
                      : permissions?.item?.availableOnline && 7
                  }
                >
                  <PermissionField
                    Component={TextField}
                    {...params}
                    label={'Online Status'}
                    variant="outlined"
                  />
                </Permission>
              )}
            />
          </Grid>
        </Permission>
      </Grid>
    </>
  );
};

export default LocationItemTableToolbar;
