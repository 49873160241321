import { gql } from "@apollo/client";

const UPDATE_TAX_GROUP = gql`
  mutation UpdateTaxGroup($input: UpdateTaxgroupMutationInput!) {
    updateTaxgroup(input: $input) {
      viewer {
        id
      }
      taxgroup {
        id
      }
    }
  }
`;

export default UPDATE_TAX_GROUP;
