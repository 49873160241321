import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  marginBottom: {
    marginBottom: `${theme.spacing(4)}px`,
  },
});

const ContentInfoStyles = makeStyles(Styles);

export default ContentInfoStyles;
