import React, { useEffect, useState } from "react";
import { Button, Chip, CircularProgress } from "@material-ui/core";
import { Modal } from "../../../../../../../../blocks";
import { Controller } from "react-hook-form";
import Add from "@material-ui/icons/Add";
import CategorySelect from "../../../../GeneralSettings/DiscountType/CategorySelect";
import { LIST_CATEGORY_SUBCATEGORY } from "../../../../../../../../../apollo/queries";
import { useLazyQuery } from "@apollo/client";
import { uglifyId } from "../../../../../../../../../utils";
function CategorySelectWrapper({
  control,
  cartItemsRequired,
  setCartItemsRequired,
  couponTriggers,
}) {
  const [open, setOpen] = useState(false);

  const [loadItems, { loading }] = useLazyQuery(LIST_CATEGORY_SUBCATEGORY, {
    onCompleted: (data) => {
      setCartItemsRequired(
        data.viewer.subcategoryConnection.edges.map((edge) => ({
          key: edge.node.id,
          id: edge.node.id,
          itemId: edge.node.subcategoryId,
          label: `${edge.node.label} - ID_${edge.node.subcategoryId}`,
          isItem: true,
        }))
      );
    },
    onError: (err) => {
      console.log(err, "err...");
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (couponTriggers.couponTriggerId === 5) {
      loadItems({
        variables: {
          filter: {
            id: {
              in: couponTriggers.values.map((itemNumId) =>
                uglifyId("Subcategory", itemNumId.valueOff)
              ),
            },
          },
        },
      });
    }
  }, []);

  if (loading) {
    return (
      <p>
        Loading items... <CircularProgress />
      </p>
    );
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (itemId) => {
    const newArr = cartItemsRequired.filter((item) => item.id !== itemId);
    setCartItemsRequired(newArr);
  };

  return (
    <div>
      <Controller
        control={control}
        name="triggerEvents.automatic.subcategoryItemInCart.categoryId"
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Subcategories</h3>
        <div>
          <Button onClick={setOpen.bind(this, true)} variant="outlined">
            <Add /> Add Subcategories
          </Button>
        </div>
      </div>
      <div>
        {cartItemsRequired.map((item) => {
          return (
            <Chip
              style={{ margin: "4px" }}
              key={item.id}
              label={item.label}
              onDelete={(e) => handleDelete(item.id)}
            />
          );
        })}
      </div>
      <Modal open={open} handleClose={handleClose} title="Add Subcategories">
        <CategorySelect
          selectedSubcategoriesAction={cartItemsRequired}
          setSelectedSubcategoriesAction={setCartItemsRequired}
          handleClose={handleClose}
        />
      </Modal>
    </div>
  );
}

export default CategorySelectWrapper;
