import { gql } from '@apollo/client';

const VENDOR_INVOICE_PRODUCTS = gql`
  query VENDOR_INVOICE_PRODUCTS($filter: [InoviceFilter!], $invoiceId: Int!) {
    viewer {
      id
      invoiceConnection(filter: $filter, fieldGroupOnly: 88) {
        edges {
          node {
            id

            location {
              storeNumber
              label
              address {
                state {
                  abbreviation
                }
              }
            }
            invoiceId
            isSimple
            created
            invoiceFileUrl
            invoiceDate
            dueDate
            invoiceId
            updatable
            invoiceNumber
            storeNumber
            supplierName
            subtotal
            tax
            total
            calculatedTotal
            isSubmitted
            invoiceproductConnection {
              edges {
                node {
                  id
                  invoiceId
                  productId
                  unitsOrdered
                  unitsShiped
                  quantityInvoiced
                  vendorUOM
                  packSize
                  netWeight
                  unitPrice
                  extendedPrice
                  label
                  description
                  vendorproduct {
                    id
                    vendorId
                    ingredientId
                    vendorMeasureId
                    inventoryConversion
                    vendorproductId
                    vendorProductNumber
                    ingredient {
                      id
                      measure {
                        label
                      }
                      inventoryitem {
                        label
                        inventoryitemId
                        description
                        cost
                      }
                      invoiceIngredient(invoiceId: $invoiceId) {
                        id
                        invoiceId
                        measureId
                        quantity
                        note
                        ingredientId
                        baseQuantity
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default VENDOR_INVOICE_PRODUCTS;
