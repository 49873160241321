export const formatDataForTable = data => {
  const result = {};
  const locationStorageColumns = data?.viewer?.locationStorageConnection?.edges?.map(edge => ({
    field: `uom.locationStorageId-${edge.node.locationStorageId}.quantity`,
    title: edge.node?.customLabel || edge.node?.storage?.label,
  }));

  if (data?.viewer?.locationConnection?.edges?.length) {
    data.viewer.locationConnection.edges[0].node.inventorycount?.inventorycountLocationStorageInventoryitemConnection?.edges?.forEach(
      inventoryItemEdge => {
        const id = `inventoryitemId-${inventoryItemEdge.node.locationStorageInventoryitem?.inventoryitemId}`;
        const measureId = `measureId-${inventoryItemEdge.node.measureId}`;
        const locationStorageId = `locationStorageId-${inventoryItemEdge.node.locationStorageInventoryitem?.locationStorage?.locationStorageId}`;

        const measure = {
          id: measureId,
          measureId: inventoryItemEdge.node.measureId,
          measureLabel: inventoryItemEdge.node.measure?.abbreviation,
          total: inventoryItemEdge.node.quantity || 0,
          totalCost: inventoryItemEdge.node.calculatedCost || 0,
          [locationStorageId]: {
            id: locationStorageId,
            label:
              inventoryItemEdge.node.locationStorageInventoryitem?.locationStorage?.customLabel,
            quantity: inventoryItemEdge.node.quantity,
          },
        };

        const uom = { [measureId]: measure };

        if (result[id]) {
          if (result[id].uom[measureId]) {
            if (result[id].uom[measureId])
              result[id].uom[measureId] = {
                ...result[id].uom[measureId],
                total: result[id].uom[measureId].total + measure.total,
                totalCost: result[id].uom[measureId].totalCost + measure.totalCost,
                [locationStorageId]: measure[locationStorageId],
              };
          } else if (result[id].uom) {
            result[id].uom = {
              ...result[id].uom,
              ...uom,
            };
          } else {
            result[id].uom = uom;
          }
        } else {
          result[id] = {
            id,
            label: inventoryItemEdge.node.locationStorageInventoryitem?.inventoryitem?.label,
            uom,
          };
        }
      },
    );
  }

  const tableData = [];

  Object.values(result).forEach(item =>
    tableData.push(
      ...Object.values(item.uom).map((uom, index) => ({
        ...item,
        rowSpan: index === 0 ? Object.values(item.uom).length : 0,
        uom,
      })),
    ),
  );

  return {
    tableData,
    locationStorageColumns,
  };
};

export const getLocationStorageInventoryitems = locationStorageInventoryItemConnection => {
  const result = {};

  locationStorageInventoryItemConnection.inventorycount?.inventorycountLocationStorageInventoryitemConnection?.edges.forEach(
    edge => {
      const id = `locationStorageInventoryitemId-${edge.node.locationStorageInventoryitemId}`;

      const locationStorageInventoryitem = {
        locationStorageInventoryitemId: edge.node.locationStorageInventoryitemId,
        measures: [
          {
            measureId: edge.node.measureId,
            quantity: edge.node.quantity,
          },
        ],
      };

      if (result[id]) {
        result[id] = {
          ...result[id],
          measures: [...result[id].measures, ...locationStorageInventoryitem?.measures],
        };
      } else {
        result[id] = locationStorageInventoryitem;
      }
    },
  );

  return Object.values(result);
};
