import { gql } from '@apollo/client';

const DELETE_USERGROUP_USER = gql`
  mutation deleteUsergroupUser($usergroupId: Int!, $loginId: Int!) {
    deleteUsergroupUser(input: { usergroupId: $usergroupId, loginId: $loginId }) {
      usergroupId
    }
  }
`;

export default DELETE_USERGROUP_USER;
