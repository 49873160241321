import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import TextField from "@material-ui/core/TextField";

function DisputeChargebackModalView({
  handleClose,
  handleResponseChange,
  handleSubmitToBank,
  uploadCommunicationForm,
  handleUploadCommunication,
  handleUploadReceipt,
  onUploadReceiptFormClick,
  uploadReceiptForm,
  onUploadCommunicationFormClick,
  onUploadUncategorizedFormClick,
  handleUploadUncategorized,
  uploadUncategorizedForm,
  disableButton,
  disputeReason,
  chargeback,
  createChargeBackLoading,
}) {
  return (
    <DialogContent>
      <Typography style={{ marginBottom: "1rem" }} gutterBottom>
        Chargeback reason: <strong>{disputeReason.toUpperCase()}</strong>
      </Typography>
      <Typography
        style={{
          color: "orange",
          fontWeight: "bold",
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
        }}
        gutterBottom
      >
        <div>
          <span>
            <ReportProblemOutlinedIcon
              style={{
                backgroundColor: "orange",
                borderRadius: "100%",
                color: "white",
                fontSize: "3rem",
                padding: "5px",
              }}
              fontSize="large"
            />
          </span>
        </div>
        <div>
          NOTE: To improve your chance of winning this chargeback, you need to
          provide to the customer's credit card company that your products or
          service where successfully delivered. It can be helpfull to inlcude
          online tracking or delivery confirmations, customer service emails, or
          proof of a prior refunds.
        </div>
      </Typography>
      <div>
        <TextField
          id="outlined-multiline-static"
          label="Response"
          multiline
          rows={5}
          fullWidth
          placeholder="Response message"
          variant="outlined"
          onChange={handleResponseChange}
        />
      </div>
      <hr />
      <div>
        <h2>Receipt</h2>
        <div
          style={{
            padding: "5px",
            border: "2px solid lightgray",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={onUploadReceiptFormClick}
            style={{ marginRight: "1rem", borderRadius: "5px" }}
          >
            {!chargeback.receipt && "Upload file"}
            {chargeback.receipt && "Change file"}
          </Button>
          <div>
            <Tooltip
              title="Supported types: PDF, XML, JPG, PNG, CSV"
              aria-label="add"
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <input
            ref={uploadReceiptForm}
            type="file"
            style={{ display: "none" }}
            accept=".pdf,.xml,.jpg,.png,.csv,.tsv"
            onChange={handleUploadReceipt}
          />
          {chargeback.receipt && <span>{chargeback.receipt.name}</span>}
          {!chargeback.receipt && <span>No file selected.</span>}
        </div>
      </div>
      <div>
        <h2>Communication</h2>
        <p>
          Include here any communication with the customer that you feel is
          relevant to your case, such as emails proving that they revceived or
          used the product or service.
        </p>
        <div
          style={{
            padding: "5px",
            border: "2px solid lightgray",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={onUploadCommunicationFormClick}
            style={{ marginRight: "1rem", borderRadius: "5px" }}
          >
            {!chargeback.communication && "Upload file"}
            {chargeback.communication && "Change file"}
          </Button>
          <div>
            <Tooltip
              title="Supported types: PDF, XML, JPG, PNG, CSV"
              aria-label="add"
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <input
            ref={uploadCommunicationForm}
            type="file"
            style={{ display: "none" }}
            accept=".pdf,.xml,.jpg,.png,.csv,.tsv"
            onChange={handleUploadCommunication}
          />
          {chargeback.communication && (
            <span>{chargeback.communication.name}</span>
          )}
          {!chargeback.communication && <span>No file selected.</span>}
        </div>
      </div>
      <div>
        <h2>Other Documentation</h2>
        <p>
          Include here any extra evidence or statements you'd like the bank to
          see.
        </p>
        <div
          style={{
            padding: "5px",
            border: "2px solid lightgray",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={onUploadUncategorizedFormClick}
            style={{ marginRight: "1rem", borderRadius: "5px" }}
          >
            {!chargeback.uncategorized && "Upload file"}
            {chargeback.uncategorized && "Change file"}
          </Button>
          <div>
            <Tooltip
              title="Supported types: PDF, XML, JPG, PNG, CSV"
              aria-label="add"
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <input
            ref={uploadUncategorizedForm}
            type="file"
            style={{ display: "none" }}
            accept=".pdf,.xml,.jpg,.png,.csv,.tsv"
            onChange={handleUploadUncategorized}
          />
          {chargeback.uncategorized && (
            <span>{chargeback.uncategorized.name}</span>
          )}
          {!chargeback.uncategorized && <span>No file selected.</span>}
        </div>
      </div>
      <hr />
      <div style={{ textAlign: "right" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={disableButton}
          style={{ margin: "0 10px" }}
          onClick={handleSubmitToBank.bind(this, false)}
        >
          Save for later
        </Button>
        <Button
          onClick={handleSubmitToBank.bind(this, true)}
          variant="contained"
          disabled={createChargeBackLoading || disableButton}
          style={{ color: "white", background: "red" }}
        >
          SUBMIT DIRECTLY TO BANK
        </Button>
      </div>
    </DialogContent>
  );
}

export default DisputeChargebackModalView;
