import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  heading: {
    margin: theme.headingMargins,
  },

  marginLeft: {
    marginLeft: theme.spacing(3),
  },
});

const LoyaltyGeneralInfoStyles = makeStyles(Styles);

export default LoyaltyGeneralInfoStyles;