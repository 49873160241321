import { gql } from '@apollo/client';

const SAVE_CLOSING_INVENTORY = gql`
  mutation saveClosingInventory($input: SaveClosingInventoryMutationInput!) {
    saveClosingInventory(input: $input) {
      clientMutationId
    }
  }
`;

export default SAVE_CLOSING_INVENTORY;
