import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { clone } from 'lodash';
import DateTime from 'luxon/src/datetime';
import { CircularProgress } from '@material-ui/core';

import SalesProjectionsView from './View';
import {
  CREATE_WEEKLY_SALE_COMMITMENT,
  UPDATE_WEEKLY_SALE_COMMITMENT,
} from '../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

import { convert } from '../../../../../utils';

const SalesProjections = ({
  fetchSchedule,
  selectedLocationState,
  selectedWeek,
  smokestackData,
  smokestackWeekTotal,
  smokestackDataLoading,
  weeklyScheduleProjections,
  fetchWeeklyScheduleProjectionsVariables,
  fetchWeeklyScheduleProjections,
  accessToMutations,
  mutationPermissions,
  sortedWeekdays,
  projectionInputs,
  setProjectionInputs,
  weeklyScheduleProjectionsLoading,
}) => {
  const { setSnackbar } = useSnackbar();

  const formattedCurrency = convert.formattedCurrency;

  const [adjustedWeeklyTotal, setAdjustedWeeklyTotal] = useState(0);

  const [
    insertWeeklySaleProjections,
    { loading: insertWeeklySaleProjectionsLoading },
  ] = useMutation(CREATE_WEEKLY_SALE_COMMITMENT, {
    onCompleted: data => {
      const weeklyScheduleId =
        data?.createScheduleweeklysalesprojection?.viewer?.scheduleweeklysalesprojectionConnection
          ?.edges[0]?.node?.scheduleweeklysalesprojectionId;
      const locationId =
        data?.createScheduleweeklysalesprojection.viewer?.scheduleweeklysalesprojectionConnection
          ?.edges[0]?.node?.locationId;
      fetchSchedule({
        variables: {
          scheduleFilter: {
            scheduleweeklysalesprojectionId: { eq: weeklyScheduleId },
            locationId: { eq: selectedLocationState?.locationId },
          },
          personJobFilter: {
            locationId: { eq: locationId },
          },
        },
      });
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Weekly sale projection created successfully',
      });
      fetchWeeklyScheduleProjections({ variables: { ...fetchWeeklyScheduleProjectionsVariables } });
    },
    onError: data => {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Error creating weekly sale projection',
      });
    },
  });
  const [
    updateWeeklySaleProjections,
    { loading: updateWeeklySaleProjectionsLoading },
  ] = useMutation(UPDATE_WEEKLY_SALE_COMMITMENT, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Weekly sale projection updated successfully',
      });
      fetchWeeklyScheduleProjections({ variables: { ...fetchWeeklyScheduleProjectionsVariables } });
    },
    onError: data => {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Error updating weekly sale projection',
      });
    },
  });

  const weeklySaleProjectionsData = useMemo(
    () => weeklyScheduleProjections?.viewer.scheduleweeklysalesprojectionConnection.edges[0]?.node,
    [weeklyScheduleProjections],
  );

  const { handleSubmit, register, control, getValues, setValue, errors } = useForm();

  useEffect(() => {
    setProjectionInputs(Object.values(getValues()));
  }, [getValues, weeklyScheduleProjectionsLoading, smokestackDataLoading, selectedWeek]);

  const calculateAdjustedWeeklyTotal = useCallback(() => {
    const weekDayProjections = getValues();
    if (weekDayProjections) {
      let total = 0;
      Object.keys(weekDayProjections).forEach(key => {
        const intValue =
          !weekDayProjections[key] || typeof parseFloat(weekDayProjections[key]) !== 'number'
            ? 0
            : parseFloat(weekDayProjections[key]);
        total += intValue * 100;
      });
      return total / 100;
    }
  }, [getValues, projectionInputs]);

  const onInputChange = useCallback(
    event => {
      let eventValue = event.target.value;
      // Remove non number
      if (isNaN(eventValue)) {
        event.target.value = eventValue.replace(/\D/g, '');
      }
      if (`${eventValue}`[0] === '0') {
        event.target.value = parseFloat(`${eventValue}`.substring(0));
      }

      setAdjustedWeeklyTotal(calculateAdjustedWeeklyTotal());
      setProjectionInputs(Object.values(getValues()));
    },
    [getValues, calculateAdjustedWeeklyTotal],
  );

  useEffect(() => {
    setAdjustedWeeklyTotal(calculateAdjustedWeeklyTotal());
  }, [smokestackData, calculateAdjustedWeeklyTotal, setValue]);

  const onSubmit = useCallback(
    event => {
      const weekdayAmounts = [];
      for (const [key, value] of Object.entries(event)) {
        weekdayAmounts.push({ weekdayId: Number(key), amount: Math.trunc(Number(value) * 100) });
      }

      //Update
      if (weeklySaleProjectionsData) {
        updateWeeklySaleProjections({
          variables: {
            input: {
              scheduleweeklysalesprojectionId: weeklySaleProjectionsData.id,
              amounts: weekdayAmounts,
            },
          },
        });
      } else {
        //Insert
        insertWeeklySaleProjections({
          variables: {
            ...fetchWeeklyScheduleProjectionsVariables,
            input: {
              locationId: selectedLocationState.id,
              started: selectedWeek?.toISODate().toString(),
              finished: selectedWeek?.plus({ days: 6 }).toISODate(),
              amounts: weekdayAmounts,
            },
          },
        });
      }
    },
    [
      weeklySaleProjectionsData,
      fetchWeeklyScheduleProjectionsVariables,
      selectedLocationState,
      selectedWeek,
      updateWeeklySaleProjections,
      insertWeeklySaleProjections,
    ],
  );

  // Prettify commitments and sales trends
  const totalWeekSalesTrend = useMemo(() => {
    return smokestackData?.viewer?.locationConnection?.edges[0]?.node?.averageFourWeekSales.reduce(
      (prev, curr) => {
        if (prev?.salesAmount) {
          return prev.salesAmount + curr.salesAmount;
        }
        return prev + curr.salesAmount;
      },
      0,
    );
  }, [smokestackData]);

  const {
    dailySalesCommitments,
    auvSalesTrendWeights,
    dailySalesTrends,
    totalAuvPercent,
  } = useMemo(() => {
    const zeroDollars = formattedCurrency(0);
    const dailySalesCommitmentsTemp = {};
    const auvSalesTrendWeightsTemp = {};

    sortedWeekdays.forEach(weekday => {
      dailySalesCommitmentsTemp[weekday] = zeroDollars;
      auvSalesTrendWeightsTemp[weekday] = 0;
    });
    const dailySalesTrendsTemp = clone(dailySalesCommitmentsTemp, true);

    // Daily commitments
    smokestackData?.viewer?.auvCommitmentsEdwConnection?.edges?.map(edge => {
      const day = moment(edge?.node?.businessDt).format('dddd');
      dailySalesCommitmentsTemp[day] = formattedCurrency(edge?.node?.salesAmt * 100);
    });

    // AUV sales trend
    smokestackData?.viewer?.locationConnection?.edges[0]?.node?.averageFourWeekSales?.map(
      daySales => {
        const day = moment(daySales?.date).format('dddd');
        dailySalesTrendsTemp[day] = formattedCurrency(daySales?.salesAmount * 100);
        auvSalesTrendWeightsTemp[day] =
          daySales?.salesAmount === 0
            ? 0
            : Math.round((daySales?.salesAmount / totalWeekSalesTrend) * 100);
      },
    );

    let totalAuvPercentTemp = 0;
    const auvObjectValues = Object.values(auvSalesTrendWeightsTemp);
    if (Math.max(...auvObjectValues) > 0) {
      // Level out percentages so it equals 100 by modifying the highest percentage with the delta to 100%
      const delta = 100 - auvObjectValues?.reduce((acc, x) => acc + x);
      const indexOfMax = auvObjectValues.indexOf(Math.max(...auvObjectValues));
      auvSalesTrendWeightsTemp[sortedWeekdays[indexOfMax]] = Math.round(
        auvSalesTrendWeightsTemp[sortedWeekdays[indexOfMax]] + delta,
      );
      totalAuvPercentTemp = Object.values(auvSalesTrendWeightsTemp)?.reduce(
        (prev, curr) => prev + curr,
      );
    }

    return {
      dailySalesCommitments: dailySalesCommitmentsTemp,
      auvSalesTrendWeights: auvSalesTrendWeightsTemp,
      dailySalesTrends: dailySalesTrendsTemp,
      totalAuvPercent: totalAuvPercentTemp,
    };
  }, [sortedWeekdays, smokestackData]);

  const userSetProjectionValues = useMemo(() => {
    const tempObject = {};

    if (weeklySaleProjectionsData) {
      weeklySaleProjectionsData?.scheduleweeklysalesprojectionWeekday?.map((weekday, index) => {
        tempObject[sortedWeekdays[index]] = formattedCurrency(weekday.amount);
      });
    }

    return tempObject;
  }, [weeklySaleProjectionsData]);

  const submitProjectionIsDisabled = useMemo(() => {
    if (DateTime.local().startOf('day') > selectedWeek.plus({ days: 7 }).startOf('day')) {
      return true;
    }
    return false;
  }, [selectedWeek]);

  return smokestackDataLoading || weeklyScheduleProjectionsLoading ? (
    <CircularProgress />
  ) : (
    <SalesProjectionsView
      mutationPermissions={mutationPermissions}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      weeklyScheduleProjections={weeklyScheduleProjections}
      dailySalesCommitments={dailySalesCommitments}
      smokestackWeekTotal={smokestackWeekTotal}
      dailySalesTrends={dailySalesTrends}
      totalWeekSalesTrend={totalWeekSalesTrend}
      auvSalesTrendWeights={auvSalesTrendWeights}
      totalAuvPercent={totalAuvPercent}
      sortedWeekdays={sortedWeekdays}
      onInputChange={onInputChange}
      weeklySaleProjectionsData={weeklySaleProjectionsData}
      userSetProjectionValues={userSetProjectionValues}
      register={register}
      adjustedWeeklyTotal={adjustedWeeklyTotal}
      submitProjectionIsDisabled={submitProjectionIsDisabled}
      formattedCurrency={formattedCurrency}
      accessToMutations={accessToMutations}
      insertWeeklySaleProjectionsLoading={insertWeeklySaleProjectionsLoading}
      updateWeeklySaleProjectionsLoading={updateWeeklySaleProjectionsLoading}
    />
  );
};
export default SalesProjections;
