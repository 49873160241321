import { gql } from "@apollo/client";

const SEND_EMAILS = gql`
  mutation sendEmails($input:  SendEmailMutationInput!) {
    sendEmails(input: $input) {
      viewer {
        id
      }
    }
  }
`;
export default SEND_EMAILS;
