import { gql } from '@apollo/client';
const FETCH_INVENTORY_ITEMS = gql`
  query FETCH_INVENTORY_ITEMS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [InventoryitemSort]
    $filter: [InventoryitemFilter]
  ) {
    viewer {
      id
      inventoryitemConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
      ) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            inventoryitemId
            measureId
            label
            id
            inventoryitemId
            measureId
            label
            measure {
              measureId
              abbreviation
              label
            }
            type
            recipe {
              recipetype {
                recipetypeId
                label
              }
            }
            inventoryitemMeasureConnection {
              edges {
                node {
                  measure {
                    measureId
                    abbreviation
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_INVENTORY_ITEMS;
