import { gql } from "@apollo/client";

export const SUBCATEGORY_ITEM_LIST = gql`
  query SubcategoryItem($filter: [SubcategoryItemFilter]!){
    viewer{
      id
      subcategoryItemConnection(filter: $filter) {
         edges {
           node {
             id
             subcategoryId
             item {
               id
               itemId
               label
               }
             }
           }
         }
       }
  }
`;