import React, { useState } from 'react';
import AddIcon from '@material-ui/core/SvgIcon/SvgIcon';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ConnectionTable } from '../../../../../../../blocks';
import FETCH_MENU_MULTIPLE from '../../../../../../../../apollo/queries/menus/fetchMenuMultiples';
import { ADD_ITEM_MULTIPLE } from '../../../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { Grid, Button } from '@material-ui/core';

const AddMultipleItem = ({
  handleClose,
  itemId,
  existingMultipleIds,
  refetch,
  numberOfMultiples,
  variables,
}) => {
  AddMultipleItem.propTypes = {
    handleClose: PropTypes.func,
    itemId: PropTypes.string,
  };
  const { setSnackbar } = useSnackbar();
  const [multipleIdArray, setMultipleIdArray] = useState([]);
  const [addItemMultiple] = useMutation(ADD_ITEM_MULTIPLE);

  const onSubmit = () => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Choice is being added!',
    });
    addItemMultiple({
      variables: {
        input: {
          itemId,
          multipleIdArray: multipleIdArray.map((state, index) => ({
            id: state.id,
            order: numberOfMultiples + index,
          })),
        },
      },
    }).then(() => {
      refetch({
        ...variables,
      }).then(() => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Choice is added!',
        });
        handleClose();
      });
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ConnectionTable
          query={FETCH_MENU_MULTIPLE}
          customActionsTop={() => <AddIcon />}
          nested
          fetchPolicy={'network-only'}
          selectableRows={true}
          initialQueryVariables={{
            first: 15,
            fieldGroupOnly: 7,
            filter: [
              {
                multipleId: {
                  nin: existingMultipleIds,
                },
              },
            ],
          }}
          structureTable={data => {
            return data?.viewer?.multipleConnection?.edges.map(multiple => ({
              id: multiple?.node?.id,
              name: multiple?.node?.label,
              description: multiple?.node?.multipleChoiceConnection.edges
                .map(multipleChoice => multipleChoice?.node?.choice?.label)
                .join(',  '),
            }));
          }}
          // filterByDate={'quote.expected'}
          // hideTotalCount={true}
          customActionsCenter={(data, query, selected) => {
            setMultipleIdArray(selected);
          }}
          columns={[
            { title: 'Name', field: 'name' },
            {
              title: 'Description',
              field: 'description',
            },
          ]}
        />
      </Grid>

      <Grid item xs={12} container spacing={3} justify="flex-end">
        <Grid item>
          <Button variant="outlined" style={{ marginRight: '1rem' }} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddMultipleItem;
