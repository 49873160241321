import { gql } from '@apollo/client';

const FETCH_INVOICE_PRODUCTS = gql`
  query FETCH_INVOICE_PRODUCTS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [InvoiceIngredientFilter]
    $sort: [InvoiceIngredientSort]
  ) {
    viewer {
      id
      invoiceIngredientConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        fieldGroupOnly: 95
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            quantity
            cost
            measure {
              label
              abbreviation
            }
            invoiceproduct {
              unitsOrdered
              productId
            }
            ingredient {
              id
              ingredientId
              inventoryitem {
                id
                label
                inventoryitemcategory {
                  inventoryitemcategoryId
                  inventoryitemcategorytypeId
                  reportcategoryId
                  generalledgeraccountId
                  generalledgeraccount {
                    label
                  }
                  reportcategory {
                    label
                  }
                  inventoryitemcategorytype {
                    label
                  }
                  label
                }
              }
            }
            invoice {
              invoiceNumber
              invoiceId
              isSimple
              storeNumber
              supplierName
              isSubmitted
              location {
                id
                locationId
                storeNumber
                address {
                  state {
                    abbreviation
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_INVOICE_PRODUCTS;
