import { gql } from '@apollo/client';

export const loyaltysettingsFragment = {
  loyaltysettings: gql`
    fragment LoyaltysettingsData on Loyaltysettings {
      id
      loyaltysettingId
      label
      multiplier
      startDate
      endDate
      startTime
      endTime
      timezoneId
      active
      brands {
        id
        label
      }
      origins {
        id
        label
      }
      weekdayConnection {
        edges {
          node {
            id
            weekday {
              id
              weekdayId
              abbreviation1
            }
          }
        }
      }
      loyaltysettingsCouponactionConnection {
        edges {
          node {
            couponaction {
              id
              couponactionId
              label
            }
          }
        }
      }
      loyaltysettingsCoupontriggerConnection {
        edges {
          node {
            coupontrigger {
              id
              couponTriggerId
              label
            }
          }
        }
      }
      loyaltysettingsItemConnection {
        edges {
          node {
            item {
              id
              itemId
              label
            }
            isIncluded
          }
        }
      }
    }
  `,
};
