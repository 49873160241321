import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FETCH_REPORT_CATEGORY } from '../../../../../../apollo/queries';
import { renderStatus, statusOptions } from '../../../../../../constants/statuses';
import { Modal } from '../../../../../blocks';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import ReportingCategoryModal from './ReportingCategoryModal';

const ReportingCategories = () => {
  const [isReportingCategoryModalOpen, setIsReportingCategoryModalOpen] = useState(false);
  const [reportingCategory, setReportingCategory] = useState();

  const handleOnRowClick = useCallback(({ data }) => {
    setReportingCategory(data);
    setIsReportingCategoryModalOpen(true);
  }, []);

  const handleCloseReportingCategoryModal = useCallback(() => {
    setReportingCategory();
    setIsReportingCategoryModalOpen(false);
  }, []);

  const handleOnAddReportingCategoryClick = useCallback(() => {
    setIsReportingCategoryModalOpen(true);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.active) {
        delete filters.active;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          active: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        title="Reporting Categories"
        customActionsTop={() => (
          <Button onClick={handleOnAddReportingCategoryClick} fullWidth>
            Add New Reporting Category
          </Button>
        )}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        columns={[
          { field: 'reportcategoryId', title: 'ID' },
          {
            field: 'label',
            title: 'Name',
          },
          {
            field: 'description',
            title: 'Description',
          },
          {
            field: 'status',
            title: 'Status',
            render: renderStatus,
            enablePropagation: true,
            disableSort: true,
          },
          { field: 'updated', customSortField: 'created', title: 'Updated' },
        ]}
        query={FETCH_REPORT_CATEGORY}
        initialQueryVariables={{
          fieldGroupOnly: 94,
        }}
        structureTable={data =>
          data?.viewer?.reportcategoryConnection?.edges?.map(edge => ({
            label: edge.node.label,
            reportcategoryId: edge.node.reportcategoryId,
            description: edge.node.description,
            status: edge.node.active,
            updated: moment(edge.node.created).format('MM/DD/YYYY'),
            data: edge.node,
          })) || []
        }
        onTableRowClick={handleOnRowClick}
      />
      <Modal
        open={isReportingCategoryModalOpen}
        handleClose={handleCloseReportingCategoryModal}
        title={
          reportingCategory?.reportcategoryId ? 'Edit Reporting Category' : 'Add Reporting Category'
        }
      >
        <ReportingCategoryModal
          reportingCategory={reportingCategory}
          handleClose={handleCloseReportingCategoryModal}
        />
      </Modal>
    </>
  );
};

export default ReportingCategories;
