import React, { Fragment, useMemo } from 'react';

import { Button, Grid, Link, Slider, TextField, Typography } from '@material-ui/core';
import { Elements, Permission, PermissionField } from '../../../../..//blocks';
import { useForm } from 'react-hook-form';

import { Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { convert, uglifyId } from '../../../../../../utils';
import Statuses from './Statuses';
import { MUTATION_NAME } from '../../../../../../constants';

const CategoryHandoffCardForm = ({
  locationHandoffCategory,
  handoff,
  category,
  isBulkEdit,
  propsControl,
  propsHandleSubmit,
  propsRegister,
  propsGetValues,
  propsErrors,
  deliveryId,
  handoffId,
  location,
  setValue,
  categoryStatuses,
  statuses,
  disabled,
  toggleLocationHandoffCategory,
  classes,
  locationId,
  onSubmit,
  sliderClasses,
  permissions,
  bulkEditPermission,
  handleCloseModal,
  defaultStatus,
  updateLocationHandoffCategoryLoading,
  toggleLocationHandoffCategoryLoading,
  accessToMutations,
  toggleLocationHandoffCategoryPermission,
  updateLocationHandoffCategoryPermission,
}) => {
  const findlocationHandoff = locationHandoffCategory.edges.find(
    locationHandoffEdge =>
      locationHandoffEdge?.node?.handoff &&
      locationHandoffEdge?.node?.handoff?.id === handoff.id &&
      locationHandoffEdge?.node?.category &&
      locationHandoffEdge?.node?.category?.id === category?.node?.id,
  );
  const {
    handleSubmit: formHandleSubmit,
    register: formRegister,
    control: formControl,
    getValues: formGetValues,
    errors: formErrors,
  } = useForm();

  const { handleSubmit, register, control, getValues, errors } = useMemo(
    () =>
      isBulkEdit
        ? {
            control: propsControl,
            handleSubmit: propsHandleSubmit,
            register: propsRegister,
            getValues: propsGetValues,
            errors: propsErrors,
          }
        : {
            control: formControl,
            handleSubmit: formHandleSubmit,
            register: formRegister,
            getValues: formGetValues,
            errors: formErrors,
          },
    [
      propsControl,
      propsHandleSubmit,
      propsRegister,
      propsGetValues,
      propsErrors,
      formControl,
      formHandleSubmit,
      formRegister,
      formGetValues,
      formErrors,
      isBulkEdit,
    ],
  );

  const FormComponent = isBulkEdit ? Fragment : 'form';

  const check =
    (!!findlocationHandoff && findlocationHandoff.node?.active) ||
    categoryStatuses[category?.node?.id];


  if (check) {
    return (
      <div key={category.id}>
        <Typography gutterBottom variant="h4" component="h2">
          {category.node.label}
        </Typography>
        <Permission
          access={
            isBulkEdit
              ? Math.min(
                  bulkEditPermission?.access,
                  toggleLocationHandoffCategoryPermission?.access,
                )
              : Math.min(
                  permissions?.locationHandoffCategory?.handoff?.__typename,
                  permissions?.locationHandoffCategory?.category?.__typename,
                  accessToMutations?.[MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput],
                  toggleLocationHandoffCategoryPermission?.access,
                )
          }
        >
          {statuses ? (
            <PermissionField
              Component={Statuses}
              access={
                isBulkEdit
                  ? Math.min(
                      bulkEditPermission?.access,
                      toggleLocationHandoffCategoryPermission?.access,
                    )
                  : Math.min(
                      permissions?.locationHandoffCategory?.handoff?.__typename,
                      permissions?.locationHandoffCategory?.category?.__typename,
                      accessToMutations?.[MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput],
                      toggleLocationHandoffCategoryPermission?.access,
                    )
              }
              control={control}
              statuses={statuses}
              disabled={disabled}
              handoff={handoff}
              category={category}
              locationId={locationId}
              defaultStatus={
                categoryStatuses[category.node.id] !== undefined
                  ? categoryStatuses[category.node.id]
                  : defaultStatus
              }
              toggleLocationHandoffCategory={toggleLocationHandoffCategory}
            />
          ) : (
            <div style={{ width: '100%' }}>
              <PermissionField
                Component={FormControlLabel}
                createHelperText
                className={classes.editSwitch}
                value={category?.node?.id}
                checked={findlocationHandoff?.node?.active}
                control={
                  <Permission
                    access={Math.min(
                      permissions?.locationHandoffCategory?.handoff?.__typename,
                      permissions?.locationHandoffCategory?.category?.__typename,
                      accessToMutations?.[MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput],
                      toggleLocationHandoffCategoryPermission?.access,
                    )}
                  >
                    <PermissionField
                      Component={Elements.Switch}
                      checked={findlocationHandoff?.node?.active}
                      handleSwitchChange={event => {
                        toggleLocationHandoffCategory({
                          variables: {
                            input: {
                              handoffId: findlocationHandoff?.node?.handoff?.id || handoff?.id,
                              categoryId: category?.node?.id,
                              locationIds: [{ id: findlocationHandoff?.node?.location?.id }],
                              toggle: event,
                            },
                          },
                        });
                      }}
                    />
                  </Permission>
                }
                label={`Allow ${handoff.label} for ${category?.node?.label} orders`}
                labelPlacement="start"
              />
            </div>
          )}
        </Permission>
        <FormComponent onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} key={category.node.id}>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.minOrderAmount
              }
            >
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={`${category?.node?.id}-minOrderAmount`}
                  inputRef={register({
                    transformValue: value => parseFloat(value),
                  })}
                  isFloat
                  type="number"
                  register={register}
                  inputProps={{
                    step: 0.01,
                  }}
                  className={classes.editTextfield}
                  label="Minimum order amount $"
                  defaultValue={
                    isBulkEdit
                      ? undefined
                      : convert.centsToDollars(findlocationHandoff?.node?.minOrderAmount || 0)
                  }
                  variant="outlined"
                />
              </Grid>
            </Permission>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.maxOrderAmount
              }
            >
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={`${category?.node?.id}-maxOrderAmount`}
                  isFloat
                  type="number"
                  register={register}
                  inputProps={{
                    step: 0.01,
                  }}
                  inputRef={register({
                    validate: value => {
                      if (!value) {
                        return value !== 0;
                      }

                      const minOrderAmount = Number(
                        getValues(`${category?.node?.id}-minOrderAmount`),
                      );
                      return !(minOrderAmount && minOrderAmount > value);
                    },
                  })}
                  error={errors[`${category?.node?.id}-maxOrderAmount`]}
                  helperText={
                    errors[`${category?.node?.id}-maxOrderAmount`] &&
                    'Max order amount is not valid'
                  }
                  className={classes.editTextfield}
                  label="Max Order Amount $"
                  defaultValue={
                    isBulkEdit
                      ? undefined
                      : findlocationHandoff?.node?.maxOrderAmount &&
                        convert.centsToDollars(findlocationHandoff?.node?.maxOrderAmount)
                  }
                  variant="outlined"
                />
              </Grid>
            </Permission>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.minOrderQuantity
              }
            >
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={`${category?.node?.id}-minOrderQuantity`}
                  inputRef={register}
                  type="number"
                  register={register}
                  className={classes.editTextfield}
                  label="Minimum number of item"
                  defaultValue={
                    isBulkEdit ? undefined : findlocationHandoff?.node?.minOrderQuantity || 0
                  }
                  variant="outlined"
                />
              </Grid>
            </Permission>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.maxOrderQuantity
              }
            >
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={`${category?.node?.id}-maxOrderQuantity`}
                  className={classes.editTextfield}
                  label="Max Number of Items"
                  register={register}
                  type="number"
                  inputRef={register({
                    validate: value => {
                      if (!value) {
                        return value !== 0;
                      }

                      const minOrderQuantity = Number(
                        getValues(`${category?.node?.id}-minOrderQuantity`),
                      );
                      return !(minOrderQuantity && minOrderQuantity > value);
                    },
                  })}
                  error={errors[`${category?.node?.id}-maxOrderQuantity`]}
                  helperText={
                    errors[`${category?.node?.id}-maxOrderQuantity`] &&
                    'Max number of items is not valid'
                  }
                  defaultValue={
                    isBulkEdit ? undefined : findlocationHandoff?.node?.maxOrderQuantity
                  }
                  variant="outlined"
                />
              </Grid>
            </Permission>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.additionalCharge
              }
            >
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={`${category?.node?.id}-additionalCharge`}
                  inputRef={register({
                    required: true,
                  })}
                  error={errors[`${category?.node?.id}-additionalCharge`]}
                  helperText={
                    errors[`${category?.node?.id}-additionalCharge`] && 'Service fee is required'
                  }
                  className={classes.editTextfield}
                  label="Service fee $"
                  defaultValue={
                    isBulkEdit
                      ? undefined
                      : convert.centsToDollars(findlocationHandoff?.node?.additionalCharge || 0)
                  }
                  variant="outlined"
                />
              </Grid>
            </Permission>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.additionalChargeTaxable
              }
            >
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Elements.Switch
                      name={`${category?.node?.id}-additionalChargeTaxable`}
                      ref={register}
                      checked={findlocationHandoff?.node?.additionalChargeTaxable}
                    />
                  }
                  label="Tax service fee"
                  labelPlacement="start"
                  className={[classes.switchWrapEditSwitch]}
                />
              </Grid>
            </Permission>
            <Grid item xs={6}>
              <Permission
                access={
                  isBulkEdit
                    ? bulkEditPermission?.access
                    : permissions?.locationHandoffCategory?.discountAmount
                }
              >
                <TextField
                  name={`${category?.node?.id}-discountAmount`}
                  inputRef={register}
                  className={classes.editTextfield}
                  label="Discount Amount"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors[`${category?.node?.id}-discountAmount`]}
                  helperText={
                    errors[`${category?.node?.id}-discountAmount`] && 'Discount amount is required'
                  }
                  defaultValue={
                    isBulkEdit
                      ? undefined
                      : convert.centsToDollars(findlocationHandoff?.node?.discountAmount || 0)
                  }
                  variant="outlined"
                />
              </Permission>
            </Grid>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.discountFraction
              }
            >
              <Grid item xs={6}>
                <PermissionField
                  Component={TextField}
                  name={`${category?.node?.id}-discountFraction`}
                  inputRef={register({
                    required: true,
                  })}
                  error={errors[`${category?.node?.id}-discountFraction`]}
                  helperText={
                    errors[`${category?.node?.id}-discountFraction`] &&
                    'Discount fraction is required'
                  }
                  className={classes.editTextfield}
                  label="Discount fraction %"
                  defaultValue={
                    isBulkEdit ? undefined : findlocationHandoff?.node?.discountFraction * 100 || 0
                  }
                  variant="outlined"
                />
              </Grid>
            </Permission>
            <Permission
              access={
                isBulkEdit
                  ? bulkEditPermission?.access
                  : permissions?.locationHandoffCategory?.leadTime
              }
            >
              <Grid item xs={6}>
                <div className={classes.editTextfield}>
                  <Typography gutterBottom>Lead time</Typography>
                  <Controller
                    name={`${category?.node?.id}-leadTime`}
                    control={control}
                    defaultValue={
                      isBulkEdit ? undefined : (findlocationHandoff?.node?.leadTime || 0) / 60
                    }
                    render={props => (
                      <Permission
                        access={
                          isBulkEdit
                            ? bulkEditPermission?.access
                            : permissions?.locationHandoffCategory?.leadTime
                        }
                      >
                        <PermissionField createHelperText>
                          <Slider
                            {...props}
                            classes={sliderClasses}
                            onChange={(_, value) => {
                              props.onChange(value);
                            }}
                            valueLabelDisplay="auto"
                            max={1000}
                            step={15}
                            min={0}
                          />
                        </PermissionField>
                      </Permission>
                    )}
                  />
                </div>
              </Grid>
            </Permission>
            {deliveryId === handoffId && (
              <Permission
                access={
                  isBulkEdit
                    ? bulkEditPermission?.access
                    : permissions?.locationHandoffCategory?.discountFraction
                }
              >
                <Grid item xs={6}>
                  <PermissionField
                    Component={TextField}
                    name={`${category?.node?.id}-radius`}
                    inputRef={register}
                    className={classes.editTextfield}
                    label="Delivery Radius (miles)"
                    variant="outlined"
                    defaultValue={findlocationHandoff?.node?.radius}
                  />
                </Grid>
              </Permission>
            )}
            {deliveryId === handoffId && (
              <Permission
                access={
                  isBulkEdit
                    ? bulkEditPermission?.access
                    : permissions?.locationHandoffCategory?.alwaysDeliver
                }
              >
                <Grid item xs={6}>
                  {statuses ? (
                    <div style={{ width: '100%' }}>
                      <PermissionField
                        Component={Statuses}
                        createHelperText
                        control={control}
                        register={register}
                        statuses={statuses}
                        name={`${category?.node?.id}-alwaysDeliver`}
                        disabled={disabled}
                        access={
                          isBulkEdit
                            ? bulkEditPermission?.access
                            : permissions?.locationHandoffCategory?.alwaysDeliver
                        }
                        handleToggleClick={value =>
                          setValue(
                            `${category?.node?.id}-alwaysDeliver`,
                            value === 0 ? undefined : value,
                          )
                        }
                        label={`Delivery always available for ${category?.node?.label} orders regardless of scheduled driver.`}
                      />
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <PermissionField
                        Component={FormControlLabel}
                        createHelperText
                        className={classes.editSwitch}
                        control={
                          <Permission
                            access={
                              isBulkEdit
                                ? bulkEditPermission?.access
                                : permissions?.locationHandoffCategory?.alwaysDeliver
                            }
                          >
                            <FormControlLabel
                              control={
                                <Elements.Switch
                                  name={`${category?.node?.id}-alwaysDeliver`}
                                  ref={register}
                                  checked={findlocationHandoff?.node?.alwaysDeliver}
                                />
                              }
                              labelPlacement="start"
                              label={`Delivery always available for ${category?.node?.label} orders regardless of scheduled driver.`}
                            />
                          </Permission>
                        }
                      />
                    </div>
                  )}
                </Grid>
              </Permission>
            )}
            {deliveryId === handoffId && (
              <Permission
                access={
                  isBulkEdit
                    ? bulkEditPermission?.access
                    : permissions?.locationHandoffCategory?.doordashDriveEnabled
                }
              >
                <Grid item xs={6}>
                  {statuses ? (
                    <div style={{ width: '100%' }}>
                      <PermissionField
                        Component={Statuses}
                        createHelperText
                        register={register}
                        name={`${category?.node?.id}-doordashDriveEnabled`}
                        control={control}
                        statuses={statuses}
                        disabled={disabled}
                        access={
                          isBulkEdit
                            ? bulkEditPermission?.access
                            : permissions?.locationHandoffCategory?.doordashDriveEnabled
                        }
                        handleToggleClick={value =>
                          setValue(
                            `${category?.node?.id}-doordashDriveEnabled`,
                            value === 0 ? undefined : value,
                          )
                        }
                        label={`Enable DoorDash driver for ${category?.node?.label}.`}
                      />
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <PermissionField
                        Component={FormControlLabel}
                        createHelperText
                        name={`${category?.node?.id}-doordashDriveEnabled`}
                        className={classes.editSwitch}
                        control={
                          <Permission
                            access={
                              isBulkEdit
                                ? bulkEditPermission?.access
                                : permissions?.locationHandoffCategory?.doordashDriveEnabled
                            }
                          >
                            <FormControlLabel
                              control={
                                <Elements.Switch
                                  name={`${category?.node?.id}-doordashDriveEnabled`}
                                  ref={register}
                                  checked={findlocationHandoff?.node?.doordashDriveEnabled}
                                />
                              }
                              labelPlacement="start"
                              className={[classes.switchWrapEditSwitch]}
                              label={`Enable DoorDash driver for ${category?.node?.label}.`}
                            />
                          </Permission>
                        }
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {location?.burqId && (
                    <PermissionField
                      Component={FormControlLabel}
                      createHelperText
                      register={register}
                      name={`${location?.burqId}-burqEnabled`}
                      className={classes.editSwitch}
                      control={
                        <Permission
                          access={
                            isBulkEdit
                              ? bulkEditPermission?.access
                              : permissions?.locationHandoffCategory?.burqEnabled
                          }
                        >
                          <FormControlLabel
                            control={
                              <Elements.Switch
                                name={`${category?.node.id}-burqEnabled`}
                                inputRef={register}
                                ref={register}
                                checked={findlocationHandoff?.node?.burqEnabled}
                              />
                            }
                            labelPlacement="start"
                            className={[classes.switchWrapEditSwitch]}
                            label={`Enable Burq Delivery for ${category?.node?.label}.`}
                          />
                        </Permission>
                      }
                    />
                  )}
                </Grid>
              </Permission>
            )}
            {isBulkEdit ? null : (
              <Grid xs={12} className={classes.buttonGroup}>
                <Button variant="outlined" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Permission
                  access={Math.min(
                    accessToMutations?.[MUTATION_NAME.UpdateLocationHandoffCategoryMutationInput],
                    updateLocationHandoffCategoryPermission?.access,
                  )}
                >
                  <div>
                    <PermissionField createHelperText>
                      <Button
                        type="submit"
                        disabled={
                          updateLocationHandoffCategoryLoading ||
                          toggleLocationHandoffCategoryLoading
                        }
                      >
                        Save
                      </Button>
                    </PermissionField>
                  </div>
                </Permission>
              </Grid>
            )}
          </Grid>
        </FormComponent>
      </div>
    );
  }

  return (
    <div className={classes.switchWrap}>
      <Typography gutterBottom variant="h4" component="h2">
        {category?.node?.label}
      </Typography>
      <Permission
        access={
          isBulkEdit
            ? Math.min(bulkEditPermission?.access, toggleLocationHandoffCategoryPermission?.access)
            : Math.min(
                permissions?.locationHandoffCategory?.handoff?.__typename,
                permissions?.locationHandoffCategory?.category?.__typename,
                accessToMutations?.[MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput],
                toggleLocationHandoffCategoryPermission?.access,
              )
        }
      >
        {statuses ? (
          <PermissionField
            Component={Statuses}
            access={
              isBulkEdit
                ? Math.min(
                    bulkEditPermission?.access,
                    toggleLocationHandoffCategoryPermission?.access,
                  )
                : Math.min(
                    permissions?.locationHandoffCategory?.handoff?.__typename,
                    permissions?.locationHandoffCategory?.category?.__typename,
                    accessToMutations?.[MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput],
                    toggleLocationHandoffCategoryPermission?.access,
                  )
            }
            control={control}
            statuses={statuses}
            handoff={handoff}
            category={category}
            locationId={locationId}
            defaultStatus={
              categoryStatuses[category.node.id] !== undefined
                ? categoryStatuses[category.node.id]
                : defaultStatus
            }
            toggleLocationHandoffCategory={toggleLocationHandoffCategory}
          />
        ) : (
          <Permission
            access={Math.min(
              permissions?.locationHandoffCategory?.handoff?.__typename,
              permissions?.locationHandoffCategory?.category?.__typename,
              accessToMutations?.[MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput],
              toggleLocationHandoffCategoryPermission?.access,
            )}
          >
            <div style={{ width: '100%' }}>
              <PermissionField
                Component={FormControlLabel}
                createHelperText
                className={classes.editSwitch}
                value={category?.node?.id}
                control={
                  <Permission
                    access={Math.min(
                      permissions?.locationHandoffCategory?.handoff?.__typename,
                      permissions?.locationHandoffCategory?.category?.__typename,
                      accessToMutations?.[MUTATION_NAME.ToggleLocationHandoffCategoryMutationInput],
                      toggleLocationHandoffCategoryPermission?.access,
                    )}
                  >
                    <PermissionField
                      Component={Elements.Switch}
                      handleSwitchChange={event => {
                        toggleLocationHandoffCategory({
                          variables: {
                            input: {
                              handoffId: handoff?.id,
                              categoryId: category?.node?.id,
                              locationIds: [{ id: uglifyId('Location', locationId) }],
                              toggle: event,
                            },
                          },
                        });
                      }}
                    />
                  </Permission>
                }
                label={`Allow ${handoff.label} for ${category?.node?.label} orders`}
                labelPlacement="start"
              />
            </div>
          </Permission>
        )}
      </Permission>
    </div>
  );
};

export default CategoryHandoffCardForm;
