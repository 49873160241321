import { gql } from "@apollo/client";
import { paymentFragment } from "../../fragments/index";

const CREATE_GIFT_CARD_PAYMENT = gql`
  mutation createGiftCardPayment(
    $quoteId: ID!
    $amount: Int!
    $tenderId: ID!
    $cardNumber: String!
  ) {
    createGiftCardPayment(
      input: {
        quoteId: $quoteId
        tenderId: $tenderId
        amount: $amount
        cardNumber: $cardNumber
      }
    ) {
      paymentEdge {
        node {
          ...PaymentData
        }
      }
    }
  }
  ${paymentFragment.payment}
`;

export default CREATE_GIFT_CARD_PAYMENT;
