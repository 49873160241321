import 'date-fns';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider,
} from '@material-ui/core';
import LocationItemTable from './LocationItemTable';
import ImageUpload from '../../../../../../../blocks/ImageUpload';
import { UPDATE_ITEM } from '../../../../../../../../apollo/mutations';
import { FETCH_BRANDS, FETCH_ITEM } from '../../../../../../../../apollo/queries';
import GeneralInfoInput from '../../GeneralInfoInput';
import ViewSettingsInput from '../../ViewSettingsInput';
import AvailableOnDaysInput from '../../AvailableOnDaysInput';
import { uglifyId, convert } from '../../../../../../../../utils';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import { CrossSell, SetPricing } from '../../../../../../../blocks';

const IMAGE = {
  sizes: ['1144x510', '375x268', '490x350', '375x167'],
};

const ItemInfo = ({ classes, refetchMenuList, itemTypeOptions, ids, menuData }) => {
  const { itemId } = useParams();
  const { setSnackbar } = useSnackbar();

  const gqlItemId = uglifyId('Item', itemId);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState(
    IMAGE.sizes.map(size => {
      return {
        size,
      };
    }),
  );
  const [tempImages, setTempImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [availableOnDays, setAvailableOnDays] = useState('everyday');
  const [tierArray, setTierArray] = useState();
  const [itemTierArray, setItemTierArray] = useState();
  const [tierPrice, setTierPrice] = useState();
  const [tierPriceCap, setTierPriceCap] = useState();
  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [crossSellItems, setCrossSellItems] = useState([]);

  useEffect(() => {
    return () => {
      setImages(
        IMAGE.sizes.map(size => {
          return {
            size,
          };
        }),
      );
      setPreviewImage('');
      setTempImages([]);
      setDays({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      });
      setAvailableOnDays('everyday');
    };
  }, [itemId]);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find(image => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images, open]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { handleSubmit, control, register, errors, reset, getValues } = useForm({
    defaultValues: {
      label: '',
      label2: '',
      description: '',
      price: '0',
      priceCap: '0',
      preparationTime: '0',
      minQuantity: '0',
      maxQuantity: null,
      showLocation: false,
      availableOnline: false,
      availablePos: false,
      availableThirdParty: false,
      perPerson: true,
      numberPeopleCanServe: 1,
      crossSellItems: [],
    },
  });

  const [updateItem] = useMutation(UPDATE_ITEM, {
    onCompleted: async () => {
      await refetchMenuList();

      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Item updated!',
      });
    },
    onError: error =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      }),
  });

  const { data: brandList, error: brandListError, loading: brandListLoading } = useQuery(
    FETCH_BRANDS,
  );

  const getItemTierPrice = useCallback(
    itemTier => {
      const { id, tierId } = itemTier;

      if (tierPrice) {
        return tierPrice[`tier-${id}`];
      }

      const itemTierPrice = itemTierArray?.find(el => el?.tierId === tierId)?.price;

      if (typeof itemTierPrice === 'number') {
        return convert.centsToDollars(itemTierPrice);
      }

      return null;
    },
    [itemTierArray, tierPrice],
  );

  const { error, loading, variables, data: fetchItemData, refetch } = useQuery(FETCH_ITEM, {
    variables: {
      id: gqlItemId,
      itemCrosssellSort: {
        created: 'ASC',
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data?.viewer?.item) {
        setTierPrice();
        setTierPriceCap();

        setTierArray(
          data?.viewer?.tierConnection?.edges.map(edge => {
            return {
              id: edge?.node?.id,
              tierId: edge?.node?.tierId,
              label: edge?.node?.label,
            };
          }),
        );

        setItemTierArray(
          data?.viewer?.item?.itemTierConnection?.edges.map(edge => {
            return {
              id: edge?.node?.id,
              tierId: edge?.node?.tierId,
              price: edge?.node?.price,
              priceCap: edge?.node?.priceCap,
            };
          }),
        );

        data.viewer.item.itemimageConnection.edges.forEach(({ node }) => {
          const index = images.findIndex(image => Object.values(image).includes(node.label));

          if (index !== -1) {
            setImages(images => {
              const image = {
                name: '',
                description: node.description || '',
                encoded: '',
                size: node.label,
                url: node.url || '',
              };

              images[index] = image;

              return [...images];
            });
          }
        });

        setCrossSellItems(
          data.viewer.item.itemCrosssellConnection.edges.map((edge, index) => ({
            label: `${edge.node.crosssellItem.label} - ID_${edge.node.crosssellItem.itemId}`,
            itemId: edge.node.crosssellItem.itemId,
            value: edge.node.crosssellItem.id,
            index,
            id: edge.node.crosssellItem.id,
          })),
        );

        const weekdays = data.viewer.item.weekdays.reduce(
          (o, key) => ({ ...o, [key.toLowerCase()]: true }),
          {},
        );
        setDays({
          ...{
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          },
          ...weekdays,
        });

        const price = convert.centsToDollars(data.viewer.item.price);
        const priceCap = data.viewer.item.priceCap
          ? convert.centsToDollars(data.viewer.item.priceCap)
          : null;
        const item = Object.assign({}, data.viewer.item, { price, priceCap });
        item.maxCalories = data.viewer.item?.maxCalories || '';
        item.minCalories = data.viewer.item?.minCalories || '';

        if (item.weekdays.length) {
          setAvailableOnDays('specific');
        }

        const tierObject = {};
        data?.viewer?.tierConnection?.edges.map(tierEdge => {
          const itemTierPrice = data?.viewer?.item?.itemTierConnection?.edges.filter(
            itemTierEdge => itemTierEdge?.node?.tierId === tierEdge?.node?.tierId[0]?.price,
          );
          tierObject[`tier-${tierEdge?.node?.id}`] = itemTierPrice;
        });

        reset({ ...item, ...tierObject });
      }
    },
  });

  const handleClose = useCallback(() => {
    setTempImages([]);
    setOpen(false);
  }, []);

  const handleSave = useCallback(() => {
    setImages([...tempImages]);
    setTempImages([]);
    setOpen(false);
  }, [tempImages]);

  if (loading || brandListLoading) return <CircularProgress className={classes.spinner} />;
  if (error || brandListError) console.log(error, brandListError);

  const onSubmit = async data => {
    if (data.maxQuantity || data.maxQuantity === 0) {
      if (+data.minQuantity > +data.maxQuantity) {
        return setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: 'Min quantity can not be greater than max quantity!',
        });
      }
    }

    const showOnDays = availableOnDays === 'specific';
    const weekdays = Object.values(days);

    const tiers = Object.entries(data)
      .filter(pair => pair[0].includes('tier') && pair[1] && !pair[0].includes('price-cap'))
      .map(tier => {
        return {
          tierId: tier[0].split('-')[1],
          price: convert.dollarsToCents(tier[1]),
          priceCap: convert.dollarsToCents(
            Object.entries(data).find(pair =>
              pair[0].includes(`tier-price-cap-${tier[0].split('-')[1]}`),
            )[1],
          ),
        };
      });

    const crossSellItemIds = crossSellItems.map(item => item.itemId);

    const imagesToUpload = images.map(image => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    setButtonDisabled(true);
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Updating item...',
    });

    await updateItem({
      variables: {
        input: {
          minCalories: data.minCalories ? data.minCalories : null,
          maxCalories: data.maxCalories ? data.maxCalories : null,
          itemId: gqlItemId,
          label: data.label,
          label2: data.label2,
          description: data.description,
          preparationTime: data.preparationTime,
          minQuantity: data.minQuantity,
          ...(data.maxQuantity && { maxQuantity: data.maxQuantity }),
          showLocation: data.showLocation,
          availableOnline: data.availableOnline,
          availablePos: data.availablePos,
          availableThirdParty: data.availableThirdParty,
          itemTypeId: data.itemtypeId,
          isDonation: false,
          isGift: false,
          showOnDays,
          weekdays,
          images: imagesToUpload,
          brandId: data.brandId,
          perPerson: data.perPerson,
          numberPeopleCanServe: data.numberPeopleCanServe,
          crossSellItems: crossSellItemIds,
        },
      },
      refetchQueries: [
        {
          query: FETCH_ITEM,
          variables: { ...variables },
        },
      ],
    });

    setButtonDisabled(false);
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
          <Grid item>
            <Button disabled={buttonDisabled} type="submit">
              Save
            </Button>
          </Grid>
        </Grid>

        <GeneralInfoInput
          isItem={true}
          brandList={brandList?.viewer?.brandConnection?.edges}
          brand={fetchItemData?.viewer?.item?.brand}
          itemTypeId={fetchItemData?.viewer?.item?.itemtype?.itemtypeId}
          imageUrl={previewImage}
          setOpen={() => {
            setOpen(true);
            setTempImages([...images]);
          }}
          control={control}
          register={register}
          errors={errors}
          itemTypeOptions={itemTypeOptions}
          perPerson={fetchItemData?.viewer?.item?.perPerson}
          numberPeopleCanServe={fetchItemData?.viewer?.item?.numberPeopleCanServe}
        />
        <AvailableOnDaysInput
          days={days}
          setDays={setDays}
          availableOnDays={availableOnDays}
          setAvailableOnDays={setAvailableOnDays}
        />
        <Container className={classes.sectionContainer}>
          <Grid container spacing={3}>
            <ViewSettingsInput
              title={'View Settings'}
              showToDashboardUser={true}
              control={control}
            />
          </Grid>
        </Container>
        <Divider style={{ margin: '25px 0px', width: '100%' }} />
        <Container
          className={classes.sectionContainer}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            style={{ marginRight: '40px', whiteSpace: 'nowrap' }}
          >
            Item Pricing
          </Typography>
          <SetPricing itemId={itemId} gqlItemId={gqlItemId} getValuesOuterForm={getValues} />
        </Container>
        <Grid container spacing={3}>
          <CrossSell
            crossSellItems={crossSellItems}
            setCrossSellItems={setCrossSellItems}
            menuId={ids.menuId}
            categoryId={ids.categoryId}
            menuData={menuData}
          />
        </Grid>
        <Typography gutterBottom variant="h6" component="h2">
          Location availability
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LocationItemTable itemId={itemId} />
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Item Images</DialogTitle>

        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                Web
              </Grid>
              <Grid item xs={6}>
                Mobile
              </Grid>
            </Grid>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ImageUpload
                images={tempImages}
                setImages={setTempImages}
                size={IMAGE.sizes[0]}
                maxSize={130 * 1000}
                helperText="Required image size is 1144 x 510 px in JPG format. File weight in max. 130KB."
              />
            </Grid>
            <Grid item xs={6}>
              <ImageUpload
                images={tempImages}
                setImages={setTempImages}
                size={IMAGE.sizes[1]}
                maxSize={50 * 1000}
                helperText="Required image size is 375 x 268 px in JPG format. File weight max. 50KB."
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ImageUpload
                images={tempImages}
                setImages={setTempImages}
                size={IMAGE.sizes[2]}
                maxSize={70 * 1000}
                helperText="Required image size is 490 x 350 px in JPG format. File weight in max. 70KB."
              />
            </Grid>
            <Grid item xs={6}>
              <ImageUpload
                images={tempImages}
                setImages={setTempImages}
                size={IMAGE.sizes[3]}
                maxSize={70 * 1000}
                helperText="Required image size is 375 x 167 px in JPG format. File weight in max. 70KB."
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

ItemInfo.propTypes = {
  gqlItemId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ItemInfo;
