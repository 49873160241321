import { gql } from '@apollo/client';

export const taxGroupFragment = {
  taxGroup: gql`
    fragment TaxGroupData on Taxgroup {
      taxgroupId
      id
      label
      description
      taxgroupTaxrateConnection {
        edges {
          node {
            id
            taxrate {
              id
              label
            }
          }
        }
      }
    }
  `,
};
