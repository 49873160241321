import { gql } from '@apollo/client';

const FETCH_STORAGES = gql`
  query FETCH_STORAGES(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [StorageSort]
    $filter: [StorageFilter]
  ) {
    viewer {
      id
      storageConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroup: 102
      ) {
        totalCount
        permissions
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            created
            storageId
            status
            label
          }
        }
      }
    }
  }
`;

export default FETCH_STORAGES;
