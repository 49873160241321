import { gql } from "@apollo/client";

const SET_QUOTE_STEP = gql`
  mutation SetQuoteStep(
    $input: SetQuoteStepMutationInput!
  ) {
    setQuoteStep(
      input: $input
    ) {
      quote {
				stageId
			}
    }
  }
`;

export default SET_QUOTE_STEP;
