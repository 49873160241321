import React, { useCallback, useState } from 'react';
import SubcategoryListItem from './SubcategoryListItem';
import { List } from '@material-ui/core';
import update from 'immutability-helper';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import UPDATE_SUBCATEGORY_ORDER_KEYS from '../../../../../../../../apollo/mutations/menus/updateSubcategoryOrderKeys';
import { FETCH_MENU_LIST, SUBCATEGORY_ITEM_LIST } from '../../../../../../../../apollo/queries';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
const SubcategoryList = ({ data, menuId, categoryId, basePath, classes }) => {
  const { setSnackbar } = useSnackbar();

  const [subcategories, setSubcategories] = useState(data?.map((item, i) => ({ item, i })));
  const [updateSubcategoryOrderKeys, { loading: updateSubcategoryOrderKeysLoading }] = useMutation(
    UPDATE_SUBCATEGORY_ORDER_KEYS,
  );
  const saveSubcategoryOrderKeys = () => {
    setSnackbar({
      type: 'info',
      text: 'Subcategory order keys are being saved',
      open: true,
    });
    const formatSubcategories = subcategories.map(item => ({
      id: item?.item?.node?.id,
      orderKey: item?.i + 1,
    }));
    updateSubcategoryOrderKeys({
      variables: {
        input: {
          subcategories: formatSubcategories,
        },
      },
      refetchQueries: [
        {
          query: FETCH_MENU_LIST,
        },
      ],
    })
      .then(r =>
        setSnackbar({
          type: 'success',
          text: 'Subcategory order keys are save successfully',
          open: true,
        }),
      )
      .catch(() => {
        setSnackbar({
          type: 'error',
          text: 'Something went wrong.',
          open: true,
        });
      });
  };

  const moveSubcategory = useCallback(
    (dragIndex, hoverIndex) => {
      let dragCard = subcategories[dragIndex];
      dragCard.i = hoverIndex;
      subcategories[hoverIndex].i = dragIndex;
      setSubcategories(
        update(subcategories, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [subcategories],
  );

  return (
    <div>
      <div
        style={{ marginBottom: '1rem', textAlign: 'center' }}
        onClick={() => saveSubcategoryOrderKeys()}
      >
        <Button disabled={updateSubcategoryOrderKeysLoading}>Save order</Button>
      </div>
      <div>
        <List component="nav" className={classes.list}>
          {subcategories?.map((edge, i) => {
            return (
              <SubcategoryListItem
                menuId={menuId}
                key={edge?.item?.node?.subcategoryId}
                subcategoryId={edge?.item?.node?.subcategoryId}
                index={i}
                order={edge?.i}
                moveSubcategory={moveSubcategory}
                categoryId={categoryId}
                basePath={basePath}
                edge={edge?.item}
                classes={classes}
              />
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default SubcategoryList;
