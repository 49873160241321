import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  label: {
    width: "80%",
  },
  labelPlacementStart: {
    width: "100%",
  },
});

const styles = makeStyles(Styles);

export default styles;
