import React, { useMemo, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { uglifyId, convert } from '../../../../../../../../utils';
import { useMutation } from '@apollo/client';
import UPDATE_LOCATION_MULTIPLE_CHOICE from '../../../../../../../../apollo/mutations/menus/updateMultipleChoicePrice';
import LOCATION_ITEM_PRICE from '../../../../../../../../apollo/queries/menus/locationItemPrice';
import TextFieldInput from '../../../../../../../blocks/TextFieldInput';
import FETCH_MULTIPLE from '../../../../../../../../apollo/queries/menus/fetchMultiple';
import { isNumber, keys, orderBy } from 'lodash';
import { Permission, PermissionField } from '../../../../../../../blocks';
import { MUTATION_NAME } from '../../../../../../../../constants';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { formatNumberStringToFloat } from '../../../../../../helpers';
import { InfoOutlined } from '@material-ui/icons';

const ChoicesSettings = ({
  mutationPermissions,
  accessToMutations,
  isBulkEdit,
  choices,
  handleClose,
  multipleId,
  locationId,
  variables,
  locationMultiple,
  locationIds,
  chowlyAPIkey,
  permissions,
  isPriceCapApplicable,
}) => {
  const { setSnackbar } = useSnackbar();
  const { register, handleSubmit, setValue, errors, watch } = useForm();

  const updateLocationMultipleChoicePermission = useMemo(
    () =>
      mutationPermissions?.find(permission => permission.label === 'updateLocationMultipleChoice'),
    [mutationPermissions],
  );

  const [updateLocationMultipleChoicePrice, { loading }] = useMutation(
    UPDATE_LOCATION_MULTIPLE_CHOICE,
  );

  const onSubmit = formData => {
    var showPriceCapWarning = false;

    const formChoices = [];
    for (const property in formData) {
      const obj = {
        id: property,
        ...formData[property],
      };
      formChoices.push(obj);
    }

    formChoices.forEach(formChoice => {
      var choicePriceCap = choices.find(x => x.id === formChoice.id)?.priceCap;

      if (choicePriceCap !== null) {
        var posPrice = formChoice?.posPrice ? convert.dollarsToCents(formChoice?.posPrice) : null;
        var onlinePrice = formChoice?.onlinePrice
          ? convert.dollarsToCents(formChoice?.onlinePrice)
          : null;
        var thirdPartyPrice = formChoice?.thirdPartyPrice
          ? convert.dollarsToCents(formChoice?.thirdPartyPrice)
          : null;

        if (
          posPrice > choicePriceCap ||
          onlinePrice > choicePriceCap ||
          thirdPartyPrice > choicePriceCap
        ) {
          showPriceCapWarning = true;
        }
      }
    });

    showPriceCapWarning = document?.querySelector('[data-isValid="Max Price exceeded"]')
      ? true
      : false;

    const newData = {
      locationIds: locationIds ? locationIds : [{ id: uglifyId('Location', locationId) }],
      multipleId,
      choices: formChoices.map(choice => {
        if (isBulkEdit) {
          return {
            choiceId: choice.id,
            posPrice: choice?.posPrice
              ? convert.dollarsToCents(choice?.posPrice)
              : choice?.posPrice === null
              ? null
              : undefined,
            thirdPartyPrice: choice?.thirdPartyPrice
              ? convert.dollarsToCents(choice?.thirdPartyPrice)
              : choice?.thirdPartyPrice === null
              ? null
              : undefined,
            onlinePrice: choice?.onlinePrice
              ? convert.dollarsToCents(choice?.onlinePrice)
              : choice?.onlinePrice === null
              ? null
              : undefined,
          };
        }

        const result = {
          choiceId: choice.id,
        };

        keys(choice).forEach(key => {
          if (key !== 'id') {
            if (isNumber(choice[key])) {
              result[key] = convert.dollarsToCents(choice[key]);
            } else {
              result[key] = null;
            }
          }
        });

        return result;
      }),
    };

    updateLocationMultipleChoicePrice({
      variables: {
        input: {
          ...newData,
        },
      },
      refetchQueries: [
        {
          query: locationMultiple ? FETCH_MULTIPLE : LOCATION_ITEM_PRICE,
          variables: {
            ...variables,
          },
        },
      ],
    }).then(r => {
      setTimeout(handleClose, 700);
      if (showPriceCapWarning) {
        setSnackbar({
          open: true,
          type: 'warning',
          text:
            'Valid multiple choices have been updated. Please note: values over the Max Price cannot be saved.',
        });
      } else {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Multiple choices have been updated.',
        });
      }
    });
  };

  const isPriceCapExceeded = useCallback(
    (value, priceCap) =>
      typeof priceCap === 'number' &&
      value &&
      parseFloat(value) > parseFloat(convert.centsToDollars(priceCap))
        ? 'Max Price exceeded'
        : '',
    [],
  );

  const renderChoices = () => {
    return choices.map(
      ({
        id,
        title,
        suggestedPrice,
        priceCap,
        posPrice,
        onlinePrice,
        thirdPartyPrice,
        activeTierProps,
        platforms,
      }) => (
        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              marginLeft: '2rem',
            }}
          >
            {title}
          </Grid>
          <Permission access={permissions?.suggestedPrice}>
            <Grid
              item
              xs={isPriceCapApplicable ? 1 : 2}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div>${suggestedPrice && convert.centsToDollars(suggestedPrice)}</div>
            </Grid>
          </Permission>
          {isPriceCapApplicable && (
            <Permission access={permissions?.priceCap}>
              <Grid
                item
                xs={1}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'grey',
                }}
              >
                <Tooltip
                  title={orderBy(platforms, ['node.label'], ['desc'])?.map((platform, index) => {
                    {
                      const platformsSerialized = activeTierProps
                        ? activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.map(
                            e => {
                              if (e?.node?.platform?.label === 'TPV') {
                                return {
                                  ...e.node,
                                  platform: {
                                    ...e?.node?.platform,
                                    label: '3PV',
                                  },
                                };
                              }
                              return e.node;
                            },
                          )
                        : [];

                      const platformMaxPrice =
                        platformsSerialized?.find(
                          pl => pl?.platform?.label === platform?.node?.label,
                        )?.priceCap || priceCap;

                      return (
                        <Typography variant="caption" style={{ display: 'block' }}>
                          {`${platform?.node?.label} Max Price: `}
                          {typeof platformMaxPrice === 'number' ? (
                            <Typography style={{ fontWeight: 700 }} variant="caption">
                              {`$${convert.centsToDollars(platformMaxPrice)}`}
                            </Typography>
                          ) : (
                            <Typography style={{ fontWeight: 700 }} variant="caption">
                              {'Not Set'}
                            </Typography>
                          )}
                        </Typography>
                      );
                    }
                  })}
                >
                  <InfoOutlined />
                </Tooltip>
              </Grid>
            </Permission>
          )}
          <Permission access={permissions?.posPrice}>
            <Grid item xs={2}>
              <div>
                <PermissionField
                  Component={TextFieldInput}
                  name={`${id}.posPrice`}
                  variant="outlined"
                  hasRemove={isBulkEdit}
                  type={'number'}
                  inputProps={{
                    min: 0,
                    step: 0.01,
                  }}
                  register={register}
                  isFloat={true}
                  error={errors[id]?.posPrice}
                  setValue={setValue}
                  rules={{
                    validate: {
                      positive: value => !value || formatNumberStringToFloat(value) >= 0,
                    },
                  }}
                  label={'Pos Price'}
                  defaultValue={
                    (posPrice !== null &&
                      posPrice !== undefined &&
                      convert.centsToDollars(posPrice)) ||
                    ''
                  }
                  style={{ width: '100%' }}
                />
                {isPriceCapApplicable && (
                  <span
                    style={{ color: '#D40E37', fontSize: '0.875rem' }}
                    data-isValid={isPriceCapExceeded(
                      watch(`${id}.posPrice`),
                      activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.find(
                        pl => pl?.node?.platform?.label === 'POS',
                      )?.node?.priceCap || priceCap,
                    )}
                  >
                    {isPriceCapExceeded(
                      watch(`${id}.posPrice`),
                      activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.find(
                        pl => pl?.node?.platform?.label === 'POS',
                      )?.node?.priceCap || priceCap,
                    )}
                  </span>
                )}
              </div>
            </Grid>
          </Permission>
          <Permission access={permissions?.onlinePrice}>
            <Grid item xs={2}>
              <div>
                <PermissionField
                  Component={TextFieldInput}
                  name={`${id}.onlinePrice`}
                  variant="outlined"
                  type={'number'}
                  hasRemove={isBulkEdit}
                  inputProps={{
                    min: 0,
                    step: 0.01,
                  }}
                  register={register}
                  isFloat={true}
                  error={errors[id]?.onlinePrice}
                  setValue={setValue}
                  rules={{
                    validate: {
                      positive: value => !value || formatNumberStringToFloat(value) >= 0,
                    },
                  }}
                  label={'Online Price'}
                  defaultValue={
                    (onlinePrice !== null &&
                      onlinePrice !== undefined &&
                      convert.centsToDollars(onlinePrice)) ||
                    ''
                  }
                  style={{ width: '100%' }}
                />
                {isPriceCapApplicable && (
                  <span
                    style={{ color: '#D40E37', fontSize: '0.875rem' }}
                    data-isValid={isPriceCapExceeded(
                      watch(`${id}.onlinePrice`),
                      activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.find(
                        pl => pl?.node?.platform?.label === 'OLO',
                      )?.node?.priceCap || priceCap,
                    )}
                  >
                    {isPriceCapExceeded(
                      watch(`${id}.onlinePrice`),
                      activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.find(
                        pl => pl?.node?.platform?.label === 'OLO',
                      )?.node?.priceCap || priceCap,
                    )}
                  </span>
                )}
              </div>
            </Grid>
          </Permission>
          <Permission access={permissions?.thirdPartyPrice}>
            <Grid item xs={2}>
              <div>
                <PermissionField
                  Component={TextFieldInput}
                  name={`${id}.thirdPartyPrice`}
                  variant="outlined"
                  hasRemove={isBulkEdit}
                  disabled={!chowlyAPIkey}
                  register={register}
                  inputProps={{
                    min: 0,
                    step: 0.01,
                  }}
                  type={'number'}
                  setValue={setValue}
                  isFloat={true}
                  error={errors[id]?.thirdPartyPrice}
                  rules={{
                    validate: {
                      positive: value => !value || formatNumberStringToFloat(value) >= 0,
                    },
                  }}
                  label={'Chowly 3PV Price'}
                  defaultValue={
                    (thirdPartyPrice !== null &&
                      thirdPartyPrice !== undefined &&
                      convert.centsToDollars(thirdPartyPrice)) ||
                    ''
                  }
                  style={{ width: '100%' }}
                />
                {isPriceCapApplicable && (
                  <span
                    style={{ color: '#D40E37', fontSize: '0.875rem' }}
                    data-isValid={isPriceCapExceeded(
                      watch(`${id}.thirdPartyPrice`),
                      activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.find(
                        pl => pl?.node?.platform?.label === '3PV',
                      )?.node?.priceCap || priceCap,
                    )}
                  >
                    {isPriceCapExceeded(
                      watch(`${id}.thirdPartyPrice`),
                      activeTierProps?.node?.multipleChoiceTierPlatformConnection?.edges?.find(
                        pl => pl?.node?.platform?.label === '3PV',
                      )?.node?.priceCap || priceCap,
                    )}
                  </span>
                )}
              </div>
            </Grid>
          </Permission>
        </Grid>
      ),
    );
  };

  return (
    <Paper style={{ height: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} style={{ marginTop: '1rem', alignItems: 'center' }}>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '2rem',
            }}
          >
            ITEM
          </Grid>
          <Grid
            item
            xs={isPriceCapApplicable ? 1 : 2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            MCPI
          </Grid>
          {isPriceCapApplicable && (
            <Grid item xs={1} style={{ display: 'flex', textAlign: 'center' }}>
              MAX PRICE
            </Grid>
          )}
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            POS PRICE
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            OLO PRICE
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            CHOWLY 3PV PRICE
          </Grid>
        </Grid>
        {renderChoices()}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <div>
            <Button
              style={{ marginRight: '1rem' }}
              onClick={() => handleClose()}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Permission
              access={Math.min(
                accessToMutations?.[
                  MUTATION_NAME.updateLocationItemMultipleChoicePriceMutationNDInput
                ],
                updateLocationMultipleChoicePermission?.access,
              )}
            >
              <div>
                <PermissionField createHelperText>
                  <Button type={'submit'} disabled={loading}>
                    Save
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </div>
        </div>
      </form>
    </Paper>
  );
};

export default ChoicesSettings;
