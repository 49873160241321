import OrderTrackerIcon1 from './OrderTracker1Active';
import OrderTrackerIcon2 from './OrderTracker2';
import OrderTrackerIcon5 from './OrderTracker5';
import OrderTrackerIcon6 from './OrderTracker6';
import OrderTrackerIcon10 from './OrderTracker10';
import OrderTrackerIcon12 from './OrderTracker12';

import OrderTrackerIcon1Active from './OrderTracker1Active';
import OrderTrackerIcon2Active from './OrderTracker2Active';
import OrderTrackerIcon5Active from './OrderTracker5Active';
import OrderTrackerIcon6Active from './OrderTracker6Active';
import OrderTrackerIcon10Active from './OrderTracker10Active';
import OrderTrackerIcon12Active from './OrderTracker12Active';

const icons = {
  OrderTrackerIcon1, //order in the cue
  OrderTrackerIcon1Active,
  OrderTrackerIcon2, // smoking to perfection
  OrderTrackerIcon2Active,
  OrderTrackerIcon5, //ready for pickup
  OrderTrackerIcon5Active,
  OrderTrackerIcon6, // out for delivery
  OrderTrackerIcon6Active,
  OrderTrackerIcon10, //ready for curbside
  OrderTrackerIcon10Active,
  OrderTrackerIcon12, //order handed to customer
  OrderTrackerIcon12Active,
};

export default icons;
