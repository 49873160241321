import { gql } from '@apollo/client';

const LOYALTY_POINT_LOG = gql`
  query LoyaltyPointLog(
    $first: Int
    $last: Int
    $filter: [LoyaltypointlogFilter]
    $after: String
    $sort: [LoyaltypointlogSort]
    $before: String
  ) {
    viewer {
      id
      loyaltypointlogConnection(
        filter: $filter
        sort: $sort
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            personId
            created
            brand {
              id
              label
            }
            person {
              id
              firstName
              lastName
            }
            loyaltypointreason {
              id
              label
            }
            points
            bonusPoints
          }
        }
      }
    }
  }
`;

export default LOYALTY_POINT_LOG;
