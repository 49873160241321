import React from "react";
import GeneralInfo from "../../Locations/Location/GeneralInfo";
import OrderSettings from "../../Locations/Location/OrderSettings";
import CateringOrder from "../CateringOrder";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { SideBarSectionsLayout, TabPanel } from "../../../blocks";
import Catering from "../index";

const CateringView = ({ currentTab, handleTabChange }) => {
  const tabsViewData = [
    {
      label: "General Info",
      value: "generalInfo",
      component: CateringOrder,
      id: "524e0a6s-598d-4733-a5e5-6069a856637c",
      sections: [
        { name: "Catering Info", id: "cateringInfo" },
        // { name: 'Location Hours', id: 'locationHours' },
        { name: "Customer Info", id: "customerInfo" },
        { name: "Event Info", id: "eventInfo" },
        { name: "Order Items", id: "orderItems" },
        { name: "Payments", id: "payments" },
      ],
    },
    // TODO
    // {
    //   label: "Quote History",
    //   value: "orderSettings",
    //   component: OrderSettings,
    //   id: "32bn34dz-28ce-433b-9a69-aa848e9dc44f"
    // }
  ];
  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {tabsViewData.map((tab) => (
          <Tab label={tab.label} value={tab.value} key={tab.id} />
        ))}
      </Tabs>
      {tabsViewData.map((tabPanel) => (
        <TabPanel value={currentTab} index={tabPanel.value} key={tabPanel.id}>
          <SideBarSectionsLayout sectionList={tabPanel.sections || []}>
            {<tabPanel.component />}
          </SideBarSectionsLayout>
        </TabPanel>
      ))}
    </>
  );
};

export default CateringView;
