import React from 'react';
import { Tabs, Tab } from '@material-ui/core';

import VendorGeneralInfo from '../GeneralInfo';
import VendorLocations from '../Locations';

import { TabPanel } from '../../../../../../../components/blocks';

const getTabsViewData = [
  {
    label: 'General Info',
    value: 'generalInfo',
    component: VendorGeneralInfo,
    id: 'VendorGeneralInfoComp',
  },
  {
    label: 'Vendor Locations',
    value: 'vendorLocations',
    component: VendorLocations,
    id: 'VendorLocationsComp',
  },
];

const VendorView = ({
  vendor,
  vendorLoading,
  handleOpenModal,
  currentTab,
  handleTabChange,
}) => {

  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {getTabsViewData.map((tab) => (
          <Tab label={tab.label} value={tab.value} key={tab.id} />
        ))}
      </Tabs>
      {getTabsViewData.map((tabPanel) => (
        <TabPanel value={currentTab} index={tabPanel.value} key={tabPanel.id}>
          {<tabPanel.component 
            vendor={vendor}
            vendorLoading={vendorLoading}
            handleOpenModal={handleOpenModal}
          />}
        </TabPanel>
      ))}
    </>
  );
}

export default VendorView;
