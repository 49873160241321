import React, { useState } from 'react';
import { Button, Chip } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Add from '@material-ui/icons/Add';

import { Modal } from '../../../../../../../../blocks';
import CategorySelect from '../../../../GeneralSettings/DiscountType/CategorySelect';
import AddItemsModal from '../../../../DiscountRestrictions/Items/AddItemsModal';

function ItemFromSubcategoryAndItem({
  control,
  cartItemsRequired,
  setCartItemsRequired,
  selectedSubcategoriesTrigger,
  setSelectedSubcategoriesTrigger,
}) {
  const [openSubcategory, setOpenSubcategory] = useState(false);
  const [openItem, setOpenItem] = useState(false);

  const handleCloseSubcategory = () => {
    setOpenSubcategory(false);
  };

  const handleCloseItem = () => {
    setOpenItem(false);
  };

  const handleDeleteItem = itemId => {
    const newArr = cartItemsRequired?.length
      ? cartItemsRequired.filter(item => item.id !== itemId)
      : [];
    setCartItemsRequired(newArr);
  };

  const handleDeleteSubcategory = subcategoryId => {
    const newArr = selectedSubcategoriesTrigger?.length
      ? selectedSubcategoriesTrigger.filter(subcategory => subcategory.id !== subcategoryId)
      : [];
    setSelectedSubcategoriesTrigger(newArr);
  };

  return (
    <>
      <div>
        <Controller
          control={control}
          name="triggerEvents.automatic.forSubcategoryAndItemInCart.subcategoryIds"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3>Selected Subcategories</h3>
          <div>
            <Button onClick={setOpenSubcategory.bind(this, true)} variant="outlined">
              <Add /> Add Subcategories
            </Button>
          </div>
        </div>
        <div>
          {selectedSubcategoriesTrigger?.id && (
            <Chip
              style={{ margin: '4px' }}
              key={selectedSubcategoriesTrigger.id}
              label={selectedSubcategoriesTrigger.label}
              onDelete={e => handleDeleteSubcategory(selectedSubcategoriesTrigger)}
            />
          )}
        </div>
        <Modal
          open={openSubcategory}
          handleClose={handleCloseSubcategory}
          title="Add Subcategories"
          maxWidth="md"
        >
          <CategorySelect
            selectedSubcategoriesAction={selectedSubcategoriesTrigger}
            setSelectedSubcategoriesAction={setSelectedSubcategoriesTrigger}
            handleClose={handleCloseSubcategory}
            multiple={false}
          />
        </Modal>
      </div>

      <div>
        <Controller
          control={control}
          name="triggerEvents.automatic.forSubcategoryAndItemInCart.itemIds"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3>Selected Items</h3>
          <div>
            <Button onClick={setOpenItem.bind(this, true)} variant="outlined">
              <Add /> Add Items
            </Button>
          </div>
        </div>
        <div>
          {cartItemsRequired?.id && (
            <Chip
              style={{ margin: '4px' }}
              key={cartItemsRequired.id}
              label={cartItemsRequired.label}
              onDelete={e => handleDeleteItem(cartItemsRequired)}
            />
          )}
        </div>
        <Modal open={openItem} handleClose={handleCloseItem} title="Add Items" maxWidth="md">
          <AddItemsModal
            excludedItems={[cartItemsRequired]} // Modal accepts only array of items
            setExcludedItems={setCartItemsRequired}
            handleClose={handleCloseItem}
            multiple={false}
          />
        </Modal>
      </div>
    </>
  );
}

export default ItemFromSubcategoryAndItem;
