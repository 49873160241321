import { gql } from '@apollo/client';

const FETCH_SUBCATEGORY = gql`
  query fetchSubcategory(
    $id: ID!
    $filter: [SubcategoryItemFilter]!
    $sort: [SubcategoryItemSort]
    $subcategoryCrosssellSort: [SubcategoryCrosssellSort]
  ) {
    viewer {
      id
      subcategory(id: $id, fieldGroupOnly: 7) {
        id
        subcategoryId
        label
        description
        isAvailableOnline
        isAvailablePos
        isAvailable3pv
        shortName
        startDate
        startTime
        endDate
        endTime
        timezoneId
        menuId
        subcategoryWeekdayConnection {
          edges {
            node {
              id
              subcategoryId
              weekdayId
              startTime
              endTime
            }
          }
        }
        subcategoryCrosssellConnection(sort: $subcategoryCrosssellSort) {
          edges {
            node {
              crosssellItem {
                id
                itemId
                label
              }
            }
          }
        }
        locationSubcategoryConnection {
          edges {
            node {
              id
              subcategoryId
              locationId
              available
              startTime
              endTime
            }
          }
        }
        locationSubcategoryWeekdayConnection {
          edges {
            node {
              id
              locationId
              subcategoryId
              weekdayId
              startTime
              endTime
            }
          }
        }
        subcategoryimageConnection {
          edges {
            node {
              id
              label
              url
              description
            }
          }
        }
        subcategoryItemConnection(filter: $filter, sort: $sort) {
          edges {
            node {
              id
              item {
                id
                itemId
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_SUBCATEGORY;
