import { gql } from '@apollo/client';

const UPDATE_SUBCATEGORY_ITEM_ORDER_KEY = gql`
  mutation updateSubcategoryItemOrder($input: updateSubcategoryItemOrderMutationInput!) {
    updateSubcategoryItemOrder(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_SUBCATEGORY_ITEM_ORDER_KEY;
