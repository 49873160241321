import { gql } from "@apollo/client";

const AddNote = gql`
  mutation newQuoteNote($input: NewQuoteNoteMutationInput!) {
    newQuoteNote(input: $input) {
      viewer {
        id
      }
    }
  }
`;
export default AddNote;
