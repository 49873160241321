import { gql } from '@apollo/client';

const REMOVE_ALLERGEN = gql`
  mutation removeAllergen($input: RemoveAllergenMutationInput!) {
    removeAllergen(input: $input) {
      viewer {
        id
      }
      removedAllergenId
    }
  }
`;

export default REMOVE_ALLERGEN;
