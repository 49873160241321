import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import {
  InputAdornment,
  IconButton,
  Container,
  Grid,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import AutoCompleteMUI from './../../../blocks/AutoComplete';
import { useMutation } from '@apollo/client';
import { SelectFactory } from '../../../blocks';
import { Controller } from 'react-hook-form';
import FETCH_CATERING_EXPERT from '../../../../apollo/queries/caterings/fetchCateringExpert';
import Permission from '../../../blocks/Permission';
import PermissionField from '../../../blocks/PermissionField';
import uglifyId from '../../../../utils/uglifyId';
import ConfirmationModal from '../../../blocks/ConfirmationModal';
import Button from '@material-ui/core/Button';
import QUOTE_LOCATION_ACCEPTANCE from '../../../../apollo/mutations/quotecatering/quoteLocationAcceptance';
import Modal from '../../../blocks/Modal';
import SEND_EMAILS from '../../../../apollo/mutations/quotecatering/sendEmails';
import { useSnackbar } from '../../../../hooks/useSnackbar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
  },
  title: {
    marginTop: '-1.25rem',
    marginBottom: '1rem',
    background: theme.palette.background.paper,
    padding: '0 0.5rem',
    margin: '0 -0.5rem',
    fontSize: theme.typography.fontSizeL,
  },
  greyText: {
    color: theme.palette.secondary.main,
  },
  closeButton: {
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const CateringInfo = ({
  quote,
  errors,
  locationList,
  fetchLocations,
  locationQueryCalled,
  personList,
  cateringStatusList,
  removeOrderItems,
  setOrderItemsList,
  setLocationInfo,
  control,
  containerId,
  refetch,
  locationInfo,
  loggedInPerson,
  locationsLoading,
}) => {
  const { setSnackbar } = useSnackbar();

  const [clearAction, setClearAction] = useState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [shouldCloseAutocompleteOnCloseModal, setShouldCloseAutocompleteOnCloseModal] = useState(
    true,
  );
  const [cateringStatus, setCateringStatus] = useState(
    quote && {
      label: quote?.quotecatering?.status?.label,
      value: quote?.quotecatering?.status?.id,
    },
  );

  const [isAwatingLocationConfirmation, setIsAwatingLocationConfirmation] = useState(
    quote?.quotecatering?.status?.statusId === 6,
  );
  const [open, setOpen] = useState(false);
  const [quoteLocationAcceptance] = useMutation(QUOTE_LOCATION_ACCEPTANCE);
  const [sendEmails] = useMutation(SEND_EMAILS);
  const classes = useStyles();

  const onOpenLocationAutocomplete = useCallback(() => {
    if (!quote && !isEmpty(locationInfo)) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsAutocompleteOpen(true);
    }
    if (!locationQueryCalled) {
      fetchLocations({
        variables: {
          sort: {
            distance: {
              latitude: quote?.address?.latitude || null,
              longitude: quote?.address?.longitude || null,
              order: 'ASC',
            },
          },
        },
      });
    }
  }, [quote, locationInfo]);

  const handleCloseConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
    setClearAction();

    if (shouldCloseAutocompleteOnCloseModal) {
      setIsAutocompleteOpen(false);
      setShouldCloseAutocompleteOnCloseModal(true);
    }
  }, [shouldCloseAutocompleteOnCloseModal]);

  const handleOnLocationChange = useCallback(
    data => {
      if (data) {
        setLocationInfo(data);

        if (!quote) {
          setOrderItemsList([
            {
              id: 0,
              props: {
                locationId: data,
                noLabel: false,
                removeOrderItem: removeOrderItems,
                lineNumber: 0,
              },
            },
          ]);
        }
      } else {
        setLocationInfo({});
      }

      setIsAutocompleteOpen(false);
    },
    [removeOrderItems, setLocationInfo, setOrderItemsList, quote],
  );

  const handleOnConfirm = useCallback(() => {
    if (clearAction) {
      setLocationInfo({});
      clearAction({ value: '', label: '' });
      setIsConfirmationModalOpen(false);
      setClearAction();
    } else {
      setIsAutocompleteOpen(true);
      setShouldCloseAutocompleteOnCloseModal(false);
    }
  }, [setShouldCloseAutocompleteOnCloseModal, clearAction, setLocationInfo]);

  const onClearLocationAutocomplete = useCallback(
    (event, onChange) => {
      event.stopPropagation();

      if (!quote && !isEmpty(locationInfo)) {
        setClearAction(() => onChange);
        setIsConfirmationModalOpen(true);
      } else {
        setLocationInfo({});
        onChange({ value: '', label: '' });

        setIsAutocompleteOpen(false);
      }
    },
    [locationInfo, setLocationInfo, quote],
  );

  // eslint-disable-next-line no-param-reassign
  locationList = locationList?.map(location => ({
    label: `${location?.node?.label} - #${location?.node?.storeNumber} - ${
      location?.node?.address?.state?.abbreviation
    } ~ ${location?.node?.menu?.label} ~ ${Math.round((location?.node?.distance || 0) * 100) /
      100} mi`,
    value: location?.node?.id,
    menuId: location?.node.menuId,
    timezoneLabel: location?.node?.timezone?.label,
    locationId: location?.node?.locationId,
  }));

  // eslint-disable-next-line no-param-reassign
  personList = personList?.edges?.map(person => ({
    label: `${person.node.firstName} ${person.node.lastName}`,
    value: person.node.id,
  }));

  // eslint-disable-next-line no-param-reassign
  cateringStatusList = cateringStatusList?.edges?.map(cateringStatus => ({
    label: cateringStatus.node?.label,
    value: cateringStatus.node.id,
  }));

  useEffect(() => {
    if (quote?.quotecatering?.status?.statusId === 6) {
      setIsAwatingLocationConfirmation(true);
    } else {
      setIsAwatingLocationConfirmation(false);
    }
  }, [quote?.quotecatering?.status?.statusId]);

  useEffect(() => {
    if (quote?.location) {
      setLocationInfo({
        label: quote?.location?.label,
        timezoneLabel: quote?.location?.timezone?.label,
        value: quote?.location?.id || quote?.locationId,
        locationId: quote?.locationId,
        active: quote?.location?.active,
        menuId: quote?.menuId,
      });
    } else {
      setLocationInfo({});
    }
  }, [quote?.location, quote?.menuId, setLocationInfo]);

  const acknowledgeQuote = () => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Quote is acknowledging.',
    });

    quoteLocationAcceptance({
      variables: {
        input: {
          quoteId: quote?.quoteId,
          accepted: true,
        },
      },
    })
      .then(r => {
        refetch({ id: r?.data?.quoteLocationAcceptance?.quote?.id })
          .then(r => {
            setSnackbar({
              open: true,
              type: 'success',
              text: 'Quote is acknowledged.',
            });

            setCateringStatus(
              cateringStatusList.find(
                status => status.value === r?.data?.viewer?.quote?.quotecatering?.status?.id,
              ),
            );
          })
          .catch(e =>
            setSnackbar({
              open: true,
              type: 'error',
              text: e.message,
            }),
          );
      })
      .catch(e =>
        setSnackbar({
          open: true,
          type: 'error',
          text: e.message,
        }),
      );
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const contactSupport = () => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Contact support email is sending.',
    });
    let data = {
      quotecateringId: quote?.quotecatering?.id,
      template: 'contact_support',
      to: 'OSC@dickeys.com',
    };
    sendEmails({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then(() => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Contact support email is sent.',
        });
        setTimeout(handleCloseModal, 500);
      })
      .catch(() =>
        setSnackbar({
          open: true,
          type: 'error',
          text: 'Something went wrong.',
        }),
      );
  };

  return (
    <Container key="cateringInfo" id={containerId}>
      <div className={classes.root}>
        <span>
          <h2 className={classes.title}>Catering info</h2>
        </span>
      </div>
      <Grid container item xs={12} spacing={3}>
        <Permission access={quote?.permissions?.location?.__typename}>
          <Grid item xs={5} spacing={3}>
            <PermissionField
              Component={AutoCompleteMUI}
              id={'locationId'}
              getOptionLabel={option => option.label || {}}
              defaultValue={
                quote
                  ? {
                      locationId: quote?.location?.locationId,
                      value: quote?.location?.id,
                      label: `${quote?.location?.label} - #${quote?.location?.storeNumber} - ${quote?.location?.address?.state?.abbreviation} ~ ${quote.distance}`,
                    }
                  : {}
              }
              options={locationList}
              rules={{ required: 'Required' }}
              style={{ width: '100%', popupIndicator: { width: '500px' } }}
              control={control}
              loading={locationsLoading}
              open={isAutocompleteOpen}
              disableClearable
              createHelperText
              onClose={() => setIsAutocompleteOpen(false)}
              onOpen={onOpenLocationAutocomplete}
              onChange={handleOnLocationChange}
              renderInput={(params, onChange) => (
                <Permission access={quote?.permissions?.location?.__typename}>
                  <PermissionField
                    {...params}
                    Component={TextField}
                    label={'Location'}
                    variant="outlined"
                    input
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <InputAdornment
                            position="end"
                            onClick={event => onClearLocationAutocomplete(event, onChange)}
                          >
                            <IconButton size="small" className={classes.closeButton}>
                              <Close fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                </Permission>
              )}
            />
            <span style={{ color: 'red' }}>{errors.locationId?.message}</span>
          </Grid>
        </Permission>
        <Permission access={quote?.permissions?.quotecatering?.created}>
          <Grid item xs={3} style={{ justifyContent: 'center' }}>
            <div>
              <Typography variant="subtitle2" gutterBottom>
                Last update
              </Typography>
              {quote?.created
                ? moment
                    .tz(moment.utc(quote?.created), quote?.location?.timezone?.label)
                    .format('YYYY-MM-DD hh:mm A')
                : 'No Date'}
            </div>
          </Grid>
        </Permission>
        <Permission access={quote?.permissions?.location?.active}>
          <Grid item xs={2}>
            <div>
              <Typography variant="subtitle2" gutterBottom>
                Active
              </Typography>
              {locationInfo?.active ? 'Yes' : 'No'}
            </div>
          </Grid>
        </Permission>
        <Grid container item xs={12} spacing={3}>
          <Permission access={quote?.permissions?.quotecatering?.inquiryDate}>
            <Grid item xs={2}>
              <div>
                <Typography variant="subtitle2" gutterBottom>
                  Catering created
                </Typography>
                {quote?.quotecatering?.inquiryDate
                  ? moment
                      .tz(
                        moment.utc(quote?.quotecatering?.inquiryDate),
                        quote?.location?.timezone?.label,
                      )
                      .format('YYYY-MM-DD hh:mm A')
                  : 'No Date'}
              </div>
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.quotecatering?.cateringExpert?.__typename}>
            <Grid item xs={3}>
              <Controller
                control={control}
                rules={{ required: 'Required' }}
                name="cateringExpert"
                defaultValue={
                  quote?.quotecatering?.cateringExpert
                    ? {
                        label: `${quote?.quotecatering?.cateringExpert?.person?.firstName} ${quote?.quotecatering?.cateringExpert?.person?.lastName}`,
                        value: quote?.quotecatering?.cateringExpert?.id,
                        id: quote?.quotecatering?.cateringExpert?.id,
                      }
                    : !quote
                    ? {
                        label: `${loggedInPerson?.firstName} ${loggedInPerson?.lastName}`,
                        value: loggedInPerson?.personId,
                        id: uglifyId('Login', loggedInPerson?.loginId),
                      }
                    : null
                }
                render={props => {
                  return (
                    <Permission
                      access={quote?.permissions?.quotecatering?.cateringExpert?.__typename}
                    >
                      <PermissionField
                        Component={SelectFactory}
                        specifcSearchFields={['first_name', 'last_name', 'email', 'phone']}
                        customProps={props}
                        label={'Catering Expert'}
                        placeholder={'Select'}
                        fullWidth={true}
                        defaultValue={
                          quote?.quotecatering?.cateringExpert
                            ? {
                                label: `${quote?.quotecatering?.cateringExpert?.person?.firstName} ${quote?.quotecatering?.cateringExpert?.person?.lastName}`,
                                value: quote?.quotecatering?.cateringExpert?.id,
                                id: quote?.quotecatering?.cateringExpert?.id,
                              }
                            : !quote
                            ? {
                                label: `${loggedInPerson?.firstName} ${loggedInPerson?.lastName}`,
                                value: loggedInPerson?.personId,
                                id: uglifyId('Login', loggedInPerson?.loginId),
                              }
                            : null
                        }
                        query={FETCH_CATERING_EXPERT}
                        filter={{
                          accessLevel: {
                            eq: 866,
                          },
                        }}
                        style={{ width: '100%' }}
                        multiple={false}
                        renderCheckBox={false}
                        structureData={data => {
                          return data?.viewer?.loginConnection?.edges?.map((login, index) => ({
                            label: `${login.node.person.firstName} ${login.node.person.lastName} - ${login.node.email} - ${login.node.person.phone.phone}`,
                            value: login.node.person?.personId,
                            id: login.node.id,
                            index,
                          }));
                        }}
                        error={!!errors?.cateringExpert}
                        helperText={errors?.cateringExpert && 'Catering Expert is required'}
                      />
                    </Permission>
                  );
                }}
              />
            </Grid>
          </Permission>
          <Permission access={quote?.permissions?.quotecatering?.status?.__typename}>
            <Grid item xs={3} direction={'column'}>
              <AutoCompleteMUI
                id={'cateringStatus'}
                getOptionLabel={option => option.label}
                defaultValue={
                  quote && {
                    label: quote?.quotecatering?.status?.label,
                    value: quote?.quotecatering?.status?.id,
                  }
                }
                onChange={setCateringStatus}
                options={cateringStatusList}
                style={{ width: '100%' }}
                disabled={isEmpty(locationInfo)}
                rules={{ required: true }}
                control={control}
                renderInput={params => (
                  <Permission access={quote?.permissions?.quotecatering?.status?.__typename}>
                    <PermissionField
                      {...params}
                      Component={TextField}
                      label={'Catering Status'}
                      variant="outlined"
                      error={!!errors?.cateringStatus}
                      helperText={errors?.cateringStatus && 'Catering status is required'}
                    />
                  </Permission>
                )}
              />
            </Grid>
          </Permission>
          {isAwatingLocationConfirmation && (
            <>
              <Grid item xs={2}>
                <Button onClick={() => handleOpenModal()}>Contact Support</Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="outlined" onClick={() => acknowledgeQuote()}>
                  Acknowledge
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        confirmAction={handleOnConfirm}
        text={'Are you sure you want to change location? It will delete all order items.'}
      />
      <Modal open={open} handleClose={handleCloseModal} title={'Contact support'}>
        <div>
          <div style={{ marginBottom: '1rem' }}>
            Notify the OSC team you currently don&apos;t want to acknowledge this quote.
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => handleCloseModal()}
              style={{ marginRight: '0.5rem' }}
              variant={'outlined'}
            >
              Cancel
            </Button>
            <Button onClick={() => contactSupport()}>Send</Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default CateringInfo;
