import { useMutation } from '@apollo/client';
import { Grid, Button, TextField } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CREATE_EQUIPMENT from '../../../../../../apollo/mutations/equipment/createEquipment';
import UPDATE_EQUIPMENT from '../../../../../../apollo/mutations/equipment/updateEquipment';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const defaultValues = {
  label: '',
};

const EquipmentForm = ({ handleCancel, equipment }) => {
  const { register, errors, handleSubmit, reset } = useForm({
    name: '',
  });

  const { setSnackbar } = useSnackbar();

  const [createEquipment, { loading: creating }] = useMutation(CREATE_EQUIPMENT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Equipment created',
        open: true,
      });

      handleCancel();
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createEquipment?.viewer),
        fields: {
          equipmentConnection() {
            return {
              __typename: 'EquipmentEdge',
            };
          },
        },
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [updateEquipment, { loading: updating }] = useMutation(UPDATE_EQUIPMENT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Equipment updated',
        open: true,
      });

      handleCancel();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (equipment?.equipmentId) {
      reset({ label: equipment.label });
    } else {
      reset(defaultValues);
    }
  }, [equipment?.equipmentId]);

  const onSubmit = useCallback(
    formData => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Creating Equipment...',
        open: true,
      });

      if (equipment?.equipmentId) {
        updateEquipment({
          variables: { input: { ...formData, equipmentId: equipment?.equipmentId } },
        });
      } else {
        createEquipment({ variables: { input: formData } });
      }
    },
    [equipment?.equipmentId],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register({ required: 'true' })}
            fullWidth
            label="Name"
            error={!!errors.label}
            helperText={!!errors.label ? 'Name is required' : ''}
            name="label"
            variant="outlined"
            placeholder="Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          <div>
            <Button type="submit" disabled={creating || updating}>
              {equipment?.equipmentId ? 'Update' : 'Create'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default EquipmentForm;
