import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import CreateUpdateNutrientModalView from './View';
import { NUTRIENTS_LIST } from '../../../../../../apollo/queries';
import { CREATE_NUTRIENT, UPDATE_NUTRIENT } from '../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

function CreateUpdateNutrientModal({ handleClose, query, nutrientId }) {
  const { setSnackbar } = useSnackbar();

  const [nutrient, setNutrient] = useState(null);
  const [measures, setMeasures] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {
      label: '',
      description: '',
      measureId: '',
    },
  });

  const [createNutrient] = useMutation(CREATE_NUTRIENT, {
    refetchQueries: [{ query: NUTRIENTS_LIST, variables: { ...query.variables } }],
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Nutrient created',
      });
      handleClose();
    },
    onError: () => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Oops, something went wrong',
      });
      handleClose();
    },
  });

  const [updateNutrient] = useMutation(UPDATE_NUTRIENT, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Nutrient updated',
      });
      handleClose();
    },
    onError: () => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Oops, something went wrong',
      });
    },
  });

  const { loading } = useQuery(NUTRIENTS_LIST, {
    variables: query.variables
      ? query.variables
      : { filter: { id: { eq: nutrientId } }, fieldGroupOnly: 89 },
    onCompleted: data => {
      if (data.viewer.nutrientConnection.edges.length && nutrientId) {
        setNutrient(data.viewer.nutrientConnection.edges[0].node);
        const { label, description, measure } = data.viewer.nutrientConnection.edges[0].node;
        reset({
          label,
          description,
          measureId: measure?.id,
        });
      }
      setMeasures(data.viewer.measureConnection);
    },
  });

  if (loading) {
    return '';
  }

  const submit = async data => {
    if (data.label.trim() === '' && data.measureId.trim() === '') {
      setSnackbar({
        open: true,
        type: 'warning',
        text: "Nutrient 'name' and 'unit type' are required fields.",
      });
      return;
    }
    if (data.label.trim() === '') {
      setSnackbar({
        open: true,
        type: 'warning',
        text: "Nutrient 'name' is required field.",
      });
      return;
    }
    if (data.measureId.trim() === '') {
      setSnackbar({
        open: true,
        type: 'warning',
        text: "Nutrient 'unit type' is required field.",
      });
      return;
    }
    data.label = data.label.trim();
    data.description = data.description.trim();
    setButtonDisabled(true);
    if (!nutrientId) {
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Creating nutrient...',
      });
      await createNutrient({
        variables: { ...query.variables, input: data },
      });
    } else {
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Updating nutrient...',
      });
      data.id = nutrientId;
      await updateNutrient({
        variables: { input: data },
      });
    }
  };

  return (
    <CreateUpdateNutrientModalView
      handleClose={handleClose}
      nutrient={nutrient}
      handleSubmit={handleSubmit}
      measures={measures}
      register={register}
      control={control}
      buttonDisabled={buttonDisabled}
      submit={submit}
    />
  );
}

CreateUpdateNutrientModal.propTypes = {
  nutrientId: PropTypes.string,
  setNutrientId: PropTypes.func,
  temp_open: PropTypes.object,
  setTemp_open: PropTypes.func,
};
export default CreateUpdateNutrientModal;
