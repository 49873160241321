import { gql } from '@apollo/client';
const FETCH_SMOKESTACK_DATA = gql`
  query fetchSmokestackData(
    $auvFilter: [auvCommitmentsEdwFilter]
    $locFilter: [LocationFilter]
    $avgSalesDate: String!
  ) {
    viewer {
      id
      auvCommitmentsEdwConnection(filter: $auvFilter) {
        permissions
        totalCount
        edges {
          node {
            locationId
            businessDt
            salesAmt
          }
        }
      }
      locationConnection(filter: $locFilter) {
        edges {
          node {
            id
            averageFourWeekSales(avgSalesDate: $avgSalesDate) {
              date
              salesAmount
            }
          }
        }
      }
    }
  }
`;

export default FETCH_SMOKESTACK_DATA;
