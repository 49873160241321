import { TextField } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { formatNumberStringToFloat } from '../../modules/helpers';

const getNumberValue = value => {
  if ([null, undefined, ''].includes(value)) {
    return true;
  }

  return typeof value === 'string' ? formatNumberStringToFloat(value) : value || 0;
};

export const NumberInput = ({
  allowNegative = false,
  autoFocus,
  control,
  decimalScale = 4,
  defaultValue = '',
  disabled,
  error,
  helperText,
  id,
  label,
  placeholder = '0.0000',
  required = false,
  validations = {},
  onBlur,
  thousandSeparator = true,
}) => {
  const validate = useMemo(() => {
    const updatedValidations = {};

    Object.entries(validations).map(([key, validateFunction]) => {
      const updatedValidateFunction = value => validateFunction(getNumberValue(value));

      updatedValidations[key] = updatedValidateFunction;
    });

    return updatedValidations;
  }, [validations]);

  return (
    <Controller
      defaultValue={defaultValue}
      rules={{
        required,
        validate,
      }}
      control={control}
      id={id}
      name={id}
      render={props => (
        <NumberFormat
          {...props}
          decimalScale={decimalScale}
          fullWidth
          disabled={disabled}
          allowNegative={allowNegative}
          error={error}
          onBlur={onBlur}
          helperText={helperText}
          isNumericString
          label={label}
          placeholder={placeholder}
          autoFocus={autoFocus}
          thousandSeparator={thousandSeparator}
          customInput={TextField}
        />
      )}
    />
  );
};

export default NumberInput;
