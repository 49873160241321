import { gql } from '@apollo/client';

const CREATE_INVENTORY_TRANSFER_REQUEST = gql`
  mutation CREATE_INVENTORY_TRANSFER_REQUEST($input: CreateInventoryTransferRequestInput!) {
    createInventoryTransferRequest(input: $input) {
      viewer {
        id
      }
      inventorytransfer {
        id
      }
    }
  }
`;

export default CREATE_INVENTORY_TRANSFER_REQUEST;
