import React, { useRef, useState } from 'react';
import TableCell from '@material-ui/core/TableCell/TableCell';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AddChoice from './AddChoice';
import { Modal } from '../../../../../../blocks';

const ChoiceItem = ({
  id,
  cost,
  label,
  choiceId,
  numberOfMultipleChoices,
  description,
  ingredients,
  imageUrl,
  refetch,
  variables,
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [{ isDragging }, drag, drop] = useDrag({
    item: {
      type: 'Choice',
      id,
      cost: cost,
      label,
      choiceId,
      order: (numberOfMultipleChoices || 0) + 1,
      newChoice: true,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  const existingChoiceData = {
    id,
    choiceId,
    label,
    description,
    ingredients,
    imageUrl,
  };
  return (
    <>
      <TableCell
        component="th"
        align={'left'}
        scope="row"
        ref={ref}
        style={{ opacity: isDragging ? '0.5' : '1', cursor: isDragging && 'move' }}
      >
        {label} <Link onClick={() => handleOpen()}>{`ID-${choiceId}`}</Link>
      </TableCell>
      <Modal
        title={existingChoiceData?.choiceId ? 'Edit Choice' : 'Add Choice'}
        open={open}
        handleClose={handleClose}
      >
        <AddChoice
          handleClose={handleClose}
          existingChoiceData={existingChoiceData}
          refetch={refetch}
          variables={variables}
          update={true}
        />
      </Modal>
    </>
  );
};

export default ChoiceItem;
