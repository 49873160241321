import { gql } from '@apollo/client';

const ADD_ITEM_MULTIPLE = gql`
  mutation AddItemMultiple($input: AddItemMultipleInput!) {
    addItemMultiple(input: $input) {
      item {
        id
        multipleConnection {
          edges {
            node {
              id
              label
              description
              multipleId
              multipleChoiceConnection {
                edges {
                  node {
                    id
                    choice {
                      id
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ADD_ITEM_MULTIPLE;
