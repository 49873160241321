const menuSettings = {
  TREE_NODE_TYPES: {
    MENU: 'menu',
    CATEGORY: 'category',
    SUBCATEGORY: 'subcategory',
    ITEM: 'item',
    CHOICE: 'choice',
    INGREDIENT: 'ingredient',
  },
};

export default menuSettings;
