
import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
  section: {
    border: `2px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
    margin: "2rem 1rem",
    paddingBottom: "2rem",
  },
  root: {
    display: "flex",
    boxShadow: "none",
    margin: "0 1rem",
  },
  title: {
    marginTop: '-1.25rem',
    marginBottom: "1rem",
    background: theme.palette.background.paper,
    padding: '0 0.5rem',
    margin: '0 -0.5rem',
    fontSize: theme.typography.fontSizeL
  },
}));
