import { gql } from '@apollo/client';

const FETCH_MULTIPLE_CHOICE_TIERS = gql`
  query fetchMultipleChoiceTiers($id: Int, $choiceId: Int, $gqlMultipleId: ID!) {
    viewer {
      multiple(id: $gqlMultipleId) {
        multipleChoiceConnection(sort: { orderKey: ASC }, filter: { choiceId: { eq: $choiceId } }) {
          edges {
            node {
              price
              priceCap
            }
          }
        }
      }
      platformConnection {
        edges {
          node {
            id
            label
          }
        }
      }
      tierConnection {
        edges {
          node {
            id
            label
            tierId
          }
        }
      }
      multipleChoiceTierConnection(
        filter: { multipleId: { eq: $id }, choiceId: { eq: $choiceId } }
      ) {
        edges {
          node {
            id
            price
            priceCap
            multiple {
              label
              multipleId
            }
            tier {
              id
              label
            }
            choice {
              label
              choiceId
              cost
            }
            multipleChoiceTierPlatformConnection {
              edges {
                node {
                  id
                  priceCap
                  platform {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_MULTIPLE_CHOICE_TIERS;
