import { gql } from '@apollo/client';

const DELETE_JOB = gql`
  mutation deleteJob($jobId: Int!) {
    deleteJob(input: { jobId: $jobId }) {
      jobId
    }
  }
`;

export default DELETE_JOB;
