import { gql } from "@apollo/client";

export const FETCH_COUPON_TRIGGERS = gql`
  query couponTriggers {
    viewer {
      couponTriggerConnection {
        edges {
          node {
            id
            couponTriggerId
            label
          }
        }
      }
    }
  }
`;
