const mutationNames = {
  ActivationEmployeeMutationInput: 'ActivationEmployeeMutationInput',
  ActivationLocationPersonMutationInput: 'ActivationLocationPersonMutationInput',
  AddCategoryToTreeMutationInput: 'AddCategoryToTreeMutationInput',
  AddItemMultipleInput: 'AddItemMultipleInput',
  AddItemToTreeMutationInput: 'AddItemToTreeMutationInput',
  AddLineChoiceMutationInput: 'AddLineChoiceMutationInput',
  AddLineMultipleMutationInput: 'AddLineMultipleMutationInput',
  AddLineOptionsChoiceInput: 'AddLineOptionsChoiceInput',
  AddLineOptionsLineInput: 'AddLineOptionsLineInput',
  AddLineOptionsMultipleInput: 'AddLineOptionsMultipleInput',
  AddLineOptionsMutationInput: 'AddLineOptionsMutationInput',
  AddMenuToTreeMutationInput: 'AddMenuToTreeMutationInput',
  AddSubcategoryToTreeMutationInput: 'AddSubcategoryToTreeMutationInput',
  AddTaxgroupTaxrates: 'AddTaxgroupTaxrates',
  AddressInput: 'AddressInput',
  BulkLocationPersonUpdateInput: 'BulkLocationPersonUpdateInput',
  BulkLocationUpdateInput: 'BulkLocationUpdateInput',
  BuyLoyaltyCouponMutationInput: 'BuyLoyaltyCouponMutationInput',
  ChargebackRespondMutationInput: 'ChargebackRespondMutationInput',
  CheckPaytronixBalanceMutationInput: 'CheckPaytronixBalanceMutationInput',
  ClientCreateQuotecateringMultipleItemsMutationInput:
    'ClientCreateQuotecateringMultipleItemsMutationInput',
  ClockoutTimeshiftMutationInput: 'ClockoutTimeshiftMutationInput',
  CompletedTaskMutationInput: 'CompletedTaskMutationInput',
  ConfirmCurbsideNotificationInput: 'ConfirmCurbsideNotificationInput',
  ConfirmCurbsideNotificationQuotePersonInput: 'ConfirmCurbsideNotificationQuotePersonInput',
  ContactCustomerMutationInput: 'ContactCustomerMutationInput',
  CopyQuotecateringMutationInput: 'CopyQuotecateringMutationInput',
  CopyScheduleMutationInput: 'CopyScheduleMutationInput',
  CreateAddressMutationInput: 'CreateAddressMutationInput',
  CreateAllergenInput: 'CreateAllergenInput',
  CreateAllergenInputND: 'CreateAllergenInputND',
  CreateAllergenMutationInput: 'CreateAllergenMutationInput',
  CreateAutomaticLineOptionsChoiceInput: 'CreateAutomaticLineOptionsChoiceInput',
  CreateAutomaticLineOptionsLineInput: 'CreateAutomaticLineOptionsLineInput',
  CreateAutomaticLineOptionsMultipleInput: 'CreateAutomaticLineOptionsMultipleInput',
  CreateCategoryMutationInput: 'CreateCategoryMutationInput',
  CreateCateringLineWithAutomaticMutationInput: 'CreateCateringLineWithAutomaticMutationInput',
  CreateCateringPaymentMutationInput: 'CreateCateringPaymentV2MutationInput',
  CreateChoiceMutationInput: 'CreateChoiceMutationInput',
  CreateDashboardOrderPaymentMutationInput: 'CreateDashboardOrderPaymentMutationInput',
  CreateEmployeeMutationInput: 'CreateEmployeeMutationInput',
  CreateFreeOrderPaymentMutationInput: 'CreateFreeOrderPaymentMutationInput',
  CreateGiftCardPaymentMutationInput: 'CreateGiftCardPaymentMutationInput',
  CreateHouseAccountDepositMutationInput: 'CreateHouseAccountDepositMutationInput',
  CreateHouseaccountMutationInput: 'CreateHouseaccountMutationInput',
  CreateIngredientAllergenNutrientMutationInput: 'CreateIngredientAllergenNutrientMutationInput',
  CreateIngredientInput: 'CreateIngredientInput',
  CreateIngredientMutationInput: 'CreateIngredientMutationInput',
  CreateIngredientMutationNDInput: 'CreateIngredientMutationNDInput',
  CreateItemMutationInput: 'CreateItemMutationInput',
  CreateJobBreakRuleMutationInput: 'CreateJobBreakRuleMutationInput',
  CreateLineChoiceClient: 'CreateLineChoiceClient',
  CreateLineChoicesInput: 'CreateLineChoicesInput',
  CreateLineLoyaltyChoicesInput: 'CreateLineLoyaltyChoicesInput',
  CreateLineLoyaltyMultiplesInput: 'CreateLineLoyaltyMultiplesInput',
  CreateLineMultipleClient: 'CreateLineMultipleClient',
  CreateLineMultiplesInput: 'CreateLineMultiplesInput',
  CreateLineMutationInput: 'CreateLineMutationInput',
  CreateLineWithAutomaticMutationInput: 'CreateLineWithAutomaticMutationInput',
  CreateLocationEmployeeMutationInput: 'CreateLocationEmployeeMutationInput',
  CreateLocationExclusiveDeliveryAddressMutationInput:
    'CreateLocationExclusiveDeliveryAddressMutationInput',
  CreateLocationMutationInput: 'CreateLocationMutationInput',
  CreateLocationPersonMutationInput: 'CreateLocationPersonMutationInput',
  CreateLocationStripeMutationInput: 'CreateLocationStripeMutationInput',
  CreateLocationWeekdayMutationInput: 'CreateLocationWeekdayMutationInput',
  CreateLocationsInput: 'CreateLocationsInput',
  CreateLoyaltyLineMutationInput: 'CreateLoyaltyLineMutationInput',
  CreateLoyaltyclaimedreceiptMutationInput: 'CreateLoyaltyclaimedreceiptMutationInput',
  CreateMenuCategoryMutationInput: 'CreateMenuCategoryMutationInput',
  CreateMenuMutationInput: 'CreateMenuMutationInput',
  CreateMultipleChoiceTierInput: 'CreateMultipleChoiceTierInput',
  CreateMultipleInput: 'CreateMultipleInput',
  CreateMultipleMutationInput: 'CreateMultipleMutationInput',
  CreateMultipleNDInput: 'CreateMultipleNDInput',
  CreateMultipleNDMutationInput: 'CreateMultipleNDMutationInput',
  CreateMultiplegroupMutationInput: 'CreateMultiplegroupMutationInput',
  CreateNutrientInput: 'CreateNutrientInput',
  CreateNutrientInputND: 'CreateNutrientInputND',
  CreateNutrientMutationInput: 'CreateNutrientMutationInput',
  CreateOvertimerulesMutationInput: 'CreateOvertimerulesMutationInput',
  CreatePaymentMutationInput: 'CreatePaymentMutationInput',
  CreatePaymentTempMutationInput: 'CreatePaymentTempMutationInput',
  CreatePersonAddressMutationInput: 'CreatePersonAddressMutationInput',
  CreatePersonAddressPhoneMutationInput: 'CreatePersonAddressPhoneMutationInput',
  CreatePersonLocationMutationInput: 'CreatePersonLocationMutationInput',
  CreatePersonMutationInput: 'CreatePersonMutationInput',
  CreatePersonvehicleMutationInput: 'CreatePersonvehicleMutationInput',
  CreatePhoneMutationInput: 'CreatePhoneMutationInput',
  CreateQuickOrderPaymentMutationInput: 'CreateQuickOrderPaymentMutationInput',
  CreateQuickOrderStripePaymentMutationInput: 'CreateQuickOrderStripePaymentMutationInput',
  CreateQuoteCateringItemInput: 'CreateQuoteCateringItemInput',
  CreateQuoteCateringMultiplesInput: 'CreateQuoteCateringMultiplesInput',
  CreateQuoteMutationInput: 'CreateQuoteMutationInput',
  CreateQuotecateringLocation: 'CreateQuotecateringLocation',
  CreateQuotecateringNDMutationInput: 'CreateQuotecateringNDMutationInput',
  CreateRefundMutationInput: 'CreateRefundMutationInput',
  CreateRemoveLocationInput: 'CreateRemoveLocationInput',
  CreateScheduleMutationInput: 'CreateScheduleMutationInput',
  CreateScheduleweeklysalesprojectionMutationInput:
    'CreateScheduleweeklysalesprojectionMutationInput',
  CreateSubcategoryMutationInput: 'CreateSubcategoryMutationInput',
  CreateSurveyresponseMutationInput: 'CreateSurveyresponseMutationInput',
  CreateTaxgroupMutationInput: 'CreateTaxgroupMutationInput',
  CreateTaxgroupTaxrateMutationInput: 'CreateTaxgroupTaxrateMutationInput',
  CreateTaxrateMutationInput: 'CreateTaxrateMutationInput',
  CreateTestPrintMutationInput: 'CreateTestPrintMutationInput',
  CreateTierInput: 'CreateTierInput',
  CreateTierMutationInput: 'CreateTierMutationInput',
  CreateTimeshiftMutationInput: 'CreateTimeshiftMutationInput',
  CreateTipPaymentMutationInput: 'CreateTipPaymentV2MutationInput',
  CreateTipPaymentTempMutationInput: 'CreateTipPaymentTempMutationInput',
  CreateUpdateLoginHierarchy: 'CreateUpdateLoginHierarchy',
  CreateUpdatePersonHierarchyInput: 'CreateUpdatePersonHierarchyInput',
  CreateWageRuleMutationInput: 'CreateWageRuleMutationInput',
  DashboardLoginMutationInput: 'DashboardLoginMutationInput',
  DashboardLoginWithMicroserviceMutationInput: 'DashboardLoginWithMicroserviceMutationInput',
  DeactivateCustomerMutationInput: 'DeactivateCustomerMutationInput',
  DeactivateMenuMutationInput: 'DeactivateMenuMutationInput',
  DeleteCreditCardMutationInput: 'DeleteCreditCardMutationInput',
  DeleteUsergroupMutationInput: 'DeleteUsergroupMutationInput',
  DeleteUsergroupUserMutationInput: 'DeleteUsergroupUserMutationInput',
  DeliveryRangeFilter: 'DeliveryRangeFilter',
  FavoriteQuotecateringMutationInput: 'FavoriteQuotecateringMutationInput',
  FinalizePayrollMutationInput: 'FinalizePayrollMutationInput',
  ForgotPasswordMutationInput: 'ForgotPasswordMutationInput',
  GetLocationListMutationInput: 'GetLocationListMutationInput',
  GuestLoginMutationInput: 'GuestLoginMutationInput',
  ItemsInput: 'ItemsInput',
  ItemsList: 'ItemsList',
  JobsCreate: 'JobsCreate',
  LineChoiceInput: 'LineChoiceInput',
  LineMultipleInput: 'LineMultipleInput',
  LocationBulkGeneralInfoIds: 'LocationBulkGeneralInfoIds',
  LocationHandofCategoryIds: 'LocationHandofCategoryIds',
  LocationIds: 'LocationIds',
  LocationIdsOrderSettings: 'LocationIdsOrderSettings',
  LocationIngredientsIds: 'LocationIngredientsIds',
  LocationItemIds: 'LocationItemIds',
  LocationItemMenuIds: 'LocationItemMenuIds',
  LocationItemMenuSettingsIds: 'LocationItemMenuSettingsIds',
  LocationItemOloSettingsIds: 'LocationItemOloSettingsIds',
  LocationItemsArray: 'LocationItemsArray',
  LocationMenuIngredientsIds: 'LocationMenuIngredientsIds',
  LocationNotesIds: 'LocationNotesIds',
  LocationPageInput: 'LocationPageInput',
  LoginMutationInput: 'LoginMutationInput',
  LoginSocialMutationInput: 'LoginSocialMutationInput',
  LoginWithMicroserviceMutationInput: 'LoginWithMicroserviceMutationInput',
  LoyaltyClaimedReceiptPageInput: 'LoyaltyClaimedReceiptPageInput',
  ManipulateUsergroupMutationInput: 'ManipulateUsergroupMutationInput',
  ManipulateUsergroupUserMutationInput: 'ManipulateUsergroupUserMutationInput',
  MultipleChoiceArray: 'MultipleChoiceArray',
  MultipleIdArray: 'MultipleIdArray',
  NewLocationnoteMutationInput: 'NewLocationnoteMutationInput',
  NewQuoteNoteMutationInput: 'NewQuoteNoteMutationInput',
  NewQuoteTaskMutationInput: 'NewQuoteTaskMutationInput',
  OwnerActivationEmployeeMutationInput: 'OwnerActivationEmployeeMutationInput',
  PayPalOffboardingMutationInput: 'PayPalOffboardingMutationInput',
  PermitLocationActivityMutationInput: 'PermitLocationActivityMutationInput',
  PostPageInput: 'PostPageInput',
  PrinterstationSubcategoryLocationTypePageInput: 'PrinterstationSubcategoryLocationTypePageInput',
  QuoteCreateGuestMutationInput: 'QuoteCreateGuestMutationInput',
  QuoteInfoMutationInput: 'QuoteInfoMutationInput',
  QuoteLoginMutationInput: 'QuoteLoginMutationInput',
  QuoteLoginSocialMutationInput: 'QuoteLoginSocialMutationInput',
  QuotePageInput: 'QuotePageInput',
  QuotePersonInput: 'QuotePersonInput',
  QuoteSignUpMutationInput: 'QuoteSignUpMutationInput',
  RePrintMutationInput: 'RePrintMutationInput',
  ReactivateQuoteMutationInput: 'ReactivateQuoteMutationInput',
  ReadNotificationMutationInput: 'ReadNotificationMutationInput',
  RedeemLoyaltyCouponMutationInput: 'RedeemLoyaltyCouponMutationInput',
  RemoveAllLocationExclusiveDeliveryAddressMutationInput:
    'RemoveAllLocationExclusiveDeliveryAddressMutationInput',
  RemoveAllergenMutationInput: 'RemoveAllergenMutationInput',
  RemoveCategoryFromTreeMutationInput: 'RemoveCategoryFromTreeMutationInput',
  RemoveCategoryMutationInput: 'RemoveCategoryMutationInput',
  RemoveCateringLineMutationInput: 'RemoveCateringLineMutationInput',
  RemoveChoiceMutationInput: 'RemoveChoiceMutationInput',
  RemoveCouponMutationInput: 'RemoveCouponMutationInput',
  RemoveHouseaccountMutationInput: 'RemoveHouseaccountMutationInput',
  RemoveIngredientMutationInput: 'RemoveIngredientMutationInput',
  RemoveItemFromTreeMutationInput: 'RemoveItemFromTreeMutationInput',
  RemoveItemIngredientInput: 'RemoveItemIngredientInput',
  RemoveItemMultipleInput: 'RemoveItemMultipleInput',
  RemoveItemMutationInput: 'RemoveItemMutationInput',
  RemoveJobBreakRuleMutationInput: 'RemoveJobBreakRuleMutationInput',
  RemoveLineChoiceMutationInput: 'RemoveLineChoiceMutationInput',
  RemoveLineMultipleMutationInput: 'RemoveLineMultipleMutationInput',
  RemoveLineMutationInput: 'RemoveLineMutationInput',
  RemoveLocationEmployeeMutationInput: 'RemoveLocationEmployeeMutationInput',
  RemoveLocationExclusiveDeliveryAddressMutationInput:
    'RemoveLocationExclusiveDeliveryAddressMutationInput',
  RemoveLocationStripeMutationInput: 'RemoveLocationStripeMutationInput',
  RemoveLocationWeekdayMutationInput: 'RemoveLocationWeekdayMutationInput',
  RemoveLocationnoteMutationInput: 'RemoveLocationnoteMutationInput',
  RemoveMenuFromTreeMutationInput: 'RemoveMenuFromTreeMutationInput',
  RemoveMultipleChoiceMutationInput: 'RemoveMultipleChoiceMutationInput',
  RemoveMultipleMutationInput: 'RemoveMultipleMutationInput',
  RemoveNutrientMutationInput: 'RemoveNutrientMutationInput',
  RemoveOrderItemMutationInput: 'RemoveOrderItemMutationInput',
  RemoveOvertimerulesMutationInput: 'RemoveOvertimerulesMutationInput',
  RemovePersonAddressMutationInput: 'RemovePersonAddressMutationInput',
  RemovePersonLocationMutationInput: 'RemovePersonLocationMutationInput',
  RemovePersonvehicleMutationInput: 'RemovePersonvehicleMutationInput',
  RemovePostMutationInput: 'RemovePostMutationInput',
  RemoveQuoteCouponMutationInput: 'RemoveQuoteCouponMutationInput',
  RemoveQuotecateringMutationInput: 'RemoveQuotecateringMutationInput',
  RemoveScheduleMutationInput: 'RemoveScheduleMutationInput',
  RemoveSubcategoryFromTreeMutationInput: 'RemoveSubcategoryFromTreeMutationInput',
  RemoveTaxgroupMutationInput: 'RemoveTaxgroupMutationInput',
  RemoveTaxrateMutationInput: 'RemoveTaxrateMutationInput',
  RemoveTierMutationInput: 'RemoveTierMutationInput',
  RemoveTimeshiftMutationInput: 'RemoveTimeshiftMutationInput',
  RemoveWageRuleMutationInput: 'RemoveWageRuleMutationInput',
  ReorderQuoteMutationInput: 'ReorderQuoteMutationInput',
  ReplaceLineChoiceInput: 'ReplaceLineChoiceInput',
  ReplaceLineMultipleInput: 'ReplaceLineMultipleInput',
  ReplaceLineMutationInput: 'ReplaceLineMutationInput',
  ResetPasswordMutationInput: 'ResetPasswordMutationInput',
  RestoreItemMutationInput: 'RestoreItemMutationInput',
  SaveCreditCardMutationInput: 'SaveCreditCardMutationInput',
  SaveUsergroupRulesMutationInput: 'SaveUsergroupRulesMutationInput',
  ScheduleItemChoicesPriceInput: 'ScheduleItemChoicesPriceInput',
  ScheduleMultipleChoicesPriceInput: 'ScheduleMultipleChoicesPriceInput',
  SchedulesInput: 'SchedulesInput',
  SendEmailMutationInput: 'SendEmailMutationInput',
  SendFeedbackEmailMutationInput: 'SendFeedbackEmailMutationInput',
  SendLocationMutationInput: 'SendLocationMutationInput',
  SendPdfEmailMutationInput: 'SendPdfEmailMutationInput',
  SetCategorySubcategoriesInput: 'SetCategorySubcategoriesInput',
  SetDefaultCreditCardMutationInput: 'SetDefaultCreditCardMutationInput',
  SetItemChoicesInput: 'SetItemChoicesInput',
  SetItemChoicesMutationInput: 'SetItemChoicesMutationInput',
  SetItemIngredientsInput: 'SetItemIngredientsInput',
  SetItemIngredientsMutationInput: 'SetItemIngredientsMutationInput',
  SetLocalFavoriteMutationInput: 'SetLocalFavoriteMutationInput',
  SetMenuCategoriesInput: 'SetMenuCategoriesInput',
  SetMenuCategoriesMutationInput: 'SetMenuCategoriesMutationInput',
  SetMenuCategoriesSubcategoriesItemsMutationInput:
    'SetMenuCategoriesSubcategoriesItemsMutationInput',
  SetMenuCategoriesSubcategoriesMutationInput: 'SetMenuCategoriesSubcategoriesMutationInput',
  SetQuoteAddressMutationInput: 'SetQuoteAddressMutationInput',
  SetQuoteCategoryMutationInput: 'SetQuoteCategoryMutationInput',
  SetQuoteCouponMutationInput: 'SetQuoteCouponMutationInput',
  SetQuoteDeliveryChargeMutationInput: 'SetQuoteDeliveryChargeMutationInput',
  SetQuoteHandoffMutationInput: 'SetQuoteHandoffMutationInput',
  SetQuoteInfoMutationInput: 'SetQuoteInfoMutationInput',
  SetQuoteLocationMutationInput: 'SetQuoteLocationMutationInput',
  SetQuoteMenuMutationInput: 'SetQuoteMenuMutationInput',
  SetQuotePersonMutationInput: 'SetQuotePersonMutationInput',
  SetQuoteStageMutationInput: 'SetQuoteStageMutationInput',
  SetQuoteStepMutationInput: 'SetQuoteStepMutationInput',
  SetQuoteTipMutationInput: 'SetQuoteTipMutationInput',
  SetQuoteVehicleMutationInput: 'SetQuoteVehicleMutationInput',
  SetStatusMutationInput: 'SetStatusMutationInput',
  SignUpMutationInput: 'SignUpMutationInput',
  SquareOffboardingMutationInput: 'SquareOffboardingMutationInput',
  StateCityLocationCascadePageInput: 'StateCityLocationCascadePageInput',
  SubcategoriesList: 'SubcategoriesList',
  SubcategoryItemsList: 'SubcategoryItemsList',
  SurveyanswerInput: 'SurveyanswerInput',
  TTDSurveyanswerInput: 'TTDSurveyanswerInput',
  ToggleAllLocationsIngredientMutationInput: 'ToggleAllLocationsIngredientMutationInput',
  ToggleAllLocationIngredientsMutationInput: 'toggleAllIngredientsOnLocation',
  ToggleArchiveCouponMutationInput: 'ToggleArchiveCouponMutationInput',
  ToggleDriverMutationInput: 'ToggleDriverMutationInput',
  ToggleItemLocationOLOMutationInput: 'ToggleItemLocationOLOMutationInput',
  ToggleLocalFavoriteMutationInput: 'ToggleLocalFavoriteMutationInput',
  ToggleLocationHandoffCategoryMutationInput: 'ToggleLocationHandoffCategoryMutationInput',
  ToggleLocationIngredientMutationInput: 'ToggleLocationIngredientMutationInput',
  ToggleLocationItemMutationInput: 'ToggleLocationItemMutationInput',
  ToggleLocationMutationInput: 'ToggleLocationMutationInput',
  UpdateAllergenInputND: 'UpdateAllergenInputND',
  UpdateAllergenMutationInput: 'UpdateAllergenMutationInput',
  UpdateCategoryMutationInput: 'UpdateCategoryMutationInput',
  UpdateChoiceMutationInput: 'UpdateChoiceMutationInput',
  UpdateCouponMutationInput: 'UpdateCouponMutationInput',
  UpdateCreditCardMutationInput: 'UpdateCreditCardMutationInput',
  UpdateDefaultAddressMutationInput: 'UpdateDefaultAddressMutationInput',
  UpdateDefaultPersonvehicleMutationInput: 'UpdateDefaultPersonvehicleMutationInput',
  UpdateDevicePrinterstationInput: 'UpdateDevicePrinterstationInput',
  UpdateEmployeeMutationInput: 'UpdateEmployeeMutationInput',
  UpdateHouseaccountMutationInput: 'UpdateHouseaccountMutationInput',
  UpdateIngredientInput: 'UpdateIngredientInput',
  UpdateIngredientMutationInput: 'UpdateIngredientMutationInput',
  UpdateIngredientMutationNDInput: 'UpdateIngredientMutationNDInput',
  UpdateItemChoicesList: 'UpdateItemChoicesList',
  UpdateItemChoicesPriceInput: 'UpdateItemChoicesPriceInput',
  UpdateItemMultipleInput: 'UpdateItemMultipleInput',
  UpdateItemMultiplesChoicesList: 'UpdateItemMultiplesChoicesList',
  UpdateItemMultiplesList: 'UpdateItemMultiplesList',
  UpdateItemMutationInput: 'UpdateItemMutationInput',
  UpdateJobBreakRuleMutationInput: 'UpdateJobBreakRuleMutationInput',
  UpdateLineChoicesInput: 'UpdateLineChoicesInput',
  UpdateLineMultiplesInput: 'UpdateLineMultiplesInput',
  UpdateLineMutationInput: 'UpdateLineMutationInput',
  UpdateLineNoteMutationInput: 'UpdateLineNoteMutationInput',
  UpdateLinePriceMutationInput: 'UpdateLinePriceMutationInput',
  UpdateLocationEmployeeMutationInput: 'UpdateLocationEmployeeMutationInput',
  UpdateLocationHandoffCategories: 'UpdateLocationHandoffCategories',
  UpdateLocationHandoffCategoryMutationInput: 'UpdateLocationHandoffCategoryMutationInput',
  UpdateLocationHandoffOrderSettings: 'UpdateLocationHandoffOrderSettings',
  UpdateLocationHours: 'UpdateLocationHours',
  UpdateLocationIngredientMutationInput: 'UpdateLocationIngredientMutationInput',
  UpdateLocationItemChoiceMutationInput: 'UpdateLocationItemChoiceMutationInput',
  UpdateLocationItemMutationInput: 'UpdateLocationItemMutationInput',
  UpdateLocationMultipleChoiceMutationInput: 'UpdateLocationMultipleChoiceMutationInput',
  UpdateLocationMutationInput: 'UpdateLocationMutationInput',
  UpdateLocationOrderSettingsMutationInput: 'UpdateLocationOrderSettingsMutationInput',
  UpdateLocationTimeSlotMutationInput: 'UpdateLocationTimeSlotMutationInput',
  UpdateLocationWeekdayMutationInput: 'UpdateLocationWeekdayMutationInput',
  UpdateLocationsInput: 'UpdateLocationsInput',
  UpdateMenuMutationInput: 'UpdateMenuMutationInput',
  UpdateMultipleChoiceTierInput: 'UpdateMultipleChoiceTierInput',
  UpdateMultipleChoicesPriceInput: 'UpdateMultipleChoicesPriceInput',
  UpdateMultipleInput: 'UpdateMultipleInput',
  UpdateMultipleMutationInput: 'UpdateMultipleMutationInput',
  UpdateMultiplesOrderInput: 'UpdateMultiplesOrderInput',
  UpdateMultiplesOrderMutationInput: 'UpdateMultiplesOrderMutationInput',
  UpdateNutrientInputND: 'UpdateNutrientInputND',
  UpdateNutrientMutationInput: 'UpdateNutrientMutationInput',
  UpdateOvertimerulesMutationInput: 'UpdateOvertimerulesMutationInput',
  UpdatePasswordMutationInput: 'UpdatePasswordMutationInput',
  UpdatePaymentProcessorInput: 'UpdatePaymentProcessorInput',
  UpdatePersonAddressMutationInput: 'UpdatePersonAddressMutationInput',
  UpdatePersonAddressPhoneMutationInput: 'UpdatePersonAddressPhoneMutationInput',
  UpdatePersonLiteMutationInput: 'UpdatePersonLiteMutationInput',
  UpdatePersonMutationInput: 'UpdatePersonMutationInput',
  UpdatePersonvehicleMutationInput: 'UpdatePersonvehicleMutationInput',
  UpdateQuoteMutationInput: 'UpdateQuoteMutationInput',
  UpdateQuotecateringLocation: 'UpdateQuotecateringLocation',
  UpdateQuotecateringNDMutationInput: 'UpdateQuotecateringNDMutationInput',
  UpdateScheduleMutationInput: 'UpdateScheduleMutationInput',
  UpdateScheduleweeklysalesprojectionMutationInput:
    'UpdateScheduleweeklysalesprojectionMutationInput',
  UpdateSubcategoryMutationInput: 'UpdateSubcategoryMutationInput',
  UpdateSubcategoryOrderKeysInput: 'UpdateSubcategoryOrderKeysInput',
  UpdateTaxgroupMutationInput: 'UpdateTaxgroupMutationInput',
  UpdateTaxrateMutationInput: 'UpdateTaxrateMutationInput',
  UpdateTierInput: 'UpdateTierInput',
  UpdateTierMutationInput: 'UpdateTierMutationInput',
  UpdateTimeshiftMutationInput: 'UpdateTimeshiftMutationInput',
  UpdateUsergroupLabelMutationInput: 'UpdateUsergroupLabelMutationInput',
  UpdateWageRuleMutationInput: 'UpdateWageRuleMutationInput',
  UploadLocationMenuMutationInput: 'UploadLocationMenuMutationInput',
  UploadMenuMutationInput: 'UploadMenuMutationInput',
  addAutomaticLineOptionsChoiceInput: 'addAutomaticLineOptionsChoiceInput',
  addAutomaticLineOptionsLineInput: 'addAutomaticLineOptionsLineInput',
  addAutomaticLineOptionsMultipleInput: 'addAutomaticLineOptionsMultipleInput',
  addAutomaticLineOptionsMutationInput: 'addAutomaticLineOptionsMutationInput',
  addPromoPointsInput: 'addPromoPointsInput',
  buyLoyaltyStoreItemInput: 'buyLoyaltyStoreItemInput',
  clientResponseQuotecateringInput: 'clientResponseQuotecateringInput',
  createHierarchyMutationInput: 'createHierarchyMutationInput',
  createJobMutationInput: 'createJobMutationInput',
  createLocationHierarchyMutationInput: 'createLocationHierarchyMutationInput',
  createPosRoleInput: 'createPosRoleInput',
  createWeekday: 'createWeekday',
  deleteJobMutationInput: 'deleteJobMutationInput',
  locations: 'locations',
  locationsND: 'locationsND',
  removeHierarchyMutationInput: 'removeHierarchyMutationInput',
  removeLocationHierarchyMutationInput: 'removeLocationHierarchyMutationInput',
  scheduleItemMultipleChoicePriceMutationInput: 'scheduleItemMultipleChoicePriceMutationInput',
  setPersonNotificationToShownMutationInput: 'setPersonNotificationToShownMutationInput',
  toggleItemMultipleOptionalInput: 'toggleItemMultipleOptionalInput',
  updateBulkGeneralInfoMutationInput: 'updateBulkGeneralInfoMutationInput',
  updateHierarchyMutationInput: 'updateHierarchyMutationInput',
  updateItemMultipleChoicePriceMutationInput: 'updateItemMultipleChoicePriceMutationInput',
  updateJobMutationInput: 'updateJobMutationInput',
  updateLocationItemMultipleChoicePriceMutationInput:
    'updateLocationItemMultipleChoicePriceMutationInput',
  updateLocationItemMultipleChoicePriceMutationInputND:
    'updateLocationItemMultipleChoicePriceMutationInputND',
  updateLocationItemMultipleChoicePriceMutationNDInput:
    'updateLocationItemMultipleChoicePriceMutationNDInput',
  updatePosRoleInput: 'updatePosRoleInput',
  updatedWeekday: 'updatedWeekday',
  removeCreditCard: 'removeCreditCardInput',
};

export default mutationNames;
