import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  imageContainer: {
    padding: theme.spacing(4),
    width: '100%',
  },
  image: {
    width: "100%",
    height: 'auto',
  },
  link: {
    color: console.log(theme) ||theme.palette.text.primary,
  },
  manualWrapper: {
      width: '100%'
  },
  title: {
      marginBottom: theme.spacing(8)
  }
});

export default makeStyles(Styles);
