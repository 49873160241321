import { useState } from 'react';
import { Button, Link as MuiLink } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import ConnectionTable from "../../../../blocks/ConnectionTable";
import { VENDORS_LIST } from "../../../../../apollo/queries";
import { REMOVE_VENDOR } from "../../../../../apollo/mutations";
import { paths, COLOR } from "../../../../../constants";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { uglifyId, formatConnectionName } from '../../../../../utils';

const VendorsTable = () => {
  const history = useHistory();
  const { setSnackbar } = useSnackbar();
  const [connectionVariables, setConnectionVariables] = useState({});

  const [removeVendor, { loading: removeVendorLoading }] = useMutation(REMOVE_VENDOR, {
    update: (cache, { data: { removeVendor } }) => {
      const { dynamicConnectionName } = formatConnectionName('vendor', connectionVariables);
      cache.modify({
        id: cache.identify(removeVendor.viewer),
        fields: {
          [dynamicConnectionName]: existingVendorConnection => {
            return {
              ...existingVendorConnection,
              totalCount: existingVendorConnection.totalCount - 1,
              edges: [...existingVendorConnection.edges.filter(
                vendor =>
                  vendor.node.__ref !== `Vendor:${uglifyId('Vendor', removeVendor.removedVendorId)}`
              )],
            }
          }
        }
      });
    },
    onCompleted: (data) => {
      setSnackbar({
        open: true,
        type: "success",
        text: "Vendor deleted",
      });
    },
    onError: (error) => {
      setSnackbar({
        open: true,
        type: "error",
        text: error.message,
      });
    }
  });

  const onDeleteConfirmation = (row, vars, refetch) => {
    setSnackbar({
      open: true,
      type: "info",
      text: "Deleting vendor...",
    });

    removeVendor({
      variables: { 
        input: { vendorId: row.rawId },
        ...vars
      },
    })
  };

  return (
    <div id="vendors">
      <ConnectionTable
        setConnectionVariables={setConnectionVariables}
        query={VENDORS_LIST}
        shouldRefetch={false}
        initialQueryVariables={{
          sort: { id: "ASC" },
          first: 15,
          fieldGroupOnly: 76,
        }}
        title="Vendors"
        customActionsTop={(data, query) => {
          return (
            <Link to={paths.SETTINGS_VENDOR_NEW}>
              <Button disabled={removeVendorLoading}>New Vendor</Button>
            </Link>
          );
        }}
        onTableRowClick={(row, data, variables) => {
          history.push(`/spark/system-settings/vendors/${row.id}/generalInfo`);
        }}
        structureTable={(data) =>
          data?.viewer?.vendorConnection?.edges.map((edge) => {
            return {
              id: edge.node.vendorId,
              label: edge.node.label,
              orderUrl: edge.node.orderUrl,
              contactName: edge.node.contactName,
              email: edge.node.email,
              rawId: edge.node.id,
            };
          })
        }
        handleDeleteRow={onDeleteConfirmation}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this vendor?"
        columns={[
          { title: "ID", field: "id" },
          { title: "Name", field: "label" },
          {
            title: "Link",
            field: "orderUrl",
            disableSort: true,
            render: (data, vars,row) => (
              <MuiLink
                component='a'
                variant='body2'
                href={data && data.includes('http') ?
                  data?.trim() :
                  `//${data?.trim()}`}
                rel="noreferrer"
                target="_blank"
              >
                {data}
              </MuiLink>
            ),
            cellStyle: {
              color: COLOR.INFO,
            },
          },
          { title: "Contact Name", field: "contactName" },
          { 
            title: "Email", 
            field: "email", 
            disableSort: true,
            cellStyle: {
              color: COLOR.INFO,
            },
          },
        ]}
      />
    </div>
  );
}

export default VendorsTable;
