import React from 'react';
import PaperMain from '../../../../blocks/PaperMain';
import SideBarSectionsLayout from '../../../../blocks/SideBarSectionsLayout';
import Categories from './Categories';
import Subcategories from './Subcategories';
import GeneralLedgerAccounts from './GeneralLedgerAccounts';
import ReportingCategories from './ReportingCategories';

const sections = [
  { name: 'Categories', id: 'categories', Component: Categories },
  { name: 'Subcategories', id: 'subcategories', Component: Subcategories },
  { name: 'GL Accounts', id: 'generalLedgerAccounts', Component: GeneralLedgerAccounts },
  { name: 'Reporting Categories', id: 'reportingCategories', Component: ReportingCategories },
];

const CategorySettings = () => {
  return (
    <>
      <SideBarSectionsLayout sectionList={sections}>
        <PaperMain>
          {sections.map(({ id, Component }) => (
            <div key={id} id={id} style={{ width: '100%' }}>
              <Component />
            </div>
          ))}
        </PaperMain>
      </SideBarSectionsLayout>
    </>
  );
};

export default CategorySettings;
