import React from 'react'
import ManualView from "./View"

function Manual({control, manualTriggers, errors, codeGenerationType}) {
  return (
    <div>
      <ManualView manualTriggers={manualTriggers} control={control} errors={errors} codeGenerationType={codeGenerationType} />
    </div>
  )
}

export default Manual
