import { gql } from '@apollo/client';

const CUSTOMER = gql`
  query Customer($filter: [PersonFilter]) {
    viewer {
      id
      personConnection(filter: $filter) {
        edges {
          node {
            id
            dateOfBirth
            firstName
            lastName
            phone {
              id
              phone
            }
            personId
            address {
              id
              address
              city
              zip
              additionalInfo
              state {
                label
                stateId
              }
            }
            login {
              id
              loginId
              referralCode
              inactive
              lifetimePoints
              lifetimeBonusPoints
              email
              spendablePoints
              phone
              brand {
                id
                brandId
                label
              }
            }
            stripeAccount
          }
        }
      }
    }
  }
`;

export default CUSTOMER;
