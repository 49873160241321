import { gql } from '@apollo/client';
import { addressFragment, personFragment } from '../../fragments';

const ACTIVE_LOCATIONS_FOR_EMPLOYEE = gql`
  query ActiveLocationsForEmployee($personId: ID!, $locationId: Int) {
    viewer {
      id
      person(id: $personId, fieldGroup: 68) {
        permissions
        ...PersonData
        schedulePreference
        pincode(locationId: $locationId)
        hireDate(locationId: $locationId)
        terminationDate(locationId: $locationId)
        address {
          ...AddressData
        }
        login {
          employeeInactive
          usergroupConnection {
            edges {
              node {
                id
                label
              }
            }
          }
        }
        locationPersonConnection {
          edges {
            node {
              id
              inactive
              locationId
              location {
                id
                label
              }
              scheduleDeactivationEmployeeConnection {
                edges {
                  node {
                    id
                    deactivationDate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${addressFragment.address}
  ${personFragment.person}
`;

export default ACTIVE_LOCATIONS_FOR_EMPLOYEE;
