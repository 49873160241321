import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  heading: {
    marginBottom: theme.spacing(4),
  },
  titleRoot: {
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(0),
  },
  textContent: {
    color: theme.typography.subtitle1.color,
    marginBottom: theme.spacing(4),
  },
});

const BankSettingsStyles = makeStyles(Styles);

export default BankSettingsStyles;
