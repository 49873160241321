import React from "react";
import { Controller } from "react-hook-form";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const DateInput = ({ name, control, defaultValue = new Date(), rules, label, fullWidth }) => {
  const [selectedDate, setSelectedDate] = React.useState(defaultValue ? new Date(defaultValue.toString().replace(/-/g, "/")) : new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        as={
          <KeyboardDatePicker
            onChange={handleDateChange}
            value={selectedDate}
            fullWidth={fullWidth}
          />
        }
        control={control}
        rules={rules}
        name={name}
        label={label}
        id="date-picker-inline"
        variant="inline"
        autoOk
        defaultValue={selectedDate}
        onChange={handleDateChange}
        value={selectedDate}
        format="MM/dd/yyyy"
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateInput;
