import { gql } from '@apollo/client';

const FINALIZE_PAYROLL = gql`
  mutation FinalizePayroll($input: FinalizePayrollMutationInput!) {
    finalizePayroll(input: $input) {
      clientMutationId
    }
  }
`;

export default FINALIZE_PAYROLL;
