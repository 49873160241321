import { gql } from "@apollo/client";

export const FETCH_WEEKDAYS = gql`
  query fetchWeekdays {
    viewer {
      id
      weekdayConnection(filter: { showHoliday: { eq: true } }) {
        edges {
          node {
            id
            label
            weekdayId
            type
          }
        }
      }
    }
  }
`;
