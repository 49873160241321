import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import moment from "moment-timezone";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import { getDisputeInfo, toFixed10 } from '../../../../../utils';

function ChargebackNotification({ handleOpenChargebackModal,handleOpenContactCustomerModal, data }) {
  let { disputeDueBy, disputeReason, amount } = getDisputeInfo(data);

  disputeDueBy = moment(disputeDueBy).format("LLL");
  return (
    <Card
      style={{
        borderLeft: "5px solid red",
        marginBottom: "1rem",
        borderRadius: "5px",
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div>
            <ReportProblemOutlinedIcon
              style={{ color: "red" }}
              fontSize="large"
            />
          </div>
          <div>
            <h1 style={{ color: "red" }}>You have received a chargeback</h1>
          </div>
        </div>
        <p>
          The customer has initiated a chargeback for this order through thier
          credit card issuer.
          <br />
          This has led to an immediate
          <strong> refund of ${toFixed10(amount / 100, 2)}</strong> and a{" "}
          <strong>$15.00 chargeback fee</strong>.
        </p>
        <p>
          <strong>CHARGEBACK REASON: {disputeReason.toUpperCase()}</strong>
          <br />
          We recommend getting in touch with the customer directly to resolve.
        </p>
        <Divider style={{ margin: "0" }} />
        <div
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "4fr 1fr",
          }}
        >
          <div>
            <p>
              Respond to chargeback before <strong> {disputeDueBy} </strong> for
              a chance to get your money back, including the chargeback fee.
              When you submit your evidence, we'll compile and send it to the
              appropriate financial partner, and then email you as soon as
              they've reached a decision (usually within 60 days).{" "}
              <strong>Evidence submission is final</strong>, which means you can
              submit evidence <strong>only once</strong>, after that no further
              changes will be allowed. However, you can use "Save for later"
              option where your response will be saved for editing instead of
              submitting it immediately. We will automatically submit any saved
              evidence at the due date on {disputeDueBy}
            </p>
          </div>
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={handleOpenChargebackModal}
              variant="contained"
              color="secondary"
              style={{ color: "white", background: "red" }}
            >
              Respond to chargeback
            </Button>
          </div>
        </div>
        <Divider style={{ margin: "0" }} />
        <div
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "4fr 1fr",
          }}
        >
          <div>
            <p>
              Attempt to resolve the matter directly with the customer if this
              chargeback was caused by misunderstanding. The customer can
              arrange gor the chargeback to be cancelled at any time. However,
              in that case you would still need to provide an evidence of that,
              such as email from customer where he claims that he drops his
              chargeback request.
            </p>
          </div>
          <div style={{ textAlign: "right" }}>
            <Button
                onClick={handleOpenContactCustomerModal}
              variant="contained"
              color="secondary"
              style={{ color: "white", background: "red" }}
            >
              Contact Customer
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default ChargebackNotification;
