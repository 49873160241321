import { gql } from '@apollo/client';

const UPDATE_JOB = gql`
  mutation updateJob(
    $jobId: Int!
    $label: String
    $locationIds: [Int]!
    $colorId: Int!
    $posRoleId: Int!
    $orderKey: Int
  ) {
    updateJob(
      input: {
        jobId: $jobId
        label: $label
        locationIds: $locationIds
        colorId: $colorId
        posRoleId: $posRoleId
        orderKey: $orderKey
      }
    ) {
      job {
        id
        jobId
        label
        posAccess
      }
    }
  }
`;

export default UPDATE_JOB;
