import { gql } from '@apollo/client';

const UPDATE_VENDOR_PACK_SIZE = gql`
  mutation UPDATE_VENDOR_PACK_SIZE($input: UpdateVendorproductmeasureInput!) {
    updateVendorproductmeasure(input: $input) {
      viewer {
        id
      }
      vendorproductmeasure {
        id
        active
        created
        packSize
        vendorproductmeasureId
      }
    }
  }
`;

export default UPDATE_VENDOR_PACK_SIZE;
