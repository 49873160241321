import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { uglifyId } from "../../../../../utils";
import VendorsView from "./View";

const Vendors = () => {
  const { locationId } = useParams();
  const uglifiedId = uglifyId("Location", locationId);

  const history = useHistory();

  return (
    <VendorsView
      uglifiedId={uglifiedId}
      history={history}
    />
  );
}

export default Vendors;
