import { gql } from "@apollo/client";

const USERGROUP_RULES = gql`
  query UsergroupRules($fieldgroupId: Int!, $usergroupId: Int!) {
    viewer {
      id
      usergroupJsonTree(fieldgroupId: $fieldgroupId, usergroupId: $usergroupId) {
        jsonTree
      }
    }
  }
`;

export default USERGROUP_RULES;
