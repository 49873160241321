import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import { forEach } from "lodash";

const FORMAT = '(###) ###-####'

function getValueNumber(value = '') {
  if (value) {
    return `+1${Number(value.replace('+1', '').replace(/\D/g, '') || '')}`;
  }

  return ''
}

function getValueString(value = '') {
  if (value) {
    let string = value.replace('+1', '').replace(/\D/g, '');

    let result = FORMAT;

    forEach(string, (char => {
      result = result.replace('#', char); 
    }));

    return result.replace(/#/g, '');
  }

  return ''
}


const PhoneNumberInput = ({ rules, name, control, label, helperText, error, disabled, fullWidth, defaultValue, ...rest }) => {
  useEffect(() => {
    document.getElementById(name).value = getValueNumber(defaultValue);
  }, [defaultValue, name]);

  return (
    <Controller
      render={({ onChange, value }) =>
        <NumberFormat
          {...rest}
          label={label}
          id={name}
          disabled={disabled}
          fullWidth={fullWidth}
          error={error}
          isNumericString={false}
          format={getValueString}
          removeFormatting={getValueNumber}
          displayType="input"
          defaultValue={getValueNumber(defaultValue)}
          helperText={error && helperText}
          customInput={TextField}
          onChange={({ target }) => onChange(getValueNumber(target.value))}
        />
      }
      name={name}
      rules={!disabled && {
        ...(rules || {}), 
        validate: {
          ...(rules?.validate || {}),
          regex: value => value ? /^\+1[0-9]{10}$/.test(value) : true,
        }
      }}
      variant="outlined"
      defaultValue={getValueNumber(defaultValue)}
      control={control}
    />
  );
};

export default PhoneNumberInput;
