import { useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CREATE_INVENTORY_ITEM_CATEGORY_TYPE,
  UPDATE_INVENTORY_ITEM_CATEGORY_TYPE,
} from '../../../../../../../apollo/mutations';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { statusOptions } from '../../../../../../../constants/statuses';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const defaultValues = {
  label: '',
  active: '',
  isCost: '',
};

const CategoryModal = ({ handleClose, category, isCostOptions }) => {
  const { control, errors, handleSubmit, register, reset } = useForm(defaultValues);

  const { setSnackbar } = useSnackbar();

  const [createCategoryType] = useMutation(CREATE_INVENTORY_ITEM_CATEGORY_TYPE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Category created',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createInventoryitemcategorytype?.viewer),
        fields: {
          inventoryitemcategorytypeConnection() {
            return {
              __typename: 'inventoryitemcategorytypeEdge',
            };
          },
        },
      });
    },
  });

  const [updateCategoryType] = useMutation(UPDATE_INVENTORY_ITEM_CATEGORY_TYPE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Category updated',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (category?.inventoryitemcategorytypeId) {
      reset({
        label: category.label,
        isCost: category.isCost ? 1 : 0,
        active: category.active ? 1 : 0,
      });
    } else {
      reset(defaultValues);
    }
  }, [category]);

  const onSubmit = useCallback(
    formData => {
      const input = {
        label: formData.label,
        active: !!formData.active,
        isCost: !!formData.isCost,
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: category?.inventoryitemcategorytypeId
          ? 'Updating category...'
          : 'Creating category...',
        open: true,
      });

      if (category?.inventoryitemcategorytypeId) {
        input.inventoryitemcategorytypeId = category.inventoryitemcategorytypeId;

        updateCategoryType({ variables: { input } });
      } else {
        createCategoryType({ variables: { input } });
      }
    },
    [category?.inventoryitemcategorytypeId],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            label="Name"
            name="label"
            variant="outlined"
            placeholder="Name"
            error={errors.label}
            helperText={!!errors.label && 'Name is required'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.isCost}>Type</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="isCost"
              name="isCost"
              label="Type"
              placeholder="Type"
              defaultValue={category?.isCost || ''}
              error={!!errors?.isCost}
              as={
                <Select label="Type" multiple={false} error={!!errors?.isCost}>
                  {isCostOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.isCost?.type === 'required' ? (
              <FormHelperText error>Type is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.active}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="active"
              name="active"
              label="Status"
              placeholder="Status"
              defaultValue={category?.active ? 1 : 0}
              error={!!errors?.active}
              as={
                <Select label="Status" multiple={false} error={!!errors?.active}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.active?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit">
                {category?.inventoryitemcategorytypeId ? 'Save' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CategoryModal;
