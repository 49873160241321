import { gql } from "@apollo/client";
import { addressFragment, personFragment } from "../../fragments";

const USERS_BY_USERGROUP = gql`
  query EmployeesByUsergroup(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LoginSort]
    $fieldGroup: Int
    $filter: [LoginFilter],
  ) {
    viewer {
      id
      loginConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        filter: $filter
        sort: $sort
        fieldGroup: $fieldGroup
      ) {
        totalCount
        availableFilters
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        permissions
        edges {
          node {
            id
            loginId
            email
            inactive
            employeeInactive
            person {
              id
              personId
              firstName
              lastName
              dateOfBirth
              phone {
                id
                phone
              }
            }
          }
        }
      }
      personConnection(first: 1) {
        permissions
        totalCount
        edges {
          node {
            ...PersonData
            address {
              ...AddressData
            }
          }
        }
      }
    }
  }
  ${addressFragment.address}
  ${personFragment.person}
`;

export default USERS_BY_USERGROUP;
