import { gql } from "@apollo/client";

const UPDATE_LOCATION_HOURS = gql`
  mutation UpdateLocationWeekdayMutation(
    $input: UpdateLocationWeekdayMutationInput!
  ) {
    updateLocationWeekday(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_LOCATION_HOURS;
