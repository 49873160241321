import React from 'react';

const checkPermission = ({ access, children }) => {
  access = access === true ? 7 : access;

  const checkedChildren = React.Children.toArray(children).map((child,index) => {

    const childsChildren = child?.props?.children || children.length
      ? checkPermission({ access, children: child?.props?.children || child.length })
      : undefined;

    if(typeof child === 'string' || typeof child === 'number' || child.length){
        return access & 4 ? child : null
    } else {
      return childsChildren
        ? access & 4 ? React.cloneElement(child, access & 2 ? { key: index } : {
          key: index,
          disabled: true,
          disabledByPermission: true,
        }, childsChildren) : null
        : access & 4 ? React.cloneElement(child, access & 2 ? { key: index } : { key: index, disabled: true, disabledByPermission: true }) : null
    }
  });

  return checkedChildren.length === 1 
    ? checkedChildren[0]
    : checkedChildren;
}

const Permission = ({ access = true, children }) => {
  return checkPermission({ access: !access && Number.isNaN(access) ? true : access, children })
}

export default Permission;
