import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  TableBody,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import {
  DataTable,
  Permission,
  PermissionField,
} from "../../../../../../blocks";

import { convert, toFixed10 } from '../../../../../../../utils';
import { MUTATION_NAME, SNACKBAR_STATUS } from "../../../../../../../constants";
import {
  CREATE_TAX_GROUP,
  CREATE_TAX_GROUP_TAX_RATE,
  UPDATE_TAX_GROUP,
} from "../../../../../../../apollo/mutations/locations";
import { FETCH_LOCATION } from "../../../../../../../apollo/queries";
import { compact } from "lodash";

const AddTaxGroup = ({
  taxRates,
  handleCancel,
  handleOpenSnackBar,
  editData,
  locationId,
  permissions,
  accessToMutations,
  mutationPermissions,
  showModal,
}) => {
  const [selectedTaxValuesTotal, setSelectedTaxValuesTotal] = useState({
    fraction: 0,
    amount: 0,
  });

  const [createTaxGroup] = useMutation(CREATE_TAX_GROUP);
  const [createTaxGroupTaxRate] = useMutation(CREATE_TAX_GROUP_TAX_RATE);
  const [updateTaxGroup] = useMutation(UPDATE_TAX_GROUP);
  const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    register("taxRateIds");
  }, [register]);

  const createTaxGroupTaxRatePermission = useMemo(
    () =>
      mutationPermissions?.find(
        (permission) => permission.label === "createTaxGroupTaxrate"
      ),
    [mutationPermissions]
  );
  const createTaxGroupPermission = useMemo(
    () =>
      mutationPermissions?.find(
        (permission) => permission.label === "createTaxGroup"
      ),
    [mutationPermissions]
  );
  const updateTaxGroupPermission = useMemo(
    () =>
      mutationPermissions?.find(
        (permission) => permission.label === "updateTaxGroup"
      ),
    [mutationPermissions]
  );

  const handleSelectedValues = useCallback(
    (selectedValues, data) => {
      const selectedTaxRates =
        compact(
          selectedValues?.map((value) =>
            taxRates?.edges.find((taxRate) => taxRate?.node?.id === value?.id)
          )
        ) || [];

      const totalTaxValue = selectedTaxRates.reduce(
        (accumulator, currentValue) => {
          return {
            fraction: accumulator.fraction + currentValue.node.fraction,
            amount: accumulator.amount + currentValue.node.amount,
          };
        },
        { fraction: 0, amount: 0 }
      );

      setSelectedTaxValuesTotal(totalTaxValue);

      setValue(
        "taxRateIds",
        selectedValues?.map((value) => ({ taxrateId: value.id }))
      );
    },
    [taxRates, setValue]
  );

  const defaultValues = useMemo(
    () =>
      editData?.taxgroupTaxrateConnection?.edges?.map(
        (edge) => edge.node?.taxrate
      ) || [],
    [editData]
  );

  useEffect(() => {
    handleSelectedValues(
      editData?.taxgroupTaxrateConnection?.edges?.map(
        (edge) => edge.node?.taxrate
      ) || []
    );

    setValue("taxGroupName", editData?.label || "");
    setValue("description", editData?.description || "");
  }, [editData, handleSelectedValues, showModal, setValue]);

  const onSubmit = useCallback(
    async (data) => {
      handleOpenSnackBar("Updating Tax Group", SNACKBAR_STATUS.INFO);

      const taxGroupInputVariables = {
        locationId,
        label: data.taxGroupName,
        description: data.description,
      };

      try {
        let taxGroupResponse;

        if (editData) {
          taxGroupResponse = await updateTaxGroup({
            variables: {
              input: {
                taxgroupId: editData.id,
                label: data.taxGroupName,
                description: data.description,
              },
            },
          });

          if (taxGroupResponse)
            taxGroupResponse = taxGroupResponse?.data?.updateTaxgroup?.taxgroup;
        } else {
          taxGroupResponse = await createTaxGroup({
            variables: { input: taxGroupInputVariables },
          });

          if (taxGroupResponse)
            taxGroupResponse = taxGroupResponse?.data?.createTaxgroup?.taxgroup;
        }

        const taxGroupTaxRateVariables = {
          taxgroupId: taxGroupResponse?.id,
          taxrates: data.taxRateIds,
        };
        await createTaxGroupTaxRate({
          variables: { input: taxGroupTaxRateVariables },
          refetchQueries: [
            {
              query: FETCH_LOCATION,
              variables: { id: locationId, first: 2147483647 },
            },
          ],
        });
        const snackbarMessage = editData
          ? "Tax Group Updated"
          : "New Tax group added";
        handleCancel();
        handleOpenSnackBar(snackbarMessage, SNACKBAR_STATUS.SUCCESS);
      } catch (error) {
        handleOpenSnackBar(error.message, SNACKBAR_STATUS.ERROR);
      }
    },
    [
      createTaxGroup,
      createTaxGroupTaxRate,
      editData,
      handleCancel,
      handleOpenSnackBar,
      locationId,
      updateTaxGroup,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Permission
          access={permissions?.taxgroupConnection?.edges?.node?.label}
        >
          <Grid item container xs={6}>
            <PermissionField
              Component={TextField}
              name="taxGroupName"
              label="Tax Group Name"
              fullWidth
              inputRef={register({ required: true })}
              error={!!errors.taxGroupName}
              helperText={errors.taxGroupName && "Tax Group Name is required"}
            />
          </Grid>
        </Permission>
        <Permission
          access={permissions?.taxgroupConnection?.edges?.node?.description}
        >
          <Grid item container xs={6}>
            <PermissionField
              Component={TextField}
              name="description"
              label="Description"
              fullWidth
              inputRef={register}
            />
          </Grid>
        </Permission>
        <Grid item container xs={12}>
          <DataTable
            data={taxRates}
            defaultValues={defaultValues}
            permissions={permissions?.taxrateConnection?.edges?.node}
            structureTable={(data) =>
              data?.edges.map((taxrate) => ({
                label: taxrate.node.label,
                description: taxrate.node.description,
                fraction:
                  taxrate.node.fraction === 0
                    ? "/"
                    : `${taxrate.node.fraction * 100} %`,
                amount:
                  !taxrate?.node?.amount || taxrate.node.amount === 0
                    ? "/"
                    : `${convert.centsToDollars(taxrate.node.amount)} $`,
                surcharge: taxrate.node.surcharge,
                taxable: taxrate.node.taxable,
                display: taxrate.node.display,
                id: taxrate.node.id,
              }))
            }
            columns={[
              { title: "", field: "", customPermissionField: "__typename" },
              { title: "Tax Name", field: "label" },
              { title: "Description", field: "description" },
              {
                title: "Fraction - %",
                field: "fraction",
              },
              {
                title: "Amount - $",
                field: "amount",
              },
              {
                title: "Surcharge",
                field: "surcharge",
                render: (surcharge) => (surcharge ? "On" : "Off"),
              },
              {
                title: "Taxable",
                field: "taxable",
                render: (taxable) => (taxable ? "On" : "Off"),
              },
              {
                title: "Display on bill",
                field: "display",
                render: (display) => (display ? "On" : "Off"),
              },
            ]}
            selectableRows={true}
            getSelectedValues={handleSelectedValues}
          />
          <Grid item container xs={12}>
            <Table aria-label="total counts" size="small">
              <TableBody>
                <TableRow style={{ backgroundColor: "#F4F4F9" }}>
                  <TableCell align="center" scope="row">
                    <Typography variant="subtitle2">Total:</Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell
                    align="left"
                    style={{ maxWidth: "45px", paddingLeft: 0 }}
                  >
                    <b>
                      {selectedTaxValuesTotal.fraction
                        ? `${parseFloat(
                            toFixed10(selectedTaxValuesTotal.fraction * 100, 2)
                          )} %`
                        : "/"}
                    </b>
                  </TableCell>
                  <TableCell style={{ maxWidth: "45px", paddingLeft: 0 }}>
                    <b>
                      {selectedTaxValuesTotal.amount
                        ? `$ ${convert.centsToDollars(
                            selectedTaxValuesTotal.amount
                          )}`
                        : "/"}
                    </b>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={4} justify="flex-end">
          <Grid item>
            <Button onClick={handleCancel} color="secondary" variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Permission
              access={
                accessToMutations?.[
                  editData
                    ? Math.min(
                        MUTATION_NAME.UpdateTaxgroupMutationInput,
                        updateTaxGroupPermission?.access
                      )
                    : Math.min(
                        MUTATION_NAME.CreateTaxgroupMutationInput,
                        createTaxGroupTaxRatePermission?.access,
                        createTaxGroupPermission?.access
                      )
                ]
              }
            >
              <div>
                <PermissionField createHelperText>
                  <Button type="submit">Submit</Button>
                </PermissionField>
              </div>
            </Permission>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddTaxGroup;
