import { gql } from "@apollo/client";

export const FETCH_HANDOFFS = gql`
  query fetchHandoffs {
    viewer {
      handoffConnection {
        edges {
          node {
            id
            label
          }
        }
      }
    }
  }
`;
