import React, { useState, useMemo } from 'react';
import { Container, Paper, Tab, Tabs, Typography } from '@material-ui/core';

import { TabPanel } from '../../../../../../../../components/blocks';
import Reward from '../Reward';
import LoyaltyRewardshistoryStyles from './styles';

export default function LoyaltyRewardsView({ queryLoading, errorQuery, data }) {
  const classes = LoyaltyRewardshistoryStyles();

  const regularRewards = data?.viewer?.availableCoupons?.edges?.filter(
    item =>
      item?.node?.coupon?.loyaltystoreitemCouponConnection?.edges[0]?.node?.loyaltystoreitem
        ?.typeId === 1,
  );
  const legendaryRewards = data?.viewer?.availableCoupons?.edges?.filter(
    item =>
      item?.node?.coupon?.loyaltystoreitemCouponConnection?.edges[0]?.node?.loyaltystoreitem
        ?.typeId === 3,
  );

  const tabsViewData = useMemo(() => {
    return [
      {
        label: 'Regular Rewards',
        value: 'regular',
        id: 'regular',
        data: regularRewards,
      },
      {
        label: 'Legendary Rewards',
        value: 'legendary',
        id: 'legendary',
        data: legendaryRewards,
      },
    ];
  }, [regularRewards, legendaryRewards]);

  const [currentTab, setCurrentTab] = useState(tabsViewData[0].value);

  const handleTabChange = (event, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  if (queryLoading) <div>Loading...</div>;
  if (errorQuery) <div>error</div>;

  return (
    <Container>
      <Typography variant="h4" className={classes.title}>
        Available Rewards
      </Typography>

      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab value={'regular'} label={'Regular Rewards'} />
        <Tab value={'legendary'} label={'Legendary Rewards'} />
      </Tabs>

      {tabsViewData.map(tab => (
        <TabPanel value={currentTab} index={tab.value} key={tab.id}>
          <Paper>
            <div className={classes.root}>
              {tab.data?.length ? (
                tab.data.map(e => <Reward node={e.node} classes={classes} />)
              ) : (
                <h3>No rewards</h3>
              )}
            </div>
          </Paper>
        </TabPanel>
      ))}
    </Container>
  );
}
