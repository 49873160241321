import React, { useEffect, useState } from 'react';
import { Card, IconButton, Chip, Paper } from '@material-ui/core';
import { Modal } from '../../../../../../blocks';
import AddLocationsModal from './AddLocationsModal';
import AddIcon from '@material-ui/icons/Add';
import { Elements } from '../../../../../../blocks';
import { Controller } from 'react-hook-form';

function Locations({
  title,
  name = 'discountRestrictions.locations.enabled',
  type = 'include',
  control,
  locations,
  setLocations,
  displayedLocationRestriction,
  setDisplayedLocationRestriction,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    setShowOptions(!!locations.length);
  }, [locations]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Controller
          control={control}
          name={name}
          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '2rem' }}>
                <Elements.Switch
                  ref={ref}
                  checked={value}
                  handleSwitchChange={isChecked => {
                    setShowOptions(isChecked);
                    onChange(isChecked);
                    if (type === 'include' && displayedLocationRestriction === 'include') {
                      setDisplayedLocationRestriction('all');
                    }
                    if (type === 'exclude' && displayedLocationRestriction === 'exclude') {
                      setDisplayedLocationRestriction('all');
                    }
                    if (type === 'include' && displayedLocationRestriction === 'all') {
                      setDisplayedLocationRestriction('include');
                    }
                    if (type === 'exclude' && displayedLocationRestriction === 'all') {
                      setDisplayedLocationRestriction('exclude');
                    }
                  }}
                />
              </div>
              <div>
                <h3>{title}</h3>
              </div>
            </div>
          )}
        />
      </div>
      {showOptions && (
        <div style={{ display: 'flex', margin: '0 10px' }}>
          <Paper style={{ width: ' 100%' }}>
            <Card
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '5px',
              }}
            >
              <div></div>
              <IconButton onClick={handleOpen}>
                <AddIcon />
              </IconButton>
            </Card>
            <div style={{ padding: '10px 0' }}>
              {locations.map(item => {
                if (!item.isParent) {
                  return (
                    <Chip
                      key={item.id + '' + item.parentId}
                      style={{ margin: '4px' }}
                      label={item.label}
                      onDelete={e => setLocations(locations.filter(loc => loc.id !== item.id))}
                    />
                  );
                }
              })}
              {!locations.length && (
                <h3 style={{ marginLeft: '5px' }}>
                  {type === 'include' ? 'No included locations.' : 'No excluded locations.'}
                </h3>
              )}
            </div>
          </Paper>
        </div>
      )}

      <Modal open={openModal} handleClose={handleClose} title="Add Locations">
        <AddLocationsModal
          handleClose={handleClose}
          includedLocations={locations}
          setIncludedLocations={setLocations}
        />
      </Modal>
    </div>
  );
}

export default Locations;
