import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import { TextField, FormHelperText, Grid, Box, IconButton } from '@material-ui/core';
import { fileReaders } from '../../../utils';
import { HighlightOff } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '1rem',
  },
  dropzone: {
    width: '100%',
    border: '1px solid black',
    textAlign: 'center',
  },
  media: {
    height: 'auto',
    width: '100%',
  },
  cursor: {
    cursor: 'pointer',
  },
}));

const MAX_IMAGE_SIZE = 350000;

const useDidMount = () => {
  const didMountRef = useRef(true);
  useEffect(() => {
    didMountRef.current = false;
  }, []);
  return didMountRef.current;
};

const ImageUpload = ({
  images,
  setImages,
  size,
  maxSize,
  isIcon,
  error: propsError,
  helperText,
  disabled,
  onClick,
  showDescription = true,
  dropzoneXsWidth = 6,
  spacing1 = 3,
  spacing2 = 3,
  enableClear = false,
  onClearImage,
}) => {
  const classes = useStyles();

  const didMount = useDidMount();
  const [error, setError] = useState(propsError);
  const [image, setImage] = useState({});

  useEffect(() => {
    if (didMount) {
      const index = images.findIndex(image => Object.values(image).includes(size));
      if (index !== -1) {
        setImage(images[index]);
        return;
      }
    }
  }, [didMount, image, images, setImages, size]);

  const onDescriptionChange = e => {
    setImage({ ...image, description: e.target.value });
    setImages(images => {
      const result = images.map(oldImage => ({ ...oldImage }));

      const index = result.findIndex(oldImage => oldImage.size === size);
      if (index !== -1) {
        const newImage = Object.assign(
          { ...image },
          {
            ...image,
            description: e.target.value,
          },
        );

        result[index] = newImage;

        setImage(newImage);
        return result;
      }
      return result;
    });
  };

  const onDrop = async (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const [file] = rejected;
      if (file.size > maxSize || MAX_IMAGE_SIZE) {
        setError(`Image size is greater than ${(maxSize || MAX_IMAGE_SIZE) / 1000}kB.`);
      } else {
        setError('Image is not valid');
      }
    } else {
      const [file] = accepted;
      setError(propsError);
      const encoded = await fileReaders.readFile(file);
      setImages(images => {
        const result = images.map(oldImage => ({ ...oldImage }));

        const index = result.findIndex(oldImage => oldImage.size === size);
        if (index !== -1) {
          const newImage = Object.assign(
            { ...image },
            {
              description: image.description,
              name: file.name,
              encoded,
              url: URL.createObjectURL(file),
            },
          );

          result[index] = newImage;

          setImage(newImage);
          return result;
        }
        return result;
      });
    }
  };

  const handleOnRemove = useCallback(
    event => {
      event.stopPropagation();
      setImage({});

      if (onClearImage) {
        onClearImage();
      }
    },
    [onClearImage],
  );

  const container = useMemo(
    () =>
      image?.url ? (
        <Box
          style={{
            position: 'relative',
            ...(isIcon
              ? {
                  width: '24px',
                  height: '24px',
                }
              : {}),
          }}
        >
          <Box style={{ position: isIcon ? 'relative' : 'absolute', top: -10, right: -10 }}>
            <IconButton size="small" onClick={handleOnRemove} style={{ background: 'white' }}>
              <HighlightOff />
            </IconButton>
          </Box>
          <img src={image.url || ''} className={classes.media} alt="preview" />
        </Box>
      ) : (
        <div className={classes.dropzone}>
          <h3>Click here to add an image</h3>
        </div>
      ),
    [image?.url, classes],
  );

  return (
    <Grid container spacing={spacing1}>
      <Grid container item xs={12} spacing={spacing2} className={classes.cursor}>
        <Grid item xs={dropzoneXsWidth}>
          <Dropzone
            multiple={false}
            accept="image/jpeg, image/png, image/webp"
            maxSize={maxSize || MAX_IMAGE_SIZE}
            onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
          >
            {({
              getRootProps,
              getInputProps,
              isDragAccept,
              isDragReject,
              acceptedFiles,
              rejectedFiles,
            }) => (
              <section style={{ width: '100%' }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {!isDragReject && container}
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>
        {helperText || error ? (
          <Grid item xs={6}>
            <FormHelperText error={!!error}>{helperText || error}</FormHelperText>
          </Grid>
        ) : null}
      </Grid>
      {size !== '900x675' && showDescription && (
        <Grid item xs={12} style={{ marginTop: 'auto' }}>
          <TextField
            fullWidth
            label="Image description (alt)"
            value={image.description}
            onChange={e => onDescriptionChange(e)}
          />
        </Grid>
      )}
    </Grid>
  );
};

ImageUpload.propTypes = {
  images: PropTypes.array.isRequired,
  setImages: PropTypes.array.isRequired,
  maxSize: PropTypes.number,
};

export default ImageUpload;
