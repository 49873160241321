import { gql } from "@apollo/client";

const FETCH_TIERS = gql`
  query fetchTiers {
    viewer {
      id
      tierConnection {
        totalCount
        edges {
          node {
            id
            tierId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_TIERS;
