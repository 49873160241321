import { gql } from '@apollo/client';
import { locationHandoffCategoryFragment, viewerFragment } from '../../fragments';

const UPDATE_LOCATION_HANDOFF_CATEGORY = gql`
  mutation UpdateLocationHandoffCategory($input: UpdateLocationHandoffCategoryMutationInput!) {
    updateLocationHandoffCategory(input: $input) {
      viewer {
        ...ViewerData
      }
      locationHandoffCategoryEdge {
        node {
          ...LocationHandoffCategoryData
        }
      }
    }
  }
  ${viewerFragment.viewer}
  ${locationHandoffCategoryFragment.locationHandoffCategory}
`;

export default UPDATE_LOCATION_HANDOFF_CATEGORY;
