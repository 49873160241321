import React, { useCallback, useState } from "react";
import ItemListItem from "./ItemListItem";
import { List } from "@material-ui/core";
import update from 'immutability-helper';
import Button from '@material-ui/core/Button';
import { useMutation } from "@apollo/client";
import { SNACKBAR_STATUS } from "../../../../../../../../constants";
import SnackBar from "../../../../../../../blocks/SnackBar";
import { FETCH_MENU_LIST } from "../../../../../../../../apollo/queries";
import UPDATE_SUBCATEGORY_ITEM_ORDER_KEY from "../../../../../../../../apollo/mutations/menus/updateSubcategoryItemOrder";
import { uglifyId } from "../../../../../../../../utils";
const ItemList = ({data, menuId, categoryId, subcategoryId, basePath, classes}) => {
  const [items, setItems] = useState(data.map((item, i) => ({item, i})));
  const [state, setState] = useState({
    open: false,
    type: 'info',
    text: '',
  });
  const [updateSubcategoryItemOrderKeys, {loading: updateSubcategoryItemOrderKeysLoading}] = useMutation(UPDATE_SUBCATEGORY_ITEM_ORDER_KEY);

  const saveSubcategoryItemOrderKeys = () => {
    setState({
      type: 'info',
      text: 'Item order keys are being saved',
      open: true,
    })
    const formatItems = items.map(item => ({id: item?.item?.node?.item?.id, orderKey: item?.i + 1}))
    updateSubcategoryItemOrderKeys({
      variables: {
        input: {
          items: formatItems,
          subcategoryId: uglifyId('Subcategory', subcategoryId)
        }
      },
      refetchQueries: [
        {
          query: FETCH_MENU_LIST,
        },
      ],

    }).then(r => setState({
      type: 'success',
      text: 'Item order keys are save successfully',
      open: true,
    })).catch(() => {
      setState({
        type: 'error',
        text: 'Something went wrong.',
        open: true,
      })
    })
  }

const moveSubcategory = useCallback((dragIndex, hoverIndex) => {
    let dragCard = items[dragIndex];
    dragCard.i = hoverIndex;
    items[hoverIndex].i = dragIndex;
    setItems(update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    }));
  }, [items]);
  return (
    <div>
      <div style={{marginBottom: '1rem', textAlign: 'center'}}><Button onClick={() => saveSubcategoryItemOrderKeys()} disabled={updateSubcategoryItemOrderKeysLoading}>Save order</Button></div>
      <div>
    <List component="nav" className={classes.list}>
    {items?.map(
      (edge, i) => {
        return (
          <ItemListItem
            menuId={menuId}
            key={edge?.item?.node?.item?.itemId}
            subcategoryId={subcategoryId}
            index={i}
            order={edge?.i}
            moveSubcategory={moveSubcategory}
            categoryId={categoryId}
            basePath={basePath}
            edge={edge?.item}
            classes={classes}
          />
        );
      }
    )}
    </List>
      </div>
      <SnackBar text={state.text} setState={setState} open={state.open} type={state.type} />
      </div>
  );
};

export default ItemList;
