import { gql } from '@apollo/client';

const REMOVE_HIERARCHY = gql`
  mutation removeHierarchy($input: removeHierarchyMutationInput!) {
    removeHierarchy(input: $input) {
      hierarchyId
    }
  }
`;

export default REMOVE_HIERARCHY;
