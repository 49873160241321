import { gql } from '@apollo/client';

const CREATE_INVENTORY_VENDOR = gql`
  mutation CREATE_INVENTORY_VENDOR($input: CreateInventoryvendorInput!) {
    createInventoryvendor(input: $input) {
      viewer {
        id
      }
      inventoryVendorEdge {
        id
        inventoryvendorId
        inventoryvendorVendorId
        label
        status
      }
    }
  }
`;

export default CREATE_INVENTORY_VENDOR;
