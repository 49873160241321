import React, { useCallback, useEffect, useState } from 'react';
import { Button, TextField, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';

import { Modal } from '../../blocks';
import FETCH_TIERS from './setPricing.constants';
import { convert } from '../../../utils';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../constants';
import { UPDATE_ITEM } from '../../../apollo/mutations';

const SetPricing = ({ itemId, gqlItemId, getValuesOuterForm }) => {
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [itemLabel, setItemLabel] = useState('');
  const [defaultPrice, setDefaultPrice] = useState();
  const [tiersConnection, setTiersConnection] = useState();

  const { setSnackbar } = useSnackbar();

  const [loadTiers, { loading: loadingTiers, refetch: refetchTiers }] = useLazyQuery(FETCH_TIERS, {
    onCompleted: data => {
      if (data?.viewer?.itemTierConnection && data?.viewer?.platformConnection) {
        setDefaultPrice({
          defaultMcpi: data?.viewer?.item?.price,
          defaultMaxPrice: data?.viewer?.item?.priceCap || '',
        });
        setItemLabel(data?.viewer?.item?.label);
        setTiersConnection(
          data?.viewer?.tierConnection?.edges.map(edge => {
            return {
              tierId: edge?.node?.id,
              tierPrice: edge?.node?.price,
              tierLabel: edge?.node.label,
              nameLabel: edge?.node?.label.replace(/\s+/g, '-').toLowerCase(),
              tierPlatforms: data?.viewer?.platformConnection,
              tierPriceData: data?.viewer?.itemTierConnection?.edges.find(
                tier => tier?.node?.tier?.id === edge?.node?.id,
              ),
            };
          }),
        );
      }
    },
    onError: err => {
      console.log(err, 'error...');
    },
    fetchPolicy: 'network-only',
  });

  const [updateTiers] = useMutation(UPDATE_ITEM, {
    onCompleted: async () => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Tiers updated!',
      });
      setButtonDisabled(false);
      setOpenModal(false);
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      }),
        setButtonDisabled(false);
    },
  });

  const { handleSubmit, register, getValues, errors, reset, setError } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (defaultPrice && tiersConnection?.length) {
      const tiersFields = tiersConnection.map((tier, index) => ({
        [`${tier.nameLabel}-mcpi`]:
          typeof tier?.tierPriceData?.node?.price === 'number'
            ? convert.centsToDollars(tier?.tierPriceData?.node?.price)
            : '',
        [`${tier.nameLabel}-oloMaxPrice`]: getPriceCap(
          index,
          'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
        ),
        [`${tier.nameLabel}-posMaxPrice`]: getPriceCap(
          index,
          'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
        ),
        [`${tier.nameLabel}-3pvMaxPrice`]: getPriceCap(
          index,
          'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
        ),
      }));
      const tiersProps = tiersFields?.reduce((acc, curr) => {
        return Object.assign(acc, curr);
      }, {});

      reset({
        defaultMaxPrice:
          typeof defaultPrice?.defaultMaxPrice === 'number'
            ? convert.centsToDollars(defaultPrice.defaultMaxPrice)
            : '',
        defaultMcpi:
          typeof defaultPrice?.defaultMcpi === 'number'
            ? convert.centsToDollars(defaultPrice.defaultMcpi)
            : '',
        ...tiersProps,
      });
    }
  }, [defaultPrice, tiersConnection]);

  const onSubmit = useCallback(
    async data => {
      try {
        tiersConnection?.forEach(tier => {
          if (!getValues(`${tier.nameLabel}-mcpi`)) {
            if (
              getValues(`${tier.nameLabel}-oloMaxPrice`) !== '' ||
              getValues(`${tier.nameLabel}-posMaxPrice`) !== '' ||
              getValues(`${tier.nameLabel}-3pvMaxPrice`) !== ''
            ) {
              setError(`${tier.nameLabel}-mcpi`, { type: 'customError' });
              throw new Error(
                'MCPI can’t be empty if you’re trying to enter Max Price per Platform',
              );
            }
          }
        });

        const names = Object.keys(data);
        const values = Object.values(data);

        const dataSerialized = names.reduce((acc, curr, index) => {
          acc.push({
            name: curr,
            value: values[index],
          });
          return acc;
        }, []);

        const inputTiersData = tiersConnection?.map(data => {
          if (dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value) {
            return {
              tierId: data.tierId,
              price: convert.dollarsToCents(
                dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value,
              ),
              platforms: data.tierPlatforms.edges.reduce((acc, curr) => {
                if (curr?.node?.id === 'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                  if (dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)?.value) {
                    acc.push({
                      platformId: curr?.node?.id,
                      priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)
                        ?.value
                        ? convert.dollarsToCents(
                            dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)
                              ?.value,
                          )
                        : null,
                    });
                  }
                }
                if (curr?.node?.id === 'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                  if (dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)?.value) {
                    acc.push({
                      platformId: curr?.node?.id,
                      priceCap: convert.dollarsToCents(
                        dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)?.value,
                      ),
                    });
                  }
                }
                if (curr?.node?.id === 'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                  if (dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)?.value) {
                    acc.push({
                      platformId: curr?.node?.id,
                      priceCap: convert.dollarsToCents(
                        dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)?.value,
                      ),
                    });
                  }
                }
                return acc;
              }, []),
            };
          }
        });

        const input = {
          itemId: gqlItemId,
          price: convert.dollarsToCents(data.defaultMcpi),
          priceCap: convert.dollarsToCents(data.defaultMaxPrice),
          availableOnline: getValuesOuterForm('availableOnline'),
          availablePos: getValuesOuterForm('availablePos'),
          availableThirdParty: getValuesOuterForm('availableThirdParty'),
          isDonation: false,
          label: itemLabel,
          images: [],
          tiers: inputTiersData.filter(data => data !== undefined),
        };

        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.INFO,
          text: 'Updating tiers...',
        });

        setButtonDisabled(true);

        await updateTiers({
          variables: {
            input,
          },
        });
      } catch (errorMessage) {
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: `${errorMessage}`,
        });
      }
    },
    [setButtonDisabled, gqlItemId, itemLabel, getValuesOuterForm, updateTiers, tiersConnection],
  );

  const forwardSave = data => {
    onSubmit(data);
  };

  const handleSubmitWithoutPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(forwardSave)(e);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
    loadTiers({
      variables: {
        id: itemId,
        gqlItemId: gqlItemId,
      },
    });
  };

  const getPriceCap = (index, platformHash) => {
    const priceCapValue = tiersConnection[
      index
    ]?.tierPriceData?.node?.itemTierPlatformConnection?.edges?.find(
      e => e?.node?.platform?.id === platformHash,
    )?.node?.priceCap;

    if (typeof priceCapValue === 'number') {
      return convert.centsToDollars(priceCapValue);
    }
    return '';
  };

  const formRender = useCallback(() => {
    return tiersConnection && !loadingTiers ? (
      <form onSubmit={handleSubmitWithoutPropagation} style={{ width: '100%' }}>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4" component="h4">
              Default
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              gap: '10px',
              flexWrap: 'nowrap',
            }}
          >
            <Grid item xs={6}>
              <TextField
                name="defaultMcpi"
                label="Default MCPI"
                type="text"
                inputRef={register({
                  required: true,
                  pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                })}
                fullWidth
                defaultValue={convert.centsToDollars(defaultPrice?.defaultMcpi)}
                error={errors?.defaultMcpi}
                helperText={
                  errors?.defaultMcpi && (errors?.defaultMcpi ? 'Default MCPI is required.' : null)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="defaultMaxPrice"
                label="Default Max Price"
                type="text"
                inputRef={register({
                  pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                  validate: {
                    min: value => {
                      const priceValue = getValues('defaultMcpi');
                      return priceValue && value
                        ? parseFloat(value) >= parseFloat(priceValue)
                        : true;
                    },
                  },
                })}
                fullWidth
                defaultValue={
                  typeof defaultPrice.defaultMaxPrice === 'number'
                    ? convert.centsToDollars(defaultPrice.defaultMaxPrice)
                    : ''
                }
                error={errors?.defaultMaxPrice}
                helperText={
                  errors?.defaultMaxPrice &&
                  (errors?.defaultMaxPrice?.type === 'min'
                    ? "Max Price can't be less than Default MCPI"
                    : 'Tier Max Price must be a positive number.')
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              justifyContent: 'space-between',
            }}
          >
            {tiersConnection?.map((tier, index) => (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <Typography gutterBottom variant="h4" component="h4">
                    {tier.tierLabel}
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{
                    gap: '10px',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-mcpi`}
                      label={`${tier.tierLabel} MCPI`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={
                        tier?.tierPriceData
                          ? convert.centsToDollars(tier?.tierPriceData?.node?.price)
                          : ''
                      }
                      error={errors[`${tier.nameLabel}-mcpi`]}
                      helperText={
                        errors[`${tier.nameLabel}-mcpi`]?.type === 'customError'
                          ? 'MCPI can’t be empty if you’re trying to enter Max Price per Platform'
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-posMaxPrice`}
                      label={`${tier.tierLabel} POS Max Price`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={getPriceCap(
                        index,
                        'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-oloMaxPrice`}
                      label={`${tier.tierLabel} OLO Max Price`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={getPriceCap(
                        index,
                        'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-3pvMaxPrice`}
                      label={`${tier.tierLabel} 3PV Max Price`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={getPriceCap(
                        index,
                        'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==',
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid
            container
            style={{
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Grid item>
              <Button
                onClick={handleClose}
                style={{
                  marginRight: '14px',
                }}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={buttonDisabled}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    ) : (
      <Typography gutterBottom variant="h5" component="h5">
        Loading...
      </Typography>
    );
  }, [errors, buttonDisabled, tiersConnection, defaultPrice]);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Set Pricing
      </Button>
      <Grid container>
        <Modal open={openModal} handleClose={handleClose} title={itemLabel} maxWidth="lg">
          {formRender()}
        </Modal>
      </Grid>
    </>
  );
};

export default SetPricing;
