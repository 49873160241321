import React, { useState, useEffect, useMemo } from 'react';
import { omit, compact } from 'lodash';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import paths from '../../../../../../../constants/paths';
import EditLocationFormView from './View';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { LOCATIONS, FETCH_TIER_LIST, FETCH_BRANDS } from '../../../../../../../apollo/queries';
import FETCH_MENUS from '../../../../../../../apollo/queries/menus/fetchMenus';
import FETCH_STORE_TYPES from '../../../../../../../apollo/queries/locations/storeTypes';
import {
  CREATE_LOCATION,
  UPDATE_LOCATION_INFO,
} from '../../../../../../../apollo/mutations/locations';
import FETCH_LOCATION from '../../../../../../../apollo/queries/fetchLocation';
import { PAYROLL_PERIODS } from '../../../../../../../apollo/queries/locations/fetchPayrollPeriod';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import FETCH_PRINTER_SERVICE from '../../../../../../../apollo/queries/fetchPrinterService';

const IMAGE = {
  sizes: ['900x675'],
};

const EditLocation = ({
  countryList,
  locationInfoData,
  stateList,
  permissions,
  handleCloseModal,
  timezoneList,
  weekdaysData,
  mutationPermissions,
}) => {
  const { register, handleSubmit, errors, control } = useForm({});
  const [images, setImages] = useState([]);

  const createLocationPermission = useMemo(
    () => mutationPermissions?.find(perm => perm.label === '"createLocation"'),
    [mutationPermissions],
  );
  const updateLocationPermission = useMemo(
    () => mutationPermissions?.find(perm => perm.label === 'updateLocationInfo'),
    [mutationPermissions],
  );

  useEffect(() => {
    setImages(
      IMAGE.sizes.map(size => {
        return {
          size,
        };
      }),
    );
  }, []);

  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const { data: menuList, loading: menuListLoading, error: menuListError } = useQuery(FETCH_MENUS);
  const { data: brandList, error: brandListError } = useQuery(FETCH_BRANDS);
  const { data: printerServiceList, error: printerServiceListError } = useQuery(
    FETCH_PRINTER_SERVICE,
  );

  const [createLocationInfo, { loading: createLocationInfoLoading }] = useMutation(
    CREATE_LOCATION,
    {
      onCompleted: data => {
        history.push(
          paths.LOCATION.replace(':locationId', data.createLocation.location.locationId).replace(
            ':currentTab?',
            'generalInfo',
          ),
        );
      },
    },
  );
  const [updateLocationInfo, { loading: updateLocationInfoLoading }] = useMutation(
    UPDATE_LOCATION_INFO,
  );

  const { data: tiers, loading: tiersLoading, error: tiersError } = useQuery(FETCH_TIER_LIST);

  const {
    data: payrollPeriodData,
    loading: payrollPeriodLoading,
    error: payrollPerioError,
  } = useQuery(PAYROLL_PERIODS);
  const {
    data: locationTypesData,
    loading: locaitonTypesLoading,
    error: locationTypesError,
  } = useQuery(FETCH_STORE_TYPES);

  const handleOpenSnackBar = (text, type) => {
    setSnackbar({ open: true, type, text });
  };

  const weekDays = compact(
    weekdaysData?.map(weekDay => {
      return weekDay?.node?.type === 'Weekday'
        ? {
            id: weekDay?.node?.weekdayId,
            value: weekDay?.node?.value,
            label: weekDay?.node?.label,
          }
        : null;
    }),
  );
  const taxGroups = locationInfoData?.taxgroupConnection?.edges.map(taxGroup => ({
    value: taxGroup?.node?.id,
    label: taxGroup?.node?.label,
  }));
  const stateListFormat = stateList.edges.map(state => ({
    label: state.node.label,
    value: state.node.stateId,
  }));

  if (menuListLoading || tiersLoading || locaitonTypesLoading || payrollPeriodLoading) {
    return <CircularProgress />;
  }

  if (menuListError || tiersError || locationTypesError || brandListError || payrollPerioError) {
    return <>Error</>;
  }

  const onSubmit = async formData => {
    if (locationInfoData?.id) {
      try {
        await updateLocationInfo({
          variables: {
            input: formData,
            locationId: locationInfoData.id,
          },
          refetchQueries: [
            {
              query: FETCH_LOCATION,
              variables: { id: formData.locationId, first: 2147483647 },
            },
          ],
        });
        handleCloseModal();
        handleOpenSnackBar('Location info updated.', SNACKBAR_STATUS.SUCCESS);
        history.push();
      } catch (error) {
        handleOpenSnackBar(error.message, SNACKBAR_STATUS.ERROR);
      }
    } else {
      try {
        await createLocationInfo({
          variables: {
            input: omit(formData, ['receiptFooter', 'taxgroupId', 'menuLabel']),
          },
          refetchQueries: [
            {
              query: LOCATIONS,
            },
          ],
        });

        handleCloseModal();
        handleOpenSnackBar('Location update.', SNACKBAR_STATUS.SUCCESS);
      } catch (error) {
        handleOpenSnackBar(error.message, SNACKBAR_STATUS.ERROR);
      }
    }
  };

  return (
    <>
      <EditLocationFormView
        locationInfoData={locationInfoData}
        menuList={menuList?.viewer?.menuConnection?.edges}
        brandList={brandList?.viewer?.brandConnection?.edges}
        onSubmit={onSubmit}
        payrollPeriods={payrollPeriodData?.viewer?.payrollPeriodConnection?.edges}
        updateLocationInfoLoading={updateLocationInfoLoading}
        createLocationInfoLoading={createLocationInfoLoading}
        control={control}
        permissions={permissions}
        handleSubmit={handleSubmit}
        register={register}
        errors={errors}
        handleCloseModal={handleCloseModal}
        stateList={stateListFormat}
        timezoneList={timezoneList}
        countryList={countryList}
        tiers={tiers?.viewer?.tierConnection?.edges}
        weekDays={weekDays}
        taxGroups={taxGroups}
        locationTypes={locationTypesData?.viewer?.storeTypeConnection?.edges || []}
        images={images}
        setImages={setImages}
        imageSize={IMAGE.sizes[0]}
        createLocationPermission={createLocationPermission}
        updateLocationPermission={updateLocationPermission}
        printerServiceList={printerServiceList?.viewer?.printerserviceConnection?.edges}
      />
    </>
  );
};

export default EditLocation;
