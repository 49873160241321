import { Link } from '@material-ui/core';

import ConnectionTable from "../../../../../blocks/ConnectionTable";
import { LOCATION_VENDORS } from "../../../../../../apollo/queries";
import { paths, COLOR } from '../../../../../../constants';

function VendorsView({ uglifiedId, history }) {
  return (
    <div id="vendors">
      <ConnectionTable
        query={LOCATION_VENDORS}
        initialQueryVariables={{
          sort: {
            vendor: {
              id: "ASC"
            }
          },
          first: 15,
          filter: {
            location: {
              id: {
                eq: uglifiedId
              }
            }
          }
        }}
        title="Vendors"
        onTableRowClick={(row, data, variables) => {
          history.push(`${paths.SETTINGS_VENDORS}/${row.vendorId}/generalInfo`);
        }}
        structureTable={(data) =>
          data?.viewer?.locationVendorConnection?.edges.map((edge) => {
            return {
              vendorId: edge.node.vendorId,
              label: edge.node.vendor.label,
              deliveryUrl: edge.node.deliveryUrl,
            };
          })
        }
        columns={[
          {
            title: "Name",
            field: "label",
            disableSort: true,
          },
          {
            title: "Link",
            field: "deliveryUrl",
            disableSort: true,
            render: (data, vars,row) => (
              <Link
                component='a'
                variant='body2'
                href={data && data.includes('http') ?
                  data?.trim() :
                  `//${data?.trim()}`}
                rel="noreferrer"
                target="_blank"
              >
                {data}
              </Link>
            ),
            cellStyle: {
              color: COLOR.INFO,
            },
          },
        ]}
      />
    </div>
  );
}

export default VendorsView;
