
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    handoffButtonActive: {
        width: '100%',
        borderRadius: 0,
        marginBottom: theme.spacing(4),
        background: theme.palette.primary.main,
        padding: theme.spacing(3),
        border: 0,
        fontSize: '1rem',
        textAlign: 'left',
        outline: 0,
        color: theme.palette.text.primary,
    },
    handoffButtonInactive: {
        fontSize: '1rem',
        width: '100%',
        borderRadius: 0,
        marginBottom: theme.spacing(4),
        background: 'transparent',
        padding: theme.spacing(3),
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottomColor: theme.palette.grey[500],
        borderBottomWidth: '1px',
        textAlign: 'left',
        outline: 0,
        cursor: 'pointer',
    },
    buttonGroup: {
      justifyContent: "flex-start",
      display: "flex",
      padding: "0 8px",
      marginBottom: ".5rem",
      "& > button:first-child": {
        marginRight: "1rem",
        backgroundColor: "transparent",
        borderColor: "#e7e8ef",
      },
    },
    handoffCard: {
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
      marginBottom: '0.5rem',
      paddingBottom: '0.5rem',
  }
}));
  