import React, {useMemo, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';

import OrderListView from './View';

import {ORDERS, FETCH_BRANDS, FETCH_STATES} from '../../../../../apollo/queries';
import { LOCATIONS, ORIGINS, STAGES, HANDOFFS } from '../../../../../apollo/queries/filters';
import moment from 'moment-timezone';
import MENU_CATEGORIES from '../../../../../apollo/queries/filters/menuCategories';
import { convert } from '../../../../../utils';
import {groupBy} from 'lodash';

const OrderList = () => {
  const history = useHistory();
  const [openDateRangeSelectionModal, setOpenDateRangeSelectionModal] = useState(false);
  const [dateRangeStart, setDateRangeStart] = useState(moment());
  const [dateRangeEnd, setDateRangeEnd] = useState(moment());
  const { data: originData, loading: originLoading, error: originError } = useQuery(ORIGINS,{
    variables: {
      sort: {
        label: 'ASC'
      }
    }
  });
  const [getLocationsByState, { data: locationData, loading: locationLoading, error: locationError }] = useLazyQuery(LOCATIONS);
  const { data: stateData, loading: stateLoading, error: stateError } = useQuery(FETCH_STATES, {
    variables: {
      sort: {
        label: 'ASC'
      }
    }
  });
  const { data: stageData, loading: stageLoading, error: stageError } = useQuery(STAGES, {
    variables: {
      sort: {
        label: 'ASC'
      }
    }
  });
  const { data: brandData, loading: brandLoading, error: brandError } = useQuery(FETCH_BRANDS, {
    variables: {
      sort: {
        label: 'ASC'
      }
    }
  });
  const { data: menuCategoriesData, loading: menuCategoriesLoading, error: menuCategoriesError } = useQuery(MENU_CATEGORIES, {
    variables: {
      filter: {
        id: {
          //[Order Menu, Catering, Holiday, Upsell],
          in: ['Q2F0ZWdvcnk6MTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==', 'Q2F0ZWdvcnk6Mjo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==', 'Q2F0ZWdvcnk6NDo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==', 'Q2F0ZWdvcnk6NTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==', 'Q2F0ZWdvcnk6Mzo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA=='],
        },
        showHiddenToLocation: {
          eq: true
        },
        showHiddenToCustomer: {
          eq: true
        }
      }
    }
  });
  const { data: handoffData, loading: handoffLoading, error: handoffError } = useQuery(HANDOFFS, {
    variables: {
      sort: {
        label: 'ASC'
      }
    }
  });

  if(originLoading || stageLoading || menuCategoriesLoading || handoffLoading || brandLoading) <div>loading...</div>
  if(originError || stageError || menuCategoriesError || handoffError || brandError) <div>error</div>

  const getSelectedDateRangeValue = (variables) => {
      const filters = variables.filter;
       const startDate = filters?.expected?.gte;
        const endDate = filters?.expected?.lte;

    return `${(startDate && moment(startDate)?.format('MM/DD/YYYY')) ||
    'MM/DD/YYYY'} to ${(endDate && moment(endDate)?.format('MM/DD/YYYY')) || 'MM/DD/YYYY'}`;
  };

  const handleDateRangeSelection = ({ refetch, variables }, removeDate = false, ) => {
    if (removeDate) {
      const filters = variables.filter;
      delete filters.expected;
      refetch({
        ...variables,
        filter: filters
      });
    } else {
      // need only date
      const extractedDateStart = dateRangeStart.format('YYYY-MM-DD');
      const extractedDateEnd = dateRangeEnd.format('YYYY-MM-DD');
      // convert date to utc
      const startDateConvertedToUTC = moment(extractedDateStart).utc();
      const endDateConvertedToUTC = moment(extractedDateEnd).utc().add(23, 'hours').add('59', 'minutes').add(59, 'seconds');

      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          expected: {
            gte: startDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss'),
            lte: endDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss')
          }
        },
      });
    }
    setOpenDateRangeSelectionModal(false);
  };

  const handleDateRangeStartChange = value => {
    setDateRangeStart(value);
  }
  const handleDateRangeEndChange = value => {
    setDateRangeEnd(value);
  }

  const handleOpenDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(true);
  };
  const handleCloseDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(false);
  };

  const stateList = useMemo(
      () =>
          stateData?.viewer?.stateConnection?.edges?.map((state) => ({
            label: state.node.label,
            value: state.node.id,
          })),
      [stateData?.viewer?.stateConnection?.edges]
  );

  const cities = useMemo(
      () => {
        const uniqueCities = locationData?.viewer?.locationConnection?.edges?.slice((cityA, cityB) =>
            cityA.node.address.city > cityB.node.address.city ? 1 : -1,
        );

        const groupedResult = groupBy(uniqueCities, 'node.address.city');

        return Object.entries(groupedResult).map((value) => ({
          label: value[0],
          value: value[0],
        })).sort((cityA, cityB) => cityA.label.localeCompare(cityB.label));
      },
      [locationData?.viewer?.locationConnection?.edges]
  );

  return (
    <OrderListView
      query={ORDERS}
      history={history}
      locationSelectFactoryQuery={LOCATIONS}
      sources={originData}
      stages={stageData}
      menuCategories={menuCategoriesData}
      brands={brandData}
      handoffs={handoffData}
      dateRangeStart={dateRangeStart}
      dateRangeEnd={dateRangeEnd}
      stateList={stateList}
      cities={cities}
      getLocationsByState={getLocationsByState}
      handleDateRangeSelection={handleDateRangeSelection}
      openDateRangeSelectionModal={openDateRangeSelectionModal}
      getSelectedDateRangeValue={getSelectedDateRangeValue}
      handleDateRangeStartChange={handleDateRangeStartChange}
      handleDateRangeEndChange={handleDateRangeEndChange}
      handleOpenDateRangeSelectionModal={handleOpenDateRangeSelectionModal}
      handleCloseDateRangeSelectionModal={handleCloseDateRangeSelectionModal}
    />
  );
};

export default OrderList;
