import { gql } from '@apollo/client';
import recipeFragment from '../fragments/recipeFragment';

const FETCH_RECIPE_TYPES = gql`
  query FETCH_RECIPE_TYPES(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $sort: [RecipetypeSort]
    $filter: [RecipetypeFilter]
    $search: String
  ) {
    viewer {
      id
      recipetypeConnection(
        first: $first
        last: $last
        sort: $sort
        filter: $filter
        before: $before
        after: $after
        search: $search
        fieldGroupOnly: 7
      ) {
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
        totalCount
        edges {
          node {
            ...RecipeTypeData
          }
        }
      }
    }
  }

  ${recipeFragment.recipeType}
`;
export default FETCH_RECIPE_TYPES;
