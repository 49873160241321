import { useMemo } from 'react';

import { OrderTrackerClasses } from './styles';
import Icons from './Icons';

const OrderTrackerStep = ({ icon, completed, active, label, onClick, stageId }) => {
  const orderTrackerClasses = OrderTrackerClasses({ active, completed });

  const IconComponent = useMemo(
    () =>
      Icons[`OrderTrackerIcon${stageId}${active || completed ? 'Active' : ''}`] ||
      Icons.OrderTrackerIcon1,
    [stageId, active, completed],
  );

  return (
    <div className={orderTrackerClasses.container} onClick={onClick}>
      {stageId !== undefined ? (
        <div className={orderTrackerClasses.icon}>
          <IconComponent />
        </div>
      ) : null}

      {icon !== undefined ? (
        <div className={orderTrackerClasses.iconContainer}>
          <div className={orderTrackerClasses.iconWrapper}>{icon}</div>
        </div>
      ) : null}

      {label ? (
        <div className={orderTrackerClasses.labelContainer}>
          <span className={orderTrackerClasses.labelWrapper}>{label}</span>
        </div>
      ) : null}
    </div>
  );
};

export default OrderTrackerStep;
