import { isEmpty, sortBy } from 'lodash-es';
import generateCountSheetPdf from '../../../../../utils/pdfUtils/generateCountSheetPdf';

export function printCountSheet(data) {
  const locationStorages =
    !isEmpty(data?.viewer?.locationStorageConnection?.edges) &&
    data.viewer.locationStorageConnection.edges?.map(edge => edge.node);

  if (isEmpty(locationStorages)) {
    return null;
  }

  const storages = locationStorages.map(locationStorage => ({
    items: sortBy(
      locationStorage.locationStorageInventoryitemConnection?.edges?.map(edge => ({
        label: edge.node.inventoryitem?.label,
        measures: edge.node.inventoryitem?.inventoryitemMeasureConnection?.edges?.map(
          measureEdge => measureEdge.node.measure?.abbreviation,
        ),
        orderKey: edge.node.orderKey,
      })),
      'orderKey',
    ),
    title: locationStorage.customLabel || locationStorage.storage.label,
  }));

  generateCountSheetPdf(storages);
}
