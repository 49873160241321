import { gql } from '@apollo/client';

const FETCH_MENU = gql`
  query fetchMenu($id: ID!) {
    viewer {
      id
      menu(id: $id) {
        id
        menuId
        label
        description
        brand {
          id
          brandId
          label
          description
          abbreviation
        }
        menuCategoryConnection {
          edges {
            node {
              category {
                id
                categoryId
                label
              }
            }
          }
        }
        menuimageConnection {
          edges {
            node {
              id
              label
              url
              description
            }
          }
        }
      }
    }
  }
`;

export default FETCH_MENU;
