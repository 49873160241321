import { Grid, IconButton } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import React, { useCallback, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DragAndDropItem = ({
  id,
  item,
  index,
  handleDrop,
  itemComponent: ItemComponent,
  type,
  onRemoveItem,
  itemComponentProps = {},
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      ...item,
      id,
      type,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [_, drop] = useDrop({
    accept: type,
    drop(droppedItem) {
      handleDrop(droppedItem.id, item.id);
      return undefined;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleRemoveClick = useCallback(() => {
    onRemoveItem(index);
  }, [index]);

  return (
    <div ref={drop} style={{ width: '100%' }}>
      <Grid ref={drag} container spacing={3} alignItems="center">
        <Grid item xs={1} justify="center" container>
          <DragIndicator style={{ cursor: 'grab' }} />
        </Grid>
        <Grid
          item
          xs={10}
          style={{
            opacity: isDragging ? '0.5' : '1',
            cursor: isDragging && 'move',
          }}
        >
          <ItemComponent {...itemComponentProps} id={id} index={index} item={item} />
        </Grid>
        <Grid item xs={1} justify="center" container>
          <IconButton size="small" onClick={handleRemoveClick}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default DragAndDropItem;
