import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { PermissionField } from '../../../../../../../blocks';
import { SelectFactory } from '../../../../../../../blocks';
import { LOCATIONS } from '../../../../../../../../apollo/queries';
import { pad } from '../../../../../../../../utils/index';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { uglifyId } from '../../../../../../../../utils';

const AddLocationView = ({
  onSubmit,
  editRowData,
  vendorId,
  handleCloseModal,
  createLocationVendorLoading,
}) => {
  const { setSnackbar } = useSnackbar();
  const { handleSubmit, register, control, errors } = useForm();

  const [selectedLocation, setSelectedLocation] = useState(editRowData);

  const handleOnSubmit = useCallback(
    formData => {
      if (!selectedLocation) {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          open: true,
          text: 'Please select a location.',
        });

        return;
      }

      let inputData;
      if (editRowData) {
        inputData = {
          vendorId: editRowData.vendorRawId,
          locationId: editRowData.locationRawId,
          deliveryUrl: formData?.deliveryUrl,
        };
      } else {
        inputData = {
          vendorId: uglifyId('Vendor', vendorId),
          locationId: selectedLocation.id,
          deliveryUrl: formData?.deliveryUrl,
        };
      }

      onSubmit(inputData);
    },
    [editRowData, onSubmit, selectedLocation, setSnackbar, vendorId],
  );

  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <SelectFactory
              label={'Location'}
              fullWidth
              placeholder={'Select Location'}
              query={LOCATIONS}
              defaultValue={
                selectedLocation && editRowData
                  ? {
                      ...selectedLocation,
                      ...{
                        label: `${editRowData?.locationState}-${pad(
                          editRowData?.locationStoreNum,
                          4,
                        )} ${editRowData?.label}`,
                      },
                    }
                  : {}
              }
              disabled={!!editRowData}
              disableCloseOnSelect={false}
              multiple={false}
              renderCheckBox
              inputRef={register}
              control={control}
              filter={{
                active: { null: false },
              }}
              structureData={data => {
                return data?.viewer?.locationConnection?.edges.map((location, index) => {
                  return {
                    label: `${location.node.address?.state?.abbreviation}-${pad(
                      location?.node?.storeNumber,
                      4,
                    )} ${location?.node?.label}`,
                    id: location?.node?.id,
                    locationId: location?.node?.locationId,
                    index,
                  };
                });
              }}
              onSelect={value => {
                setSelectedLocation(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PermissionField
              Component={TextField}
              name="deliveryUrl"
              label="Delivery URL"
              defaultValue={editRowData?.deliveryUrl || ''}
              fullWidth
              inputRef={register({ required: true })}
              control={control}
              error={errors.deliveryUrl}
              helperText={
                errors?.deliveryUrl ? 'Delivery URL cannot be empty' : errors?.deliveryUrl?.message
              }
            />
          </Grid>

          <Grid container item xs={12} justify="flex-end" spacing={3}>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <div>
                <PermissionField createHelperText>
                  <Button type="submit" disabled={createLocationVendorLoading}>
                    Save
                  </Button>
                </PermissionField>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddLocationView;
