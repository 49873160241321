import { gql } from '@apollo/client';

export const timezoneFragment = {
  timezone: gql`
    fragment TimezoneData on Timezone {
      timezoneId
      id
      label
    }
  `,
};
