import { gql } from '@apollo/client';

const ADD_INVENTORYITEM_TO_PRODUCTION_FORECASTER = gql`
  mutation addInventoryItemToProductionForecaster(
    $input: AddInventoryitemToProductionForecasterInput!
  ) {
    addInventoryitemToProductionForecaster(input: $input) {
      inventoryitem {
        id
        inventoryitemCustomCategory {
          id
          inventoryitemCustomCategoryId
          label
        }
        inventoryitemPrep {
          id
          cspm
          size
          sizeCooked
          format
          partOfClosingInventory
          hasStrategic
          averageCasePackInfo
          smokeTodayForTomorrow
        }
      }
    }
  }
`;

export default ADD_INVENTORYITEM_TO_PRODUCTION_FORECASTER;
