import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Container,
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import PhoneNumberInput from "../../../blocks/PhoneNumberInput";
import { Controller } from "react-hook-form";
import { Elements, SelectFactory } from "../../../blocks";
import FETCH_CATERING_EXPERT from "../../../../apollo/queries/caterings/fetchCateringExpert";
import Permission from "../../../blocks/Permission";
import PermissionField from "../../../blocks/PermissionField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: theme.typography.fontSize,
  },
}));

const CustomerInfo = ({
  quote,
  register,
  personList,
  customer,
  errors,
  setValue,
  control,
  isLocationSet,
  containerId,
  setChangedCreditCard
}) => {
  CustomerInfo.propTypes = {
    quote: PropTypes.object,
    register: PropTypes.func,
    personList: PropTypes.object,
    customer: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      login: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
      }),
      phone: PropTypes.shape({
        id: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
    errors: PropTypes.object,
  };

  // eslint-disable-next-line no-param-reassign
  personList = personList?.edges?.map((person) => ({
    label: `${person.node.firstName} ${person.node.lastName}`,
    value: person.node.id,
    firstName: person.node.firstName,
    lastName: person.node.lastName,
    email: quote?.email,
    phone: person.node.phone?.phone,
  }));

  const initialPerson = useMemo(() => quote?.person?.id, [quote]);

  const [taxExempt, setIsTaxExempt] = useState(quote?.person?.taxId);
  const [customerInfo, setCustomerInfo] = useState({});
  const [initialCustomerInfo, setInitialCustomerInfo] = useState(true);

  const classes = useStyles();

  const handleCustomerChange = useCallback((customerData, isFromDropdown) => {
    // eslint-disable-next-line no-unused-expressions
    if(document.getElementById("customerFirstName") && document.getElementById("customerEmail") && document.getElementById("customerLastName")) {
      document.getElementById("customerFirstName").value = "";
      document.getElementById("customerEmail").value = "";
      document.getElementById("customerLastName").value = "";
    }
    if (customerData?.value === initialPerson){
      setChangedCreditCard(false);
    }

    customerData &&
      setCustomerInfo({
        label: `${customerData.firstName} ${customerData.lastName}`,
        id: customerData.id,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        taxId: customerData.taxId,
        email: customerData.email,
        phone: customerData.phone?.phone || customerData.phone,
        isFromDropdown,
      });
  }, [initialPerson, setChangedCreditCard]);

  useEffect(() => {
    handleCustomerChange({...customer, email: customer?.email || quote?.email}, !!customer);
  }, [customer, quote?.email, handleCustomerChange]);

  return (
    <>
      <Container key="cateringCustomerInfo" id={containerId}>
        <h2 className={classes.title}>Customer info</h2>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3}>
            <Permission access={quote?.permissions?.person?.__typename}>
             <Grid item xs={4}>
                <Controller
                  control={control}
                  name="personId"
                  defaultValue={
                    quote && {
                      label: `${quote?.person?.firstName} ${quote?.person?.lastName}`,
                      value: quote?.person?.id,
                    }
                  }
                  render={(props) => {
                    return (
                      <Permission access={quote?.permissions?.person?.__typename}>
                        <PermissionField
                          Component={SelectFactory}
                          customProps={props}
                          label={"Customer Info"}
                          placeholder={"Select"}
                          fullWidth={true}
                          error={!!errors.personId}
                          helperText={errors.personId && errors.personId.message}
                          defaultValue={
                            quote && {
                              label: `${quote?.person?.firstName} ${quote?.person?.lastName}`,
                              value: quote?.person?.id,
                            }
                          }
                          query={FETCH_CATERING_EXPERT}
                          onSelect={(e) => {
                            if (!e) {
                              setCustomerInfo({});
                              setChangedCreditCard(true);
                              setInitialCustomerInfo(false);
                            }
                            setInitialCustomerInfo(false);
                            setChangedCreditCard(true);
                            handleCustomerChange(e, true);
                          }}
                          style={{ width: "100%" }}
                          multiple={false}
                          renderCheckBox={false}
                          structureData={(data) => {
                            return data?.viewer?.loginConnection?.edges?.map(
                              (login, index) => ({
                                label: `${login?.node?.person?.firstName} ${login?.node?.person?.lastName} - ${login?.node?.email}`,
                                value: login?.node?.person?.id,
                                firstName: login?.node?.person?.firstName,
                                lastName: login?.node?.person?.lastName,
                                email: login?.node?.email || quote?.email,
                                phone: login?.node?.person?.phone?.phone,
                                index,
                              })
                            );
                          }}
                        />
                      </Permission>
                    );
                  }}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.person?.firstName}>
              <Grid item xs={4}>
                <PermissionField
                  Component={TextField}
                  inputRef={register({ required: "Required" })}
                  name="customerFirstName"
                  id={"customerFirstName"}
                  x-id="customerFirstName"
                  placeholder="First name"
                  label="First Name"
                  value={customerInfo.firstName}
                  error={!customerInfo.firstName && errors.customerFirstName?.message}
                  helperText={!customerInfo.firstName && errors.customerFirstName?.message}
                  style={{ width: "100%" }}
                  variant="outlined"
                  disabled={customerInfo?.isFromDropdown}
                />
              </Grid>
            </Permission>
            <Permission access={quote?.permissions?.person?.lastName}>
              <Grid item xs={4}>
                <PermissionField
                  Component={TextField}
                  label="Last Name"
                  inputRef={register({ required: "Required" })}
                  name="customerLastName"
                  id="customerLastName"
                  placeholder="Last name"
                  error={!customerInfo.lastName && errors.customerLastName?.message}
                  helperText={!customerInfo.lastName && errors.customerLastName?.message}
                  value={customerInfo.lastName}
                  onChange={(e) =>
                    setCustomerInfo((prevState) => ({
                      ...prevState,
                      lastName: e?.target?.value,
                    }))
                  }
                  style={{ width: "100%" }}
                  variant="outlined"
                  disabled={customerInfo?.isFromDropdown}
                />
              </Grid>
            </Permission>
            <Grid container item xs={12} spacing={3}>
              <Permission access={quote?.permissions?.email}>
               <Grid item xs={4}>
                  <PermissionField
                    Component={TextField}
                    inputRef={register({ required: "Required" })}
                    name="customerEmail"
                    label="Email"
                    id="customerEmail"
                    error={!customerInfo.email && errors.customerEmail?.message}
                    helperText={!customerInfo.email && errors.customerEmail?.message}
                    placeholder="Email"
                    variant="outlined"
                    value={customerInfo.email}
                    onChange={(e) =>
                      setCustomerInfo((prevState) => ({
                        ...prevState,
                        email: e?.target?.value,
                      }))
                    }
                    style={{ width: "100%" }}
                    disabled={customerInfo?.isFromDropdown}
                  />
                </Grid>
              </Permission>
              <Permission access={quote?.permissions?.person?.phone?.__typename}>
                <Grid item xs={4}>
                  <PermissionField
                    Component={PhoneNumberInput}
                    label={"Customer Phone"}
                    rules={{ required: true }}
                    name={"customerPhone"}
                    fullWidth
                    isDefaultVaultChanging={Boolean(customerInfo.phone)}
                    error={!customerInfo.phone && errors.customerPhone}
                    defaultValue={
                      initialCustomerInfo
                        ? customer?.phone?.phone
                        : customerInfo?.phone || null
                    }
                    helperText={!customerInfo.phone && errors.customerPhone && "Phone is required."}
                    style={{ width: "100%" }}
                    disabled={customerInfo?.isFromDropdown}
                    control={control}
                  />
                </Grid>
              </Permission>
              <Permission access={quote?.permissions?.person?.taxId}>
                <Grid item lg={2} xs={4}>
                  <div>
                    <PermissionField
                      Component={FormControlLabel}
                      createHelperText
                      label="Tax exempt"
                      labelPlacement="start"
                      control={(
                        <Controller
                          control={control}
                          name={"isTaxExempt"}
                          render={({ ref }) => (
                            <Permission access={quote?.permissions?.person?.taxId}>
                              <PermissionField
                                Component={Elements.Switch}
                                ref={ref}
                                xs={6}
                                name={"isTaxExempt"}
                                checked={taxExempt}
                                handleSwitchChange={(isChecked) => {
                                  setIsTaxExempt((prevState) => !prevState);
                                }}
                                disabled={!isLocationSet}
                              />
                            </Permission>
                          )}
                        />
                      )}
                      >
                    </PermissionField>
                  </div>
                </Grid>
              </Permission>
              <Permission access={quote?.permissions?.person?.taxId}>
                <Grid item lg={2} xs={4}>
                  <PermissionField
                    Component={TextField}
                    disabled={!taxExempt || !isLocationSet}
                    defaultValue={quote?.person?.taxId}
                    inputRef={register({
                      required: !!taxExempt,
                      minLength: 1,
                      maxLength: 14,
                    })}
                    variant="outlined"
                    name="taxId"
                    x-id="taxId"
                    placeholder="Tax ID"
                    style={{ width: "100%" }}
                    error={errors.taxId}
                    helperText={errors.taxId && "Tax ID must be between 12 and 14 characters"}
                  />
                </Grid>
              </Permission>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CustomerInfo;
