import React, { useContext, useMemo } from 'react';
import HouseAccountsView from './View';
import { CoreContext } from '../../../../../../../CoreContext';

export default function HouseAccounts({ customerData }) {
  const context = useContext(CoreContext);
  const mutationPermissions = useMemo(() => context?.coreUtils?.getAppActions()[75]?.mutations, [
    context?.coreUtils,
  ]);

  return (
    <HouseAccountsView customerData={customerData} mutationPermissions={mutationPermissions} />
  );
}
