import { gql } from '@apollo/client';

const FETCH_MENU_MULTIPLE = gql`
  query multiple(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [MultipleSort]
    $filter: [MultipleFilter]
    $fieldGroupOnly: Int
    $fieldGroup: Int
  ) {
    viewer {
      id
      multipleConnection(
        first: $first
        last: $last
        sort: $sort
        after: $after
        before: $before
        search: $search
        filter: $filter
        fieldGroupOnly: $fieldGroupOnly
        fieldGroup: $fieldGroup
      ) {
        permissions
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            multipleId
            label
            description
            multipleChoiceConnection {
              edges {
                node {
                  id
                  price
                  choice {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_MENU_MULTIPLE;
