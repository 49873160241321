import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddInventoryItems from './AddInventoryItems';
import { Box, Grid, Button } from '@material-ui/core';
import { ConfirmationModal } from '../../../../../blocks';
import { useHistory, useParams } from 'react-router-dom';
import { paths, SNACKBAR_STATUS } from '../../../../../../constants';
import CREATE_INVENTORY_TRANSFER_REQUEST from '../../../../../../apollo/mutations/inventory/createInventorytransferRequest';
import { useMutation } from '@apollo/client';
import PaperMain from '../../../../../blocks/PaperMain';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { prepareFormData } from './helpers';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

function CreateInventoryTransfer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showInventoryItemsError, setShowInventoryItemsError] = useState(false);

  let { locationId: ids, invoiceId } = useParams();
  const locationId = ids.split('-')[0];
  const [targetLocationId, setTargetLocationId] = useState(null);
  const { push } = useHistory();
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();

  const {
    control,
    errors,
    register,
    handleSubmit,
    reset,
    getValues,
    unregister,
    setValue,
  } = useForm({});

  const [createInventorytransferRequest, { loading }] = useMutation(
    CREATE_INVENTORY_TRANSFER_REQUEST,
    {
      onCompleted: data => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Transfer Request Created.',
          open: true,
        });

        push(paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER.replace(':locationId', ids));
      },
      onError: e => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        });
      },
    },
  );

  const pathToTaskList = useMemo(
    () => paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER.replace(':locationId', ids),
    [locationId],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    push(pathToTaskList);
  }, [pathToTaskList]);

  const handleSubmitAction = useCallback(
    ({ formData }) => {
      const input = prepareFormData({
        formData,
        setShowInventoryItemsError,
      });

      if (!targetLocationId) {
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          text: 'Please select target location',
          open: true,
        });
        return;
      }

      input.sourceLocationId = +locationId;
      input.targetLocationId = +targetLocationId;

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Creating Transfer request...',
        open: true,
      });

      createInventorytransferRequest({
        variables: {
          input,
        },
      });
    },
    [locationId, setShowInventoryItemsError, targetLocationId],
  );

  const onSubmit = useCallback(
    formData => {
      handleSubmitAction({ formData });
    },
    [handleSubmitAction],
  );

  const onCancelClick = useCallback(() => {
    push(pathToTaskList);
  }, [pathToTaskList]);

  return (
    <PaperMain className={classes.paper}>
      <>
        <form
          style={{
            width: '100%',
            maxWidth: '600px',
            minHeight: 'calc(100% - 100px)',
          }}
        >
          <Box style={{ width: '100%', height: '100%' }}>
            <AddInventoryItems
              setTargetLocationId={setTargetLocationId}
              register={register}
              errors={errors}
              control={control}
              showInventoryItemsError={showInventoryItemsError}
              setValue={setValue}
              unregister={unregister}
              getValues={getValues}
              submitButtonLocked={loading}
            />
          </Box>
        </form>
        {
          <Grid container className={classes.buttonsContainer} spacing={2}>
            <Grid item className={classes.button}>
              <Button
                style={{ minWidth: '100%' }}
                disabled={loading || errors.inventoryItems?.message}
                onClick={handleSubmit(onSubmit)}
              >
                Create Transfer Request
              </Button>
            </Grid>
            <Grid className={classes.button} item>
              <Button
                style={{ minWidth: '100%' }}
                type="button"
                variant="outlined"
                onClick={() => onCancelClick()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        }
      </>
      <ConfirmationModal
        handleClose={handleCloseModal}
        open={isModalOpen}
        text="Your inventory has been updated, and a report has been emailed to you."
        title="Thank You"
        showCancel={false}
        buttonText="Continue"
      />
    </PaperMain>
  );
}

export default CreateInventoryTransfer;
