import { gql } from "@apollo/client";

const CREATE_POS_ACCESS_ROLE = gql`
  mutation createPosRole(
    $input: createPosRoleInput!
  ) {
    createPosRole(input: $input) {
      clientMutationId
    }
  }
`;

export default CREATE_POS_ACCESS_ROLE;
