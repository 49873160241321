import { gql } from "@apollo/client";

export const FETCH_LOCATION_POS_SETTINGS = gql`
  query printerstationSubcategoryLocationConnection($locationId: ID!) {
    viewer {
      id
      location(id: $locationId, fieldGroupOnly: 20) {
        permissions
        squareRefreshToken
        payPalRefreshToken
        menu {
          menuId
          label
          menuCategoryConnection {
            edges {
              node {
                id
                category {
                  id
                  categoryId
                  label
                  subcategoryConnection {
                    edges {
                      node {
                        id
                        subcategoryId
                        menuId
                        label
                        subcategoryPrinterstationLocation {
                          id
                          locationId
                          printerstationId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        deviceConnection {
          edges {
            node {
              deviceId
              expired
              description
              description
              posAppVersion
              locationId
              deviceSetting {
                id
                devicesettingId
                deviceId
                gatewayId
                created
                expired
              }
              locationPrinterstation {
                edges {
                  node {
                    id
                    devicePrinterstationId
                    deviceId
                    printerstation_id
                    printerAdress
                    expired
                  }
                }
              }
            }
          }
        }
      }
      gatewayConnection {
        permissions
        edges {
          node {
            id
            label
            gatewayId
          }
        }
      }
    }
  }
`;
