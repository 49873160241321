import { gql } from "@apollo/client";

const UPDATE_LOCATION_ITEM = gql`
  mutation updateLocationItem($input: UpdateLocationItemMutationInput!) {
    updateLocationItem(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_LOCATION_ITEM;
