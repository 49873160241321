import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  description: {
    margin: `${theme.spacing(2)}px 0`,
  },
  title: {
    fontSize: '14px',
  },
  tooltip: {
    marginLeft: '10px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipDiv: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  laborContainer: {
    padding: '15px 0',
  },
  data: {
    fontSize: '16px',
  },
  percentages: {
    lineHeight: 2,
    marginTop: theme.spacing(2),
  },
  totalPercentage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  employeeList: {
    maxHeight: 600,
    overflowY: 'auto',

    '& .MuiGrid-container': {
      display: 'none',
    },
  },
  scheduleTimelineActions: {
    marginBottom: theme.spacing(2),

    '& .MuiButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
});

export const ScheduleLaborStyles = makeStyles(Styles);
