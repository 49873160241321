const readFile = file => {
  return new Promise(resolve => {
    const fr = new global.FileReader();
    fr.onload = () => resolve(fr.result);
    fr.readAsDataURL(file); // or readAsText(file) to get raw content
  });
};

const readFileAsBase64 = file => {
  return new Promise(resolve => {
    const fr = new global.FileReader();
    fr.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(fr.result))));
    fr.readAsArrayBuffer(file);
  });
};

const fileReaders = { readFile, readFileAsBase64 }

export default fileReaders;
