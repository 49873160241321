import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import LuxonUtils from '@date-io/luxon';
import DateTime from 'luxon/src/datetime';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { COPY_SCHEDULE } from '../../../../../apollo/mutations';
import Permission from '../../../../blocks/Permission';
import { MUTATION_NAME } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const CopyScheduleModal = ({
  scheduleweeklysalesprojectionId,
  selectedWeek,
  setIsVisibleState,
  copySchedulePermission,
  isWeekCopy,
  timelineVisibleDate,
  refetchScheduleVariables,
}) => {
  const { setSnackbar } = useSnackbar();

  const { handleSubmit, register, control, errors } = useForm();

  const [disabled, setDisabled] = useState(isWeekCopy);
  const [weekToCopyTo, setWeekToCopyTo] = useState(selectedWeek);
  const [startDate, setStartDate] = useState(DateTime.local());
  const [endDate, setEndDate] = useState(DateTime.local());

  const [copySchedule, { loading: copyScheduleLoading }] = useMutation(COPY_SCHEDULE, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Schedules copied successfully',
      });

      setIsVisibleState(false);
    },
    onError: error => {
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
  });

  const onDateChangeStart = useCallback(date => {
    setStartDate(date);
    setEndDate(date);
  }, []);

  const onDateChangeEnd = useCallback(date => {
    setEndDate(date);
  }, []);

  const onDateChange = useCallback(
    date => {
      if (date.weekNumber !== weekToCopyTo.weekNumber) {
        setDisabled(false);
      }

      setWeekToCopyTo(date);
    },
    [weekToCopyTo],
  );

  const onSubmit = useCallback(
    event => {
      const input = { scheduleweeklysalesprojectionId: scheduleweeklysalesprojectionId };

      if (isWeekCopy) {
        input.startDate = weekToCopyTo.toISODate();
        input.endDate = weekToCopyTo.plus({ days: 6 }).toISODate();
      } else {
        input.startDate = startDate.toISODate();
        input.endDate = endDate.toISODate();
        input.selectedDate = timelineVisibleDate.format('YYYY-MM-DD');
      }

      copySchedule({
        variables: {
          ...refetchScheduleVariables,
          input,
        },
      });
    },
    [
      scheduleweeklysalesprojectionId,
      isWeekCopy,
      weekToCopyTo,
      startDate,
      endDate,
      timelineVisibleDate,
      refetchScheduleVariables,
    ],
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom={true} style={{ color: 'red' }}>
              Any selected {isWeekCopy ? 'week' : 'date range'} may contain schedules that WILL BE
              OVERWRITTEN
            </Typography>
          </Grid>
          {isWeekCopy ? (
            <Grid item xs={12} justify="center">
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="LL/dd/yyyy"
                  margin="normal"
                  label="Date range start"
                  labelFunc={() =>
                    `${weekToCopyTo.toLocaleString('en-us')} to ${weekToCopyTo
                      .plus({ days: 6 })
                      .toLocaleString('en-us')}`
                  }
                  initialFocusedDate={weekToCopyTo}
                  onChange={onDateChange}
                  shouldDisableDate={date => date.weekday !== selectedWeek.weekday}
                  TextFieldComponent={props => <TextField {...props} disabled={true} />}
                  style={{ width: '100%' }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          ) : (
            <>
              <Grid item xs={6} justify="center">
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="LL/dd/yyyy"
                    margin="normal"
                    label="Date range start"
                    labelFunc={() => startDate.toLocaleString('en-us')}
                    initialFocusedDate={startDate}
                    value={startDate.toISODate()}
                    onChange={date => onDateChangeStart(date)}
                    shouldDisableDate={date => date < selectedWeek.minus({ days: 1 })}
                    TextFieldComponent={props => <TextField {...props} disabled={true} />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} justify="center">
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="LL/dd/yyyy"
                    margin="normal"
                    label="Date range end"
                    labelFunc={() => endDate.toLocaleString('en-us')}
                    initialFocusedDate={endDate}
                    value={endDate.toISODate()}
                    onChange={date => onDateChangeEnd(date)}
                    shouldDisableDate={date => date < startDate.minus({ days: 1 })}
                    TextFieldComponent={props => <TextField {...props} disabled={true} />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </>
          )}

          <Grid item xs={12} justify="flex-end" style={{ marginTop: '10px' }}>
            <Permission
              access={Math.min(
                copySchedulePermission?.access,
                MUTATION_NAME.CopyScheduleMutationInput,
              )}
            >
              <Button
                type="submit"
                disabled={disabled || copyScheduleLoading}
                style={{ margin: '5px' }}
              >
                Save
              </Button>
            </Permission>

            <Button
              disabled={copyScheduleLoading}
              onClick={() => {
                setIsVisibleState(false);
              }}
              style={{ margin: '5px' }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CopyScheduleModal;
