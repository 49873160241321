import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import {
  Container,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import ImageUpload from '../../../../../../../blocks/ImageUpload';
import GeneralInfoInput from '../../GeneralInfoInput';
import { CREATE_CATEGORY } from '../../../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { FETCH_CATEGORY_TYPES } from '../../../../../../../../apollo/queries';

const IMAGE = {
  sizes: ['615x615', '375x268'],
};

const CreateCategory = ({ ids, basePath, classes, refetchMenuList }) => {
  const { setSnackbar } = useSnackbar();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  const BLANK_CATEGORYTYPE_ID = 24;

  useEffect(() => {
    setImages(
      IMAGE.sizes.map(size => {
        return {
          size,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find(image => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [createCategory] = useMutation(CREATE_CATEGORY);

  const {
    data: categoryTypes,
    loading: categoryTypesLoading,
    error: categoryTypesError,
  } = useQuery(FETCH_CATEGORY_TYPES);

  const { handleSubmit, register, errors, control, getValues } = useForm({
    defaultValues: {
      label: '',
      description: '',
      categorytypeId: BLANK_CATEGORYTYPE_ID,
    },
  });

  const handleClose = () => {
    setImages([]);
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const { gqlMenuId, menuId } = ids;

  const onSubmit = async ({ label, description, showCustomer, categorytypeId }) => {
    setButtonDisabled(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Creating category...',
    });

    const imagesToUpload = images.map(image => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    const response = await createCategory({
      variables: {
        input: {
          menuId: gqlMenuId,
          label,
          categorytypeId: btoa(`Categorytype:${categorytypeId}:9999-12-31 23:59:59.000000`),
          showCustomer,
          description,
          images: imagesToUpload,
        },
      },
    });

    await refetchMenuList();

    setSnackbar({
      open: true,
      type: 'success',
      text: 'Category created!',
    });

    setRedirectUrl(
      `${basePath}/menus/${menuId}/categories/${response.data.createCategory.categoryEdge.node.categoryId}`,
    );
    setButtonDisabled(false);
  };

  if (categoryTypesLoading) return <CircularProgress />;
  if (categoryTypesError) console.log(categoryTypesError);

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
          <Grid item>
            <Button disabled={buttonDisabled} type="submit">
              Create Category
            </Button>
          </Grid>
        </Grid>
        <GeneralInfoInput
          imageUrl={previewImage}
          setOpen={setOpen}
          register={register}
          errors={errors}
        />
        <Grid item xs={4}>
          <FormControl component="fieldset">
            <Typography gutterBottom variant="h6" component="h2">
              Display On
            </Typography>
            <FormControlLabel
              label="Is Available on OLO"
              control={
                <Controller
                  name="showCustomer"
                  control={control}
                  render={props => (
                    <Switch
                      onChange={e => props.onChange(e.target.checked)}
                      checked={props.value}
                      className={classes.switch}
                    />
                  )}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component="fieldset">
            <Typography gutterBottom variant="h6" component="h2">
              Slug
            </Typography>
            <Controller
              name="categorytypeId"
              control={control}
              inputRef={register({
                required: true,
              })}
              rules={{
                validate: {
                  required: value => {
                    if (getValues('showCustomer') && value == BLANK_CATEGORYTYPE_ID)
                      return 'Slug should not be empty when category is available on OLO';
                    return true;
                  },
                },
              }}
              render={props => (
                <>
                  <Select
                    fullWidth
                    defaultValue={BLANK_CATEGORYTYPE_ID}
                    onChange={event => props.onChange(event.target.value)}
                    value={props.value}
                    label="Slug"
                    error={!!errors?.categorytypeId?.message}
                    helperText={errors?.categorytypeId?.message}
                  >
                    {categoryTypes?.viewer?.categorytypeConnection?.edges?.map(categoryType => (
                      <MenuItem value={categoryType?.node?.categorytypeId}>
                        <em>{categoryType?.node?.label}</em>
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.categorytypeId?.message && (
                    <FormHelperText>{errors?.categorytypeId?.message}</FormHelperText>
                  )}
                </>
              )}
            />
          </FormControl>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Category Images</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                Web
              </Grid>
              <Grid item xs={6}>
                Mobile
              </Grid>
            </Grid>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[0]}
                maxSize={200 * 1000}
                helperText="Required image size is 615 x 615 px in JPG format. File weight in max. 200KB."
              />
            </Grid>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[1]}
                maxSize={50 * 1000}
                helperText="Required image size is 375 x 268 px in JPG format. File weight in max. 50KB."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

CreateCategory.propTypes = {
  ids: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CreateCategory;
