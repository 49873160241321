import { gql } from '@apollo/client';

const FETCH_BULK_ORDER_SETTINGS_HANDOFF = gql`
  query bulkOrderSettingsHandoff($filter: [CategoryFilter]) {
    viewer {
      id
      locationConnection(first: 1, fieldGroup: 13) {
        permissions
        edges {
          node {
            locationId
          }
        }
      }
      categoryConnection(filter: $filter, fieldGroup: 18) {
        permissions
        edges {
          node {
            id
            label
            categoryId
          }
        }
      }
      handoffConnection(fieldGroup: 18) {
        permissions
        totalCount
        edges {
          node {
            id
            label
            handoffId
          }
        }
      }
    }
  }
`;

export default FETCH_BULK_ORDER_SETTINGS_HANDOFF;
