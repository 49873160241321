import { gql } from '@apollo/client';

const UPDATE_GENERAL_LEDGER_ACCOUNT = gql`
  mutation UPDATE_GENERAL_LEDGER_ACCOUNT($input: UpdateGeneralledgeraccountInput!) {
    updateGeneralledgeraccount(input: $input) {
      viewer {
        id
      }
      generalledgeraccount {
        id
        code
        generalLedgerAccountId
        label
        active
        created
      }
    }
  }
`;

export default UPDATE_GENERAL_LEDGER_ACCOUNT;
