import React from 'react';
import PaperMain from '../../../../blocks/PaperMain';
import SideBarSectionsLayout from '../../../../blocks/SideBarSectionsLayout';
import UnitsOfMeasures from './UnitsOfMeasures';
import VendorPackSizes from './VendorPackSizes';

const sections = [
  { name: 'Units of Measure', id: 'unitsOfMeasure', Component: UnitsOfMeasures },
  { name: 'Vendor Pack Sizes', id: 'vendorPackSizes', Component: VendorPackSizes },
];

const MeasureSettings = () => {
  return (
    <>
      <SideBarSectionsLayout sectionList={sections}>
        <PaperMain>
          {sections.map(({ id, Component }) => (
            <div key={id} id={id} style={{ width: '100%' }}>
              <Component />
            </div>
          ))}
        </PaperMain>
      </SideBarSectionsLayout>
    </>
  );
};

export default MeasureSettings;
