import { gql } from '@apollo/client';

const CREATE_ITEM = gql`
  mutation createItem($input: CreateItemMutationInput!) {
    createItem(input: $input) {
      viewer{
        id
      }
      subcategoryItem {
              id
              subcategoryId
              item {
                id
                itemId
                label
              }
      }
    }
  }
`;

export default CREATE_ITEM;
