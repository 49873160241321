import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function createContent({ items, label, dateCounted }, hasPageBreak) {
  const hLineThickIndexes = [0, 1];
  const vLineThickIndexes = [0, 1, 3];
  const rows = [
    [
      { text: 'Ingredient', style: 'tableHeader', alignment: 'center' },
      { text: 'Measure', style: 'tableHeader', alignment: 'center' },
      { text: 'Quantity', style: 'tableHeader', alignment: 'center' },
      { text: 'Total Cost', style: 'tableHeader', alignment: 'center' },
    ],
  ];

  let lastThickLineIndex = 1;

  items.forEach(item => {
    const newIndex = lastThickLineIndex + 1;
    hLineThickIndexes.push(newIndex);
    lastThickLineIndex = newIndex;

    rows.push([
      {
        text: item.locationStorageInventoryitem.inventoryitem.label,
      },
      { text: item.measure.label },
      { text: item.quantity },
      {
        text:
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(item.calculatedCost) || 0,
      },
    ]);
  });

  return [
    { text: label, style: 'header' },
    { text: `Date Counted: ${moment(dateCounted).format('MM/DD/YYYY')}`, style: 'date' },
    {
      style: 'tableStyle',
      color: '#444',
      pageBreak: hasPageBreak ? 'after' : '',
      table: {
        widths: ['40%', '20%', '20%', '20%'],
        body: rows,
      },
      layout: {
        hLineWidth: function(i) {
          return hLineThickIndexes.includes(i) ? 2 : 1;
        },
        vLineWidth: function(i) {
          return vLineThickIndexes.includes(i) ? 2 : 1;
        },
        paddingLeft: function() {
          return 10;
        },
        paddingRight: function() {
          return 10;
        },
        paddingTop: function() {
          return 5;
        },
        paddingBottom: function() {
          return 5;
        },
      },
    },
  ];
}

const printCountSummary = (storages = []) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const docDefinition = {
    pageOrientation: 'portret',
    pageSize: 'A4',
    content: storages.map((storage, index) =>
      createContent(storage, index + 1 !== storages.length),
    ),
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 18],
      },
      date: {
        marginBottom: 18,
      },
      tableStyle: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black',
        alignItems: 'center',
      },
    },
    defaultStyle: {
      fontSize: 10,
      color: '#444444',
    },
  };

  pdfMake.createPdf(docDefinition).open();
};

export default printCountSummary;
