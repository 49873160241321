import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import {
  FETCH_GENERAL_LEDGER_ACCOUNT,
  FETCH_RECIPE_CATEGORIES,
  FETCH_RECIPE_CATEGORY_TYPES,
  FETCH_REPORT_CATEGORY,
} from '../../../../../../apollo/queries';
import { Modal, SelectFactory } from '../../../../../blocks';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import SubcategoryModal from './SubcategoryModal';
import { statusOptions, renderStatus } from '../../../../../../constants/statuses';
import { isEmpty } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { REMOVE_INVENTORYITEMCATEGORY } from '../../../../../../apollo/mutations/inventory/removeInventoryitemcategory';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../constants';

const Subcategories = () => {
  const { setSnackbar } = useSnackbar();
  const [isSubcategoryModalOpen, setIsSubcategoryModalOpen] = useState(false);
  const [subcategory, setSubcategory] = useState();

  const [remove, { loading }] = useMutation(REMOVE_INVENTORYITEMCATEGORY, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Subcategory removed',
        open: true,
      });
    },
    onError: data => {
      setSnackbar({
        type: SNACKBAR_STATUS.WARNING,
        text: data.graphQLErrors[0].message,
        open: true,
      });
    },
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(data?.removeInventoryitemcategory?.viewer),
        fields: {
          inventoryitemcategoryConnection() {
            return {
              __typename: 'inventoryitemcategoryEdge',
            };
          },
        },
      });
    },
  });

  const handleOnRowClick = useCallback(({ data }) => {
    setSubcategory(data);
    setIsSubcategoryModalOpen(true);
  }, []);

  const handleOnDeleteClick = useCallback(id => {
    remove({
      variables: {
        inventoryitemcategoryId: id,
      },
    });
  }, []);

  const handleCloseSubcategoryModal = useCallback(() => {
    setSubcategory();
    setIsSubcategoryModalOpen(false);
  }, []);

  const handleOnAddSubcategoryClick = useCallback(() => {
    setIsSubcategoryModalOpen(true);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.active) {
        delete filters.active;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          active: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  const handleOnReportCategorySelect = useCallback(({ value, refetch, variables }) => {
    if (isEmpty(value)) {
      const filters = variables.filter;
      if (filters?.reportcategoryId) {
        delete filters.reportcategoryId;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          reportcategoryId: {
            in: value.map(({ id }) => id),
          },
        },
      });
    }
  }, []);

  const handleOnGeneralLedgerSelect = useCallback(({ value, refetch, variables }) => {
    if (isEmpty(value)) {
      const filters = variables.filter;
      if (filters?.generalledgeraccountId) {
        delete filters.generalledgeraccountId;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          generalledgeraccountId: {
            in: value.map(({ id }) => id),
          },
        },
      });
    }
  }, []);

  const handleOnCategorySelect = useCallback(({ value, refetch, variables }) => {
    if (isEmpty(value)) {
      const filters = variables.filter;
      if (filters?.inventoryitemcategorytypeId) {
        delete filters.inventoryitemcategorytypeId;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          inventoryitemcategorytypeId: {
            in: value.map(({ id }) => id),
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        title="Subcategories"
        customActionsTop={() => (
          <Button onClick={handleOnAddSubcategoryClick} fullWidth>
            Add New Subcategory
          </Button>
        )}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={6} md={3}>
              <SelectFactory
                defaultValue={[]}
                label="Category"
                fullWidth
                sort={{ label: 'ASC' }}
                placeholder="Search Categories..."
                query={FETCH_RECIPE_CATEGORY_TYPES}
                structureData={data =>
                  data?.viewer?.inventoryitemcategorytypeConnection?.edges?.map(
                    (category, index) => ({
                      value: category.node.inventoryitemcategorytypeId,
                      id: category.node.inventoryitemcategorytypeId,
                      label: category.node.label,
                      index,
                    }),
                  ) || []
                }
                onSelect={value => handleOnCategorySelect({ value, refetch, variables })}
                multiple
                renderCheckBox
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectFactory
                defaultValue={[]}
                label="General Ledger"
                placeholder="Search General Ledgers..."
                sort={{ label: 'ASC' }}
                query={FETCH_GENERAL_LEDGER_ACCOUNT}
                otherVariables={{
                  fieldGroupOnly: 94,
                }}
                structureData={data =>
                  data?.viewer?.generalledgeraccountConnection?.edges?.map((edge, index) => ({
                    value: edge.node.generalLedgerAccountId,
                    id: edge.node.generalLedgerAccountId,
                    index,
                    label: edge.node.label,
                  }))
                }
                onSelect={value => handleOnGeneralLedgerSelect({ value, refetch, variables })}
                fullWidth
                multiple
                renderCheckBox
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectFactory
                defaultValue={[]}
                label="Reporting Category"
                placeholder="Search Reporting Categories..."
                sort={{ label: 'ASC' }}
                onSelect={value => handleOnReportCategorySelect({ value, refetch, variables })}
                query={FETCH_REPORT_CATEGORY}
                otherVariables={{
                  fieldGroupOnly: 94,
                }}
                disableCloseOnSelect
                structureData={data =>
                  data?.viewer?.reportcategoryConnection?.edges?.map((edge, index) => ({
                    value: edge.node.reportcategoryId,
                    id: edge.node.reportcategoryId,
                    index,
                    label: edge.node.label,
                  }))
                }
                fullWidth
                multiple
                renderCheckBox
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        columns={[
          { field: 'inventoryitemcategoryId', title: 'ID' },
          { field: 'label', title: 'Name' },
          {
            field: 'categoryType',
            title: 'Category',
            customSortField: 'inventoryitemcategorytype.label',
          },
          {
            field: 'generalledgeraccount',
            title: 'GL Account',
            customSortField: 'generalledgeraccount.label',
          },
          {
            field: 'reportcategory',
            title: 'Reporting Category',
            customSortField: 'reportcategory.label',
          },
          {
            field: 'status',
            title: 'Status',
            render: renderStatus,
            enablePropagation: true,
            disableSort: true,
          },
          { field: 'updated', customSortField: 'created', title: 'Updated' },
        ]}
        query={FETCH_RECIPE_CATEGORIES}
        initialQueryVariables={{
          fieldGroupOnly: 94,
        }}
        structureTable={data =>
          data?.viewer?.inventoryitemcategoryConnection?.edges?.map(edge => ({
            label: edge.node.label,
            inventoryitemcategoryId: edge.node.inventoryitemcategoryId,
            categoryType: edge.node.inventoryitemcategorytype?.label,
            generalledgeraccount: edge.node.generalledgeraccount?.label,
            reportcategory: edge.node.reportcategory?.label,
            status: edge.node.active,
            updated: moment(edge.node.created).format('MM/DD/YYYY'),
            data: edge.node,
          })) || []
        }
        onTableRowClick={handleOnRowClick}
        handleDeleteRow={row => {
          handleOnDeleteClick(row.inventoryitemcategoryId);
        }}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this subcategory?"
      />
      <Modal
        open={isSubcategoryModalOpen}
        handleClose={handleCloseSubcategoryModal}
        title={subcategory?.inventoryitemcategoryId ? 'Edit Subcategory' : 'Add Subcategory'}
      >
        <SubcategoryModal
          statusOptions={statusOptions}
          subcategory={subcategory}
          handleClose={handleCloseSubcategoryModal}
        />
      </Modal>
    </>
  );
};

export default Subcategories;
