import InventoryItem from './InventoryItem';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import AddInventoryItem from './AddInventoryItem';
import FormItems from '../../../../../../blocks/FormItems';
import { FormHelperText } from '@material-ui/core';
import { LOCATIONS } from '../../../../../../../apollo/queries';
import { pad } from 'lodash';
import { useParams } from 'react-router-dom';
import { SelectFactory } from '../../../../../../blocks';

const AddInventoryItems = ({
  submitButtonLocked,
  register,
  errors,
  control,
  setValue,
  unregister,
  getValues,
  showInventoryItemsError,
  setTargetLocationId,
}) => {
  const [showSelectFactory, setShowSelectFactory] = useState(true);
  const [selectedIngredientIds, setSelectedIngredientIds] = useState([]);
  let { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];

  const handleSetItems = useCallback(items => {
    setSelectedIngredientIds(items.map(item => item.ingredientId));
  }, []);

  const handleOnSelect = useCallback(() => {
    setShowSelectFactory(false);
  }, []);

  const inventoryItems = useMemo(() => [], []);

  useEffect(() => {
    setSelectedIngredientIds(inventoryItems?.map(item => item.ingredientId) || []);
  }, [inventoryItems]);

  return (
    <>
      <Grid container direction="column" spacing={3} style={{ marginBottom: '16px' }}>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            Inventory Transfer
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <SelectFactory
              label="Transfer to"
              name="locationId"
              placeholder="Search location..."
              fullWidth
              query={LOCATIONS}
              multiple={false}
              disableSearchReset
              renderCheckBox={false}
              filter={{
                locationId: {
                  nin: [locationId],
                },
              }}
              onSelect={value => {
                setTargetLocationId(value.value);
              }}
              structureData={data =>
                data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                  value: edge.node.locationId,
                  id: edge.node.locationId,
                  index,
                  storeNumber: edge.node.storeNumber,
                  label: `${edge.node?.address?.state?.abbreviation}-${pad(
                    edge.node?.storeNumber || 0,
                    4,
                    '0',
                  )} - ${edge.node.label}`,
                }))
              }
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <FormItems
            onSelect={handleOnSelect}
            setItems={handleSetItems}
            addItemComponent={AddInventoryItem}
            addItemComponentProps={{
              setShowSelectFactory,
              showSelectFactory,
              submitButtonLocked,
              selectedIngredientIds,
            }}
            name="inventoryItem"
            setValue={setValue}
            unregister={unregister}
            control={control}
            getValues={getValues}
            register={register}
            items={inventoryItems}
            errors={errors}
            itemComponent={InventoryItem}
            enableEmpty
          />
        </Grid>
        {showInventoryItemsError ? (
          <Grid item xs={12}>
            <FormHelperText error>At least one Ingredient is required</FormHelperText>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default AddInventoryItems;
