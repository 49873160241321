import AppBar from '@material-ui/core/AppBar/AppBar';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ItemList from './ItemList';
import ChoiceSettings from './ChoiceSettings';

const MultipleEditTab = ({
  value,
  setValue,
  setNumberOfMultipleChoices,
  setMultipleLabel
}) => {
  MultipleEditTab.propTypes = {
    choices: PropTypes.array,
    setChoiceListSideBar: PropTypes.func,
    setChoices: PropTypes.func,
    setLabelMultiple: PropTypes.func,
  }
  const TabPanel = props => {
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };
    // eslint-disable-next-line no-shadow
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ width: "100%" }}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="menu settings tabs">
          <Tab label="Choice Settings" />
          <Tab label="Item List" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ChoiceSettings
          key={"choice-settings"}
          setNumberOfMultipleChoices={setNumberOfMultipleChoices}
          notifyOnNetworkStatusChange={true}
          setMultipleLabel={setMultipleLabel}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ItemList/>
      </TabPanel>
    </div>
  );
};
export default MultipleEditTab;
