import React from 'react';

import PropTypes from 'prop-types';

import { ConnectionTable } from '../../../../../blocks';
import { generateStoreAbbreviation } from '../../../../../../utils';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

const LocationListView = ({ query, history, mutationPermissions }) => {
  return (
    <ConnectionTable
      query={query}
      title="Select a Location"
      minSearchValueLength={1}
      headerHidden={'xs'}
      initialQueryVariables={{
        first: 15,
        fieldGroupOnly: 88,
        specificFields: ['label', 'store_number'],
      }}
      onTableRowClick={row => {
        history.push(
          `${history.location.pathname}/locations/${row.locationId}-${row.storeNumber}/task-list`,
        );
      }}
      structureTable={data => {
        return data?.viewer?.locationConnection?.edges.map(location => ({
          id: generateStoreAbbreviation(location.node).id,
          locationId: location.node.locationId,
          storeNumber: location.node.storeNumber,
          storeAbbreviation: generateStoreAbbreviation(location.node),
          label: location.node.label,
        }));
      }}
      columns={[
        {
          title: 'ID',
          enablePropagation: true,
          field: 'id',
          hidden: 'xs',
          customSortField: 'storeNumber',
          render: (id, _, row) => {
            return row.storeAbbreviation.abbreviation;
          },
        },
        {
          title: 'Name',
          enablePropagation: true,
          field: 'label',
          render: (label, _, row) => (
            <Grid
              container
              spacing={3}
              style={{ paddingLeft: '12px' }}
              justify="space-between"
              alignItems="center"
            >
              <Hidden smUp>
                <Grid item>
                  <Typography variant="body1">
                    {row.storeAbbreviation.abbreviation} - {label}
                  </Typography>
                </Grid>
                <Grid item>
                  <ArrowForwardIos style={{ fontSize: '12px', color: '#595959' }} />
                </Grid>
              </Hidden>
              <Hidden only="xs">
                <Typography variant="body1">{label}</Typography>
              </Hidden>
            </Grid>
          ),
        },
      ]}
    />
  );
};

LocationListView.propTypes = {
  query: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  createNewLocation: PropTypes.func.isRequired,
  handleLocationActivation: PropTypes.func.isRequired,
  bulkEditLocations: PropTypes.func.isRequired,
};

export default LocationListView;
