export const getDisputeInfo = (data) => {
  const { disputeDueBy, disputeReason, paymentDetails } = data.viewer.quote.paymentConnection.edges[0].node.paymentGatewayConnection.edges[0].node;
  const { amount } = data.viewer.quote.paymentConnection.edges[0].node
  return { disputeDueBy, disputeReason, amount, paymentDetails };
}

export const isDispute = (data) => {
  if(!data.viewer.quote){
    return false;
  }
  const { disputeReason} = data.viewer.quote.paymentConnection.edges[0].node.paymentGatewayConnection.edges[0].node;
  return disputeReason ? true : false;
}