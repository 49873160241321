import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PaperMain from "../../../blocks/PaperMain";

import { HierarchySideBar, HierarchyDetails } from "./View";
import { HierarchyStyles } from "./View/styles";
import UserDataGrid from "./DataGrids/UserDataGrid";
import LocationDataGrid from "./DataGrids/LocationDataGrid";
import { Elements } from "../../../blocks";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_LOGIN_HIERARCHY,
  CREATE_PERSON_LOCATION_HIERARCHY,
} from "../../../../apollo/mutations";
import LocationUsers from "./LocationUsers";
import { FETCH_PERSONS } from "../../../../apollo/queries/fetchLocationPersons";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const Hierarchy = () => {
  const { setSnackbar } = useSnackbar();
  const classes = HierarchyStyles();

  const [area, setArea] = React.useState({});
  const [locationId, setLocationId] = React.useState(null);
  const [locationDetails, setLocationDetails] = React.useState({});
  const [locationIds, setLocationIds] = React.useState([]);
  const [hierarchyIds, setHierarchyIds] = React.useState([]);
  const [loginIds, setLoginIds] = React.useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = React.useState([]);
  const [userIds, setUserIds] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [toggleOption, setToggleOption] = React.useState("locations");
  const [reset, setReset] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [bulkUpdateUsersState, setBulkUpdateUsersState] = React.useState({
    action: "none",
  });

  const [createPersonHierarchy] = useMutation(
    CREATE_PERSON_LOCATION_HIERARCHY,
    {
      onCompleted: (data) => {
        setButtonDisabled(false);
        if (locationId) {
          loadData();
          setSnackbar({
            open: true,
            type: "success",
            text: "Location Users updated.",
          });
          setUserIds([]);
          setLoginIds([]);
          setLocationIds([]);
          // setSelectedLocationIds([]);
          setReset(true);
          setBulkUpdateUsersState({ action: "none" });
          return;
        }
        setSnackbar({
          open: true,
          type: "success",
          text: "Users added to locations",
        });
        setUserIds([]);
        setLoginIds([]);
        setLocationIds([]);
        setSelectedLocationIds([]);
        setReset(true);
        setBulkUpdateUsersState({ action: "none" });
        // alert("Employees added!");
      },
      onError: (err) => {
        console.log(err);
        setButtonDisabled(false);
        setSnackbar({
          open: true,
          type: "error",
          text: "Oops, something went wrong.",
        });
      },
    }
  );

  const [createLoginHierarchy] = useMutation(CREATE_LOGIN_HIERARCHY, {
    onCompleted: (data) => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: "success",
        text: "Users added to hierarchy",
      });
      setUserIds([]);
      setLoginIds([]);
      setLocationIds([]);
      setSelectedLocationIds([]);
      setHierarchyIds([]);
      setReset(true);
    },
    onError: (err) => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: "error",
        text: "Oops, something went wrong.",
      });
    },
  });

  React.useEffect(() => {
    if (locationId) {
      setFilter({
        showOnlyEmployees: { eq: true },
        showEmployesOnLocation: locationId,
      });
    } else {
      setFilter({
        showOnlyEmployees: { eq: true },
      });
    }
  }, [locationId]);

  const [loadData] = useLazyQuery(FETCH_PERSONS, {
    variables: {
      filter,
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.log(error, "error");
    },
    fetchPolicy: "network-only",
  });

  const options = [
    {
      label: "Locations",
      value: "locations",
    },
    {
      label: "Users",
      value: "users",
    },
  ];

  const handleOptionChange = (value) => {
    setUserIds([]);
    setLoginIds([]);
    setHierarchyIds([]);
    setLocationIds([]);
    setLocationId(null);
    setToggleOption(value);
  };

  const sendUsersAndLocations = async () => {
    setButtonDisabled(true);
    // location_hierarchy
    const newLocations = locationIds
      .filter((loc) => loc.isLocation)
      .map((loc) => +atob(loc.id).split(":")[1]);
    if (newLocations.length > 0 || locationId) {
      await createPersonHierarchy({
        variables: {
          input: {
            locationIds: newLocations.length > 0 ? newLocations : locationId,
            persons: userIds,
            bulkLocationPersonUpdate: bulkUpdateUsersState,
          },
        },
      });
    }

    // login_hierarchy
    const newLoginHierachy = hierarchyIds.map(
      (hier) => +atob(hier.id).split(":")[1]
    );
    if (newLoginHierachy.length || area.id) {
      await createLoginHierarchy({
        variables: {
          input: {
            hierarchyIds: newLoginHierachy.length
              ? newLoginHierachy
              : [+atob(area.id).split(":")[1]],
            loginIds,
          },
        },
      });
    }
  };

  const renderSideBar = () => {
    switch (toggleOption) {
      case "users":
        return (
          <UserDataGrid
            setLoginIds={setLoginIds}
            loginIds={loginIds}
            reset={reset}
            setReset={setReset}
            userIds={userIds}
            bulkUpdateUsersState={bulkUpdateUsersState}
            setBulkUpdateUsersState={setBulkUpdateUsersState}
            buttonDisabled={buttonDisabled}
            setUserIds={setUserIds}
            sendUsersAndLocations={sendUsersAndLocations}
          />
        );
      case "locations":
        return (
          <LocationDataGrid
            locations={selectedLocationIds}
            setLocations={setSelectedLocationIds}
            area={area}
          />
        );
      default:
        return "";
    }
  };

  return (
    <PaperMain>
      <Grid container>
        <Grid item xs={9}>
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Location Structure
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.toggle}>
          <Elements.ToggleButton
            value={options[0].value}
            handleToggleClick={(value) => handleOptionChange(value)}
            options={options}
          />
        </Grid>
        <Grid item xs={3} direction="column">
          <HierarchySideBar
            setArea={setArea}
            setHierarchyIds={setHierarchyIds}
            hierarchyIds={hierarchyIds}
            setLocationId={setLocationId}
            setLocationDetails={setLocationDetails}
            locations={toggleOption === "users" ? true : false}
            locationIds={toggleOption === "users" ? locationIds : null}
            setLocationIds={toggleOption === "users" ? setLocationIds : null}
          />
        </Grid>
        <Grid item xs={3} direction="column" className={classes.areas}>
          {renderSideBar()}
        </Grid>
        {!locationId && (
          <Grid item xs={6} direction="column" className={classes.properties}>
            <HierarchyDetails
              bulkUpdateUsersState={bulkUpdateUsersState}
              setBulkUpdateUsersState={setBulkUpdateUsersState}
              locationId={locationId}
              area={area}
              locationDetails={locationDetails}
            />
          </Grid>
        )}
        {locationId && (
          <Grid item xs={6} direction="column" className={classes.properties}>
            <LocationUsers
              sendUsersAndLocations={sendUsersAndLocations}
              userIds={userIds}
              loginIds={loginIds}
              setLoginIds={setLoginIds}
              bulkUpdateUsersState={bulkUpdateUsersState}
              setBulkUpdateUsersState={setBulkUpdateUsersState}
              setUserIds={setUserIds}
              locationId={locationId}
              locationDetails={locationDetails}
            />
          </Grid>
        )}
      </Grid>
    </PaperMain>
  );
};

export default Hierarchy;
