import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';

function NutrientForm({ handleNutrientsChange, nut, ingredientNutrientConnection, control }) {
  const nutrient = ingredientNutrientConnection.edges.filter(
    edge => edge.node.nutrient.id === nut.id,
  )[0];
  const { id } = nut;
  const [initQuantity, setInitQuantity] = useState(nutrient ? nutrient.node.quantity : 0);
  return (
    <div>
      <Controller
        render={() => (
          <TextField
            type="number"
            inputProps={{
              min: 0,
            }}
            onChange={e => {
              const temp_nutrient = { nutrientId: id, quantity: +e.target.value };
              setInitQuantity(+e.target.value);
              handleNutrientsChange(temp_nutrient);
            }}
            defaultValue={initQuantity}
            label={`${nut.label} (${nut.measure.abbreviation}).`}
            fullWidth
          />
        )}
        control={control}
        name={'nutrients'}
      />
    </div>
  );
}

NutrientForm.propTypes = {
  handleNutrientsChange: PropTypes.func,
  nut: PropTypes.object,
  ingredientNutrientConnection: PropTypes.object,
  control: PropTypes.object,
};

export default NutrientForm;
