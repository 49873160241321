import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import ConnectionTable from '../../../blocks/ConnectionTable';
import { CATERING_ORDERS, FETCH_STATES } from '../../../../apollo/queries';
import { generateStoreAbbreviation } from '../../../../utils';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { convert } from '../../../../utils';
import { compact, debounce, get, groupBy } from 'lodash';
import SearchIcon from '../../../../assets/icons/SearchIcon.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SelectFactory } from '../../../blocks';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useLazyQuery, useQuery } from '@apollo/client';
import { LOCATIONS } from '../../../../apollo/queries/filters';
import { orderListViewStyles } from '../../Orders/AllOrders/List/View/styles';
import STATUS from '../../../../apollo/queries/statusFetch';
import { CoreContext } from '../../../../CoreContext';
import { Permission, PermissionField } from '../../../blocks';
import { useFormSubmitPermissions } from '../../../../hooks';
import { MUTATION_NAME } from '../../../../constants';
import { Autocomplete } from '@material-ui/lab';
import FETCH_CATERING_EXPERT from '../../../../apollo/queries/caterings/fetchCateringExpert';

const NO_CATERING_EXPERT_SELECTED_FILTER_VALUE = 'no expert';
const NO_CATERING_EXPERT_NO_FILTER_VALUE = 'no filter';

const CateringOrderList = () => {
  const history = useHistory();
  const context = useContext(CoreContext);
  const specificPermissions = context?.coreUtils?.getAppActions()[51]?.specific;

  const [searchValue, setSearchValue] = useState();
  const [permissions, setPermissions] = useState();

  const unpaidCateringsPermission = useMemo(
    () => specificPermissions.find(permission => permission.label === 'unpaidCaterings'),
    [specificPermissions],
  );

  const { accessToMutations } = useFormSubmitPermissions({
    mutationNames: [MUTATION_NAME.CreateQuotecateringNDMutationInput],
    permissions,
  });

  const createNewCateringOrder = () =>
    history.push(`${history.location.pathname}/new-catering-order/generalInfo`);
  const classes = orderListViewStyles();
  const [openDateRangeSelectionModal, setOpenDateRangeSelectionModal] = useState(false);
  const [dateRangeStart, setDateRangeStart] = useState(moment());
  const [errors, setErrors] = useState({});
  const [dateRangeEnd, setDateRangeEnd] = useState(moment());
  const { data: statusData, loading: statusLoading, error: statusError } = useQuery(STATUS);

  const [
    getLocationsByState,
    { data: locationData, loading: locationLoading, error: locationError },
  ] = useLazyQuery(LOCATIONS);

  const { data: stateData, loading: stateLoading, error: stateError } = useQuery(FETCH_STATES, {
    variables: {
      sort: {
        label: 'ASC',
      },
    },
  });

  const {
    data: cateringExpertData,
    loading: cateringExpertLoading,
    error: cateringExpertError,
  } = useQuery(FETCH_CATERING_EXPERT, {
    variables: {
      filter: {
        accessLevel: {
          eq: '866',
        },
      },
      sort: {
        person: {
          lastName: 'ASC',
        },
      },
    },
  });

  const getSelectedDateRangeValue = (field, variables) => {
    const filters = variables.filter;
    const startDate = filters?.[field]?.gte;
    const endDate = filters?.[field]?.lte;

    return `${(startDate && moment(startDate)?.format('MM/DD/YYYY')) ||
      'MM/DD/YYYY'} to ${(endDate && moment(endDate)?.format('MM/DD/YYYY')) || 'MM/DD/YYYY'}`;
  };

  const handleDateRangeSelection = (field, { refetch, variables }, removeDate = false) => {
    setErrors({});

    if (removeDate) {
      const filters = variables.filter;
      delete filters[field];
      refetch({
        ...variables,
        filter: filters,
      });
    } else {
      if (moment(dateRangeStart).isAfter(moment(dateRangeEnd))) {
        setErrors({ dateRangeEnd: 'Invalid date range.' });

        return;
      }
      // need only date
      const extractedDateStart = dateRangeStart.format('YYYY-MM-DD');
      const extractedDateEnd = dateRangeEnd.format('YYYY-MM-DD');
      // convert date to utc
      const startDateConvertedToUTC = moment(extractedDateStart).utc();
      const endDateConvertedToUTC = moment(extractedDateEnd)
        .utc()
        .add(23, 'hours')
        .add('59', 'minutes')
        .add(59, 'seconds');

      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          [field]: {
            gte: startDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss'),
            lte: endDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      });
    }
    setOpenDateRangeSelectionModal(false);
  };

  const search = (refetch, variables, value) => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
        quoteId: {
          eq: parseInt(value),
        },
      },
    });
    setSearchValue(value);
  };

  const removeQuoteIdFilter = (refetch, variables) => {
    delete variables.filter.quoteId;
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
      },
    });
    document.getElementById('quoteID').value = null;
    setSearchValue(null);
  };

  const [debouncedSearch] = useState(() =>
    debounce((refetch, variables, value) => search(refetch, variables, value), 400),
  );
  const handleSearchInput = (refetch, variables, event) => {
    setSearchValue(event.target.value);
  };

  const handleDateRangeStartChange = value => {
    setDateRangeStart(value);
  };
  const handleDateRangeEndChange = value => {
    setDateRangeEnd(value);
  };

  const handleOpenDateRangeSelectionModal = field => {
    setOpenDateRangeSelectionModal(field);
  };
  const handleCloseDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(false);
  };

  const stateList = useMemo(
    () =>
      stateData?.viewer?.stateConnection?.edges?.map(state => ({
        label: state.node.label,
        value: state.node.id,
      })),
    [stateData?.viewer?.stateConnection?.edges],
  );

  const cities = useMemo(() => {
    const uniqueCities = locationData?.viewer?.locationConnection?.edges?.slice((cityA, cityB) =>
      cityA.node.address.city > cityB.node.address.city ? 1 : -1,
    );

    const groupedResult = groupBy(uniqueCities, 'node.address.city');

    return Object.entries(groupedResult)
      .map(value => ({
        label: value[0],
        value: value[0],
      }))
      .sort((cityA, cityB) => cityA.label.localeCompare(cityB.label));
  }, [locationData?.viewer?.locationConnection?.edges]);

  const filterLocations = variables => {
    if (variables?.filter?.quote?.location?.address?.city) {
      return {
        address: {
          city: {
            eq: variables.filter.quote.location.address.city.eq,
          },
        },
      };
    } else if (variables?.filter?.quote?.location?.address?.state) {
      return {
        address: {
          state: {
            id: {
              eq: variables.filter.quote.location.address.state.id.eq,
            },
          },
        },
      };
    } else {
      return {};
    }
  };

  if (statusLoading || stateLoading || cateringExpertLoading) {
    return <CircularProgress />;
  }
  if (statusError || stateError || cateringExpertError) {
    return <div>Error</div>;
  }

  return (
    <>
      <ConnectionTable
        query={CATERING_ORDERS}
        fetchPolicy={'network-and-cache'}
        onTableRowClick={row =>
          window.open(`${history.location.pathname}/${row.quoteId}/generalInfo`)
        }
        initialQueryVariables={{
          first: 10,
          filter: {
            ignoreShowLocation: {
              eq: !!unpaidCateringsPermission?.access,
            },
          },
          sort: [{ inquiryDate: 'DESC' }],
          specificFields: ['first_name', 'last_name', 'contact_phone', 'contact_name', 'email'],
        }}
        title="Catering Orders"
        customActionsCenter={(data, { refetch, variables }, selected, permissions) => (
          <Grid container spacing={2} alignItems="center" style={{ marginLeft: '0.5rem' }} xs={12}>
            <Permission access={permissions?.quote.location?.__typename && 7}>
              <Grid xs={2} style={{ paddingRight: '6px' }}>
                <Autocomplete
                  id="state"
                  getOptionLabel={option => option.label}
                  defaultValue={{ label: 'None', value: 0 }}
                  renderInput={params => <TextField {...params} label="State" />}
                  onChange={(event, selected) => {
                    document.querySelector('#city__grid .MuiAutocomplete-clearIndicator')?.click();

                    if (selected) {
                      getLocationsByState({
                        variables: {
                          filter: {
                            address: {
                              state: {
                                id: {
                                  eq: selected.value,
                                },
                              },
                            },
                          },
                        },
                      });
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          quote: {
                            ...variables.filter.quote,
                            location: {
                              ...variables.filter?.quote?.location,
                              address: {
                                state: {
                                  id: {
                                    eq: selected.value,
                                  },
                                },
                              },
                            },
                          },
                        },
                      });
                    } else if (variables?.filter?.quote?.location?.address?.state?.id?.eq) {
                      const filters = variables.filter;
                      delete filters.quote.location.address;

                      getLocationsByState({
                        variables: {
                          filter: {
                            address: {
                              state: {
                                id: {
                                  eq: '',
                                },
                              },
                            },
                          },
                        },
                      });
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    }
                  }}
                  options={stateList || []}
                  fullWidth
                />
              </Grid>
              <Grid xs={2} id="city__grid">
                <Autocomplete
                  id="city"
                  getOptionLabel={option => option.label}
                  defaultValue={{ label: 'None', value: 0 }}
                  renderInput={params => <TextField {...params} label="City" />}
                  onChange={(event, selected) => {
                    if (
                      (!selected || selected.value === 'None') &&
                      variables?.filter?.quote?.location?.address
                    ) {
                      const filters = variables.filter;
                      delete filters.quote.location.address.city;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else if (selected?.value) {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          quote: {
                            ...variables.filter?.quote,
                            location: {
                              ...variables.filter?.quote?.location,
                              address: {
                                ...variables.filter?.quote?.location?.address,
                                city: {
                                  eq: selected.value,
                                },
                              },
                            },
                          },
                        },
                      });
                    }
                  }}
                  options={cities || []}
                  disabled={!cities.length}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <SelectFactory
                  label={'Location'}
                  fullWidth
                  placeholder={'Select Location'}
                  query={LOCATIONS}
                  sort={{
                    label: 'ASC',
                  }}
                  filter={filterLocations(variables)}
                  disableCloseOnSelect={true}
                  structureData={data => {
                    return data?.viewer?.locationConnection?.edges.map((location, index) => {
                      return {
                        label: compact([location.node.label, location.node.storeNumber]).join(
                          ' - #',
                        ),
                        id: location.node.id,
                        index,
                      };
                    });
                  }}
                  onSelect={values => {
                    const locationIds = values.map(location => location.id);
                    if (values.length === 0) {
                      const filters = variables.filter;
                      delete filters.location;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          quote: {
                            location: {
                              id: {
                                in: locationIds,
                              },
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              </Grid>
            </Permission>
            <Permission access={permissions?.status?.__typename && 7}>
              <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    defaultValue=""
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.stageId;
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            statusId: {
                              null: false,
                            },
                          },
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            statusId: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {statusData?.viewer?.statusConnection?.edges?.map(status => (
                      <MenuItem key={status?.node?.statusId} value={status?.node?.statusId}>
                        <FiberManualRecordIcon
                          style={{
                            fontSize: '0.9rem',
                            marginRight: '.5rem',
                            color: status?.node?.color?.code,
                          }}
                        />
                        {status?.node?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Permission>
            <Permission access={permissions?.inquiryDate && 7}>
              <Grid item xs={2}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ padding: 0 }}
                  className={classes.selectDateRange}
                >
                  <IconButton
                    disableRipple
                    onClick={() => handleOpenDateRangeSelectionModal('inquiryDate')}
                    className={classes.dateRangeTextButton}
                  >
                    <TextField
                      fullWidth
                      label="Select Inquiry Date Range"
                      value={getSelectedDateRangeValue('inquiryDate', variables)}
                      InputProps={{
                        readOnly: true,
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                      variant="outlined"
                    />
                  </IconButton>
                  {getSelectedDateRangeValue('inquiryDate', variables) !==
                  'MM/DD/YYYY to MM/DD/YYYY' ? (
                    <IconButton
                      disableRipple
                      className={classes.dateRangeCloseButton}
                      onClick={() =>
                        handleDateRangeSelection('inquiryDate', { refetch, variables }, true)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : null}
                </FormControl>
              </Grid>
            </Permission>
            <Permission access={permissions?.eventDate && 7}>
              <Grid item xs={2}>
                <FormControl
                  variant="outlined"
                  style={{ padding: 0 }}
                  fullWidth
                  className={classes.selectDateRange}
                >
                  <IconButton
                    disableRipple
                    onClick={() => handleOpenDateRangeSelectionModal('eventDate')}
                    className={classes.dateRangeTextButton}
                  >
                    <TextField
                      label="Select Event Date Range"
                      fullWidth
                      value={getSelectedDateRangeValue('eventDate', variables)}
                      InputProps={{
                        readOnly: true,
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                      variant="outlined"
                    />
                  </IconButton>
                  {getSelectedDateRangeValue('eventDate', variables) !==
                  'MM/DD/YYYY to MM/DD/YYYY' ? (
                    <IconButton
                      disableRipple
                      className={classes.dateRangeCloseButton}
                      onClick={() =>
                        handleDateRangeSelection('eventDate', { refetch, variables }, true)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : null}
                </FormControl>
              </Grid>
            </Permission>
            <Dialog
              open={!!openDateRangeSelectionModal}
              onClose={handleCloseDateRangeSelectionModal}
              disableEnforceFocus
            >
              <DialogTitle disableTypography>
                <Typography variant="h6">Select Date Range</Typography>
                <IconButton
                  aria-label="close"
                  className={classes.dialogCloseButton}
                  onClick={handleCloseDateRangeSelectionModal}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DialogContent dividers>
                  <Grid container justify="space-between" spacing={3}>
                    <Grid item>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        margin="normal"
                        label="Date range start"
                        value={dateRangeStart}
                        onChange={handleDateRangeStartChange}
                      />
                    </Grid>
                    <Grid item>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        margin="normal"
                        label="Date range end"
                        value={dateRangeEnd}
                        error={errors.dateRangeEnd}
                        helperText={errors.dateRangeEnd}
                        onChange={handleDateRangeEndChange}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      handleDateRangeSelection(openDateRangeSelectionModal, { refetch, variables })
                    }
                  >
                    Apply
                  </Button>
                </DialogActions>
              </MuiPickersUtilsProvider>
            </Dialog>
          </Grid>
        )}
        structureTable={data =>
          data?.viewer?.quotecateringConnection?.edges.map(quotecatering => ({
            'quote.origin.label': quotecatering.node.quote?.origin?.label,
            quoteId: quotecatering.node.quote?.quoteId,
            'quote.location': generateStoreAbbreviation(quotecatering?.node?.quote?.location)
              .abbreviation,
            'quote.person': `${quotecatering.node.quote?.person?.firstName} ${quotecatering.node.quote?.person?.lastName}`,
            'quote.totalPrice': `$${convert.centsToDollars(quotecatering.node.quote?.totalPrice)}`,
            'quote.dueAmount': `$${convert.centsToDollars(
              quotecatering.node.quote?.dueAmount + quotecatering.node.quote?.refunded,
            )}`,
            'quote.expected': quotecatering.node.inquiryDate
              ? moment
                  .tz(
                    moment.utc(quotecatering.node.inquiryDate),
                    quotecatering.node.quote?.location?.timezone?.label,
                  )
                  .format('MM/DD/YYYY hh:mm A')
              : '-',
            'status.label': {
              label: quotecatering.node.status?.label,
              color: quotecatering.node.status?.color?.code,
            },
            'status.action': quotecatering.node.status?.action,
            'quote.eventDate': quotecatering.node?.eventDate
              ? moment
                  .tz(
                    moment.utc(quotecatering.node?.eventDate),
                    quotecatering.node.quote?.location?.timezone?.label,
                  )
                  .format('MM/DD/YYYY hh:mm A')
              : '-',
            cateringExpert: `${get(
              quotecatering.node.cateringExpert,
              'person.firstName',
              '',
            )} ${get(quotecatering.node.cateringExpert, 'person.lastName', '')}`,
          }))
        }
        hideTotalCount={true}
        columns={[
          { title: 'Source', field: 'quote.origin.label' },
          { title: 'Quote ID', field: 'quoteId' },
          {
            title: 'Location ID',
            field: 'quote.location',
            customSortField: 'quote.location.storeNumber',
            customPermissionField: 'quote.location.id',
          },
          {
            title: 'Customer',
            field: 'quote.person',
            customSortField: 'quote.person.firstName',
            customPermissionField: 'quote.person.__typename',
          },
          { title: 'Total', field: 'quote.totalPrice' },
          { title: 'Due Amount', field: 'quote.dueAmount' },
          { title: 'Inquiry date', field: 'quote.expected', customSortField: 'inquiryDate' },
          { title: 'Event date', field: 'quote.eventDate', customSortField: 'eventDate' },
          {
            title: 'Status',
            field: 'status.label',
            render: (status, _, row) => (
              <div>
                {status?.label && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <FiberManualRecordIcon
                        style={{ fontSize: '0.9rem', marginRight: '.5rem', color: status?.color }}
                      />
                    </div>
                    <div>{status?.label}</div>
                  </div>
                )}
              </div>
            ),
          },
          {
            title: 'Action',
            field: 'status.action',
          },
          {
            title: 'Catering Expert',
            field: 'cateringExpert',
            customSortField: 'cateringExpert.person.firstName',
            customPermissionField: 'cateringExpert.person.__typename',
          },
        ]}
        /* eslint-disable-next-line no-unused-vars */
        customActionsTop={(data, query) => {
          if (!permissions) {
            setPermissions({
              ...data?.viewer?.quotecateringConnection?.permissions,
              cateringExpertId:
                data?.viewer?.quotecateringConnection?.permissions?.cateringExpert?.person
                  ?.__typename,
              cateringStatusId:
                data?.viewer?.quotecateringConnection?.permissions?.status?.__typename,
              eventType: data?.viewer?.quotecateringConnection?.permissions?.eventTypeId,
              locationId:
                data?.viewer?.quotecateringConnection?.permissions?.quote?.location?.__typename,
            });
          }

          return (
            <Permission
              access={accessToMutations?.[MUTATION_NAME.CreateQuotecateringNDMutationInput]}
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    onClick={createNewCateringOrder}
                    variant="contained"
                    style={{ marginBottom: '1rem' }}
                  >
                    New Order
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          );
        }}
        customActionsBottom={(data, { refetch, variables }, _, permissions) => {
          return (
            <Grid container style={{ marginTop: '0.2rem', width: '40%' }}>
              <Permission access={permissions?.quote?.quoteId && 7}>
                <Grid item xs={6}>
                  <TextField
                    name={'quoteID'}
                    id={'quoteID'}
                    variant={'outlined'}
                    type={'number'}
                    onKeyPress={ev => {
                      if (ev.key === 'Enter') {
                        debouncedSearch(refetch, variables, searchValue);
                      }
                    }}
                    value={searchValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => debouncedSearch(refetch, variables, searchValue)}
                          style={{ cursor: 'pointer' }}
                        >
                          <img alt="Search icon" src={SearchIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          aria-label="remove"
                          onClick={() => removeQuoteIdFilter(refetch, variables)}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      ),
                    }}
                    onChange={event => handleSearchInput(refetch, variables, event)}
                    placeholder={'Search ID'}
                  />
                </Grid>
              </Permission>
              <Permission access={permissions?.cateringExpert?.__typename && 7}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Catering Expert</InputLabel>
                    <Select
                      defaultValue=""
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={event => {
                        const value = event.target.value;
                        if (value === NO_CATERING_EXPERT_NO_FILTER_VALUE) {
                          const filters = variables.filter;
                          delete filters.cateringExpert;
                          delete filters.cateringExpertId;
                          refetch({
                            ...variables,
                            filter: {
                              ...variables.filter,
                            },
                          });
                        } else if (value === NO_CATERING_EXPERT_SELECTED_FILTER_VALUE) {
                          const filters = variables.filter;
                          delete filters.cateringExpert;
                          refetch({
                            ...variables,
                            filter: {
                              ...variables.filter,
                              cateringExpertId: {
                                null: true,
                              },
                            },
                          });
                        } else {
                          const filters = variables.filter;
                          delete filters.cateringExpertId;
                          refetch({
                            ...variables,
                            filter: {
                              ...variables.filter,
                              cateringExpert: {
                                person: {
                                  id: {
                                    eq: value,
                                  },
                                },
                              },
                            },
                          });
                        }
                      }}
                      label="Catering Expert"
                    >
                      <MenuItem
                        key={NO_CATERING_EXPERT_NO_FILTER_VALUE}
                        value={NO_CATERING_EXPERT_NO_FILTER_VALUE}
                      >
                        <em>No Filter</em>
                      </MenuItem>
                      <MenuItem
                        key={NO_CATERING_EXPERT_SELECTED_FILTER_VALUE}
                        value={NO_CATERING_EXPERT_SELECTED_FILTER_VALUE}
                      >
                        <em>No Catering Expert</em>
                      </MenuItem>
                      {cateringExpertData?.viewer?.loginConnection?.edges?.map(cateringExpert => (
                        <MenuItem
                          key={cateringExpert.node.person.id}
                          value={cateringExpert.node.person.id}
                        >
                          {`${cateringExpert.node.person.firstName} ${cateringExpert.node.person.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Permission>
            </Grid>
          );
        }}
        // customActionsCenter={(data, query, selected) => <Button onClick={() => bulkEditLocations(selected)} variant="contained">Bulk Editing</Button>
      />
    </>
  );
};

export default CateringOrderList;
