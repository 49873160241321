import { gql } from '@apollo/client';

const CREATE_SUBCATEGORY = gql`
  mutation crateSubcategory($input: CreateSubcategoryMutationInput!) {
    createSubcategory(input: $input) {
      subcategoryEdge {
        node {
          subcategoryId
        }
      }
    }
  }
`;

export default CREATE_SUBCATEGORY;
