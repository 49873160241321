import { makeStyles } from '@material-ui/core';
const Styles = (theme) => ({
  selectFactoryWrap: {
    display: 'flex',
    marginLeft: "0.5rem",
    alignItems: 'center',
    width: "100%",
    justifyContent: 'center',
    '& > div': {
      minWidth: '145px',
      padding: '0px 12px 0px 0px',
      flex: 1,
    },
  },
  selectDateRange: {
    flexDirection: 'row',    
    '& button': {
      padding: 0,
      '& span > div': {
        flex: 1,
      },
    },

    '&:hover $dateRangeCloseButton': {
      visibility: 'visible',
    },
  },
  dateRangeTextButton: {
    flex: 1,
    padding: 0,

    '&:hover': {
      background: 'none',
    },
  },
  dateRangeCloseButton: {    
    position: 'absolute',
    right: 20,
    top: '50%',
    transform: 'translateY(-50%)',
    visibility: 'hidden',
    backgroundColor: theme.customToggleButton.backgroundColor,
    color: theme.customToggleButton.labelColor,

    '&:hover': {
      backgroundColor: theme.customToggleButton.hoverBackgroundColor,
    },

    '& svg': {
      transform: 'scale(.75)',
    }
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
});

export const orderListViewStyles = makeStyles(Styles);
