import React from 'react';
import PropTypes from 'prop-types';
import ConnectionTable from '../../../../../../../blocks/ConnectionTable';
import SwitchButton from './switchButton';
import INGREDIENT_LOCATION_AVAILABILITY from '../../../../../../../../apollo/queries/ingredientLocationAvailability';
import CustomActionsTop from '../CustomActionsTop';
function LocationAvailabilityView({
  locationIngredientId,
  handleLocationsChange,
  inStock,
  myLocations,
  control,
  register,
  setInStock,
}) {
  return (
    <div>
      <ConnectionTable
        nested
        title={'Location Availability'}
        query={INGREDIENT_LOCATION_AVAILABILITY}
        initialQueryVariables={{
          first: 15,
          ingredientFilter: {
            ingredientId: { eq: locationIngredientId || null },
          },
          fieldGroupOnly: 89,
        }}
        customActionsTop={() => <CustomActionsTop setInStock={setInStock} register={register} />}
        structureTable={data => {
          return data?.viewer?.locationConnection?.edges.map(location => {
            return {
              storeNumber: location.node.storeNumber,
              name: location.node.label,
              city: location.node.address.city,
              state: location.node.address.state.label,
              available: location.node,
            };
          });
        }}
        columns={[
          { title: 'ID', field: 'storeNumber' },
          { title: 'Name', field: 'name' },
          { title: 'City', field: 'city' },
          { title: 'State', field: 'state' },
          {
            title: 'In Stock',
            field: 'available',
            render: location => (
              <SwitchButton
                control={control}
                myLocations={myLocations}
                location={location}
                handleLocationsChange={handleLocationsChange}
                inStock={inStock}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

LocationAvailabilityView.propTypes = {
  locationIngredientId: PropTypes.number,
  handleLocationsChange: PropTypes.func,
  inStock: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  myLocations: PropTypes.array,
  control: PropTypes.object,
};

export default LocationAvailabilityView;
