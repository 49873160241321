import { gql } from "@apollo/client";

const CREATE_TAX_GROUP_TAX_RATE = gql`
  mutation CreateTaxGroupTaxRate($input: CreateTaxgroupTaxrateMutationInput!) {
    createTaxgroupTaxrate(input: $input) {
      taxgroup {
        id
      }
      taxgroupTaxrate {
        id
      }
      viewer {
        id
      }
    }
  }
`;

export default CREATE_TAX_GROUP_TAX_RATE;
