import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { set, get } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Table, TableContainer } from '@material-ui/core';

import PaperMain from '../PaperMain';
import ConfirmationModal from '../ConfirmationModal';

import {
  ConnectionTableBody,
  ConnectionTableHead,
  ConnectionTablePagination,
  ConnectionTableToolbar,
} from './View';

import { tableSortTypes, ROWS_PER_PAGE_OPTIONS, DEFAULT_PAGE } from '../../../constants';
import { Hidden } from '@material-ui/core';

const getShouldRenderTable = (columns, permissions) => {
  return !!columns.find(
    column =>
      ![0, null].includes(get(permissions, column.customPermissionField || column.field, 7)),
  );
};

/*
CONNECTION TABLE = a "smart" table used to manipulate backend data, almost every action on this table is a
                   request to our API.

PROPS:
  selectAllCheckbox - Toggle for selecting all currently listed rows, works in combination with the onSelectAllClick handler
  columns - array of objects that define the title, data, sorting, filtering of a specific table column
          - object consists of properties: title, field, filter, render, cellStyle, customSortField
          - title - String value displayed as title of the column in the head of the table
          - field - value used to map legit values with columns, recommended to be a string that represents
                    an object path from node to the legit value that will be mapped on the column.
                    Example: if we are iterating through quoteConnection and want to map the origin of the quote
                    to the column we can write something like this 'origin.label': quote.node.origin.label.
                    Reason why it is recommended for the field property to have a value like that is because that
                    value can then automatically be used for filtering and sorting.
          - filter - value used to render a filter dropdown that allows filtering over the values of this column.
                     If this property is missing there will be no filter for this column, if filter is set to true
                     the 'field' property value will be used for filtering. You can add a string to the filter property
                     to overwrite the value used for filtering.
          - render - function used to overwrite the value displayed in the table cell, intended for rendering of
                     icons, buttons, and other non string entities.
          - cellStyle - used to overwrite CSS of current column
          - customSortField - sort is enabled by default for each column and it takes value from "field" property
                              as value to sort over. This property allows you to overwrite the value used for sorting.
                              Kinda like using string for the "filter" property instead of "true" value.
          -customPermissionField - alternative field by which it searches for permission for that collumn
  title - title of the table displayed in top left corner of the table
  structureTable - function used to map real data to a specific column using the "field" key. Whichever data gets passed
                  here is will be displayed as a value in the column or will be passed as argument in properties like render
                  and cellStyle.
  customActionsTop - function used for rendering DOM elements at the top of the table, to the right of the title.
                   - receives 3 parameters: data, query, selected.
                   - data - result from the query and variables passed to the ConnectionTable
                   - query - object that contains: a function used for refetching the current query and variables that will be
                             passed to it
                   - selected - array of selected rows (their ids) if the rows are selectable
  selectableRows - determines whether or not the rows are selectable
  customActionsCenter - function used for rendering DOM elements between the search and filters
                      - receives 3 parameters: data, query, selected.
                      - data - result from the query and variables passed to the ConnectionTable
                      - query - object that contains: a function used for refetching the current query and variables that will be
                                passed to it
                      - selected - array of selected rows (their ids) if the rows are selectable
  initialQueryVariables - variables that will be used for the first data fetch, if none are defined the default "first: 5" variable will be used
  customActionsBottom - function used for rendering DOM elements at the bottom of the table toolbar
                      - receives 3 parameters: data, query, selected.
                      - data - result from the query and variables passed to the ConnectionTable
                      - query - object that contains: a function used for refetching the current query and variables that will be
                                passed to it
                      - selected - array of selected rows (their ids) if the rows are selectable
  query - a defined gql query (NOT QUERY RESULTS)
  onTableRowClick - function triggered when clicking on a table row
                  - receives 2 parameters: row, data.
                  - row - object that contains all values passed to the "field" property of the specific column
                  - data - result of query currently used in the ConnectionTable
  filterByDate - renders datepicker that allows for a specific time interval selection in which data displayed in the table will be fetched,
                 renders right of the filters. Takes in a string value that determines what date value to filter with.
  hideTotalCount - hides totalCount from the footer and removes some footer pagination functionalities. Used for connections that
                  have a hardcoded totalCount
  useConfirmationModalForDelete - shows confirmation modal for delete action
  paginateQuery - If there are more than one connections on the 'Viewer', it will always use the first connection it finds,
                                  using this prop you can control which connection on the 'Viewer' will be used for pagination. (or just put the connection you want to paginate first on the 'Viewer' in the query definition)
 */

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableContainerRoot: {
    margin: '0 -16px',
    width: 'calc(100% + 32px)',

    [theme.breakpoints.up('sm')]: {
      margin: 0,
      width: '100%',
    },
  },
}));

const ConnectionTable = ({
  columns,
  title,
  subtitle,
  loading: propsLoading,
  showSearch = true,
  structureTable,
  customActionsTop,
  searchStyle,
  searchPlaceholder,
  showPagination = true,
  selectableRows,
  customActionsCenter,
  initialQueryVariables,
  customActionsBottom,
  query,
  alignItemsCenter,
  paginateQuery,
  onTableRowClick,
  filterByDate,
  hideTotalCount,
  fetchPolicy = 'cache-first',
  getSelectedValues,
  handleDeleteRow,
  collapsableRows,
  nested = false,
  shouldRefetch,
  setShouldRefetch,
  preselectedValues = [],
  useConfirmationModalForDelete,
  deleteConfirmationText,
  dynamicDataFetching = true,
  minSearchValueLength,
  isDeleting,
  setConnectionVariables,
  selectAllCheckbox = true,
  headerHidden = true,
  connectionName: propsConnectionName,
}) => {
  const { data, loading, error, refetch, variables } = useQuery(query, {
    variables: initialQueryVariables || {
      first: ROWS_PER_PAGE_OPTIONS[0],
    },
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });
  const [connectionName, setConnectionName] = useState(propsConnectionName);
  const [order, setOrder] = useState(tableSortTypes.NONE);
  const [orderBy, setOrderBy] = useState();
  const [selected, setSelected] = useState(preselectedValues);
  const [deletedData, setDeleteData] = useState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [searchValue, setSearchValue] = useState();
  const [shouldRenderTable, setShouldRenderTable] = useState(true);

  const styles = useStyles();

  useEffect(() => {
    if (setConnectionVariables) {
      setConnectionVariables(variables);
    }
  }, [variables, setConnectionVariables]);

  useEffect(() => {
    if (!connectionName && data) {
      if (paginateQuery) {
        setConnectionName(paginateQuery);
        return;
      }
      setConnectionName(Object.keys(data.viewer).find(element => element.includes('Connection')));
    }
  }, [connectionName, data, paginateQuery]);

  useEffect(() => {
    if (shouldRefetch && setShouldRefetch) {
      setShouldRefetch(false);
      refetch({ variables: initialQueryVariables });
    }
  }, [shouldRefetch, setShouldRefetch, initialQueryVariables, refetch]);

  useEffect(() => {
    if (selected.length >= 0 && typeof getSelectedValues === 'function') {
      getSelectedValues(selected);
    }
  }, [getSelectedValues, selected]);

  useEffect(() => {
    if (preselectedValues && preselectedValues.length) setSelected(preselectedValues);
  }, [preselectedValues]);

  useEffect(() => {
    if (data?.viewer[connectionName]?.permissions) {
      setShouldRenderTable(
        getShouldRenderTable(columns, data?.viewer[connectionName]?.permissions),
      );
    }
  }, [columns, data?.viewer, connectionName]);

  const tableData = useMemo(() => structureTable(data, variables), [
    data,
    variables,
    structureTable,
  ]);

  // if (error) return <div>error</div>;

  const handleRequestSort = (event, property) => {
    const possibleDirections = [tableSortTypes.ASC, tableSortTypes.DESC, tableSortTypes.NONE];
    const currentDirectionIndex = possibleDirections.indexOf(order);
    const sortDirection =
      currentDirectionIndex === 2
        ? possibleDirections[0]
        : possibleDirections[currentDirectionIndex + 1];
    setOrder(sortDirection);
    setOrderBy(sortDirection !== tableSortTypes.NONE ? property : null);
    const sort =
      sortDirection !== tableSortTypes.NONE
        ? [set({}, property, sortDirection.replace(/['"]+/g, '').toUpperCase())]
        : null;
    refetch({
      ...variables,
      sort,
    });
  };

  const handleChangePage = (event, page) => {
    if (currentPage > page) {
      const isFirstPage = page === 0;
      refetch({
        ...variables,
        first: null,
        after: null,
        last: variables.first || variables.last,
        before: isFirstPage
          ? btoa(`arrayconnection:${variables.first || variables.last}`) // eslint-disable-line no-undef
          : data.viewer[connectionName]?.pageInfo?.startCursor,
      });
      setCurrentPage(page);
    } else if (currentPage < page) {
      const isLastPage =
        Math.floor(data.viewer[connectionName].totalCount / (variables.first || variables.last)) ===
        page;
      refetch({
        ...variables,
        first: variables.first || variables.last,
        after: isLastPage
          ? btoa(`arrayconnection:${(variables.first || variables.last) * page - 1}`) // eslint-disable-line no-undef
          : data.viewer[connectionName]?.pageInfo?.endCursor,
        last: null,
        before: null,
      });
      setCurrentPage(page);
    }
  };

  const handleTableRefresh = () => {
    refetch({
      ...variables,
    });
  };

  const handleChangeRowsPerPage = event => {
    if (variables.first) {
      refetch({
        ...variables,
        first: event.target.value,
      });
    } else {
      refetch({
        ...variables,
        last: event.target.value,
      });
    }
  };

  const isSelected = name => {
    return name && selected.map(item => item?.id).includes(name?.id);
  };

  const handleClick = (event, row) => {
    event.stopPropagation();
    const selectedIndex = selected.map(item => item.id).indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelected(tableData);
      return;
    }
    setSelected([]);
  };

  const stopPropagationLogic = (event, shouldStopPropagation) => {
    if (shouldStopPropagation) {
      event.stopPropagation();
    }
  };

  const onDeleteRow = (row, variables) => {
    if (useConfirmationModalForDelete) {
      setIsConfirmationModalOpen(true);
      setDeleteData({ row, variables });
    } else {
      handleDeleteRow(row, variables);
    }
  };
  const shouldShowTableToolbar =
    title ||
    subtitle ||
    customActionsBottom ||
    customActionsCenter ||
    customActionsTop ||
    showSearch;
  // TODO: disabled because after searching the connection is reset, query refetched and permissions get undefined rendering the table invisible
  // return connectionName ? data?.viewer[connectionName]?.permissions?.['__typename'] ? (
  return shouldRenderTable ? (
    <PaperMain nested={nested} id={`${connectionName}-table-container`}>
      {shouldShowTableToolbar && (
        <ConnectionTableToolbar
          refetch={refetch}
          variables={variables}
          title={title}
          alignItemsCenter={alignItemsCenter}
          subtitle={subtitle}
          columnsToFilterBy={columns.filter(column => column.filter)}
          availableFilters={data?.viewer[connectionName]?.availableFilters}
          numSelected={selected.length}
          filterByDate={filterByDate}
          showSearch={showSearch}
          searchPlaceholder={searchPlaceholder}
          dynamicDataFetching={dynamicDataFetching}
          setSearchValue={setSearchValue}
          searchStyle={searchStyle}
          customActionsTop={customActionsTop}
          customActionsCenter={customActionsCenter}
          customActionsBottom={customActionsBottom}
          tableData={tableData}
          selected={selected}
          data={data}
          permissions={data?.viewer[connectionName]?.permissions}
          minSearchValueLength={minSearchValueLength}
        />
      )}

      <TableContainer className={styles.tableContainerRoot}>
        <Table size="small" aria-label="sticky table">
          <Hidden only={headerHidden}>
            <ConnectionTableHead
              order={order}
              orderBy={orderBy}
              numSelected={selected.length}
              selectableRows={selectableRows}
              columns={columns}
              onSelectAllClick={handleSelectAllClick}
              rowCount={tableData?.length}
              onRequestSort={handleRequestSort}
              tableName={title}
              handleDeleteRow={useConfirmationModalForDelete ? onDeleteRow : handleDeleteRow}
              selectAllCheckbox={selectAllCheckbox}
              collapsableRows={collapsableRows}
              permissions={data?.viewer[connectionName]?.permissions}
            />
          </Hidden>
          <ConnectionTableBody
            onTableRowClick={onTableRowClick}
            isSelected={isSelected}
            tableData={tableData}
            loading={loading}
            refetch={refetch}
            collapsableRows={collapsableRows}
            handleClick={handleClick}
            searchValue={searchValue}
            stopPropagationLogic={stopPropagationLogic}
            selectableRows={selectableRows}
            columns={columns}
            variables={variables}
            handleDeleteRow={useConfirmationModalForDelete ? onDeleteRow : handleDeleteRow}
            data={data}
            permissions={data?.viewer[connectionName]?.permissions}
            isCheckbox
          />
          {showPagination && !loading && (
            <ConnectionTablePagination
              count={data?.viewer[connectionName]?.totalCount}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              hideTotalCount={hideTotalCount}
              currentPage={currentPage}
              variables={variables}
              handleTableRefresh={handleTableRefresh}
            />
          )}
        </Table>
      </TableContainer>

      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={() => {
          setIsConfirmationModalOpen(false);
          setDeleteData();
        }}
        disabled={isDeleting}
        text={deleteConfirmationText}
        confirmAction={() => handleDeleteRow(deletedData.row, deletedData.variables, refetch)}
      />
    </PaperMain>
  ) : null;
};

export default ConnectionTable;

ConnectionTable.propTypes = {
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  structureTable: PropTypes.func,
  customActionsTop: PropTypes.func,
  selectableRows: PropTypes.bool,
  customActionsCenter: PropTypes.func,
  initialQueryVariables: PropTypes.object,
  customActionsBottom: PropTypes.func,
  query: PropTypes.object,
  onTableRowClick: PropTypes.func,
  filterByDate: PropTypes.bool,
  hideTotalCount: PropTypes.bool,
  nested: PropTypes.bool,
};
