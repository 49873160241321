import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  sendButton: {
    margin: '10px 0',
  },
  select: {
    marginBottom: '20px',

    '& .MuiSelect-root': {
      minWidth: '10rem',
    },
  },
  typography: {
    margin: '10px 0',
  },
  input: {
    marginBottom: '5px',

    '& .MuiInputBase-root': {
      minWidth: '20rem',
    },
  },
});

export const EmailModalStyles = makeStyles(Styles);
