import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import SideBarSectionsLayoutStyles from './styles';
import Permission from '../Permission';

let sectionPositions = [];

const SideBarSectionsLayout = ({ children, sectionList, onClick = null }) => {
  const [selectedSection, setSelectedSection] = useState(sectionList[0]?.id || '');

  const classes = SideBarSectionsLayoutStyles();

  useEffect(() => {
    if (sectionList.length > 0) {
      const mainContainerElement = document.getElementById('scroll-container');

      const observer = new MutationObserver(getSectionPositions);
      observer.observe(mainContainerElement, {
        childList: true,
      });

      mainContainerElement.addEventListener('scroll', handleScroll);

      return function cleanup() {
        mainContainerElement.removeEventListener('scroll', handleScroll);
        observer.disconnect();
      };
    }
  }, []);

  const handleSectionClick = (event, itemId) => {
    event.preventDefault();
    const selectedElement = document.getElementById(itemId);
    selectedElement.scrollIntoView();
    setSelectedSection(itemId);
  };

  const getSectionPositions = () => {
    sectionPositions = sectionList.map(section => {
      return Math.round(
        (document.getElementById(section.id)?.getBoundingClientRect()?.top || 0) - 132,
      );
    });
  };

  const handleScroll = () => {
    const mainContainerElement = document.getElementById('scroll-container');
    const currentPosition = mainContainerElement.scrollTop + 100;
    const containerScrollHeight = mainContainerElement.scrollHeight;
    const offsetHeight = mainContainerElement.offsetHeight;
    const maxHeight = containerScrollHeight - offsetHeight;

    for (let i = 0; i < sectionList.length; i++) {
      if (currentPosition >= maxHeight) {
        setSelectedSection(sectionList[sectionList.length - 1].id);
        break;
      }
      if (currentPosition >= sectionPositions[i] && currentPosition <= sectionPositions[i + 1]) {
        setSelectedSection(sectionList[i].id);
        break;
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={2} className={classes.sectionListContainer}>
        {sectionList.length > 0 && (
          <List aria-label="sub module navigation" className={classes.sectionList}>
            {sectionList.map((section, index) => (
              <Permission
                key={index}
                access={sectionList.access === undefined ? 7 : sectionList.access && 7}
              >
                <ListItem
                  key={section.id}
                  button
                  selected={section.id === selectedSection}
                  onClick={event =>
                    onClick ? onClick(section) : handleSectionClick(event, section.id)
                  }
                  className={classes.sectionListItem}
                  classes={{ selected: classes.selectedSectionListItem }}
                >
                  <ListItemText primary={section.name} />
                </ListItem>
              </Permission>
            ))}
          </List>
        )}
      </Grid>
      <Grid item container xs={10} className={classes.contentContainer} id="scroll-container">
        {children}
      </Grid>
    </Grid>
  );
};

SideBarSectionsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sectionList: PropTypes.array.isRequired,
};

export default SideBarSectionsLayout;
