import React, { useState, useCallback  } from 'react';
import { FormHelperText } from '@material-ui/core';

const PermissionField = ({ error, helperText, disabledByPermission, onClick, Component, createHelperText, ...rest}) => {
	const [hasError, setHasError] = useState(false);
	const [permissionHelperText, setPermissionHelperText] = useState();

	const handleOnClick = useCallback(() => {
		setHasError(true);
		setPermissionHelperText("No access for this field");
	}, []);

	return Component
		? (
			<>
				<Component 
					{...rest} 
					error={hasError || error} 
					helperText={permissionHelperText || helperText} 
					onClick={(e) => disabledByPermission ? handleOnClick() : onClick && onClick(e)} 
				/>
				{createHelperText && (permissionHelperText || helperText) ? (
					<FormHelperText error={hasError || error}>{permissionHelperText || helperText}</FormHelperText>
				) : null}
			</>
		) : (
		<>
			<div 
				{...rest} 
				role="button"
				onClick={(e) => disabledByPermission ? handleOnClick() : undefined} 
			/>
			{createHelperText && (permissionHelperText || helperText) ? (
				<FormHelperText error={hasError || error}>{permissionHelperText || helperText}</FormHelperText>
			) : null}
		</>
	)
}

export default PermissionField;