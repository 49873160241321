import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  description: {
    margin: `${theme.spacing(2)}px 0`,
  },
  select: {
    width: '75%',
  },
});

export const ScheduleHeaderStyles = makeStyles(Styles);
