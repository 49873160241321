import { gql } from '@apollo/client';

const JOB_LIST = gql`query jobList($filter: [JobFilter]) {
  viewer {
    id
    jobConnection(fieldGroup: 62, filter: $filter) {
      permissions
      edges {
        node {
          id
          jobId
          label
          color {
            id
            colorId
            code
          }
        }
      }
    }
  }
}`;

export default JOB_LIST