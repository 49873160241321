import React, { useEffect, useState } from 'react';
import OvertimeView from './View';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { STATES } from '../../../../../../../apollo/queries'
import OVERTIMERULE from '../../../../../../../apollo/queries/overtimerule/overtimerule';
import OVERTIMERULES from '../../../../../../../apollo/queries/overtimerule/overtimerules';
import CREATE_OVERTIMERULES from '../../../../../../../apollo/mutations/overtime/createOvertimerules';
import UPDATE_OVERTIMERULES from '../../../../../../../apollo/mutations/overtime/updateOvertimerules';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../../../constants';

const Overtime = ({ overtimeId, show, handleClose,  }) => {
  const [selectedStates, setSelectedStates] = useState([]);

  const { data: stateData, loading: stateLoading, error: stateError } = useQuery(STATES);

  const [getOvertimerule, { data: overtimeruleData, loading: overtimeruleLoading, error: overtimeruleError }] = useLazyQuery(OVERTIMERULE, {
    fetchPolicy: 'cache-and-network'
  });
  const [createOvertimerules, { loading: createOTLoading }] = useMutation(CREATE_OVERTIMERULES, {
    refetchQueries: [
      { query: OVERTIMERULES, variables: {
          first: ROWS_PER_PAGE_OPTIONS[0]
        } }
    ],
  });
  const [updateOvertimerules, { loading: updateOTLoading }] = useMutation(UPDATE_OVERTIMERULES, {
    refetchQueries: [
      { query: OVERTIMERULES, variables: {
          first: ROWS_PER_PAGE_OPTIONS[0]
        } }
    ],
  });

  useEffect(() => {
    if (show && overtimeId && getOvertimerule) {
        getOvertimerule({
          variables: {
            id: overtimeId
          },
      })
    }
  },  [overtimeId, show, getOvertimerule])

  useEffect(() => {
    setSelectedStates(
      overtimeruleData?.viewer?.overtimerule?.stateConnection?.edges?.map((state) => state.node.id) || []
    )
  }, [overtimeruleData?.viewer?.overtimerule?.stateConnection?.edges])

  return show ? (
    <OvertimeView
      states={stateData?.viewer?.stateConnection?.edges}
      handleClose={handleClose}
      loading={stateLoading || overtimeruleLoading}
      error={stateError || overtimeruleError}
      overtimerule={overtimeruleData?.viewer?.overtimerule}
      createOvertimerules={createOvertimerules}
      updateOvertimerules={updateOvertimerules}
      createOTLoading={createOTLoading}
      updateOTLoading={updateOTLoading}
      selectedStates={selectedStates}
      setSelectedStates={setSelectedStates}
    />
  ) : null;
};

export default Overtime;
