import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { convert, validateSingleValue } from "../../../../../../utils";
import { ContentInfo } from "../../../../../blocks";

const CategoryCard = ({
  handoffLabel,
  categoryLabel,
  locationHandoffCategory,
  locationHandoffCategoryPermissions,
}) => {
  const [isActive] = useState(
    !!locationHandoffCategory && locationHandoffCategory.node?.active
  );

  const categoryInfoRenderData = [
    [
      {
        id: `e4e75323-${handoffLabel}-${Math.random()}`,
        label: `Allow ${handoffLabel} for ${categoryLabel} orders`,
        value: "On",
        permission: 7,
      },
    ],
    [
      {
        id: "16eb1644-92ec-491e-b8ff-a6a819a8496b",
        label: "Minimum order amount $",
        value: validateSingleValue(
          locationHandoffCategory?.node?.minOrderAmount,
          "$",
          convert.centsToDollars
        ),
        permission: locationHandoffCategoryPermissions?.minOrderAmount
      },
      {
        id: `61edf55d-${handoffLabel}-${Math.random()}`,
        label: "Maximum order amount $",
        value: validateSingleValue(
          locationHandoffCategory?.node?.maxOrderAmount,
          "$",
          convert.centsToDollars
        ),
        permission: locationHandoffCategoryPermissions?.maxOrderQuantity
      },
      {
        id: `a6690fc1-${handoffLabel}-${Math.random()}`,
        label: "Minimum number of items",
        value: validateSingleValue(
          locationHandoffCategory?.node?.minOrderQuantity
        ),
        permission: locationHandoffCategoryPermissions?.minOrderQuantity
      },
    ],
    [
      {
        id: `86ec0b27-${handoffLabel}-${Math.random()}`,
        label: "Maximum number of items",
        value: validateSingleValue(
          locationHandoffCategory?.node?.maxOrderQuantity
        ),
        permission: locationHandoffCategoryPermissions?.maxOrderQuantity
      },
      {
        id: `549edc5a-${handoffLabel}-${Math.random()}`,
        label: "Service fee $",
        value: validateSingleValue(
          locationHandoffCategory?.node?.additionalCharge,
          "",
          convert.centsToDollars
        ),
        permission: locationHandoffCategoryPermissions?.additionalCharge
      },
      {
        id: `fc9e27f2-71de-40ac-86c7-d56b3bdb224e`,
        label: "Tax service fee",
        value: validateSingleValue(
          locationHandoffCategory?.node?.additionalChargeTaxable.toString()
        ),
        permission: locationHandoffCategoryPermissions?.additionalChargeTaxable
      },
    ],
    [
      {
        id: `88a596f9-${handoffLabel}-${Math.random()}`,
        label: "Discount amount $",
        value: validateSingleValue(
          locationHandoffCategory?.node?.discountAmount,
          "$",
          convert.centsToDollars
        ),
        permission: locationHandoffCategoryPermissions?.discountAmount
      },
      {
        id: `45d67bac-${handoffLabel}-${Math.random()}`,
        label: "Discount fraction %",
        value: validateSingleValue(
          locationHandoffCategory?.node?.discountFraction * 100,
          "%"
        ),
        permission: locationHandoffCategoryPermissions?.discountFraction
      },
      {
        id: `28899ce6-${handoffLabel}-${Math.random()}`,
        label: "Lead time",
        value: validateSingleValue(
          locationHandoffCategory?.node?.leadTime ? Math.round(locationHandoffCategory?.node?.leadTime / 60) : undefined,
          "min"
        ),
        permission: locationHandoffCategoryPermissions?.leadTime
      },
    ],
  ];

  const categoryCards =
    !!locationHandoffCategory && isActive ? (
      <Grid container spacing={4}>
        <Grid item container xs={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            {categoryLabel}
          </Typography>
          <ContentInfo
            contentInfo={categoryInfoRenderData}
            id={handoffLabel}
            numOfColumns={3}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={4}>
        <Grid item container xs={12} direction="column">
          <Typography variant="h6" gutterBottom component="h2">
            {categoryLabel}
          </Typography>
          <Typography variant="subtitle2" component="p" gutterBottom>
            {`Allow ${handoffLabel} for ${categoryLabel} orders`}
          </Typography>
          <Typography variant="body2" component="p">
            Off
          </Typography>
        </Grid>
      </Grid>
    );

  return categoryCards;
};

export default CategoryCard;
