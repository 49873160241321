import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  cardStyles: {
    background: '#F4F4F9',
    borderRadius: '10px',
  },
  imgStyle: {
    width: '100%',
  },
  container: {
    boxShadow: theme.shadows[2],
  },
  pStyles1: {
    marginBottom: '0',
  },
  pStyles2: {
    marginTop: '0',
  },
  title: {
    margin: '15px 0',
  },
});

const LoyaltyRewardshistoryStyles = makeStyles(Styles);

export default LoyaltyRewardshistoryStyles;
