import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select as SelectMUI,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

const Select = ({
  name,
  label,
  id,
  control,
  defaultValue = '',
  children,
  shrink,
  rules,
  fullWidth,
  error,
  helperText,
  ...props
}) => {
  const labelId = `${name}-label`;
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <Controller
      render={({ onChange }) => (
        <FormControl {...props} variant="outlined" fullWidth={fullWidth}>
          <InputLabel htmlFor={labelId} shrink={shrink} error={error}>
            {label}
          </InputLabel>
          <SelectMUI
            notched
            error={error}
            value={selectedValue}
            label={label}
            displayEmpty
            onChange={(event, selected) => {
              onChange(selected.props.value);
              setSelectedValue(selected.props.value);
            }}
          >
            {!defaultValue && (
              <MenuItem value="" disabled>
                Select
              </MenuItem>
            )}
            {children}
          </SelectMUI>
          {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
      )}
      rules={rules && rules}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default Select;
