import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import EditLoyaltyEventModalView from './View';

const EditLoyaltyEventModal = ({ handleClose, handleOnUpdateLoyaltyevent, loyaltyUpdateData }) => {
  const { control, handleSubmit, errors, reset } = useForm();
  const [selectedItem, setSelectedItem] = useState({});

  const submit = useCallback(
    data => {
      const formattedData = { brandsPayload: [] };

      // Get value of each brands awards
      const numOfBrands = Array.from(new Set(Object.keys(data).map(x => x.split('-')[1])));
      numOfBrands.map((_, index) => {
        const currIndexKeys = Object.keys(data).filter(
          key => parseInt(key.split('-')[1]) === index,
        );
        let tempObj = {};

        currIndexKeys.map(key => {
          // If data[key] is an object without a coupon ID, set it to null, else get the value of data[key]
          tempObj[key.split('-')[0]] =
            typeof data[key] === 'object' ? data[key].couponId || null : data[key];
        });

        tempObj['brandId'] = parseInt(currIndexKeys[0].split('-')[1]) + 1;

        // Overwrite loyaltystoreitem if a new one was selected
        if (selectedItem[index] !== undefined) {
          tempObj.loyaltystoreitem = selectedItem[index]?.couponId || null;
        }
        formattedData.brandsPayload.push(tempObj);
      });

      // Rename key loyaltystoreitem to loyaltystoreitemCouponId in each brand payload
      for (const payload of formattedData.brandsPayload) {
        delete Object.assign(payload, {
          ['loyaltystoreitemCouponId']: payload['loyaltystoreitem'],
        })['loyaltystoreitem'];
      }
      handleOnUpdateLoyaltyevent(formattedData);
    },
    [selectedItem],
  );

  return (
    <div style={{ width: '100%' }}>
      <EditLoyaltyEventModalView
        control={control}
        submit={submit}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        loyaltyUpdateData={loyaltyUpdateData}
        setSelectedItem={setSelectedItem}
      />
    </div>
  );
};

export default EditLoyaltyEventModal;
