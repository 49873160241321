import React, { useState } from "react";
import { IconButton, Paper, Card, Chip } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Elements, Modal } from "../../../../../../../blocks";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddItemsModal from "../AddItemsModal";

function ItemsView({
  control,
  showOptions,
  setShowOptions,
  openModal,
  setOpenModal,
  handleClose,
  handleOpen,
  setExcludedItems,
  handleDelete,
  excludedItems,
}) {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Controller
          control={control}
          name="discountRestrictions.items.enabled"
          defaultValue={showOptions}
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "2rem" }}>
                <Elements.Switch
                  ref={ref}
                  checked={value}
                  handleSwitchChange={(isChecked) => {
                    setShowOptions(isChecked);
                    onChange(isChecked);
                  }}
                />
              </div>
              <div>
                <h3>Items</h3>
              </div>
            </div>
          )}
        />
      </div>
      {showOptions && (
        <div style={{ display: "flex", margin: "0 10px" }}>
          <Paper style={{ width: " 100%" }}>
            <Card
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <HighlightOffIcon style={{ color: "red", margin: "0 5px" }} />
                <h3>Excluded Items</h3>
              </div>
              <IconButton onClick={handleOpen}>
                <AddIcon />
              </IconButton>
            </Card>
            <div style={{ padding: "10px 0" }}>
              {excludedItems.map((item) => {
                if (item.isItem) {
                  return (
                    <Chip
                      key={item.id}
                      style={{ margin: "4px" }}
                      label={item.label}
                      onDelete={(e) => handleDelete(item.id)}
                    />
                  );
                } else {
                  return "";
                }
              })}
              {!excludedItems.length && (
                <h3 style={{ marginLeft: "5px" }}>No excluded items.</h3>
              )}
            </div>
          </Paper>
        </div>
      )}

      <Modal open={openModal} handleClose={handleClose} title="Add Items">
        <AddItemsModal
          setExcludedItems={setExcludedItems}
          handleClose={handleClose}
          excludedItems={excludedItems}
        />
      </Modal>
    </div>
  );
}

export default ItemsView;
