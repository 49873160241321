import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";

import { ContentInfo } from "../../../blocks";

const DemoPageView = () => {
  return (
    <>
      <Typography variant="h3" component="h1" align="center">
        Demo Page View
      </Typography>
    </>
  );
};

export default DemoPageView;
