import { gql } from '@apollo/client';

const FETCH_JOBS = gql`
  query FetchJobs($first: Int, $filter: [JobFilter]) {
    viewer {
      id
      jobConnection(first: $first, filter: $filter) {
        edges {
          node {
            id
            jobId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_JOBS;
