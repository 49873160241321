import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const DataTableToolbar = ({
  data,
  title,
  selected,
  customActionsTop,
  customActionsCenter,
  customActionsBottom,
  tableData,
}) => {
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        {customActionsTop && customActionsTop(data, { tableData }, selected)}
      </Grid>
      <Grid item xs={12}>
        {customActionsCenter && customActionsCenter(data, { tableData }, selected)}
      </Grid>
      <Grid item xs={12}>
        {customActionsBottom && customActionsBottom(data, { tableData }, selected)}
      </Grid>
    </Grid>
  );
};

export default DataTableToolbar;

DataTableToolbar.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  variables: PropTypes.object,
  title: PropTypes.string,
  selected: PropTypes.array,
  customActionsTop: PropTypes.func,
  customActionsCenter: PropTypes.func,
  customActionsBottom: PropTypes.func,
};
