import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import NumberInput from '../../../../../blocks/NumberInput';

const Recipe = ({
  item: inventoryitem,
  unregister,
  register,
  id,
  errors,
  setValue,
  control,
  autoFocus,
}) => {
  useEffect(() => {
    register(`${id}.inventoryitemId`);
    setValue(`${id}.inventoryitemId`, inventoryitem?.inventoryitemId);

    return () => {
      unregister(`${id}.inventoryitemId`);
    };
  }, [inventoryitem, id, register, setValue, unregister]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={4}>
        <Typography variant="body1">{inventoryitem?.label} <br />

          {
            inventoryitem?.itemId ? <Typography variant="body1">Item: {inventoryitem?.itemMenuLink.itemLabel} -  ID: <Link target='_blank' to={`/spark/system-settings/menu-tree/menus/${inventoryitem?.itemMenuLink.menuId}/categories/${inventoryitem?.itemMenuLink.categoryId}/subcategories/${inventoryitem?.itemMenuLink.subcategoryId}/items/${inventoryitem?.itemMenuLink.itemId}`}>
              {inventoryitem?.itemMenuLink.itemId}
            </Link></Typography> : 'Item: All Items'
          }
        </Typography>

      </Grid>
      <Grid item xs={6} sm={4}>
        <NumberInput
          defaultValue={inventoryitem?.quantity}
          autoFocus={autoFocus}
          id={`${id}.quantity`}
          required
          label={'Quantity'}
          error={!!errors[id]?.quantity}
          helperText={errors[id]?.quantity?.type === 'required' ? 'Value is required' : ''}
          control={control}
        />
      </Grid>
      <div style={{ display: 'none' }}>
        <NumberInput
          defaultValue={inventoryitem?.itemId || null}
          id={`${id}.itemId`}
          control={control}
        />
      </div>
      <Grid item xs={6} sm={4}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel error={!!errors[id]?.measureId}>UOM</InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            id={`${id}.measureId`}
            name={`${id}.measureId`}
            defaultValue={inventoryitem?.measureId}
            label="UOM"
            placeholder="UOM"
            error={!!errors[id]?.measureId}
            as={
              <Select label="UOM" multiple={false} error={!!errors[id]?.measureId}>
                {inventoryitem?.measureOptions?.map(measure => (
                  <MenuItem key={measure.value} value={measure.value}>
                    {measure.label}
                  </MenuItem>
                )) || []}
              </Select>
            }
            control={control}
          />
          {errors[id]?.measureId?.type === 'required' ? (
            <FormHelperText error>UOM is required</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Recipe;
