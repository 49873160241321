import React, { useState, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import CreateUpdateIngredientModal from '../../Modals/CreateUpdateIngredientModal';
import { REMOVE_INGREDIENT } from '../../../../../../apollo/mutations/';
import { FETCH_MEASURES, INGREDIENTS_LIST } from '../../../../../../apollo/queries';
import {
  Modal,
  ProductionForecasterSettings,
  WasteLogConfirmationModal,
} from '../../../../../blocks';
import CustomActionTop from './CustomActionTop';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

function IngredientsTable() {
  const { setSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({});
  const [isProductionForecasterModalOpen, setIsProductionForecasterModalOpen] = useState(false);
  const [isWasteLogModalOpen, setIsWasteLogModalOpen] = useState(false);
  const [locationIngredientId, setLocationIngredientId] = useState(null);
  const [rawIngredientId, setRawIngredientId] = useState(null);
  const [removeIngredient, { loading: isDeletingIngredient }] = useMutation(REMOVE_INGREDIENT);

  const { data, loading } = useQuery(FETCH_MEASURES);

  const onAddToProductionForecasterClick = useCallback(() => {
    setIsProductionForecasterModalOpen(true);
  }, []);

  const handleProductionForecasterModalClose = useCallback(() => {
    setIsProductionForecasterModalOpen(false);
  }, []);

  const onAddToWasteLogClick = useCallback(() => {
    setIsWasteLogModalOpen(true);
  }, []);

  const handleWasteLogModalClose = useCallback(() => {
    setIsWasteLogModalOpen(false);
  }, []);

  if (loading) {
    return 'Loading....';
  }

  const measures = data?.viewer?.measureConnection?.edges.map(edge => ({
    label: edge.node.label,
  }));

  const openDialog = (locationId, ingredientId, variables) => {
    setQuery(prevValues => ({ ...prevValues, variables }));
    setLocationIngredientId(locationId);
    setRawIngredientId(ingredientId);
    setOpen(true);
  };

  const handleClose = () => {
    resetStateAfterIngredientUpdate();
    setOpen(false);
  };

  const resetStateAfterIngredientUpdate = () => {
    setLocationIngredientId(null);
    setRawIngredientId(null);
  };

  const handleOnDeleteConfirmationClick = (ingredient, variables) => {
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Removing ingredient...',
    });

    removeIngredient({
      variables: { input: { ingredientId: ingredient.rawId } },
      refetchQueries: [{ query: INGREDIENTS_LIST, variables: variables }],
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Ingredient removed',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  return (
    <div id="ingredients">
      <ConnectionTable
        query={INGREDIENTS_LIST}
        initialQueryVariables={{
          sort: { inventoryitem: { label: 'ASC' } },
          first: 10,
          fieldGroupOnly: 89,
        }}
        title="Ingredients"
        customActionsTop={(data, query) => {
          return (
            <Button
              onClick={() => {
                setQuery(prevValues => ({ ...prevValues, query }));
                setOpen(true);
              }}
              disabled={isDeletingIngredient}
            >
              New Ingredient
            </Button>
          );
        }}
        customActionsCenter={(data, query) => {
          return (
            <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              <CustomActionTop refetch={query} measures={measures} />
            </div>
          );
        }}
        structureTable={data =>
          data?.viewer?.ingredientConnection?.edges.map(ing => {
            return {
              id: ing.node.ingredientId,
              label: ing.node.inventoryitem?.label,
              cost: `${
                ing.node.inventoryitem?.cost
                  ? '$' + ing.node.inventoryitem.cost
                  : 'Invoice calculated'
              }`,
              description: ing.node.inventoryitem?.description,
              'measure.label': ing.node.inventoryitem?.measure?.label,
              created: moment
                .tz(ing.node.inventoryitem?.created, 'YYYY/MM/DD HH:mm', 'UTC')
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY h:mm A'),
              rawId: ing.node.id,
            };
          })
        }
        columns={[
          { title: 'Id', field: 'id' },
          { title: 'Name', field: 'label' },
          { title: 'Description', field: 'description' },
          { title: 'Unit Type', field: 'measure.label' },
          { title: 'Average Unit Price', field: 'cost' },
          { title: 'Updated', field: 'created' },
        ]}
        handleDeleteRow={handleOnDeleteConfirmationClick}
        useConfirmationModalForDelete
        isDeleting={isDeletingIngredient}
        deleteConfirmationText="Are you sure you want to delete this ingredient?"
        onTableRowClick={(r, d, v) => openDialog(r.id, r.rawId, v)}
      />
      <Modal
        handleClose={handleClose}
        open={open}
        title={locationIngredientId ? 'Update Ingredient' : 'Add Ingredient'}
        headerContent={() =>
          locationIngredientId && (
            <>
              <Button variant="outlined" onClick={() => onAddToWasteLogClick()}>
                {query?.inventoryitem?.partOfWastelog ? 'Edit Waste Log Info' : 'Add To Waste Log'}
              </Button>
              <Button variant="outlined" onClick={() => onAddToProductionForecasterClick()}>
                {query?.inventoryitem?.inventoryitemPrep?.id
                  ? 'Edit Production Forecaster Info'
                  : 'Add To Production Forecaster'}
              </Button>
            </>
          )
        }
      >
        <CreateUpdateIngredientModal
          handleClose={handleClose}
          query={query}
          setQuery={setQuery}
          locationIngredientId={locationIngredientId}
          rawIngredientId={rawIngredientId}
        />
      </Modal>
      <ProductionForecasterSettings
        isProductionForecasterModalOpen={isProductionForecasterModalOpen}
        updateInventoryitem={setQuery}
        handleProductionForecasterModalClose={handleProductionForecasterModalClose}
        inventoryitem={query?.inventoryitem}
      />
      <WasteLogConfirmationModal
        isWasteLogModalOpen={isWasteLogModalOpen}
        handleWasteLogModalClose={handleWasteLogModalClose}
        updateInventoryitem={setQuery}
        inventoryitem={query?.inventoryitem}
      />
    </div>
  );
}

IngredientsTable.propTypes = {};

export default IngredientsTable;
