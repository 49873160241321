import { gql } from '@apollo/client';

const UPDATE_SUBCATEGORY_ORDER_KEYS = gql`
  mutation UpdateSubcategoryOrderKeys($input: UpdateSubcategoryOrderKeyMutationInput!) {
    updateSubcategoryOrderKeys(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_SUBCATEGORY_ORDER_KEYS;
