import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ChoicesSideBar from './ChoicesSideBar';
import MultipleEditTab from './MultipleEditTab';
import CREATE_MULTIPLE from "../../../../../../apollo/mutations/menus/createMultiple";
import PaperMain from "../../../../../blocks/PaperMain";

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const MultipleEdit = () => {
  const { multipleId } = useParams();

  const [createMultiple] = useMutation(CREATE_MULTIPLE);
  const [numberOfMultipleChoices, setNumberOfMultipleChoices] = useState();
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [multipleLabel, setMultipleLabel] = useState();
  return (
    <>
      <PaperMain elevation={3}>
          <div className={classes.headerContainer}>
            <Typography variant="h3" component="h2">
              {multipleId === "new-multiple" ? "New Multiple" : multipleLabel}
            </Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ChoicesSideBar
                numberOfMultipleChoices={numberOfMultipleChoices}
                value={value}
              />
            </Grid>
            <Grid item xs={9}>
              <MultipleEditTab
                setValue={setValue}
                value={value}
                setMultipleLabel={setMultipleLabel}
                setNumberOfMultipleChoices={setNumberOfMultipleChoices}
                multipleId={multipleId}
              />
            </Grid>
          </Grid>
        </PaperMain>
    </>
  );
};

export default MultipleEdit;
