import { gql } from '@apollo/client';

const USER_COUPONS = gql`
  query USER_COUPONS($availableCouponsFilter: [PersonCouponFilter]) {
    viewer {
      id
      availableCoupons: personCouponConnection(
        sort: { created: ASC }
        filter: $availableCouponsFilter
      ) {
        edges {
          node {
            id
            coupon {
              id
              label
              description
              imageURL
              started
              finished
              expired
              loyaltystoreitemCouponConnection {
                edges {
                  node {
                    loyaltystoreitem {
                      typeId
                    }
                  }
                }
              }
            }
            brand {
              id
              label
            }
            created
            validUntil
            usedOn
            lineId
          }
        }
      }
    }
  }
`;

export default USER_COUPONS;
