import { gql } from '@apollo/client';

const UPDATE_INGREDIENT = gql`
  mutation UpdateIngredientMutationND($input: UpdateIngredientMutationNDInput!) {
    updateIngredientND(input: $input) {
      viewer {
        id
      }
      ingredientEdge {
        node {
          id
          ingredientId
          inventoryitem {
            label
            description
            cost
          }
          created
        }
      }
    }
  }
`;

export default UPDATE_INGREDIENT;
