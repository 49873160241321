import { useLazyQuery } from '@apollo/client';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../blocks';
import FETCH_TIERS_CONNECTIONS from './setPricingCreateItem.constants';

const SetPricingCreateItem = ({ setPricingData, customError, setCustomError, pricingData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [tiersConnection, setTiersConnection] = useState();

  const { handleSubmit, register, getValues, errors, reset, setError } = useForm({
    defaultValues: {},
  });

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
    loadTiers();
  };

  useEffect(() => {
    if (pricingData) {
      const tiersFields = tiersConnection.map((tier, index) => ({
        [`${tier.nameLabel}-mcpi`]: Object.entries(pricingData)?.find(
          ([key]) => key === `${tier.nameLabel}-mcpi`,
        )[1],
        [`${tier.nameLabel}-oloMaxPrice`]: Object.entries(pricingData)?.find(
          ([key]) => key === `${tier.nameLabel}-oloMaxPrice`,
        )[1],
        [`${tier.nameLabel}-posMaxPrice`]: Object.entries(pricingData)?.find(
          ([key]) => key === `${tier.nameLabel}-posMaxPrice`,
        )[1],
        [`${tier.nameLabel}-3pvMaxPrice`]: Object.entries(pricingData)?.find(
          ([key]) => key === `${tier.nameLabel}-3pvMaxPrice`,
        )[1],
      }));

      const tiersProps = tiersFields?.reduce((acc, curr) => {
        return Object.assign(acc, curr);
      }, {});

      reset({
        defaultMcpi: pricingData?.defaultMcpi,
        defaultMaxPrice: pricingData?.defaultMaxPrice,
        ...tiersProps,
      });
    }
  }, [pricingData]);

  const [loadTiers, { loading: loadingTiers, refetch: refetchTiers }] = useLazyQuery(
    FETCH_TIERS_CONNECTIONS,
    {
      onCompleted: data => {
        if (data?.viewer?.tierConnection && data?.viewer?.platformConnection) {
          setTiersConnection(
            data?.viewer?.tierConnection?.edges.map(edge => {
              return {
                tierId: edge?.node?.id,
                tierPrice: edge?.node?.price,
                tierLabel: edge?.node.label,
                nameLabel: edge?.node?.label.replace(/\s+/g, '-').toLowerCase(),
                tierPlatforms: data?.viewer?.platformConnection,
              };
            }),
          );
        }
      },
      onError: err => {
        console.log(err, 'error...');
      },
      fetchPolicy: 'network-only',
    },
  );

  const onSubmit = useCallback(
    data => {
      try {
        tiersConnection.forEach(tier => {
          if (!getValues(`${tier.nameLabel}-mcpi`)) {
            if (
              getValues(`${tier.nameLabel}-oloMaxPrice`) !== '' ||
              getValues(`${tier.nameLabel}-posMaxPrice`) !== '' ||
              getValues(`${tier.nameLabel}-3pvMaxPrice`) !== ''
            ) {
              setError(`${tier.nameLabel}-mcpi`, { type: 'customError' });
              throw new Error(
                'MCPI can’t be empty if you’re trying to enter Max Price per Platform',
              );
            }
          }
        });

        setPricingData(data);
        if (data?.defaultMcpi) {
          setCustomError(false);
        }
        handleClose();
      } catch (errorMessage) {
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: `${errorMessage}`,
        });
      }
    },
    [setPricingData, handleClose],
  );

  const forwardSave = data => {
    onSubmit(data);
  };

  const handleSubmitWithoutPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(forwardSave)(e);
  };

  const formRender = useCallback(() => {
    return tiersConnection ? (
      <form onSubmit={handleSubmitWithoutPropagation}>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4" component="h4">
              Default
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              gap: '10px',
              flexWrap: 'nowrap',
            }}
          >
            <Grid item xs={6}>
              <TextField
                name="defaultMcpi"
                label="Default MCPI"
                type="text"
                inputRef={register({
                  required: true,
                  pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                })}
                fullWidth
                defaultValue={''}
                error={errors?.defaultMcpi}
                helperText={
                  errors?.defaultMcpi && (errors?.defaultMcpi ? 'Default MCPI is required.' : null)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="defaultMaxPrice"
                label="Default Max Price"
                type="text"
                inputRef={register({
                  pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                  validate: {
                    min: value => {
                      const priceValue = getValues('defaultMcpi');
                      return priceValue && value
                        ? parseFloat(value) >= parseFloat(priceValue)
                        : true;
                    },
                  },
                })}
                fullWidth
                defaultValue={''}
                error={errors?.defaultMaxPrice}
                helperText={
                  errors?.defaultMaxPrice &&
                  (errors?.defaultMaxPrice?.type === 'min'
                    ? "Max Price can't be less than Default MCPI"
                    : 'Tier Max Price must be a positive number.')
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              justifyContent: 'space-between',
            }}
          >
            {tiersConnection?.map((tier, index) => (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <Typography gutterBottom variant="h4" component="h4">
                    {tier.tierLabel}
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{
                    gap: '10px',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-mcpi`}
                      label={`${tier.tierLabel} MCPI`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={''}
                      error={errors[`${tier.nameLabel}-mcpi`]}
                      helperText={
                        errors[`${tier.nameLabel}-mcpi`]?.type === 'customError'
                          ? 'MCPI can’t be empty if you’re trying to enter Max Price per Platform'
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-posMaxPrice`}
                      label={`${tier.tierLabel} POS Max Price`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-oloMaxPrice`}
                      label={`${tier.tierLabel} OLO Max Price`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`${tier.nameLabel}-3pvMaxPrice`}
                      label={`${tier.tierLabel} 3PV Max Price`}
                      type="text"
                      inputRef={register({
                        pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                      })}
                      fullWidth
                      defaultValue={''}
                    />
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>

          <Grid
            container
            style={{
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Grid item>
              <Button
                onClick={handleClose}
                style={{
                  marginRight: '14px',
                }}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={false}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    ) : (
      <Typography gutterBottom variant="h5" component="h5">
        Loading...
      </Typography>
    );
  }, [tiersConnection, errors]);

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        style={customError ? { border: '2px solid #D40E37' } : null}
      >
        Set Pricing
      </Button>
      {customError ? (
        <Typography gutterBottom variant="h6" component="h6" style={{ color: '#D40E37' }}>
          Default MCPI is required field!
        </Typography>
      ) : null}
      <Grid container>
        <Modal open={openModal} handleClose={handleClose} title="Set Item Pricing" maxWidth="lg">
          {formRender()}
        </Modal>
      </Grid>
    </>
  );
};

export default SetPricingCreateItem;
