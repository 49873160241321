import { gql } from '@apollo/client';

const SUBMIT_INVENTORY_COUNT = gql`
  mutation SUBMIT_INVENTORY_COUNT($input: submitInventorycountInput!) {
    submitInventorycount(input: $input) {
      inventorycount {
        inventorycountId
        dateCounted
      }
    }
  }
`;

export default SUBMIT_INVENTORY_COUNT;
