import { gql } from '@apollo/client';

const DISPUTES = gql`
  query Disputes(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [QuoteFilter]
    $sort: [QuoteSort]
    $specificFields: [String]
  ) {
    viewer {
      id
      quoteConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        sort: $sort
        search: $search
        specificFields:$specificFields
      ) {
        permissions
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            quoteId
            stage {
              label
            }
            origin {
              label
            }
            totalPrice
            dueAmount
            expected
            doordashId
            printJob {
              datePrinted
              inQueue
            }
            person {
              firstName
              lastName
            }
            category {
              label
            }
            handoff {
              label
            }
            location {
              label
              locationId
              storeNumber
              timezone {
                label
              }
              address {
                state {
                  label
                  abbreviation
                }
              }
            }
            paymentConnection {
              totalCount
              edges {
                node {
                  paymentId
                  paymentGatewayConnection(first: 1) {
                    edges {
                      node {
                        disputeReason
                        disputeStatus
                        disputeDueBy
                        paymentDetails {
                          customerIPAddress
                          billingAddress
                          submissionCount
                          isRefundable
                          uncategorizedText
                          customerCommunication
                          receipt
                          uncategorizedFile
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DISPUTES;
