import { gql } from '@apollo/client';

const OVERTIMERULE = gql`
  query overtimerule(
    $id: ID!
  ) {
    viewer {
      id
      overtimerule(
        id: $id,
        fieldGroupOnly: 12
      ) {
        id
        overtimeruleId
        name
        weeklyOtStart
        weeklyOtMultiplier
        dailyOtStart
        dailyOtMultiplier
        secondDailyOtStart
        secondDailyOtMultiplier
        seventhDayWhMultiplier
        seventhDayOtStart
        seventhDayOtMultiplier
        resident
        stateConnection{
          edges{
            node{
              id
              abbreviation
            }
          }
        }
      }
    }
  }
`;

export default OVERTIMERULE;
