import { gql } from '@apollo/client';

const FETCH_CATEGORY_AND_MENU = gql`
  query fetchCategoryAndMenu($categoryIds: [Int]!) {
    viewer {
      id
      menuCategoryConnection(filter: { categoryId: { in: $categoryIds } }) {
        edges {
          node {
            menu {
              id
              menuId
              label
              brand {
                id
                brandId
              }
            }
            category {
              id
              categoryId
              label
              categorytypeId
            }
          }
        }
      }
    }
  }
`;

export default FETCH_CATEGORY_AND_MENU;
