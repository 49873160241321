import moment from 'moment-timezone';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import { DISCOUNTS_LIST } from '../../../../../apollo/queries';
import { REMOVE_COUPON } from '../../../../../apollo/mutations';
import ArchiveSwitchButton from './TableRenderComponents/ArchiveSwitchButton';
import { convert } from '../../../../../utils';
import { Link, useHistory } from 'react-router-dom';
import { paths } from '../../../../../constants';
import { useMutation } from '@apollo/client';
import Archive from './TableRenderComponents/Filters/Archive';
import Platforms from './TableRenderComponents/Filters/Platforms';
import DiscountType from './TableRenderComponents/Filters/DiscountType';
import Status from './TableRenderComponents/Filters/Status';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { SerializedCouponDistributionModal } from '../../../../blocks';
import { SERIALIZED_COUPON_TYPE_ID } from '../../../../../constants';
import { MYSTERY_COUPON_TYPE_ID } from '../../../Loyalty/LoyaltyRewards/components/MysteryRewards/components/MysteryRewardModal/constants';

function DiscountsTable() {
  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const [filter, setFilter] = useState({});
  const [serializedCouponModal, setSerializedCouponModal] = useState(false);

  const [removeCoupon, { loading: removeCouponLoading }] = useMutation(REMOVE_COUPON);

  const genereateProductField = edge => {
    if (edge.node.couponItemConnection.edges.length > 1) {
      return edge.node.couponItemConnection.edges.map((temp_edge, i) => {
        if (i === edge.node.couponItemConnection.edges.length - 1) {
          return ' ' + temp_edge.node.item.label;
        }
        return ' ' + temp_edge.node.item.label + ', ';
      });
    }
    if (edge.node.couponItemConnection.edges.length === 1) {
      return edge.node.couponItemConnection.edges[0].node.item.label;
    }
    return '';
  };

  const decideOnCouponStatus = status => {
    if (status === 'SCHEDULE AHEAD') {
      return <span style={{ fontWeight: 'bold', color: 'orange' }}>{status}</span>;
    }
    if (status === 'ENDED') {
      return <span style={{ fontWeight: 'bold' }}>{status}</span>;
    }
    if (status === 'PUBLISHED') {
      return <span style={{ fontWeight: 'bold', color: 'green' }}>{status}</span>;
    }
    if (status === 'ARCHIVED') {
      return <span style={{ fontWeight: 'bold', color: 'red' }}>{status}</span>;
    }
  };

  const generateStartEndDate = edge => {
    const startDate = `${moment
      .tz(edge.node.started, 'YYYY/MM/DD HH:mm', 'UTC')
      .tz(moment.tz.guess())
      .format('MM/DD/YYYY')}`;
    const endDate = `${moment
      .tz(edge.node.finished, 'YYYY/MM/DD HH:mm', 'UTC')
      .tz(moment.tz.guess())
      .format('MM/DD/YYYY')}`;
    if (endDate.startsWith('1000') || endDate.startsWith('9999') || edge.node.finished === null) {
      return `${startDate} - /`;
    }
    return `${startDate} - ${endDate}`;
  };

  const genereateValueOff = edge => {
    const couponAction = edge.node.couponActionConnection.edges[0];
    let valueOff;
    if (couponAction) {
      if (couponAction.node.couponactionId === 1) {
        valueOff = `$ ${convert.centsToDollars(couponAction.node.valueOff)}`;
      }
      if (couponAction.node.couponactionId === 2) {
        valueOff = `% ${couponAction.node.valueOff}`;
      }
      if (couponAction.node.couponactionId === 3) {
        valueOff = `$ ${convert.centsToDollars(couponAction.node.valueOff)}`;
      }
      if (couponAction.node.couponactionId === 4) {
        valueOff = `% ${couponAction.node.valueOff}`;
      }
      if (couponAction.node.couponactionId === 5) {
        valueOff = `$ ${convert.centsToDollars(couponAction.node.valueOff)}`;
      }
      if (couponAction.node.couponactionId === 6) {
        valueOff = `% ${couponAction.node.valueOff}`;
      }
      if (couponAction.node.couponactionId === 7) {
        valueOff = `$ ${convert.centsToDollars(couponAction.node.valueOff)}`;
      }
      if (couponAction.node.couponactionId === 8) {
        valueOff = `% ${couponAction.node.valueOff}`;
      }
      if (couponAction.node.couponactionId === 9) {
        valueOff = 'FREE ITEM(S)';
      }
      return valueOff;
    }
  };

  const onDeleteConfirmation = async (row, vars, refetch) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Deleting discount...',
    });

    await removeCoupon({
      variables: { input: { couponId: row.rawId } },
    })
      .then(async () => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Discount deleted',
        });
        await refetch();
      })
      .catch(error =>
        setSnackbar({
          open: true,
          type: 'error',
          text: error.message,
        }),
      );
  };

  return (
    <div id="discounts">
      <ConnectionTable
        fetchPolicy="network-only"
        query={DISCOUNTS_LIST}
        initialQueryVariables={{
          sort: { created: 'DESC' },
          first: 15,
          filter: { coupontypeId: { neq: MYSTERY_COUPON_TYPE_ID } },
        }}
        title="Discounts"
        customActionsTop={(data, query) => {
          return (
            <Link to={paths.SETTINGS_DISCOUNTS_NEW}>
              <Button disabled={removeCouponLoading}>New Discount</Button>
            </Link>
          );
        }}
        customActionsCenter={(data, query) => {
          return (
            <div style={{ display: 'flex', gap: '1rem', marginLeft: 'auto' }}>
              <Archive setFilter={setFilter} refetch={query} />
              <Platforms setFilter={setFilter} refetch={query} />
              <DiscountType setFilter={setFilter} refetch={query} />
              <Status setFilter={setFilter} refetch={query} />
            </div>
          );
        }}
        onTableRowClick={(row, data, variables) => {
          history.push(`/spark/system-settings/discounts/${row.id}/details`);
        }}
        structureTable={data =>
          data?.viewer?.couponConnection?.edges.map(edge => {
            return {
              id: edge.node.couponId,
              label: edge.node.label,
              internalLabel: edge.node.internalLabel,
              archived: edge.node.archived,
              discountAmount: genereateValueOff(edge),
              appliedLocations: edge?.node?.couponPlatformConnection?.edges.map(edge =>
                edge.node.platform[0] ? edge.node.platform[0].label + ' ' : ' ',
              ),
              coupontype: edge.node.couponAction ? edge.node.couponAction.label : 'WILL BREAK',
              product: genereateProductField(edge),
              startedFinished: generateStartEndDate(edge),
              status: decideOnCouponStatus(edge.node.status),
              used: edge.node.used,
              rawId: edge.node.id,
              serialCoupon: edge.node,
            };
          })
        }
        handleDeleteRow={onDeleteConfirmation}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this coupon?"
        columns={[
          {
            title: 'Archived',
            field: 'archived',
            render: (data, vars, row) => <ArchiveSwitchButton data={data} couponId={row.rawId} />,
            disableSort: true,
          },
          { title: 'Id', field: 'id' },
          { title: 'Public name', field: 'label' },
          { title: 'Internal name', field: 'internalLabel' },
          {
            title: 'Applied Location',
            field: 'appliedLocations',
            disableSort: true,
          },
          { title: 'Discount Type', field: 'coupontype', disableSort: true },
          { title: 'Value', field: 'discountAmount', disableSort: true },
          // { title: "Product", field: "product" },
          {
            title: 'Start - End Date',
            field: 'startedFinished',
            customSortField: 'started',
          },
          { title: 'Status', field: 'status', disableSort: true },
          {
            title: '',
            field: 'serialCoupon',
            render: coupon => {
              if (coupon.coupontypeId === SERIALIZED_COUPON_TYPE_ID) {
                const brandIds = coupon.couponBrandConnection?.edges?.map(
                  node => node.node.brandId,
                );

                return (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSerializedCouponModal({ couponId: coupon.id, brandIds });
                    }}
                  >
                    Generate codes
                  </Button>
                );
              } else {
                return '';
              }
            },
          },
        ]}
      />
      <SerializedCouponDistributionModal
        open={serializedCouponModal}
        setSerializedCouponModal={setSerializedCouponModal}
        couponId={serializedCouponModal.couponId}
        brands={serializedCouponModal.brandIds}
      />
    </div>
  );
}
DiscountsTable.propTypes = {};

export default DiscountsTable;
