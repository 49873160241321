import { gql } from '@apollo/client';

const DELETE_INVENTORY_VENDOR = gql`
  mutation DELETE_INVENTORY_VENDOR($inventoryvendorId: Int!) {
    removeInventoryvendor(input: { inventoryvendorId: $inventoryvendorId }) {
      viewer {
        id
      }
      inventoryvendorId
    }
  }
`;

export default DELETE_INVENTORY_VENDOR;
