import { gql } from '@apollo/client';
import { itemtypeFragment } from "../../fragments";

const CREATE_ITEM_TYPE = gql`
  mutation createItemtype($input: createItemtypeMutationInput!) {
    createItemtype(input: $input) {
      viewer {
        id
      }
      itemtype {
        ...ItemtypeData
      }
    }
  }
  ${itemtypeFragment.itemtype}
`;

export default CREATE_ITEM_TYPE;
