import React, { useState } from 'react';
import Add from '@material-ui/icons/Add';
import { LOCATION_NOTES } from '../../../../../../apollo/queries/locations/locationNotes';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import moment from 'moment-timezone';
import IconButton from '@material-ui/core/IconButton';
import Modal from '../../../../../blocks/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import ADD_LOCATION_NOTE from '../../../../../../apollo/mutations/locations/addLocationNote';
import { useForm } from 'react-hook-form';
import { uglifyId } from '../../../../../../utils';
import REMOVE_LOCATION_NOTE from '../../../../../../apollo/mutations/locations/removeLocationNote';
import { ConfirmationModal, Permission, PermissionField } from '../../../../../blocks';
import { MUTATION_NAME } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const LocationNotes = ({
  locationId,
  mutationPermissions,
  setOpenWarningMutationName,
}) => {
  const { setSnackbar } = useSnackbar();

  const addLocationNotePermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'addLocationNote',
  );
  const removeLocationNotePermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'removeLocationNote',
  );

  const [open, setOpen] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [variables, setVariables] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const [addLocationNote] = useMutation(ADD_LOCATION_NOTE);
  const [removeLocationNote] = useMutation(REMOVE_LOCATION_NOTE);
  const handleOpen = variables => {
    setVariables(variables?.variables);
    setOpen(true);
    setOpenWarningMutationName(MUTATION_NAME.NewLocationnoteMutationInput);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const handleDeleteNote = () => {
    removeLocationNote({
      variables: {
        input: {
          noteId,
        },
      },
      refetchQueries: [{ query: LOCATION_NOTES, variables: { ...variables } }],
    })
      .then(() => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Note is deleted.',
        });
        setTimeout(handleClose, 1200);
      })
      .catch(() =>
        setSnackbar({
          open: true,
          type: 'error',
          text: 'Something went wrong.',
        }),
      );
  };

  const onSubmit = ({ note }) => {
    addLocationNote({
      variables: {
        input: {
          locationIds: [{ id: uglifyId('Location', locationId) }],
          note,
        },
      },
      refetchQueries: [{ query: LOCATION_NOTES, variables: { ...variables } }],
    })
      .then(() => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Note is added.',
        });
        setTimeout(handleClose, 1200);
      })
      .catch(() =>
        setSnackbar({
          open: true,
          type: 'error',
          text: 'Something went wrong.',
        }),
      );
  };
  return (
    <>
      <ConnectionTable
        query={LOCATION_NOTES}
        title={'Location catering notes'}
        handleDeleteRow={(data, variables) => {
          setNoteId(data?.id);
          setVariables(variables);
          setConfirmationModal(true);
        }}
        customActionsTop={(data, variables) => (
          <Permission
            access={Math.min(
              addLocationNotePermission?.access,
              MUTATION_NAME.NewLocationnoteMutationInput,
            )}
          >
            <div>
              <PermissionField createHelperText>
                <IconButton variant="contained" onClick={() => handleOpen(variables)}>
                  <Add />
                </IconButton>
              </PermissionField>
            </div>
          </Permission>
        )}
        initialQueryVariables={{
          first: 5,
          filter: [
            {
              locationId: {
                eq: locationId,
              },
            },
          ],
        }}
        /* eslint-disable-next-line no-shadow */
        structureTable={data =>
          data?.viewer?.locationnoteConnection?.edges.map(location => ({
            id: location?.node.id,
            note: location?.node?.note,
            created: moment(location.node.created).format('MM/DD/YYYY h:mm A z'),
            disableDelete: !removeLocationNotePermission?.access,
          }))
        }
        columns={[
          {
            title: 'Note',
            field: 'note',
          },
          {
            title: 'Date',
            field: 'created',
          },
        ]}
      />
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        handleClose={handleClose}
        title={'Add Location Note'}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '95%', marginTop: '2rem' }}>
          <TextField
            name={'note'}
            variant={'outlined'}
            label={'Note'}
            fullWidth={true}
            inputRef={register({ required: true })}
            error={errors?.note}
            helperText={errors?.note && 'Note is required.'}
          />
          <div style={{ marginTop: '1.5rem' }}>
            <div style={{ display: 'flex' }}>
              <Button style={{ marginRight: '.5rem' }} onClick={() => handleClose()}>
                Close
              </Button>
              <Permission access={addLocationNotePermission?.access}>
                <div>
                  <PermissionField createHelperText>
                    <Button type={'submit'} onClick={() => onSubmit()}>
                      Add Note
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
            </div>
          </div>
        </form>
      </Modal>
      <ConfirmationModal
        open={confirmationModal}
        handleClose={handleCloseConfirmationModal}
        confirmAction={() => handleDeleteNote(noteId)}
      />
    </>
  );
};

export default LocationNotes;
