import { gql } from '@apollo/client';

const FETCH_INVENTORY_COUNT_EXPORT = gql`
  query inventorycountExport(
    $search: String
    $sort: [LocationIngredientSort]
    $filter: [LocationIngredientFilter]
    $locationId: Int!
    $dateRange: DateRangeFilterInputInventoryCount
  ) {
    viewer {
      id
      locationIngredientConnection(
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 95
        showInLocationStorageOnly: true
      ) {
        edges {
          node {
            id
            inventoryitem {
              id
              inventoryitemId
              label
              measure {
                label
                abbreviation
              }
              inventorycountSummary(locationId: $locationId, dateRange: $dateRange)
              inventoryitemcategory {
                id
                inventoryitemcategoryId
                reportcategoryId
                reportcategory {
                  id
                  label
                }
                generalledgeraccountId
                generalledgeraccount {
                  id
                  label
                }
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_INVENTORY_COUNT_EXPORT;
