import Employees from './Employees';
import Schedule from './Schedule';
import Timeshift from './Timeshift';
import Settings from './Settings';

const Labor = {
  Employees,
  Schedule,
  Timeshift,
  Settings,
};

export default Labor;
