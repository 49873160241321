import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  sectionListContainer: {
    zIndex: 1,
  },

  contentContainer: {
    backgroundColor: theme.colors.boxBackground,
    overflow: "auto",
    maxHeight: "calc(100vh - 132px)",    
    zIndex: 2,

    '& .MuiPaper-root': {
      boxShadow: 'none',
      margin: 0,      
    },

    '& .MuiGridList-root': {
      margin: 0,
    },

    '& .MuiGridListTile-root': {
      margin: 0,
    },

  },

  sectionList: {
    paddingTop: 0,
    width: '100%',
  },

  sectionListItem: {
    color: theme.sectionList.color,
  },

  selectedSectionListItem: theme.sectionList.selected,
});

const SideBarSectionsLayoutStyles = makeStyles(Styles);

export default SideBarSectionsLayoutStyles;
