import React, { useCallback, useEffect, useMemo } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import Ingredient from './Ingredient';
import Summary from './Summary';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useMutation, useQuery } from '@apollo/client';
import VENDOR_INVOICE_PRODUCTS from '../../../../../apollo/queries/inventoryInvoices/vendorInvoiceProducts';
import PaperMain from '../../../../blocks/PaperMain';
import { SAVE_VENDOR_INVOICE } from '../../../../../apollo/mutations/inventory/saveVendorInvoice';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { paths, SNACKBAR_STATUS } from '../../../../../constants';
import { isEmpty } from 'lodash';
import { SUBMIT_VENDOR_INVOICE } from '../../../../../apollo/mutations/inventory/submitVendorInvoice';
import useStyles from '../SimpleInvoice/styles';
import FormItems from '../../../../blocks/FormItems';
import { useForm } from 'react-hook-form';
import { formatData, formatNumberStringToFloat } from '../../../helpers';
import UPDATE_SIMPLE_INVOICE from '../../../../../apollo/mutations/inventory/updateSimpleInvoice';
import { prepareFormData } from '../SimpleInvoice/helpers';
import { convert, CurrencyInputFormat } from '../../../../../utils';

const InvoiceSummary = () => {
  const { invoiceId, locationId: ids } = useParams();
  const locationId = ids.split('-')[0];
  const storeNumber = ids.split('-')[1];

  const { push } = useHistory();
  const { setSnackbar } = useSnackbar();
  const classes = useStyles();

  const {
    getValues,
    setValue,
    errors,
    onSubmit,
    register,
    control,
    unregister,
    handleSubmit,
  } = useForm();

  const { data, loading, error } = useQuery(VENDOR_INVOICE_PRODUCTS, {
    fetchPolicy: 'network-only',
    variables: {
      invoiceId,
      filter: {
        invoiceId: { eq: invoiceId },
        storeNumber: { eq: storeNumber },
      },
    },
  });

  const [saveVendorInvoice] = useMutation(SAVE_VENDOR_INVOICE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Vendor invoice saved.',
        open: true,
      });

      push(paths.COREOPS_INVENTORY_LOCATION_TASKLIST.replace(':locationId', ids));
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [submitVendorInvoice] = useMutation(SUBMIT_VENDOR_INVOICE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Vendor invoice submitted.',
        open: true,
      });

      push(paths.COREOPS_INVENTORY_LOCATION_TASKLIST.replace(':locationId', ids));
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const [updateSimpleInvoice] = useMutation(UPDATE_SIMPLE_INVOICE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Simple invoice updated.',
        open: true,
      });

      push(paths.COREOPS_INVENTORY_LOCATION_INVOICE_JOURNAL.replace(':locationId', ids));
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  const invoice = useMemo(
    () =>
      (!isEmpty(data?.viewer?.invoiceConnection?.edges) &&
        data.viewer.invoiceConnection.edges[0]?.node) ||
      {},
    [data],
  );

  useEffect(() => {
    if (invoice && invoice.isSimple && !invoice.isSubmitted) {
      push(
        paths.COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_ID.replace(':locationId', ids).replace(
          ':invoiceId',
          invoiceId,
        ),
      );
    }
  }, [invoice, locationId, invoiceId]);

  const ingredients = useMemo(() => {
    if (invoice) {
      if (invoice.isSimple) {
        return (
          invoice.invoiceIngredientConnection?.edges?.map(edge => ({
            ...edge.node,
            productId: edge.node.ingredientId,
            ingredientLabel: edge.node.ingredient?.inventoryitem?.label,
            vendorUOM: edge.node.measure?.abbreviation,
            cost: edge.node.ingredient?.inventoryitem?.cost || 0,
          })) || []
        );
      } else {
        return (
          invoice.invoiceproductConnection?.edges?.map(edge => ({
            ...edge.node,
            quantity: edge.node.vendorproduct?.ingredient?.invoiceIngredient
              ? edge.node.vendorproduct?.ingredient?.invoiceIngredient?.baseQuantity
              : edge.node.quantityInvoiced || 0,
            note:
              edge.node.vendorproduct?.ingredient?.invoiceIngredient?.note || edge.node.note || '',
            ingredientId: edge.node?.vendorproduct?.ingredientId,
            ingredientLabel: edge.node.vendorproduct?.ingredient?.inventoryitem?.label,
          })) || []
        );
      }
    }

    return [];
  }, [invoice]);

  const onSaveClick = useCallback(
    formData => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Saving vendor invoice...',
        open: true,
      });

      const products = formatData({
        formData,
        label: 'products',
        mapFunction: ({ id, ...rest }) => rest,
      });

      saveVendorInvoice({
        variables: {
          invoiceId,
          isSubmitted: false,
          products,
        },
      });
    },
    [saveVendorInvoice, invoiceId],
  );

  const onSubmitClick = useCallback(
    formData => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Submitting vendor invoice...',
        open: true,
      });

      if (invoice.isSimple) {
        const input = prepareFormData({
          formData: {
            ...formData,
            invoiceNumber: invoice.invoiceNumber,
            supplierName: invoice.supplierName,
            dateOfPurchase: invoice.invoiceDate,
            subtotal: invoice.subtotal,
            tax: invoice.tax,
            total: invoice.total,
          },
          formatTotals: false,
          mapFunction: ({ measureId, quantity, cost, productId }) => ({
            measureId,
            quantity: formatNumberStringToFloat(quantity),
            cost: convert.dollarsToCents(CurrencyInputFormat(cost)),
            ingredientId: productId,
          }),
          image: {
            url: invoice.invoiceFileUrl,
          },
          isSubmit: true,
          storeNumber: invoice.storeNumber,
          invoiceId,
          label: 'products',
        });

        updateSimpleInvoice({
          variables: {
            input,
          },
        });
      } else {
        const products = formatData({
          formData,
          label: 'products',
          mapFunction: ({ id, ...rest }) => rest,
        });

        submitVendorInvoice({
          variables: {
            invoiceId,
            isSubmitted: true,
            products,
          },
        });
      }
    },
    [submitVendorInvoice, invoiceId, invoice?.isSimple, invoice?.storeNumber],
  );

  const onBackClick = useCallback(() => {
    push(
      paths[
        invoice?.isSubmitted
          ? 'COREOPS_INVENTORY_LOCATION_INVOICE_JOURNAL'
          : 'COREOPS_INVENTORY_LOCATION_TASKLIST'
      ].replace(':locationId', ids),
    );
  }, [locationId, push, invoice?.isSubmitted]);

  // const isConfirmEnabled = useMemo(() => {
  //   return (
  //     invoice.isSimple || (ingredients.length && ingredients.length === checkedProducts.length)
  //   );
  // }, [ingredients.length, checkedProducts.length, invoice.isSimple]);

  return (
    <PaperMain className={classes.paper}>
      {loading ? (
        <div>loading</div>
      ) : error || isEmpty(invoice) ? (
        <div>error</div>
      ) : (
        <>
          <form
            style={{
              maxWidth: '600px',
              minHeight: !invoice.isSubmitted ? 'calc(100% - 100px)' : 'calc(100% - 33px)',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography align="center" variant="h3" style={{ marginBottom: '16px' }}>
              Invoice #{invoice.invoiceNumber}
            </Typography>
            <FormItems
              name="products"
              addItemComponent={() => null}
              items={ingredients}
              unregister={unregister}
              control={control}
              getValues={getValues}
              register={register}
              errors={errors}
              setValue={setValue}
              itemComponent={Ingredient}
              enableRemove={false}
              itemComponentProps={{
                isSimple: invoice.isSimple,
              }}
            />
            <Summary invoice={invoice} />
          </form>

          <Grid container className={classes.buttonsContainer} spacing={2}>
            <Grid className={classes.button} item style={{ marginRight: 'auto' }}>
              <Button style={{ minWidth: '100%' }} variant="outlined" onClick={onBackClick}>
                Back
              </Button>
            </Grid>
            {invoice.updatable ? (
              <>
                <Grid className={classes.button} item>
                  <Button
                    style={{ minWidth: '100%' }}
                    disabled={invoice.isSubmitted}
                    onClick={handleSubmit(onSaveClick)}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item className={classes.button}>
                  <Button style={{ minWidth: '100%' }} onClick={handleSubmit(onSubmitClick)}>
                    Confirm & Reconcile
                  </Button>
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      )}
    </PaperMain>
  );
};

export default InvoiceSummary;
