import { SvgIcon } from '@material-ui/core';

const OrderTracker1Active = () => (
  <SvgIcon
    style={{ width: '100%', height: '100%' }}
    viewBox="0 0 91 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect id="Rectangle_7178" x="26.94" y="23.52" width="24" height="24" fill="none" />
    <polygon
      points="30.1 32.63 31.27 27.12 32.72 26.41 58.83 26.64 59.75 27.7 60.71 32.97 30.1 32.63"
      fill="#fdda00"
    />
    <path
      d="m61.72,43.01c-.08,0-.15,0-.22-.03-.07-.03-.14-.07-.19-.12-.06-.05-.1-.12-.13-.19-.03-.07-.05-.15-.05-.22s.02-.15.05-.22c.03-.07.07-.13.13-.19.06-.05.12-.09.19-.12.07-.03.15-.04.22-.03h3.75l-5.39-8.48h-30.17l-5.39,8.48h31.08c.07,0,.15.01.21.04.07.03.13.07.18.12.05.05.09.11.12.18.03.07.04.14.04.21s-.01.15-.04.21c-.03.07-.07.13-.12.18-.05.05-.11.09-.18.12-.07.03-.14.04-.21.04h-31.55v6.61c0,.34.13.66.37.9.24.24.56.38.9.38h39.3c.34,0,.66-.14.91-.38.24-.24.38-.56.39-.9v-6.61h-4.22Z"
      fill="#ffda00"
    />
    <g>
      <path
        d="m33.67,19.2v-.38c0-.1-.04-.2-.11-.27-.07-.07-.17-.11-.27-.11-.05,0-.1,0-.14.03-.05.02-.09.05-.12.08s-.06.08-.08.12c-.02.05-.03.1-.03.14,0,.13-.01.26-.05.38h.81Z"
        fill="#fecf09"
      />
      <path
        d="m34.82,29.69h5.87v-10.87c0-.1-.04-.2-.11-.27-.07-.07-.17-.11-.27-.11h-5.54c.04.12.06.25.05.38v10.87Zm1.3-10.12h3.16c.15,0,.29.06.4.16.1.1.16.25.16.4s-.06.29-.16.4c-.1.1-.25.16-.4.16h-3.16c-.15,0-.29-.06-.4-.16-.1-.1-.16-.25-.16-.4s.06-.29.16-.4c.1-.1.25-.16.4-.16Zm0,2.64h3.16c.14,0,.28.07.38.17.1.1.15.24.15.38s-.05.28-.15.38c-.1.1-.23.17-.38.17h-3.16c-.14,0-.28-.07-.38-.17-.1-.1-.15-.24-.15-.38s.05-.28.15-.38c.1-.1.23-.17.38-.17Zm0,2.61h3.16c.15,0,.29.06.4.16.1.1.16.25.16.4s-.06.29-.16.4-.25.16-.4.16h-3.16c-.15,0-.29-.06-.4-.16-.1-.1-.16-.25-.16-.4s.06-.29.16-.4c.1-.1.25-.16.4-.16Zm0,2.23h3.16c.14,0,.28.07.38.17.1.1.15.24.15.38s-.05.28-.15.38c-.1.1-.23.17-.38.17h-3.16c-.14,0-.28-.07-.38-.17-.1-.1-.15-.24-.15-.38s.05-.28.15-.38c.1-.1.23-.17.38-.17Z"
        fill="#ffda00"
      />
      <path
        d="m36.87,16.04v-6.95h-5.86v9.75c0,.1.05.18.13.25.07.06.17.1.26.09.1,0,.19-.04.26-.11.07-.07.11-.16.11-.26,0-.4.17-.79.45-1.08.29-.29.67-.45,1.08-.45h4.11v-.69c-.14,0-.28-.07-.38-.17-.1-.1-.16-.24-.16-.38"
        fill="#ffda00"
      />
      <path
        d="m46.83,17.2v3.98c0,.07.02.14.06.21.04.06.1.11.16.14.04.02.08.03.12.03h9.87s.08-.01.12-.03c.07-.03.12-.08.16-.14.04-.06.06-.13.06-.21v-3.98c0-.07-.02-.14-.06-.21-.04-.06-.1-.11-.16-.14-.04-.02-.08-.02-.12-.03h-9.87s-.08.01-.12.03c-.07.03-.12.08-.16.14-.04.06-.06.13-.06.21Z"
        fill="#ffda00"
      />
      <path
        d="m50.78,35.2h-1.04c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16h1.04c.15,0,.29-.06.4-.16s.16-.25.16-.4-.06-.29-.16-.4c-.1-.1-.25-.16-.4-.16Z"
        fill="#070908"
      />
      <path
        d="m54.58,37.29h-1.07c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17h1.05c.15,0,.29-.06.39-.17.1-.1.16-.24.17-.39,0-.07,0-.14-.03-.21-.03-.07-.06-.13-.11-.18-.05-.05-.11-.09-.17-.12-.07-.03-.14-.05-.21-.05"
        fill="#070908"
      />
      <path
        d="m54.58,35.2h-1.07c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16h1.05c.15,0,.29-.06.39-.16.1-.1.16-.24.17-.39,0-.07,0-.14-.04-.21-.03-.07-.06-.13-.11-.18-.05-.05-.11-.09-.17-.12-.07-.03-.14-.05-.21-.05"
        fill="#070908"
      />
      <path
        d="m54.59,39.35h-1.08c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16h1.05c.15,0,.29-.06.39-.16.1-.1.16-.24.16-.39,0-.14-.05-.28-.14-.39-.1-.11-.23-.17-.37-.18"
        fill="#070908"
      />
      <path
        d="m50.78,37.29h-1.04c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17h1.04c.14,0,.28-.07.38-.17.1-.1.15-.24.15-.38s-.05-.28-.15-.38c-.1-.1-.23-.17-.38-.17Z"
        fill="#070908"
      />
      <path
        d="m58.36,39.35h-1.05c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16h1.04c.15,0,.29-.06.4-.16.1-.1.16-.25.16-.4s-.06-.29-.16-.4c-.1-.1-.25-.16-.4-.16"
        fill="#070908"
      />
      <path
        d="m67.04,42.45c0-.07,0-.14-.03-.2v-.08s-.04-.03-.04-.03c0-.01-.02-.02-.03-.03l-6.07-9.56-.86-4.64c-.09-.56-.38-1.07-.82-1.44-.43-.37-.98-.57-1.55-.57h-3.05v-2.98c0-.15-.05-.29-.15-.4-.1-.11-.24-.17-.38-.18-.15,0-.29.05-.4.15-.05.05-.1.11-.13.17-.03.07-.05.14-.05.21,0,.02,0,.03,0,.05v2.99h-2.67v-2.98c0-.15-.05-.29-.14-.4-.1-.11-.24-.17-.38-.18-.15,0-.29.05-.4.15-.05.05-.1.11-.13.17-.03.07-.05.14-.05.21,0,.02,0,.03,0,.05v2.99h-6.72c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17h14.68c.3,0,.58.11.81.3.23.19.37.46.42.76l.77,4.15h-29.38l.77-4.16c.05-.29.2-.56.42-.75.23-.19.51-.3.81-.3h.1c.08,0,.15,0,.22-.03.07-.03.14-.07.19-.12.06-.05.1-.12.13-.19.03-.07.05-.15.05-.22s-.02-.15-.05-.22c-.03-.07-.07-.13-.13-.19-.06-.05-.12-.09-.19-.12-.07-.03-.15-.04-.22-.03h-.1c-.57,0-1.11.21-1.55.57-.43.37-.72.87-.82,1.43l-.82,4.64-6.09,9.58c-.06.09-.09.2-.09.3v7.17c0,.64.25,1.26.7,1.72.45.46,1.07.72,1.71.72h39.34c.64,0,1.26-.26,1.71-.72.45-.46.7-1.08.7-1.72l-.04-7.16Zm-1.11,7.16c-.01.34-.15.66-.39.9-.24.24-.57.38-.91.38H25.33c-.34,0-.66-.14-.9-.38-.24-.24-.37-.57-.37-.9v-6.61h31.55c.07,0,.15-.01.21-.04.07-.03.13-.07.18-.12.05-.05.09-.11.12-.18.03-.07.04-.14.04-.21s-.01-.15-.04-.21c-.03-.07-.07-.13-.12-.18-.05-.05-.11-.09-.18-.12-.07-.03-.14-.04-.21-.04h-31.08l5.39-8.48h30.17l5.39,8.48h-3.75c-.08,0-.15,0-.22.03-.07.03-.14.07-.19.12-.06.05-.1.12-.13.19-.03.07-.05.15-.05.22s.02.15.05.22c.03.07.07.13.13.19.06.05.12.09.19.12.07.03.15.04.22.03h4.21v6.61Z"
        fill="#070908"
      />
      <path
        d="m47.18,22.72h9.87c.4,0,.78-.16,1.06-.44.28-.28.44-.66.44-1.06v-4.03c0-.4-.16-.78-.44-1.06-.28-.28-.66-.44-1.06-.44h-9.87c-.4,0-.78.16-1.06.44-.28.28-.44.66-.44,1.06v4.03c0,.4.16.78.44,1.06.28.28.66.44,1.06.44Zm-.34-5.53c0-.07.02-.14.06-.19.04-.06.1-.1.16-.13.04-.01.08-.02.12-.02h9.87c.09,0,.17.04.24.11.06.06.1.15.11.24v4.03c0,.07-.02.14-.06.19-.04.06-.1.1-.16.13-.04.02-.08.02-.12.03h-9.87c-.09,0-.18-.04-.24-.11-.06-.06-.1-.15-.11-.24v-4.03Z"
        fill="#070908"
      />
      <path
        d="m58.36,37.29h-1.05c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17h1.04c.14,0,.28-.07.38-.17.1-.1.15-.24.15-.38s-.05-.28-.15-.38c-.1-.1-.23-.17-.38-.17"
        fill="#070908"
      />
      <path
        d="m50.78,39.35h-1.04c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16h1.04c.15,0,.29-.06.4-.16s.16-.25.16-.4-.06-.29-.16-.4-.25-.16-.4-.16Z"
        fill="#070908"
      />
      <path
        d="m58.36,35.2h-1.05c-.15,0-.29.06-.4.16s-.16.25-.16.4.06.29.16.4c.1.1.25.16.4.16h1.04c.15,0,.29-.06.4-.16.1-.1.16-.25.16-.4s-.06-.29-.16-.4c-.1-.1-.25-.16-.4-.16"
        fill="#070908"
      />
      <path
        d="m31.37,20.39h2.33v9.3h-.83c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17h9.8c.14,0,.28-.07.38-.17.1-.1.15-.24.15-.38s-.05-.28-.15-.38c-.1-.1-.23-.17-.38-.17h-.84v-10.87c0-.4-.17-.79-.45-1.08-.29-.29-.67-.45-1.08-.45h-7.02c-.4,0-.79.17-1.08.45-.29.29-.45.67-.45,1.08,0,.1-.04.19-.11.26-.07.07-.16.11-.26.11-.1,0-.19-.03-.26-.09-.07-.06-.12-.15-.13-.25,0,0,0-.02,0-.03v-9.73h5.87v6.95c0,.14.06.28.16.38.1.1.24.16.38.17,0,0,.02,0,.02,0,.15,0,.29-.06.4-.16.1-.1.16-.25.16-.4v-7.55c0-.15-.06-.29-.17-.39-.1-.1-.25-.16-.39-.16h-7.02c-.15,0-.29.06-.39.17-.1.1-.16.25-.16.39v10.32c0,.41.15.81.44,1.1.29.29.68.46,1.09.47m8.94-1.95c.1,0,.2.04.27.11.07.07.11.17.11.27v10.87h-5.87v-10.87c0-.13-.02-.26-.05-.38h5.54Zm-7.02,0c.1,0,.2.04.27.11.07.07.11.17.11.27v.38h-.81c.04-.12.06-.25.05-.39,0-.05,0-.1.03-.14.02-.05.05-.09.08-.12s.08-.06.12-.08c.05-.02.1-.03.14-.03Z"
        fill="#070908"
      />
      <path
        d="m47.01,35.2h-1.04c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16h1.04c.15,0,.29-.06.4-.16.1-.1.16-.25.16-.4s-.06-.29-.16-.4c-.1-.1-.25-.16-.4-.16Z"
        fill="#070908"
      />
      <path
        d="m47.01,39.35h-1.04c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16h1.04c.15,0,.29-.06.4-.16.1-.1.16-.25.16-.4s-.06-.29-.16-.4c-.1-.1-.25-.16-.4-.16Z"
        fill="#070908"
      />
      <path
        d="m47.01,37.29h-1.04c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17h1.04c.14,0,.28-.07.38-.17.1-.1.15-.24.15-.38s-.05-.28-.15-.38c-.1-.1-.23-.17-.38-.17Z"
        fill="#070908"
      />
      <path
        d="m36.12,20.68h3.16c.14,0,.28-.07.38-.17.1-.1.15-.24.15-.38s-.05-.28-.15-.38c-.1-.1-.23-.17-.38-.17h-3.16c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17Z"
        fill="#070908"
      />
      <path
        d="m36.12,23.32h3.16c.14,0,.28-.07.38-.17.1-.1.15-.24.15-.38s-.05-.28-.15-.38c-.1-.1-.23-.17-.38-.17h-3.16c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38.23.17.38.17Z"
        fill="#070908"
      />
      <path
        d="m36.12,28.16h3.16c.14,0,.28-.07.38-.17.1-.1.15-.24.15-.38s-.05-.28-.15-.38c-.1-.1-.23-.17-.38-.17h-3.16c-.14,0-.28.07-.38.17-.1.1-.15.24-.15.38s.05.28.15.38c.1.1.23.17.38.17Z"
        fill="#070908"
      />
      <path
        d="m36.12,25.93h3.16c.15,0,.29-.06.4-.16.1-.1.16-.25.16-.4s-.06-.29-.16-.4c-.1-.1-.25-.16-.4-.16h-3.16c-.15,0-.29.06-.4.16-.1.1-.16.25-.16.4s.06.29.16.4c.1.1.25.16.4.16Z"
        fill="#070908"
      />
    </g>
  </SvgIcon>
);

export default OrderTracker1Active;
