import { gql } from '@apollo/client';

const DELETE_EQUIPMENT = gql`
  mutation DELETE_EQUIPMENT($input: DeleteEquipmentMutationInput!) {
    deleteEquipment(input: $input) {
      viewer {
        id
      }
      deletedEquipmentId
    }
  }
`;

export default DELETE_EQUIPMENT;
