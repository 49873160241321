import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  rootContainer: {
    width: '100%',
    overflowX: 'auto',
  },

  inlineContainer: {
    backgroundColor: theme.customToggleButton.backgroundColor,
    borderRadius: theme.spacing(2),
    padding: '2px',
    maxHeight: '30px',
    display: 'inline-flex',
  },

  rootLabel: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    color: theme.customToggleButton.labelColor,
    margin: 0,
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',

    '&:hover:not(.active)': {
      cursor: 'pointer',
      backgroundColor: theme.customToggleButton.hoverBackgroundColor,
    },

    '&.active': {
      backgroundColor: theme.customToggleButton.activeBackgroundColor,
      color: theme.customToggleButton.activeLabelColor,
      pointerEvents: 'none',
      boxShadow: '0px 3px 8px #0000001F',
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  rootDivider: {
    marginLeft: '3px',
    marginRight: '3px',
    marginTop: '2px',
    marginBottom: 0,
    minHeight: '80%',
    maxHeight: '22px',
  },
});

const ToggleButtonStyles = makeStyles(Styles);

export default ToggleButtonStyles;
