import { gql } from '@apollo/client';

const BULK_EDIT_LOCATIONS = gql`
  query BulkeditLocation($filter: [LocationFilter]) {
    viewer {
      id
      locationConnection(filter: $filter) {
        edges {
          node {
            id
            label
            menuId
            tierId
            address {
              state {
                abbreviation
              }
            }
            storeNumber
          }
        }
      }
      menuConnection(fieldGroup: 17) {
        edges {
          node {
            id
            label
            menuId
          }
        }
      }
      tierConnection {
        edges {
          node {
            id
            label
            tierId
          }
        }
      }
    }
  }
`;

export default BULK_EDIT_LOCATIONS;
