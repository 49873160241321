import { gql } from "@apollo/client";

const FETCH_HIERARCHY = gql`
  query fetchHierarchy {
    viewer {
      id
      hierarchyConnection(fieldGroupOnly: 69) {
        edges {
          node {
            id
            label
            locationConnection {
              edges {
                node {
                  id
                  storeNumber
                  locationId
                  label
                }
              }
            }
            children {
              id
              label
              locationConnection {
                edges {
                  node {
                    id
                    storeNumber
                    locationId
                    label
                  }
                }
              }
              children {
                id
                label
                locationConnection {
                  edges {
                    node {
                      id
                      storeNumber
                      locationId
                      label
                    }
                  }
                }
                children {
                  id
                  label
                  locationConnection {
                    edges {
                      node {
                        id
                        storeNumber
                        locationId
                        label
                      }
                    }
                  }
                  children {
                    id
                    label
                    locationConnection {
                      edges {
                        node {
                          id
                          storeNumber
                          locationId
                          label
                        }
                      }
                    }
                    children {
                      id
                      label
                      locationConnection {
                        edges {
                          node {
                            id
                            storeNumber
                            locationId
                            label
                          }
                        }
                      }
                      children {
                        id
                        label
                        locationConnection {
                          edges {
                            node {
                              id
                              storeNumber
                              locationId
                              label
                            }
                          }
                        }
                      }
                      children {
                        id
                        label
                        locationConnection {
                          edges {
                            node {
                              id
                              storeNumber
                              locationId
                              label
                            }
                          }
                        }
                        children {
                          id
                          label
                          locationConnection {
                            edges {
                              node {
                                id
                                storeNumber
                                locationId
                                label
                              }
                            }
                          }
                          children {
                            id
                            label
                            locationConnection {
                              edges {
                                node {
                                  id
                                  storeNumber
                                  locationId
                                  label
                                }
                              }
                            }
                            children {
                              id
                              label
                              locationConnection {
                                edges {
                                  node {
                                    id
                                    storeNumber
                                    locationId
                                    label
                                  }
                                }
                              }
                              children {
                                id
                                label
                                locationConnection {
                                  edges {
                                    node {
                                      id
                                      storeNumber
                                      locationId
                                      label
                                    }
                                  }
                                }
                                children {
                                  id
                                  label
                                  locationConnection {
                                    edges {
                                      node {
                                        id
                                        storeNumber
                                        locationId
                                        label
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_HIERARCHY;
