const formatConnectionName = (entityName, variables) => {
  // order alphabetically to match apollo cache sorting
  const ordered = Object.keys(variables)
    .sort()
    .reduce((obj, key) => {
      obj[key] = variables[key];
      return obj;
    }, {});

  const dynamicConnectionName =
    `${entityName}Connection(` + JSON.stringify(ordered) + `)`;
  return { dynamicConnectionName };
};

export default formatConnectionName;
