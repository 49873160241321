import { compact, isEmpty } from 'lodash-es';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import imageConvert from '../imageConvert';
import peopleIcon from '../../assets/icons/PeopleIcon.png';
import chefIcon from '../../assets/icons/ChefIcon.png';
import timeIcon from '../../assets/icons/TimeIcon.png';

function createHeaderContent({ title, yieldQuantity, shelfLife, prepTime }) {
  return [
    {
      style: 'header',
      table: {
        widths: ['100%'],
        body: [
          [
            {
              text: title,
              marginTop: 10,
              marginBottom: 10,
              border: [0, 0],
              alignment: 'center',
            },
          ],
        ],
      },
      layout: {
        fillColor: function(rowIndex) {
          return rowIndex === 0 ? '#FFDA00' : '#F8F8FA';
        },
      },
    },
    {
      style: 'subheader',
      layout: {
        fillColor: function() {
          return '#F8F8FA';
        },
        hLineWidth: function() {
          return 2;
        },
        vLineWidth: function() {
          return 0;
        },
        hLineColor: function() {
          return '#D3D3DB';
        },
        paddingTop: function() {
          return 5;
        },
        paddingBottom: function() {
          return 5;
        },
      },
      table: {
        widths: ['*', 'auto', 'auto', '*', 'auto', 'auto', '*', 'auto', 'auto', '*'],
        body: [
          [
            {},
            { image: peopleIcon, width: 14, height: 14 },
            {
              text: [{ text: 'Yield: ', bold: true }, { text: yieldQuantity }],
            },
            {},
            { image: timeIcon, width: 14, height: 14 },

            {
              text: [{ text: 'Shelf Life: ', bold: true }, { text: shelfLife || '-' }],
            },
            {},
            { image: chefIcon, width: 14, height: 14 },

            {
              text: [{ text: 'Prep Time: ', bold: true }, { text: prepTime || '-' }],
            },
            {},
          ],
        ],
      },
    },
  ];
}

function createBodyContent({
  equipment = [],
  ingredients = [],
  storageAndHoldingProcedures,
  recipeImageBase64,
  prepInstructions,
  serviceStandards,
  facts = {},
}) {
  const leftStack = [
    ...(!isEmpty(equipment)
      ? [
          {
            text: 'Equipment:',
            style: 'headerText',
          },
          {
            layout: 'noBorders',
            style: 'content',
            table: {
              headerRows: 1,
              widths: ['60%', '40%'],
              body: [
                [
                  { text: 'Name:', style: 'innerTableHeader' },
                  { text: 'Qty:', style: 'innerTableHeader' },
                ],
                ...equipment.map(item => [item.label, item.quantity]),
              ],
            },
          },
        ]
      : []),
    ...(!isEmpty(ingredients)
      ? [
          {
            text: 'Ingredients:',
            style: 'headerText',
          },
          {
            layout: 'noBorders',
            style: 'content',
            table: {
              headerRows: 1,
              widths: ['60%', '20%', '20%'],
              body: [
                [
                  { text: 'Name:', style: 'innerTableHeader' },
                  { text: 'Qty:', style: 'innerTableHeader' },
                  { text: 'UOM:', style: 'innerTableHeader' },
                ],
                ...ingredients.map(item => [item.label, item.quantity, item.measure]),
              ],
            },
          },
        ]
      : []),
    ...(storageAndHoldingProcedures
      ? [
          {
            text: 'Storage & Holding Procedures:',
            style: 'headerText',
          },
          {
            style: 'content',
            text: storageAndHoldingProcedures,
          },
        ]
      : []),
  ];

  const rightStack = compact([
    recipeImageBase64
      ? {
          image: recipeImageBase64,
          width: '326.5',
          style: 'imageStyle',
        }
      : null,
    ...(prepInstructions
      ? [
          {
            text: 'Preparation Instructions:',
            style: 'headerText',
          },
          {
            style: 'content',
            text: prepInstructions,
          },
        ]
      : []),
    ...(serviceStandards
      ? [
          {
            text: 'Service Standars:',
            style: 'headerText',
          },
          {
            style: 'content',
            text: serviceStandards,
          },
        ]
      : []),

    {
      text: [
        {
          text: 'F',
          style: 'factLetter',
        },
        {
          style: 'lastContent',
          text: `lavor: ${facts.flavor}`,
        },
      ],
    },
    {
      text: [
        {
          text: 'A',
          style: 'factLetter',
        },
        {
          style: 'lastContent',
          text: `ppearance: ${facts.appearance}`,
        },
      ],
    },
    {
      text: [
        {
          text: 'C',
          style: 'factLetter',
        },
        {
          style: 'lastContent',
          text: `onsistency: ${facts.consistency}`,
        },
      ],
    },
    {
      text: [
        {
          text: 'T',
          style: 'factLetter',
        },
        {
          style: 'lastContent',
          text: `emperature: ${facts.temperature}`,
        },
      ],
    },
  ]);

  return {
    style: 'body',
    layout: {
      hLineWidth: function(i, node) {
        return 0;
      },
      vLineWidth: function(i) {
        return i === 1 ? 2 : 0;
      },
      vLineColor: function(i) {
        return '#D3D3DB';
      },
      paddingTop: function(i) {
        return 10;
      },
      paddingBottom: function(i, node) {
        return 10;
      },
      paddingLeft: function(i) {
        return 10;
      },
      paddingRight: function(i, node) {
        return 10;
      },
    },
    table: {
      widths: ['40%', '60%'],
      body: [
        [
          {
            stack: leftStack.map((item, index) => ({
              ...item,
              style:
                index === leftStack.length - 1 && item.style === 'content'
                  ? 'lastContent'
                  : item.style,
            })),
          },
          {
            stack: rightStack.map((item, index) => ({
              ...item,
              style:
                index === rightStack.length - 1 && item.style === 'content'
                  ? 'lastContent'
                  : item.style,
            })),
          },
        ],
      ],
    },
  };
}

function getDocDefition(recipes) {
  const content = [];
  recipes.forEach(
    (
      {
        title,
        prepTime,
        shelfLife,
        yieldQuantity,
        storageAndHoldingProcedures,
        recipeImageBase64,
        equipment,
        ingredients,
        prepInstructions,
        serviceStandards,
        lastUpdated,
        facts,
      },
      index,
    ) =>
      content.push(
        ...createHeaderContent({ title, prepTime, shelfLife, yieldQuantity }),
        createBodyContent({
          storageAndHoldingProcedures,
          recipeImageBase64,
          equipment,
          ingredients,
          prepInstructions,
          serviceStandards,
          facts,
        }),
        {
          pageBreak: index + 1 < recipes.length ? 'after' : '',
          style: 'underTableContent',
          alignment: 'right',
          text: `Last Update: ${lastUpdated}`,
        },
      ),
  );

  return {
    footer: function(currentPage, pageCount) {
      return {
        style: 'content',
        columns: [
          {
            alignment: 'right',
            text: `${currentPage.toString()}/${pageCount}`,
          },
        ],
        margin: [25, 12, 25, 0],
      };
    },
    content: content,
    styles: {
      imageStyle: {
        marginBottom: 10,
      },
      header: {
        fontSize: 16,
        bold: true,
        color: '#3B3A43',
      },
      subheader: {
        alignment: 'center',
      },
      content: {
        fontSize: 10,
        marginBottom: 40,
        lineHeight: 1.5,
      },
      lastContent: { fontSize: 10, lineHeight: 1.5 },
      headerText: {
        marginBottom: 10,
        fontSize: 13,
        bold: true,
        decoration: 'underline',
      },
      factLetter: {
        marginBottom: 10,
        fontSize: 13,
        bold: true,
      },
      innerTableHeader: {
        bold: true,
        marginBottom: 5,
        fontSize: 12,
      },
      underTableContent: { fontSize: 10, marginLeft: 5, marginRight: 5, lineHeight: 1.5 },
      body: { marginBottom: 5 },
    },
    defaultStyle: {
      color: '#3B3A43',
    },
    pageMargins: [12, 12, 12, 42],
  };
}

const generateRecipePdf = async (recipes = []) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const updatedRecipes = await Promise.all(
    recipes.map(({ recipeImageUrl, ...data }) => {
      const promise = new Promise(resolve => {
        if (recipeImageUrl) {
          try {
            imageConvert.urlToBase64(recipeImageUrl, recipeImageBase64 =>
              resolve({
                ...data,
                recipeImageBase64,
              }),
            );
          } catch {
            resolve(data);
          }
        } else {
          resolve(data);
        }
      });

      return promise;
    }),
  );
  const docDefinition = getDocDefition(updatedRecipes);

  pdfMake.createPdf(docDefinition).open();
};

export default generateRecipePdf;
