import { gql } from '@apollo/client';

const ACHIEVEMENTS = gql`
  query Achievement($first: Int, $last: Int, $after: String, $before: String) {
    viewer {
      id
      achievementConnection(after: $after, first: $first, last: $last, before: $before) {
        edges {
          node {
            id
            label
            active
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`;

export default ACHIEVEMENTS;
