import { gql } from '@apollo/client';

export const FETCH_INVENTORY_VENDORS = gql`
  query FETCH_INVENTORY_VENDORS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [InventoryvendorSort]
    $filter: [InventoryvendorFilter]
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      inventoryvendorConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        sort: $sort
        filter: $filter
        fieldGroupOnly: $fieldGroupOnly
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            label
            inventoryvendorId
            inventoryvendorVendorId
            status
          }
        }
      }
    }
  }
`;
