import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CurrencyInput, Permission, PermissionField } from '../../../../../../blocks';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import UPDATE_HOUSE_ACCOUNT from '../../../../../../../apollo/mutations/loyalty/updateHouseAccount';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../../../constants';
import { CurrencyInputFormat } from '../../../../../../../utils';
import {
  convertHouseAccountValueForDatabase,
  convertHouseAccountValueForFrontend,
} from '../../../../../../../utils/houseAccountUtils';
import {
  limitGreaterOrEqualToBalanceMessage,
  limitGreaterOrEqualToZeroMessage,
} from '../../../../../../../constants/houseAccounts';

export default function EditHouseAccountModal({
  houseAccountData,
  updateHouseAccountPermission,
  permissions,
  accessToMutations,
  handleCloseModal,
}) {
  const { handleSubmit, control, errors } = useForm();
  const { setSnackbar } = useSnackbar();
  const [updateHouseAccount, { loading: updateHouseAccountLoading }, error] = useMutation(
    UPDATE_HOUSE_ACCOUNT,
  );

  const onSubmit = ({ limit }) => {
    updateHouseAccount({
      variables: {
        input: {
          locationId: houseAccountData.location.id,
          loginId: houseAccountData.login.id,
          limit: convertHouseAccountValueForDatabase(CurrencyInputFormat(limit)),
        },
      },
    })
      .then(() => {
        setSnackbar({
          text: 'House Account successfully updated!',
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
        });
        handleCloseModal(true);
      })
      .catch(e =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        }),
      );
  };

  if (updateHouseAccountLoading) return <div>loading...</div>;
  if (error) return <div>error</div>;

  return (
    <Grid container style={{ flexDirection: 'column' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container style={{ marginTop: '1rem' }}>
          <Grid item xs={12}>
            <Permission access={permissions.person}>
              <PermissionField
                Component={TextField}
                name="customer"
                label="Customer"
                disabled={true}
                defaultValue={`${houseAccountData?.login?.person?.firstName ||
                  ''} ${houseAccountData?.login?.person?.lastName || ''}`}
                fullWidth
              />
            </Permission>
          </Grid>
          <Grid item xs={12} style={{ margin: '1rem 0' }}>
            <Permission access={permissions.limit}>
              <PermissionField
                Component={CurrencyInput}
                control={control}
                rules={{
                  validate: {
                    negative: value => {
                      const formattedValue = CurrencyInputFormat(value) || null;
                      return (
                        !formattedValue || formattedValue >= 0 || limitGreaterOrEqualToZeroMessage
                      );
                    },
                    limitBiggerThanBalance: value => {
                      const formattedValue =
                        value || value === 0 ? CurrencyInputFormat(value) : null;
                      return (
                        formattedValue === null ||
                        CurrencyInputFormat(value) >=
                          convertHouseAccountValueForFrontend(houseAccountData.balance) ||
                        limitGreaterOrEqualToBalanceMessage
                      );
                    },
                  },
                }}
                label={'Limit'}
                defaultValue={
                  String(convertHouseAccountValueForFrontend(houseAccountData?.limit)) || null
                }
                error={!!errors.limit}
                helperText={errors?.limit?.message}
                fullWidth
                name="limit"
              />
            </Permission>
          </Grid>
        </Grid>
        <Grid item justify="flex-end" spacing={3} xs={12}>
          <Grid item>
            <Button color="primary" variant="outlined" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Permission
              access={Math.min(
                updateHouseAccountPermission.access,
                accessToMutations?.[MUTATION_NAME?.UpdateHouseaccountMutationInput],
              )}
            >
              <Button
                disabled={updateHouseAccountLoading}
                type="submit"
                style={{ marginLeft: '0.3rem' }}
              >
                Save
              </Button>
            </Permission>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
