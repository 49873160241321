import { gql } from "@apollo/client";

const CREATE_MULTIPLE = gql`
  mutation CreateMultiple($input: CreateMultipleNDMutationInput!) {
    createMultipleND(input: $input) {
        multiple {
        id
        multipleId
      }
    }
  }
`;

export default CREATE_MULTIPLE;
