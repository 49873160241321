import { gql } from '@apollo/client';

export const viewerFragment = {
  viewer: gql`
    fragment ViewerData on Viewer {
      id
      email
      accessLevel
      personId
    }
  `,
};
