import { gql } from '@apollo/client';

const FETCH_ITEM_CHOICES_INGREDIENTS = gql`
  query fetchItemChoiceIngredients($filter: [ItemMultipleFilter]) {
    viewer {
    id
    itemMultipleConnection(filter: $filter, sort: {order: ASC}) {
      edges {
        node {
          id
          order
          multiple {
            id
            label
            multipleId
            onlineLabel
            multipleChoiceConnection {
              edges {
                node {
                  id
                  orderKey
                  choice {
                    id
                    choiceId
                    label
                    choiceIngredientConnection {
                          edges {
                            node {
                              id
                              choiceId
                              ingredientId
                            	itemId
                              choiceIngredientId
                              created
                              quantity
                              measure {
                                id
                                measureId
                                label
                                abbreviation
                              }
                              ingredient {
                                inventoryitem {
                                  id
                                  label
                                  inventoryitemMeasureConnection {
                                    edges {
                                      node {
                                        id
                                        measure {
                                          label
                                          abbreviation
                                          id
                                          measureId
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;

export default FETCH_ITEM_CHOICES_INGREDIENTS;
