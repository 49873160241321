import React from 'react';
import BasicInfo from '../BasicInfo';
import StartEndDatePicker from '../StartEndDatePicker';
import AvailableDays from '../AvailableDays';
import TimezoneSelection from '../TimezoneSelection';
import DiscountType from '../DiscountType';

function GeneralSettingsView({
  platformList,
  platforms,
  setPlatforms,
  timezones,
  discountDetailsItems,
  setDiscountDetailsItems,
  control,
  itemAddedNumberTimes,
  setItemAddedNumberTimes,
  setSelectedSubcategoriesAction,
  selectedSubcategoriesAction,
  errors,
  brands,
  setBrands,
  brandList,
  excludeAllBoostLocations,
  handleBoostStoreExclusion,
  handleIsSmartChange,
  isSmart,
}) {
  return (
    <div>
      <h2 id="generalSettings">General Settings</h2>
      <BasicInfo
        platformList={platformList}
        platforms={platforms}
        setPlatforms={setPlatforms}
        control={control}
        brands={brands}
        setBrands={setBrands}
        brandList={brandList}
        isSmart={isSmart}
        handleIsSmartChange={handleIsSmartChange}
        excludeAllBoostLocations={excludeAllBoostLocations}
        handleBoostStoreExclusion={handleBoostStoreExclusion}
      />

      {/* Start end date inputs */}
      <StartEndDatePicker control={control} />

      {/* Timezone select */}
      <TimezoneSelection timezones={timezones} control={control} />

      {/* Available on days checkboxes */}
      <AvailableDays control={control} />

      {/* Discont type */}
      <DiscountType
        control={control}
        discountDetailsItems={discountDetailsItems}
        setDiscountDetailsItems={setDiscountDetailsItems}
        itemAddedNumberTimes={itemAddedNumberTimes}
        setItemAddedNumberTimes={setItemAddedNumberTimes}
        setSelectedSubcategoriesAction={setSelectedSubcategoriesAction}
        selectedSubcategoriesAction={selectedSubcategoriesAction}
        errors={errors}
      />
    </div>
  );
}

export default GeneralSettingsView;
