import { gql } from "@apollo/client";

const LOYALTY_POINT_REASON = gql`
  query LoyaltyPointLog($filter: [loyaltypointreasonFilter]) {
    viewer {
      id
      loyaltypointreasonConnection(filter: $filter) {
        edges {
          node {
            id
            label
            loyaltypointreasonId
            description
          }
        }
      }
    }
  }
`;

export default LOYALTY_POINT_REASON;
