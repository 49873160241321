import React from 'react';
import Events from '../Events';

function AutomaticView({
  control,
  triggers,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  itemInCart,
  setItemInCart,
  errors,
  forEachItem,
  setForEachItem,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
}) {
  return (
    <div>
      <Events
        triggers={triggers}
        control={control}
        cartItemsRequired={cartItemsRequired}
        setCartItemsRequired={setCartItemsRequired}
        itemAddedNumberTimes={itemAddedNumberTimes}
        itemInCart={itemInCart}
        setItemInCart={setItemInCart}
        errors={errors}
        forEachItem={forEachItem}
        setForEachItem={setForEachItem}
        setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
        selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
      />
    </div>
  );
}

export default AutomaticView;
