import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Paper,
  FormControl,
  Grid,
  GridListTile,
  GridList,
  Button,
  TextField,
  Divider,
} from '@material-ui/core';
import { Controller } from "react-hook-form";
import { useQuery } from '@apollo/client';

import { Permission, PermissionField, AutoComplete } from '../../../../../blocks';
import { FETCH_STATES } from '../../../../../../apollo/queries'

import CreateVendorStyles from './styles';
import { paths } from '../../../../../../constants';
import { regexPattern } from '../../../../../../utils';
import ImageUpload from '../../../../../blocks/ImageUpload';
import PhoneNumberInput from '../../../../../blocks/PhoneNumberInput';

const CreateVendorView = ({
  handleSubmit,
  submit,
  control,
  register,
  disableCreateButton,
  setImage,
  image,
  errors,
  IMAGES,
}) => {

  const classes = CreateVendorStyles();

  const { data, loading } = useQuery(FETCH_STATES, {
    variables: {
      first: 100,
    },
  });

  const stateList = useMemo(() => data?.viewer?.stateConnection?.edges?.map(state => ({
    label: state.node.label,
    value: state.node.stateId
  })), [data?.viewer?.stateConnection?.edges]);

  useEffect(() => {
    setImage(
      IMAGES.sizes.map((size) => {
        return {
          size,
        };
      })
    );
  }, []);

  if (loading) return <div>loading...</div>

  return (
    <Paper>
      <Grid container>
        <Grid item md={12}>
          <form onSubmit={handleSubmit(submit)}>
            <GridList
              className={classes.gridList}
              cellHeight={'auto'}
              cols={1}
            >
              <GridListTile>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h1 className={classes.heading}>New Vendor</h1>
                  <div>
                    <Link to={paths.SETTINGS_VENDORS}>
                      <Button
                        disabled={disableCreateButton}
                        variant='outlined'
                        className={classes.cancelBtn}
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button disabled={disableCreateButton} type='submit'>
                      CREATE
                    </Button>
                  </div>
                </div>
              </GridListTile>

              <GridListTile>
                <Grid container className={`${classes.marginBottom} ${classes.marginTop}`}>
                  <Grid container direction='column' item md={8}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='label'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={register({required: true})}
                            name={name}
                            label="Name"
                            fullWidth
                            placeholder='Vendor Name'
                            error={errors?.label}
                            helperText={errors?.label ? 'Label cannot be empty' : errors?.label?.message}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container direction='column' item md={4}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='promoCode'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={ref}
                            fullWidth
                            placeholder='Promo Code'
                            label="Promo Code"
                            type='number'
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container className={classes.marginBottom}>
                  <Grid container direction='column' item md={12}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='orderUrl'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={ref}
                            fullWidth
                            placeholder='Order URL'
                            label="Order URL"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container className={classes.marginBottom}>
                  <Grid container direction='column' item md={4}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='contactName'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={ref}
                            fullWidth
                            placeholder='Contact Name'
                            label="Contact Name"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container direction='column' item md={4}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Permission access={7}>
                        <PermissionField
                          Component={TextField}
                          name="email"
                          label="Email"
                          placeholder={'Email'}
                          inputRef={register({
                            pattern: {
                              value: regexPattern.email,
                              message: "Invalid email address",
                            },
                          })}
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email && "Invalid email address."}
                        />
                      </Permission>
                    </FormControl>
                  </Grid>
                  <Grid container direction='column' item md={4}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Permission access={7}>
                        <PermissionField
                          Component={PhoneNumberInput}
                          label={"Phone"}
                          name="phone"
                          id='phone'
                          fullWidth
                          error={errors.phone}
                          helperText="Phone is not correct."
                          placeholder={'Phone number'}
                          control={control}
                        />
                      </Permission>
                    </FormControl>
                  </Grid>
                </Grid>

                <Divider className={classes.marginBottom} />

                <Grid container className={classes.marginBottom}>
                  <Grid container direction='column' item md={3}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='address1'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={ref}
                            fullWidth
                            placeholder='Address'
                            label="Address"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container direction='column' item md={3}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='address2'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={ref}
                            fullWidth
                            placeholder='Additional address info'
                            label="Additional address"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container direction='column' item md={2}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Permission access={7}>
                        <PermissionField
                          Component={TextField}
                          name="city"
                          label="City"
                          placeholder={'City'}
                          inputRef={register({
                            pattern: {
                              value: regexPattern.onlyText,
                              message: "Invalid city",
                            },
                          })}
                          fullWidth
                          error={!!errors.city}
                          helperText={errors.city && "Invalid city."}
                        />
                      </Permission>
                    </FormControl>
                  </Grid>
                  <Grid container direction='column' item md={2}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='zip'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={ref}
                            label="Zip"
                            fullWidth
                            placeholder='Zip'
                            type={'number'}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container direction='column' item md={2}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Permission access={7}>
                        <PermissionField
                          Component={AutoComplete}
                          name='stateLabel'
                          id={"stateLabel"}
                          getOptionLabel={(option) => option.label}
                          control={control}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label={"State"}
                              variant="outlined"
                            />
                          }
                          options={stateList || []}
                        />
                      </Permission>
                    </FormControl>
                  </Grid>
                </Grid>

                <Divider className={classes.marginBottom} />

                <Grid container className={classes.marginBottom}>
                  <Grid container direction='column' item md={12}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='notes'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            rows={4}
                            multiline
                            onChange={(e) => onChange(e.target.value)}
                            inputRef={ref}
                            fullWidth
                            placeholder='Notes'
                            label="Notes"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Divider className={classes.marginBottom} />

                <Grid container className={classes.marginBottom}>
                  <Grid container direction='column' item md={6}>
                    <FormControl className={classes.nameField} component='fieldset'>
                      <Controller
                        control={control}
                        name='logoImage'
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <ImageUpload
                            images={image}
                            setImages={setImage}
                            size={IMAGES.sizes[0]}
                            maxSize={50*1000}
                            helperText="Required image size is 75 x 75 px in JPG format. File weight in max. 50KB."
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </GridListTile>
            </GridList>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CreateVendorView;
