import { gql } from '@apollo/client';

const CREATE_REPORTING_CATEGORY = gql`
  mutation CREATE_REPORTING_CATEGORY($input: CreateReportcategoryInput!) {
    createReportcategory(input: $input) {
      viewer {
        id
      }
      reportcategory {
        id
      }
    }
  }
`;

export default CREATE_REPORTING_CATEGORY;
