import { gql } from '@apollo/client';

export const CREATE_LOCATION_VENDOR = gql`
  mutation CreateLocationVendor(
    $input: CreateLocationVendorMutationInput!
    ) {
    createLocationVendor(input: $input) {
      viewer {
        id
      }
      locationVendor {
        id
        vendor {
          id
          vendorId
        }
        location {
          id
          locationId
          label
        }
        deliveryUrl
      }
    }
  }
`;
