import { gql } from '@apollo/client';

export const taxRateFragment = {
  taxRate: gql`
    fragment TaxRateData on Taxrate {
      id
      taxrateId
      fraction
      amount
      taxable
      display
      description
      surcharge
      label
      taxrateHandoffConnection {
        edges {
          node {
            handoffId
            handoff {
              id
              label
            }
          }
        }
      }
    }
  `,
};
