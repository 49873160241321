import React from 'react';
import { HomeViewStyles } from './styles';

import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import logo from '../../../../assets/images/logo2.png';

const HomeView = ({ features }) => {
  const classes = HomeViewStyles();

  return (
    <div className={classes.home}>
      <div className={classes.homeInner}>
        <img className={classes.logo} src={logo} alt="Dickey's logo" />
        <h1 className={classes.title}>Welcome to Spark Direct Dashboard!</h1>
        <p className={classes.description}>Do you want to see the list of online and mobile orders for your restaurant?
        Or you maybe need to check information of the upcoming catering event? You can do it easily in Spark Direct Dashboard
        built for Dickey's Barbecue Pit order tracking, employee - customer management and keeping data for use in statistical procedures.</p>
        <h2 className={classes.subtitle}><span>Most Used Features</span></h2>
        <ul className={classes.list}>
          {features.map(feature => (
            <li key={feature.id}>
              <Link component={RouterLink} to={feature.path}>
                <img src={feature.icon} alt={feature.label} />
                {feature.label}
              </Link>
            </li>
          ))}          
        </ul>

      </div>
    </div>
  );
};

export default HomeView;
