import React, { useState } from 'react';
import moment from 'moment-timezone';
import LoyaltySubmissionsView from './View';

function LoyaltySubmissions() {
  const [openDateRangeSelectionModal, setOpenDateRangeSelectionModal] = useState(false);
  const [dateRangeStart, setDateRangeStart] = useState(moment());
  const [dateRangeEnd, setDateRangeEnd] = useState(moment());

  const getSelectedDateRangeValue = variables => {
    const filters = variables.filter;
    const startDate = filters?.dateOfVisit?.gte;
    const endDate = filters?.dateOfVisit?.lte;

    return `${(startDate && moment(startDate)?.format('MM/DD/YYYY')) ||
      'MM/DD/YYYY'} to ${(endDate && moment(endDate)?.format('MM/DD/YYYY')) || 'MM/DD/YYYY'}`;
  };

  const handleDateRangeSelection = ({ refetch, variables }, removeDate = false) => {
    if (removeDate) {
      const filters = variables.filter;
      delete filters.dateOfVisit;
      refetch({
        ...variables,
        filter: filters,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          dateOfVisit: {
            gte: dateRangeStart.format('YYYY-MM-DD 00:00:00'),
            lte: dateRangeEnd.format('YYYY-MM-DD 23:59:59'),
          },
        },
      });
    }
    setOpenDateRangeSelectionModal(false);
  };

  const handleDateRangeStartChange = value => {
    setDateRangeStart(value);
  };
  const handleDateRangeEndChange = value => {
    setDateRangeEnd(value);
  };

  const handleOpenDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(true);
  };
  const handleCloseDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(false);
  };

  return (
    <LoyaltySubmissionsView
      dateRangeStart={dateRangeStart}
      dateRangeEnd={dateRangeEnd}
      handleDateRangeSelection={handleDateRangeSelection}
      openDateRangeSelectionModal={openDateRangeSelectionModal}
      getSelectedDateRangeValue={getSelectedDateRangeValue}
      handleDateRangeStartChange={handleDateRangeStartChange}
      handleDateRangeEndChange={handleDateRangeEndChange}
      handleOpenDateRangeSelectionModal={handleOpenDateRangeSelectionModal}
      handleCloseDateRangeSelectionModal={handleCloseDateRangeSelectionModal}
    />
  );
}

export default LoyaltySubmissions;
