import React, { useEffect, useState } from 'react';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { USERS_BY_USERGROUP } from '../../../../../../apollo/queries';
import UsergroupStyles from '../styles';
import Button from '@material-ui/core/Button';
import { useMutation, useQuery } from '@apollo/client';
import MANIPULATE_USERGROUP_USER from '../../../../../../apollo/mutations/usergroups/manipulateUsergroupUser';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

// This is the raw, unfiltered user list
const FullEmployeeList = ({ usergroupId, tableState, handleCloseModal, preselectedUsers = [] }) => {
  const classes = UsergroupStyles();
  const { setSnackbar } = useSnackbar();
  const [preselected, setPreselected] = useState([]);

  const [manipulateUsergroupUser] = useMutation(MANIPULATE_USERGROUP_USER);
  const { data: usersInUsergroup, loading } = useQuery(USERS_BY_USERGROUP, {
    variables: {
      filter: {
        usergroupIds: { in: [usergroupId] },
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data.viewer.loginConnection.edges.length) {
        setPreselected(
          data.viewer.loginConnection.edges.map(login => ({
            id: login.node.id,
            loginId: login.node.loginId,
          })),
        );
      }
    },
  });

  const handleUsers = selected => {
    const loginIds = [];
    selected.forEach(row => loginIds.push(row.loginId));

    manipulateUsergroupUser({
      variables: {
        usergroupId,
        loginIds,
      },
      refetchQueries: [{ query: USERS_BY_USERGROUP, variables: { ...tableState.variables } }],
    }).then(() => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Users updated',
      });
      handleCloseModal();
    });
  };

  return (
    <>
      <ConnectionTable
        query={USERS_BY_USERGROUP}
        initialQueryVariables={{
          first: 15,
        }}
        preselectedValues={preselected}
        title="Users"
        selectAllCheckbox={false}
        loading={loading}
        structureTable={data =>
          data?.viewer?.loginConnection?.edges.map(user => ({
            id: user.node?.id,
            loginId: user.node?.loginId,
            person: user.node?.person,
            phone: user.node?.person?.phone,
            email: user.node?.email,
            inactive: user.node?.inactive,
          }))
        }
        selectableRows={true}
        columns={[
          { title: 'id', field: 'loginId' },
          {
            title: 'Full name',
            field: 'person',
            render: person =>
              person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : '/',
          },
          {
            title: 'Phone',
            field: 'phone',
            render: phone => phone?.phone || '/',
          },
          { title: 'Email', field: 'email' },
          {
            title: 'Status',
            field: 'inactive',
            render: inactive =>
              inactive ? (
                <span className={classes.inactive}>Inactive</span>
              ) : (
                <span className={classes.active}>Active</span>
              ),
          },
        ]}
        customActionsTop={(data, query, selected) => (
          <Button onClick={() => handleUsers(selected)} variant="outlined">
            Assign selected users
          </Button>
        )}
      />
    </>
  );
};

export default FullEmployeeList;
