import { gql } from '@apollo/client';
import { stateFragment } from '../fragments/stateFragment';

const STATES = gql`
  query states {
    viewer {
      id
      stateConnection {
        edges {
          node {
            ...StateData
          }
        }
      }
    }
  }
  ${stateFragment.state}
`;

export default STATES;
