import { gql } from '@apollo/client';

const WASTE_LOG = gql`
  query wasteLog($id: ID!, $wasteLogDate: String) {
    viewer {
      id
      location(id: $id, fieldGroupOnly: 88) {
        id
        locationId
        storeNumber
        label
        wasteLog(wasteLogDate: $wasteLogDate)
      }
    }
  }
`;

export default WASTE_LOG;
