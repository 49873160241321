import { useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import {
  CREATE_INVENTORY_ITEM_CATEGORY,
  UPDATE_INVENTORY_ITEM_CATEGORY,
} from '../../../../../../../apollo/mutations';
import {
  FETCH_GENERAL_LEDGER_ACCOUNT,
  FETCH_RECIPE_CATEGORY_TYPES,
  FETCH_REPORT_CATEGORY,
} from '../../../../../../../apollo/queries';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { statusOptions } from '../../../../../../../constants/statuses';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import SelectFactory from '../../../../../../blocks/SelectFactory';

const defaultValues = {
  label: '',
  active: '',
  generalLedger: '',
  inventoryitemcategorytype: '',
  reportingCategory: '',
};

const SubcategoryModal = ({ subcategory, handleClose }) => {
  const { register, control, errors, reset, handleSubmit } = useForm();

  const { setSnackbar } = useSnackbar();

  const [createCategory] = useMutation(CREATE_INVENTORY_ITEM_CATEGORY, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Subcategory created',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createInventoryitemcategory?.viewer),
        fields: {
          inventoryitemcategoryConnection() {
            return {
              __typename: 'inventoryitemcategoryEdge',
            };
          },
        },
      });
    },
  });

  const [updateCategory] = useMutation(UPDATE_INVENTORY_ITEM_CATEGORY, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Subcategory updated',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (subcategory?.inventoryitemcategoryId) {
      reset({
        label: subcategory.label,
        active: subcategory.active ? 1 : 0,
        generalLedger: subcategory.generalledgeraccount
          ? {
              id: subcategory.generalledgeraccount.generalLedgerAccountId,
              value: subcategory.generalledgeraccount.generalLedgerAccountId,
              label: subcategory.generalledgeraccount.label,
            }
          : '',
        inventoryitemcategorytype: subcategory.inventoryitemcategorytype
          ? {
              id: subcategory.inventoryitemcategorytype.inventoryitemcategorytypeId,
              value: subcategory.inventoryitemcategorytype.inventoryitemcategorytypeId,
              label: subcategory.inventoryitemcategorytype.label,
            }
          : '',

        reportingCategory: subcategory.reportcategory
          ? {
              id: subcategory.reportcategory.reportcategoryId,
              value: subcategory.reportcategory.reportcategoryId,
              label: subcategory.reportcategory.label,
            }
          : '',
      });
    } else {
      reset(defaultValues);
    }
  }, [subcategory]);

  const onSubmit = useCallback(
    formData => {
      const input = {
        label: formData.label,
        active: !!formData.active,
        inventoryitemcategorytypeId: formData.inventoryitemcategorytype.id,
        generalLedgerAccountId: formData.generalLedger.id,
        reportcategoryId: formData.reportingCategory.id,
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: subcategory?.inventoryitemcategoryId
          ? 'Updating subcategory...'
          : 'Creating subcategory...',
        open: true,
      });

      if (subcategory?.inventoryitemcategoryId) {
        input.inventoryitemcategoryId = subcategory.inventoryitemcategoryId;

        updateCategory({ variables: { input } });
      } else {
        createCategory({ variables: { input } });
      }
    },
    [subcategory?.inventoryitemcategoryId],
  );

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            label="Name"
            name="label"
            variant="outlined"
            placeholder="Name"
            error={errors.label}
            helperText={!!errors.label && 'Name is required'}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.active}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="active"
              name="active"
              label="Status"
              placeholder="Status"
              defaultValue={subcategory?.active ? 1 : 0}
              error={!!errors?.active}
              as={
                <Select label="Status" multiple={false} error={!!errors?.active}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.active?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="inventoryitemcategorytype"
            label="Parent Category"
            control={control}
            rules={{ required: true }}
            render={props => (
              <SelectFactory
                customProps={props}
                defaultValue={
                  subcategory?.inventoryitemcategorytype
                    ? {
                        id: subcategory.inventoryitemcategorytype.inventoryitemcategorytypeId,
                        value: subcategory.inventoryitemcategorytype.inventoryitemcategorytypeId,
                        label: subcategory.inventoryitemcategorytype.label,
                      }
                    : ''
                }
                label="Parent Category"
                fullWidth
                sort={{ label: 'ASC' }}
                placeholder="Search Parent Categories..."
                query={FETCH_RECIPE_CATEGORY_TYPES}
                structureData={data =>
                  data?.viewer?.inventoryitemcategorytypeConnection?.edges?.map(
                    (category, index) => ({
                      value: category.node.inventoryitemcategorytypeId,
                      id: category.node.inventoryitemcategorytypeId,
                      label: category.node.label,
                      index,
                    }),
                  ) || []
                }
                multiple={false}
                renderCheckBox={false}
                helperText={errors.inventoryitemcategorytype && 'Parent Category is required'}
                error={!!errors.inventoryitemcategorytype}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="generalLedger"
            label="General Ledger"
            control={control}
            rules={{ required: true }}
            render={props => (
              <SelectFactory
                customProps={props}
                defaultValue={
                  subcategory?.generalledgeraccount
                    ? {
                        id: subcategory.generalledgeraccount.generalLedgerAccountId,
                        value: subcategory.generalledgeraccount.generalLedgerAccountId,
                        label: subcategory.generalledgeraccount.label,
                      }
                    : ''
                }
                label="General Ledger"
                placeholder="Search General Ledgers..."
                sort={{ label: 'ASC' }}
                query={FETCH_GENERAL_LEDGER_ACCOUNT}
                otherVariables={{
                  fieldGroupOnly: 94,
                }}
                structureData={data =>
                  data?.viewer?.generalledgeraccountConnection?.edges?.map((edge, index) => ({
                    value: edge.node.generalLedgerAccountId,
                    id: edge.node.generalLedgerAccountId,
                    index,
                    label: edge.node.label,
                  }))
                }
                fullWidth
                multiple={false}
                helperText={errors.generalLedger && 'General Ledger is required'}
                error={!!errors.generalLedger}
                renderCheckBox={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="reportingCategory"
            label="Reporting Category"
            control={control}
            rules={{ required: true }}
            render={props => (
              <SelectFactory
                customProps={props}
                defaultValue={
                  subcategory?.reportcategory
                    ? {
                        id: subcategory.reportcategory.reportcategoryId,
                        value: subcategory.reportcategory.reportcategoryId,
                        label: subcategory.reportcategory.label,
                      }
                    : ''
                }
                label="Reporting Category"
                placeholder="Search Reporting Categories..."
                sort={{ label: 'ASC' }}
                query={FETCH_REPORT_CATEGORY}
                otherVariables={{
                  fieldGroupOnly: 94,
                }}
                structureData={data =>
                  data?.viewer?.reportcategoryConnection?.edges?.map((edge, index) => ({
                    value: edge.node.reportcategoryId,
                    id: edge.node.reportcategoryId,
                    index,
                    label: edge.node.label,
                  }))
                }
                fullWidth
                multiple={false}
                helperText={errors.reportingCategory && 'Reporting Category is required'}
                error={!!errors.reportingCategory}
                renderCheckBox={false}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit">
              {subcategory?.inventoryitemcategoryId ? 'Save' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SubcategoryModal;
