import React, { useState } from 'react';
import { Button, TextField, Grid, Container, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { convert, toFixed10 } from '../../../../../../utils';
import PaperMain from '../../../../../blocks/PaperMain';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import WasteLogStyles from './styles';

const WasteLogView = ({
  data,
  calculateWasteLog,
  calculateWasteLogTotal,
  calculateCostSide,
  calculateCostProtein,
  saveWasteLog,
  saveWasteLogLoading,
  variables,
  refetch,
}) => {
  const { setSnackbar } = useSnackbar();
  const [wasteLog, setWasteLog] = useState(calculateWasteLog(data.viewer.location.wasteLog));

  const classes = WasteLogStyles();

  const handleSaveWasteLog = submit => {
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: `Waste Log is ${submit ? 'submitting' : 'saving'}`,
    });

    let saveWasteLogInput = {
      locationId: data.viewer.location.locationId,
      date: variables?.wasteLogDate || DateTime.local().toFormat('yyyy-LL-dd'),
      submit,
      inventoryitems: [
        ...wasteLog.proteins.map(protein => ({
          inventoryitemId: protein.protein.id,
          label: protein.protein.label,
          count: protein.count,
          cost: protein.yieldedCost,
        })),
        ...wasteLog.sides.map(side => ({
          inventoryitemId: side.side.id,
          label: side.side.label,
          count: side.count,
          cost: side.yieldedCost,
        })),
      ],
    };

    if (submit) {
      saveWasteLogInput.proteinTotalCost = wasteLog.wasteLogProteinsTotal.yieldedCost;
      saveWasteLogInput.sideTotalCost = wasteLog.wasteLogSidesTotal.yieldedCost;
      saveWasteLogInput.totalCost = wasteLog.wasteLogTotal.yieldedCost;
    }

    saveWasteLog({
      variables: {
        input: saveWasteLogInput,
      },
    })
      .then(() => {
        if (submit) {
          setWasteLog(prevWasteLog => ({ ...prevWasteLog, isSubmitted: true }));
        }
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: `Waste Log is ${submit ? 'submitted' : 'saved'}`,
        });
      })
      .catch(err =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: `Waste Log failed to ${submit ? 'submit' : 'save'}: ${err}`,
        }),
      );
  };

  return (
    <Container>
      <PaperMain>
        <Typography
          variant="h4"
          component="h2"
          className={classes.titlePadding}
          id="closingInventory"
        >
          Waste Log
        </Typography>
        <Grid container className={classes.heading}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <Grid container className={classes.heading}>
              <Grid item xs={5} className={classes.flexDirection}>
                <DatePicker
                  variant="inline"
                  inputVariant="outlined"
                  format="LL/dd/yyyy"
                  style={{ width: '230px' }}
                  margin="normal"
                  label="Waste Log Date"
                  autoOk={true}
                  onChange={date => {
                    refetch({
                      ...variables,
                      wasteLogDate: date.toFormat('yyyy-LL-dd'),
                    });
                  }}
                  value={variables?.wasteLogDate || DateTime.local()}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container className={classes.heading}>
          <Grid item xs={12} style={{ flexDiretion: 'row' }}>
            <Grid item xs={3} className={classes.flexDirection}>
              <div className={classes.contentInfoHolder}>
                <b>Proteins</b>
              </div>
            </Grid>
            <Grid item xs={2} className={classes.flexDirection}>
              <div className={classes.contentInfoHolder}>
                <b>UOM</b>
              </div>
            </Grid>
            <Grid item xs={2} className={classes.flexDirection}>
              <div className={classes.contentInfoHolder}>
                <b>Count</b>
              </div>
            </Grid>
            <Grid item xs={5} className={classes.flexDirection} style={{ paddingLeft: '1rem' }}>
              <div className={classes.contentInfoHolder}>
                <b>Yielded Cost</b>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.flexDirection}>
            <div className={classes.contentInfoHolder}>
              <Grid container className={classes.heading}>
                {wasteLog.proteins.map((protein, index) => (
                  <React.Fragment key={protein.protein.id}>
                    <Grid
                      item
                      xs={3}
                      className={classes.flexDirection}
                      style={{ marginBottom: '0.5rem' }}
                    >
                      <div className={classes.centerVerticalContent}>{protein.protein.label}</div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={classes.flexDirection}
                      style={{ marginBottom: '0.5rem' }}
                    >
                      <div className={classes.centerVerticalContent}>{protein.protein.measure}</div>
                    </Grid>
                    <Grid item xs={2} style={{ flexDirection: 'row', marginBottom: '0.5rem' }}>
                      <div className={classes.contentInfoHolder}>
                        <TextField
                          name="ciCount"
                          type="number"
                          defaultValue={protein.count}
                          disabled={wasteLog.isSubmitted}
                          variant="outlined"
                          onChange={event => {
                            setWasteLog(prevWasteLog => {
                              const proteins = [...prevWasteLog.proteins];

                              const protein = {
                                ...proteins[index],
                                count: parseFloat(event.target.value),
                              };

                              proteins[index] = {
                                ...protein,
                                yieldedCost: calculateCostProtein(protein),
                              };

                              const wasteLog = {
                                ...prevWasteLog,
                                proteins,
                              };

                              return {
                                ...wasteLog,
                                ...calculateWasteLogTotal(wasteLog),
                              };
                            });
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      className={classes.flexDirection}
                      style={{ marginBottom: '0.5rem', paddingLeft: '1rem' }}
                    >
                      <div className={classes.centerVerticalContent}>
                        <b>{protein.yieldedCost ? `$ ${toFixed10(protein.yieldedCost, 2)}` : ''}</b>
                      </div>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ flexDirection: 'row' }}>
            <Grid item xs={3} className={classes.flexDirection}>
              <div className={classes.contentInfoHolder}>
                <b>Sides</b>
              </div>
            </Grid>
            <Grid item xs={2} className={classes.flexDirection}>
              <div className={classes.contentInfoHolder}>
                <b>UOM</b>
              </div>
            </Grid>
            <Grid item xs={2} className={classes.flexDirection}>
              <div className={classes.contentInfoHolder}>
                <b>Count</b>
              </div>
            </Grid>
            <Grid item xs={5} className={classes.flexDirection} style={{ paddingLeft: '1rem' }}>
              <div className={classes.contentInfoHolder}>
                <b>Cost</b>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.flexDirection}>
            <div className={classes.contentInfoHolder}>
              <Grid container className={classes.heading}>
                {wasteLog.sides.map((side, index) => (
                  <React.Fragment key={side.side.id}>
                    <Grid
                      item
                      xs={3}
                      className={classes.flexDirection}
                      style={{ marginBottom: '0.5rem' }}
                    >
                      <div className={classes.centerVerticalContent}>{side.side.label}</div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={classes.flexDirection}
                      style={{ marginBottom: '0.5rem' }}
                    >
                      <div className={classes.centerVerticalContent}>{side.side.measure}</div>
                    </Grid>
                    <Grid item xs={2} style={{ flexDirection: 'row', marginBottom: '0.5rem' }}>
                      <div className={classes.contentInfoHolder}>
                        <TextField
                          name="ciCount"
                          type="number"
                          defaultValue={side.count}
                          disabled={wasteLog.isSubmitted}
                          variant="outlined"
                          onChange={event => {
                            setWasteLog(prevWasteLog => {
                              const sides = [...prevWasteLog.sides];

                              const side = {
                                ...sides[index],
                                count: parseFloat(event.target.value),
                              };

                              sides[index] = {
                                ...side,
                                yieldedCost: calculateCostSide(side),
                              };

                              const wasteLog = {
                                ...prevWasteLog,
                                sides,
                              };

                              return {
                                ...wasteLog,
                                ...calculateWasteLogTotal(wasteLog),
                              };
                            });
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      className={classes.flexDirection}
                      style={{ marginBottom: '0.5rem', paddingLeft: '1rem' }}
                    >
                      <div className={classes.centerVerticalContent}>
                        <b>{side.yieldedCost ? `$ ${toFixed10(side.yieldedCost, 2)}` : ''}</b>
                      </div>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid container spacing={4} className={classes.heading}>
            <Grid item xs={3} className={classes.flexDirection}>
              <div>
                <b>Protein Waste Total:</b>
              </div>
            </Grid>
            <Grid item xs={7} className={classes.flexDirection}>
              <div>{`$ ${toFixed10(wasteLog.wasteLogProteinsTotal.yieldedCost, 2)}`}</div>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.heading}>
            <Grid item xs={3} className={classes.flexDirection}>
              <div>
                <b>Side Waste Total:</b>
              </div>
            </Grid>
            <Grid item xs={7} className={classes.flexDirection}>
              <div>{`$ ${toFixed10(wasteLog.wasteLogSidesTotal.yieldedCost, 2)}`}</div>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.heading}>
            <Grid item xs={3} className={classes.flexDirection}>
              <div>
                <b>Total:</b>
              </div>
            </Grid>
            <Grid item xs={7} className={classes.flexDirection}>
              <div>{`$ ${toFixed10(wasteLog.wasteLogTotal.yieldedCost, 2)}`}</div>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                disabled={saveWasteLogLoading || wasteLog.isSubmitted}
                onClick={() => {
                  handleSaveWasteLog(0);
                }}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={saveWasteLogLoading || wasteLog.isSubmitted}
                onClick={() => {
                  handleSaveWasteLog(1);
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PaperMain>
    </Container>
  );
};

export default WasteLogView;
