import { Button, FormControl, Grid, IconButton, TextField } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import React, { useState } from 'react';
import Modal from '../Modal';
import { get, omit, set } from 'lodash';

// @fieldName - filter name
const DateRange = ({ refetch, variables, fieldName, onDateChange, disableDelete }) => {
  const getSelectedDateRangeValue = (field, variables) => {
    const filters = variables.filter;
    const startDate = get(filters, `${field}.gte`);
    const endDate = get(filters, `${field}.lte`);

    return `${(startDate && moment(startDate)?.format('MM/DD/YYYY')) ||
      'MM/DD/YYYY'} to ${(endDate && moment(endDate)?.format('MM/DD/YYYY')) || 'MM/DD/YYYY'}`;
  };

  const dateRangeValue = getSelectedDateRangeValue(fieldName, variables);

  const [dateRangeStart, setDateRangeStart] = useState(
    moment(get(variables, `filter.${fieldName}.gte`)),
  );
  const [dateRangeEnd, setDateRangeEnd] = useState(
    moment(get(variables, `filter.${fieldName}.lte`)),
  );

  const handleDateRangeStartChange = value => {
    setDateRangeStart(value);
  };
  const handleDateRangeEndChange = value => {
    setDateRangeEnd(value);
  };

  const [openDateRangeSelectionModal, setOpenDateRangeSelectionModal] = useState(false);

  const handleOpenDateRangeSelectionModal = field => {
    setOpenDateRangeSelectionModal(field);
  };
  const handleCloseDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(false);
  };

  const handleDateRangeSelection = removeDate => {
    if (removeDate) {
      const filters = variables.filter;

      if (refetch) {
        refetch({
          ...variables,
          filter: omit(filters, [fieldName]),
        });
      }
    } else {
      // need only date
      const extractedDateStart = dateRangeStart.format('YYYY-MM-DD');
      const extractedDateEnd = dateRangeEnd.format('YYYY-MM-DD');
      if (onDateChange) {
        onDateChange({ gte: extractedDateStart, lte: extractedDateEnd });
      }
      // convert date to utc
      const startDateConvertedToUTC = moment(extractedDateStart).utc();
      const endDateConvertedToUTC = moment(extractedDateEnd)
        .utc()
        .add(23, 'hours')
        .add('59', 'minutes')
        .add(59, 'seconds');
      if (refetch) {
        const refetchInput = {
          ...variables,
        };

        set(refetchInput, `filter.${fieldName}`, {
          gte: startDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss'),
          lte: endDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss'),
        });

        refetch(refetchInput);
      }
    }
    setOpenDateRangeSelectionModal(false);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Grid container spacing={1}>
        <Grid item style={{ flexGrow: 1 }}>
          <TextField
            role="button"
            style={{ padding: 0, width: '100%' }}
            onClick={handleOpenDateRangeSelectionModal}
            label="Select Date Range"
            value={dateRangeValue || 'MM/DD/YYYY to MM/DD/YYYY'}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            inputProps={{
              style: {
                cursor: 'pointer',
              },
            }}
            variant="outlined"
          />
        </Grid>

        {!disableDelete && dateRangeValue !== 'MM/DD/YYYY to MM/DD/YYYY' && (
          <Grid item>
            <IconButton onClick={() => handleDateRangeSelection(true)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Modal
        open={openDateRangeSelectionModal}
        handleClose={handleCloseDateRangeSelectionModal}
        title="Select Date Range"
      >
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                margin="normal"
                label="Date range start"
                value={dateRangeStart}
                onChange={handleDateRangeStartChange}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                margin="normal"
                label="Date range end"
                value={dateRangeEnd}
                onChange={handleDateRangeEndChange}
              />
            </Grid>
            <Grid item xs={12} sm={2} justify="flex-end">
              <Button onClick={() => handleDateRangeSelection(false)}>Apply</Button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Modal>
    </FormControl>
  );
};

export default DateRange;
