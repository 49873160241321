export const MYSTERY_REWARD_TYPE = {
  item: 'item',
  order: 'order',
  subcategory: 'subcategory',
  handoff: 'handoff',
};

export const COUPON_TYPE = {
  amount: 'amount',
  percentage: 'percentage',
  free: 'free',
};

export const COUPON_ACTION = {
  orderAmount: 1,
  orderPercentage: 2,
  itemAmount: 3,
  itemPercentage: 4,
  subcategoryAmount: 5,
  subcategoryPercentage: 6,
  handoffAmount: 7,
  handoffPercentage: 8,
  itemFree: 9,
};

export const COUPON_ACTION_MAP = {
  [COUPON_ACTION.itemAmount]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.item,
    couponType: COUPON_TYPE.amount,
  },
  [COUPON_ACTION.itemPercentage]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.item,
    couponType: COUPON_TYPE.percentage,
  },
  [COUPON_ACTION.subcategoryAmount]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.subcategory,
    couponType: COUPON_TYPE.amount,
  },
  [COUPON_ACTION.subcategoryPercentage]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.subcategory,
    couponType: COUPON_TYPE.percentage,
  },
  [COUPON_ACTION.orderAmount]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.order,
    couponType: COUPON_TYPE.amount,
  },
  [COUPON_ACTION.orderPercentage]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.order,
    couponType: COUPON_TYPE.percentage,
  },
  [COUPON_ACTION.handoffAmount]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.handoff,
    couponType: COUPON_TYPE.amount,
  },
  [COUPON_ACTION.handoffPercentage]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.handoff,
    couponType: COUPON_TYPE.percentage,
  },
  [COUPON_ACTION.itemFree]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.item,
    couponType: COUPON_TYPE.free,
  },
  [COUPON_ACTION.handoffAmount]: {
    mysteryRewardType: MYSTERY_REWARD_TYPE.handoff,
    couponType: COUPON_TYPE.amount,
  },
};

export const MYSTERY_COUPON_TYPE_ID = 7;

export const IMAGE = {
  sizes: ['490x350'],
};

export const PLATFORM_ID = {
  WEBSITE: 1,
  MOBILE: 2,
};
