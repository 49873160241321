import { gql } from '@apollo/client';

const REMOVE_LOCATION_EXCLUSIVE_DELIVERY_ADDRESS = gql`
  mutation RemoveLocationExclusiveDeliveryAddress(
    $input: RemoveLocationExclusiveDeliveryAddressMutationInput!
  ) {
    removeLocationExclusiveDeliveryAddress(input: $input) {
      viewer {
        id
      }
      removedLocationExclusiveDeliveryAddressId
    }
  }
`;

export default REMOVE_LOCATION_EXCLUSIVE_DELIVERY_ADDRESS;
