const IMAGE = {
  sizes: ['75x75']
};

export const setImageToFetchedImage = (images, fetchedImage) => {
  const image = {
    name: '',
    description: fetchedImage?.description || '',
    encoded: '',
    size: fetchedImage?.label || IMAGE.sizes[0],
    url: fetchedImage?.url || '',
  };

  images[0] = image;
  return [...images];
};