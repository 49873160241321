import React, { useMemo, useCallback } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import SelectMUI from '../Select';
import { SelectFactory, Elements } from '..';
import { BRANDS } from '../../../apollo/queries/brands';
import { LOCATIONS, STATES } from '../../../apollo/queries';
import { ORIGINS, HANDOFFS } from '../../../apollo/queries/filters';
import FETCH_TIERS from '../../../apollo/queries/menus/fetchTiers';
import { pad } from '../../../utils/index';
import { CustomerGroupFiltersStyles } from './styles';

const ORDER_VALUE_RANGE = [
  { label: '$0 - $50', min: 0, max: 5000, value: 0 },
  { label: '$50 - $100', min: 5000, max: 10000, value: 1 },
  { label: '$100 - $200', min: 10000, max: 20000, value: 2 },
  { label: '$200 and above', min: 20000, max: 9999999, value: 3 },
];

const CustomerGroupFilters = ({
  control,
  errors,
  customerGroupFilters,
  setCustomerGroupFilters,
}) => {
  const classes = CustomerGroupFiltersStyles();

  const { data: brandData } = useQuery(BRANDS);
  const brandList = useMemo(() => brandData?.viewer?.brandConnection || [], [brandData]);
  let selectedBrandList = useMemo(() => [], []);

  const { data: tierData } = useQuery(FETCH_TIERS);
  const tierList = useMemo(() => tierData?.viewer?.tierConnection || [], [tierData]);
  let selectedTierList = useMemo(() => [], []);

  const handleOnStartDateChange = useCallback(
    startDate => setCustomerGroupFilters({ ...customerGroupFilters, startDate }),
    [customerGroupFilters],
  );
  const handleOnEndDateChange = useCallback(
    endDate => setCustomerGroupFilters({ ...customerGroupFilters, endDate }),
    [customerGroupFilters],
  );

  const handleBrandChange = useCallback(
    e => {
      if (e.target.checked) {
        selectedBrandList.push(+e.target.value);
      } else {
        selectedBrandList = selectedBrandList.filter(brand => brand !== +e.target.value);
      }

      setCustomerGroupFilters({ ...customerGroupFilters, brandIds: selectedBrandList });
    },
    [customerGroupFilters, selectedBrandList],
  );

  const handleTierChange = useCallback(
    e => {
      if (e.target.checked) {
        selectedTierList.push(+e.target.value);
      } else {
        selectedTierList = selectedTierList.filter(tier => tier !== +e.target.value);
      }

      setCustomerGroupFilters({ ...customerGroupFilters, tierIds: selectedTierList });
    },
    [customerGroupFilters, selectedTierList],
  );

  const handleOrderValueChange = useCallback(
    e => {
      const { min, max } = ORDER_VALUE_RANGE[e.target.value];

      setCustomerGroupFilters({ ...customerGroupFilters, orderValueRange: { min, max } });
    },
    [customerGroupFilters, ORDER_VALUE_RANGE],
  );

  const locationSelectFilter = useMemo(() => {
    let filter = {
      active: { null: false },
    };

    if (customerGroupFilters?.stateIds) {
      filter = {
        ...filter,
        address: {
          state: {
            stateId: {
              in: customerGroupFilters.stateIds,
            },
          },
        },
      };
    }

    if (customerGroupFilters?.brandIds) {
      filter = {
        ...filter,
        brand: {
          brandId: {
            in: customerGroupFilters.brandIds,
          },
        },
      };
    }

    return filter;
  }, [customerGroupFilters]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Applying these filters to all orders, you will get a list of customers whose orders
          satisfy these conditions.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={6}>
          <Elements.DatePicker
            control={control}
            label="Orders made after"
            name="startDate"
            format={'yyyy-MM-dd'}
            shouldDisableDate={date => {
              if (customerGroupFilters?.endDate) {
                return moment(date) < moment(customerGroupFilters.endDate).subtract(1, 'month');
              }
            }}
            onDateChange={handleOnStartDateChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Elements.DatePicker
            control={control}
            label="Orders made before"
            name="endDate"
            format={'yyyy-MM-dd'}
            shouldDisableDate={date => {
              if (customerGroupFilters?.startDate) {
                return moment(date) > moment(customerGroupFilters.startDate).add(1, 'month');
              }
            }}
            onDateChange={handleOnEndDateChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SelectFactory
          label={'Orders made at locations'}
          fullWidth
          placeholder={'Select Location'}
          query={LOCATIONS}
          disableCloseOnSelect={true}
          renderCheckBox
          control={control}
          filter={locationSelectFilter}
          fetchOnEachOpen={true}
          fetchPolicy="network-only"
          structureData={data => {
            return data?.viewer?.locationConnection?.edges.map((location, index) => {
              return {
                label: `${location.node.address?.state?.abbreviation}-${pad(
                  location?.node?.storeNumber,
                  4,
                )} ${location?.node?.label}`,
                id: location?.node?.id,
                locationId: location?.node?.locationId,
                index,
              };
            });
          }}
          onSelect={value => {
            setCustomerGroupFilters({
              ...customerGroupFilters,
              locationIds: value.map(location => location.locationId),
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormLabel component="legend">Orders made from brands</FormLabel>
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        {brandList?.edges?.map(brand => (
          <FormControlLabel
            key={brand.node.brandId}
            control={<Checkbox onChange={handleBrandChange} value={brand.node.brandId} />}
            label={brand.node.label}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={6} className={classes.twoColumnField}>
          <SelectFactory
            id="origins"
            name="origins"
            label={'Orders made through channels'}
            placeholder={'Channels'}
            query={ORIGINS}
            error={!!errors?.origins}
            disableCloseOnSelect={true}
            structureData={data => {
              return data?.viewer?.originConnection?.edges.map((origin, index) => {
                return {
                  id: origin?.node?.originId,
                  label: origin?.node?.label,
                  index,
                };
              });
            }}
            onSelect={value => {
              setCustomerGroupFilters({
                ...customerGroupFilters,
                originIds: value.map(origin => origin.id),
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectMUI
            items={ORDER_VALUE_RANGE}
            id={'orderRange'}
            label={'Value of order before tip'}
            variant="outlined"
            control={control}
            width={'100%'}
            onValueChange={handleOrderValueChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={6} className={classes.twoColumnField}>
          <SelectFactory
            id="state"
            name="state"
            label={'States in which the order was made'}
            placeholder={'States'}
            query={STATES}
            error={!!errors?.states}
            disableCloseOnSelect={true}
            structureData={data => {
              return (
                data?.viewer?.stateConnection?.edges.map((state, index) => {
                  return {
                    id: state?.node?.stateId,
                    label: state?.node?.label,
                    index,
                  };
                }) || []
              );
            }}
            onSelect={value => {
              setCustomerGroupFilters({
                ...customerGroupFilters,
                stateIds: value.map(state => state.id),
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectFactory
            id="handoff"
            name="handoff"
            label={'Orders made with handoffs'}
            placeholder={'Handoff'}
            query={HANDOFFS}
            error={!!errors?.handoffs}
            disableCloseOnSelect={true}
            structureData={data => {
              return data?.viewer?.handoffConnection?.edges.map((handoff, index) => {
                return {
                  id: handoff?.node?.handoffId,
                  label: handoff?.node?.label,
                  index,
                };
              });
            }}
            onSelect={value => {
              setCustomerGroupFilters({
                ...customerGroupFilters,
                handoffIds: value.map(handoff => handoff.id),
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormLabel component="legend">Orders made in locations tiers</FormLabel>
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        {tierList?.edges?.map(tier => (
          <FormControlLabel
            key={tier.node.tierId}
            control={<Checkbox onChange={handleTierChange} value={tier.node.tierId} />}
            label={tier.node.label}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default CustomerGroupFilters;
