import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import {
  Container,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ImageUpload from '../../../../../../../blocks/ImageUpload';
import GeneralInfoInput from '../../GeneralInfoInput';
import { CREATE_SUBCATEGORY } from '../../../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';

const IMAGE = {
  sizes: ['375x268'],
};

const CreateSubcategory = ({ ids, basePath, classes, refetchMenuList }) => {
  const { setSnackbar } = useSnackbar();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    setImages(
      IMAGE.sizes.map((size) => {
        return {
          size,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find((image) => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [createSubategory] = useMutation(CREATE_SUBCATEGORY);

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      label: '',
      description: '',
      isAvailableOnline: false,
    },
  });

  const handleClose = () => {
    setImages([]);
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const { gqlMenuId, menuId, gqlCategoryId, categoryId } = ids;

  const onSubmit = async (data) => {
    setButtonDisabled(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Creating subcategory...',
    });

    const imagesToUpload = images.map((image) => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    const response = await createSubategory({
      variables: {
        input: {
          menuId: gqlMenuId,
          categoryId: gqlCategoryId,
          label: data.label,
          description: data.description,
          availableOnline: data.isAvailableOnline,
          isAvailablePos: data.isAvailablePos,
          isAvailable3pv: data.isAvailable3pv,
          images: imagesToUpload,
        },
      },
    });

    await refetchMenuList();

    setSnackbar({
      open: true,
      type: 'success',
      text: 'Subcategory created!',
    });

    setRedirectUrl(
      `${basePath}/menus/${menuId}/categories/${categoryId}/subcategories/${response.data.createSubcategory.subcategoryEdge.node.subcategoryId}`
    );
    setButtonDisabled(false);
  };

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Button disabled={buttonDisabled} type="submit">
              Create Subcategory
            </Button>
          </Grid>
        </Grid>
        <GeneralInfoInput
          imageUrl={previewImage}
          setOpen={setOpen}
          register={register}
          errors={errors}
        />
        <Grid item xs={4}>
          <FormControl component="fieldset">
            <Typography gutterBottom variant="h6" component="h2">
              Display On
            </Typography>
            <FormControlLabel
              label="Is Available on OLO"
              control={
                <Controller
                  name="isAvailableOnline"
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                      className={classes.switch}
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Is Available on POS"
              control={
                <Controller
                  name="isAvailablePos"
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                      className={classes.switch}
                    />
                  )}
                />
              }
            />
            <FormControlLabel
              label="Is Available on 3PV"
              control={
                <Controller
                  name="isAvailable3pv"
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                      className={classes.switch}
                    />
                  )}
                />
              }
            />
          </FormControl>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Subcategory Image</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ImageUpload
                  images={images}
                  setImages={setImages}
                  size={IMAGE.sizes[0]}
                  maxSize={50 * 1000}
                  helperText="Required image size is 375 x 268 px in JPG format. File weight in max. 50KB."
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

CreateSubcategory.propTypes = {
  ids: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CreateSubcategory;
