import { gql } from '@apollo/client';

const SAVE_SSPM = gql`
  mutation saveSSPM($input: SaveSSPMMutationInput!) {
    saveSSPM(input: $input) {
      clientMutationId
    }
  }
`;

export default SAVE_SSPM;
