import React from 'react';
import { Link } from 'react-router-dom';
import {
  Paper,
  Grid,
  GridListTile,
  GridList,
  Button,
  Typography,
  Divider,
  Link as MuiLink
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { paths, COLOR } from '../../../../../../../constants';
import GeneralInfoStyles from './styles';

const VendorGeneralInfo = ({
  vendor,
  vendorLoading,
  handleOpenModal,
}) => {

  const classes = GeneralInfoStyles();

  return (
    <Paper>
      <Grid container>
        <Grid item md={12}>
          <GridList
            className={classes.gridList}
            cellHeight={'auto'}
            cols={1}
          >
            <GridListTile>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h1>{vendor?.label}</h1>
                <div>
                  <Link to={paths.SETTINGS_VENDORS}>
                    <Button 
                      variant='outlined'
                      style={{margin: '0 1rem'}}
                      disabled={vendorLoading}
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button type='submit' onClick={handleOpenModal}>
                    Edit
                  </Button>
                </div>
              </div>
            </GridListTile>

            <GridListTile>
              <Grid container className={classes.marginBottom}>
                <Grid container direction='column' item md={4}>
                  <Typography variant="subtitle2" component="p" gutterBottom >
                    Order URL
                  </Typography>
                  <MuiLink
                    component='a'
                    variant='body2'
                    href={vendor?.orderUrl && vendor?.orderUrl?.includes('http') ?
                      vendor?.orderUrl?.trim() :
                      `//${vendor?.orderUrl?.trim()}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {vendor?.orderUrl}
                  </MuiLink>
                </Grid>
                <Grid container direction='column' item md={4}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Promo Code
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.promoCode}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.marginBottom}>
                <Grid container direction='column' item md={4}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Contact Name
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.contactName}
                  </Typography>
                </Grid>
                <Grid container direction='column' item md={4}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Email
                  </Typography>
                  <Typography variant="body2" component="p" style={{color: COLOR.INFO}}  >
                    {vendor?.email}
                  </Typography>
                </Grid>
                <Grid container direction='column' item md={4}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Phone
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.phone?.phone}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container className={classes.marginBottom}>
                <Grid container direction='column' item md={3}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Address
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.address?.address}
                  </Typography>
                </Grid>
                <Grid container direction='column' item md={3}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Additional Address
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.address?.additionalInfo}
                  </Typography>
                </Grid>
                <Grid container direction='column' item md={2}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    City
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.address?.city}
                  </Typography>
                </Grid>
                <Grid container direction='column' item md={2}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Zip
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.address?.zip}
                  </Typography>
                </Grid>
                <Grid container direction='column' item md={2}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    State
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.address?.state?.label}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container className={classes.marginBottom}>
                <Grid container direction='column' item md={12}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Notes
                  </Typography>
                  <Typography variant="body2" component="p">
                    {vendor?.notes}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container className={classes.marginBottom}>
                <Grid container direction='column' item md={2}>
                  <Typography variant="subtitle2" component="p" gutterBottom>
                    Logo
                  </Typography>
                  {vendor?.vendorimage?.url ? (
                    <img
                      src={vendor?.vendorimage?.url}
                      alt=""
                      className={classes.logoImg}
                    />
                  ) : (
                    <ImageIcon
                      style={{ fontSize: 80, margin: 'auto' }}
                    />
                  )}
                </Grid>
              </Grid>
            </GridListTile>
          </GridList>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VendorGeneralInfo;
