import React, { useCallback, useEffect, useState } from "react";
import {TextField, InputAdornment, IconButton, Typography} from "@material-ui/core";
import { DeleteOutline } from '@material-ui/icons'

const TextFieldInput = ({ name, hasRemove, info, inputProps = {}, fullWidth, defaultValue, rules, label, helperText, placeHolder, disabled, onChange, register, setValue, style, error, value, type, isFloat, ...rest }) => {
  const [fieldValue, setFieldValue] = useState(defaultValue)

  useEffect(() => {
    setValue(name, defaultValue);
  }, [name, defaultValue, setValue]);

  const handleRemove = useCallback(() => {
    register(name, rules);
    setValue(name, null);
    setFieldValue(null);
  }, [name, setValue, register, rules])

  return (
    <TextField
      {...rest}
      label={label}
      fullWidth={fullWidth}
      type={type}
      value={fieldValue || ''}
      placeholder={placeHolder}
      error={error}
      onFocus={(e) => {
        register(name, rules);
        setValue(name, e.target.value ? (isFloat ? parseFloat(e.target.value) : parseInt(e.target.value)) : undefined);
      }}
      inputProps={inputProps}
      helperText={helperText}
      onChange={e => {
        setFieldValue(e.target.value || undefined);
        setValue(name, e.target.value ? (isFloat ? parseFloat(e.target.value) : parseInt(e.target.value)) : undefined);
        onChange && onChange(e);
      }}
      style={style}
      variant="outlined"
      disabled={disabled}
      InputProps={hasRemove ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="Remove"
              onClick={handleRemove}
            >
              <DeleteOutline fontSize="small"/>
            </IconButton>
          </InputAdornment>
        ),
         
          startAdornment: fieldValue === null ?  <InputAdornment position="start"><Typography variant="caption">Reset</Typography></InputAdornment> : null,
      } : undefined}
    />
  );
};

export default TextFieldInput;
