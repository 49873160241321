import { gql } from "@apollo/client";

const REMOVE_QUOTECATERING = gql`
  mutation removeQuotecatering($quotecateringId: ID!) {
    removeQuotecatering(input: { quotecateringId: $quotecateringId }) {
      viewer {
        id
      }
    }
  }
`;
export default REMOVE_QUOTECATERING;