import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';

function CreateUpdateNutrientModalView({
  handleClose,
  nutrient,
  measures,
  handleSubmit,
  register,
  control,
  buttonDisabled,
  submit,
}) {
  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        {nutrient && (
          <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '2rem' }}>
              <div style={{ flexGrow: '1' }}>
                <TextField
                  fullWidth
                  inputRef={register}
                  name="label"
                  type="text"
                  label="Nutrient Name"
                />
              </div>
              <div style={{ flexGrow: '1' }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel name="measureId" id="demo-simple-select-outlined-label">
                    Unit Type
                  </InputLabel>
                  <Controller
                    as={
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Unit Type"
                      >
                        <MenuItem value={nutrient?.measure?.id}>
                          {nutrient?.measure?.label}
                        </MenuItem>
                        {measures?.edges?.map(edge => (
                          <MenuItem key={edge?.node?.id} value={edge?.node?.id}>
                            {edge?.node?.label} ({edge?.node?.abbreviation})
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name="measureId"
                    control={control}
                  />
                </FormControl>
              </div>
            </div>
            <div>
              <TextField
                fullWidth
                inputRef={register}
                name="description"
                type="text"
                multiline
                rows={3}
                label="Description"
              />
            </div>
          </div>
        )}
        {!nutrient && (
          <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '2rem' }}>
              <div style={{ flexGrow: '1' }}>
                <TextField
                  fullWidth
                  inputRef={register}
                  name="label"
                  type="text"
                  label="Nutrient Name"
                />
              </div>
              <div style={{ flexGrow: '1' }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel name="measureId" id="demo-simple-select-outlined-label">
                    Unit Type
                  </InputLabel>
                  <Controller
                    as={
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Unit Type"
                      >
                        {measures?.edges?.map(edge => (
                          <MenuItem key={edge?.node?.id} value={edge?.node?.id}>
                            {edge?.node?.label} ({edge?.node?.abbreviation})
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name="measureId"
                    control={control}
                  />
                </FormControl>
              </div>
            </div>
            <div>
              <TextField
                fullWidth
                inputRef={register}
                name="description"
                type="text"
                multiline
                rows={3}
                label="Description"
              />
            </div>
          </div>
        )}
        <DialogActions>
          <Button disabled={buttonDisabled} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={buttonDisabled} type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </>
  );
}

CreateUpdateNutrientModalView.propTypes = {
  nutrient: PropTypes.object,
  measures: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  control: PropTypes.object,
  register: PropTypes.func,
  submit: PropTypes.func,
};

export default CreateUpdateNutrientModalView;
