import React, { useEffect, useState, useMemo } from "react";
import { Container, Grid, Button, Divider } from "@material-ui/core";
import SelectMUI from "../../../blocks/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useLazyQuery } from "@apollo/client";
import SUBCATEGORY_CATERING from "../../../../apollo/queries/caterings/subcategoryFetch";
import ItemSelect from "./ItemSelect";
import REMOVE_ORDER_ITEM from "../../../../apollo/mutations/quotecatering/removeOrderItem";
import { ConfirmationModal, Permission, PermissionField } from "../../../blocks";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));


const OrderItems = ({ 
  quote, 
  errors,
  setValue,
  id,
  setOrderItemsList,
  line,
  locationId,
  refetch,
  containerId,
  control,
  register,
  noLabel = false,
  lineNumber,
  setOrderItemLineId,
  removeOrderItemPermission, 
}) => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();
  
  const [itemId, setItemId] = useState();
  //Track previous locationId if location is changed
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    line && setOrderItemLineId(prevState => ({ ...prevState, [`${lineNumber}-selected-lineId`]: line?.id }));
  }, [line, setOrderItemLineId, lineNumber]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [removeLine] = useMutation(REMOVE_ORDER_ITEM);

  const [fetchSubcategories, { data, loading }] = useLazyQuery(SUBCATEGORY_CATERING);

  useEffect(() => {
    if(locationId?.menuId) {
      fetchSubcategories({
        variables: {
          filter: {
            categoryId: {
              eq: 3
            },
            menuId: {
              eq: locationId?.menuId
            },
            showUnavailableOnline: {
              eq: true
            }
          }
        },
        nextFetchPolicy: "cache-first",
        fetchPolicy: "cache-first",
      })
    }
  }, [locationId?.menuId, fetchSubcategories])

  const subCategoryValues = useMemo(() => data?.viewer?.subcategoryConnection?.edges?.map(subcategory => ({
    label: subcategory?.node?.label,
    value: subcategory?.node?.subcategoryId
  })) || [], [data?.viewer?.subcategoryConnection?.edges]);

  useEffect(() => {
    if (line?.subcategory?.subcategoryId && subCategoryValues.length) {
      setSelectedSubcategory(line?.subcategory?.subcategoryId)
    }
  }, [line?.subcategory?.subcategoryId, subCategoryValues])

  if (loading) return <div>Loading</div>;

  const removeOrderItem = () => {
    line && removeLine({
      variables: {
        input: {
          quoteId: quote?.id,
          lineId: line?.id
        }
      }
    }).then(r => {
      refetch({
        id: r?.data?.removeOrderItem?.quote?.id
      });
      setSnackbar({
        open: true,
        type: "success",
        text: "Quote is updated successfully."
      });
    });
    
    setOrderItemsList(prevState => prevState.filter(el => el.id !== id));
    setOrderItemLineId(prevState => {
      if (prevState[`${lineNumber}-selected-lineId`] === line?.id) delete prevState[`${lineNumber}-selected-lineId`]
      return prevState
    });
  };

  return (
    <Container id={containerId} style={{ marginTop: "2rem", marginBottom: "1rem" }}>
      {!noLabel && (<h2 className={classes.title}>Order Items</h2>)}
      {noLabel && (<Divider/>)}
      <Grid container xs={12} spacing={2}>
        <Permission access={quote?.permissions?.lineConnection?.edges?.node?.subcategory?.__typename}>
          <Grid item xs={9} lg={10}>
            {data && !line && (
              <PermissionField
                Component={SelectMUI}
                items={subCategoryValues}
                id={`${lineNumber}-selected-subcategoryId`}
                rules={{required: true}}
                error={errors && errors[`${lineNumber}-selected-subcategoryId`]}
                helperText={errors && errors[`${lineNumber}-selected-subcategoryId`] && 'Subcategory is required.'}
                label={"Subcategory"}
                onValueChange={() => setItemId()}
                defaultValue={line?.subcategory?.subcategoryId || ""}
                disabled={!locationId}
                variant="outlined"
                control={control}
                setSelected={setSelectedSubcategory}
                width={"100%"}
              />
            )}
          </Grid>
        </Permission>
      </Grid>
      <br/><br/>
      {selectedSubcategory && locationId && (
        <ItemSelect
          subcategoryId={selectedSubcategory}
          itemId={itemId}
          quote={quote}
          handleOpen={handleOpen}
          setItemId={setItemId}
          setOrderItemsList={setOrderItemsList}
          key={`itemSelect-${id}`}
          locationId={locationId}
          id={id}
          line={line}
          errors={errors}
          setValue={setValue}
          lineNumber={lineNumber}
          control={control}
          register={register}
          removeOrderItemPermission={removeOrderItemPermission}
        />
      )}
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        confirmAction={() => removeOrderItem()}
      />
    </Container>
  );
};

export default OrderItems;
