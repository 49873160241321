import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import { Button } from '@material-ui/core';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { ALLERGENS_LIST } from '../../../../../../apollo/queries';
import { REMOVE_ALLERGEN } from '../../../../../../apollo/mutations';
import CreateUpdateAllergenModal from '../../Modals/CreateUpdateAllergen';
import { Modal } from '../../../../../blocks';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
function AllergensTable() {
  const { setSnackbar } = useSnackbar();

  const [allergenId, setAllergenId] = useState(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({ variables: null });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAllergenId(null);
    setOpen(false);
  };

  const [removeAllergen, { loading: isDeletinghAllergen }] = useMutation(REMOVE_ALLERGEN, {
    onCompleted: () => {
      setAllergenId(null);
    },
    refetchQueries: [{ query: ALLERGENS_LIST }],
  });

  const onDeleteConfirmationClick = (allergen, variables) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Removing allergen...',
    });

    removeAllergen({
      variables: { input: { allergenId: allergen.rawId } },
      refetchQueries: [{ query: ALLERGENS_LIST, variables: variables }],
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Allergen removed',
        }),
      )
      .catch(error => {
        setSnackbar({
          open: true,
          type: 'error',
          text: error.message,
        });
      });
  };

  return (
    <div id="allergens">
      <ConnectionTable
        query={ALLERGENS_LIST}
        title="Allergens"
        customActionsTop={(data, query) => {
          return (
            <Button
              onClick={() => {
                setOpen(true);
                setQuery(query);
              }}
              disabled={isDeletinghAllergen}
            >
              New Allergen
            </Button>
          );
        }}
        onTableRowClick={(row, data, variables) => {
          setAllergenId(row.rawId);
          setOpen(true);
          setQuery({ query: variables });
        }}
        structureTable={data =>
          data?.viewer?.allergenConnection?.edges.map(ing => {
            return {
              id: ing.node.allergenId,
              label: ing.node.label,
              description: ing.node.description,
              updated: moment
                .tz(ing.node.updated, 'YYYY/MM/DD HH:mm', 'UTC')
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY h:mm A'),
              rawId: ing.node.id,
            };
          })
        }
        columns={[
          { title: 'Id', field: 'id' },
          { title: 'Name', field: 'label' },
          { title: 'Description', field: 'description' },
          { title: 'Updated', field: 'updated' },
        ]}
        initialQueryVariables={{
          fieldGroupOnly: 89,
        }}
        handleDeleteRow={onDeleteConfirmationClick}
        useConfirmationModalForDelete
        isDeleting={isDeletinghAllergen}
        deleteConfirmationText="Are you sure you want to delete this allergen?"
      />
      <Modal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        title={allergenId ? 'Update Allergen' : 'Add Allergen'}
      >
        <CreateUpdateAllergenModal
          handleClose={handleClose}
          query={query}
          allergenId={allergenId}
          setAllergenId={setAllergenId}
        />
      </Modal>
    </div>
  );
}
AllergensTable.propTypes = {};

export default AllergensTable;
