import { useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CREATE_MEASURE, UPDATE_MEASURE } from '../../../../../../../apollo/mutations';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { statusOptions } from '../../../../../../../constants/statuses';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const defaultValues = {
  label: '',
  active: '',
  abbreviation: '',
  thresholdValue: '',
  description: '',
};

const UnitOfMeasureModal = ({ handleClose, measure, refetch }) => {
  const { control, errors, handleSubmit, register, reset } = useForm(defaultValues);

  const { setSnackbar } = useSnackbar();

  const [createMeasure] = useMutation(CREATE_MEASURE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Measure created',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createMeasure?.viewer),
        fields: {
          measureConnection() {
            return {
              __typename: 'MeasureEdge',
            };
          },
        },
      });
    },
  });

  const [updateMeasure] = useMutation(UPDATE_MEASURE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Measure updated',
        open: true,
      });

      if (refetch) {
        refetch(true);
      }

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (measure?.measureId) {
      reset({
        label: measure.label,
        active: measure.active ? 1 : 0,
        abbreviation: measure.abbreviation,
        thresholdValue: measure.thresholdValue,
      });
    } else {
      reset(defaultValues);
    }
  }, [measure]);

  const onSubmit = useCallback(
    formData => {
      const input = {
        label: formData.label,
        active: !!formData.active,
        abbreviation: formData.abbreviation,
        thresholdValue: formData.thresholdValue !== '' ? formData.thresholdValue : null,
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: measure?.measureId ? 'Updating Measure...' : 'Creating Measure...',
        open: true,
      });

      if (measure?.measureId) {
        input.measureId = measure.measureId;

        updateMeasure({ variables: { input } });
      } else {
        createMeasure({ variables: { input } });
      }
    },
    [measure?.measureId],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            label="Name"
            name="abbreviation"
            variant="outlined"
            placeholder="Name"
            error={errors.label}
            helperText={!!errors.label && 'Name is required'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            inputRef={register}
            fullWidth
            label="Max Threshold Value"
            name="thresholdValue"
            variant="outlined"
            placeholder="Max Threshold Value"
            type="number"
            inputProps={{
              step: 0.0001,
              min: 0,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.active}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="active"
              name="active"
              label="Status"
              placeholder="Status"
              defaultValue={measure?.active ? 1 : 0}
              error={!!errors?.active}
              as={
                <Select label="Status" multiple={false} error={!!errors?.active}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.active?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            label="Description"
            name="label"
            variant="outlined"
            placeholder="Description"
            error={errors.label}
            helperText={!!errors.label && 'Description is required'}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit">{measure?.measureId ? 'Save' : 'Create'}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UnitOfMeasureModal;
