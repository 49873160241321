import { gql } from '@apollo/client';
import recipeFragment from '../../fragments/recipeFragment';

const UPDATE_RECIPE = gql`
  mutation UPDATE_RECIPE($input: UpdateRecipeMutationInput!) {
    updateRecipe(input: $input) {
      viewer {
        id
      }
      recipeEdge {
        node {
          ...RecipeData
        }
      }
    }
  }

  ${recipeFragment.recipe}
`;

export default UPDATE_RECIPE;
