import CategoryHandoffCardForm from './components/CategoryHandoffCardForm';

import { useStyles, useSliderStyles } from './styles';

const CategoryHandoffCard = ({
  categoryStatuses = {},
  categories,
  control: propsControl,
  handleCloseModal,
  handleSubmit: propsHandleSubmit,
  handoff,
  locationHandoffCategory = { edges: [] },
  locationId,
  location,
  onSubmit,
  permissions,
  register: propsRegister,
  toggleLocationHandoffCategory,
  statuses,
  defaultStatus,
  isBulkEdit,
  setValue,
  disabled,
  getValues: propsGetValues,
  errors: propsErrors,
  bulkEditPermission,
  updateLocationHandoffCategoryLoading,
  toggleLocationHandoffCategoryLoading,
  accessToMutations,
  toggleLocationHandoffCategoryPermission,
  updateLocationHandoffCategoryPermission,
  setOpenWarningMutationName,
}) => {
  const classes = useStyles();
  const sliderClasses = useSliderStyles();

  const Newcategories = categories?.edges?.map(category => (
    <CategoryHandoffCardForm
      bulkEditPermission={bulkEditPermission}
      categories={categories}
      category={category}
      location={location}
      setValue={setValue}
      categoryStatuses={categoryStatuses}
      classes={classes}
      deliveryId="SGFuZG9mZjozOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw"
      orderMenuId="Q2F0ZWdvcnk6MTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA=="
      handoffId={handoff.id}
      defaultStatus={defaultStatus}
      disabled={disabled}
      handleCloseModal={handleCloseModal}
      handoff={handoff}
      isBulkEdit={isBulkEdit}
      locationHandoffCategory={locationHandoffCategory}
      locationId={locationId}
      onSubmit={onSubmit}
      permissions={permissions}
      propsControl={propsControl}
      propsErrors={propsErrors}
      propsGetValues={propsGetValues}
      propsHandleSubmit={propsHandleSubmit}
      propsRegister={propsRegister}
      statuses={statuses}
      sliderClasses={sliderClasses}
      toggleLocationHandoffCategory={toggleLocationHandoffCategory}
      toggleLocationHandoffCategoryLoading={toggleLocationHandoffCategoryLoading}
      accessToMutations={accessToMutations}
      setOpenWarningMutationName={setOpenWarningMutationName}
      updateLocationHandoffCategoryLoading={updateLocationHandoffCategoryLoading}
      toggleLocationHandoffCategoryPermission={toggleLocationHandoffCategoryPermission}
      updateLocationHandoffCategoryPermission={updateLocationHandoffCategoryPermission}
    />
  ));

  return <div>{Newcategories}</div>;
};

export default CategoryHandoffCard;
