import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Checkbox, Hidden } from '@material-ui/core';
import { get } from 'lodash';
import Permission from '../../Permission';
import { Skeleton } from '@material-ui/lab';

const ConnectionTableRow = ({
  index,
  onTableRowClick,
  styles,
  row = {},
  data,
  variables,
  columns,
  selectableRows,
  refetch,
  loading,
  isCheckbox,
  isItemSelected,
  handleClick,
  stopPropagationLogic,
  handleDeleteRow,
  handleEditRow,
  collapsableRows,
  permissions,
}) => {
  const [open, setOpen] = useState(false);
  const handleDelete = (event, id = null) => {
    stopPropagationLogic(event, true);
    handleDeleteRow(id, variables);
  };

  const handleEdit = (event, id = null) => {
    stopPropagationLogic(event, true);
    handleEditRow(id, variables, data);
  };

  return (
    <React.Fragment key={`${index}-fragment`}>
      <TableRow
        className={styles.tableRowRoot}
        hover
        tabIndex={-1}
        key={index}
        onClick={() =>
          onTableRowClick && !row['disableTableRowClick'] && onTableRowClick(row, data, variables)
        }
      >
        {columns.map((column, columnIndex) => {
          if (loading) {
            return (
              <TableCell key={column.id || columnIndex}>
                <Skeleton color="secondary" variant="text" />
              </TableCell>
            );
          }

          const value =
            column?.field === 'id' && row['idLabel'] ? row['idLabel'] : row[column.field];

          let style = {};
          if (typeof column.cellStyle === 'function') {
            style = column.cellStyle(value);
          } else if (typeof column.cellStyle === 'object') {
            style = column.cellStyle;
          }

          if (column.enablePropagation) {
            style.cursor = 'pointer';
          }

          const access = get(permissions, column.customPermissionField || column.field, 7);

          return (
            <Hidden only={column.hidden} key={column.id || columnIndex}>
              {columnIndex === 0 && collapsableRows && (
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      setOpen(!open);
                    }}
                  >
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </TableCell>
              )}
              {columnIndex === 0 && selectableRows && isCheckbox && (
                <TableCell padding="checkbox" key={`${columnIndex}-checkbox`}>
                  <Checkbox checked={isItemSelected} onClick={event => handleClick(event, row)} />
                </TableCell>
              )}
              <Permission access={access}>
                <TableCell
                  key={columnIndex}
                  style={style}
                  onClick={event =>
                    stopPropagationLogic(event, !column.enablePropagation && !!column.render)
                  }
                >
                  {column.render ? column.render(row[column.field], variables, row) : value}
                </TableCell>
              </Permission>
            </Hidden>
          );
        })}
        {((!row['disableEdit'] && handleEditRow) || (!row['disableDelete'] && handleDeleteRow)) && (
          <TableCell className={styles.actionTableCell}>
            {!row['disableEdit'] && handleEditRow && (
              <IconButton
                aria-label="edit"
                className={styles.iconButtonRoot}
                onClick={event => {
                  handleEdit(event, row);
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            )}
            {!row['disableDelete'] && handleDeleteRow && (
              <IconButton
                aria-label="delete"
                className={styles.iconButtonRoot}
                onClick={event => handleDelete(event, row)}
              >
                <Delete fontSize="small" />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      {collapsableRows && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>{collapsableRows(data, row, { variables, refetch })}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default ConnectionTableRow;
