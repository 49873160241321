import { gql } from '@apollo/client';

/* IMPORTANT!!
  TODO: if location is not existing, return permissions and not just location `null`,
  then you can remove locationConnection query part and replace permissions on location/generalInfo
  to map from location.permissions and not locationConnection.permissions
*/

const FETCH_LOCATION_PERMISSIONS = gql`
  query fetchLocationPermissions {
    viewer {
      id
      locationConnection(first: 0, fieldGroup: 17) {
        permissions
        edges {
          node {
            id
            storeTypeId
            autoSubmitInvoices
            menu {
              id
              menuId
            }
            locationId
            label
            timezone {
              id
              __typename
            }
            phone {
              id
              phone
            }
            email
            storeNumber
            printerPassword
            address {
              id
              address
              city
              zip
              state {
                label
              }
            }
            taxgroupConnection(first: 0) {
              edges {
                node {
                  id
                  taxgroupId
                  label
                }
              }
            }
            taxrateConnection(first: 0) {
              edges {
                node {
                  amount
                  fraction
                  surcharge
                  taxable
                  display
                  taxrateId
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_LOCATION_PERMISSIONS;
