/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Checkbox, TextField, CircularProgress, Chip, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Waypoint } from 'react-waypoint';
import { CheckBoxOutlineBlank, CheckBox, Info } from '@material-ui/icons';
import { useLazyQuery } from '@apollo/client';
import { ROWS_PER_PAGE_OPTIONS, SNACKBAR_STATUS } from '../../../constants';
import { debounce } from 'lodash';
import { SelectFactoryStyles, TooltipStyles } from './styles';
import { useSnackbar } from '../../../hooks/useSnackbar';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const SelectFactory = ({
  label,
  placeholder,
  query,
  structureData,
  name,
  defaultValue,
  onSelect,
  fullWidth = true,
  groupBy,
  customProps = {},
  multiple = true, // multiple select
  disableCloseOnSelect = false,
  disableSearchReset = false,
  renderCheckBox = true,
  filter,
  sort,
  error: textFieldError,
  helperText,
  value: propsValue,
  specifcSearchFields,
  otherVariables,
  initRefetch,
  fetchOnEachOpen = false,
  fetchPolicy = 'cache-first',
  ...props
}) => {
  const { setSnackbar } = useSnackbar();

  const [connectionName, setConnectionName] = useState();
  const [value, setValue] = useState(propsValue || defaultValue);

  const [fetchQuery, { data, loading, refetch, variables, fetchMore, called }] = useLazyQuery(
    query,
    {
      variables: {
        first: ROWS_PER_PAGE_OPTIONS[0],
        filter,
        sort,
        specificFields: specifcSearchFields,
        ...otherVariables,
      },
      fetchPolicy,
      notifyOnNetworkStatusChange: true,
      onError: e =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        }),
    },
  );
  const classes = SelectFactoryStyles();
  const tooltipClasses = TooltipStyles();

  useEffect(() => {
    // if deafult value apppend to intial array
    if (defaultValue) {
      structureData = () => structureData(data) && [...structureData(data), defaultValue];
    }
  }, [defaultValue, structureData, data]);

  useEffect(() => {
    if (initRefetch !== undefined && refetch) {
      refetch(variables);
    }
  }, [initRefetch]);

  const search = value => {
    refetch &&
      refetch({
        ...variables,
        first: variables?.first || variables?.last,
        after: null,
        last: null,
        before: null,
        search: value ? value?.replace("'", "''").replace('%', '\\%') : null,
      });
  };

  useEffect(() => {
    if (!connectionName && data) {
      setConnectionName(Object.keys(data.viewer).find(element => element.includes('Connection')));
    }
  }, [data, connectionName]);

  const selectFactoryData = structureData(data) || [];

  const handleSearchInput = debounce(event => {
    search(event?.target?.value);
  }, 1000);

  return (
    <Autocomplete
      {...props}
      classes={classes}
      fullWidth={fullWidth}
      filterOptions={(options, state) => options}
      multiple={multiple}
      value={propsValue || value}
      groupBy={groupBy || null}
      options={selectFactoryData || []}
      getOptionSelected={(option, value) => option?.id === value?.id}
      disableCloseOnSelect={disableCloseOnSelect}
      loading={loading}
      onOpen={() => (fetchOnEachOpen ? fetchQuery() : !called && fetchQuery())}
      getOptionLabel={option => option?.label || ''}
      onChange={(event, values, reason) => {
        if (reason === 'remove-option' && event.type !== 'keydown') {
          onSelect && onSelect(values);
          setValue(values);
          customProps?.onChange && customProps.onChange(values);
        }
        if (reason === 'select-option' || reason === 'clear') {
          onSelect && onSelect(values);
          setValue(values);
          customProps?.onChange && customProps.onChange(values);
        }
      }}
      onInputChange={event => {
        if (!event?.target?.value && disableSearchReset) {
          return;
        }

        handleSearchInput(event);
      }}
      renderOption={(option, { selected }) => (
        <div className={classes.option}>
          <div>
            {renderCheckBox && (
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
            )}
            {option.label}
          </div>
          {option.tooltip ? (
            <div>
              <Tooltip title={option.tooltip} placement="left-start" classes={tooltipClasses}>
                <Info className={classes.info} color="action" />
              </Tooltip>
            </div>
          ) : null}
          {data?.viewer[connectionName]?.pageInfo?.hasNextPage &&
            option.index === selectFactoryData?.length - 1 && (
              <Waypoint
                onEnter={() =>
                  fetchMore({
                    variables: {
                      first: ROWS_PER_PAGE_OPTIONS[0],
                      after: data?.viewer[connectionName]?.pageInfo?.endCursor,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return previousResult;
                      }

                      if (previousResult?.viewer?.[connectionName]) {
                        return {
                          viewer: {
                            ...previousResult.viewer,
                            [connectionName]: {
                              ...previousResult.viewer[connectionName],
                              pageInfo: {
                                ...previousResult.viewer[connectionName].pageInfo,
                                endCursor:
                                  fetchMoreResult.viewer[connectionName]?.pageInfo?.endCursor,
                                hasNextPage:
                                  fetchMoreResult.viewer[connectionName]?.pageInfo?.hasNextPage,
                              },
                              edges: [
                                ...previousResult.viewer[connectionName]?.edges,
                                ...fetchMoreResult.viewer[connectionName]?.edges,
                              ],
                            },
                          },
                        };
                      } else {
                        return null;
                      }
                    },
                  })
                }
              />
            )}
        </div>
      )}
      renderInput={params => (
        <TextField
          {...params}
          {...props}
          variant="outlined"
          label={label}
          error={textFieldError}
          helperText={helperText}
          placeholder={placeholder}
          onKeyDown={event => {
            if (event.code === 'Backspace') {
              event.stopPropagation();
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <div style={{ maxHeight: '74px', overflow: 'auto' }}>
                {params.InputProps.startAdornment}
              </div>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectFactory;
