import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  titleContainer: {
    display: "flex",
  },

  subtitleMarginRight: {
    marginRight: theme.spacing(3),
  },
});

const LoyaltyPointsModaStyles = makeStyles(Styles);

export default LoyaltyPointsModaStyles;
