import { gql } from '@apollo/client';
import { locationAddressFragment } from '../../fragments';

export const UPDATE_VENDOR = gql`
  mutation updateVendor(
    $input: UpdateVendorMutationInput!
  ) {
  updateVendor(input: $input){
    vendor{
        permission
        id
        vendorId
        label
        orderUrl
        promoCode
        contactName
        phone {
          id
          phone
        }
        email
        address {
          ...LocationAddressData
        }
        notes
        vendorimage {
          label
          description
          url
        }
      }
    }
  }
  ${locationAddressFragment.address}
`;
