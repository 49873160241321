import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';

const MainMeasure = ({ measureOptions, control, errors, ingredient, setMainMeasure }) => {
  useEffect(() => {
    if (ingredient?.inventoryitem?.measure?.measureId) {
      setMainMeasure(ingredient?.inventoryitem?.measure?.abbreviation);
    }
  }, [ingredient]);

  const handleOnSelect = useCallback(
    measure => {
      setMainMeasure(measure.abbreviation);
    },
    [setMainMeasure],
  );

  return measureOptions?.length ? (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <InputLabel error={!!errors?.measureId}>Main UOM</InputLabel>
      <Controller
        rules={{
          required: true,
        }}
        id="measureId"
        name="measureId"
        defaultValue={ingredient?.inventoryitem?.measure?.measureId || ''}
        label="Main UOM"
        placeholder="Main UOM"
        error={!!errors?.measureId}
        as={
          <Select label="Main UOM" multiple={false} error={!!errors?.measureId}>
            {measureOptions?.map(measure => (
              <MenuItem
                onClick={() => handleOnSelect(measure)}
                key={measure.value}
                value={measure.value}
              >
                {measure.label}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
      />
      {errors?.measureId ? <FormHelperText error>Main UOM is required</FormHelperText> : null}
    </FormControl>
  ) : null;
};

export default MainMeasure;
