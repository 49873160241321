import { gql } from "@apollo/client";

const REMOVE_CREDIT_CARD = gql`
  mutation removeCreditCard($input: removeCreditCardInput!) {
    removeCreditCard(input: $input) {
      stripeData
    }
  }
`;

export default REMOVE_CREDIT_CARD;
