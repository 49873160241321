import React from "react";
import {
  Select,
  MenuItem,
  makeStyles,
  InputLabel,
  FormControl,
  Grid,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

import PermissionField from "../../PermissionField";
import Permission from "../../Permission";

/*
* Example of usage:

 <Elements.TimePicker
    control={control}
    hoursName="yourNameInForm"
    minutesName="yourNameInForm"
    amPmName="yourNameInForm
 />

props:        'control' - object from 'useFrom()' (@react-hook-form)
              'hoursName' - name that will be used to set field name in a form
              'minutesName' - name that will be used to set field name in a form
              'amPmName' - name that will be used to set field name in a form

NOTE:
    Use 'formatToValidDate' util when getting the value from the form.
    Use 'formatFromValidDate' util when you need to set the value into the form
*/

const useStyles = makeStyles({
  timePicker: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  hours: {
    "& label": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  minutes: {
    "& label": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
});

function Timepicker({
  hoursName,
  minutesName,
  amPmName,
  control,
  defaultValues,
  onHoursChange,
  onMinutesChange,
  onMeridiemChange,
  stateValues,
  access = 7,
  disabled,
  handleTimeChange,
  ...rest
}) {
  const classes = useStyles();

  const onTimeChange = (e, type) => {
    if (handleTimeChange) {
      handleTimeChange(e.target.value, type);
    }
  };

  return (
    <Grid
      xs={12}
      container
      spacing={3}
      className={classes.timePicker}
      alignItems="center"
    >
      <Grid item xs={4}>
        <Controller
          name={hoursName}
          control={control}
          defaultValue={defaultValues?.hours || 12}
          render={({ onChange, ref, value }) => (
            <Permission access={access}>
              <FormControl className={classes.hours} fullWidth>
                <PermissionField Component={InputLabel}>Hours</PermissionField>
                <PermissionField
                  {...rest}
                  Component={Select}
                  createHelperText
                  inputRef={ref}
                  disabled={disabled}
                  variant="outlined"
                  onChange={(event) => {
                    if (onHoursChange) {
                      onHoursChange(event);
                    }
                    onTimeChange(event, "hours");
                    onChange(event);
                  }}
                  label="Hours"
                  placeholder="Hours"
                  value={stateValues?.hours || value}
                >
                  {(() => {
                    let hours = [];
                    for (let i = 1; i <= 12; i++) {
                      hours.push(
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      );
                    }
                    return hours;
                  })()}
                </PermissionField>
              </FormControl>
            </Permission>
          )}
          placeholder="Hours"
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name={minutesName}
          control={control}
          defaultValue={defaultValues?.minutes || 0}
          render={({ onChange, ref, value }) => (
            <Permission access={access}>
              <FormControl className={classes.minutes} fullWidth>
                <PermissionField Component={InputLabel}>
                  Minutes
                </PermissionField>
                <PermissionField
                  {...rest}
                  Component={Select}
                  createHelperText
                  disabled={disabled}
                  inputRef={ref}
                  variant="outlined"
                  onChange={(event) => {
                    if (onMinutesChange) {
                      onMinutesChange(event);
                    }
                    onTimeChange(event, "minutes");
                    onChange(event);
                  }}
                  label="Minutes"
                  placeholder="Minutes"
                  value={stateValues?.minutes || value}
                >
                  <MenuItem value="0">00</MenuItem>
                  {(() => {
                    let minutes = [];
                    for (let i = 1; i <= 59; i++) {
                      if (i < 10) {
                        minutes.push(
                          <MenuItem key={i} value={i}>
                            0{i}
                          </MenuItem>
                        );
                      }
                      if (i >= 10) {
                        minutes.push(
                          <MenuItem key={i} value={i}>
                            {i}
                          </MenuItem>
                        );
                      }
                    }
                    return minutes;
                  })()}
                </PermissionField>
              </FormControl>
            </Permission>
          )}
          placeholder="Minutes"
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name={amPmName}
          control={control}
          defaultValue={defaultValues?.meridiem || "am"}
          render={({ onChange, ref, value }) => (
            <Permission access={access}>
              <div style={{ width: "100%" }}>
                <PermissionField
                  {...rest}
                  Component={Select}
                  createHelperText
                  fullWidth
                  inputRef={ref}
                  disabled={disabled}
                  variant="outlined"
                  onChange={(event) => {
                    if (onMeridiemChange) {
                      onMeridiemChange(event);
                    }
                    onTimeChange(event, "meridiem");
                    onChange(event);
                  }}
                  label="Minutes"
                  value={stateValues?.meridiem || value}
                >
                  <MenuItem value="am">AM</MenuItem>
                  <MenuItem value="pm">PM</MenuItem>
                </PermissionField>
              </div>
            </Permission>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default Timepicker;
