import { gql } from "@apollo/client";

const UPDATE_LOCATION_MULTIPLE_CHOICE = gql`
  mutation updateLocationItemMultipleChoicePriceND($input: updateLocationItemMultipleChoicePriceMutationNDInput!) {
    updateLocationItemMultipleChoicePriceND(input: $input) {
      viewer {
      id
      }
    }
  }
`;

export default UPDATE_LOCATION_MULTIPLE_CHOICE;
