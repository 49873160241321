import { gql } from '@apollo/client';
import { viewerFragment } from '../../fragments';

const UPDATE_LOYATLY_POINTS = gql`
  mutation updateLoyaltyPoints($input: UpdateLoyaltyPointsMutationInput!) {
    updateLoyaltyPoints(input: $input) {
      login {
        id
        spendablePoints
        lifetimePoints
      }
      loyaltypointlogEdge {
        node {
          id
          personId
          created
          person {
            id
            firstName
            lastName
          }
          loyaltypointreason {
            id
            label
          }
          points
          bonusPoints
        }
      }
    }
  }
`;

export default UPDATE_LOYATLY_POINTS;
