import { gql } from '@apollo/client';
import { timezoneFragment } from '../../fragments';

const DISCOUNTS_CREATE_DATA = gql`
  query discounts($storeTypeId: Int) {
    viewer {
      timezoneConnection {
        edges {
          node {
            ...TimezoneData
          }
        }
      }
      couponTriggerConnection {
        edges {
          node {
            id
            couponTriggerId
            label
          }
        }
      }
      platformConnection {
        edges {
          node {
            id
            label
            platformId
          }
        }
      }
      brandConnection {
        edges {
          node {
            id
            label
            brandId
          }
        }
      }
      handoffConnection {
        edges {
          node {
            id
            handoffId
            label
          }
        }
      }
      locationConnection(filter: { storeTypeId: { eq: $storeTypeId } }) {
        edges {
          node {
            id
            label
            storeNumber
          }
        }
      }
    }
  }
  ${timezoneFragment.timezone}
`;

export default DISCOUNTS_CREATE_DATA;
