import { gql } from '@apollo/client';

const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee(
    $loginId: Int!
    $firstName: String
    $lastName: String
    $dateOfBirth: String
    $address: String
    $additionalInfo: String
    $city: String
    $stateLabel: String
    $countryId: Int
    $zip: String
    $email: String
    $phone: String
    $usergroupIds: [Int]
    $schedulePreference: String
  ) {
    updateEmployee(
      input: {
        loginId: $loginId
        firstName: $firstName
        lastName: $lastName
        address: $address
        additionalInfo: $additionalInfo
        dateOfBirth: $dateOfBirth
        city: $city
        stateLabel: $stateLabel
        countryId: $countryId
        zip: $zip
        phone: $phone
        usergroupIds: $usergroupIds
        email: $email
        schedulePreference: $schedulePreference
      }
    ) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_EMPLOYEE;
