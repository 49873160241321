import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Button, FormControl, Grid, MenuItem, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import EditBankSettingsStyles from './styles';
import { LocationIdContext } from '../../../../Location';

import {
  PlacesAutocomplete,
  Elements,
  PermissionField,
  Permission,
} from '../../../../../../blocks';
import { regexPattern } from '../../../../../../../utils';
import { Autocomplete } from '@material-ui/lab';
import { useStripe } from '@stripe/react-stripe-js';
import { MUTATION_NAME } from '../../../../../../../constants';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const EditBankSettingsForm = ({
  onSubmit,
  stateList,
  handleCloseModal,
  permission,
  locationInfo,
  disableSubmitButton,
  setDisableSubmitButton,
  accessToMutations,
  mutationPermissions,
}) => {
  const classes = EditBankSettingsStyles();

  const stripe = useStripe();
  const { setSnackbar } = useSnackbar();

  const [locationAddress, setLocationAddress] = useState({
    address: undefined,
    city: undefined,
    state: undefined,
    postal_code: undefined,
  });
  const [locationAddressDisabled, setLocationAddressDisabled] = useState({
    address: true,
    city: true,
    state: true,
    postal_code: true,
  });

  // const [stateState, setStateState] = useState(
  //   locationInfo.stripeAccount
  //     ? stateList.filter(stat => {
  //         return stat.label === locationInfo.stripeAccount.legal_entity.address.state;
  //       })[0]
  //     : null,
  // );

  const createLocationStripePermission = useMemo(
    () => mutationPermissions?.find(perm => perm.label === 'createLocationStripe'),
    [mutationPermissions],
  );

  const locationId = useContext(LocationIdContext);

  const { register, handleSubmit, control, errors } = useForm();

  const handleAddressChange = (event, type) => {
    const value = event.target?.value || event.label;
    setLocationAddress(prevState => ({ ...prevState, [type]: value }));
  };

  const handleGeocodeAutocomplete = address => {
    const { state } = address;
    address.state = stateList.find(existingState => existingState.label === state);

    for (const property in address) {
      if (address[property] === '') {
        setLocationAddressDisabled(prevState => ({
          ...prevState,
          [property]: false,
        }));
      }
    }
    address.postal_code = address.zip;
    setLocationAddress({ ...address, state: address.state });
  };

  // useEffect(() => {
  //   setStateState(locationAddress.state);
  // }, [locationAddress.state]);

  const handleOnSubmit = async formData => {
    setDisableSubmitButton(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Creating bank settings...',
    });

    let day = '',
      month = '',
      year = '',
      state = formData.state;

    let token = null;

    if (formData.dateOfBirth) {
      const fullDate = moment(formData.dateOfBirth, 'YYYY/MM/DD');
      day = fullDate.format('D');
      month = fullDate.format('M');
      year = fullDate.format('Y');
    }

    if (formData.state && formData.state.label === 'None') {
      setSnackbar({
        open: true,
        type: 'warning',
        text: 'Please select state',
      });
      setDisableSubmitButton(false);
      return;
    }

    if (!formData.state && locationAddress?.state?.label && locationAddress?.state?.value) {
      state = locationAddress.state;
    }
    const { stripeAccount } = locationInfo;

    let country;
    let currency;

    if (formData.locationCountry === 'CAD') {
      country = 'CA';
      currency = 'CAD';
    } else {
      country = 'US';
      currency = 'USD';
    }

    const bankAccount = {
      country,
      currency,
      account_holder_name: `${formData.firstName} ${formData.lastName}`,
      account_number: formData.locationBankNumber,
      routing_number: formData.locationBankRoutingNumber,
      account_holder_type: formData.locationBankAccountType,
    };

    token = await stripe.createToken('bank_account', bankAccount);

    if (!token || token.error) {
      setSnackbar({
        open: true,
        type: 'error',
        text: token?.error ? token?.error.message : 'Bank settings invalid',
      });
      setDisableSubmitButton(false);
      return;
    }

    const submitInputData = {
      locationId,
      state: state?.label,
      businessName: formData.businessName || locationInfo.label,
      // city: formData.city || stripeAccount.legal_entity.address.city,
      // line1: formData.locationBankAddress || stripeAccount.legal_entity.address.line1,
      // postalCode: formData.postal_code || stripeAccount.legal_entity.address.postal_code,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dobDay: day,
      dobMonth: month,
      dobYear: year,
      ssnLast4: formData.ssnLast4,
      businessTaxId: formData.locationBusinessTaxId,
      externalAccount: token.token.id,
    };

    onSubmit(submitInputData);
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
      <Grid container spacing={5} className={classes.formControlRoot}>
        <Permission access={permission}>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                // error={!!errors.locationBusinessName}
                inputRef={register}
                id="businessName"
                name="businessName"
                label="Location Business Name"
                defaultValue={locationInfo.label}
                placeholder="Location Business Name"
              />
              {/* {errors.businessName && (
                <FormHelperText error>This field is required</FormHelperText>
              )} */}
            </Grid>
            <Grid item container xs={6}>
              <PermissionField
                Component={Elements.PasswordInput}
                error={!!errors.locationBusinessTaxId}
                ref={register({ required: true })}
                name="locationBusinessTaxId"
                id="locationBusinessTaxId"
                label="Location Business Tax ID"
                helperText={
                  errors.locationBusinessTaxId
                    ? 'This field is required'
                    : 'Tax ID must have 9 digits'
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            {/* TODO: internationalization is not decided yet*/}
            <Grid item xs={4}>
              <PermissionField
                Component={Elements.Select}
                name="locationCountry"
                id="locationCountry"
                label="Country / Region"
                defaultValue="USA"
                control={control}
                placeholder="Select Country / Region"
              >
                <MenuItem value={'USA'}>USA</MenuItem>
                <MenuItem value={'CAD'}>CAD</MenuItem>
              </PermissionField>
            </Grid>
            <Grid item container xs={8}>
              <PermissionField
                Component={PlacesAutocomplete}
                handleGeocodeAutocomplete={handleGeocodeAutocomplete}
                name="googleAutocompleteAddress"
                // error={!!errors.googleAutocompleteAddress}
              />
              {/* {errors.googleAutocompleteAddress && (
                <FormHelperText error>This field is required</FormHelperText>
              )} */}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                error={!!errors.locationBankAddress}
                inputRef={register}
                name="locationBankAddress"
                id="locationBankAddress"
                label="Street Address"
                // defaultValue={
                // locationInfo?.stripeAccount
                // ? locationInfo.stripeAccount.legal_entity.address.line1
                // : ''
                // }
                InputProps={{
                  readOnly: locationAddressDisabled.address,
                }}
                onChange={event => handleAddressChange(event, 'address')}
                value={locationAddress.address && locationAddress.address}
                helperText={errors.locationBankAddress && 'This field is required'}
              />
            </Grid>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                id="locationBankAdditionalInfo"
                name="locationBankAdditionalInfo"
                label="Apartment, Suite, Unit, Building, Floor..."
                // defaultValue={
                //   locationInfo?.stripeAccount
                //     ? locationInfo.stripeAccount.legal_entity.address.line2
                //     : ''
                // }
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={4}>
              <PermissionField
                Component={TextField}
                error={!!errors.city}
                inputRef={register({ required: true })}
                name="city"
                id="city"
                label="City"
                // defaultValue={
                //   locationInfo?.stripeAccount
                //     ? locationInfo.stripeAccount.legal_entity.address.city
                //     : ''
                // }
                InputProps={{
                  readOnly: locationAddressDisabled.city,
                }}
                onChange={event => handleAddressChange(event, 'city')}
                value={locationAddress.city}
                helperText={errors.city && 'City is required'}
              />
            </Grid>
            <Grid item xs={4}>
              {/* <FormControl variant="outlined">
                <Autocomplete
                  id="state"
                  getOptionLabel={option => option.label}
                  control={control}
                  defaultValue={stateState || { label: 'None', value: 0 }}
                  renderInput={params => (
                    <Permission access={permission}>
                      <PermissionField
                        Component={TextField}
                        {...params}
                        label="State"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Permission>
                  )}
                  onChange={selected => {
                    setLocationAddress({
                      ...locationAddress,
                      state: selected,
                    });
                  }}
                  options={stateList}
                  selectedOption={stateState}
                  value={stateState}
                  // disabled={locationAddressDisabled.state}
                  fullWidth
                />
              </FormControl> */}
            </Grid>
            <Grid item container xs={4}>
              {/* <PermissionField
                Component={TextField}
                inputRef={register({ required: true })}
                InputProps={{
                  readOnly: locationAddressDisabled.postal_code,
                }}
                error={!!errors.zip}
                name="postal_code"
                label="Zip"
                defaultValue={
                  locationInfo?.stripeAccount
                    ? locationInfo.stripeAccount.legal_entity.address.postal_code
                    : ''
                }
                style={{ width: '100%' }}
                onChange={event => handleAddressChange(event, 'postal_code')}
                value={locationAddress?.postal_code}
                helperText={errors.zip && 'This field is required'}
              /> */}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                error={!!errors.firstName}
                inputRef={register({ required: true })}
                name="firstName"
                id="firstName"
                label="Authorized Representative First Name"
                helperText={errors.firstName && 'This field is required'}
              />
            </Grid>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                error={!!errors.lastName}
                inputRef={register({ required: true })}
                name="lastName"
                id="lastName"
                label="Authorized Representative Last Name"
                fullWidth
                helperText={errors.lastName && 'This field is required'}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={6}>
              <PermissionField
                Component={Elements.DatePicker}
                name="dateOfBirth"
                label="Date of Birth (representative)"
                control={control}
                // defaultValue={
                //   locationInfo?.stripeAccount
                //     ? new Date(
                //         `${locationInfo.stripeAccount.legal_entity.dob.month}/${locationInfo.stripeAccount.legal_entity.dob.day}/${locationInfo.stripeAccount.legal_entity.dob.year}`,
                //       )
                //     : ''
                // }
                rules={{ required: true }}
                error={!!errors.dateOfBirth}
                createHelperText
                helperText={errors.dateOfBirth && 'This field is required'}
              />
            </Grid>
            <Grid container item xs={6}>
              <PermissionField
                Component={Elements.PasswordInput}
                error={!!errors.ssnLast4}
                ref={register({
                  required: true,
                  pattern: { value: regexPattern.onlyNumber },
                  maxLength: 4,
                })}
                name="ssnLast4"
                id="ssnLast4"
                label="SSN last 4 numbers (representative)"
                helperText={errors.ssnLast4 && 'Input valid value'}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                error={!!errors.locationBankName}
                inputRef={register({ required: true })}
                name="locationBankName"
                id="locationBankName"
                label="Bank Name"
                helperText={errors.locationBankName && 'This field is required'}
              />
            </Grid>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                error={!!errors.locationBankNumber}
                inputRef={register({ required: true })}
                name="locationBankNumber"
                id="locationBankNumber"
                label="Bank Account Number"
                helperText={errors.locationBankNumber && 'This field is required'}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={6}>
              <PermissionField
                Component={TextField}
                error={!!errors.locationBankRoutingNumber}
                inputRef={register({ required: true })}
                name="locationBankRoutingNumber"
                id="locationBankRoutingNumber"
                label="Bank Account Routing Number"
                helperText={
                  errors.locationBankRoutingNumber
                    ? 'This field is required'
                    : 'Routing number must have 9 digits'
                }
              />
            </Grid>
            <Grid item container xs={6}>
              <PermissionField
                Component={Elements.Select}
                name="locationBankAccountType"
                label="Bank Account Type"
                control={control}
                shrink
                // defaultValue={
                // locationInfo?.stripeAccount ? locationInfo.stripeAccount.legal_entity.type : ''
                // }
                placeholder="Bank Account Type"
                rules={{ required: true }}
                error={!!errors.locationBankAccountType}
                helperText={errors.locationBankAccountType && 'This field is required'}
              >
                <MenuItem value="company">Company</MenuItem>
                <MenuItem value="individual">Individual</MenuItem>
              </PermissionField>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} justify="flex-end">
            <Button
              disabled={disableSubmitButton}
              onClick={handleCloseModal}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Permission
              access={Math.min(
                accessToMutations?.[MUTATION_NAME.CreateLocationStripeMutationInput],
                createLocationStripePermission?.access,
              )}
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    disabled={disableSubmitButton}
                    type="submit"
                    className={classes.submitRoot}
                  >
                    Submit
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </Grid>
        </Permission>
      </Grid>
    </form>
  );
};

export default EditBankSettingsForm;
