import React from 'react';
import InventoryView from './View';
import TaskList from './TaskList';
import SimpleInvoice from './SimpleInvoice';
import InvoiceSummary from './InvoiceSummary';
import WasteLog from './WasteLog';
import InvoiceJournal from './InvoiceJournal';
import StorageArea from './StorageArea';
import StorageAreas from './StorageAreas';
import Summary from './Summary';
import { InventoryTransfer } from './InventoryTransfer';

function Inventory() {
  return <InventoryView />;
}

Inventory.TaskList = TaskList;
Inventory.SimpleInvoice = SimpleInvoice;
Inventory.InvoiceSummary = InvoiceSummary;
Inventory.WasteLog = WasteLog;
Inventory.InvoiceJournal = InvoiceJournal;
Inventory.StorageArea = StorageArea;
Inventory.StorageAreas = StorageAreas;
Inventory.Summary = Summary;
Inventory.InventoryTransfer = InventoryTransfer;

export default Inventory;
