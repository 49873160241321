import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  formControlRoot: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    marginBottom: `${theme.spacing(4)}px`,
  },

  submitRoot: {
    marginLeft: `${theme.spacing(4)}px`,
  },
});

const EditBankSettingsStyles = makeStyles(Styles);

export default EditBankSettingsStyles;
