import { gql } from '@apollo/client';

const INVENTORYITEM_CUSTOM_CATEGORY = gql`
  query inventoryitemCustomCategory {
    viewer {
      id
      inventoryitemCustomCategoryConnection {
        edges {
          node {
            id
            inventoryitemCustomCategoryId
            label
          }
        }
      }
    }
  }
`;

export default INVENTORYITEM_CUSTOM_CATEGORY;
