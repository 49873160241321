import { gql } from '@apollo/client';

const UPDATE_HOUSE_ACCOUNT = gql`
  mutation updateHouseaccount($input: UpdateHouseaccountMutationInput!) {
    updateHouseaccount(input: $input) {
      houseaccount {
        id
        houseaccountId
        limit
      }
    }
  }
`;

export default UPDATE_HOUSE_ACCOUNT;
