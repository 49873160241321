import React, { useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Elements } from "../../../../../../blocks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: "20px 0",
  },
  switchControl: {
    margin: "0 20px",
  },
}));

function AvailableDays({ control }) {
  const classes = useStyles();

  const [enableDaySelection, setEnableDaySelection] = useState(false);
  const [state, setState] = React.useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const handleEnableChange = (isChecked) => {
    if (isChecked) {
      setState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
    }
    setEnableDaySelection(!enableDaySelection);
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup row>
          <Controller
            control={control}
            name="availableOnDays.enabled"
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <FormControlLabel
                style={{ marginLeft: "0", marginRight: "15px" }}
                labelPlacement="start"
                control={
                  <div style={{ marginLeft: "2rem" }}>
                    <Elements.Switch
                      ref={ref}
                      handleSwitchChange={(isChecked) => {
                        handleEnableChange(isChecked);
                        onChange(isChecked);
                      }}
                      color="primary"
                    />
                  </div>
                }
                label="Available on specific day of week"
              />
            )}
          />
          <div style={{ marginLeft: "3rem" }}>
            {Object.keys(state).map((day, i) => (
              <FormControlLabel
                key={day}
                labelPlacement="end"
                disabled={!enableDaySelection}
                control={
                  <Controller
                    control={control}
                    name={`availableOnDays.days[${day}]`}
                    render={(
                      { onChange, onBlur, value, name, ref },
                      { invalid, isTouched, isDirty }
                    ) => (
                      <Checkbox
                        inputRef={ref}
                        disabled={!enableDaySelection}
                        onChange={(e) => onChange(e.target.checked)}
                        value={day}
                      />
                    )}
                  />
                }
                label={day[0].toUpperCase() + day.substring(1)}
              />
            ))}
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default AvailableDays;
