import { gql } from '@apollo/client';

const FETCH_LOCATION_INVENTORYITEM = gql`
  query FETCH_LOCATION_INVENTORYITEM(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationIngredientSort]
    $filter: [LocationIngredientFilter]
  ) {
    viewer {
      id
      locationIngredientConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 95
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            inventoryitem {
              id
              type
              inventoryitemId
              label
              measure {
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_LOCATION_INVENTORYITEM;
