import LocationListView from './View';
import { useHistory } from 'react-router-dom';

import { LOCATIONS } from '../../../../../apollo/queries';

const LocationList = () => {
  const history = useHistory();

  return <LocationListView query={LOCATIONS} history={history} />;
};

export default LocationList;
