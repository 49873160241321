import { gql } from '@apollo/client';

const UPDATE_NUTRIENT = gql`
  mutation UpdateNutrient($input: UpdateNutrientMutationInput!) {
    updateNutrient(input: $input) {
      nutrient: nutrientEdge {
        node {
          id
          nutrientId
          label
          description
          updated
          measure {
            id
            label
          }
        }
      }
    }
  }
`;

export default UPDATE_NUTRIENT;
