import { gql } from '@apollo/client';

const UPDATE_LOCATIONS_TIME_SLOT = gql`
  mutation UpdateLocations($input: UpdateLocationTimeSlotMutationInput!) {
    updateLocationTimeSlot(input: $input) {
      viewer {
        id
      }
     }
    }
`;

export default UPDATE_LOCATIONS_TIME_SLOT;
