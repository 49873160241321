import { useMutation } from '@apollo/client';
import { Button, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { SET_ITEM_CHOICE_INVENTORYITEM } from '../../../../../../../../../../../apollo/mutations/menus/setItemChoiceInventoryitem';
import FETCH_INVENTORY_ITEMS from '../../../../../../../../../../../apollo/queries/fetchInventoryItem';
import { SNACKBAR_STATUS } from '../../../../../../../../../../../constants';
import { useSnackbar } from '../../../../../../../../../../../hooks/useSnackbar';
import { SelectFactory } from '../../../../../../../../../../blocks';

function ChoiceIngredient({
  itemId,
  choiceId,
  node,
  refetch,
  myKids,
  setDisabledSave,
  disabledSave,
  reusable,
  handleReuse,
  handleRemove,
  setLastSubmitId,
}) {
  const [selectedInventoryItem, setSelectedInventoryitem] = useState(
    node && !node.new ? { ...node, inventoryitemId: node?.ingredientId } : null,
  );
  const [selectedMeasure, setSelectedMeasure] = useState(node?.measure?.measureId || null);
  const [measureList, setMeasureList] = useState(
    node && !node.new
      ? node?.ingredient.inventoryitem.inventoryitemMeasureConnection.edges.map(edge => (
          <MenuItem value={edge.node.measure.measureId}>
            {edge.node.measure.label} ({edge.node.measure.abbreviation})
          </MenuItem>
        ))
      : null,
  );
  const [selectedQuantity, setSelectedQuantity] = useState(node?.quantity || 0);
  const { setSnackbar } = useSnackbar();
  const [setItemChoiceInventoryitem] = useMutation(SET_ITEM_CHOICE_INVENTORYITEM, {
    onCompleted: async data => {
      if (refetch) {
        await refetch();
      }
      setDisabledSave(false);
      if (data.setItemChoiceInventoryitem.isRemove) {
        setSelectedInventoryitem(null);
        setSelectedMeasure(null);
        setSelectedQuantity(0);
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Choice ingredient removed',
          open: true,
        });
        return;
      }
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Choice ingredient updated',
        open: true,
      });
    },
  });

  const handleOnSelect = useCallback(value => {
    if (node.new) {
      const alreadyAssigned = myKids.find(k => k.ingredientId === value.inventoryitemId);
      if (alreadyAssigned) {
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          text: 'Ingredient already assigned',
          open: true,
        });
        setSelectedInventoryitem(null);
        return;
      }
      value.new = true;
    }
    setSelectedInventoryitem(value);
    setMeasureList(
      value.measureOptions.map(val => <MenuItem value={val.value}>{val.label}</MenuItem>),
    );
  }, []);

  const submit = () => {
    setDisabledSave(true);
    const alreadyExsists = myKids.find(
      k => k.ingredientId === selectedInventoryItem.inventoryitemId,
    );
    if (alreadyExsists) {
      if (selectedInventoryItem.new) {
        setDisabledSave(false);
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          text: 'Ingredient already assigned',
          open: true,
        });
        return;
      }
    }
    if (!selectedInventoryItem || !selectedQuantity || !selectedMeasure) {
      setDisabledSave(false);
      setSnackbar({
        type: SNACKBAR_STATUS.WARNING,
        text: 'Please select ingredient, quantity and measure',
        open: true,
      });
      return;
    }

    if (selectedQuantity < 0) {
      setDisabledSave(false);
      setSnackbar({
        type: SNACKBAR_STATUS.WARNING,
        text: 'Quantity must be positive value',
        open: true,
      });
      return;
    }

    const dataToSend = {
      itemId,
      choiceId,
      choiceIngredientId: selectedInventoryItem.choiceIngredientId,
      inventoryitem: {
        inventoryitemId: selectedInventoryItem.inventoryitemId,
        quantity: selectedQuantity,
        measureId: selectedMeasure,
      },
    };
    setLastSubmitId(node.id);
    setItemChoiceInventoryitem({
      variables: {
        input: dataToSend,
      },
    });
  };

  const remove = () => {
    setDisabledSave(true);
    const dataToSend = {
      itemId,
      choiceId,
      inventoryitem: {
        inventoryitemId: selectedInventoryItem.inventoryitemId,
      },
      choiceIngredientId: selectedInventoryItem.choiceIngredientId,
      isRemove: true,
    };
    setItemChoiceInventoryitem({
      variables: {
        input: dataToSend,
      },
    });
  };
  if (node && !node.new) {
    return (
      <Grid container spacing={4} onClick={e => e.stopPropagation()}>
        <Grid item xs={4}>
          {node ? (
            <p>{node.ingredient.inventoryitem.label}</p>
          ) : (
            <SelectFactory
              label="Ingredient Name"
              fullWidth
              sort={{
                inventoryitemId: 'ASC',
              }}
              name="recipe"
              placeholder="Search Ingredients"
              query={FETCH_INVENTORY_ITEMS}
              structureData={data =>
                data?.viewer?.inventoryitemConnection?.edges?.map((edge, index) => {
                  return {
                    label: edge.node.label,
                    inventoryitemId: edge.node.inventoryitemId,
                    quantity: '',
                    measureId: '',
                    measureOptions: edge.node?.inventoryitemMeasureConnection?.edges?.map(
                      measureEdge => ({
                        value: measureEdge.node.measure?.measureId,
                        label: `${measureEdge.node.measure?.label} (${measureEdge.node.measure?.abbreviation})`,
                      }),
                    ),
                  };
                })
              }
              defaultValue={{ label: node.ingredient.inventoryitem.label, inventoryitemId: 24 }}
              multiple={false}
              renderCheckBox={false}
              onSelect={handleOnSelect}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputProps={{
              readOnly: !itemId,
            }}
            defaultValue={node.quantity}
            inputProps={{ min: 0.0001, step: 0.0001, type: 'number' }}
            onChange={(e, data) => {
              setSelectedQuantity(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {!itemId ? (
            <TextField
              InputProps={{
                readOnly: !itemId,
              }}
              defaultValue={`${selectedInventoryItem.measure.label} (${selectedInventoryItem.measure.abbreviation})`}
            />
          ) : (
            <Select
              disabled={!itemId}
              onChange={e => {
                setSelectedMeasure(e.target.value);
              }}
              label="Measure"
              defaultValue={selectedMeasure}
            >
              {measureList}
            </Select>
          )}
        </Grid>
        {itemId ? (
          <Grid item xs={1}>
            <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
              <Button
                onClick={submit}
                size="medium"
                variant="outlined"
                type={'button'}
                disabled={disabledSave}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  if (!node.reused) {
                    remove();
                    return;
                  }
                  handleRemove(node.id);
                }}
                size="medium"
                variant="outlined"
                type={'button'}
                disabled={disabledSave}
              >
                Remove
              </Button>
            </div>
          </Grid>
        ) : null}
        {reusable && !myKids.find(k => k.ingredientId === selectedInventoryItem.inventoryitemId) ? (
          <Grid item xs={1}>
            <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
              <Button
                onClick={() => {
                  handleReuse(selectedInventoryItem);
                }}
                size="medium"
                variant="outlined"
                type={'button'}
                disabled={disabledSave}
              >
                Copy to This Item
              </Button>
            </div>
          </Grid>
        ) : null}
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={3} onClick={e => e.stopPropagation()}>
        <Grid item xs={4}>
          <SelectFactory
            label="Ingredient Name"
            fullWidth
            sort={{
              inventoryitemId: 'ASC',
            }}
            name="recipe"
            placeholder="Search Ingredients"
            query={FETCH_INVENTORY_ITEMS}
            structureData={data =>
              data?.viewer?.inventoryitemConnection?.edges?.map((edge, index) => {
                return {
                  label: edge.node.label,
                  inventoryitemId: edge.node.inventoryitemId,
                  quantity: '',
                  measureId: '',
                  measureOptions: edge.node?.inventoryitemMeasureConnection?.edges?.map(
                    measureEdge => ({
                      value: measureEdge.node.measure?.measureId,
                      label: `${measureEdge.node.measure?.label} (${measureEdge.node.measure?.abbreviation})`,
                    }),
                  ),
                };
              })
            }
            multiple={false}
            renderCheckBox={false}
            onSelect={handleOnSelect}
          />
        </Grid>
        {selectedInventoryItem && (
          <>
            <Grid item xs={2}>
              <TextField
                onChange={e => {
                  setSelectedQuantity(e.target.value);
                }}
                defaultValue={selectedInventoryItem.quantity}
                inputProps={{ min: 0.1, step: 0.1, type: 'number' }}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                label="Measure"
                onChange={e => {
                  setSelectedMeasure(e.target.value);
                }}
                defaultValue={selectedInventoryItem.measureId}
              >
                {selectedInventoryItem.measureOptions.map(opt => (
                  <MenuItem value={opt.value}>{opt.label}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={1}>
              <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
                <Button
                  onClick={submit}
                  size="medium"
                  variant="outlined"
                  type={'button'}
                  disabled={disabledSave}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    handleRemove(node.id);
                  }}
                  size="medium"
                  variant="outlined"
                  type={'button'}
                  disabled={disabledSave}
                >
                  Remove
                </Button>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

export default ChoiceIngredient;
