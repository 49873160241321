import React, { useState } from "react";
import { Button, Chip, DialogActions, Grid } from "@material-ui/core";
import { HierarchySideBar } from "../../../../../Hierarchy/View";
import { SelectFactory } from "../../../../../../../blocks";
import { LOCATIONS } from "../../../../../../../../apollo/queries";
import { compact } from "lodash";

function AddLocationsModal({
  handleClose,
  includedLocations,
  setIncludedLocations,
}) {
  const [newSelectedLocations, setNewSelectedLocations] = useState(
    includedLocations
  );
  const onSave = () => {
    setIncludedLocations(newSelectedLocations);
    handleClose();
  };
  return (
    <div>
      <Grid container>
        <Grid item md={12}>
          {/* <HierarchySideBar
            locations
            setLocationIds={setNewSelectedLocations}
            locationIds={newSelectedLocations}
          /> */}
          <SelectFactory
            style={{ width: "100%" }}
            label={"Locations"}
            placeholder={"Select Locations"}
            query={LOCATIONS}
            defaultValue={newSelectedLocations}
            disableCloseOnSelect={true}
            multiple
            renderCheckBox
            structureData={(data) => {
              return data?.viewer?.locationConnection?.edges.map(
                (location, index) => {
                  return {
                    label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
                    id: location.node.id,
                    locationId: location.node.locationId,
                    index,
                  };
                }
              );
            }}
            onSelect={(values) => {
              setNewSelectedLocations(values);
            }}
          />
        </Grid>
        {/* <Grid item md={6}>
          <div>
            {newSelectedLocations.map((item) => {
              if (!item.isParent) {
                return (
                  <Chip
                    key={item.id + "" + item.parentId}
                    style={{ margin: "4px" }}
                    label={item.label}
                    onDelete={(e) =>
                      setNewSelectedLocations(
                        newSelectedLocations.filter((loc) => loc.id !== item.id)
                      )
                    }
                  />
                );
              }
            })}
          </div>
        </Grid> */}
      </Grid>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSave}>SAVE</Button>
      </DialogActions>
    </div>
  );
}

export default AddLocationsModal;
