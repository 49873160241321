import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Grid, CircularProgress } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { SelectFactory } from '../../../../../../../../blocks';
import CATEGORIES_AND_MENUS from '../../../../../../../../../apollo/queries/discounts/categoriesAndMenus';
import FETCH_CATEGORY_AND_MENU from '../../../../../../../../../apollo/queries/discounts/fetchCategoryAndMenus';
import OrderCategoryStyles from './styles';
import getCategoryLabelWithMenus from '../../../../../../../../../utils/getCategoryLabelWithMenus';

function OrderCategory({ control, errors, couponTriggers }) {
  const classes = OrderCategoryStyles();

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [display, setDispaly] = useState(false);

  const [fetchCategories, { data, loading }] = useLazyQuery(FETCH_CATEGORY_AND_MENU, {
    variables: {
      categoryIds: couponTriggers.values,
    },
    onCompleted: (data) => {
      const result = getCategoryLabelWithMenus(data);
      setSelectedCategory(result);
      setDispaly(true);
    },
  });

  useEffect(() => {
    if (couponTriggers.values.length) {
      fetchCategories();
    } else {
      setDispaly(true);
    }
  }, [couponTriggers]);

  return display ? (
    <Grid item xs={12} className={classes.row}>
      <Controller
        control={control}
        name={'triggerEvents.automatic.orderCategory.categoryIds'}
        defaultValue={selectedCategory}
        render={({ onChange, ...props }) => {
          return (
            <SelectFactory
              customProps={props}
              id="categories"
              label={'Category'}
              placeholder={'Category'}
              query={CATEGORIES_AND_MENUS}
              defaultValue={selectedCategory}
              error={!!errors?.categories}
              multiple={true}
              renderCheckBox={true}
              disableCloseOnSelect={true}
              control={control}
              structureData={(data) => getCategoryLabelWithMenus(data)}
              onSelect={onChange}
            />
          );
        }}
      />
    </Grid>
  ) : (
    'Loading...'
  );
}

export default OrderCategory;
