import { gql } from '@apollo/client';

const ITEM_FROM_MULTIPLE = gql`
  query itemsFromMultiple($id: ID!) {
    viewer {
      multiple(id: $id) {
        id
        itemMultipleConnection {
          edges {
            node {
              id
              item {
                id
                itemId
                label
                subcategoryItemConnection {
                edges {
                  node {
                    id
                    subcategory {
                      id
                      subcategoryId
                      menuId
                      categoryId
                    }
                  }
                }
              }
              }
            }
          }
        }
      }
    }
  }
`;

export default ITEM_FROM_MULTIPLE;
