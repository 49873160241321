import { Button, Grid } from '@material-ui/core';
import React from 'react';

function CustomActionsTop({ register, setInStock }) {
  return (
    <div>
      <input type="checkbox" ref={register} hidden={true} name="inStock" />
      <Grid container spacing={2}>
        <Grid item>
          <Button
            color="primary"
            onClick={() => {
              setInStock(true);
            }}
            variant="outlined"
          >
            All in Stock
          </Button>
        </Grid>

        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setInStock(false);
            }}
          >
            All out of Stock
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default CustomActionsTop;
