import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Elements } from '../../../../../../../blocks';
// import { Switch } from '@material-ui/core';
function SwitchButton({ location, handleLocationsChange, myLocations, control, inStock }) {
  let temp_checked;
  const myLocation = myLocations.filter(i => i.locationId === location.id)[0];
  // eslint-disable-next-line no-nested-ternary
  temp_checked = myLocation ? myLocation.available : inStock !== 'not-changed' ? inStock : false;

  if (!myLocation && inStock === 'not-changed') {
    temp_checked = {};
    temp_checked = location?.locationIngredientConnection?.edges.some(
      ing => ing.node.available === true,
    );
  }

  const [checked, setChecked] = useState(temp_checked);
  // eslint-disable-next-line no-unused-vars
  const [locationState, setLocationState] = useState({
    locationId: location.id,
    available: checked,
  });

  const handleChange = () => {
    setChecked(prevState => !prevState);
    handleLocationsChange({ ...locationState, available: !checked });
  };

  useEffect(() => {
    if (inStock !== 'not-changed' && !myLocation) {
      setChecked(inStock);
    }
  }, [inStock, myLocation]);

  return (
    // <Controller
    //   name="locationAvailability"
    //   control={control}
    //   render={() => <Switch checked={checked} onChange={handleChange} />}
    // />
    <Controller
      name="locationAvailability" // wont work... :(
      control={control}
      render={({ ref }) => (
        <Elements.Switch handleSwitchChange={handleChange} checked={checked} ref={ref} />
      )}
    />
  );
}

SwitchButton.propTypes = {
  location: PropTypes.object,
  handleLocationsChange: PropTypes.func,
  myLocations: PropTypes.array,
  control: PropTypes.object,
  inStock: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SwitchButton;
