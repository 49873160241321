import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Container, CircularProgress, Button, Grid, Typography } from '@material-ui/core';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import uglifyId from '../../../../utils/uglifyId';
import CustomerInfo from '../CustomerInfo';
import CateringInfo from '../CateringInfo';
import EventInfo from '../EventInfo';
import CATERING_ORDER_INFO_FOR_EDIT from '../../../../apollo/queries/caterings/cateringOrderInfoForEdit';
import OrderItems from '../OrderItems';
import CREATE_QUOTE_CATERING from '../../../../apollo/mutations/quotecatering/createQuotecatering';
import FETCH_LOCATIONS from '../../../../apollo/queries/fetchLocations';
import { useHistory } from 'react-router-dom';
import AdditionalInfo from '../AdditionalInfo';
import Payment from '../../../blocks/Payment';
import QuoteDetail from '../QuoteDetail';
import QuoteCateringToolbar from '../QuoteCateringToolbar/QuoteCateringToolbar';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../../../App';
import Skeleton from '@material-ui/lab/Skeleton';
import RefundPaymentModal from './RefundPaymentModal';
import UPDATE_QUOTE_CATERING from '../../../../apollo/mutations/quotecatering/updateQuoteCateringND';
import date from '../../../../utils/date';
import paths from '../../../../constants/paths';
import { CurrencyInputFormat, validateGeocode, convert, findLastRef } from '../../../../utils';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import { DataTable, Modal, Permission, PermissionField } from '../../../blocks';
import { CREATE_REFUND } from '../../../../apollo/mutations';
import { useStyles } from './styles';
import { CoreContext } from '../../../../CoreContext';
import { MUTATION_NAME, NEW_CATERING_ORDER_ID } from '../../../../constants';
import { useFormSubmitPermissions } from '../../../../hooks';
import { useSnackbar } from '../../../../hooks/useSnackbar';

export const ConnectForm = ({ children }) => {
  const methods = useFormContext();

  return children({ ...methods });
};

const CateringOrder = () => {
  const context = useContext(CoreContext);
  const { setSnackbar } = useSnackbar();

  const mutationPermissions = useMemo(
    () => [
      ...(context?.coreUtils?.getAppActions()[41]?.mutations || []),
      ...(context?.coreUtils?.getAppActions()[52]?.mutations || []),
    ],
    [context?.coreUtils],
  );

  const removeOrderItemPermission = useMemo(
    () => mutationPermissions.find(perm => perm.label === 'removeOrderItem'),
    [mutationPermissions],
  );
  const createQuoteCateringPermission = useMemo(
    () => mutationPermissions.find(perm => perm.label === 'createQuoteCatering'),
    [mutationPermissions],
  );
  const removeCreditCardPermission = useMemo(
    () => mutationPermissions.find(perm => perm.label === 'removeCreditCard'),
    [mutationPermissions],
  );
  const updateQuoteCateringPermission = useMemo(
    () => mutationPermissions.find(perm => perm.label === 'updateQuoteCateringND'),
    [mutationPermissions],
  );
  const quoteInfoPermission = useMemo(
    () => mutationPermissions.find(perm => perm.label === 'quoteInfo'),
    [mutationPermissions],
  );
  const addTipPermission = useMemo(
    () => mutationPermissions.find(perm => perm.label === 'addTip'),
    [mutationPermissions],
  );

  const { quoteId } = useParams();
  const [changedCreditCard, setChangedCreditCard] = useState(false);
  const fullQuoteId = useMemo(() => uglifyId('Quote', quoteId), [quoteId]);

  const classes = useStyles();

  const methods = useForm();
  const [locationInfo, setLocationInfo] = useState(null);
  const [eventAddress, setEventAddress] = useState({});
  const [orderItemLineId, setOrderItemLineId] = useState({});
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [disableRefundControls, setDisableRefundControls] = useState(false);
  const [refundData, setRefundData] = useState({});
  const [focusOnError, setFocusOnError] = useState(false);
  const [refundState, setRefundState] = useState({
    amount: 0,
    refundReasonId: 1,
    note: '',
  });
  const history = useHistory();
  const [orderItemsList, setOrderItemsList] = useState([]);
  const [errorRef, setErrorRef] = useState();
  const [permissions, setPermissions] = useState();
  const [refundReasonPermissions, setRefundReasonsPermissions] = useState();
  const [updateButtonIsDisabled, setUpdateButtonIsDisabled] = useState(false);

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.CreateQuotecateringNDMutationInput,
      MUTATION_NAME.UpdateQuotecateringNDMutationInput,
      MUTATION_NAME.QuoteInfoMutationInput,
      MUTATION_NAME.CreatePaymentMutationInput,
      MUTATION_NAME.SaveCreditCardMutationInput,
      MUTATION_NAME.CreateTipPaymentMutationInput,
      MUTATION_NAME.CreateCateringPaymentMutationInput,
      MUTATION_NAME.CreateRefundMutationInput,
      MUTATION_NAME.removeCreditCard,
    ],
    permissions: {
      ...(permissions || {}),
      ...(refundReasonPermissions || {}),
    },
  });

  const [createQuotecatering, { loading: createQuotecateringLoading }] = useMutation(
    CREATE_QUOTE_CATERING,
  );
  const [updateQuoteCatering, { loading: updateQuoteCateringLoading }] = useMutation(
    UPDATE_QUOTE_CATERING,
  );
  const [createRefund] = useMutation(CREATE_REFUND, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Payment refunded',
      });
      setOpenRefundModal(false);
      setDisableRefundControls(false);
    },
    onError: err => {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Something went wrong....',
      });
      setDisableRefundControls(false);
    },
  });
  // const {data: dataNote, loading: loadingNote, error: errorNote} = useQuery(LOCATION_NOTES, {
  //   variables: {
  //     filter: [{ locationId: {
  //   eq: locationInfo?.locationId
  // }}]
  //   },
  //   skip: !locationInfo?.locationId
  // });
  const { data, error, loading, refetch, variables } = useQuery(CATERING_ORDER_INFO_FOR_EDIT, {
    variables: {
      id: fullQuoteId,
    },
    onCompleted: data1 => {
      if (!permissions) {
        setPermissions({
          cateringExpertId:
            data1?.viewer?.quote?.permissions?.quotecatering?.cateringExpert?.__typename,
          cateringStatusId: data1?.viewer?.quote?.permissions?.quotecatering?.status?.__typename,
          eventType: data1?.viewer?.quote?.permissions?.quotecatering?.eventType?.__typename,
          locationId: data1?.viewer?.quote?.permissions?.location?.__typename,
          quoteId: data1?.viewer?.quote?.permissions?.quoteId,
          contactPhone: data1?.viewer?.quote?.permissions?.quotecatering?.contactPhone,
          eventDateTime: data1?.viewer?.quote?.permissions?.quotecatering?.eventDate,
          handoffType: data1?.viewer?.quote?.permissions?.handoff?.__typename,
          personId: data1?.viewer?.quote?.permissions?.person?.__typename,
          id: data1?.viewer?.quote?.permissions?.quoteId,
          source: data1?.viewer?.quote?.permissions?.person?.stripeAccount,
          tip: data1?.viewer?.quote?.permissions?.tip,
          tenderId:
            data1?.viewer?.quote?.permissions?.paymentConnection?.edges?.node?.tender?.__typename,
          gatewayId:
            data1?.viewer.quote?.permissions?.paymentConnection?.edges?.node
              ?.paymentGatewayConnection?.edges?.node?.__typename,
          amount: data1?.viewer?.quote?.permissions?.paymentConnection?.edges?.node?.amount,
          stripeCustomerId: data1?.viewer?.quote?.permissions?.person?.stripeAccount,
          stripeCardId: data1?.viewer?.quote?.permissions?.person?.stripeAccount,
        });

        if (quoteId === NEW_CATERING_ORDER_ID) {
          setOpenWarningMutationName(MUTATION_NAME.CreateQuotecateringNDMutationInput);
        } else if (quoteId) {
          setOpenWarningMutationName(MUTATION_NAME.UpdateQuotecateringNDMutationInput);
        }
      }

      data1?.viewer?.quote &&
        setLocationInfo(
          data1?.viewer?.quote.location
            ? {
                label: data1?.viewer?.quote.location?.label,
                value: data1?.viewer?.quote.locationId,
                menuId: data1?.viewer?.quote?.menuId,
                timezoneLabel: data1?.viewer?.quote.location?.timezone?.label,
                locationId: data1?.viewer?.quote.locationId,
              }
            : {},
        );
    },
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
  });
  // eslint-disable-next-line no-unused-vars
  const removeOrderItems = lineNumber => {
    setOrderItemsList(prevState => prevState.filter(element => element.id !== lineNumber));
  };

  const [
    fetchLocations,
    { data: locationsData, loading: loadingLocations, error: locationsError, called },
  ] = useLazyQuery(FETCH_LOCATIONS);

  useEffect(() => {
    // if the form finds an error, it should focus on it (workaround for Select component)
    if (errorRef && focusOnError) {
      setFocusOnError(false);
      (
        document.getElementById(errorRef.name) || document.getElementsByName(errorRef.name)?.[0]
      )?.focus();
    }
  }, [errorRef, focusOnError]);

  const shouldDisplayScreenView =
    data?.viewer?.quote || history.location.pathname.includes('new-catering-order');

  const Loading = () => {
    const loadingArray = [];
    for (let j = 0; j < 3; j++) {
      loadingArray.push(
        <Grid item xs={4} key={`Loading-Catering-${j}`}>
          <Skeleton variant={'rect'} width={'150vh'} height={'6vh'} />
        </Grid>,
      );
    }
    return (
      <Grid container spacing={2} style={{ height: '100vh' }}>
        <Grid item xs={12}>
          <Skeleton variant={'rect'} width={'150vh'} height={'6vh'} />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          {loadingArray}
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Skeleton variant={'rect'} width={'150vh'} height={'20vh'} />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          {loadingArray}
        </Grid>
      </Grid>
    );
  };
  useEffect(() => {
    data?.viewer?.quote &&
      setOrderItemsList(() =>
        data?.viewer?.quote?.lineConnection?.edges?.map((line, i) => ({
          id: line?.node?.id,
          props: {
            line: line?.node,
            locationId: {
              label: data?.viewer?.quote.location?.label,
              value: data?.viewer?.quote.location?.id,
              menuId: data?.viewer?.quote?.menuId,
              locationId: data?.viewer?.quote.locationId,
            },
            noLabel: i !== 0,
            lineNumber: i,
          },
        })),
      );
    // locationInfo &&
    //   setRerenderTriggers((prevState) => ({
    //     ...prevState,
    //     isLocationSet: locationInfo,
    //   }));
  }, [data?.viewer?.quote, locationInfo]);

  if (loading) return <Loading />;
  if (error || locationsError) return <div>error</div>;

  const selected = object => {
    const newArray = [];
    const groupdObject = {};
    //group properties by their lineNumber
    for (const property in object) {
      const lineNumber = property.split('-')[0];
      const selected = property.split('-')[1];
      for (let i = 0; i <= orderItemsList?.length + 1; i++) {
        if (parseInt(lineNumber) === i && selected === 'selected') {
          groupdObject[lineNumber] = {
            ...groupdObject[lineNumber],
            [property]: object[property],
          };
        }
      }
    }
    //Filter object for items Array
    for (const property in groupdObject) {
      const pushedItem = {
        multiples: [],
      };
      for (const key in groupdObject[property]) {
        if (key.split('-')[2] === 'subcategoryId') {
          pushedItem.subcategoryId = uglifyId('Subcategory', groupdObject[property][key]);
        }
        if (key.split('-')[2] === 'lineId') {
          const lineSubcategoryId = data?.viewer?.quote?.lineConnection?.edges.filter(
            edge => edge?.node?.id === groupdObject[property][key],
          )[0]?.node?.subcategory?.id;
          if (lineSubcategoryId) {
            pushedItem.subcategoryId = lineSubcategoryId;
          }
          pushedItem.lineId = groupdObject[property][key];
        }
        if (key.split('-')[2] === 'quantity') {
          pushedItem.quantity = parseInt(groupdObject[property][key]);
        }
        if (key.split('-')[2] === 'note') {
          pushedItem.note = groupdObject[property][key];
        }
        if (key.split('-')[2] === 'price') {
          pushedItem.price =
            groupdObject[property][key] &&
            convert.dollarsToCents(CurrencyInputFormat(groupdObject[property][key]));
        }
        if (key.split('-')[2] === 'itemId') {
          pushedItem.itemId = uglifyId('Item', groupdObject[property][key]);
        }
        if (key.split('-')[2] === 'multipleId') {
          for (const choice in groupdObject[property][key]) {
            pushedItem.multiples.push({
              multipleId: key.split('-')[3],
              choiceId: choice,
              quantity: parseInt(groupdObject[property][key][choice].quantity),
              price: convert.dollarsToCents(
                CurrencyInputFormat(groupdObject[property][key][choice].price),
              ),
            });
          }
        }
      }
      newArray.push(pushedItem);
    }
    return newArray;
  };
  const getPath = (quoteId, tab) => {
    return paths.CATERING_ORDER.replace(':quoteId', quoteId).replace(':currentTab', tab);
  };
  const onSubmit = async formData => {
    setUpdateButtonIsDisabled(true);

    const isCustomAddress = Object.keys(eventAddress).length === 0;

    let isValidAddress = false,
      lat,
      lng,
      error;
    if (formData.handoffType.value === 'SGFuZG9mZjozOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw') {
      ({ isValidAddress, lat, lng, error } = await validateGeocode(
        isCustomAddress ? formData.streetAddress : eventAddress?.address || formData.streetAddress,
        isCustomAddress ? formData.cityAddress : eventAddress?.city || formData.cityAddress,
        isCustomAddress
          ? formData.stateAddress
          : eventAddress?.state?.label || formData.stateAddress,
        isCustomAddress ? formData.zipAddress : eventAddress?.zip || formData.zipAddress,
        isCustomAddress ? formData.country?.alpha2 : eventAddress?.country?.alpha2,
      ));
    }

    if (eventAddress?.address || formData.streetAddress) {
      if (!isValidAddress) {
        return setSnackbar({
          open: true,
          type: 'error',
          text: `${error}, try a different combination of address`,
        });
      }
    }

    const newCustomer = {};
    if (!formData?.personId?.value) {
      newCustomer.customerFirstName = formData?.customerFirstName;
      newCustomer.customerLastName = formData?.customerLastName;
      newCustomer.customerEmail = formData?.customerEmail;
      newCustomer.customerPhone = formData?.customerPhone;
    }

    const input = {
      ...newCustomer,
      numberOfCustomers: parseInt(formData.numberOfCustomers),
      originId: 1,
      personId: formData?.personId?.value,
      taxId: parseInt(formData?.taxId),
      contactName: formData.contactName,
      contactPhone: formData.contactPhone,
      additionalInfo: formData.additionalInfo,
      latitude: lat,
      longitude: lng,
      address: isCustomAddress
        ? formData.streetAddress
        : eventAddress?.address || formData.streetAddress,
      city: isCustomAddress ? formData.cityAddress : eventAddress?.city || formData.cityAddress,
      addressAdditionalInfo: formData.additionalinfoStreetAddress,
      zip: isCustomAddress ? formData.zipAddress : eventAddress?.zip || formData.zipAddress,
      stateLabel: isCustomAddress
        ? formData.stateAddress
        : eventAddress?.state?.label || formData.stateAddress,
      countryId: isCustomAddress
        ? formData.country?.id
        : eventAddress?.country?.countryId || formData.country?.id,
      locationId: formData.locationId.value,
      cateringExpert: {
        cateringExpertId: formData.cateringExpert.id,
      },
      cateringStatus: {
        cateringStatusId: formData.cateringStatus.value,
      },
      eventType: formData?.eventType?.value,
      handoffType: formData.handoffType.value,
      companyName: formData.companyName,
      items: selected({ ...formData, ...orderItemLineId }),
      eventDateTime: moment
        .tz(
          moment(
            date.formatToValidDate(
              formData.eventDate,
              {
                hours: formData.eventHours,
                minutes: formData.minutesEvent,
              },
              formData.amPmEvent,
            ),
          ).format('YYYY-MM-DD HH:mm'),
          locationInfo?.timezoneLabel,
        )
        .utc()
        .format('YYYY-MM-DD HH:mm'),
    };

    // if (input?.items?.filter(item => (!item?.itemId || !item.subcategoryId)).length){
    //   setState({
    //     open: true,
    //     type: "error",
    //     text: "Order items are not completed."
    //   });
    //   return;
    // }
    if (quoteId === NEW_CATERING_ORDER_ID) {
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Quote is creating.',
      });
      createQuotecatering({
        variables: {
          input,
        },
      })
        .then(r => {
          refetch({
            id: r?.data?.createQuotecateringND?.quote?.id,
          })
            .then(r => {
              setSnackbar({
                open: true,
                type: 'success',
                text: 'Quote is created successfully.',
              });
            })
            .catch(error =>
              setSnackbar({
                open: true,
                type: 'error',
                text: error.message,
              }),
            );
          history.push(
            getPath(
              atob(r?.data?.createQuotecateringND?.quote?.id)?.split(':')?.[1],
              'generalInfo',
            ),
          );
        })
        .catch(error =>
          setSnackbar({
            open: true,
            type: 'error',
            text: error.message,
          }),
        );
    } else {
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Quote is updating.',
      });
      // Append additional info for logging
      input.cateringStatus.cateringStatusLabel = formData.cateringStatus.label;
      input.cateringExpert.cateringExpertLabel = formData.cateringExpert.label;
      updateQuoteCatering({
        variables: {
          input: {
            quoteId: data?.viewer?.quote.id,
            ...input,
          },
        },
      })
        .then(r => {
          //setChagendCreditCard
          setChangedCreditCard(false);
          refetch({
            id: r?.data?.updateQuoteCateringND?.quote?.id,
          }).then(r => {
            setUpdateButtonIsDisabled(false);
            setSnackbar({
              open: true,
              type: 'success',
              text: 'Quote is updated successfully.',
            });
          });
          if (r?.errors) {
            setUpdateButtonIsDisabled(false);
            setSnackbar({
              open: true,
              type: 'error',
              text: 'Something went wrong.',
            });
          }
        })
        .catch(error => {
          setUpdateButtonIsDisabled(false);
          setSnackbar({
            open: true,
            type: 'error',
            text: error.message,
          });
        });
    }
  };

  const addOrderItems = () => {
    setOrderItemsList(prevState => [
      ...prevState,
      {
        id: orderItemsList.length,
        props: {
          quote: data?.viewer?.quote,
          containerId: 'orderItems',
          locationId: locationInfo,
          noLabel: true,
          lineNumber: prevState.length + 1,
        },
      },
    ]);
  };
  const handleCloseRefundModal = () => {
    setRefundData({});
    setDisableRefundControls(false);
    setOpenRefundModal(false);
  };

  const handleRefund = data => {
    setDisableRefundControls(true);
    let convertedAmount;
    if (data.amount) {
      convertedAmount = convert.dollarsToCents(CurrencyInputFormat(data.amount));
      if (convertedAmount > refundData.amount - refundData.refunded) {
        setSnackbar({
          open: true,
          type: 'error',
          text: "Refund amount can't be bigger than total paid.",
        });
        setDisableRefundControls(false);
        return;
      }
    }
    if (!data.reason) {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Refund reason is required!',
      });
      setDisableRefundControls(false);
      return;
    }
    const tempRefundData = {
      paymentId: refundData.id,
      amount: convertedAmount,
      refundreasonId: btoa(`Refundreason:${+data.reason}`),
      note: data.note,
    };

    createRefund({
      variables: {
        input: tempRefundData,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Container disableGutters>
        <Card>
          {shouldDisplayScreenView ? (
            <>
              <QuoteCateringToolbar quote={data?.viewer?.quote} />
              <br />
              <form
                onSubmit={e => {
                  e.preventDefault();
                  setFocusOnError(true);
                  methods.handleSubmit(onSubmit)(e);
                }}
              >
                <ConnectForm>
                  {({ register, control, setValue, errors }) => {
                    // if there are errors, find ref of the last one so we can focus on it
                    const lastErrorRef = findLastRef(errors);

                    if (lastErrorRef !== errorRef) {
                      setErrorRef(lastErrorRef);
                    }

                    return (
                      <div className={classes.section}>
                        <CateringInfo
                          containerId={'cateringInfo'}
                          loggedInPerson={{
                            personId: data?.viewer?.personId,
                            firstName: data?.viewer?.firstName,
                            lastName: data?.viewer?.lastName,
                            loginId: data?.viewer?.loginId,
                          }}
                          fetchLocations={fetchLocations}
                          quote={data?.viewer?.quote}
                          locationsLoading={loadingLocations}
                          locationQueryCalled={called}
                          isLocationSet={locationInfo}
                          methods={methods}
                          setOrderItemLineId={setOrderItemLineId}
                          setOrderItemsList={setOrderItemsList}
                          setLocationInfo={setLocationInfo}
                          setValue={setValue}
                          register={register}
                          errors={errors}
                          refetch={refetch}
                          control={control}
                          locationInfo={locationInfo}
                          locationList={locationsData?.viewer?.locationConnection?.edges || []}
                          personList={data?.viewer?.personConnection}
                          cateringStatusList={data?.viewer?.statusConnection}
                        />
                        <br />
                        <CustomerInfo
                          quote={data?.viewer?.quote}
                          containerId={'customerInfo'}
                          setChangedCreditCard={setChangedCreditCard}
                          customer={data?.viewer?.quote?.person}
                          setValue={setValue}
                          isLocationSet={locationInfo}
                          control={control}
                          personList={data?.viewer?.personConnection}
                          register={register}
                          errors={errors}
                        />
                        <br />
                        <EventInfo
                          quote={data?.viewer?.quote}
                          control={control}
                          setValue={setValue}
                          containerId={'eventInfo'}
                          setEventAddress={setEventAddress}
                          handoffTypes={data?.viewer?.handoffConnection}
                          eventTypes={data?.viewer?.eventTypeConnection}
                          register={register}
                          isLocationSet={locationInfo}
                          errors={errors}
                        />
                        <br />
                        {/* TODO */}
                        {[
                          ...orderItemsList?.map(el => (
                            <OrderItems
                              id={el.id}
                              key={el.id}
                              quote={data?.viewer?.quote}
                              containerId={'orderItems'}
                              line={el.props.line}
                              control={control}
                              setOrderItemsList={setOrderItemsList}
                              setOrderItemLineId={setOrderItemLineId}
                              setValue={setValue}
                              refetch={refetch}
                              register={register}
                              locationId={locationInfo}
                              noLabel={el.props.noLabel}
                              errors={errors}
                              removeOrderItem={removeOrderItems}
                              lineNumber={el.props.lineNumber}
                              removeOrderItemPermission={removeOrderItemPermission}
                            />
                          )),
                        ]}
                        <Grid container spacing={3} style={{ padding: '0 1rem' }}>
                          <Grid item>
                            <Button onClick={() => addOrderItems()}>Add Item</Button>
                          </Grid>
                          <Grid item>
                            {quoteId === 'new-catering-order' ? (
                              <Permission
                                access={Math.min(
                                  createQuoteCateringPermission?.access,
                                  accessToMutations?.[
                                    MUTATION_NAME.CreateQuotecateringNDMutationInput
                                  ],
                                )}
                              >
                                <div>
                                  <PermissionField createHelperText>
                                    <Button
                                      type={'submit'}
                                      disabled={
                                        createQuotecateringLoading || updateQuoteCateringLoading
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </PermissionField>
                                </div>
                              </Permission>
                            ) : (
                              <Permission
                                access={Math.min(
                                  updateQuoteCateringPermission?.access,
                                  accessToMutations?.[
                                    MUTATION_NAME.UpdateQuotecateringNDMutationInput
                                  ],
                                )}
                              >
                                <div>
                                  <PermissionField createHelperText>
                                    <Button
                                      type={'submit'}
                                      disabled={
                                        createQuotecateringLoading ||
                                        updateQuoteCateringLoading ||
                                        updateButtonIsDisabled
                                      }
                                    >
                                      Update Quote
                                    </Button>
                                  </PermissionField>
                                </div>
                              </Permission>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    );
                  }}
                </ConnectForm>
              </form>
              <div className={classes.section}>
                <Elements stripe={stripePromise}>
                  <AdditionalInfo
                    quote={data?.viewer?.quote}
                    variables={variables}
                    quoteInfoPermission={quoteInfoPermission}
                    accessToMutations={accessToMutations}
                  />
                </Elements>
              </div>
              <div className={classes.section}>
                <div className={classes.root}>
                  <span>
                    <h2 className={classes.title}>Payment info</h2>
                  </span>
                </div>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={4}>
                    <QuoteDetail
                      quote={data?.viewer?.quote}
                      accessToMutations={accessToMutations}
                      addTipPermission={addTipPermission}
                    />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Elements stripe={stripePromise}>
                      <Payment
                        quote={data?.viewer?.quote}
                        changedCreditCard={changedCreditCard}
                        personId={null}
                        isQuoteCatering={true}
                        accessToMutations={accessToMutations}
                        removeCreditCardPermission={removeCreditCardPermission}
                        addTipPermission={addTipPermission}
                      />
                    </Elements>
                  </Grid>
                </Grid>
              </div>
              {data?.viewer?.quote?.paymentConnection?.edges.length ? (
                <Permission
                  access={
                    data?.viewer?.quote?.permissions?.paymentConnection?.edges?.node?.__typename
                  }
                >
                  <div style={{ padding: '0 1rem' }}>
                    <Typography variant="h3" component="h2">
                      Payments
                    </Typography>
                    <DataTable
                      data={data?.viewer?.quote?.paymentConnection?.edges}
                      /* eslint-disable-next-line no-shadow */
                      structureTable={paymentConnectionEdges =>
                        paymentConnectionEdges?.map(edge => {
                          var cardLabel = '';
                          if (edge?.node?.paymentGatewayConnection?.edges[0]?.node?.brand) {
                            cardLabel = `${edge?.node?.paymentGatewayConnection?.edges[0]?.node?.brand} ending in ${edge?.node.paymentGatewayConnection?.edges[0]?.node?.label}`;
                          } else if (edge?.node?.paymentGatewayConnection?.edges[0]?.node?.label) {
                            cardLabel = `...X-${edge?.node?.paymentGatewayConnection?.edges[0]?.node?.label}`;
                          }

                          return {
                            paymentId: edge?.node.paymentId,
                            tender: edge?.node?.tender.label,
                            cardNo: cardLabel,
                            created: moment(edge.node.created).format('YYYY-MM-DD hh:mm A'),
                            amount: `$${convert.centsToDollars(edge?.node?.amount)}`,
                            refunded: `$${convert.centsToDollars(edge?.node?.refunded)}`,
                            payment: edge?.node,
                          };
                        })
                      }
                      columns={[
                        {
                          title: 'Payment ID',
                          field: 'paymentId',
                          customPermissionField: 'paymentConnection.edges.node.paymentId',
                        },
                        {
                          title: 'Tender',
                          field: 'tender',
                          customPermissionField: 'paymentConnection.edges.node.tender.__typename',
                        },
                        {
                          title: 'Card No.',
                          field: 'cardNo',
                          customPermissionField:
                            'paymentConnection.edges.node.paymentGatewayConnection.edges.node.__typename',
                        },
                        {
                          title: 'Processed Date',
                          field: 'created',
                          customPermissionField: 'paymentConnection.edges.node.created',
                        },
                        {
                          title: 'Total Paid',
                          field: 'amount',
                          customPermissionField: 'paymentConnection.edges.node.amount',
                        },
                        {
                          title: 'Total Refunded',
                          field: 'refunded',
                          customPermissionField: 'paymentConnection.edges.node.refunded',
                        },
                        {
                          title: '',
                          field: 'payment',
                          render: data => {
                            return (
                              <Button
                                disabled={data.amount === data.refunded}
                                onClick={() => {
                                  setRefundData(data);
                                  setOpenRefundModal(true);
                                  setOpenWarningMutationName(
                                    MUTATION_NAME.CreateRefundMutationInput,
                                  );
                                }}
                                style={
                                  data.amount === data.refunded
                                    ? { background: 'gray', color: 'white' }
                                    : { background: 'red', color: 'white' }
                                }
                              >
                                Refund
                              </Button>
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                </Permission>
              ) : null}
              {data?.viewer?.quote?.location?.locationnoteConnection && (
                <Permission
                  access={
                    data?.viewer?.quote?.permissions?.location?.locationnoteConnection?.edges?.node
                      ?.__typename
                  }
                >
                  <div style={{ padding: '0 1rem 2rem 1rem' }}>
                    <Typography variant="h3" component="h2">
                      Location Notes
                    </Typography>
                    <DataTable
                      data={data?.viewer?.quote?.location?.locationnoteConnection}
                      /* eslint-disable-next-line no-shadow */
                      structureTable={locationNoteData =>
                        locationNoteData?.edges?.map(location => ({
                          id: location?.node.id,
                          note: location?.node?.note,
                          created: moment
                            .tz(
                              moment.utc(location.node.created),
                              data?.viewer?.quote?.location?.timezone?.label,
                            )
                            .format('YYYY-MM-DD hh:mm A'),
                        }))
                      }
                      columns={[
                        {
                          title: 'Note',
                          field: 'note',
                          customPermissionField:
                            'location.locationnoteConnection.edges.node.__typename',
                        },
                        {
                          title: 'Date',
                          field: 'created',
                          customPermissionField:
                            'location.locationnoteConnection.edges.node.created',
                        },
                      ]}
                    />
                  </div>
                </Permission>
              )}
              <Permission
                access={
                  data?.viewer?.quote?.permissions?.quotecatering?.quoteNoteConnection?.edges?.node
                    ?.__typename
                }
              >
                <div style={{ padding: '0 1rem 2rem 1rem' }}>
                  <DataTable
                    data={data?.viewer?.quote}
                    title="Notes"
                    structureTable={data => {
                      return data?.quotecatering?.quoteNoteConnection?.edges.map(note => {
                        return {
                          note: note.node.note,
                          date: moment
                            .tz(moment.utc(note?.node?.created), data?.location?.timezone?.label)
                            .format('YYYY-DD-MM hh:mm A'),
                          creator: note?.node?.creator
                            ? `${note?.node?.creator?.firstName} ${note?.node?.creator?.lastName}`
                            : '',
                        };
                      });
                    }}
                    columns={[
                      {
                        title: 'NOTE',
                        field: 'note',
                        customPermissionField: 'quotecatering.quoteNoteConnection.edges.node.note',
                      },
                      {
                        title: 'DATE',
                        field: 'date',
                        customPermissionField:
                          'quotecatering.quoteNoteConnection.edges.node.created',
                      },
                      {
                        title: 'CREATOR',
                        field: 'creator',
                        customPermissionField:
                          'quotecatering.quoteNoteConnection.edges.node.creator.__typename',
                      },
                    ]}
                  />
                </div>
              </Permission>
            </>
          ) : (
            loading && <CircularProgress />
          )}
        </Card>
      </Container>
      <Modal
        maxWidth="md"
        title={`Refund Payment - ${refundData.paymentId}`}
        open={openRefundModal}
        handleClose={handleCloseRefundModal}
      >
        <RefundPaymentModal
          data={refundData}
          disableRefundControls={disableRefundControls}
          handleCloseRefundModal={handleCloseRefundModal}
          handleRefund={handleRefund}
          refundState={refundState}
          setRefundState={setRefundState}
          setRefundReasonsPermissions={setRefundReasonsPermissions}
          accessToMutations={accessToMutations}
          refundReasonPermissions={refundReasonPermissions}
        />
      </Modal>
    </FormProvider>
  );
};

export default CateringOrder;
