import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import ConnectionTable from "../../../../blocks/ConnectionTable";
import { USERGROUPS } from "../../../../../apollo/queries";
import { Button } from "@material-ui/core";
import { Modal } from "../../../../blocks";
import { CreateUsergroupForm } from "../Usergroup";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CreateIcon from "@material-ui/icons/Create";
import MANIPULATE_USERGROUP from "../../../../../apollo/mutations/usergroups/manipulateUsergroup";
import DELETE_USERGROUP from "../../../../../apollo/mutations/usergroups/deleteUsergroup";
import { SNACKBAR_STATUS } from "../../../../../constants";
import { useSnackbar } from "../../../../../hooks/useSnackbar";

const UsergroupList = () => {
  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const [tableState, setTableState] = useState();
  const [manipulateUsergroup] = useMutation(MANIPULATE_USERGROUP);
  const [deleteUsergroup, { loading: isDeletingUsergroup }] = useMutation(
    DELETE_USERGROUP
  );
  const [updateData, setUpdateData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (query) => {
    setTableState(query);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setUpdateData(null);
    setShowModal(false);
  };

  const handleDeleteUsergroup = useCallback(
    (row, variables) => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.INFO,
        text: "Deleting usergroup...",
      });

      deleteUsergroup({
        variables: {
          usergroupId: row.usergroupId,
        },
        refetchQueries: [{ query: USERGROUPS, variables }],
      })
        .then(() =>
        setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: "Usergroup deleted",
          })
        )
        .catch((error) =>
        setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
            open: true,
          })
        );
    },
    [deleteUsergroup, setSnackbar]
  );

  return (
    <>
      <ConnectionTable
        query={USERGROUPS}
        initialQueryVariables={{
          first: 10,
          stateFirst: 50,
        }}
        title="Access Management"
        customActionsTop={(data, query) => {
          // pass table state so we can refresh it in cache later
          return (
            <Button
              onClick={() => handleOpenModal(query)}
              disabled={isDeletingUsergroup}
            >
              New Usergroup
            </Button>
          );
        }}
        structureTable={(data) =>
          data?.viewer?.usergroupConnection?.edges.map((usergroup) => ({
            id: usergroup.node.id,
            usergroupId: usergroup.node.usergroupId,
            label: usergroup.node.label,
            usergroup,
          }))
        }
        onTableRowClick={(row) => {
          return history.push(
            `${history.location.pathname}/${row.usergroupId}/rules`
          );
        }}
        selectableRows={false}
        columns={[
          { title: "Id", field: "usergroupId" },
          { title: "Label", field: "label" },
          {
            title: "Copy Usergroup",
            disableSort: true,
            field: "label", // cloning is by label and not by id, not ideal
            render: (usergroupLabel, variables) => (
              <FileCopyIcon
                onClick={async () => {
                  await manipulateUsergroup({
                    variables: {
                      usergroupLabel,
                      action: "Copy",
                    },
                    refetchQueries: [{ query: USERGROUPS, variables }],
                  });
                  setSnackbar({
                    open: true,
                    type: "success",
                    text: "Usergroup copied",
                  });
                }}
              />
            ),
          },
          {
            title: "Update Label",
            disableSort: true,
            field: "usergroup",
            render: ({ node }, variables) => (
              <CreateIcon
                onClick={() => {
                  setUpdateData(node);
                  handleOpenModal(variables);
                }}
              />
            ),
          },
        ]}
        handleDeleteRow={handleDeleteUsergroup}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete usergroup?"
        isDeleting={isDeletingUsergroup}
      />
      <Modal
        open={showModal}
        handleClose={handleCloseModal}
        title={updateData ? "Update usergroup" : "Create usergroup"}
        tableState={tableState}
      >
        <CreateUsergroupForm
          handleClose={handleCloseModal}
          updateData={updateData}
          tableState={tableState}
        />
      </Modal>
    </>
  );
};

export default UsergroupList;
