import React from "react";
import LoyaltyPointsModaStyles from "./styles";
import { PermissionField } from "../../../../../../../blocks";
import {
  TextField,
  Typography,
  Grid,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import LOYALTY_POINT_REASON from "../../../../../../../../apollo/queries/loyalty/loyaltyPointReason";
import { Elements } from "../../../../../../../blocks";
import { useForm } from "react-hook-form";
import { useSnackbar } from "../../../../../../../../hooks/useSnackbar";
import UPDATE_LOYATLY_POINTS from "../../../../../../../../apollo/mutations/loyalty/updateLoyaltyPoints";
import { SNACKBAR_STATUS } from "../../../../../../../../constants";
const ADD_POINTS_MANUALLY_ID = 6;
const SUBTRACTED_POINTS_MANUALLY_ID = 5;

export default function LoyaltyPointsModal({
  customerData,
  handleCloseModal,
  operationType,
  handleClose,
}) {
  const classes = LoyaltyPointsModaStyles();
  const { handleSubmit, control, register, errors } = useForm();
  const { setSnackbar } = useSnackbar();

  const { data: loyaltyPointReasonData, loading, error } = useQuery(
    LOYALTY_POINT_REASON,
    {
      variables: {
        filter: {
          loyaltypointreasonId: {
            neq:
              operationType === "ADD"
                ? SUBTRACTED_POINTS_MANUALLY_ID
                : ADD_POINTS_MANUALLY_ID,
          },
        },
      },
    }
  );
  const [
    updateLoyaltyPoints,
    { loading: updateLoyaltyPointsLoading },
  ] = useMutation(UPDATE_LOYATLY_POINTS, {
    update: (cache, data) => {
      cache.modify({
        id: `Viewer:${loyaltyPointReasonData?.viewer?.id}`,
        fields: {
          [`loyaltypointlogConnection({"filter":{"personId":{"eq":${customerData?.node?.personId}}},"first":10,"sort":{"created":"DESC"}})`]: (
            existingLoyaltyPointLogConnection
          ) => {
            const newUpdatedLoyaltyPointLogConnection = [
              ...existingLoyaltyPointLogConnection.edges,
            ];

            newUpdatedLoyaltyPointLogConnection.unshift(
              data?.data?.updateLoyaltyPoints?.loyaltypointlogEdge
            );

            newUpdatedLoyaltyPointLogConnection.pop();
            return {
              ...existingLoyaltyPointLogConnection,
              totalCount: existingLoyaltyPointLogConnection.totalCount + 1,
              edges: newUpdatedLoyaltyPointLogConnection,
            };
          },
        },
      });
    },
  });
  if (loading) return <div>Loading...</div>;
  if (error) console.log(error, "error");

  const onSubmit = ({ points, reasonId }) => {
    updateLoyaltyPoints({
      variables: {
        input: {
          personId: customerData?.node?.id,
          points,
          operationType,
          reasonTypeId: reasonId,
        },
      },
    })
      .then(() => {
        setSnackbar({
          text: "Points are successfully updated!.",
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
        });
        handleCloseModal();
      })
      .catch((e) =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        })
      );
  };
  if (loyaltyPointReasonData)
    return (
      <Grid container style={{ flexDirection: "column" }}>
        <div className={classes.titleContainer}>
          <div>
            <Typography
              variant="subtitle2"
              component="div"
              gutterBottom
              className={classes.subtitleMarginRight}
            >
              Spendable Points
            </Typography>
            {customerData?.node?.login?.spendablePoints}
          </div>
          <div>
            <Typography variant="subtitle2" component="div" gutterBottom>
              Liftime Points
            </Typography>
            {customerData?.node?.login?.lifetimePoints}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ marginTop: "1rem" }}>
            <Grid item xs={12}>
              <PermissionField
                Component={TextField}
                name="points"
                type={"number"}
                label="Points"
                inputRef={register({
                  required: true,
                  validate: {
                    positive: (value) => {
                      return value >= 0;
                    },
                  },
                })}
                error={!!errors.points}
                fullWidth
                helperText={
                  errors?.points?.required
                    ? "Points are required."
                    : errors?.points?.type === "positive" &&
                    "Points must be a postive number."
                }
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "1rem 0" }}>
              <PermissionField
                Component={Elements.Select}
                name="reasonId"
                label="Reason"
                control={control}
                rules={{ required: true }}
                error={!!errors.reasonId}
                helperText={errors.reasonId && "Reason is required."}
                shrink
                fullWidth
              >
                {loyaltyPointReasonData?.viewer?.loyaltypointreasonConnection?.edges?.map(
                  (reason) => (
                    <MenuItem
                      value={reason?.node?.loyaltypointreasonId}
                      key={reason?.node?.loyaltypointreasonId}
                    >
                      {reason?.node?.label}
                    </MenuItem>
                  )
                )}
              </PermissionField>
            </Grid>
          </Grid>
          <Grid item justify="flex-end" spacing={3} xs={12}>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={() => handleCloseModal()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={updateLoyaltyPointsLoading}
                type="submit"
                style={{ marginLeft: "0.3rem" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
}
