import { Button, FormControl, InputLabel, MenuItem, Select, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FETCH_GENERAL_LEDGER_ACCOUNT } from '../../../../../../apollo/queries';
import { Modal } from '../../../../../blocks';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import GeneralLedgerAccountModal from './GeneralLedgerAccountModal';
import { renderStatus, statusOptions } from '../../../../../../constants/statuses';
import { isEmpty } from 'lodash-es';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { REMOVE_GENERALLEDGER } from '../../../../../../apollo/mutations/inventory/removeGeneralLeadger';

const GeneralLedgerAccounts = () => {
  const { setSnackbar } = useSnackbar();
  const [isGeneralLedgerModalOpen, setIsGeneralLedgerModalOpen] = useState(false);
  const [generalLedger, setGeneralLedger] = useState();

  const [remove, { loading }] = useMutation(REMOVE_GENERALLEDGER, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'General ledger account removed',
        open: true,
      });
    },
    onError: data => {
      setSnackbar({
        type: SNACKBAR_STATUS.WARNING,
        text: data.graphQLErrors[0].message,
        open: true,
      });
    },
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(data?.removeGeneralledger?.viewer),
        fields: {
          generalledgeraccountConnection() {
            return {
              __typename: 'Generalledgeraccount',
            };
          },
        },
      });
    },
  });

  const handleOnRowClick = useCallback(({ data }) => {
    setGeneralLedger(data);
    setIsGeneralLedgerModalOpen(true);
  }, []);

  const handleOnDeleteClick = useCallback(id => {
    remove({
      variables: {
        generalledgerId: id,
      },
    });
  }, []);

  const handleCloseGeneralLedgerModal = useCallback(() => {
    setGeneralLedger();
    setIsGeneralLedgerModalOpen(false);
  }, []);

  const handleOnAddGeneralLedgerClick = useCallback(() => {
    setIsGeneralLedgerModalOpen(true);
  }, []);

  const handleOnStatusChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.active) {
        delete filters.active;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          active: {
            eq: !!value,
          },
        },
      });
    }
  }, []);

  return (
    <>
      <ConnectionTable
        title="General Ledger Accounts"
        customActionsTop={() => (
          <Button onClick={handleOnAddGeneralLedgerClick} fullWidth>
            Add New GL Account
          </Button>
        )}
        customActionsCenter={(_, { refetch, variables }) => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  multiple={false}
                  defaultValue={-1}
                  onChange={({ target: { value } }) =>
                    handleOnStatusChange({ value, refetch, variables })
                  }
                >
                  {[{ value: -1, label: 'All', color: '#747480' }, ...statusOptions]?.map(
                    option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          color: option.color,
                          textTransform: option.value !== -1 ? 'uppercase' : 'unset',
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        columns={[
          { field: 'generalLedgerAccountId', title: 'ID' },
          { field: 'code', title: 'Code' },
          {
            field: 'label',
            title: 'Name',
          },
          {
            field: 'status',
            title: 'Status',
            render: renderStatus,
            enablePropagation: true,
            disableSort: true,
          },
          { field: 'updated', customSortField: 'created', title: 'Updated' },
        ]}
        query={FETCH_GENERAL_LEDGER_ACCOUNT}
        initialQueryVariables={{
          fieldGroupOnly: 94,
        }}
        structureTable={data =>
          data?.viewer?.generalledgeraccountConnection?.edges?.map(edge => ({
            label: edge.node.label,
            code: edge.node.code,
            generalLedgerAccountId: edge.node.generalLedgerAccountId,
            status: edge.node.active,
            updated: moment(edge.node.created).format('MM/DD/YYYY'),
            data: edge.node,
          })) || []
        }
        onTableRowClick={handleOnRowClick}
        handleDeleteRow={row => {
          handleOnDeleteClick(row.generalLedgerAccountId);
        }}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this ledger account?"
      />
      <Modal
        open={isGeneralLedgerModalOpen}
        handleClose={handleCloseGeneralLedgerModal}
        title={generalLedger?.generalLedgerAccountId ? 'Edit GL Account' : 'Add GL Account'}
      >
        <GeneralLedgerAccountModal
          generalLedger={generalLedger}
          handleClose={handleCloseGeneralLedgerModal}
        />
      </Modal>
    </>
  );
};

export default GeneralLedgerAccounts;
