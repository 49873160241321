import { gql } from '@apollo/client';
import { quotecateringFragment, quoteForCateringFragment } from "../../fragments";

const CATERING_ORDER_INFO_FOR_EDIT = gql`
  query quote($id: ID!) {
    viewer {
      id
      personId
      firstName
      lastName
      loginId
      quote(id: $id, fieldGroup: 52) {
        created
        ...QuoteFromCateringData
        quotecatering {
          ...QuotecateringData
        }
          permissions
          menu  {
          id
          menuId
          label
        }
       
      }
      eventTypeConnection {
        permissions
        edges {
          node {
            id
            label
          }
        }
      }
      handoffConnection {
        permissions
        edges {
          node {
            id
            label
          }
        }
      }
      statusConnection {
        permissions
        edges {
          node {
            id
            label
            statusId
            actionColor {
            id
            code
           }
          }
        }
      }
    }
  }
 
  ${quoteForCateringFragment.quote}
  ${quotecateringFragment.quotecatering}
`;
export default CATERING_ORDER_INFO_FOR_EDIT;
