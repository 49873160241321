import { Typography } from '@material-ui/core';
import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { EQUIPMENTS } from '../../../../../../../../apollo/queries';
import { SelectFactory } from '../../../../../../../blocks';
import NumberInput from '../../../../../../../blocks/NumberInput';

const EquipmentItem = ({ id, item, index, register, errors, control }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} justify="center" container alignContent="center">
        <Typography variant="body1">{index + 1}.</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Controller
          control={control}
          key={`${id}.equipmentId`}
          name={`equipments.${index}.equipmentId`}
          defaultValue={item?.equipmentId || ''}
          rules={{ required: true }}
          render={props => (
            <SelectFactory
              customProps={props}
              error={errors.equipments && !!errors.equipments[index]?.equipmentId}
              helperText={
                errors.equipments && !!errors.equipments[index]?.equipmentId
                  ? 'Equipment name is required'
                  : ''
              }
              label="Equipment name"
              fullWidth
              placeholder="Equipment name"
              query={EQUIPMENTS}
              renderCheckBox={false}
              defaultValue={item?.equipmentId || ''}
              disableCloseOnSelect={false}
              multiple={false}
              structureData={data => {
                return data?.viewer?.equipmentConnection?.edges.map(edge => {
                  return {
                    label: edge.node.label,
                    id: edge.node.equipmentId,
                  };
                });
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={5} md={2}>
        <NumberInput
          decimalScale={0}
          key={`${id}.count`}
          defaultValue={item?.count || ''}
          id={`equipments.${index}.count`}
          required
          label="Count"
          placeholder={'1'}
          error={errors.equipments && !!errors.equipments[index]?.count}
          helperText={
            errors.equipments && errors.equipments[index]?.count?.type === 'required'
              ? 'Value is required'
              : ''
          }
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          inputRef={register()}
          fullWidth
          key={`${id}.note`}
          label="Note"
          name={`equipments.${index}.note`}
          defaultValue={item?.note}
          variant="outlined"
          placeholder="Note"
        />
      </Grid>
    </Grid>
  );
};

export default EquipmentItem;
