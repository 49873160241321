import { gql } from '@apollo/client';

const FETCH_CATERING_EXPERT = gql`
  query fetchCateringExpert(
    $first: Int
    $filter: [LoginFilter]
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LoginSort]
    $specificFields: [String]
  ) {
    viewer {
      id
      loginConnection(
        first: $first
        filter: $filter
        last: $last
        after: $after
        before: $before
        search: $search
        sort: $sort
        specificFields: $specificFields
        fieldGroup: 52
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        permissions
        edges {
          node {
            email
            personId
            id
            person {
              id
              personId
              firstName
              lastName
              phone {
                id
                phone
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_CATERING_EXPERT;
