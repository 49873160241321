import { useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CREATE_VENDOR_PACK_SIZE from '../../../../../../../apollo/mutations/inventory/createVendorPackSize';
import UPDATE_VENDOR_PACK_SIZE from '../../../../../../../apollo/mutations/inventory/updateVendorPackSize';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { statusOptions } from '../../../../../../../constants/statuses';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const defaultValues = {
  packSize: '',
  active: '',
};

const UnitOfMeasureModal = ({ handleClose, vendorPackSize }) => {
  const { control, errors, handleSubmit, register, reset } = useForm(defaultValues);

  const { setSnackbar } = useSnackbar();

  const [createVendorPackSize] = useMutation(CREATE_VENDOR_PACK_SIZE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Vendor Pack Size created',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createVendorproductmeasure?.viewer),
        fields: {
          vendorproductmeasureConnection() {
            return {
              __typename: 'VendorproductmeasureEdge',
            };
          },
        },
      });
    },
  });

  const [updateVendorPackSize] = useMutation(UPDATE_VENDOR_PACK_SIZE, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Vendor Pack Size updated',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (vendorPackSize?.vendorproductmeasureId) {
      reset({
        packSize: vendorPackSize.packSize,
        active: vendorPackSize.active ? 1 : 0,
      });
    } else {
      reset(defaultValues);
    }
  }, [vendorPackSize]);

  const onSubmit = useCallback(
    formData => {
      const input = {
        packSize: formData.packSize,
        active: !!formData.active,
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: vendorPackSize?.vendorproductmeasureId
          ? 'Updating Vendor Pack Size...'
          : 'Creating Vendor Pack Size...',
        open: true,
      });

      if (vendorPackSize?.vendorproductmeasureId) {
        input.vendorproductmeasureId = vendorPackSize.vendorproductmeasureId;

        updateVendorPackSize({ variables: { input } });
      } else {
        createVendorPackSize({ variables: { input } });
      }
    },
    [vendorPackSize?.vendorproductmeasureId],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            label="Name"
            name="packSize"
            variant="outlined"
            placeholder="Name"
            error={errors.packSize}
            helperText={!!errors.packSize && 'Name is required'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.active}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="active"
              name="active"
              label="Status"
              placeholder="Status"
              defaultValue={vendorPackSize?.active ? 1 : 0}
              error={!!errors?.active}
              as={
                <Select label="Status" multiple={false} error={!!errors?.active}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.active?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit">
                {vendorPackSize?.vendorproductmeasureId ? 'Save' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UnitOfMeasureModal;
