import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import CreateUpdateAllergenModalView from './View';
import { ALLERGENS_LIST } from '../../../../../../apollo/queries';
import { CREATE_ALLERGEN, UPDATE_ALLERGEN } from '../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

function CreateUpdateAllergenModal({ allergenId, setAllergenId, handleClose, query }) {
  const { setSnackbar } = useSnackbar();

  const [allergen, setAllergen] = useState(null);
  const [tempAllergenId, setTempAllergenId] = useState(allergenId);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      label: '',
      description: '',
    },
  });
  const [createAllergen] = useMutation(CREATE_ALLERGEN, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Allergen created',
      });
      handleClose();
    },
    onError: () => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Oops, something went wrong',
      });
    },
  });

  const [updateAllergen] = useMutation(UPDATE_ALLERGEN, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Allergen updated',
      });
      setAllergenId(null);
      handleClose();
    },
    onError: () => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Oops, something went wrong',
      });
    },
  });

  useEffect(() => {
    if (allergenId) {
      setTempAllergenId(allergenId);
      return;
    }
    setTempAllergenId(null);
  }, [allergenId]);

  const { loading } = useQuery(ALLERGENS_LIST, {
    variables: { filter: { id: { eq: tempAllergenId } }, fieldGroupOnly: 89 },
    onCompleted: data => {
      setAllergen(data.viewer.allergenConnection.edges[0].node);
      const { label, description } = data.viewer.allergenConnection.edges[0].node;
      reset({
        label,
        description,
      });
    },
    skip: !tempAllergenId,
  });

  if (loading) {
    return '';
  }

  const submit = async data => {
    if (data.label.trim() === '') {
      setSnackbar({
        open: true,
        type: 'warning',
        text: "Allergen 'name' is required field.",
      });
      return;
    }
    data.label = data.label.trim();
    data.description = data.description.trim();
    setButtonDisabled(true);
    if (!tempAllergenId) {
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Creating allergen...',
      });
      await createAllergen({
        variables: { ...query.variables, input: data },
        refetchQueries: [{ query: ALLERGENS_LIST, variables: { ...query.variables } }],
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Updating allergen...',
      });
      data.id = tempAllergenId;
      await updateAllergen({
        variables: { input: data },
      });
    }
  };

  return (
    <CreateUpdateAllergenModalView
      handleClose={handleClose}
      allergen={allergen}
      handleSubmit={handleSubmit}
      register={register}
      buttonDisabled={buttonDisabled}
      submit={submit}
    />
  );
}

CreateUpdateAllergenModal.propTypes = {
  allergenId: PropTypes.string,
  setAllergenId: PropTypes.func,
  temp_open: PropTypes.object,
  setTemp_open: PropTypes.func,
};
export default CreateUpdateAllergenModal;
