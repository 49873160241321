import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  row: {
    margin: '10px 0',
  },
  minQty: {
    padding: '0 15px',
  },
  maxQty: {
    paddingRight: '15px',
  },
});

const ItemGroupStyles = makeStyles(Styles);

export default ItemGroupStyles;
