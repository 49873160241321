import { gql } from '@apollo/client';

export const SAVE_VENDOR_INVOICE = gql`
  mutation saveVendorInvoice($invoiceId: Int!, $products: [invoiceProducts]!) {
    submitVendorInvoice(input: { invoiceId: $invoiceId, isSubmitted: false, products: $products }) {
      clientMutationId
      invoice {
        id
      }
    }
  }
`;
