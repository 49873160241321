import { gql } from '@apollo/client';

const CREATE_LOCATION_STORAGE = gql`
  mutation CREATE_LOCATION_STORAGE($input: CreateLocationStorageInput!) {
    createLocationStorage(input: $input) {
      viewer {
        id
      }
      locationStorage {
        locationStorageId
      }
    }
  }
`;

export default CREATE_LOCATION_STORAGE;
