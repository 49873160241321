const getCategoryLabelWithMenus = (data) => {
  const menuCategoryData = data?.viewer?.menuCategoryConnection?.edges?.map(
    (menuCategory, index) => {
      return {
        categoryId: menuCategory?.node?.category?.categoryId,
        categoryLabel: menuCategory?.node?.category?.label,
        menuLabel: menuCategory?.node?.menu?.label,
        index: menuCategory?.node?.category?.categoryId,
        id: menuCategory?.node?.category?.id,
      };
    },
  );

  // Iterate over all menus in which the category is contained and update the label
  const categoryData = [];
  menuCategoryData?.map((menuCategory) => {
    const existingIdIndex = categoryData.findIndex(
      (category) => category?.categoryId === menuCategory.categoryId,
    );

    if (existingIdIndex >= 0) {
      const oldSplitLabel = categoryData[existingIdIndex].label.split('(');
      const newLabel = `${oldSplitLabel[0].trim()} (${
        menuCategory.menuLabel
      }, ${oldSplitLabel[1].trim()}`;

      categoryData[existingIdIndex] = {
        ...categoryData[existingIdIndex],
        label: newLabel,
      };
    } else {
      categoryData.push({
        categoryId: menuCategory.categoryId,
        label: `${menuCategory.categoryLabel} (${menuCategory.menuLabel})`,
        index: menuCategory?.index,
        id: menuCategory?.id,
      });
    }
  });

  return categoryData;
};

export default getCategoryLabelWithMenus;
