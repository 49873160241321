import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useCallback } from 'react';

import { UPDATE_LOYALTY_EVENT } from '../../../../apollo/mutations';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import LoyaltyEventsView from './View';

const LoyaltyEvents = () => {
  const { setSnackbar } = useSnackbar();
  const [loyaltyUpdateData, setLoyaltyUpdateData] = useState(null);
  const [open, setOpen] = useState(false);
  const [connectionVariables, setConnectionVariables] = useState({});

  const [
    updateLoyaltyevent,
    { error: updateLoyaltyError, loading: updateLoyaltyeventLoading, data: updateLoyaltyData },
  ] = useMutation(UPDATE_LOYALTY_EVENT, {
    variables: {
      sort: { loyaltyeventId: 'ASC' },
      first: 15,
    },
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Loyalty Event Updated',
      });
      handleClose();
    },
    onError: error => {
      console.dir(error);
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
  });

  const handleClose = () => {
    setOpen(false);
    setLoyaltyUpdateData(null);
  };

  const handleOnUpdateLoyaltyevent = data => {
    data.loyaltyeventId = loyaltyUpdateData?.loyaltyeventId;

    updateLoyaltyevent({
      variables: {
        input: data,
      },
    });
  };

  return (
    <LoyaltyEventsView
      handleClose={handleClose}
      loyaltyUpdateData={loyaltyUpdateData}
      open={open}
      setLoyaltyUpdateData={setLoyaltyUpdateData}
      setOpen={setOpen}
      handleOnUpdateLoyaltyevent={handleOnUpdateLoyaltyevent}
      setConnectionVariables={setConnectionVariables}
    />
  );
};

export default LoyaltyEvents;
