import { gql } from '@apollo/client';

const VENDOR_LOCATIONS_LIST = gql`
  query VendorLocation (
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationVendorSort]
    $filter: [LocationVendorFilter]
    $specificFields: [String]
  ) {
    viewer {
      id
      locationVendorConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        specificFields: $specificFields
        fieldGroupOnly: 76
      ) {
        totalCount
        permissions
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            vendor {
              id
              vendorId
              label
            }
            location {
              id
              locationId
              label
              address {
                state {
                  abbreviation
                }
              }
              storeNumber
            }
            deliveryUrl
          }
        }
      }
    }
  }
`;

export default VENDOR_LOCATIONS_LIST;
