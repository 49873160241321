import { gql } from '@apollo/client';

export const UPDATE_LOCATION_VENDOR = gql`
  mutation updateLocationVendor(
    $input: UpdateLocationVendorMutationInput!
  ) {
  updateLocationVendor(input: $input){
    locationVendor {
      id
      vendor {
        id
        vendorId
        label
      }
      location {
        id
        locationId
        label
        address {
          state {
            abbreviation
          }
        }
        storeNumber
      }
      deliveryUrl
    }
  }
}
`;
