import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  noteRow: {
    marginBottom: '21px',
    paddingBottom: '10px',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.hrColor}`,
    },
  },

  outlinedLabel: {
    padding: '5px 15px',
    backgroundColor: theme.outlinedLabel.backgroundColor,
    color: theme.outlinedLabel.color,
    borderRadius: '8px',
  },
  paddingLeft: {
    paddingLeft: theme.spacing(2),
  },
});

const NotesStyles = makeStyles(Styles);

export default NotesStyles;
