import { gql } from '@apollo/client';

export const locationHandoffCategoryFragment = {
  locationHandoffCategory: gql`
    fragment LocationHandoffCategoryData on LocationHandoffCategory {
      id
      handoff {
        id
        label
      }
      category {
        id
        label
      }
      location {
        id
      }
      active
      burqEnabled
      alwaysDeliver
      doordashDriveEnabled
      additionalCharge
      additionalChargeTaxable
      discountFraction
      discountAmount
      radius
      leadTime
      minOrderAmount
      maxOrderAmount
      minOrderQuantity
      maxOrderQuantity
    }
  `,
};
