import React, { useMemo } from 'react';
import Conversion from './Conversion';
import FormItems from '../../../../../../blocks/FormItems';

const initialConversion = {
  conversionMath: '',
  measureId: '',
  isForCount: false,
};

const Conversions = ({
  control,
  errors,
  register,
  getValues,
  setValue,
  unregister,
  duplicateMeasureIds,
  measureOptions,
  mainMeasure,
  recipe,
}) => {
  const conversions = useMemo(() => {
    return (
      recipe?.recipeMeasureConnection?.edges?.map(edge => ({
        measureId: edge.node?.measureId,
        conversionMath: edge.node.conversion,
        isForCount: edge.node.isForCount,
      })) || [initialConversion]
    );
  }, [recipe]);

  return (
    <FormItems
      title="Conversions"
      addItemButtonText="Add UOM"
      name="conversion"
      initialItem={initialConversion}
      items={conversions}
      unregister={unregister}
      getValues={getValues}
      control={control}
      register={register}
      setValue={setValue}
      errors={errors}
      itemComponent={Conversion}
      dividerBetweenItems={true}
      dividerAfterItems={false}
      enableRemove
      enableEmpty={false}
      emptyErrorText="At least one unit of measure is required."
      itemComponentProps={{
        measureOptions,
        duplicateMeasureIds,
        mainMeasure,
      }}
    />
  );
};

export default Conversions;
