import { DateTime } from 'luxon';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import logo from '../../assets/images/logo2.png';

import generatePrepPdf from './generatePrepPdf';
import generateOvertimePdf from './generateOvertimePdf';
import generateOrderPdf from './generateOrderPdf';
import generateSchedulePdf from './generateSchedulePdf';
import { toFixed10 } from '../mathUtils';

// needed for generating pdf
export function getDataUri(url, callback) {
  // eslint-disable-next-line no-undef
  const image = new Image();

  image.setAttribute('crossOrigin', 'anonymous');

  image.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width; // or 'width' if you want a special/scaled size
    canvas.height = image.height; // or 'height' if you want a special/scaled size

    canvas.getContext('2d').drawImage(image, 0, 0);
    callback(canvas.toDataURL('image/png'));
  };

  image.src = url;
}

export const getSpecificWeekdayOrder = started => {
  const workDays = [
    {
      style: 'tableHeader',
      margin: [-10, 15, 0, 0],
      text: `${DateTime.fromSQL(started).toFormat('EEEE')} (regular)`,
    },
    {
      style: 'tableHeader',
      margin: [-10, 15, 0, 0],
      text: `${DateTime.fromSQL(started).toFormat('EEEE')} (OT)`,
    },
  ];
  for (let i = 1; i <= 6; i++) {
    workDays.push({
      style: 'tableHeader',
      margin: [-10, 15, 0, 0],
      text: `${DateTime.fromSQL(started)
        .plus({ days: i })
        .toFormat('EEEE')} (regular)`,
    });
    workDays.push({
      style: 'tableHeader',
      margin: [-10, 15, 0, 0],
      text: `${DateTime.fromSQL(started)
        .plus({ days: i })
        .toFormat('EEEE')} (OT)`,
    });
  }
  return workDays;
};

export const getTimeshiftHoursForDay = (date, timeshifts) => {
  let dailyWorkingHours = 0;
  timeshifts.forEach(timeshift => {
    if (moment(timeshift.node.started).format('YYYY-MM-DD') === date) {
      if (timeshift.node.isFinished) {
        dailyWorkingHours += moment
          .duration(moment(timeshift?.node?.finished).diff(moment(timeshift?.node?.started)))
          .asMilliseconds();
      } else {
        dailyWorkingHours = 'Not clocked out';
      }
    }
  });

  return dailyWorkingHours;
};

export const getTimeshiftOTForDay = (date, timeshifts) => {
  let dailyOvertime = 0;
  timeshifts.forEach(timeshift => {
    if (moment(timeshift.node.started).format('YYYY-MM-DD') === date) {
      if (timeshift.node.isFinished) {
        dailyOvertime = timeshift?.node?.overtime?.hours * 3600000;
      } else {
        dailyOvertime = 'Not clocked out';
      }
    }
  });

  return dailyOvertime;
};

export const calculateWeeklyTimeshiftHours = timeshifts => {
  let totalHours = 0;
  timeshifts.forEach(timeshift => {
    if (timeshift.node.isFinished) {
      totalHours += moment
        .duration(moment(timeshift?.node?.finished).diff(moment(timeshift?.node?.started)))
        .asMilliseconds();
    }
  });
  return totalHours;
};

export const calculateWeeklyOvertimeHours = timeshifts => {
  let totalOvertime = 0;
  let type = null;
  if (timeshifts.length && !timeshifts[0].node.overtime.error) {
    type = timeshifts[0].node.overtime.type;

    timeshifts.forEach(timeshift => {
      if (timeshift.node.isFinished) {
        totalOvertime += timeshift.node.overtime.hours;
      }
    });
  }
  return {
    type,
    overtime: totalOvertime,
  };
};

const generateScheduleSummary = data => {
  const headers = data[0].map(header => ({ text: `\n\n\n${header}` }));
  data.shift();
  const breakfest = data
    ?.filter(el => el.Breakfast)
    .map(
      el =>
        `Store Number: ${el?.store_Number},\n Date: ${el?.sd},\n Breakfast: ${el?.Breakfast},\n Job: ${el?.Job}\n`,
    );
  const lunch = data
    ?.filter(el => el.Lunch)
    .map(
      el =>
        `Store Number: ${el?.store_Number},\n Date: ${el?.sd},\n Lunch: ${el?.Lunch},\n Job: ${el?.Job}\n`,
    );
  const dinner = data
    ?.filter(el => el.Dinner)
    .map(
      el =>
        `Store Number: ${el?.store_Number},\n Date: ${el?.sd},\n Dinner: ${el?.Dinner},\n Job: ${el?.Job}`,
    );
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const docDefinition = {
    pageOrientation: 'landscape',
    header: {
      columns: [
        {
          image: logo,
          width: 75,
        },
      ],
    },
    content: [
      {
        alignment: 'left',
        columns: headers,
        style: 'header',
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 700,
            y2: 5,
            lineWidth: 4,
            color: '#ffda00',
            margin: [20, 0],
          },
        ],
      },
      {
        alignment: 'left',

        columns: [
          {
            ol: breakfest,
          },
          {
            ol: lunch,
          },
          {
            ol: dinner,
          },
        ],
      },
    ],
    styles: {
      header: {
        fontSize: 12,
        bold: true,
        color: 'black',
      },
      tableHeader: {
        color: 'black',
      },
    },
    defaultStyle: {
      fontSize: 8,
      color: '#444444',
    },
  };

  pdfMake.createPdf(docDefinition).open();
};

export {
  generateOrderPdf,
  generatePrepPdf,
  generateScheduleSummary,
  generateOvertimePdf,
  generateSchedulePdf,
};
