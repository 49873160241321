import ItemChoices from './ItemChoices';

function ItemChoiceIngredients() {

    return <ItemChoices />

}

export default ItemChoiceIngredients

