import React, { useState, useMemo } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import {
  PermissionField,
  Elements,
  PlacesAutocomplete,
  SelectFactory,
} from '../../../../../../../blocks';
import moment from 'moment-timezone';
import PhoneNumberInput from '../../../../../../../blocks/PhoneNumberInput';
import { AutoComplete } from '../../../../../../../blocks';
import { FETCH_STATES } from '../../../../../../../../apollo/queries';
import { BRANDS } from '../../../../../../../../apollo/queries/brands';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { validateGeocode } from '../../../../../../../../utils';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import UPDATE_CUSTOMER from '../../../../../../../../apollo/mutations/loyalty/updateCustomer';
import CREATE_CUSTOMER from '../../../../../../../../apollo/mutations/loyalty/createCustomer';
export default function CustomerModalView({ handleCloseModal, customerInfo, newCustomer = false }) {
  const { setSnackbar } = useSnackbar();

  const { handleSubmit, control, register, errors, setValue } = useForm();

  const [locationAddress, setLocationAddress] = useState(
    customerInfo && {
      address: customerInfo?.node?.address?.address,
      city: customerInfo?.node?.address?.city,
      zip: customerInfo?.node?.address?.zip,
      additionalInfo: customerInfo?.node?.address?.additionalInfo,
      state: {
        label: customerInfo?.node?.address?.state?.label,
        value: customerInfo?.node?.address?.state?.stateId,
      },
    },
  );

  const [brandState, setBrandState] = useState(
    customerInfo && {
      brandId: customerInfo?.node?.login?.brand?.brandId,
      brandLabel: customerInfo?.node?.login?.brand?.label,
    },
  );

  const { data: dataState, loading: loadingState, error: errorState } = useQuery(FETCH_STATES, {
    variables: {
      first: 100000,
    },
  });

  const { data: dataBrands, loading: loadingBrands, error: errorBrands } = useQuery(BRANDS);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const stateList = useMemo(
    () =>
      dataState?.viewer?.stateConnection?.edges?.map(state => ({
        label: state.node.label,
        value: state.node.stateId,
      })),
    [dataState?.viewer?.stateConnection?.edges],
  );

  const brandList = useMemo(() => {
    return (
      dataBrands?.viewer?.brandConnection?.edges?.map(brand => ({
        label: brand.node.label,
        value: brand.node.brandId,
      })) || []
    );
  }, [dataBrands?.viewer?.brandConnection?.edges]);

  const handleAddressChange = (event, type) => {
    const value = event.target?.value || event.label;
    setLocationAddress(prevState => ({ ...prevState, [type]: value }));
  };

  const handleGeocodeAutocomplete = address => {
    const { state } = address;

    // eslint-disable-next-line no-shadow
    const formatState = dataState?.viewer?.stateConnection?.edges?.map(state => ({
      label: state.node.label,
      value: state.node.stateId,
    }));

    // eslint-disable-next-line no-param-reassign
    address.state = formatState.find(existingState => existingState.label === state);

    setLocationAddress(address);
    setValue('state', address.state.label);
  };

  const onSubmit = async ({
    additionalInfo,
    birthday,
    city,
    email,
    firstName,
    lastName,
    phone,
    state,
    streetAddress,
    zip,
    brand,
  }) => {
    const { isValidAddress = false, lat, lng, error } = await validateGeocode(
      streetAddress,
      city,
      zip,
      locationAddress.state.label,
    );

    if (!isValidAddress) {
      setSnackbar({
        open: true,
        type: 'error',
        text: `${error}, try a different combination of address`,
      });
      return;
    }

    if (newCustomer) {
      createCustomer({
        variables: {
          input: {
            firstName,
            lastName,
            address: streetAddress,
            additionalInfo,
            city,
            stateLabel: state,
            dateOfBirth: moment(birthday).format('YYYY-MM-DD'),
            zip,
            longitude: lat,
            latitude: lng,
            phone,
            email,
            brandId: brand.value,
          },
        },
      })
        .then(() => {
          setSnackbar({
            text: newCustomer
              ? 'Customer is successfully created!.'
              : 'Customer is successfully updated!.',
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
          });
          handleCloseModal();
        })
        .catch(e =>
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: e.message,
            open: true,
          }),
        );
    } else {
      updateCustomer({
        variables: {
          input: {
            loginId: customerInfo?.node?.login?.loginId,
            firstName,
            lastName,
            address: streetAddress,
            additionalInfo,
            city,
            stateLabel: state,
            dateOfBirth: moment(birthday).format('YYYY-MM-DD'),
            zip,
            longitude: lat,
            latitude: lng,
            phone,
            email,
            brandId: brand.value,
          },
        },
      })
        .then(() => {
          setSnackbar({
            text: 'Customer is successfully updated!.',
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
          });
          handleCloseModal();
        })
        .catch(e =>
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: e.message,
            open: true,
          }),
        );
    }
  };

  if (loadingState && loadingBrands) return <div>Loading...</div>;

  if (errorState || errorBrands) {
    return <div>Error</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <PermissionField
            Component={TextField}
            name="firstName"
            label="First Name"
            // disabled={
            //   locationInfoData?.storeNumber === 0 ||
            //   Boolean(locationInfoData?.storeNumber)
            // }
            defaultValue={customerInfo?.node?.firstName}
            inputRef={register({ required: true, min: 0, max: 50000 })}
            error={errors.firstName}
            helperText={errors.firstName && 'First Name is required.'}
            fullWidth
            type="text"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <PermissionField
            Component={TextField}
            name="lastName"
            label="Last Name"
            // disabled={
            //   locationInfoData?.storeNumber === 0 ||
            //   Boolean(locationInfoData?.storeNumber)
            // }
            defaultValue={customerInfo?.node?.lastName}
            inputRef={register({ required: true, min: 0, max: 50000 })}
            error={errors.lastName}
            helperText={errors.lastName && 'Last Name is required.'}
            fullWidth
            type="text"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <PermissionField
            Component={AutoComplete}
            name="brand"
            id={'brand'}
            rules={{
              required: true,
            }}
            defaultValue={{
              label: brandState?.brandLabel || '',
              value: brandState?.brandId || '',
            }}
            getOptionLabel={option => {
              if (option !== null) {
                return option.label;
              }
              return option;
            }}
            control={control}
            error={errors.brand}
            helperText={errors.brand && 'Brand is required.'}
            renderInput={params => (
              <TextField
                {...params}
                label={'Brand'}
                variant="outlined"
                inputRef={register({
                  required: true,
                })}
              />
            )}
            options={brandList}
            onChange={selected => {
              setBrandState(selected);
            }}
            selectedOption={{
              label: brandState?.brandLabel || '',
              value: brandState?.brandId || '',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <FormControl fullWidth> */}
          <PermissionField
            Component={Elements.DatePicker}
            name="birthday"
            label="Birthday"
            control={control}
            fullWidth={true}
            defaultValue={customerInfo?.node?.dateOfBirth || null}
          />
          {/* </FormControl> */}
        </Grid>
        <Grid item xs={6}>
          <PermissionField
            Component={PhoneNumberInput}
            label={'Phone'}
            name={'phone'}
            id="phone"
            fullWidth
            rules={{ required: true }}
            error={errors.phone}
            defaultValue={customerInfo?.node?.login?.phone || null}
            control={control}
            helperText="Phone number is required."
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column">
            <PlacesAutocomplete handleGeocodeAutocomplete={handleGeocodeAutocomplete} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <PermissionField
            Component={TextField}
            name="streetAddress"
            label="Street Address"
            inputRef={register({
              required: true,
            })}
            error={!!errors.streetAddress}
            onChange={event => handleAddressChange(event, 'address')}
            fullWidth
            value={locationAddress?.address}
            defaultValue={locationAddress?.address}
            helperText={errors.streetAddress && 'Street Address is required.'}
          />
        </Grid>
        <Grid item xs={6}>
          <PermissionField
            Component={TextField}
            name="additionalInfo"
            label="Apartment, Suite, Unit, Building..."
            inputRef={register}
            defaultValue={locationAddress?.additionalInfo}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <PermissionField
            Component={TextField}
            name="city"
            label="City"
            inputRef={register({
              required: true,
            })}
            error={!!errors.city}
            value={locationAddress?.city}
            onChange={event => handleAddressChange(event, 'city')}
            fullWidth
            helperText={errors.city && 'City is required.'}
          />
        </Grid>

        <Grid item xs={4} container>
          <Grid direction="column" xs={12} item>
            <PermissionField
              Component={AutoComplete}
              name="state"
              id={'state'}
              rules={{
                required: true,
              }}
              defaultValue={locationAddress?.state}
              getOptionLabel={option => {
                if (typeof option === 'object' && !Array.isArray(option) && option !== null) {
                  return option.label;
                }
                return option;
              }}
              control={control}
              error={errors.state}
              helperText={errors.state && 'State is required.'}
              renderInput={params => (
                <TextField
                  {...params}
                  label={'State'}
                  variant="outlined"
                  inputRef={register({
                    required: true,
                  })}
                />
              )}
              options={stateList || []}
              onChange={selected => {
                setLocationAddress(prevState => ({
                  ...prevState,
                  state: selected,
                }));
              }}
              selectedOption={{
                label: locationAddress?.state ? locationAddress?.state?.label : '',
                value: locationAddress?.state ? locationAddress?.state?.value : '',
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <PermissionField
            Component={TextField}
            name="zip"
            label="Zip"
            inputRef={register({
              required: true,
            })}
            error={!!errors.zip}
            onChange={event => handleAddressChange(event, 'zip')}
            value={locationAddress?.zip}
            helperText={errors.zip && 'Zip is required.'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PermissionField
            Component={TextField}
            name="email"
            label="Email Address"
            // disabled={
            //   locationInfoData?.storeNumber === 0 ||
            //   Boolean(locationInfoData?.storeNumber)
            // }
            error={errors.email}
            defaultValue={customerInfo?.node?.login?.email}
            inputRef={register({ required: true, min: 0, max: 50000 })}
            helperText={errors.email && 'Email is required.'}
            fullWidth
            type="email"
            control={control}
          />
        </Grid>
        <Grid item justify="flex-end" spacing={3} xs={12}>
          <Grid item>
            <Button color="primary" variant="outlined" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              //   disabled={createEmployeeLoading || updateEmployeeLoading}
              type="submit"
              style={{ marginLeft: '0.3rem' }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
