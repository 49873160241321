import { gql } from '@apollo/client';

const FETCH_ITEM_CHOICES = gql`
  query fetchItemChoice($filter: [ItemMultipleFilter]) {
    viewer {
    id
    itemMultipleConnection(filter: $filter, sort: {order: ASC}) {
      edges {
        node {
          id
          order
          item {
            id
            label
            minQuantity
            price
          }
          allowedMaximum
          optional
          requiredMinimum
          multiple {
            id
            label
            multipleId
            onlineLabel
            multipleChoiceConnection {
              edges {
                node {
                  id
                  allowedMaximum
                  price
                  orderKey
                  choice {
                    id
                    choiceId
                    cost
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;

export default FETCH_ITEM_CHOICES;
