import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import CHOICES from '../../../../../apollo/queries/choices';
import paths from '../../../../../constants/paths';
import FETCH_MULTIPLE from '../../../../../apollo/queries/menus/fetchMultiple';
import AddChoice from './MultipleEdit/ChoicesSideBar/AddChoice';
import { useMutation } from '@apollo/client';
import REMOVE_CHOICE from '../../../../../apollo/mutations/menus/removeChoice';
import REMOVE_MULTIPLE from '../../../../../apollo/mutations/menus/removeMultiple';
import { SNACKBAR_STATUS } from '../../../../../constants';
import FETCH_MENU_MULTIPLE from '../../../../../apollo/queries/menus/fetchMenuMultiples';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { Modal } from '../../../../blocks';

const ChoicesSettings = () => {
  const { setSnackbar } = useSnackbar();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [queryVariables, setQueryVariables] = useState({});
  const [removeChoice, { loading: isDeletingChoice }] = useMutation(REMOVE_CHOICE);
  const [removeMultiple, { loading: isDeletingMultiple }] = useMutation(REMOVE_MULTIPLE);
  const [existingChoiceData, setExistingChoiceData] = useState([]);

  const handleOpen = (type, choiceData, variables) => {
    type === 'update' && setExistingChoiceData(choiceData);
    setOpen(true);
    setQueryVariables(variables);
  };

  const handleClose = () => {
    setExistingChoiceData({});
    setOpen(false);
  };

  const getPath = id => {
    return paths.SETTINGS_CHOICES_MULTIPLE_EDIT.replace(':multipleId', id);
  };

  const handleMultipleDelete = (multipleId, queryVariables) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Multiple is deleting!',
    });

    removeMultiple({
      variables: {
        input: {
          multipleId,
        },
      },
      refetchQueries: [
        {
          query: FETCH_MULTIPLE,
          variables: { ...queryVariables },
        },
      ],
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Multiple is deleted.',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  const handleChoiceDelete = (choiceId, queryVariables) => {
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Choice is deleting!',
    });

    removeChoice({
      variables: {
        input: {
          choiceId,
        },
      },
      refetchQueries: [
        {
          query: CHOICES,
          variables: { ...queryVariables },
        },
      ],
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Choice is deleted.',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  return (
    <>
      <ConnectionTable
        query={FETCH_MENU_MULTIPLE}
        title="Multiples"
        onTableRowClick={data => history.push(getPath(data.multipleId))}
        handleDeleteRow={(data, variables) => handleMultipleDelete(data.id, variables)}
        useConfirmationModalForDelete
        isDeleting={isDeletingMultiple}
        deleteConfirmationText="Are you sure you want to delete this multiple?"
        customActionsTop={() => (
          <AddIcon
            onClick={() =>
              history.push('/spark/system-settings/choices/multiple-edit/new-multiple')
            }
          />
        )}
        initialQueryVariables={{
          first: 15,
          fieldGroupOnly: 7,
        }}
        structureTable={data => {
          return data?.viewer?.multipleConnection?.edges.map(multiple => ({
            id: multiple?.node?.id,
            multipleId: multiple?.node?.multipleId,
            label: multiple?.node?.label,
            description: multiple?.node?.description,
          }));
        }}
        columns={[
          { title: 'ID', field: 'multipleId' },
          { title: 'Name', field: 'label' },
          {
            title: 'Description',
            field: 'description',
          },
        ]}
      />
      <br />
      <br />
      <ConnectionTable
        query={CHOICES}
        title="Choices"
        customActionsTop={(data, queryProperty) => (
          <AddIcon onClick={() => handleOpen('new', data, queryProperty)} />
        )}
        initialQueryVariables={{
          first: 15,
        }}
        onTableRowClick={(data, _, variables) => handleOpen('update', data, variables)}
        handleDeleteRow={(data, variables) => handleChoiceDelete(data.id, variables)}
        minSearchValueLength={1}
        useConfirmationModalForDelete
        isDeleting={isDeletingChoice}
        deleteConfirmationText="Are you sure you want to delete this choice?"
        structureTable={data => {
          return data?.viewer?.choiceConnection?.edges.map(choice => ({
            id: choice?.node?.id,
            choiceId: choice?.node?.choiceId,
            label: choice?.node?.label,
            description: choice?.node?.description,
            ingredients: choice?.node?.choiceIngredientConnection?.edges?.map((edge, index) => {
              const inventoryItem = edge.node.ingredient || edge.node.recipe;

              return {
                label: inventoryItem?.inventoryitem?.label,
                inventoryitemId: inventoryItem?.inventoryitem?.inventoryitemId,
                itemId: edge.node.itemId,
                itemMenuLink: edge.node.itemMenuLink,
                quantity: edge.node.quantity,
                index,
                measureId: edge.node.measure?.measureId,
                measureOptions: inventoryItem?.inventoryitem?.inventoryitemMeasureConnection?.edges?.map(
                  measureEdge => ({
                    value: measureEdge.node.measureId,
                    label: `${measureEdge.node.measure?.label} (${measureEdge.node.measure?.abbreviation})`,
                  }),
                ),
              };
            }),
            imageUrl: choice?.node?.choiceimageConnection?.edges[0]?.node?.url,
          }));
        }}
        columns={[
          { title: 'ID', field: 'choiceId' },
          { title: 'Name', field: 'label' },
          {
            title: 'Description',
            field: 'description',
          },
        ]}
      />
      <Modal
        open={open}
        handleClose={handleClose}
        title={existingChoiceData?.choiceId ? 'Edit Choice' : 'Add Choice'}
      >
        <AddChoice
          handleClose={handleClose}
          variables={queryVariables}
          existingChoiceData={existingChoiceData}
          update={Object.keys(existingChoiceData).length !== 0}
        />
      </Modal>
    </>
  );
};

export default ChoicesSettings;
