import { gql } from '@apollo/client';

const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: CreateAddressMutationInput!) {
    createAddress(input: $input) {
      address {
        id
      }
      viewer {
        id
      }
    }
  }
`;

export default CREATE_ADDRESS;
