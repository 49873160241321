import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";

function DiscountType({ setFilter, refetch: { refetch, variables } }) {
  return (
    <div>
      <FormControl>
        <InputLabel htmlFor="name-native-error">Discount Type</InputLabel>
        <Select
          style={{ width: "8rem" }}
          inputProps={{
            name: "Discount Type",
          }}
          onChange={(e) => {
            let filter;
            if (e.target.value !== "none") {
              filter = {
                ...variables,
                filter: {
                  ...variables.filter,
                  discountType: e.target.value,
                },
              };
            } else {
              filter = {
                ...variables,
                filter: {
                  ...variables.filter,
                  discountType: undefined,
                },
              };
            }
            refetch(filter);
          }}
          variant="outlined"
          defaultValue="none"
          label="Discount Type"
          placeholder="Discount Type"
        >
          <MenuItem value="none">
            <em>None</em>
          </MenuItem>
          <MenuItem value={1}>Order Amount</MenuItem>
          <MenuItem value={2}>Order Percentange</MenuItem>
          <MenuItem value={3}>Item Amount</MenuItem>
          <MenuItem value={4}>Item Percentange</MenuItem>
          <MenuItem value={5}>Category Amount</MenuItem>
          <MenuItem value={6}>Category Percentage</MenuItem>
          <MenuItem value={7}>Handoff Amount</MenuItem>
          <MenuItem value={8}>Handoff Percentange</MenuItem>
          <MenuItem value={9}>Free Item</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default DiscountType;
