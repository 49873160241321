import { gql } from '@apollo/client';

const MANIPULATE_USERGROUP_USER = gql`
  mutation manipulateUsergroupUser($usergroupId: Int!, $loginIds: [Int!]) {
    manipulateUsergroupUser(input: { 
      usergroupId: $usergroupId, 
      loginIds: $loginIds 
    }) {
      usergroupId
    }
  }
`;

export default MANIPULATE_USERGROUP_USER;
