import { useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CREATE_REPORTING_CATEGORY,
  UPDATE_REPORTING_CATEGORY,
} from '../../../../../../../apollo/mutations';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { statusOptions } from '../../../../../../../constants/statuses';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const defaultValues = {
  label: '',
  active: '',
  description: '',
};

const ReportingCategoryModal = ({ handleClose, reportingCategory }) => {
  const { control, errors, handleSubmit, register, reset } = useForm(defaultValues);

  const { setSnackbar } = useSnackbar();

  const [createReportingCategory] = useMutation(CREATE_REPORTING_CATEGORY, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Reporting Category created',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(data?.createReportcategory?.viewer),
        fields: {
          reportcategoryConnection() {
            return {
              __typename: 'ReportcategoryEdge',
            };
          },
        },
      });
    },
  });

  const [updateReportingCategory] = useMutation(UPDATE_REPORTING_CATEGORY, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Reporting Category updated',
        open: true,
      });

      handleClose();
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      });
    },
  });

  useEffect(() => {
    if (reportingCategory?.reportcategoryId) {
      reset({
        label: reportingCategory.label,
        active: reportingCategory.active ? 1 : 0,
        description: reportingCategory.description,
      });
    } else {
      reset(defaultValues);
    }
  }, [reportingCategory]);

  const onSubmit = useCallback(
    formData => {
      const input = {
        label: formData.label,
        active: !!formData.active,
        description: formData.description,
      };

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: reportingCategory?.reportcategoryId
          ? 'Updating Reporting Category...'
          : 'Creating Reporting Category...',
        open: true,
      });

      if (reportingCategory?.reportcategoryId) {
        input.reportcategoryId = reportingCategory.reportcategoryId;

        updateReportingCategory({ variables: { input } });
      } else {
        createReportingCategory({ variables: { input } });
      }
    },
    [reportingCategory?.reportcategoryId],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            label="Name"
            name="label"
            variant="outlined"
            placeholder="Name"
            error={errors.label}
            helperText={!!errors.label && 'Name is required'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.active}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="active"
              name="active"
              label="Status"
              placeholder="Status"
              defaultValue={reportingCategory?.active ? 1 : 0}
              error={!!errors?.active}
              as={
                <Select label="Status" multiple={false} error={!!errors?.active}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.active?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            multiline
            label="Description"
            name="description"
            rows={4}
            rowsMax={4}
            variant="outlined"
            placeholder="Description"
            error={errors.description}
            helperText={!!errors.description && 'Description is required'}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit">
                {reportingCategory?.reportcategoryId ? 'Save' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportingCategoryModal;
