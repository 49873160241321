import { gql } from '@apollo/client';

const CATEGORIES = gql`
  query Categories ($filter: [CategoryFilter]!) {
    viewer {
      id
      categoryConnection(filter: $filter) {
        permissions
        edges {
          node {
            id
            categoryId
            label
          }
        }
      }
    }
  }
`;

export default CATEGORIES;
