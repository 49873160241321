import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import layout from '../../assets/images/line_layout.jpg';
import imageConvert from '../imageConvert';
import { getDataUri } from '.';

const PREP_INFO_SUBCATEGORY_IDS = [
  1660,
  1615,
  1050,
  1105,
  1482,
  457,
  1660,
  1533,
  1615,
  1041,
  1050,
  1096,
  1105,
  1473,
  1482,
  5534,
  5489,
];

const generatePrepPdf = (quote, destination) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  imageConvert.urlToBase64(`${process.env.REACT_APP_SITE_URL}${layout}`, result =>
    getDataUri(result, layoutUri => {
      // change the time by location timezone offset
      function getDateTime(date, format) {
        const locationTZ = quote.location.timezone.label;
        return moment
          .utc(date)
          .tz(locationTZ)
          .format(format);
      }

      function getMeatQty() {
        return `${quote.quotecatering.prepInfo.meat} LB.`;
      }

      function getSideQty() {
        return quote.quotecatering.prepInfo.side;
      }

      function getDessertQty(customers) {
        const pieces = parseInt(Math.round(0.7321 * customers + 4.6154), 10);
        return `${pieces} x `;
      }

      function getDrinkQty(customers) {
        const gals = parseInt(customers / 10, 10);
        return `${gals} (1 gallon jug) (${customers} Little Yellow Cups) of`;
      }

      function getSauceQty() {
        return `${quote.quotecatering.prepInfo.sauce} of`;
      }

      function getSandwichQty(customers) {
        return `${customers} x 4 oz`;
      }

      function getChipsQty(customers) {
        return `${customers} bags of`;
      }

      function getQty(multipleLabel, customers) {
        const choice = multipleLabel.toLowerCase();
        switch (true) {
          case choice.includes('meat'):
            return getMeatQty();
          case choice.includes('side'):
            return getSideQty();
          case choice.includes('dessert'):
            return getDessertQty(customers);
          case choice.includes('drink'):
            return getDrinkQty(customers);
          case choice.includes('sauce'):
            return getSauceQty();
          case choice.includes('sandwich'):
            return getSandwichQty(customers);
          case choice.includes('chips'):
            return getChipsQty(customers);
          default:
            return `${customers} x `;
        }
      }

      function printSauces(sauces, customers) {
        const numberOfSauces = sauces.length;
        let output = '';

        sauces.forEach(sauce => {
          output += `${getQty(sauce.label, customers / numberOfSauces)} ${sauce.label}\n`;
        });

        return output;
      }

      function printRelish() {
        return quote.quotecatering.prepInfo.relish;
      }

      function printAdditionalItems(items) {
        let out = '';

        items.forEach(line => {
          out += `${line.quantity}x ${line.item.label}\n`;
          line.lineMultipleConnection.edges.forEach(edge => {
            out += `     +${line.quantity}x ${edge.node.choice.label}\n`;
          });
          if (line.note) {
            out += `     Note: ${line.note}\n`;
          }
        });

        return out;
      }

      function printTime(customers, service) {
        const isFullService = service.item.label.toLowerCase().includes('service');

        let min = (Math.ceil(customers / 50) + 1 + (isFullService ? 1 : 0)) * 15;
        let h = 0;
        if (min >= 60) {
          h = Math.floor(min / 60);
          min %= 60;
        }

        return `${h ? `${h} h` : ''} ${min ? `${min} min` : ''}`;
      }

      function prepTable() {
        if (!quote.lineConnection.edges.length) {
          return [''];
        }
        const body = [];
        let mainItem;
        const additionalItems = [];
        quote.lineConnection.edges.forEach(edge => {
          if (PREP_INFO_SUBCATEGORY_IDS.includes(edge.node?.subcategory?.subcategoryId)) {
            mainItem = edge.node;
          } else {
            additionalItems.push(edge.node);
          }
        });

        const customers = quote.quotecatering.numberOfCustomers;
        if (!mainItem) {
          body.push([
            { text: 'Items', style: 'tableHeader' },
            {
              border: [true, true, true, true],
              preserveLeadingSpaces: true,
              text: printAdditionalItems(additionalItems),
            },
          ]);
        } else {
          body.push(
            [
              { text: 'Catering Option', style: 'tableHeader' },
              {
                border: [true, true, true, true],
                text: `${mainItem ? mainItem.item.label : ''}`,
              },
            ],
            [
              { text: 'Number of People', style: 'tableHeader' },
              { border: [true, true, true, true], text: `${customers || ''}` },
            ],
          );

          if (mainItem.note) {
            body.push([
              { text: 'Note', style: 'tableHeader' },
              {
                border: [true, true, true, true],
                text: `${mainItem.note}`,
              },
            ]);
          }

          const isBoxLunch = mainItem.item.label.toLowerCase().includes('box lunch');
          mainItem.lineMultipleConnection.edges.forEach(edge => {
            const multiple = edge.node.multiple.label;
            const quantity = getQty(multiple, customers);
            if (multiple.toLowerCase().includes('sandwich')) {
              const ingredients = edge.node.choice.ingredients;
              let ings = '';
              ingredients.forEach(ingredient => {
                ings += `\n     -- ${customers} x ${ingredient.ingredientQuantity} ${
                  ingredient.measureAbbreviation
                } ${ingredient.ingredientLabel} (${customers * ingredient.ingredientQuantity} ${
                  ingredient.measureAbbreviation
                })`;
              });
              body.push([
                { text: multiple, style: 'tableHeader' },
                {
                  border: [true, true, true, true],
                  preserveLeadingSpaces: true,
                  text: `${customers} x ${edge.node.choice.label}${ings}`,
                },
              ]);
            } else if (multiple.toLowerCase().includes('sauce') && isBoxLunch) {
              body.push([
                { text: multiple, style: 'tableHeader' },
                {
                  border: [true, true, true, true],
                  preserveLeadingSpaces: true,
                  text: `${quantity} ${edge.node.choice.label}\n    -- ${customers} x 1 oz. souffle cups ${edge.node.choice.label}`,
                },
              ]);
            } else {
              body.push([
                { text: multiple, style: 'tableHeader' },
                {
                  border: [true, true, true, true],
                  text: `${quantity} of ${edge.node.choice.label}`,
                },
              ]);
            }
          });

          const sauces = [];
          let relish = false;

          if (sauces.length) {
            body.push([
              { text: 'Sauce', style: 'tableHeader' },
              {
                border: [true, true, true, true],
                preserveLeadingSpaces: true,
                text: printSauces(sauces, customers),
              },
            ]);
          }
          if (relish) {
            body.push([
              { text: 'Relish Tray', style: 'tableHeader' },
              {
                border: [true, true, true, true],
                preserveLeadingSpaces: true,
                text: printRelish(),
              },
            ]);
          }
          body.push([
            { text: 'Plates & Cutlery Kits', style: 'tableHeader' },
            { border: [true, true, true, true], text: customers + 3 },
          ]);

          body.push([
            { text: 'Additional Items', style: 'tableHeader' },
            {
              border: [true, true, true, true],
              preserveLeadingSpaces: true,
              text: printAdditionalItems(additionalItems),
            },
          ]);
          body.push([
            {
              text: ' ',
              style: 'tableHeader',
              border: [false, false, false, true],
            },
            { border: [false, false, false, true], text: '' },
          ]);

          body.push([
            { text: 'Total Set Up Time Needed', style: 'tableHeader' },
            {
              border: [true, true, true, true],
              text: printTime(customers, mainItem),
            },
          ]);
        }
        return body;
      }

      const docDefinition = {
        pageMargins: [20, 80, 20, 20],
        header: {
          columns: [
            {
              alignment: 'right',
              text: `* 1.866.BARBECUE (227-2328)*\n\nCatering Quote #${quote.quoteId}`,
              margin: [0, 35, 20, 20],
            },
          ],
        },
        content: [
          {
            alignment: 'left',
            columns: [
              {
                text: 'Quote Summary',
              },
              {
                text: 'Location Info',
              },
              {
                text: 'Customer Info',
              },
            ],
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 595 - 80,
                y2: 5,
                lineWidth: 3,
                color: '#ffda00',
              },
            ],
          },
          '\n',
          {
            alignment: 'left',
            columns: [
              {
                text: `Catering Expert: ${
                  quote.quotecatering.cateringExpert
                    ? quote.quotecatering.cateringExpert.person.firstName
                    : ''
                } ${
                  quote.quotecatering.cateringExpert
                    ? quote.quotecatering.cateringExpert.person.lastName
                    : ''
                }\n
                              Contact Info: 1866 BARBECUE (227-2328)\n
                              Status: ${
                                quote.quotecatering.status ? quote.quotecatering.status.label : ''
                              }\n
                              Quote: #${quote.quoteId}`,
              },
              {
                text: `Location: ${quote.location ? quote.location.label : ''}\n
                              Address: ${quote.location ? quote.location.address.address : ''},
                                      ${quote.location ? quote.location.address.city : ''},${
                  quote.location ? quote.location.address.state.abbreviation : ''
                } ${quote.location ? quote.location.address.zip : ''}\n
                              Phone: ${quote.location ? quote.location.phone.phone : ''}\n
                              Distance: ${quote.distance}`,
              },
              {
                text: `First Name: ${quote.person ? quote.person.firstName : ''}\n
                              Last Name:  ${quote.person ? quote.person.lastName : ''}\n
                              Phone: ${
                                quote.person && quote.person.phone ? quote.person.phone.phone : ''
                              }\n
                              Email: ${quote.email || ''}\n`,
              },
            ],
          },
          '\n\n',
          {
            alignment: 'left',
            columns: [
              {
                width: '33%',
                text: 'Event Info',
              },
              {
                text: 'Prep Info',
              },
            ],
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 595 - 80,
                y2: 5,
                lineWidth: 3,
                color: '#ffda00',
              },
            ],
          },
          '\n',
          {
            alignment: 'left',
            columns: [
              {
                width: '33%',
                stack: [
                  {
                    text: `Event Type: ${
                      quote.quotecatering.eventType ? quote.quotecatering.eventType.label : ''
                    }\n
                                Number of Guests: ${quote.quotecatering.numberOfCustomers ||
                                  ''}\n\n`,
                  },
                  {
                    text: `Event Date: ${
                      quote.quotecatering.eventDate
                        ? getDateTime(quote.quotecatering.eventDate, 'MMMM Do YYYY')
                        : ''
                    }\n\n`,
                    background: '#faff00',
                  },
                  {
                    text: `Handoff: ${quote.handoff ? quote.handoff.label : ''}\n\n`,
                  },
                  {
                    text: `Delivery Time: ${
                      quote.expected ? getDateTime(quote.expected, 'hh:mm A') : ''
                    }\n`,
                    background: '#faff00',
                  },
                  {
                    text: `
                              Location Type: ${
                                quote.quotecatering.locationType
                                  ? quote.quotecatering.locationType.label
                                  : ''
                              }\n
                              Company Name: ${quote.quotecatering.companyName || ''}\n
                              Delivery Street Address: ${
                                quote.address ? quote.address.address : ''
                              }\n
                              Delivery Street Address Suite: ${quote.quotecatering.instructions ||
                                ''}\n
                              City: ${quote.address ? quote.address.city : ''}\n
                              State: ${quote.address ? quote.address.state.label : ''}\n
                              Zip Code: ${quote.address ? quote.address.zip : ''}\n
                              Contact Name: ${quote.quotecatering.contactName || ''}\n
                              Contact Phone: ${quote.quotecatering.contactPhone || ''}\n
                              Additional Info: ${quote.quotecatering.additionalInfo || ''}\n`,
                  },
                ],
              },
              {
                stack: [
                  {
                    table: {
                      widths: ['30%', '*'],
                      margin: [10, 10, 0, 0],
                      body: prepTable(),
                    },
                    layout: {
                      hLineWidth() {
                        return 1;
                      },
                      vLineWidth(i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 0;
                      },
                      hLineColor() {
                        return 'lightgray';
                      },
                      vLineColor() {
                        return 'lightgray';
                      },
                      paddingBottom() {
                        return 4;
                      },
                      paddingTop() {
                        return 4;
                      },
                    },
                  },
                ],
              },
            ],
          },
          '\n',
          '\n',
          {
            text: 'Catering Order Line Layout',
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 595 - 80,
                y2: 5,
                lineWidth: 3,
                color: '#ffda00',
              },
            ],
          },
          '\n',
          '\n',
          {
            image: layoutUri,
            width: 500,
          },
        ],
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            color: 'black',
          },
          tableHeader: {
            color: 'black',
          },
        },
        defaultStyle: {
          fontSize: 8,
          color: '#444444',
        },
      };

      if (destination === 1) {
        pdfMake.createPdf(docDefinition).open();
      } else if (destination === 2) {
        pdfMake.createPdf(docDefinition).download(`${quote.quoteId}_Catering_PrepInfo`);
      }
    }),
  );
};

export default generatePrepPdf;
