import React, { useMemo } from 'react';
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Permission, SelectFactory, PermissionField } from "../../../../../blocks";
import { LOCATIONS } from "../../../../../../apollo/queries";
import { compact } from 'lodash';

const MinimumWageRuleForm = ({
  title,
  handleSubmit,
  onSubmit,
  register,
  errors,
  permissions,
  locations,
  descriptionElementRef,
  mutationPermissions,
  buttonDisabled,
  open,
  handleClose,
  setLocationIds,
  wageRuleId,
}) => {
  const createPermission = useMemo(() =>
    mutationPermissions?.find(permission => permission.label === "createWageRule") || {}, 
    [mutationPermissions]
  );
  const updatePermission = useMemo(() =>
    mutationPermissions?.find(permission => permission.label === "updateWageRule") || {}, 
    [mutationPermissions]
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Grid container spacing={3}>
                        <Permission access={permissions?.label}>
                          <Grid item xs={12}>
                            <PermissionField 
                              Component={TextField}
                              name="label"
                              label="Rule Name"
                              type="text"
                              inputRef={register({ required: true })}
                              fullWidth
                              error={!!errors.label}
                              helperText={errors.label ? 'Rule name is a required field.' : undefined }
                            />
                          </Grid>
                        </Permission>
                        <Grid item xs={12}>
                          Paid Break
                        </Grid>
                        <Permission access={permissions?.minimumWage}>
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="minimumWage"
                              label="Minimum Wage"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.minimumWage}
                              helperText={errors.minimumWage ? 'Minimum wage is a required field, and must be a positive number.' : undefined }
                            /> 
                          </Grid> 
                        </Permission>
                        <Permission access={permissions?.tipCreditWage}>
                          <Grid item xs={6}>
                            <PermissionField
                              Component={TextField}
                              name="tipCreditWage"
                              label="Tip Credit Wage"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                              })}
                              fullWidth
                              error={!!errors.tipCreditWage}
                              helperText={errors.tipCreditWage ? 'Tip credit wage is a required field, and must be a positive number.' : undefined }
                            />
                          </Grid>
                        </Permission>
                      </Grid>
                    </Grid>
                    <Permission access={permissions?.locationConnection?.edges?.node?.__typename}>
                      <Grid item xs={8}>
                        <PermissionField
                          Component={SelectFactory}
                          label={"Locations"}
                          placeholder={"Select Locations"}
                          query={LOCATIONS}
                          defaultValue={locations}
                          disableCloseOnSelect={true}
                          multiple
                          renderCheckBox
                          structureData={(data) => {
                            return data?.viewer?.locationConnection?.edges.map(
                              (location, index) => {
                                return {
                                  label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
                                  id: location.node.id,
                                  index,
                                };
                              }
                            );
                          }}
                          onSelect={(values) => {
                            setLocationIds(values.map((loc) => loc.id));
                          }}
                        />
                      </Grid>
                    </Permission>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Permission access={wageRuleId ? updatePermission?.access : createPermission?.access}>
              <Button disabled={buttonDisabled} type="submit">
                Save
              </Button>
            </Permission>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

MinimumWageRuleForm.propTypes = {};

export default MinimumWageRuleForm;
