import React from "react";
import AutoComplete from "../../../../../../../blocks/AutoComplete";
import { useQuery } from "@apollo/client";
import { FETCH_HANDOFFS } from "../../../../../../../../apollo/queries";
import { TextField, FormControl } from "@material-ui/core";
import { uglifyId } from "../../../../../../../../utils";

function HandoffSelect({
  control,
  name = "discountDetails.handoff",
  setItem,
  coupon,
  errors,
}) {
  const { data, loading, error } = useQuery(FETCH_HANDOFFS);

  if (loading) return "Loading...";
  if (error) return "error";

  const handoffsList = data?.viewer?.handoffConnection?.edges.map((edge) => ({
    label: edge.node.label,
    value: edge.node.id,
  }));

  const selectedHandoff = handoffsList.filter(
    (handoff) =>
      handoff.value ===
      uglifyId("Handoff", coupon.couponActionConnection.edges[0].node.target)
  );

  return (
    <div>
      <FormControl variant="outlined">
        <AutoComplete
          id={name}
          style={{ width: 250 }}
          defaultValue={selectedHandoff[0]}
          selectedOption={selectedHandoff[0]}
          rules={{
            required: "Handoff is required",
          }}
          onChange={(e, newValue) => {
            if (setItem) {
              setItem({
                label: newValue.label,
                id: newValue.value,
                quantity: 1,
              });
            }
          }}
          control={control}
          getOptionLabel={(option) => option.label || ""}
          options={handoffsList}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors?.discountDetails && errors?.discountDetails?.handoff}
              helperText={
                errors?.discountDetails && errors?.discountDetails?.handoff
                  ? errors?.discountDetails?.handoff?.message
                  : ""
              }
              label={"Handoffs"}
              variant="outlined"
            />
          )}
        />
      </FormControl>
    </div>
  );
}

export default HandoffSelect;
