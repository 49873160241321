import React from "react";
import GeneralInfoView from "./View";
import { useQuery } from "@apollo/client";
import CUSTOMER from "../../../../../../../apollo/queries/loyalty/customer";
import { useParams } from "react-router";
function GeneralInfo({ customerData }) {
  return <GeneralInfoView customerInfo={customerData} />;
}

export default GeneralInfo;
