import { gql } from '@apollo/client';

const SAVE_LOCATION_STORAGE_INVENTORY_COUNT = gql`
  mutation SAVE_LOCATION_STORAGE_INVENTORY_COUNT($input: saveLocationStorageInventorycountInput!) {
    saveLocationStorageInventorycount(input: $input) {
      inventorycount {
        inventorycountId
      }
    }
  }
`;

export default SAVE_LOCATION_STORAGE_INVENTORY_COUNT;
