import React, { createContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import LocationView from "./View";
import { uglifyId } from "../../../../utils";

export const LocationIdContext = createContext();

const Location = () => {
  const { locationId, currentTab } = useParams();
  const fullLocationId = uglifyId("Location", locationId);

  const history = useHistory();

  const handleTabChange = (event, newTabValue) => {
    event.preventDefault();
    history.replace(newTabValue);
  };

  return (
    <LocationIdContext.Provider value={fullLocationId}>
      <LocationView isNew={locationId === "new"} currentTab={currentTab || "generalInfo"} handleTabChange={handleTabChange} />
    </LocationIdContext.Provider>
  );
};

export default Location;
