import { gql } from '@apollo/client';

const TAX_GROUP_LIST = gql`query taxGroups($filter: [TaxgroupFilter] ){
  viewer {
    taxgroupConnection(filter: $filter) {
    edges {
        node {
          id
          label
          taxgroupId
        } 
      }     
    }
  }
}
`;

export default TAX_GROUP_LIST;
