import { gql } from '@apollo/client';

const CREATE_HIERARCHY = gql`
  mutation createHierarchy($input: createHierarchyMutationInput!) {
    createHierarchy(input: $input) {
      hierarchy {
        id
        hierarchyId
      }
    }
  }
`;

export default CREATE_HIERARCHY;
