import React from 'react';
import { useQuery } from '@apollo/client';
import AddItemsModalView from './View';
import { FETCH_MENU_LIST_ALL } from '../../../../../../../../apollo/queries';

function AddItemsModal({ setExcludedItems, excludedItems, handleClose, multiple }) {
  // const { loading, error, data } = useQuery(FETCH_MENU_LIST_ALL, {
  //   variables: {
  //     language: "english",
  //     filter: {
  //       showHiddenToLocation: { eq: true },
  //       showUnavailableOnline: { eq: true },
  //     },
  //   },
  // });
  // if (loading) return <p>Loading ...</p>;
  // if (error) return "ERROR";

  // const generateTreeData = (data) =>
  //   data?.viewer?.menuConnection?.edges.map((menu) => {
  //     return {
  //       key: `${menu.node.id}:${menu.node.label}`,
  //       id: menu.node.id,
  //       menuId: menu.node.menuId,
  //       title: menu.node.label,
  //       isMenu: true,
  //       children: menu.node.menuCategoryConnection.edges.map((menuCategory) => {
  //         return {
  //           key: `${menuCategory.node.category.id}:${menuCategory.node.category.label}`,
  //           id: menuCategory.node.category.id,
  //           categoryId: menuCategory.node.category.categoryId,
  //           menuId: menu.node.menuId,
  //           title: menuCategory.node.category.label,
  //           isCategory: true,
  //           children: menuCategory.node.category.subcategoryConnection.edges.map(
  //             (subcategory) => {
  //               return {
  //                 key: `${subcategory.node.id}:${subcategory.node.label}:${subcategory.node.subcategoryId}`,
  //                 id: subcategory.node.id,
  //                 subcategoryId: subcategory.node.subcategoryId,
  //                 menuId: menu.node.menuId,
  //                 categoryId: menuCategory.node.category.categoryId,
  //                 title: subcategory.node.label,
  //                 isSubcategory: true,
  //                 children: subcategory.node.subcategoryItemConnection.edges.map(
  //                   (subcategoryItem) => {
  //                     return {
  //                       key: `${subcategoryItem.node.item.id}:${subcategoryItem.node.item.label}:${subcategory.node.subcategoryId}`,
  //                       id: subcategoryItem.node.item.id,
  //                       itemId: subcategoryItem.node.item.itemId,
  //                       subcategoryId: subcategory.node.subcategoryId,
  //                       categoryId: menuCategory.node.category.categoryId,
  //                       menuId: menu.node.menuId,
  //                       title: subcategoryItem.node.item.label,
  //                       isItem: true,
  //                     };
  //                   }
  //                 ),
  //               };
  //             }
  //           ),
  //         };
  //       }),
  //     };
  //   });

  // const formated_data = generateTreeData(data);

  return (
    <AddItemsModalView
      // data={formated_data}
      setExcludedItems={setExcludedItems}
      excludedItems={excludedItems}
      handleClose={handleClose}
      multiple={multiple}
    />
  );
}

export default AddItemsModal;
