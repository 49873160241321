import { Chip } from "@material-ui/core";
import React from "react";

function SelectItemsNew({
  discountDetailsItems,
  setDiscountDetailsItems,
  title,
}) {
  return (
    <div>
      <h3>{title}</h3>
      <div>
        {discountDetailsItems.map((item) => (
          <Chip
            style={{ margin: "4px" }}
            key={item.id}
            label={item.label}
            onDelete={(e) =>
              setDiscountDetailsItems(
                discountDetailsItems.filter((_item) => _item.id !== item.id)
              )
            }
          />
        ))}
      </div>
    </div>
  );
}

export default SelectItemsNew;
