import { gql } from '@apollo/client';
import locationStorageFragment from '../../fragments/locationStorageFragment';

const UPDATE_LOCATION_STORAGE = gql`
  mutation UPDATE_LOCATION_STORAGE($input: UpdateLocationStorageInput!) {
    updateLocationStorage(input: $input) {
      viewer {
        id
      }
      locationStorage {
        ...LocationStorageData
      }
    }
  }

  ${locationStorageFragment.locationStorage}
`;

export default UPDATE_LOCATION_STORAGE;
