import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

function AllergenCheckbox({ allergen, ingredientAllergenConnection, register }) {
  const [checked, setChecked] = useState(
    !!ingredientAllergenConnection.edges.filter(edge => edge.node.allergen.id === allergen.id)[0],
  );
  const checkUncheck = () => {
    setChecked(!checked);
  };
  return (
    <div>
      <Checkbox
        inputRef={register}
        checked={checked}
        onChange={checkUncheck}
        name="allergens"
        value={allergen.id}
        color="primary"
      />
    </div>
  );
}

AllergenCheckbox.propTypes = {
  allergen: PropTypes.object,
  ingredientAllergenConnection: PropTypes.object,
  register: PropTypes.func,
};

export default AllergenCheckbox;
