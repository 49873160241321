import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { DISPUTES, LOCATIONS } from "../../../../../apollo/queries";
import { useQuery } from "@apollo/client";
import {
  CATEGORIES,
  HANDOFFS,
  ORIGINS,
  STAGES,
} from "../../../../../apollo/queries/filters";
import DisputesListView from "./View";
import { convert } from '../../../../../utils';

function Disputes() {
  const history = useHistory();
  const [
    openDateRangeSelectionModal,
    setOpenDateRangeSelectionModal,
  ] = useState(false);
  const [dateRangeStart, setDateRangeStart] = useState(moment());
  const [dateRangeEnd, setDateRangeEnd] = useState(moment());
  const {
    data: originData,
    loading: originLoading,
    error: originError,
  } = useQuery(ORIGINS);
  const {
    data: stageData,
    loading: stageLoading,
    error: stageError,
  } = useQuery(STAGES);

  const {
    data: handoffData,
    loading: handoffLoading,
    error: handoffError,
  } = useQuery(HANDOFFS);
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery(CATEGORIES, {
    variables: {
      filter: {
        id: {
          //[Order Menu, Catering, Holiday, Upsell],
          in: [
            "Q2F0ZWdvcnk6MTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==",
            "Q2F0ZWdvcnk6Mjo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==",
            "Q2F0ZWdvcnk6NDo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==",
            "Q2F0ZWdvcnk6NTo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==",
            "Q2F0ZWdvcnk6Mzo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA==",
          ],
        },
        showHiddenToLocation: {
          eq: true,
        },
        showHiddenToCustomer: {
          eq: true,
        },
      },
    },
  });
  if (originLoading || stageLoading || categoryLoading || handoffLoading)
    <div>loading...</div>;
  if (originError || stageError || categoryError || handoffError)
    <div>error</div>;

  const getSelectedDateRangeValue = (variables) => {
    const filters = variables.filter;
    const startDate = filters?.expected?.gte;
    const endDate = filters?.expected?.lte;

    return `${(startDate && moment(startDate)?.format("MM/DD/YYYY")) ||
      "MM/DD/YYYY"} to ${(endDate && moment(endDate)?.format("MM/DD/YYYY")) ||
      "MM/DD/YYYY"}`;
  };

  const handleDateRangeSelection = (
    { refetch, variables },
    removeDate = false
  ) => {
    if (removeDate) {
      const filters = variables.filter;
      delete filters.expected;
      refetch({
        ...variables,
        filter: filters,
      });
    } else {
      // need only date
      const extractedDateStart = dateRangeStart.format('YYYY-MM-DD');
      const extractedDateEnd = dateRangeEnd.format('YYYY-MM-DD');
      // convert date to utc
      const startDateConvertedToUTC = moment(extractedDateStart).utc();
      const endDateConvertedToUTC = moment(extractedDateEnd).utc().add(23, 'hours').add('59', 'minutes').add(59, 'seconds');

      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          expected: {
            gte: startDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss'),
            lte: endDateConvertedToUTC.format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      });
    }
    setOpenDateRangeSelectionModal(false);
  };

  const handleDateRangeStartChange = (value) => {
    setDateRangeStart(value);
  };
  const handleDateRangeEndChange = (value) => {
    setDateRangeEnd(value);
  };

  const handleOpenDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(true);
  };
  const handleCloseDateRangeSelectionModal = () => {
    setOpenDateRangeSelectionModal(false);
  };
  return (
    <DisputesListView
      query={DISPUTES}
      history={history}
      locationSelectFactoryQuery={LOCATIONS}
      sources={originData}
      stages={stageData}
      categories={categoryData}
      handoffs={handoffData}
      dateRangeStart={dateRangeStart}
      dateRangeEnd={dateRangeEnd}
      handleDateRangeSelection={handleDateRangeSelection}
      openDateRangeSelectionModal={openDateRangeSelectionModal}
      getSelectedDateRangeValue={getSelectedDateRangeValue}
      handleDateRangeStartChange={handleDateRangeStartChange}
      handleDateRangeEndChange={handleDateRangeEndChange}
      handleOpenDateRangeSelectionModal={handleOpenDateRangeSelectionModal}
      handleCloseDateRangeSelectionModal={handleCloseDateRangeSelectionModal}
    />
  );
}

export default Disputes;
