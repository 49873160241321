import { gql } from '@apollo/client';

const ORIGINS = gql`
  query Origins($filter: [OriginFilter], $sort: [OriginSort]) {
    viewer {
      id
      originConnection(filter: $filter, sort: $sort) {
        permissions
        edges {
          node {
            id
            originId
            label
          }
        }
      }
    }
  }
`;

export default ORIGINS;
