import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import React from "react";
import { Controller } from "react-hook-form";
function ContactCustomerView({
  disputeReason,
  data,
  errors,
  submit,
  handleSubmit,
  handleClose,
  control,
  disableButton,
}) {
  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Typography style={{ marginBottom: "1rem" }} gutterBottom>
            Chargeback reason: <strong>{disputeReason.toUpperCase()}</strong>
          </Typography>
          <Typography
            style={{
              color: "orange",
              fontWeight: "bold",
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
            }}
            gutterBottom
          >
            <div>
              <span>
                <ReportProblemOutlinedIcon
                  style={{
                    backgroundColor: "orange",
                    borderRadius: "100%",
                    color: "white",
                    fontSize: "3rem",
                    padding: "5px",
                  }}
                  fontSize="large"
                />
              </span>
            </div>
            <div>
              This form is to get in touch with the cardholder to understand the
              reason for the chargeback, It’s possible that the customer simply
              did not recognize the transaction. Contacting the customer can
              often help resolve the issue quickly. If the chargeback is the
              result of a misunderstanding, then the customer can ask their bank
              to withdraw the chargeback. If that happens, you still need to
              submit evidence about it, such as an email where customer claims
              he drops his chargeback request.
            </div>
          </Typography>
          <div style={{ marginBottom: "1rem" }}>
            <Controller
              control={control}
              defaultValue={data?.viewer?.quote?.location?.email}
              name="locationEmail"
              render={({ value }) => {
                return (
                  <TextField disabled value={value} fullWidth label="From" />
                );
              }}
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <Controller
              control={control}
              name="customerEmail"
              defaultValue={data?.viewer?.quote?.email}
              render={({ value }) => {
                return (
                  <TextField disabled value={value} fullWidth label="To" />
                );
              }}
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <Controller
              control={control}
              name="orderId"
              defaultValue={`Chargeback on Order #${data?.viewer?.quote?.quoteId}`}
              render={({ value }) => {
                return (
                  <TextField disabled value={value} fullWidth label="Subject" />
                );
              }}
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <Controller
              control={control}
              name="message"
              defaultValue=""
              rules={{
                required: "Message is required!",
                validate: (data) => {
                  return data.trim().length === 0
                    ? "Message can't be empty"
                    : true;
                },
              }}
              render={({ value, onChange }) => {
                return (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    multiline
                    rows="5"
                    label="Message"
                    error={errors?.message}
                    helperText={errors?.message?.message}
                  />
                );
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={disableButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={disableButton}
            type="submit"
            style={{ color: "white", background: "red" }}
          >
            SEND TO CUSTOMER
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

export default ContactCustomerView;
