import { gql } from '@apollo/client';
import { stateFragment } from '../fragments';

const FETCH_LOCATION = gql`
  query fetchLocationExclusivelocation(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: [locationExclusiveDeliveryAddressFilter]
  ) {
    viewer {
      id
      locationExclusiveDeliveryAddressConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        fieldGroup: 18
      ) {
        permissions
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            locationId
            addressId
            address {
              id
              addressId
              address
            }
          }
        }
      }
      stateConnection(first: $first, fieldGroup: 18) {
        permissions
        edges {
          node {
            ...StateData
          }
        }
      }
    }
  }
  ${stateFragment.state}
`;

export default FETCH_LOCATION;
