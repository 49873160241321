import LocationLog from './LocationLogs';
import LocationItemLog from './LocationItemLogs';
import QuoteLog from './QuoteLogs';
import QuotecateringLog from './CateringLogs';
import LocationInventoryitemLog from './LocationInventoryitemLogs';

export const ActionLogs = {
  LocationLog,
  LocationItemLog,
  QuoteLog,
  QuotecateringLog,
  LocationInventoryitemLog,
};
