import React, { useState } from 'react';
import { Typography, Button, Grid, Divider, Chip } from '@material-ui/core';

import { Modal } from '../../blocks';
import { CrossSellStyles } from './style';
import AddItemsModal from './AddItemsModal';

const CrossSell = ({ crossSellItems, setCrossSellItems, menuId, categoryId, menuData }) => {
  const classes = CrossSellStyles();
  const [openModal, setOpenModal] = useState(false);
  const [itemSelectionFilter, setItemSelectionFilter] = useState({});

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleDelete = itemId => {
    const newCrossSellItems = crossSellItems.filter(item => item.id !== itemId);
    setCrossSellItems(newCrossSellItems);
  };

  return (
    <>
      <Divider className={classes.divider} />

      <Grid xs={12}>
        <Grid container xs={12} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="h4" className={classes.title}>
              Cross-sell
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Button onClick={handleOpen} variant="outlined" className={classes.addItemButton}>
              Add Items
            </Button>
          </Grid>
        </Grid>

        <Grid xs={12} className={classes.chipGrid}>
          {crossSellItems.map(item => (
            <Chip
              key={item.id}
              className={classes.chip}
              label={item.label}
              onDelete={e => handleDelete(item.id)}
            />
          ))}
          {!crossSellItems.length && <h3 className={classes.noItems}>No selected items.</h3>}
        </Grid>
      </Grid>

      <Modal open={openModal} handleClose={handleClose} title="Add Items" maxWidth="sm">
        <AddItemsModal
          menuId={menuId}
          categoryId={categoryId}
          setCrossSellItems={setCrossSellItems}
          handleClose={handleClose}
          crossSellItems={crossSellItems}
          itemSelectionFilter={itemSelectionFilter}
          setItemSelectionFilter={setItemSelectionFilter}
          menuData={menuData}
        />
      </Modal>

      <Divider className={classes.divider} />
    </>
  );
};

export default CrossSell;
