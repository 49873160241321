import { gql } from '@apollo/client';

const ADD_MENU_TO_TREE = gql`
  mutation AddMenuToTree($input: AddMenuToTreeMutationInput!) {
    addMenuToTree(input: $input) {
      viewer {
        id
        menuConnection {
          edges {
            node {
              id
              menuId
              label
              menuCategoryConnection {
                edges {
                  node {
                    id
                    category {
                      id
                      label
                      subcategoryConnection {
                        edges {
                          node {
                            id
                            label
                            subcategoryItemConnection {
                              edges {
                                node {
                                  id
                                  item {
                                    id
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ADD_MENU_TO_TREE;
