import { gql } from '@apollo/client';
import { viewerFragment } from '../fragments/index';

const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    viewer {
      ...ViewerData
    }
  }
  ${viewerFragment.viewer}
`;

export default IS_LOGGED_IN;
