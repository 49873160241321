import { gql } from "@apollo/client";

const SUBCATEGORY_CATERING = gql`
  query subcategoryCatering(
    $filter: [SubcategoryFilter]
  ) {
    viewer {
      id
      subcategoryConnection(
        filter: $filter,
      ) {
        edges {
          node {
            id
            subcategoryId
            label
          }
        }
      }
    }
  }
`;

export default SUBCATEGORY_CATERING;
