import { makeStyles } from '@material-ui/core';
const Styles = theme => ({
  dataTable: {
    display: 'block',
    margin: 'auto',
    width: '60%',
    paddingBottom: '2rem',
  },
});

export const LoyaltyEventsStyle = makeStyles(Styles);
