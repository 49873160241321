import React from 'react';
import AddItemsModalView from './View';
function AddItemsModal({ setExcludedItems, excludedItems, handleClose, multiple }) {
  return (
    <AddItemsModalView
      setExcludedItems={setExcludedItems}
      excludedItems={excludedItems}
      handleClose={handleClose}
      multiple={multiple}
    />
  );
}

export default AddItemsModal;
