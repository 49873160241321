import { gql } from '@apollo/client';

const CREATE_EQUIPMENT = gql`
  mutation CREATE_EQUIPMENT($input: createEquipmentMutationInput!) {
    createEquipment(input: $input) {
      viewer {
        id
      }
      equipment {
        equipmentId
      }
    }
  }
`;

export default CREATE_EQUIPMENT;
