import { gql } from '@apollo/client';

export const FETCH_LOCATION_COUNT_SHEET = gql`
  query FETCH_LOCATION_COUNT_SHEET(
    $locationId: Int
    $locationStorageFilter: [LocationStorageFilter]
    $shouldSkip: Boolean!
  ) {
    viewer {
      id
      locationStorageConnection(
      filter: $locationStorageFilter 
      fieldGroupOnly: 88) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            locationStorageId
            customLabel
            status
            storagestatusId
            storage {
              label
              storageId
            }
            locationStorageInventoryitemConnection @skip(if: $shouldSkip ) {
              edges {
                node {
                  id
                  inventoryitem {
                    id
                    inventoryitemId
                    label
                  }
                }
              }
            }
          }
        }
      }
      locationConnection(filter: { locationId: { eq: $locationId } }) {
        edges {
          node {
            id
            inventorycount {
              id
              inventorycountId
              dateCounted
            }
          }
        }
      }
    }
  }
`;
