import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
	inputRoot: {
		height: 'fit-content', 
		width: '100%',  
	},
	option: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		'&:hover $info': {
			visibility: "visible",
		}
	},
	info: {
		visibility: "hidden",
	},
	tooltip: {
		fontSize: "1rem !important",
	}
});

const TooltipStyle = (theme) => ({
	tooltip: {
		fontSize: theme.typography.fontSizeXS,
	}
});

export const SelectFactoryStyles = makeStyles(Styles);
export const TooltipStyles = makeStyles(TooltipStyle);
