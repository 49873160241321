// remove duplicates for array of objects by one of their properties
const removeDuplicates = (duplicates, value) => {
  const flag = {};
  const unique = [];
  duplicates.forEach(elem => {
    if (!flag[elem[value]]) {
      flag[elem[value]] = true;
      unique.push(elem);
    }
  });
  return unique;
}

export default removeDuplicates;
