import React from "react";
import MultipleChoiceTable from "./MultipleChoiceTable";

const ChoiceSettings = ({ setNumberOfMultipleChoices, setMultipleLabel }) => {
  return (
    <div>
      <MultipleChoiceTable setNumberOfMultipleChoices={setNumberOfMultipleChoices} setMultipleLabel={setMultipleLabel}/>
    </div>
  );
};

export default ChoiceSettings;
