import { gql } from "@apollo/client";

export const UPDATE_DEVICE_PRINTERSTATION = gql`
mutation updateDevicePrinterstation(
  $input: UpdateDevicePrinterstationInput!
) {
  updateDevicePrinterstation(input: $input) {
    devicePrinterstation {
      devicePrinterstationId
      printerstation_id
    }
  }
}

`;