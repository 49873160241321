import { convert } from './index';

/**
 * Converting HA limit and balance for frontend usage (they are saved as negative values in database)
 * @param value
 * @returns {string|null}
 */
export const convertHouseAccountValueForFrontend = value => {
  return value || value === 0 ? Math.abs(convert.centsToDollars(value || 0)).toFixed(2) : null;
};

/**
 * Converting HA limit and balance to negative values for the API
 * @param value
 * @returns {number|null}
 */
export const convertHouseAccountValueForDatabase = value => {
  return value || value === 0 ? -Math.abs(convert.dollarsToCents(value || 0)) : null;
};

/**
 * Calculating available spending amount for House Account
 * @param limit
 * @param balance
 * @returns {string|null}
 */
export const getAvailableCredit = (limit, balance) => {
  return limit || limit === 0
    ? Math.abs(convert.centsToDollars((limit || 0) - (balance || 0))).toFixed(2)
    : null;
};
