import { Container, Divider, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ConfirmationModal, Elements, Modal, PermissionField } from '../../../../../blocks';
import { Grid } from '@material-ui/core';
import { uglifyId, convert } from '../../../../../../utils';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import LOCATION_INGREDIENTS from '../../../../../../apollo/queries/menus/locationIngredients';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useCallback, useState } from 'react';
import TOGGLE_LOCATION_INGREDIENT from '../../../../../../apollo/mutations/menus/toggleLocationIngredient';
import UPDATE_LOCATION_INGREDIENT from '../../../../../../apollo/mutations/menus/updateLocationIngredient';
import Permission from '../../../../../blocks/Permission';
import { MUTATION_NAME } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { isEmpty } from 'lodash';
import { TOGGLE_ALL_LOCATION_INGREDIENTS } from '../../../../../../apollo/mutations/inventory/toggleAllLocationIngredients';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    justifyContent: 'flex-start',
    display: 'flex',
    padding: '0 8px',
    margin: '1rem',
    '& > button:first-child': {
      marginRight: '1rem',
      backgroundColor: 'transparent',
      borderColor: '#e7e8ef',
    },
  },
}));

const Ingredients = ({
  locationId,
  statuses,
  locationIds,
  mutationPermissions,
  isBulkEdit,
  bulkEditPermission,
  accessToMutations,
  ingredientPermissions,
  setIngredientPermissions,
}) => {
  const { setSnackbar } = useSnackbar();
  const toggleLocationIngredientPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'toggleLocationIngredient',
  );
  const toggleAllLocationIngredientsPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'toggleAllIngredientsOnLocation',
  );

  const updateLocationIngredientPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'updateLocationIngredient',
  );

  const [updateLocationIngredient] = useMutation(UPDATE_LOCATION_INGREDIENT);
  const [toggleLocationIngredient] = useMutation(TOGGLE_LOCATION_INGREDIENT, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Ingredient is updated.',
      });
    },
  });
  const [queryVariables, setQueryVariables] = useState({});
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [activeInfo, setActiveInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    message: '',
    action: null,
  });

  const classes = useStyles();

  const toggleIngredient = ({ ingredientId, toggle }) => {
    const toRefetch = [
      {
        query: LOCATION_INGREDIENTS,
        variables: queryVariables,
      },
    ];

    toggleLocationIngredient({
      variables: {
        input: {
          locationIds: locationIds || [
            {
              id: uglifyId('Location', locationId),
            },
          ],
          ingredientId,
          toggle,
        },
      },
      refetchQueries: toRefetch,
    });
  };
  const [toggleAllLocationIngredients] = useMutation(TOGGLE_ALL_LOCATION_INGREDIENTS, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'All Ingredients are updated.',
      });
      setOpenActions(false);
    },
    refetchQueries: [
      {
        query: LOCATION_INGREDIENTS,
        variables: queryVariables,
      },
    ],
  });
  const toggleAllIngredientsOn = async () => {
    toggleAllLocationIngredients({
      variables: {
        input: {
          locationId,
          inStock: true,
        },
      },
    });
  };

  const toggleAllIngredientsOff = async () => {
    toggleAllLocationIngredients({
      variables: {
        input: {
          locationId,
          inStock: false,
        },
      },
    });
  };

  const { register, errors, control, handleSubmit, setValue } = useForm();

  const onSubmit = formData => {
    const formatData = {
      locationIds: locationIds || [{ id: uglifyId('Location', locationId) }],
      ingredients: [],
    };
    for (const property in formData) {
      if (isBulkEdit) {
        formatData.ingredients.push({
          ingredientId: property,
          cost: formData[property]
            ? formData[property]
            : formData[property] === null
            ? null
            : undefined,
        });
      } else {
        formatData.ingredients.push({
          ingredientId: property,
          cost: formData[property] ? formData[property] : null,
        });
      }
    }
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Ingredients price are updating.',
    });

    updateLocationIngredient({
      variables: {
        input: formatData,
      },
      refetchQueries: [
        {
          query: LOCATION_INGREDIENTS,
          variables: { ...queryVariables },
        },
      ],
    }).then(r =>
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Ingredients are updated.',
      }),
    );
  };

  const getLocationIngredient = (ingredientId, locationIngredients, permissions) => {
    const locationObj = {
      permissions,
    };
    const locationIngredient = locationIngredients.filter(
      edge => edge?.node?.inventoryitem?.id === ingredientId,
    );
    if (locationIngredient.length) {
      locationObj.cost = isBulkEdit ? undefined : locationIngredient[0].node.cost;
      locationObj.id = ingredientId;
      locationObj.available = locationIngredient[0].node.available;
      locationObj.theoreticalQuantity = `${locationIngredient[0].node?.theoreticalQuantity ||
        '0'} ${locationIngredient[0].node?.measure?.abbreviation || ''}`;
    } else {
      locationObj.cost = isBulkEdit ? undefined : 0;
      locationObj.id = ingredientId;
      locationObj.available = 0;
      locationObj.theoreticalQuantity = '-';
    }

    return locationObj;
  };

  const handleInventoryitemTypeChange = useCallback(({ value, refetch, variables }) => {
    const filters = variables.filter;
    if (value === -1) {
      if (filters?.type) {
        delete filters.type;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      if (value === 'ingredient') {
        if (filters?.recipetypeId) {
          delete filters.recipetypeId;
        }
      }
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          type: { eq: value },
        },
      });
    }
  }, []);

  const handleOnRecipetypeChange = useCallback(({ value, refetch, variables }) => {
    if (value === -1) {
      const filters = variables.filter;
      if (filters?.recipetypeId) {
        delete filters.recipetypeId;
      }

      refetch({
        ...variables,
        filter: !isEmpty(filters) ? filters : undefined,
      });
    } else {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          recipetypeId: {
            eq: value,
          },
        },
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={'ingredients'}>
      <Container id={'ingredients'}>
        <ConnectionTable
          query={LOCATION_INGREDIENTS}
          customActionsCenter={(_, { refetch, variables }) => (
            <Grid container spacing={2} justify="flex-end">
              {!isBulkEdit && (
                <Grid item xs={3} md={3}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <Permission access={!!toggleAllLocationIngredientsPermission?.access}>
                      <div>
                        <PermissionField>
                          <Button onClick={() => setOpenActions(true)}>Stock Actions</Button>
                        </PermissionField>
                      </div>
                    </Permission>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={6} md={4}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel>Inventory item Type</InputLabel>
                  <Select
                    label="Type"
                    multiple={false}
                    defaultValue={-1}
                    onChange={({ target: { value } }) =>
                      handleInventoryitemTypeChange({ value, refetch, variables })
                    }
                  >
                    {[
                      { label: 'All', value: -1 },
                      { label: 'Ingredient', value: 'ingredient' },
                      { label: 'Recipe', value: 'recipe' },
                    ]?.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {variables?.filter?.type?.eq === 'recipe' && (
                <Grid item xs={6} md={4}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel>Recipe Type</InputLabel>
                    <Select
                      label="Recipe Type"
                      multiple={false}
                      defaultValue={-1}
                      onChange={({ target: { value } }) =>
                        handleOnRecipetypeChange({ value, refetch, variables })
                      }
                    >
                      {[
                        { label: 'All', value: -1 },
                        { label: 'Prep', value: 1 },
                        { label: 'Line', value: 2 },
                      ]?.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          )}
          title={'Inventory items'}
          initialQueryVariables={{
            first: 15,
            sort: {
              label: 'ASC',
            },
            filter: {
              showOnLocation: {
                eq: 1,
              },
            },
            locationFilter: {
              locationId: isBulkEdit
                ? {
                    in: locationIds.map(location => atob(location.id)?.split(':')?.[1]),
                  }
                : {
                    eq: locationId,
                  },
            },
          }}
          structureTable={(data, vars) => {
            if (!ingredientPermissions) {
              setIngredientPermissions({
                ingredientId: data?.viewer?.inventoryitemConnection?.permissions?.id,
                toggle: data?.viewer?.locationIngredientConnection?.permissions?.available,
              });
            }

            setQueryVariables(vars);
            return data?.viewer?.inventoryitemConnection?.edges.map(edge => {
              const locationIngredient = getLocationIngredient(
                edge?.node?.id,
                data?.viewer?.locationIngredientConnection?.edges,
                data?.viewer?.locationIngredientConnection?.permissions,
              );
              const recipes = edge?.node?.partOf?.recipes || [];
              const recipeIngredients = edge?.node?.partOf?.recipeIngredients || [];
              locationIngredient.dependenciesToRefetch = [
                ...recipes.map(r => r.id),
                ...recipeIngredients.map(r => r.id),
              ];
              return {
                id: edge?.node?.id,
                name: edge?.node?.label,
                locationIngredient: locationIngredient,
                theoreticalQuantity: locationIngredient.theoreticalQuantity,
                locationIngredientAvailable: locationIngredient,
                partOf: { details: edge.node, partOfDetails: edge.node.partOf },
              };
            });
          }}
          columns={[
            {
              title: 'Name',
              field: 'name',
              customSortField: 'label',
              customPermissionField: 'label',
            },
            {
              title: 'STOCK STATUS',
              field: 'locationIngredientAvailable',
              customSortField: 'available',
              disableSort: true,
              render: locationIngredientAvailable => {
                return statuses ? (
                  <Permission
                    access={
                      isBulkEdit
                        ? bulkEditPermission.access
                        : Math.min(
                            toggleLocationIngredientPermission?.access,
                            locationIngredientAvailable?.permissions?.available,
                          )
                    }
                  >
                    <div>
                      <PermissionField
                        createHelperText
                        Component={Elements.ToggleButton}
                        ref={locationIngredientAvailable.ref}
                        options={statuses}
                        value={statuses[0].value}
                        handleToggleClick={value => {
                          toggleIngredient({
                            ingredientId: locationIngredientAvailable.id,
                            toggle: value,
                            locationIngredient: locationIngredientAvailable,
                          });
                        }}
                      />
                    </div>
                  </Permission>
                ) : (
                  <Permission
                    access={
                      isBulkEdit
                        ? bulkEditPermission.access
                        : Math.min(
                            toggleLocationIngredientPermission?.access,
                            locationIngredientAvailable?.permissions?.available,
                            accessToMutations?.[
                              MUTATION_NAME.ToggleLocationIngredientMutationInput
                            ],
                          )
                    }
                  >
                    <div>
                      <PermissionField
                        createHelperText
                        Component={Elements.Switch}
                        checked={locationIngredientAvailable.available}
                        handleSwitchChange={toggle =>
                          toggleIngredient({
                            ingredientId: locationIngredientAvailable.id,
                            toggle,
                            locationIngredient: locationIngredientAvailable,
                          })
                        }
                      />
                    </div>
                  </Permission>
                );
              },
            },
            {
              title: 'Quantity',
              field: 'theoreticalQuantity',
              disableSort: true,
            },
            {
              title: 'Info',
              field: 'partOf',
              render: data => {
                return (
                  <Button
                    onClick={() => {
                      setActiveInfo(data);
                      setOpen(true);
                    }}
                  >
                    Info
                  </Button>
                );
              },
            },
          ]}
        />
        <Grid container spacing={4} alignItems="center" className={classes.buttonGroup}>
          <Button type={'button'} onClick={() => setShouldRefetch(true)} variant="outlined">
            Discard
          </Button>
          <Permission
            access={
              isBulkEdit
                ? bulkEditPermission.access
                : Math.min(
                    updateLocationIngredientPermission?.access,
                    accessToMutations?.[MUTATION_NAME.UpdateLocationIngredientMutationInput],
                  )
            }
          >
            <Button type={'submit'}>Save</Button>
          </Permission>
        </Grid>
      </Container>
      {activeInfo && (
        <Modal
          handleClose={() => {
            setOpen(false);
            setActiveInfo(null);
          }}
          open={open}
          title={`${activeInfo?.details?.label} - ID :${activeInfo?.details?.inventoryitemId}`}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <h2>Linked Items</h2>
                <p>This {activeInfo.details.type} is linked to those items directly.</p>
              </div>
              <div>
                {activeInfo.partOfDetails.items.length ? (
                  activeInfo.partOfDetails.items.map(item => (
                    <h4>
                      {item.label} -{item.itemId}
                    </h4>
                  ))
                ) : (
                  <h3>No Links</h3>
                )}
              </div>
            </Grid>
            <Grid item xs={6} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <h2>Linked Recipes</h2>
                <p>This {activeInfo.details.type} is an ingredient in these recipes.</p>
              </div>
              <div>
                {activeInfo.partOfDetails.recipes.length ? (
                  activeInfo.partOfDetails.recipes.map(item => (
                    <h4>
                      {item.label} - {item.id}
                    </h4>
                  ))
                ) : (
                  <h3>No Links</h3>
                )}
              </div>
            </Grid>
            <Grid item xs={6} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <h2>Linked Choices</h2>
                <p>This {activeInfo.details.type} is linked to these choices directly.</p>
              </div>
              <div>
                {activeInfo.partOfDetails.choices.length ? (
                  activeInfo.partOfDetails.choices.map(item => (
                    <h4>
                      {item.label} - {item.id}
                    </h4>
                  ))
                ) : (
                  <h3>No Links</h3>
                )}
              </div>
            </Grid>
          </Grid>
          {activeInfo.details.type === 'recipe' && <Divider style={{ width: '100%' }} />}
          {activeInfo.details.type === 'recipe' &&
            !activeInfo.partOfDetails.recipeIngredients.length && <h3>No Components</h3>}

          <Grid container spacing={2}>
            {activeInfo.details.type === 'recipe' &&
              !!activeInfo.partOfDetails.recipeIngredients.length && (
                <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <h2>Recipe Components</h2>
                    <p>This {activeInfo.details.type} consists of these components.</p>
                    <p>
                      NOTE: Recipe {activeInfo?.details?.label} will be available only if all of
                      these components are available
                    </p>
                  </div>
                  <div>
                    <div>
                      <ConnectionTable
                        query={LOCATION_INGREDIENTS}
                        initialQueryVariables={{
                          sort: {
                            label: 'ASC',
                          },
                          filter: {
                            inventoryitemId: {
                              in: activeInfo.partOfDetails.recipeIngredients.map(item => item.id),
                            },
                          },
                          locationFilter: {
                            locationId: isBulkEdit
                              ? {
                                  in: locationIds.map(
                                    location => atob(location.id)?.split(':')?.[1],
                                  ),
                                }
                              : {
                                  eq: locationId,
                                },
                            ingredientId: {
                              in: activeInfo.partOfDetails.recipeIngredients.map(item => item.id),
                            },
                          },
                        }}
                        shouldRefetch={shouldRefetch}
                        showSearch={false}
                        showPagination={false}
                        setShouldRefetch={setShouldRefetch}
                        structureTable={(data, vars) => {
                          if (!ingredientPermissions) {
                            setIngredientPermissions({
                              ingredientId: data?.viewer?.inventoryitemConnection?.permissions?.id,
                              toggle:
                                data?.viewer?.locationIngredientConnection?.permissions?.available,
                            });
                          }

                          return data?.viewer?.inventoryitemConnection?.edges.map(edge => {
                            const locationIngredient = getLocationIngredient(
                              edge?.node?.id,
                              data?.viewer?.locationIngredientConnection?.edges,
                              data?.viewer?.locationIngredientConnection?.permissions,
                            );
                            const recipes = edge?.node?.partOf?.recipes || [];
                            const recipeIngredients = edge?.node?.partOf?.recipeIngredients || [];
                            locationIngredient.dependenciesToRefetch = [
                              ...recipes.map(r => r.id),
                              ...recipeIngredients.map(r => r.id),
                            ];
                            return {
                              id: edge?.node?.id,
                              name: edge?.node?.label,
                              locationIngredient: locationIngredient,
                              theoreticalQuantity: locationIngredient.theoreticalQuantity,
                              locationIngredientAvailable: locationIngredient,
                            };
                          });
                        }}
                        columns={[
                          {
                            title: 'Name',
                            field: 'name',
                            customSortField: 'label',
                            customPermissionField: 'label',
                          },
                          {
                            title: 'Stock Status',
                            field: 'locationIngredientAvailable',
                            customSortField: 'available',
                            disableSort: true,
                            render: locationIngredientAvailable => {
                              return statuses ? (
                                <Permission
                                  access={
                                    isBulkEdit
                                      ? bulkEditPermission.access
                                      : Math.min(
                                          toggleLocationIngredientPermission?.access,
                                          locationIngredientAvailable?.permissions?.available,
                                        )
                                  }
                                >
                                  <div>
                                    <PermissionField
                                      createHelperText
                                      Component={Elements.ToggleButton}
                                      ref={locationIngredientAvailable.ref}
                                      options={statuses}
                                      value={statuses[0].value}
                                      handleToggleClick={value => {
                                        toggleIngredient({
                                          ingredientId: locationIngredientAvailable.id,
                                          toggle: value,
                                          locationIngredient: locationIngredientAvailable,
                                        });
                                      }}
                                    />
                                  </div>
                                </Permission>
                              ) : (
                                <Permission
                                  access={
                                    isBulkEdit
                                      ? bulkEditPermission.access
                                      : Math.min(
                                          toggleLocationIngredientPermission?.access,
                                          locationIngredientAvailable?.permissions?.available,
                                          accessToMutations?.[
                                            MUTATION_NAME.ToggleLocationIngredientMutationInput
                                          ],
                                        )
                                  }
                                >
                                  <div>
                                    <PermissionField
                                      createHelperText
                                      Component={Elements.Switch}
                                      checked={locationIngredientAvailable.available}
                                      handleSwitchChange={toggle =>
                                        toggleIngredient({
                                          ingredientId: locationIngredientAvailable.id,
                                          toggle,
                                          locationIngredient: locationIngredientAvailable,
                                        })
                                      }
                                    />
                                  </div>
                                </Permission>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </Grid>
              )}
          </Grid>
        </Modal>
      )}
      <Modal
        maxWidth="sm"
        title="Actions"
        open={openActions}
        handleClose={e => setOpenActions(false)}
      >
        <div style={{ textAlign: 'center', width: '100%' }}>
          <h2>All Ingredients Stock Status</h2>
          <p>This action is not reversible</p>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>
              <Button
                onClick={() => {
                  setConfirmationModal({
                    open: true,
                    message: 'Are you sure you want to turn ON all ingredients for this location?',
                    action: toggleAllIngredientsOn,
                  });
                }}
              >
                In stock
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setConfirmationModal({
                    open: true,
                    message: 'Are you sure you want to turn OFF all ingredients for this location?',
                    action: toggleAllIngredientsOff,
                  });
                }}
              >
                Out of stock
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        open={confirmationModal.open}
        handleClose={() => {
          setConfirmationModal({ open: false, message: '', action: null });
        }}
        text={confirmationModal.message}
        confirmAction={() => confirmationModal.action()}
      />
    </form>
  );
};

export default Ingredients;
