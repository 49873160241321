import React, { useEffect, useState } from 'react';
import TreeView from '@material-ui/lab/TreeView/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ItemChoice from './ItemChoice';
import FETCH_ITEM_CHOICES_INGREDIENTS from '../../../../../../../../../../apollo/queries/menus/fetchItemChoiceIngredients';
import { useQuery } from '@apollo/client';


const ItemChoiceTable = ({ itemId }) => {

  const [multipleData, setMultipleData] = useState([]);
  const [childValue, setChildValue] = useState({});

  const { data: itemData, error, loading, refetch, variables } = useQuery(FETCH_ITEM_CHOICES_INGREDIENTS, {
    variables: {
      filter: {
        itemId: {
          eq: itemId,
        },
      },
    },
  });
  useEffect(() => {
    setMultipleData(
      itemData?.viewer?.itemMultipleConnection?.edges?.map(edge => {
        return {
          id: edge?.node?.multiple?.id,
          order: edge?.node?.order,
          multipleId: edge?.node?.multiple?.multipleId,
          title: edge?.node?.multiple?.label,
          onlineLabel: edge?.node?.multiple?.onlineLabel,
          children: edge?.node?.multiple?.multipleChoiceConnection?.edges?.map(choiceEdge => ({
            id: choiceEdge?.node?.choice?.id,
            choiceId: choiceEdge?.node?.choice?.choiceId,
            order: choiceEdge?.node?.orderKey,
            title: choiceEdge?.node?.choice?.label,
            ingredients: choiceEdge?.node?.choice?.choiceIngredientConnection
          })),
        };
      }),
    );
  }, [itemData]);

  if (loading) return <div>Loading...</div>;
  if (error) console.log(error);
  return (
    <>
      <form>
        <div>
          <div>
            <h2>Item Multiples</h2>
          </div>
        </div>
        <TreeView
          defaultCollapseIcon={
            <div>
              <ExpandMoreIcon fontSize={'large'} />
            </div>
          }
          defaultExpandIcon={
            <div>
              <ChevronRightIcon />
            </div>
          }
        >
          {multipleData?.map((item, index) => {
            return (
              <ItemChoice
                item={item}
                itemId={itemId}
                key={`ItemChoice-${index}`}
                data={multipleData}
                setValue={setChildValue}
                value={childValue}
                itemData={itemData}
                index={index}
                refetch={refetch}
              />
            );
          }) || null}
        </TreeView>
      </form>
    </>
  );
};

export default ItemChoiceTable;
