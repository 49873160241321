import React, { useCallback } from "react";
import {
  Snackbar,
  IconButton,
  Slide,
  SnackbarContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";

import { SnackBarStyles, SnackBarContentStyles } from "./styles";

import { SNACKBAR_STATUS } from "../../../constants";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const SnackBar = ({
  text,
  durationInSec = 3,
  setState,
  open,
  type = SNACKBAR_STATUS.INFO,
}) => {
  const snackbarClasses = SnackBarStyles();
  const snackbarContentClasses = SnackBarContentStyles({ type });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const renderIcon = useCallback((snackbarType) => {
    switch (snackbarType) {
      case SNACKBAR_STATUS.ERROR:
        return <ErrorIcon />;

      case SNACKBAR_STATUS.SUCCESS:
        return <SuccessIcon />;

      case SNACKBAR_STATUS.WARNING:
        return <WarningIcon />;

      default:
        return <InfoIcon />;
    }
  }, []);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={
        [SNACKBAR_STATUS.INFO, SNACKBAR_STATUS.SUCCESS].includes(type)
          ? durationInSec * 1000
          : null
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      TransitionComponent={TransitionUp}
      classes={snackbarClasses}
    >
      <SnackbarContent
        classes={snackbarContentClasses}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        message={
          <div className={snackbarContentClasses.message}>
            {renderIcon(type)}
            <p>{text}</p>
          </div>
        }
      />
    </Snackbar>
  )
};

export default SnackBar;
