import { gql } from '@apollo/client';

const personFragment = {
  person: gql`
    fragment PersonData on Person {
      id
      personId
      firstName
      lastName
      dateOfBirth
      stripeAccount
      phone {
        id
        phone
      }
      login {
        id
        loginId
        email
      }
    }
  `,
};

export default personFragment;
