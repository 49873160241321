import React, { useCallback, useState } from 'react';
import { initialInventoryItem } from '..';
import DragAndDropInput from '../../../../../../blocks/DragAndDropInput';
import AddInventoryItem from './AddInventoryItem';
import InventoryItem from './InventoryItem';

const InventoryItems = ({ control, register, getValues, errors, setValue, unregister }) => {
  const [showSelectFactory, setShowSelectFactory] = useState(false);

  const handleOnSelect = useCallback(() => {
    setShowSelectFactory(false);
  }, []);

  return (
    <DragAndDropInput
      getValues={getValues}
      name="inventoryItems"
      onSelect={handleOnSelect}
      title="Ingredients"
      addItemComponent={AddInventoryItem}
      addItemComponentProps={{
        setShowSelectFactory,
        showSelectFactory,
      }}
      control={control}
      itemComponentProps={{ register, errors, control, setValue, unregister }}
      initialItem={initialInventoryItem}
      itemComponent={InventoryItem}
      enableEmpty={false}
      emptyErrorText="At least one Ingredient is required"
    />
  );
};

export default InventoryItems;
