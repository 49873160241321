import { gql } from '@apollo/client';

const FETCH_ITEMS = gql`
  query fetchItems(
    $filter: [ItemFilter]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    viewer {
      id
      itemConnection(
        filter: $filter
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            itemId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_ITEMS;
