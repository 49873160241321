
import { gql } from '@apollo/client';

const UPDATE_BULK_GENERAL_INFO = gql`
  mutation UpdateBulkGeneralInfo($input: updateBulkGeneralInfoMutationInput!) {
    updateBulkGeneralInfo(input: $input) {
      viewer {
       id
      }
    }
  }
`;

export default UPDATE_BULK_GENERAL_INFO;
