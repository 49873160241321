import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/client';

import ScheduleEmployees from './ScheduleEmployees';
import CalendarScheduleTimelineHeader from './CalendarScheduleTimelineHeader';
import CalendarScheduleTimeline from './CalendarScheduleTimeline';
import { JOB_LIST, FETCH_EMPLOYEES_ON_LOCATION } from '../../../../../apollo/queries';

const ScheduleTimeline = ({
  setScheduleDialogData,
  setIsScheduleModalVisible,
  setOpenWarningMutationName,
  setIsWeekCopy,
  setIsCopyModalVisible,
  prettySchedulesData,
  exportSchedulePDFPermission,
  copySchedulePermission,
  selectedLocationState,
  selectedWeek,
  schedules,
  employeeJobHourTotals,
  scheduleQueryVariables,
  mutationPermissions,
  accessToMutations,
  timelineVisibleDate,
  setVisibleDayStart,
}) => {
  const { data: employeesData } = useQuery(FETCH_EMPLOYEES_ON_LOCATION, {
    variables: {
      filter: {
        showEmployesOnLocation: selectedLocationState?.locationId,
        employeeInactive: { eq: false },
      },
      personJobFilter: {
        locationId: { eq: selectedLocationState?.locationId },
      },
      locationId: selectedLocationState?.locationId,
    },
  });

  const filters = [];
  if (selectedLocationState?.locationId) {
    filters.push({
      locationIds: {
        in: [selectedLocationState?.locationId],
      },
    });
  }

  const { data: jobList } = useQuery(JOB_LIST, {
    skip: !selectedLocationState?.locationId,
    variables: {
      filter: filters,
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={2} direction="column">
        <ScheduleEmployees
          employees={employeesData}
          selectedWeek={selectedWeek}
          setScheduleDialogData={setScheduleDialogData}
          setIsScheduleModalVisible={setIsScheduleModalVisible}
          setOpenWarningMutationName={setOpenWarningMutationName}
          selectedLocationState={selectedLocationState}
        />
      </Grid>
      <Grid item xs={10} direction="column">
        <CalendarScheduleTimelineHeader
          copySchedulePermission={copySchedulePermission}
          prettySchedulesData={prettySchedulesData}
          setIsWeekCopy={setIsWeekCopy}
          setIsCopyModalVisible={setIsCopyModalVisible}
          exportSchedulePDFPermission={exportSchedulePDFPermission}
          jobList={jobList}
          selectedLocationState={selectedLocationState}
          schedules={schedules}
          employeeJobHourTotals={employeeJobHourTotals}
          selectedWeek={selectedWeek}
          timelineVisibleDate={timelineVisibleDate}
          employees={employeesData}
        />

        <CalendarScheduleTimeline
          scheduleData={prettySchedulesData}
          selectedWeek={moment(selectedWeek.ts).tz(selectedLocationState.timezone.label)}
          refetchScheduleVariables={scheduleQueryVariables}
          mutationPermissions={mutationPermissions}
          jobList={jobList?.viewer?.jobConnection?.edges}
          locationTimezone={selectedLocationState.timezone.label}
          schedulePermissions={schedules?.viewer?.scheduleConnection?.permissions}
          setOpenWarningMutationName={setOpenWarningMutationName}
          accessToMutations={accessToMutations}
          timelineVisibleDate={timelineVisibleDate}
          setVisibleDayStart={setVisibleDayStart}
        />
      </Grid>
    </Grid>
  );
};

export default ScheduleTimeline;
