import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  gridList: {
    width: '100%',
    padding: '0 20px',
  },
  logoImg: {
    margin: '1rem auto',
    display: 'block',
    maxWidth: '200px',
    maxHeight: '200px',
    width: 'auto',
    height: 'auto',
  },
  marginBottom: {
    margin: '0 0 1rem 0',
  }
});

const GeneralInfoStyles = makeStyles(Styles);

export default GeneralInfoStyles;
