import { useState, useCallback } from 'react';
import { Typography, Button, Grid, TextField, MenuItem } from '@material-ui/core';

import Permission from '../../../../../blocks/Permission';
import PermissionField from '../../../../../blocks/PermissionField';
import { generateSchedulePdf } from '../../../../../../utils/pdfUtils';
import Modal from '../../../../../blocks/Modal';
import { ScheduleTimelineHeaderStyles } from './styles';
import EmailPdfModal from './EmailPdfModal';

const CalendarScheduleTimelineHeader = ({
  copySchedulePermission,
  prettySchedulesData,
  setIsWeekCopy,
  setIsCopyModalVisible,
  exportSchedulePDFPermission,
  jobList,
  selectedLocationState,
  schedules,
  employeeJobHourTotals,
  selectedWeek,
  timelineVisibleDate,
  emailSchedulePDFPermission,
  employees,
}) => {
  const classes = ScheduleTimelineHeaderStyles();

  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isPdfModalVisible, setIsPdfModalVisible] = useState(false);
  const [pdfSortOrder, setPdfSortOrder] = useState(1);

  const generatePdf = useCallback(
    async (generateForEntireWeek, openInNewTab = true) =>
      await generateSchedulePdf(
        selectedLocationState,
        selectedWeek.valueOf(),
        schedules,
        employeeJobHourTotals,
        pdfSortOrder,
        generateForEntireWeek ? null : timelineVisibleDate.clone(),
        openInNewTab,
      ),
    [
      generateSchedulePdf,
      selectedLocationState,
      selectedWeek,
      schedules,
      employeeJobHourTotals,
      pdfSortOrder,
      timelineVisibleDate,
    ],
  );

  return (
    <Grid container className={classes.scheduleTimelineActions}>
      <Grid item xs={2} direction="row" justify="flex-start">
        <Permission access={copySchedulePermission?.access}>
          <div>
            <PermissionField createHelperText>
              <Button
                variant="outlined"
                disabled={!prettySchedulesData?.length > 0}
                onClick={() => {
                  setIsWeekCopy(false);
                  setIsCopyModalVisible(true);
                }}
              >
                Copy Schedule - Day
              </Button>
            </PermissionField>
          </div>
        </Permission>
      </Grid>
      <Grid item xs={10} direction="row" justify="flex-end">
        <Permission access={emailSchedulePDFPermission?.access}>
          <div>
            <PermissionField createHelperText>
              <Button
                variant="outlined"
                disabled={
                  !employees?.viewer?.loginConnection?.edges?.length > 0 ||
                  !jobList?.viewer?.jobConnection?.edges?.length > 0
                }
                onClick={() => setIsEmailModalVisible(true)}
              >
                Email PDF
              </Button>
            </PermissionField>
          </div>
        </Permission>
        <Permission access={exportSchedulePDFPermission?.access}>
          <div>
            <PermissionField createHelperText>
              <Button
                variant="outlined"
                disabled={
                  !prettySchedulesData?.length > 0 ||
                  !jobList?.viewer?.jobConnection?.edges?.length > 0
                }
                onClick={() => setIsPdfModalVisible(true)}
              >
                Generate PDF
              </Button>
            </PermissionField>
          </div>
        </Permission>
        <Permission access={copySchedulePermission?.access}>
          <div>
            <PermissionField createHelperText>
              <Button
                variant="outlined"
                disabled={!prettySchedulesData?.length > 0}
                onClick={() => {
                  setIsWeekCopy(true);
                  setIsCopyModalVisible(true);
                }}
              >
                Copy Schedule - Week
              </Button>
            </PermissionField>
          </div>
        </Permission>
      </Grid>

      <Modal
        open={isPdfModalVisible}
        handleClose={() => setIsPdfModalVisible(false)}
        title="Generate Schedule PDF"
        maxWidth="md"
      >
        <Grid container>
          <Grid item xs={12} className={classes.sortSelect}>
            <TextField
              onChange={(e, v) => setPdfSortOrder(e.target.value)}
              label="PDF Sort Order"
              value={pdfSortOrder}
              select
            >
              <MenuItem value={1}>By Job</MenuItem>
              <MenuItem value={2}>By First Name</MenuItem>
            </TextField>
          </Grid>
          <Typography variant="subtitle1" gutterBottom={true} className={classes.modalSub}>
            Would you like to generate the schedule PDF for the current day in the timeline or the
            whole week?
          </Typography>
          <Grid item xs={12} justify="space-between" className={classes.modalButtons}>
            <Button onClick={() => generatePdf(false)}>Individual day</Button>
            <Button onClick={() => generatePdf(true)}>Whole week</Button>
          </Grid>
        </Grid>
      </Modal>

      <Modal
        open={isEmailModalVisible}
        handleClose={() => setIsEmailModalVisible(false)}
        title="Email Schedule PDF"
        maxWidth="md"
      >
        <EmailPdfModal
          employees={employees}
          prettySchedulesData={prettySchedulesData}
          timelineVisibleDate={timelineVisibleDate}
          locationId={selectedLocationState.locationId}
          generatePdf={generatePdf}
          setPdfSortOrder={setPdfSortOrder}
          pdfSortOrder={pdfSortOrder}
        />
      </Modal>
    </Grid>
  );
};

export default CalendarScheduleTimelineHeader;
