import { gql } from '@apollo/client';

const FETCH_ITEM_INGREDIENTS = gql`
  query itemIngredients($id: ID!) {
    viewer {
      item(id: $id) {
        id
        itemIngredientConnection {
          edges {
            node {
              id
              quantity
              measure {
                id
                measureId
              }
              recipe {
                inventoryitem {
                  inventoryitemMeasureConnection {
                    edges {
                      node {
                        id
                        measure {
                          label
                          abbreviation
                          id
                          measureId
                        }
                      }
                    }
                  }
                  inventoryitemId
                  label
                  cost
                  measure {
                    label
                    abbreviation
                    id
                    measureId
                  }
                }
              }
              ingredient {
                id
                ingredientId
                inventoryitem {
                  inventoryitemMeasureConnection {
                    edges {
                      node {
                        id
                        measure {
                          label
                          abbreviation
                          id
                          measureId
                        }
                      }
                    }
                  }
                  inventoryitemId
                  label
                  cost
                  measure {
                    label
                    abbreviation
                    id
                    measureId
                  }
                }
                measure {
                  label
                  abbreviation
                  id
                  measureId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_ITEM_INGREDIENTS;
