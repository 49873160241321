import MenuSettingsComponent from '../../Location/MenuSettings';
import GeneralInfo from '../GeneralInfo';

const statuses = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'On',
    value: true,
  },
  {
    label: 'Off',
    value: false,
  },
];

const MenuSettings = ({
  locationId,
  data,
  locationIds,
  bulkEditPermission,
  bulkEditGeneralInfoPermission,
}) => {
  return (
    <>
      {/* <GeneralInfo data={data} locationIds={locationIds} bulkEditGeneralInfoPermission={bulkEditGeneralInfoPermission}/> */}
      <MenuSettingsComponent
        locationId={atob(locationId)?.split(':')?.[0]}
        statuses={statuses}
        locationIds={locationIds}
        isBulkEdit
        bulkEditPermission={bulkEditPermission}
      />
    </>
  );
};

export default MenuSettings;
