import { gql } from '@apollo/client';
import { paymentFragment } from '../../fragments/index';

const CREATE_CATERING_PAYMENT = gql`
  mutation createCateringPayment(
    $quoteId: ID!
    $gatewayId: ID!
    $tenderId: ID!
    $referenceNumber: String
    $amount: Int!
    $source: String!
    $chargeCustomer: Boolean
    $confirmPaymentIntentId: String
  ) {
    createCateringPaymentV2(
      input: {
        quoteId: $quoteId
        gatewayId: $gatewayId
        referenceNumber: $referenceNumber
        tenderId: $tenderId
        amount: $amount
        source: $source
        chargeCustomer: $chargeCustomer
        confirmPaymentIntentId: $confirmPaymentIntentId
      }
    ) {
      paymentEdge {
        node {
          ...PaymentData
        }
      }
      requiresAction {
        paymentIntentId
        paymentIntentClientSecret
      }
    }
  }
  ${paymentFragment.payment}
`;

export default CREATE_CATERING_PAYMENT;
