import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  heading: {
    marginBottom: theme.spacing(4),
  },
  titleRoot: {
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(0),
  },
  textContent: {
    color: theme.typography.subtitle1.color,
    marginBottom: theme.spacing(4),
  },
  nameField: {
    marginRight: theme.spacing(4),
    marginBottom: "25px",
  },
  marginRight: {
    marginRight: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: "25px",
  },
  basicInfoRadioWrap: { display: "flex", flexDirection: "row" },
  basicInfoRadio: { justifyContent: 'flex-start', marginTop: '11px', padding: '0 5px' }
});

const GeneralSettingsStyles = makeStyles(Styles);

export default GeneralSettingsStyles;
