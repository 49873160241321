import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Checkbox,
  TableSortLabel,
  TableCell,
  TableRow,
  TableHead,
  Hidden,
} from '@material-ui/core';

import { connectionTableHeaderStyles } from './style';

import { tableSortTypes } from '../../../../constants';
import Permission from '../../Permission';

const ConnectionTableHead = props => {
  const {
    columns,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    selectableRows,
    tableName,
    selectAllCheckbox,
    handleDeleteRow,
    handleEditRow,
    permissions,
    collapsableRows,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const classes = connectionTableHeaderStyles();

  return (
    <TableHead>
      <TableRow>
        {collapsableRows && <TableCell />}
        {selectableRows && (
          <TableCell padding="checkbox" className={classes.headerCell}>
            {selectAllCheckbox && (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': `select all ${tableName}` }}
              />
            )}
          </TableCell>
        )}
        {columns.map((column, index) => {
          const sortField = column.customSortField || column.field;

          const access = get(permissions, column.customPermissionField || column.field, 7);

          return (
            <Hidden only={column.hidden}>
              <Permission access={access}>
                {!column.disableSort ? (
                  <TableCell
                    className={classes.headerCell}
                    key={index}
                    sortDirection={
                      orderBy === sortField && order !== tableSortTypes.NONE ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === sortField && order !== tableSortTypes.NONE}
                      direction={
                        orderBy === sortField && order !== tableSortTypes.NONE
                          ? order
                          : tableSortTypes.ASC
                      }
                      onClick={createSortHandler(sortField)}
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell className={classes.headerCell} key={index}>
                    {column.title}
                  </TableCell>
                )}
              </Permission>
            </Hidden>
          );
        })}
        {(handleEditRow || handleDeleteRow) && <TableCell>&nbsp;</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default ConnectionTableHead;

ConnectionTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  selectableRows: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
};
