import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { FETCH_HIERARCHY } from "../../../../../apollo/queries";
import LOCATIONS from "../../../../../apollo/queries/locations";
import { Button, Grid, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { HierarchyStyles } from "../View/styles";
import {
  CREATE_LOCATION_HIERARCHY,
  REMOVE_LOCATION_HIERARCHY,
} from "../../../../../apollo/mutations";
import { uglifyId } from "../../../../../utils";
import { compact } from "lodash";
import { useSnackbar } from "../../../../../hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const LocationDataGrid = ({
  area,
  value,
  locations,
  setLocations,
}) => {
  const { setSnackbar } = useSnackbar();

  // const [locations, setLocations] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [preSelected, setPreselected] = useState([]);
  const [newSelectedLocations, setNewSelectedLocations] = useState([]);
  const [bulkUpdateState, setBulkUpdateState] = useState({ action: "none" });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [createLocationHierarchy] = useMutation(CREATE_LOCATION_HIERARCHY, {
    onCompleted: () => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: "success",
        text: "Locations updated!",
      });
    },
    onError: () => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: "error",
        text: "Oops, something went wrong.",
      });
    },
  });
  const [removeLocationHierarchy] = useMutation(REMOVE_LOCATION_HIERARCHY);

  const classes = HierarchyStyles();
  const searchClasses = useStyles();

  const { data, error, loading } = useQuery(LOCATIONS, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.log(error, "error");
    },
  });

  useEffect(() => {
    if (data) {
      setLocations(
        data?.viewer?.locationConnection?.edges?.map((location) => {
          return {
            label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
            id: location?.node?.locationId,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    const addEvent = (e) => {
      if (e.currentTarget.checked) {
        setBulkUpdateState({ action: "add" });
      } else {
        setBulkUpdateState({ action: "remove" });
      }
    };
    if (
      document.querySelector(
        ".MuiDataGrid-colCellCheckbox input[type='checkbox']"
      )
    ) {
      document
        .querySelector(".MuiDataGrid-colCellCheckbox input[type='checkbox']")
        .addEventListener("change", addEvent);
      return function() {
        if (
          document.querySelector(
            ".MuiDataGrid-colCellCheckbox input[type='checkbox']"
          )
        ) {
          document
            .querySelector(".MuiDataGrid-colCellCheckbox input[type='checkbox']")
            .removeEventListener("change", addEvent);
        }
      };
    }
  });

  useEffect(() => {
    (async () => {
      setNewSelectedLocations([]);
      setPreselected(area?.locations?.map((location) => location.locationId));
    })();
  }, [area]);

  useEffect(() => {
    if (search.length !== 0) {
      setSearchResult(
        locations.filter((location) =>
          location.label.toLowerCase().includes(search)
        )
      );
    } else {
      setSearchResult([]);
      setPreselected(area?.locations?.map((location) => location.locationId));
    }
  }, [search]);

  if (error) console.log(error);

  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "label", headerName: "Location", width: 180 },
  ].map((column) => ({
    ...column,
    disableClickEventBubbling: true,
  }));

  if (loading) {
    return "Loading...";
  }

  const handleRowSelect = async (e) => {
    let newLocationIds = newSelectedLocations;
    newLocationIds = newLocationIds.filter(
      (loc) => +atob(loc.locationId).split(":")[1] !== e.data.id
    );
    if (!e.isSelected) {
      setNewSelectedLocations([
        ...newLocationIds,
        { action: "remove", locationId: uglifyId("Location", e.data.id) },
      ]);
    } else {
      setNewSelectedLocations([
        ...newLocationIds,
        { action: "add", locationId: uglifyId("Location", e.data.id) },
      ]);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h2">
          Locations
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputBase
          className={searchClasses.input}
          placeholder="Search Locations"
          inputProps={{ "aria-label": "search locations" }}
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
        />
        <IconButton className={searchClasses.iconButton} aria-label="search">
          {search.length !== 0 ? (
            <CloseIcon onClick={() => setSearch("")} />
          ) : (
            <SearchIcon />
          )}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          autoHeight={true}
          columns={columns}
          loading={!(locations && locations.length)}
          rows={searchResult.length !== 0 ? searchResult : locations}
          pageSize={8}
          checkboxSelection
          selectionModel={preSelected}
          onRowSelected={handleRowSelect}
        />
      </Grid>
      <Grid item xs={12} className={classes.areasActions}>
        <Button
          disabled={buttonDisabled || !area.id}
          onClick={async () => {
            setButtonDisabled(true);
            if (area?.id) {
              await createLocationHierarchy({
                variables: {
                  input: {
                    hierarchyId: area?.id,
                    locations: newSelectedLocations,
                    bulkLocationUpdate: bulkUpdateState,
                  },
                },
                refetchQueries: [
                  {
                    query: FETCH_HIERARCHY,
                  },
                  {
                    query: LOCATIONS,
                  },
                ],
                onCompleted: () => {
                  setButtonDisabled(false);
                  setNewSelectedLocations([]);
                },
              });
            }
          }}
        >
          Assign to Area
        </Button>
      </Grid>
    </Grid>
  );
};

export default LocationDataGrid;
