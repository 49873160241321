import React from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import { useDrag, useDrop } from 'react-dnd';
import { menuSettings as constants } from '../../../../../constants';

const DraggableTreeItem = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    item: { type: props.type, id: props.nodeId, parentId: props.parentId, label: props.itemLabel },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  // eslint-disable-next-line no-unused-vars
  const handleDrop = (item, didDropToChild) => {
    if (item.type === constants.TREE_NODE_TYPES.ITEM) {
      props.setTreeAction({
        item: {
          label: item.label,
          type: item.type,
        },
        destination: {
          label: props.itemLabel,
          type: props.type,
        },
        actions: [
          {
            label: 'Move',
            mutation: props.mutations.addItemToTree,
            variables: {
              input: {
                operation: 'move',
                itemId: item.id,
                targetSubcategoryId: props.nodeId,
                startingSubcategoryId: item.parentId,
              },
            },
          },
          {
            label: 'Copy',
            // eslint-disable-next-line react/prop-types
            mutation: props.mutations.addItemToTree,
            variables: {
              input: {
                operation: 'copy',
                itemId: item.id,
                startingSubcategoryId: item.parentId,
                targetSubcategoryId: props.nodeId,
              },
            },
          },
        ],
      });
    } else if (item.type === constants.TREE_NODE_TYPES.SUBCATEGORY) {
      props.setTreeAction({
        item: {
          label: item.label,
          type: item.type,
        },
        destination: {
          label: props.itemLabel,
          type: props.type,
        },
        actions: [
          {
            label: 'Move',
            mutation: props.mutations.addSubcategoryToTree,
            variables: {
              input: {
                subcategoryId: item.id,
                targetCategoryId: props.nodeId,
                targetMenuId: props.menuId, 
                startingCategoryId: item.parentId,
              },
            },
          },
        ],
      });
    } else if (item.type === constants.TREE_NODE_TYPES.CATEGORY) {
      props.setTreeAction({
        item: {
          label: item.label,
          type: item.type,
        },
        destination: {
          label: props.itemLabel,
          type: props.type,
        },
        actions: [
          {
            label: 'Copy',
            mutation: props.mutations.addCategoryToTree,
            variables: {
              input: {
                operation: 'copy',
                categoryId: item.id,
                targetMenuId: props.nodeId,
                startingMenuId: item.parentId
              },
            },
          },
          {
            label: 'Move',
            mutation: props.mutations.addCategoryToTree,
            variables: {
              input: {
                operation: 'move',
                categoryId: item.id,
                targetMenuId: props.nodeId,
                startingMenuId: item.parentId,
              },
            },
          },
        ],
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [{ isOver, draggingColor, canDrop }, drop] = useDrop({
    accept: props.childtype || 'nothing',
    drop(item, monitor) {
      const didDropToChild = monitor.didDrop();
      handleDrop(item, didDropToChild);
      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop}>
      <TreeItem {...props} ref={drag} />
    </div>
  );
};

export default DraggableTreeItem;
