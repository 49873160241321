import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";

function Archive({ setFilter, refetch: { refetch, variables } }) {
  return (
    <div>
      <FormControl>
        <InputLabel htmlFor="name-native-error">Archived</InputLabel>
        <Select
          style={{ width: "8rem" }}
          inputProps={{
            name: "Archived",
          }}
          onChange={(e) => {
            let filter;
            if (e.target.value !== "none") {
              filter = {
                ...variables,
                filter: {
                  ...variables.filter,
                  archived: { eq: e.target.value },
                },
              };
            } else {
              filter = {
                ...variables,
                filter: {
                  ...variables.filter,
                  archived: undefined,
                },
              };
            }
            refetch(filter);
          }}
          variant="outlined"
          defaultValue="none"
          label="Archived"
          placeholder="Archived"
        >
          <MenuItem value="none">
            <em>None</em>
          </MenuItem>
          <MenuItem value={true}>On</MenuItem>
          <MenuItem value={false}>Off</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default Archive;
