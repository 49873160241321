import React from 'react';
import moment from 'moment';
import { Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
export default function Reward({ node, classes }) {
  return (
    <Card className={classes.cardStyles}>
      <Grid container direction="row">
        <Grid item xs="5">
          <CardMedia
            component="img"
            alt="nice image"
            className={classes.imgStyle}
            image={node?.coupon.imageURL}
          />
        </Grid>
        <Grid item xs="7">
          <CardContent>
            <Typography component="subtitle2" variant="textSecondary">
              {node?.brand.label}
            </Typography>
            <Typography component="h5" variant="h5">
              {node?.coupon.label}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {node?.coupon.description}
            </Typography>
            <div>
              <div>
                <p className={classes.pStyles1}>Bought from loyalty store</p>
                <p className={classes.pStyles2}>
                  {moment
                    .utc(node?.created)
                    .local()
                    .format('MM/DD/YYYY hh:mm A')}
                </p>
              </div>
              <div>
                <p className={classes.pStyles1}>Valid until</p>
                <p className={classes.pStyles2}>
                  {moment
                    .utc(node?.validUntil)
                    .local()
                    .format('MM/DD/YYYY hh:mm A')}
                </p>
              </div>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
