import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

function CustomActionTop({ refetch: { refetch, variables }, measures = [] }) {
  const handleChange = e => {
    if (e.target.value === 'NONE') {
      refetch({
        first: variables.first,
        filter: {},
      });
    } else {
      refetch({
        ...variables,
        filter: {
          measure: {
            label: { eq: e.target.value },
          },
        },
      });
    }
  };

  return (
    <div>
      <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">Unit Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          defaultValue="NONE"
          onChange={handleChange}
          label="Age"
          style={{ width: '14rem' }}
        >
          <MenuItem value="NONE">
            <em>None</em>
          </MenuItem>
          {measures.map(mes => {
            return (
              <MenuItem key={mes.label} value={mes.label}>
                {mes.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomActionTop;
