import { TableHead, TableRow, TableCell, Typography } from '@material-ui/core';
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Permission from '../Permission';

const DataTableHead = (props) => {
  const { columns, showActions, permissions } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          const access = get(permissions, column.customPermissionField || column.field, 7);

          return (
            <Permission access={access} key={index} >
              <TableCell className={props.classes.headerCell}>
                <Typography variant="subtitle2">{column.title}</Typography>
              </TableCell>
            </Permission>
          );
        })}
        {showActions && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHead;

DataTableHead.propTypes = {
  columns: PropTypes.array,
};
