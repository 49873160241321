import { gql } from '@apollo/client';

const SET_QUOTE_TIP = gql`
  mutation SetQuoteTip($input: SetQuoteTipMutationInput!) {
    setQuoteTip(input: $input) {
      viewer {
        id
      }
      quote {
        id
        tip
        totalTax
        totalPrice
        dueAmount
      }
    }
  }
`;

export default SET_QUOTE_TIP;
