import { gql } from '@apollo/client';
import personFragment from './personFragment';

export const quotecateringFragment = {
  quotecatering: gql`
    fragment QuotecateringData on Quotecatering {
      id
      quotecateringId
      created
      companyName
      inquiryDate
      numberOfCustomers
      eventDate
      contactName
      contactPhone
      prepInfo {
        meat
        side
        relish
        sauce
      }
      additionalInfo
     quoteNoteConnection {
          edges {
            node {
              id
              note
              created
              creator {
                id
                firstName
                lastName
              }
            }
          }
        }
      status {
        id
        statusId
        label
      }
      eventType {
        id
        label
      }
      cateringExpert {
        id
        person {
          id
          ...PersonData
        }
      }
    }
    ${personFragment.person}
  `,
};
