import { gql } from '@apollo/client';

const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $firstName: String!
    $lastName: String!
    $address: String!
    $additionalInfo: String
    $city: String!
    $stateLabel: String!
    $countryId: Int
    $zip: String!
    $phone: String!
    $email: String!
    $locationIds: [Int]
    $dateOfBirth: String
    $schedulePreference: String
  ) {
    createEmployee(
      input: {
        firstName: $firstName
        lastName: $lastName
        address: $address
        additionalInfo: $additionalInfo
        city: $city
        stateLabel: $stateLabel
        countryId: $countryId
        zip: $zip
        phone: $phone
        email: $email
        dateOfBirth: $dateOfBirth
        locationIds: $locationIds
        schedulePreference: $schedulePreference
      }
    ) {
      viewer {
        id
      }
      person {
        id
        personId
      }
    }
  }
`;

export default CREATE_EMPLOYEE;
