import { Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FETCH_STORAGE_INVENTORY_ITEMS } from '../../../../../../../../apollo/queries';
import { SelectFactory } from '../../../../../../../blocks';

const InventoryItem = ({
  id,
  index,
  item: inventoryItem,
  errors,
  getValues,
  control,
  duplicateInventoryItemIds,
}) => {
  const isDuplicate = useMemo(
    () =>
      getValues()?.inventoryItems
        ? duplicateInventoryItemIds.includes(
            getValues()?.inventoryItems[index]?.inventoryItemId?.id,
          )
        : false,
    [duplicateInventoryItemIds],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} justify="center" container alignContent="center">
        <Typography variant="body1">{index + 1}.</Typography>
      </Grid>
      <Grid item xs={11}>
        <Controller
          control={control}
          name={`inventoryItems.${index}.inventoryItemId`}
          key={`${id}.inventoryItemId`}
          defaultValue={inventoryItem?.inventoryItemId || ''}
          rules={{ required: true }}
          render={props => (
            <SelectFactory
              customProps={props}
              error={
                isDuplicate ||
                (errors.inventoryItems && !!errors.inventoryItems[index]?.inventoryItemId)
              }
              helperText={
                (isDuplicate && 'Inventory Item is duplicated') ||
                (errors.inventoryItems &&
                  errors.inventoryItems[index]?.inventoryItemId &&
                  'Inventory Item is required')
              }
              label="Inventory Item"
              fullWidth
              filter={{
                recipetypeId: {
                  eq: 1,
                },
              }}
              placeholder="Inventory Item"
              query={FETCH_STORAGE_INVENTORY_ITEMS}
              renderCheckBox={false}
              defaultValue={inventoryItem?.inventoryItemId || ''}
              disableCloseOnSelect={false}
              multiple={false}
              structureData={data => {
                return data?.viewer?.inventoryitemConnection?.edges.map(edge => {
                  return {
                    label: edge.node.label,
                    id: edge.node.inventoryitemId,
                  };
                });
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default InventoryItem;
