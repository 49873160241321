import { gql } from '@apollo/client';

const FETCH_EMPLOYEES_ON_LOCATION = gql`
  query Employees(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LoginSort]
    $filter: [LoginFilter]
    $personJobFilter: [PersonJobFilter]
    $locationId: Int!
  ) {
    viewer {
      id
      loginConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        fieldGroup: 62
      ) {
        permissions
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        availableFilters
        permissions
        edges {
          node {
            email
            inactive
            employeeLocationInactive(locationId: $locationId)
            person {
              personJobConnection(filter: $personJobFilter) {
                edges {
                  node {
                    id
                    salaryAmount
                    job {
                      id
                      jobId
                      label
                    }
                  }
                }
              }
              id
              personId
              firstName
              lastName
              schedulePreference
            }
          }
        }
      }
    }
  }
`;

export default FETCH_EMPLOYEES_ON_LOCATION;
