import { gql } from '@apollo/client';

export const GENERATE_COUPON_CODES = gql`
mutation generateCouponCodes(
  $input: GenerateCouponCodesMutationInput!
) {
 generateCouponCodes(input: $input){
   clientMutationId
 }
}
`