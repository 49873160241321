import React, { useMemo, useState } from 'react';
import { Container, Divider } from '@material-ui/core';
import ProductionForecasterStyles from './styles';
import StoreSpecificUsage from '../StoreSpecificUsage';
import OrderingForecasterCategory from '../OrderingForecasterCategory';
import DailyForecasterCategory from '../DailyForecasterCategory';
import { SideBarSectionsLayout } from '../../../../../blocks';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import ClosingInventory from '../ClosingInventory';

const getComponentViewData = () => [
  {
    sections: [
      { name: 'Store Specific Usage - %', id: 'storeSpecificUsage' },
      { name: 'Ordering Forecaster Category', id: 'orderingForecasterCategory' },
      { name: 'Daily Forecaster Category', id: 'dailyForecasterCategory' },
      { name: 'Closing Inventory', id: 'closingInventory' },
    ],
  },
];

const ProductionForecasterForm = ({
  data,
  variables,
  refetch,
  saveSSPM,
  saveDailyForecaster,
  saveClosingInventory,
  getSideUOM,
  saveSSPMLoading,
  saveDailyForecasterLoading,
  saveClosingInventoryLoading,
}) => {
  const componentViewData = useMemo(() => getComponentViewData(), []);
  const { setSnackbar } = useSnackbar();

  return (
    <>
      {componentViewData.map((tabPanel, index) => (
        <SideBarSectionsLayout key={index} sectionList={tabPanel.sections || []}>
          <Container>
            <StoreSpecificUsage
              data={data}
              saveSSPM={saveSSPM}
              saveSSPMLoading={saveSSPMLoading}
              setSnackbar={setSnackbar}
            />
            <Divider />
            <OrderingForecasterCategory
              data={data}
              refetch={refetch}
              variables={variables}
              setSnackbar={setSnackbar}
            />
            <Divider />
            <DailyForecasterCategory
              data={data}
              refetch={refetch}
              variables={variables}
              saveDailyForecaster={saveDailyForecaster}
              saveDailyForecasterLoading={saveDailyForecasterLoading}
              getSideUOM={getSideUOM}
              setSnackbar={setSnackbar}
            />
            <Divider />
            <ClosingInventory
              data={data}
              saveClosingInventory={saveClosingInventory}
              getSideUOM={getSideUOM}
              saveClosingInventoryLoading={saveClosingInventoryLoading}
              setSnackbar={setSnackbar}
            />
          </Container>
        </SideBarSectionsLayout>
      ))}
    </>
  );
};

export default ProductionForecasterForm;
