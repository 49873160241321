import { gql } from '@apollo/client';

const ADD_SUBCATEGORY_TO_TREE = gql`
  mutation AddSubcategoryToTree($input: AddSubcategoryToTreeMutationInput!) {
    addSubcategoryToTree(input: $input) {
      category {
        id
        subcategoryConnection {
          edges {
            node {
              id
              label
              subcategoryItemConnection {
                edges {
                  node {
                    id
                    item {
                      id
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ADD_SUBCATEGORY_TO_TREE;
