import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ToggleButtonStyles from './styles';

/** options prop example
 *   options = [
 *     { value: 'fraction', label: 'Fraction - %' },
 *     { value: 'amount', label: 'Amount - $' },
 *   ]
 */

const ToggleButton = ({ options = [], handleToggleClick, value, disabled, onClick }) => {
  const classes = ToggleButtonStyles();
  const [active, setActive] = useState(value || options[0].value || '');

  const handleToggle = (event, value) => {
    event.preventDefault();

    if (onClick) {
      onClick(event);
    }

    if (!disabled) {
      handleToggleClick(value);
      setActive(value);
    }
  };

  useEffect(() => {
    setActive(value);
  },[value])

  return (
    <div className={classes.rootContainer}>
      <div className={classes.inlineContainer}>
        {options.map((option, index) => {
          return (
            <React.Fragment key={option.value}>
              <span
                onClick={(event) => handleToggle(event, option.value)}
                className={
                  option.value === active ? `${classes.rootLabel} active` : classes.rootLabel
                }
              >
                {option.label}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

ToggleButton.propTypes = {
  handleToggleClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
};

export default ToggleButton;
