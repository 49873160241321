import { gql } from '@apollo/client';

const LOYALTY_STORE_ITEMS = gql`
  query LOYALTY_STORE_ITEMS($filter: [LoyaltyStoreItemCouponFilter]) {
    viewer {
      id
      loyaltystoreitemCouponConnection(sort: { created: ASC }, filter: $filter) {
        edges {
          node {
            id
            couponId
            loyaltystoreitem {
              brandId
              label
            }
          }
        }
      }
    }
  }
`;

export default LOYALTY_STORE_ITEMS;
