import React from 'react';
import { useQuery } from '@apollo/client';
import USER_COUPONS from '../../../../../../../apollo/queries/loyalty/userCoupons';
import LoyaltyRewardsView from './View';
import moment from 'moment';
import { useParams } from 'react-router';

export default function LoyaltyRewards() {
  const { customerId } = useParams();
  const { loading: queryLoading, error: errorQuery, data: availableCoupons } = useQuery(
    USER_COUPONS,
    {
      variables: {
        availableCouponsFilter: [
          {
            usedOn: { null: true },
            personId: { eq: +customerId },
            validUntil: { gte: moment.utc().format('YYYY-MM-DD HH:mm:ss') },
          },
        ],
      },
    },
  );

  return (
    <LoyaltyRewardsView
      errorQuery={errorQuery}
      queryLoading={queryLoading}
      data={availableCoupons}
    />
  );
}
