import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

const PercentageInput = ({ name, control, label, rules, helperText, error, disabled, fullWidth = false, defaultValue = "", ...rest }) => {
  return (
    <Controller
      rules={rules}
      render={(props) =>
        <NumberFormat
          {...props}
          {...rest}
          customInput={TextField}
          disabled={disabled}
          thousandSeparator
          label={label}
          variant={"outlined"}
          error={error}
          helperText={helperText}
          fullWidth={fullWidth}
          isAllowed={(value) => {
            if (value.floatValue === 0 && value.formattedValue.length > 2) {
              return false;
            }
            return value.floatValue <= 100 || !value.floatValue;
          }
          }
          suffix="%"
        />
      }
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  );
};

export default PercentageInput;
