import React, { useState, useContext, useMemo, useCallback } from 'react';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import {
  Button,
  Select,
  MenuItem,
  ListItemText,
  Grid,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Modal, Permission, PermissionField } from '../../../../blocks';
import CreateEmployeeForm from '../CreateEmployeeForm';
import { USERS_BY_USERGROUP } from '../../../../../apollo/queries';
import { LOCATIONS } from '../../../../../apollo/queries/filters';
import UsergroupStyles from '../../../SystemSettings/AccessManagement/Usergroup/styles';
import { CoreContext } from '../../../../../CoreContext';
import SelectFactory from '../../../../blocks/SelectFactory';
import { MUTATION_NAME } from '../../../../../constants';
import { useFormSubmitPermissions } from '../../../../../hooks';
import { compact } from 'lodash';

const EmployeeList = () => {
  const context = useContext(CoreContext);
  const mutationPermissions = context?.coreUtils?.getAppActions()[60]?.mutations;

  const createPermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'createEmployee'),
    [mutationPermissions],
  );
  const updatePermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'updateEmployee'),
    [mutationPermissions],
  );
  const deletePermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'removeEmployee'),
    [mutationPermissions],
  );
  const updateLocationPermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'updateLocationEmployee'),
    [mutationPermissions],
  );
  const activationEmployeePermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'activationEmployee'),
    [mutationPermissions],
  );

  const [tableState, setTableState] = useState();
  const [personId, setPersonId] = useState(null);
  const [active, setActive] = useState('none');
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [permissions, setPermissions] = useState();
  const [locationPermissions, setLocationPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.CreateEmployeeMutationInput,
      MUTATION_NAME.UpdateEmployeeMutationInput,
    ],
    permissions: {
      ...locationPermissions,
      ...permissions,
    },
  });

  const classes = UsergroupStyles();

  const handleCloseCreateUserModal = () => {
    setShowCreateUserModal(false);
    setPersonId(null);
  };

  const handleCreateUser = useCallback(
    (query, row) => {
      if (row) {
        setPersonId(row.person.personId);
      }

      setTableState(query);
      setShowCreateUserModal(true);
      setOpenWarningMutationName(
        row?.person?.personId
          ? MUTATION_NAME.UpdateEmployeeMutationInput
          : MUTATION_NAME.CreateEmployeeMutationInput,
      );
    },
    [setOpenWarningMutationName],
  );

  const handleEmployeeStatusChange = (isActive, refetch, variables) => {
    setActive(isActive);
    if (isActive === 'none') {
      delete variables.filter.employeeInactive;
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
        },
      });
    } else if (variables && variables.filter && refetch) {
      refetch({
        ...variables,
        filter: {
          ...variables.filter,
          employeeInactive: { eq: !isActive },
        },
      });
    }
  };

  return (
    <>
      <ConnectionTable
        hideTotalCount={true}
        query={USERS_BY_USERGROUP}
        paginateQuery="loginConnection"
        initialQueryVariables={{
          fieldGroup: 60,
          first: 10,
          filter: {
            showEmployeesOnYourLocations: { eq: true },
          },
        }}
        title="Employees"
        customActionsTop={(data, query) => {
          if (!permissions) {
            setPermissions({
              firstName: data?.viewer?.loginConnection?.permissions?.person?.firstName,
              lastName: data?.viewer?.loginConnection?.permissions?.person?.lastName,
              address: data?.viewer?.personConnection?.permissions?.address?.address,
              city: data?.viewer?.personConnection?.permissions?.address?.city,
              stateLabel: data?.viewer?.personConnection?.permissions?.address?.state?.label,
              zip: data?.viewer?.personConnection?.permissions?.address?.zip,
              phone: data?.viewer?.loginConnection?.permissions?.person?.phone?.__typename,
              loginId: data?.viewer?.loginConnection?.permissions?.id,
              personId: data?.viewer?.personConnection?.permissions?.personId,
            });
          }

          return (
            <div>
              <Permission
                access={Math.min(
                  createPermission?.access,
                  accessToMutations?.[MUTATION_NAME.CreateEmployeeMutationInput],
                )}
              >
                <div>
                  <PermissionField createHelperText>
                    <Button onClick={() => handleCreateUser(query)}>Create new employee</Button>
                  </PermissionField>
                </div>
              </Permission>
            </div>
          );
        }}
        customActionsCenter={(data, { refetch, variables }, selected) => {
          return (
            <Grid container justify="flex-end" spacing={4} alignItems="center">
              <Grid item xs={4}>
                <SelectFactory
                  label={'Location'}
                  placeholder={'Select Location'}
                  query={LOCATIONS}
                  fullWidth
                  structureData={data => {
                    return data?.viewer?.locationConnection?.edges.map((location, index) => {
                      return {
                        label: compact([location.node.label, location.node.storeNumber]).join(
                          ' - #',
                        ),
                        id: location.node.id,
                        index,
                        locationId: location.node.locationId,
                      };
                    });
                  }}
                  onSelect={values => {
                    const locationIds = values.map(location => location.locationId);
                    if (values.length === 0) {
                      const filter = {
                        ...variables.filter,
                        showEmployeesOnYourLocations: {
                          eq: true,
                        },
                      };
                      // if no locations are selected, show all user belongs to
                      delete filter['showEmployesOnLocation'];
                      refetch({
                        ...variables,
                        filter,
                      });
                    } else {
                      const filter = {
                        ...variables.filter,
                        showEmployesOnLocation: locationIds,
                      };
                      // if requesting specific locations, remove global location scoping filter
                      delete filter['showEmployeesOnYourLocations'];
                      refetch({
                        ...variables,
                        filter,
                      });
                    }
                  }}
                />
              </Grid>
              <Permission access={!!data?.viewer?.loginConnection?.permissions?.inactive ? 7 : 0}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="employee-status" className={classes.inputLabelSelect}>
                      Employee status
                    </InputLabel>
                    <Select
                      variant="outlined"
                      id="employee-status"
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ maxHeight: '3rem' }}
                      defaultValue={active}
                      fullWidth
                      onChange={e => handleEmployeeStatusChange(e.target.value, refetch, variables)}
                    >
                      <MenuItem key="0" value="none">
                        <ListItemText style={{ margin: '0' }} primary="None" />
                      </MenuItem>
                      <MenuItem selected={active} key="1" value>
                        <ListItemText style={{ margin: '0' }} primary="Active" />
                      </MenuItem>
                      <MenuItem selected={!active} key="2" value={false}>
                        <ListItemText style={{ margin: '0' }} primary="Inactive" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Permission>
            </Grid>
          );
        }}
        structureTable={data =>
          data?.viewer?.loginConnection?.edges.map(user => ({
            id: user.node?.id,
            loginId: user.node?.loginId,
            inactive: user.node?.employeeInactive,
            email: user.node?.email,
            person: user.node?.person,
            phone: user.node?.person?.phone,
            disableDelete: !deletePermission?.access,
            disableTableRowClick: !createPermission?.access,
          }))
        }
        filterByDate={false}
        selectableRows={false}
        columns={[
          { title: 'Id', field: 'loginId' },
          {
            // TODO: permissions dont work as intended here because of `person` field
            title: 'Full name',
            field: 'person',
            customSortField: 'person.lastName',
            customPermissionField: 'person.__typename',
            enablePropagation: true,
            render: person =>
              person && person.firstName && person.lastName
                ? `${person.firstName} ${person.lastName}`
                : '/',
          },
          {
            title: 'Phone',
            field: 'phone',
            customSortField: 'person.phone.phone',
            enablePropagation: true,
            render: phone => phone?.phone || '/',
          },
          { title: 'Email', field: 'email' },
          {
            title: 'Status',
            field: 'inactive',
            enablePropagation: true,
            disableSort: true,
            render: inactive =>
              inactive ? (
                <span className={classes.inactive}>INACTIVE</span>
              ) : (
                <span className={classes.active}>ACTIVE</span>
              ),
          },
          // { title: 'Location', field: 'Location' }, TODO: does location column make sense here?
        ]}
        onTableRowClick={(row, _, variables) => {
          handleCreateUser(variables, row);
        }}
        useConfirmationModalForDelete
        isDeleting={false} // TODO: add isDeleting here
        deleteConfirmationText="Are you sure you want to delete employee?"
      />
      <Modal
        open={showCreateUserModal}
        title={personId ? 'Edit Employee' : 'Create Employee'}
        handleClose={handleCloseCreateUserModal}
      >
        <CreateEmployeeForm
          personId={personId}
          handleCloseModal={handleCloseCreateUserModal}
          tableState={tableState}
          setPersonId={setPersonId}
          activationEmployeePermission={activationEmployeePermission}
          createMutationPermission={createPermission}
          updateMutationPermission={updatePermission}
          updateLocationPermission={updateLocationPermission}
          accessToMutations={accessToMutations}
          locationPermissions={locationPermissions}
          setLocationPermissions={setLocationPermissions}
        />
      </Modal>
    </>
  );
};

export default EmployeeList;
