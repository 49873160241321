import React, { useState, useMemo } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Elements from '../../../../../blocks/Elements';
import PhoneNumberInput from '../../../../../blocks/PhoneNumberInput';
import { DateTime } from 'luxon';
import {
  Permission,
  PermissionField,
  PlacesAutocomplete,
  SelectFactory,
} from '../../../../../blocks';
import { useQuery, useMutation } from '@apollo/client';
import {
  USERS_BY_USERGROUP,
  ACTIVE_LOCATIONS_FOR_EMPLOYEE,
  FETCH_STATES,
  LOCATIONS,
  FETCH_COUNTRIES,
} from '../../../../../../apollo/queries';
import AutoComplete from '../../../../../blocks/AutoComplete';
import { CREATE_EMPLOYEE, UPDATE_EMPLOYEE } from '../../../../../../apollo/mutations/employees';
import { validateGeocode, regexPattern } from '../../../../../../utils';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../../constants';
import GLOBAL_EMPLOYEE_ACTIVATION from '../../../../../../apollo/mutations/employees/activationEmployee';
import { compact } from 'lodash';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const EmployeeInfo = ({
  handleTabChange,
  existingEmployee,
  handleCloseModal,
  tableState,
  permissions,
  locationId,
  mutationPermission,
  setPersonId,
  refetchActiveLocationsForEmployee,
  accessToMutations,
  activationEmployeePermission,
}) => {
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const { setSnackbar } = useSnackbar();

  // const [formUsergroup, setFormUsergroup] = useState([]);
  // const [usergroupsError, setUsergroupError] = useState();
  const [userLocations, setUserLocations] = useState([]);

  const { data, loading } = useQuery(FETCH_STATES, {
    variables: {
      first: 100,
    },
  });

  const { data: countryData, loading: countryLoading } = useQuery(FETCH_COUNTRIES);

  const stateList = useMemo(
    () =>
      data?.viewer?.stateConnection?.edges?.map(state => ({
        label: state.node.label,
        value: state.node.stateId,
      })),
    [data?.viewer?.stateConnection?.edges],
  );

  const countryList = useMemo(
    () =>
      countryData?.viewer?.countryConnection?.edges?.map(country => ({
        label: country.node.label,
        value: country.node.countryId,
        alpha2: country.node.alpha2,
      })),
    [countryData?.viewer?.countryConnection?.edges],
  );

  const [createEmployee, { loading: createEmployeeLoading }] = useMutation(CREATE_EMPLOYEE);
  const [updateEmployee, { loading: updateEmployeeLoading }] = useMutation(UPDATE_EMPLOYEE);

  const [globalEmployeeActivation] = useMutation(GLOBAL_EMPLOYEE_ACTIVATION);

  // const { data, loading, error } = useQuery(USERGROUPS, {
  //   variables: {
  //     first: 50,
  //     stateFirst: 100,
  //   },
  // })
  const [locationAddress, setLocationAddress] = useState(
    existingEmployee && {
      address: existingEmployee?.address?.address,
      city: existingEmployee?.address?.city,
      zip: existingEmployee?.address?.zip,
      additionalInfo: existingEmployee?.address?.additionalInfo,
      state: {
        label: existingEmployee?.address?.state?.label,
        value: existingEmployee?.address?.state?.stateId,
      },
      country: {
        label: existingEmployee?.address?.state?.country?.label,
        value: existingEmployee?.address?.state?.country?.countryId,
        alpha2: existingEmployee?.address?.state?.country?.alpha2,
      },
    },
  );

  const handleAddressChange = (event, type) => {
    const value = event.target?.value || event.label;
    setLocationAddress(prevState => ({ ...prevState, [type]: value }));
  };

  // const usergroups = useMemo(() => data?.viewer?.usergroupConnection?.edges?.map(usergroup => ({
  //   label: usergroup.node.label,
  //   value: usergroup.node.usergroupId,
  //   id: usergroup.node.usergroupId,
  // })), [data?.viewer?.usergroupConnection?.edges]);

  // const defaultUsergroups = useMemo(() => existingEmployee?.login?.usergroupConnection?.edges?.map(edge => ({
  //   value: Number(fromGlobalId(edge.node.id).id.split(':')[0]),
  //   id: Number(fromGlobalId(edge.node.id).id.split(':')[0]),
  //   label: edge.node.label,
  // }))|| [],
  //  [existingEmployee?.login?.usergroupConnection?.edges]);
  //
  //  useEffect(() => {
  //    setFormUsergroup(defaultUsergroups)
  //  }, [defaultUsergroups])

  if (loading) return <div>loading...</div>;
  if (countryLoading) return <div>loading...</div>;

  const handleGeocodeAutocomplete = address => {
    const { state, country } = address;

    const formatState = data?.viewer?.stateConnection?.edges?.map(state => ({
      label: state.node.label,
      value: state.node.stateId,
    }));

    address.state = formatState.find(existingState => existingState.label === state);
    address.country = countryList.find(existingCountry => existingCountry.alpha2 === country);

    setLocationAddress(address);

    setValue('state', address?.state.label);
    setValue('country', address?.country.label);
  };

  const onSubmit = async formData => {
    if (!existingEmployee && !userLocations?.length && !locationAddress?.state?.label) {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Location and state are required.',
      });
      return;
    }
    if (!existingEmployee && !userLocations?.length) {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Location is required.',
      });
      return;
    }
    if (!existingEmployee && !locationAddress?.state?.label) {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'State is required.',
      });
      return;
    }
    const { isValidAddress = false, lat, lng, error } = await validateGeocode(
      formData.address,
      formData.city,
      formData.zip,
      locationAddress.state.label,
      locationAddress.country?.alpha2,
    );

    if (!isValidAddress) {
      return setSnackbar({
        open: true,
        type: 'error',
        text: `${error}, try a different combination of address`,
      });
    }

    // if (isEmpty(formUsergroup)) {
    //   return setUsergroupError('Select at least one usergrouup')
    // } else {
    //   setUsergroupError()
    // }

    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: existingEmployee ? 'Updating employee...' : 'Creating employee...',
    });

    if (!existingEmployee) {
      await createEmployee({
        variables: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: formData.address,
          additionalInfo: formData.additionalInfo,
          city: formData.city,
          stateLabel: locationAddress.state.label,
          countryId: locationAddress.country?.id,
          zip: formData.zip,
          phone: formData.phone,
          email: formData.email,
          latitude: lat,
          longitude: lng,
          dateOfBirth: formData.dateOfBirth,
          locationIds: userLocations,
          schedulePreference: formData.schedulePreference,
          // usergroupIds: formUsergroup?.map(userGroup => userGroup.value),
        },
        refetchQueries: [{ query: USERS_BY_USERGROUP, variables: { ...tableState } }],
      })
        .then(async result => {
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Successfully created employee',
          });

          setPersonId(result?.data?.createEmployee?.person?.personId);

          await refetchActiveLocationsForEmployee();

          handleTabChange(undefined, 'locations');
        })
        .catch(error => {
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
          });
        });
    } else {
      const updateEmployeeData = {};
      // only send changed data
      if (formData.firstName !== existingEmployee.firstName)
        updateEmployeeData.firstName = formData.firstName;
      if (formData.lastName !== existingEmployee.lastName)
        updateEmployeeData.lastName = formData.lastName;
      if (formData.additionalInfo !== existingEmployee.address.additionalInfo)
        updateEmployeeData.additionalInfo = formData.additionalInfo;
      if (formData.phone !== existingEmployee.phone.phone)
        updateEmployeeData.phone = formData.phone;
      if (formData.dateOfBirth !== existingEmployee.dateOfBirth)
        updateEmployeeData.dateOfBirth = formData.dateOfBirth;
      if (formData.schedulePreference !== existingEmployee.schedulePreference)
        updateEmployeeData.schedulePreference = formData.schedulePreference;

      // updateEmployeeData.usergroupIds = formUsergroup?.map(userGroup => userGroup.value);
      updateEmployeeData.email = formData.email;
      updateEmployeeData.zip = formData.zip;
      updateEmployeeData.address = formData.address;
      updateEmployeeData.city = formData.city;
      updateEmployeeData.stateLabel = locationAddress?.state?.label;
      updateEmployeeData.loginId = existingEmployee.login.loginId;
      updateEmployeeData.longitude = lng;
      updateEmployeeData.latitude = lat;
      updateEmployeeData.countryId = locationAddress?.country?.id;

      updateEmployee({
        variables: updateEmployeeData,
        refetchQueries: [
          { query: USERS_BY_USERGROUP, variables: { ...tableState } },
          {
            query: ACTIVE_LOCATIONS_FOR_EMPLOYEE,
            variables: { personId: existingEmployee?.id, locationId },
          },
        ],
      })
        .then(() => {
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Successfully saved employee',
          });
          handleCloseModal();
        })
        .catch(error => {
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
          });
        });
    }
  };

  const globalActivation = activate => {
    globalEmployeeActivation({
      variables: {
        input: {
          loginId: existingEmployee?.login?.id,
          activate,
        },
      },
      refetchQueries: [
        {
          query: USERS_BY_USERGROUP,
          variables: {
            fieldGroup: 60,
            first: 10,
            filter: {
              // showOnlyEmployees: { eq: true },
              showEmployeesOnYourLocations: { eq: true },
            },
          },
        },
      ],
    })
      .then(() => {
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: `Employee successfully ${activate ? 'activated' : 'deactivated'}`,
        });
        handleCloseModal();
      })
      .catch(error => {
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        });
      });
  };

  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5}>
          <Permission access={existingEmployee ? permissions?.firstName : 7}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                name="firstName"
                label="First Name"
                defaultValue={existingEmployee?.firstName}
                inputRef={register({
                  required: true,
                })}
                error={!!errors.firstName}
                helperText={errors.firstName && 'First name is required.'}
                fullWidth
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.lastName : 7}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                name="lastName"
                label="Last Name"
                defaultValue={existingEmployee?.lastName}
                inputRef={register({
                  required: true,
                })}
                error={!!errors.lastName}
                fullWidth
                helperText={errors.lastName && 'Last name is required.'}
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.login?.email : 7}>
            <Grid item xs={4}>
              <PermissionField
                Component={TextField}
                name="email"
                label="Email"
                defaultValue={existingEmployee?.login?.email}
                inputRef={register({
                  required: true,
                  pattern: {
                    value: regexPattern.email,
                    message: 'Invalid email address',
                  },
                })}
                fullWidth
                error={!!errors.email}
                helperText={errors.email && 'Email is required.'}
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.phone?.phone : 7}>
            <Grid item xs={4}>
              <PermissionField
                Component={PhoneNumberInput}
                label={'Phone'}
                name="phone"
                id="phone"
                fullWidth
                error={errors.phone}
                rules={{
                  validate: {
                    existing: value => !!value,
                  },
                }}
                helperText="Phone is not correct."
                defaultValue={existingEmployee?.phone?.phone}
                control={control}
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.dateOfBirth : 7}>
            <Grid item xs={4}>
              <PermissionField
                Component={Elements.DatePicker}
                control={control}
                name="dateOfBirth"
                label="Date of birth"
                format="yyyy-MM-dd"
                fullWidth
                defaultValue={
                  existingEmployee?.dateOfBirth || DateTime.local().toFormat('yyyy-LL-dd')
                }
              />
            </Grid>
          </Permission>
          <Grid item xs={3}>
            <PermissionField
              Component={AutoComplete}
              name="country"
              id={'country'}
              rules={{
                required: true,
              }}
              defaultValue={existingEmployee ? locationAddress?.country : null}
              getOptionLabel={option => {
                if (typeof option === 'object' && !Array.isArray(option) && option !== null) {
                  return option.label;
                }
                return option;
              }}
              control={control}
              error={errors.country}
              helperText={errors.country && 'Country is required.'}
              renderInput={params => (
                <TextField
                  {...params}
                  name="countey"
                  label={'Country'}
                  variant="outlined"
                  inputRef={register({
                    required: true,
                  })}
                />
              )}
              options={countryList || []}
              onChange={selected => {
                setLocationAddress(prevState => ({
                  ...prevState,
                  country: selected,
                }));
              }}
              selectedOption={{
                label: locationAddress?.country ? locationAddress?.country?.label : '',
                value: locationAddress?.country ? locationAddress?.country?.value : '',
                alpha2: locationAddress?.country ? locationAddress?.country?.alpha2 : '',
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="column">
              <PlacesAutocomplete handleGeocodeAutocomplete={handleGeocodeAutocomplete} />
            </Grid>
          </Grid>
          <Permission access={existingEmployee ? permissions?.address?.address : 7}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                name="address"
                label="Street Address"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.streetAddress}
                onChange={event => handleAddressChange(event, 'address')}
                fullWidth
                value={locationAddress?.address}
                defaultValue={locationAddress?.address}
                helperText={errors.streetAddress && 'Street Address is required.'}
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.address?.additionalInfo : 7}>
            <Grid item xs={6}>
              <PermissionField
                Component={TextField}
                name="additionalInfo"
                label="Apartment, Suite, Unit, Building..."
                inputRef={register}
                defaultValue={locationAddress?.additionalInfo}
                fullWidth
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.address?.city : 7}>
            <Grid item xs={4}>
              <PermissionField
                Component={TextField}
                name="city"
                label="City"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.city}
                value={locationAddress?.city}
                onChange={event => handleAddressChange(event, 'city')}
                fullWidth
                helperText={errors.city && 'City is required.'}
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.address?.state?.['__typename'] : 7}>
            <Grid item xs={4} container>
              <Grid direction="column" xs={12} item>
                <PermissionField
                  Component={AutoComplete}
                  name="state"
                  id={'state'}
                  rules={{
                    required: true,
                  }}
                  defaultValue={existingEmployee ? locationAddress?.state : null}
                  getOptionLabel={option => {
                    if (typeof option === 'object' && !Array.isArray(option) && option !== null) {
                      return option.label;
                    }
                    return option;
                  }}
                  control={control}
                  error={errors.state}
                  helperText={errors.state && 'State is required.'}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name="state"
                      label={'State'}
                      variant="outlined"
                      inputRef={register({
                        required: true,
                      })}
                    />
                  )}
                  options={stateList || []}
                  onChange={selected => {
                    setLocationAddress(prevState => ({
                      ...prevState,
                      state: selected,
                    }));
                  }}
                  selectedOption={{
                    label: locationAddress?.state ? locationAddress?.state?.label : '',
                    value: locationAddress?.state ? locationAddress?.state?.value : '',
                  }}
                />
              </Grid>
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.address?.zip : 7}>
            <Grid item xs={4}>
              <PermissionField
                Component={TextField}
                name="zip"
                label="Zip"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.zip}
                onChange={event => handleAddressChange(event, 'zip')}
                value={locationAddress?.zip}
                helperText={errors.zip && 'Zip is required.'}
                fullWidth
              />
            </Grid>
          </Permission>
          <Permission access={existingEmployee ? permissions?.schedulePreference : 7}>
            <Grid item xs={12}>
              <PermissionField
                Component={TextField}
                name="schedulePreference"
                label="Schedule Preference"
                inputRef={register}
                defaultValue={existingEmployee?.schedulePreference}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
          </Permission>
          {/* Location assignment is done only on new employee creation, for editing, use the location tab */}
          {!existingEmployee && (
            <Grid item xs={6} style={{ flexDirection: 'column' }}>
              <SelectFactory
                label={'Locations'}
                placeholder={'Select Locations'}
                query={LOCATIONS}
                disableCloseOnSelect={true}
                multiple
                renderCheckBox
                structureData={data => {
                  return data?.viewer?.locationConnection?.edges.map((location, index) => {
                    return {
                      label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
                      id: location.node.locationId,
                      index,
                    };
                  });
                }}
                fullWidth
                onSelect={values => {
                  setUserLocations(values.map(loc => loc.id));
                }}
              />
              <span
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  marginLeft: '14px',
                  marginRight: '14px',
                  paddingLeft: '13px',
                  marginTop: '3px',
                }}
              >
                Location is required.
              </span>
            </Grid>
          )}
          {/* TODO: ADD LOCATION SELECTOR HERE */}
          {/*<Grid item xs={4}>*/}
          {/*  <Grid container direction="column">*/}
          {/*    <Permission access={existingEmployee ? data?.viewer?.usergroupConnection?.permissions?.['__typename'] : 7}>*/}
          {/*    <SelectMUI*/}
          {/*      label="Usergroups"*/}
          {/*      id="usergroups"*/}
          {/*      isMultiple*/}
          {/*      setSelected={setFormUsergroup}*/}
          {/*      control={control}*/}
          {/*      defaultValue={defaultUsergroups}*/}
          {/*      items={usergroups}*/}
          {/*      variant="outlined"*/}
          {/*      error={usergroupsError}*/}
          {/*      helperText={usergroupsError}*/}
          {/*    />*/}
          {/*    </Permission>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          <Grid xs={12} container style={{ marginBottom: '0.5rem' }}>
            <Grid item xs={4}>
              <Permission access={activationEmployeePermission?.access}>
                <div>
                  <PermissionField createHelperText>
                    {existingEmployee &&
                      (!existingEmployee?.login?.employeeInactive ? (
                        <Button
                          onClick={() => globalActivation(false)}
                          variant="contained"
                          color="secondary"
                          style={{ color: 'white', background: 'red' }}
                        >
                          Deactivate on all employee locations
                        </Button>
                      ) : (
                        <Button variant={'outlined'} onClick={() => globalActivation(true)}>
                          Activate on all employee locations
                        </Button>
                      ))}
                  </PermissionField>
                </div>
              </Permission>
            </Grid>
            <Grid item justify="flex-end" spacing={3} xs={8}>
              <Grid item>
                <Button color="primary" variant="outlined" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Permission
                  access={Math.min(
                    mutationPermission?.access,
                    existingEmployee
                      ? accessToMutations?.[MUTATION_NAME.UpdateEmployeeMutationInput]
                      : accessToMutations?.[MUTATION_NAME.CreateEmployeeMutationInput],
                  )}
                >
                  <Button disabled={createEmployeeLoading || updateEmployeeLoading} type="submit">
                    Save
                  </Button>
                </Permission>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EmployeeInfo;
