import { gql } from '@apollo/client';

const CREATE_VENDOR_PACK_SIZE = gql`
  mutation CREATE_VENDOR_PACK_SIZE($input: CreateVendorproductmeasureInput!) {
    createVendorproductmeasure(input: $input) {
      viewer {
        id
      }
      vendorproductmeasure {
        id
      }
    }
  }
`;

export default CREATE_VENDOR_PACK_SIZE;
