import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import ProductionForecasterStyles from '../../View/styles.js';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import productionForecasterPdf from '../../productionForecasterPdf.js';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { SNACKBAR_STATUS } from '../../../../../../../constants';
import { SlideshowRounded } from '@material-ui/icons';

const DailyForecasterCategoryView = ({
  refetch,
  variables,
  data,
  getNextDay,
  getPreviousDay,
  calculateDailyForecaster,
  calculateSmokeTodayForTomorrowUsage,
  calculateSideUsage,
  calculateProteinUsage,
  saveDailyForecaster,
  getSideUOM,
  saveDailyForecasterLoading,
  setSnackbar,
}) => {
  const classes = ProductionForecasterStyles();

  const [dailyForecaster, setDailyForecaster] = useState({});

  useEffect(() => {
    setDailyForecaster(calculateDailyForecaster(data.viewer.location.dailyForecaster));
  }, [data.viewer.location.dailyForecaster]);

  const handleSaveDailyForecaster = () => {
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Saving Daily Forecaster Category...',
    });

    const saveDailyForecasterInput = {
      locationId: data.viewer.location.locationId,
      dailyForecasterDate:
        variables?.orderingForecasterDate || DateTime.local().toFormat('yyyy-LL-dd'),
      inventoryitems: [
        ...dailyForecaster.proteins.map(protein => ({
          inventoryitemId: protein.protein.id,
          am: protein.am,
          pm: protein.pm,
          catering: protein.catering,
          onHand: protein.onHand,
          strategic: protein.strategic,
        })),
        ...dailyForecaster.sides.map(side => ({
          inventoryitemId: side.side.id,
          am: side.am,
          pm: side.pm,
          catering: side.catering,
          onHand: side.onHand,
          strategic: side.strategic,
        })),
      ],
      smokeTodayForTomorrow: [
        ...dailyForecaster.smokeTodayForTomorrow.proteins.map(protein => ({
          inventoryitemId: protein.protein.id,
          am: protein.am,
          pm: protein.pm,
          catering: protein.catering,
          strategic: protein.strategic,
        })),
      ],
    };

    saveDailyForecaster({
      variables: {
        input: saveDailyForecasterInput,
      },
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Daily Forecaster Category saved',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        }),
      );
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        className={classes.titlePadding}
        id="dailyForecasterCategory"
      >
        Daily Forecaster Category
      </Typography>
      <Grid container className={classes.heading}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <Grid container className={classes.heading}>
            {getPreviousDay(variables, refetch)}
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              format="LL/dd/yyyy"
              margin="normal"
              style={{ width: '230px' }}
              label="Daily Forecaster Date"
              autoOk={true}
              disabled={true}
              initialFocusedDate={DateTime.local()}
              value={variables?.orderingForecasterDate || DateTime.local()}
            />
            {getNextDay(variables, refetch)}
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container className={classes.heading}>
        <Grid item xs={12} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder} style={{ fontSize: '24px' }}>
            <b>Proteins</b>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}>
            <b>Today Smoke/Usage</b>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}></div>
        </Grid>
        <Grid item xs={8} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder} style={{ marginLeft: '5%' }}>
            <b>PAR</b>
          </div>
        </Grid>
        <Grid container className={classes.heading}>
          <Grid item xs={2} className={classes.flexDirection} style={{ flexDirection: 'row' }} />
          <Grid item xs={10} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
            <Grid item xs={6} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>AM</div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>PM</div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>Catering</div>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>On Hand</div>
              </Grid>
              <Grid item xs={3} className={classes.flexDirection} style={{ marginLeft: '1rem' }}>
                <div className={classes.contentInfoHolder}>
                  <b>Usage</b>
                </div>
              </Grid>
              <Grid item xs={5} className={classes.flexDirection} style={{ paddingLeft: '1.5rem' }}>
                <div className={classes.contentInfoHolder}>Strategic</div>
              </Grid>
            </Grid>
          </Grid>
          {dailyForecaster.proteins?.map((proteinDF, index) => (
            <React.Fragment key={proteinDF.protein.id}>
              <Grid
                item
                xs={2}
                className={classes.flexDirection}
                style={{ marginBottom: '0.5rem' }}
              >
                <div className={classes.contentInfoHolder}>
                  <b>{proteinDF.protein.label}</b>
                </div>
                <div
                  className={classes.contentInfoHolder}
                >{`${proteinDF.protein.size}${proteinDF.protein.measure} ${proteinDF.protein.format}`}</div>
              </Grid>
              <Grid item xs={10} style={{ flexDirection: 'row' }}>
                <Grid
                  item
                  xs={6}
                  className={classes.flexDirection}
                  style={{ flexDirection: 'row' }}
                >
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{
                      marginBottom: '0.5rem',
                      marginRight: '1rem',
                    }}
                  >
                    <TextField
                      name={`${proteinDF.protein.id}.dailyForecaster.am`}
                      type="number"
                      value={parseFloat(proteinDF.am).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const proteins = [...prevDailyForecaster.proteins];

                          const protein = {
                            ...proteins[index],
                            am: parseFloat(event.target.value),
                          };

                          const usage = calculateProteinUsage(protein);

                          proteins[index] = {
                            ...protein,
                            usage,
                            strategic: usage,
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            proteins,
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{
                      marginBottom: '0.5rem',
                      marginRight: '1rem',
                    }}
                  >
                    <TextField
                      name={`${proteinDF.protein.id}.dailyForecaster.pm`}
                      type="number"
                      value={parseFloat(proteinDF.pm).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const proteins = [...prevDailyForecaster.proteins];

                          const protein = {
                            ...proteins[index],
                            pm: parseFloat(event.target.value),
                          };

                          const usage = calculateProteinUsage(protein);

                          proteins[index] = {
                            ...protein,
                            usage,
                            strategic: usage,
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            proteins,
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{
                      marginBottom: '0.5rem',
                      marginRight: '1rem',
                    }}
                  >
                    <TextField
                      name={`${proteinDF.protein.id}.dailyForecaster.catering`}
                      type="number"
                      value={parseFloat(proteinDF.catering).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const proteins = [...prevDailyForecaster.proteins];

                          const protein = {
                            ...proteins[index],
                            catering: parseFloat(event.target.value),
                          };

                          const usage = calculateProteinUsage(protein);

                          proteins[index] = {
                            ...protein,
                            usage,
                            strategic: usage,
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            proteins,
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{
                      marginBottom: '0.5rem',
                      marginRight: '1rem',
                    }}
                  >
                    {proteinDF.protein.partOfCi ? (
                      <TextField
                        name={`${proteinDF.protein.id}.dailyForecaster.onHand`}
                        type="number"
                        value={parseFloat(proteinDF.onHand).toFixed(2)}
                        variant="outlined"
                        onChange={event => {
                          setDailyForecaster(prevDailyForecaster => {
                            const proteins = [...prevDailyForecaster.proteins];

                            const protein = {
                              ...proteins[index],
                              onHand: parseFloat(event.target.value),
                            };

                            const usage = calculateProteinUsage(protein);

                            proteins[index] = {
                              ...protein,
                              usage,
                              strategic: usage,
                            };

                            const dailyForecaster = {
                              ...prevDailyForecaster,
                              proteins,
                            };

                            return dailyForecaster;
                          });
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <div className={classes.centerVerticalContent}>
                      {parseFloat(proteinDF.usage).toFixed(2)}
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDirection}>
                    {proteinDF.protein.hasStrategic ? (
                      <TextField
                        name={`${proteinDF.protein.id}.dailyForecaster.strategic`}
                        type="number"
                        variant="outlined"
                        value={parseFloat(proteinDF.strategic).toFixed(2)}
                        onChange={event => {
                          setDailyForecaster(prevDailyForecaster => {
                            const proteins = [...prevDailyForecaster.proteins];

                            proteins[index] = {
                              ...proteins[index],
                              strategic: parseFloat(event.target.value),
                            };

                            const dailyForecaster = {
                              ...prevDailyForecaster,
                              proteins,
                            };

                            return dailyForecaster;
                          });
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}>
            <b>Smoke today for tomorrow</b>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}></div>
        </Grid>
        <Grid item xs={8} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder} style={{ marginLeft: '5%' }}>
            <b>PAR</b>
          </div>
        </Grid>
        <Grid container className={classes.heading}>
          <Grid item xs={2} className={classes.flexDirection} style={{ flexDirection: 'row' }} />
          <Grid item xs={10} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
            <Grid item xs={6} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>AM</div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>PM</div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>Catering</div>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
              <Grid item xs={4} className={classes.flexDirection} />
              <Grid item xs={4} className={classes.flexDirection} />
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>Strategic</div>
              </Grid>
            </Grid>
          </Grid>
          {dailyForecaster.smokeTodayForTomorrow?.proteins?.map((proteinDF, index) => (
            <React.Fragment key={proteinDF.protein.id}>
              <Grid
                item
                xs={2}
                className={classes.flexDirection}
                style={{ flexDirection: 'column' }}
              >
                <div className={classes.contentInfoHolder}>
                  <b>{proteinDF.protein.label}</b>
                </div>
                <div
                  className={classes.contentInfoHolder}
                >{`${proteinDF.protein.size}${proteinDF.protein.measure} ${proteinDF.protein.format}`}</div>
              </Grid>
              <Grid item xs={10} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
                <Grid item xs={6} style={{ flexDirection: 'row' }}>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <TextField
                      name={`${proteinDF.protein.id}.dailyForecaster.am`}
                      type="number"
                      value={parseFloat(proteinDF.am).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const proteins = [...prevDailyForecaster.smokeTodayForTomorrow.proteins];

                          const protein = {
                            ...proteins[index],
                            am: parseFloat(event.target.value),
                          };

                          proteins[index] = {
                            ...protein,
                            strategic: calculateSmokeTodayForTomorrowUsage(protein),
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            smokeTodayForTomorrow: {
                              ...prevDailyForecaster.smokeTodayForTomorrow,
                              proteins,
                            },
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <TextField
                      name={`${proteinDF.protein.id}.dailyForecaster.pm`}
                      type="number"
                      value={parseFloat(proteinDF.pm).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const proteins = [...prevDailyForecaster.smokeTodayForTomorrow.proteins];

                          const protein = {
                            ...proteins[index],
                            pm: parseFloat(event.target.value),
                          };

                          proteins[index] = {
                            ...protein,
                            strategic: calculateSmokeTodayForTomorrowUsage(protein),
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            smokeTodayForTomorrow: {
                              ...prevDailyForecaster.smokeTodayForTomorrow,
                              proteins,
                            },
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <TextField
                      name={`${proteinDF.protein.id}.dailyForecaster.catering`}
                      type="number"
                      value={parseFloat(proteinDF.catering).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const proteins = [...prevDailyForecaster.smokeTodayForTomorrow.proteins];

                          const protein = {
                            ...proteins[index],
                            catering: parseFloat(event.target.value),
                          };

                          proteins[index] = {
                            ...protein,
                            strategic: calculateSmokeTodayForTomorrowUsage(protein),
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            smokeTodayForTomorrow: {
                              ...prevDailyForecaster.smokeTodayForTomorrow,
                              proteins,
                            },
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={classes.flexDirection}
                  style={{ flexDirection: 'row' }}
                >
                  <Grid item xs={4} className={classes.flexDirection} />
                  <Grid item xs={4} className={classes.flexDirection} />
                  <Grid item xs={4} className={classes.flexDirection}>
                    <TextField
                      name={`${proteinDF.protein.id}.dailyForecaster.strategic`}
                      type="number"
                      variant="outlined"
                      value={parseFloat(proteinDF.strategic).toFixed(2)}
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const proteins = [...prevDailyForecaster.smokeTodayForTomorrow.proteins];

                          proteins[index] = {
                            ...proteins[index],
                            strategic: parseFloat(event.target.value),
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            smokeTodayForTomorrow: {
                              ...prevDailyForecaster.smokeTodayForTomorrow,
                              proteins,
                            },
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}>
            <b>Sides</b>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}></div>
        </Grid>
        <Grid item xs={8} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder} style={{ marginLeft: '5%' }}>
            <b>PAR</b>
          </div>
        </Grid>
        <Grid container className={classes.heading}>
          <Grid item xs={2} className={classes.flexDirection} style={{ flexDirection: 'row' }} />
          <Grid item xs={10} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
            <Grid item xs={6} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>AM</div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>PM</div>
              </Grid>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>Catering</div>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
              <Grid item xs={4} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder}>On Hand</div>
              </Grid>
              <Grid item xs={3} className={classes.flexDirection}>
                <div className={classes.contentInfoHolder} style={{ marginLeft: '1rem' }}>
                  <b>Usage</b>
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.flexDirection}
                style={{ paddingLeft: '1.75rem' }}
              >
                <div className={classes.contentInfoHolder}>Strategic</div>
              </Grid>
            </Grid>
          </Grid>
          {dailyForecaster.sides?.map((sideDF, index) => (
            <React.Fragment key={sideDF.side.id}>
              <Grid
                item
                xs={2}
                className={classes.flexDirection}
                style={{ flexDirection: 'column' }}
              >
                <div className={classes.contentInfoHolder}>
                  <b>{sideDF.side.label}</b>
                </div>
                <div className={classes.contentInfoHolder}>{getSideUOM(sideDF.side, 0)}</div>
              </Grid>
              <Grid item xs={10} className={classes.flexDirection} style={{ flexDirection: 'row' }}>
                <Grid
                  item
                  xs={6}
                  className={classes.flexDirection}
                  style={{ flexDirection: 'row' }}
                >
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <TextField
                      name={`${sideDF.side.id}.dailyForecaster.am`}
                      type="number"
                      value={parseFloat(sideDF.am).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const sides = [...prevDailyForecaster.sides];

                          const side = {
                            ...sides[index],
                            am: parseFloat(event.target.value),
                          };

                          const usage = calculateSideUsage(side);

                          sides[index] = {
                            ...side,
                            usage,
                            strategic: calculateSideUsage(side),
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            sides,
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <TextField
                      name={`${sideDF.side.id}.dailyForecaster.pm`}
                      type="number"
                      value={parseFloat(sideDF.pm).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const sides = [...prevDailyForecaster.sides];

                          const side = {
                            ...sides[index],
                            pm: parseFloat(event.target.value),
                          };

                          const usage = calculateSideUsage(side);

                          sides[index] = {
                            ...side,
                            usage,
                            strategic: usage,
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            sides,
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <TextField
                      name={`${sideDF.side.id}.dailyForecaster.catering`}
                      type="number"
                      value={parseFloat(sideDF.catering).toFixed(2)}
                      variant="outlined"
                      onChange={event => {
                        setDailyForecaster(prevDailyForecaster => {
                          const sides = [...prevDailyForecaster.sides];

                          const side = {
                            ...sides[index],
                            catering: parseFloat(event.target.value),
                          };

                          const usage = calculateSideUsage(side);

                          sides[index] = {
                            ...side,
                            usage,
                            strategic: usage,
                          };

                          const dailyForecaster = {
                            ...prevDailyForecaster,
                            sides,
                          };

                          return dailyForecaster;
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={classes.flexDirection}
                  style={{ flexDirection: 'row' }}
                >
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    {sideDF.side.partOfCi ? (
                      <TextField
                        name={`${sideDF.side.id}.dailyForecaster.onHand`}
                        type="number"
                        value={parseFloat(sideDF.onHand).toFixed(2)}
                        variant="outlined"
                        onChange={event => {
                          setDailyForecaster(prevDailyForecaster => {
                            const sides = [...prevDailyForecaster.sides];

                            const side = {
                              ...sides[index],
                              onHand: parseFloat(event.target.value),
                            };

                            const usage = calculateSideUsage(side);

                            sides[index] = {
                              ...side,
                              usage,
                              strategic: usage,
                            };

                            const dailyForecaster = {
                              ...prevDailyForecaster,
                              sides,
                            };

                            return dailyForecaster;
                          });
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes.flexDirection}
                    style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  >
                    <div className={classes.centerVerticalContent}>
                      {parseFloat(sideDF.usage).toFixed(2)}
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDirection}>
                    {sideDF.side.hasStrategic ? (
                      <TextField
                        name={`${sideDF.side.id}.dailyForecaster.strategic`}
                        type="number"
                        variant="outlined"
                        value={parseFloat(sideDF.strategic).toFixed(2)}
                        onChange={event => {
                          setDailyForecaster(prevDailyForecaster => {
                            const sides = [...prevDailyForecaster.sides];

                            sides[index] = {
                              ...sides[index],
                              strategic: parseFloat(event.target.value),
                            };

                            const dailyForecaster = {
                              ...prevDailyForecaster,
                              sides,
                            };

                            return dailyForecaster;
                          });
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid container className={classes.heading} spacing={2}>
        <Grid item>
          <Button
            onClick={() => {
              productionForecasterPdf({
                dailyForecaster,
                location: data?.viewer?.location?.label,
                date: variables?.orderingForecasterDate || DateTime.local().toFormat('yyyy-LL-dd'),
              });
            }}
          >
            Export PDF
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={saveDailyForecasterLoading}
            onClick={() => {
              handleSaveDailyForecaster();
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DailyForecasterCategoryView;
