import { gql } from "@apollo/client";
import { viewerFragment } from "../../fragments";

const REMOVE_ORDER_ITEM = gql`
  mutation RemoveOrderItem($input: RemoveOrderItemMutationInput!) {
    removeOrderItem(input: $input) {
      viewer {
        ...ViewerData
      }
      quote {
        id
      }
    }
  }
  ${viewerFragment.viewer}
`;

export default REMOVE_ORDER_ITEM;
