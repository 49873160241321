import { gql } from '@apollo/client';

const UPDATE_LOCATION_EMPLOYEE = gql`
  mutation updateLocationEmployee(
    $personId: Int!,
    $locationId: Int!,
    $statusDate: String,
    $jobs: [Jobs],
    $pincode: String!,
    $hireDate: String
    $terminationDate: String
  ) {
    updateLocationEmployee(
      input: {
        personId: $personId,
        locationId: $locationId,
        statusDate: $statusDate,
        jobs: $jobs,
        pincode: $pincode,
        hireDate: $hireDate,
        terminationDate: $terminationDate
      }
    ) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_LOCATION_EMPLOYEE;
