import CateringIcon from '../assets/icons/CateringIcon.png';
import CoreOpsIcon from '../assets/icons/CoreOpsFormsIcon.png';
import HomeIcon from '../assets/icons/HomeIcon.png';
import InventoryIcon from '../assets/icons/InventoryIcon.png';
import LaborIcon from '../assets/icons/LaborIcon.png';
import LocationIcon from '../assets/icons/LocationIcon.png';
import OrdersIcon from '../assets/icons/OrdersIcon.png';
import ReportsIcon from '../assets/icons/ReportsIcon.png';
import SystemSettingsIcon from '../assets/icons/SystemSettingsIcon.png';

const icons = {
  CateringIcon,
  CoreOpsIcon,
  HomeIcon,
  InventoryIcon,
  LaborIcon,
  LocationIcon,
  OrdersIcon,
  ReportsIcon,
  SystemSettingsIcon,
};

export default icons;
