import { gql } from '@apollo/client';

export const FETCH_SUBCATEGORIES = gql`
  query subcategoryFetch(
    $menuFilter: [SubcategoryFilter]
    $menuCategoryFilter: [MenuCategoryFilter]
    $locationId: Int
  ) {
    viewer {
      id
      subcategoryConnection(filter: $menuFilter, fieldGroup: 19) {
        permissions
        edges {
          node {
            id
            subcategoryId
            label
            availableOn(locationId: $locationId) {
              now
            }
          }
        }
      }
      menuCategoryConnection(filter: $menuCategoryFilter, fieldGroup: 19) {
        permissions
        edges {
          node {
            id
            category {
              id
              categoryId
              label
            }
          }
        }
      }
    }
  }
`;

export default FETCH_SUBCATEGORIES;
