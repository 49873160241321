import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  dialogTitle: {
    fontSize: '22px',
  },
  noniContactTitle: {
    marginBottom: '15px',
  },
  fixedMethod: {
    width: '100%',
  },
  customGroupFiltersDivider: {
    width: '100%',
    margin: '20px 0',
  },
  customGroupFiltersEmail: {
    width: '100%',
    margin: '10px 0',
  },
});

export const SerializedCuouponDistributionModalStyles = makeStyles(Styles);
