import PaymentProcessors from "../PaymentProcessors";
import PrinterStationSettings from "../PrinterStationSettings";
import CategoryPrintSettings from "../CategoryPrintSettings";
import { Divider } from "@material-ui/core";

function POSSettingsView({
  gateways,
  devicesAndPrinters,
  enablePayPalAndSquare,
  subcategoriesPrinterstations,
  permissions
}) {
  return (
    <div>
      <PaymentProcessors
        enablePayPalAndSquare={enablePayPalAndSquare}
        devicesAndPrinters={devicesAndPrinters}
        gateways={gateways}
        permissions={permissions?.getaways}
      />
      <Divider />
      <PrinterStationSettings
        devicesAndPrinters={devicesAndPrinters}
        permissions={permissions?.location}
      />
      <Divider />
      <CategoryPrintSettings
        subcategoriesPrinterstations={subcategoriesPrinterstations}
        permissions={permissions?.location}
      />
    </div>
  );
}

export default POSSettingsView;
