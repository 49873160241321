export const gatewayId = {
  PAYPAL: 'R2F0ZXdheToyOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  STRIPE: 'R2F0ZXdheToxOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
};

export const tenderId = {
  APPLE_PAY: 'VGVuZGVyOjExOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  CASH: 'VGVuZGVyOjE6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
  CHARGE: 'VGVuZGVyOjI6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
  CHECK: 'VGVuZGVyOjM6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
  EXTERNAL_CHARGE: 'VGVuZGVyOjc6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
  GIFT_CARD: 'VGVuZGVyOjQ6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
  GOOGLE_PAY: 'VGVuZGVyOjEyOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  HOUSE_ACCOUNT: 'VGVuZGVyOjg6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
  PAYPAL: 'VGVuZGVyOjEzOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
};
