import { gql } from "@apollo/client";

export const WAGE_RULES_LIST = gql`
  query WageRules {
    viewer {
      id
      wageruleConnection(fieldGroup: 59) {
        totalCount
        permissions
        edges {
          node {
            id
            wageruleId
            label
            minimumWage
            tipCreditWage
            locationConnection {
              edges {
                node {
                  id
                  locationId
                  storeNumber
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;
