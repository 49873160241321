import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Box, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { Modal } from '../';
import {
  ADD_INVENTORYITEM_TO_PRODUCTION_FORECASTER,
  REMOVE_INVENTORYITEM_FROM_PRODUCTION_FORECASTER,
} from '../../../apollo/mutations';
import { INVENTORYITEM_CUSTOM_CATEGORY } from '../../../apollo/queries/inventory';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../constants';
import SelectMUI from '../Select';

const ProductionForecasterSettings = ({
  isProductionForecasterModalOpen,
  handleProductionForecasterModalClose,
  inventoryitem,
  updateInventoryitem,
}) => {
  const { register, errors, control, handleSubmit } = useForm();
  const { setSnackbar } = useSnackbar();
  const [displaySizeCooked, setDisplaySizeCooked] = useState();
  const { data, loading: categoryLoading, error } = useQuery(INVENTORYITEM_CUSTOM_CATEGORY);
  const [addInventoryItemToProductionForecaster, { loading }] = useMutation(
    ADD_INVENTORYITEM_TO_PRODUCTION_FORECASTER,
    {
      onCompleted: data => {
        updateInventoryitem(prevData => {
          return {
            ...prevData,
            inventoryitem: {
              ...prevData.inventoryitem,
              inventoryitemPrep:
                data.addInventoryitemToProductionForecaster.inventoryitem.inventoryitemPrep,
              inventoryitemCustomCategory:
                data.addInventoryitemToProductionForecaster.inventoryitem
                  .inventoryitemCustomCategory,
            },
          };
        });
      },
    },
  );

  const [removeInventoryitemFromProductionForecaster, { loading: loadingRemove }] = useMutation(
    REMOVE_INVENTORYITEM_FROM_PRODUCTION_FORECASTER,
    {
      onCompleted: () => {
        updateInventoryitem(prevData => {
          return {
            ...prevData,
            inventoryitem: {
              ...prevData.inventoryitem,
              inventoryitemPrep: null,
              inventoryitemCustomCategory: null,
            },
          };
        });
      },
    },
  );

  useEffect(() => {
    setDisplaySizeCooked(inventoryitem?.inventoryitemPrep?.partOfClosingInventory);
  }, [inventoryitem]);

  const removeInventoryitem = () => {
    removeInventoryitemFromProductionForecaster({
      variables: {
        input: {
          inventoryitemId: inventoryitem?.inventoryitemId,
        },
      },
    })
      .then(() => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: `${inventoryitem?.label} removed from Production Forecaster`,
          open: true,
        });
        handleProductionForecasterModalClose();
      })
      .catch(err => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: `${err}`,
          open: true,
        });
      });
  };

  const onSubmit = formData => {
    addInventoryItemToProductionForecaster({
      variables: {
        input: {
          inventoryitemId: inventoryitem?.inventoryitemId,
          cspm: formData.cspm,
          size: formData.size,
          format: formData.format,
          closingInventory: formData.isPartOfClosingInventory,
          sizeCooked: formData.sizeCooked,
          smokeTodayForTomorrow: formData.smokeTodayForTomorrow,
          hasStrategic: formData.hasStrategic,
          inventoryitemCustomCategoryId: formData.inventoryitemCustomCategory,
        },
      },
    })
      .then(() => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: `${inventoryitem?.label} added to Production Forecaster`,
          open: true,
        });
        handleProductionForecasterModalClose();
      })
      .catch(err => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: `${err}`,
          open: true,
        });
      });
  };

  const categories = useMemo(
    () =>
      data?.viewer?.inventoryitemCustomCategoryConnection.edges?.map(
        inventoryitemCustomCategory => ({
          value: inventoryitemCustomCategory.node.inventoryitemCustomCategoryId,
          label: inventoryitemCustomCategory.node.label,
        }),
      ),
    [data],
  );

  return (
    <Modal
      handleClose={handleProductionForecasterModalClose}
      open={isProductionForecasterModalOpen}
      title={
        inventoryitem?.inventoryitemPrep
          ? `Edit ${inventoryitem?.label} Production Forecaster data`
          : `Add ${inventoryitem?.label} to Production Forecaster`
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Box style={{ marginBottom: '12px' }}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register({ required: 'true' })}
                fullWidth
                autoFocus
                label="Size"
                type="number"
                error={!!errors.size}
                helperText={!!errors.size ? 'Size is required' : ''}
                name="size"
                variant="outlined"
                placeholder="Size"
                defaultValue={inventoryitem?.inventoryitemPrep?.size}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register({ required: 'true' })}
                fullWidth
                autoFocus
                label="Format"
                error={!!errors.format}
                helperText={!!errors.format ? 'Format is required' : ''}
                name="format"
                variant="outlined"
                placeholder="Format"
                defaultValue={inventoryitem?.inventoryitemPrep?.format}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register({ required: 'true' })}
                fullWidth
                autoFocus
                type="number"
                label="CSPM (%)"
                error={!!errors.cspm}
                helperText={!!errors.cspm ? 'CSPM is required' : ''}
                name="cspm"
                variant="outlined"
                placeholder="CSPM (%)"
                defaultValue={inventoryitem?.inventoryitemPrep?.cspm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectMUI
                items={categories}
                id={'inventoryitemCustomCategory'}
                label={'Select category'}
                defaultValue={
                  inventoryitem?.inventoryitemCustomCategory?.inventoryitemCustomCategoryId ||
                  categories?.[0]?.value
                }
                variant="outlined"
                control={control}
                width={'100%'}
              />
            </Grid>
            <Grid item xs={12} sm={6} container>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isPartOfClosingInventory"
                      inputRef={register}
                      onChange={() => setDisplaySizeCooked(prevState => !prevState)}
                      checked={displaySizeCooked}
                    />
                  }
                  label="Is Part Of Closing Inventory"
                />
              </Grid>
              {displaySizeCooked && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputRef={register}
                    fullWidth
                    autoFocus
                    label="Size Cooked"
                    name="sizeCooked"
                    variant="outlined"
                    placeholder="Size Cooked"
                    defaultValue={inventoryitem?.inventoryitemPrep?.sizeCooked}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6} container>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={register}
                      name="smokeTodayForTomorrow"
                      defaultChecked={inventoryitem?.inventoryitemPrep?.smokeTodayForTomorrow}
                    />
                  }
                  label="Smoke Today For Tomorrow"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={register}
                      name="hasStrategic"
                      defaultChecked={inventoryitem?.inventoryitemPrep?.hasStrategic}
                    />
                  }
                  label="Has Strategic"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleProductionForecasterModalClose}
            style={{ marginRight: '8px' }}
            variant="outlined"
          >
            Cancel
          </Button>
          {inventoryitem?.inventoryitemPrep?.id && (
            <Button
              disabled={loadingRemove}
              style={{ marginRight: '8px' }}
              onClick={removeInventoryitem}
            >
              Remove
            </Button>
          )}
          <Button type="submit" disabled={loading}>
            {inventoryitem?.inventoryitemPrep?.id ? 'Update' : 'Save'}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ProductionForecasterSettings;
