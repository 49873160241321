import React, { useCallback, useState } from 'react';
import { USERS_BY_USERGROUP } from '../../../../../../apollo/queries';
import DELETE_USERGROUP_USER from '../../../../../../apollo/mutations/usergroups/deleteUsergroupUser';
import { useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { useMutation } from '@apollo/client';
import { Modal } from '../../../../../blocks';
import FullEmployeeList from '../FullUserList';
import UsergroupStyles from '../styles';
import CreateEmployeeForm from '../../../../Labor/Employees/CreateEmployeeForm';
import { SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const UserList = () => {
  const { usergroupId } = useParams();
  const { setSnackbar } = useSnackbar();

  const [deleteUsergroupUser, { loading: isDeletingUser }] = useMutation(DELETE_USERGROUP_USER);
  const [tableState, setTableState] = useState();
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const classes = UsergroupStyles();

  const handleCloseCreateUserModal = () => {
    setShowCreateUserModal(false);
  };

  const handleCloseEditUserModal = () => {
    setShowAddUserModal(false);
  };

  const handleAddUser = (query, data) => {
    setTableState(query);
    setShowAddUserModal(true);
  };

  const handleDeleteUser = useCallback(
    (row, variables) => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Deleting user=...',
        open: true,
      });

      deleteUsergroupUser({
        variables: {
          usergroupId,
          loginId: row.loginId,
        },
        refetchQueries: [{ query: USERS_BY_USERGROUP, variables }],
      })
        .then(() =>
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'User deleted',
          }),
        )
        .catch(error =>
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
          }),
        );
    },
    [deleteUsergroupUser, usergroupId, setSnackbar],
  );

  return (
    <>
      <ConnectionTable
        query={USERS_BY_USERGROUP}
        initialQueryVariables={{
          filter: [
            {
              usergroupIds: {
                in: [usergroupId],
              },
            },
          ],
          first: 15,
        }}
        title="Users"
        customActionsTop={(data, query) => (
          <div>
            <Button onClick={() => handleAddUser(query, data)}>Assign users</Button>
          </div>
        )}
        structureTable={data =>
          data?.viewer?.loginConnection?.edges.map(user => ({
            id: user.node?.id,
            loginId: user.node?.loginId,
            email: user.node?.email,
            person: user.node?.person,
            phone: user.node?.person?.phone,
          }))
        }
        filterByDate={false}
        selectableRows={false}
        columns={[
          { title: 'Id', field: 'loginId' },
          {
            title: 'Full name',
            field: 'person',
            render: person =>
              person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : '/',
          },
          {
            title: 'Phone',
            field: 'phone',
            render: phone => phone?.phone || '/',
          },
          { title: 'Email', field: 'email' },
          {
            title: 'Status',
            field: 'inactive',
            render: inactive =>
              inactive ? (
                <span className={classes.inactive}>Inactive</span>
              ) : (
                <span className={classes.active}>Active</span>
              ),
          },
          // { title: 'Location', field: 'Location' }, TODO: does location column make sense here?
        ]}
        handleDeleteRow={handleDeleteUser}
        isDeleting={isDeletingUser}
        useConfirmationModalForDelete
        deleteConfirmationText="Are you sure you want to delete this user?"
      />
      <Modal open={showAddUserModal} handleClose={handleCloseEditUserModal}>
        <FullEmployeeList
          handleCloseModal={handleCloseEditUserModal}
          usergroupId={usergroupId}
          tableState={tableState}
        />
      </Modal>

      <Modal open={showCreateUserModal} handleClose={handleCloseCreateUserModal}>
        <CreateEmployeeForm handleCloseModal={handleCloseCreateUserModal} />
      </Modal>
    </>
  );
};

export default UserList;
