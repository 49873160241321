import { useQuery } from "@apollo/client";
import { Chip, CircularProgress } from "@material-ui/core";
import React from "react";
import LIST_CATEGORIES_SUBCATEGORIES from "../../../../../../../../apollo/queries/categorySubcategoryList";
import { uglifyId } from "../../../../../../../../utils";

function SelectSubcategoriesNew({
  discountDetailsItems,
  setDiscountDetailsItems,
  coupon,
}) {
  const itemFilter = {
    subcategoryId: {
      in: coupon?.couponActionConnection?.edges.map((edge) => edge.node.target),
    },
  };
  const { data, loading, error } = useQuery(LIST_CATEGORIES_SUBCATEGORIES, {
    variables: {
      language: "english",
      filter: {
        ...itemFilter,
      },
    },
    onCompleted: (data) => {
      const itemList = data.viewer.subcategoryConnection.edges.map((edge) => {
        return {
          key: edge.node.id,
          id: edge.node.id,
          itemId: edge.node.subcategoryId,
          label: `${edge.node.label} -  ID_${edge.node.subcategoryId}`,
        };
      });
      const myItems = coupon?.couponActionConnection?.edges.map((edge) =>
        uglifyId("Subcategory", edge.node.target || null)
      );
      const temp_preSelectedItems = itemList
        .filter((item) => myItems.includes(item.id))
        .map((item) => ({
          label: item.label,
          id: item.id,
          key: item.key,
          isItem: true,
        }));
      setDiscountDetailsItems(temp_preSelectedItems);
    },
  });

  return (
    <div>
      <h3>Selected Subcategories</h3>
      <div>
        {loading ? (
          <CircularProgress />
        ) : (
          discountDetailsItems.map((item) => (
            <Chip
              key={item.id}
              style={{ margin: "4px" }}
              label={item.label}
              onDelete={(e) =>
                setDiscountDetailsItems(
                  discountDetailsItems.filter((_item) => _item.id !== item.id)
                )
              }
            />
          ))
        )}
      </div>
    </div>
  );
}

export default SelectSubcategoriesNew;
