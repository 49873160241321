import React, { useMemo, useCallback } from 'react';

import {
  TableBody,
  TableRow,
  Typography,
  TableCell,
  Grid,
} from '@material-ui/core';
import { ErrorOutlineOutlined } from '@material-ui/icons';

import ConnectionTableRow from './ConnectionTableRow';
import { connectionTableBodyStyles } from './style';
import { isEmpty } from 'lodash';

const createEmptyArray = (length) => {
  let emptyArray = [];
  for (let i = 0; i < length; i++) {
    emptyArray.push(i);
  }

  return emptyArray;
};

const ConnectionTableBody = ({
  onTableRowClick,
  isSelected,
  tableData,
  loading,
  handleClick,
  stopPropagationLogic,
  selectableRows,
  columns,
  variables,
  data,
  isCheckbox,
  handleDeleteRow,
  handleEditRow,
  collapsableRows,
  refetch,
  permissions,
  searchValue,
}) => {
  const styles = connectionTableBodyStyles();

  const checkSearchValueMatch = useCallback(
    (row) => {
      let renderRow = false;
      columns.forEach((column) => {
        if (column.render) {
          const cellValue = column.render(row[column.field], variables, row);
          if (
            typeof cellValue === 'string' &&
            cellValue.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            renderRow = true;
          }
        } else {
          if (
            !row[column.field] ||
            row[column.field]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            renderRow = true;
          }
        }
      });
      return renderRow;
    },
    [columns, searchValue, variables]
  );

  const content = useMemo(() => {
    if (loading) {
      return createEmptyArray(variables?.first || 10).map((index) => (
        <ConnectionTableRow
          index={index}
          styles={styles}
          columns={columns}
          isCheckbox={isCheckbox}
          loading
          key={index}
        />
      ));
    }

    if (!isEmpty(tableData)) {
      return tableData?.map((row, index) => {
        let shouldRenderRow = true;
        if (searchValue) {
          shouldRenderRow = checkSearchValueMatch(row, columns);
        }

        if (shouldRenderRow) {
          const isItemSelected = isSelected(row);
          return (
            <ConnectionTableRow
              index={index}
              onTableRowClick={onTableRowClick}
              styles={styles}
              row={row}
              data={data}
              variables={variables}
              columns={columns}
              selectableRows={selectableRows}
              isCheckbox={isCheckbox}
              isItemSelected={isItemSelected}
              handleClick={handleClick}
              refetch={refetch}
              permissions={permissions}
              stopPropagationLogic={stopPropagationLogic}
              handleDeleteRow={handleDeleteRow}
              handleEditRow={handleEditRow}
              collapsableRows={collapsableRows}
              key={index}
            />
          );
        }
      });
    } else {
      return (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Grid
              container
              spacing={3}
              justify="center"
              alignItems="center"
              style={{ padding: '4rem 0' }}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary"
                  style={{ fontSize: '20px', fontWeight: 'bold' }}
                >
                  No data available
                </Typography>
              </Grid>
              <Grid item style={{ height: 'min-content' }}>
                <ErrorOutlineOutlined color="error" fontSize="large" />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    }
  }, [
    loading,
    tableData,
    searchValue,
    checkSearchValueMatch,
    collapsableRows,
    columns,
    data,
    handleClick,
    handleDeleteRow,
    handleEditRow,
    isCheckbox,
    isSelected,
    onTableRowClick,
    permissions,
    refetch,
    selectableRows,
    stopPropagationLogic,
    styles,
    variables,
  ]);

  return <TableBody>{content}</TableBody>;
};

export default ConnectionTableBody;
