import { Typography } from '@material-ui/core';

export const statusOptions = [
  { label: 'Active', value: 1, color: '#37C78A' },
  { label: 'Inactive', value: 0, color: '#FF9900' },
];

export function renderStatus(active) {
  return active ? (
    <Typography style={{ color: '#37C78A', fontWeight: 'bold' }} variant="body1">
      Active
    </Typography>
  ) : (
    <Typography style={{ color: '#FF9900', fontWeight: 'bold' }} variant="body1">
      Inactive
    </Typography>
  );
}
