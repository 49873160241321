import { gql } from '@apollo/client';

export const UPDATE_LOYALTY_EVENT = gql`
  mutation updateLoyaltyevent(
    $input: UpdateLoyaltyeventBrandMutationInput!
    $after: String
    $first: Int
    $before: String
    $last: Int
    $sort: [LoyaltyeventSort]
    $filter: [LoyaltyeventFilter]
    $search: String
  ) {
    updateLoyaltyeventBrand(input: $input) {
      viewer {
        id
        loyaltyeventConnection(
          after: $after
          first: $first
          before: $before
          last: $last
          sort: $sort
          filter: $filter
          search: $search
        ) {
          totalCount
          edges {
            node {
              id
              loyaltyeventId
              label
              description
              loyaltyeventBrandConnection {
                edges {
                  node {
                    id
                    brand {
                      brandId
                      label
                    }
                    points
                    bonusPoints
                    coupon {
                      couponId
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
