import { gql } from "@apollo/client";
import { viewerFragment } from "../../fragments";

const UPDATE_QUOTE_CATERING = gql`
  mutation UpdateQuoteCatering($input: UpdateQuotecateringNDMutationInput!) {
    updateQuoteCateringND(input: $input) {
      viewer {
        ...ViewerData
      }
      quote {
        id
      }
    }
  }
  ${viewerFragment.viewer}
`;

export default UPDATE_QUOTE_CATERING;
