import React, { useContext, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { groupBy } from 'lodash';

import LocationListView from './View';

import { LOCATIONS, FETCH_BRANDS, FETCH_STATES } from '../../../../../apollo/queries';
import { TOGGLE_LOCATION } from '../../../../../apollo/mutations/locations';
import { paths } from '../../../../../constants';
import { CoreContext } from '../../../../../CoreContext';
import { useFormSubmitPermissions } from '../../../../../hooks';
import { MUTATION_NAME } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import FETCH_MENUS from '../../../../../apollo/queries/menus/fetchMenus';

const LocationList = () => {
  const context = useContext(CoreContext);
  const { setSnackbar } = useSnackbar();

  const mutationPermissions = useMemo(() => context?.coreUtils?.getAppActions()[13]?.mutations, [
    context?.coreUtils,
  ]);

  const [toggleLocation] = useMutation(TOGGLE_LOCATION);

  const { data: brandData } = useQuery(FETCH_BRANDS);

  const { data: menuData, loading: menuLoading, error: menuError } = useQuery(FETCH_MENUS);

  const { data: stateData, loading: stateLoading, error: stateError } = useQuery(FETCH_STATES);

  const [permissions, setPermissions] = useState();

  const { accessToMutations } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.ToggleLocationMutationInput,
      MUTATION_NAME.CreateLocationMutationInput,
    ],
    permissions,
  });

  const history = useHistory();

  const handleLocationActivation = (active, { id }) => {
    toggleLocation({
      variables: {
        input: {
          id,
        },
      },
    })
      .then(() =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Location is updated.',
        }),
      )
      .catch(error =>
        setSnackbar({
          open: true,
          type: 'error',
          text: error.message,
        }),
      );
  };

  const createNewLocation = () => history.push(paths.LOCATION_NEW);
  const bulkEditLocations = selected =>
    selected.length === 1
      ? history.push(
          paths.LOCATION.replace(':locationId', atob(selected[0])?.split(':')?.[1]).replace(
            ':currentTab?',
            'generalInfo',
          ),
        )
      : history.push({
          pathname: paths.LOCATIONS_BULK_EDIT,
          state: { selected },
        });

  const stateList = useMemo(
    () =>
      stateData?.viewer?.stateConnection?.edges?.map(state => ({
        label: state.node.label,
        value: state.node.id,
      })),
    [stateData?.viewer?.stateConnection?.edges],
  );

  return (
    <>
      <LocationListView
        query={LOCATIONS}
        menuData={menuData}
        brands={brandData}
        handleLocationActivation={handleLocationActivation}
        createNewLocation={createNewLocation}
        bulkEditLocations={bulkEditLocations}
        mutationPermissions={mutationPermissions}
        history={history}
        stateList={stateList}
        setPermissions={setPermissions}
        permissions={permissions}
        accessToMutations={accessToMutations}
      />
    </>
  );
};

export default LocationList;
