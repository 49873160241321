import { gql } from '@apollo/client';

const CREATE_CHOICE = gql`
  mutation CreateChoice($input: CreateChoiceMutationInput!) {
    createChoice(input: $input) {
      viewer {
        choiceConnection {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              choiceId
              label
              description
            }
          }
        }
      }
    }
  }
`;

export default CREATE_CHOICE;
