import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    '&.MuiPaper-root': {
      backgroundColor: theme.colors.boxBackground,
      boxShadow: theme.boxShadowBase,
      borderRadius: 0,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      width: '100%',
      zIndex: 1,

      [theme.breakpoints.down('xs')]: {
        backgroundColor: theme.colors.boxBackground,
        borderRadius: 0,
        marginBottom: theme.spacing(3),
        width: '100%',
        zIndex: 1,
        boxShadow: 'none',
        margin: '-16px',
        width: 'calc(100% + 32px)',
        padding: '16px',
        height: 'calc(100vh - 100px)',
      },
    },
  },
  buttonsContainer: {
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      bottom: '-16px',
      zIndex: 1200,
      position: 'sticky',
      background: 'white',
      borderTop: '2px solid #D3D3DB',
      margin: '0 -16px',
      padding: '8px 4px 4px',
      width: 'calc(100% + 32px)',
    },
  },
  button: {
    minWidth: '150px',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
