import React from 'react';
import { Divider, Typography, makeStyles, Box, Hidden } from '@material-ui/core';
import InventoryItem from './InventoryItem';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '600px',
    background: 'white',
    minHeight: '100%',
  },
}));

const InventoryCountForm = ({
  storageArea,
  fields,
  register,
  setValue,
  control,
  errors,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Typography align="center" variant="h3" style={{ marginBottom: '16px' }}>
          {storageArea.customLabel || storageArea.storage?.label}
        </Typography>
        {fields.map((inventoryItem, index) => (
          <InventoryItem
            index={index}
            key={inventoryItem.id}
            inventoryItem={inventoryItem}
            id={inventoryItem.id}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            disabled={disabled}
            measures={inventoryItem.measures}
          />
        ))}
        <Hidden xsDown>
          <Divider />
        </Hidden>
      </Box>
    </>
  );
};

export default InventoryCountForm;
