import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import ProductionForecasterStyles from '../../View/styles.js';
import { toFixed10 } from '../../../../../../../utils';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

const OrderingForecasterCategoryView = ({
  refetch,
  forecastedOrderingPar,
  prepCommitments,
  variables,
}) => {
  const classes = ProductionForecasterStyles();

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        className={classes.titlePadding}
        id="orderingForecasterCategory"
      >
        Ordering Forecaster Category
      </Typography>
      <Grid container className={classes.heading}>
        <Grid item xs={12} className={classes.flexDirection}>
          <div className={classes.contentInfoHolder}>
            {`The following forecasted ordering pars, based on the weighted average of your last
                  4 weeks of sales, is currently trending ${(prepCommitments * 100).toFixed(2)}% ${
              prepCommitments >= 0 ? 'above' : 'below'
            } your sales commitment.`}
          </div>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Grid container className={classes.heading}>
          <Grid item xs={4} className={classes.flexDirection}>
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              format="LL/dd/yyyy"
              style={{ width: '230px' }}
              margin="normal"
              label="Ordering Usage Start Date"
              autoOk={true}
              onChange={date => {
                refetch({
                  ...variables,
                  orderingForecasterStart: date.toFormat('yyyy-LL-dd'),
                  orderingForecasterDate: date.toFormat('yyyy-LL-dd'),
                });
              }}
              value={variables?.orderingForecasterStart || DateTime.local()}
            />
          </Grid>
          <Grid item xs={4} className={classes.flexDirection}>
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              format="LL/dd/yyyy"
              margin="normal"
              style={{ width: '230px' }}
              label="Ordering Usage End Date"
              autoOk={true}
              initialFocusedDate={DateTime.local()}
              onChange={date => {
                refetch({
                  ...variables,
                  orderingForecasterEnd: date.toFormat('yyyy-LL-dd'),
                });
              }}
              value={variables?.orderingForecasterEnd || DateTime.local()}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid container className={classes.heading}>
        <Grid container item className={classes.heading}>
          <Grid item xs={3} className={classes.flexDirection}>
            <div className={classes.contentInfoHolder}></div>
          </Grid>
          <Grid item xs={3} className={classes.flexDirection}>
            <div className={classes.contentInfoHolder}>Weight</div>
          </Grid>
          <Grid item xs={3} className={classes.flexDirection}>
            <div className={classes.contentInfoHolder}>No. Cases</div>
          </Grid>
          <Grid item xs={3} className={classes.flexDirection} style={{ paddingLeft: '1rem' }}>
            <div className={classes.contentInfoHolder}>Case Pack Information</div>
          </Grid>
          <Grid container item style={{ display: 'flex' }}>
            <Grid
              container
              item
              xs={9}
              style={{
                border: '1px solid #d3d3db',
                borderRadius: '10px',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                boxShadow: '#d3d3db 0px 1px 3px 0',
              }}
            >
              {forecastedOrderingPar.proteins.map(proteinFOP => (
                <React.Fragment key={proteinFOP.protein.id}>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ paddingLeft: '1rem' }}
                  >
                    <div className={classes.contentInfoHolder}>{proteinFOP.protein.label}</div>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDirection}>
                    <div className={classes.contentInfoHolder}>
                      <b>{toFixed10(proteinFOP.weight, 2)}</b>
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDirection}>
                    <div className={classes.contentInfoHolder}>
                      <b>{toFixed10(proteinFOP.numberOfCases, 2)}</b>
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Grid
              container
              item
              xs={3}
              style={{ paddingTop: '1rem', paddingLeft: '1rem', paddingBottom: '1rem' }}
            >
              {forecastedOrderingPar.proteins.map(proteinFOP => (
                <Grid item xs={12} className={classes.flexDirection} key={proteinFOP.protein.id}>
                  <div className={classes.contentInfoHolder}>
                    <b>
                      {proteinFOP.casePackInfo
                        ? `${toFixed10(proteinFOP.casePackInfo, 2)} lb case average`
                        : 'Case average unavailable'}
                    </b>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Divider style={{ width: '90%' }} />
          <Grid container item style={{ display: 'flex' }}>
            <Grid
              container
              item
              xs={9}
              style={{
                border: '1px solid #d3d3db',
                borderRadius: '10px',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                boxShadow: '#d3d3db 0px 1px 3px 0',
              }}
            >
              {forecastedOrderingPar.sides.map(sideFOP => (
                <React.Fragment key={sideFOP.side.id}>
                  <Grid
                    item
                    xs={4}
                    className={classes.flexDirection}
                    style={{ paddingLeft: '1rem' }}
                  >
                    <div className={classes.contentInfoHolder}>{sideFOP.side.label}</div>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDirection}>
                    <div className={classes.contentInfoHolder}>
                      <b>{toFixed10(sideFOP.weight, 2)}</b>
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.flexDirection}>
                    <div className={classes.contentInfoHolder}>
                      <b>{toFixed10(sideFOP.numberOfCases, 2)}</b>
                    </div>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Grid
              container
              item
              xs={3}
              style={{ paddingTop: '1rem', paddingLeft: '1rem', paddingBottom: '1rem' }}
            >
              {forecastedOrderingPar.sides.map(sideFOP => (
                <Grid item xs={12} className={classes.flexDirection} key={sideFOP.side.id}>
                  <div className={classes.contentInfoHolder}>
                    <b>
                      {sideFOP.casePackInfo
                        ? `${toFixed10(sideFOP.casePackInfo, 2)} lb case average`
                        : 'Case average unavailable'}
                    </b>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.flexDirection} style={{ marginTop: '-1.5rem' }}>
          <div className={classes.contentInfoHolder}>
            *Ordering forecaster does not take into account the Catering orders
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderingForecasterCategoryView;
