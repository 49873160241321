import { Button, Divider, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DateTime from 'luxon/src/datetime';
import Interval from 'luxon/src/interval.js';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { CREATE_SCHEDULE, UPDATE_SCHEDULE, REMOVE_SCHEDULE } from '../../../../../apollo/mutations';
import ModifyScheduleView from './View';
import { ModifyScheduleStyles } from './View/styles';
import moment from 'moment';
import Permission from '../../../../blocks/Permission';
import { MUTATION_NAME } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const CreateEmployeeSchedule = ({
  disabled,
  createScheduleData,
  editScheduleData,
  refetchScheduleVariables,
  setIsVisibleState,
  locationTimezone,
  schedulePermissions,
  mutationPermissions,
  timelineVisibleDate,
  setVisibleDayStart,
  selectedWeek,
}) => {
  const classes = ModifyScheduleStyles();
  const { setSnackbar } = useSnackbar();

  const createSchedulePermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'createSchedule',
  );
  const updateSchedulePermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'updateSchedule',
  );
  const removeSchedulePermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'removeSchedule',
  );

  const { handleSubmit, register, control, errors, setValue } = useForm();
  const [totalTimeshiftsState, setTotalTimeshiftsState] = useState({ timeshiftIds: [0] });

  const [createScheduleMutation, { loading: createScheduleMutationLoading }] = useMutation(
    CREATE_SCHEDULE,
    {
      onCompleted: () => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Schedule created successfully',
        });
      },
    },
  );

  const [updateScheduleMutation, { loading: updateScheduleMutationLoading }] = useMutation(
    UPDATE_SCHEDULE,
    {
      update: (cache, data) => {
        cache.modify({
          id: `Viewer:${createScheduleData?.viewer?.id}`,
          fields: {
            [`scheduleConnection("filter": ${refetchScheduleVariables.scheduleFilter})`]: existingScheduleConnection => {
              const newUpdatedScheduleConnection = [...existingScheduleConnection.edges];

              newUpdatedScheduleConnection.unshift(data?.data?.updateSchedule?.scheduleConnection);
              newUpdatedScheduleConnection.pop();

              return {
                ...existingScheduleConnection,
                totalCount: existingScheduleConnection.totalCount,
                edges: newUpdatedScheduleConnection,
              };
            },
          },
        });
      },
      onCompleted: () => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Schedule updated successfully',
        });
      },
    },
  );

  const [removeScheduleMutation, { loading: removeScheduleMutationLoading }] = useMutation(
    REMOVE_SCHEDULE,
    {
      onCompleted: () => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Schedule removed successfully',
        });
      },
    },
  );

  let employee = undefined;
  let defaultValues = undefined;
  let submitFormFunction = undefined;
  let addDeleteButtonLabel = undefined;
  let addRemoveScheduleButtonFunction = undefined;

  const dateFormatString = 'ccc LLL dd y hh:mm:ss';
  if (editScheduleData) {
    submitFormFunction = updateSchedule;
    addDeleteButtonLabel = 'Delete Timeshift';
    addRemoveScheduleButtonFunction = deleteTimeshift;
    employee = editScheduleData.person;
    // Extract hours minutes meridiem
    const dateIn = DateTime.fromSQL(editScheduleData.started, { zone: 'utc' }).setZone(
      locationTimezone,
    );
    const dateOut = DateTime.fromSQL(editScheduleData.finished, { zone: 'utc' }).setZone(
      locationTimezone,
    );

    const timeIn = dateIn.toFormat('h:m:a').split(':');
    const timeOut = dateOut.toFormat('h:m:a').split(':');

    defaultValues = {
      dateIn: dateIn.toFormat(dateFormatString),
      dateOut: dateOut.toFormat(dateFormatString),
      isDelivery: editScheduleData.delivery,
      selectedJob: editScheduleData.jobId,
      timeInHours: timeIn[0],
      timeInMinutes: timeIn[1],
      timeInMeridiem: timeIn[2].toLowerCase(),
      timeOutHours: timeOut[0],
      timeOutMinutes: timeOut[1],
      timeOutMeridiem: timeOut[2].toLowerCase(),
    };
  } else {
    submitFormFunction = createSchedules;
    addDeleteButtonLabel = 'Add Timeshift';
    addRemoveScheduleButtonFunction = addTimeshift;
    employee = createScheduleData.employee;

    const weekInterval = Interval.fromDateTimes(
      createScheduleData.selectedWeek,
      createScheduleData.selectedWeek.plus({ days: 7 }),
    );

    // From moment to luxon
    const timelineVisibleDateLuxon = DateTime.fromISO(timelineVisibleDate.toISOString());

    defaultValues = {
      dateIn: weekInterval.contains(timelineVisibleDateLuxon)
        ? timelineVisibleDateLuxon.toFormat(dateFormatString)
        : createScheduleData.selectedWeek.toFormat(dateFormatString),
      dateOut: weekInterval.contains(timelineVisibleDateLuxon)
        ? timelineVisibleDateLuxon.toFormat(dateFormatString)
        : createScheduleData.selectedWeek.toFormat(dateFormatString),
      isDelivery: false,
      selectedJob: undefined,
      timeInHours: '6',
      timeInMeridiem: 'am',
      timeInMinutes: '0',
      timeOutHours: '7',
      timeOutMinutes: '0',
      timeOutMeridiem: 'pm',
    };
  }
  const [timeshiftFormValues, setTimeshiftFormValues] = useState({
    0: {
      ...defaultValues,
    },
  });

  const onDateChange = (event, keyValue, inputName) => {
    const timeshiftFormValuesForKey = timeshiftFormValues[keyValue];
    timeshiftFormValuesForKey[inputName] = event;

    if (inputName === 'dateIn') {
      timeshiftFormValuesForKey['dateOut'] = event;
      setValue(`employeeSchedule.${keyValue}.dateOut`, event);
    }

    setTimeshiftFormValues({
      ...timeshiftFormValues,
      [keyValue]: { ...timeshiftFormValuesForKey },
    });
  };

  const onHoursChange = (event, keyValue, inputName) => {
    const timeshiftFormValuesForKey = timeshiftFormValues[keyValue];
    timeshiftFormValuesForKey[inputName] = event.target.value;
    setTimeshiftFormValues({
      ...timeshiftFormValues,
      [keyValue]: { ...timeshiftFormValuesForKey },
    });
  };
  const onMinutesChange = (event, keyValue, inputName) => {
    const timeshiftFormValuesForKey = timeshiftFormValues[keyValue];
    timeshiftFormValuesForKey[inputName] = event.target.value;
    setTimeshiftFormValues({
      ...timeshiftFormValues,
      [keyValue]: { ...timeshiftFormValuesForKey },
    });
  };

  const onSelectedJobChange = (event, keyValue, inputName) => {
    const timeshiftFormValuesForKey = timeshiftFormValues[keyValue];
    timeshiftFormValuesForKey[inputName] = event.target.value;
    setTimeshiftFormValues({
      ...timeshiftFormValues,
      [keyValue]: { ...timeshiftFormValuesForKey },
    });
  };

  const calculateTotalHours = key => {
    const timeshiftFormValue = timeshiftFormValues[key];
    if (timeshiftFormValue) {
      const timeInHours = timeshiftFormValue.timeInHours;
      const timeInMinutes = timeshiftFormValue.timeInMinutes;
      const timeInMeridiem = timeshiftFormValue.timeInMeridiem;
      const timeOutHours = timeshiftFormValue.timeOutHours;
      const timeOutMinutes = timeshiftFormValue.timeOutMinutes;
      const timeOutMeridiem = timeshiftFormValue.timeOutMeridiem;

      const timeIn = moment(`${timeInHours}:${timeInMinutes} ${timeInMeridiem}`, ['HH:m A']);
      const timeOut = moment(`${timeOutHours}:${timeOutMinutes} ${timeOutMeridiem}`, ['HH:m A']);

      let test = moment.duration(timeOut.diff(timeIn)).asMilliseconds();
      return moment
        .utc(test)
        .format('HH:mm')
        .toString();
    }
  };

  function createSchedules(event) {
    const { locationId, auvProjectionId } = createScheduleData;
    event.employeeSchedule.forEach(async elem => {
      // Cant get to format with luxon
      const dateIn = moment(elem.dateIn).format('yyyy-MM-DD');
      const dateOut = moment(elem.dateOut).format('yyyy-MM-DD');
      const data = {
        locationId: locationId,
        personId: employee.id,
        scheduleweeklysalesprojectionId: auvProjectionId,
        delivery: elem.isDelivery,
        jobId: elem.selectedJob,
        schedules: [
          {
            start: DateTime.fromFormat(
              `${dateIn} ${elem.timeInHours}:${elem.timeInMinutes} ${elem.timeInMeridiem}`,
              'yyyy-MM-dd h:m a',
              { zone: locationTimezone },
            )
              .toUTC()
              .toISO(),
            end: DateTime.fromFormat(
              `${dateOut} ${elem.timeOutHours}:${elem.timeOutMinutes} ${elem.timeOutMeridiem}`,
              'yyyy-MM-dd h:m a',
              { zone: locationTimezone },
            )
              .toUTC()
              .toISO(),
          },
        ],
      };
      await createScheduleMutation({
        variables: {
          ...refetchScheduleVariables,
          input: { ...data },
        },
      });
    });

    const earliestCreatedScheduleDate = event.employeeSchedule.sort((a, b) => {
      if (moment(a.dateIn).isSame(b.dateIn)) {
        return 0;
      }
      return moment(a.dateIn).isBefore(b.dateIn) ? -1 : 1;
    })[0].dateIn;

    const createScheduleMoment = moment(earliestCreatedScheduleDate);
    const createScheduleDate = DateTime.local(
      createScheduleMoment.year(),
      createScheduleMoment.month() + 1,
      createScheduleMoment.date(),
    );

    setVisibleDayStart(moment.tz(createScheduleDate.toISODate(), locationTimezone).startOf('day'));
    setIsVisibleState(false);
  }

  function addTimeshift() {
    const timeshiftIds = totalTimeshiftsState.timeshiftIds;
    const oldLastTimeshiftIndex = timeshiftIds[timeshiftIds.length - 1];
    const newTimeshiftIndex = timeshiftIds[timeshiftIds.length - 1] + 1;

    timeshiftIds.push(newTimeshiftIndex);

    const addedTimeshiftIncrementedDate = moment(timeshiftFormValues[oldLastTimeshiftIndex].dateIn)
      .add(1, 'days')
      .toISOString();

    setTotalTimeshiftsState({ timeshiftIds });
    setTimeshiftFormValues({
      ...timeshiftFormValues,
      [newTimeshiftIndex]: {
        ...defaultValues,
        dateIn: addedTimeshiftIncrementedDate,
        dateOut: addedTimeshiftIncrementedDate,
      },
    });
  }

  // Increment date for added timeshift
  useEffect(() => {
    const addedTimeshiftIndex = Math.max(
      ...Object.keys(timeshiftFormValues).map(val => Number(val)),
    );
    const addedTimeshiftIncrementedDateIn = timeshiftFormValues[addedTimeshiftIndex].dateIn;
    const addedTimeshiftIncrementedDateOut = timeshiftFormValues[addedTimeshiftIndex].dateOut;

    setValue(`employeeSchedule.${addedTimeshiftIndex}.dateIn`, addedTimeshiftIncrementedDateIn);
    setValue(`employeeSchedule.${addedTimeshiftIndex}.dateOut`, addedTimeshiftIncrementedDateOut);
  }, [timeshiftFormValues]);

  const removeTimeshiftForm = key => {
    if (totalTimeshiftsState.timeshiftIds.length > 1) {
      setTotalTimeshiftsState({
        timeshiftIds: totalTimeshiftsState.timeshiftIds.filter(id => id !== key),
      });

      const timeshiftFormValuesCopy = { ...timeshiftFormValues };
      delete timeshiftFormValuesCopy[key];
      setTimeshiftFormValues({ ...timeshiftFormValuesCopy });
    }
  };

  function updateSchedule(event) {
    let employeeSchedule = event.employeeSchedule[0];
    // Cant format with DateTime
    const dateIn = moment(employeeSchedule.dateIn).format('yyyy-MM-DD');
    const dateOut = moment(employeeSchedule.dateOut).format('yyyy-MM-DD');
    const data = {
      scheduleId: editScheduleData.id,
      start: DateTime.fromFormat(
        `${dateIn} ${employeeSchedule.timeInHours}:${employeeSchedule.timeInMinutes} ${employeeSchedule.timeInMeridiem}`,
        'yyyy-MM-dd h:m a',
        { zone: locationTimezone },
      )
        .toUTC()
        .toISO(),
      end: DateTime.fromFormat(
        `${dateOut} ${employeeSchedule.timeOutHours}:${employeeSchedule.timeOutMinutes} ${employeeSchedule.timeOutMeridiem}`,
        'yyyy-MM-dd h:m a',
        { zone: locationTimezone },
      )
        .toUTC()
        .toISO(),
      jobId: employeeSchedule.selectedJob,
      delivery: employeeSchedule.isDelivery,
    };
    updateScheduleMutation({
      variables: {
        ...refetchScheduleVariables,
        input: { ...data },
      },
    });

    const editedScheduleMoment = moment(event.employeeSchedule[0].dateIn);
    const editedScheduleDate = DateTime.local(
      editedScheduleMoment.year(),
      editedScheduleMoment.month() + 1,
      editedScheduleMoment.date(),
    );
    setVisibleDayStart(moment.tz(editedScheduleDate.toISODate(), locationTimezone).startOf('day'));
    setIsVisibleState(false);
  }

  function deleteTimeshift() {
    removeScheduleMutation({
      variables: {
        ...refetchScheduleVariables,
        input: {
          scheduleId: editScheduleData?.id,
        },
      },
    });
    setIsVisibleState(false);
  }

  const editTimeshiftIsDisabled = useMemo(() => {
    if (DateTime.local().startOf('day') > selectedWeek.plus({ days: 7 }).startOf('day')) {
      return true;
    }
    return false;
  }, [selectedWeek]);

  // Prevent adding timeshift outside of projection week
  const addTimeshiftIsDisabled = useCallback(() => {
    if (addDeleteButtonLabel !== 'Delete Timeshift') {
      const timeshiftIds = totalTimeshiftsState.timeshiftIds;
      const oldLastTimeshiftIndex = timeshiftIds[timeshiftIds.length - 1];

      const nextDayTimeshiftDate = moment(timeshiftFormValues[oldLastTimeshiftIndex].dateIn)
        .startOf('day')
        .add(1, 'days');
      const endOfScheduleWeekDate = moment(selectedWeek.plus({ days: 7 }).toISODate()).startOf(
        'day',
      );

      return nextDayTimeshiftDate >= endOfScheduleWeekDate;
    }

    return false;
  }, [totalTimeshiftsState, timeshiftFormValues, selectedWeek]);

  return (
    <>
      <form onSubmit={handleSubmit(event => submitFormFunction(event))} style={{ width: '100%' }}>
        {createScheduleData?.employee?.schedulePreference && (
          <>
            <Grid container flexDirection="column" className={classes.schedulePreference}>
              <Grid item xs={2}>
                <Typography variant="h6" component="h6">
                  Schedule Preference:
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2">
                  {createScheduleData?.employee?.schedulePreference}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </>
        )}

        {totalTimeshiftsState.timeshiftIds?.map(id => {
          return (
            <>
              <ModifyScheduleView
                keyValue={id}
                control={control}
                employee={employee}
                ref={register}
                defaultValues={defaultValues}
                removeTimeshiftFormLoading={removeScheduleMutationLoading}
                removeTimeshiftForm={removeTimeshiftForm}
                onDateChange={onDateChange}
                onHoursChange={onHoursChange}
                onMinutesChange={onMinutesChange}
                onSelectedJobChange={onSelectedJobChange}
                fromValues={timeshiftFormValues[id]}
                totalHoursForSchedule={calculateTotalHours}
                schedulePermissions={schedulePermissions}
                errors={errors}
                disabled={disabled}
                totalTimeshiftsNumber={totalTimeshiftsState.timeshiftIds?.length}
                selectedWeek={selectedWeek}
              />
              <Divider />
            </>
          );
        })}

        <Grid container className={classes.actions}>
          <Grid item xs={12}>
            <Permission
              access={Math.min(
                editScheduleData
                  ? (updateSchedulePermission?.access, MUTATION_NAME.UpdateScheduleMutationInput)
                  : (createSchedulePermission?.access, MUTATION_NAME.CreateScheduleMutationInput),
              )}
            >
              <Button
                type="submit"
                disabled={
                  createScheduleMutationLoading ||
                  updateScheduleMutationLoading ||
                  removeScheduleMutationLoading ||
                  disabled ||
                  editTimeshiftIsDisabled
                }
              >
                Save
              </Button>
            </Permission>
            <Permission
              access={Math.min(
                editScheduleData
                  ? removeSchedulePermission?.access
                  : (createSchedulePermission?.access, MUTATION_NAME.CreateScheduleMutationInput),
              )}
            >
              <Button
                disabled={
                  removeScheduleMutationLoading ||
                  disabled ||
                  editTimeshiftIsDisabled ||
                  addTimeshiftIsDisabled()
                }
                onClick={() => {
                  addRemoveScheduleButtonFunction();
                }}
              >
                {addDeleteButtonLabel}
              </Button>
            </Permission>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CreateEmployeeSchedule;
