import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { Checkbox, Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDrag, useDrop } from 'react-dnd';
import { Grid, CircularProgress } from '@material-ui/core';
import CurrencyInput from '../../../../../../../../blocks/CurrencyInput';
import { CurrencyInputFormat, convert } from '../../../../../../../../../utils';
import { useQuery } from '@apollo/client';
import FETCH_TIERS from '../../../../../../../../../apollo/queries/menus/fetchTiers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SetPricingMultiple, SetPricingCreateMultiple } from '../../../../../../../../blocks';

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  childrenContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  mainTextField: {
    width: '75%',
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  preparationTime: {
    marginRight: '0.5rem',
  },
});

const ItemChoice = ({
  choice,
  removeMultipleChoice,
  index,
  control,
  errors,
  getValues,
  setValue,
  moveChoiceItem,
  choices,
  multipleId,
  register,
  setMultipleChoiceValues,
  multipleChoiceValues,
  paramsMultipleId,
  setNewChoicesSetUp,
  newChoicesSetUp,
  refetch,
}) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [tiers, setTiers] = useState([]);

  const [, drop] = useDrop({
    accept: ['ChoiceItem'],
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveChoiceItem(dragIndex, hoverIndex, [choices[dragIndex], choices[hoverIndex]]);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'ChoiceItem', index, choiceId: choice.choiceId, id: choice.id, newChoice: false },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const { data, loading, error } = useQuery(FETCH_TIERS, {
    onCompleted: data1 =>
      setTiers(
        data1?.viewer?.tierConnection?.edges?.map(tier => ({
          id: tier?.node?.id,
          tierId: tier?.node?.tierId,
          label: tier?.node?.label,
          priceCap: tier?.node?.priceCap,
        })),
      ),
  });

  if (loading) {
    return (
      <div style={{ margin: '2rem auto', textAlign: 'center' }}>
        <CircularProgress />{' '}
      </div>
    );
  }

  if (error) console.log(error);

  return (
    <>
      <Grid container alignItems="baseline" spacing={3} ref={ref}>
        <Grid alignItems="center" item xs={3}>
          <IconButton style={{ paddingRight: '.5rem' }} aria-label="copy">
            <OpenWithIcon fontSize={'small'} />
          </IconButton>
          {choice?.label}
        </Grid>
        <Grid item xs={8}>
          <Grid style={{ justifyContent: 'space-between' }} container spacing={3}>
            <Grid item xs={3}>
              <TextField
                label="Preparation Time/(s)"
                variant={'outlined'}
                error={
                  errors[multipleId]?.choicesValues &&
                  Boolean(errors[multipleId]?.choicesValues[choice.id]?.preparationTime)
                }
                helperText={
                  errors[multipleId]?.choicesValues &&
                  Boolean(errors[multipleId]?.choicesValues[choice.id]?.preparationTime) &&
                  "Preparation time can't be negative or empty."
                }
                name={`${multipleId}.choicesValues.${choice.id}.preparationTime`}
                type={'number'}
                inputRef={register({
                  valueAsNumber: true,
                  required: true,
                  validate: {
                    positive: value => value >= 0,
                  },
                })}
                onChange={e =>
                  setMultipleChoiceValues(prevState => ({
                    ...prevState,
                    [`${choice.id}-preparationTime`]: e.target.value,
                  }))
                }
                defaultValue={
                  multipleChoiceValues[`${choice.id}-preparationTime`] ||
                  choice?.preparationTime ||
                  null
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Allowed Maximum"
                variant={'outlined'}
                error={
                  errors[multipleId]?.choicesValues &&
                  Boolean(errors[multipleId]?.choicesValues[choice.id]?.allowedMaximum)
                }
                helperText={
                  errors[multipleId]?.choicesValues &&
                  Boolean(errors[multipleId]?.choicesValues[choice.id]?.allowedMaximum) &&
                  "Allowed Maximum can't be negative."
                }
                name={`${multipleId}.choicesValues.${choice.id}.allowedMaximum`}
                type={'number'}
                inputRef={register({
                  valueAsNumber: true,
                  validate: {
                    positive: value => value >= 0,
                  },
                })}
                onBlur={e => {
                  setMultipleChoiceValues(prevState => ({
                    ...prevState,
                    [`${choice.id}-allowedMaximum`]: e.target.value,
                  }));
                }}
                defaultValue={
                  multipleChoiceValues[`${choice.id}-allowedMaximum`] ||
                  choice?.allowedMaximum ||
                  null
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={`${multipleId}.choicesValues.${choice.id}.defaultSelected`}
                    defaultChecked={choice?.defaultSelected}
                    inputRef={register}
                  />
                }
                label={<Typography style={{ fontSize: '16px' }}>Default value</Typography>}
              />
            </Grid>
            {paramsMultipleId === 'new-multiple' ? (
              <SetPricingCreateMultiple
                choiceId={choice.choiceId}
                choiceLabel={choice.label}
                multipleId={paramsMultipleId}
                gqlMultipleId={multipleId}
                gqlChoiceId={choice.id}
                setNewChoicesSetUp={setNewChoicesSetUp}
                newChoicesSetUp={newChoicesSetUp}
              />
            ) : (
              <SetPricingMultiple
                choiceId={choice.choiceId}
                choiceLabel={choice.label}
                multipleId={paramsMultipleId}
                gqlMultipleId={multipleId}
                gqlChoiceId={choice.id}
                refetch={refetch}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={1}>
          <IconButton
            aria-label="copy"
            onClick={() => removeMultipleChoice(choice.id, choice.multipleId)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
export default ItemChoice;
