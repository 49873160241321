import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import SET_ITEM_INGREDIENTS from '../../../../../../../../apollo/mutations/menus/setItemIngredients';
import { FETCH_ITEM_INGREDIENTS } from '../../../../../../../../apollo/queries';
import { SNACKBAR_STATUS } from '../../../../../../../../constants';
import { useSnackbar } from '../../../../../../../../hooks/useSnackbar';
import { formatData, formatNumberStringToFloat } from '../../../../../../helpers';
import RecipesTable from '../../../../RecipesTable';
import ItemChoiceIngredients from './ItemChoiceIngredients';

const ItemRecipes = ({ gqlItemId }) => {
  const { control, register, unregister, errors, setValue, getValues, handleSubmit } = useForm();
  const { setSnackbar } = useSnackbar();

  const { data: itemIngredientsData, loading, error } = useQuery(FETCH_ITEM_INGREDIENTS, {
    variables: {
      id: gqlItemId,
    },
  });

  const recipes = useMemo(
    () =>
      itemIngredientsData?.viewer?.item?.itemIngredientConnection?.edges?.map((edge, index) => {
        const inventoryItem = edge.node.ingredient || edge.node.recipe;

        return {
          inventoryitemId: inventoryItem?.inventoryitem?.inventoryitemId,
          label: inventoryItem?.inventoryitem?.label,
          index,
          quantity: edge.node.quantity,
          measureId: edge.node.measure?.measureId,
          measureOptions: inventoryItem?.inventoryitem?.inventoryitemMeasureConnection?.edges?.map(
            ingredient => ({
              value: ingredient.node.measure?.measureId,
              label: `${ingredient.node.measure?.label} (${ingredient.node.measure?.abbreviation})`,
            }),
          ),
        };
      }),
    [itemIngredientsData],
  );

  const [setItemRecipes] = useMutation(SET_ITEM_INGREDIENTS, {
    onCompleted: () =>
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Ingredients added.',
        open: true,
      }),
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
        open: true,
      }),
  });

  const onSubmit = useCallback(
    formData => {
      const recipes = formatData({
        formData,
        label: 'recipe',
        mapFunction: ({ inventoryitemId, measureId, quantity }) => ({
          ingredientId: inventoryitemId,
          measureId,
          quantity: formatNumberStringToFloat(quantity),
        }),
      });

      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Adding ingredients...',
        open: true,
      });

      setItemRecipes({
        variables: {
          input: {
            itemId: gqlItemId,
            ingredients: recipes,
          },
        },
      });
    },
    [gqlItemId, setItemRecipes],
  );

  return loading ? (
    <div>Loading...</div>
  ) : error ? (
    <div>Error</div>
  ) : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <RecipesTable
              setValue={setValue}
              unregister={unregister}
              control={control}
              getValues={getValues}
              register={register}
              errors={errors}
              inventoryitems={recipes}
            />
            <Button type="submit" variant="outlined">
              Save
            </Button>
          </form>
          <ItemChoiceIngredients />
        </div>
  );
};

export default ItemRecipes;
