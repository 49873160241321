import { isEmpty } from 'lodash-es';
import { convert, CurrencyInputFormat } from '../../../../../utils';
import { formatData, formatNumberStringToFloat } from '../../../helpers';

export const prepareFormData = ({
  formData,
  image,
  setShowInventoryItemsError,
  isSubmit,
  storeNumber,
  invoiceId,
  formatTotals = true,
  label = 'inventoryItem',
  mapFunction = ({ id, quantity, cost, ...rest }) => ({
    ...rest,
    cost: convert.dollarsToCents(CurrencyInputFormat(cost)),
    quantity: formatNumberStringToFloat(quantity),
  }),
}) => {
  const ingredients = formatData({
    formData,
    label,
    mapFunction,
  });

  if (isSubmit && isEmpty(ingredients)) {
    if (setShowInventoryItemsError) {
      setShowInventoryItemsError(true);
    }

    return;
  }

  if (setShowInventoryItemsError) {
    setShowInventoryItemsError(false);
  }

  const invoiceInfo = {
    invoiceId,
    storeNumber: invoiceId ? undefined : storeNumber,
    invoiceNumber: formData.invoiceNumber,
    supplierName: formData.supplierName,
    dateOfPurchase: formData.dateOfPurchase,
    subtotal: formatTotals ? CurrencyInputFormat(formData.subtotal) : formData.subtotal,
    tax: formatTotals ? CurrencyInputFormat(formData.tax) : formData.tax,
    total: formatTotals ? CurrencyInputFormat(formData.total) : formData.total,
  };

  return {
    isSubmit: isSubmit,
    invoiceInfo,
    ingredients,
    images: image
      ? [
          {
            encoded: image.encoded,
            url: image.url,
            name: image.name,
          },
        ]
      : [],
  };
};
