import { gql } from '@apollo/client';

const CREATE_SIMPLE_INVOICE = gql`
  mutation CREATE_SIMPLE_INVOICE($input: CreateSimpleInvoiceInput!) {
    createSimpleInvoice(input: $input) {
      viewer {
        id
      }
      invoice {
        isSubmitted
      }
    }
  }
`;

export default CREATE_SIMPLE_INVOICE;
