import { gql } from '@apollo/client';
import { colorFragment } from '../../fragments';

const LOCATIONS_BY_JOB = gql`
  query LocationsByJob(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [JobSort]
    $filter: [JobFilter]
  ) {
    viewer {
      id
      jobConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        fieldGroup: 59
      ) {
        totalCount
        availableFilters
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        permissions
        edges {
          node {
            color {
              ...ColorData
            }
            id
            label
            jobId
            posRole {
              posRoleId
              label
            }
            locationJobConnection(first: $first) {
              edges {
                node {
                  id
                  location {
                    id
                    label
                    storeNumber
                    locationId
                  }
                }
              }
            }
            orderKey
          }
        }
      }
    }
  }
  ${colorFragment.color}
`;

export default LOCATIONS_BY_JOB;
