import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
    buttonGroup: {
        margin: `${theme.spacing(2)}px 0`,

        '& .MuiButton-root': {
            margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        }
    }
});

  export const ReportsStyles = makeStyles(Styles);