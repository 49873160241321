import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Checkbox, Grid, IconButton, makeStyles } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem/TreeItem';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';
import TextField from '@material-ui/core/TextField/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDrag, useDrop } from 'react-dnd';
import { uglifyId, convert } from '../../../../../../../../../../utils';
import TextFieldInput from '../../../../../../../../../blocks/TextFieldInput';
import UncontrolledCurrencyInput from '../../../../../../../../../blocks/UncontrolledCurrencyInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useMutation } from '@apollo/client';
import TOGGLE_ITEM_MULTIPLE_OPTIONAL from '../../../../../../../../../../apollo/mutations/menus/toggleItemMultipleOptional';
import { SnackBar } from '../../../../../../../../../blocks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  rootOnDrag: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
    boxShadow: theme.shadows[5],
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  margin: {
    marginRight: '1rem',
    width: 200,
  },
  row: {
    alignItems: 'center',
    width: '62rem',
  },
  firsttTextField: {
    marginRight: '1rem',
    width: 580,
  },
  submargin: {
    marginRight: '2rem',
    width: 250,
  },
}));

const ItemChoice = ({
  item,
  itemId,
  register,
  setFormValue,
  getPath,
  deleteItemMultiple,
  itemData,
  index,
  moveChoiceItem,
  data,
  value,
  setValue,
  errors,
  control,
}) => {
  const [snackbar, setSnackbar] = useState({});
  const classes = useStyles();
  const ref = useRef(null);

  const [toggleItemMultipleOptional] = useMutation(TOGGLE_ITEM_MULTIPLE_OPTIONAL, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Optional is updated.',
      });
    },
  });

  const sortedItemChildren = useMemo(() => {
    return sortBy(item.children, 'order');
  }, [item.children]);

  const [, drop] = useDrop({
    accept: ['Multiple'],
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveChoiceItem(dragIndex, hoverIndex, [data[dragIndex], data[hoverIndex]]);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'Multiple', index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  // const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const submitOptional = e => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Optional is updating.',
    });
    toggleItemMultipleOptional({
      variables: {
        input: {
          itemId: uglifyId('Item', itemId),
          multipleId: item.id,
        },
      },
    });
  };

  useEffect(() => {
    setValue(prevState => ({
      ...prevState,
      [`${item.id}-requiredMinimum`]: item?.requiredMinimum,
      [`${item.id}-allowedMaximum`]: item?.allowedMaximum,
    }));
  }, []);

  const postive =
    Boolean(errors[item.id]?.multipleValues?.allowedMaximum.type === 'positive') &&
    "Allowed Maximum can't be negative.";
  const customValidation =
    Boolean(errors[item.id]?.multipleValues?.allowedMaximum.type === 'customValidation') &&
    "Allowed Maximum can't be negative.";
  return (
    <div className={isDragging ? classes.rootOnDrag : classes.root} ref={ref}>
      <TreeItem
        nodeId={item.id}
        key={item.id}
        style={{ marginBottom: '2.5rem' }}
        label={
          <Grid container spacing={2} onClick={e => e.stopPropagation()} className={classes.row}>
            <Grid item xs={1}>
              <div>
                <span style={{ fontSize: '1rem', marginRight: '0.5rem' }}>{item.title}</span>
                <Link style={{ fontSize: '1rem' }} to={getPath(item.multipleId)}>
                  ID-{item.multipleId}
                </Link>
              </div>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name={`${item.id}.multipleValues.onlineOrderingLabel`}
                value={value[`${item?.id}-onlineOrderingLabel`] || item?.onlineLabel}
                onChange={e =>
                  setValue(prevState => ({
                    ...prevState,
                    [`${item.id}-onlineOrderingLabel`]: e.target.value,
                  }))
                }
                label="Online Ordering Label"
                variant="outlined"
                inputRef={register}
                defaultValue={item?.onlineLabel || null}
                placeholder={'Enter online ordering label.'}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name={`${item.id}.multipleValues.requiredMinimum`}
                label="Required Minimum"
                type={'number'}
                value={value[`${item?.id}-requiredMinimum`]}
                onChange={e =>
                  setValue(prevState => ({
                    ...prevState,
                    [`${item.id}-requiredMinimum`]: e.target.value,
                  }))
                }
                fullWidth
                error={Boolean(errors[item.id]?.multipleValues?.requiredMinimum)}
                helperText={
                  (postive && "Allowed Maximum can't be negative.") ||
                  (customValidation && 'Minimum must be larger than maximum.')
                }
                inputRef={register({
                  valueAsNumber: true,
                  validate: {
                    positive: value => value >= 0,
                    customValidation: data => {
                      const reqMinimum = parseInt(data);
                      const reqMaximum = parseInt(value[`${item.id}-allowedMaximum`]);
                      if (!reqMinimum || !reqMaximum) {
                        return true;
                      }
                      return reqMinimum <= reqMaximum;
                    },
                  },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={'Enter value'}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name={`${item.id}.multipleValues.allowedMaximum`}
                type={'number'}
                value={value[`${item?.id}-allowedMaximum`]}
                onChange={e =>
                  setValue(prevState => ({
                    ...prevState,
                    [`${item.id}-allowedMaximum`]: e.target.value,
                  }))
                }
                inputRef={register({
                  valueAsNumber: true,
                  validate: {
                    positive: value => value >= 0,
                    customValidation: data => {
                      const reqMinimum = parseInt(value[`${item.id}-requiredMinimum`]);
                      const reqMaximum = parseInt(data);
                      if (!reqMinimum || !reqMaximum) {
                        return true;
                      }
                      return reqMinimum <= reqMaximum;
                    },
                  },
                })}
                error={Boolean(errors[item.id]?.multipleValues?.allowedMaximum)}
                helperText={
                  (postive && "Allowed Maximum can't be negative.") ||
                  (customValidation && 'Maximum must be lower than minimum.')
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Allowed Maximum"
                placeholder={'Enter value'}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox defaultChecked={item?.optional} />}
                onChange={e => {
                  submitOptional(e);
                }}
                label={<p style={{ color: '#A9A9A9' }}>Optional</p>}
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                style={{ padding: '0' }}
                aria-label="copy"
                onClick={e =>
                  deleteItemMultiple(
                    item.id,
                    itemData?.viewer?.itemMultipleConnection?.edges[0]?.node?.item?.id,
                    e,
                  )
                }
              >
                <DeleteIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        }
      >
        {sortedItemChildren.map(itemchildren => {
          return (
            <TreeItem
              nodeId={itemchildren.id}
              key={`itemChildren-${itemchildren.id}`}
              style={{ marginBottom: '1rem' }}
              label={
                <Grid container spacing={2} onClick={e => e.stopPropagation()}>
                  <Grid item xs={6}>
                    <p>{itemchildren.title}</p>
                  </Grid>
                  <Grid item xs={3}>
                    <UncontrolledCurrencyInput
                      register={register}
                      setValue={setFormValue}
                      rules={{
                        validateAsNumber: true,
                        validate: {
                          positive: value => {
                            const price = value;
                            const isPositive = price >= 0;
                            return isPositive;
                          },
                        },
                      }}
                      error={Boolean(
                        errors[item.id]?.multipleChoices &&
                          errors[item.id]?.multipleChoices[itemchildren.id]?.suggestedPrice,
                      )}
                      helperText={
                        Boolean(
                          errors[item.id]?.multipleChoices &&
                            errors[item.id]?.multipleChoices[itemchildren.id]?.suggestedPrice,
                        ) && "Price can't be negative or empty."
                      }
                      label={'Default Price'}
                      name={`${item.id}.multipleChoices.${itemchildren.id}.suggestedPrice`}
                      onValueChange={e =>
                        setValue(prevState => ({
                          ...prevState,
                          [`${itemchildren?.id}-price`]: e.floatValue,
                        }))
                      }
                      defaultValue={
                        value[`${itemchildren?.id}-price`] ||
                        convert.centsToDollars(itemchildren?.price) ||
                        null
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextFieldInput
                      name={`${item.id}.multipleChoices.${itemchildren.id}.allowedMaximum`}
                      type={'number'}
                      setValue={setFormValue}
                      register={register}
                      value={value[`${itemchildren?.id}-allowedMaximum`]}
                      defaultValue={
                        value[`${itemchildren?.id}-allowedMaximum`] ||
                        itemchildren?.allowedMaximum ||
                        null
                      }
                      onChange={e => {
                        setValue(prevState => ({
                          ...prevState,
                          [`${itemchildren?.id}-allowedMaximum`]: e.target.value,
                        }));
                      }}
                      rules={{
                        shouldValidate: true,
                        validateAsNumber: true,
                        validate: {
                          positive: value => {
                            const price = value;
                            const isPositive = price > 0;
                            return isPositive || isNaN(price);
                          },
                        },
                      }}
                      error={Boolean(
                        errors[item.id]?.multipleChoices &&
                          errors[item.id]?.multipleChoices[itemchildren.id]?.allowedMaximum,
                      )}
                      helperText={
                        Boolean(
                          errors[item.id]?.multipleChoices &&
                            errors[item.id]?.multipleChoices[itemchildren.id]?.allowedMaximum,
                        ) && "Allowed Maximum can't be negative and 0."
                      }
                      label="Allowed Maximum"
                    />
                  </Grid>
                </Grid>
              }
            />
          );
        })}
      </TreeItem>
      <SnackBar
        setState={setSnackbar}
        open={snackbar.open}
        text={snackbar.text}
        type={snackbar.type}
      />
    </div>
  );
};

export default ItemChoice;
