import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import CreateUpdateIngredientModalView from './View';
import {
  INGREDIENT_BUILDER,
  INGREDIENT_LOCATION_AVAILABILITY,
  INGREDIENTS_LIST,
} from '../../../../../../apollo/queries';
import { groupBy, isEmpty, uniqBy } from 'lodash';
import { CREATE_INGREDIENT, UPDATE_INGREDIENT } from '../../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { formatData, formatNumberStringToFloat } from '../../../../helpers';
import { SNACKBAR_STATUS } from '../../../../../../constants';

function CreateUpdateIngredientModal({
  locationIngredientId,
  query,
  setQuery,
  handleClose,
  rawIngredientId,
}) {
  const { setSnackbar } = useSnackbar();

  const [duplicateMeasureIds, setDuplicateMeasureIds] = useState([]);
  const [inStock, setInStock] = useState('not-changed');
  const [locations, setLocations] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const [mainMeasureLabel, setMainMeasureLabel] = useState();

  const [createIngredient] = useMutation(CREATE_INGREDIENT, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Ingredient created',
      });
      handleClose();
    },
    onError: err => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: err.message,
      });
    },
  });

  const [updateIngredient] = useMutation(UPDATE_INGREDIENT, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Ingredient updated',
      });
      handleClose();
    },
    onError: err => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: err.message,
      });
    },
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    errors,
    unregister,
  } = useForm({
    defaultValues: {
      measureId: '',
      label: '',
      status: '',
      inventoryStatus: '',
      ingredientcategoryId: '',
      description: '',
      cost: 0,
      nutrients: [],
      allergens: [],
      locationAvailability: [],
    },
  });

  const { data, error, loading } = useQuery(INGREDIENT_BUILDER, {
    variables: {
      ingridientAllergenFilter: {
        ingredientId: { eq: locationIngredientId || null },
      },
      ingridientNutrientFilter: {
        ingredientId: { eq: locationIngredientId || null },
      },
      ingredientFilter: { ingredientId: { eq: locationIngredientId || null } },
      fieldGroupOnly: 89,
    },
    fetchPolicy: 'network-only',
  });

  const currentFiles = useMemo(
    () =>
      !error && !loading
        ? data?.viewer?.ingredientConnection?.edges[0]?.node?.ingredientfilesConnection?.edges?.map(
            edge => edge.node,
          ) || []
        : [],
    [data, error, loading],
  );

  const measureOptions = useMemo(
    () =>
      data?.viewer?.measureConnection?.edges?.map(edge => ({
        value: edge.node?.measureId,
        label: `${edge.node?.label} (${edge.node?.abbreviation})`,
        abbreviation: edge.node?.abbreviation,
      })) || [],
    [data?.viewer?.measureConnection],
  );

  useEffect(() => {
    if (data) {
      setQuery(prevValue => ({
        ...prevValue,
        inventoryitem: data.viewer.ingredientConnection.edges[0]?.node?.inventoryitem,
      }));
    }
    if (locationIngredientId && data) {
      setMainMeasureLabel(
        data.viewer.ingredientConnection.edges[0].node.inventoryitem?.measure?.abbreviation,
      );

      reset({
        label: data.viewer.ingredientConnection.edges[0].node.inventoryitem?.label,
        description: data.viewer.ingredientConnection.edges[0].node.inventoryitem?.description,
        showOnLocation: +data.viewer.ingredientConnection.edges[0].node.inventoryitem
          ?.showOnLocation,
        allowAutomaticStockToggle: +data.viewer.ingredientConnection.edges[0].node.inventoryitem
          ?.allowAutomaticStockToggle,
        status:
          data.viewer.ingredientConnection.edges[0].node.inventoryitem?.status === false
            ? 0
            : data.viewer.ingredientConnection.edges[0].node.inventoryitem?.status === true
            ? 1
            : '',
        ingredientcategoryId: {
          id:
            data.viewer.ingredientConnection?.edges[0]?.node.inventoryitem.inventoryitemcategory
              .inventoryitemcategoryId,
          label:
            data.viewer.ingredientConnection?.edges[0]?.node.inventoryitem.inventoryitemcategory
              .label,
        },
        inventoryStatus:
          data.viewer.ingredientConnection.edges[0].node.inventoryitem?.inventoryStatus === false
            ? 0
            : data.viewer.ingredientConnection.edges[0].node.inventoryitem?.inventoryStatus === true
            ? 1
            : '',
        measureId: data.viewer.ingredientConnection.edges[0].node.inventoryitem?.measure?.measureId,
        nutrients: [
          ...data.viewer.ingredientNutrientConnection.edges?.map(edge => {
            return {
              nutrientId: edge.node.nutrient.id,
              quantity: edge.node.quantity,
            };
          }),
        ],
        cost: data.viewer.ingredientConnection.edges[0].node.inventoryitem?.cost,
        allergens: [
          ...data.viewer.ingredientAllergenConnection.edges?.map(edge => edge.node.allergen.id),
        ],
      });

      setFiles(
        data.viewer?.ingredientConnection?.edges[0]?.node?.ingredientfilesConnection?.edges?.map(
          edge => ({
            url: edge.node.fileUrl,
            name: edge.node.label,
            ingredientfileId: edge.node.ingredientfileId,
          }),
        ),
      );
    }
  }, [data]);

  const submit = useCallback(
    formData => {
      let conversionsForCountNumber = 0;

      const conversionMath = formatData({
        formData,
        label: 'unit-of-measure',
        mapFunction: value => {
          if (value.isForCount) {
            conversionsForCountNumber += 1;
          }

          return {
            recipeConversionMeasureId: +value.measureId,
            isForCount: value.isForCount,
            recipeConversionQuantity: formatNumberStringToFloat(value.conversionMath),
          };
        },
      });

      if (isEmpty(conversionMath)) {
        return;
      }

      if (uniqBy(conversionMath, 'recipeConversionMeasureId')?.length !== conversionMath?.length) {
        const notUniqConversions = Object.entries(
          groupBy(conversionMath, 'recipeConversionMeasureId'),
        ).filter(([, value]) => value?.length > 1);

        setDuplicateMeasureIds(notUniqConversions.map(([measureId]) => +measureId));
        return;
      } else {
        setDuplicateMeasureIds([]);
      }

      if (!conversionsForCountNumber) {
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          open: true,
          text: 'Check at least one conversion to be included in inventory',
        });
      } else if (conversionsForCountNumber > 3) {
        setSnackbar({
          type: SNACKBAR_STATUS.WARNING,
          open: true,
          text: `Maximum of 3 conversions can be included in inventory, please remove ${conversionsForCountNumber -
            3}`,
        });
      }

      const vendorProducts = formatData({
        formData,
        label: 'vendor-product',
        mapFunction: ({
          vendor,
          vpn,
          isPrimary,
          isSplit,
          isSub,
          vendorUOM,
          invConversion,
          packSize,
        }) => ({
          vendorId: vendor.id,
          vendorProductNumber: vpn,
          isPrimary,
          isSplit,
          isSubstitute: isSub,
          vendorConversionQuantity: formatNumberStringToFloat(invConversion),
          packSize: packSize.label,
          vendorUOM,
        }),
      });

      if (isEmpty(vendorProducts)) {
        return;
      }
      const brandIds = formData.brandIds.map(brand => ({ brandId: brand.id }));

      // TODO: add vendor products  to create/update ingredient
      let { measureId, nutrients, cost, description, label, allergens } = formData;

      label = label.trim();
      description = description.trim();

      const addedFiles = [];
      const oldIngredientfileIds = [];

      files.forEach(file => {
        if (!file.ingredientfileId) {
          addedFiles.push(file);
        } else {
          oldIngredientfileIds.push(file.ingredientfileId);
        }
      });

      const removedFiles = currentFiles.filter(
        currFile => !oldIngredientfileIds.includes(currFile.ingredientfileId),
      );

      const temp_newIngredient = {
        label,
        description,
        cost: cost !== '' ? cost : null,
        nutrients,
        allergens,
        locations,
        measureId,
        showOnLocation: formData.showOnLocation,
        allowAutomaticStockToggle: formData.allowAutomaticStockToggle,
        ingredientMeasures: conversionMath,
        // TODO: Make all this below dynamic after adding components
        brands: brandIds,
        vendorProducts: vendorProducts,
        inventoryitemcategoryId: formData.ingredientcategoryId.id,
        status: formData.status,
        inventoryStatus: formData.inventoryStatus,
        ingredientFiles: [
          ...addedFiles.map(({ name, encoded }) => ({ name, encoded })),
          ...removedFiles.map(({ ingredientfileId }) => ({
            ingredientfileId,
            shouldDelete: true,
          })),
        ],
      };

      // format allergens
      if (Array.isArray(allergens)) {
        // in a case if there is only one allergen(checkbox)
        temp_newIngredient.allergens = allergens.map(al => ({
          allergenId: al,
        }));
      } else {
        temp_newIngredient.allergens = [{ allergenId: allergens }];
      }

      // check if inStock buttons where clicked.
      if (inStock !== 'not-changed') {
        temp_newIngredient.inStock = inStock;
      } else {
        temp_newIngredient.inStock = null;
      }

      // remove available because it is used just to present state to the user
      if (locations?.length) {
        temp_newIngredient.locations = locations.map(location => ({
          locationId: location.locationId,
        }));
      }

      setButtonDisabled(true);

      if (locationIngredientId) {
        temp_newIngredient.ingredientId = rawIngredientId;
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.INFO,
          text: 'Updating ingredient...',
        });

        updateIngredient({
          variables: { input: { ...temp_newIngredient } },
          refetchQueries: [
            inStock !== 'not-changed' || locations?.length
              ? {
                  query: INGREDIENT_LOCATION_AVAILABILITY,
                  variables: {
                    ingredientFilter: {
                      ingredientId: { eq: locationIngredientId },
                    },
                  },
                }
              : '',
            {
              query: INGREDIENT_BUILDER,
              variables: {
                ingridientAllergenFilter: {
                  ingredientId: { eq: locationIngredientId },
                },
                ingridientNutrientFilter: {
                  ingredientId: { eq: locationIngredientId },
                },
                ingredientFilter: { ingredientId: { eq: locationIngredientId } },
                fieldGroupOnly: 89,
              },
            },
          ],
          awaitRefetchQueries: true,
        });
        return;
      }

      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.INFO,
        text: 'Creating ingredient',
      });

      createIngredient({
        variables: { input: { ...temp_newIngredient } },
        refetchQueries: [{ query: INGREDIENTS_LIST, variables: { ...query.variables } }],
      });
    },
    [
      createIngredient,
      setSnackbar,
      inStock,
      locations,
      locationIngredientId,
      updateIngredient,
      currentFiles,
      files,
    ],
  );

  if (loading) return '';
  if (error) return <p>Error</p>;

  const {
    allergenConnection,
    ingredientAllergenConnection,
    nutrientConnection,
    ingredientNutrientConnection,
    ingredientConnection,
  } = data.viewer;

  const handleNutrientsChange = nutrient => {
    const { nutrientId, quantity } = nutrient;
    const { nutrients } = getValues();
    setValue('nutrients', [
      ...nutrients.filter(nut => nut.nutrientId !== nutrientId),
      { nutrientId, quantity },
    ]);
  };

  const handleInStockChange = stock => {
    setInStock(stock);
    setLocations([]);
    setValue('inStock', inStock);
  };

  const handleLocationsChange = locationState => {
    const { locationId } = locationState;
    if (!!locations.filter(loc => loc.locationId === locationId)?.length) {
      setLocations([...locations.filter(l => l.locationId !== locationId)]);
      return;
    }
    setLocations([...locations.filter(l => l.locationId !== locationId), locationState]);
  };

  return (
    <CreateUpdateIngredientModalView
      handleClose={handleClose}
      allergenConnection={allergenConnection}
      ingredientAllergenConnection={ingredientAllergenConnection}
      ingredientNutrientConnection={ingredientNutrientConnection}
      nutrientConnection={nutrientConnection}
      measureOptions={measureOptions}
      setInStock={handleInStockChange}
      inStock={inStock}
      locationIngredientId={locationIngredientId}
      handleNutrientsChange={handleNutrientsChange}
      handleLocationsChange={handleLocationsChange}
      myLocations={locations}
      register={register}
      handleSubmit={handleSubmit}
      ingredientConnection={ingredientConnection}
      buttonDisabled={buttonDisabled}
      submit={submit}
      control={control}
      errors={errors}
      duplicateMeasureIds={duplicateMeasureIds}
      setValue={setValue}
      unregister={unregister}
      getValues={getValues}
      setFiles={setFiles}
      files={files}
      mainMeasureLabel={mainMeasureLabel}
      setMainMeasureLabel={setMainMeasureLabel}
    />
  );
}

CreateUpdateIngredientModal.propTypes = {
  locationIngredientId: PropTypes.number,
  _open: PropTypes.object,
  _setOpen: PropTypes.func,
  rawIngredientId: PropTypes.string,
  setLocationIngredientId: PropTypes.func,
};

export default CreateUpdateIngredientModal;
