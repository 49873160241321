import { gql } from '@apollo/client';

const STAGES = gql`
  query Stages($sort: [StageSort]) {
    viewer {
      id
      stageConnection(sort: $sort) {
        permissions
        edges {
          node {
            id
            stageId
            label
          }
        }
      }
    }
  }
`;

export default STAGES;
