import moment from 'moment';
import { utils, writeFile } from 'xlsx';

export const generateXLSX = (tableData, filters, locationLabel) => {
  let formated = [];
  if (!tableData) {
    return formated;
  }

  formated = tableData.map(line => {
    return {
      CATEGORY: line.groupByLabel,
      ['INVENTORY ITEM']: line.label,
      ['STORAGE AREAS']: line.storages,
      ['MAIN UOM']: line.invUom,
      ['TOTAL QTY']: !line.isGroupLabel && !line.isTotalCost ? line.totalQty : '',
      ['TOTAL COST']: !line.isGroupLabel || line.isTotalCost ? line.totalQtyCost : '',
    };
  });
  if (!filters.displaySubategories) {
    formated = tableData.map(line => {
      return {
        label: line.label,
        cost: line.totalQtyCost,
      };
    });
  }

  const wb = utils.book_new();
  const ws = utils.json_to_sheet(formated);
  utils.book_append_sheet(wb, ws, 'Inventory Count');
  writeFile(
    wb,
    `inventorycount-(${locationLabel})-(${moment(filters.dateRange.gte)?.format(
      'MM/DD/YYYY',
    )} - ${moment(filters.dateRange.lte)?.format('MM/DD/YYYY')}).xlsx`,
  );
};
