import { useEffect } from 'react';
import { Typography } from "@material-ui/core";
import { Elements, Permission, PermissionField } from "../../../../../blocks";

import { Controller } from "react-hook-form";
import { uglifyId } from "../../../../../../utils";
import { useCallback, useState } from "react";

// BULK EDIT
const Statuses = ({
	access,
	category,
	control,
	statuses,
	defaultStatus = statuses?.[0].value,
	disabled,
	handleToggleClick = () => {},
	handoff,
	label,
	name,
	locationId,
	register,
	toggleLocationHandoffCategory = () => {},
	...rest
}) => {
	const [value, setValue] = useState(defaultStatus);

	useEffect(() => {
		if (register) {
			register(name);
		}
	}, [register, name])

	const handleChange = useCallback((newValue) => {
		setValue(newValue);
		handleToggleClick(newValue);
		toggleLocationHandoffCategory({
			variables: {
				input: {
				handoffId: handoff?.id,
				categoryId: category?.node?.id,
				toggle: newValue,
				locationId: uglifyId("Location", locationId),
				},
			},
		})
	}, [handleToggleClick, toggleLocationHandoffCategory, handoff, category, locationId, setValue])

	return (
		<>
			<Typography>{label || `Allow ${handoff?.label} for ${category?.node?.label} orders`}</Typography>
			<Controller
				name="activeStatus"
				control={control}
				defaultValue={defaultStatus}
				render={(
					{ onChange, ref }
				) => (
					<Permission access={access}>
						<div style={{ width: "100%" }}>
							<PermissionField
								{...rest}
								Component={Elements.ToggleButton}
								createHelperText
								ref={ref}
								options={statuses}
								value={value}
								disabled={disabled}
								handleToggleClick={handleChange}
							/>
						</div>
					</Permission>
				)}
			/>
		</>
	);
}
export default Statuses;
