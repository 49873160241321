import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem/TreeItem';
import { sortBy } from 'lodash';
import { SnackBar } from '../../../../../../../../../../blocks';
import ItemChoiceTree from './itemChoiceTree';

const useStyles = makeStyles(theme => ({
  rootOnDrag: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
    boxShadow: theme.shadows[5],
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  margin: {
    marginRight: '1rem',
    width: 200,
  },
  row: {
    alignItems: 'center',
    width: '62rem',
  },
  firsttTextField: {
    marginRight: '1rem',
    width: 580,
  },
  submargin: {
    marginRight: '2rem',
    width: 250,
  },
}));

const ItemChoice = ({ item, itemId, refetch, itemData }) => {
  const [snackbar, setSnackbar] = useState({});
  const classes = useStyles();
  const ref = useRef(null);
  const [disabledSave, setDisabledSave] = useState(false);

  const sortedItemChildren = useMemo(() => {
    return sortBy(item.children, 'order');
  }, [item.children]);

  return (
    <div ref={ref}>
      <TreeItem
        nodeId={item.id}
        key={item.id}
        style={{ marginBottom: '2.5rem' }}
        label={
          <Grid container spacing={2} onClick={e => e.stopPropagation()} className={classes.row}>
            <Grid item xs={2}>
              <div>
                <span style={{ fontSize: '1rem', marginRight: '0.5rem' }}>
                  {item.title} - {item.multipleId}
                </span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <p>{item?.onlineLabel}</p>
            </Grid>
          </Grid>
        }
      >
        {sortedItemChildren.map(itemchildren => {
          return (
            <TreeItem
              nodeId={itemchildren.id}
              key={`itemChildren-${itemchildren.id}`}
              style={{ marginBottom: '1rem' }}
              label={
                <ItemChoiceTree
                  itemchildren={itemchildren}
                  itemId={itemId}
                  setDisabledSave={setDisabledSave}
                  disabledSave={disabledSave}
                  refetch={refetch}
                  itemData={itemData}
                />
              }
            />
          );
        })}
      </TreeItem>
      <SnackBar
        setState={setSnackbar}
        open={snackbar.open}
        text={snackbar.text}
        type={snackbar.type}
      />
    </div>
  );
};

export default ItemChoice;
