import { Button } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback } from 'react';
import FETCH_INVENTORY_ITEMS from '../../../../../../apollo/queries/fetchInventoryItem';
import { SelectFactory } from '../../../../../blocks';

const AddRecipeItem = ({
  hasItems,
  onClick,
  setShowSelectFactory,
  showSelectFactory,
  selectedRecipeIds,
}) => {
  const handleOnSelect = useCallback(
    value => {
      if (value) {
        onClick(value);
      } else {
        setShowSelectFactory(false);
      }
    },
    [onClick, setShowSelectFactory],
  );

  return (
    <Grid container spacing={3} justify="flex-end" alignItems="center">
      {showSelectFactory || !hasItems ? (
        <>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Add Ingredient
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectFactory
              label="Ingredient Name"
              fullWidth
              filter={{
                inventoryitemId: {
                  nin: selectedRecipeIds,
                },
              }}
              sort={{
                inventoryitemId: 'ASC',
              }}
              name="recipe"
              placeholder="Search Ingredients"
              query={FETCH_INVENTORY_ITEMS}
              structureData={data =>
                data?.viewer?.inventoryitemConnection?.edges?.map((edge, index) => {
                  return {
                    label: edge.node.label,
                    inventoryitemId: edge.node.inventoryitemId,
                    quantity: '',
                    measureId: '',
                    measureOptions: edge.node?.inventoryitemMeasureConnection?.edges?.map(
                      measureEdge => ({
                        value: measureEdge.node.measure?.measureId,
                        label: `${measureEdge.node.measure?.label} (${measureEdge.node.measure?.abbreviation})`,
                      }),
                    ),
                  };
                })
              }
              defaultValue=""
              multiple={false}
              renderCheckBox={false}
              onSelect={handleOnSelect}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={6}>
          <Button
            style={{ minWidth: '100%' }}
            type="button"
            variant="outlined"
            onClick={() => setShowSelectFactory(true)}
          >
            <AddIcon style={{ marginRight: '4px' }} /> Add more recipes
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AddRecipeItem;
