import { gql } from '@apollo/client';
import { stateFragment } from './stateFragment';

export const locationAddressFragment = {
  address: gql`
    fragment LocationAddressData on Address {
      id
      address
      city
      additionalInfo
      zip
      longitude
      latitude
      state {
        ...StateData
      }
    }
    ${stateFragment.state}
  `,
};
