import React, { useEffect, useState } from 'react';
import CreateLoyaltySettingsView from './View';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

function CreateLoyaltySettings({
  handleClose,
  handleOnCreateLoyaltysettings,
  timezones,
  loadingTimezone,
  loyaltyUpdateData,
  createLoyaltyLoading,
  updateLoyaltysettingsLoading,
}) {
  const { control, handleSubmit, errors, reset } = useForm();
  const { setSnackbar } = useSnackbar();
  const [showEndDate, setShowEndDate] = useState(
    loyaltyUpdateData?.endDate.replace('/', '') ? true : false,
  );
  const [selectedBrands, setSelectedBrands] = useState(
    loyaltyUpdateData?.brands?.map(brand => ({ id: brand?.id, label: brand?.label })),
  );
  const [selectedOrigins, setSelectedOrigins] = useState(
    loyaltyUpdateData?.origins?.map(origin => ({ id: origin?.id, label: origin?.label })),
  );
  const [selectedCoupontriggers, setSelectedCoupontriggers] = useState(
    loyaltyUpdateData?.coupontriggers?.map(trigger => ({
      id: trigger?.node?.coupontrigger?.couponTriggerId,
      label: trigger?.node?.coupontrigger?.label,
    })) || [],
  );
  const [selectedCouponaction, setSelectedCouponaction] = useState(
    loyaltyUpdateData?.couponaction?.couponactionId,
  );
  const [selectedItems, setSelectedItems] = useState(
    loyaltyUpdateData?.items?.map(item => ({
      id: item?.node?.item?.id,
      itemId: item?.node?.item?.itemId,
      label: `${item?.node?.item?.label} - ID_${item?.node?.item?.itemId}`,
      isIncluded: item?.node?.isIncluded,
    })) || [],
  );
  const [itemInCartIsEnabled, setItemInCartIsEnabled] = useState(
    Boolean(selectedCoupontriggers?.find(trigger => trigger.id === 3)),
  );

  const weekdays = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  useEffect(() => {
    if (loyaltyUpdateData?.weekdaysRaw.edges.length) {
      reset({ availableOnDays: { enabled: true } });
    }
  }, []);

  const submit = data => {
    const formatedData = {};
    formatedData.active = data.active;
    formatedData.label = data.label;
    formatedData.multiplier = data.multiplier;
    formatedData.startDate = moment(data.startDate).format('YYYY-MM-DD');
    if (showEndDate) {
      formatedData.endDate = moment(data.endDate).format('YYYY-MM-DD');
    }
    if (data?.enableTimeSelection) {
      formatedData.startTime = moment(
        `${data.startTimeHours}:${data.startTimeMinutes} ${data.startTimeType}`,
        ['HH:mm A'],
      ).format('HH:mm');
      formatedData.endTime = moment(
        `${data.endTimeHours}:${data.endTimeMinutes} ${data.endTimeType}`,
        ['HH:mm A'],
      ).format('HH:mm');
    }
    if (data?.timezoneId) {
      if (!data.timezone.timezoneId) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Please select timezone',
        });
        return;
      }
      formatedData.timezoneId = data?.timezone.timezoneId.id;
    }
    if (data?.timezone?.enabled) {
      if (!data.timezone.timezoneId) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Please select timezone',
        });
        return;
      }
      formatedData.timezoneId = data?.timezone.timezoneId.value;
    }

    formatedData.weekdays = [];
    if (data?.availableOnDays?.enabled) {
      for (const day in data?.availableOnDays?.days) {
        if (data?.availableOnDays?.days[day]) {
          formatedData.weekdays.push({ weekdayId: weekdays[day] });
        }
      }

      if (!formatedData.weekdays.length) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Please select at least 1 day',
        });
        return;
      }
    }

    formatedData.brandIds = selectedBrands?.map(brand => brand.id);
    formatedData.originIds = selectedOrigins?.map(origin => origin.id);

    if (data.itemInCart && !selectedItems?.length) {
      setSnackbar({
        open: true,
        type: 'warning',
        text: 'Please add at least 1 item',
      });
      return;
    }

    if (itemInCartIsEnabled) {
      formatedData.loyaltysettingsDetails = { couponactionId: selectedCouponaction };
      formatedData.triggerEvents = {
        itemInCart: {
          couponTriggerId: selectedCoupontriggers[0]?.id,
          itemIds: selectedItems?.map(item => item.id),
          isIncluded: selectedItems?.every(item => item.isIncluded),
        },
      };
    }

    handleOnCreateLoyaltysettings(formatedData);
  };

  return (
    <div style={{ width: '100%' }}>
      <CreateLoyaltySettingsView
        control={control}
        errors={errors}
        data={timezones}
        loadingTimezone={loadingTimezone}
        submit={submit}
        handleSubmit={handleSubmit}
        setShowEndDate={setShowEndDate}
        showEndDate={showEndDate}
        handleClose={handleClose}
        loyaltyUpdateData={loyaltyUpdateData}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedOrigins={selectedOrigins}
        setSelectedOrigins={setSelectedOrigins}
        selectedCoupontriggers={selectedCoupontriggers}
        setSelectedCoupontriggers={setSelectedCoupontriggers}
        selectedCouponaction={selectedCouponaction}
        setSelectedCouponaction={setSelectedCouponaction}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        createLoyaltyLoading={createLoyaltyLoading}
        updateLoyaltysettingsLoading={updateLoyaltysettingsLoading}
        itemInCartIsEnabled={itemInCartIsEnabled}
        setItemInCartIsEnabled={setItemInCartIsEnabled}
      />
    </div>
  );
}

export default CreateLoyaltySettings;
