import { makeStyles } from '@material-ui/core';
const Styles = (theme) => ({
  selectFactoryWrap: {
    display: 'flex',
    marginLeft: "0.5rem",
    alignItems: 'center',
    width: "100%",
    '& > div': {
      minWidth: '250px',
      flexGrow:1,
      padding: '0px 12px 0px 0px',
    },
  },
  selectDateRange: {
    flexDirection: 'row',    
    '& button': {
      padding: 0,
      '& span > div': {
        flex: 1,
      },
    },
  },
});

export const orderListViewStyles = makeStyles(Styles);
