import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import MenuTreeStyles from '../View/styles';

const ViewSettingsInput = ({
  title = 'Display On',
  showToDashboardUser = false,
  control,
}) => {
  const classes = MenuTreeStyles();

  return (
    <Grid item xs={4}>
      <FormControl component="fieldset">
        <Typography gutterBottom variant="h6" component="h2">
          {title}
        </Typography>
        {showToDashboardUser && (
          <FormGroup row={true}>
            <FormControlLabel
              label="Show Item to Dashboard users"
              control={
                <Controller
                  name="showLocation"
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                      className={classes.switch}
                    />
                  )}
                />
              }
            />
          </FormGroup>
        )}
        <FormControlLabel
          label="Is Available on OLO"
          control={
            <Controller
              name="availableOnline"
              control={control}
              render={(props) => (
                <Switch
                  onChange={(e) => props.onChange(e.target.checked)}
                  checked={props.value}
                  className={classes.switch}
                />
              )}
            />
          }
        />
        <FormControlLabel
          label="Is Available on POS"
          control={
            <Controller
              name="availablePos"
              control={control}
              render={(props) => (
                <Switch
                  onChange={(e) => props.onChange(e.target.checked)}
                  checked={props.value}
                  className={classes.switch}
                />
              )}
            />
          }
        />
        <FormControlLabel
          label="Is Available on Chowly 3PV"
          control={
            <Controller
              name="availableThirdParty"
              control={control}
              render={(props) => (
                <Switch
                  onChange={(e) => props.onChange(e.target.checked)}
                  checked={props.value}
                  className={classes.switch}
                />
              )}
            />
          }
        />
      </FormControl>
    </Grid>
  );
};

ViewSettingsInput.propTypes = {
  title: PropTypes.string,
  showToDashboardUser: PropTypes.bool,
  control: PropTypes.object.isRequired,
};

export default ViewSettingsInput;
