import React from "react";
import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import { Controller } from "react-hook-form";
import AutoComplete from "../../../../../../blocks/AutoComplete";

function TimezoneSelection({ timezones, control, coupon, disabled = false }) {
  const [disabledTimezone, setDisabledTimezone] = React.useState(
    !!coupon.timezoneId
  );

  const timezoneOptions = timezones.edges.map((timezone) => ({
    label: timezone.node.label,
    value: timezone.node.id,
    numId: timezone.node.timezoneId,
  }));

  const selectedTimezone = timezoneOptions.filter(
    (timezone) => timezone.numId === coupon.timezoneId
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "3fr 2fr",
        gap: "1rem",
        marginBottom: "15px",
        alignItems: "center",
      }}
    >
      <div>
        <Controller
          control={control}
          name="timezone.enabled"
          defaultValue={disabledTimezone}
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  defaultChecked={disabledTimezone}
                  inputRef={ref}
                  onChange={(e) => {
                    setDisabledTimezone(!disabledTimezone);
                    onChange(e.target.checked);
                  }}
                  value="online"
                />
              }
              label="Specify a single Time Zone to control Start/End time for all locations"
            />
          )}
        />
      </div>
      <div>
        <AutoComplete
          id="timezone.timezoneId"
          control={control}
          options={timezoneOptions}
          defaultValue={selectedTimezone[0]}
          selectedOption={selectedTimezone[0]}
          disabled={!disabledTimezone}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={"Timezone"} variant="outlined" />
          )}
        />
      </div>
    </div>
  );
}

export default TimezoneSelection;
