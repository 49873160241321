import React from 'react';
import VendorsTable from '../Table';

const VendorsView = () => {
  return (
    <div>
        <VendorsTable />
    </div>
  );
};

export default VendorsView;
