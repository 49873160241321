import React, { useEffect, useState } from 'react';
import TreeView from '@material-ui/lab/TreeView/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ItemChoice from './ItemChoice';
import AddMultipleItem from '../ItemChoicesModal';
import { uglifyId } from '../../../../../../../../../utils';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_ITEM_CHOICES } from '../../../../../../../../../apollo/queries';
import { REMOVE_ITEM_MULTIPLE } from '../../../../../../../../../apollo/mutations';
import UPDATE_ITEM_MULTIPLE from '../../../../../../../../../apollo/mutations/menus/updateItemMultiple';
import { useForm } from 'react-hook-form';
import { Button, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { useSnackbar } from '../../../../../../../../../hooks/useSnackbar';
import { Modal } from '../../../../../../../../blocks';

const useStyles = makeStyles({
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
});

const ItemChoiceTable = ({ getPath, handleClose, open, itemId, handleOpen }) => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();

  const [multipleData, setMultipleData] = useState([]);
  const [childValue, setChildValue] = useState({});
  const [removeItemMultiple] = useMutation(REMOVE_ITEM_MULTIPLE);
  const [updateItemMultiple] = useMutation(UPDATE_ITEM_MULTIPLE);
  const { register, handleSubmit, control, errors, setValue } = useForm();

  const switchElements = (arr, indexA, indexB) => {
    let temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };

  const moveChoiceItem = (dragIndex, hoverIndex) => {
    setMultipleData(prevState => [...switchElements(prevState, dragIndex, hoverIndex)]);
  };
  const { data: itemData, error, loading, refetch, variables } = useQuery(FETCH_ITEM_CHOICES, {
    variables: {
      filter: {
        itemId: {
          eq: itemId,
        },
      },
    },
  });
  useEffect(() => {
    setMultipleData(
      itemData?.viewer?.itemMultipleConnection?.edges?.map(edge => {
        return {
          id: edge?.node?.multiple?.id,
          order: edge?.node?.order,
          multipleId: edge?.node?.multiple?.multipleId,
          title: edge?.node?.multiple?.label,
          onlineLabel: edge?.node?.multiple?.onlineLabel,
          optional: edge?.node?.optional,
          allowedMaximum: edge?.node?.allowedMaximum,
          requiredMinimum: edge?.node?.requiredMinimum,
          children: edge?.node?.multiple?.multipleChoiceConnection?.edges?.map(choiceEdge => ({
            id: choiceEdge?.node?.choice?.id,
            order: choiceEdge?.node?.orderKey,
            title: choiceEdge?.node?.choice?.label,
            allowedMaximum: choiceEdge?.node?.allowedMaximum,
            price: choiceEdge?.node?.price,
          })),
        };
      }),
    );
  }, [itemData]);

  if (loading) return <div>Loading...</div>;
  if (error) console.log(error);
  const deleteItemMultiple = (multipleId, itemId, e) => {
    e.preventDefault();
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Choice is being deleted!',
    });
    removeItemMultiple({
      variables: {
        input: {
          multipleId,
          itemId,
        },
      },
    }).then(() => {
      refetch({
        ...variables,
      }).then(r =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Choice is deleted!',
        }),
      );
    });
  };
  const onSubmit = formData => {
    let formatData = [];
    for (const property in formData) {
      const multiple = formData[property];
      const multipleDataWithOrder = multipleData.map((el, index) => {
        el.order = index + 1;
        return el;
      });
      const order = multipleDataWithOrder.find(el => el.id === property).order;
      const multipleChoices = multiple?.multipleChoices;
      if (multipleChoices) {
        const choices = [];
        for (const choiceProperty in multipleChoices) {
          const choice = multipleChoices[choiceProperty];
          choices.push({
            choiceId: choiceProperty,
            ...choice,
            suggestedPrice: choice.suggestedPrice,
          });
        }
        formatData.push({ multipleId: property, order, ...multiple?.multipleValues, choices });
      } else {
        formatData.push({ multipleId: property, order, ...multiple?.multipleValues });
      }
    }
    updateItemMultiple({
      variables: {
        input: {
          itemId: uglifyId('Item', itemId),
          multipleChoiceArray: formatData,
        },
      },
    }).then(() => {
      refetch({
        filter: {
          itemId: {
            eq: itemId,
          },
        },
      }).then(() =>
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Multiple is successfully changed.',
        }),
      );
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.containerHeader}>
          <div>
            <h2>Item Choices</h2>
          </div>
          <div>
            <IconButton style={{ padding: '0' }} aria-label="copy" onClick={() => handleOpen()}>
              <Add fontSize={'large'} />
            </IconButton>
          </div>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Button size="medium" variant="outlined" type={'submit'} style={{ textAlign: 'right' }}>
            Save
          </Button>
        </div>

        <TreeView
          defaultCollapseIcon={
            <div>
              <OpenWithIcon fontSize={'large'} onClick={e => e.stopPropagation()} />
              <ExpandMoreIcon fontSize={'large'} />
            </div>
          }
          defaultExpandIcon={
            <div>
              <OpenWithIcon fontSize={'large'} onClick={e => e.stopPropagation()} />
              <ChevronRightIcon />
            </div>
          }
        >
          {multipleData?.map((item, index) => {
            return (
              <ItemChoice
                item={item}
                itemId={itemId}
                key={`ItemChoice-${index}`}
                register={register}
                data={multipleData}
                control={control}
                errors={errors}
                getPath={getPath}
                setValue={setChildValue}
                value={childValue}
                setFormValue={setValue}
                deleteItemMultiple={deleteItemMultiple}
                moveChoiceItem={moveChoiceItem}
                itemData={itemData}
                index={index}
              />
            );
          }) || null}
        </TreeView>
      </form>
      <Modal open={open} handleClose={handleClose} title="Add Choices">
        <AddMultipleItem
          handleClose={handleClose}
          numberOfMultiples={multipleData?.length}
          refetch={refetch}
          variables={variables}
          itemId={uglifyId('Item', itemId)}
          existingMultipleIds={multipleData?.map(multiple => multiple.multipleId)}
        />
      </Modal>
    </>
  );
};

export default ItemChoiceTable;
