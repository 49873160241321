import { createContext, useState } from 'react';

import { SNACKBAR_STATUS } from './constants';
import SnackBar from './components/blocks/SnackBar'

export const SnackbarContext = createContext(null);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
		type: SNACKBAR_STATUS.INFO,
		text: '',
		open: false,
	});

	return (
		<SnackbarContext.Provider value={{ setSnackbar }}>
			{children}
			<SnackBar {...snackbar} setState={setSnackbar} />
		</SnackbarContext.Provider>
	)
};
