import { gql } from '@apollo/client';

const REMOVE_CATEGORY_FROM_TREE = gql`
  mutation RemoveCategoryFromTree($input: RemoveCategoryFromTreeMutationInput!) {
    removeCategoryFromTree(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default REMOVE_CATEGORY_FROM_TREE;
