function getFromAddressComponents(components, type) {
  if (!components || !components.length || !type) {
    return null;
  }

  const component = components.find(node => node.types.includes(type));

  if (type == 'country') {
    return component && component.short_name;
  }

  return component && component.long_name;
}

const getAddressPartsFromGeocode = ({ address_components }) => {
  if (!address_components) throw new Error('No address components provided!');

  return {
    address: `${getFromAddressComponents(address_components, 'street_number') ||
      ''} ${getFromAddressComponents(address_components, 'route') || ''}`,
    city: getFromAddressComponents(address_components, 'locality') || '',
    country: getFromAddressComponents(address_components, 'country') || '',
    state: getFromAddressComponents(address_components, 'administrative_area_level_1') || '',
    zip: getFromAddressComponents(address_components, 'postal_code') || '',
  };
};

export default getAddressPartsFromGeocode;
