import { SvgIcon } from '@material-ui/core';

const OrderTracker12Active = () => (
  <SvgIcon
    style={{ width: '100%', height: '100%' }}
    viewBox="0 0 91 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 60">
      <path
        d="m63.82,30c0-3.72-1.1-7.36-3.17-10.46-2.07-3.1-5.01-5.51-8.45-6.93-3.44-1.42-7.22-1.8-10.88-1.07-3.65.73-7,2.52-9.64,5.15-2.63,2.63-4.42,5.99-5.15,9.64-.73,3.65-.35,7.44,1.07,10.88,1.42,3.44,3.84,6.38,6.93,8.45,3.1,2.07,6.73,3.17,10.46,3.17,2.47,0,4.92-.49,7.2-1.43,2.28-.95,4.36-2.33,6.11-4.08s3.13-3.82,4.08-6.11c.95-2.28,1.43-4.73,1.43-7.2Z"
        fill="#ffda00"
        stroke="#010101"
        stroke-width="1.55px"
      />
      <path
        d="m54.53,22.11l-12.06,13.57-7.17-5.73c-.23-.17-.51-.24-.79-.2-.28.04-.53.18-.71.4-.17.22-.26.5-.23.78s.16.54.38.72l7.96,6.37c.22.17.49.26.77.23.28-.02.53-.15.72-.36l12.75-14.34c.19-.21.29-.5.27-.78-.02-.29-.15-.56-.36-.75-.21-.19-.5-.29-.78-.27-.29.02-.56.15-.75.36"
        fill="#010101"
      />
    </svg>
  </SvgIcon>
);

export default OrderTracker12Active;
