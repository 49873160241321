import React, { useState, forwardRef, useEffect } from 'react';
import { Switch as MuiSwitch, FormControlLabel, Typography, Grid } from '@material-ui/core';

import { SwitchStyles, LabelStyles } from './styles';

const Switch = forwardRef(
  (
    {
      checked = false,
      handleSwitchChange,
      onLabel = 'On',
      offLabel = 'Off',
      name,
      label,
      disabled = false,
      xs = 1,
      error,
      ...rest
    },
    ref,
  ) => {
    const classes = SwitchStyles();
    const formControlLabelClasses = LabelStyles();
    const [isChecked, setIsChecked] = useState(checked || false);

    const handleOnChange = () => {
      if (handleSwitchChange) handleSwitchChange(!isChecked);
      setIsChecked(!isChecked);
    };

    // allow external update of the switch state
    useEffect(() => {
      if (checked !== undefined) {
        setIsChecked(checked);
      }
    }, [checked]);

    return (
      <Grid container alignItems="center" xs={12}>
        {label ? (
          <Grid item xs={xs}>
            <Typography
              variant="caption"
              style={{ marginRight: '0.5rem' }}
              disabled={disabled}
              color={error ? 'error' : 'initial'}
            >
              {label}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12 - xs}>
          <FormControlLabel
            {...rest}
            classes={formControlLabelClasses}
            disabled={disabled}
            control={
              <MuiSwitch
                {...rest}
                inputRef={ref}
                checked={isChecked}
                disabled={disabled}
                onChange={handleOnChange}
                name={name}
              />
            }
            labelPlacement="end"
            label={
              <span
                className={
                  isChecked ? classes.switchControlLabelTextOn : classes.switchControlLabelTextOff
                }
              >
                {isChecked ? onLabel : offLabel}
              </span>
            }
          />
        </Grid>
      </Grid>
    );
  },
);

export default Switch;
