import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Elements } from '../../../../../../../blocks';
import moment from 'moment';

function AdvancedTimeSettingsModal({ days, setDays, control }) {
  const handleDayUpdate = (e, day) => {
    const dayIndex = days.indexOf(day);
    if (e.target.checked) {
      days[dayIndex].enabled = true;
    } else {
      days[dayIndex].enabled = false;
    }

    setDays([...days]);
  };

  const handleTimeChange = (e, timeType, index) => {
    const value = e.target.value;
    switch (timeType) {
      case 'hours-start':
        days[index].startTime.hours = value;
        break;
      case 'minutes-start':
        days[index].startTime.minutes = value;
        break;
      case 'meridiem-start':
        days[index].startTime.meridiem = value;
        break;
      case 'hours-end':
        days[index].endTime.hours = value;
        break;
      case 'minutes-end':
        days[index].endTime.minutes = value;
        break;
      case 'meridiem-end':
        days[index].endTime.meridiem = value;
        break;
      default:
    }
    setDays([...days]);
  };

  const getDefaultValue = (day, startOrEnd) => {
    if (day.formatedStartTime && startOrEnd === 'start') {
      return {
        hours: moment(day.formatedStartTime.split(':')[0], 'h')
          .format('LT')
          .split(':')[0],
        minutes: +day.formatedStartTime.split(':')[1],
        meridiem: moment(day.formatedStartTime, 'h')
          .format('LT')
          .split(' ')[1]
          .toLowerCase(),
      };
    }
    if (day.startTime && !day.formatedStartTime && startOrEnd === 'start') {
      return {
        hours: day.startTime.hours || 12,
        minutes: day.startTime.minutes || 0,
        meridiem: day.startTime.meridiem || 'am',
      };
    }
    if (day.formatedEndTime && startOrEnd === 'end') {
      // add metadata for default value
      return {
        hours: moment(day.formatedEndTime.split(':')[0], 'h')
          .format('LT')
          .split(':')[0],
        minutes: +day.formatedEndTime.split(':')[1],
        meridiem: moment(day.formatedEndTime, 'h')
          .format('LT')
          .split(' ')[1]
          .toLowerCase(),
      };
    }
    if (day.endTime && !day.formatedEndTime && startOrEnd === 'end') {
      return {
        hours: day.endTime.hours || 12,
        minutes: +day.endTime.minutes || 59,
        meridiem: day.endTime.meridiem || 'pm',
      };
    }
  };

  const dayFormat = (day, index) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ minWidth: '7rem' }}>
          <FormControlLabel
            label={day.name}
            control={
              <Checkbox onChange={e => handleDayUpdate(e, day)} checked={day.enabled} name="type" />
            }
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: '1', width: '20rem' }}>
            <Elements.TimePicker
              control={control}
              hoursName={`startTimeHours-${index}`}
              minutesName={`startTimeMinutes-${index}`}
              amPmName={`startTimeType-${index}`}
              required
              disabled={!day.enabled}
              onHoursChange={e => handleTimeChange(e, 'hours-start', index)}
              onMinutesChange={e => handleTimeChange(e, 'minutes-start', index)}
              onMeridiemChange={e => handleTimeChange(e, 'meridiem-start', index)}
              defaultValues={getDefaultValue(day, 'start')}
            />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <h3>to</h3>
          </div>
          <div style={{ flexGrow: '1', width: '20rem' }}>
            <Elements.TimePicker
              control={control}
              hoursName={`endTimeHours-${index}`}
              minutesName={`endTimeMinutes-${index}`}
              amPmName={`endTimeType-${index}`}
              disabled={!day.enabled}
              required
              onHoursChange={e => handleTimeChange(e, 'hours-end', index)}
              onMinutesChange={e => handleTimeChange(e, 'minutes-end', index)}
              onMeridiemChange={e => handleTimeChange(e, 'meridiem-end', index)}
              defaultValues={getDefaultValue(day, 'end')}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days_temp = [];
    for (const day of days) {
      days_temp.push(dayFormat(day, days.indexOf(day)));
    }
    return days_temp;
  };

  return <div>{renderDays()}</div>;
}

export default AdvancedTimeSettingsModal;
