import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
  active: {
    color: '#37C78A',
  },
  inactive: {
    color: '#de0c4f',
  },
  inputLabelSelect: {
    marginLeft: '.2rem',
    top: '-8px',
    left: '8px',
    padding: '0 5px',
    zIndex: 1,
    background: '#fff',
  },
});

const UsergroupStyles = makeStyles(Styles);

export default UsergroupStyles;
