import React from "react";
import { Controller } from "react-hook-form";
import { CurrencyInputFormat } from "../../../../../../../../../utils";
import CurrencyInput from "../../../../../../../../blocks/CurrencyInput";
function SubtotalSpecifiAmount({ control, errors }) {
  return (
    <>
      <Controller
        control={control}
        name="triggerEvents.automatic.subtotalSpecificAmount.minAmount"
        render={({ onChange, value }) => (
          <CurrencyInput
          rules={{
            required:"Minimal amount is requried",
            validate: {
              positive: (value) => {
                const price = CurrencyInputFormat(value);
                const isPositive = price >= 0;
                return isPositive;
              },
            },
          }}
          control={control}
          error={
            errors?.triggerEvents?.automatic &&
            errors?.triggerEvents?.automatic?.subtotalSpecificAmount?.minAmount
          }
          helperText={
            errors?.triggerEvents?.automatic &&
            errors?.triggerEvents?.automatic?.subtotalSpecificAmount?.minAmount
              ? errors?.triggerEvents?.automatic?.subtotalSpecificAmount.minAmount.message
              : ""
          }
          name="triggerEvents.automatic.subtotalSpecificAmount.minAmount"
          label="Minimum Amount"
          defaultValue={1}
          onValueChange={(e) => {
            onChange(e.value);
          }}
        />
        )}
      />
      <Controller
        name="triggerEvents.automatic.subtotalSpecificAmount.maxAmount"
        control={control}
        rules={{
          validate: {
            positive: (value) => {
              if(value.trim().length === 0){
                return true;
              }
              const price = CurrencyInputFormat(value);
              const isPositive = price >= 0;
              return isPositive;
            },
          },
        }}
        render={({ onChange, value }) => (
          <CurrencyInput
            control={control}
            error={
              errors?.triggerEvents?.automatic &&
              errors?.triggerEvents?.automatic?.subtotalSpecificAmount
                ?.maxAmount
            }
            helperText={
              errors?.triggerEvents?.automatic &&
              errors?.triggerEvents?.automatic?.subtotalSpecificAmount
                ?.maxAmount
                ? errors?.triggerEvents?.automatic?.subtotalSpecificAmount
                    .maxAmount.message
                : ""
            }
            name="triggerEvents.automatic.subtotalSpecificAmount.maxAmount"
            label="Maximum Amount"
            defaultValue={1}
            onValueChange={(e) => {
              onChange(e.value);
            }}
          />
        )}
      />
    </>
  );
}

export default SubtotalSpecifiAmount;
