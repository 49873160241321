import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { convert } from './';

const getEmployeesData = ({locationPersons, weeklyTotalValues}) => {
  const { result: table, weeklyValues } = locationPersons.reduce(({result, weeklyValues}, locationPerson) => {
    let totalOTfee = 0;
    let totalWHfee = 0;
    let totalFee = 0;
    let totalWhHours = 0;
    let totalOtHours = 0;
    let totalHours = 0;

    const dataToPush = locationPerson.node?.person?.timeshiftConnection?.edges?.reduce((timeshiftResult, timeshift) => {
      totalHours += timeshift?.node?.isFinished
        ? moment
          .duration(
            moment(timeshift?.node?.finished).diff(
              moment(timeshift?.node?.started)
            )
          )
          .asHours() : 0;
      totalOTfee += !timeshift?.node?.overtime?.error ? timeshift?.node?.overtime?.fee : 0;
      totalWHfee += !timeshift?.node?.wages?.error ? timeshift?.node?.wages?.fee : 0;
      totalFee += !timeshift?.node?.shiftTotal?.error ? timeshift?.node?.shiftTotal?.fee : 0;
      totalWhHours += !timeshift?.node?.wages?.error ? timeshift?.node?.wages?.hours : 0
      totalOtHours += !timeshift?.node?.overtime?.error ? timeshift?.node?.overtime?.hours : 0

      timeshiftResult.push([
        '',
        timeshift.node?.job?.label,
        moment.tz(moment.utc(timeshift?.node?.started), timeshift?.node?.location?.timezone?.label)
          .format('MM/DD/YYYY hh:mm A'),
        moment.tz(moment.utc(timeshift?.node?.finished), timeshift?.node?.location?.timezone?.label)
          .format('MM/DD/YYYY hh:mm A'),
        `$${convert.centsToDollars(timeshift?.node?.tip || 0)}`,
        `$${timeshift?.node?.wages?.rate || 0}`,
        `$${timeshift?.node?.overtime?.rate || 0}`,
        `$${convert.centsToDollars(timeshift?.node?.overtime?.fee || 0)}`,
        timeshift?.node?.wages?.hours ? convert.decimalHoursToHHmm(timeshift?.node?.wages?.hours) : 0,
        timeshift?.node?.overtime?.hours ? convert.decimalHoursToHHmm(timeshift?.node?.overtime?.hours) : 0,
        `$${convert.centsToDollars(timeshift?.node?.wages?.fee || 0)}`,
        timeshift?.node?.isFinished
          ? convert.decimalHoursToHHmm(moment
            .duration(
              moment(timeshift?.node?.finished).diff(
                moment(timeshift?.node?.started)
              )
            )
            .asHours()) : 0,
        `$${convert.centsToDollars(timeshift?.node?.shiftTotal?.fee || 0)}`
      ]);

      return timeshiftResult;
    }, []);

    if (!isEmpty(dataToPush)) {
      dataToPush.push([
        '',
        'Total:',
        '',
        '',
        '',
        '',
        '',
        `$${convert.centsToDollars(totalOTfee)}`,
        convert.decimalHoursToHHmm(totalWhHours),
        convert.decimalHoursToHHmm(totalOtHours),
        `$${convert.centsToDollars(totalWHfee)}`,
        convert.decimalHoursToHHmm(totalHours),
        `$${convert.centsToDollars(totalFee)}`,
      ]);
    }

    result.push(
      [`${locationPerson.node?.person?.firstName} ${locationPerson.node?.person?.lastName}`],
      ...dataToPush,
    );

      weeklyValues.allHours += totalHours;
      weeklyValues.allOtFee += totalOTfee;
      weeklyValues.allWhFee += totalWHfee;
      weeklyValues.allFee += totalFee;
      weeklyValues.allWhHours += totalWhHours;
      weeklyValues.allOtHours += totalOtHours;

    return { result, weeklyValues };
  }, { result :[], weeklyValues: weeklyTotalValues});

  table.push([
    '',
    'TOTAL:', 
    '',
    '',
    '',
    '',
    '',
    `$${convert.centsToDollars(weeklyValues.allOtFee)}`,
    convert.decimalHoursToHHmm(weeklyValues.allWhHours),
    convert.decimalHoursToHHmm(weeklyValues.allOtHours),
    `$${convert.centsToDollars(weeklyValues.allWhFee)}`,
    convert.decimalHoursToHHmm(weeklyValues.allHours),
    `$${convert.centsToDollars(weeklyValues.allFee)}`,
  ]);

  return table;
}

const generateOvertimeCSV = (locationPersons) => {
  const csvData = [];

  csvData.push([
    'Employee',
    'Job Name',
    'Clock In Dttm',
    'Clock Out Dttm',
    'Tips',
    'Shift Pay Rate', 
    'OT Pay Rate',
    'OT Pay Amt',
    'Shift Hour Duration',
    'OT Hour Duration',
    'Shift PAy Amt',
    'Total Hours',
    'Total Payment'
  ])
  csvData.push(...getEmployeesData({
    locationPersons,
    weeklyTotalValues: {
      allOtFee: 0,
      allWhHours: 0,
      allOtHours: 0,
      allWhFee: 0,
      allHours: 0,
      allFee: 0
    },
  }));

  return csvData;
};

export {
  generateOvertimeCSV
}
