import React, { useState } from 'react';
import { CurrencyInput, SelectFactory } from '../../../../../../../../blocks';
import { Button, Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from '../../../../../../../../../hooks/useSnackbar';
import { LOCATIONS } from '../../../../../../../../../apollo/queries';
import { compact } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { SNACKBAR_STATUS } from '../../../../../../../../../constants';
import CREATE_HOUSE_ACCOUNT from '../../../../../../../../../apollo/mutations/loyalty/createHouseAccount';
import LOYALTY_HOUSE_ACCOUNTS from '../../../../../../../../../apollo/queries/loyalty/loyaltyHouseAccounts';
import { useParams } from 'react-router-dom';
import Permission from '../../../../../../../../blocks/Permission';
import { CurrencyInputFormat } from '../../../../../../../../../utils';
import { convertHouseAccountValueForDatabase } from '../../../../../../../../../utils/houseAccountUtils';
import { limitGreaterOrEqualToZeroMessage } from '../../../../../../../../../constants/houseAccounts';

export default function AddHouseAccountModal({
  customerData,
  handleCloseModal,
  createHouseAccountPermission,
}) {
  const { handleSubmit, control, errors } = useForm();
  const { setSnackbar } = useSnackbar();
  const [newSelectedLocationId, setNewSelectedLocationId] = useState(null);
  const [createHouseAccount, { loading: createHouseAccountLoading }] = useMutation(
    CREATE_HOUSE_ACCOUNT,
    {
      update(cache, { data }) {
        cache.modify({
          id: cache.identify(data?.createHouseaccount?.viewer),
          fields: {
            ['houseaccountConnection']: existingItemtypeConnection => {
              return {
                ...existingItemtypeConnection,
                totalCount: existingItemtypeConnection.totalCount + 1,
                edges: [
                  ...existingItemtypeConnection.edges,
                  {
                    node: {
                      __ref: `houseaccountType:${data.createHouseaccount.id}`,
                    },
                  },
                ],
              };
            },
          },
        });
      },
    },
  );

  const { customerId } = useParams();
  const { data, loading, error } = useQuery(LOYALTY_HOUSE_ACCOUNTS, {
    variables: {
      sort: [
        {
          id: 'DESC',
        },
      ],
      filter: {
        loginId: {
          eq: parseInt(customerId),
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmit = ({ limit }) => {
    createHouseAccount({
      variables: {
        input: {
          locationId: newSelectedLocationId,
          loginId: customerData.node.login.id,
          limit: convertHouseAccountValueForDatabase(CurrencyInputFormat(limit)),
        },
      },
    })
      .then(() => {
        setSnackbar({
          text: 'House Account successfully added!',
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
        });
        handleCloseModal(true);
      })
      .catch(e =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        }),
      );
  };

  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;
  return (
    <Grid container style={{ flexDirection: 'column' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container style={{ marginTop: '1rem' }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{ required: 'Required' }}
              name="location"
              defaultValue={newSelectedLocationId}
              render={props => (
                <SelectFactory
                  customProps={props}
                  label={'Location'}
                  placeholder={'Select location'}
                  fullWidth={true}
                  defaultValue={newSelectedLocationId}
                  query={LOCATIONS}
                  filter={{
                    locationId: {
                      nin: data?.viewer?.houseaccountConnection?.edges.map(
                        edge => edge.node.location.locationId,
                      ),
                    },
                    isOpened: { eq: true },
                  }}
                  style={{ width: '100%' }}
                  multiple={false}
                  renderCheckBox={false}
                  structureData={data => {
                    return data?.viewer?.locationConnection?.edges.map((location, index) => {
                      return {
                        label: compact([location.node.label, location.node.storeNumber]).join(
                          ' - #',
                        ),
                        id: location.node.id,
                        index,
                      };
                    });
                  }}
                  onSelect={values => {
                    setNewSelectedLocationId(values?.id);
                  }}
                  error={!!errors?.location}
                  helperText={errors?.location && 'Location is required'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: '1rem 0' }}>
            <CurrencyInput
              control={control}
              rules={{
                validate: {
                  negative: value => {
                    const formattedValue = CurrencyInputFormat(value) || null;
                    return (
                      !formattedValue || formattedValue >= 0 || limitGreaterOrEqualToZeroMessage
                    );
                  },
                },
              }}
              label={'Limit'}
              error={!!errors.limit}
              helperText={errors?.limit?.message}
              fullWidth
              name="limit"
            />
          </Grid>
        </Grid>
        <Grid item justify="flex-end" spacing={3} xs={12}>
          <Grid item>
            <Button color="primary" variant="outlined" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Permission access={createHouseAccountPermission.access}>
              <Button
                disabled={createHouseAccountLoading}
                type="submit"
                style={{ marginLeft: '0.3rem' }}
              >
                Save
              </Button>
            </Permission>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
