import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Button,
  Container,
  Grid,
  DialogTitle,
  DialogContent,
  Dialog,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EditIcon from '@material-ui/icons/Edit';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { convert, toFixed10 } from '../../../../utils';
import Permission from '../../../blocks/Permission';
import { QuoteDetailStyles } from './styles';
import Payment from '../../../blocks/Payment';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../constants';
import { PermissionField } from '../../../blocks';
import { SET_QUOTE_TIP } from '../../../../apollo/mutations';
import { useSnackbar } from '../../../../hooks/useSnackbar';

const CATERING_TIP_PERCENTAGES = [0.15, 0.2, 0.25];

const QuoteDetail = ({ quote, accessToMutations, addTipPermission }) => {
  const classes = QuoteDetailStyles();

  const { setSnackbar } = useSnackbar();
  const [openPayTip, setOpenPayTip] = useState(false);
  const [openEditTip, setOpenEditTip] = useState(false);
  const [selectedTipAmount, setSelectedTipAmount] = useState(quote?.tip / 100);

  const { handleSubmit, register } = useForm();

  const tipValues = useMemo(
    () => CATERING_TIP_PERCENTAGES.map(percent => (quote?.subtotalPrice / 100) * percent),
    [quote],
  );

  const [setQuoteTip, { loading: setQuoteTipLoading }] = useMutation(SET_QUOTE_TIP, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Tip edited successfully.',
      });
      handleCloseEditTip();
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      });
    },
  });

  const handleOpenPayTip = () => {
    setOpenPayTip(true);
  };

  const handleClosePayTip = () => {
    setOpenPayTip(false);
  };

  const handleOpenEditTip = () => {
    if (!tipValues.includes(selectedTipAmount)) {
      setSelectedTipAmount('custom');
    }

    setOpenEditTip(true);
  };

  const handleCloseEditTip = () => {
    setOpenEditTip(false);
  };

  const handleTipSelection = (event, value) => {
    setSelectedTipAmount(value === 'custom' ? 'custom' : parseFloat(value));
  };

  useEffect(() => {
    if (openEditTip && selectedTipAmount === 'custom') {
      // setFocus from react-hook-form not working
      document.getElementById('customTipField').focus();
    }
  }, [selectedTipAmount, openEditTip]);

  const handleOnSubmit = useCallback(
    async formData => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Editing tip...',
        open: true,
      });

      const tip = selectedTipAmount === 'custom' ? formData.customTip : selectedTipAmount;

      await setQuoteTip({
        variables: {
          input: {
            id: quote.id,
            tip: convert.dollarsToCents(tip),
            isDashboardEditTip: true,
          },
        },
      });
    },
    [quote, selectedTipAmount],
  );

  const modalAddTip = () => {
    return (
      <div className={classes.widthModal}>
        <Payment
          quote={quote}
          paymentType={'tip'}
          handleClose={handleClosePayTip}
          accessToMutations={accessToMutations}
          addTipPermission={addTipPermission}
        />
      </div>
    );
  };

  const modalEditTip = () => {
    return (
      <Grid container spacing={2}>
        <Container>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid item xs={12}>
              <div
                style={{
                  marginBottom: '1rem',
                  marginTop: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormControl>
                  <FormLabel>Tip Amount</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    value={selectedTipAmount}
                    onChange={handleTipSelection}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <div>
                        <FormControlLabel
                          value={tipValues[0]}
                          name="tip"
                          style={{ marginRight: '5rem' }}
                          inputRef={register}
                          control={<Radio />}
                          label={`${Math.round(CATERING_TIP_PERCENTAGES[0] * 100)}% (${toFixed10(
                            tipValues[0],
                            2,
                          )} $)`}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          value={tipValues[1]}
                          name="tip"
                          style={{ marginRight: '5rem' }}
                          inputRef={register}
                          control={<Radio />}
                          label={`${Math.round(CATERING_TIP_PERCENTAGES[1] * 100)}% (${toFixed10(
                            tipValues[1],
                            2,
                          )} $)`}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          value={tipValues[2]}
                          name="tip"
                          style={{ marginRight: '5rem' }}
                          inputRef={register}
                          control={<Radio />}
                          label={`${Math.round(CATERING_TIP_PERCENTAGES[2] * 100)}% (${toFixed10(
                            tipValues[2],
                            2,
                          )} $)`}
                        />
                      </div>
                      <FormControlLabel
                        value={'custom'}
                        control={<Radio />}
                        label="Custom Amount"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
                <TextField
                  name="customTip"
                  inputRef={register}
                  disabled={selectedTipAmount !== 'custom'}
                  placeholder="$ 0.00"
                  variant="outlined"
                  id="customTipField"
                />
              </div>
            </Grid>

            <Grid container item xs={12} justify="flex-end" spacing={3}>
              <Grid item>
                <div>
                  <PermissionField createHelperText>
                    <Button type="submit" disabled={setQuoteTipLoading}>
                      Save
                    </Button>
                  </PermissionField>
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Permission access={quote ? quote?.permissions?.subtotalPrice : 7}>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              <p>Subtotal price</p>
            </div>
            <div className={classes.rowValueGray}>
              ${quote?.subtotalPrice ? convert.centsToDollars(quote?.subtotalPrice) : '0.00'}
            </div>
          </div>
        </Permission>
        <Permission access={quote ? quote?.permissions?.tip : 7}>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              <p>Tip</p>
            </div>
            <div className={`${classes.rowValueGray} ${classes.editDiv}`}>
              <IconButton
                variant="contained"
                onClick={() => handleOpenEditTip()}
                size={'small'}
                className={classes.editButton}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <span>${quote?.tip ? convert.centsToDollars(quote?.tip) : '0.00'}</span>
            </div>
          </div>
        </Permission>
        <Permission access={quote ? quote?.permissions?.discountAmount : 7}>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              <p>Discount</p>
            </div>
            <div className={classes.rowValueGray}>
              ${quote?.couponAmount ? convert.centsToDollars(quote?.couponAmount) : '0.00'}
            </div>
          </div>
        </Permission>
        <Permission access={quote ? quote?.permissions?.totalTax : 7}>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              <p>Total tax</p>
            </div>
            <div className={classes.rowValueGray}>
              ${quote?.totalTax ? convert.centsToDollars(quote?.totalTax) : '0.00'}
            </div>
          </div>
        </Permission>
        <Permission access={quote ? quote?.permissions?.additionalCharge : 7}>
          {quote?.additionalCharge?.serviceAndDeliveryFee ? (
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Service & Delivery fee</p>
              </div>
              <div className={classes.rowValueGray}>
                ${convert.centsToDollars(quote?.additionalCharge?.serviceAndDeliveryFee)}
              </div>
            </div>
          ) : null}

          {quote?.additionalCharge?.processingFee ? (
            <div className={classes.row}>
              <div className={classes.rowTitle}>
                <p>Renew/Recycle Program:</p>
              </div>
              <div className={classes.rowValueGray}>
                ${convert.centsToDollars(quote?.additionalCharge?.processingFee)}
              </div>
            </div>
          ) : null}
        </Permission>
        <Permission access={quote ? quote?.permissions?.totalPrice : 7}>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              <p>Total price</p>
            </div>
            <div className={classes.rowLarge}>
              ${quote?.totalPrice ? convert.centsToDollars(quote?.totalPrice) : '0.00'}
            </div>
          </div>
        </Permission>
        <Permission access={quote ? quote?.permissions?.dueAmount : 7}>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              <p>Due Amount</p>
            </div>
            <div className={classes.rowValue}>
              $
              {quote?.dueAmount
                ? convert.centsToDollars(quote?.dueAmount + quote?.refunded)
                : '0.00'}
            </div>
          </div>
        </Permission>
        <Permission
          access={Math.min(
            quote?.permissions?.tip,
            accessToMutations?.[MUTATION_NAME.CreateTipPaymentMutationInput],
          )}
        >
          <div className={classes.row} style={{ marginTop: '1rem' }}>
            <PermissionField createHelperText>
              <Button
                style={{ width: '9rem', marginLeft: '2rem' }}
                disabled={!quote || setQuoteTipLoading}
                onClick={() => handleOpenPayTip()}
              >
                <CreditCardIcon /> Add Tip
              </Button>
            </PermissionField>
          </div>
        </Permission>
      </div>
      <Dialog open={openEditTip} keepMounted onClose={handleCloseEditTip} maxWidth={'l'}>
        <Container>
          <DialogTitle>Edit Tip</DialogTitle>
        </Container>
        <DialogContent>
          <Permission access={quote?.permissions?.tip}>
            <div>{modalEditTip()}</div>
          </Permission>
        </DialogContent>
      </Dialog>
      <Dialog open={openPayTip} keepMounted onClose={handleClosePayTip} maxWidth={'xl'}>
        <Container>
          <DialogTitle className={classes.modalTitle}>Add Tip</DialogTitle>
        </Container>
        <DialogContent>
          <Permission access={quote?.permissions?.tip}>
            <div>{modalAddTip()}</div>
          </Permission>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default QuoteDetail;
