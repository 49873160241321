import { gql } from '@apollo/client';
import { jobFragment } from "../../fragments/jobFragment";

const JOBS_BY_LOCATION = gql`
  query JobsForLocation(
    $locationFilter: [JobFilter],
    $personFilter: [PersonJobFilter],
  ) {
    viewer {
      id
      jobConnection(filter: $locationFilter, fieldGroup: 68) {
        permissions
        edges {
          node {
            ...JobData
            personJobConnection(filter: $personFilter) {
              edges {
                node {
                  id
                  tipCredit
                  overtimeExempt
                  posManagerAccess
                  person {
                    id
                  }
                  salaryAmount
                  salaryType {
                    id
                    salarytypeId
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${jobFragment.job}
`;



export default JOBS_BY_LOCATION;
