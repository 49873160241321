import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import {
  CREATE_LOYALTY_SETTINGS,
  REMOVE_LOYALTYSETTINGS,
  UPDATE_LOYALTY_SETTINGS,
} from '../../../../apollo/mutations';
import { TIMEZONE_LIST } from '../../../../apollo/queries';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { formatConnectionName, uglifyId } from '../../../../utils';
import LoyaltySettingsView from './View';

function LoyaltySettings() {
  const { setSnackbar } = useSnackbar();
  const [loyaltyUpdateData, setLoyaltyUpdateData] = useState(null);
  const [open, setOpen] = useState(false);
  const [connectionVariables, setConnectionVariables] = useState({});
  const { data: timezoneData, loading: loadingTimezone, error } = useQuery(TIMEZONE_LIST);
  const [
    createLoyaltysettings,
    { error: createLoyaltyError, loading, data: createLoyaltyData, loading: createLoyaltyLoading },
  ] = useMutation(CREATE_LOYALTY_SETTINGS, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Loyalty Settings Created',
      });
      handleClose();
    },
    update: (cache, { data: { createLoyaltysettings } }) => {
      const { dynamicConnectionName } = formatConnectionName(
        'loyaltysettings',
        connectionVariables,
      );
      cache.modify({
        id: cache.identify(createLoyaltysettings.viewer),
        fields: {
          [dynamicConnectionName]: existingLoyaltysettings => {
            return {
              ...existingLoyaltysettings,
              totalCount: existingLoyaltysettings.totalCount + 1,
              edges: [
                ...existingLoyaltysettings.edges,
                {
                  node: {
                    __ref: `Loyaltysettings:${createLoyaltysettings.loyaltysettingsEdge.id}`,
                  },
                },
              ],
            };
          },
        },
      });
    },
    onError: error => {
      console.dir(error);
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
  });

  const [
    updateLoyaltysettings,
    { error: updateLoyaltyError, loading: updateLoyaltysettingsLoading, data: updateLoyaltyData },
  ] = useMutation(UPDATE_LOYALTY_SETTINGS, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Loyalty Settings Updated',
      });
      handleClose();
    },
    onError: error => {
      console.dir(error);
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
  });
  const [removeLoyaltysettings, { loading: removeLoyaltysettingsLoading }] = useMutation(
    REMOVE_LOYALTYSETTINGS,
    {
      onCompleted: data => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Loyalty Settings Removed',
        });
      },
      update: (cache, { data: { removeLoyaltysettings } }) => {
        const { dynamicConnectionName } = formatConnectionName(
          'loyaltysettings',
          connectionVariables,
        );
        cache.modify({
          id: cache.identify(removeLoyaltysettings.viewer),
          fields: {
            [dynamicConnectionName]: existingLoyaltysettings => {
              return {
                ...existingLoyaltysettings,
                totalCount: existingLoyaltysettings.totalCount - 1,
                edges: [
                  ...existingLoyaltysettings.edges.filter(
                    edge =>
                      edge.node.__ref !==
                      `Loyaltysettings:${uglifyId(
                        'Loyaltysettings',
                        removeLoyaltysettings.removedLoyaltysettingsId,
                      )}`,
                  ),
                ],
              };
            },
          },
        });
      },
    },
  );

  const handleClose = () => {
    setOpen(false);
    setLoyaltyUpdateData(null);
  };

  const handleOnCreateLoyaltysettings = data => {
    if (loyaltyUpdateData) {
      data.id = loyaltyUpdateData.loyaltysettingsId;
      updateLoyaltysettings({
        variables: {
          input: data,
        },
      });
      return;
    }

    createLoyaltysettings({
      variables: {
        input: data,
      },
    });
  };

  const onDeleteConfirmation = async (row, vars, refetch) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Deleting loyalty rule...',
    });

    await removeLoyaltysettings({
      variables: {
        input: { id: row.loyaltysettingsId },
      },
    });
  };

  const getStatus = status => {
    if (status) {
      return <span style={{ fontWeight: 'bold', color: 'green' }}>ACTIVE</span>;
    }
    return <span style={{ fontWeight: 'bold', color: 'red' }}>INACTIVE</span>;
  };

  const getWeekdays = weekdays => {
    let weekdays_temp = [];
    if (weekdays.edges.length) {
      weekdays_temp = weekdays.edges.map(day => `${day.node.weekday.abbreviation1}, `);
      weekdays_temp[weekdays_temp.length - 1] = weekdays_temp[weekdays_temp.length - 1].replace(
        ', ',
        '',
      );
      return weekdays_temp;
    }
    return '/';
  };

  return (
    <LoyaltySettingsView
      getStatus={getStatus}
      getWeekdays={getWeekdays}
      handleClose={handleClose}
      loadingTimezone={loadingTimezone}
      loyaltyUpdateData={loyaltyUpdateData}
      onDeleteConfirmation={onDeleteConfirmation}
      open={open}
      removeLoyaltysettingsLoading={removeLoyaltysettingsLoading}
      setLoyaltyUpdateData={setLoyaltyUpdateData}
      setOpen={setOpen}
      timezoneData={timezoneData}
      handleOnCreateLoyaltysettings={handleOnCreateLoyaltysettings}
      setConnectionVariables={setConnectionVariables}
      createLoyaltyLoading={createLoyaltyLoading}
      updateLoyaltysettingsLoading={updateLoyaltysettingsLoading}
    />
  );
}

export default LoyaltySettings;
