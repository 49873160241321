import { gql } from '@apollo/client';

const FETCH_LOCATION_INVENTORY_COUNT_ITEMS = gql`
  query FETCH_LOCATION_INVENTORY_COUNT_ITEMS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationSort]
    $filter: [LocationFilter]
    $locationId: Int!
  ) {
    viewer {
      id
      locationStorageConnection(
        filter: { locationId: { eq: $locationId }, status: { eq: 1 } }
        fieldGroupOnly: 88
      ) {
        edges {
          node {
            id
            locationStorageId
            customLabel
            storage {
              label
            }
          }
        }
      }
      locationConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 88
      ) {
        edges {
          node {
            inventoryCountStatus
            inventorycount {
            totalInventorycountCost
              inventorycountId
              inventorycountLocationStorageInventoryitemConnection {
                edges {
                  node {
                    locationStorageInventoryitemId
                    measureId
                    quantity
                    inventorycountId
                    calculatedCost
                    locationStorageInventoryitem {
                      locationStorage {
                        customLabel
                        locationStorageId
                      }
                      inventoryitemId
                      inventoryitem {
                        label
                      }
                    }
                    measure {
                      abbreviation
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_LOCATION_INVENTORY_COUNT_ITEMS;
