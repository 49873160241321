import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import DateTime from 'luxon/src/datetime';
import { Divider } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

import Modal from '../../../blocks/Modal';
import PaperMain from '../../../blocks/PaperMain';

import ScheduleHeader from './ScheduleHeader';
import CreateEmployeeSchedule from './ModifySchedule';
import SalesProjections from './SalesProjections';
import ScheduleLabor from './ScheduleLabor';
import ScheduleTimeline from './ScheduleTimeline';
import EmployeeJobsTotal from './EmployeesJobsTotal';
import CopyScheduleModal from './CopyScheduleModal';

import {
  FETCH_SCHEDULE_PROJECTIONS,
  FETCH_SCHEDULES,
  FETCH_SMOKESTACK_DATA,
} from '../../../../apollo/queries';

import { useFormSubmitPermissions } from '../../../../hooks';
import { MUTATION_NAME } from '../../../../constants';
import { CoreContext } from '../../../../CoreContext';
import { useSnackbar } from '../../../../hooks/useSnackbar';

const Schedule = () => {
  const context = useContext(CoreContext);
  const mutationPermissions = context?.coreUtils?.getAppActions()[62]?.mutations;
  const { setSnackbar } = useSnackbar();

  const copySchedulePermission = useMemo(
    () =>
      mutationPermissions.find(mutationPermission => mutationPermission.label === 'copySchedule'),
    [mutationPermissions],
  );
  const exportSchedulePDFPermission = useMemo(
    () =>
      mutationPermissions.find(
        mutationPermission => mutationPermission.label === 'exportSchedulePDF',
      ),
    [mutationPermissions],
  );

  const [scheduleDialogData, setScheduleDialogData] = useState({
    person: undefined,
    location: undefined,
  });
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [selectedLocationState, setSelectedLocationState] = useState();
  const [selectedWeek, setSelectedWeek] = useState(DateTime.local());
  const [permissions, setPermissions] = useState();
  const [schedulePermissions, setSchedulePermissions] = useState();
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [isWeekCopy, setIsWeekCopy] = useState(true);
  const [smokestackWeekTotal, setSmokestackWeekTotal] = useState();
  const [employeeJobHourTotals, setEmployeeJobHourTotals] = useState();
  const [allJobs, setAllJobs] = useState();
  const [projectionInputs, setProjectionInputs] = useState([]);
  const [timelineVisibleDate, setVisibleDayStart] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.CreateScheduleweeklysalesprojectionMutationInput,
      MUTATION_NAME.UpdateScheduleweeklysalesprojectionMutationInput,
      MUTATION_NAME.CopyScheduleMutationInput,
      MUTATION_NAME.CreateScheduleMutationInput,
      MUTATION_NAME.UpdateScheduleMutationInput,
    ],
    permissions: {
      ...(permissions || {}),
      ...(schedulePermissions || {}),
    },
  });

  const [
    fetchSchedule,
    { data: schedules, loading: schedulesLoading, variables: scheduleQueryVariables },
  ] = useLazyQuery(FETCH_SCHEDULES, {
    onCompleted: data => {
      if (!schedulePermissions) {
        setSchedulePermissions(data?.viewer?.scheduleConnection?.permissions);
      }
    },
  });

  const [
    fetchSmokestackData,
    { loading: smokestackDataLoading, data: smokestackData },
  ] = useLazyQuery(FETCH_SMOKESTACK_DATA, {
    notifyOnNetworkStatusChange: true,
    variables: {
      auvFilter: {
        locationId: { eq: selectedLocationState?.locationId },
        startOfWeek: { eq: selectedWeek.toISODate() },
        endOfWeek: { eq: selectedWeek.plus({ days: 6 }).toISODate() },
      },
      locFilter: { locationId: { eq: selectedLocationState?.locationId } },
      avgSalesDate: moment()
        .startOf('isoWeek')
        .add(selectedLocationState?.workWeekStartId - 1, 'days')
        .format('YYYY-MM-DD'),
    },
    onCompleted: data => {
      const total =
        data?.viewer?.auvCommitmentsEdwConnection?.edges?.length > 0
          ? data?.viewer?.auvCommitmentsEdwConnection?.edges?.reduce((prev, curr) => {
              if (prev?.node?.salesAmt) {
                return prev.node.salesAmt + curr.node.salesAmt;
              }
              return prev + curr.node.salesAmt;
            })
          : 0;
      setSmokestackWeekTotal(total * 100);
    },
    onError: msg => {
      setSnackbar({
        type: 'error',
        text: `Error when pulling smokestack data: ${msg}`,
        open: true,
      });
    },
  });

  const [
    fetchWeeklyScheduleProjections,
    {
      loading: weeklyScheduleProjectionsLoading,
      data: weeklyScheduleProjections,
      variables: fetchWeeklyScheduleProjectionsVariables,
    },
  ] = useLazyQuery(FETCH_SCHEDULE_PROJECTIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      weeklySaleProjectionsFilter: {
        locationId: { eq: selectedLocationState?.locationId },
        started: { eq: selectedWeek.toISODate() },
      },
    },
    onCompleted: data => {
      if (!permissions) {
        setPermissions(data?.viewer?.scheduleweeklysalesprojectionConnection?.permissions);
      }
      const weeklyScheduleProjectionId =
        data?.viewer.scheduleweeklysalesprojectionConnection?.edges[0]?.node
          ?.scheduleweeklysalesprojectionId;
      if (weeklyScheduleProjectionId) {
        setOpenWarningMutationName(MUTATION_NAME.UpdateScheduleweeklysalesprojectionMutationInput);

        fetchSchedule({
          variables: {
            scheduleFilter: {
              scheduleweeklysalesprojectionId: {
                eq:
                  data?.viewer?.scheduleweeklysalesprojectionConnection?.edges[0]?.node
                    ?.scheduleweeklysalesprojectionId,
              },
              locationId: { eq: selectedLocationState?.locationId },
            },
            personJobFilter: {
              locationId: { eq: selectedLocationState?.locationId },
            },
          },
        });
      } else {
        setOpenWarningMutationName(MUTATION_NAME.CreateScheduleweeklysalesprojectionMutationInput);
      }

      if (data?.viewer?.scheduleweeklysalesprojectionConnection?.edges?.length === 0) {
        fetchSmokestackData();
      }
    },
  });

  const prettySchedulesData = schedules?.viewer?.scheduleConnection?.edges;

  const onLocationChange = useCallback(async location => {
    let startingWeekForLocation = DateTime.local().setZone(location.node.timezone.label);
    const startingWeekDayForLocation =
      typeof location.node.workWeekStartId == 'number' ? location.node.workWeekStartId : 1;
    if (
      startingWeekDayForLocation &&
      startingWeekForLocation.weekday !== startingWeekDayForLocation
    ) {
      do {
        startingWeekForLocation = startingWeekForLocation.minus({ days: 1 });
      } while (startingWeekForLocation.weekday !== startingWeekDayForLocation);
    }

    setSelectedWeek(startingWeekForLocation);
    setSelectedLocationState({
      ...location.node,
    });
    fetchSmokestackData();
    fetchWeeklyScheduleProjections();
  }, []);

  const onDateChange = useCallback(
    date => {
      if (selectedLocationState?.locationId) {
        fetchSmokestackData();
        fetchWeeklyScheduleProjections();
        setSelectedWeek(date);
      }
    },
    [selectedLocationState],
  );

  useEffect(() => {
    setVisibleDayStart(
      moment.tz(selectedWeek, selectedLocationState?.timezone?.label).startOf('day'),
    );
  }, [selectedWeek, selectedLocationState]);

  const sortedWeekdays = useMemo(() => {
    const sortedWeekdaysTemp = [selectedWeek?.weekdayLong || 'Monday'];
    let referenceDate = selectedWeek;

    for (let i = 1; i < 7; i++) {
      referenceDate = referenceDate?.plus({ days: 1 });
      sortedWeekdaysTemp.push(referenceDate?.weekdayLong);
    }

    return sortedWeekdaysTemp;
  }, [selectedWeek]);

  return (
    <>
      <PaperMain>
        <ScheduleHeader
          onLocationChange={onLocationChange}
          selectedLocationState={selectedLocationState}
          selectedWeek={selectedWeek}
          onDateChange={onDateChange}
        />
        {!!selectedLocationState?.locationId && (
          <>
            <SalesProjections
              fetchSchedule={fetchSchedule}
              selectedLocationState={selectedLocationState}
              selectedWeek={selectedWeek}
              smokestackData={smokestackData}
              smokestackWeekTotal={smokestackWeekTotal}
              smokestackDataLoading={smokestackDataLoading}
              weeklyScheduleProjections={weeklyScheduleProjections}
              weeklyScheduleProjectionsLoading={weeklyScheduleProjectionsLoading}
              fetchWeeklyScheduleProjectionsVariables={fetchWeeklyScheduleProjectionsVariables}
              fetchWeeklyScheduleProjections={fetchWeeklyScheduleProjections}
              accessToMutations={accessToMutations}
              mutationPermissions={mutationPermissions}
              sortedWeekdays={sortedWeekdays}
              projectionInputs={projectionInputs}
              setProjectionInputs={setProjectionInputs}
            />
            <Divider />

            {weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection?.edges
              ?.length > 0 && (
              <>
                <ScheduleLabor
                  prettySchedulesData={prettySchedulesData}
                  schedulesLoading={schedulesLoading}
                  selectedLocationState={selectedLocationState}
                  weeklyScheduleProjections={weeklyScheduleProjections}
                  weeklyScheduleProjectionsLoading={weeklyScheduleProjectionsLoading}
                  smokestackData={smokestackData}
                  sortedWeekdays={sortedWeekdays}
                  projectionInputs={projectionInputs}
                  salaryPermission={
                    schedules?.viewer?.scheduleConnection?.permissions?.person?.personJobConnection
                      ?.edges?.node?.salaryAmount
                  }
                />
                <Divider />
                <ScheduleTimeline
                  setScheduleDialogData={setScheduleDialogData}
                  setIsScheduleModalVisible={setIsScheduleModalVisible}
                  setOpenWarningMutationName={setOpenWarningMutationName}
                  setIsWeekCopy={setIsWeekCopy}
                  setIsCopyModalVisible={setIsCopyModalVisible}
                  prettySchedulesData={prettySchedulesData}
                  exportSchedulePDFPermission={exportSchedulePDFPermission}
                  copySchedulePermission={copySchedulePermission}
                  selectedLocationState={selectedLocationState}
                  selectedWeek={selectedWeek}
                  schedules={schedules}
                  employeeJobHourTotals={employeeJobHourTotals}
                  scheduleQueryVariables={scheduleQueryVariables}
                  mutationPermissions={mutationPermissions}
                  accessToMutations={accessToMutations}
                  timelineVisibleDate={timelineVisibleDate}
                  setVisibleDayStart={setVisibleDayStart}
                />
                <Divider />
                {prettySchedulesData?.length > 0 && (
                  <EmployeeJobsTotal
                    prettySchedulesData={prettySchedulesData}
                    schedulesLoading={schedulesLoading}
                    setEmployeeJobHourTotals={setEmployeeJobHourTotals}
                    setAllJobs={setAllJobs}
                    permissions={permissions}
                  />
                )}
                <Modal
                  open={isCopyModalVisible}
                  handleClose={() => {
                    setIsCopyModalVisible(false);
                  }}
                  title="Copy schedule"
                  maxWidth={isWeekCopy ? 'xs' : 'sm'}
                >
                  <CopyScheduleModal
                    scheduleweeklysalesprojectionId={
                      weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection
                        ?.edges[0]?.node?.id
                    }
                    selectedWeek={selectedWeek}
                    setIsVisibleState={setIsCopyModalVisible}
                    copySchedulePermission={copySchedulePermission}
                    isWeekCopy={isWeekCopy}
                    timelineVisibleDate={timelineVisibleDate}
                    refetchScheduleVariables={scheduleQueryVariables}
                  />
                </Modal>
                <Modal
                  open={isScheduleModalVisible}
                  handleClose={() => {
                    setIsScheduleModalVisible(false);
                  }}
                  title={`${scheduleDialogData?.person?.firstName} ${scheduleDialogData?.person?.lastName}`}
                >
                  <CreateEmployeeSchedule
                    createScheduleData={{
                      employee: scheduleDialogData?.person,
                      locationId: selectedLocationState?.id,
                      auvProjectionId:
                        weeklyScheduleProjections?.viewer?.scheduleweeklysalesprojectionConnection
                          ?.edges[0]?.node?.id,
                      selectedWeek: selectedWeek,
                      viewer: weeklyScheduleProjections?.viewer,
                    }}
                    setIsVisibleState={setIsScheduleModalVisible}
                    mutationPermissions={mutationPermissions}
                    refetchScheduleVariables={scheduleQueryVariables}
                    locationTimezone={selectedLocationState.timezone.label}
                    schedulePermissions={schedules?.viewer?.scheduleConnection?.permissions}
                    timelineVisibleDate={timelineVisibleDate}
                    setVisibleDayStart={setVisibleDayStart}
                    selectedWeek={selectedWeek}
                  />
                </Modal>
              </>
            )}
          </>
        )}
      </PaperMain>
    </>
  );
};
export default Schedule;
