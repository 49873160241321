import React, { useCallback, useEffect } from 'react';
import Timeline, { CustomHeader, TimelineHeaders } from 'react-calendar-timeline';
import Grid from '@material-ui/core/Grid';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import { find, filter } from 'lodash';

import { ScheduleTimelineStyles } from './styles';

import 'react-calendar-timeline/lib/Timeline.css';
import Permission from '../../../../../../blocks/Permission';

const CalendarScheduleTimelineView = ({
  scheduleData,
  schedulePermissions,
  timelineVisibleDate,
  onIncrementDate,
  onDecreaseDate,
  prepareForScheduleEdit,
  jobList,
  formattedCurrency,
  locationTimezone,
}) => {
  const classes = ScheduleTimelineStyles();
  const itemPadding = 20;

  let items = [];
  let groups = [];

  // Order start and end times chronologically and count the maximum number of overlaps
  const calculateMaximumNumberOfOverlaps = useCallback(personItems => {
    let items = [];
    // Store start and end times of each shift
    personItems.forEach(item => {
      items.push([item.start_time, 'startTime']);
      items.push([item.end_time, 'endTime']);
    });

    items.sort((a, b) => {
      if (a[0].isSame(b[0], 'hour')) {
        return 0;
      }

      return a[0].isBefore(b[0]) ? -1 : 1;
    });

    let maxNumberOfOverlaps = 0;
    let overlapCount = 0;
    items.forEach(timePoint => {
      // New shift occurs
      if (timePoint[1] === 'startTime') {
        overlapCount++;
      }

      // Shift has ended
      if (timePoint[1] === 'endTime') {
        overlapCount--;
      }

      // Check if current overlap count is greater than the max
      maxNumberOfOverlaps = Math.max(maxNumberOfOverlaps, overlapCount);
    });

    return maxNumberOfOverlaps;
  }, []);

  const populateItemsGroups = useCallback(
    schedule => {
      const SINGLE_ITEM_HEIGHT = 40;
      const firstName = schedule.person.firstName;
      const lastName = schedule.person.lastName;
      const personJob = schedule.person.personJobConnection.edges.filter(
        elem => elem.node?.job?.jobId === schedule.jobId,
      )[0];
      const salaryAmountCents = personJob?.node.salaryAmount;
      const jobLabel = personJob?.node.job?.label;
      const jobColor = personJob?.node.job?.color?.code;

      let scheduleStartTime = moment.utc(schedule.started).tz(locationTimezone);
      let scheduleEndTime = moment.utc(schedule.finished).tz(locationTimezone);

      // Group should be unique for every employee
      if (!find(groups, group => group.personId === schedule.personId)) {
        groups.push({
          id: groups.length,
          title: `${schedule.person.firstName} ${schedule.person.lastName}`,
          stackItems: true,
          height: SINGLE_ITEM_HEIGHT,
          personId: schedule.personId,
        });
      }

      const item = {
        id: schedule.scheduleId,
        group: groups.find(group => group.personId === schedule.personId)?.id,
        start_time: scheduleStartTime,
        end_time: scheduleEndTime,
        canMove: false,
        canResize: false,
        canChangeGroup: false,
        canSelect: true,
        color: jobColor || 'black',
        // Custom properties
        firstName: firstName,
        lastName: lastName,
        personId: schedule.personId,
        jobLabel: jobLabel,
        salaryAmountCents: salaryAmountCents,
        workingHoursString: `${scheduleStartTime.format('LT')}-${scheduleEndTime.format('LT')}`,
        schedulePermissions: schedulePermissions,
      };
      items.push(item);

      //Update height
      groups = groups.map(elem => {
        if (elem.personId === schedule.personId) {
          const personItems = filter(items, item => item.personId === schedule.personId);
          const personItemsCurrentDate = filter(personItems, item =>
            item.start_time.isSame(timelineVisibleDate, 'day'),
          ).sort((a, b) => {
            if (a.start_time.isSame(b.start_time, 'hour')) {
              if (a.end_time.isSame(b.end_time, 'hour')) {
                return 0;
              }
              return a.end_time.isBefore(b.end_time) ? -1 : 1;
            }

            return a.start_time.isBefore(b.start_time) ? -1 : 1;
          });

          const maxNumberOfOverlaps = calculateMaximumNumberOfOverlaps(personItemsCurrentDate);
          const height = maxNumberOfOverlaps * SINGLE_ITEM_HEIGHT || SINGLE_ITEM_HEIGHT;
          return { ...elem, height };
        } else {
          return { ...elem };
        }
      });
    },
    [locationTimezone, groups, items, calculateMaximumNumberOfOverlaps],
  );

  scheduleData?.forEach(scheduleEdge => {
    populateItemsGroups(scheduleEdge.node);
  });

  const textToBeShown = useCallback((text, itemWidth, isWorkingHours = false) => {
    const avgCharWidth = isWorkingHours ? 9 : 8;
    const charsToRender = Math.round((itemWidth - itemPadding) / avgCharWidth);
    return text.slice(0, charsToRender);
  }, []);

  const accomodateTextToWidth = useCallback(
    (item, itemWidth, name, jobTitle, salaryAmount) => {
      const textToRender = textToBeShown(`${name} ${jobTitle} ${salaryAmount}`, itemWidth);

      if (textToRender?.length < name?.length) {
        return (
          <Permission
            access={Math.min(
              item?.schedulePermissions?.person?.firstName,
              item?.schedulePermissions?.person?.lastName,
            )}
          >
            <label
              style={{
                marginLeft: '1.5px',
                fontFamily: 'Lato',
                ontWeight: 'Bold',
                fontSize: '12px',
              }}
            >
              {name?.slice(0, textToRender?.length)}...
            </label>
          </Permission>
        );
      }

      if (textToRender?.length < `${name} ${jobTitle}`?.length) {
        return (
          <>
            <Permission
              access={Math.min(
                item?.schedulePermissions?.person?.firstName,
                item?.schedulePermissions?.person?.lastName,
              )}
            >
              <label
                style={{
                  marginLeft: '1.5px',
                  fontFamily: 'Lato',
                  ontWeight: 'Bold',
                  fontSize: '12px',
                }}
              >
                {name}
              </label>
            </Permission>
            <label
              style={{
                marginLeft: '1.5px',
                fontFamily: 'Lato',
                ontWeight: 'Bold',
                fontSize: '12px',
              }}
            >
              {jobTitle?.slice(0, textToRender?.length - name?.length)}...
            </label>
          </>
        );
      }

      if (textToRender?.length < `${name} ${jobTitle} ${salaryAmount}`?.length) {
        return (
          <>
            <Permission
              access={Math.min(
                item?.schedulePermissions?.person?.firstName,
                item?.schedulePermissions?.person?.lastName,
              )}
            >
              <label
                style={{
                  marginLeft: '1.5px',
                  fontFamily: 'Lato',
                  ontWeight: 'Bold',
                  fontSize: '12px',
                }}
              >
                {name}
              </label>
            </Permission>
            <label
              style={{
                marginLeft: '1.5px',
                fontFamily: 'Lato',
                ontWeight: 'Bold',
                fontSize: '12px',
              }}
            >
              {jobTitle}
            </label>
            <Permission
              access={
                item?.schedulePermissions?.person?.personJobConnection?.edges?.node?.salaryAmount
              }
            >
              <label style={{ marginLeft: '1.5px', fontSize: '12px' }}>
                {salaryAmount?.slice(0, textToRender?.length - name?.length - jobTitle?.length)}...
              </label>
            </Permission>
          </>
        );
      }

      return (
        <>
          <Permission
            access={Math.min(
              item?.schedulePermissions?.person?.firstName,
              item?.schedulePermissions?.person?.lastName,
            )}
          >
            <label
              style={{
                marginLeft: '1.5px',
                fontFamily: 'Lato',
                ontWeight: 'Bold',
                fontSize: '12px',
              }}
            >
              {name}
            </label>
          </Permission>
          <label
            style={{
              marginLeft: '1.5px',
              fontFamily: 'Lato',
              ontWeight: 'Bold',
              fontSize: '12px',
            }}
          >
            {jobTitle}
          </label>
          <Permission
            access={
              item?.schedulePermissions?.person?.personJobConnection?.edges?.node?.salaryAmount
            }
          >
            <label style={{ marginLeft: '1.5px', fontSize: '12px' }}>{salaryAmount}</label>
          </Permission>
        </>
      );
    },
    [textToBeShown],
  );

  const itemRenderer = useCallback(
    ({ item, itemContext, getItemProps, getResizeProps }) => {
      const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

      const workingHoursToRender = textToBeShown(item.workingHoursString, itemContext.width, true);
      const startEndTimeDiff = moment.duration(item.end_time.diff(item.start_time)).asHours();

      return (
        <div
          {...getItemProps()}
          style={{
            ...getItemProps().style,
            borderRadius: '30px',
            height: '32px',
            lineHeight: '14px',
            backgroundColor: 'white',
            color: 'black',
            borderColor: item.color,
            borderWidth: '2px 2px 2px 2px',
          }}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
          <div
            style={{
              paddingLeft: startEndTimeDiff > 1 ? `${itemPadding}px` : `${itemPadding / 3}px`,
              alignContent: 'center',
              height: '30px',
              whiteSpace: 'nowrap',
              marginLeft: 'auto',
              display: 'block',
              alignItems: 'center',
            }}
          >
            <Grid container xs={1} style={{ display: 'block' }}>
              <Grid item>
                {accomodateTextToWidth(
                  item,
                  itemContext.width,
                  `${item.firstName} ${item.lastName}`,
                  item.jobLabel,
                  formattedCurrency(item.salaryAmountCents),
                )}
              </Grid>
              <Grid item>
                <label
                  style={{
                    marginLeft: '1.5px',
                    fontFamily: 'Lato',
                    ontWeight: 'Bold',
                    fontSize: '12px',
                  }}
                >
                  {workingHoursToRender}
                  {workingHoursToRender?.length < item?.workingHoursString?.length && '...'}
                </label>
              </Grid>
            </Grid>
          </div>
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
      );
    },
    [textToBeShown, accomodateTextToWidth, formattedCurrency, items, groups],
  );

  return (
    <>
      <Grid container className={classes.timelineHeader}>
        <Grid item xs={3} className={classes.dayPicker}>
          <ArrowBackIosIcon onClick={onDecreaseDate} />
          <CalendarTodayIcon />
          <label>{timelineVisibleDate.format('ddd MMM DD,YYYY')}</label>
          <ArrowForwardIosIcon onClick={onIncrementDate} />
        </Grid>
        <Grid item xs={9} className={classes.positions}>
          {jobList?.map(job => {
            return (
              <div className={classes.position}>
                <span
                  className={classes.positionBadge}
                  style={{ backgroundColor: job?.node?.color?.code || 'black' }}
                ></span>
                <span className={classes.positionLabel}>{job?.node?.label}</span>
              </div>
            );
          })}
        </Grid>
      </Grid>

      <Timeline
        groups={groups}
        items={items}
        visibleTimeStart={timelineVisibleDate.valueOf()}
        visibleTimeEnd={timelineVisibleDate.endOf('day').valueOf()}
        unit={'hour'}
        sidebarWidth={0}
        itemHeightRatio={0.75}
        itemTouchSendsClick={true}
        canMove={false}
        canResize={false}
        itemsSorted
        showCursorLine
        stackItems
        onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) =>
          updateScrollCanvas(
            moment(timelineVisibleDate).valueOf(),
            moment(timelineVisibleDate)
              .endOf('day')
              .valueOf(),
          )
        }
        minZoom={7 * 24 * 60 * 60 * 1000}
        maxZoom={7 * 24 * 60 * 60 * 1000}
        onItemClick={itemId => {
          prepareForScheduleEdit(itemId);
        }}
        itemRenderer={itemRenderer}
      >
        <TimelineHeaders calendarHeaderStyle={{ backgroundColor: 'white', width: '40px' }}>
          <CustomHeader height={50}>
            {({ headerContext: { intervals }, getRootProps, getIntervalProps }) => {
              return (
                <div {...getRootProps()}>
                  {intervals.map(interval => {
                    const intervalStyle = {
                      lineHeight: '50px',
                      width: '50px',
                      textAlign: 'left',
                      borderLeft: '1px solid black',
                      backgroundColor: '#F4F4F9',
                      fontSize: '12px',
                      padding: '4px',
                    };
                    return (
                      <div
                        {...getIntervalProps({
                          interval,
                          style: intervalStyle,
                        })}
                      >
                        <div>
                          {moment(interval.startTime)
                            .tz(locationTimezone)
                            .format('h:mm A')}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          </CustomHeader>
        </TimelineHeaders>
      </Timeline>
    </>
  );
};

export default CalendarScheduleTimelineView;
