import { gql } from '@apollo/client';

const LOYALTY_HOUSE_ACCOUNTS = gql`
  query HouseAccountQuery(
    $first: Int
    $last: Int
    $filter: [HouseaccountFilter]
    $after: String
    $sort: [HouseaccountSort]
    $before: String
    $search: String
    $fieldGroup: Int
    $fieldGroupOnly: Int
  ) {
    viewer {
      id
      houseaccountConnection(
        filter: $filter
        sort: $sort
        first: $first
        last: $last
        after: $after
        before: $before
        search: $search
        fieldGroup: $fieldGroup
        fieldGroupOnly: $fieldGroupOnly
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        permissions
        edges {
          node {
            id
            houseaccountId
            balance
            limit
            loginId
            location {
              id
              label
              locationId
              isOpened
            }
            login {
              id
              loginId
              person {
                id
                personId
                firstName
                lastName
                stripeAccount
              }
            }
          }
        }
      }
    }
  }
`;

export default LOYALTY_HOUSE_ACCOUNTS;
