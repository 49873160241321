import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { UPDATE_PRINTERSTATION_SUB_LOC } from "../../../../../../../apollo/mutations/locations/POSSettings/updatePrinterstationSubcategoryLocation";
import { Permission, PermissionField } from '../../../../../../blocks';
import { useSnackbar } from "../../../../../../../hooks/useSnackbar";

function SubcategorySetting({ sub, permissions }) {
  const { setSnackbar } = useSnackbar();
  const { locationId } = useParams();

  const [printerValue, setPrinterValue] = useState(
    sub.subcategoryPrinterstationLocation
      ? sub.subcategoryPrinterstationLocation.printerstationId
      : 0
  );
  const [disabledRadio, setDisabledRadio] = useState(false);

  const [updateMutation] = useMutation(UPDATE_PRINTERSTATION_SUB_LOC, {
    onCompleted: (data) => {
      setPrinterValue(
        data.updatePrinterstation.printerstationSubcategoryLocationEdge.node
          .printerstationId
      );
      setSnackbar({
        open: true,
        type: "success",
        text: "Category print setting updated",
      });
      setDisabledRadio(false);
    },
    onError: (error) => {
      console.log(error);
      setSnackbar({
        open: true,
        type: "error",
        text: "Oops, something went wrong...",
      });
      setDisabledRadio(false);
    },
  });
  const handleChange = async (e) => {
    setDisabledRadio(true);
    setSnackbar({
      open: true,
      type: "info",
      text: "Updating Category print setting...",
    });
    await updateMutation({
      variables: {
        input: {
          locationId: +locationId,
          subcategoryId: +sub.subcategoryId,
          printerstationId: +e.target.value,
        },
      },
    });
  };

  return (
    <div>
      <FormControl
        component="div"
        style={{
          flexDirection: "row",
          gap: "0.5rem",
          justifyContent: "space-between",
          minWidth: "500px",
        }}
      >
        <Permission access={permissions?.menu?.menuCategoryConnection?.edges?.node?.category?.subcategoryConnection?.edges?.node?.subcategoryPrinterstationLocation?.__typename}>
          <FormLabel component="p">{sub.subcategoryName}</FormLabel>
          <div>
            <PermissionField
              createHelperText
              Component={RadioGroup}
              onChange={handleChange}
              value={printerValue}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                disabled={disabledRadio}
                value={0}
                control={<Radio />}
                label="None"
              />
              <FormControlLabel
                disabled={disabledRadio}
                value={2}
                control={<Radio />}
                label="Kitchen"
              />
              <FormControlLabel
                disabled={disabledRadio}
                value={3}
                control={<Radio />}
                label="Bar"
              />
            </PermissionField>
          </div>
        </Permission>
      </FormControl>
    </div>
  );
}

export default SubcategorySetting;
