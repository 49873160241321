import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Container, CircularProgress, Divider } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import BankSettings from './BankSettings';
import LocationHours from './LocationHours';
import LocationInfo from './LocationInfo';
import TaxRates from './TaxRates';
import TaxGroups from './TaxGroups';
import PaymentProcessors from './PaymentProcessors';

import { LocationIdContext } from '../index';

import {
  FETCH_LOCATION,
  FETCH_LOCATION_PERMISSIONS,
  FETCH_WEEKDAYS,
} from '../../../../../apollo/queries';
import { CoreContext } from '../../../../../CoreContext';
import { useFormSubmitPermissions } from '../../../../../hooks';
import { MUTATION_NAME } from '../../../../../constants';

const GeneralInfo = () => {
  const locationId = useContext(LocationIdContext);
  const context = useContext(CoreContext);
  const mutationPermissions = useMemo(
    () => [
      ...(context?.coreUtils?.getAppActions()[13]?.mutations || []),
      ...(context?.coreUtils?.getAppActions()[17]?.mutations || []),
    ],
    [context?.coreUtils],
  );

  const [mutationAccessPermissions, setMutationAccessPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.UpdateLocationMutationInput,
      MUTATION_NAME.CreateLocationMutationInput,
      MUTATION_NAME.CreateTestPrintMutationInput,
      MUTATION_NAME.UpdateLocationWeekdayMutationInput,
      MUTATION_NAME.CreateTaxrateMutationInput,
      MUTATION_NAME.UpdateTaxrateMutationInput,
      MUTATION_NAME.CreateTaxgroupMutationInput,
      MUTATION_NAME.UpdateTaxgroupMutationInput,
      MUTATION_NAME.CreateLocationStripeMutationInput,
    ],
    permissions: mutationAccessPermissions,
    changeFieldLabel: [
      {
        mutationName: MUTATION_NAME.CreateTaxrateMutationInput,
        label: 'label',
        newLabel: 'taxrateLabel',
      },
      {
        mutationName: MUTATION_NAME.UpdateTaxrateMutationInput,
        label: 'label',
        newLabel: 'taxrateLabel',
      },
      {
        mutationName: MUTATION_NAME.CreateTaxgroupMutationInput,
        label: 'label',
        newLabel: 'taxgroupLabel',
      },
    ],
  });

  useQuery(FETCH_LOCATION_PERMISSIONS, {
    onCompleted: data1 => {
      if (!mutationAccessPermissions) {
        setMutationAccessPermissions({
          ...(data1?.viewer?.locationConnection?.permissions || {}),
          menuId: data1?.viewer?.locationConnection?.permissions?.menu?.__typename,
          address: data1?.viewer?.locationConnection?.permissions?.address?.address,
          city: data1?.viewer?.locationConnection?.permissions?.address?.city,
          stateLabel: data?.viewer?.locationConnection?.permissions?.address?.state?.label,
          zip: data1?.viewer?.locationConnection?.permissions?.address?.zip,
          phone: data1?.viewer?.locationConnection?.permissions?.phone?.phone,
          timezoneId: data1?.viewer?.locationConnection?.permissions?.timezone?.__typename,
          printerPassword: data1?.viewer?.locationConnection?.permissions?.printerPassword,
          taxrateLabel:
            data1?.viewer?.locationConnection?.permissions?.taxrateConnection?.edges?.node?.label,
          fraction:
            data1?.viewer?.locationConnection?.permissions?.taxrateConnection?.edges?.node
              ?.fraction,
          amount:
            data1?.viewer?.locationConnection?.permissions?.taxrateConnection?.edges?.node?.amount,
          surcharge:
            data1?.viewer?.locationConnection?.permissions?.taxrateConnection?.edges?.node
              ?.surcharge,
          taxable:
            data1?.viewer?.locationConnection?.permissions?.taxrateConnection?.edges?.node?.taxable,
          display:
            data1?.viewer?.locationConnection?.permissions?.taxrateConnection?.edges?.node?.display,
          taxrateId:
            data1?.viewer?.locationConnection?.permissions?.taxrateConnection?.edges?.node
              ?.taxrateId,
          taxgroupId:
            data1?.viewer?.locationConnection?.permissions?.taxgroupConnection?.edges?.node
              ?.taxgroupId,
          taxgroupLabel:
            data1?.viewer?.locationConnection?.permissions?.taxgroupConnection?.edges?.node?.label,
        });
      }
    },
  });

  const { data, error, loading } = useQuery(FETCH_LOCATION, {
    variables: {
      id: locationId,
      first: 2147483647,
    },
  });

  const { data: weekdaysData, error: weekdaysError, loading: weekdaysLoading } = useQuery(
    FETCH_WEEKDAYS,
  );

  useEffect(() => {
    if (
      locationId === 'new' &&
      accessToMutations?.[MUTATION_NAME.CreateLocationMutationInput] < 7
    ) {
      setOpenWarningMutationName(MUTATION_NAME.CreateLocationMutationInput);
    }
  }, [accessToMutations, locationId, setOpenWarningMutationName]);

  const locationHours = useMemo(() => {
    const existingLocationHours = data?.viewer?.location?.locationWeekdayConnection;

    return weekdaysData?.viewer?.weekdayConnection?.edges?.map(edge => ({
      ...edge?.node,

      locationHours: existingLocationHours?.edges
        ?.filter(hours => hours.node.weekdayId === edge.node.weekdayId)
        .map(hour => hour.node.locationHours)[0],
    }));
  }, [data, weekdaysData]);

  if (error || weekdaysError) return <>Error</>;
  if (loading || weekdaysLoading) return <CircularProgress />;
  if (atob(locationId)?.split(':')?.[1] !== 'new' && !data?.viewer?.location)
    return <div>No data available for this location</div>;

  const permissions = {
    location: data?.viewer?.location?.permissions,
    timezone: data?.viewer?.timezoneConnection?.permissions,
    menu: data?.viewer?.menuConnection?.permissions,
    state: data?.viewer?.stateConnection?.permissions,
  };

  return (
    <Container>
      <LocationInfo
        countryList={data?.viewer?.countryConnection}
        locationInfo={data?.viewer?.location}
        permissions={permissions}
        stateList={data?.viewer?.stateConnection}
        timezoneList={data?.viewer?.timezoneConnection}
        menuList={data?.viewer?.menuConnection}
        mutationPermissions={mutationPermissions}
        weekdaysData={weekdaysData?.viewer?.weekdayConnection?.edges || []}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
      />
      <Divider />
      <LocationHours
        locationHours={locationHours}
        locationId={locationId}
        mutationPermissions={mutationPermissions}
        permissions={data?.viewer?.location?.permissions}
        weekdayType="Weekday"
        title="Core Hours"
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
      />
      <LocationHours
        locationHours={locationHours}
        mutationPermissions={mutationPermissions}
        locationId={locationId}
        permissions={data?.viewer?.location?.permissions}
        weekdayType="Holiday"
        title="Holiday Hours"
        tableTitle="Holiday Hours"
      />
      <Divider />
      <TaxRates
        taxRates={data?.viewer?.location?.taxrateConnection}
        permissions={data?.viewer?.location?.permissions}
        mutationPermissions={mutationPermissions}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
      />
      <Divider />
      <TaxGroups
        taxGroups={data?.viewer?.location?.taxgroupConnection}
        taxRates={data?.viewer?.location?.taxrateConnection}
        permissions={data?.viewer?.location?.permissions}
        mutationPermissions={mutationPermissions}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
      />
      <Divider />
      <BankSettings
        bankInfo={data?.viewer?.location?.stripeAccount}
        stateList={data?.viewer?.stateConnection}
        permissions={data?.viewer?.location?.permissions}
        locationInfo={data?.viewer?.location}
        mutationPermissions={mutationPermissions}
        accessToMutations={accessToMutations}
        setOpenWarningMutationName={setOpenWarningMutationName}
      />
      <Divider />
      <PaymentProcessors
        locationInfo={data?.viewer?.location}
        locationId={locationId}
        permissions={data?.viewer?.location?.permissions}
        mutationPermissions={mutationPermissions}
      />
      <Divider />
    </Container>
  );
};

export default GeneralInfo;
