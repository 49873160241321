import React from 'react';
import { useParams } from 'react-router-dom';
import { uglifyId } from '../../../../../utils';
import POSSettingsView from './View';
import { useQuery } from '@apollo/client';
import { FETCH_LOCATION_POS_SETTINGS } from '../../../../../apollo/queries';

function POSSettings() {
  const { locationId } = useParams();
  const uglifiedId = uglifyId('Location', locationId);
  const { data, loading, error } = useQuery(FETCH_LOCATION_POS_SETTINGS, {
    variables: {
      locationId: uglifiedId,
    },
  });

  if (loading) {
    return 'Loading...';
  }

  if (error) {
    console.log(error);
    return 'error';
  }

  if (!data?.viewer?.location) return <div>No data available for this location</div>;

  const generateTreeData = (menu, itemData) => {
    return (
      menu?.menuCategoryConnection?.edges?.map(menuCategory => {
        return {
          id: menuCategory.node.category.id,
          categoryId: menuCategory.node.category.categoryId,
          menuId: menu.menuId,
          categoryName: menuCategory.node.category.label,
          subcategories: menuCategory.node.category.subcategoryConnection.edges
            .filter(subcategory => subcategory.node.menuId === menu.menuId)
            .map(subcategory => {
              return {
                id: subcategory.node.id,
                subcategoryId: subcategory.node.subcategoryId,
                parentGlobalId: menuCategory.node.category.id,
                menuId: menu.menuId,
                categoryId: menuCategory.node.category.categoryId,
                subcategoryName: subcategory.node.label,
                subcategoryPrinterstationLocation:
                  subcategory.node.subcategoryPrinterstationLocation,
              };
            }),
        };
      }) || []
    );
  };

  let subcategoryPrinters = generateTreeData(data?.viewer?.location?.menu);

  const devicesAndPrinters = data?.viewer?.location.deviceConnection.edges.map(edge => {
    return {
      deviceId: edge.node.deviceId,
      posAppVersion: edge.node.posAppVersion,
      description: edge.node.description,
      deviceSetting: edge.node.deviceSetting,
      devicePrinters: edge.node.locationPrinterstation.edges.map(edge => {
        return {
          id: edge.node.id,
          printerAddress: edge.node.printerAdress,
          printerstationId: edge.node.printerstation_id,
        };
      }),
    };
  });

  const gateways = data?.viewer?.gatewayConnection.edges
    .map(edge => {
      return {
        label: edge.node.label,
        gatewayId: edge.node.gatewayId,
      };
    })
    .filter(gat => gat.gatewayId !== 1);

  const enablePayPalAndSquare = {
    enablePayPal: data?.viewer?.location.payPalRefreshToken,
    enableSquare: data?.viewer?.location.squareRefreshToken,
  };

  return (
    <POSSettingsView
      gateways={gateways}
      permissions={{
        getaways: data?.viewer?.gatewayConnection?.permissions,
        location: data?.viewer?.location.permissions,
      }}
      devicesAndPrinters={devicesAndPrinters}
      enablePayPalAndSquare={enablePayPalAndSquare}
      subcategoriesPrinterstations={subcategoryPrinters}
    />
  );
}

export default POSSettings;
