import { gql } from '@apollo/client';

const PERSON_JOBS = gql`
  query personJobConnection($filter: [PersonJobFilter]) {
    viewer {
      id
      personJobConnection(filter: $filter, fieldGroup: 61) {
        edges {
          node {
            job {
              id
              jobId
              label
            }
          }
        }
      }
    }
  }
`;

export default PERSON_JOBS;
