import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FETCH_COST_OF_GOODS, LOCATIONS } from '../../../../../apollo/queries';
import { convert, pad } from '../../../../../utils';
import { DateRange, SelectFactory } from '../../../../blocks';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import PaperMain from '../../../../blocks/PaperMain';

const initialDateRange = {
  gte: moment()
    .add(-7, 'days')
    .format('MM/DD/YYYY'),
  lte: moment().format('MM/DD/YYYY'),
};

const CostOfGoods = () => {
  const [location, setLocation] = useState();
  const [filters, setFilters] = useState({
    dateRange: initialDateRange,
  });

  const handleOnLocationSelect = useCallback(value => {
    setLocation(value);
  }, []);

  const handleOnDateRangeChange = useCallback(
    ({ dateRange, refetch, variables }) => {
      refetch({
        ...variables,
        dateRange: {
          startDate: dateRange.gte,
          endDate: dateRange.lte,
        },
      });

      setFilters(prevFilters => ({ ...prevFilters, dateRange }));
    },
    [setFilters],
  );

  return (
    <PaperMain>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <SelectFactory
            label="Location"
            name="locationId"
            placeholder="Search location..."
            fullWidth
            query={LOCATIONS}
            multiple={false}
            disableSearchReset
            renderCheckBox={false}
            onSelect={value => {
              handleOnLocationSelect(value);
            }}
            structureData={data =>
              data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                value: edge.node.locationId,
                id: edge.node.locationId,
                index,
                storeNumber: edge.node.storeNumber,
                label: `${edge.node?.address?.state?.abbreviation}-${pad(
                  edge.node?.storeNumber || 0,
                  4,
                  '0',
                )} - ${edge.node.label}`,
              }))
            }
          />
        </Grid>
        {location?.id ? (
          <Grid item xs={12}>
            <ConnectionTable
              nested
              alignItemsCenter="flex-start"
              showPagination={false}
              customActionsCenter={(_, { refetch, variables }) => (
                <Grid container spacing={2} alignItems="flex-start" justify="flex-end">
                  <Grid item xs={6}>
                    <DateRange
                      disableDelete
                      fieldName={'dateRange'}
                      variables={{ filter: { dateRange: filters.dateRange } }}
                      onDateChange={dateRange =>
                        handleOnDateRangeChange({ dateRange, refetch, variables })
                      }
                    />
                  </Grid>
                </Grid>
              )}
              showSearch={false}
              query={FETCH_COST_OF_GOODS}
              initialQueryVariables={{
                storeNumber: location.storeNumber,
                dateRange: { startDate: initialDateRange.gte, endDate: initialDateRange.lte },
              }}
              columns={[
                { title: 'Bucket', field: 'label', disableSort: true },
                { title: 'Cost', field: 'costOfGoods', disableSort: true },
              ]}
              structureTable={data =>
                data?.viewer?.reportcategoryConnection?.edges?.map(edge => ({
                  label: edge.node.label,
                  costOfGoods:
                    edge.node.reports !== null && edge.node.reports.costOfGoods !== null
                      ? `$ ${convert.centsToDollars(edge.node.reports.costOfGoods)}`
                      : '-',
                })) || []
              }
              title="Cost Of Goods"
            />
          </Grid>
        ) : null}
      </Grid>
    </PaperMain>
  );
};

export default CostOfGoods;
