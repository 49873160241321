import { gql } from "@apollo/client";

const TOGGLE_LOCATION_LOCAL_FAVORITE = gql`
  mutation ToggleLocationLocalFavorite($input: ToggleLocalFavoriteMutationInput!) {
    toggleLocalFavorite(input: $input) {
      viewer {
      id
      }
    }
  }
`;

export default TOGGLE_LOCATION_LOCAL_FAVORITE;
