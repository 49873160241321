import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { UPDATE_PAYMENT_PROCESSOR } from "../../../../../../../apollo/mutations/locations/POSSettings/updatePaymentProcessor";
import { useMutation } from "@apollo/client";
import { Permission, PermissionField } from '../../../../../../blocks';
import { useSnackbar } from "../../../../../../../hooks/useSnackbar";

function PaymentGateway({
  devicesettingId,
  deviceGatewayId,
  enablePayPalAndSquare,
  gateways,
  permissions,
}) {
  const { setSnackbar } = useSnackbar();

  const [deviceGateway, setDeviceGatway] = useState(deviceGatewayId);
  const [disabledRadio, setDisabledRadio] = useState(false);

  const [updatePayment] = useMutation(UPDATE_PAYMENT_PROCESSOR, {
    onCompleted: (data) => {
      setDeviceGatway(data.updatePaymentProcessor.devicesettingEdge.gatewayId);
      setDisabledRadio(false);
      setSnackbar({
        open: true,
        type: "success",
        text: "Payment Processor updated",
      });
    },
    onError: (error) => {
      console.log(error);
      setDisabledRadio(false);
      setSnackbar({
        open: true,
        type: "error",
        text: "Oops, something went wrong...",
      });
    },
  });

  const handleChange = (e) => {
    setDisabledRadio(true);
    setSnackbar({
      open: true,
      type: "info",
      text: "Updating Payment Processor...",
    });
    const newGatewayId = +e.target.value;
    updatePayment({
      variables: {
        input: {
          id: devicesettingId,
          gatewayId: newGatewayId,
        },
      },
    });
  };

  return (
    <Permission access={permissions?.__typename}>
      <PermissionField Component={RadioGroup} createHelperText value={deviceGateway} onChange={handleChange} row>
        {gateways.map((gateway) => (
          <FormControlLabel
            createHelperText
            disabled={
              disabledRadio ||
              (gateway.gatewayId === 2 && !enablePayPalAndSquare.enablePayPal) ||
              (gateway.gatewayId === 3 && !enablePayPalAndSquare.enableSquare)
            }
            value={gateway.gatewayId}
            control={<Radio />}
            label={gateway.label}
          />
        ))}
      </PermissionField>
    </Permission>
  );
}

export default PaymentGateway;
