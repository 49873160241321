import { gql } from "@apollo/client";

export const LOCATION_NOTES = gql`
query LocationNotes(
  $filter: [LocationnoteFilter]
  $first: Int
  $last: Int
  $after: String
  $before: String
  $search: String) {
  viewer {
    id
    locationnoteConnection (
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      ) {
      permissions
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          note
          created
        }
      }
    }
  }
}
`;
