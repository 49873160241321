import { useLazyQuery } from '@apollo/client';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../blocks';
import FETCH_MULTIPLE_CHOICE_TIERS from './setPricingMultiple.constants';

const SetPricingCreateMultiple = ({
  choiceId,
  multipleId,
  choiceLabel,
  gqlMultipleId,
  gqlChoiceId,
  setNewChoicesSetUp,
  newChoicesSetUp,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tiersConnection, setTiersConnection] = useState();

  const [loadTiers, { loading: loadingTiers }] = useLazyQuery(FETCH_MULTIPLE_CHOICE_TIERS, {
    onCompleted: data => {
      if (data?.viewer?.multipleChoiceTierConnection && data?.viewer?.platformConnection) {
        setTiersConnection(
          data?.viewer?.tierConnection?.edges.map(edge => {
            return {
              tierId: edge?.node?.id,
              tierPrice: edge?.node?.price,
              tierLabel: edge?.node.label,
              nameLabel: edge?.node?.label.replace(/\s+/g, '-').toLowerCase(),
              tierPlatforms: data?.viewer?.platformConnection,
              tierPriceData: data?.viewer?.multipleChoiceTierConnection?.edges.find(
                tier => tier?.node?.tier?.id === edge?.node?.id,
              ),
            };
          }),
        );
      }
    },
    onError: err => {
      console.log(err, 'error...');
    },
    fetchPolicy: 'network-only',
  });

  const { handleSubmit, register, getValues, errors, reset, setError } = useForm({
    defaultValues: {},
  });

  const handleOpen = useCallback(() => {
    setOpenModal(true);
    loadTiers({
      variables: {
        choiceId: choiceId,
        gqlMultipleId: gqlMultipleId,
      },
    });
  }, [multipleId, choiceId, loadTiers]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const prepareData = useCallback(
    data => {
      const names = Object.keys(data);
      const values = Object.values(data);

      const dataSerialized = names.reduce((acc, curr, index) => {
        acc.push({
          name: curr,
          value: values[index],
        });
        return acc;
      }, []);

      const inputTiersData = tiersConnection?.map(data => {
        if (dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value) {
          return {
            tierId: data.tierId,
            price:
              parseFloat(dataSerialized.find(e => e.name === `${data.nameLabel}-mcpi`)?.value) ||
              null,
            platforms: data.tierPlatforms.edges.reduce((acc, curr) => {
              if (curr?.node?.id === 'UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                if (dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)?.value) {
                  acc.push({
                    platformId: curr?.node?.id,
                    priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)
                      ?.value
                      ? parseFloat(
                          dataSerialized.find(e => e.name === `${data.nameLabel}-oloMaxPrice`)
                            ?.value,
                        )
                      : null,
                  });
                }
              }
              if (curr?.node?.id === 'UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                if (dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)?.value) {
                  acc.push({
                    platformId: curr?.node?.id,
                    priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)
                      ?.value
                      ? parseFloat(
                          dataSerialized.find(e => e.name === `${data.nameLabel}-posMaxPrice`)
                            ?.value,
                        )
                      : null,
                  });
                }
              }
              if (curr?.node?.id === 'UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ==') {
                if (dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)?.value) {
                  acc.push({
                    platformId: curr?.node?.id,
                    priceCap: dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)
                      ?.value
                      ? parseFloat(
                          dataSerialized.find(e => e.name === `${data.nameLabel}-3pvMaxPrice`)
                            ?.value,
                        )
                      : null,
                  });
                }
              }
              return acc;
            }, []),
          };
        }
      });

      return {
        choiceId: gqlChoiceId,
        price: parseFloat(data.defaultMcpi),
        priceCap: parseFloat(data.defaultMaxPrice) || null,
        orderKey: 1,
        tiers: inputTiersData.filter(data => data !== undefined),
      };
    },
    [tiersConnection],
  );

  const forwardSave = data => {
    try {
      tiersConnection.forEach(tier => {
        if (!getValues(`${tier.nameLabel}-mcpi`)) {
          if (
            getValues(`${tier.nameLabel}-oloMaxPrice`) !== '' ||
            getValues(`${tier.nameLabel}-posMaxPrice`) !== '' ||
            getValues(`${tier.nameLabel}-3pvMaxPrice`) !== ''
          ) {
            setError(`${tier.nameLabel}-mcpi`, { type: 'customError' });
            throw new Error('MCPI can’t be empty if you’re trying to enter Max Price per Platform');
          }
        }
      });

      setNewChoicesSetUp(prevArray => [
        ...prevArray,
        { choiceId: gqlChoiceId, ...prepareData(data), formData: data },
      ]);
      handleClose();
    } catch (errorMessage) {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: `${errorMessage}`,
      });
    }
  };

  const handleSubmitWithoutPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(forwardSave)(e);
  };

  useEffect(() => {
    const activePricingObject = newChoicesSetUp.find(choice => choice.choiceId === gqlChoiceId);
    if (newChoicesSetUp?.length && activePricingObject?.formData && tiersConnection?.length) {
      const tiersFields = tiersConnection.map((tier, index) => ({
        [`${tier.nameLabel}-mcpi`]:
          Object.entries(activePricingObject?.formData)?.find(
            ([key]) => key === `${tier.nameLabel}-mcpi`,
          )[1] || '',
        [`${tier.nameLabel}-oloMaxPrice`]:
          Object.entries(activePricingObject?.formData)?.find(
            ([key]) => key === `${tier.nameLabel}-oloMaxPrice`,
          )[1] || '',
        [`${tier.nameLabel}-posMaxPrice`]:
          Object.entries(activePricingObject?.formData)?.find(
            ([key]) => key === `${tier.nameLabel}-posMaxPrice`,
          )[1] || '',
        [`${tier.nameLabel}-3pvMaxPrice`]:
          Object.entries(activePricingObject?.formData)?.find(
            ([key]) => key === `${tier.nameLabel}-3pvMaxPrice`,
          )[1] || '',
      }));

      const tiersProps = tiersFields?.reduce((acc, curr) => {
        return Object.assign(acc, curr);
      }, {});

      reset({
        defaultMcpi: activePricingObject?.formData?.defaultMcpi || '',
        defaultMaxPrice: activePricingObject?.formData?.defaultMaxPrice || '',
        ...tiersProps,
      });
    }
  }, [newChoicesSetUp, tiersConnection]);

  const formRender = useCallback(() => {
    return tiersConnection && !loadingTiers ? (
      <Grid container>
        <form
          onSubmit={handleSubmitWithoutPropagation}
          style={{
            width: '100%',
          }}
        >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4" component="h4">
                Default
              </Typography>
            </Grid>
            <Grid
              container
              style={{
                gap: '10px',
                flexWrap: 'nowrap',
              }}
            >
              <Grid item xs={6}>
                <TextField
                  name="defaultMcpi"
                  label="Default MCPI"
                  type="text"
                  inputRef={register({
                    required: true,
                    pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                  })}
                  fullWidth
                  error={errors?.defaultMcpi}
                  helperText={
                    errors?.defaultMcpi &&
                    (errors?.defaultMcpi ? 'Default MCPI is required.' : null)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="defaultMaxPrice"
                  label="Default Max Price"
                  type="text"
                  inputRef={register({
                    pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                    validate: {
                      min: value => {
                        const priceValue = getValues('defaultMcpi');
                        return priceValue && value
                          ? parseFloat(value) >= parseFloat(priceValue)
                          : true;
                      },
                    },
                  })}
                  fullWidth
                  error={errors?.defaultMaxPrice}
                  helperText={
                    errors?.defaultMaxPrice &&
                    (errors?.defaultMaxPrice?.type === 'min'
                      ? "Max Price can't be less than Default MCPI"
                      : 'Tier Max Price must be a positive number.')
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                justifyContent: 'space-between',
              }}
            >
              {tiersConnection?.map((tier, index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <Typography gutterBottom variant="h4" component="h4">
                      {tier.tierLabel}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    style={{
                      gap: '10px',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-mcpi`}
                        label={`${tier.tierLabel} MCPI`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                        error={errors[`${tier.nameLabel}-mcpi`]}
                        helperText={
                          errors[`${tier.nameLabel}-mcpi`]?.type === 'customError'
                            ? 'MCPI can’t be empty if you’re trying to enter Max Price per Platform'
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-posMaxPrice`}
                        label={`${tier.tierLabel} POS Max Price`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-oloMaxPrice`}
                        label={`${tier.tierLabel} OLO Max Price`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`${tier.nameLabel}-3pvMaxPrice`}
                        label={`${tier.tierLabel} 3PV Max Price`}
                        type="text"
                        inputRef={register({
                          pattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                        })}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
            <Grid
              container
              style={{
                justifyContent: 'flex-end',
                marginTop: '16px',
              }}
            >
              <Grid item>
                <Button
                  onClick={handleClose}
                  style={{
                    marginRight: '14px',
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={buttonDisabled}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    ) : (
      <Typography gutterBottom variant="h5" component="h5">
        Loading...
      </Typography>
    );
  }, [tiersConnection, errors, buttonDisabled, handleClose, loadingTiers]);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Set Pricing
      </Button>
      <Modal open={openModal} handleClose={handleClose} title={choiceLabel} maxWidth="lg">
        {formRender()}
      </Modal>
    </>
  );
};

export default SetPricingCreateMultiple;
