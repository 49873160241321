import { gql } from '@apollo/client';
const FETCH_SCHEDULES = gql`
  query scheduleList($scheduleFilter: [scheduleFilter], $personJobFilter: [PersonJobFilter]) {
    viewer {
      id
      scheduleConnection(filter: $scheduleFilter, fieldGroup: 62) {
        permissions
        edges {
          node {
            id
            scheduleId
            personId
            started
            finished
            jobId
            delivery
            person {
              id
              firstName
              lastName
              login {
                id
                email
              }
              personJobConnection(filter: $personJobFilter) {
                edges {
                  node {
                    id
                    salaryType {
                      id
                      salarytypeId
                    }
                    salaryAmount
                    job {
                      label
                      id
                      jobId
                      color {
                        id
                        code
                      }
                      orderKey
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_SCHEDULES;
