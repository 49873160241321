import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { paths } from '../../../../constants';
import CategorySettings from './CategorySettings';
import Vendors from './Vendors';
import StorageAreas from './StorageAreas';
import MeasureSettings from './MeasureSettings';

const TabContainer = ({ value }) => (
  <AppBar position="static">
    <Tabs value={value}>
      <Tab label="Vendors" component={Link} to={paths.SETTINGS_INVENTORY_SETTINGS_VENDORS} />
      <Tab
        label="Storage Areas"
        component={Link}
        to={paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS}
      />
      <Tab
        label="Category Settings"
        component={Link}
        to={paths.SETTINGS_INVENTORY_SETTINGS_CATEGORY_SETTINGS}
      />
      <Tab
        label="Measure Settings"
        component={Link}
        to={paths.SETTINGS_INVENTORY_SETTINGS_MEASURE_SETTINGS}
      />
    </Tabs>
  </AppBar>
);

TabContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const InventorySettings = () => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path={paths.SETTINGS_INVENTORY_SETTINGS_VENDORS}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <Vendors />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS}
          render={() => (
            <Fragment>
              <TabContainer value={1} />
              <StorageAreas />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_INVENTORY_SETTINGS_CATEGORY_SETTINGS}
          render={() => (
            <Fragment>
              <TabContainer value={2} />
              <CategorySettings />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.SETTINGS_INVENTORY_SETTINGS_MEASURE_SETTINGS}
          render={() => (
            <Fragment>
              <TabContainer value={3} />
              <MeasureSettings />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  );
};

export default InventorySettings;
