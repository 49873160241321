import { gql } from '@apollo/client';

const UPDATE_ALL_WEEKDAYS_FOR_LOCATIONS = gql`
  mutation updateAllWeekdaysForLocations($input: UpdateAllWeekdaysForLocationsInput!) {
    updateAllWeekdaysForLocations(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default UPDATE_ALL_WEEKDAYS_FOR_LOCATIONS;
