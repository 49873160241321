import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { uglifyId, convert } from '../../../../../../utils';
import MultipleSelectItem from './MultipleSelectItem';
import LOCATION_ITEM from '../../../../../../apollo/queries/caterings/locationItem';
import FETCH_QUOTE_CATERING_ITEM_CHOICES from '../../../../../../apollo/queries/caterings/fetchQuoteCateringItemChoices';
import Skeleton from '@material-ui/lab/Skeleton';
import { Permission, PermissionField } from '../../../../../blocks';

const MultipleSelect = ({
  itemId,
  id,
  note,
  errors,
  control,
  register,
  lineNumber,
  setLocationItem,
  locationId,
  line,
  permissions,
  setValue,
}) => {
  const { data, error, loading } = useQuery(FETCH_QUOTE_CATERING_ITEM_CHOICES, {
    variables: {
      filter: {
        itemId: {
          eq: itemId,
        },
      },
      itemFilter: {
        itemId: {
          eq: itemId,
        },
      },
      locationItemFilter: {
        itemId: {
          eq: itemId,
        },
        locationId: {
          eq: locationId.value ? parseInt(atob(locationId.value)?.split(':')?.[1]) : null,
        },
      },
      locationId: locationId?.locationId,
    },
    onCompleted: data1 => {
      setLocationItem(prevState => ({
        ...prevState,
        cspPrice: data1?.viewer?.itemConnection?.edges[0]?.node?.price,
        minQuantity: data1?.viewer?.itemConnection?.edges[0]?.node?.minQuantity,
      }));
      setLocationItem(prevState => ({
        ...prevState,
        price: data1?.viewer?.locationItemConnection?.edges[0]?.node?.price,
      }));
    },
  });
  if (loading)
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant={'rect'} width={'20'} height={'6vh'} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant={'rect'} width={'20'} height={'6vh'} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant={'rect'} width={'20'} height={'6vh'} />
        </Grid>
      </Grid>
    );
  const renderMultiple = () => {
    return data?.viewer?.itemMultipleConnection?.edges.map(multiple => {
      const multipleChoiceValues = multiple?.node?.multiple?.multipleChoiceConnection?.edges?.map(
        multipleChoice => {
          return {
            label: multipleChoice?.node?.choice?.label,
            value: multipleChoice?.node?.choice?.id,
            id: multipleChoice?.node?.choice?.id,
            cost:
              multipleChoice?.node?.price && convert.centsToDollars(multipleChoice?.node?.price),
            choiceAllowedMaximum:
              multipleChoice?.node?.allowedMaximum &&
              parseInt(multipleChoice?.node?.allowedMaximum),
            allowedMaximum:
              multipleChoice?.allowedMaximum && parseInt(multipleChoice?.allowedMaximum),
            quantity: 1,
          };
        },
      );

      const filterdMultipleSelected = line?.lineMultipleConnection?.edges?.filter(
        lineMultiple =>
          line?.item?.itemId === itemId &&
          lineMultiple?.node?.multiple?.id === multiple?.node?.multiple?.id,
      );

      const multipleSelected = filterdMultipleSelected?.map(el => {
        const allowedMaximum = multiple?.node?.multiple?.multipleChoiceConnection?.edges?.filter(
          x => x?.node?.choice?.id === el?.node?.choice?.id,
        );
        return {
          ...el,
          choiceAllowedMaximum: allowedMaximum[0]?.node?.allowedMaximum
            ? parseInt(allowedMaximum[0]?.node?.allowedMaximum)
            : null,
        };
      });

      return (
        <MultipleSelectItem
          permissions={permissions}
          values={multipleChoiceValues}
          key={`${multiple?.node?.multiple?.id}-${itemId}}`}
          id={id}
          register={register}
          itemId={itemId}
          line={line}
          errors={errors}
          multipleSelected={multipleSelected}
          multipleAllowedMaximum={multiple?.node?.allowedMaximum}
          multipleRequiredMinimum={multiple?.node?.requiredMinimum}
          locationId={locationId}
          lineNumber={lineNumber}
          multipleId={multiple?.node?.multiple?.id}
          control={control}
          multipleLabel={multiple?.node?.multiple?.label}
          setValue={setValue}
        />
      );
    });
  };

  return (
    <>
      <Grid xs={12} container spacing={2}>
        {renderMultiple()}
        <Permission access={permissions?.lineConnection?.edges?.node?.note}>
          <Grid item xs={12}>
            <PermissionField
              Component={TextField}
              variant={'outlined'}
              fullWidth
              inputRef={register}
              defaultValue={note || ''}
              label={'Note'}
              control={control}
              name={`${lineNumber}-selected-note-item`}
            />
          </Grid>
        </Permission>
      </Grid>
    </>
  );
};

export default MultipleSelect;
