import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({
    title: {
        marginBottom: theme.spacing(3),
    },
    areas: {
        borderLeft: `1px solid ${theme.colors.hrColor}`,
        padding: `0 ${theme.spacing(4)}px`,
    },
    areasActions: {
        marginTop: theme.spacing(2),
    },
    properties: {
        border: `1px solid ${theme.colors.hrColor}`,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(4),
    },
    propertiesAreas: {
        marginTop: theme.spacing(4),
    },
    propertiesContainer: {
        height: 'calc(100vh - 225px)',
        overflowY: 'auto',
    },
    treeView: {
        marginTop: theme.spacing(3),
        maxHeight: 800,
        overflowY: 'auto',
    },
    treeItem: {
        '& .MuiCollapse-container': {
            borderLeft: '1px dashed #000',
            marginLeft: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
    },
    treeItemLabel: {
        alignItems: 'center',
        display: 'flex',
        fontSize: theme.typography.fontSizeS,
        margin: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(2)}px 0`,
    },
    treeItemId: {
        fontSize: theme.typography.fontSizeXS,
        marginLeft: 'auto',
        opacity: '.75',
    },
    treeItemActions: {
        marginLeft: '10px',
        whiteSpace: 'nowrap',
    },
    treeItemNew: {
        display: 'block',
        margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    toggle: {
        justifyContent: 'end',

        '& > div': {
            width: 'auto',
        },
    },
});

  export const HierarchyStyles = makeStyles(Styles);