import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { paths } from '../../../../constants';
import MysteryRewards from './components/MysteryRewards';
import Achievements from './components/Achievements';

const TabContainer = ({ value }) => (
  <AppBar position="static">
    <Tabs value={value}>
      <Tab label="Mystery Rewards" component={Link} to={paths.LOYALTY_REWARDS_MYSTERY_REWARDS} />
      <Tab label="Achievements" component={Link} to={paths.LOYALTY_REWARDS_ACHIEVEMENTS} />
    </Tabs>
  </AppBar>
);

TabContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const LoyaltyRewards = () => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path={paths.LOYALTY_REWARDS_MYSTERY_REWARDS}
          render={() => (
            <Fragment>
              <TabContainer value={0} />
              <MysteryRewards />
            </Fragment>
          )}
        />
        <Route
          exact
          path={paths.LOYALTY_REWARDS_ACHIEVEMENTS}
          render={() => (
            <Fragment>
              <TabContainer value={1} />
              <Achievements />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  );
};

export default LoyaltyRewards;
