export function formatData({ formData, label, mapFunction = value => value }) {
  return Object.entries(formData)
    .filter(([key]) => key.startsWith(label))
    .map(([key, value]) => mapFunction({ id: key, ...value }));
}

export function getId(prefix) {
  return `${prefix}-${new Date().toISOString().replace(/\./g, '')}`;
}

export function formatNumberStringToFloat(value) {
  return +`${value}`.replace(/,/g, '');
}
