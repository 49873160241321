import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RouterWrapper = ({
  component: Component,
  context,
  exact,
  id,
  metaTitle,
  path,
  parents,
  breadcrumbs,
}) => {
  useEffect(() => {
    context.coreUtils.setActiveModule &&
      context.coreUtils.setActiveModule({
        id: id,
        metaTitle: metaTitle,
        parents: parents,
        breadcrumbs,
      });
  }, [context, id, metaTitle, parents, breadcrumbs]);

  return (
    <Route
      path={path}
      exact={exact}
      render={props => {
        return <Component {...props} />;
      }}
    ></Route>
  );
};

RouterWrapper.propTypes = {
  component: PropTypes.func,
  context: PropTypes.object,
  exact: PropTypes.bool,
  id: PropTypes.string,
  metaTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
  parents: PropTypes.array,
};

export default RouterWrapper;
