import { gql } from '@apollo/client';
import equipmentFragment from '../fragments/equipmentFragment';

const EQUIPMENTS = gql`
  query EQUIPMENTS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [EquipmentSort]
    $filter: [EquipmentFilter]
  ) {
    viewer {
      id
      equipmentConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 7
      ) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...EquipmentData
          }
        }
      }
    }
  }

  ${equipmentFragment.equipment}
`;

export default EQUIPMENTS;
