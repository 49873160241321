import React from 'react';
import PropTypes from 'prop-types';
import LocationAvailabilityView from './View';

const LocationAvailability = ({
  locationIngredientId,
  handleLocationsChange,
  inStock,
  myLocations,
  control,
  setInStock,
  register,
}) => {
  return (
    <div>
      <LocationAvailabilityView
        control={control}
        myLocations={myLocations}
        handleLocationsChange={handleLocationsChange}
        inStock={inStock}
        setInStock={setInStock}
        register={register}
        locationIngredientId={locationIngredientId}
      />
    </div>
  );
};

LocationAvailability.propTypes = {
  locationIngredientId: PropTypes.number,
  handleLocationsChange: PropTypes.func,
  inStock: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  myLocations: PropTypes.array,
  control: PropTypes.object,
};

export default LocationAvailability;
