import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';

import GeneralInfo from '../GeneralInfo';
import OrderSettings from '../OrderSettings';
import POSSettings from '../POSSettings';
import Vendors from '../Vendors';
import CountSheets from '../CountSheets';

import { TabPanel, SideBarSectionsLayout } from '../../../../../components/blocks';
import MenuSettings from '../MenuSettings';
import HouseAccountSettings from '../HouseAccountSettings';

const HANDOFF_IDS = {
  dineIn: 'SGFuZG9mZjoxOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  pickup: 'SGFuZG9mZjoyOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  delivery: 'SGFuZG9mZjozOjk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  curbside: 'SGFuZG9mZjo0Ojk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  driveThru: 'SGFuZG9mZjo2Ojk5OTktMTItMzEgMjM6NTk6NTkuMDAwMDAw',
  catering: 'Q2F0ZWdvcnk6Mjo5OTk5LTEyLTMxIDIzOjU5OjU5LjAwMDAwMA',
};

const getTabsViewData = isNew =>
  isNew
    ? [
        {
          label: 'General Info',
          value: 'generalInfo',
          component: GeneralInfo,
          id: '519e0a6f-598d-4733-a5e5-6069a856637c',
          sections: [
            { name: 'Location Info', id: 'locationInfo' },
            // { name: "Location Hours", id: "locationHours" },
            { name: 'Tax Rates', id: 'taxRates' },
            { name: 'Tax Groups', id: 'taxGroups' },
            { name: 'Bank Settings', id: 'bankSettings' },
            { name: 'Payment Processors', id: 'paymentProcessors' },
          ],
        },
      ]
    : [
        {
          label: 'General Info',
          value: 'generalInfo',
          component: GeneralInfo,
          id: '519e0a6f-598d-4733-a5e5-6069a856637c',
          sections: [
            { name: 'Location Info', id: 'locationInfo' },
            // { name: "Location Hours", id: "locationHours" },
            { name: 'Tax Rates', id: 'taxRates' },
            { name: 'Tax Groups', id: 'taxGroups' },
            { name: 'Bank Settings', id: 'bankSettings' },
            { name: 'Payment Processors', id: 'paymentProcessors' },
          ],
        },
        {
          label: 'Order Settings',
          value: 'orderSettings',
          component: OrderSettings,
          id: '32aa54da-28ce-433b-9a69-aa848e9dc44f',
          sections: [
            { name: 'Order Settings', id: 'orderSettings' },
            { name: 'Dine In', id: HANDOFF_IDS.dineIn },
            { name: 'Pickup', id: HANDOFF_IDS.pickup },
            { name: 'Delivery', id: HANDOFF_IDS.delivery },
            { name: 'Curbside', id: HANDOFF_IDS.curbside },
            { name: 'DriveThru', id: HANDOFF_IDS.driveThru },
            { name: 'Catering', id: HANDOFF_IDS.catering },
          ],
        },
        {
          label: 'Menu Settings',
          value: 'menuSettings',
          component: MenuSettings,
          sections: [
            { name: 'Menu', id: 'menu' },
            { name: 'Items', id: 'items' },
            { name: 'Multiples', id: 'multiples' },
            { name: 'Inventory items', id: 'ingredients' },
          ],
          id: '810b15f6-16b9-47f9-8a8a-2b0849d2f549',
        },
        {
          label: 'POS Settings',
          value: 'POSSettings',
          component: POSSettings,
          id: '32aa54da-28ce-433b-9a69-aa848e9dc44g',
          sections: [
            { name: 'Payment Processors', id: 'paymentProcessors' },
            // { name: "Manager Approval", id: "managerApproval" },
            // { name: "Available Tenders", id: "availableTenders" },
            { name: 'Printer Station Settings', id: 'printerStationSettings' },
            { name: 'Category Print Settings', id: 'categoryPrintSettings' },
          ],
        },
        {
          label: 'House Account Settings',
          value: 'houseAccountSettings',
          component: HouseAccountSettings,
          id: '32aa54da-28ce-433b-9a69-aa848e9dc44h',
          sections: [{ name: 'House Accounts Settings', id: 'houseAccountSettings' }],
        },
        {
          label: 'Vendors',
          value: 'vendors',
          component: Vendors,
          id: '32aa54da-28ce-433b-9a69-aa848e9dc44gh',
          sections: [{ name: 'Vendors', id: 'vendors' }],
        },
        {
          label: 'Count Sheets',
          value: 'countSheets',
          component: CountSheets,
          id: 'countSheets',
          sections: [{ name: 'Count Sheets', id: 'countSheets' }],
        },
      ];

const LocationView = ({ currentTab, handleTabChange, isNew }) => {
  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        variant="scrollable"
        visibleScrollbar={false}
        scrollButtons={{ display: 'none' }}
      >
        {getTabsViewData(isNew).map(tab => (
          <Tab label={tab.label} value={tab.value} key={tab.id} />
        ))}
      </Tabs>
      {getTabsViewData(isNew).map(tabPanel => (
        <TabPanel value={currentTab} index={tabPanel.value} key={tabPanel.id}>
          <SideBarSectionsLayout sectionList={tabPanel.sections || []}>
            {<tabPanel.component />}
          </SideBarSectionsLayout>
        </TabPanel>
      ))}
    </>
  );
};

LocationView.propTypes = {
  currentTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default LocationView;
