import { Button, Hidden } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { INVENTORY_TRANSFERS_INVENTORYITEM } from '../../../../../../apollo/queries/inventory';
import ACCEPT_OR_DECLINE_INVENTORYTRANSFER from '../../../../../../apollo/mutations/inventory/acceptOrDeclineInventorytransfer';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import { getButtons, BUTTON_NAME } from '../../renderers';
import MobileButton from '../../../../../blocks/Mobile/MobileButton';
import { ConfirmationModal, Modal } from '../../../../../blocks';
import { generateStoreAbbreviation } from '../../../../../../utils';
import { useMutation } from '@apollo/client';
import CANCEL_INVENTORYTRANSFER from '../../../../../../apollo/mutations/inventory/cancelInventoryrtransfer';
import { paths, SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const InvoiceJournal = () => {
  const { push } = useHistory();
  let { locationId: ids, transferId } = useParams();
  const locationId = ids.split('-')[0];
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { setSnackbar } = useSnackbar();

  const buttons = getButtons([
    {
      buttonName: BUTTON_NAME.TAKS_LIST,
      link: paths.COREOPS_INVENTORY_LOCATION_TASKLIST.replace(':locationId', ids),
    },
    {
      buttonName: BUTTON_NAME.INVENTORY_TRANSFER,
      link: paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER.replace(':locationId', ids),
    },
  ]);

  const [acceptOrDecline, { loading: loadingAcceptOrDecline }] = useMutation(
    ACCEPT_OR_DECLINE_INVENTORYTRANSFER,
    {
      onCompleted: data => {
        if (data.acceptOrDeclineInventorytranfer.inventorytransfer.targetLocationAccepted) {
          setSnackbar({
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Transfer ACCEPTED successfully, your inventory was updated',
            open: true,
          });
        } else {
          setSnackbar({
            type: SNACKBAR_STATUS.SUCCESS,
            text: 'Transfer DECLINED successfully',
            open: true,
          });
        }
        push(paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER.replace(':locationId', ids));
      },
    },
  );

  const [cancelInventoryrtransfer, { loading: loadingCancelInventoryrtransfer }] = useMutation(
    CANCEL_INVENTORYTRANSFER,
    {
      onCompleted: () => {
        setSnackbar({
          type: SNACKBAR_STATUS.SUCCESS,
          text: 'Transfer CANCELED successfully',
          open: true,
        });
        push(paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER.replace(':locationId', ids));
      },
    },
  );

  const [openModal, setOpenModal] = useState(false);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleAcceptOrDeclineRequest = acceptedOrDeclined => {
    acceptOrDecline({
      variables: {
        input: {
          inventorytransferId: +transferId,
          accepted: acceptedOrDeclined,
        },
      },
    });
  };

  const handleCancelRequest = () => {
    cancelInventoryrtransfer({
      variables: {
        input: {
          inventorytransferId: +transferId,
        },
      },
    });
  };

  return (
    <>
      <ConnectionTable
        title="Inventory Transfers"
        connectionName="inventorytransferInventoryitemConnection"
        headerHidden={'xs'}
        showSearch={false}
        selectableRows={false}
        customActionsTop={() => (
          <>
            <Hidden only="xs">{buttons}</Hidden>
            <Hidden smUp>
              <MobileButton style={{ cursor: 'pointer' }} onClick={handleOpen} />
            </Hidden>
          </>
        )}
        customActionsBottom={data => {
          if (!data) {
            return '';
          }

          const targetLocationAccepted =
            data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocationAccepted;

          const isOutGoingRequest =
            data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocationId ===
            +locationId;

          if (!isOutGoingRequest) {
            if (targetLocationAccepted === null) {
              return (
                <div>
                  <h2>
                    Transfer To:{' '}
                    {
                      data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocation
                        ?.label
                    }{' '}
                    (
                    {
                      generateStoreAbbreviation(
                        data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocation,
                      ).abbreviation
                    }
                    )
                  </h2>
                  <h3>
                    STATUS: <span style={{ color: 'orange' }}>WAITING ON RESPONSE</span>{' '}
                  </h3>
                  <Button
                    disabled={loadingCancelInventoryrtransfer}
                    onClick={() => setIsCancelModalOpen(true)}
                  >
                    Cancel Request
                  </Button>
                </div>
              );
            }
            if (targetLocationAccepted === true) {
              return (
                <div>
                  <h2>
                    Transfer To:{' '}
                    {
                      data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocation
                        ?.label
                    }{' '}
                    (
                    {
                      generateStoreAbbreviation(
                        data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocation,
                      ).abbreviation
                    }
                    )
                  </h2>
                  <h3>
                    STATUS: <span style={{ color: 'green' }}>ACCEPTED</span>{' '}
                  </h3>
                </div>
              );
            }
            if (targetLocationAccepted === false) {
              return (
                <div>
                  <h2>
                    Transfer To:{' '}
                    {
                      data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocation
                        ?.label
                    }{' '}
                    (
                    {
                      generateStoreAbbreviation(
                        data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocation,
                      ).abbreviation
                    }
                    )
                  </h2>
                  <h3>
                    STATUS: <span style={{ color: 'red' }}>DECLINED</span>{' '}
                  </h3>
                </div>
              );
            }
          }
          return (
            <div>
              <div>
                <h2>
                  Incoming From:{' '}
                  {data?.viewer?.inventorytransferConnection?.edges[0]?.node?.sourceLocation.label}{' '}
                  (
                  {
                    generateStoreAbbreviation(
                      data?.viewer?.inventorytransferConnection?.edges[0]?.node?.sourceLocation,
                    ).abbreviation
                  }
                  )
                </h2>
                {data?.viewer?.inventorytransferConnection?.edges[0]?.node
                  ?.targetLocationAccepted && (
                  <h3>
                    STATUS: <span style={{ color: 'green' }}>ACCEPTED</span>{' '}
                  </h3>
                )}
                {data?.viewer?.inventorytransferConnection?.edges[0]?.node
                  ?.targetLocationAccepted === null && (
                  <h3>
                    STATUS: <span style={{ color: 'orange' }}>WAITING ON RESPONSE</span>{' '}
                  </h3>
                )}
                {data?.viewer?.inventorytransferConnection?.edges[0]?.node
                  ?.targetLocationAccepted === false && (
                  <h3>
                    STATUS: <span style={{ color: 'red' }}>DECLINED</span>{' '}
                  </h3>
                )}
              </div>
              {data?.viewer?.inventorytransferConnection?.edges[0]?.node?.targetLocationAccepted ===
                null && (
                <div>
                  <Button
                    disabled={loadingAcceptOrDecline}
                    onClick={() => setIsAcceptModalOpen(true)}
                    style={{ marginRight: '1rem' }}
                  >
                    Accept
                  </Button>
                  <Button
                    disabled={loadingAcceptOrDecline}
                    onClick={() => setIsDeclineModalOpen(true)}
                  >
                    Decline
                  </Button>
                </div>
              )}
            </div>
          );
        }}
        columns={[
          {
            title: 'Ingredient',
            field: 'ingredient',
            enablePropagation: true,
            render: data => {
              return data.inventoryitem.label;
            },
          },
          {
            title: 'Quantity',
            enablePropagation: true,
            field: 'quantity',
          },
          {
            title: 'Measure',
            field: 'measure',
            enablePropagation: true,
            render: data => {
              return `${data.label} (${data.abbreviation}.)`;
            },
          },
          {
            title: 'Cost per unit',
            enablePropagation: true,
            field: 'cost',
            render: cost => {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cost);
            },
          },
          {
            title: 'Total Cost',
            enablePropagation: true,
            field: 'calculatedTotalCost',
            render: cost => {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cost);
            },
          },
        ]}
        structureTable={data =>
          data?.viewer?.inventorytransferInventoryitemConnection?.edges?.map(edge => edge.node)
        }
        initialQueryVariables={{
          filter: {
            inventorytransferId: { eq: +transferId },
          },
          sort: {
            created: 'DESC',
          },
          first: 30,
          shouldSkip: false,
          transferId: +transferId,
        }}
        query={INVENTORY_TRANSFERS_INVENTORYITEM}
      />
      <Hidden smUp>
        <Modal open={openModal} handleClose={handleClose} title="Options" maxWidth="sm">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {buttons}
          </div>
        </Modal>
      </Hidden>
      <ConfirmationModal
        handleClose={() => setIsAcceptModalOpen(false)}
        open={isAcceptModalOpen}
        text="Are you sure you want to ACCEPT request?"
        title="Accept"
        showCancel={true}
        confirmAction={() => {
          handleAcceptOrDeclineRequest(true);
        }}
        buttonText="Confirm"
      />
      <ConfirmationModal
        handleClose={() => setIsDeclineModalOpen(false)}
        open={isDeclineModalOpen}
        text="Are you sure you want to DECLINE request?"
        title="Decline"
        showCancel={true}
        confirmAction={() => {
          handleAcceptOrDeclineRequest(false);
        }}
        buttonText="Confirm"
      />
      <ConfirmationModal
        handleClose={() => setIsCancelModalOpen(false)}
        open={isCancelModalOpen}
        text="Are you sure you want to CANCEL request?"
        title="Cancel"
        showCancel={true}
        confirmAction={() => {
          handleCancelRequest(+transferId);
        }}
        buttonText="Confirm"
      />
    </>
  );
};

export default InvoiceJournal;
