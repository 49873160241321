import { gql } from "@apollo/client";

export const FETCH_PERSONS = gql`
  query locationPersons($filter: [LoginFilter]) {
    viewer {
      id
      loginConnection(filter: $filter) {
        edges {
          node {
            id
            person {
              id
              login {
                loginId
              }
              personId
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
