import { gql } from '@apollo/client';

const GLOBAL_EMPLOYEE_ACTIVATION = gql`
  mutation activationEmployee($input: ActivationEmployeeMutationInput!) {
    activationEmployee(input: $input) {
      loginEdge {
        node {
            loginId
            email
            inactive
            employeeInactive
            person {
              id
              personId
              firstName
              lastName
              dateOfBirth
              phone {
                id
                phone
              }
      }
    }
    }
  }
}
`;

export default GLOBAL_EMPLOYEE_ACTIVATION;
