import React, { useState } from 'react';
import CUSTOMERS from '../../../../../apollo/queries/loyalty/customers';
import { ConnectionTable } from '../../../../blocks';
import { COLOR } from '../../../../../constants';
import { phoneNumberFormat } from '../../../../../utils';
import { useHistory } from 'react-router-dom';
import { LoyaltyCustomersStyle } from './style';
import { Button, Select, InputLabel, Grid, FormControl, MenuItem } from '@material-ui/core';
import { Modal } from '../../../../blocks';
import CustomerModal from '../LoyaltyCustomer/Customer/CustomerModal';
function LoyaltyCustomersView() {
  const classes = LoyaltyCustomersStyle();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ConnectionTable
        query={CUSTOMERS}
        minSearchValueLength={1}
        searchPlaceholder="Search by email"
        title="Customers"
        initialQueryVariables={{
          first: 10,
          specificFields: ['email'],
        }}
        onTableRowClick={row =>
          history.push(`${history.location.pathname}/${row.personId}/generalInfo`)
        }
        structureTable={data => {
          return data?.viewer?.loginConnection?.edges.map(login => ({
            id: login?.node?.id,
            loginId: login?.node?.loginId,
            personId: login?.node?.person?.personId,
            brandId: login?.node?.brand?.brandId,
            brandLabel: login?.node?.brand?.label,
            name: `${login?.node?.person?.firstName} ${login?.node?.person?.lastName}`,
            phone: login?.node?.phone || login?.node?.person?.phone?.phone,
            email: login?.node?.email,
            inactive: login?.node?.inactive,
          }));
        }}
        columns={[
          {
            title: 'ID',
            field: 'loginId',
          },
          { title: 'Brand', field: 'brandLabel' },
          { title: 'Name', field: 'name', customSortField: 'person.firstName' },
          {
            title: 'Phone Number',
            field: 'phone',
            disableSort: true,
            cellStyle: {
              color: COLOR.INFO,
            },
            render: phone => {
              return phoneNumberFormat(phone);
            },
          },
          {
            title: 'Email',
            field: 'email',
            cellStyle: {
              color: COLOR.INFO,
            },
          },
          {
            title: 'Status',
            field: 'inactive',
            cellStyle: value => ({
              color: value ? COLOR.ERROR : COLOR.SUCCESS,
              fontWeight: 'bold',
            }),
            render: status => {
              return status ? 'INACTIVE' : 'ACTIVE';
            },
          },
        ]}
        customActionsTop={() => (
          <Button variant="contained" onClick={() => handleOpenModal()}>
            New User
          </Button>
        )}
        customActionsCenter={(data, { refetch, variables }, selected) => {
          /* eslint-disable-next-line no-unused-vars */
          return (
            <div className={classes.selectFactoryWrap}>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Brand</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.brand;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            brand: {
                              brandId: {
                                eq: value,
                              },
                            },
                          },
                        });
                      }
                    }}
                    label="All brands"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {data?.viewer?.brandConnection?.edges?.map(brand => (
                      <MenuItem key={brand.node.brandId} value={brand.node.brandId}>
                        {brand.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={2}>
                {/* TODO: add permission for brand */}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    fullWidth
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.showUnactive;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            showUnactive: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem key={'inactive'} value={true} style={{ color: COLOR.ERROR }}>
                      Inactive
                    </MenuItem>
                    <MenuItem key={'active'} value={false} style={{ color: COLOR.SUCCESS }}>
                      Active
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </div>
          );
        }}
      />
      <Modal open={showModal} handleClose={handleCloseModal} title="Create Customer">
        <CustomerModal handleClose={handleCloseModal} newCustomer={true} />
      </Modal>
    </>
  );
}

export default LoyaltyCustomersView;
