import { gql } from '@apollo/client';
import { fieldgroupFragment } from '../../fragments';

const FIELDGROUP_LIST = gql`
  query Fieldgroups($first: Int, $fieldgroupFilter: [FieldgroupFilter]) {
    viewer {
      id
      fieldgroupConnection(first: $first, filter: $fieldgroupFilter, fieldGroupOnly: 10) {
        edges {
          node {
            ...FieldgroupData
          }
        }
      }
    }
  }
  ${fieldgroupFragment.fieldgroup}
`;

export default FIELDGROUP_LIST;
