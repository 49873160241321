import React from 'react';
import { TextField, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller } from 'react-hook-form';

import ItemSelect from '../../../../../GeneralSettings/DiscountType/ItemSelect';
import ItemGroupStyles from './styles';

function ItemGroup({ control, index, group, handleDelete, errors }) {
  const classes = ItemGroupStyles();

  return (
    <Grid item xs={12} className={classes.row}>
      <ItemSelect
        couponTriggers={group}
        control={control}
        name={`triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[${index}].itemIds`}
        errors={errors}
        isTrigger={true}
        includeSubcategories={false}
      />

      <Controller
        control={control}
        name={`triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[${index}].minQuantity`}
        defaultValue={group.minQty}
        rules={{
          required: 'Minimum quantity is required',
          min: 1,
        }}
        render={({ onChange, value }) => {
          return (
            <TextField
              defaultValue={group.minQty}
              onChange={onChange}
              inputProps={{
                min: 1,
              }}
              error={
                errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData[index]
                  ?.minQuantity
              }
              helperText={
                errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData[index]
                  ?.minQuantity?.message
              }
              type="number"
              value={value}
              label="Min Quantity"
              className={classes.minQty}
            />
          );
        }}
      />

      <Controller
        control={control}
        name={`triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[${index}].maxQuantity`}
        defaultValue={group.maxQty}
        rules={{
          required: 'Maximum quantity is required',
          min: 1,
        }}
        render={({ onChange, value }) => {
          return (
            <TextField
              onChange={onChange}
              defaultValue={group.maxQty}
              inputProps={{
                min: 1,
              }}
              error={
                errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData[index]
                  ?.maxQuantity
              }
              helperText={
                errors?.triggerEvents?.automatic?.itemAddedNumberTimes?.itemQuantityData[index]
                  ?.maxQuantity?.message
              }
              value={value}
              type="number"
              label="Max Quantity"
              className={classes.maxQty}
            />
          );
        }}
      />

      <IconButton onClick={() => handleDelete(index)} aria-label="close">
        <CloseIcon />
      </IconButton>
    </Grid>
  );
}

export default ItemGroup;
