import { gql } from '@apollo/client';

export const REMOVE_INVENTORYITEMCATEGORYTYPE = gql`
  mutation REMOVE_INVENTORYITEMCATEGORYTYPE($inventoryitemcategorytypeId: Int!) {
    removeInventoryitemcategorytype(
      input: { inventoryitemcategorytypeId: $inventoryitemcategorytypeId }
    ) {
      viewer {
        id
      }
      removed
    }
  }
`;
