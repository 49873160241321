import { gql } from "@apollo/client";

export const REMOVE_COUPON = gql`
mutation removeCoupon($input: RemoveCouponMutationInput!) {
  removeCoupon(input:$input){
    viewer{
      id
    }
    removedCouponId
  }
}
`;
