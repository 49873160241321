import { makeStyles } from "@material-ui/core";
import { SNACKBAR_STATUS } from "../../../constants";

const Snackbar = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const SnackbarContent = (theme) => ({
  root: {
    backgroundColor: ({ type }) => {
      switch (type) {
        case SNACKBAR_STATUS.ERROR: {
          return theme.palette.error.main;
        }
        case SNACKBAR_STATUS.SUCCESS: {
          return theme.palette.success.main;
        }
        case SNACKBAR_STATUS.INFO: {
          return theme.palette.info.main;
        }
        case SNACKBAR_STATUS.WARNING: {
          return theme.palette.warning.main;
        }
        default: {
          return theme.palette.info.main;
        }
      }
    },
  },
  message: {
    "& > p": {
      margin: 0,
      marginLeft: "20px",
    },
    display: "flex",
    alignItems: "center",
  },
});

const SnackBarStyles = makeStyles(Snackbar);
const SnackBarContentStyles = makeStyles(SnackbarContent);

export { SnackBarStyles, SnackBarContentStyles };
