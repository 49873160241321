import { gql } from '@apollo/client';

const VENDORS_LIST = gql`
  query Vendors(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [VendorSort]
    $filter: [VendorFilter]
    $specificFields: [String]
  ) {
    viewer {
      id
      vendorConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        specificFields: $specificFields
        fieldGroupOnly: 76
      ) {
        totalCount
        permissions
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            expired
            vendorId
            label
            orderUrl
            contactName
            phone {
              phone
            }
            email
            address {
              city
              zip
              address
              state {
                label
                abbreviation
              }
            }
            promoCode
            notes
            vendorimage {
              label
              description
              url
            }
          }
        }
      }
    }
  }
`;

export default VENDORS_LIST;
