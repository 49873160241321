import React, { useState } from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

import ItemAddedNumberTimes from './ItemAddedNumberTimes';
import SubtotalSpecificAmount from './SubtotalSpecificAmount';
import CartContainsItems from './CartContainsItems';
import ItemInCart from './ItemInCart';
import ForEachItem from './ForEachItem';
import CategorySelectWrapper from './SubcategoryItemInCart';
import ForEachCategorySelectWrapper from './ForEachSubcategoryItemInCart';
import ItemFromSubcategoryAndItem from './ItemFromSubcategoryAndItem';
import OrderCategory from './OrderCategory';

function Events({
  coupon,
  couponTriggers,
  control,
  triggers,
  cartItemsRequired,
  setCartItemsRequired,
  itemAddedNumberTimes,
  itemInCart,
  setItemInCart,
  errors,
  forEachItem,
  setForEachItem,
  setSelectedSubcategoriesTrigger,
  selectedSubcategoriesTrigger,
  setValue,
}) {

  const [eventType, setEventType] = useState(couponTriggers?.couponTriggerId);
  const handleEventTypeChange = e => {
    const { value } = e.target;
    setEventType(value);
  };

  const renderSwitchCase = () => {
    switch (eventType) {
      case 2:
        return (
          <SubtotalSpecificAmount
            couponTriggers={couponTriggers}
            errors={errors}
            control={control}
          />
        );
      case 3:
        return (
          <ItemInCart
            couponTriggers={couponTriggers}
            errors={errors}
            control={control}
            cartItemsRequired={itemInCart}
            setCartItemsRequired={setItemInCart}
          />
        );

      case 4:
        return (
          <CartContainsItems
            couponTriggers={couponTriggers}
            control={control}
            errors={errors}
            cartItemsRequired={cartItemsRequired}
            setCartItemsRequired={setCartItemsRequired}
          />
        );
      case 5:
        return (
          <CategorySelectWrapper
            couponTriggers={couponTriggers}
            control={control}
            errors={errors}
            setCartItemsRequired={setSelectedSubcategoriesTrigger}
            cartItemsRequired={selectedSubcategoriesTrigger}
          />
        );
      case 6:
        return (
          <ItemAddedNumberTimes
            couponTriggers={couponTriggers}
            errors={errors}
            control={control}
            itemAddedNumberTimes={itemAddedNumberTimes}
          />
        );
      case 7:
        return (
          <ForEachItem
            couponTriggers={couponTriggers}
            control={control}
            errors={errors}
            cartItemsRequired={forEachItem}
            setCartItemsRequired={setForEachItem}
          />
        );
      case 8:
        return (
          <ForEachCategorySelectWrapper
            control={control}
            errors={errors}
            couponTriggers={couponTriggers}
            cartItemsRequired={selectedSubcategoriesTrigger}
            setCartItemsRequired={setSelectedSubcategoriesTrigger}
          />
        );
      case 10:
        return (
          <ItemFromSubcategoryAndItem
            control={control}
            errors={errors}
            couponTriggers={couponTriggers}
            cartItemsRequired={cartItemsRequired}
            setCartItemsRequired={setCartItemsRequired}
            selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
            setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
          />
        );
      case 11:
        return (
          <OrderCategory
            errors={errors}
            control={control}
            couponTriggers={couponTriggers}
            setValue={setValue}
          />
        );
      default:
        return '';
    }
  };
  const styles = {
    display: eventType === 2 || eventType === 6 ? 'flex' : 'block',
    gap: '1rem',
  };
  return (
    <div style={styles}>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-native-simple">Events</InputLabel>
        <Select
          onChange={handleEventTypeChange}
          name="event"
          label="Events"
          defaultValue={eventType}
        >
          {triggers.map((trigger, i) => (
            <MenuItem key={i} value={trigger.couponTriggerId}>
              {trigger.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {renderSwitchCase()}
    </div>
  );
}

export default Events;
