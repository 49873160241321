import { gql } from '@apollo/client';

const REMOVE_MENU_FROM_TREE = gql`
  mutation RemoveMenuFromTree($input: RemoveMenuFromTreeMutationInput!) {
    removeMenuFromTree(input: $input) {
      viewer {
        id
        }
        }
  }
`;

export default REMOVE_MENU_FROM_TREE;
