import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import OrderView from './View';
import { uglifyId } from '../../../../../utils';
import { useMutation, useQuery } from '@apollo/client';
import { ORDER_INFO } from '../../../../../apollo/queries';
import { CircularProgress } from '@material-ui/core';
import REPRINT from '../../../../../apollo/mutations/orders/rePrint';
import { CoreContext } from '../../../../../CoreContext';
import { useFormSubmitPermissions } from '../../../../../hooks';
import { MUTATION_NAME } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const Order = () => {
  const { setSnackbar } = useSnackbar();
  const context = useContext(CoreContext);
  const mutationPermissions = context?.coreUtils?.getAppActions()[41]?.mutations;

  const { orderId } = useParams();
  const fullOrderId = uglifyId('Order', orderId);
  const [tipModalOpen, setTipModalOpen] = useState(false);
  const [refundModalOpen, setRefundModalOpen] = useState({
    open: false,
    payment: null
  });
  const [permissions, setPermissions] = useState();

  const { accessToMutations } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.RePrintMutationInput,
      MUTATION_NAME.SetQuoteStepMutationInput,
      MUTATION_NAME.SaveCreditCardMutationInput,
      MUTATION_NAME.CreatePaymentMutationInput,
      MUTATION_NAME.CreateCateringPaymentMutationInput
    ],
    permissions,
  });

  const { data, error, loading } = useQuery(ORDER_INFO, {
    variables: {
      id: fullOrderId,
      first: 2147483647,
    },
    onCompleted: data1 => {
      if (!permissions) {
        setPermissions({
          id: data1?.viewer?.quote?.permissions?.id,
          quoteId: data1?.viewer?.quote?.permissions?.id,
          personId: data1?.viewer?.quote?.permissions?.person?.__typename,
          source: data1?.viewer?.quote?.permissions?.person?.stripeAccount,
          tenderId: data1?.viewer?.quote?.permissions?.paymentConnection?.edges?.node?.tender?.__typename,
          gatewayId: data1?.viewer.quote?.permissions?.paymentConnection?.edges?.node?.paymentGatewayConnection?.edges?.node?.__typename,
          amount: data1?.viewer?.quote?.permissions?.paymentConnection?.edges?.node?.amount,
       })
      }
    }
  });
  const [reprint, {loading: reprintLoading}] = useMutation(REPRINT, {
    onCompleted: () => setSnackbar({
      open: true,
      type: "success",
      text: 'Your receipt will be printed within 30 seconds'
    }),
    onError: (error) => setSnackbar({
      open: true,
      type: "error",
      text: error.message
    })
  });

  if (error) return { error };
  if (loading) return <CircularProgress />;

  const handleReprint = () => {
    reprint({
      variables: {
          quoteId: data?.viewer?.quote?.id
      }
    })
  };

  const handleTipModalOpen = () => {
    setTipModalOpen(true)
  };

  const handleTipModalClose = () => {
    setTipModalOpen(false)
  };

  const handleRefundModalOpen = (value) => {
    setRefundModalOpen(value)
  };

  const stateList = data?.viewer?.stateConnection?.edges.map(state => ({
    label: state.node.label,
    value: state.node.stateId,
  }));

  return (
    <>
    <OrderView
      data={data}
      handleReprint={handleReprint}
      reprintLoading={reprintLoading}
      tipModalOpen={tipModalOpen}
      stateList={stateList}
      mutationPermissions={mutationPermissions}
      handleTipModalOpen={handleTipModalOpen}
      handleTipModalClose={handleTipModalClose}
      refundModalOpen={refundModalOpen}
      handleRefundModalOpen={handleRefundModalOpen}
      accessToMutations={accessToMutations}
    />
    </>
  );
};

export default Order;
