import { Hidden, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ConnectionTable, Modal } from '../../../../blocks';
import MobileButton from '../../../../blocks/Mobile/MobileButton';
import US_FOODS_INVOICES from '../../../../../apollo/queries/inventoryInvoices/usFoodsInvoices';
import { ArrowForwardIos } from '@material-ui/icons';
import { BUTTON_NAME, getButtons, renderIcon } from '../renderers';
import { paths, INVOICE_ICON, SNACKBAR_STATUS } from '../../../../../constants';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import CREATE_FULL_INVENTORY_COUNT from '../../../../../apollo/mutations/inventoryCount/createInventoryCount';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { getDescription, getName, printCountSheetResults, structureTable } from './helpers';
import { FETCH_LAST_INVENTORY_COUNT } from '../../../../../apollo/queries/locations/fetchLastInventoryCount';

const TaskList = () => {
  const { push } = useHistory();
  let { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];
  const storeNumber = ids.split('-')[1];
  const { setSnackbar } = useSnackbar();
  const [showMoreAction, setShowMoreActions] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [fetchPrintData, { loading, error, data }] = useLazyQuery(FETCH_LAST_INVENTORY_COUNT, {
    onCompleted: data => {
      if (data.viewer.inventorycountConnection.edges[0]) {
        printCountSheetResults(data);
      } else {
        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          text: 'No previous inventory count',
          open: true,
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const buttonsActions = {
    [BUTTON_NAME.PRINT_LAST_COUNT]: e => {
      fetchPrintData({
        variables: {
          locationId,
        },
      });
    },
    [BUTTON_NAME.MORE_ACTIONS]: e => {
      setShowMoreActions(true);
    },
    [BUTTON_NAME.JUMP_TO_COUNT_REPORTS]: e => {
      console.log('Heyyy');
    },
  };

  const allButtons = getButtons(
    [
      { buttonName: BUTTON_NAME.VIEW_JOURNAL },
      { buttonName: BUTTON_NAME.CREATE_INVOICE },
      { buttonName: BUTTON_NAME.INVENTORY_TRANSFER },
      { buttonName: BUTTON_NAME.PRINT_LAST_COUNT },
      { buttonName: BUTTON_NAME.LOCATIONS },
      { buttonName: BUTTON_NAME.JUMP_TO_COUNT_REPORTS },
    ],
    buttonsActions,
  );

  const buttons = getButtons(
    [
      { buttonName: BUTTON_NAME.VIEW_JOURNAL },
      { buttonName: BUTTON_NAME.MORE_ACTIONS },
      { buttonName: BUTTON_NAME.LOCATIONS },
    ],
    buttonsActions,
  );

  const moreActionsButtons = getButtons(
    [
      { buttonName: BUTTON_NAME.CREATE_INVOICE },
      { buttonName: BUTTON_NAME.INVENTORY_TRANSFER },
      { buttonName: BUTTON_NAME.PRINT_LAST_COUNT },
      { buttonName: BUTTON_NAME.JUMP_TO_COUNT_REPORTS },
    ],
    buttonsActions,
  );

  const [createInventorycount] = useMutation(CREATE_FULL_INVENTORY_COUNT, {
    onCompleted: () => {
      push(paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS.replace(':locationId', ids));
    },
    onError: () => {
      push(paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS.replace(':locationId', ids));
    },
  });

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleTableRowClick = useCallback(
    row => {
      if (row.type === INVOICE_ICON.FULL_INVENTORY_COUNT) {
        if (!row.inventorycount) {
          createInventorycount({
            variables: {
              input: {
                locationId,
              },
            },
          });
        } else {
          push(paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS.replace(':locationId', ids));
        }
      } else if (row.isTransfer) {
        push(
          paths.COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER_ID.replace(
            ':locationId',
            ids,
          ).replace(':transferId', row.inventorytransferId),
        );
      } else if (row.type === INVOICE_ICON.SIMPLE_INVOICE && !row.isSubmitted) {
        push(
          paths.COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_ID.replace(':locationId', ids).replace(
            ':invoiceId',
            row.invoiceId,
          ),
        );
      } else if (row.type === INVOICE_ICON.WASTE_LOG) {
        push(paths.WASTE_LOG.replace(':locationId', ids).replace(':invoiceId', row.invoiceId));
      } else {
        push(
          paths.COREOPS_INVENTORY_LOCATION_INVOICE_SUMMARY.replace(':locationId', ids).replace(
            ':invoiceId',
            row.invoiceId,
          ),
        );
      }
    },
    [locationId],
  );

  return (
    <>
      <ConnectionTable
        title="Task List"
        subtitle="Assigned to you"
        connectionName="invoiceConnection"
        headerHidden={'xs'}
        onTableRowClick={handleTableRowClick}
        customActionsTop={() => (
          <>
            <Hidden only="xs">{buttons}</Hidden>
            <Hidden smUp>
              <MobileButton style={{ cursor: 'pointer' }} onClick={handleOpen} />
            </Hidden>
          </>
        )}
        columns={[
          {
            title: '',
            field: 'type',
            render: type => renderIcon(type),
            enablePropagation: true,
          },
          {
            title: 'Name',
            field: 'invoiceNumber',
            enablePropagation: true,
            render: (invoiceNumber, _, row) => (
              <>
                <Hidden smUp>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <div style={{ flexGrow: 1, marginRight: '16px' }}>
                      <Typography variant="body1">{getName(row)}</Typography>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <Typography variant="caption" style={{ color: '#747480' }}>
                            {getDescription(row)}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 'bold', color: '#575757' }}
                          >
                            {row.type === INVOICE_ICON.FULL_INVENTORY_COUNT
                              ? row.dueDate
                              : row.type !== INVOICE_ICON.WASTE_LOG &&
                                `Due ${moment(row.dueDate).format('MM/DD/YYYY z')}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ArrowForwardIos style={{ fontSize: '12px', color: '#595959' }} />
                    </div>
                  </div>
                </Hidden>
                <Hidden only="xs">{getName(row)}</Hidden>
              </>
            ),
          },
          {
            title: 'Recieved',
            enablePropagation: true,
            field: 'invoiceDate',
            hidden: 'xs',
            render: (invoiceDate, _, row) => {
              if (!invoiceDate) {
                return '/';
              }
              return moment(invoiceDate).format('MM/DD/YYYY z');
            },
          },
          {
            title: 'Due Date',
            field: 'dueDate',
            enablePropagation: true,
            hidden: 'xs',
            render: (dueDate, _, row) => {
              if (row.type === INVOICE_ICON.WASTE_LOG || row.isTransfer) {
                return '/';
              }
              if (row.type === INVOICE_ICON.FULL_INVENTORY_COUNT) {
                return dueDate;
              }
              return moment(dueDate).format('MM/DD/YYYY z');
            },
          },
        ]}
        structureTable={structureTable}
        initialQueryVariables={{
          filter: {
            storeNumber: { eq: storeNumber },
            isSubmitted: { eq: false },
          },
          filterInventorytransfers: {
            getAllTransfersForlocationId: +locationId,
            targetLocationAccepted: { null: true },
          },
          sort: {
            invoiceDate: 'DESC',
          },
          first: 10,
          storeNumber,
        }}
        query={US_FOODS_INVOICES}
        fetchPolicy={'network-only'}
      />
      <Hidden smUp>
        <Modal open={openModal} handleClose={handleClose} title="Options" maxWidth="sm">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {allButtons}
          </div>
        </Modal>
      </Hidden>
      <Modal
        open={showMoreAction}
        handleClose={() => {
          setShowMoreActions(false);
        }}
        title="Options"
        maxWidth="sm"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {moreActionsButtons}
        </div>
      </Modal>
    </>
  );
};

export default TaskList;
