import { Grid, TextField } from '@material-ui/core';
import LuxonUtils from '@date-io/luxon';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { LOCATIONS } from '../../../../../../apollo/queries';
import { FETCH_LOCATION_INVENTORYITEM } from '../../../../../../apollo/queries/inventory';
import { pad } from '../../../../../../utils';
import { SelectFactory } from '../../../../../blocks';

const Filters = ({ location, setLocation, selectedWeek, setSelectedWeek, setItemId }) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={4} sm={4}>
        <SelectFactory
          label="Location"
          name="locationId"
          placeholder="Search location..."
          fullWidth
          query={LOCATIONS}
          multiple={false}
          renderCheckBox={false}
          otherVariables={{
            fieldGroupOnly: 95,
          }}
          onSelect={value => {
            setLocation(value);
          }}
          structureData={data =>
            data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
              value: edge.node.locationId,
              id: edge.node.locationId,
              index,
              label: `${edge.node?.address?.state?.abbreviation}-${pad(
                edge.node?.storeNumber || 0,
                4,
                '0',
              )} - ${edge.node.label}`,
            }))
          }
        />
      </Grid>

      {location?.id ? (
        <Grid item xs={4} sm={4}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              style={{
                marginTop: 0,
              }}
              inputVariant="outlined"
              format="LL/dd/yyyy"
              margin="normal"
              label="Date Range"
              labelFunc={() => {
                return `${selectedWeek.setLocale('en-us').toLocaleString()} to ${selectedWeek
                  .plus({ days: 6 })
                  .setLocale('en-us')
                  .toLocaleString()}`;
              }}
              initialFocusedDate={selectedWeek}
              onChange={date => {
                setSelectedWeek(date);
              }}
              shouldDisableDate={date => {
                return date.weekday !== 1;
              }}
              TextFieldComponent={props => <TextField fullWidth {...props} disabled />}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      ) : null}
      {location?.id ? (
        <Grid item xs={4} sm={4}>
          <SelectFactory
            label="Location Item"
            name="locationId"
            placeholder="Search Item."
            fullWidth
            disableSearchReset
            query={FETCH_LOCATION_INVENTORYITEM}
            filter={{
              locationId: {
                eq: location?.id,
              },
            }}
            multiple={false}
            renderCheckBox={false}
            onSelect={value => {
              setItemId(value);
            }}
            structureData={data =>
              data?.viewer?.locationIngredientConnection?.edges?.map((edge, index) => ({
                value: edge?.node?.inventoryitem?.inventoryitemId,
                id: edge?.node?.inventoryitem?.inventoryitemId,
                item: edge?.node,
                index,
                label: edge?.node?.inventoryitem?.label,
              }))
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Filters;
