import React, { useState } from "react";
import { Button, Container, Divider, CircularProgress, makeStyles } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { UPLOAD_MENU } from "../../../../../../apollo/mutations";
import { uglifyId } from "../../../../../../utils";
import { SNACKBAR_STATUS } from "../../../../../../constants";
import ConfirmationModal from "../../../../../blocks/ConfirmationModal";
import { Permission, PermissionField } from '../../../../../blocks';
import { useSnackbar } from "../../../../../../hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.fontSizeL,
    marginBottom: theme.spacing(2),
  },
  menuTitle: {
    display: "flex",
    flexDirection: "column"
  },
  activeText: {
    color: theme.colors.bodyTextSecondary,
    marginRight: theme.spacing(4),
  },
  container: {
    display: "flex",
    justifyContent: "space-between"
  },
  MenuText: {
    color: theme.palette.error.main,
    width: "28rem"
  },
  menuButton: {
    textAlign: "right"
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  }
}));

const Menu = ({ fetchMenu, locationId, showPublishButton, lastPublished, menuLabel, mutationPermissions, locationIds, permissions }) => {
  const { setSnackbar } = useSnackbar();
  const publishFullMenuPermission = mutationPermissions.find(
    (mutationPermission) =>
      mutationPermission.label === 'publishFullMenu'
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [uploadMenu, { loading }] = useMutation(UPLOAD_MENU)

  const handleOnUploadClick = () => {
    setIsDialogOpen(true)
  }

  // TODO: improve to handle bulk upload
  const onUploadConfirmation = () => {
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: 'Menu uploading...',
    });
    
    uploadMenu({
      variables: {
        locationId: uglifyId('Location', locationId)
      }
    }).then(() => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Menu uploaded',
      });

      setIsDialogOpen(false);

      fetchMenu();
    }).catch((e) => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: e.message,
      });

      setIsDialogOpen(false);
    });
  }

  const classes = useStyles();
  if (loading) return <CircularProgress/>

  return (
    <Container style={{ width: "100%", marginTop: "1rem" }} id={"menu"}>
      <div className={classes.container}>
        <div className={classes.menuTitle}>
          <div className={classes.title}>
            Menu
          </div>
          <div className={classes.textWrapper}>
            <Permission access={permissions?.menu?.label}>
              <div>
                <div>
                  <span className={classes.activeText}>Active Menu</span>
                </div>
                <div>
                  <span>{menuLabel}</span>
                </div>
              </div>
            </Permission>
            <Permission access={permissions?.locationChowly}>
              <div>
                <div>
                  <span className={classes.activeText}>Last Upload</span>
                </div>
                <div>
                  <span>{lastPublished}</span>
                </div>
              </div>
            </Permission>
          </div>
        </div>
        <div>
          <div className={classes.menuButton}>
            <Permission access={publishFullMenuPermission?.access}>
              <div>
                <PermissionField createHelperText>
                  <Button
                    disabled={!showPublishButton || loading}
                    onClick={() => handleOnUploadClick()}
                    className={classes.errorColor}
                  >
                    PUBLISH FULL MENU
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </div>
          <div className={classes.MenuText}>
            <span>
              Publish Full Menu button will begin a process to update your entire menu
              with Chowly. This action may take up to 30 minutes before changes are completely reflected
              to Chowly.
            </span>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={isDialogOpen}
        confirmAction={onUploadConfirmation}
        handleClose={() => setIsDialogOpen(false)}
      />
      <Divider/>
    </Container>
  );
};

export default Menu;
