import React, { useState } from 'react';
import LuxonUtils from '@date-io/luxon';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TextField, Grid, Typography } from '@material-ui/core';

import SelectFactory from '../../../../blocks/SelectFactory';
import { ScheduleHeaderStyles } from './styles';
import { LOCATIONS } from '../../../../../apollo/queries';
import { pad } from '../../../../../utils/index';

const ScheduleHeader = ({
  onLocationChange,
  selectedLocationState,
  selectedWeek,
  onDateChange,
}) => {
  const classes = ScheduleHeaderStyles();

  return (
    <>
      <Typography variant="h3" component="h2">
        Schedule
      </Typography>
      <Typography variant="body2" className={classes.description}>
        Select the location and date range you would like to build a schedule for.
      </Typography>
      <Grid container item xs={12} spacing={4} alignItems="center">
        <Grid item xs={6}>
          <SelectFactory
            label={'Location'}
            fullWidth
            placeholder={'Select Location'}
            query={LOCATIONS}
            defaultValue={{ ...selectedLocationState }}
            disableCloseOnSelect={false}
            multiple={false}
            renderCheckBox={false}
            filter={{
              active: { null: false },
            }}
            structureData={data => {
              return data?.viewer?.locationConnection?.edges.map((location, index) => {
                return {
                  label: `${location.node.address?.state?.abbreviation}-${pad(
                    location?.node?.storeNumber,
                    4,
                  )} ${location?.node?.label}`,
                  id: location?.node?.id,
                  locationId: location?.node?.locationId,
                  index,
                  locationData: location,
                };
              });
            }}
            onSelect={value => onLocationChange(value.locationData)}
            className={classes.select}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="LL/dd/yyyy"
              margin="normal"
              label="Date range start"
              labelFunc={() =>
                `${selectedWeek.toLocaleString()} to ${selectedWeek
                  .plus({ days: 6 })
                  .toLocaleString()}`
              }
              initialFocusedDate={selectedWeek}
              onChange={date => onDateChange(date)}
              shouldDisableDate={date => {
                const workWeekStartId =
                  typeof selectedLocationState?.workWeekStartId == 'number'
                    ? selectedLocationState?.workWeekStartId
                    : 1;
                return date.weekday !== workWeekStartId;
              }}
              TextFieldComponent={props => <TextField {...props} disabled={true} />}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleHeader;
