import { useLazyQuery } from '@apollo/client';
import { Grid, Typography, Button } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { FETCH_WEEK_IN_LIFE } from '../../../../../apollo/queries/inventory';
import generateReportPdf from '../../../../../utils/pdfUtils/generateReportPdf';
import { DataTable } from '../../../../blocks';
import Switch from '../../../../blocks/Elements/Switch';
import PaperMain from '../../../../blocks/PaperMain';
import { Skeleton } from '@material-ui/lab';
import Filters from './Filters';

const WeekInLife = () => {
  const [weekInLife, setWeekInLife] = useState(null);
  const [displayCost, setDisplayCost] = useState(false);
  const [location, setLocation] = useState();
  const [selectedWeek, setSelectedWeek] = useState(DateTime.local());
  const [itemId, setItemId] = useState(null);

  const [fetchWeekInLife, { loading }] = useLazyQuery(FETCH_WEEK_IN_LIFE, {
    onCompleted: data => {
      setWeekInLife(data.viewer.locationIngredientConnection.edges[0].node);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (location && selectedWeek && itemId) {
      fetchWeekInLife({
        variables: {
          filter: {
            locationId: { eq: location.id },
            ingredientId: { eq: itemId.id },
          },
          dateRange: {
            startDate: selectedWeek.toISODate(),
            endDate: selectedWeek.plus({ days: 6 }).toISODate(),
          },
          displayAsCost: true,
        },
      });
    }
  }, [location, selectedWeek, itemId]);

  const handleOnExportPdfClick = useCallback(
    ({ tableData }) => {
      generateReportPdf({
        title: `Week In The Life - ${location.label} (${moment(selectedWeek.toISODate())?.format(
          'MM/DD/YYYY',
        ) || ''} - ${moment(selectedWeek.plus({ days: 6 }).toISODate())?.format('MM/DD/YYYY') ||
          ''})`,
        subtitles: weekInLife
          ? [
              `Selected product: ${weekInLife.inventoryitem.label}`,
              `ID: ${weekInLife.inventoryitem.inventoryitemId}`,
              `Main UOM: ${weekInLife.inventoryitem.measure.label}`,
            ]
          : null,
        tableData,
        header: [
          { text: 'Transaction Date', field: 'transactionDate' },
          { text: 'Transaction Type', field: 'transactionType' },
          { text: 'Source/Destination', field: 'source' },
          { text: 'Number', field: 'invoiceNumber' },
          { text: 'User', field: 'user' },
          { text: 'Storage Location', field: 'storageLocation' },
          { text: 'Note', field: 'note' },
          { text: 'Value', field: 'actualQty' },
          { text: 'Qty In', field: 'qtyIn' },
          { text: 'Qty Out', field: 'qtyOut' },
          { text: 'Culmulative Qty', field: 'theoreticalQty' },
        ],
      });
    },
    [location, selectedWeek, weekInLife],
  );

  return (
    <PaperMain>
      <Typography variant="h3" style={{ marginBottom: '1rem' }}>
        Week In The Life
      </Typography>
      <Filters
        selectedWeek={selectedWeek}
        setItemId={setItemId}
        setSelectedWeek={setSelectedWeek}
        displayCost={displayCost}
        location={location}
        setLocation={setLocation}
      />
      <div style={{ display: 'flex' }}>
        <div>
          <h3>Dispaly Cost</h3>
        </div>
        <Switch
          onChange={e => {
            setDisplayCost(e.target.checked);
          }}
          handleSwitchChange={e => {
            setDisplayCost(e);
          }}
          checked={displayCost}
        />
      </div>
      {loading && (
        <div style={{ marginTop: '40px' }}>
          <h3>Loading...</h3>
          <Skeleton height={60} animation="pulse" variant="text" />
          <Skeleton height={60} animation="pulse" variant="text" />
          <Skeleton height={60} animation="pulse" variant="text" />
          <Skeleton height={60} animation="pulse" variant="text" />
        </div>
      )}
      {weekInLife && !loading ? (
        <DataTable
          data={weekInLife.weekInTheLife}
          customActionsCenter={(_, { tableData, variables }) => (
            <Grid container spacing={3} alignItems="center">
              <Grid item direction="column" xs={12} sm={8}>
                <Typography variant="h5">
                  Date:
                  <strong>
                    {` ${moment(selectedWeek.toISODate())?.format('MM/DD/YYYY') || ''} to ${moment(
                      selectedWeek.plus({ days: 6 }).toISODate(),
                    )?.format('MM/DD/YYYY') || ''}`}
                  </strong>
                </Typography>
                <br />
                <Typography variant="h5">
                  Selected product: <strong> {weekInLife.inventoryitem.label} </strong>
                </Typography>
                <Typography variant="h5">
                  ID:<strong> {weekInLife.inventoryitem.inventoryitemId}</strong>
                </Typography>
                <Typography variant="h5">
                  Main UOM: <strong> {weekInLife.inventoryitem.measure.label}</strong>
                </Typography>
              </Grid>
              <Grid item justify="flex-end" xs={12} sm={4}>
                <div>
                  <Button
                    disabled={isEmpty(tableData)}
                    onClick={() => handleOnExportPdfClick({ tableData, variables })}
                  >
                    Export PDF
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
          structureTable={data =>
            data.length
              ? data.map(row => ({
                  invoiceNumber:
                    !row || [1, 3, 4].includes(row.transactionId) ? '/' : row.invoiceNumber,
                  source:
                    !row || !row?.invoiceNumber || [1, 3, 4].includes(row.transactionId)
                      ? '/'
                      : row.supplierName
                      ? row.supplierName
                      : 'US FOODS',
                  transactionDate: moment(row.transactionDate).format('MM/DD/YYYY hh:mm A'),
                  transactionType: !row.transactionType ? '/' : row.transactionType,
                  number: '/',
                  user: !row.user ? '/' : row.user,
                  storageLocation: !row || row.transactionId !== 4 ? '/' : row.storageLocation,
                  note: !row || ![2, 5].includes(row.transactionId) ? '/' : row.note,
                  actualQty:
                    !row || row.transactionId !== 4
                      ? '/'
                      : displayCost
                      ? `$ ${row.actualQtyCost || 'No cost'}`
                      : row.actualQty,
                  qtyIn:
                    !row.qtyIn || row.transactionId === 4
                      ? '/'
                      : displayCost
                      ? `$ ${row.qtyInCost || 'No cost'}`
                      : row.qtyIn,
                  qtyOut: !row.qtyOut
                    ? '/'
                    : displayCost
                    ? `$ ${row.qtyOutCost || 'No cost'}`
                    : row.qtyOut,
                  theoreticalQty: displayCost
                    ? `$ ${row.theoreticalQtyCost || 'No cost'}`
                    : row.theoreticalQty,
                }))
              : null
          }
          columns={[
            {
              title: 'Transaction Date',
              field: 'transactionDate',
            },
            {
              title: 'Transaction Type',
              field: 'transactionType',
            },
            {
              title: 'Source/Destination',
              field: 'source',
            },
            {
              title: 'Number',
              field: 'invoiceNumber',
            },
            {
              title: 'User',
              field: 'user',
            },
            {
              title: 'Storage Location',
              field: 'storageLocation',
            },
            {
              title: 'Note',
              field: 'note',
            },
            {
              title: 'Value',
              field: 'actualQty',
            },
            {
              title: 'Qty In',
              field: 'qtyIn',
            },
            {
              title: 'Qty Out',
              field: 'qtyOut',
            },
            {
              title: 'Culmulative Qty',
              field: 'theoreticalQty',
            },
          ]}
        />
      ) : (
        !loading && !itemId && <h2>Select Location And Item</h2>
      )}
    </PaperMain>
  );
};

export default WeekInLife;
