import { gql } from '@apollo/client';

const UPDATE_WEEKLY_SALE_COMMITMENT = gql`mutation updateWeeklysalesprojectionsForLocation(
$input: UpdateScheduleweeklysalesprojectionMutationInput!
){
  updateScheduleweeklysalesprojection(input: $input ) {
    scheduleweeklysalesprojection {
      id
      scheduleweeklysalesprojectionId
      created
      started
      locationId
      auvCommitmentAmount
      auvWeightMondayFraction
      auvWeightTuesdayFraction
      auvWeightWednesdayFraction
      auvWeightThursdayFraction
      auvWeightFridayFraction
      auvWeightSaturdayFraction
      auvWeightSundayFraction
    }
  }
}`;

export default UPDATE_WEEKLY_SALE_COMMITMENT