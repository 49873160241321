import { gql } from '@apollo/client';

export const addressFragment = {
  address: gql`
    fragment AddressData on Address {
      id
      address
      city
      zip
      additionalInfo
      state {
        id
        stateId
        label
        country {
          countryId
          alpha2
          id
          label
        }
      }
    }
  `,
};
