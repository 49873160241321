import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  title: {
    fontSize: '16px',
  },
  tooltip: {
    marginLeft: '10px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipDiv: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  projectionContainer: {
    margin: '15px 0',
  },
  input: {
    paddingRight: '15px',
  },
  data: {
    fontSize: '18px',
  },
  totalPercentage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  employeeList: {
    maxHeight: 600,
    overflowY: 'auto',

    '& .MuiGrid-container': {
      display: 'none',
    },
  },
  scheduleTimelineActions: {
    marginBottom: theme.spacing(2),

    '& .MuiButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
});

export const EmployeesJobsTotalSyles = makeStyles(Styles);
