import { gql } from '@apollo/client';

const CREATE_TIMESHIFT = gql`
  mutation CreateTimeshift($input: CreateTimeshiftMutationInput!) {
    createTimeshift(input: $input) {
      clientMutationId
    }
  }
`;

export default CREATE_TIMESHIFT;
