import { gql } from '@apollo/client';

export const FETCH_LOCATION_MENU = gql`
  query locationMenu($id: ID!) {
    viewer {
      location(id: $id) {
        id
        permissions
        menuId
        burqId
        address {
          id
        }
      }
    }
  }
`;

export default FETCH_LOCATION_MENU;
