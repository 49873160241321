import { gql } from "@apollo/client";

const SEND_LOCATION = gql`
  mutation sendLocation($input: SendLocationMutationInput!) {
    sendLocation(input: $input) {
      viewer {
        id
      }
    }
  }
`;
export default SEND_LOCATION;