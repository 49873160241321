import { gql } from "@apollo/client";

const QuoteInfo = gql`
  mutation quoteInfo($input: QuoteInfoMutationInput!) {
    quoteInfo(input: $input) {
      viewer {
        id
      }
    }
  }
`;
export default QuoteInfo;