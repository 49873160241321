import { gql } from '@apollo/client';

const DELETE_RECIPE = gql`
  mutation DELETE_RECIPE($input: DeleteRecipeMutationInput!) {
    deleteRecipe(input: $input) {
      viewer {
        id
      }
      deletedRecipeId
    }
  }
`;

export default DELETE_RECIPE;
