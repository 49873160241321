import { gql } from '@apollo/client';

const FETCH_TIERS_CONNECTIONS = gql`
  query fetchTiersAndPlatforms {
    viewer {
      id
      platformConnection {
        edges {
          node {
            id
            label
          }
        }
      }
      tierConnection {
        edges {
          node {
            id
            label
            tierId
          }
        }
      }
    }
  }
`;
export default FETCH_TIERS_CONNECTIONS;
