import React, { useState } from "react";
import CustomerModalView from "./View";
import { useForm } from "react-hook-form";

export default function CustomerModal({ handleClose, customerInfo = {}, newCustomer }) {

  return (
    <>
      <CustomerModalView
        handleCloseModal={handleClose}
        customerInfo={customerInfo}
        newCustomer={newCustomer}
      />
    </>
  );
}
