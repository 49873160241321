import { gql } from '@apollo/client';

const FETCH_ACTUAL_VS_THEORETICAL = gql`
  query actualVsTheoretical(
    $search: String
    $sort: [LocationIngredientSort]
    $filter: [LocationIngredientFilter]
    $dateRange: DateRangeActualVsTheoreticalFilterInput
    $displayAsCost: Boolean
  ) {
    viewer {
      id
      locationIngredientConnection(
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 95
      ) {
        edges {
          node {
            id
            actualVsTheoretical(dateRange: $dateRange, displayAsCost: $displayAsCost)
            ingredient {
              id
              ingredientId
              inventoryitem {
                id
                inventoryitemId
                label
                inventoryitemcategory {
                  id
                  inventoryitemcategoryId
                  reportcategoryId
                  reportcategory {
                    id
                    label
                  }
                  generalledgeraccountId
                  generalledgeraccount {
                    id
                    label
                  }
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_ACTUAL_VS_THEORETICAL;
