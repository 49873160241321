import { gql } from '@apollo/client';

const INVENTORY_TRANSFERS = gql`
  query INVENTORY_TRANSFERS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [InventorytransferFilter]
    $sort: [InventorytransferSort]
  ) {
    viewer {
      id
      inventorytransferConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        fieldGroupOnly: 88
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            inventorytransferId
            sourceLocation {
              label
              storeNumber
              address {
                state {
                  abbreviation
                }
              }
            }
            targetLocation {
              label
              storeNumber
              address {
                state {
                  abbreviation
                }
              }
            }
            targetLocationAccepted
          }
        }
      }
    }
  }
`;

export default INVENTORY_TRANSFERS;
