import React, { useMemo } from 'react';
import OrderingForecasterCategoryView from './View';

const OrderingForecasterCategory = ({ data, refetch, variables }) => {
  const forecastedOrderingPar = useMemo(() => data.viewer.location.forecastedOrderingPar, [
    data.viewer.location.forecastedOrderingPar,
  ]);
  const prepCommitments = useMemo(() => data.viewer.location.prepCommitments, [
    data.viewer.location.prepCommitments,
  ]);

  return (
    <OrderingForecasterCategoryView
      refetch={refetch}
      variables={variables}
      forecastedOrderingPar={forecastedOrderingPar}
      prepCommitments={prepCommitments}
    />
  );
};

export default OrderingForecasterCategory;
