import React from 'react';
import DragAndDropInput from '../../../../../../blocks/DragAndDropInput';
import EquipmentItem from './EquipmentItem';

const initialEquipment = {
  equipmentId: '',
  count: '',
  note: '',
};

const Equipment = ({ register, control, errors, setValue }) => {
  return (
    <DragAndDropInput
      name="equipments"
      title="Equipment"
      addItemButtonText="Add Equipment"
      initialItem={initialEquipment}
      itemComponent={EquipmentItem}
      control={control}
      itemComponentProps={{
        register,
        errors,
        control,
        setValue,
      }}
    />
  );
};

export default Equipment;
