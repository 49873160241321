import { gql } from '@apollo/client';

const FETCH_LOCATION_MENU = gql`
  query menuLocation($filter: [LocationFilter]) {
    viewer {
      id
      locationConnection(filter: $filter, fieldGroup: 19) {
        permissions
        edges {
          node {
            priceCapApplicable
            id
            tierId
            timezone {
              id
              label
            }
            locationChowly {
              lastUpload
              chowlyApiKey
            }
            menuId
            menu {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export default FETCH_LOCATION_MENU;
