import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TreeView, TreeItem } from '@material-ui/lab';
import {
  Grid,
  Chip,
  Paper,
  Checkbox,
  Button,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { SelectFactory } from '../../../../../../../../blocks';
import { FETCH_ITEM_LIST } from '../../../../../../../../../apollo/queries';

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function AddItemsModalView({ data, setExcludedItems, excludedItems, handleClose, multiple }) {
  const [selected, setSelected] = useState([...excludedItems]);

  const handleSave = () => {
    setExcludedItems(selected);
    handleClose(selected);
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container xs={12} style={{ marginBottom: '1rem' }}>
        <Grid item xs={12} md={6}>
          <SelectFactory
            style={{ minWidth: '15rem' }}
            name="excludedItems"
            label="Items"
            placeholder="Select items"
            disableSearchReset
            disableCloseOnSelect={true}
            query={FETCH_ITEM_LIST}
            filter={{
              showHiddenToLocation: {
                eq: true,
              },
              showUnavailableOnline: {
                eq: true,
              },
            }}
            onSelect={values => {
              setSelected(values);
            }}
            multiple={multiple}
            defaultValue={selected}
            structureData={data => {
              return data?.viewer?.itemConnection?.edges.map((edge, index) => {
                return {
                  isItem: true,
                  label: `${edge.node.label} - ID_${edge.node.itemId}`,
                  value: edge.node.id,
                  index,
                  id: edge.node.id,
                };
              });
            }}
          />
        </Grid>
      </Grid>
      <Button onClick={handleSave}>Save</Button>
    </div>
  );
}

export default AddItemsModalView;
