import { gql } from "@apollo/client";

const FETCH_POS_PERMISSIONS = gql`
	query FetchPosPermissions {
		viewer {
			id
			posPermissionConnection(fieldGroup: 59) {
				permissions
				edges {
					node {
						id
						posPermissionId
						label
						description
					}
				}
			}
		}
	}
`;

export default FETCH_POS_PERMISSIONS;
