import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import ConnectionTable from '../../../../../blocks/ConnectionTable';
import {
  CREATE_JOB_BREAK_RULE,
  REMOVE_JOB_BREAK_RULE,
} from '../../../../../../apollo/mutations';
import { JOB_BREAK_RULE_LIST } from '../../../../../../apollo/queries';
import JobBreakRuleForm from '../JobBreakRuleForm';
import { SNACKBAR_STATUS, MUTATION_NAME } from '../../../../../../constants';
import { Permission, PermissionField } from '../../../../../blocks';
import { useFormSubmitPermissions } from '../../../../../../hooks';
import { compact } from 'lodash';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const JobBreakRulesView = ({ mutationPermissions }) => {
  const { setSnackbar } = useSnackbar();

  const createPermission = useMemo(
    () =>
      mutationPermissions.find(
        (permission) => permission.label === 'createJobBreakRule'
      ),
    [mutationPermissions]
  );
  const deletePermission = useMemo(
    () =>
      mutationPermissions.find(
        (permission) => permission.label === 'removeJobBreakRule'
      ),
    [mutationPermissions]
  );

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [jobBreakRule, setJobBreakRule] = useState({});
  const [locationIds, setLocationIds] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [permissions, setPermissions] = useState();

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [MUTATION_NAME.CreateJobBreakRuleMutationInput, MUTATION_NAME.UpdateJobBreakRuleMutationInput],
    permissions,
  });

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openCreateModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openCreateModal]);

  const [
    createJobBreakRule,
    { loading: createJobBreakRuleLoading },
  ] = useMutation(CREATE_JOB_BREAK_RULE);
  const [removeJobBreakRule, { loading: isDeletingJobBreakRule }] = useMutation(
    REMOVE_JOB_BREAK_RULE
  );

  const handleDeleteRow = useCallback(
    (row) => {
      setSnackbar({
        text: 'Deleting job break rule',
        type: SNACKBAR_STATUS.INFO,
        open: true,
      });

      removeJobBreakRule({
        variables: {
          input: {
            locationId: row.jobBreakRule.location.locationId,
          },
        },
        refetchQueries: [{ query: JOB_BREAK_RULE_LIST }],
      })
        .then(() =>
          setSnackbar({
            text: 'Job break rule deleted',
            type: SNACKBAR_STATUS.SUCCESS,
            open: true,
          })
        )
        .catch((error) =>
          setSnackbar({
            text: error.message,
            type: SNACKBAR_STATUS.ERROR,
            open: true,
          })
        );
    },
    [removeJobBreakRule, setSnackbar]
  );

  const { handleSubmit, control, register, reset, errors } = useForm({
    defaultValues: {
      ruleName: '',
      paidBreakMaxDuration: '0',
      paidBreakMinDuration: '0',
      paidBreakInterval: '0',
      paidBreakStartOffset: '0',
      paidBreakIntervalBetweenBreaks: '0',
      paidBreakRequiresApproval: false,
      paidBreakFlipsToUnpaid: false,
      paidBreakManagerApprovalToClockinEarly: false,
      unpaidBreakMaxDuration: '0',
      unpaidBreakMinDuration: '0',
      unpaidBreakStartOffset: '0',
      unpaidBreakIntervalBetweenBreaks: '0',
      unpaidBreakManagerApprovalToClockinEarly: false,
    },
  });

  useEffect(() => {
    if (jobBreakRule) {
      reset(jobBreakRule);
    }
  }, [jobBreakRule, reset]);

  const handleClose = () => {
    setOpenCreateModal({
      open: false,
      permissions: null,
    });
    setOpenUpdateModal({
      open: false,
      permissions: null,
    });
    setJobBreakRule({});
  };

  const openCreateJobBreakRuleDialog = useCallback((data) => {
    setOpenCreateModal({
      open: true,
      permissions:
        data?.viewer?.jobBreakRuleConnection?.permissions,
    });

    setOpenWarningMutationName(MUTATION_NAME.CreateJobBreakRuleMutationInput);
  }, [setOpenWarningMutationName]);

  const onTableRowClick = useCallback((row) => {
    const jobBreakRule = {
      id: row.jobBreakRule.id,
      ruleName: row.jobBreakRule.ruleName,
      store: row.jobBreakRule.location.label,
      location: row.jobBreakRule.location,
      paidBreakMaxDuration: Math.floor(
        row.jobBreakRule.paidBreakMaxDuration / 60
      ),
      paidBreakMinDuration: Math.floor(
        row.jobBreakRule.paidBreakMinDuration / 60
      ),
      paidBreakInterval: Math.floor(
        row.jobBreakRule.paidBreakInterval / 60
      ),
      paidBreakStartOffset: Math.floor(
        row.jobBreakRule.paidBreakStartOffset / 60
      ),
      paidBreakIntervalBetweenBreaks: Math.floor(
        row.jobBreakRule.paidBreakIntervalBetweenBreaks / 60
      ),
      paidBreakRequiresApproval: row.jobBreakRule.requiresManagerApproval,
      paidBreakFlipsToUnpaid: row.jobBreakRule.paidBreakFlipsToUnpaid,
      paidBreakManagerApprovalToClockinEarly:
        row.jobBreakRule.paidBreakManagerApprovalToClockinEarly,
      unpaidBreakMaxDuration: Math.floor(
        row.jobBreakRule.unpaidBreakMaxDuration / 60
      ),
      unpaidBreakMinDuration: Math.floor(
        row.jobBreakRule.unpaidBreakMinDuration / 60
      ),
      unpaidBreakStartOffset: Math.floor(
        row.jobBreakRule.unpaidBreakStartOffset / 60
      ),
      unpaidBreakIntervalBetweenBreaks: Math.floor(
        row.jobBreakRule.unpaidBreakIntervalBetweenBreaks / 60
      ),
      unpaidBreakManagerApprovalToClockinEarly:
        row.jobBreakRule.unpaidBreakManagerApprovalToClockinEarly,
    };
    setJobBreakRule(jobBreakRule);
    setOpenUpdateModal({
      open: true,
      permissions: row.permissions,
    });
    setOpenWarningMutationName(MUTATION_NAME.UpdateJobBreakRuleMutationInput);
  }, [setOpenWarningMutationName]);

  const onSubmit = async ({
    ruleName,
    paidBreakMaxDuration,
    paidBreakMinDuration,
    paidBreakInterval,
    paidBreakStartOffset,
    paidBreakIntervalBetweenBreaks,
    paidBreakRequiresApproval,
    paidBreakFlipsToUnpaid,
    paidBreakManagerApprovalToClockinEarly,
    unpaidBreakMaxDuration,
    unpaidBreakMinDuration,
    unpaidBreakStartOffset,
    unpaidBreakIntervalBetweenBreaks,
    unpaidBreakManagerApprovalToClockinEarly,
  }) => {
    setButtonDisabled(true);

    await createJobBreakRule({
      variables: {
        input: {
          ruleName,
          paidBreakMaxDuration,
          paidBreakMinDuration,
          paidBreakInterval,
          paidBreakStartOffset,
          paidBreakIntervalBetweenBreaks,
          paidBreakRequiresApproval,
          paidBreakFlipsToUnpaid,
          paidBreakManagerApprovalToClockinEarly,
          unpaidBreakMaxDuration,
          unpaidBreakMinDuration,
          unpaidBreakStartOffset,
          unpaidBreakIntervalBetweenBreaks,
          unpaidBreakManagerApprovalToClockinEarly,
          locationIds:
            locationIds.length !== 0
              ? locationIds
              : jobBreakRule.location.locationId,
        },
      },
      refetchQueries: [{ query: JOB_BREAK_RULE_LIST }],
    });
    setLocationIds([]);
    handleClose();
    setButtonDisabled(false);
  };

  return (
    <div>
      <ConnectionTable
        showSearch
        query={JOB_BREAK_RULE_LIST}
        initialQueryVariables={{
          first: 15,
        }}
        title="Job Break Rules"
        customActionsTop={(data, query) => {
          if (!permissions) {
            setPermissions({
              ...data?.viewer?.jobBreakRuleConnection?.permissions,
              paidBreakRequiresApproval: data?.viewer?.jobBreakRuleConnection?.permissions?.requiresManagerApproval,
            });
          }

          return (
            <Permission access={Math.min(createPermission?.access, accessToMutations?.[MUTATION_NAME.CreateJobBreakRuleMutationInput])}>
              <div>
                <PermissionField createHelperText>
                  <Button onClick={() => openCreateJobBreakRuleDialog(data)}>
                    New Job Break Rule
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          );
        }}
        structureTable={(data) => {
          const permissions = data?.viewer?.jobBreakRuleConnection?.permissions;
          return data?.viewer?.jobBreakRuleConnection?.edges.map(({ node }) => {
            return {
              id: node.id,
              ruleName: node.ruleName,
              store: compact([node.location.label, node.location.storeNumber]).join(' - #'),
              paidBreakMaxDuration: node.paidBreakMaxDuration,
              paidBreakMinDuration: node.paidBreakMinDuration,
              paidBreakInterval: node.paidBreakInterval,
              paidBreakStartOffset: node.paidBreakStartOffset,
              paidBreakIntervalBetweenBreaks:
                node.paidBreakIntervalBetweenBreaks,
              paidBreakRequiresApproval: node.requiresManagerApproval,
              paidBreakFlipsToUnpaid: node.paidBreakFlipsToUnpaid,
              paidBreakManagerApprovalToClockinEarly:
                node.paidBreakManagerApprovalToClockinEarly,
              unpaidBreakMaxDuration: node.unpaidBreakMaxDuration,
              unpaidBreakMinDuration: node.unpaidBreakMinDuration,
              unpaidBreakStartOffset: node.unpaidBreakStartOffset,
              unpaidBreakIntervalBetweenBreaks:
                node.unpaidBreakIntervalBetweenBreaks,
              unpaidBreakManagerApprovalToClockinEarly:
                node.unpaidBreakManagerApprovalToClockinEarly,
              jobBreakRule: node,
              permissions,
              disableDelete: !deletePermission?.access,
              disableTableRowClick: !createPermission?.access,
            };
          });
        }}
        columns={[
          { title: 'Rule Name', field: 'ruleName' },
          { title: 'Store', field: 'store', customPermissionField:'location.__typename' },
        ]}
        onTableRowClick={onTableRowClick}
        handleDeleteRow={handleDeleteRow}
        useConfirmationModalForDelete
        isDeleting={isDeletingJobBreakRule}
        deleteConfirmationText="Are you sure you want to delete job break rule?"
      />
      <JobBreakRuleForm
        title={'Create Job Break Rule'}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        createJobBreakRuleLoading={createJobBreakRuleLoading}
        open={openCreateModal.open}
        control={control}
        mutationPermission={createPermission}
        register={register}
        permissions={openCreateModal.permissions}
        errors={errors}
        descriptionElementRef={descriptionElementRef}
        handleClose={handleClose}
        buttonDisabled={buttonDisabled || createJobBreakRuleLoading || accessToMutations?.[MUTATION_NAME.CreateJobBreakRuleMutationInput] < 7}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
      />
      <JobBreakRuleForm
        title={'Update Job Break Rule'}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        open={openUpdateModal.open}
        mutationPermission={createPermission}
        jobBreakRule={jobBreakRule}
        control={control}
        register={register}
        permissions={openUpdateModal.permissions}
        errors={errors}
        descriptionElementRef={descriptionElementRef}
        handleClose={handleClose}
        buttonDisabled={buttonDisabled || createJobBreakRuleLoading || accessToMutations?.[MUTATION_NAME.UpdateJobBreakRuleMutationInput] < 7}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
      />
    </div>
  );
};

JobBreakRulesView.propTypes = {
};

export default JobBreakRulesView;
