import React from 'react';
import { Autocomplete as AutocompleteMUI } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { toLower } from 'lodash';
import { AutocompleteStyles } from './styles';
import { FormHelperText } from '@material-ui/core';

const AutoComplete = ({
  selectedOption,
  id,
  onClear,
  onClose,
  defaultValue = null,
  disableClearable,
  open,
  onOpen,
  getOptionLabel,
  groupBy,
  style,
  renderInput,
  options,
  onChange,
  control,
  register,
  rules,
  disabled,
  error,
  helperText,
  multiple,
  renderOption,
  renderTags,
  disableCloseOnSelect,
  loading,
  onClick,
}) => {
  const handleSelectChange = value => {
    onChange(value);
  };

  const classes = AutocompleteStyles();

  function filterOptions(options, state) {
    if (!state.inputValue) {
      return options;
    }

    return options.filter(
      option =>
        toLower(option?.label || '').match(new RegExp(`^${toLower(state.inputValue || '')}`)) ||
        option?.label?.match(new RegExp(`#${state.inputValue || ''}`)),
    );
  }

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={props => (
        <div style={{ width: '100%' }}>
          <AutocompleteMUI
            {...props}
            id={id}
            ref={register}
            classes={classes}
            renderTags={renderTags || null}
            multiple={multiple}
            value={props.value || selectedOption}
            disabled={disabled}
            defaultValue={defaultValue}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            disableCloseOnSelect={disableCloseOnSelect}
            groupBy={groupBy}
            open={open}
            loading={loading}
            onClose={onClose}
            disableClearable={disableClearable}
            onOpen={onOpen}
            getOptionSelected={(option, value) => option?.id === value?.id}
            onChange={(_, data) => {
              props.onChange(data);
              onChange && handleSelectChange(data);
            }}
            options={options}
            style={style}
            renderOption={renderOption}
            renderInput={inputProps => renderInput({ ...inputProps, onClick }, props.onChange)}
            fullWidth
          />
          {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </div>
      )}
    />
  );
};

export default AutoComplete;
