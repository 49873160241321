import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import NumberInput from '../../../../../../../blocks/NumberInput';

const InventoryItem = ({ id, item: inventoryItem, index, register, errors, control, setValue }) => {
  useEffect(() => {
    if (inventoryItem?.inventoryitemId) {
      setValue(`inventoryItems.${index}.inventoryitemId`, inventoryItem.inventoryitemId);
    }
  }, [id, index, inventoryItem?.inventoryitemId, setValue]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={3} container alignItems="center">
        <Controller
          control={control}
          name={`inventoryItems.${index}.inventoryitemId`}
          key={`${id}.inventoryitemId`}
        />
        <Typography variant="body1">{`${index + 1}. ${inventoryItem?.label}`}</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          defaultValue={inventoryItem.count || ''}
          key={`${id}.count`}
          id={`inventoryItems.${index}.count`}
          required
          label="Quantity"
          error={errors.inventoryItems && !!errors.inventoryItems[index]?.count}
          helperText={
            errors.inventoryItems && errors.inventoryItems[index]?.count?.type === 'required'
              ? 'Value is required'
              : ''
          }
          control={control}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel error={errors.inventoryItems && !!errors.inventoryItems[index]?.measureId}>
            UOM
          </InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            key={`${id}.measureId`}
            id={`inventoryItems.${index}.measureId`}
            name={`inventoryItems.${index}.measureId`}
            defaultValue={inventoryItem?.measureId || ''}
            label="UOM"
            placeholder="UOM"
            error={errors.inventoryItems && !!errors.inventoryItems[index]?.measureId}
            as={
              <Select
                label="UOM"
                multiple={false}
                error={errors.inventoryItems && !!errors.inventoryItems[index]?.measureId}
              >
                {inventoryItem?.measureOptions?.map(measure => (
                  <MenuItem key={measure.value} value={measure.value}>
                    {measure.label}
                  </MenuItem>
                )) || []}
              </Select>
            }
            control={control}
          />
          {errors.inventoryItems && errors.inventoryItems[index]?.measureId?.type === 'required' ? (
            <FormHelperText error>UOM is required</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          inputRef={register}
          fullWidth
          label="Preparation"
          key={`${id}.preparation`}
          name={`inventoryItems.${index}.preparation`}
          defaultValue={inventoryItem?.preparation}
          variant="outlined"
          placeholder="Preparation"
        />
      </Grid>
    </Grid>
  );
};

export default InventoryItem;
