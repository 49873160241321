import { gql } from '@apollo/client';

const SET_ITEM_INGREDIENTS = gql`
  mutation SetItemIngredientsMutation($input: SetItemIngredientsMutationInput!) {
    setItemIngredients(input: $input) {
      item {
        id
        itemIngredientConnection {
          edges {
            node {
              id
              quantity
              ingredient {
                id
                inventoryitem {
                  label
                  measure {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SET_ITEM_INGREDIENTS;
