import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from "@material-ui/core";
import { LOCATIONS } from "../../../../../../apollo/queries";
import { SelectFactory } from "../../../../../blocks";
import { compact } from "lodash";

const TierForm = ({
  title,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  descriptionElementRef,
  buttonDisabled,
  open,
  handleClose,
  locationIds,
  setLocationIds,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            name="label"
                            label="Tier Name"
                            type="text"
                            inputRef={register({ required: true })}
                            fullWidth
                          />
                          {errors.label && (
                            <FormHelperText>
                              Tier name is a required field.
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      {/* <HierarchySideBar
                        locations
                        locationIds={locationIds}
                        setLocationIds={setLocationIds}
                      /> */}
                      <SelectFactory
                        label={"Locations"}
                        placeholder={"Select Locations"}
                        query={LOCATIONS}
                        defaultValue={locationIds}
                        disableCloseOnSelect={true}
                        multiple
                        renderCheckBox
                        structureData={(data) => {
                          return data?.viewer?.locationConnection?.edges.map(
                            (location, index) => {
                              return {
                                label: compact([location.node.label, location.node.storeNumber]).join(' - #'),
                                id: location.node.id,
                                locationId: location.node.locationId,
                                index,
                              };
                            }
                          );
                        }}
                        onSelect={(values) => {
                          setLocationIds(values);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={buttonDisabled} type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

TierForm.propTypes = {};

export default TierForm;
