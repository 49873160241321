import { gql } from '@apollo/client';
import recipeFragment from '../fragments/recipeFragment';

const FETCH_RECIPES = gql`
  query FETCH_RECIPES(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [RecipeSort]
    $filter: [RecipeFilter]
  ) {
    viewer {
      id
      recipeConnection(
        first: $first
        last: $last
        before: $before
        after: $after
        sort: $sort
        filter: $filter
        search: $search
        fieldGroupOnly: 7
      ) {
        availableFilters
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...RecipeData
          }
        }
      }
    }
  }

  ${recipeFragment.recipe}
`;

export default FETCH_RECIPES;
