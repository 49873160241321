import { gql } from '@apollo/client';

const CREATE_INVENTORY_ITEM_CATEGORY_TYPE = gql`
  mutation CREATE_INVENTORY_ITEM_CATEGORY_TYPE($input: CreateInventoryitemcategorytypeInput!) {
    createInventoryitemcategorytype(input: $input) {
      viewer {
        id
      }
      inventoryitemcategorytype {
        id
      }
    }
  }
`;

export default CREATE_INVENTORY_ITEM_CATEGORY_TYPE;
