import { makeStyles } from "@material-ui/core";

const Styles = (theme) => ({
  container: {
    width: "100%",
    position: "relative",

    "& > button": {
      position: "absolute",
      bottom: "1rem",
      right: "1rem",
    },
  },
});

const TextFieldWithButtonStyles = makeStyles(Styles);

export default TextFieldWithButtonStyles;
