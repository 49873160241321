import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CREATE_HOUSE_ACCOUNT from '../../../../../../../apollo/mutations/loyalty/createHouseAccount';
import LOYALTY_HOUSE_ACCOUNTS from '../../../../../../../apollo/queries/loyalty/loyaltyHouseAccounts';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../../../constants';
import { Button, Grid } from '@material-ui/core';

import {
  CurrencyInput,
  Permission,
  PermissionField,
  SelectFactory,
} from '../../../../../../blocks';
import CUSTOMERS from '../../../../../../../apollo/queries/loyalty/customers';
import { compact } from 'lodash';
import { CurrencyInputFormat, uglifyId } from '../../../../../../../utils';
import { convertHouseAccountValueForDatabase } from '../../../../../../../utils/houseAccountUtils';
import { limitGreaterOrEqualToZeroMessage } from '../../../../../../../constants/houseAccounts';

export default function AddHouseAccountModal({
  locationId,
  createHouseAccountPermission,
  permissions,
  accessToMutations,
  handleCloseModal,
}) {
  const { handleSubmit, control, errors } = useForm();
  const { setSnackbar } = useSnackbar();
  const [newSelectedCustomerId, setNewSelectedCustomerId] = useState(null);
  const [createHouseAccount, { loading: createHouseAccountLoading }] = useMutation(
    CREATE_HOUSE_ACCOUNT,
    {
      update(cache, { data }) {
        cache.modify({
          id: cache.identify(data?.createHouseaccount?.viewer),
          fields: {
            ['houseaccountConnection']: existingItemtypeConnection => {
              return {
                ...existingItemtypeConnection,
                totalCount: existingItemtypeConnection.totalCount + 1,
                edges: [
                  ...existingItemtypeConnection.edges,
                  {
                    node: {
                      __ref: `houseaccountType:${data.createHouseaccount.id}`,
                    },
                  },
                ],
              };
            },
          },
        });
      },
    },
  );

  const { data, loading, error } = useQuery(LOYALTY_HOUSE_ACCOUNTS, {
    variables: {
      sort: [
        {
          id: 'DESC',
        },
      ],
      filter: {
        locationId: {
          eq: parseInt(locationId),
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmit = ({ limit }) => {
    createHouseAccount({
      variables: {
        input: {
          locationId: uglifyId('Location', locationId),
          loginId: newSelectedCustomerId,
          limit: convertHouseAccountValueForDatabase(CurrencyInputFormat(limit)),
        },
      },
    })
      .then(() => {
        setSnackbar({
          text: 'House Account successfully added!',
          open: true,
          type: SNACKBAR_STATUS.SUCCESS,
        });
        handleCloseModal(true);
      })
      .catch(e =>
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: e.message,
          open: true,
        }),
      );
  };

  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;
  return (
    <Grid container style={{ flexDirection: 'column' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container style={{ marginTop: '1rem' }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{ required: 'Required' }}
              name="customer"
              defaultValue={newSelectedCustomerId}
              render={props => {
                return (
                  <Permission access={permissions.person}>
                    <PermissionField
                      Component={SelectFactory}
                      customProps={props}
                      label={'Customer'}
                      placeholder={'Select Customer'}
                      fullWidth={true}
                      defaultValue={newSelectedCustomerId}
                      query={CUSTOMERS}
                      filter={{
                        loginId: {
                          nin: data?.viewer?.houseaccountConnection?.edges.map(
                            edge => edge.node.login.loginId,
                          ),
                        },
                      }}
                      style={{ width: '100%' }}
                      multiple={false}
                      renderCheckBox={false}
                      structureData={data => {
                        return data?.viewer?.loginConnection?.edges.map((login, index) => {
                          return {
                            label: compact([
                              login.node.person.firstName + ' ' + login.node.person.lastName,
                              login.node.person.personId,
                            ]).join(' - #'),
                            id: login.node.id,
                            index,
                          };
                        });
                      }}
                      onSelect={values => {
                        setNewSelectedCustomerId(values?.id);
                      }}
                      error={!!errors?.customer}
                      helperText={errors?.customer && 'Customer is required'}
                    />
                  </Permission>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: '1rem 0' }}>
            <Permission access={permissions.limit}>
              <PermissionField
                Component={CurrencyInput}
                control={control}
                rules={{
                  validate: {
                    negative: value => {
                      const formattedValue = CurrencyInputFormat(value) || null;
                      return (
                        !formattedValue || formattedValue >= 0 || limitGreaterOrEqualToZeroMessage
                      );
                    },
                  },
                }}
                label={'Limit'}
                error={!!errors.limit}
                helperText={errors?.limit?.message}
                fullWidth
                name="limit"
              />
            </Permission>
          </Grid>
        </Grid>
        <Grid item justify="flex-end" spacing={3} xs={12}>
          <Grid item>
            <Button color="primary" variant="outlined" onClick={() => handleCloseModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Permission
              access={Math.min(
                createHouseAccountPermission.access,
                accessToMutations?.[MUTATION_NAME?.CreateHouseaccountMutationInput],
              )}
            >
              <Button
                disabled={createHouseAccountLoading}
                type="submit"
                style={{ marginLeft: '0.3rem' }}
              >
                Save
              </Button>
            </Permission>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
