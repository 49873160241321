import React, { useContext, useState } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import PaperMain from "../../blocks/PaperMain";
import GenericFileUpload from "../../blocks/GenericFileUpload";
import ADD_REMOVE_LOYALTY_POINTS_FROM_FILE from "../../../apollo/mutations/loyalty/addRemovePointsFromFile";
import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { ReportsStyles } from "./View/styles";
import { Permission, SelectFactory } from "../../blocks";
import { LOCATIONS } from "../../../apollo/queries";
import { generateScheduleSummary } from "../../../utils/pdfUtils";
import { fileReaders } from '../../../utils';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { compact } from "lodash";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { useMutation } from "@apollo/client";
import { SNACKBAR_STATUS } from "../../../constants";
import { CoreContext } from "../../../CoreContext";

const Reports = () => {
  const context = useContext(CoreContext);
  const mutationPermissions = context?.coreUtils?.getAppActions()[70]?.mutations;

  const uploadLoyaltyExcelPermission = mutationPermissions.find(
    (mutationPermission) =>
      mutationPermission.label === "addRemovePointsFromFile"
  );
  const classes = ReportsStyles();
  const { setSnackbar } = useSnackbar();

  const [uploadBase64File, { loading: uploadBase64FileLoading }] = useMutation(ADD_REMOVE_LOYALTY_POINTS_FROM_FILE, {
    onCompleted: (result) => {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.SUCCESS,
        text: "Processing done, don't forget to check the report"
      });
      setReportLink(result?.addRemovePointsFromFile?.reportUrl);
    },
    onError: (error) => setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.ERROR,
      text: error.message
    })
  });

  const [reportLink, setReportLink] = useState();
  const [file, setFile] = useState();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [fetchInProgress, setFetchInProgress] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const locationIds = selectedLocations?.reduce((accumulator, currValue) => {
    if (currValue?.locationId) {
      accumulator.push(currValue.locationId);
    }
    return accumulator;
  }, []);
  //This values are hardcoded on API
  const reportsNameIdPairs = {
    1: "Channel sale",
    2: "Check Details",
    3: "Item Details",
    4: "Payment Details",
    5: "Payment Summary",
    6: "Sale Summary",
    7: "Tender Summary",
    8: "Labor Summary",
    9: "Schedule Summary"
  };
  const fetchReport = reportId => {
    if (locationIds.length < 1) {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: "Please select one or more locations."
      });
      return;
    }
    if (fetchInProgress) {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.WARNING,
        text: "Fetch already in progress."
      });
      return;
    }
    const date = moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: "Fetching Reports"
    });
    setFetchInProgress(true);
    window
      .fetch(
        `${
          process.env.REACT_APP_API_URL
        }/report?reportId=${reportId}&locationIds=[${locationIds.toString()}]&date=${date.toString()}`,
        {
          method: "GET"
        }
      )
      .then(res => {
        if (res.status === 200) {
          setFetchInProgress(false);
          setSnackbar({
            open: true,
            type: SNACKBAR_STATUS.SUCCESS,
            text: `${reportsNameIdPairs[reportId]} fetched successfully`
          });
        }
        if (res.url.includes("reportId=9")) {
          return res.json();
        } else {
          return res.blob();
        }
        throw new Error();
      })
      .then(blob => {
        if (reportId === "9") {
          generateScheduleSummary(blob);
          return;
        }
        FileSaver.saveAs(blob, `report${reportsNameIdPairs[reportId]}.csv`);
      })
      .catch(error => {
        setFetchInProgress(false);
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: `Failed to fetch ${reportsNameIdPairs[reportId]}`
        });
      });
  };
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const downloadFile = () => {
    window.location.href = reportLink;
  }

  const handleFileUpload = async () => {
    if (!file) {
      setSnackbar({
        open: true,
        type: SNACKBAR_STATUS.ERROR,
        text: "Select a file!"
      });
    }
    setSnackbar({
      open: true,
      type: SNACKBAR_STATUS.INFO,
      text: "Processing excel"
    });
    setFetchInProgress(true);
    const base64String = await fileReaders.readFileAsBase64(file);
    uploadBase64File({
      variables: {
        base64File: base64String
      }
    })
  };
  return (
    <PaperMain>
      <Typography variant="h3" component="h2" gutterBottom>
        Reports
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={3} direction="column">
          <SelectFactory
            label={"Locations"}
            placeholder={"Select Locations"}
            query={LOCATIONS}
            defaultValue={selectedLocations}
            disableCloseOnSelect={true}
            multiple
            renderCheckBox
            structureData={data => {
              return data?.viewer?.locationConnection?.edges.map(
                (location, index) => {
                  return {
                    label: compact([
                      location.node.label,
                      location.node.storeNumber
                    ]).join(" - #"),
                    id: location.node.id,
                    locationId: location.node.locationId,
                    index
                  };
                }
              );
            }}
            onSelect={values => {
              setSelectedLocations(values);
            }}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="LL/dd/yyyy"
              margin="normal"
              value={selectedDate}
              shouldDisableDate={date => {
                return date > new Date();
              }}
              onChange={handleDateChange}
              label="Date range start"
              TextFieldComponent={props => <TextField {...props} />}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={9} direction="column">
          <Typography variant="h5" component="h3">
            Select Report
          </Typography>
          <div className={classes.buttonGroup}>
            {Object.keys(reportsNameIdPairs).map(reportId => (
              <Button
                size="small"
                onClick={() => {
                  fetchReport(reportId);
                }}
              >
                {reportsNameIdPairs[reportId]}
              </Button>
            ))}
          </div>
        </Grid>
      </Grid>
      <br/>
      <Permission access={uploadLoyaltyExcelPermission?.access} >
        <GenericFileUpload file={file} setFile={setFile} />
        {uploadBase64FileLoading && <CircularProgress />}
        {file && <Button onClick={handleFileUpload}>Upload</Button>}
        {reportLink && <Button onClick={downloadFile} target="_blank">Download report</Button>}
      </Permission>
    </PaperMain>
  );
};

export default Reports;
