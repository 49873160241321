import { useState } from 'react';
import LuxonUtils from '@date-io/luxon';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Button,
  DialogContent,
  DialogActions,
  Dialog,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateTime from 'luxon/src/datetime';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { Elements, Permission, PermissionField, SelectFactory } from '../../../../blocks';
import moment from 'moment-timezone';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../constants';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { PERSON_JOBS } from '../../../../../apollo/queries/labor';

const useStyles = makeStyles(theme => ({
  inputLabelSelect: {
    marginLeft: '.2rem',
    top: '-8px',
    left: '8px',
    padding: '0 5px',
    zIndex: 1,
    background: '#fff',
  },
}));

const hours = 0;

const CreateTimeshift = forwardRef(
  (
    {
      open,
      locationPerson,
      timezone,
      startedFilter,
      finishedFilter,
      handleCloseCreateTimeshiftModal,
      handleSubmit,
      createTimeshift,
      control,
      permissions,
      errors,
      mutationLoading,
      accessToMutations,
      createTimeshiftPermission,
    },
    ref,
  ) => {
    const classes = useStyles();
    const { setSnackbar } = useSnackbar();
    const [selectedJob, setSelectedJob] = useState(null);
    const onSubmit = data => {
      if (!selectedJob) {
        setSnackbar({
          open: true,
          text: 'You must select a job.',
          type: SNACKBAR_STATUS.WARNING,
        });
        return;
      }

      const start = DateTime.fromFormat(
        `${data.started.date} ${data.started.timeHours}:${data.started.timeMinutes} ${data.started.timeMeridiem}`,
        'LL/dd/yyyy h:m a',
        { zone: timezone },
      );

      if (
        start.toMillis() >
        DateTime.local()
          .setZone(timezone)
          .toMillis()
      ) {
        setSnackbar({
          open: true,
          text: 'Started Date/Time must not be in future.',
          type: SNACKBAR_STATUS.WARNING,
        });
        return;
      }

      const end = DateTime.fromFormat(
        `${data.finished.date} ${data.finished.timeHours}:${data.finished.timeMinutes} ${data.finished.timeMeridiem}`,
        'LL/dd/yyyy h:m a',
        { zone: timezone },
      );

      if (start.toMillis() >= end.toMillis()) {
        setSnackbar({
          open: true,
          text: 'Started Date/Time must be before Finished.',
          type: SNACKBAR_STATUS.WARNING,
        });
        return;
      }

      createTimeshift({
        variables: {
          input: {
            locationId: locationPerson.locationId,
            personId: locationPerson.personId,
            jobId: selectedJob,
            start: start.toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
            end: end.toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
          },
        },
      });
    };

    const started = DateTime.local();
    const finished = DateTime.local();

    return (
      (
        <Dialog open={open} onClose={handleCloseCreateTimeshiftModal} maxWidth="lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container xs={12} spacing={2} alignItems={'center'}>
                <Grid item xs={12} container spacing={3}>
                  <Permission
                    access={permissions?.person?.timeshiftConnection?.edges?.node?.started}
                  >
                    <Grid xs={6}>
                      <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <Controller
                          control={control}
                          name={'started.date'}
                          defaultValue={started.toFormat('LL/dd/yyyy')}
                          render={({ onChange, value }) => (
                            <Permission
                              access={
                                permissions?.person?.timeshiftConnection?.edges?.node?.started
                              }
                            >
                              <PermissionField
                                Component={KeyboardDatePicker}
                                variant="inline"
                                fullWidth
                                inputVariant="outlined"
                                format="LL/dd/yyyy"
                                margin="normal"
                                label="Started"
                                autoOk={true}
                                initialFocusedDate={DateTime.local()}
                                onChange={date => {
                                  onChange(date.toFormat('LL/dd/yyyy'));
                                }}
                                value={value}
                                // Disable text input
                                TextFieldComponent={props => (
                                  <TextField {...props} disabled={true} fullwidth />
                                )}
                              />
                            </Permission>
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid xs={6}>
                      <Elements.TimePicker
                        access={permissions?.person?.timeshiftConnection?.edges?.node?.started}
                        control={control}
                        hoursName={'started.timeHours'}
                        minutesName={'started.timeMinutes'}
                        amPmName={'started.timeMeridiem'}
                        defaultValues={{
                          hours: started?.toFormat('h'),
                          minutes: started?.toFormat('mm'),
                          meridiem: started?.toFormat('a').toLowerCase(),
                        }}
                      />
                    </Grid>
                  </Permission>
                </Grid>
                <Grid item xs={12}>
                  <Permission
                    access={permissions?.person?.timeshiftConnection?.edges?.node?.finished}
                  >
                    <Grid xs={6}>
                      <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <Controller
                          control={control}
                          name={'finished.date'}
                          defaultValue={finished.toFormat('LL/dd/yyyy')}
                          render={({ onChange, value }) => (
                            <Permission
                              access={
                                permissions?.person?.timeshiftConnection?.edges?.node?.finished
                              }
                            >
                              <PermissionField
                                Component={KeyboardDatePicker}
                                variant="inline"
                                inputVariant="outlined"
                                format="LL/dd/yyyy"
                                margin="normal"
                                label="Finished"
                                initialFocusedDate={DateTime.local()}
                                onChange={date => {
                                  onChange(date.toFormat('LL/dd/yyyy'));
                                }}
                                autoOk={true}
                                value={value}
                                // Disable text input
                                TextFieldComponent={props => (
                                  <TextField {...props} disabled={true} fullWidth />
                                )}
                              />
                            </Permission>
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Permission>
                  <Permission
                    access={permissions?.person?.timeshiftConnection?.edges?.node?.finished}
                  >
                    <Grid xs={6}>
                      <Elements.TimePicker
                        access={permissions?.person?.timeshiftConnection?.edges?.node?.finished}
                        control={control}
                        hoursName={'finished.timeHours'}
                        minutesName={'finished.timeMinutes'}
                        amPmName={'finished.timeMeridiem'}
                        defaultValues={{
                          hours: finished?.toFormat('h'),
                          minutes: finished?.toFormat('m'),
                          meridiem: finished?.toFormat('a').toLowerCase(),
                        }}
                      />
                    </Grid>
                  </Permission>
                </Grid>
                <Grid xs={12} container spacing={3} style={{ margin: '0.5rem 0' }}>
                  <Grid item xe={2} style={{ flexDirection: 'column' }}>
                    <label>
                      TOTAL HRS <br />
                      {hours}
                      {hours ? moment.utc(hours).format('HH:mm') : '0:00'}
                    </label>
                  </Grid>
                  <Grid item xs={5}>
                    <Permission
                      access={Math.min(
                        permissions?.person?.timeshiftConnection?.edges?.node?.job?.__typename,
                        permissions?.person?.timeshiftConnection?.edges?.node?.job?.label,
                        permissions?.person?.timeshiftConnection?.edges?.node?.job?.id,
                      )}
                    >
                      <Grid xs={12} direction="column">
                        <PermissionField Component={FormControl} fullWidth createHelperText>
                          <Controller
                            name={'job'}
                            control={control}
                            render={({ onChange, value }) => (
                              <Permission
                                access={Math.min(
                                  permissions?.person?.timeshiftConnection?.edges?.node?.job
                                    ?.__typename,
                                  permissions?.person?.timeshiftConnection?.edges?.node?.job?.label,
                                  permissions?.person?.timeshiftConnection?.edges?.node?.job?.id,
                                )}
                              >
                                <PermissionField
                                  Component={SelectFactory}
                                  control={control}
                                  label={'Job'}
                                  fullWidth={true}
                                  query={PERSON_JOBS}
                                  filter={{
                                    locationId: { eq: locationPerson?.locationIdInt },
                                    personId: { eq: locationPerson?.personId },
                                  }}
                                  style={{ width: '100%' }}
                                  multiple={false}
                                  renderCheckBox={false}
                                  onSelect={value => {
                                    setSelectedJob(value.id);
                                  }}
                                  structureData={data => {
                                    return data?.viewer?.personJobConnection?.edges?.map(
                                      (edge, index) => ({
                                        label: edge.node.job.label,
                                        value: edge.node.job.id,
                                        id: edge.node.job.id,
                                        index,
                                      }),
                                    );
                                  }}
                                />
                              </Permission>
                            )}
                          />
                        </PermissionField>
                      </Grid>
                    </Permission>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreateTimeshiftModal} variant="outlined">
                Cancel
              </Button>
              <Permission
                access={Math.min(
                  createTimeshiftPermission?.access,
                  accessToMutations?.[MUTATION_NAME.CreateTimeshiftMutationInput],
                )}
              >
                <div>
                  <PermissionField createHelperText>
                    <Button type="submit" color="primary" disabled={mutationLoading} autoFocus>
                      Save
                    </Button>
                  </PermissionField>
                </div>
              </Permission>
            </DialogActions>
          </form>
        </Dialog>
      ) || null
    );
  },
);

export default CreateTimeshift;
