import { gql } from "@apollo/client";

export const REMOVE_LOYALTYSETTINGS = gql`
  mutation removeLoyaltysettings($input: removeLoyaltysettingsMutationInput!) {
    removeLoyaltysettings(input: $input) {
      viewer {
        id
      }
      removedLoyaltysettingsId
    }
  }
`;
