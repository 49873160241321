import { gql } from "@apollo/client";

const CREATE_TAX_RATE = gql`
  mutation CreateTaxRate($input: CreateTaxrateMutationInput!) {
    createTaxrate(input: $input) {
      taxrate {
        id
      }
      viewer {
        id
      }
    }
  }
`;

export default CREATE_TAX_RATE;
