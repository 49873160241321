import { keys } from 'lodash';
import { convert } from '../../../../../utils';

export function formatDataForItemMutation(formData, isBulkEdit) {
  const formatData = [];

  for (const property in formData) {
    if (
      property !== 'menuType' &&
      property !== 'onlineStatus' &&
      property !== 'subcategory' &&
      property !== 'activeStatus'
    ) {
      const itemId = property;
      const itemProperties = formData[property];

      let result = {};
      if (isBulkEdit) {
        result.price = itemProperties?.onlinePrice
          ? convert.dollarsToCents(itemProperties?.onlinePrice)
          : itemProperties?.onlinePrice === null
          ? null
          : undefined;
        result.posPrice = itemProperties?.posPrice
          ? convert.dollarsToCents(itemProperties?.posPrice)
          : itemProperties?.posPrice === null
          ? null
          : undefined;
        result.thirdPartyPrice = itemProperties?.thirdPartyPrice
          ? convert.dollarsToCents(itemProperties?.thirdPartyPrice)
          : itemProperties?.thirdPartyPrice === null
          ? null
          : undefined;
      } else {
        if (itemProperties) {
          keys(itemProperties).forEach(key => {
            if (key === 'taxGroupId') {
              result[key] = itemProperties[key]?.value ? itemProperties[key].value : null;
            } else {
              result[key === 'onlinePrice' ? 'price' : key] =
                itemProperties[key] || itemProperties[key] === 0
                  ? convert.dollarsToCents(itemProperties[key])
                  : null;
            }
          });
        }
      }

      formatData.push({
        itemId,
        ...result,
      });
    }
  }

  return formatData;
}
