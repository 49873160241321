import { gql } from '@apollo/client';

export const BRANDS = gql`
  query brands($after: String, $first: Int, $before: String, $last: Int, $search: String) {
    viewer {
      id
      brandConnection(first: $first, last: $last, before: $before, after: $after, search: $search) {
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
        totalCount
        edges {
          node {
            id
            brandId
            label
            description
            abbreviation
          }
        }
      }
    }
  }
`;
