import { makeStyles } from '@material-ui/core';

const Styles = (theme) => ({  
    timelineHeader: {
        margin: `${theme.spacing(4)}px 0`,
    },
    dayPicker: {
        fontSize: theme.typography.fontSizeBase,

        '& label': {
            margin: `0 ${theme.spacing(3)}px`,
        },
    },
    positions: {
        justifyContent: 'right',
        flexWrap: 'wrap',
    },
    position: {
        whiteSpace: 'nowrap',
        display: 'inline-block',
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    positionBadge: {
        width: 12,
        height: 12,
        display: 'inline-block',
        marginRight: theme.spacing(1),
    },
    positionLabel: {
        fontSize: theme.typography.fontSizeXS,
    },
});

export const ScheduleTimelineStyles = makeStyles(Styles);
