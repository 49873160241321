import { gql } from '@apollo/client';

const ADD_ITEM_TO_TREE = gql`
  mutation AddItemToTree($input: AddItemToTreeMutationInput!) {
    addItemToTree(input: $input) {
      viewer{
        id
      }
      operation
      subcategoryItem {
              id
              subcategoryId
              item {
                id
                itemId
                label
              }
      }
    }
  }
`;

export default ADD_ITEM_TO_TREE;
