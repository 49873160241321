import React, { useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";
import { Controller, useForm } from "react-hook-form";
import { TreeView, TreeItem } from "@material-ui/lab";
import { ExpandMore, ChevronRight } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  FETCH_HIERARCHY,
  FETCH_HIERARCHY_USERS,
} from "../../../../../apollo/queries";
import IconButton from "@material-ui/core/IconButton";
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import {
  CREATE_HIERARCHY,
  CREATE_LOGIN_HIERARCHY,
  REMOVE_HIERARCHY,
} from "../../../../../apollo/mutations";
import removeDuplicates from "../../../../../utils/removeDuplicates";
import { HierarchyStyles } from "./styles";
import UserDataGrid from "../DataGrids/UserDataGrid";
import { compact } from "lodash";
import { useSnackbar } from "../../../../../hooks/useSnackbar";

export const HierarchySideBar = ({
  locationId,
  locationIds,
  locations,
  setArea,
  setIsLoading,
  setLocation,
  setLocationDetails,
  setLocationId,
  setLocationIds,
  setHierarchyIds,
  hierarchyIds,
}) => {
  const { setSnackbar } = useSnackbar();
  
  const [parentId, setParentId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const descriptionElementRef = React.useRef(null);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const classes = HierarchyStyles();

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { handleSubmit, register, control, errors } = useForm({
    defaultValues: {
      label: "",
    },
  });

  const [createHierarchy] = useMutation(CREATE_HIERARCHY, {
    onCompleted: () => {
      setOpen(false);
      setSnackbar({
        open: true,
        type: "success",
        text: "Hierarchy added!",
      });
      setTimeout(() => {
        setButtonDisabled(false);
      }, 500);
    },
    onError: (err) => {
      setButtonDisabled(false);
      console.log(err);
      setSnackbar({
        open: true,
        type: "error",
        text: err.message || "Oops, something went wrong...",
      });
    },
  });
  const [removeHierarchy] = useMutation(REMOVE_HIERARCHY, {
    onCompleted: () => {
      setSnackbar({
        open: true,
        type: "success",
        text: "Hierarchy removed!",
      });
    },
    onError: (err) => {
      setButtonDisabled(false);
      console.log(err);
      setSnackbar({
        open: true,
        type: "error",
        text: err.message || "Oops, something went wrong...",
      });
    },
  });

  const { data: hierarchyData, error, loading } = useQuery(FETCH_HIERARCHY);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(loading);
    }
  }, [setIsLoading, loading]);

  if (loading) return <CircularProgress />;
  if (error) console.log(error);

  const handleClose = () => {
    setOpen(false);
  };

  const generateTreeData = (data) =>
    data?.viewer?.hierarchyConnection?.edges.map((firstLevel) => {
      return {
        key: uuid(),
        id: firstLevel.node.id,
        isParent: true,
        label: firstLevel.node.label,
        locations: firstLevel.node.locationConnection.edges?.map((edge) => {
          let tempNode = { ...edge.node };
          tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
          tempNode.parentId = firstLevel.node.id;
          tempNode.isLocation = true;
          return tempNode;
        }),
        children: firstLevel?.node?.children?.map((secondLevel) => {
          return {
            key: uuid(),
            id: secondLevel.id,
            isParent: true,
            label: secondLevel.label,
            locations: secondLevel.locationConnection.edges?.map((edge) => {
              let tempNode = { ...edge.node };
              tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
              tempNode.parentId = secondLevel.id;
              tempNode.isLocation = true;
              return tempNode;
            }),
            children: secondLevel?.children?.map((thirdLevel) => {
              return {
                key: uuid(),
                id: thirdLevel.id,
                isParent: true,
                label: thirdLevel.label,
                locations: thirdLevel.locationConnection.edges?.map((edge) => {
                  let tempNode = { ...edge.node };
                  tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                  tempNode.parentId = thirdLevel.id;
                  tempNode.isLocation = true;

                  return tempNode;
                }),
                children: thirdLevel?.children?.map((fourthLevel) => {
                  return {
                    key: uuid(),
                    isParent: true,
                    id: fourthLevel.id,
                    label: fourthLevel.label,
                    locations: fourthLevel.locationConnection.edges?.map(
                      (edge) => {
                        let tempNode = { ...edge.node };
                        tempNode.parentId = fourthLevel.id;
                        tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                        tempNode.isLocation = true;
                        return tempNode;
                      }
                    ),
                    children: fourthLevel?.children?.map((fifthLevel) => {
                      return {
                        key: uuid(),
                        id: fifthLevel.id,
                        label: fifthLevel.label,
                        isParent: true,
                        locations: fifthLevel.locationConnection.edges?.map(
                          (edge) => {
                            let tempNode = { ...edge.node };
                            tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                            tempNode.parentId = fifthLevel.id;
                            tempNode.isLocation = true;
                            return tempNode;
                          }
                        ),
                        children: fifthLevel?.children?.map((sixthLevel) => {
                          return {
                            key: uuid(),
                            id: sixthLevel.id,
                            isParent: true,
                            label: sixthLevel.label,
                            locations: sixthLevel.locationConnection.edges?.map(
                              (edge) => {
                                let tempNode = { ...edge.node };
                                tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                tempNode.parentId = sixthLevel.id;
                                tempNode.isLocation = true;
                                return tempNode;
                              }
                            ),
                            children: sixthLevel?.children?.map(
                              (seventhLevel) => {
                                return {
                                  key: uuid(),
                                  id: seventhLevel.id,
                                  isParent: true,
                                  label: seventhLevel.label,
                                  locations: seventhLevel.locationConnection.edges?.map(
                                    (edge) => {
                                      let tempNode = { ...edge.node };
                                      tempNode.parentId = seventhLevel.id;
                                      tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                      tempNode.isLocation = true;
                                      return tempNode;
                                    }
                                  ),
                                  children: seventhLevel?.children?.map(
                                    (eightLevel) => {
                                      return {
                                        key: uuid(),
                                        id: eightLevel.id,
                                        isParent: true,
                                        label: eightLevel.label,
                                        locations: eightLevel.locationConnection.edges?.map(
                                          (edge) => {
                                            let tempNode = { ...edge.node };
                                            tempNode.parentId = eightLevel.id;
                                            tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                            tempNode.isLocation = true;
                                            return tempNode;
                                          }
                                        ),
                                        children: eightLevel?.children?.map(
                                          (ninthLevel) => {
                                            return {
                                              key: uuid(),
                                              id: ninthLevel.id,
                                              isParent: true,
                                              label: ninthLevel.label,
                                              locations: ninthLevel.locationConnection.edges?.map(
                                                (edge) => {
                                                  let tempNode = {
                                                    ...edge.node,
                                                  };
                                                  tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                                  tempNode.parentId =
                                                    ninthLevel.id;
                                                  tempNode.isLocation = true;
                                                  return tempNode;
                                                }
                                              ),
                                              children: ninthLevel?.children?.map(
                                                (tenthLevel) => {
                                                  return {
                                                    key: uuid(),
                                                    id: tenthLevel.id,
                                                    isParent: true,
                                                    label: tenthLevel.label,
                                                    locations: tenthLevel.locationConnection.edges?.map(
                                                      (edge) => {
                                                        let tempNode = {
                                                          ...edge.node,
                                                        };
                                                        tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                                        tempNode.parentId =
                                                          tenthLevel.id;
                                                        tempNode.isLocation = true;
                                                        return tempNode;
                                                      }
                                                    ),
                                                    children: tenthLevel?.children?.map(
                                                      (eleventhLevel) => {
                                                        return {
                                                          key: uuid(),
                                                          id: eleventhLevel.id,
                                                          isParent: true,
                                                          label:
                                                            eleventhLevel.label,
                                                          locations: eleventhLevel.locationConnection.edges?.map(
                                                            (edge) => {
                                                              let tempNode = {
                                                                ...edge.node,
                                                              };
                                                              tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                                              tempNode.parentId =
                                                                eleventhLevel.id;
                                                              tempNode.isLocation = true;
                                                              return tempNode;
                                                            }
                                                          ),
                                                          children: eleventhLevel?.children?.map(
                                                            (twelwethLevel) => {
                                                              return {
                                                                key: uuid(),
                                                                id:
                                                                  twelwethLevel.id,
                                                                isParent: true,
                                                                label:
                                                                  twelwethLevel.label,
                                                                locations: twelwethLevel.locationConnection.edges?.map(
                                                                  (edge) => {
                                                                    let tempNode = {
                                                                      ...edge.node,
                                                                    };
                                                                    tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                                                    tempNode.parentId =
                                                                      twelwethLevel.id;
                                                                    tempNode.isLocation = true;
                                                                    return tempNode;
                                                                  }
                                                                ),
                                                                children: twelwethLevel?.children?.map(
                                                                  (
                                                                    thirteenthLevel
                                                                  ) => {
                                                                    return {
                                                                      key: uuid(),
                                                                      id:
                                                                        thirteenthLevel.id,
                                                                      isParent: true,
                                                                      label:
                                                                        thirteenthLevel.label,
                                                                      locations: thirteenthLevel.locationConnection.edges?.map(
                                                                        (
                                                                          edge
                                                                        ) => {
                                                                          let tempNode = {
                                                                            ...edge.node,
                                                                          };
                                                                          tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                                                          tempNode.parentId =
                                                                            thirteenthLevel.id;
                                                                          tempNode.isLocation = true;
                                                                          return tempNode;
                                                                        }
                                                                      ),
                                                                      children: thirteenthLevel?.children?.map(
                                                                        (
                                                                          fourtheentLevel
                                                                        ) => {
                                                                          return {
                                                                            key: uuid(),
                                                                            id:
                                                                              fourtheentLevel.id,
                                                                            isParent: true,
                                                                            label:
                                                                              fourtheentLevel.label,
                                                                            locations: fourtheentLevel.locationConnection.edges?.map(
                                                                              (
                                                                                edge
                                                                              ) => {
                                                                                let tempNode = {
                                                                                  ...edge.node,
                                                                                };
                                                                                tempNode.label = compact([edge.node.label, edge.node.storeNumber]).join(' - #');
                                                                                tempNode.parentId =
                                                                                  fourtheentLevel.id;
                                                                                tempNode.isLocation = true;
                                                                                return tempNode;
                                                                              }
                                                                            ),
                                                                          };
                                                                        }
                                                                      ),
                                                                    };
                                                                  }
                                                                ),
                                                              };
                                                            }
                                                          ),
                                                        };
                                                      }
                                                    ),
                                                  };
                                                }
                                              ),
                                            };
                                          }
                                        ),
                                      };
                                    }
                                  ),
                                };
                              }
                            ),
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            }),
          };
        }),
      };
    });

  const data = generateTreeData(hierarchyData);

  const onNodeClick = async (node, checked) => {
    if (checked) {
      setLocationIds((prevState) => {
        if (node.locations) {
          let results = [...prevState, node, ...node.locations];
          results = removeDuplicates(results, "id");
          return results;
        }
        let results = [...prevState, node];
        results = removeDuplicates(results, "id");
        return results;
      });
      if (node.isParent && setHierarchyIds) {
        setHierarchyIds((prevState) => {
          let results = [...prevState, node];
          results = removeDuplicates(results, "id");
          return results;
        });
      }
    } else {
      if (!node.isParent) {
        setLocationIds([...locationIds.filter((loc) => loc.id !== node.id)]);
        return;
      }
      let arrayToRemove = [node];
      if (node?.locations?.length) {
        arrayToRemove = arrayToRemove.concat(node.locations);
      }
      setLocationIds((prevState) => {
        let newState = prevState.filter(
          (n) => !arrayToRemove.some((x) => x.id === n.id)
        );
        return removeDuplicates(newState, "id");
      });
      if (node.isParent && setHierarchyIds) {
        setHierarchyIds(hierarchyIds.filter((hier) => hier.id !== node.id));
      }
    }
    node.children && node.children.forEach((n) => onNodeClick(n, checked));
  };

  const renderItems = (treeData, parentId) => {
    const childrenArray = treeData.children
      ? treeData.children.map((treeNode) => renderTree(treeNode, treeNode.id))
      : [];
    const locationsArray = treeData.locations
      ? treeData.locations.map((loc) => renderTree(loc))
      : [];

    const result = [...childrenArray, ...locationsArray];

    if (!locations && treeData.isParent)
      result.push(
        <span className={classes.treeItemNew}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setParentId(parentId);
              setOpen(true);
            }}
          >
            Add Area
          </Button>
        </span>
      );

    return result;
  };

  const renderTree = (treeData, parentId) => (
    <TreeItem
      className={classes.treeItem}
      nodeId={treeData.id}
      label={
        <div className={classes.treeItemLabel}>
          <div className={classes.treeItemTitle}>
            {locations && (
              <Checkbox
                checked={locationIds?.some((loc) => loc.id === treeData.id)}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  onNodeClick(treeData, e.target.checked);
                }}
              />
            )}
            {treeData.label}
          </div>
          {!locations && !treeData.isLocation && (
            <span className={classes.treeItemActions}>
              <IconButton
                style={{ padding: "0" }}
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  await removeHierarchy({
                    variables: {
                      input: {
                        hierarchyId: treeData.id,
                      },
                    },
                    refetchQueries: [{ query: FETCH_HIERARCHY }],
                  });
                }}
                aria-label="delete"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          )}
        </div>
      }
      onLabelClick={(e) => {
        e.preventDefault();
        if (!treeData.isLocation) {
          if (setLocationId || setArea) {
            setLocationId(null);
            setArea(treeData);
          }
        }
        if (treeData.isLocation) {
          if (setArea && setLocationId && setLocationDetails) {
            setArea({});
            setLocationId(treeData.locationId);
            setLocationDetails(treeData);
          }
        }
      }}
    >
      {renderItems(treeData, parentId)}
    </TreeItem>
  );

  const onSubmit = async (data) => {
    setButtonDisabled(true);
    createHierarchy({
      variables: {
        input: {
          parentId: parentId,
          label: data.label,
        },
      },
      refetchQueries: [
        {
          query: FETCH_HIERARCHY,
        },
      ],
    });
  };

  return (
    <>
      <Typography gutterBottom variant="h6" component="h2">
        Areas
      </Typography>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
      >
        {data.map((hierarchy) => renderTree(hierarchy, hierarchy?.id))}
        {!locations && (
          <span className={classes.treeItemNew}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setParentId(null);
                setOpen(true);
              }}
            >
              Add Hierarchy
            </Button>
          </span>
        )}
      </TreeView>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="sm"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="scroll-dialog-title">Hierarchy</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        rules={{
                          required: "Hierarchy name is required",
                          validate: {
                            isEmpty: (value) => {
                              return value.trim().length > 0 ? true : false;
                            },
                          },
                        }}
                        name="label"
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <TextField
                            label="Hierarchy Name"
                            type="text"
                            fullWidth
                            onChange={onChange}
                            error={errors?.label}
                            helperText={
                              errors?.label
                                ? errors?.label.message
                                : errors?.label?.type === "isEmpty"
                                ? "Hierarchy name can't be empty"
                                : ""
                            }
                          />
                        )}
                      />
                      {/* <TextField
                        name="label"
                        required
                        label="Hierarchy Name"
                        type="text"
                        inputRef={register({ required: true })}
                        fullWidth
                      /> */}
                      {/* {errors.label && (
                        <FormHelperText>
                          Label is a required field.
                        </FormHelperText>
                      )} */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={buttonDisabled} type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const HierarchyDetails = ({
  area,
  setBulkUpdateUsersState,
  bulkUpdateUsersState,
}) => {
  const { setSnackbar } = useSnackbar();
  const classes = HierarchyStyles();

  const [loginIds, setLoginIds] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [createLoginHierarchy] = useMutation(CREATE_LOGIN_HIERARCHY, {
    variables: {
      input: {
        hierarchyIds: area.id ? [+atob(area.id).split(":")[1]] : null,
        loginIds,
      },
    },
    onCompleted: (data) => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: "success",
        text: "Hierarchy users updated!",
      });
      setLoginIds([]);
    },
    onError: (err) => {
      setButtonDisabled(false);
      setSnackbar({
        open: true,
        type: "error",
        text: "Oops, something went wrong.",
      });
    },
  });

  const sendUsers = () => {
    setButtonDisabled(true);
    createLoginHierarchy();
  };

  return (
    <div className={classes.propertiesContainer}>
      {Object.keys(area).length > 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h2">
              {`${area.label} Properties`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <UserDataGrid
              buttonDisabled={buttonDisabled}
              area={area}
              loginIds={loginIds}
              setBulkUpdateUsersState={setBulkUpdateUsersState}
              bulkUpdateUsersState={bulkUpdateUsersState}
              setLoginIds={setLoginIds}
              sendUsersAndLocations={sendUsers}
              isHierarchyDetails={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h3">
              Areas
            </Typography>
          </Grid>
          <Grid item xs={12} direction="column">
            <Typography gutterBottom variant="h6" component="h3">
              Locations
            </Typography>
            <div className={classes.propertiesAreas}>
              <Grid container spacing={3}>
                {area.locations.length !== 0 &&
                  area.locations.map((location) => (
                    <Grid item xs={12} lg={4}>
                      <Typography variant="caption">
                        {console.log(location) || location.storeNumber}{" "}
                        {location.label}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        "Area not selected"
      )}
    </div>
  );
};

export const LocationDetails = ({ location }) => {
  const classes = HierarchyStyles();

  return (
    <>
      {Object.keys(location).length > 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h2">
              {`${location.label} Properties`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h3">
              Users
            </Typography>
          </Grid>
        </Grid>
      ) : (
        "Location not selected"
      )}
    </>
  );
};
