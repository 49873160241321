import { gql } from '@apollo/client';

const ACCEPT_OR_DECLINE_INVENTORYTRANSFER = gql`
  mutation ACCEPT_OR_DECLINE_INVENTORYTRANSFER($input: AcceptOrDeclineInventorytranferInput!) {
    acceptOrDeclineInventorytranfer(input: $input) {
      viewer {
        id
      }
      inventorytransfer {
        id
        targetLocationAccepted
      }
    }
  }
`;

export default ACCEPT_OR_DECLINE_INVENTORYTRANSFER;
