import React, { useState, useMemo, useCallback } from 'react';
import { CurrencyInputFormat, date as dateUtils, convert } from '../../../../../utils';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import DISCOUNTS_CREATE_DATA from '../../../../../apollo/queries/discounts/fetchDiscountCreateData';
import { CREATE_COUPON } from '../../../../../apollo/mutations';
import CreateDiscountView from './View';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { compact } from 'lodash';

function CreateDiscount() {
  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const [includedLocations, setIncludedLocations] = useState([]);
  const [displayedLocationRestriction, setDisplayedLocationRestriction] = useState('all');
  const [excludedLocations, setExcludedLocations] = useState([]);
  const [excludedItems, setExcludedItems] = useState([]);
  const [cartItemsRequired, setCartItemsRequired] = useState([]);
  const [forEachItem, setForEachItem] = useState([]);
  const [selectedSubcategoriesAction, setSelectedSubcategoriesAction] = useState([]);
  const [selectedSubcategoriesTrigger, setSelectedSubcategoriesTrigger] = useState([]);
  const [itemAddedNumberTimes, setItemAddedNumberTimes] = useState({
    label: '',
    id: '',
    quantity: 1,
  });
  const [itemInCart, setItemInCart] = useState([]);
  const [excludedDates, setExcludedDates] = useState([]);
  const [excludedSubcategories, setExcludedSubcategories] = useState([]);
  const [excludedHandoffs, setExcludedHandoffs] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [discountDetailsItems, setDiscountDetailsItems] = useState([]);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [brands, setBrands] = useState([]);
  const [excludeAllBoostLocations, setExcludeAllBoostLocations] = useState(false);
  const [isSmart, setIsSmart] = useState(false);

  const handleIsSmartChange = useCallback(value => {
    setIsSmart(value);
  }, []);

  const [createCoupon] = useMutation(CREATE_COUPON, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Coupon created!',
      });
      const couponId = atob(data?.createCoupon.coupon.id).split(':')[1];
      setDisableCreateButton(false);
      setTimeout(() => {
        history.push(`/spark/system-settings/discounts/${couponId}/details`);
      }, 100);
    },
    onError: err => {
      setDisableCreateButton(false);
      setSnackbar({
        open: true,
        type: 'error',
        text: err.message || 'Oops, something went wrong...',
      });
    },
  });

  const { control, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      label: '',
      internalLabel: '',
      description: '',
      platforms: [],
      brands: [],
      time: {
        start: {
          date: new Date(),
          time: {
            hours: 12,
            minutes: 0,
            type: 'am',
          },
        },
        end: {
          date: new Date(),
          time: {
            hours: 12,
            minutes: 0,
            type: 'am',
          },
        },
      },
      availableOnDays: {
        enabled: false,
        days: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
      },
      timezone: {
        enabled: false,
        timezoneId: '',
      },
      discountDetails: {
        discountType: 'dollar',
        aplicateTo: 'item',
        items: [],
        amount: '1',
      },
      triggerEvents: {
        manual: {
          code: {
            autoGenerate: false,
            code: '',
          },
        },
        automatic: {
          itemInCart: {
            itemIds: [],
          },
          itemAddedNumberTimes: {
            itemQuantityData: [{ itemIds: [], minQuantity: 1, maxQuantity: 1 }],
          },
          subtotalSpecificAmount: {
            minAmount: '1',
            maxAmount: '1',
          },
          subcategoryItemInCart: {
            subcategoryIds: [],
          },
          forEachSubcategoryItemInCart: {
            subcategoryIds: [],
          },
          cartContainsRequiredItems: {
            itemIds: [],
          },
          forEachItem: {
            itemIds: [],
          },
          forSubcategoryAndItemInCart: {
            itemId: [],
            subcategoryId: [],
          },
          orderCategory: {},
        },
      },
      discountRestrictions: {
        usageLimit: {
          enabled: false,
          options: {
            maxNumberOfUses: 0,
            usageLimitPerUser: 0,
            usageLimitPerCoupon: 0,
            discountNeedsManagerApproval: false,
          },
        },
        couponStacking: {
          enabled: false,
          options: {
            allowCouponsToBeCombined: false,
            excludeSaleItems: false,
          },
        },
        locations: {
          enabled: false,
          includedLocations: [],
        },
        categories: {
          enabled: false,
          excludedCategories: [],
        },
        subcategories: {
          enabled: false,
          excludedSubcategories: [],
        },
        items: {
          enabled: false,
          excludedItems: [],
        },
        excludeHandoffs: {
          enabled: false,
          handoffs: [],
        },
        excludeSpecificDates: {
          enabled: false,
          excludedDates: [],
        },
      },
    },
  });

  const decideOnCouponAction = discountDetails => {
    const { discountType, aplicateTo } = discountDetails;
    let couponActionId;

    if (discountType === 'dollar' && aplicateTo === 'order') {
      couponActionId = 1;
    }
    if (discountType === 'percent' && aplicateTo === 'order') {
      couponActionId = 2;
    }
    if (discountType === 'dollar' && aplicateTo === 'item') {
      couponActionId = 3;
    }
    if (discountType === 'percent' && aplicateTo === 'item') {
      couponActionId = 4;
    }
    if (discountType === 'dollar' && aplicateTo === 'category') {
      couponActionId = 5;
    }
    if (discountType === 'percent' && aplicateTo === 'category') {
      couponActionId = 6;
    }
    if (discountType === 'dollar' && aplicateTo === 'handoff') {
      couponActionId = 7;
    }
    if (discountType === 'percent' && aplicateTo === 'handoff') {
      couponActionId = 8;
    }
    if (discountType === 'freeItem') {
      couponActionId = 9;
    }

    return couponActionId;
  };

  const { data, loading } = useQuery(DISCOUNTS_CREATE_DATA, { variables: { storeTypeId: 10 } });

  const boostLocations = useMemo(
    () =>
      data?.viewer.locationConnection?.edges?.map(edge => ({
        label: compact([edge.node.label, edge.node.storeNumber]).join(' - #'),
        id: edge.node.id,
      })),
    [data],
  );

  const handleBoostStoreExclusion = useCallback(
    allBoostLocationAreExcluded => {
      setExcludeAllBoostLocations(allBoostLocationAreExcluded);

      if (allBoostLocationAreExcluded) {
        setValue('discountRestrictions.locations.enabledExclude', true);

        const excludedLocationsIds = excludedLocations.map(location => location.id);

        const allExcludedLocations = [...excludedLocations];

        boostLocations.forEach(location => {
          if (!excludedLocationsIds.includes(location.id)) {
            allExcludedLocations.push(location);
          }
        });

        setExcludedLocations(allExcludedLocations);
      } else {
        const boostLocationIds = boostLocations.map(boostLocation => boostLocation.id);

        setExcludedLocations(prevExcludedLocations => {
          const excludedLocationsWithoutBoostLocations = prevExcludedLocations.filter(
            location => !boostLocationIds.includes(location.id),
          );

          if (excludedLocationsWithoutBoostLocations.length === 0) {
            setValue('discountRestrictions.locations.enabledExclude', false);
          }

          return excludedLocationsWithoutBoostLocations;
        });
      }
    },
    [excludeAllBoostLocations, data],
  );

  // TODO: VALIDATION : Start time must not be later than the end time!
  const submit = discount => {
    setDisableCreateButton(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Creating coupon...',
    });

    let endDate;
    // Time of start and end
    const startDate = dateUtils.formatToValidDate(
      discount.time.start.date,
      discount.time.start.time,
      discount.time.start.time.type,
      { returnISODate: true },
    );

    if (discount.time.end) {
      endDate = dateUtils.formatToValidDate(
        discount.time.end.date,
        discount.time.end.time,
        discount.time.end.time.type,
        { returnISODate: true },
      );
      discount.time.end = endDate;
    } else {
      discount.time.end = null;
    }

    // Platforms
    if (platforms.length === 0) {
      setSnackbar({
        open: true,
        type: 'warning',
        text: 'Select at least one platform',
      });
      setDisableCreateButton(false);
      return;
    }

    discount.platforms = platforms;

    // Brands
    if (brands.length === 0) {
      setSnackbar({
        open: true,
        type: 'warning',
        text: 'Select at least one brand',
      });
      setDisableCreateButton(false);
      return;
    }

    discount.brands = brands;

    // Timezone
    if (discount.timezone.enabled && discount.timezone.timezoneId) {
      discount.timezone.timezoneId = discount.timezone.timezoneId.id;
    }

    discount.time.start = moment.utc(startDate).format();
    if (discount.time.end) {
      discount.time.end = moment.utc(endDate).format();
    }

    // Discount details
    if (discount.discountDetails.discountType === 'freeItem') {
      discount.discountDetails.amount = 100;
      if (!discount.discountDetails.quantity) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: "Coupon 'item quantity' is required",
        });
        setDisableCreateButton(false);
        return;
      }
    }
    if (discount.discountDetails.discountType === 'dollar') {
      discount.discountDetails.amount = convert.dollarsToCents(
        `${CurrencyInputFormat(`${discount.discountDetails.amount}`)}`,
      );
    }

    if (
      discount.discountDetails.aplicateTo === 'item' ||
      discount.discountDetails.discountType === 'freeItem'
    ) {
      if (!discountDetailsItems.length) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: "Coupon 'Items' are required",
        });
        setDisableCreateButton(false);
        return;
      }
      discount.discountDetails.items = discountDetailsItems.filter(i => i.isItem).map(i => i.id);
    }

    if (discount.discountDetails.aplicateTo === 'category') {
      if (selectedSubcategoriesAction.length === 0) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: "Coupon 'Subcategories' are required",
        });
        setDisableCreateButton(false);
        return;
      }
      discount.discountDetails.items = selectedSubcategoriesAction.map(i => i.id);
    }

    if (discount.discountDetails.handoff) {
      const handoffId = discount.discountDetails.handoff.value.trim();
      if (handoffId.length === 0) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: "Coupon 'Handoff' is required",
        });
        setDisableCreateButton(false);
        return;
      }
      discount.discountDetails.handoff = handoffId;
    }

    // Trigger events
    if (discount.triggerEvents) {
      if (discount.triggerEvents.manual) {
        discount.triggerEvents.manual.couponTriggerId = 1;
        if (discount.triggerEvents.manual.code.code.trim().length === 0) {
          setSnackbar({
            open: true,
            type: 'warning',
            text: "Coupon 'Code' is required",
          });
          setDisableCreateButton(false);
          return;
        }
      }

      if (discount.triggerEvents.automatic?.subtotalSpecificAmount) {
        const hasMaxAmount = !!discount.triggerEvents.automatic.subtotalSpecificAmount.maxAmount.trim()
          .length;
        let maxAmount;
        discount.triggerEvents.automatic.subtotalSpecificAmount.couponTriggerId = 2;
        let minAmount = CurrencyInputFormat(
          `${discount.triggerEvents.automatic.subtotalSpecificAmount.minAmount}`,
        );
        if (hasMaxAmount) {
          maxAmount = CurrencyInputFormat(
            `${discount.triggerEvents.automatic.subtotalSpecificAmount.maxAmount}`,
          );
        } else {
          maxAmount = null;
        }
        discount.triggerEvents.automatic.subtotalSpecificAmount.minAmount = minAmount;
        discount.triggerEvents.automatic.subtotalSpecificAmount.maxAmount = maxAmount;

        if (minAmount > maxAmount && maxAmount) {
          setSnackbar({
            open: true,
            type: 'warning',
            text: "Trigger Events: 'Minimal amount' can't be bigger than 'Maximal amount'",
          });
          setDisableCreateButton(false);
          return;
        }
      }

      if (discount.triggerEvents.automatic?.itemInCart) {
        if (itemInCart.length) {
          discount.triggerEvents.automatic.itemInCart.itemIds = [
            ...new Set(itemInCart.filter(i => i.isItem).map(i => i.id)),
          ];
          discount.triggerEvents.automatic.itemInCart.couponTriggerId = 3;
        } else {
          setSnackbar({
            open: true,
            type: 'warning',
            text: "Coupon 'Item in cart items' are required.",
          });
          setDisableCreateButton(false);
          return;
        }
      }

      if (discount.triggerEvents.automatic?.cartContainsRequiredItems) {
        if (cartItemsRequired.length) {
          discount.triggerEvents.automatic.cartContainsRequiredItems.itemIds = [
            ...new Set(cartItemsRequired.filter(i => i.isItem).map(i => i.id)),
          ];
          discount.triggerEvents.automatic.cartContainsRequiredItems.couponTriggerId = 4;
        } else {
          setSnackbar({
            open: true,
            type: 'warning',
            text: "Coupon 'Required cart items' are required.",
          });
          setDisableCreateButton(false);
          return;
        }
      }

      if (discount.triggerEvents.automatic?.subcategoryItemInCart) {
        discount.triggerEvents.automatic.subcategoryItemInCart.couponTriggerId = 5;
        discount.triggerEvents.automatic.subcategoryItemInCart.subcategoryIds = selectedSubcategoriesTrigger.map(
          i => i.id,
        );
        if (!selectedSubcategoriesTrigger.length) {
          setSnackbar({
            open: true,
            type: 'warning',
            text: "Coupon 'Subcategories' are required",
          });
          setDisableCreateButton(false);
          return;
        }
      }

      if (discount.triggerEvents.automatic?.forEachSubcategoryItemInCart) {
        discount.triggerEvents.automatic.forEachSubcategoryItemInCart.couponTriggerId = 8;
        discount.triggerEvents.automatic.forEachSubcategoryItemInCart.subcategoryIds = selectedSubcategoriesTrigger.map(
          i => i.id,
        );
        if (!selectedSubcategoriesTrigger.length) {
          setSnackbar({
            open: true,
            type: 'warning',
            text: "Coupon 'Subcategories' are required",
          });
          setDisableCreateButton(false);
          return;
        }
      }

      if (discount.triggerEvents.automatic?.itemAddedNumberTimes) {
        discount.triggerEvents.automatic.itemAddedNumberTimes.couponTriggerId = 6;

        // Remove empty elements from array in case a group was deleted
        discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData = discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData.filter(
          Boolean,
        );

        try {
          discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData.map(
            (itemGroup, index) => {
              // Add trigger group
              discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[
                index
              ].triggerGroupId = index + 1;
              // Extract only item IDs
              discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[
                index
              ].itemIds = discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[
                index
              ].itemIds.map(item => item.id);

              // Convert quantity to int
              discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[
                index
              ].minQuantity = parseInt(itemGroup.minQuantity);
              discount.triggerEvents.automatic.itemAddedNumberTimes.itemQuantityData[
                index
              ].maxQuantity = parseInt(itemGroup.maxQuantity);

              if (parseInt(itemGroup.minQuantity) > parseInt(itemGroup.maxQuantity)) {
                throw "Trigger Events: 'Minimum quantity' can't be bigger than 'Maximum quantity'";
              }
            },
          );
        } catch (error) {
          setSnackbar({
            open: true,
            type: 'warning',
            text: error,
          });

          setDisableCreateButton(false);
          return;
        }
      }
    }

    if (discount.triggerEvents?.automatic?.forEachItem) {
      if (forEachItem.length) {
        discount.triggerEvents.automatic.forEachItem.itemIds = [
          ...new Set(forEachItem.filter(i => i.isItem).map(i => i.id)),
        ];
        discount.triggerEvents.automatic.forEachItem.couponTriggerId = 7;
      } else {
        setSnackbar({
          open: true,
          type: 'warning',
          text: "Coupon 'For each item' are required.",
        });
        setDisableCreateButton(false);
        return;
      }
    }

    if (discount.triggerEvents?.automatic?.forSubcategoryAndItemInCart) {
      discount.triggerEvents.automatic.forSubcategoryAndItemInCart.couponTriggerId = 10;
      discount.triggerEvents.automatic.forSubcategoryAndItemInCart.subcategoryId =
        selectedSubcategoriesTrigger.id;
      discount.triggerEvents.automatic.forSubcategoryAndItemInCart.itemId = cartItemsRequired.id;

      if (!selectedSubcategoriesTrigger.id || !cartItemsRequired.id) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Coupon subcategories and items are required',
        });
        setDisableCreateButton(false);
        return;
      }
    }

    if (discount.triggerEvents?.automatic?.orderCategory) {
      const data = {
        categoryIds: discount.triggerEvents.automatic.orderCategory.categoryIds.map(cat => cat.id),
        couponTriggerId: 11,
      };
      discount.triggerEvents.automatic.orderCategory = data;

      if (!discount.triggerEvents.automatic.orderCategory.categoryIds.length) {
        setSnackbar({
          open: true,
          type: 'warning',
          text: 'Coupon order category is required',
        });
        setDisableCreateButton(false);
        return;
      }
    }

    // discountRestrictons
    if (discount.discountRestrictions.usageLimit.enabled) {
      if (!discount.discountRestrictions.usageLimit.options.maxNumberOfUses) {
        discount.discountRestrictions.usageLimit.options.maxNumberOfUses = null;
      }
      if (!discount.discountRestrictions.usageLimit.options.usageLimitPerUser) {
        discount.discountRestrictions.usageLimit.options.usageLimitPerUser = null;
      }
    }

    if (discount.discountRestrictions.items.enabled) {
      discount.discountRestrictions.items.excludedItems = [
        ...new Set(excludedItems.filter(i => i.isItem).map(item => item.id)),
      ];
    }

    if (discount.discountRestrictions.subcategories.enabled) {
      discount.discountRestrictions.subcategories.excludedSubcategories = [
        ...new Set(excludedSubcategories.map(subcategory => subcategory.id)),
      ];
    }

    if (discount.discountRestrictions.excludeHandoffs.enabled) {
      discount.discountRestrictions.excludeHandoffs.handoffs = excludedHandoffs;
    }

    if (discount.discountRestrictions.locations.enabled) {
      discount.discountRestrictions.locations.includedLocations = includedLocations
        .filter(loc => !loc.isParent)
        .map(loc => loc.id);
    }

    if (discount.discountRestrictions.locations.enabledExclude) {
      discount.discountRestrictions.locations.enabled = true;
      discount.discountRestrictions.locations.excludedLocations = excludedLocations.map(
        location => location.id,
      );
    }

    if (discount?.discountRestrictions?.locations?.enabledExclude !== undefined) {
      delete discount.discountRestrictions.locations.enabledExclude;
    }

    if (
      discount?.discountRestrictions?.locations?.includedLocations?.length &&
      discount?.discountRestrictions?.locations?.excludedLocations?.length
    ) {
      setSnackbar({
        open: true,
        type: 'warning',
        text: 'Locations can be either included or excluded',
      });
      setDisableCreateButton(false);
      return;
    }

    discount.discountDetails.couponActionId = decideOnCouponAction(discount.discountDetails);
    discount.isSmart = isSmart;

    createCoupon({
      variables: {
        input: discount,
      },
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  const couponTriggers = data?.viewer?.couponTriggerConnection;
  const timezones = data?.viewer?.timezoneConnection;
  const platformList = data?.viewer?.platformConnection;
  const handoffList = data?.viewer?.handoffConnection;
  const brandList = data?.viewer?.brandConnection;

  return (
    <>
      <CreateDiscountView
        submit={submit}
        handleSubmit={handleSubmit}
        control={control}
        includedLocations={includedLocations}
        setIncludedLocations={setIncludedLocations}
        excludedItems={excludedItems}
        setExcludedItems={setExcludedItems}
        excludedLocations={excludedLocations}
        setExcludedLocations={setExcludedLocations}
        cartItemsRequired={cartItemsRequired}
        setCartItemsRequired={setCartItemsRequired}
        itemAddedNumberTimes={itemAddedNumberTimes}
        setItemAddedNumberTimes={setItemAddedNumberTimes}
        excludedDates={excludedDates}
        setExcludedDates={setExcludedDates}
        displayedLocationRestriction={displayedLocationRestriction}
        setDisplayedLocationRestriction={setDisplayedLocationRestriction}
        itemInCart={itemInCart}
        setItemInCart={setItemInCart}
        excludedSubcategories={excludedSubcategories}
        setExcludedSubcategories={setExcludedSubcategories}
        disableCreateButton={disableCreateButton}
        errors={errors}
        couponTriggers={couponTriggers}
        timezones={timezones}
        platformList={platformList}
        discountDetailsItems={discountDetailsItems}
        setDiscountDetailsItems={setDiscountDetailsItems}
        platforms={platforms}
        setPlatforms={setPlatforms}
        setExcludedHandoffs={setExcludedHandoffs}
        excludedHandoffs={excludedHandoffs}
        handoffList={handoffList}
        forEachItem={forEachItem}
        setSelectedSubcategoriesAction={setSelectedSubcategoriesAction}
        selectedSubcategoriesAction={selectedSubcategoriesAction}
        setSelectedSubcategoriesTrigger={setSelectedSubcategoriesTrigger}
        selectedSubcategoriesTrigger={selectedSubcategoriesTrigger}
        setForEachItem={setForEachItem}
        brands={brands}
        setBrands={setBrands}
        brandList={brandList}
        excludeAllBoostLocations={excludeAllBoostLocations}
        handleBoostStoreExclusion={handleBoostStoreExclusion}
        isSmart={isSmart}
        handleIsSmartChange={handleIsSmartChange}
      />
    </>
  );
}

export default CreateDiscount;
