import { gql } from "@apollo/client";

const LOYALTY_CLAIMED_RECEIPT_STATUS = gql`
  mutation loyaltyClaimedReceiptStatus(
    $input: loyaltyClaimedReceiptStatusInput!
  ) {
    loyaltyClaimedReceiptStatus(input: $input) {
      loyaltyclaimedreceipt{
        id
        approved
      }
    }
  }
`;

export default LOYALTY_CLAIMED_RECEIPT_STATUS;
