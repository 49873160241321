import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import ConfirmationModal from '../ConfirmationModal';
import DataTableStyles from './styles';
import DataTableHead from './DataTableHead';
import DataTableToolbar from './DataTableToolbar';
import { Permission } from '../index';
import PermissionField from '../PermissionField';

const getShouldRenderTable = (columns, permissions) => {
  return !!columns.find(
    column =>
      ![0, null].includes(get(permissions, column.customPermissionField || column.field, 7)),
  );
};

const DataTable = ({
  columns,
  title,
  structureTable,
  customActionsTop,
  customActionsCenter,
  customActionsBottom,
  data,
  onTableRowClick,
  handleDeleteRow,
  permissions,
  handleEditRow,
  isDeleting,
  selectableRows,
  getSelectedValues,
  defaultValues = [],
  useConfirmationModalForDelete,
  deleteConfirmationModalText,
  lastRow
}) => {
  const [selected, setSelected] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [shouldRenderTable, setShouldRenderTable] = useState(true);

  const updateValues = useCallback(
    selected => {
      if (selected.length >= 0 && typeof getSelectedValues === 'function') {
        setSelected(selected);
        getSelectedValues(selected);
      }
    },
    [getSelectedValues],
  );

  const handleCheckboxClick = useCallback(
    (event, row, sel) => {
      event?.stopPropagation();
      const selectedIndex = sel.map(value => value.id).indexOf(row.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(sel, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(sel.slice(1));
      } else if (selectedIndex === sel.length - 1) {
        newSelected = newSelected.concat(sel.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(sel.slice(0, selectedIndex), sel.slice(selectedIndex + 1));
      }

      updateValues(newSelected);
    },
    [updateValues],
  );

  useEffect(() => {
    updateValues(defaultValues);
  }, [defaultValues, updateValues]);

  useEffect(() => {
    if (permissions) {
      setShouldRenderTable(getShouldRenderTable(columns, permissions));
    }
  }, [columns, permissions]);

  const styles = DataTableStyles();
  const tableData = structureTable(data);

  const handleEdit = (event, id = null, row) => {
    stopPropagationLogic(event, true);
    handleEditRow(id, row);
  };

  const isSelected = name => {
    const findSelected = selected.find(value => value.id === name.id);
    return findSelected ? true : false;
  };

  const stopPropagationLogic = (event, shouldStopPropagation) => {
    if (shouldStopPropagation) {
      event.stopPropagation();
    }
  };

  const onDeleteRow = (event, id) => {
    stopPropagationLogic(event, true);

    if (useConfirmationModalForDelete) {
      setIsConfirmationModalOpen(true);
      setDeleteId(id);
    } else {
      handleDeleteRow(id);
    }
  };

  return shouldRenderTable ? (
    <>
      <DataTableToolbar
        title={title}
        customActionsTop={customActionsTop}
        tableData={tableData}
        customActionsCenter={customActionsCenter}
        customActionsBottom={customActionsBottom}
        data={data}
      />
      <TableContainer>
        <Table size="small" aria-label="sticky table">
          <DataTableHead
            permissions={permissions}
            columns={columns}
            showActions={handleDeleteRow || handleEditRow}
            classes={styles}
          />
          <TableBody>
            {tableData ? (
              tableData?.map((row, index) => {
                const isItemSelected = isSelected(row);

                return (
                  <React.Fragment key={`${index}-fragment`}>
                    <TableRow
                      className={styles.tableRowRoot}
                      hover
                      tabIndex={-1}
                      key={index}
                      onClick={() =>
                        onTableRowClick &&
                        !row['disableTableRowClick'] &&
                        onTableRowClick(row, data)
                      }
                    >
                      {columns.map((column, columnIndex) => {
                        const value = get(row, column.field);
                        let style;
                        if (typeof column.cellStyle === 'function') {
                          style = column.cellStyle(value);
                        } else if (typeof column.cellStyle === 'object') {
                          style = column.cellStyle;
                        }

                        const access = get(
                          permissions,
                          column.customPermissionField || column.field,
                          7,
                        );

                        const rowspan = column.getRowspan ? column.getRowspan(row) : 1;

                        return rowspan ? (
                          <Permission access={access} key={`${columnIndex}-fragment`}>
                            {columnIndex === 0 && selectableRows ? (
                              <TableCell padding="checkbox" key={`${columnIndex}-checkbox`}>
                                <PermissionField createHelperText>
                                  <PermissionField
                                    Component={Checkbox}
                                    checked={isItemSelected}
                                    onClick={event => handleCheckboxClick(event, row, selected)}
                                  />
                                </PermissionField>
                              </TableCell>
                            ) : (
                              <TableCell
                                key={columnIndex}
                                style={style}
                                rowSpan={rowspan}
                                onClick={event =>
                                  stopPropagationLogic(
                                    event,
                                    !column.enablePropagation && !!column.render,
                                  )
                                }
                              >
                                {column.render ? column.render(row[column.field]) : value}
                              </TableCell>
                            )}
                          </Permission>
                        ) : null;
                      })}
                      {((!row['disableEdit'] && handleEditRow) ||
                        (!row['disableDelete'] && handleDeleteRow)) && (
                          <TableCell className={styles.actionTableCell}>
                            <div className={styles.actionTableCellButtonHolder}>
                              {!row['disableEdit'] && handleEditRow && (
                                <IconButton
                                  aria-label="edit"
                                  className={styles.iconButtonRoot}
                                  onClick={event => {
                                    handleEdit(event, row.id, row);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              )}
                              {!row['disableDelete'] && handleDeleteRow && (
                                <IconButton
                                  aria-label="delete"
                                  className={styles.iconButtonRoot}
                                  onClick={event => onDeleteRow(event, row.id)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                            </div>
                          </TableCell>
                        )}
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No data</TableCell>
              </TableRow>
            )}
            {
              lastRow && (
                <TableRow>
                  <TableCell align='right' colSpan={columns.length}>
                    {lastRow}
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={() => {
          setIsConfirmationModalOpen(false);
          setDeleteId();
        }}
        disabled={isDeleting}
        text={deleteConfirmationModalText}
        confirmAction={() => handleDeleteRow(deleteId)}
      />
    </>
  ) : null;
};

export default DataTable;

DataTable.propTypes = {
  columns: PropTypes.array,
  title: PropTypes.string,
  structureTable: PropTypes.func,
  customActionsTop: PropTypes.func,
  customActionsCenter: PropTypes.func,
  customActionsBottom: PropTypes.func,
  data: PropTypes.object,
  onTableRowClick: PropTypes.func,
};
