import { gql } from '@apollo/client';

const FETCH_MULTIPLE = gql`
  query multiple(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [MultipleSort]
    $filter: [MultipleFilter]
    $fieldGroupOnly: Int
    $fieldGroup: Int
    $locationMultipleChoice: [LocationMultipleChoiceFilter]
    $tierId: Int
  ) {
    viewer {
      id
      platformConnection(
        filter: {
          id: {
            in: [
              "UGxhdGZvcm06MzpQT1M6OTk5OS0xMi0zMSAyMzo1OTo1OQ=="
              "UGxhdGZvcm06MTpPTE86OTk5OS0xMi0zMSAyMzo1OTo1OQ=="
              "UGxhdGZvcm06NDpUUFY6OTk5OS0xMi0zMSAyMzo1OTo1OQ=="
            ]
          }
        }
        sort: { label: DESC }
      ) {
        edges {
          node {
            id
            label
          }
        }
      }
      multipleConnection(
        first: $first
        last: $last
        sort: $sort
        after: $after
        before: $before
        search: $search
        filter: $filter
        fieldGroupOnly: $fieldGroupOnly
        fieldGroup: $fieldGroup
      ) {
        permissions
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            locationMultipleChoiceConnection(filter: $locationMultipleChoice) {
              edges {
                node {
                  price
                  posPrice
                  thirdPartyPrice
                  multiple {
                    id
                  }
                  choice {
                    id
                    cost
                    label
                  }
                }
              }
            }
            multipleId
            label
            description
            multipleChoiceConnection {
              edges {
                node {
                  id
                  price
                  priceCap
                  choice {
                    id
                    label
                  }
                  multipleChoiceTierConnection {
                    edges {
                      node {
                        id
                        tier {
                          id
                          tierId
                        }
                        price
                        priceCap
                        multipleChoiceTierPlatformConnection(
                          filter: { tier: { tierId: { eq: $tierId } } }
                        ) {
                          edges {
                            node {
                              tier {
                                label
                              }
                              platform {
                                label
                              }
                              priceCap
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_MULTIPLE;
