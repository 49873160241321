import { gql } from '@apollo/client';

const DISCONNECT_FROM_SQUARE = gql`
  mutation disconnectFromSquare($input: SquareOffboardingMutationInput!) {
    squareOffboarding(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default DISCONNECT_FROM_SQUARE;
