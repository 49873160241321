import React, { useEffect, useState } from "react";
import SelectMUI from "../../../../../../blocks/Select";
import { Permission, PermissionField } from "../../../../../../blocks";
import { Grid, TextField, IconButton, Checkbox } from "@material-ui/core";
import ChoiceSelectedList from "./ChoiceSelectedList";
import { convert } from "../../../../../../../utils";
import { MultipleSelectItemStyles } from "./styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AutoComplete from "../../../../../../blocks/AutoComplete";
const MultipleSelectItem = ({
  values,
  multipleLabel,
  control,
  register,
  multipleId,
  itemId,
  line,
  id,
  errors,
  lineNumber,
  locationId,
  multipleSelected,
  setValue,
  permissions,
}) => {
  const classes = MultipleSelectItemStyles();
  const defaultValue =
    multipleSelected?.map((selectedChoice) => ({
      id: selectedChoice?.node?.choice?.id,
      value: selectedChoice?.node?.choice?.id,
      label: selectedChoice?.node?.choice?.label,
      cost:
        selectedChoice?.node?.price &&
        convert.centsToDollars(selectedChoice?.node?.price),
      choiceAllowedMaximum:
        selectedChoice.choiceAllowedMaximum &&
        selectedChoice.choiceAllowedMaximum,
      quantity: selectedChoice?.node?.quantity
        ? selectedChoice?.node?.quantity
        : 0,
      fromLine: Boolean(line),
    })) || [];
  const [selected, setSelected] = useState(
    (multipleSelected &&
      multipleSelected?.map((selectedChoice) => {
        return {
          id: selectedChoice?.node?.choice?.id,
          value: selectedChoice?.node?.choice?.id,
          label: selectedChoice?.node?.choice?.label,
          cost:
            selectedChoice?.node?.price &&
            convert.centsToDollars(selectedChoice?.node?.price),
          choiceAllowedMaximum:
            selectedChoice.choiceAllowedMaximum &&
            selectedChoice.choiceAllowedMaximum,
          quantity: selectedChoice?.node?.quantity
            ? selectedChoice?.node?.quantity
            : 0,
          fromLine: Boolean(line),
        };
      })) ||
      []
  );
  useEffect(() => {
    const obj = {};
    selected.forEach((el) => {
      obj[`${lineNumber}-selected-multipleId-${multipleId}-${el.id}`] =
        el.quantity || 1;
    });
    setMultipleChoicesQuantity((prevState) => {
      return {
        ...obj,
      };
    });
  }, [selected]);

  const [multipleChoicesQuantity, setMultipleChoicesQuantity] = useState({});
  const renderHelperText = (requiredMinimum, allowedMaximum) => {
    if (requiredMinimum && allowedMaximum) {
      return `You can choose between ${requiredMinimum} and ${allowedMaximum} of choices!.`;
    } else if (!requiredMinimum && !allowedMaximum) {
      return `No choice restriction!.`;
    } else if (!requiredMinimum && allowedMaximum) {
      return `Yo can choose maximum of ${allowedMaximum} choices!`;
    } else if (!allowedMaximum && requiredMinimum) {
      return `Yo can choose minimum of ${requiredMinimum} choices!.`;
    }
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Permission
        access={
          permissions?.lineConnection?.edges?.node?.lineMultipleConnection
            ?.edges?.node?.choice?.__typename
        }
      >
        <Grid item xs={12} className={classes.input}>
          <PermissionField
            Component={AutoComplete}
            id={`${lineNumber}-${multipleId}`}
            multiple
            defaultValue={defaultValue || []}
            style={{ width: "100%" }}
            disableCloseOnSelect
            onChange={setSelected}
            control={control}
            getOptionLabel={(option) => option.label || ""}
            renderTags={(value, getTagProps) =>
              value.map(({ label }) => label).join(",  ")
            }
            options={values}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  id={option.id}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <Permission
                access={
                  permissions?.lineConnection?.edges?.node
                    ?.lineMultipleConnection?.edges?.node?.choice?.__typename
                }
              >
                <PermissionField
                  Component={TextField}
                  {...params}
                  style={{ width: "100%" }}
                  error={
                    errors?.discountDetails && errors?.discountDetails?.handoff
                  }
                  helperText={
                    errors?.discountDetails && errors?.discountDetails?.handoff
                      ? errors?.discountDetails?.handoff?.message
                      : ""
                  }
                  label={`Select ${multipleLabel}`}
                  variant="outlined"
                />
              </Permission>
            )}
          />
        </Grid>
      </Permission>
      <Grid item container spacing={2} xs={12} className={classes.list}>
        {selected?.map((x, i) => {
          return (
            <ChoiceSelectedList
              setValue={setValue}
              permissions={permissions}
              lineNumber={lineNumber}
              locationId={locationId}
              multipleId={multipleId}
              fromLine={x.fromLine}
              key={`choiceSelected-${id}-${i}`}
              label={x.label}
              control={control}
              cost={x.cost}
              id={x.id}
              defaultQuantity={x.quantity}
              register={register}
              allowedMaximum={x.choiceAllowedMaximum}
              errors={errors}
              setMultipleChoicesQuantity={setMultipleChoicesQuantity}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default MultipleSelectItem;
