import React from "react";
import PaymentProcessorsView from "./View";

function PaymentProcessors({ devicesAndPrinters, gateways, enablePayPalAndSquare, permissions }) {
  return (
    <PaymentProcessorsView
      enablePayPalAndSquare={enablePayPalAndSquare}
      devicesAndPrinters={devicesAndPrinters}
      gateways={gateways}
      permissions={permissions}
    />
  );
}

export default PaymentProcessors;
