import { gql } from "@apollo/client";

const STATUS = gql`
  query states($sort: [StatusSort]) {
    viewer {
      id
    statusConnection(sort: $sort) {
      edges {
       node {
          id
            label
            statusId
            action
            color {
              code
            }
          }
        }
      }
    }
  }
`;

export default STATUS;
