import { gql } from '@apollo/client';

const REMOVE_LOCATION_HIERARCHY = gql`
  mutation removeLocationHierarchy(
    $input: removeLocationHierarchyMutationInput!
  ) {
    removeLocationHierarchy(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default REMOVE_LOCATION_HIERARCHY;
