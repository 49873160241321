import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { CREATE_POS_ACCESS_ROLE, UPDATE_POS_ACCESS_ROLE } from '../../../../../../apollo/mutations';
import { FETCH_POS_PERMISSIONS, POS_ACCESS_LIST } from '../../../../../../apollo/queries';
import { Modal, PermissionField, Permission, SelectFactory, Elements } from '../../../../../blocks';
import { Button, Grid, TextField, FormControlLabel } from '@material-ui/core';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
// import { Delete as DeleteIcon } from '@material-ui/icons';

const PosAccessEditModal = ({ 
	accessToMutations,
	createPosRolePermission, 
	handleClose, 
	open, 
	posAccessRole, 
	posAccessRolePermissions,
	updatePosRolePermission, 
	variables, 
 }) => {
  const { setSnackbar } = useSnackbar();
  const { errors, handleSubmit, register } = useForm();

	const [posAccesses, setPosAccesses] = useState([]);
	const [posAccessError, setPosAccessError] = useState(false);

	const [createPosAccessRole, { loading: createLoading }] = useMutation(CREATE_POS_ACCESS_ROLE ,{
		refetchQueries: [
			{ query: POS_ACCESS_LIST,
				variables,
			},
		]
	});
	const [editPosAccessRole, { loading: editLoading }] = useMutation(UPDATE_POS_ACCESS_ROLE, {
		refetchQueries: [
			{ query: POS_ACCESS_LIST,
				variables,
			},
		]
	});

	const loading = useMemo(() => createLoading || editLoading, [createLoading, editLoading]);

	useEffect(() => {
    if (posAccessRole?.posRolePosPermissionConnection?.length) {
      setPosAccesses(posAccessRole?.posRolePosPermissionConnection?.map(edge => ({
				label:edge.node?.posPermission?.label,
				id: edge.node?.posPermissionId,
				value: edge.node?.posPermissionId,
				tooltip: edge.node?.description, 
			})) || []);
    }
  }, [posAccessRole]);

	useEffect(() => {
		if (!open) {
			setPosAccesses([]);
		}
	}, [open])

	const onSubmit = useCallback((formData) => {
		if (!posAccesses?.length) {
			return setPosAccessError(true);
		}

		setPosAccessError(false);
		
		const values = {
			...formData,
			posPermissionIds: posAccesses?.map(access => access.id),
		}
		
		setSnackbar({
			type: SNACKBAR_STATUS.INFO,
			text: posAccessRole?.id ? "Updating POS Access Role..." : "Creating POS Access Role...",
			open: true,
		});

		if (posAccessRole?.id) {
			editPosAccessRole({ variables: { 
				input: {
					...values, 
					posRoleId: posAccessRole?.id,
				}
			}}).then(() => {
				setSnackbar({
					type: SNACKBAR_STATUS.SUCCESS,
					text: "POS Access Role updated",
					open: true,
				});

				handleClose();
			}).catch(e => {
				setSnackbar({
					type: SNACKBAR_STATUS.ERROR,
					text: e.message,
					open: true,
				});
			});
		} else {
			createPosAccessRole({ 
				variables: {
					input: values
				}
			}).then(() => {
				setSnackbar({
					type: SNACKBAR_STATUS.SUCCESS,
					text: "POS Access Role created",
					open: true,
				});

				handleClose();
			}).catch(e => {
				setSnackbar({
					type: SNACKBAR_STATUS.ERROR,
					text: e.message,
					open: true,
				});
			});
		}
	}, [posAccessRole, createPosAccessRole, editPosAccessRole, posAccesses, setSnackbar, handleClose]);
	
	// const handleRemoveItem = useCallback((id) => {
	// 	setPosAccesses(prevPosAccesses => prevPosAccesses.filter(item => item.id !== id));
	// }, []);
	
	const handleOnPermissionSelect = useCallback((items) => {
		setPosAccesses(items);
	}, []);
	
	return (
		<Modal
		 open={open} 
		 handleClose={handleClose} 
		 title={posAccessRole ? "Edit POS Access" : "Create POS Access"}
		>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
				<Grid xs={12} container spacing={4}>
					<Permission access={posAccessRolePermissions?.label}>
						<Grid item xs={12}>
							<PermissionField 
								Component={TextField}
								name="label"
								label="POS Access Role"
								defaultValue={posAccessRole?.label}
								disabled={loading}
								error={!!errors.label}
								helperText={errors.label ? "POS Access Role is required" : ''}
								inputRef={register({ required: "Required" })}
								fullWidth
							/>
						</Grid>
					</Permission>
				</Grid>
				<Grid container xs={12} spacing={4}>
					<Permission access={posAccessRolePermissions?.posRolePosPermissionConnection?.edges?.node?.id}>
						<Grid xs={12} item>
							<PermissionField 
								Component={SelectFactory}
								label={"Permissions"}
								placeholder={"Select Permissions"}
								fullWidth
								query={FETCH_POS_PERMISSIONS}
								style={{ width: "100%" }}
								multiple
								disableCloseOnSelect
								renderCheckBox
								structureData={data => {
									return data?.viewer?.posPermissionConnection?.edges?.map(edge => ({
										label: edge?.node?.label,
										id: edge.node?.posPermissionId,
										value: edge.node?.posPermissionId,
										tooltip: edge.node?.description,
									}))
								}}
								onSelect={handleOnPermissionSelect}
								name="permissions"
								value={posAccesses}
								error={posAccessError}
								helperText={posAccessError ? "Select at least one permission" : ""}
							/>
						</Grid>
					</Permission>
				</Grid>
				{/* <Grid xs={12} container>
					<Permission access={7}>
						{posAccesses?.map(item => (
							<Grid item xs={12} justify="space-between" key={item.id}>
								<div>
									<Typography variant="body1">{item.label}</Typography>
								</div>
								<div>
									<PermissionField createHelperText>
										<IconButton onClick={() => handleRemoveItem(item.id)}>
											<DeleteIcon />
										</IconButton>
									</PermissionField>
								</div>
							</Grid>
						))}
					</Permission>
				</Grid> */}
				<Grid container xs={12}>
					<Permission access={posAccessRolePermissions?.active}>
						<Grid xs={6} item>
							<PermissionField
								Component={FormControlLabel}
								label="Active"
								createHelperText
								labelPlacement="start"
								control={
									<Elements.Switch
										checked={posAccessRole?.status}
										ref={register}
										name="active"
									/>
								}
							/>
						</Grid>
					</Permission>
				</Grid>
				<Grid container xs={12} justify="flex-end">
					<Permission access={posAccessRole 
						? Math.min(accessToMutations?.[MUTATION_NAME.updatePosRoleInput], updatePosRolePermission)
						: Math.min(accessToMutations?.[MUTATION_NAME.createPosRoleInput], createPosRolePermission)
					 }>
						<Button variant="outlined" color="secondary" type="button" onClick={handleClose} style={{ marginRight: "1rem" }}>Cancel</Button>
						<div>
							<PermissionField createHelperText>
								<Button variant="outlined" color="primary" type="submit">Save</Button>
							</PermissionField>
						</div>
					</Permission>
				</Grid> 
			</form>
		</Modal>
	)
};

export default PosAccessEditModal;