import { gql } from '@apollo/client';

const UPDATE_SCHEDULE = gql`mutation updateSchedule($input: UpdateScheduleMutationInput!, $scheduleFilter:[scheduleFilter], $personJobFilter: [PersonJobFilter]){
  updateSchedule(input: $input) {
    viewer {
    id
    scheduleConnection(filter: $scheduleFilter, fieldGroup: 62) {
      permissions
        edges{
          node {
            id
            scheduleId
            personId
            started
            finished
            jobId
            delivery
            person {
              id
              firstName
              lastName
              personJobConnection(filter:$personJobFilter){
                edges {
                  node {
                    id
                    salaryType {
                      id
                      salarytypeId
                    }
                    salaryAmount
                    job {
                      label
                      id
                      jobId
                      color {
                        id
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export default UPDATE_SCHEDULE