import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { SelectFactory } from '../../../../../../blocks';
import { FETCH_BRANDS, INGREDIENT_CATEGORIES } from '../../../../../../../apollo/queries';

const statusOptions = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

const yesOrNoOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
function BasicInfoForms({ register, control, errors, ingredientConnection }) {
  const defaultBrands = useMemo(
    () =>
      ingredientConnection?.edges[0]?.node?.inventoryitem?.brands?.map(brand => ({
        id: brand.brandId,
        label: brand.label,
      })) || [],
    [ingredientConnection],
  );

  return (
    <Grid container spacing={3}>
      <Grid item sm={6}>
        <TextField
          inputRef={register({ required: 'true' })}
          fullWidth
          label="Name"
          error={!!errors.label}
          helperText={!!errors.label ? 'Name is required' : ''}
          name="label"
          variant="outlined"
        />
      </Grid>
      <Grid item sm={6}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel error={!!errors?.status}>Status</InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            id="status"
            name="status"
            label="Status"
            defaultValue=""
            placeholder="Status"
            error={!!errors?.status}
            as={
              <Select label="Status" multiple={false} error={!!errors?.status}>
                {statusOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
          />
          {errors?.status?.type === 'required' ? (
            <FormHelperText error>Status is required</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Show On Location Menu Settings</InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            id="showOnLocation"
            name="showOnLocation"
            label="Show On Location Menu Settings"
            defaultValue={1}
            placeholder="Show On Location Menu Settings"
            as={
              <Select label="Show On Location" multiple={false}>
                {yesOrNoOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Allow Automatic Stock Toggle</InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            id="Allow Automatic Stock Toggle"
            name="allowAutomaticStockToggle"
            label="Allow Automatic Stock Toggle"
            defaultValue={1}
            placeholder="Allow Automatic Stock Toggle"
            as={
              <Select label="Show On Location" multiple={false}>
                {yesOrNoOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <Controller
          control={control}
          rules={{
            validate: {
              required: value => !!value?.length,
            },
          }}
          name="brandIds"
          defaultValue={defaultBrands || []}
          render={props => {
            return (
              <SelectFactory
                customProps={props}
                label="Brand Availability"
                placeholder="Select Available Brands"
                query={FETCH_BRANDS}
                style={{ width: '100%' }}
                multiple
                defaultValue={defaultBrands || []}
                renderCheckBox
                disableCloseOnSelect
                structureData={brands =>
                  brands?.viewer?.brandConnection?.edges?.map(edge => ({
                    id: edge.node?.brandId,
                    label: edge.node?.label,
                  })) || []
                }
                error={!!errors?.brandIds}
                helperText={!!errors?.brandIds && 'At least one brand is required'}
              />
            );
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel error={!!errors?.inventoryStatus}>Inventory status</InputLabel>
          <Controller
            rules={{
              required: true,
            }}
            id={'inventoryStatus'}
            name={'inventoryStatus'}
            label="Inventory status"
            placeholder="Inventory status"
            defaultValue=""
            error={!!errors?.inventoryStatus}
            as={
              <Select label="Inventory status" multiple={false} error={!!errors?.inventoryStatus}>
                {statusOptions?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
          />
          {errors?.inventoryStatus?.type === 'required' ? (
            <FormHelperText error>Inventory status is required</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <Controller
            control={control}
            id={'ingredientcategoryId'}
            name={'ingredientcategoryId'}
            rules={{
              required: true,
            }}
            defaultValue={{
              id:
                ingredientConnection?.edges[0]?.node.inventoryitem.inventoryitemcategory
                  .inventoryitemcategoryId,
              label: ingredientConnection?.edges[0]?.node.inventoryitem.inventoryitemcategory.label,
            }}
            render={props => (
              <SelectFactory
                {...props}
                defaultValue={[
                  {
                    id:
                      ingredientConnection?.edges[0]?.node.inventoryitem.inventoryitemcategory
                        .inventoryitemcategoryId,
                    label:
                      ingredientConnection?.edges[0]?.node.inventoryitem.inventoryitemcategory
                        .label,
                  },
                ]}
                customProps={props}
                label="Cost Category"
                fullWidth
                filter={{ inventoryitemcategorytype: { isCost: { eq: 1 } } }}
                name={'ingredientcategoryId'}
                placeholder="Search Categories"
                query={INGREDIENT_CATEGORIES}
                structureData={data =>
                  data?.viewer?.inventoryitemcategoryConnection?.edges?.map((edge, index) => ({
                    id: edge.node.inventoryitemcategoryId,
                    label: edge.node.label,
                    index,
                  }))
                }
                multiple={false}
                renderCheckBox={false}
                error={!!errors?.costCategory}
                helperText={errors?.costCategory && 'Cost Category is required'}
              />
            )}
          />
          {errors?.ingredientcategoryId?.type === 'required' ? (
            <FormHelperText error>Cost Category is required</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item sm={6} style={{ flexDirection: 'column' }}>
        <TextField
          name="cost"
          inputRef={register}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{
            min: 0.0,
            step: 0.000000000000001,
          }}
          fullWidth
          label="Average Unit Price"
          variant="outlined"
        />
        <p>If left blank, the system will use the latest invoice price.</p>
      </Grid>
      <Grid item sm={12}>
        <TextField
          inputRef={register}
          fullWidth
          name="description"
          label="Description"
          variant="outlined"
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  );
}

BasicInfoForms.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
};

export default BasicInfoForms;
