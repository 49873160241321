import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  MenuItem,
  Select,
  CircularProgress,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Add } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

import { FETCH_ICONTACT_DATA } from '../../../apollo/queries/discounts/fetchiContactData';
import { CREATE_CUSTOM_FIELD_ICONTACT, GENERATE_COUPON_CODES } from '../../../apollo/mutations';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../constants';
import { regexPattern } from '../../../utils';
import CustomerGroupFilters from '../CustomerGroupFilters';
import { SerializedCuouponDistributionModalStyles } from './styles.js';

const CODE_GENERATION_METHODS = {
  FIXED: 'fixed',
  CUSTOMER_GROUP_SIZE: 'customerGroup',
};

const SerializedCouponDistributionModal = ({
  open,
  setSerializedCouponModal,
  couponId,
  brands,
}) => {
  const classes = SerializedCuouponDistributionModalStyles();

  const [customFieldModal, setCustomFieldModal] = useState(false);
  const [generateCodesFor, setGenerateCodesFor] = useState('print');
  const [generationFormat, setGenerationFormat] = useState('direct');
  const [codeQuantityGenerationMethod, setCodeQuantityGenerationMethod] = useState(
    CODE_GENERATION_METHODS.FIXED,
  );
  const [customerGroupFilters, setCustomerGroupFilters] = useState({});

  const { register, handleSubmit, errors, control } = useForm();
  const { setSnackbar } = useSnackbar();
  const [fetchiContactData, { data, loading, error, called }] = useLazyQuery(FETCH_ICONTACT_DATA, {
    notifyOnNetworkStatusChange: true,
  });
  const [createCustomFieldiContact, { loading: createCustomFieldiContactLoading }] = useMutation(
    CREATE_CUSTOM_FIELD_ICONTACT,
    {
      // eslint-disable-next-line no-shadow
      update: (cache, mutationResult) => {
        cache.modify({
          id: `iContact:${data.viewer.iContact.id}`,
          fields: {
            customFields: existingCustomFields => {
              return [
                ...existingCustomFields,
                mutationResult.data.createCustomFieldiContact.customField,
              ];
            },
          },
        });
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Custom field created.',
        });
        handleNewCustomFieldClose();
      },
      onError: error => {
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        });
      },
    },
  );
  const [generateCouponCodes, { loading: generateCouponCodesLoading }] = useMutation(
    GENERATE_COUPON_CODES,
    {
      // eslint-disable-next-line no-shadow
      onCompleted: () => {
        handleClose();
      },
      onError: error => {
        setSnackbar({
          open: true,
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
        });
      },
    },
  );

  if (error) return <div>ERROR</div>;

  if (open && !called) {
    fetchiContactData();
  }

  const handleNewCustomFieldOpen = useCallback(() => {
    setSerializedCouponModal(false);
    setCustomFieldModal(true);
  }, []);

  const handleCodeQuantityGenerationMethod = useCallback(e => {
    setCodeQuantityGenerationMethod(e.target.value);
  }, []);

  const handleNewCustomFieldClose = useCallback(() => {
    setSerializedCouponModal(true);
    setCustomFieldModal(false);
  }, []);

  const handleClose = useCallback(() => {
    setCustomerGroupFilters({});
    setSerializedCouponModal(false);
  }, []);

  const handleGenerateCodesForChange = useCallback(event => {
    setGenerateCodesFor(event.target.value);
  }, []);

  const handleGenerationFormat = useCallback(event => {
    setGenerationFormat(event.target.value);
  }, []);

  const onSubmit = useCallback(
    data => {
      if (data.customFieldPrivateName && data.customFieldPublicName) {
        setSnackbar({
          open: true,
          type: 'info',
          text: 'Creating custom field...',
        });
        createCustomFieldiContact({
          variables: {
            input: {
              customFieldPrivateName: data.customFieldPrivateName,
              customFieldPublicName: data.customFieldPublicName,
            },
          },
        });
      } else {
        let input = {};
        if (codeQuantityGenerationMethod === CODE_GENERATION_METHODS.FIXED) {
          input = {
            couponId: couponId,
            customFieldId: data.customField,
            quantity: data.quantity,
            listId: data.list,
            email: data.email,
          };
        } else {
          input = {
            couponId,
            email: data.email,
            customergroup: {
              filters: {
                ...customerGroupFilters,
              },
              brandIds: brands,
            },
          };

          if (data?.startDate && data?.endDate) {
            input.customergroup.filters = {
              ...input.customergroup.filters,
              startDate: moment(data.startDate)?.format('YYYY-MM-DD'),
              endDate: moment(data.endDate)?.format('YYYY-MM-DD'),
            };
          }

          if (!(data.startDate && data.endDate) && !customerGroupFilters?.locationIds?.length) {
            setSnackbar({
              open: true,
              type: 'error',
              text: 'Generating customer groups requires a date range or at least one location.',
            });

            return;
          }
        }

        setSnackbar({
          open: true,
          type: 'info',
          text: 'Generating coupon codes, they should be available shortly...',
        });

        generateCouponCodes({
          variables: {
            input,
          },
        });
      }
    },
    [
      setSnackbar,
      createCustomFieldiContact,
      generateCouponCodes,
      couponId,
      codeQuantityGenerationMethod,
      customerGroupFilters,
    ],
  );

  return (
    <>
      <Dialog open={!!open} onClose={handleClose} scroll="paper" fullWidth={true} maxWidth="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <span className={classes.dialogTitle}>Generate serial codes</span>
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {/* <FormControl component="fieldset" style={{marginBottom: '15px'}}>
                   <FormLabel focused={false}>Generate codes for:</FormLabel>
                   <RadioGroup
                       row
                       value={generateCodesFor}
                       onChange={handleGenerateCodesForChange}
                   >
                       <FormControlLabel value="print" control={<Radio/>} label="Print (codes only)"/>
                       <FormControlLabel value="iContact" control={<Radio/>} label="iContact"/>
                   </RadioGroup>
                </FormControl> */}
                {generateCodesFor === 'iContact' ? (
                  <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                    <Grid item={true} xs={6}>
                      <div style={{ width: '100%' }}>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                          <InputLabel>Choose a list from iContact</InputLabel>
                          <Controller
                            defaultValue={''}
                            error={errors.list?.hasOwnProperty('message')}
                            rules={{
                              required: true,
                            }}
                            as={
                              <Select
                                label="Choose a list from iContact"
                                error={errors.list?.hasOwnProperty('message')}
                                variant="outlined"
                              >
                                {data?.viewer?.iContact?.lists?.map(list => (
                                  <MenuItem key={list.listId} value={list.listId}>
                                    {list.publicname}
                                  </MenuItem>
                                ))}
                              </Select>
                            }
                            name="list"
                            control={control}
                          />
                        </FormControl>
                        {errors.list && (
                          <div>
                            <FormHelperText error>A list must be selected</FormHelperText>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <div style={{ width: '100%' }}>
                        <FormControl variant="outlined" style={{ width: '89%' }}>
                          <InputLabel>Choose custom field from iContact</InputLabel>
                          <Controller
                            defaultValue={''}
                            error={errors.customField?.hasOwnProperty('message')}
                            rules={{
                              required: true,
                            }}
                            as={
                              <Select label="Choose custom field from iContact" variant="outlined">
                                {data?.viewer?.iContact?.customFields?.map(customField => (
                                  <MenuItem
                                    key={customField.customFieldId}
                                    value={customField.customFieldId}
                                  >
                                    {customField.privateName}
                                  </MenuItem>
                                ))}
                              </Select>
                            }
                            name="customField"
                            control={control}
                          />
                        </FormControl>
                        {errors.customField && (
                          <div style={{ width: '89%' }}>
                            <FormHelperText style={{ width: '89%' }} error>
                              A custom field must be selected
                            </FormHelperText>
                          </div>
                        )}
                      </div>
                      <Tooltip title="Create custom field">
                        <IconButton aria-label="create" onClick={handleNewCustomFieldOpen}>
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.noniContactTitle}>
                        <FormLabel focused={false}>Generate code quantity:</FormLabel>
                        <RadioGroup
                          row
                          value={codeQuantityGenerationMethod}
                          onChange={handleCodeQuantityGenerationMethod}
                        >
                          <FormControlLabel
                            value={CODE_GENERATION_METHODS.FIXED}
                            control={<Radio />}
                            label="Fixed quantity"
                          />
                          <FormControlLabel
                            value={CODE_GENERATION_METHODS.CUSTOMER_GROUP_SIZE}
                            control={<Radio />}
                            label="Customer group size quantity"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {codeQuantityGenerationMethod === CODE_GENERATION_METHODS.FIXED ? (
                      <>
                        <Grid item={true} xs={4}>
                          <div className={classes.fixedMethod}>
                            <TextField
                              name="quantity"
                              label="Quantity"
                              error={errors.quantity?.hasOwnProperty('message')}
                              inputRef={register({ required: true })}
                              fullWidth
                            />
                            {errors.quantity && (
                              <div>
                                <FormHelperText error>Quantity must be entered</FormHelperText>
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item={true} xs={8}>
                          <div className={classes.fixedMethod}>
                            <TextField
                              name="email"
                              label="Email codes to"
                              error={errors.email?.hasOwnProperty('message')}
                              inputRef={register({
                                required: true,
                                pattern: {
                                  value: regexPattern.email,
                                  message: 'Invalid email address',
                                },
                              })}
                              fullWidth
                            />
                            {errors.email && (
                              <div>
                                <FormHelperText error>
                                  {errors.email?.message || 'Email must be entered'}
                                </FormHelperText>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <CustomerGroupFilters
                          control={control}
                          errors={errors}
                          customerGroupFilters={customerGroupFilters}
                          setCustomerGroupFilters={setCustomerGroupFilters}
                        />
                        <Divider variant="middle" className={classes.customGroupFiltersDivider} />
                        <Grid item={true} xs={12}>
                          <div className={classes.customGroupFiltersEmail}>
                            <TextField
                              name="email"
                              label="Email codes to"
                              error={errors.email?.hasOwnProperty('message')}
                              inputRef={register({
                                required: true,
                                pattern: {
                                  value: regexPattern.email,
                                  message: 'Invalid email address',
                                },
                              })}
                              fullWidth
                            />
                            {errors.email && (
                              <div>
                                <FormHelperText error>
                                  {errors.email?.message || 'Email must be entered'}
                                </FormHelperText>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
                {generateCodesFor === 'iContact' && (
                  <>
                    <FormControl style={{ marginBottom: '15px' }} component="fieldset">
                      <FormLabel component="legend" focused={false}>
                        Code format creation:
                      </FormLabel>
                      <RadioGroup row value={generationFormat} onChange={handleGenerationFormat}>
                        <FormControlLabel
                          value="direct"
                          control={<Radio />}
                          label="Directly in iContact"
                        />
                        <FormControlLabel value="csv" control={<Radio />} label="CSV" />
                      </RadioGroup>
                    </FormControl>
                    {generationFormat === 'csv' && (
                      <div style={{ width: '100%' }}>
                        <TextField
                          name="email"
                          label="Email codes to"
                          error={errors.email?.hasOwnProperty('message')}
                          inputRef={register({
                            required: true,
                            pattern: {
                              value: regexPattern.email,
                              message: 'Invalid email address',
                            },
                          })}
                          fullWidth
                        />
                        {errors.email && (
                          <div>
                            <FormHelperText error>
                              {errors.email?.message || 'Email must be entered'}
                            </FormHelperText>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions style={{ paddingRight: '24px' }}>
            <Button disabled={loading || error} onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading || error} type="submit">
              Generate
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={customFieldModal}
        onClose={handleNewCustomFieldClose}
        scroll="paper"
        fullWidth={true}
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <span style={{ fontSize: '22px' }}>Create new custom field</span>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item={true} xs={6}>
                <div style={{ width: '100%' }}>
                  <TextField
                    name="customFieldPrivateName"
                    label="Custom Field Private Name"
                    error={errors.customFieldPrivateName?.hasOwnProperty('message')}
                    inputRef={register({ required: true })}
                    fullWidth
                  />
                  {errors.customFieldPrivateName && (
                    <div>
                      <FormHelperText error>
                        Custom field private name must be entered
                      </FormHelperText>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item={true} xs={6}>
                <div style={{ width: '100%' }}>
                  <TextField
                    name="customFieldPublicName"
                    label="Custom Field Public Name"
                    error={errors.customFieldPrivateName?.hasOwnProperty('message')}
                    inputRef={register({ required: true })}
                    fullWidth
                  />
                  {errors.customFieldPublicName && (
                    <div>
                      <FormHelperText error>
                        Custom field public name must be entered
                      </FormHelperText>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingRight: '24px' }}>
            <Button disabled={loading || error} onClick={handleNewCustomFieldClose}>
              Cancel
            </Button>
            <Button disabled={loading || error} type="submit">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default SerializedCouponDistributionModal;
