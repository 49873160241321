import { gql } from '@apollo/client';
import personFragment from './personFragment';

export const quoteFragment = {
  quote: gql`
    fragment QuoteData on Quote {
      id
      quoteId
      locationId
      menuId
      category {
        label
      }
      handoff {
        id
        label
      }
      expected
      email
      dueAmount
      paid
      subtotalPrice
      totalPrice
      discountAmount
      discountFraction
      couponAmount
      additionalCharge
      totalTax
      tip
      note
      distance
      refunded
      burqId
      doordashId
      location {
        id
        created
        active
        email
        label
        timezone {
          label
        }
        phone {
          phone
        }
        address {
          address
          city
          longitude
          latitude
          state {
            label
            abbreviation
            country {
              id
              alpha2
            }
          }
          zip
        }
      }
      address {
        address
        city
        longitude
        latitude
        state {
          label
          abbreviation
        }
        zip
      }
      person {
        ...PersonData
        taxId
      }
      taxBasePrice
      lineConnection {
        edges {
          node {
            id
            totalPrice
            note
            price
            quantity
            subcategory {
              label
            }
            item {
              label
            }
            lineMultipleConnection {
              edges {
                node {
                  id
                  price
                  multiple {
                    id
                    label
                  }
                  quantity
                  choice {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      }
      paymentConnection {
        edges {
          node {
            id
            paymentId
            tender {
              label
            }
            refunded
            amount
            created
            paymentGatewayConnection(first: 1) {
              edges {
                node {
                  label
                  disputeReason
                  disputeStatus
                  disputeDueBy
                  paymentDetails {
                    customerIPAddress
                    billingAddress
                    submissionCount
                    isRefundable
                    uncategorizedText
                    customerCommunication
                    receipt
                    uncategorizedFile
                  }
                }
              }
            }
          }
        }
      }
    }
    ${personFragment.person}
  `,
};
