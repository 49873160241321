import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  title: {
    fontSize: '14px',
    ['@media (max-width:1200px)']: {
      fontSize: '12px',
    },
  },
  tooltip: {
    marginLeft: '10px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipDiv: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  projectionContainer: {
    margin: '15px 0',
  },
  input: {
    paddingRight: '15px',
    ['@media (max-width:1200px)']: {
      paddingRight: '5px',
    },

    '& div input': {
      ['@media (max-width:1200px)']: {
        fontSize: '13px',
      },
    },
  },
  data: {
    fontSize: '16px',
    ['@media (max-width:1200px)']: {
      fontSize: '13px',
    },
  },
  totalPercentage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  employeeList: {
    maxHeight: 600,
    overflowY: 'auto',

    '& .MuiGrid-container': {
      display: 'none',
    },
  },
  scheduleTimelineActions: {
    marginBottom: theme.spacing(2),

    '& .MuiButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
});

export const SalesProjectionsStyles = makeStyles(Styles);
