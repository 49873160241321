import { Chip, Grid } from '@material-ui/core';
import React, { useCallback } from 'react';

import GenericFileUpload from '../../../../../../blocks/GenericFileUpload';

const UploadFilesComponent = ({ files, setFiles }) => {
  const handleDeleteLocalFile = useCallback(
    url => {
      setFiles(prevFiles => prevFiles.filter(file => file.url !== url));
    },
    [setFiles],
  );

  const downloadFile = useCallback(url => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <>
      <h4>Upload any relevant files</h4>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <GenericFileUpload
            multiple
            shouldReadFile
            file={files}
            setFile={setFiles}
            text="Click here to browse files"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {files?.map(
            file =>
              file.url && (
                <div key={`file-${file.url}`}>
                  <Chip
                    style={{ marginRight: '6px', marginBottom: '6px' }}
                    onDelete={() => handleDeleteLocalFile(file.url)}
                    onClick={() => file.url && downloadFile(file.url)}
                    component={'a'}
                    label={file.name || file.label}
                  />
                </div>
              ),
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UploadFilesComponent;
