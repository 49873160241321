import { Button } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import HighlightOffOutlined from "@material-ui/icons/HighlightOffOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import ItemTypeForm from "../ItemTypeForm";
import { ConnectionTable, Modal } from "../../../../blocks";
import FETCH_ITEM_TYPES from "../../../../../apollo/queries/menus/fetchItemTypes";
import HANDOFFS from "../../../../../apollo/queries/filters/handoffs";
import REMOVE_ITEM_TYPE from "../../../../../apollo/mutations/menus/removeItemType";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { SNACKBAR_STATUS } from "../../../../../constants";
import { uglifyId, formatConnectionName } from "../../../../../utils";

const Status = ({ active }) =>
  active ? (
    <CheckCircleOutlineIcon style={{ color: "#37C78A" }} />
  ) : (
    <HighlightOffOutlined color="error" />
  );

const ItemType = () => {
  const { setSnackbar } = useSnackbar();

  const { data: handoffs, loading: isLoadingHandoffs } = useQuery(HANDOFFS);

  const [connectionVariables, setConnectionVariables] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [itemType, setItemType] = useState();

  const [removeItemType] = useMutation(REMOVE_ITEM_TYPE, {
    update: (cache, { data: { deleteItemtype } }) => {
      const { dynamicConnectionName } = formatConnectionName(
        "itemtype",
        connectionVariables
      );
      cache.modify({
        id: cache.identify(deleteItemtype.viewer),
        fields: {
          [dynamicConnectionName]: (existingItemtypeConnection) => {
            return {
              ...existingItemtypeConnection,
              totalCount: existingItemtypeConnection.totalCount - 1,
              edges: [
                ...existingItemtypeConnection.edges.filter(
                  (itemType) =>
                    itemType.node.__ref !==
                    `Itemtype:${uglifyId(
                      "Itemtype",
                      deleteItemtype.deletedItemtypeId
                    )}`
                ),
              ],
            };
          },
        },
      });
    },
    onCompleted: () => {
      setShowModal(false);

      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: "Item Type deleted",
        open: true,
      });
    },
    onError: (error) => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      });
    },
  });

  const handleEditRow = useCallback((data) => {
    setShowModal(true);
    setItemType(data);
  }, []);

  const handleDeleteRow = useCallback(
    (itemType) => {
      removeItemType({
        variables: {
          input: {
            itemtypeId: itemType.itemtypeId,
          },
        },
      });
    },
    [removeItemType]
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setItemType();
  }, []);

  const handoffColumns = useMemo(() => {
    if (handoffs?.viewer?.handoffConnection?.edges?.length) {
      return handoffs.viewer.handoffConnection.edges.map((edge) => ({
        title: edge.node?.label,
        field: `itemtypeHandoffConnection`,
        disableSort: true,
        customPermissionField:
          "itemtypeHandoffConnection.edges.node.__typename",
        enablePropagation: true,
        render: (handoffConnection) => {
          const handoff = handoffConnection?.edges?.find(
            (handoffEdge) => handoffEdge.node?.handoff?.handoffId === edge.node?.handoffId
          );

          return <Status active={handoff?.node?.isAvailable} />;
        },
      }));
    }

    return [];
  }, [handoffs]);

  return (
    <div>
      <ConnectionTable
        loading={isLoadingHandoffs}
        setConnectionVariables={setConnectionVariables}
        query={FETCH_ITEM_TYPES}
        onTableRowClick={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        deleteConfirmationText={
          "Are you sure you want to delete this item type?"
        }
        useConfirmationModalForDelete
        customActionsTop={() => (
          <Button onClick={() => handleEditRow()}>Create New Item Type</Button>
        )}
        structureTable={(data) =>
          data?.viewer?.itemtypeConnection?.edges?.map((edge) => edge.node) ||
          []
        }
        columns={[
          {
            title: "ID",
            field: "itemtypeId",
          },
          {
            title: "Label",
            field: "label",
          },
          {
            title: "Description",
            field: "description",
          },
          {
            title: "Taxable",
            field: "isTaxable",
            enablePropagation: true,
            render: (isTaxable) => <Status active={isTaxable} />,
          },
          {
            title: "Loyalty",
            field: "isLoyalty",
            enablePropagation: true,
            render: (isLoyalty) => <Status active={isLoyalty} />,
          },
          {
            title: "Discountable",
            field: "isDiscountable",
            enablePropagation: true,
            render: (isDiscountable) => <Status active={isDiscountable} />,
          },
          {
            title: "Alcohol",
            field: "isAlcohol",
            enablePropagation: true,
            render: (isAlcohol) => <Status active={isAlcohol} />,
          },
          ...handoffColumns,
        ]}
      />
      <Modal
        open={showModal}
        title={!itemType ? "Create New Item Type" : "Edit Item Type"}
        handleClose={handleCloseModal}
      >
        <ItemTypeForm
          handoffsData={handoffs?.viewer?.handoffConnection?.edges || []}
          itemType={itemType}
          handleCloseModal={handleCloseModal}
          connectionVariables={connectionVariables}
        />
      </Modal>
    </div>
  );
};

export default ItemType;
