import React, { useState } from 'react';
import { Button, Chip } from '@material-ui/core';
import { Modal } from '../../../../../../../../blocks';
import { Controller } from 'react-hook-form';
import Add from '@material-ui/icons/Add';
import CategorySelect from '../../../../GeneralSettings/DiscountType/CategorySelect';
function CategorySelectWrapper({ control, cartItemsRequired, setCartItemsRequired }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = itemId => {
    const newArr = cartItemsRequired.filter(item => item.id !== itemId);
    setCartItemsRequired(newArr);
  };

  return (
    <div>
      <Controller control={control} name="triggerEvents.automatic.subcategoryItemInCart" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h3>Selected Subcategories</h3>
        <div>
          <Button onClick={setOpen.bind(this, true)} variant="outlined">
            <Add /> Add Subcategories
          </Button>
        </div>
      </div>
      <div>
        {cartItemsRequired.map(item => {
          return (
            <Chip
              style={{ margin: '4px' }}
              key={item.id}
              label={item.label}
              onDelete={e => handleDelete(item.id)}
            />
          );
        })}
      </div>
      <Modal open={open} handleClose={handleClose} title="Add Subcategories">
        <CategorySelect
          selectedSubcategoriesAction={cartItemsRequired}
          setSelectedSubcategoriesAction={setCartItemsRequired}
          handleClose={handleClose}
        />
      </Modal>
    </div>
  );
}

export default CategorySelectWrapper;
