import { gql } from "@apollo/client";

export const itemtypeFragment = {
  itemtype: gql`
    fragment ItemtypeData on Itemtype {
      id
      permission
      itemtypeId
      label
      description
      isTaxable
      isLoyalty
      isDiscountable
      isAlcohol
      itemtypeHandoffConnection {
        edges {
          node {
            id
            isAvailable
            handoffId
            itemtypeId
            handoff {
              handoffId
              id
              label
            }
          }
        }
      }
    }
  `,
};
