import { gql } from '@apollo/client';

const INVENTORY_TRANSFERS_INVENTORYITEM = gql`
  query INVENTORY_TRANSFERS_INVENTORYITEM(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $filter: [InventorytransferInventoryitemFilter]
    $sort: [InventorytransferInventoryitemSort]
    $transferId: Int
    $shouldSkip: Boolean!
  ) {
    viewer {
      id
      inventorytransferConnection(filter: { inventorytransferId: { eq: $transferId } })
        @skip(if: $shouldSkip) {
        edges {
          node {
            id
            inventorytransferId
            invoice_id
            sourceLocationId
            targetLocationId
            targetLocationAccepted
            sourceLocation {
              label
              storeNumber
              address {
                state {
                  abbreviation
                }
              }
            }
            targetLocation {
              label
              storeNumber
              address {
                state {
                  abbreviation
                }
              }
            }
          }
        }
      }
      inventorytransferInventoryitemConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        fieldGroupOnly: 88
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            ingredient {
              id
              inventoryitem {
                id
                label
              }
            }
            measure {
              id
              label
              abbreviation
            }
            cost
            quantity
            calculatedTotalCost
          }
        }
      }
    }
  }
`;

export default INVENTORY_TRANSFERS_INVENTORYITEM;
