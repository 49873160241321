import { DateTime } from 'luxon';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import { generateStoreAbbreviation } from '../';
import { toFixed10 } from '../mathUtils';

const SORT_BY_JOB_ORDER_KEY = 1;
const SORT_BY_FIRST_NAME = 2;

const getTotalHoursByWeekday = (schedules, locationTimezone, daysOfWeek, currentTimelineDate) => {
  const body = [];
  const hours = new Array(daysOfWeek.length).fill(0);

  schedules?.viewer?.scheduleConnection.edges?.map(schedule => {
    const scheduleStarted = moment.tz(schedule.node.started, locationTimezone);
    const scheduleFinished = moment.tz(schedule.node.finished, locationTimezone);
    const scheduleDayNumber = daysOfWeek.indexOf(scheduleStarted.format('dddd'));

    const scheduleIsOnTimelineDate =
      currentTimelineDate < DateTime.fromISO(scheduleStarted.toISOString()) &&
      DateTime.fromISO(scheduleStarted.toISOString()) < currentTimelineDate.plus({ day: 1 });

    if (currentTimelineDate && !scheduleIsOnTimelineDate) {
      return;
    }

    const totalWorkHoursMs = scheduleFinished.valueOf() - scheduleStarted.valueOf();
    const totalWorkHours = Math.ceil(totalWorkHoursMs / (1000 * 60 * 60));

    hours[scheduleDayNumber] += totalWorkHours;
  });

  hours.forEach(hour => {
    body.push([
      {
        stack: [
          {
            text: toFixed10(hour, 2),
            style: ['totalHours'],
            alignment: daysOfWeek.length > 1 ? 'center' : 'left',
            margin: [0, 5],
          },
        ],
      },
    ]);
  });

  return body;
};

const calculateTotalHoursForSingleDay = currentKeySchedules => {
  return Object.values(currentKeySchedules).reduce((acc, curr) => {
    return (
      acc +
      curr.reduce((previous, current) => {
        const currentShiftText = current.text;
        const shiftStartAndEndHours = currentShiftText.split(' ')[0].split('-');

        // Convert shift start string to DateTime
        const shiftStartString = shiftStartAndEndHours[0];
        const shiftStart = DateTime.fromFormat(shiftStartString, 'h:mma');

        // Convert shift end string to DateTime
        const shiftEndString = shiftStartAndEndHours[1].slice(0, -1);
        const shiftEnd = DateTime.fromFormat(shiftEndString, 'h:mma');

        return previous + shiftEnd.diff(shiftStart, 'hours').toObject().hours;
      }, 0)
    );
  }, 0);
};

const createSchedulePdfBody = (
  schedules,
  locationTimezone,
  daysOfWeek,
  employeeJobHourTotals,
  sortOrder,
  currentTimelineDate,
) => {
  // Reduces schedules by day with job info if job sort is selected
  // Reduces schedules by person ID if first name sort is selected
  const reducedSchedules = schedules?.viewer?.scheduleConnection.edges?.reduce(
    (accumulator, currentValue) => {
      // Current schedule data
      const currentSchedule = currentValue?.node;

      const person = currentSchedule?.person;

      const currentJob = person?.personJobConnection?.edges?.filter(
        personJob => personJob?.node?.job?.jobId === currentSchedule?.jobId,
      )?.[0]?.node?.job;

      const personId = currentSchedule.personId;

      // Schedule started and finished
      const scheduleStartedDate = DateTime.fromSQL(currentSchedule.started, {
        zone: 'utc',
      }).setZone(locationTimezone);

      const scheduleStartedHour = scheduleStartedDate.toFormat('h:mma');

      const scheduleFinishedDate = DateTime.fromSQL(currentSchedule.finished, {
        zone: 'utc',
      }).setZone(locationTimezone);

      const scheduleFinishedHour = scheduleFinishedDate.toFormat('h:mma');

      const startedDay = DateTime.fromSQL(currentSchedule.started, {
        zone: 'utc',
      }).setZone(locationTimezone).weekdayLong;

      // PDF body data
      const shift = `${scheduleStartedHour}-${scheduleFinishedHour}`;
      const personFullName = `${person?.firstName} ${person?.lastName}`;

      let margin = [0, 5, 0, 10];

      const getWeekdaySchedules = (weekday, job, person = null) => {
        if (person) {
          return accumulator[person].schedulesByDay[weekday];
        }

        return accumulator[job].schedulesByDay[weekday];
      };

      const scheduleIsOnTimelineDate =
        currentTimelineDate < scheduleStartedDate &&
        scheduleStartedDate < currentTimelineDate.plus({ day: 1 });

      if (currentTimelineDate && !scheduleIsOnTimelineDate) {
        return accumulator;
      }

      // Assign schedule to schedule date object
      if (sortOrder === SORT_BY_JOB_ORDER_KEY) {
        if (accumulator[currentJob?.jobId]) {
          if (getWeekdaySchedules(startedDay, currentJob?.jobId)?.length) {
            margin = [0, 0, 0, 10];
          }
        } else {
          accumulator[currentJob?.jobId] = {
            jobLabel: {
              text: currentJob?.label ?? 'Removed Job',
              orderKey: currentJob?.orderKey,
              jobId: currentJob?.jobId,
            },
            schedulesByDay: {
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            },
          };
        }

        getWeekdaySchedules(startedDay, currentJob?.jobId).push({
          text: `${shift}\n ${personFullName}`,
          margin,
        });
      }

      if (sortOrder === SORT_BY_FIRST_NAME) {
        if (accumulator[personId]) {
          if (getWeekdaySchedules(startedDay, null, personId)?.length) {
            margin = [0, 0, 0, 10];
          }
        } else {
          accumulator[personId] = {
            personName: {
              text: personFullName,
            },
            schedulesByDay: {
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            },
          };
        }

        getWeekdaySchedules(startedDay, null, personId).push({
          text: `${shift}\n ${currentJob?.label ?? 'Removed Job'}`,
          margin,
        });
      }

      return accumulator;
    },
    {},
  );

  // Transform schedule date object to pdf body data
  let body = [];
  Object.keys(reducedSchedules).forEach(jobId => {
    const currentKeySchedules = reducedSchedules[jobId].schedulesByDay;
    const weekdaySchedulesBody = daysOfWeek.map(day => ({ stack: currentKeySchedules[day] }));

    let totalHours = 0;
    if (jobId !== 'undefined') {
      if (sortOrder === SORT_BY_JOB_ORDER_KEY) {
        if (currentTimelineDate) {
          // Single day is selected
          totalHours = calculateTotalHoursForSingleDay(currentKeySchedules);
        } else {
          // Calculate total hours for week
          totalHours = Object.values(employeeJobHourTotals).reduce((acc, curr) => {
            if (curr.jobs[reducedSchedules[jobId].jobLabel?.jobId]) {
              return acc + curr.jobs[reducedSchedules[jobId].jobLabel?.jobId][0].hours;
            }

            return acc;
          }, 0);
        }
      }

      if (sortOrder === SORT_BY_FIRST_NAME) {
        if (currentTimelineDate) {
          // Single day is selected
          totalHours = calculateTotalHoursForSingleDay(currentKeySchedules);
        } else {
          //Calculate total hours for individual day
          totalHours = Object.values(employeeJobHourTotals).filter(
            emp => `${emp.firstName} ${emp.lastName}` === reducedSchedules[jobId].personName.text,
          )[0]?.totalHours;
        }
      }

      totalHours = toFixed10(totalHours, 2);
    } else {
      // Current job was removed and number of hours is unknown
      totalHours = '/';
    }

    body.push([
      {
        text:
          sortOrder === SORT_BY_JOB_ORDER_KEY
            ? reducedSchedules[jobId].jobLabel.text
            : reducedSchedules[jobId].personName.text,
        orderKey:
          sortOrder === SORT_BY_JOB_ORDER_KEY
            ? reducedSchedules[jobId].jobLabel.orderKey
            : reducedSchedules[jobId].personName.text,
        margin: [0, 5, 0, 0],
      },
      ...weekdaySchedulesBody,
      { stack: [{ text: totalHours, alignment: 'center', margin: [0, 5, 0, 0] }] },
    ]);
  });

  if (sortOrder === SORT_BY_JOB_ORDER_KEY) {
    body.sort((a, b) => {
      if (a[0].orderKey < b[0].orderKey) {
        return -1;
      }
      if (a[0].orderKey > b[0].orderKey) {
        return 1;
      }
      return 0;
    });
  }

  if (sortOrder === SORT_BY_FIRST_NAME) {
    body.sort((a, b) => {
      if (a[0].text < b[0].text) {
        return -1;
      }
      if (a[0].text > b[0].text) {
        return 1;
      }
      return 0;
    });
  }

  body.push([
    { text: 'Total Hours', style: ['totalHours'], margin: [0, 5] },
    ...getTotalHoursByWeekday(schedules, locationTimezone, daysOfWeek, currentTimelineDate),
    {
      stack: [
        {
          text: '/',
          style: ['totalHours'],
          alignment: daysOfWeek.length > 1 ? 'center' : 'left',
          margin: [0, 5],
        },
      ],
    },
  ]);

  return body;
};

const generateSchedulePdf = async (
  location,
  dateInMs,
  schedules,
  employeeJobHourTotals,
  sortOrder,
  timelineVisibleDate,
  open = true,
) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let daysOfWeek, currentTimelineDate;
  if (timelineVisibleDate) {
    currentTimelineDate = DateTime.fromISO(timelineVisibleDate.toISOString()).setZone(
      location.timezone.label,
    );
    daysOfWeek = [currentTimelineDate.weekdayLong];
  } else {
    daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map(days =>
      moment
        .tz(dateInMs, location.timezone.label)
        .add(days, 'days')
        .format('dddd'),
    );
  }

  let pdfDateRange = `${moment(dateInMs).format('ddd MMM D, YY')} - ${moment(dateInMs)
    .add(6, 'days')
    .endOf('day')
    .format('ddd MMM D, YY')}`;
  if (timelineVisibleDate) {
    pdfDateRange = `${moment(dateInMs).format('ddd MMM D, YY')}`;
  }

  const docDefinition = {
    layout: 'lightHorizontalLines', // optional
    pageOrientation: 'landscape',
    pageSize: 'A4',
    content: [
      {
        columns: [
          'Scheduled Employees',
          `${generateStoreAbbreviation(location).abbreviation} ${location.label}`,
          pdfDateRange,
        ],
      },
      {
        layout: 'lightHorizontalLines', // optional
        widths: ['*', 'auto', 100, '*'],
        margin: [0, 40, 0, 0],
        table: {
          headerRows: 1,
          widths: new Array(daysOfWeek.length + 2).fill(timelineVisibleDate ? '*' : 'auto'),
          body: [
            [
              {
                text: sortOrder === SORT_BY_JOB_ORDER_KEY ? 'Job' : 'Employee Name',
                bold: true,
                fontSize: 12,
              },
              ...daysOfWeek.map(day => ({ text: day, bold: true, fontSize: 12 })),
              { text: 'Total Hours', bold: true, fontSize: 12 },
            ],
            ...createSchedulePdfBody(
              schedules,
              location.timezone.label,
              daysOfWeek,
              employeeJobHourTotals,
              sortOrder,
              currentTimelineDate,
            ),
          ],
        },
      },
    ],
    styles: {
      totalHours: {
        bold: true,
        fontSize: 12,
        lineHeight: 1.5,
      },
    },
    defaultStyle: {
      fontSize: 10,
    },
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  if (open) {
    pdfDocGenerator.open();
  } else {
    return await new Promise(resolve => {
      pdfDocGenerator.getBase64(data => resolve(data));
    });
  }
};

export default generateSchedulePdf;
