import { gql } from '@apollo/client';

export const jobFragment = {
  job: gql`
    fragment JobData on Job {
      id
      jobId
      label
    }
  `,
};
