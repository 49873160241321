import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { LOCATIONS } from '../../../../../apollo/queries';
import FETCH_INVENTORY_COUNT_EXPORT from '../../../../../apollo/queries/inventoryCount/fetchInventoryCountExport';
import { pad } from '../../../../../utils';
import { ConnectionTable, SelectFactory } from '../../../../blocks';
import PaperMain from '../../../../blocks/PaperMain';
import Filters from './Filters';
import Togglers from './Togglers';

const groupByOptions = [
  { label: 'Category/Subcategory', value: 'inventoryitemcategory' },
  { label: 'General Ledger', value: 'generalledgeraccount' },
  { label: 'Reporting Category', value: 'reportcategory' },
];

const initialDateRange = {
  gte: moment()
    .add(-7, 'days')
    .format('MM/DD/YYYY'),
  lte: moment().format('MM/DD/YYYY'),
};

const InventoryCount = () => {
  const [location, setLocation] = useState();
  const [filters, setFilters] = useState({
    inventoryitemcategory: [],
    generalledgeraccount: [],
    reportcategory: [],
    groupBy: groupByOptions[0].value,
    displayAsCost: 0,
    displaySubategories: 1,
    dateRange: initialDateRange,
  });

  const handleOnLocationSelect = useCallback(value => {
    setLocation(value);
  }, []);

  const structureTable = useCallback(
    data => {
      const groupById = `${filters.groupBy}Id`;

      const groups = [];

      let tableData = data?.viewer?.locationIngredientConnection?.edges
        ?.filter(edge => !!edge.node?.inventoryitem)
        .reduce((result, edge) => {
          if (
            result[result.length - 1]?.[groupById] !==
            edge.node?.inventoryitem?.inventoryitemcategory?.[groupById]
          ) {
            groups.push({
              [groupById]: edge.node?.inventoryitem?.inventoryitemcategory?.[groupById],
              index: result.length,
            });
          } else {
            const group = groups[groups.length - 1];

            groups[groups.length - 1] = {
              ...group,
            };
          }

          if (filters.displaySubategories) {
            result.push({
              label: edge.node?.inventoryitem?.label,
              invUom: `${edge.node?.inventoryitem?.measure?.label} (${edge.node?.inventoryitem?.measure?.abbreviation})`,
              groupByLabel:
                filters.groupBy === groupByOptions[0].value
                  ? edge.node?.inventoryitem?.inventoryitemcategory.label
                  : edge.node?.inventoryitem?.inventoryitemcategory[filters.groupBy].label,
              totalQty: edge.node?.inventoryitem?.inventorycountSummary.totalQty || 0,
              storages: edge.node?.inventoryitem?.inventorycountSummary.storages,
              totalQtyCost: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(edge.node?.inventoryitem?.inventorycountSummary.totalCost),
              totalQtyCostNoFormat: edge.node?.inventoryitem?.inventorycountSummary.totalCost,
              [groupById]: edge.node?.inventoryitem?.inventoryitemcategory?.[groupById],
            });
          }
          return result;
        }, []);

      groups.forEach(group => {
        tableData[group.index] = {
          ...tableData[group.index],
        };
      });

      const totalCost = tableData?.reduce((prev, curr) => {
        if (curr.totalQtyCostNoFormat) {
          return (prev += curr.totalQtyCostNoFormat);
        }
        return prev;
      }, 0);

      tableData?.push({ groupByLabel: '---' });
      tableData?.push({
        groupByLabel: 'TOTAL COST',
        totalQtyCost: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(totalCost),
        isTotalCost: true,
        isGroupLabel: true,
      });
      return tableData;
    },
    [filters.groupBy, filters.displaySubategories],
  );

  return (
    <PaperMain>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <SelectFactory
            label="Location"
            name="locationId"
            placeholder="Search location..."
            fullWidth
            query={LOCATIONS}
            multiple={false}
            disableSearchReset
            renderCheckBox={false}
            otherVariables={{
              fieldGroupOnly: 95,
            }}
            onSelect={value => {
              handleOnLocationSelect(value);
            }}
            structureData={data =>
              data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                value: edge.node.locationId,
                id: edge.node.locationId,
                index,
                label: `${edge.node?.address?.state?.abbreviation}-${pad(
                  edge.node?.storeNumber || 0,
                  4,
                  '0',
                )} - ${edge.node.label}`,
              }))
            }
          />
        </Grid>
        {location?.id ? (
          <Grid item xs={12}>
            <ConnectionTable
              nested
              alignItemsCenter="flex-start"
              showPagination={false}
              customActionsCenter={(_, { refetch, variables }) => (
                <Filters
                  groupByOptions={groupByOptions}
                  filters={filters}
                  setFilters={setFilters}
                  refetch={refetch}
                  variables={variables}
                />
              )}
              customActionsBottom={(data, { refetch, variables, tableData }) => (
                <Togglers
                  refetch={refetch}
                  variables={variables}
                  filters={filters}
                  setFilters={setFilters}
                  tableData={tableData}
                  locationLabel={location?.label}
                />
              )}
              query={FETCH_INVENTORY_COUNT_EXPORT}
              initialQueryVariables={{
                locationId: location.id,
                dateRange: initialDateRange,
                filter: {
                  locationId: { eq: location?.id },
                },
                sort: {
                  inventoryitem: {
                    inventoryitemcategory: {
                      inventoryitemcategoryId: 'ASC',
                    },
                  },
                },
              }}
              columns={[
                { title: 'Category', field: 'groupByLabel', disableSort: true },
                {
                  title: 'Inventory Item',
                  field: 'label',
                  disableSort: true,
                  render: (label, _, row) => (row.isGroupLabel ? <b>{label}</b> : label),
                },
                { title: 'Storage Area', field: 'storages', disableSort: true },
                { title: 'Main Uom', field: 'invUom', disableSort: true },
                { title: 'Total Qty', field: 'totalQty', disableSort: true },
                {
                  title: 'Total Cost',
                  field: 'totalQtyCost',
                  disableSort: true,
                  render: (label, _, row) => (row.isGroupLabel && !row.isTotalCost ? '' : label),
                },
              ]}
              structureTable={data => structureTable(data)}
              title="Inventory Count"
            />
          </Grid>
        ) : null}
      </Grid>
    </PaperMain>
  );
};

export default InventoryCount;
